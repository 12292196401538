import { isUndefinedOrNull } from 'src/common/utils'
import { Contact } from 'src/types/Contact'
import { DataDoc } from 'src/types/CouchDb'

export function contactConverter() {
    const { CUSTOMER, VENDOR, INTERNAL, OTHER } = Contact.Type

    return (dataDoc: Contact.ContactDocument | DataDoc<Contact.ContactDocument>): Contact.ContactDocument => {
        const contactDoc: Contact.ContactDocument =
            (dataDoc as DataDoc<Contact.ContactDocument>).doc || (dataDoc as Contact.ContactDocument)

        let { type, name, avatarUrl } = contactDoc

        if (avatarUrl) {
            avatarUrl = avatarUrl.replace('http://', 'https://')
        }

        if (isUndefinedOrNull(type)) {
            type = OTHER
        }

        if (isUndefinedOrNull(name)) {
            name = ''
        }

        return {
            ...contactDoc,
            type,
            name,
        }
    }
}
