import { ConfigureDocument } from 'src/types/Configure'

export function updateTourGuideValue(newValue: Array<string>) {
  return (tourGuideConfigure: ConfigureDocument) => ({
    ...tourGuideConfigure,
    value: JSON.stringify(newValue),
  })
}

export function updateHiddenEnvelopesValue(hiddenEnvelopesValue: Array<number>, value: number, isHidden: boolean) {
  const newValue = addOrRemoveHiddenEnvelope(hiddenEnvelopesValue, value, isHidden)

  return (tourGuideConfigure: ConfigureDocument) => ({
    ...tourGuideConfigure,
    value: newValue.join(),
  })
}

function addOrRemoveHiddenEnvelope(hiddenEnvelopes: Array<number>, value: number, isHidden: boolean): Array<number> {
  if (isHidden) {
    return [...hiddenEnvelopes, value]
  } else {
    return hiddenEnvelopes.filter(hiddenEnvelope => hiddenEnvelope !== value)
  }
}
