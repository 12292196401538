import * as Sentry from "@sentry/react"
import { BrowserTracing } from "@sentry/tracing"
import {
  getDebugConsoleLogs,
  getGitCommit,
  getDebugSentryAcceptLocalhost,
  getSentryDsn,
  getSentryEnv,
  getVersion,
  isProduction,
} from "src/common/environment"
import { isUndefinedOrNull } from "src/common/utils"
import { getErrorText } from "src/common/helpers"
import { isDesktop as shouldLogToSentry, isSupported } from "src/common/runtime"

export function isDebug() {
  return window.location.hash.includes("debug=true")
}

function disableLogging() {
  const debugLoggingMethods = [
    "log",
    "info",
    "debug",
    "group",
    "groupCollapsed",
    "groupEnd",
    "warn",
    "error",
  ]
  debugLoggingMethods.forEach((method) => (console[method] = () => {}))
}

export function disableLoggingInProduction() {
  if (!isDebug() && !getDebugConsoleLogs() && isProduction()) {
    disableLogging()
  }
}

/* Sentry module */
/* Sentry config/utility functions */

export function sentryUserContext(userContext) {
  Sentry.setUser(userContext)
}

function buildError(exception, message) {
  const newError = new Error(`${message}: ${getErrorText(exception)}`)
  const latestStack = newError.stack.split("\n")
  // remove 2 rows which are 2 internal calls in this module. Start from 1 since the first one is the error message
  latestStack.splice(1, 2)
  newError.stack = `${exception.stack}\n${latestStack.join("\n")}`
  // name = message is for backward compatibility otherwise it would be nice to have
  // newError.name = 'CaughtError' or 'CustomError'or something more specific like newError.name = 'AppInitError'
  // instead of 'action=appInit ....'
  newError.name = newError.message
  return newError
}

export function initSentry() {
  Sentry.init({
    dsn: getSentryDsn(),
    release: getVersion(),
    integrations: [new BrowserTracing()],
    environment: getSentryEnv(),
    // sample rate - number between 0 and 1
    tracesSampleRate: 0.05,
    // autoBreadcrumbs: true = default value
    // stacktrace: true, // stacktrace: default is false
    ignoreUrls: getDebugSentryAcceptLocalhost() ? [] : [/localhost/, /webpack/],
  })
  Sentry.setTag("git_commit", getGitCommit())
  // otherwise we'll need to search supported:True with uppercase T
  Sentry.setTag("supported", isSupported ? "true" : "false")
}

export function buildCaptureException(message = "Caught exception", extraData = {}) {
  return (exception) => {
    captureException(exception, message, extraData)
  }
}

/* Sentry logging functions */
export function error(message, data) {
  if (shouldLogToSentry) {
    Sentry.withScope((scope) => {
      scope.setExtras(data)
      scope.setLevel("error")
      Sentry.captureMessage(message)
    })
  }
  console.error(message, data)
}

export function warning(message, data) {
  if (shouldLogToSentry) {
    Sentry.withScope((scope) => {
      scope.setExtras(data)
      scope.setLevel("warning")
      Sentry.captureMessage(message)
    })
  }
  console.warn(message, data)
}

export function info(message, data) {
  if (shouldLogToSentry) {
    Sentry.withScope((scope) => {
      scope.setExtras(data)
      scope.setLevel("info")
      Sentry.captureMessage(message)
    })
  }
  console.info(message, data)
}

export function captureException(exception, message = "Caught exception", extraData = {}) {
  if (shouldLogToSentry) {
    Sentry.withScope((scope) => {
      scope.setExtras({
        ...extraData,
        originalErrorName: exception.name,
        originalMessage: exception.message,
      })
      Sentry.captureException(buildError(exception, message))
    })
  }
  console.error(message, exception, extraData)
}

/* end of Sentry logging functions, end of Sentry module */

export function logMissingAttributes(requiredAttributes, doc) {
  requiredAttributes.forEach((attribute) => {
    const attributeValue = doc[attribute]
    if (isUndefinedOrNull(attributeValue)) {
      info(`There is missing required attribute '${attribute}'!`, { doc })
    }
  })
}
