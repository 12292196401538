import * as actions from './actions'
import { combineReducers } from 'redux'
import { FormType } from 'src/frontend/scenes/settings/enums'

const initialFormState = {
  formType: FormType.ADD,
  isOpen: false,
  formValues: {},
  currency: null,
  loadingExchange: false,
  errors: {},
}

function form(state = initialFormState, action) {
  switch (action.type) {
    case actions.SETTINGS_CURRENCIES_EXCHANGE_RATE_START: {
      return { ...state, loadingExchange: true }
    }
    case actions.SETTINGS_CURRENCIES_EXCHANGE_RATE_FINISH:
    case actions.SETTINGS_CURRENCIES_EXCHANGE_RATE_ERROR: {
      return { ...state, loadingExchange: false }
    }
    case actions.SETTINGS_CURRENCIES_CLOSE_FORM: {
      return {
        ...state,
        formType: FormType.ADD,
        currency: {},
        isOpen: false,
        errors: {},
        formValues: {},
      }
    }
    case actions.SETTINGS_CURRENCIES_OPEN_FORM: {
      return { ...state, isOpen: true, ...action.payload }
    }
    case actions.SETTINGS_CURRENCIES_SAVE_START:
    case actions.SETTINGS_CURRENCIES_SAVE_SUCCESS:
    case actions.SETTINGS_CURRENCIES_SAVE_ERROR: {
      return { ...state, ...action.payload }
    }
    case actions.SETTINGS_CURRENCIES_CHANGE_FIELD_VALUE:
    case actions.SETTINGS_CURRENCIES_CHANGE_CODE_VALUE: {
      return { ...state, formValues: { ...state.formValues, ...action.payload } }
    }
    default: {
      return state
    }
  }
}

const initialDeleteFormState = {
  referencedTypes: null,
  canDelete: null,
  currency: {},
}
function deleteForm(state = initialDeleteFormState, action) {
  switch (action.type) {
    case actions.SETTINGS_CURRENCIES_DELETE_SUCCESS: {
      return { ...state, currency: null }
    }
    case actions.SETTINGS_CURRENCIES_DELETE_START:
    case actions.SETTINGS_CURRENCIES_DELETE_ERROR:
    case actions.SETTINGS_CURRENCIES_OPEN_DELETE_FORM: {
      return { ...state, ...action.payload }
    }
    case actions.SETTINGS_CURRENCIES_CLOSE_DELETE_FORM: {
      return { ...state, canDelete: null, referencedTypes: null }
    }
    default: {
      return state
    }
  }
}

const rootReducer = combineReducers({ form, deleteForm })

export default rootReducer
