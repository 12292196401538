import {pipe} from 'ramda'
import { UserConsentType, UserDeleteDataType } from 'src/frontend/scenes/settings/privacy/enums'
import { selectSettings } from 'src/frontend/scenes/settings/selectors'
import { RootState } from 'src/types/State'
import { PrivacyState } from 'src/frontend/scenes/settings/privacy/reducer'

export const selectEmailingConsentModalOpen = consentModalOpen(UserConsentType.EMAILING)

export const selectDeleteUserAndDataModalOpen = deleteDataModalOpen(UserDeleteDataType.DELETE_USER_AND_DATA)
export const selectDeleteUserAndDataConfirmModalOpen = deleteDataModalOpen(
    UserDeleteDataType.DELETE_USER_AND_DATA_CONFIRM
)
export const selectDeleteDataModalOpen = deleteDataModalOpen(UserDeleteDataType.DELETE_DATA)
export const selectDeleteDataConfirmModalOpen = deleteDataModalOpen(UserDeleteDataType.DELETE_DATA_CONFIRM)

export const selectIsConsentLoading = pipe(selectLocalState, (privacy: PrivacyState) => privacy.loading)

function selectLocalState(state: RootState) {
    return selectSettings(state).privacy
}

function consentModalOpen(type: UserConsentType) {
    return (state: RootState) => {
        const localState = selectLocalState(state)
        return localState.confirmModalOpen && localState.privacyType === type
    }
}

function deleteDataModalOpen(type: UserDeleteDataType) {
    return (state: RootState) => {
        const localState = selectLocalState(state)
        return localState.deleteDataModalOpen && localState.deleteType === type
    }
}
