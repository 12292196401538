import { IntervalGranularityType } from 'src/backend/enums'
import * as timeUtils from 'src/backend/time/time'
import { Period } from 'src/types/Filter'
import _sumBy from 'lodash/sumBy'
import { Dataset } from 'src/frontend/components/chart/types'
import { SpendingByNatureVariant } from 'src/frontend/scenes/dashboard/widgets/SpendingByNatureBars/enums'
import { EnvelopeCardinality } from 'src/backend/categories/enums'
import {
  ASSETS_PURCHASE_ID,
  FIXED_COSTS_SUPER_ENVELOPE_IDS,
  getEnvelopesByCardinality, getEnvelopesBySuperEnvelopeId,
  getEnvelopesBySuperEnvelopeIds, VARIABLE_COSTS_SUPER_ENVELOPE_IDS,
} from 'src/backend/categories/envelopes'
import { isEnvelopeExpense } from 'src/backend/categories/helpers'

export function getDashboardGranularity(period: Period): IntervalGranularityType {
  const intervalSize = timeUtils.getDayIntervalSize(period.start, period.end)

  if (intervalSize >= 365) {
    return IntervalGranularityType.MONTH
  }

  if (intervalSize >= 90) {
    return IntervalGranularityType.WEEK
  }

  return IntervalGranularityType.DAY
}

export function sortByDataSum(a: Dataset, b: Dataset) {
  return Math.abs(_sumBy(b.data, (dataPoint) => dataPoint.y)) - Math.abs(_sumBy(a.data, (dataPoint) => dataPoint.y))
}

export function filterEmptyDatasets(dataset: Dataset) {
  return !dataset.data.every((value) => value.y === 0)
}

export function mapBarDatasetToPieDataset(dataset: Dataset) {
  return {
    id: dataset.id,
    category: dataset.category,
    value: _sumBy(dataset.data, (value) => value.y * 100),
  }
}

export function getCardinalityEnvelopes(variant: SpendingByNatureVariant) {
  switch (variant) {
    default:
    case SpendingByNatureVariant.SPENDING:
      return {
        [EnvelopeCardinality.MUST]: getEnvelopesByCardinality(EnvelopeCardinality.MUST).filter(isEnvelopeExpense),
        [EnvelopeCardinality.NEED]: getEnvelopesByCardinality(EnvelopeCardinality.NEED).filter(isEnvelopeExpense),
        [EnvelopeCardinality.WANT]: getEnvelopesByCardinality(EnvelopeCardinality.WANT).filter(isEnvelopeExpense),
      }

    case SpendingByNatureVariant.COSTS:
      return {
        [EnvelopeCardinality.FIXED_COSTS]: getEnvelopesBySuperEnvelopeIds(FIXED_COSTS_SUPER_ENVELOPE_IDS),
        [EnvelopeCardinality.VARIABLE_COSTS]: getEnvelopesBySuperEnvelopeIds(VARIABLE_COSTS_SUPER_ENVELOPE_IDS),
        [EnvelopeCardinality.ASSETS_COSTS]: getEnvelopesBySuperEnvelopeId(ASSETS_PURCHASE_ID),
      }
  }
}
