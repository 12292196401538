import { CLEAR_ERROR, SHOW_ERROR } from 'src/frontend/Error/actions'
import { ErrorType } from 'src/frontend/scenes/app/types'

const initialErrorState: ErrorType = {
  errorMessageId: null,
  errorRecoverable: false,
}

export function error(state: ErrorType = initialErrorState, action) {
  switch (action.type) {
    case SHOW_ERROR: {
      const { errorMessageId, errorRecoverable } = action
      return {
        ...state,
        errorMessageId,
        errorRecoverable,
      }
    }

    case CLEAR_ERROR: {
      return initialErrorState
    }

    default: {
      return state
    }
  }
}
