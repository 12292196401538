import { combineReducers } from 'redux'
import { IntervalCompareToType, IntervalGranularityType } from 'src/backend/enums'
import * as actions from 'src/frontend/scenes/analytics/actions'
import { ChartType } from 'src/frontend/components/chart/ChartType'
import { ChartSubjectType } from 'src/frontend/scenes/analytics/enums'
import { ChartState, ReportState, ViewDefinition } from 'src/frontend/scenes/analytics/types'
import { Action } from 'src/types/common'
import { isAppBoard } from 'src/common/environment'

export const viewDefinitionInitialState: ViewDefinition = {
  subject: isAppBoard() ? ChartSubjectType.BOARD_CASH_FLOW_REPORT : ChartSubjectType.INCOMES_EXPENSES_REPORT,
  groupBy: 'none',
  granularity: IntervalGranularityType.DAY,
  chartType: ChartType.LINE,
  compareTo: IntervalCompareToType.PREVIOUS_MONTH,
}

function viewDefinition(state: ViewDefinition = viewDefinitionInitialState, action: Action): ViewDefinition {
  switch (action.type) {
    case actions.CHANGE_VIEW_DEFINITION: {
      return { ...state, ...action.payload }
    }
    default: {
      return state
    }
  }
}

const chartInitialState: ChartState = {
  datasets: [],
}

function chart(state: ChartState = chartInitialState, action: Action): ChartState {
  switch (action.type) {
    case actions.FETCH_CHART_DATA_SUCCESS:
    case actions.FETCH_CHART_DATA_START:
    case actions.FETCH_CHART_DATA_ERROR: {
      return { ...state, ...action.payload }
    }
    default: {
      return state
    }
  }
}

const reportInitialState: ReportState = {
  categoriesReportData: null,
  boardCashFlowReportData: null,
}

function report(state: ReportState = reportInitialState, action: Action): ReportState {
  switch (action.type) {
    // case actions.REPORTS_CATEGORIES_DATA_START: {
    //   return { ...state, categoriesReportData: null }
    // }
    case actions.REPORTS_CATEGORIES_DATA_SUCCESS: {
      return { ...state, categoriesReportData: action.payload }
    }
    case actions.REPORTS_BOARD_CASHFLOW_DATA_SUCCESS: {
      return { ...state, boardCashFlowReportData: action.payload }
    }
    default: {
      return state
    }
  }
}

export default combineReducers({ viewDefinition, chart, report })
