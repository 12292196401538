import { Document, Image, Page, Text, View } from '@react-pdf/renderer'
import React from 'react'
import BBlogo from 'src/frontend/assets/images/receipt-logo.png'
import { formatMessage } from 'src/frontend/modules/intl'
import {
  BraintreeTransaction,
} from 'src/frontend/scenes/billing/transactionHistory/types'
import { isAppBoard } from 'src/common/environment'
import PaymentMethod from 'src/frontend/scenes/settings/billing/Receipt/components/PaymentMethod'
import ReceiptTable from 'src/frontend/scenes/settings/billing/Receipt/components/ReceiptTable'
import BBAddress from 'src/frontend/scenes/settings/billing/Receipt/components/BBAddress'
import CustomerAddress from 'src/frontend/scenes/settings/billing/Receipt/components/CustomerAddress'


interface Props {
  transaction: BraintreeTransaction,
}

export default function Receipt({ transaction }: Props) {
  return (
    <Document>
      <Page size="A4" style={{ backgroundColor: 'white', padding: '20pt', fontSize: 10 }}>
        <View style={{ marginBottom: '30pt', display: 'flex', flexDirection: 'row' }}>
          <Image src={BBlogo} style={{ margin: '1pt', width: 'auto', height: '30pt' }} />
        </View>
        <View style={{ display: 'flex', flexDirection: 'row', marginBottom: '25pt' }}>
          <BBAddress />
          {isAppBoard() && transaction.billingAddress ? <CustomerAddress {...transaction.billingAddress} /> : null}
        </View>
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            marginBottom: '10pt',
          }}
        >
          <Text style={{ fontFamily: 'Helvetica-Bold', marginRight: '5pt' }}>
            {formatMessage('billing.receipt.payment-reference')}:
          </Text>
          <Text>{transaction.id}</Text>
        </View>
        <ReceiptTable transaction={transaction} />
        <PaymentMethod paymentMethod={transaction.paymentMethod} />
      </Page>
    </Document>
  )
}
