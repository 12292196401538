import React from 'react'
import { FormattedMessage } from 'src/frontend/modules/intl'
import SoftLoginForm from 'src/frontend/scenes/auth/softLogin/SoftLoginForm'
import styles from 'src/frontend/scenes/auth/softLogin/SoftLoginPage.module.less'
import * as userSelectors from 'src/frontend/modules/user/selectors'
import { connect } from 'react-redux'
import { RootState } from 'src/types/State'
import { logoutSoft } from 'src/frontend/scenes/app/actions'
import { login } from 'src/frontend/modules/user/actions'

function mapStateToProps(state: RootState) {
  return {
    user: userSelectors.selectUser(state),
  }
}

const mapDispatchToProps = {
  handleLogout: logoutSoft,
  handleLogin: login,
}

function SoftLoginPage({ user, handleLogout, handleLogin }) {
  return (
      <div className={styles.softLogin}>
        <div className={styles.softLoginContent}>
          <div className="auth-content-header">
            <h1><FormattedMessage id="auth.welcome" /></h1>
          </div>

          <SoftLoginForm user={user} onBackToLoginPage={handleLogout} onLoginSubmit={handleLogin} />

        </div>
      </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(SoftLoginPage)
