import { RecordCategoryLevel } from 'src/backend/categories/enums'
import { isUndefinedOrNull } from 'src/common/utils'
import { PieAndSpendingWidgetVariant } from 'src/frontend/scenes/dashboard/widgets/PieAndBarSpending/enums'
import { SpendingPieData } from 'src/frontend/scenes/dashboard/widgets/PieAndBarSpending/types'
import { Dataset } from 'src/frontend/components/chart/types'
import tinyColor from 'tinycolor2'
import { FilterType } from 'src/types/Filter'
import { isSuperEnvelopeId } from 'src/backend/categories/envelopes'
import _omit from 'lodash/omit'
import { ChartType } from 'src/frontend/components/chart/ChartType'

export const CATEGORY_LEVELS = [
  RecordCategoryLevel.SUPER_ENVELOPE,
  RecordCategoryLevel.ENVELOPE,
  RecordCategoryLevel.CATEGORY,
]

export function isPossibleToChangeLevel(categoryLevel: RecordCategoryLevel, direction: 1 | -1) {
  return !isUndefinedOrNull(CATEGORY_LEVELS[CATEGORY_LEVELS.indexOf(categoryLevel) + direction])
}

export function getPieBarSpendingHeader(variant: PieAndSpendingWidgetVariant): string {
  switch (variant) {
    default:
    case PieAndSpendingWidgetVariant.SPENDING:
      return 'dashboard.widget.spending-structure.header'


    case PieAndSpendingWidgetVariant.COSTS:
      return 'dashboard.widget.operating-cost-structure.header'

    case PieAndSpendingWidgetVariant.REVENUE:
      return 'dashboard.widget.operating-revenue-structure.header'
  }
}

export function convertLabels(data: SpendingPieData[]) {
  return data.map((category) => category.name)
}

export function convertPieDatasets(data: SpendingPieData[], selectedIndex) {
  return [
    {
      data: [...data].map((category) => category.value / 100),
      backgroundColor: [...data].map((category, index) => {
        return isUndefinedOrNull(selectedIndex) || selectedIndex === index
          ? category.color
          : `${category.color}30`
      }),
      hoverBackgroundColor: [...data].map((category, index) => {
        return isUndefinedOrNull(selectedIndex) || selectedIndex === index
          ? tinyColor(category.color).darken(5).toString()
          : tinyColor(`${category.color}30`).darken(5).toString()
      }),
      type: ChartType.PIE,
    },
  ]
}

export function filterBarDatasets(datasets: Dataset[], selectedIndex: number): Dataset[] {
  return datasets.map((dataset, index) => {

    return {
      ..._omit(dataset, 'category'),
      hidden: !(isUndefinedOrNull(selectedIndex) || selectedIndex === index),
    }
  })
}

export function convertPieDataToCategoriesFilter(
  filter: FilterType,
  data: SpendingPieData[],
  selectedIndex?: number,
): FilterType {
  const key = getFilterKeyFromCategoryId(data[0].id)

  const values = data
    .filter((_dataset, index) => isUndefinedOrNull(selectedIndex) || index === selectedIndex)
    .map((dataset) => dataset.id)

  return {
    ...filter,
    [key]: values,
  }
}

function getFilterKeyFromCategoryId(id) {
  if (typeof id !== 'number') {
    return 'categoryId'
  }

  return isSuperEnvelopeId(id) ? 'superEnvelopeIds' : 'envelopeIds'
}

export function getCategoryId(widget, index: number) {
  return widget && widget.data && (widget.data.pieData[index] && widget.data.pieData[index].id)
}
