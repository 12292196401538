import React from 'react'
import { IntegrationProviders } from 'src/frontend/scenes/integrations/providersList/types'
import { FormAttributeFields } from "src/frontend/scenes/integrations/types"
import { Divider, Form } from "semantic-ui-react"
import InputType = IntegrationProviders.IntegrationRecipe.InputType
import IntegrationRecipeParam = IntegrationProviders.IntegrationRecipe.IntegrationRecipeParam
import MFAFormGroup from "src/frontend/scenes/integrations/components/MFAFormGroup"
import { FormattedMessage } from "src/frontend/modules/intl"
import { isUndefinedOrNull } from "src/common/utils"
import { PrimaryButton } from "src/frontend/components/Buttons/Buttons"
import FormattedMessageLabel from "src/frontend/modules/intl/components/FormattedMessageLabel"
import TermsOfServiceLink from "src/frontend/components/TermsOfServiceLink"
import PrivacyLink from "src/frontend/components/PrivacyLink"

interface Props {
  params: IntegrationRecipeParam[]
  title: string
  showConsent?: boolean
  loading?: boolean
  onSubmit: (fields: FormAttributeFields, inputType?: InputType, id?: string) => void
}

interface State {
  formValues: FormAttributeFields
  consentGranted: boolean
}

const PARAMETERS_TO_POST = [
  InputType.NUMBER,
  InputType.TEXT,
  InputType.SELECT,
  InputType.LIST,
  InputType.PASSWORD,
  InputType.BOOLEAN,
  InputType.OAUTH_REDIRECT_URL,
  InputType.MULTI_SELECT,
]

export default class MFAForm extends React.Component<Props, State> {
  static defaultProps = {
    showConsent: false,
    loading: false,
  }

  constructor(props) {
    super(props)

    const initValues = props.params
      .filter((param: IntegrationRecipeParam) => PARAMETERS_TO_POST.includes(param.type))
      .reduce((acc, value: IntegrationRecipeParam) => {
        if (value.type === InputType.SELECT || value.type === InputType.LIST) {
          // try to find default selected option
          const preselectedOption = value.selectOptions.find((option) => option.selected)
          return {
            ...acc,
            [value.id]: {
              value: preselectedOption ? preselectedOption.value : undefined,
              name: value.id,
              description: value.description,
            },
          }
        }

        if (value.type === InputType.BOOLEAN) {
          return {
            ...acc,
            [value.id]: {
              value: !!value.value,
              name: value.id,
              description: value.description,
            },
          }
        }
        // try to find default value
        return {
          ...acc,
          [value.id]: {
            value: value.value || undefined,
            name: value.id,
            description: value.description,
          },
        }
      }, {})
    console.log("MFA form form initValues: ", initValues)
    this.state = {
      formValues: initValues,
      consentGranted: true,
    }
  }

  //toggleConsentGranted = () => this.setState((prevState) => ({ consentGranted: !prevState.consentGranted }))

  handleUpdateFormValue = (
    field: string,
    value: string | string[] | boolean,
    shouldSubmit?: boolean,
    label?: string,
  ) =>
    this.setState((prevState: State) => {
      const nextState = {
        formValues: {
          ...prevState.formValues,
          [field]: {
            ...prevState.formValues[field],
            value,
            label,
          },
        },
      }
      shouldSubmit && this.props.onSubmit(nextState.formValues as FormAttributeFields)
      return nextState
    })

  areAllRequiredFieldsFilled = () => {
    const { formValues } = this.state
    const { params } = this.props

    return params
      .filter((param: IntegrationRecipeParam) => PARAMETERS_TO_POST.includes(param.type))
      .filter((param: IntegrationRecipeParam) => !param.optional)
      .every(
        (param: IntegrationRecipeParam) =>
          !isUndefinedOrNull(formValues[param.id].value) && formValues[param.id].value !== "",
      )
  }

  render() {
    const { params, title, showConsent, loading, onSubmit } = this.props
    const { formValues, consentGranted } = this.state

    const OAuthParam = params.find((param) => param.type === InputType.OAUTH_REDIRECT_URL)
    console.log("MFA form OAuthParam: ", OAuthParam)
    console.log("MFA form formValues: ", formValues)
    console.log("MFA form params: ", params)

    return (
      <>
        <p>
          <FormattedMessageLabel id="integrations.newConnection.enter-your-credentials" />
        </p>
        <Form autoComplete="off">
          <MFAFormGroup
            params={params}
            title={title}
            values={formValues}
            onChange={this.handleUpdateFormValue}
          />

          {!OAuthParam && (
            <>
              <PrimaryButton
                fluid
                type="submit"
                disabled={!this.areAllRequiredFieldsFilled() || (showConsent && !consentGranted)}
                loading={loading}
                onClick={() => {
                  if (formValues.account_names && Array.isArray(formValues.account_names.value)) {

                    const selectedAccounts: FormAttributeFields = {}

                    formValues.account_names.value.forEach((account: string) => {
                      const selectedOption = params[0].selectOptions.find((option) => {
                        return option.value === account
                      })
                      selectedAccounts[account] = {
                        name: account,
                        value: account,
                        description: selectedOption.text ?? undefined,
                      }
                    })
                    onSubmit(selectedAccounts, InputType.MULTI_SELECT, "account_names")
                  }

                  onSubmit(formValues)
                }}
              >
                <FormattedMessage id="form.submit" />
              </PrimaryButton>
              <Divider
                section
                hidden
              />
            </>
          )}
          <FormattedMessageLabel
            id="integrations.newConnection.consent"
            values={{
              privacyPolicyLink: <PrivacyLink />,
              termsOfServiceLink: <TermsOfServiceLink />,
            }}
          />
        </Form>
      </>
    )
  }
}
