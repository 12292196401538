import React, { useCallback, useMemo, useRef } from 'react'
import { HostedFieldsHostedFieldsFieldName } from 'braintree-web'
import { isUndefinedOrNull, mergeClasses } from 'src/common/utils'
import paymentMethodStyles from 'src/frontend/scenes/billing/paymentMethod/components/PaymentMethod.module.less'
import styles from 'src/frontend/scenes/billing/paymentMethod/components/CardPayment.module.less'
import { PaymentMethodFormType, PaymentProps } from 'src/frontend/scenes/billing/paymentMethod/components/types'
import { formatMessage, FormattedMessage } from 'src/frontend/modules/intl'
import { useHostedFields } from 'src/frontend/scenes/billing/paymentMethod/hooks'
import { Divider, Input, Loader, Modal, Transition } from 'semantic-ui-react'
import ConfirmPanel from 'src/frontend/scenes/billing/paymentMethod/components/ConfirmPanel'
import { withIntlConsumer } from 'src/frontend/modules/intl/components/withIntlConsumer'

function CardPayment({
  formType,
  braintreeClient,
  selectedProduct,
  loading: paymentLoading,
  onTokenize,
  onError,
}: PaymentProps) {

  const htmlModalRef = useRef<HTMLDivElement>()

  const [
    hostedFieldsInstance,
    threeDInstance,
    selectedCardType,
    fieldsValidity,
    isFormReady,
    showThreeDModal,
    loading,
    setLoading,
    cardholderName,
    setCardholderName,
    setShowThreeDModal,
    handleSubmit,
  ] = useHostedFields(braintreeClient, htmlModalRef, onTokenize, onError, formType, selectedProduct)

  const isEverythingValid = useMemo(() => {
    const isCardholderValid = !isUndefinedOrNull(cardholderName) && cardholderName !== ''

    return isCardholderValid
      && fieldsValidity
      && Object.keys(fieldsValidity).every((fieldName: HostedFieldsHostedFieldsFieldName) => {
        return fieldsValidity[fieldName].isValid
      })
  }, [fieldsValidity, cardholderName])


  const supportedCards = useMemo(() => {
    if (hostedFieldsInstance && braintreeClient) {
      const clientConfiguration = braintreeClient.getConfiguration()
      const hostedFieldsState = hostedFieldsInstance.getState()

      return hostedFieldsState.cards.filter((card) => {
        return clientConfiguration.gatewayConfiguration.creditCards.supportedCardTypes.some((supportedCard) => {
          return supportedCard.toLowerCase() === card.niceType.toLowerCase()
        })
      })
    }
    return []
  }, [hostedFieldsInstance, braintreeClient])

  const handleCancelThreeDSecure = useCallback(() => {
    threeDInstance && threeDInstance.cancelVerifyCard(() => {
      setShowThreeDModal(null)
      setLoading(false)
    })
  }, [threeDInstance])

  return (
    <div className={styles.cardPayment}>
      <h2>
        <FormattedMessage
          id={formType === PaymentMethodFormType.ADD
            ? 'billing.payment-method.card.header'
            : 'billing.payment-method.update'}
        />
      </h2>
      {!isFormReady && (
        <div className={paymentMethodStyles.innerContent}>
          <Loader active inline="centered" />
        </div>
      )}
      <div className={!isFormReady ? paymentMethodStyles.loading : undefined}>
        <div className={paymentMethodStyles.innerContent}>
          <div>
            <div className={styles.supportedCards}>
              <Transition.Group>
                {supportedCards.filter(Boolean).map((card) => (
                    <div
                      key={card.type}
                      className={mergeClasses(
                        styles.creditCard,
                        card && styles[card.type],
                        selectedCardType && card.type !== selectedCardType.type && styles.dimmed,
                      )}
                    />
                  ),
                )}
              </Transition.Group>
            </div>
            <div className={styles.row}>
              <label><FormattedMessage id="billing.payment-method.cardholder-name" /></label>
              <Input
                size="large"
                className={getCardholderClass(cardholderName)}
                placeholder={formatMessage('billing.payment-method.cardholder-name')}
                name="cardholder-name"
                fluid
                autoFocus
                value={cardholderName}
                onChange={(_e, { value }) => setCardholderName(value)}
              />
            </div>
            <div className={styles.row}>
              <label><FormattedMessage id="billing.payment-method.card-number" /></label>
              <div className={styles.btField} id="card-number" />
            </div>
            <div className={mergeClasses(styles.dateCvv, styles.row)}>
              <div>
                <label><FormattedMessage id="billing.payment-method.expiration-date" /></label>
                <div className={styles.btField} id="expiration-date" />
              </div>
              <div>
                <label>{selectedCardType ? selectedCardType.code.name : 'CVV'}</label>
                <div className={styles.btField} id="cvv" />
              </div>
            </div>

            <Divider hidden section />

            <Modal
              open={!!showThreeDModal}
              onClose={handleCancelThreeDSecure}
              style={{ width: 'auto', height: 'auto' }}
            >
              <div ref={htmlModalRef} />
            </Modal>
          </div>
        </div>
      </div>
      <ConfirmPanel
        formType={formType}
        selectedProduct={selectedProduct}
        disabled={!isEverythingValid || loading || paymentLoading}
        loading={loading || paymentLoading}
        onClick={handleSubmit}
      />
    </div>
  )
}


function getCardholderClass(cardholder: string): string {
  const isCardholderValid = !isUndefinedOrNull(cardholder) && cardholder !== ''
  const isCardholderInvalid = cardholder === ''

  if (isCardholderValid) {
    return styles.cardholderValid
  }

  if (isCardholderInvalid) {
    return styles.cardholderInvalid
  }

  return undefined
}

export default withIntlConsumer(CardPayment)
