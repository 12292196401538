import { fetchWithRetry } from 'src/backend/rest/helpers'

export function fetchCompanies(query: string) {
  return fetchWithRetry(`https://autocomplete.clearbit.com/v1/companies/suggest?query=${query}`)
    .then(response => response.json())
    .then(json => {
      return json.map((company, index) => {
        return { key: index, title: company.name, image: company.logo, web: company.domain }
      })
    })
    .catch(() => [])
}
