import React from 'react'
import PictureIcon from 'src/frontend/components/PictureIcon/PictureIcon'
import { PictureIconNames } from 'src/frontend/components/PictureIcon/types'
import styles from 'src/frontend/scenes/settings/accounts/components/AccountFormSelectionIcon.module.less'
import { FormattedMessage } from 'src/frontend/modules/intl'
import { mergeClasses } from "src/common/utils"

interface Props {
  onClick?: (e: React.MouseEvent) => void
  iconName: PictureIconNames
  titleString: string
  description: string
  badge?: string | undefined
  disabled?: boolean
}

export default function AccountFormSelectionIcon({
  onClick = () => void 0,
  iconName,
  titleString,
  description,
  badge = undefined,
  disabled = false,
}: Props) {
  return (
    <div
      className={mergeClasses(styles.accountFormSelectionIcon, disabled && styles.disabled)}
      onClick={onClick}
    >
      {badge && (
        <p className={styles.badge}>
          <FormattedMessage id={badge} />
        </p>
      )}
      <PictureIcon
        name={iconName}
        size="5rem"
        compact
      />
      <h3>
        <FormattedMessage id={titleString} />
      </h3>
      <p>
        <FormattedMessage id={description} />
      </p>
    </div>
  )
}
