import moment from "moment"
import { convertLabelsView } from "src/backend/converters/helpers"
import { startOfDay, startOfWeek, startOfMonth } from "src/backend/time/time"
import * as envelopes from "src/backend/categories/envelopes"
import { IgnoredRecordStates, RecordState, RecordType, RefObjectType } from "src/backend/enums"
import { logMissingAttributes } from "src/common/logger"
import { isNull, isUndefinedOrNull } from "src/common/utils"
import _isEmpty from "lodash/isEmpty"
import { DefaultRecord } from "src/backend/records/Record"
import latinize from "latinize"
import { HashMap } from "src/types/common"
import { CategoryDocument } from "src/types/Category"
import { Label } from "src/types/Label"
import { DataDoc } from "src/types/CouchDb"
import { Record } from "src/types/Record"

// TODO This does too many things (has more responsibilities)
// It would be probably better to have 3 functions (but not sure)
// - set defaults
// - convert
// - validate
export function recordConverter(
  categories: HashMap<CategoryDocument>,
  labels: HashMap<Label> = {},
) {
  const requiredAttributes = ["accountId", "currencyId"]

  return (dataDoc: Record | DataDoc<Record>): Record => {
    const doc: Record = (dataDoc as DataDoc<Record>).doc || (dataDoc as Record)

    if (IgnoredRecordStates.includes(doc.recordState) || isUndefinedOrNull(doc.accountId)) {
      console.log(
        `Record with recordState equal to RecordState '${RecordState[doc.recordState]}' omitted!`,
        doc,
      )
      return null
    }

    logMissingAttributes(requiredAttributes, doc)

    let { amount, refAmount, place, categoryId } = doc
    if (!categoryId) {
      const recordCategory = Object.values(categories).find(
        (cat) => cat.envelopeId === envelopes.SYSTEM_CATEGORIES_UNKNOWN_ID,
      )

      categoryId = recordCategory ? recordCategory._id : ""
      console.warn(`Given Record document has no category ID! using categoryID: ${categoryId}`, doc)
    }

    if (isUndefinedOrNull(amount) && isUndefinedOrNull(refAmount)) {
      amount = 0
      refAmount = 0
    }

    if (isUndefinedOrNull(refAmount)) {
      refAmount = amount
    }

    if (isNull(place) || (place && !place.id)) {
      place = undefined
    }

    const recordDate = moment(doc.recordDate)
    let envelopeId = null
    let superEnvelopeId = null
    let standingOrderId
    const category = categories ? categories[categoryId] : null

    if (category) {
      const envelope = envelopes.getEnvelopeById(category.envelopeId)
      envelopeId = envelope ? envelope.id : null
      superEnvelopeId = envelope ? envelope.parentId : null
    }

    if (!_isEmpty(doc.refObjects)) {
      const standingOrder = doc.refObjects.find(
        (referenceObject) => referenceObject.type === RefObjectType.STANDING_ORDER,
      )
      standingOrderId = standingOrder && standingOrder.id
    }

    const photos =
      doc.photos && doc.photos.length > 0 ? filterPhotos(doc.photos) : DefaultRecord().photos

    const labelsView = convertLabelsView(doc.labels, labels)

    // create sample string for fulltext search in records
    const fulltextString =
      doc.payee || doc.note || (doc.place && (doc.place.address || doc.place.name))
        ? latinize(
            `${doc.payee || ""}${doc.note || ""}${(doc.place && doc.place.address) || ""}${
              (doc.place && doc.place.name) || ""
            }`,
          ).toLowerCase()
        : null

    return {
      ...doc,

      amount,
      refAmount,

      recordDate: recordDate.toDate(),
      recordDateMonth: startOfMonth(doc.recordDate),
      recordDateWeek: startOfWeek(doc.recordDate),
      recordDateDay: startOfDay(doc.recordDate),

      // TODO this only workaround because of some bug in current web application which is some cases saves Record
      // document without type attribute
      type:
        RecordType.INCOME === doc.type || RecordType.EXPENSE === doc.type
          ? doc.type
          : RecordType.EXPENSE,
      accuracy: doc.accuracy || 0,
      warrantyInMonth: doc.warrantyInMonth || 0,
      transfer: Boolean(doc.transferId) || Boolean(doc.transferAccountId),

      reservedUpdatedAt: moment(doc.reservedUpdatedAt).toDate(),

      categoryId,
      envelopeId,
      superEnvelopeId,
      standingOrderId,
      place,
      photos,

      labelsView,

      fulltextString,

      // @todo sets default currency ID to referential currency ID
    }
  }
}

function filterPhotos(photos) {
  return photos.filter(
    (photo) => !isUndefinedOrNull(photo) && photo.url && photo.url.startsWith("https://"),
  )
}
