import React from "react"
import ReactDOM from "react-dom"
import { AppContainer } from "react-hot-loader"
import * as logger from "src/common/logger"
import * as hotjar from "src/frontend/hotjar"
import "src/polyfills/map.prototype.getordefault"
import RootComponent from "./RootComponent"
import { getHotjarTrackingId, initEnvVariables } from "src/common/environment"
import { syncEventEmitter } from "src/frontend/scenes/app/actions"
import { isIE } from "src/common/runtime"
import InternetExplorer from "src/frontend/InternetExplorer"
import * as gtm from "src/common/gtm"

const rootDOMElement = document.getElementById("root")

if (!isIE) {
  const renderApp = async (App) => {
    // Intl polyfill
    if (!Intl) {
      await Promise.all([import("intl"), import("intl/locale-data/jsonp/en.js")])
    }

    if (!Intl.PluralRules) {
      await import("intl-pluralrules")
    }

    if (!Intl.RelativeTimeFormat) {
      await Promise.all([
        import("@formatjs/intl-relativetimeformat/polyfill"),
        import("@formatjs/intl-relativetimeformat/dist/locale-data/en"),
      ])
    }

    ReactDOM.render(
      <AppContainer>
        <App />
      </AppContainer>,
      rootDOMElement,
    )
  }

  initEnvVariables()
    .then(() => {
      logger.disableLoggingInProduction()
      logger.initSentry()
    })
    .then(() => gtm.initialize())
    .then(() => {
      if (getHotjarTrackingId()) {
        hotjar.initialize(getHotjarTrackingId())
      }
      renderApp(RootComponent)
    })
    .catch((error) => {
      console.error("Fatal error: Unable to start application!", error)
    })

  if (module.hot) {
    module.hot.accept("./RootComponent", () => {
      syncEventEmitter && syncEventEmitter.cancel()
      renderApp(RootComponent)
    })
  }
} else {
  ReactDOM.render(<InternetExplorer />, rootDOMElement)
}
