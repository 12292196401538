import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import * as actions from '../actions'
import {
  selectPaymentMethodSuccess,
} from 'src/frontend/scenes/billing/paymentMethod/selectors'
import { Billing } from 'src/types/Billing'
import BraintreeProduct = Billing.BraintreeProduct
import { selectSelectedProduct } from 'src/frontend/scenes/billing/product/selectors'
import { Redirect } from 'react-router'
import { getCurrentProductId, isPaidPremium, isPremium } from 'src/backend/user/service'
import { selectUser } from 'src/frontend/modules/user/selectors'
import { User } from 'src/types/User'
import PaymentMethod from 'src/frontend/scenes/billing/paymentMethod/components/PaymentMethod'
import { PaymentMethodFormType } from 'src/frontend/scenes/billing/paymentMethod/components/types'
import * as mixpanel from 'src/common/mixpanel'
import { BillingUIMethod } from 'src/frontend/scenes/billing/enums'


function mapStateToProps(state) {
  return {
    selectedProduct: selectSelectedProduct(state),
    success: selectPaymentMethodSuccess(state),
    user: selectUser(state),
  }
}

const mapDispatchToProps = {
  submitTransaction: actions.submitTransaction,
}

interface Props {
  selectedProduct: BraintreeProduct,
  success: boolean
  user: User

  submitTransaction: (nonce: string) => void
}

function SelectPaymentMethod({
  selectedProduct,
  success,
  user,
  submitTransaction,
}: Props) {
  useEffect(() => {
    mixpanel.trackPaymentMethodShow(BillingUIMethod.PAGE)
  }, [])

  if (success
    || (selectedProduct == null && isPaidPremium(user))
    || selectedProduct?.product?.productId === getCurrentProductId(user)) {
    return <Redirect to="/settings/billing" />
  }

  if (!isPaidPremium(user) && !selectedProduct) {
    return <Redirect to="/settings/billing/choose-plan" />
  }

  return (
    <PaymentMethod
      method={BillingUIMethod.PAGE}
      onSubmit={submitTransaction}
      selectedProduct={selectedProduct}
      formType={PaymentMethodFormType.ADD}
    />
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectPaymentMethod)
