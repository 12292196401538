import React from 'react'
import styles from 'src/frontend/scenes/contacts/contactList/components/ContactListItem.module.less'
import { Contact } from 'src/types/Contact'
import ContactView = Contact.ContactView
import { Link } from 'react-router-dom'
import { mergeClasses } from 'src/common/utils'
import panelStyles from 'src/frontend/components/ColumnLayout/PanelList.module.less'
import defaultProfileImage from 'src/frontend/assets/images/user_ic.svg'

interface Props {
  contact: ContactView
}

export default function ContactListItem({
  contact,
}: Props) {
  return (
    <Link
      key={contact._id}
      to={`/contacts/detail/${contact._id}`}
      className={mergeClasses(panelStyles.panel, styles.item)}
    >
      <div>
        <div
          className={styles.avatar}
          style={{ backgroundImage: `url("${contact.avatarUrl || defaultProfileImage}")` }}
        />
      </div>
      <div className={styles.name}>{contact.name}</div>
      <div className={styles.type}>
        <div className={styles.contactType} style={{ backgroundColor: contact.color }}>
          {contact.contactTypeName}
        </div>
      </div>
    </Link>
  )
}

