import { AccountsTotals, WidgetData } from 'src/frontend/scenes/dashboard/types'
import { Id } from 'src/types/CouchDb'
import _sumBy from 'lodash/sumBy'
import { isExpense, isIncome } from 'src/backend/records/helpers'
import { Currency } from 'src/types/Currency'
import { Account } from 'src/types/Account'
import { CashLiquidityData } from 'src/frontend/scenes/dashboard/widgets/CashRatioLiquidity/types'
import { HashMap } from 'src/types/common'

export function combineCashRatioLiquidity(
  accounts: HashMap<Account>,
  accountsTotals: HashMap<AccountsTotals>,
  currencies: HashMap<Currency>,
  referentialCurrency: Currency,
) {
  return (data: WidgetData): CashLiquidityData[] => {
    if (!data || !accountsTotals) {
      return null
    }

    return Object.keys(data).map((accountId: Id) => {

      const refExpenses: number = _sumBy(data[accountId].filter((plannedPayment) => {
        return isExpense(plannedPayment)
      }), 'refAmount')

      const refIncomes: number = _sumBy(data[accountId].filter((plannedPayment) => {
        return isIncome(plannedPayment)
      }), 'refAmount')

      const account: Account = accounts[accountId]

      if (account) {
        const accountTotals: AccountsTotals = accountsTotals[accountId]
        const currency: Currency = account.currencyId && account.currencyId in currencies
          ? currencies[account.currencyId]
          : referentialCurrency

        const expenses: number = refExpenses * currency.ratioToReferential
        const incomes: number = refIncomes * currency.ratioToReferential

        const color = getBarColor(accountTotals.availableBalance > 0 ? expenses / accountTotals.availableBalance : 1)

        return {
          accountId,
          color,
          accountName: account.name,
          accountType: account.accountType,
          expenses,
          incomes,
          refExpenses,
          refIncomes,
          currencyCode: currency.code,
          availableBalance: accountTotals.availableBalance,
        }
      }
    }).filter(Boolean).filter((cashLiquidityData: CashLiquidityData) => cashLiquidityData.refExpenses > 0)
  }
}

function getBarColor(value: number) {
  if (value > 0.9) {
    return '#e74c3c'
  }
  if (value > 0.75) {
    return '#ff9600'
  }
  return '#28cb7c'
}
