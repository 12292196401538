import * as React from 'react'
import { connect } from 'react-redux'
import { isUndefined } from 'src/common/utils'
import styles from 'src/frontend/components/Filter/Filter.module.less'
import { Option } from 'src/frontend/components/Filter/types'
import { RootState } from 'src/types/State'
import {
  selectContactFilter,
  selectContactFilterDisplayOption,
  selectContactTypeOptions,
} from 'src/frontend/scenes/contacts/filter/selectors'
import MultiSelectFilter from 'src/frontend/components/Filter/MultiSelectFilter'
import { ContactsFilterDisplayOptions, ContactsFilterType } from 'src/frontend/scenes/contacts/filter/types'
import { setFilterValues, toggleDisplayFilter } from 'src/frontend/scenes/contacts/filter/actions'
import SearchFilter from 'src/frontend/components/Filter/SearchFilter'

const mapStateToProps = (state: RootState) => {
  return {
    filter: selectContactFilter(state),
    display: selectContactFilterDisplayOption(state),
    options: {
      contactTypes: selectContactTypeOptions(state),
    },
  }
}

const mapDispatchToProps = (dispatch: Function) => {
  return {
    handleChangeFilter: (name, value) => dispatch(setFilterValues({ [name]: value })),
    handleChangeMultipleFilter: (values) => dispatch(setFilterValues(values)),
    handleToggleDisplayFilter: (name) => dispatch(toggleDisplayFilter(name)),
  }
}

export type Props = {
  filter: ContactsFilterType,
  display: ContactsFilterDisplayOptions,
  options: {
    contactTypes: Option[],
  },
  handleChangeFilter: Function,
  handleChangeMultipleFilter: Function,
  handleToggleDisplayFilter: Function,
}

function ContactFilter(props: Props) {
  const {
    filter,
    display,
    options,
    handleChangeMultipleFilter,
    handleChangeFilter,
    handleToggleDisplayFilter,
  }: Props = props

  return filter && (
    <div className={styles.filters}>
      <SearchFilter
        defaultValue={filter.fulltext}
        onChange={(_e, { value }) => handleChangeFilter('fulltext', value)}
      />
      <MultiSelectFilter
        key="contactTypeIds"
        label="records.filter.contactTypes"
        labelAll="records.filter.contactTypes.all"
        name="contactTypeIds"
        active={display.contactTypeIds}
        options={options.contactTypes}
        displayAll={isUndefined(filter.contactTypeIds)}
        onFilterChange={handleChangeMultipleFilter}
        onDisplayChange={handleToggleDisplayFilter}
      />
      <div className={styles.clearbitLogo}>
        <a href="https://clearbit.com" target="_blank"><span style={{fontSize: '12pt'}}>Logos provided by Clearbit</span></a>
      </div>
    </div>
  )
}


export default connect(mapStateToProps, mapDispatchToProps)(ContactFilter)
