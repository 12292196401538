import { ImportMappingAttributeType } from 'src/backend/enums'
import {
  getInitialMapping as getInitialMappingRequest, getUserImports as getUserImportsRequest,
  saveCustomMapping as saveCustomMappingRequest, uploadImportFile,
  validateCustomMapping as validateCustomMappingRequest,
} from 'src/backend/rest/backend'
import { hasExpense, hasFee } from 'src/backend/imports/helpers'

export {
  deleteImportItem,
  deleteImportSettings,
  activateImportAccount,
  activateAutomaticImport,
  deactivateAutomaticImport,
} from 'src/backend/rest/backend'

export const getInitialImportLayout = async () => {

  const itemList = await getUserImportsRequest()

  const toUploadItems = groupItemsToImportByAccount(itemList)

  const groupedItemList = groupLatestItemsByAccount(itemList)

  return {
    toUploadItems,
    groupedItemList,
    itemList,
  }
}

export async function validateCustomMapping(itemId, settings, mappingAttributes) {
  console.log('validateCustomMapping itemId=', itemId, 'settings=', settings, 'mappingAttributes=', mappingAttributes)
  const response = await validateCustomMappingRequest(itemId, settings, mappingAttributes)
  console.log('validateCustomMappingRequest response object:', response)
  return buildColumnsFromInitialMapping(response)
}

export async function saveCustomMapping(itemId, settings, mappingAttributes) {
  console.log('saveCustomMapping itemId=', itemId, 'settings=', settings, 'mappingAttributes=', mappingAttributes)
  const response = await saveCustomMappingRequest(itemId, settings, mappingAttributes)
  console.log('saveCustomMapping', response)
  return response
}

export const assignColumnToAttribute = ({ columns, columnIndex, attributeType }) => {
  return columns
    // release occupied attribute type
    .map(column => (
      column.type === attributeType
        ? { ...column, type: ImportMappingAttributeType.UNKNOWN }
        : column
    ))
    // set the new attribute type
    .map(column => (
      column.colIndex === columnIndex
        ? { ...column, type: attributeType }
        : column
    ))
}
export const getMaxRowValuesColumnsCount = (rowValues = []) => (
  rowValues.reduce((acc, row) => Math.max(acc, row.value.length), 0)
)

export const fillParsedExample = (parsedExample, maxRowValuesColumnsCount) => (
  [
    ...parsedExample,
    ...Array.from(Array(maxRowValuesColumnsCount - parsedExample.length))
      .map((value, index) => ({
        type: ImportMappingAttributeType.UNKNOWN,
        colName: '',
        colIndex: parsedExample.length + index,
      })),
  ])

export const buildColumnsFromInitialMapping = initialMapping => {
  const { parsedExample, allowedAttributes, settings, rowValues, initResult } = initialMapping

  // quick fix when uploaded data have different row columns count, so fill missing columns
  const maxRowValuesColumnsCount = getMaxRowValuesColumnsCount(rowValues)
  const filledRowValues = (rowValues || []).map((row) => {
    const diffToMax = maxRowValuesColumnsCount - row.value.length
    if (diffToMax === 0) { return row }
    return { value: [...row.value, ...Array(diffToMax).map(() => '')] }
  })
  const filledParsedExample = parsedExample.length >= maxRowValuesColumnsCount
    ? parsedExample
    : fillParsedExample(parsedExample, maxRowValuesColumnsCount)

  const requiredAttributeTypes = allowedAttributes
    .filter(attribute => attribute.isRequired)
    .map(attribute => attribute.type)

  return {
    requiredAttributeTypes,
    columns: filledParsedExample,
    parsedExample: filledParsedExample,
    rowValues: filledRowValues,
    settings,
    initResult,
  }
}

export const getInitialMapping = async itemId => {
  const initialMapping = await getInitialMappingRequest(itemId)
  // TODO refactor state properties to match backend properties (client properties were first)
  const { hasExpenseColumn, hasFeeColumn, ...fixedSettings } = initialMapping.settings
  // hasExpense, hasFeeColumn is workaround for BE returning attributes from DB not from actual status
  fixedSettings.hasSeparateIncomeExpense = hasExpenseColumn || hasExpense(initialMapping.parsedExample)
  fixedSettings.hasSeparateFees = hasFeeColumn || hasFee(initialMapping.parsedExample)
  // settings in initialMapping does not contain dateFormatterPattern, therefore the workaround in
  // actions.validateMapping that copies dateFormatterPattern to state in order to send it back to the backend.
  return buildColumnsFromInitialMapping({ ...initialMapping, settings: fixedSettings })
}

export const groupLatestItemsByAccount = itemsList => (
  itemsList.reduce((latestItems, item) => {
    if (!isItemImported(item)) {
      return latestItems
    }

    if (!latestItems.has(item.accountId) || item.itemCreatedAt > latestItems.get(item.accountId).itemCreatedAt) {
      latestItems.set(item.accountId, { itemCreatedAt: item.itemCreatedAt })
    }

    return latestItems
  }, new Map([]))
)

/**
 * Groups uploaded items that are not imported yet into Map by account
 * Fills the latest upload date
 * Counts and fills number of items to import (waiting imports)
 * @param itemsList
 * @returns {Map.<string, Object>}
 */
export const groupItemsToImportByAccount = itemsList => {

  // Remove already imported items
  const itemsToUpload = itemsList.filter(item => !isItemImported(item))

  // Prepare initial data: Map with empty objects
  const initialData = itemsToUpload.reduce((itemGroup, item) => {
    itemGroup.set(item.accountId, { latestUpload: null, count: 0 })
    return itemGroup
  }, new Map())

  return itemsToUpload.reduce((acc, item) => {
    const itemGroup = acc.get(item.accountId)

    // Update the last upload date, set the first item upload date if the date is not set yet
    if (itemGroup.latestUpload === null || item.itemCreatedAt > itemGroup.latestUpload) {
      itemGroup.latestUpload = item.itemCreatedAt
    }

    // Count items contained in the group
    itemGroup.count++

    return acc
  }, initialData)
}

/**
 * Filters real attribute types (exclude unknown), returns sorted unique attribute type list.
 * @param attributeTypes all assigned attribute types
 * @returns {Array.<number>} attribute types
 */
export const getAssignedAttributes = attributeTypes => {
  const assignedAttributes = attributeTypes
    .filter(type => type !== ImportMappingAttributeType.UNKNOWN)
  return [...new Set(assignedAttributes)].sort()
}

export const groupRecordsByTransactionId = (records, transactionIds) => {

  const recordsGrouped = new Map()
  const recordTransactionIds = new Set()

  records.forEach(record => {
    if (!recordsGrouped.has(record.transactionId)) {
      recordsGrouped.set(record.transactionId, [])
    }
    recordsGrouped.get(record.transactionId).push(record)
    recordTransactionIds.add(record.transactionId)
  })

  return transactionIds
    .filter(id => recordTransactionIds.has(id))
    .map(id => ({ transactionId: id, records: recordsGrouped.get(id) }))
}

export const uploadCsvFiles = (file, userId, email) => {
  return uploadImportFile(file.name, file, userId, email)
}

export const isItemImported = item => !!item.transactionId
