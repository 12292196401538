import { IntegrationProviders as IP } from "src/frontend/scenes/integrations/providersList/types"
import { getBackendUrl } from "src/common/environment"
import {
  buildFetchOptions,
  fetchWithRetry,
  validateIntegrationsSuccessResponse,
  withXLocaleHeader,
} from "src/backend/rest/helpers"
import {
  IntegrationAccounts,
  IntegrationConnectedProviders,
  IntegrationLoginResponse,
  IntegrationProviderCountries,
  IntegrationProviderDetail,
  IntegrationProviders,
  UsersProviderRestrictions,
} from "static/proto/ribeez_pb"
import {
  buildIntegrationAccountConnectionRequest,
  buildIntegrationAccountCreationRequest,
  buildIntegrationFinishOauthRequest,
  buildIntegrationLoginRequest,
  buildIntegrationProvidersByCountryRequest,
  deserializeProtobuffResponse,
  handleCaughtError,
} from "src/backend/rest/ribeezHelpers"
import { Id } from "src/types/CouchDb"
import { FormAttributeField } from "src/frontend/scenes/integrations/types"
import { IntegrationConnection } from "src/frontend/scenes/integrations/newConnection/types"
import IntegrationAccountCreationBlueprint = IntegrationConnection.IntegrationAccountCreationBlueprint
import IntegrationAccountConnectionPair = IntegrationConnection.IntegrationAccountConnectionPair

const defaultPostOptions = buildFetchOptions("POST")
const defaultGetOptions = buildFetchOptions("GET")

function getIntegrationsEndpoint(): string {
  return `${getBackendUrl()}/ribeez/integration/v1`
}

export function fetchProviderRestrictions() {
  const url = `${getIntegrationsEndpoint()}/providerRestrictions`
  return fetchWithRetry(url, defaultGetOptions)
    .then(validateIntegrationsSuccessResponse)
    .then(deserializeProtobuffResponse(UsersProviderRestrictions))
    .catch(handleCaughtError)
}

export function fetchIntegrationsCountries() {
  const url = `${getIntegrationsEndpoint()}/countries`
  return fetchWithRetry(url, defaultPostOptions)
    .then(validateIntegrationsSuccessResponse)
    .then(deserializeProtobuffResponse(IntegrationProviderCountries))
    .catch(handleCaughtError)
}

export function fetchIntegrationProvidersByCountry(countryCode: string) {
  const url = `${getIntegrationsEndpoint()}/providerByCountry`
  const body = buildIntegrationProvidersByCountryRequest(countryCode)
  return fetchWithRetry(url, { ...defaultPostOptions, body })
    .then(validateIntegrationsSuccessResponse)
    .then(deserializeProtobuffResponse(IntegrationProviders))
    .catch(handleCaughtError)
}

// NEW CONNECTION
export function fetchIntegrationProvidersDetailByProviderCode(
  ownerId: Id,
  sourceName: string,
  providerCode: string,
) {
  const url = `${getIntegrationsEndpoint()}/owner/${ownerId}/source/${sourceName}/provider/code/${providerCode}`
  return fetchWithRetry(url, withXLocaleHeader(defaultGetOptions))
    .then(validateIntegrationsSuccessResponse)
    .then(deserializeProtobuffResponse(IntegrationProviderDetail))
    .catch(handleCaughtError)
}

export function finishOauth(ownerId: Id, sourceName: string, loginId: Id, queryParams: string) {
  const url = `${getIntegrationsEndpoint()}/owner/${ownerId}/source/${sourceName}/login/${loginId}/finishOauth`
  const body = buildIntegrationFinishOauthRequest(queryParams)
  return fetchWithRetry(url, { ...defaultPostOptions, body })
    .then(validateIntegrationsSuccessResponse)
    .then(deserializeProtobuffResponse(IntegrationLoginResponse))
    .catch(handleCaughtError)
}

export function fetchIntegrationProvidersDetailByLoginId(
  ownerId: Id,
  sourceName: string,
  loginId: Id,
) {
  const url = `${getIntegrationsEndpoint()}/owner/${ownerId}/source/${sourceName}/login/${loginId}/provider`
  return fetchWithRetry(url, withXLocaleHeader(defaultGetOptions))
    .then(validateIntegrationsSuccessResponse)
    .then(deserializeProtobuffResponse(IntegrationProviderDetail))
    .catch(handleCaughtError)
}

// DATA FOR SCRAPING
export function loginToProvider(
  ownerId: Id,
  sourceName: string,
  providerCode: string,
  formValues: FormAttributeField[],
) {
  const url = `${getIntegrationsEndpoint()}/owner/${ownerId}/source/${sourceName}/provider/code/${providerCode}/login`
  const body = buildIntegrationLoginRequest(formValues)
  return fetchWithRetry(url, { ...defaultPostOptions, body })
    .then(validateIntegrationsSuccessResponse)
    .then(deserializeProtobuffResponse(IntegrationLoginResponse))
    .catch(handleCaughtError)
}

export function reconnectProvider(
  ownerId: Id,
  sourceName: string,
  loginId: Id,
  formValues: FormAttributeField[],
) {
  console.warn("IntegrationLoginStatus.LOGIN_DUPLICATED")
  const url = `${getIntegrationsEndpoint()}/owner/${ownerId}/source/${sourceName}/login/${loginId}/reconnect`
  const body = buildIntegrationLoginRequest(formValues)
  return fetchWithRetry(url, { ...defaultPostOptions, body })
    .then(validateIntegrationsSuccessResponse)
    .then(deserializeProtobuffResponse(IntegrationLoginResponse))
    .catch(handleCaughtError)
}

export function fetchExistingIntegrations(ownerId: Id) {
  const url = `${getIntegrationsEndpoint()}/owner/${ownerId}/providers/connected`
  return fetchWithRetry(url, defaultGetOptions)
    .then(validateIntegrationsSuccessResponse)
    .then(deserializeProtobuffResponse(IntegrationConnectedProviders))
    .catch(handleCaughtError)
}

export function sendMFA(
  ownerId: Id,
  sourceName: string,
  loginId: Id,
  formValues: FormAttributeField[],
  inputType?: IP.IntegrationRecipe.InputType,
  id?: string,
): Promise<IntegrationLoginResponse> {
  const url = `${getIntegrationsEndpoint()}/owner/${ownerId}/source/${sourceName}/login/${loginId}/mfa`
  const body = buildIntegrationLoginRequest(formValues, id, inputType)
  console.log("sendMFA - body: ", body)
  return fetchWithRetry(url, { ...defaultPostOptions, body })
    .then(validateIntegrationsSuccessResponse)
    .then(() => {
      const result = deserializeProtobuffResponse(IntegrationLoginResponse)
      return result
    })
    .catch(handleCaughtError)
}

// REFRESH
export function refreshIntegrationTransactions(ownerId: Id, sourceName: string, loginId: Id) {
  const url = `${getIntegrationsEndpoint()}/owner/${ownerId}/source/${sourceName}/login/${loginId}/transactions/refresh`
  return fetchWithRetry(url, withXLocaleHeader(defaultPostOptions))
    .then(validateIntegrationsSuccessResponse)
    .then(deserializeProtobuffResponse(IntegrationLoginResponse))
    .catch(handleCaughtError)
}

export function checkProviderStatus(ownerId: Id, sourceName: string, loginId: Id) {
  if (loginId === "LOGIN_FAILURE") throw "IntegrationLoginStatus return error"
  const url = `${getIntegrationsEndpoint()}/owner/${ownerId}/source/${sourceName}/login/${loginId}/check`
  return fetchWithRetry(url, defaultGetOptions)
    .then(validateIntegrationsSuccessResponse)
    .then(deserializeProtobuffResponse(IntegrationLoginResponse))
    .catch(handleCaughtError)
}

export function deactivateIntegrationConnection(ownerId: Id, sourceName: string, loginId: Id) {
  const url = `${getIntegrationsEndpoint()}/owner/${ownerId}/source/${sourceName}/login/${loginId}/deactivate`
  return fetchWithRetry(url, defaultPostOptions)
    .then(validateIntegrationsSuccessResponse)
    .catch(handleCaughtError)
}

// TO GET AVAILABLE ACCOUNT TO CONNECT
export function fetchIntegrationAccounts(ownerId: Id, sourceName: string, loginId: Id) {
  const url = `${getIntegrationsEndpoint()}/owner/${ownerId}/source/${sourceName}/login/${loginId}/accounts`
  return fetchWithRetry(url, defaultGetOptions)
    .then(validateIntegrationsSuccessResponse)
    .then(deserializeProtobuffResponse(IntegrationAccounts))
    .catch(handleCaughtError)
}

// CALL TO CREATE NEW ACC AFTER ACCOUNTS SELECTION
export function createIntegrationAccounts(
  ownerId: Id,
  sourceName: string,
  loginId: Id,
  accounts: IntegrationAccountCreationBlueprint[],
) {
  const url = `${getIntegrationsEndpoint()}/owner/${ownerId}/source/${sourceName}/login/${loginId}/accounts/create`
  const body = buildIntegrationAccountCreationRequest(accounts)

  return fetchWithRetry(url, { ...defaultPostOptions, body })
    .then(validateIntegrationsSuccessResponse)
    .catch(handleCaughtError)
}

// WHEN CONNECTING EXISTING WALLET ACC TO A BANK
export function connectIntegrationAccount(
  ownerId: Id,
  sourceName: string,
  loginId: Id,
  accounts: IntegrationAccountConnectionPair[],
) {
  const url = `${getIntegrationsEndpoint()}/owner/${ownerId}/source/${sourceName}/login/${loginId}/accounts/connect`
  const body = buildIntegrationAccountConnectionRequest(accounts)

  return fetchWithRetry(url, { ...defaultPostOptions, body })
    .then(validateIntegrationsSuccessResponse)
    .catch(handleCaughtError)
}

export function disconnectIntegrationAccount(
  ownerId: Id,
  sourceName: string,
  loginId: Id,
  accounts: IntegrationAccountConnectionPair[],
) {
  const url = `${getIntegrationsEndpoint()}/owner/${ownerId}/source/${sourceName}/login/${loginId}/accounts/disconnect`
  const body = buildIntegrationAccountConnectionRequest(accounts)

  return fetchWithRetry(url, { ...defaultPostOptions, body })
    .then(validateIntegrationsSuccessResponse)
    .catch(handleCaughtError)
}
