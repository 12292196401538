import React from 'react'
import { SortableContainer } from 'react-sortable-hoc'
import _omit from 'lodash/omit'
import _isEqual from 'lodash/isEqual'
import { arrayMove } from 'src/common/utils'

interface Props {
  items: any[],
  handleReorder: Function,
  [key: string]: any
}

interface State {
  items: any[]
}

export default function SortableList(WrappedComponent) {

  const List = SortableContainer(WrappedComponent)

  return class extends React.Component<Props, State> {

    state = { items: this.props.items }

    componentDidUpdate(_prevProps: Readonly<Props>, prevState: Readonly<State>): void {
      if (!_isEqual(prevState.items, this.props.items)) {
        const { items } = this.props
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState(() => ({ items }))
      }
    }

    onSortEnd = ({ oldIndex, newIndex }) => {
      if (oldIndex !== newIndex) {
        this.setState(prevState => ({
          items: arrayMove(prevState.items, oldIndex, newIndex),
        }))

        this.props.handleReorder({ oldIndex, newIndex })
      }
    }

    render() {
      const { items } = this.state

      return (
        <List
          useDragHandle
          lockAxis="y"
          helperClass="sort-drag"
          lockToContainerEdges
          items={items}
          onSortEnd={this.onSortEnd}
          {..._omit(this.props, 'handleReorder', 'items')}
        />
      )
    }
  }
}
