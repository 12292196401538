import { isUndefinedOrNull } from 'src/common/utils'
import { DataDoc } from 'src/types/CouchDb'
import { AndroidFilter } from 'src/types/Filter'

export function filterConverter() {
  return (dataDoc: AndroidFilter | DataDoc<AndroidFilter>): AndroidFilter => {
    const doc: AndroidFilter = (dataDoc as DataDoc<AndroidFilter>).doc || dataDoc as AndroidFilter

    let { name } = doc

    if (isUndefinedOrNull(name)) {
      name = ''
    }

    return {
      ...doc,
      name,
    }
  }
}
