import React from 'react'
import { connect } from 'react-redux'
import { RootState } from 'src/types/State'
import DetailLayout from 'src/frontend/components/ColumnLayout/DetailLayout'
import { Redirect, Route, Switch } from 'react-router'
import { selectUser } from 'src/frontend/modules/user/selectors'
import { User } from 'src/types/User'
import { isBraintreeSubscriptionPremium, isLifetime, isPaidPremium, isPremium } from 'src/backend/user/service'
import SelectPaymentMethod from 'src/frontend/scenes/billing/paymentMethod/components/SelectPaymentMethod'
import UpdatePaymentMethod from 'src/frontend/scenes/billing/paymentMethod/components/UpdatePaymentMethod'
import PlanSelectPage from 'src/frontend/scenes/billing/product/components/PlanSelectPage'
import { isAppBoard } from 'src/common/environment'
import BillingInformation from 'src/frontend/scenes/billing/billingInformation/components/BillingInformation'


function mapStateToProps(state: RootState) {
  return {
    user: selectUser(state),
  }
}

interface Props {
  user: User

}

function BillingRoot({ user }: Props) {

  return (
    <Switch>
      <Route
        path="/settings/billing/update-payment-method"
        component={({ history }) => {
          return <DetailLayout transparent history={history} content={<UpdatePaymentMethod />} />
        }}
      />

      {isAppBoard() && (!isPremium(user) || isBraintreeSubscriptionPremium(user)) && (
        <Route
          path="/settings/billing/billing-information"
          component={({ history }) => {
            return <DetailLayout transparent history={history} content={<BillingInformation />} />
          }}
        />
      )}

      {/* Enable Android, iOS to upgrade to lifetime */}
      {!isLifetime(user) && (
        <Route
          path="/settings/billing/payment-method"
          component={({ history }) => {
            return <DetailLayout transparent history={history} content={<SelectPaymentMethod />} />
          }}
        />
      )}

      {!(isPaidPremium(user) || isLifetime(user)) && !isBraintreeSubscriptionPremium(user) && (
        <Route
          path="/settings/billing/choose-plan"
          component={({ history }) => {
            return <DetailLayout transparent history={history} content={<PlanSelectPage />} />
          }}
        />
      )}

      <Route component={() => <Redirect to="/settings/billing" />} />
    </Switch>
  )
}

export default connect(mapStateToProps)(BillingRoot)
