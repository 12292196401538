import _isEmpty from 'lodash/isEmpty'
import spected from 'spected'
import { isCreditCard, isOverdraft } from 'src/backend/accounts/helpers'
import * as commonValidator from 'src/backend/common/validator'
import omit from 'lodash/omit'
import { WalletFormValidationError } from 'src/backend/errors'
import { AccountFormValues } from 'src/frontend/scenes/settings/accounts/types'

export function validateAccount(account: AccountFormValues & { _id }, validationDependencies) {
  const {
    accounts,
    // colors,
  } = validationDependencies

  const requiredFields = [
    'name',
    'accountType',
    'initAmount',
    'color',
  ]

  const accountWithRequiredFields = {
    ...commonValidator.addRequiredFieldsAsUndefined(account, requiredFields),
  }

  const accountSpec: any = {
    name: [
      [commonValidator.isNotEmpty, 'form.error.enter_name'],
      [commonValidator.hasUniqueName(omit(accounts, account._id)), 'form.error.name_exists'],
      [commonValidator.isNotLongerThan(commonValidator.NAME_MAX_LENGTH), 'form.error.name_too_long'],
    ],
    initAmount: [
      [commonValidator.isNumber, 'settings.form.not_a_number'],
    ],
    // color: [
    //   [commonValidator.valueExists(colors), 'settings.labels.form.invalid_color_selected'],
    // ],
  }

  if (isCreditCard(account) || isOverdraft(account)) {
    accountSpec.creditCard = {
      limit: [
        [commonValidator.isNumber, 'settings.form.not_a_number'],
      ],
    }
  }

  const validationResult = spected(accountSpec, accountWithRequiredFields)
  const errors = commonValidator.convertValidationResultToErrors(validationResult)
  if (!_isEmpty(errors)) {
    throw new WalletFormValidationError(errors)
  }
}
