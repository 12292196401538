import React from 'react'
import {connect} from 'react-redux'
import GenericConfirmationModal from 'src/frontend/scenes/settings/privacy/components/GenericConfirmationModal'
import {
    closeConsentConfirmModal,
    removeEmailingConsent,
} from 'src/frontend/scenes/settings/privacy/actions'
import {
    selectEmailingConsentModalOpen,
} from 'src/frontend/scenes/settings/privacy/selectors'
import {BulkRate} from 'static/proto/forex_pb'

function mapStateToProps(modalOpenSelector) {
    return (state) => ({
        open: modalOpenSelector(state),
    })
}

function dispatchToProps(confirm) {
    return {
        close: closeConsentConfirmModal,
        confirm,
    }
}

interface Props {
    open: boolean
    contentMessageId: string
    confirm: Function
    close: () => void
}

function ConsentChangeRequestModal({open, contentMessageId, confirm, close}: Props) {
    return (
        <GenericConfirmationModal
            open={open}
            headerMessageId='settings.privacy.remove_emailing_consent_question.title'
            contentMessageId={contentMessageId}
            actions={[
                {
                    messageId: 'settings.privacy.remove_consent_cancel_button',
                    callback: close,
                    primary: false,
                    color: 'blue',
                    inverted: true,
                },
                {
                    primary: true,
                    inverted: false,
                    messageId: 'settings.privacy.remove_consent_confirm_button',
                    callback: confirm,
                },
            ]}
            close={close}
        />
    )
}

function buildModal(contentMessageId) {
    return (props) => <ConsentChangeRequestModal {...props} contentMessageId={contentMessageId}/>
}

export const EmailingConsentModal = connect(
    mapStateToProps(selectEmailingConsentModalOpen),
    dispatchToProps(removeEmailingConsent)
)(buildModal('settings.privacy.remove_emailing_consent_question.description'))
