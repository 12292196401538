import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { FormattedMessage } from 'src/frontend/modules/intl'
import { RootState } from 'src/types/State'
import { Loader, Modal, Transition } from 'semantic-ui-react'
import BasicRecordList from 'src/frontend/components/RecordList/BasicRecordList'
import { RecordListItemRecord } from 'src/frontend/components/RecordList/types'
import {
  selectAggregatedRecordsPreview, selectIsPreviewFilter, selectIsPreviewOpen,
  selectIsRecordListFetching,
} from 'src/frontend/scenes/records/recordList/selectors'
import { closeRecordsPreview, fetchRecordsPreview } from 'src/frontend/scenes/records/recordList/actions'
import { openEditRecordForm } from 'src/frontend/scenes/records/recordForm/actions'
import { Id } from 'src/types/CouchDb'
import { selectShouldRefreshAnalytics } from 'src/frontend/modules/moduleStatus/selectors'
import { FilterType } from 'src/types/Filter'
import './ReportsRecordListModal.less'

function mapStateToProps(state: RootState) {
  return {
    records: selectAggregatedRecordsPreview(state),
    filter: selectIsPreviewFilter(state),
    loading: selectIsRecordListFetching(state),
    shouldRefreshRecordList: selectShouldRefreshAnalytics(state),
    open: selectIsPreviewOpen(state),
  }
}

const mapDispatchToProps = {
  fetchData: fetchRecordsPreview,
  handleEditRecord: openEditRecordForm,
  closeModal: closeRecordsPreview,
}

interface Props {
  records: RecordListItemRecord[],
  loading: boolean,
  filter: FilterType,
  fetchData: Function,
  handleEditRecord: (recordId: Id) => void
  shouldRefreshRecordList: boolean
  open: boolean
  closeModal: Function
}


function ReportsRecordListModal({
  loading,
  records,
  shouldRefreshRecordList,
  handleEditRecord,
  open,
  fetchData,
  closeModal,
}: Props) {

  useEffect(() => {
    if (shouldRefreshRecordList || open) {
      setTimeout(() => fetchData(), 250)
    }
  }, [shouldRefreshRecordList, open])

  const handleCloseModal = (e) => {
    e && e.stopPropagation()
    closeModal()
  }

  return (
    <Transition
      directional
      visible={open}
      unmountOnHide
      onHide={handleCloseModal}
      duration={{ show: 250, hide: 150 }}
      animation="modal-animation"
    >
      <Modal
        size="small"
        onClose={handleCloseModal}
        open
        className="records-preview"
      >
        <Modal.Header>
          <FormattedMessage id={{ W: 'menu.records', B: 'transactions' }} />
          <span className="modal-close" onClick={handleCloseModal} />
        </Modal.Header>
        <Modal.Content>
          {!loading ? (
              <BasicRecordList records={records} editable scrollable onEdit={handleEditRecord} />
            )
            : (
              <div className="loading-container">
                <Loader active inline="centered" />
              </div>
            )}
        </Modal.Content>
      </Modal>
    </Transition>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportsRecordListModal)
