import OnBoardingLayout from 'src/frontend/scenes/onBoarding/firstRun/components/OnBoardingLayout'
import { OnBoardingStage } from 'src/frontend/scenes/onBoarding/firstRun/enums'
import SignupFormContainer from 'src/frontend/scenes/auth/signup/SignupFormContainer'
import React, { useEffect } from 'react'
import styles from 'src/frontend/scenes/auth/components/Auth.module.less'
import { FormattedMessage } from 'src/frontend/modules/intl'
import { Link } from 'react-router-dom'
import * as gtm from 'src/common/gtm'

const stages = [
  OnBoardingStage.REGISTRATION,
  OnBoardingStage.BASE_CURRENCY,
  OnBoardingStage.KB_BANK_INTEGRATION,
]

const status = {
  [OnBoardingStage.BASE_CURRENCY]: false,
  [OnBoardingStage.KB_BANK_INTEGRATION]: false,
  [OnBoardingStage.ACCOUNT_NAME]: false,
  [OnBoardingStage.CASH_BALANCE]: false,
  [OnBoardingStage.REGISTRATION]: false,
}

export default function SignUpKB() {

  useEffect(() => gtm.signUpStart('Sign Up KB'), [])

  return (
    <OnBoardingLayout stages={stages} stage={OnBoardingStage.REGISTRATION} status={status}>
      <SignupFormContainer />
      <div className={styles.link}>
        <FormattedMessage id="auth.already_have_account" />&nbsp;
        <Link to="/login">
          <FormattedMessage id="auth.login_button" />
        </Link>
      </div>
    </OnBoardingLayout>
  )
}
