import React, { FC, useEffect, useState } from "react"
import { FormattedMoney, formatMessage } from "src/frontend/modules/intl"
import styles from "./AvailableCashTab.module.less"
import ExtendedRecordListItem from "src/frontend/components/RecordList/ExtendedRecordListItem"
import { useDispatch, useSelector } from "react-redux"
import * as recordsService from "src/backend/records/service"
import { RootState } from "src/types/State"
import { selectRecordsWithDetails } from "src/frontend/scenes/records/recordsDetail/selectors"
import { Record } from "src/types/Record"
import { openEditRecordForm } from "src/frontend/scenes/records/recordForm/actions"
import * as removeFormActions from "src/frontend/scenes/records/removeForm/actions"
import recordListStyles from "src/frontend/components/RecordList/ExtendedRecordList.module.less"

import {
  RECORDS_MODULE_STATUS_EXPIRED,
  RECORDS_MODULE_STATUS_LOADING,
  RECORDS_MODULE_STATUS_UP_TO_DATE,
} from "src/frontend/modules/moduleStatus/actions"
import { ModuleStatus } from "src/frontend/modules/moduleStatus/types"

import AssetTransactionListItem from "src/frontend/components/Investments/AssetTransactionListItem"
import { RecordListItemRecord } from "src/frontend/components/RecordList/types"
import { convertDateToLabel } from "src/frontend/scenes/records/recordList/helpers"
import moment from "moment"
import { AssetTransactionListItemType, AssetTransaction } from "src/types/investments.types"
import { getAssetTransactionListItems } from "src/backend/investments/service"

interface Props {
  accountId: string
  availableCash: number
  accountCurrencyCode: string
}

const AvailableCashTab: FC<Props> = ({ accountId, accountCurrencyCode, availableCash }) => {
  const [records, setRecords] = useState<Record[]>([])
  const [assetTransactions, setAssetTransactions] = useState<AssetTransactionListItemType[]>([])
  const dispatch = useDispatch()

  const extendedRecords = useSelector(selectRecordsWithDetails(records))
  const recordsStatus = useSelector((state: RootState) => state.modules.moduleStatus.records)

  const fetchandSetAssetTransactions = async () => {
    const assetTransactionListItems = await getAssetTransactionListItems(accountId)

    setAssetTransactions(assetTransactionListItems)
  }

  const fetchAndSetRecords = async () => {
    recordsService.fetchRecordsByFilter({ accountIds: [accountId] }).then((records) => {
      setRecords(records)
    })
  }

  useEffect(() => {
    if (recordsStatus !== ModuleStatus.EXPIRED) return
    dispatch({ type: RECORDS_MODULE_STATUS_LOADING })
    fetchAndSetRecords().finally(() => {
      dispatch({ type: RECORDS_MODULE_STATUS_UP_TO_DATE })
    })
  }, [recordsStatus])

  useEffect(() => {
    dispatch({ type: RECORDS_MODULE_STATUS_EXPIRED })

    fetchandSetAssetTransactions()
  }, [])

  const recordsWithAssetTransactionsGroupedByDay = groupItemsByDay([
    ...extendedRecords,
    ...assetTransactions.filter(
      (assetTransaction) => assetTransaction.cashAmount || assetTransaction.cashAmountCurrencyCode,
    ),
  ])

  return (
    <div style={{ paddingBlock: "20px" }}>
      <div
        className={styles.overview}
        style={{ marginBottom: "20px" }}
      >
        <h2 className={styles.heading}>{formatMessage("availableCash")}</h2>
        <span className={styles.value}>
          <FormattedMoney
            currency={accountCurrencyCode}
            value={availableCash}
          />
        </span>
      </div>
      <div>
        {recordsWithAssetTransactionsGroupedByDay
          .sort((a, b) => {
            const recordDateA = new Date(
              (a[0] as AssetTransaction).date ?? (a[0] as RecordListItemRecord).recordDate,
            )
            const recordDateB = new Date(
              (b[0] as AssetTransaction).date ?? (b[0] as RecordListItemRecord).recordDate,
            )

            return recordDateB.getTime() - recordDateA.getTime()
          })
          .map((dailyRecords, i) => (
            <React.Fragment key={i}>
              <div
                style={{ top: "75px" }}
                className={recordListStyles.stickyHeader}
              >
                <div className={recordListStyles.stickyDate}>
                  {convertDateToLabel(
                    moment(
                      (dailyRecords[0] as AssetTransactionListItemType).date ??
                        (dailyRecords[0] as RecordListItemRecord).recordDate,
                    ),
                  )}
                </div>
              </div>
              {dailyRecords.map((record, index) => {
                switch (record.reservedModelType) {
                  case "AssetTransaction":
                    return (
                      <AssetTransactionListItem
                        key={index}
                        assetTransaction={record as AssetTransactionListItemType}
                      />
                    )
                  case "Record":
                    return (
                      <ExtendedRecordListItem
                        isRemoving={false}
                        key={index}
                        record={record as RecordListItemRecord}
                        onEditRecordClick={async (e) => {
                          e.stopPropagation()
                          dispatch(openEditRecordForm(record._id))
                        }}
                        onRecordDelete={() => {
                          dispatch(removeFormActions.remove([record]))
                        }}
                      />
                    )
                  default:
                    return null
                }
              })}
            </React.Fragment>
          ))}
      </div>
    </div>
  )
}

export default AvailableCashTab

function groupItemsByDay(items: (RecordListItemRecord | AssetTransaction)[]) {
  const groupedItems: (RecordListItemRecord | AssetTransaction)[][] = []
  const dateMap = new Map<string, (RecordListItemRecord | AssetTransaction)[]>()

  // Iterate through the items and group them by day
  for (const item of items) {
    const recordDate = new Date(
      (item as AssetTransaction).date ?? (item as RecordListItemRecord).recordDate,
    ) // Assuming 'recordDate' is an instance of Date

    // Extract the recordDate in 'YYYY-MM-DD' format
    const dayKey = recordDate.toISOString().split("T")[0]

    if (!dateMap.has(dayKey)) {
      dateMap.set(dayKey, [])
    }

    dateMap.get(dayKey)?.push(item)
  }

  // Convert the dateMap to an array of arrays
  for (const itemsOfDay of dateMap.values()) {
    groupedItems.push(itemsOfDay)
  }

  return groupedItems
}
