import { Action } from 'src/types/common'
import * as billing from 'src/backend/rest/backend/billing'
import { isAuthorizationError } from 'src/common/helpers'
import { expireUser } from 'src/frontend/modules/user/actions'
import * as logger from 'src/common/logger'
import { showErrorModal } from 'src/frontend/Error/actions'
import { WalletRestApiError } from 'src/backend/errors'
import { getActiveSubscriptionDetails } from 'src/frontend/scenes/billing/activeSubscription/actions'

export const CANCEL_SUBSCRIPTION_OPEN = 'CANCEL_SUBSCRIPTION_OPEN'
export const CANCEL_SUBSCRIPTION_CLOSE = 'CANCEL_SUBSCRIPTION_CLOSE'
export const CANCEL_SUBSCRIPTION_START = 'CANCEL_SUBSCRIPTION_START'
export const CANCEL_SUBSCRIPTION_FINISH = 'CANCEL_SUBSCRIPTION_FINISH'
export const CANCEL_SUBSCRIPTION_ERROR = 'CANCEL_SUBSCRIPTION_ERROR'

export function openCancelSubscription(): Action {
  return {
    type: CANCEL_SUBSCRIPTION_OPEN,
  }
}

export function closeCancelSubscription(): Action {
  return {
    type: CANCEL_SUBSCRIPTION_CLOSE,
  }
}

export function cancelSubscription() {
  return async (dispatch: Function) => {
    dispatch(cancelSubscriptionStart())

    try {
      await billing.cancelSubscription()
      await dispatch(getActiveSubscriptionDetails())
      dispatch(cancelSubscriptionSuccess())
    } catch (error) {
      dispatch(cancelSubscriptionError())
      if (isAuthorizationError(error)) {
        dispatch(expireUser())
        return
      } else if (error instanceof WalletRestApiError) {
        dispatch(showErrorModal(null, false))
      }
      logger.captureException(error, 'billing.cancelSubscription')
    }
  }
}

export function cancelSubscriptionStart(): Action {
  return {
    type: CANCEL_SUBSCRIPTION_START,
  }
}

export function cancelSubscriptionSuccess(): Action {
  return {
    type: CANCEL_SUBSCRIPTION_FINISH,
  }
}

export function cancelSubscriptionError(): Action {
  return {
    type: CANCEL_SUBSCRIPTION_ERROR,
  }
}
