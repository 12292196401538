export const AllowedSettingsPaths = [
  "general",
  "categories",
  "currencies",
  "accounts",
  "templates",
  "labels",
  "privacy",
  "rules",
  "billing",
  "help",
]
