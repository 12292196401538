import { GetState } from 'src/types/common'
import { selectUser } from 'src/frontend/modules/user/selectors'
import { isAuthorizationError } from 'src/common/helpers'
import { expireUser } from 'src/frontend/modules/user/actions'
import * as logger from 'src/common/logger'
import { showError } from 'src/frontend/scenes/app/actions'
import { DEFAULT_ERROR_MESSAGE_ID } from 'src/frontend/Error/actions'
import {
  fetchProviderDetailsStart,
  fetchProviderDetailsSuccess,
} from 'src/frontend/scenes/integrations/providersList/actions'
import { fetchKBIntegrationProvider } from 'src/backend/integrations/kb/service'
import { showPersonalDetailsForm } from 'src/frontend/scenes/integrations/newConnection/actions'
import { isCNBVerified } from 'src/backend/user/service'

export function fetchKBProviderDetails() {
  return async (dispatch: Function, getState: GetState) => {
    dispatch(fetchProviderDetailsStart())
    try {
      const { userId } = selectUser(getState())

      const providerDetails = await fetchKBIntegrationProvider(userId)

      dispatch(fetchProviderDetailsSuccess(providerDetails))
    } catch (error) {
      if (isAuthorizationError(error)) {
        dispatch(expireUser())
        return
      } else {
        logger.captureException(error, 'integrations.providersList.fetchProviderDetails', { provider: 'KB' })
        dispatch(showError([DEFAULT_ERROR_MESSAGE_ID, 'app.error.we_are_fixing_suffix']))
      }
    }
  }
}

export function showKBIntegration() {
  return (dispatch: Function, getState: GetState) => {
    const user = selectUser(getState())

    if (!isCNBVerified(user)) {
      dispatch(showPersonalDetailsForm())
    } else {
      dispatch(fetchKBProviderDetails())
    }
  }
}
