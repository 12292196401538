import { Action } from 'src/types/common'
import * as actions from './actions'
import { DefaultWebConfig } from 'src/backend/webConfig/constants'
import { WebConfig } from 'src/types/WebConfig'

export default function webConfig(state: WebConfig = DefaultWebConfig, action: Action) {
  switch (action.type) {
    case actions.WEB_CONFIG_RECEIVE: {
      return { ...state, ...action.payload }
    }
    default:
      return state
  }
}
