import { inMemoryTableNames } from "src/backend/db/inMemorySqlDbSchemaBuilder"
import { IntervalGranularityType, Platform } from "src/backend/enums"
import { AnyDocument, CouchDbDocument, Id } from "src/types/CouchDb"
import { User } from "src/types/User"
import * as repository from "./repository"
import { ConverterMap } from "src/backend/db/inMemorySqlDb"
import { sortByPosition } from "src/backend/common/helpers"
import moment from "moment"
import _isEmpty from "lodash/isEmpty"
import _intersection from "lodash/intersection"
import { HashMap } from "src/types/common"
import { Currency } from "src/types/Currency"

export enum ReferenceType {
  SCALAR = "scalar",
  ARRAY = "array",
}

// todo move to api? sqlSchema?
export const referencesModel = {
  [inMemoryTableNames.CATEGORY]: [
    { name: inMemoryTableNames.RECORD, foreignKey: "categoryId", type: ReferenceType.SCALAR },
    {
      name: inMemoryTableNames.STANDING_ORDER,
      foreignKey: "categoryId",
      type: ReferenceType.SCALAR,
    },
    { name: inMemoryTableNames.TEMPLATE, foreignKey: "categoryId", type: ReferenceType.SCALAR },
    { name: inMemoryTableNames.BUDGET, foreignKey: "categoryIds", type: ReferenceType.ARRAY },
    { name: inMemoryTableNames.FILTER, foreignKey: "categories", type: ReferenceType.ARRAY },
    { name: inMemoryTableNames.MAGIC_RULE, foreignKey: "categoryId", type: ReferenceType.SCALAR },
  ],
  [inMemoryTableNames.ACCOUNT]: [
    { name: inMemoryTableNames.FILTER, foreignKey: "accounts", type: ReferenceType.ARRAY },
    { name: inMemoryTableNames.BUDGET, foreignKey: "accountIds", type: ReferenceType.ARRAY },
    { name: inMemoryTableNames.RECORD, foreignKey: "accountId", type: ReferenceType.SCALAR },
    {
      name: inMemoryTableNames.STANDING_ORDER,
      foreignKey: "accountId",
      type: ReferenceType.SCALAR,
    },
    {
      name: inMemoryTableNames.STANDING_ORDER,
      foreignKey: "toAccountId",
      type: ReferenceType.SCALAR,
    },
    { name: inMemoryTableNames.TEMPLATE, foreignKey: "accountId", type: ReferenceType.SCALAR },
    {
      name: inMemoryTableNames.ASSET_TRANSACTION,
      foreignKey: "accountId",
      type: ReferenceType.SCALAR,
    },
  ],
  [inMemoryTableNames.LABEL]: [
    { name: inMemoryTableNames.RECORD, foreignKey: "labels", type: ReferenceType.ARRAY },
    { name: inMemoryTableNames.FILTER, foreignKey: "labels", type: ReferenceType.ARRAY },
    { name: inMemoryTableNames.STANDING_ORDER, foreignKey: "labels", type: ReferenceType.ARRAY },
    { name: inMemoryTableNames.TEMPLATE, foreignKey: "labels", type: ReferenceType.ARRAY },
    { name: inMemoryTableNames.MAGIC_RULE, foreignKey: "labelIds", type: ReferenceType.ARRAY },
  ],
  [inMemoryTableNames.CURRENCY]: [
    { name: inMemoryTableNames.RECORD, foreignKey: "currencyId", type: ReferenceType.SCALAR },
    { name: inMemoryTableNames.ACCOUNT, foreignKey: "currencyId", type: ReferenceType.SCALAR },
    {
      name: inMemoryTableNames.STANDING_ORDER,
      foreignKey: "currencyId",
      type: ReferenceType.SCALAR,
    },
    { name: inMemoryTableNames.TEMPLATE, foreignKey: "currencyId", type: ReferenceType.SCALAR },
    { name: inMemoryTableNames.FILTER, foreignKey: "currencies", type: ReferenceType.ARRAY },
    {
      name: inMemoryTableNames.ASSET_TRANSACTION,
      foreignKey: "priceCurrencyCode",
      type: ReferenceType.SCALAR,
    },
    {
      name: inMemoryTableNames.ASSET_TRANSACTION,
      foreignKey: "cashAmountCurrencyCode",
      type: ReferenceType.SCALAR,
    },
  ],
  [inMemoryTableNames.CONTACT]: [
    { name: inMemoryTableNames.RECORD, foreignKey: "contactId", type: ReferenceType.SCALAR },
    { name: inMemoryTableNames.FILTER, foreignKey: "contactIds", type: ReferenceType.ARRAY },
    { name: inMemoryTableNames.MAGIC_RULE, foreignKey: "contactId", type: ReferenceType.SCALAR },
  ],
}

export function convertGranularityToGroupByAttribute(granularity: IntervalGranularityType) {
  if (IntervalGranularityType.ALL === granularity) return null
  if (IntervalGranularityType.DAY === granularity) return "recordDateDay"
  if (IntervalGranularityType.WEEK === granularity) return "recordDateWeek"
  if (IntervalGranularityType.MONTH === granularity) return "recordDateMonth"
  throw new Error(`Can not convert granularity type ${granularity} to group by attribute`)
}

export function getEntityReferences(entity: CouchDbDocument) {
  const entityId = entity._id
  let referencesToCheck = referencesModel[entity.reservedModelType]

  const foundReferences = referencesToCheck.map((model) => {
    if (model.name === inMemoryTableNames.ASSET_TRANSACTION && !!(entity as Currency).code) {
      return repository
        .findBy(model.name, model.foreignKey, (entity as Currency).code)
        .then((reference) => {
          return reference
        })
    } else if (model.type === ReferenceType.SCALAR) {
      return repository.findBy(model.name, model.foreignKey, entityId).then((reference) => {
        return reference
      })
    } else {
      return repository.findAll(model.name).then((references) => {
        const matchingReferences = references.filter((reference) => {
          return reference[model.foreignKey] && reference[model.foreignKey].includes(entityId)
        })
        console.log("findAllAsHashMap", model.name, matchingReferences)
        return matchingReferences
      })
    }
  })

  return Promise.all(foundReferences).then(flattenArrays).then(logResult("Found references"))
}

function flattenArrays(arrays) {
  return arrays.reduce((flattenedArray, array) => {
    return [...flattenedArray, ...array]
  }, [])
}

function logResult(message) {
  return (result) => {
    console.log(message, result)
    return result
  }
}

export function updateAuditData(user: User, utcDateAsISOString: Function) {
  return (entity: any) => {
    return {
      ...entity,
      reservedAuthorId: user.userId,
      reservedCreatedAt: utcDateAsISOString(),
      reservedOwnerId: user.userId,
    }
  }
}

export async function reorderEntities(oldIndex: number, newIndex: number, tableName: string) {
  const arrayOfEntities = await repository
    .findAll(tableName)
    .then((entities) => entities.sort(sortByPosition))

  if (oldIndex === newIndex) {
    return arrayOfEntities[oldIndex]
  }

  const converter =
    typeof ConverterMap[tableName] === "function" ? ConverterMap[tableName]() : undefined

  const arrayWithValidPositions = hasInvalidPositions(arrayOfEntities)
    ? await regeneratePositions(arrayOfEntities, tableName, converter)
    : arrayOfEntities

  const entity = { ...arrayWithValidPositions[oldIndex] }
  const newPosition = getNewPosition(newIndex, oldIndex, arrayWithValidPositions)

  const updatedEntity = {
    ...entity,
    position: newPosition,
  }

  return repository.updateBulk([updatedEntity], tableName, converter)
}

export function hasInvalidPositions(arrayOfEntities: Array<AnyDocument>) {
  return arrayOfEntities.find((entity, index, entities) => {
    const previousEntity = entities[index - 1]
    return (!!previousEntity && entity.position === previousEntity.position) || !entity.position
  })
}

export function regeneratePositions(
  arrayOfEntities: Array<AnyDocument>,
  tableName: string,
  converter: Function,
): Promise<Array<AnyDocument>> {
  const arrayWithRegeneratedPositions = arrayOfEntities.reduce(
    (cumulatedEntities, entity, index) => {
      return [...cumulatedEntities, { ...entity, position: (index + 1) * 1000 }]
    },
    [],
  )
  return repository.updateBulk(arrayWithRegeneratedPositions, tableName, converter)
}

function getNewPosition(newIndex: number, oldIndex: number, arrayOfEntities: Array<AnyDocument>) {
  if (newIndex > 0 && newIndex < arrayOfEntities.length - 1 && oldIndex > newIndex) {
    return Math.floor(
      (arrayOfEntities[newIndex].position + arrayOfEntities[newIndex - 1].position) / 2,
    )
  } else if (newIndex > 0 && newIndex < arrayOfEntities.length - 1 && oldIndex < newIndex) {
    return Math.floor(
      (arrayOfEntities[newIndex].position + arrayOfEntities[newIndex + 1].position) / 2,
    )
  } else if (newIndex === 0) {
    return Math.floor((arrayOfEntities[newIndex].position || 0) / 2)
  }
  return Math.floor(arrayOfEntities[newIndex].position + 1000)
}

/**
 * Creates default(Wallet/Board) document with reserved fields
 */
export function createDefaultDocument(_id: Id, modelType: string, user: User): CouchDbDocument {
  const date = moment().toISOString()
  return {
    _id,
    reservedCreatedAt: date,
    reservedModelType: modelType,
    reservedOwnerId: user.userId,
    reservedAuthorId: user.userId,
    reservedSource: Platform.WEB,
  }
}

export function matchesFilter(filter: HashMap<any>) {
  return (entity: AnyDocument): boolean => {
    return Object.keys(filter).every((filterAttribute: string): boolean => {
      const filterArray = Array.isArray(filter[filterAttribute])
        ? filter[filterAttribute]
        : [filter[filterAttribute]]

      const valuesArray = Array.isArray(entity[filterAttribute])
        ? entity[filterAttribute]
        : [entity[filterAttribute]]

      return (
        filter[filterAttribute] === undefined ||
        (Array.isArray(filter[filterAttribute]) && _isEmpty(filter[filterAttribute])) ||
        entity[filterAttribute] === undefined ||
        _intersection(filterArray, valuesArray).length !== 0
      )
    })
  }
}
