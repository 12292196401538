// tslint:disable:no-parameter-reassignment

export function initialize(hotjarId) {
  const HOTJAR_VERSION = 6;

  (function (h, o, t, j, a, r) {
    h.hj = h.hj || function () {
      (h.hj.q = h.hj.q || []).push(arguments)
    }
    h._hjSettings = { hjid: hotjarId, hjsv: HOTJAR_VERSION }

    a = o.getElementsByTagName('head')[0]
    r = o.createElement('script')
    r.async = 1
    r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv
    a.appendChild(r)
  })(window as any, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=')
}
