import { BraintreePaymentMethod } from 'src/frontend/scenes/billing/paymentMethod/types'
import { BraintreeBillingAddress } from '../billingInformation/types'

export interface BraintreeTransaction {
  id: string
  amount: number
  billingAddress?: BraintreeBillingAddress
  createdAt: number
  currencyCode: string
  gatewayRejectionReason?: string
  planId: string
  recurring: boolean
  status: BraintreeTransactionStatus
  taxAmount?: number
  taxExempt: boolean
  vatNumber?: string
  paymentMethod: BraintreePaymentMethod
  billingStartDate: number
  billingEndDate: number
}

export enum BraintreeTransactionStatus {
  AUTHORIZATION_EXPIRED = 1,
  AUTHORIZED = 2,
  AUTHORIZING = 3,
  FAILED = 4,
  GATEWAY_REJECTED = 5,
  PROCESSOR_DECLINED = 6,
  SETTLED = 7,
  SETTLEMENT_CONFIRMED = 8,
  SETTLEMENT_DECLINED = 9,
  SETTLEMENT_PENDING = 10,
  SETTLING = 11,
  SUBMITTED_FOR_SETTLEMENT = 12,
  UNRECOGNIZED = 13,
  VOIDED = 14,
}
