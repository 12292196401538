import React, { useCallback, useEffect } from 'react'
import { connect } from 'react-redux'
import { Divider, Form } from 'semantic-ui-react'
import { formatMessage, FormattedMessage } from 'src/frontend/modules/intl'
import { RootState } from 'src/types/State'
import {
  changeValue,
  createFirstAccount,
  gotoNextStage,
  togglePreferBankIntegrationStage,
} from 'src/frontend/scenes/onBoarding/firstRun/actions'
import {
  selectOnBoardingFormValues,
  selectOnBoardingLoading,
  selectOnBoardingStage,
} from 'src/frontend/scenes/onBoarding/firstRun/selectors'
import { OnBoardingFormValues } from 'src/frontend/scenes/onBoarding/firstRun/types'
import { Account } from 'src/types/Account'
import { selectAccountsAsArray } from 'src/frontend/modules/accounts/selectors'
import { OnBoardingStage } from 'src/frontend/scenes/onBoarding/firstRun/enums'
import FormattedMessageLabel from 'src/frontend/modules/intl/components/FormattedMessageLabel'
import { PrimaryButton } from 'src/frontend/components/Buttons/Buttons'
import { isKBUser } from 'src/backend/user/service'
import { selectUser } from 'src/frontend/modules/user/selectors'
import { User } from 'src/types/User'
import styles from 'src/frontend/scenes/onBoarding/firstRun/kb/components/KBAccountIntegration.module.less'
import * as mixpanel from 'src/common/mixpanel'

interface Props {
  loading: boolean
  accounts: Account[]
  formValues: OnBoardingFormValues,
  stage: OnBoardingStage,
  user: User

  onFieldChange: (name: string, value: string) => void
  onGotoNext: () => void
  onCreateAccount: () => void
  togglePreferBank: () => void
}

const mapStateToProps = (state: RootState) => ({
  accounts: selectAccountsAsArray(state),
  formValues: selectOnBoardingFormValues(state),
  loading: selectOnBoardingLoading(state),
  stage: selectOnBoardingStage(state),
  user: selectUser(state),
})

const mapDispatchToProps = {
  onFieldChange: changeValue,
  onGotoNext: gotoNextStage,
  onCreateAccount: createFirstAccount,
  togglePreferBank: togglePreferBankIntegrationStage,
}

function AccountNameForm({
  loading,
  accounts,
  formValues,
  stage,
  user,
  onFieldChange,
  onGotoNext,
  onCreateAccount,
  togglePreferBank,
}: Props) {
  useEffect(() => {
    if (accounts && accounts.length > 0 && stage === OnBoardingStage.ACCOUNT_NAME) {
      onGotoNext()
    }
  }, [accounts, stage])

  useEffect(() => {
    mixpanel.trackAccountNameEnter()
  }, [])


  const handleChangeName = useCallback((_e, field: { value: string, name: string }) => onFieldChange(
    field.name,
    field.value,
  ), [])
  const handleCreateAccount = useCallback(() => onCreateAccount(), [])

  const accountNameInvalid = !formValues.accountName
    || formValues.accountName && formValues.accountName.trim().length < 1

  return (
    <>
      <Form>
        <Form.Input
          autoFocus
          name="accountName"
          label={<FormattedMessageLabel id="onBoarding.ACCOUNT_NAME.label" />}
          placeholder={formatMessage('onBoarding.ACCOUNT_NAME.name.placeholder')}
          value={formValues.accountName}
          onChange={handleChangeName}
        />
        <p>
          <FormattedMessage id="onBoarding.ACCOUNT_NAME.info_message" />
        </p>
        <p>
          <FormattedMessage id="onBoarding.ACCOUNT_NAME.info_message_2" />
        </p>
        <Divider hidden section />
        <PrimaryButton
          loading={loading}
          disabled={loading || accountNameInvalid}
          fluid
          type="submit"
          size="big"
          onClick={handleCreateAccount}
        >
          <FormattedMessage id="onBoarding.ACCOUNT_NAME.confirm" />
        </PrimaryButton>
        {isKBUser(user) && (
          <div className={styles.skip} onClick={() => togglePreferBank()}>
            <FormattedMessage id="kb.connect-button" />
          </div>
        )}
      </Form>
    </>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountNameForm)
