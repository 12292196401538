import { RootState } from 'src/types/State'
import { selectContacts } from 'src/frontend/scenes/contacts/selectors'
import { Option } from 'src/frontend/components/Filter/types'
import { createSelector } from 'reselect'
import { formatMessage } from 'src/frontend/modules/intl/i18n'
import { isItemVisible } from 'src/frontend/modules/filter/helpers'
import { getContactTypes } from 'src/backend/contacts/helpers'
import { ContactsFilterType } from 'src/frontend/scenes/contacts/filter/types'

export const selectContactFilterScene = (state: RootState) => selectContacts(state).contactFilter

export const selectContactFilter = (state: RootState) => selectContactFilterScene(state).filter
export const selectContactFilterDisplayOption = (state: RootState) => selectContactFilterScene(state).display

export const selectContactTypeOptions: (state: RootState) => Option[]
  = createSelector(selectContactFilter, getContactTypeOptions)

export function getContactTypeOptions(filters: ContactsFilterType): Option[] {
  const contactTypes = getContactTypes()
  return Object.keys(contactTypes)
    .map(contactTypeKey => ({ _id: contactTypes[contactTypeKey], name: `contacts.type.${contactTypeKey}` }))
    .map((paymentType): Option => {
      const visible = isItemVisible(filters, 'contactTypeIds', paymentType._id)
      const name = formatMessage(paymentType.name)
      return { ...paymentType, name, visible } as Option
    })
}
