import React from 'react'
import { Icon, Table } from 'semantic-ui-react'
import { RecordCategoryLevel } from 'src/backend/categories/enums'
import FormattedPercentageChange from 'src/frontend/modules/intl/components/FormattedPercentageChange'
import FormattedMoney from 'src/frontend/modules/intl/components/FormattedMoney'
import { Currency } from 'src/types/Currency'

interface Props {
  label: string
  className?: string
  currentValue: number
  previousValue: number
  change: number
  referentialCurrency: Currency
  recordCategoryLevel: RecordCategoryLevel
  recordCategoryValue: number | string | number[] | string[]
  onOpenCategoryPreview: Function
}

CashFlowReportTableRow.defaultProps = {
  className: '',
}

export default function CashFlowReportTableRow({
  className,
  currentValue,
  previousValue,
  change,
  label,
  referentialCurrency,
  recordCategoryLevel,
  recordCategoryValue,
  onOpenCategoryPreview,
}: Props) {

  const isZeroCurrentPeriod = currentValue === 0
  const isZeroPreviousPeriod = previousValue === 0
  const isZeroRow = isZeroCurrentPeriod && isZeroPreviousPeriod

  return (
    <Table.Row
      className={`report-row${isZeroRow ? ' zero' : ''} ${className}`}
    >
      <Table.Cell width={5}>
        {label}
      </Table.Cell>
      <Table.Cell width={4} textAlign="right">
        <FormattedMoney value={currentValue} colored currency={referentialCurrency.code} />
      </Table.Cell>
      <Table.Cell width={4} textAlign="right">
        <FormattedMoney value={previousValue} currency={referentialCurrency.code} />
      </Table.Cell>
      <Table.Cell width={2} textAlign="right">
        <FormattedPercentageChange simple change={change} />
      </Table.Cell>
      <Table.Cell width={1} textAlign="right" className="records-trigger" onClick={(e) => e.stopPropagation()}>
        {!isZeroCurrentPeriod && (
          <Icon
            className="records-preview-button"
            name="list ul"
            onClick={() => onOpenCategoryPreview(recordCategoryLevel, recordCategoryValue)}
          />
        )}
      </Table.Cell>
    </Table.Row>
  )
}
