import React from 'react'
import { Message } from 'semantic-ui-react'
import DatePicker from 'react-datepicker'
import { formatMessage, FormattedMessage } from 'src/frontend/modules/intl'
import moment, { Moment } from 'moment'
import './DateTimePicker.less'
import { withIntlConsumer } from 'src/frontend/modules/intl/components/withIntlConsumer'
// import 'src/frontend/styles/react-datepicker/datepicker.less'

export enum DatePickerType {
  TIME,
  DATE,
}

interface Props {
  name: string,
  label?: string | React.ReactNode,
  selectedDate: Date,
  minDate?: Date | Moment,
  maxDate?: Date | Moment,
  locale: string,
  error?: string,
  disabled?: boolean,
  type: DatePickerType,
  onChange: Function,
}

DateTimePicker.defaultProps = {
  label: null,
  locale: 'en',
  error: null,
  dateDisabled: false,
  minDate: undefined,
  maxDate: undefined,
}

function DateTimePicker({
  label,
  type,
  name,
  selectedDate,
  minDate,
  maxDate,
  locale,
  error,
  disabled,
  onChange,
}: Props) {
  const handleDateChange = (date, event) => onChange(event, { name, value: date })

  const disabledClass = disabled ? 'disabled' : ''
  const errorClass = !!error ? 'error' : ''

  const dateFormat = type === DatePickerType.TIME
    ? 'LT' // Localized time format
    : 'll' // Localized date format

  return (
    <div className={`field field-date ${disabledClass} ${errorClass}`}>
      {label && <label>{label}</label>}
      <DatePicker
        selected={selectedDate && moment(selectedDate)}
        minDate={minDate && moment(minDate)}
        maxDate={maxDate && moment(maxDate)}
        locale={locale}
        disabled={disabled}
        showTimeSelect={type === DatePickerType.TIME}
        showTimeSelectOnly={type === DatePickerType.TIME}
        timeIntervals={15}
        popperPlacement="bottom"
        dateFormat={dateFormat}
        timeCaption={formatMessage('time')}
        onChange={handleDateChange}
      />
      {error && <Message className="error-message" error content={<FormattedMessage id={error} />} />}
    </div>
  )
}

export default withIntlConsumer(DateTimePicker)
