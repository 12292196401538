import { FormType } from 'src/frontend/scenes/settings/enums'
import * as actions from './actions'
import { LabelFormValues } from 'src/frontend/scenes/settings/labels/types'
import { HashMap } from 'src/types/common'
import { Id } from 'src/types/CouchDb'

export interface LabelsFormState {
  formType: FormType
  isOpen: boolean
  formValues: Partial<LabelFormValues>
  colorPicker: boolean
  errors: HashMap<string>
  labelId: Id
}

const initialFormState: LabelsFormState = {
  formType: FormType.ADD,
  isOpen: false,
  formValues: {},
  labelId: null,
  colorPicker: false,
  errors: {},
}

function labels(state: LabelsFormState = initialFormState, action): LabelsFormState {
  switch (action.type) {
    case actions.SETTINGS_LABELS_OPEN_COLOR_PICKER: {
      return { ...state, colorPicker: true }
    }
    case actions.SETTINGS_LABELS_CLOSE_COLOR_PICKER: {
      return { ...state, colorPicker: false }
    }
    case actions.SETTINGS_LABELS_OPEN_FORM: {
      return {
        ...state,
        isOpen: true,
        formType: action.payload.formType,
        formValues: action.payload.formValues,
        labelId: action.payload.labelId,
      }
    }

    case actions.SETTINGS_LABELS_SAVE_ERROR: {
      return { ...state, errors: action.payload }
    }
    case actions.SETTINGS_LABELS_SAVE_SUCCESS:
    case actions.SETTINGS_LABELS_CLOSE_FORM: {
      return initialFormState
    }
    case actions.SETTINGS_LABELS_CHANGE_FIELD_VALUE: {
      return { ...state, formValues: { ...state.formValues, ...action.payload } }
    }
    default: {
      return state
    }
  }
}

export default labels
