import React from 'react';
import { mergeClasses } from 'src/common/utils';
import ChangeLanguage from './ChangeLanguage';
import TermsConditions from './TermsConditions';
import { isAppBoard } from 'src/common/environment';

// Assets
import styles from './AuthSectionLayout.module.less';
import walletLogo from 'src/frontend/assets/images/wallet_logo_web.svg';
import boardLogo from 'src/frontend/assets/images/board/board-logo-secondary.svg';

interface Props {
  children: React.ReactNode;
  invertedStyle: boolean;
}

const AuthSectionLayout: React.FC<Props> = ({ children, invertedStyle }) => {
  const appLogo = isAppBoard() ? boardLogo : walletLogo;
  const appName = isAppBoard() ? 'Wallet' : 'Board';
  return (
    <section className={styles.layout}>
      <header className={styles.header}>
        <img
          className={styles.logo}
          src={appLogo}
          alt={`${appName} logo`}
        ></img>

        <ChangeLanguage inverted={invertedStyle} />
      </header>

      <div className={styles.content}>{children}</div>

      <footer className={mergeClasses(styles.footer, 'visibility-0')}>
        <TermsConditions />
      </footer>
    </section>
  );
};

export default AuthSectionLayout;
