import * as service from 'src/backend/contacts/service'
import { Contact } from 'src/types/Contact'
import ContactDocument = Contact.ContactDocument
import { reduceBy } from 'src/common/utils'

export const CONTACTS_RECEIVE = 'CONTACTS_RECEIVE'

export function receiveContacts(contactsArray: ContactDocument[]) {
  return {
    type: CONTACTS_RECEIVE,
    payload: contactsArray.reduce(reduceBy('_id'), {}),

  }
}

export function fetchContacts() {
  return async (dispatch: Function) => {
    const contacts = await service.findAllContacts()
    return dispatch(receiveContacts(contacts))
  }
}
