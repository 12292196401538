import React from 'react'
import { Popup } from 'semantic-ui-react'
import CirclePicker from 'react-color/lib/Circle'
import { ColorPickerProps } from 'src/frontend/components/ColorPicker/types'

interface Props extends ColorPickerProps {
  onSelect: (MouseEvent) => void
}

export default function ColorPicker({
  colors,
  color,
  trigger,
  position,
  open,
  onOpen,
  onClose,
  onSelect,
}: Props) {

  const circlePicker = (
    <CirclePicker
      colors={colors}
      color={color}
      width="336px"
      onChangeComplete={onSelect}
    />
  )

  return (
    <Popup
      trigger={trigger}
      content={circlePicker}
      on="click"
      open={open}
      position={position}
      hideOnScroll
      onClose={onClose}
      onOpen={onOpen}
    />
  )

}
