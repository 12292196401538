import moment from "moment"
import { getUserInfo, logoutUser, setReferralSource } from "src/backend/rest/backend"
import { changeUserPassword, loginUser, signUpUser } from "src/backend/user/service"
import {
  getLastTermsChecked,
  setLastTermsChecked,
  setUserChecked,
} from "src/backend/db/localStorage"
import { isAuthorizationError, isUserNotFoundError } from "src/common/helpers"
import { UserLoginStatus } from "src/frontend/modules/user/enums"
import { selectUser } from "src/frontend/modules/user/selectors"
import {
  initAuthenticatedApp,
  logoutSoft,
  RESTORE_APP_STATE,
} from "src/frontend/scenes/app/actions"
import { sessionExpired } from "src/frontend/modules/modals/actions"
import * as mixpanel from "src/common/mixpanel"
import { openGdprConsentModal } from "src/frontend/scenes/gdpr/actions"
import { isLessThan24Hours } from "src/backend/time/time"
import { PasswordsForm, SignUpForm } from "src/frontend/modules/user/types"
import { GetState } from "src/types/common"
import { OAuthLoginMethod, ReferralSource, User } from "src/types/User"
import { getReplication } from "src/frontend/modules/user/helpers"
import { clearAllActiveRefreshes } from "src/frontend/scenes/integrations/connections/actions"
import { appleSignIn, oAuth2Login } from "src/backend/rest/auth"
import { Oauth2User } from "src/frontend/scenes/auth/types"
import * as logger from "src/common/logger"
import { removeReferralSource } from "src/frontend/scenes/auth/signup/hooks"
import { isUndefinedOrNull } from "src/common/utils"

export const LOGIN_USER_START = "LOGIN_USER_START"
export const GET_USER_END = "GET_USER_END"
export const GET_USER_ERROR = "GET_USER_ERROR"
export const EXPIRE_USER = "EXPIRE_USER"
export const LOGOUT_USER_START = "LOGOUT_USER_START"
export const LOGOUT_USER_END = "LOGOUT_USER_END"
export const SET_USER_CONSENT = "module/user/SET_USER_CONSENT"

export function signUp(userForm: SignUpForm, referralSource?: ReferralSource) {
  return async (dispatch: Function) => {
    await signUpUser(userForm, referralSource)
    await dispatch(getUser())
    removeReferralSource()
  }
}

export function login(username: string, password: string, referralSource: ReferralSource) {
  return async (dispatch: Function) => {
    dispatch(loginStart())
    await loginUser(username, password)
    mixpanel.trackWebSignIn()
    setUserChecked(moment().toISOString())
    const user = await dispatch(getUser())

    // need to rework when there're more referral sources in place than just one (KB)
    if (!isUndefinedOrNull(referralSource) && user.referralSource !== referralSource) {
      try {
        await setReferralSource(referralSource)
      } catch {}
      await dispatch(getUser())
    }
  }
}

export function loginOAuth(
  userPayload: Oauth2User,
  method: OAuthLoginMethod,
  referralSource: ReferralSource,
) {
  return async (dispatch: Function) => {
    try {
      await oAuth2Login(userPayload, method, referralSource)
      mixpanel.trackWebSignIn()
      const user = await dispatch(getUser())

      // need to rework when there're more referral sources in place than just one (KB)
      if (!isUndefinedOrNull(referralSource) && user.referralSource !== referralSource) {
        await setReferralSource(referralSource)
        await dispatch(getUser())
      }
      await dispatch(initAuthenticatedApp())
      removeReferralSource()
    } catch (error) {
      logger.captureException(error, "user.actions.loginOAuth", { method })
    }
  }
}

export function loginApple(token: string, referralSource: ReferralSource) {
  return async (dispatch: Function) => {
    try {
      await appleSignIn(token, referralSource)
      mixpanel.trackWebSignIn()
      const user = await dispatch(getUser())

      // need to rework when there're more referral sources in place than just one (KB)
      if (!isUndefinedOrNull(referralSource) && user.referralSource !== referralSource) {
        await setReferralSource(referralSource)
        await dispatch(getUser())
      }
      await dispatch(initAuthenticatedApp())
      removeReferralSource()
    } catch (error) {
      console.log(error)
    }
  }
}

export function changePassword(passwords: PasswordsForm) {
  return (dispatch: Function) => {
    return changeUserPassword(passwords).catch((error) => {
      if (isAuthorizationError(error)) {
        dispatch(sessionExpired())
      } else {
        throw error
      }
    })
  }
}

export function getUser() {
  return async (dispatch: Function, getState: GetState): Promise<User> => {
    const prevUser = selectUser(getState())

    try {
      const user = await getUserInfo()
      const updatedUser: User = {
        ...user,
        replication: getReplication(user),
        loginStatus: UserLoginStatus.AUTHENTICATED,
      }

      // @ts-ignore, common replication object
      delete updatedUser.replicationCouchbase
      dispatch(getUserEnd(updatedUser))
      return updatedUser
    } catch (error) {
      if (isAuthorizationError(error)) {
        if (prevUser.loginStatus === UserLoginStatus.AUTHENTICATED) {
          dispatch(
            getUserEnd({
              loginStatus: UserLoginStatus.EXPIRED,
            }),
          )
        } else {
          dispatch(
            getUserEnd({
              loginStatus: UserLoginStatus.NOT_AUTHENTICATED,
            }),
          )
        }
      } else if (isUserNotFoundError(error)) {
        dispatch(logoutSoft())
      } else {
        dispatch(getUserError())
      }
      throw error
    }
  }
}

export function expireUser() {
  return { type: EXPIRE_USER }
}

function loginStart() {
  return { type: LOGIN_USER_START }
}

function getUserEnd(payload) {
  if(payload.avatarUrl) payload.avatarUrl = payload.avatarUrl.replace('http://', 'https://')
  return { type: GET_USER_END, payload }
}

function getUserError() {
  return { type: GET_USER_ERROR }
}

export function logout() {
  return (dispatch: Function) => {
    dispatch({ type: LOGOUT_USER_START })
    dispatch(clearAllActiveRefreshes())
    return (
      logoutUser()
        // FIXME missing catch so that the important actions are always performed
        .then(() => {
          dispatch(restoreAppState())
          dispatch({
            type: LOGOUT_USER_END,
            payload: { loginStatus: UserLoginStatus.NOT_AUTHENTICATED },
          })
        })
    )
  }
}

export function restoreAppState() {
  return { type: RESTORE_APP_STATE }
}

export function checkGdprConsent(user: User) {
  return (dispatch: Function) => {
    const lastTermsChecked = getLastTermsChecked(user.userId)
    const checkedToday = lastTermsChecked && isLessThan24Hours(lastTermsChecked)
    if (checkedToday || (user.consents && user.consents.policy)) {
      return null
    } else {
      setLastTermsChecked(user.userId, moment().toISOString())
      return dispatch(openGdprConsentModal())
    }
  }
}
