import React from "react"
import PropTypes from "prop-types"
import { formatAttributeName, getHeaderName } from "src/frontend/scenes/imports/mapping/helpers"
import { ImportErrorType } from "src/backend/enums"
import { formatMessage, FormattedMessage } from "src/frontend/modules/intl"
import { IMPORT_FORMAT_DOCS_URL } from "src/frontend/scenes/imports/mapping/constants"

function isNumberOrDateError(errorType) {
  return [ImportErrorType.NUMBER_FORMAT, ImportErrorType.DATE_FORMAT].includes(errorType)
}

ErrorMessage.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.shape({ type: PropTypes.number.isRequired }).isRequired)
    .isRequired,
  headers: PropTypes.arrayOf(PropTypes.shape({ colName: PropTypes.string.isRequired }).isRequired)
    .isRequired,
  errorLine: PropTypes.number.isRequired,
  errorColumn: PropTypes.number.isRequired,
  errorType: PropTypes.number.isRequired,
  value: PropTypes.arrayOf(PropTypes.number),
}

ErrorMessage.defaultProps = {
  value: [],
}

export default function ErrorMessage({
  columns,
  headers,
  accountCurrencyCode,
  errorLine,
  errorColumn,
  errorType,
  value,
}) {
  console.log("columns", columns)
  console.log("headers", headers)
  console.log("accountCurrencyCode", accountCurrencyCode)
  console.log("errorLine", errorLine)
  console.log("errorColumn", errorColumn)
  console.log("errorType", errorType)
  console.log("value", value.toString())

  if (errorType === ImportErrorType.CURRENCY_FORMAT) {
    return formatMessage("imports.importErrorType.currency_text", {
      currencyCode: accountCurrencyCode,
    })
  } else if (isNumberOrDateError(errorType)) {
    return formatMessage("imports.importErrorType.invalid_format", {
      attributeType: errorType,
      attribute: formatAttributeName(columns[errorColumn].type),
      headerName: getHeaderName(headers, errorColumn),
      errorLine,
    })
  } else if (errorType === ImportErrorType.MISSING_REQUIRED_FIELD) {
    const missingAttributes = value.map((attribute) => formatAttributeName(attribute)).join(", ")
    return formatMessage("imports.importErrorType.required_fields", { missingAttributes })
  } else if (errorType === ImportErrorType.INCOME_EXPENSE_FILLED) {
    return (
      <FormattedMessage
        id="imports.importErrorType.income_expense"
        values={{
          link: (
            <a
              href={IMPORT_FORMAT_DOCS_URL}
              target="_blank"
              rel="noopener noreferrer"
            >
              {formatMessage("imports.importErrorType.income_expense.link_text")}
            </a>
          ),
        }}
      />
    )
  } else {
    return <>Invalid file</>
  }
}
