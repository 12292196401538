import { selectImportsState } from 'src/frontend/scenes/imports/local-selectors'
import _isEmpty from 'lodash/isEmpty'
import { createSelector } from 'reselect'
import {
  isConnected, isGeneral, isImportsActivated, positionComparator,
} from 'src/backend/accounts/helpers'
import { selectNonArchivedAccountsAsArray } from 'src/frontend/modules/accounts/selectors'
import { selectCurrencies } from 'src/frontend/modules/currencies/selectors'

const selectAccountState = state => selectImportsState(state).account

// FIXME how to avoid circular dependency or code duplication
const selectToUploadItems = state => state.scenes.imports.items.toUploadItems

export const selectAccountList = createSelector(
  [selectNonArchivedAccountsAsArray, selectToUploadItems],
  (accounts, toUploadItems) => {
    const waitingImportAccounts = accounts
      .filter(account => !isConnected(account) && isGeneral(account) && toUploadItems.has(account._id))
      .sort(positionComparator)
      .map(account => {
        const item = toUploadItems.get(account._id)
        return { ...account, latestUpload: item.latestUpload }
      })

    const importAccounts = accounts
      .filter(account => !isConnected(account) && isGeneral(account) && !toUploadItems.has(account._id))
      .sort(positionComparator)

    return [...waitingImportAccounts, ...importAccounts]
  },
)

export const selectHasAccounts = createSelector(
  [selectAccountList],
  (accountList) => (!_isEmpty(accountList)),
)

const selectedAccountIdFromState = state => selectAccountState(state).selectedAccountId

export const selectSelectedAccountId = createSelector(
  [selectAccountList, selectedAccountIdFromState],
  (accountList, selectedAccountId) => {
    if (_isEmpty(accountList)) {
      return null
    } else if (accountList.some(account => account._id === selectedAccountId)) {
      return selectedAccountId
    } else {
      return accountList[0]._id
    }
  },
)


export const selectSelectedAccount = createSelector(
  [selectSelectedAccountId, selectAccountList],
  (selectedAccountId, accountList) => {
    // FIXME selecting another account when the account is not selected manually (i.e. the first account in dropdown)
    // the account is switched to another account with pending file when the last file from the current
    // account is deleted. Works as expected when the account is selected manually.
    return selectedAccountId ? accountList.find(account => account._id === selectedAccountId) : null
  },
)

export const selectSelectedAccountName = createSelector(
  [selectSelectedAccount],
  account => (account ? account.name : null),
)

export const selectImportEmail = createSelector(
  [selectSelectedAccount],
  account => (isImportsActivated(account) ? account.importSettings.email : null),
)

export const selectSelectedAccountAutomaticImportsEnabled = createSelector(
  [selectSelectedAccount],
  (account) => !!(account && account.importSettings && account.importSettings.isAutomatic),
)

export const selectSelectedAccountCurrencyCode = createSelector(
  [selectSelectedAccount, selectCurrencies],
  (account, currencies) => {
    const currency = currencies[account.currencyId]
    if (!currency) {
      throw new Error(`Currency not found for currencyId=${account.currencyId}`)
    }
    return currency.code
  },
)
