import React from 'react'
import BasicAccountListItem from 'src/frontend/scenes/dashboard/widgets/AccountList/BasicAccountListItem'
import { AccountWithBalance } from 'src/frontend/scenes/dashboard/types'

interface Props {
  accounts: AccountWithBalance[]
}

export default function BasicAccountList({ accounts }: Props) {
  return (
    <div>
      {accounts.map((account) => <BasicAccountListItem key={account._id} account={account} />)}
    </div>
  )
}
