import { Action, HashMap } from 'src/types/common'
import { FormType } from 'src/frontend/scenes/settings/enums'
import {
  CONTACTS_CHANGE_FIELD_VALUE,
  CONTACTS_CHANGE_FIELD_VALUES,
  CONTACTS_FORM_CLOSE,
  CONTACTS_FORM_OPEN,
  CONTACTS_SAVE_ERROR,
  CONTACTS_SAVE_START,
  CONTACTS_SAVE_SUCCESS,
} from 'src/frontend/scenes/contacts/contactForm/actions'
import { ContactFormValues } from 'src/frontend/scenes/contacts/contactForm/types'
import { Id } from 'src/types/CouchDb'


export interface ContactFormState {
  formValues: ContactFormValues
  formType: FormType
  contactId: Id,
  errors: HashMap<string>
  loading: boolean
  open: boolean
}

const initState = {
  formValues: null,
  formType: null,
  contactId: null,
  open: false,
  loading: false,
  errors: {},
}

export function contactForm(state: ContactFormState = initState, action: Action) {
  switch (action.type) {

    case CONTACTS_FORM_OPEN: {
      return {
        ...state,
        open: true,
        contactId: action.payload.contactId,
        formType: action.payload.formType,
        formValues: { ...state.formValues, ...action.payload.formValues },
      }
    }

    case CONTACTS_CHANGE_FIELD_VALUE:
    case CONTACTS_CHANGE_FIELD_VALUES: {
      return { ...state, formValues: { ...state.formValues, ...action.payload } }
    }

    case CONTACTS_SAVE_ERROR: {
      return { ...state, errors: action.payload, loading: false }
    }

    case CONTACTS_SAVE_START: {
      return { ...state, loading: true }
    }

    case CONTACTS_SAVE_SUCCESS:
    case CONTACTS_FORM_CLOSE: {
      return { ...initState }
    }

    default: {
      return state
    }
  }
}
