import qs from "qs"
import { Platform } from "src/backend/enums"
import { WalletAuthError, WalletValidationError } from "src/backend/errors"
import {
  fetchWithRetry,
  validateCorsSuccessResponse,
  validateSuccessResponse,
} from "src/backend/rest/helpers"
import { getAppFlavor, getBackendUrl, getVersion } from "src/common/environment"
import { SignUpForm } from "src/frontend/modules/user/types"
import { ReferralSource, OAuthLoginMethod } from "src/types/User"
import { UserPasswords } from "src/backend/user/types"
import { Oauth2User } from "src/frontend/scenes/auth/types"

const version = getVersion()

export function login(username: string, password: string) {
  const url = `${getBackendUrl()}/auth/authenticate/userpass`
  const options: RequestInit = {
    method: "POST",
    redirect: "manual" as RequestRedirect,
    credentials: "include" as RequestCredentials,
    headers: {
      Accept: "application/json, text/plain, text/html, */*",
      "Web-Version-Code": version,
      "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      Platform: Platform.WEB,
      flavor: getAppFlavor(),
    } as unknown as HeadersInit,
    body: qs.stringify({
      username,
      password,
    }),
  }

  return fetchWithRetry(url, options)
    .then((response: Response) => {
      if (response.status === 400) {
        throw new WalletValidationError("auth.error.credentials_are_not_valid")
      } else {
        return response
      }
    })
    .then(validateCorsSuccessResponse)
}

export function oAuth2Login(
  userPayload: Oauth2User,
  method: OAuthLoginMethod,
  referralSource: ReferralSource,
) {
  const { profile, token } = userPayload
  const url = `${getBackendUrl()}/auth/api/authenticate/${method}?builder=cookie`
  const options: RequestInit = {
    method: "POST",
    redirect: "manual" as RequestRedirect,
    credentials: "include" as RequestCredentials,
    headers: {
      Accept: "application/json, text/plain, text/html, */*",
      "Web-Version-Code": version,
      "Content-Type": "application/json",
      Platform: Platform.WEB,
      flavor: getAppFlavor(),
      "referral-source": referralSource,
    } as unknown as HeadersInit,
    body: JSON.stringify({
      email: profile.email,
      info: token,
    }),
  }

  return fetchWithRetry(url, options)
    .then((response: Response) => {
      if (response.status === 400) {
        throw new WalletValidationError("auth.error.credentials_are_not_valid")
      } else {
        return response
      }
    })
    .then(validateCorsSuccessResponse)
}

export function appleSignIn(token: string, referralSource: ReferralSource) {
  const url = `${getBackendUrl()}/auth/api/authenticate/apple?builder=cookie`
  const options: RequestInit = {
    method: "POST",
    redirect: "manual" as RequestRedirect,
    credentials: "include" as RequestCredentials,
    headers: {
      Accept: "application/json, text/plain, text/html, */*",
      "Web-Version-Code": version,
      "Content-Type": "application/x-www-form-urlencoded",
      Platform: Platform.WEB,
      flavor: getAppFlavor(),
      "referral-source": referralSource,
      "Content-Length": "bodyDataLength",
    } as unknown as HeadersInit,
    body: new URLSearchParams([["token", token]]),
  }

  return fetchWithRetry(url, options)
    .then((response: Response) => {
      if (response.status === 400) {
        throw new WalletValidationError("auth.error.credentials_are_not_valid")
      } else {
        return response
      }
    })
    .then(validateCorsSuccessResponse)
}

export function getSignUpToken(email) {
  const url = `${getBackendUrl()}/auth/signup/token/${email}`
  const options: RequestInit = {
    method: "GET",
    headers: {
      Accept: "application/json, text/plain, text/html, */*",
      "Web-Version-Code": version,
      Platform: Platform.WEB,
      flavor: getAppFlavor(),
    } as unknown as HeadersInit,
  }

  return fetchWithRetry(url, options)
    .then((response) => {
      if (response.status === 400) {
        return responseTextToValidationError(response)
      } else {
        return response as Response
      }
    })
    .then(validateSuccessResponse)
    .then((response) => response.text())
}

export function signUpUser(token: string, signUpForm: SignUpForm, referralSource?: ReferralSource) {
  const url = `${getBackendUrl()}/auth/signup/${token}`
  const options: RequestInit = {
    method: "POST",
    redirect: "manual" as RequestRedirect,
    credentials: "include" as RequestCredentials,
    headers: {
      Accept: "application/json, text/plain, text/html, */*",
      "Web-Version-Code": version,
      "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      Platform: Platform.WEB,
      flavor: getAppFlavor(),
      "referral-source": referralSource,
    } as unknown as HeadersInit,
    body: qs.stringify({
      "password.password1": signUpForm.newPassword,
      "password.password2": signUpForm.newPassword,
    }),
  }

  return fetchWithRetry(url, options)
    .then((response) => {
      if (response.status === 400) {
        // When password is shorter than required length or password and password confirmation are not the same.
        return responseTextToValidationError(response)
      } else {
        return response
      }
    })
    .then(validateCorsSuccessResponse)
    .then((response) => response.text())
}

export function resetPassword(email) {
  const url = `${getBackendUrl()}/auth/reset`
  const options: RequestInit = {
    method: "POST",
    redirect: "manual" as RequestRedirect,
    credentials: "include" as RequestCredentials,
    headers: {
      Accept: "application/json, text/plain, text/html, */*",
      "Web-Version-Code": version,
      "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      Platform: Platform.WEB,
      flavor: getAppFlavor(),
    } as unknown as HeadersInit,
    body: qs.stringify({
      email,
    }),
  }

  return fetchWithRetry(url, options)
    .then((response) => {
      if (response.status === 400) {
        // Valid email required, This field is required
        // Backend does not check whether user exists
        throw new WalletValidationError("auth.error.email.is_invalid")
      } else {
        return response
      }
    })
    .then(validateCorsSuccessResponse)
}

export function newPassword(token, passwords: { password: string; passwordConfirmation: string }) {
  if (!token) {
    return Promise.reject(new Error("New password: Token is required!"))
  }

  const url = `${getBackendUrl()}/auth/reset/${token}`
  const options: RequestInit = {
    method: "POST",
    redirect: "manual" as RequestRedirect,
    credentials: "include" as RequestCredentials,
    headers: {
      Accept: "application/json, text/plain, text/html, */*",
      "Web-Version-Code": version,
      "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      Platform: Platform.WEB,
      flavor: getAppFlavor(),
    } as unknown as HeadersInit,
    body: qs.stringify({
      "password.password1": passwords.password,
      "password.password2": passwords.passwordConfirmation,
    }),
  }

  return fetchWithRetry(url, options)
    .then((response) => {
      if (response.status === 400) {
        // Password is shorter than required length or passwords did not match.
        throw new WalletValidationError("Error: Password is too short. Passwords did not match.")
      } else {
        return response
      }
    })
    .then(validateCorsSuccessResponse)
}

export function changePassword(passwords: UserPasswords) {
  const url = `${getBackendUrl()}/auth/password`
  const options: RequestInit = {
    method: "POST",
    redirect: "manual" as RequestRedirect,
    credentials: "include" as RequestCredentials,
    headers: {
      Accept: "application/json, text/plain, text/html, */*",
      "Web-Version-Code": version,
      "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      Platform: Platform.WEB,
      flavor: getAppFlavor(),
    } as unknown as HeadersInit,
    body: qs.stringify({
      currentPassword: passwords.currentPassword,
      "newPassword.password1": passwords.newPassword,
      "newPassword.password2": passwords.newPasswordConfirmation,
    }),
  }

  return fetchWithRetry(url, options)
    .then((response) => {
      if (response.status === 400) {
        // FIXME distinguish between current and the new password errors
        throw new WalletValidationError("auth.error.currentPassword.is_invalid")
      } else if (response.status === 401) {
        throw new WalletAuthError("Not authorized on backend", response)
      } else {
        return response
      }
    })
    .then(validateCorsSuccessResponse)
}

function responseTextToValidationError(response: Response) {
  // FIXME get the reason and i18n it!, BE return plain text messages, for instance: "User already exists"
  return response.text().then((text) => {
    // FIXME for dev/debug purposes
    console.log(`Validation text=${text}`)
    throw new WalletValidationError(text)
  }) as unknown as Promise<Response>
}
