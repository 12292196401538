import PropTypes from 'prop-types'
import React from 'react'
import { Checkbox, Form } from 'semantic-ui-react'
import { selectRowHeaderProperties } from 'src/frontend/scenes/imports/mapping/selectors'
import { connect } from 'react-redux'
import { formatMessage, FormattedMessage } from 'src/frontend/modules/intl'
import { buildRowPositionOptions } from 'src/frontend/scenes/imports/mapping/helpers'
import * as actions from 'src/frontend/scenes/imports/mapping/actions'
import { handleCheckboxChange, handleDropdownChange } from 'src/frontend/styles/semantic-ui/helpers'
import './MappingRowHeaderPanel.less'

const mapStateToProps = selectRowHeaderProperties

const mapDispatchToProps = {
  changeHasHeader: handleCheckboxChange(actions.changeHasHeader),
  changeFirstRecordPosition: handleDropdownChange(actions.changeFirstRecordPosition),
  lastRecordPositionChanged: handleDropdownChange(actions.lastRecordPositionChanged),
}

const MappingRowHeaderPanel = ({
  firstRecord, lastRecord, lastPossibleRecord, hasHeader, couldHasHeader, changeHasHeader,
  changeFirstRecordPosition, lastRecordPositionChanged,
}) => (
  <div className="mapping-row-header-panel">
    <p className="info">
      <FormattedMessage id="imports.mapping.wizard.header_and_rows_description" />
    </p>
    <Form>
      <Checkbox
        label={formatMessage('imports.mapping.has_header_row')}
        toggle
        checked={hasHeader}
        disabled={!couldHasHeader}
        onChange={changeHasHeader}
      />
      <Form.Dropdown
        className="mapping-select"
        value={firstRecord}
        label={hasHeader ? formatMessage('imports.mapping.header_row') : formatMessage('imports.mapping.first_row')}
        text={firstRecord.toString()}
        options={buildRowPositionOptions(1, lastRecord - (hasHeader ? 1 : 0))}
        selection
        selectOnBlur={false}
        onChange={changeFirstRecordPosition}
      />
      <Form.Dropdown
        className="mapping-select"
        value={lastRecord}
        label={formatMessage('imports.mapping.last_row')}
        text={lastRecord.toString()}
        options={buildRowPositionOptions(firstRecord + (hasHeader ? 1 : 0), lastPossibleRecord)}
        selection
        selectOnBlur={false}
        onChange={lastRecordPositionChanged}
      />
    </Form>
  </div>
)

MappingRowHeaderPanel.propTypes = {
  firstRecord: PropTypes.number.isRequired,
  lastRecord: PropTypes.number.isRequired,
  lastPossibleRecord: PropTypes.number.isRequired,
  hasHeader: PropTypes.bool.isRequired,
  couldHasHeader: PropTypes.bool.isRequired,
  changeHasHeader: PropTypes.func.isRequired,
  changeFirstRecordPosition: PropTypes.func.isRequired,
  lastRecordPositionChanged: PropTypes.func.isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(MappingRowHeaderPanel)
