import React from 'react'
import './FormattedPercentageChange.less'

type Props = {
  change?: number,
  inverted?: boolean,
  simple?: boolean,
  grayScale?: boolean,
}

FormattedPercentageChange.defaultProps = {
  change: null,
  inverted: false,
  simple: false,
  grayScale: false,
}

export default function FormattedPercentageChange({ change, inverted, grayScale, simple }: Props) {
  if (change === null || change === undefined) {
    return null
  }

  const GREEN = 'green'
  const RED = 'red'
  const POSITIVE = 'positive'
  const NEGATIVE = 'negative'

  const negativeColor = !inverted ? RED : GREEN
  const positiveColor = !inverted ? GREEN : RED

  let changeDirectionClass = ''
  let colorClass = ''

  if (grayScale) {
    colorClass = ''
  } else if (change > 0) {
    changeDirectionClass = POSITIVE
    colorClass = positiveColor
  } else if (change < 0) {
    changeDirectionClass = NEGATIVE
    colorClass = negativeColor
  }


  // the percent value at which to break to "more than" using the plus sign
  const PERCENT_THRESHOLD = 1000

  const isMoreThanThreshold = Math.abs(change) > PERCENT_THRESHOLD / 100
  const overThresholdSign = isMoreThanThreshold ? '>' : ''
  const changeValue = Math.round((isMoreThanThreshold ? Math.sign(change) * PERCENT_THRESHOLD / 100 : change) * 100)

  if (simple) {
    return (
      <span className={`percentage-change simple ${colorClass}`}>
        {overThresholdSign}
        &nbsp;
        {changeValue > 0 ? `+${changeValue}` : changeValue}
        %
      </span>
    )
  }

  return (
    <span className={`percentage-change ${changeDirectionClass} ${colorClass}`}>
      {overThresholdSign}
      {Math.abs(changeValue)}
      %
    </span>
  )
}
