/* eslint-disable react/require-default-props */
/* eslint-disable react/prop-types */
/* eslint-disable no-shadow */
import PropTypes from 'prop-types'
import React from 'react'
import { Checkbox, Form } from 'semantic-ui-react'
import {
  selectAmountAvailableColumnHeaders,
  selectAmountColumnIndex,
  selectExpenseAvailableColumnHeaders,
  selectExpenseColumnIndex,
  selectFeeAvailableColumnHeaders,
  selectFeeColumnIndex,
  selectSeparateFees,
  selectSeparateIncomeExpenses,
} from 'src/frontend/scenes/imports/mapping/selectors'
import { connect } from 'react-redux'
import { formatMessage, FormattedMessage } from 'src/frontend/modules/intl'
import {
  amountColumnChanged,
  expenseColumnChanged,
  feeColumnChanged,
  separateFeesChanged,
  separateIncomeExpenseChanged,
} from 'src/frontend/scenes/imports/mapping/actions'
import { handleCheckboxChange, handleDropdownChange } from 'src/frontend/styles/semantic-ui/helpers'
import { buildColumnOptions, formatAttributeName } from 'src/frontend/scenes/imports/mapping/helpers'
import { ImportMappingAttributeType } from 'src/backend/enums'
import { Columns } from 'src/frontend/scenes/imports/mapping/components/commonProptypes'

const { AMOUNT, INCOME, EXPENSE, FEE } = ImportMappingAttributeType

const mapStateToProps = state => ({
  amountColumn: selectAmountColumnIndex(state),
  availableAmountColumns: selectAmountAvailableColumnHeaders(state),
  expenseColumn: selectExpenseColumnIndex(state),
  availableExpenseColumns: selectExpenseAvailableColumnHeaders(state),
  feeColumn: selectFeeColumnIndex(state),
  availableFeeColumns: selectFeeAvailableColumnHeaders(state),
  separateFees: selectSeparateFees(state),
  separateIncomeExpense: selectSeparateIncomeExpenses(state),
})

const mapDispatchToProps = {
  amountColumnChanged: handleDropdownChange(amountColumnChanged),
  expenseColumnChanged: handleDropdownChange(expenseColumnChanged),
  feeColumnChanged: handleDropdownChange(feeColumnChanged),
  separateFeesChanged: handleCheckboxChange(separateFeesChanged),
  separateIncomeExpenseChanged: handleCheckboxChange(separateIncomeExpenseChanged),
}

const MappingAmountColumnsPanel = ({
  amountColumn,
  availableAmountColumns,
  availableExpenseColumns,
  expenseColumn,
  availableFeeColumns,
  feeColumn,
  separateIncomeExpense,
  separateFees,
  amountColumnChanged,
  separateIncomeExpenseChanged,
  expenseColumnChanged,
  separateFeesChanged,
  feeColumnChanged,
}) => (
  <div>
    <p className="info"><FormattedMessage id="imports.mapping.wizard.amount_description" /></p>
    <Form className="mapping-amount-column">
      <Checkbox
        label={formatMessage('imports.mapping.income_expense_in_two_columns')}
        toggle
        checked={separateIncomeExpense}
        onChange={separateIncomeExpenseChanged}
      />
      <Form.Group>
        <Form.Dropdown
          className="mapping-select"
          label={!separateIncomeExpense ? formatAttributeName(AMOUNT) : formatAttributeName(INCOME)}
          value={amountColumn}
          placeholder={formatMessage('form.dropdown.placeholder')}
          options={buildColumnOptions(availableAmountColumns)}
          selection
          selectOnBlur={false}
          onChange={amountColumnChanged}
        />
        {separateIncomeExpense && (
          <Form.Dropdown
            className="mapping-select"
            label={formatAttributeName(EXPENSE)}
            value={expenseColumn}
            placeholder={formatMessage('form.dropdown.placeholder')}
            options={buildColumnOptions(availableExpenseColumns)}
            selection
            selectOnBlur={false}
            onChange={expenseColumnChanged}
          />
        )}
      </Form.Group>
      <Checkbox
        label={formatMessage('imports.mapping.fee_in_separate_column')}
        toggle
        checked={separateFees}
        onChange={separateFeesChanged}
      />
      {separateFees && (
        <Form.Dropdown
          label={formatAttributeName(FEE)}
          placeholder={formatMessage('form.dropdown.placeholder')}
          options={buildColumnOptions(availableFeeColumns)}
          value={feeColumn}
          selection
          selectOnBlur={false}
          onChange={feeColumnChanged}
        />
      )}
    </Form>
  </div>
)

MappingAmountColumnsPanel.propTypes = {
  availableAmountColumns: Columns.isRequired,
  amountColumn: PropTypes.number,
  availableExpenseColumns: Columns,
  expenseColumn: PropTypes.number,
  availableFeeColumns: Columns,
  feeColumn: PropTypes.number,
  separateFees: PropTypes.bool.isRequired,
  separateIncomeExpense: PropTypes.bool.isRequired,
  separateIncomeExpenseChanged: PropTypes.func.isRequired,
  separateFeesChanged: PropTypes.func.isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(MappingAmountColumnsPanel)
