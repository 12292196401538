/* eslint-disable react/prop-types */
import React from 'react'
import './ImportRecordList.less'
import { FormattedDate } from 'react-intl/dist'
import { connect } from 'react-redux'
import { Accordion, Icon } from 'semantic-ui-react'
import * as actions from 'src/frontend/scenes/imports/records/actions'
import {
  selectImportRecordsForRecordList,
  selectSelectedRecordsStatus,
} from 'src/frontend/scenes/imports/records/selectors'
import { openEditRecordForm } from 'src/frontend/scenes/records/recordForm/actions'
import { FormattedMessage } from 'src/frontend/modules/intl'
import RecordList from 'src/frontend/components/RecordList/ExtendedRecordList'

const mapStateToProps = state => ({
  records: selectImportRecordsForRecordList(state),
  selectedRecordsStatus: selectSelectedRecordsStatus(state),
})

const mapDispatchToProps = {
  toggleRecordImport: actions.toggleRecordImport,
  selectRecords: actions.selectRecords,
  handleOpenRecordForm: openEditRecordForm,
}

function ImportRecordsList({
  records,
  toggleRecordImport,
}) {

  return (
    <div className="import-record-list">
      {records.map((recordGroup) => (
        <Accordion
          key={recordGroup.itemId}
          fluid
          styled
        >
          <Accordion.Title
            index={0}
            active={recordGroup.active}
            onClick={toggleRecordImport.bind(null, recordGroup.transactionId)}
          >
            <div className="import-record-group-title">
              <div className="name">
                <Icon name="dropdown" />
                {recordGroup.itemName}
              </div>
              <div className="date">
                <FormattedDate value={recordGroup.recordsFromDate} />
                &nbsp;-&nbsp;
                <FormattedDate value={recordGroup.recordsToDate} />
              </div>
              <div className="records">
                <FormattedMessage
                  id="imports.records.record_list.records"
                  values={{ recordsCount: recordGroup.recordsCount }}
                />
              </div>
            </div>
          </Accordion.Title>
          <Accordion.Content
            active={recordGroup.active}
            className="import-record-group-content"
          >
            <RecordList records={recordGroup.records} />
          </Accordion.Content>
        </Accordion>
      ))}
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(ImportRecordsList)
