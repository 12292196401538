import { connect } from 'react-redux'
import { RootState } from 'src/types/State'
import {
  selectCancelSubscriptionLoading,
  selectCancelSubscriptionOpen,
} from 'src/frontend/scenes/billing/cancelSubscription/selectors'
import {
  cancelSubscription,
  closeCancelSubscription,
  openCancelSubscription,
} from 'src/frontend/scenes/billing/cancelSubscription/actions'
import { Grid, Modal, Transition } from 'semantic-ui-react'
import FormattedMessage from 'src/frontend/modules/intl/components/FormattedMessage'
import { PrimaryButton, SecondaryButton } from 'src/frontend/components/Buttons/Buttons'
import React, { SyntheticEvent } from 'react'
import { selectActiveSubscription } from 'src/frontend/scenes/billing/activeSubscription/selectors'
import { BraintreeSubscription } from 'src/frontend/scenes/billing/activeSubscription/types'
import { formatDateLong } from 'src/frontend/modules/intl/i18n'

interface Props {
  subscription: BraintreeSubscription
  open: boolean
  loading: boolean

  handleClose: (e: SyntheticEvent) => void
  handleOpen: (e: SyntheticEvent) => void
  handleConfirm: (e: SyntheticEvent) => void
}


function mapStateToProps(state: RootState) {
  return {
    open: selectCancelSubscriptionOpen(state),
    subscription: selectActiveSubscription(state),
    loading: selectCancelSubscriptionLoading(state),
  }
}

const mapDispatchToProps = {
  handleClose: closeCancelSubscription,
  handleOpen: openCancelSubscription,
  handleConfirm: cancelSubscription,
}

function CancelSubscriptionModal({ open, loading, handleClose, subscription, handleOpen, handleConfirm }: Props) {
  return (
    <>
      <SecondaryButton loading={loading} disabled={loading} onClick={handleOpen}>
        <FormattedMessage id="billing.subscription.cancel" />
      </SecondaryButton>
      <Transition
        directional
        visible={open}
        unmountOnHide
        onHide={handleClose}
        duration={{ show: 250, hide: 150 }}
        animation="modal-animation"
      >
        <Modal size="small" open onClose={handleClose}>
          <Modal.Content>
            <p>
              <FormattedMessage
                id="billing.subscription.cancel-note"
                values={{
                  expirationDate: <strong>{formatDateLong(new Date(subscription.nextBillingDate))}</strong>,
                }}
              />
            </p>
            <p>
              <strong><FormattedMessage id="billing.subscription.cancel-note-question" /></strong>
            </p>
          </Modal.Content>
          <Modal.Actions>
            <Grid>
              <Grid.Column width={8}>
                <SecondaryButton fluid onClick={handleClose}>
                  <FormattedMessage id="form.no" />
                </SecondaryButton>
              </Grid.Column>
              <Grid.Column width={8}>
                <PrimaryButton disabled={loading} loading={loading} fluid color="red" onClick={handleConfirm}>
                  <FormattedMessage id="form.yes" />
                </PrimaryButton>
              </Grid.Column>
            </Grid>
          </Modal.Actions>
        </Modal>
      </Transition>
    </>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(CancelSubscriptionModal)
