import { FormattedMessage as IntlFormattedMessage } from 'react-intl/dist'
import { isAppBoard } from 'src/common/environment'
import React from 'react'


interface Props {
  id: string | { W: string, B: string }
  description?: string
  defaultMessage?: string
  values?: { [key: string]: string | number | boolean | JSX.Element }
  tagName?: React.ElementType
  className?: string
  children?: (...formattedMessage: Array<string | JSX.Element>) => React.ReactNode
}

FormattedMessage.defaultProps = {
  description: undefined,
  defaultMessage: undefined,
  tagName: undefined,
  values: undefined,
  className: undefined,
  children: undefined,
}

export default function FormattedMessage(props: Props) {
  if (props.id) {
    const id = typeof props.id === 'string'
      ? props.id
      : props.id[isAppBoard() ? 'B' : 'W']

    return <IntlFormattedMessage {...props} id={id} />
  }
  return null
}
