import React from 'react'
import { connect } from 'react-redux'
import PremiumChecker from 'src/frontend/components/PremiumChecker/PremiumChecker'
import { FormattedMessage } from 'src/frontend/modules/intl'
import './NoContent.less'
import * as selectors from 'src/frontend/scenes/settings/accounts/selectors'
import { RootState } from 'src/types/State'
import { openAccountForm as openFormAction } from 'src/frontend/scenes/settings/accounts/actions'
import { FormType } from 'src/frontend/scenes/settings/enums'
import { PrimaryButton } from 'src/frontend/components/Buttons/Buttons'
import { AccountFormType } from 'src/frontend/scenes/settings/accounts/enums'

const mapDispatchToProps = {
  openForm: () => openFormAction(FormType.ADD, null, AccountFormType.IMPORTS),
}

function mapStateToProps(state: RootState) {
  return {
    canAddAccount: selectors.selectCanAddAccount(state),
  }
}

type Props = {
  canAddAccount: boolean,
  openForm: (e: React.SyntheticEvent) => void,
}

const NoAccountContent = ({ canAddAccount, openForm }: Props) => (
  <div className="no-records-container">
    <div className="no-records-content">
      <div className="icons-container">
        <div className="icons" />
      </div>
      <div className="onboarding-big-text">
        <FormattedMessage id="imports.tutorial.no_account" />
      </div>
      <div className="onboarding-small-text">
        <FormattedMessage id="imports.tutorial.account_placeholder" />
      </div>
      <div className="button">
        <PremiumChecker enabled={canAddAccount} featureName="premium_checker.unlimited_accounts">
          <PrimaryButton
            fluid
            onClick={openForm}
          >
            <FormattedMessage id="imports.account.create_account" />
          </PrimaryButton>
        </PremiumChecker>
      </div>
    </div>
  </div>
)

export default connect(mapStateToProps, mapDispatchToProps)(NoAccountContent)
