import _groupBy from "lodash/groupBy"
import moment from "moment"
import {
  calcCreditCardLimitValue,
  isConnected,
  isCreditCard,
  isProviderCreditReverse,
} from "src/backend/accounts/helpers"
import { AmountValues, Totals } from "src/backend/analytics/types"
import { BalanceDisplayType, Interval } from "src/backend/enums"
import { Account } from "src/types/Account"
import { Period } from "src/types/Filter"
import _isEmpty from "lodash/isEmpty"
import { HashMap } from "src/types/common"
import {
  FIXED_COSTS_SUPER_ENVELOPE_IDS,
  INVESTMENTS_SUPER_ENVELOPE_IDS,
  LOANS_REPAYMENT_ID,
  NEW_FUNDING_ID,
  OPERATING_COSTS_SUPER_ENVELOPE_IDS,
  OPERATING_REVENUE_SUPER_ENVELOPE_IDS,
  OTHER_CASH_OUTFLOW_SUPER_ENVELOPE_IDS,
  OTHER_REVENUE_ID,
  OTHER_REVENUE_SUPER_ENVELOPE_IDS,
  SALES_REVENUE_ID,
  SYSTEM_CATEGORIES_TRANSFER_ID,
  SYSTEM_CATEGORIES_UNKNOWN_ID,
  VARIABLE_COSTS_SUPER_ENVELOPE_IDS,
} from "src/backend/categories/envelopes"
import { parseIntDecimal } from "src/common/utils"
import { Id } from "src/types/CouchDb"
import { ChartData } from "src/frontend/scenes/analytics/types"

export function reduceAmounts(groupByFields: Array<string> = []) {
  console.log("groupByFields: ", groupByFields)

  return (sum: Totals, amountValues: AmountValues): Totals => {
    // income + refIncome
    const income: number =
      (amountValues.amountIncome || 0) +
      (amountValues.refAmountIncome || 0) * (amountValues.ratioToReferential || 1)

    const refIncome: number =
      (amountValues.amountIncome || 0) / (amountValues.ratioToReferential || 1) +
      (amountValues.refAmountIncome || 0)

    const expense: number =
      (amountValues.amountExpense || 0) +
      (amountValues.refAmountExpense || 0) * (amountValues.ratioToReferential || 1)

    const refExpense: number =
      (amountValues.amountExpense || 0) / (amountValues.ratioToReferential || 1) +
      (amountValues.refAmountExpense || 0)

    return {
      // include group by values for future grouping
      ...groupByFields.reduce((fields: HashMap<string>, val: string) => {
        return amountValues[val] !== undefined
          ? {
              ...fields,
              [val]: amountValues[val],
            }
          : fields
      }, {}),

      // include totals values
      income: sum.income + income,
      refIncome: sum.refIncome + refIncome,
      expense: sum.expense + expense,
      refExpense: sum.refExpense + refExpense,
    }
  }
}

export function groupTotals(
  totals: Array<AmountValues>,
  groupBy: Array<string> = [],
  groupByIndex: number = 0,
): Totals {
  const initValues: Totals = {
    income: 0,
    refIncome: 0,
    expense: 0,
    refExpense: 0,
  }

  // return only values when no group by provided
  if (_isEmpty(groupBy)) {
    console.log("totals: ", totals)

    return totals.reduce(reduceAmounts(), initValues)
  }

  const group = _groupBy(totals, groupBy[groupByIndex])
  let obj: any = {}
  Object.keys(group).forEach((key) => {
    if (groupBy[groupByIndex] !== undefined) {
      obj[key] = groupTotals(group[key], groupBy, groupByIndex + 1)
    } else {
      obj = group[key].reduce(reduceAmounts(groupBy), initValues)
    }
  })

  return obj
}

export function convertDateAttributesToStrings(dateAttribute: string) {
  return (value: AmountValues): AmountValues => {
    return {
      ...value,
      [dateAttribute]: moment(value[dateAttribute]).format(),
    }
  }
}

export function composeBalance(
  account: Account,
  cashFlow: number,
  forceBalanceDisplayType?: BalanceDisplayType,
): number {
  const shouldNegate =
    isCreditCard(account) && isConnected(account) && !isProviderCreditReverse(account)

  const shouldAbsolute = isCreditCard(account) && isConnected(account)

  const balance = (account.initAmount || 0) + cashFlow

  return (
    (shouldNegate ? -1 : 1) * (shouldAbsolute ? Math.abs(balance) : balance) +
    calcCreditCardLimitValue(account, forceBalanceDisplayType)
  )
}

export function getDateRangeForAllDatasets(data: Totals, period: Period): Period {
  if (Interval.ALL_THE_TIME === period.interval) {
    return Object.values(data).reduce(
      (acc: Period, value: Totals) => {
        const dateRange = getDateRange(value, period)

        return {
          ...period,
          start: moment.min([acc.start, dateRange.start]),
          end: moment.max([acc.end, dateRange.end]),
        }
      },
      { start: moment(), end: moment() },
    )
  }

  return period
}

export function getDateRange(byDateData: Totals | HashMap<Totals>, period: Period): Period {
  if (Interval.ALL_THE_TIME === period.interval) {
    const dates = Object.keys(byDateData).map((date) => moment(date))
    return {
      ...period,
      start: moment.min(dates),
      end: moment.max(dates),
    }
  }

  return period
}

export function reduceTotalsByCategories(
  data: HashMap<HashMap<Totals>>,
  ids: (number | Id)[],
  attributeName: "envelopeId" | "superEnvelopeId" | "categoryId",
): Totals {
  const dataKeys = Object.keys(data)
  return dataKeys
    .filter((envelopeId) => {
      return attributeName !== "superEnvelopeId" || ids.includes(parseIntDecimal(envelopeId))
    })
    .reduce(
      (acc, superEnvelopeId) => {
        const envelopeTotals = Object.keys(data[superEnvelopeId]).reduce(
          (totalsAcc, envelopeId) => {
            return ids.includes(
              parseIntDecimal(data[superEnvelopeId][envelopeId][attributeName]),
            ) || ids.includes(data[superEnvelopeId][envelopeId][attributeName])
              ? sumTotals(totalsAcc, data[superEnvelopeId][envelopeId])
              : totalsAcc
          },
          { refExpense: 0, refIncome: 0, expense: 0, income: 0 },
        )

        return sumTotals(acc, envelopeTotals)
      },
      { refExpense: 0, refIncome: 0, expense: 0, income: 0 },
    )
}

export function sumTotals(...totals: Totals[]): Totals {
  return totals.filter(Boolean).reduce(
    (acc, value) => {
      const { refExpense, refIncome, income, expense } = value
      return {
        refIncome: acc.refIncome + (refIncome || 0),
        refExpense: acc.refExpense + (refExpense || 0),
        income: acc.income + (income || 0),
        expense: acc.expense + (expense || 0),
      }
    },
    { refExpense: 0, refIncome: 0, expense: 0, income: 0 },
  )
}

export function calculateOperatingRevenue(data: HashMap<Totals>): number {
  const { refExpense, refIncome } = reduceTotalsByCategories(
    data,
    OPERATING_REVENUE_SUPER_ENVELOPE_IDS,
    "superEnvelopeId",
  )
  return refIncome - refExpense
}

export function calculateOperatingCosts(data: HashMap<Totals>): number {
  const { refExpense, refIncome } = reduceTotalsByCategories(
    data,
    OPERATING_COSTS_SUPER_ENVELOPE_IDS,
    "superEnvelopeId",
  )
  return refIncome - refExpense
}

export function calculateSalesRevenue(data: HashMap<Totals>): number {
  const { refExpense, refIncome } = reduceTotalsByCategories(
    data,
    [SALES_REVENUE_ID],
    "superEnvelopeId",
  )
  return refIncome - refExpense
}

export function calculateOtherRevenue(data: HashMap<Totals>): number {
  const { refExpense, refIncome } = reduceTotalsByCategories(
    data,
    [OTHER_REVENUE_ID],
    "superEnvelopeId",
  )
  return refIncome - refExpense
}

export function calculateFixedCosts(data: HashMap<Totals>): number {
  const { refExpense, refIncome } = reduceTotalsByCategories(
    data,
    FIXED_COSTS_SUPER_ENVELOPE_IDS,
    "superEnvelopeId",
  )
  return refIncome - refExpense
}

export function calculateVariableCosts(data: HashMap<Totals>): number {
  const { refExpense, refIncome } = reduceTotalsByCategories(
    data,
    VARIABLE_COSTS_SUPER_ENVELOPE_IDS,
    "superEnvelopeId",
  )
  return refIncome - refExpense
}

export function calculateNewFunding(data: HashMap<Totals>): number {
  const { refExpense, refIncome } = reduceTotalsByCategories(
    data,
    [NEW_FUNDING_ID],
    "superEnvelopeId",
  )
  return refIncome - refExpense
}

export function calculateLoansRepayment(data: HashMap<Totals>): number {
  const { refExpense, refIncome } = reduceTotalsByCategories(
    data,
    [LOANS_REPAYMENT_ID],
    "superEnvelopeId",
  )
  return refIncome - refExpense
}

export function calculateInvestments(data: HashMap<Totals>): number {
  const { refExpense, refIncome } = reduceTotalsByCategories(
    data,
    INVESTMENTS_SUPER_ENVELOPE_IDS,
    "superEnvelopeId",
  )
  return refIncome - refExpense
}

export function calculateCashTransfers(data: HashMap<Totals>): number {
  const { refExpense, refIncome } = reduceTotalsByCategories(
    data,
    [SYSTEM_CATEGORIES_TRANSFER_ID],
    "envelopeId",
  )
  return refIncome - refExpense
}

export function calculateUnknown(data: HashMap<Totals>): number {
  const { refExpense, refIncome } = reduceTotalsByCategories(
    data,
    [SYSTEM_CATEGORIES_UNKNOWN_ID],
    "envelopeId",
  )
  return refIncome - refExpense
}

export function calculateOtherCashInflow(data: HashMap<Totals>): number {
  const { refExpense, refIncome } = reduceTotalsByCategories(
    data,
    OTHER_REVENUE_SUPER_ENVELOPE_IDS,
    "superEnvelopeId",
  )

  const transferCashFlow = calculateCashTransfers(data)

  const { refIncome: unknownRefIncome } = reduceTotalsByCategories(
    data,
    [SYSTEM_CATEGORIES_UNKNOWN_ID],
    "envelopeId",
  )

  return refIncome - refExpense + (transferCashFlow > 0 ? transferCashFlow : 0) + unknownRefIncome
}

export function calculateOtherCashOutFlow(data: HashMap<Totals>): number {
  const { refExpense, refIncome } = reduceTotalsByCategories(
    data,
    OTHER_CASH_OUTFLOW_SUPER_ENVELOPE_IDS,
    "superEnvelopeId",
  )

  const transferCashFlow = calculateCashTransfers(data)

  const { refExpense: unknownRefExpense } = reduceTotalsByCategories(
    data,
    [SYSTEM_CATEGORIES_UNKNOWN_ID],
    "envelopeId",
  )

  return refIncome - refExpense + (transferCashFlow < 0 ? transferCashFlow : 0) - unknownRefExpense
}

export function reduceOtherCostsTotals(data: HashMap<Totals>): Totals {
  const otherCosts = reduceTotalsByCategories(
    data,
    OTHER_CASH_OUTFLOW_SUPER_ENVELOPE_IDS,
    "superEnvelopeId",
  )
  const transfers = reduceTotalsByCategories(data, [SYSTEM_CATEGORIES_TRANSFER_ID], "envelopeId")
  const unknown = reduceTotalsByCategories(data, [SYSTEM_CATEGORIES_UNKNOWN_ID], "envelopeId")

  const transfersCashFlow = transfers.refIncome - transfers.refExpense
  const unknownCosts = {
    ...unknown,
    refIncome: 0,
    income: 0,
  }

  return sumTotals(otherCosts, transfersCashFlow < 0 ? transfers : undefined, unknownCosts)
}

export function reduceOtherRevenuesTotals(data: HashMap<Totals>): Totals {
  const otherRevenues = reduceTotalsByCategories(
    data,
    OTHER_REVENUE_SUPER_ENVELOPE_IDS,
    "superEnvelopeId",
  )
  const transfers = reduceTotalsByCategories(data, [SYSTEM_CATEGORIES_TRANSFER_ID], "envelopeId")
  const unknown = reduceTotalsByCategories(data, [SYSTEM_CATEGORIES_UNKNOWN_ID], "envelopeId")

  const transfersCashFlow = transfers.refIncome - transfers.refExpense
  const unknownRevenues = {
    ...unknown,
    refExpense: 0,
    expense: 0,
  }

  return sumTotals(otherRevenues, transfersCashFlow > 0 ? transfers : undefined, unknownRevenues)
}

export function convertChartLabels(datasets: ChartData[]) {
  return datasets && datasets[0]
    ? datasets[0].data.map((_d, index): string[] => {
        return datasets.map((input) => (input.data[index] ? input.data[index].x : undefined))
      })
    : []
}
