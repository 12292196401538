export enum Method {
  GOOGLE_PLAY = 0,
  APPLE_STORE = 1,
  PAYPAL = 2,
  PAYMILL = 3,
  CREDIT_CARD = 4,
}

export interface BraintreePaymentMethod {
  type: Method

  // credit card values
  creditCardType?: CreditCardType
  maskedNumber?: string
  expirationDate?: string // MM/YY
  isExpired?: boolean

  // PayPal values
  payPalEmail?: string
}

export enum CreditCardType {
  VISA = 0,
  MASTER_CARD = 1,
  AMEX = 2,
  CARTE_BLANCHE = 3,
  CHINA_UNION_PAY = 4,
  DINERS_CLUB_INTERNATIONAL = 5,
  DISCOVER = 6,
  JCB = 7,
  LASER = 8,
  UK_MAESTRO = 9,
  MAESTRO = 10,
  SOLO = 11,
  SWITCH = 12,
  UNKNOWN = 13,
}
