import { isAppBoard } from 'src/common/environment'
import styles from 'src/frontend/scenes/accounts/accountDetail/components/AccountDetailHeader.module.less'
import FormattedMessage from 'src/frontend/modules/intl/components/FormattedMessage'
import { isRevoked } from 'src/backend/accounts/helpers'
import React from 'react'
import { Account } from 'src/types/Account'
import { RestrictedProviders } from 'src/frontend/scenes/integrations/restrictedProviders/types'
import RestrictedProvider = RestrictedProviders.RestrictedProvider
import { isUndefinedOrNull } from 'src/common/utils'
import { formatDate } from 'src/frontend/modules/intl'

const REVOKE_GUIDE_URL = isAppBoard()
  ? 'https://support.budgetbakers.com/hc/en-us/articles/360008212460-Why-does-my-bank-need-re-connection-'
  : 'https://support.budgetbakers.com/hc/en-us/articles/360000249793-Why-does-my-bank-need-re-connection-'

interface Props {
  account: Account
  restrictedProviderObject: RestrictedProvider
}

export default function BankRestrictionColumn({ account, restrictedProviderObject }: Props) {

  if (!isUndefinedOrNull(restrictedProviderObject)) {
    return (
      <div className={styles.noticeColumn}>
        <h3>
          {getTitle(restrictedProviderObject, account)}
        </h3>
        <div className={styles.description}>
          {getMessage(restrictedProviderObject)}
        </div>
        {!isUndefinedOrNull(restrictedProviderObject.knowledgeBaseUrl) && (
          <a className={styles.link} href={restrictedProviderObject.knowledgeBaseUrl} target="_blank">
            <FormattedMessage id="integrations.restrictions.read-more" />
          </a>
        )}
      </div>
    )

  } else if (isRevoked(account)) {
    return (
      <div className={styles.noticeColumn}>
        <h3>
          <FormattedMessage
            id="integrations.consent.provider-reconnect-needed"
            values={{ providerName: account.reservedIntegrationConnection.remoteProviderName }}
          />
        </h3>
        <div className={styles.description}>
          <FormattedMessage id="integrations.consent.provider-reconnect-needed.text" />
        </div>
        <a className={styles.link} href={REVOKE_GUIDE_URL} target="_blank">
          <FormattedMessage id="integrations.consent.provider-reconnect-needed.help-link" />
        </a>
      </div>
    )
  }

  return null
}

function getTitle(restrictedProvider: RestrictedProvider, account: Account): React.ReactNode {
  switch (restrictedProvider.status) {
    case RestrictedProviders.ProviderStatus.TemporaryDisabled:
      return (
        <FormattedMessage
          id="integrations.restrictions.provider-unavailable"
          values={{ providerName: account.reservedIntegrationConnection.remoteProviderName }}
        />
      )

    case RestrictedProviders.ProviderStatus.Disabled:
      return (
        <FormattedMessage
          id="integrations.restrictions.provider-disabled"
          values={{ providerName: account.reservedIntegrationConnection.remoteProviderName }}
        />
      )

    case RestrictedProviders.ProviderStatus.Active:
      return (
        <FormattedMessage
          id="integrations.restrictions.provider-needs-attention"
          values={{ providerName: account.reservedIntegrationConnection.remoteProviderName }}
        />
      )
  }
}

function getMessage(restrictedProvider: RestrictedProvider): React.ReactNode {
  switch (restrictedProvider.status) {
    case RestrictedProviders.ProviderStatus.TemporaryDisabled:
      return (
        <FormattedMessage
          id="integrations.restrictions.temporary-unavailable-text"
          values={{
            date: !isUndefinedOrNull(restrictedProvider.validUntil) && (
              <strong>
                {formatDate(new Date(restrictedProvider.validUntil))}
              </strong>
            ),
          }}
        />
      )

    case RestrictedProviders.ProviderStatus.Disabled:
      return (
        <FormattedMessage
          id="integrations.restrictions.temporary-disabled-text"
        />
      )

    case RestrictedProviders.ProviderStatus.Active:
      return (
        <FormattedMessage
          id="integrations.restrictions.provider-needs-attention-text"
        />
      )
  }
}
