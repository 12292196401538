/* eslint-disable react/prop-types */
import React from 'react'
import { connect } from 'react-redux'
import { selectWaitingImportItems } from '../selectors'
import WaitingImportItem from './WaitingImportItem'

const mapStateToProps = state => ({ waitingItems: selectWaitingImportItems(state) })

const WaitingImportItemList = ({ waitingItems }) => {
  return (waitingItems.length > 0) && (
  <ul>
    {waitingItems.map((item) => (<WaitingImportItem key={item.itemId} item={item} />))}
  </ul>
  )
}

export default connect(mapStateToProps)(WaitingImportItemList)
