import { FilterState } from './types'
import * as actions from './actions'
import { Action } from 'src/types/common'

const initialFilterState: FilterState = {
  filter: null,
  initialFilter: null,
  selectedFilterId: null,
  loading: false,
  removeLoading: false,
  filterFormOpen: false,
  errors: {},
  display: {
    period: true,
    fulltext: true,
    accountIds: true,
    categories: false,
    labelIds: false,
    currencyId: false,
    recordTypes: false,
    amountRange: false,
    paymentType: false,
    recordState: false,
    contactIds: false,
  },
}

export default function filter(state: FilterState = initialFilterState, action: Action) {
  switch (action.type) {
    case actions.SET_FILTER_VALUES: {
      return { ...state, filter: { ...state.filter, ...action.payload } }
    }
    case actions.TOGGLE_DISPLAY_FILTER: {
      return { ...state, display: { ...state.display, [action.payload]: !state.display[action.payload] } }
    }
    case actions.SET_INITIAL_FILTER: {
      return { ...state, filter: action.payload.filter, initialFilter: action.payload.filter, selectedFilterId: null }
    }
    case actions.SET_SELECTED_FILTER: {
      const newFilter = { period: state.filter && state.filter.period, ...action.payload.filter }
      return {
        ...state,
        selectedFilterId: action.payload.filterId,
        filter: newFilter,
        initialFilter: newFilter,
      }
    }
    case actions.SAVE_FILTER_START: {
      return {
        ...state,
        loading: true,
      }
    }
    case actions.SAVE_FILTER_SUCCESS: {
      return {
        ...state,
        loading: false,
        selectedFilterId: action.payload.filterId,
        initialFilter: { period: state.filter && state.filter.period, ...action.payload.filter },
        filterFormOpen: false,
        errors: {},
      }
    }
    case actions.SAVE_FILTER_ERROR: {
      return {
        ...state,
        loading: false,
        errors: action.payload,
      }
    }
    case actions.REMOVE_FILTER_START: {
      return {
        ...state,
        removeLoading: true,
      }
    }
    case actions.REMOVE_FILTER_ERROR:
    case actions.REMOVE_FILTER_SUCCESS: {
      return {
        ...state,
        removeLoading: false,
      }
    }
    case actions.FILTER_FORM_OPEN: {
      return { ...state, filterFormOpen: true }
    }
    case actions.FILTER_FORM_CLOSE: {
      return { ...state, filterFormOpen: false, errors: {} }
    }
    default:
      return state
  }
}
