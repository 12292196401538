import { Billing } from 'src/types/Billing'
import PlanType = Billing.PlanType

export enum Feature{
  MAX_ACCOUNTS_COUNT,
  CUSTOM_LABELS_COLOR,
  INTEGRATIONS,
  AUTOMATIC_RULES,
}

export const getBillingFeatures = () => ({
  [PlanType.BASIC]: {
    [Feature.MAX_ACCOUNTS_COUNT]: Infinity,
    [Feature.CUSTOM_LABELS_COLOR]: true,
    [Feature.INTEGRATIONS]: true,
    [Feature.AUTOMATIC_RULES]: true,
  },
  [PlanType.ADVANCED]: {
    [Feature.MAX_ACCOUNTS_COUNT]: Infinity,
    [Feature.CUSTOM_LABELS_COLOR]: true,
    [Feature.INTEGRATIONS]: true,
    [Feature.AUTOMATIC_RULES]: true,
  },
  [PlanType.FREE]: {
    [Feature.MAX_ACCOUNTS_COUNT]: 3,
    [Feature.CUSTOM_LABELS_COLOR]: false,
    [Feature.INTEGRATIONS]: false,
    [Feature.AUTOMATIC_RULES]: false,
  },
  [PlanType.FREE_POST_TRIAL]: {
    [Feature.MAX_ACCOUNTS_COUNT]: 3,
    [Feature.CUSTOM_LABELS_COLOR]: false,
    [Feature.INTEGRATIONS]: false,
    [Feature.AUTOMATIC_RULES]: false,
  },
})
