import React from "react"
import { AccountFormType } from "src/frontend/scenes/settings/accounts/enums"
import AccountForm from "src/frontend/scenes/settings/accounts/components/AccountForm"
import { Redirect, RouteChildrenProps, withRouter } from "react-router"
import { connect } from "react-redux"
import { openConnectBankForm } from "src/frontend/scenes/settings/accounts/actions"
import AccountFormSelectionIcon from "src/frontend/scenes/settings/accounts/components/AccountFormSelectionIcon"
import styles from "src/frontend/scenes/settings/accounts/components/AccountFormContent.module.less"
import { isAppBoard } from "src/common/environment"

interface Props extends RouteChildrenProps {
  accountFormType: AccountFormType
  onAccountTypeChange: Function
  handleOpenBank: Function
}

const mapDispatchToProps = {
  handleOpenBank: openConnectBankForm,
}

function AccountFormContent({
  accountFormType,
  match,
  onAccountTypeChange,
  handleOpenBank,
}: Props) {
  switch (accountFormType) {
    case AccountFormType.MANUAL: {
      return <AccountForm />
    }

    case AccountFormType.IMPORTS: {
      return (
        <>
          {match && match.path !== "/imports" && <Redirect to="/imports" />}
          <AccountForm />
        </>
      )
    }

    default:
    case AccountFormType.SELECTION: {
      return (
        <div>
          <div className={styles.addAccountSelection}>
            <AccountFormSelectionIcon
              iconName="account-bank"
              titleString="settings.accounts.new.type.bank.title"
              description="settings.accounts.new.type.bank.text"
              onClick={() => handleOpenBank()}
            />

            <AccountFormSelectionIcon
              iconName="account-imports"
              titleString="settings.accounts.new.type.imports.title"
              description="settings.accounts.new.type.imports.text"
              onClick={() => onAccountTypeChange(AccountFormType.IMPORTS)}
            />

            <AccountFormSelectionIcon
              iconName="account-manual"
              titleString="settings.accounts.new.type.manual.title"
              description="settings.accounts.new.type.manual.text"
              onClick={() => onAccountTypeChange(AccountFormType.MANUAL)}
            />
            {!isAppBoard() && (
              <AccountFormSelectionIcon
                iconName="account-investments"
                titleString="settings.accounts.new.type.investments.title"
                description="settings.accounts.new.type.investments.text"
                badge="settings.accounts.new.type.investments.badge"
                disabled
              />
            )}
          </div>
        </div>
      )
    }
  }
}

export default withRouter(connect(null, mapDispatchToProps)(AccountFormContent))
