import { combineReducers } from 'redux'
import connections, { ExistingConnectionsState } from 'src/frontend/scenes/integrations/connections/reducer'
import newConnection, { NewConnectionState } from 'src/frontend/scenes/integrations/newConnection/reducer'
import providersList, { ProviderListState } from 'src/frontend/scenes/integrations/providersList/reducer'
import restrictedProviders, { RestrictedProvidersState } from 'src/frontend/scenes/integrations/restrictedProviders/reducer'


export interface IntegrationsState {
  newConnection: NewConnectionState
  connections: ExistingConnectionsState
  providersList: ProviderListState
  restrictedProviders: RestrictedProvidersState
}

export default combineReducers({ connections, newConnection, providersList, restrictedProviders })
