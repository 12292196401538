import { ChartType } from 'src/frontend/components/chart/ChartType'

const defaultOptions = {
  color: '#f3f3f3',
  axis: 'x',
}

const barBackgroundPlugin = {
  id: 'BarBackgroundPlugin',

  _hasData(data) {
    return data && data.datasets && data.datasets.length > 0
  },

  _findScale(chart, options) {
    const scales = Object.keys(chart.scales).map((d) => chart.scales[d])
    if (options.axis === 'category') {
      return scales.find((d) => d.type === 'hierarchical' || d.type === 'category')
    }
    return scales.find((d) => d.id.startsWith(options.axis))
  },

  _findVisibleDatasetMeta(chart) {
    const numberOfDatasets = chart.chart.config.data.datasets.length
    for (let i = 0; i < numberOfDatasets; i += 1) {
      if (chart.isDatasetVisible(i) && chart.chart.config.data.datasets[i].type === ChartType.BAR) {

        return chart.getDatasetMeta(i)
      }
    }

    return
  },

  _findDatasetValues(chart) {


    const visibleDataset = this._findVisibleDatasetMeta(chart)

    if (!visibleDataset) {
      return
    }

    return visibleDataset.data.map((metaData) => {
      return {
        x: metaData._model.x,
        width: metaData._model.width,
      }
    })
  },

  beforeDraw(chart, _easingValue, chartOptions) {
    const options = Object.assign({}, defaultOptions, chartOptions)

    const scale = this._findScale(chart, options)
    if (!this._hasData(chart.config.data) || !scale) {
      return
    }

    const values = this._findDatasetValues(chart)
    if (!values || values.length === 0) {
      return
    }
    const chartArea = chart.chartArea


    // push the current canvas state onto the stack
    const ctx = chart.ctx
    ctx.save()

    // set background color
    ctx.fillStyle = options.color


    const chartHeight = chartArea.bottom - chartArea.top

    values.forEach((value) => {
      ctx.fillRect(value.x - value.width / 2, chartArea.top, value.width, chartHeight)
    })


    // restore the saved state
    ctx.restore()
  },
}

// if the environment is neither amd nor commonjs, register the plugin globally for the samples and tests
export default barBackgroundPlugin
