import { RecordIntegrationProviderSource } from 'src/backend/enums'
import { Rate } from 'static/proto/forex_pb'
import {
  buildFetchOptions,
  fetchWithRetry,
  validateSuccessResponse,
} from 'src/backend/rest/helpers'
import {
  ConsentChangeRequest,
  GetUserImportsResponse,
  ImportAllowedDelimiters,
  ImportError,
  ImportParserResponse,
  ImportParserResultResponse,
  User as UserProto,
} from 'static/proto/ribeez_pb'
import {
  buildImportActivateRequest,
  buildImportActivationAutomaticRequest,
  buildImportDeactivationAutomaticRequest,
  buildImportParseStatementRequest, buildImpressionRequest,
  buildIntegrationDuplicityRequest, buildVerifiedProfileRequest,
  deserializeProtobuffResponse,
  handleCaughtError,
  validateProtobuffResponse,
} from 'src/backend/rest/ribeezHelpers'
import {
  getAppFlavor,
  getBackendUrl,
  getDocsUrl,
} from 'src/common/environment'
import { Id } from 'src/types/CouchDb'
import { ReferralSource, User, VerifiedProfile } from 'src/types/User'
import { Tracking } from 'src/frontend/scenes/kb/components/types'
import Impression = Tracking.Impression


const defaultGetOptions = buildFetchOptions('GET')
const defaultPostOptions = buildFetchOptions('POST')
const defaultDeleteOptions = buildFetchOptions('DELETE')

/** * user api ** */

export function getUserInfo(): Promise<User> {
  const url = `${getBackendUrl()}/ribeez/user/abc`
  return fetchWithRetry(url, defaultGetOptions)
    .then(validateSuccessResponse)
    .then(deserializeProtobuffResponse(UserProto))
    .catch(handleCaughtError)
}


export function setReferralSource(referralSource: ReferralSource) {
  const url = `${getBackendUrl()}/ribeez/user/referralSource/${referralSource}`
  return fetchWithRetry(url, defaultPostOptions)
    .then(validateSuccessResponse)
    .catch(handleCaughtError)
}

export function savePersonalDetails(formValues: VerifiedProfile) {
  const url = `${getBackendUrl()}/ribeez/user/verifiedProfile`
  const body = buildVerifiedProfileRequest(formValues)

  return fetchWithRetry(url, { ...defaultPostOptions, body })
    .then(validateSuccessResponse)
    .catch(handleCaughtError)
}

export function trackImpression(impression: Impression) {
  const url = `${getBackendUrl()}/ribeez/track/impression`
  const body = buildImpressionRequest(impression)

  return fetchWithRetry(url, { ...defaultPostOptions, body })
    .then(validateSuccessResponse)
    .catch(handleCaughtError)
}

export const grantGeneralToSConsent = changeConsentRequest('policy')

export const changeEmailingConsent = changeConsentRequest('emailing')

export const changeBankSynchronizationConsent = changeConsentRequest('bankSync')

function changeConsentRequest(endpoint) {
  return (value = true) => {
    const body = ConsentChangeRequest.encode({ consentGranted: value }).finish()
    const url = `${getBackendUrl()}/ribeez/user/consent/${endpoint}`
    return fetchWithRetry(url, { ...defaultPostOptions, body })
      .then(validateSuccessResponse)
  }
}

export function deleteUser() {
  const url = `${getBackendUrl()}/ribeez/user`
  return fetchWithRetry(url, defaultDeleteOptions)
    .then(validateSuccessResponse)
}

export function deleteUserData() {
    const url = `${getBackendUrl()}/ribeez/user-new-db`
    const body = '{}'
    return fetchWithRetry(url, { ...defaultPostOptions, body }).then(validateSuccessResponse)
}

/** * end of user api ** */

export function getExchangeRate(currencyFromCode, currencyToCode) {
  const options: RequestInit = {
    method: 'GET',
    headers: {
      Accept: 'application/json, text/plain, text/html, */*',
      flavor: getAppFlavor(),
    } as unknown as HeadersInit,
    credentials: 'include',
  }
  return fetchWithRetry(`${getBackendUrl()}/forex/v1/rate/${currencyFromCode}/${currencyToCode}`, options)
    .then(validateSuccessResponse)
    .then(deserializeProtobuffResponse(Rate))
    .then(exchangeRate => exchangeRate.rate)
    .catch(handleCaughtError)
}

export function logoutUser() {
  const url = `${getBackendUrl()}/auth/logout`
  return fetchWithRetry(url, { ...defaultGetOptions, redirect: 'manual' })
}

/** * imports api ** */

export const getUserImports = () => {
  const url = `${getBackendUrl()}/ribeez/import/v1/all`
  return fetchWithRetry(url, defaultGetOptions)
    .then(validateSuccessResponse)
    .then(deserializeProtobuffResponse(GetUserImportsResponse))
    .then(userImportsResponse => userImportsResponse.items || [])
    .catch(handleCaughtError)
}

/**
 * Get initial mapping of imported file columns to Wallet attributes,
 * parse result (guessed values, date format, delimiter, ...) and result preview.
 * @param importItemId
 */
export const getInitialMapping = importItemId => {
  const url = `${getBackendUrl()}/ribeez/import/v1/item/${importItemId}/init-mapping-web`
  return fetchWithRetry(url, defaultGetOptions)
    .then(validateProtobuffResponse(ImportAllowedDelimiters))
    .then(deserializeProtobuffResponse(ImportParserResponse))
    .catch(handleCaughtError)
}

/**
 * Required for validation when row changes and to get updated rows preview
 * @param importItemId
 * @param settings
 * @param importMappingAttributes
 * @returns {*}
 */
export const validateCustomMapping = (importItemId, settings, importMappingAttributes) => {
  const body = buildImportParseStatementRequest(settings, importMappingAttributes)
  const url = `${getBackendUrl()}/ribeez/import/v1/item/${importItemId}/mapping`

  return fetchWithRetry(url, { ...defaultPostOptions, body })
    .then(validateProtobuffResponse(ImportError))
    .then(deserializeProtobuffResponse(ImportParserResponse))
    .catch(handleCaughtError)
}

/**
 * Save updated mappings and fire import item. Validation is part of saving.
 * @param importItemId
 * @param settings
 * @param importMappingAttributes
 * @returns {*}
 */
export const saveCustomMapping = (importItemId, settings, importMappingAttributes) => {
  const body = buildImportParseStatementRequest(settings, importMappingAttributes)
  const url = `${getBackendUrl()}/ribeez/import/v1/item/${importItemId}/records`
  return fetchWithRetry(url, { ...defaultPostOptions, body })
    .then(validateProtobuffResponse(ImportError))
    .then(deserializeProtobuffResponse(ImportParserResultResponse))
    .catch(handleCaughtError)
}

export const activateAutomaticImport = importsSettingsId => {
  const body = buildImportActivationAutomaticRequest(importsSettingsId)
  const url = `${getBackendUrl()}/ribeez/import/v1/settings/automaticActivation`
  return fetchWithRetry(url, { ...defaultPostOptions, body })
    .then(validateSuccessResponse)
}

export const deactivateAutomaticImport = importsSettingsId => {
  const body = buildImportDeactivationAutomaticRequest(importsSettingsId)
  const url = `${getBackendUrl()}/ribeez/import/v1/settings/automaticDeactivation`
  return fetchWithRetry(url, { ...defaultPostOptions, body })
    .then(validateSuccessResponse)
}

/**
 * Delete import settings which effectively deactivates imports on the account,
 * deletes all imported files but keeps imported records.
 * @param settingsId
 * @returns {Promise}
 */
export const deleteImportSettings = settingsId => {
  const api = `${getBackendUrl()}/ribeez/import/v1/delete/settings/${settingsId}`
  return fetchWithRetry(api, defaultDeleteOptions)
    .then(validateSuccessResponse)
}

export const deleteImportItem = itemId => {
  const api = `${getBackendUrl()}/ribeez/import/v1/delete/item/${itemId}`
  return fetchWithRetry(api, defaultDeleteOptions)
    .then(validateSuccessResponse)
}

export const uploadImportFile = (fileName, fileContent, userId, uploadEmail) => {
  const fileNameHeader = { 'X-Filename': fileName }
  const userIdHeader = { 'X-UserId': userId }
  const corsHeader = { "Access-Control-Allow-Origin": "*" }
  const options: RequestInit = {
    ...defaultPostOptions,
    body: fileContent,
    headers: { ...defaultPostOptions.headers, ...fileNameHeader, ...userIdHeader, ...corsHeader },
  }
  const url = `${getDocsUrl()}/upload/import-web/${uploadEmail}`
  return fetchWithRetry(url, options)
    .then(validateSuccessResponse)
}

/**
 * API call to activate imports on specified Wallet account
 * @param accountId
 * @returns {string} import e-mail
 */
export const activateImportAccount = accountId => {
  const body = buildImportActivateRequest(accountId)
  const url = `${getBackendUrl()}/ribeez/import/v1/activate`
  return fetchWithRetry(url, { ...defaultPostOptions, body })
    .then(validateSuccessResponse)
    .then(response => response.text())
}

/** * end of imports api ** */


/** * integrations api ** */

export function requestIntegrationDuplicity(
  remoteTransactionIds: Id[],
  userId: Id,
  loginId: Id,
  source: RecordIntegrationProviderSource,
): Promise<string> {
  const body = buildIntegrationDuplicityRequest(remoteTransactionIds)
  const url = `${getBackendUrl()}/ribeez/integration/v1/owner/${userId}/source/${source}/login/${loginId}/transactions/duplicate`
  return fetchWithRetry(url, { ...defaultPostOptions, body })
    .then(validateSuccessResponse)
    .then(response => response.text())
}

/** * end of imports api ** */
