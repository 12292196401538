import { AbstractWidget, PrimitiveWidget, WidgetData, WidgetType } from 'src/frontend/scenes/dashboard/types'
import { WidgetId } from 'src/backend/dashboard/enums'
import { v4 as uuid } from 'uuid'
import { DefaultWidgetFilter, DefaultWidgetOptions } from 'src/frontend/scenes/dashboard/constants'
import _isEmpty from 'lodash/isEmpty'
import { intervalTypeToPeriod } from 'src/backend/time/time'
import { pipe } from 'ramda'
import { RelativeIntervalType } from 'src/backend/enums'

export function createRawWidget(primitiveWidget: PrimitiveWidget): AbstractWidget {
  const { widgetId, variant } = primitiveWidget
  const id = uuid()
  return {
    widgetId,
    variant,
    id,
  }
}

export function createWidgetWithData(widgetId: WidgetId, data?: WidgetData, variant?: number) {
  const widget = pipe(
    addDefaultWidgetOptions, addDefaultWidgetPeriod,
  )(createRawWidget({ widgetId, variant }))

  return {
    ...widget,
    data,
    filter: {},
    filterId: null,
  }
}

export function addDefaultWidgetOptions(widget: AbstractWidget) {
  const commonOptions = {
    relativeIntervalValue: RelativeIntervalType.LAST,
  }

  if (widget.widgetId in DefaultWidgetOptions) {
    return {
      ...widget,
      options: {
        ...widget.options,
        ...commonOptions,
        ...DefaultWidgetOptions[widget.widgetId],
      },
    }
  }

  return {
    ...widget,
    options: { ...widget.options, ...commonOptions },
  }
}

export function addDefaultWidgetPeriod(widget: AbstractWidget) {
  if (
    widget.widgetId in DefaultWidgetFilter
    && DefaultWidgetFilter[widget.widgetId].period
    && DefaultWidgetFilter[widget.widgetId].period.interval
  ) {

    const { period: { interval } } = DefaultWidgetFilter[widget.widgetId]
    const { start, end } = intervalTypeToPeriod(interval)
    const period = { start, end, interval }

    return {
      ...widget,
      filter: { period },
    }
  }

  return widget
}

export function convertWidgetToAbstractWidget(widget: WidgetType): AbstractWidget {
  const { id, widgetId, variant } = widget
  return {
    id,
    widgetId,
    variant,
  }
}

export function removeEmptyTrailingColumns(widgetColumns: AbstractWidget[][]) {
  return widgetColumns.reduceRight((acc, value) => {
    if (_isEmpty(acc) && _isEmpty(value)) {
      return acc
    }
    return [value, ...acc]
  }, [])
}
