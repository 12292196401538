import { Currency } from 'src/types/Currency'

export function adjustAmount(currencyA: Currency, currencyB: Currency, amountA: number, amountB: number): number {
  if (currencyA && currencyB) {
    if (currencyA._id === currencyB._id) {
      // in this case both amount should be same, but just in case of data inconsistency
      return amountB || amountA
    }

    return amountB * currencyA.ratioToReferential / currencyB.ratioToReferential
  }

  return amountA
}
