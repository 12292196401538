import * as timeUtils from "src/backend/time/time"
import { IntervalGranularityType } from "src/backend/enums"
import { Totals, AnalyticsData, InvestmentData } from "src/backend/analytics/types"
import { Period } from "src/types/Filter"
import moment from "moment"
import { ChartType } from "src/frontend/components/chart/ChartType"
import { convertData } from "src/frontend/scenes/analytics/helpers"
import { DatasetItem } from "src/frontend/components/chart/types"
import { HashMap } from "src/types/common"

const SUPPORTED_INTERVAL_GRANULARITY: Array<IntervalGranularityType> = [
  IntervalGranularityType.DAY,
  IntervalGranularityType.WEEK,
  IntervalGranularityType.MONTH,
  IntervalGranularityType.ALL,
]

export function prepareLinearChartData(
  datasets: Array<DatasetItem>,
  period: Period,
  granularity: IntervalGranularityType,
  onlyPositive?: boolean,
) {
  const labels = timeUtils.generateDateStrings(period, granularity)

  return {
    labels,
    datasets: convertData(ChartType.LINE, datasets, onlyPositive),
  }
}

export function prepareChartData(
  originalData: Totals | HashMap<Totals>,
  dates: Array<string>,
  initialBalance: number,
  granularity: IntervalGranularityType,
  customAggregator?: (data: HashMap<Totals>) => Totals,
  investmentsData?: InvestmentData,
): Array<AnalyticsData> {
  if (granularity === IntervalGranularityType.ALL) {
    let totalInvestmentsValue = 0

    if (investmentsData) {
      const { portfolioValueByDates, initialAssetCashFlow, assetCashFlowsByDates } = investmentsData
      const totalPortfolioValue = portfolioValueByDates[dates[0]]?.value * 100 ?? 0
      const totalAssetCashFlow = assetCashFlowsByDates[dates[0]]?.value ?? 0

      totalInvestmentsValue += totalPortfolioValue + totalAssetCashFlow + initialAssetCashFlow

      console.log("totalInvestmentsValue", totalInvestmentsValue)
    }

    const data = customAggregator
      ? customAggregator(originalData as HashMap<Totals>)
      : (originalData as Totals)

    const income = (data && data.refIncome) || 0
    const expense = (data && data.refExpense) || 0
    const cashFlow: number = income - expense
    const balance: number = initialBalance + cashFlow + totalInvestmentsValue
    const date = dates[0]
    return [{ ...data, cashFlow, balance, income, expense, date }]
  } else if (SUPPORTED_INTERVAL_GRANULARITY.includes(granularity)) {
    let balance: number = investmentsData?.initialAssetCashFlow
      ? initialBalance + investmentsData.initialAssetCashFlow
      : initialBalance

    let cumulativeExpense: number = 0
    let cumulativeIncome: number = 0
    let cumulativeCashFlow: number = 0

    return dates.map((date: string): AnalyticsData => {
      const dataForGivenDate: AnalyticsData = getDataForDate(originalData, date, customAggregator)
      const portfolioValueForDate = investmentsData?.portfolioValueByDates[date]
      const { value: assetCashFlowForDate } = investmentsData?.assetCashFlowsByDates[date] ?? {
        value: 0,
      }

      const cashFlow: number = dataForGivenDate.refIncome - dataForGivenDate.refExpense

      if (moment(date).isAfter(moment())) {
        balance = null
        cumulativeIncome = null
        cumulativeExpense = null
        cumulativeCashFlow = null
      } else {
        balance += cashFlow + assetCashFlowForDate
        cumulativeIncome += dataForGivenDate.refIncome
        cumulativeExpense += dataForGivenDate.refExpense
        cumulativeCashFlow += cashFlow
      }

      return {
        ...dataForGivenDate,
        // FIXme hotfixed attribute value. Change ChartSubjectType to have separate i18n id and stats value
        income: dataForGivenDate.refIncome,
        expense: dataForGivenDate.refExpense,
        cashFlow,
        cumulativeIncome,
        cumulativeExpense,
        cumulativeCashFlow,
        balance: moment(date).isAfter(moment())
          ? null
          : balance + (portfolioValueForDate ? portfolioValueForDate.value * 100 : 0),
        date,
      }
    })
  } else {
    throw new Error(`Unsupported interval granularity type: ${granularity}`)
  }
}

function getDataForDate(data, date, customAggregator) {
  if (data && data[date]) {
    return customAggregator ? customAggregator(data[date]) : data[date]
  }
  return { income: 0, expense: 0, refIncome: 0, refExpense: 0 }
}
