import React, { useState } from 'react'
import { Modal } from 'semantic-ui-react'
import { FormattedMessage } from 'src/frontend/modules/intl'
import ChangePasswordFormContainer from 'src/frontend/scenes/auth/changePassword/ChangePasswordFormContainer'
import { PrimaryButton } from 'src/frontend/components/Buttons/Buttons'

export default function ChangePasswordModal() {
  const [open, setOpen] = useState(false)

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  return (
    <Modal
      size="small"
      open={open}
      onClose={handleClose}
      trigger={(
        <PrimaryButton onClick={handleOpen}>
          <FormattedMessage id="settings.general.change_password" />
        </PrimaryButton>
      )}
    >
      <Modal.Header>
        <FormattedMessage id="settings.general.change_password" />
        <span className="modal-close" onClick={handleClose} />
      </Modal.Header>
      <Modal.Content>
        <ChangePasswordFormContainer onSuccess={handleClose} onCancel={handleClose} />
      </Modal.Content>
    </Modal>
  )
}
