/* eslint-disable react/prop-types */
import React from "react"
import { connect } from "react-redux"
import "./Currencies.less"
import { Dropdown } from "semantic-ui-react"
import { SortableElement } from "react-sortable-hoc"
import DragHandle from "src/frontend/scenes/settings/components/DragHandle"
import SortableList from "src/frontend/scenes/settings/components/SortableList"
import RemoveCurrencyForm from "./RemoveCurrencyForm"
import CurrencyForm from "./CurrencyForm"
import { FormattedMessage, formatMessage, FormattedMoney } from "src/frontend/modules/intl"
import * as currenciesSelectors from "src/frontend/modules/currencies/selectors"
import * as selectors from "../selectors"
import { selectUser } from "src/frontend/modules/user/selectors"
import * as actions from "../actions"
import { getUnusedCurrencies } from "src/backend/currencies/currencies"
import { FormType } from "src/frontend/scenes/settings/enums"
import { PrimaryButton } from "src/frontend/components/Buttons/Buttons"

const mapStateToProps = (state) => {
  const form = selectors.selectCurrenciesForm(state)
  return {
    currencies: currenciesSelectors.selectSortedCurrencies(state),
    referentialCurrency: currenciesSelectors.selectReferentialCurrency(state),
    formValues: form.formValues,
    formType: form.formType,
    user: selectUser(state),
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    handleChangePosition({ oldIndex, newIndex }) {
      dispatch(actions.reorderCurrencies(oldIndex, newIndex))
    },
    handleCodeChange(referentialCurrencyCode) {
      return (event, field) => {
        event.preventDefault()
        dispatch(actions.changeCurrencyCode(field.value, referentialCurrencyCode))
      }
    },
    handleOpenForm(event, formType, currency = null) {
      dispatch(actions.openForm(formType, currency))
    },
  }
}

const Currencies = (props) => {
  const {
    currencies,
    referentialCurrency,
    formType,
    formValues,
    handleCodeChange,
    handleChangePosition,
    handleOpenForm,
    user,
  } = props

  const addCurrencyDropdownValue = formType === FormType.ADD ? formValues.code : ""

  const currenciesOptions = getUnusedCurrencies(currencies).map((currency) => {
    return {
      key: `currencies-add-${currency.code}`,
      text: `${currency.code} - ${currency.name}`,
      value: currency.code,
    }
  })

  return (
    <div className="settings-currencies">
      <div className="settings-page">
        <div className="settings-page-add">
          <div className="title">
            <FormattedMessage id="settings.currencies.add_new" />
          </div>
          <div className="container">
            <DropdownWithAddButton
              name="code"
              options={currenciesOptions}
              value={addCurrencyDropdownValue || ""}
              placeholder={formatMessage("settings.currencies.form.code_placeholder")}
              onChange={handleCodeChange(referentialCurrency.code)}
              onClick={(e) => handleOpenForm(e, FormType.ADD)}
            />
            <CurrencyForm />
          </div>
        </div>
        <div className="settings-page-list">
          <div className="title">
            <FormattedMessage id="settings.currencies.your_currencies" />
          </div>
          <div className="currencies-row list-header">
            <div className="currencies-row-drag header" />
            <div className="currencies-row-code header" />
            <div className="currencies-row-name header">
              <FormattedMessage id="form.name" />
            </div>
            <div className="currencies-row-ratio header">
              <FormattedMessage id="settings.currencies.ratio" />
            </div>
            <div className="currencies-row-toolbar" />
          </div>
          <CurrenciesList
            items={currencies}
            referentialCurrency={referentialCurrency}
            user={user}
            handleReorder={handleChangePosition}
            handleOpenForm={handleOpenForm}
          />
        </div>
      </div>
    </div>
  )
}

const DropdownWithAddButton = ({ name, options, placeholder, value, onClick, onChange }) => (
  <div className="ui input">
    <div className="ui action input">
      <Dropdown
        name={name}
        className="action currency-select"
        placeholder={placeholder}
        value={value}
        search
        selection
        options={options}
        onChange={onChange}
      />
      <PrimaryButton
        type="submit"
        onClick={onClick}
      >
        ＋ &nbsp;
        {formatMessage("form.add")}
      </PrimaryButton>
    </div>
  </div>
)

const CurrenciesList = SortableList(({ items, referentialCurrency, handleOpenForm }) => (
  <ul>
    {items.map((currency, index) => (
      <CurrencyItem
        key={currency._id}
        index={index}
        currency={currency}
        referentialCurrency={referentialCurrency}
        onClick={handleOpenForm}
      />
    ))}
  </ul>
))

const CurrencyItem = SortableElement(({ currency, referentialCurrency, onClick }) => {
  const ratio = currency.referential ? (
    <FormattedMessage id="settings.currencies.referential" />
  ) : (
    <CurrencyRatio
      currency={currency}
      referentialCurrency={referentialCurrency}
    />
  )

  const toolbar = !currency.referential && (
    <div>
      <span onClick={(e) => onClick(e, FormType.EDIT, currency)}>
        <FormattedMessage id="edit" />
      </span>
      <span className="delete">
        <RemoveCurrencyForm currency={currency} />
      </span>
    </div>
  )

  const referentialCurrencyClass = currency.referential && "referential"

  return (
    <li className={`currencies-row ${referentialCurrencyClass}`}>
      <div className="currencies-row-drag">
        <DragHandle />
      </div>
      <div className="currencies-row-code">{currency.code}</div>
      <div className="currencies-row-name">{currency.name}</div>
      <div className="currencies-row-ratio">{ratio}</div>
      <div className="currencies-row-toolbar">{toolbar}</div>
    </li>
  )
})

const CurrencyRatio = ({ currency, referentialCurrency }) => (
  <div>
    <div>
      <FormattedMoney
        value={100}
        currency={referentialCurrency.code}
        numOfDigits={0}
      />
      &nbsp; = &nbsp;
      <FormattedMoney
        value={100 * currency.ratioToReferential}
        currency={currency.code}
        numOfDigits={4}
      />
    </div>
    <div>
      <FormattedMoney
        value={100}
        currency={currency.code}
        numOfDigits={0}
      />
      &nbsp; = &nbsp;
      <FormattedMoney
        value={100 / currency.ratioToReferential}
        currency={referentialCurrency.code}
        numOfDigits={4}
      />
    </div>
  </div>
)

export default connect(mapStateToProps, mapDispatchToProps)(Currencies)
