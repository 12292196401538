import React, { useCallback } from 'react'
import billingStyles from 'src/frontend/scenes/billing/components/Billing.module.less'
import styles from 'src/frontend/scenes/billing/billingInformation/components/BillingInformation.module.less'
import { Divider, Form, Grid, Message } from 'semantic-ui-react'
import { RootState } from 'src/types/State'
import { updateBillingAddress } from 'src/frontend/scenes/billing/billingInformation/actions'
import { connect } from 'react-redux'
import { Redirect, RouteChildrenProps, withRouter } from 'react-router'
import { Billing } from 'src/types/Billing'
import BraintreeProduct = Billing.BraintreeProduct
import { selectSelectedProduct } from 'src/frontend/scenes/billing/product/selectors'
import { PrimaryButton, SecondaryButton } from 'src/frontend/components/Buttons/Buttons'
import { FormattedMessage, FormattedMessageLabel } from 'src/frontend/modules/intl'
import { BraintreeBillingAddress } from 'src/frontend/scenes/billing/billingInformation/types'
import { getCountriesOptions, isEuCountry } from 'src/backend/countries/countries'
import { isPaidPremium } from 'src/backend/user/service'
import { selectUser } from 'src/frontend/modules/user/selectors'
import { User } from 'src/types/User'
import { Field, FieldProps, Formik } from 'formik'
import { selectActiveBillingAddress } from 'src/frontend/scenes/billing/activeSubscription/selectors'
import {
  getInitialFormValues,
  getValidationSchema,
} from 'src/frontend/scenes/billing/billingInformation/components/helpers'
import * as gtm from 'src/common/gtm'

function mapStateToProps(state: RootState) {
  return {
    selectedProduct: selectSelectedProduct(state),
    savedBillingAddress: selectActiveBillingAddress(state),
    user: selectUser(state),
  }
}

const mapDispatchToProps = {
  handleUpdateAddress: updateBillingAddress,
}

interface Props extends RouteChildrenProps {
  savedBillingAddress: BraintreeBillingAddress
  selectedProduct: BraintreeProduct
  user: User,
  handleUpdateAddress: (values: BraintreeBillingAddress) => Promise<any>
}

function BillingInformation({
  savedBillingAddress,
  history,
  user,
  selectedProduct,
  handleUpdateAddress,
}: Props) {

  const handleSkip = useCallback(() => {
    history.push('/settings/billing/payment-method')
  }, [])

  const onSubmit = useCallback((values: BraintreeBillingAddress) => {
    handleUpdateAddress(values)
      .then(() => {
        gtm.billingInfoFinished()
        if (selectedProduct) {
          history.push('/settings/billing/payment-method')
        } else {
          history.push('/settings/billing')
        }
      })
  }, [selectedProduct])


  const countries = React.useMemo(() => getCountriesOptions(), [])

  // redirect to the select product if no product is selected
  if (!isPaidPremium(user) && !selectedProduct) {
    return <Redirect to="/settings/billing/choose-plan" />
  }

  return (
    <div className={billingStyles.billingContainer}>
      <div className={styles.billingInformation}>
        <h2><FormattedMessage id="billing.billing-information" /></h2>
        <div className={styles.description}><FormattedMessage id="billing.billing-information.description" /></div>
        <div>
          <Formik
            initialValues={savedBillingAddress ?? getInitialFormValues(user.countryCode)}
            validationSchema={getValidationSchema()}
            validateOnChange={false}
            onSubmit={onSubmit}
          >
            {({
              handleSubmit,
              values,
              touched,
              errors,
              isSubmitting,
            }) => (
              <Form>
                <Field
                  as={Form.Input}
                  label={<FormattedMessageLabel id="billing.address.company" />}
                  name="company"
                  error={touched.company && !!errors.company}
                />
                <Message error visible={touched.company && !!errors.company}>
                  <FormattedMessage id={errors.company} />
                </Message>

                <Field
                  as={Form.Input}
                  label={<FormattedMessageLabel id="billing.address.street" />}
                  name="streetAddress"
                  error={touched.streetAddress && !!errors.streetAddress}
                />
                <Message
                  error
                  visible={touched.streetAddress && !!errors.streetAddress}
                >
                  <FormattedMessage id={errors.streetAddress} />
                </Message>

                <Field
                  as={Form.Input}
                  label={<FormattedMessageLabel id="billing.address.extended-address" />}
                  name="extendedAddress"
                  error={touched.extendedAddress && !!errors.extendedAddress}
                />
                <Message error visible={touched.extendedAddress && !!errors.extendedAddress}>
                  <FormattedMessage id={errors.extendedAddress} />
                </Message>

                <Field
                  as={Form.Input}
                  label={<FormattedMessageLabel id="billing.address.city" />}
                  name="city"
                  error={touched.city && !!errors.city}
                />
                <Message error visible={touched.city && !!errors.city}>
                  <FormattedMessage id={errors.city} />
                </Message>

                <Field
                  as={Form.Input}
                  label={<FormattedMessageLabel id="billing.address.postal-code" />}
                  name="postalCode"
                  error={touched.postalCode && !!errors.postalCode}
                />
                <Message error visible={touched.postalCode && !!errors.postalCode}>
                  <FormattedMessage id={errors.postalCode} />
                </Message>

                <Field
                  name="countryCodeAlpha2"
                >
                  {({ field, form }: FieldProps) => (
                    <Form.Select
                      options={countries}
                      search
                      name={field.name}
                      value={field.value}
                      label={<FormattedMessageLabel id="billing.address.country" />}
                      onChange={(_e, { name, value }) => {
                        if (!isEuCountry(value as string)) {
                          form.setFieldValue('vatNumber', '')
                        }
                        form.setFieldValue(name, value)
                      }}
                    />
                  )}
                </Field>

                {isEuCountry(values.countryCodeAlpha2) && (
                  <>
                    <Field
                      as={Form.Input}
                      name="vatNumber"
                      label={<FormattedMessageLabel id="billing.address.vat-id" />}
                      error={touched.vatNumber && !!errors.vatNumber}
                    />
                    <Message error visible={touched.vatNumber && !!errors.vatNumber}>
                      <FormattedMessage id={errors.vatNumber} />
                    </Message>
                  </>
                )}

                <Field
                  as={Form.Input}
                  label={<FormattedMessageLabel id="billing.address.businessId" />}
                  name="businessId"
                  error={touched.businessId && !!errors.businessId}
                />
                <Message error visible={touched.businessId && !!errors.businessId}>
                  <FormattedMessage id={errors.businessId} />
                </Message>

                <Divider hidden section />

                {selectedProduct ? (
                    <Grid>
                      <Grid.Column width={8}>
                        <SecondaryButton fluid disabled={isSubmitting} onClick={handleSkip}>
                          <FormattedMessage id="form.skip" />
                        </SecondaryButton>
                      </Grid.Column>
                      <Grid.Column width={8}>
                        <PrimaryButton
                          fluid
                          type="submit"
                          loading={isSubmitting}
                          disabled={isSubmitting}
                          onClick={handleSubmit}
                        >
                          <FormattedMessage id="billing.billing-information.confirm" />
                        </PrimaryButton>
                      </Grid.Column>
                    </Grid>
                  )
                  : (
                    <PrimaryButton
                      fluid
                      loading={isSubmitting}
                      disabled={isSubmitting}
                      type="submit"
                      onClick={handleSubmit}
                    >
                      <FormattedMessage id="form.update" />
                    </PrimaryButton>
                  )}
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  )
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BillingInformation))
