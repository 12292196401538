import { DatasetOptions } from 'src/frontend/components/chart/types'

/**
 * Shared ChartJS dataset option, common only for dashboard components
 */
export const DATASET_DEFAULT_OPTIONS: DatasetOptions = {
  borderWidth: 3,
  pointRadius: 0,
  pointHitRadius: 30,
  lineTension: 0.2,
  borderJoinStyle: 'round',
}
/** Empirical value to fit labels in the Dashboard, should be odd to cover start/end */
export const LABELS_PER_CHART: number = 6
