import { Id } from 'src/types/CouchDb'
import { BraintreePaymentMethod } from 'src/frontend/scenes/billing/paymentMethod/types'
import { BraintreeBillingAddress } from 'src/frontend/scenes/billing/billingInformation/types'

export enum BraintreeSubscriptionStatus {
  ACTIVE = 0,
  CANCELED = 1,
  EXPIRED = 2,
  PAST_DUE = 3,
  PENDING = 4,
  UNRECOGNIZED = 5,
}

export interface BraintreeSubscription {
  id: Id
  status: BraintreeSubscriptionStatus
  createdAt: number
  firstBillingDate: number
  // billingPeriodEndDate?: number
  // billingPeriodStartDate?: number
  nextBillingDate: number
  nextBillingPeriodAmount?: number
  planId: string // todo fix enum
  price: number
  currencyCode: string
  hasTrialPeriod: boolean
}

export interface BraintreeDetails {
  billingAddress?: BraintreeBillingAddress
  defaultPaymentMethod?: BraintreePaymentMethod
  subscription?: BraintreeSubscription
}
