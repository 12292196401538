import React from 'react'
import { RecordListItemRecord } from 'src/frontend/components/RecordList/types'
import TruncatedText from 'src/frontend/components/TruncatedText'

interface Props {
  record: RecordListItemRecord
}

export default function RecordContact({ record }: Props) {
  return (
    <TruncatedText text={record.contact.name} length={50} showPopup />
  )
}
