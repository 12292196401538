import { toString } from "test/utils"
import { IntegrationConnection } from "src/frontend/scenes/integrations/newConnection/types"
import { parseIntDecimal } from "src/common/utils"
import { NewConnectionState } from "src/frontend/scenes/integrations/newConnection/reducer"
import { IntegrationProviders } from "src/frontend/scenes/integrations/providersList/types"
import IntegrationLoginResponse = IntegrationProviders.IntegrationLoginResponse
import IntegrationProviderDetail = IntegrationProviders.IntegrationProviderDetail
import ConnectionType = IntegrationConnection.ConnectionType
import { IntegrationSourceName } from "src/backend/integrations/enums"

export enum BrowserStorageAction {
  SET_ITEM,
  GET_ITEM,
  CLEAR_ITEM,
}

const USER_LANGUAGE = "user_language"
const LAST_START = "last_start"
const LAST_TERMS_CHECKED = "last_terms_checked"
const OAUTH_CHECKED = "oauth_terms_checked"
export const LOGOUT_ALL_TABS = "logout_all_tabs"
const ANDROID_RATING = "android_rating"
const FEEDBACK_HIDDEN = "feedback_hidden"
const USER_CHECK = "user_check"
const BANNER = "banner"
const LAST_PROVIDER_CODE = "last_provider_code"
const LAST_INTEGRATION_SOURCE_NAME = "last_integration_source_name"
const PROVIDER_LOGIN_ID = "provider_login_id"
const LOGIN_RESPONSE = "login_response"
const NEW_CONNECTION = "new_connection"
const CONNECTION_TYPE = "connection_type"
const RECONNECT_LOGIN_ID = "reconnect_login_id"
const FORM_ATTRIBUTE_GROUPS = "form_attribute_groups"
const CONNECTION_LOGIN_ID = "connection_login_id"
const PROVIDER_DETAIL = "provider_detail"
const REDIRECT_URL = "redirect_url"
const WS_CAMPAIGN_CLOSED = "ws_campaign_closed"
const INVESTMENTS_ALERT_CLOSED = "investments_alert_closed"
const APP_START = "app_start"

export function setLastTermsChecked(userId, date) {
  localStorage.setItem(`${userId}_${LAST_TERMS_CHECKED}`, date)
}

export function getLastTermsChecked(userId) {
  return localStorage.getItem(`${userId}_${LAST_TERMS_CHECKED}`)
}

export function setUserLanguage(language) {
  localStorage.setItem(USER_LANGUAGE, language)
}

export function getUserLanguage() {
  return localStorage.getItem(USER_LANGUAGE)
}

export function setLastStart(date) {
  localStorage.setItem(LAST_START, date)
}

export function getLastStart() {
  return localStorage.getItem(LAST_START)
}

export function setOauthChecked(checked) {
  localStorage.setItem(OAUTH_CHECKED, checked)
}

export function getOauthChecked() {
  return !!localStorage.getItem(OAUTH_CHECKED)
}

export function logoutAllTabs() {
  localStorage.setItem(LOGOUT_ALL_TABS, LOGOUT_ALL_TABS)
  return localStorage.removeItem(LOGOUT_ALL_TABS)
}

export function getAndroidRating() {
  return parseIntDecimal(localStorage.getItem(ANDROID_RATING) || 0)
}

export function setAndroidRating(rating) {
  localStorage.setItem(ANDROID_RATING, rating)
}

export function setFeedbackHidden(feedbackId: string) {
  localStorage.setItem(`${FEEDBACK_HIDDEN}_${feedbackId}`, "true")
}

export function getFeedBackHidden(feedbackId: string) {
  return !!localStorage.getItem(`${FEEDBACK_HIDDEN}_${feedbackId}`)
}

export function setUserChecked(checkedDateString: string) {
  sessionStorage.setItem(USER_CHECK, checkedDateString)
}

export function getUserChecked() {
  return sessionStorage.getItem(USER_CHECK)
}

export function setAppStart(value: boolean) {
  sessionStorage.setItem(APP_START, value ? "true" : "false")
}

export function getAppStart() {
  return sessionStorage.getItem(APP_START) === "true" ? true : false
}

export function setWScampaignClosed() {
  localStorage.setItem(WS_CAMPAIGN_CLOSED, "true")
}

export function getWScampaignClosed() {
  return localStorage.getItem(WS_CAMPAIGN_CLOSED) === "true" ? true : false
}

export function setInvestmentsAlertClosed() {
  localStorage.setItem(INVESTMENTS_ALERT_CLOSED, "true")
}

export function getInvestmentsAlertClosed() {
  return localStorage.getItem(INVESTMENTS_ALERT_CLOSED) === "true" ? true : false
}

export function redirectAfterInitUrlLocalStorage(action: BrowserStorageAction, value?: string) {
  switch (action) {
    case BrowserStorageAction.SET_ITEM:
      sessionStorage.setItem(REDIRECT_URL, value)
    case BrowserStorageAction.GET_ITEM:
      return sessionStorage.getItem(REDIRECT_URL)
    case BrowserStorageAction.CLEAR_ITEM:
      sessionStorage.removeItem(REDIRECT_URL)
  }
}

export function setBannerShown(show: boolean) {
  sessionStorage.setItem(BANNER, show.toString())
}

export function getBannerShown() {
  const value = sessionStorage.getItem(BANNER)
  return value && value === "true"
}

export function getProviderLoginId(): string {
  return localStorage.getItem(`${PROVIDER_LOGIN_ID}`)
}

export function setProviderLoginId(loginId: string) {
  localStorage.setItem(`${PROVIDER_LOGIN_ID}`, loginId)
}

export function setIntegrationProviderCode(providerCode: string) {
  localStorage.setItem(`${LAST_PROVIDER_CODE}`, providerCode)
}

export function getIntegrationProviderCode(): string {
  return localStorage.getItem(`${LAST_PROVIDER_CODE}`)
}

export function setIntegrationSourceNameToLocalStorage(integrationSourceName: string) {
  localStorage.setItem(`${LAST_INTEGRATION_SOURCE_NAME}`, integrationSourceName)
}

export function getIntegrationSourceNameFromLocaleStorage() {
  return localStorage.getItem(`${LAST_INTEGRATION_SOURCE_NAME}`) as IntegrationSourceName
}

export function setConnectionLoginId(loginId: string) {
  localStorage.setItem(`${CONNECTION_LOGIN_ID}`, loginId)
}

export function getConnectionLoginId() {
  return !!localStorage.getItem(`${CONNECTION_LOGIN_ID}`)
}

export function setloginResponse(loginResponse: IntegrationLoginResponse) {
  return localStorage.setItem(`${LOGIN_RESPONSE}`, JSON.stringify(loginResponse))
}

export function getloginResponse() {
  return JSON.parse(localStorage.getItem(`${LOGIN_RESPONSE}`))
}

export function setNewConnectionToLocaleStorage(newConnection: NewConnectionState) {
  return localStorage.setItem(NEW_CONNECTION, JSON.stringify(newConnection))
}

export function getNewConnectionFromLocaleStorage(): NewConnectionState {
  return JSON.parse(localStorage.getItem(`${NEW_CONNECTION}`))
}

export function setProviderDetailToLocaleStorage(provider: IntegrationProviderDetail) {
  return localStorage.setItem(PROVIDER_DETAIL, JSON.stringify(provider))
}

export function getProviderDetailFromLocaleStorage(): IntegrationProviderDetail {
  return JSON.parse(localStorage.getItem(`${PROVIDER_DETAIL}`))
}

export function getConnectionTypeFromLocaleStorage(): string {
  return localStorage.getItem(`${CONNECTION_TYPE}`)
}

export function setConnectionType(connectionType: ConnectionType) {
  localStorage.setItem(`${CONNECTION_TYPE}`, connectionType)
}

export function issetConnectionType(): boolean {
  const connectionType = localStorage.getItem(`${CONNECTION_TYPE}`)
  return Object.values(ConnectionType).includes(connectionType as ConnectionType)
}

export function getReconnectLoginId(): string {
  return localStorage.getItem(`${RECONNECT_LOGIN_ID}`)
}

export function setReconnectLoginId(loginId: string) {
  localStorage.setItem(`${RECONNECT_LOGIN_ID}`, loginId)
}

export function removeNewConnectionDataFromLocaleStorage() {
  return [
    NEW_CONNECTION,
    RECONNECT_LOGIN_ID,
    CONNECTION_TYPE,
    LAST_PROVIDER_CODE,
    LAST_INTEGRATION_SOURCE_NAME,
    FORM_ATTRIBUTE_GROUPS,
  ].forEach((localStorageKey) => localStorage.removeItem(localStorageKey))
}
