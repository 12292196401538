import { Currency } from "src/types/Currency"
import { Account } from "src/types/Account"
import { Interval, IntervalGranularityType, RecordType } from "src/backend/enums"
import moment from "moment"
import { getRandomInt } from "src/frontend/scenes/dashboard/helpers"
import * as timeUtils from "src/backend/time/time"
import { ChartType } from "src/frontend/components/chart/ChartType"
import { createWidgetWithData } from "src/backend/webConfig/converters"
import { WidgetId } from "src/backend/dashboard/enums"
import { RevenueCostsGrowthWidgetVariant } from "src/frontend/scenes/dashboard/widgets/BoardRevenueCostsGrowth/enums"
import { isCreditCard } from "src/backend/accounts/helpers"
import {
  getEnvelopeById,
  INVENTORY_PURCHASE_ID,
  OTHER_BILLS_CHARGES_ID,
  OTHER_REVENUE_ID,
  PAYROLL_TRAVEL_ID,
  SALES_REVENUE_ID,
  SPACE_EQUIPMENT_ID,
} from "src/backend/categories/envelopes"
import { convertData } from "src/frontend/scenes/analytics/helpers"
import { PieAndSpendingWidgetVariant } from "src/frontend/scenes/dashboard/widgets/PieAndBarSpending/enums"
import { SpendingByNatureVariant } from "src/frontend/scenes/dashboard/widgets/SpendingByNatureBars/enums"
import { EnvelopeCardinality } from "src/backend/categories/enums"
import { isAppBoard } from "src/common/environment"

const PerformanceDummyData = {
  balance: {
    performanceValue: 0.8,
    labelValue: 46259555,
  },
  cashFlow: {
    performanceValue: 0.6,
    labelValue: 1394945,
  },
  spending: {
    performanceValue: 0.8,
    labelValue: 3423112,
  },
}

const BoardPerformanceDummyData = {
  revenues: {
    performanceValue: 1.05,
    labelValue: 46259555,
  },
  cashFlow: {
    performanceValue: 1.25,
    labelValue: 1394945,
  },
  balance: {
    performanceValue: 0.97,
    labelValue: 3423112,
  },
}

function getLastRecordsDummyData(accounts, currencies) {
  const [accountId] = Object.keys(accounts)
  const currenciesArray: Currency[] = Object.values(currencies)
  const currency: Currency = currenciesArray[getRandomInt(currenciesArray.length)]

  if (!currency) {
    return null
  }
  const currencyId = currency._id
  return [
    {
      type: RecordType.EXPENSE,
      amount: 32000,
      refAmount: 32000,
      recordDate: moment().subtract(8, "minute").toDate(),
      accountId,
      currencyId,
      envelopeId: 2000,
    },
    {
      type: RecordType.EXPENSE,
      amount: 12000,
      refAmount: 1200,
      recordDate: moment().subtract(1.5, "hour").toDate(),
      accountId,
      currencyId,
      envelopeId: 1000,
    },
    {
      type: RecordType.EXPENSE,
      amount: 50000,
      refAmount: 50000,
      recordDate: moment().subtract(2, "day").toDate(),
      accountId,
      currencyId,
      envelopeId: 3003,
    },
  ]
}

function getPlannedPaymentsDummyData(accounts, currencies) {
  const [accountId] = Object.keys(accounts)
  const currenciesArray: Currency[] = Object.values(currencies)
  const currency: Currency = currenciesArray[getRandomInt(currenciesArray.length)]

  if (!currency) {
    return null
  }
  const currencyId = currency._id
  return [
    {
      type: RecordType.EXPENSE,
      amount: 600000,
      refAmount: 600000,
      recordDate: moment().add(1, Interval.WEEK).startOf("day").toDate(),
      accountId,
      currencyId,
      envelopeId: 3002,
    },
    {
      type: RecordType.EXPENSE,
      amount: 120000,
      refAmount: 120000,
      recordDate: moment().add(2, Interval.WEEK).startOf("day").toDate(),
      accountId,
      currencyId,
      envelopeId: 3000,
    },
  ]
}

function getCashLiquidityDummyData(accounts, accountsTotals) {
  const accountsArray: Account[] = Object.values(accounts)

  return accountsArray
    .filter((account) => accountsTotals && accountsTotals[account._id])
    .sort((a, b) => accountsTotals[b._id].balance - accountsTotals[a._id].balance)
    .reduce((acc, value, index) => {
      // limit dummy data to 3 bars
      if (index < 3) {
        const amount = Math.abs(accountsTotals[value._id].balance / (0.6 * (index + 1)))

        const refAmount = accountsTotals[value._id] ? amount : 1000

        return {
          ...acc,
          [value._id]: [
            {
              accountId: value._id,
              currency: value.currencyId,
              type: RecordType.EXPENSE,
              amount,
              refAmount,
              recordDate: moment().add(1, Interval.WEEK).startOf("day").toDate(),
              envelopeId: 3002,
            },
          ],
        }
      } else {
        return acc
      }
    }, {})
}

function getSpendingByCategoriesDummyData(currencies) {
  const currenciesArray = Object.values(currencies)
  const referentialCurrency = currenciesArray[getRandomInt(currenciesArray.length)]
  return {
    currentPeriod: {
      30: { superEnvelopeId: 30, income: 0, refIncome: 0, expense: 205500, refExpense: 205500 },
      10: {
        superEnvelopeId: 10,
        income: 42000,
        refIncome: 42000,
        expense: 187900,
        refExpense: 187900,
      },
      70: {
        superEnvelopeId: 70,
        income: 42000,
        refIncome: 42000,
        expense: 127900,
        refExpense: 127900,
      },
      80: { superEnvelopeId: 80, income: 0, refIncome: 0, expense: 52941, refExpense: 52941 },
    },
    previousPeriod: {
      30: { superEnvelopeId: 30, income: 0, refIncome: 0, expense: 305500, refExpense: 305500 },
      10: {
        superEnvelopeId: 10,
        income: 42000,
        refIncome: 42000,
        expense: 287900,
        refExpense: 287900,
      },
      70: {
        superEnvelopeId: 70,
        income: 52000,
        refIncome: 52000,
        expense: 107900,
        refExpense: 107900,
      },
      80: { superEnvelopeId: 80, income: 0, refIncome: 0, expense: 19941, refExpense: 19941 },
    },
    referentialCurrency,
  }
}

function getPieSpending(currencies) {
  const currenciesArray = Object.values(currencies)
  const referentialCurrency = currenciesArray[getRandomInt(currenciesArray.length)]
  return {
    barChartDatasets: {
      labels: ["a", "b", "c", "d", "e", "f", "g"],
      datasets: convertData(
        ChartType.BAR,
        [
          {
            id: 30,
            data: [{ y: 0 }, { y: 3444 }, { y: 0 }, { y: 0 }, { y: 0 }, { y: 0 }, { y: 0 }],
            category: getEnvelopeById(30),
            type: ChartType.BAR,
          },
          {
            id: 10,
            data: [{ y: 0 }, { y: 500 }, { y: 0 }, { y: 1600 }, { y: 0 }, { y: 400 }, { y: 200 }],
            category: getEnvelopeById(10),
            type: ChartType.BAR,
          },
          {
            id: 70,
            data: [{ y: 500 }, { y: 200 }, { y: 0 }, { y: 0 }, { y: 700 }, { y: 0 }, { y: 0 }],
            category: getEnvelopeById(70),
            type: ChartType.BAR,
          },
          {
            id: 80,
            data: [{ y: 0 }, { y: 0 }, { y: 0 }, { y: 0 }, { y: 300 }, { y: 0 }, { y: 900 }],
            category: getEnvelopeById(80),
            type: ChartType.BAR,
          },
        ],
        true,
      ),
    },
    pieChartDatasets: [
      {
        id: 30,
        value: 205500,
        category: getEnvelopeById(30),
      },
      {
        id: 10,
        value: 187900,
        category: getEnvelopeById(10),
      },
      {
        id: 70,
        value: 127900,
        category: getEnvelopeById(70),
      },
      {
        id: 80,
        value: 19941,
        category: getEnvelopeById(80),
      },
    ],
    referentialCurrency,
  }
}

function getCashFlowCardDummyData(currencies) {
  const currenciesArray = Object.values(currencies)
  const referentialCurrency = currenciesArray[getRandomInt(currenciesArray.length)]
  return {
    currentPeriod: { income: 422720, refIncome: 422720, expense: 346341, refExpense: 346341 },
    previousPeriod: {},
    referentialCurrency,
  }
}

function getBoardCashFlowCardDummyData(currencies) {
  const currenciesArray = Object.values(currencies)
  const referentialCurrency = currenciesArray[getRandomInt(currenciesArray.length)]
  return {
    currentPeriod: {
      operatingRevenue: 422854220,
      otherCashInflow: 140463000,
      operatingCosts: -313567850,
      otherCashOutFlow: -44846230,
    },
    previousPeriod: {},
    referentialCurrency,
  }
}

function getBoardOperatingProfitDummyData(currencies) {
  const currenciesArray = Object.values(currencies)
  const referentialCurrency = currenciesArray[getRandomInt(currenciesArray.length)]
  return {
    currentPeriod: {
      salesRevenue: 422854220,
      otherRevenue: 140463000,
      fixedCosts: -313567850,
      variableCosts: -44846230,
    },
    previousPeriod: {},
    referentialCurrency,
  }
}

function getPieRevenues(currencies) {
  const currenciesArray = Object.values(currencies)
  const referentialCurrency = currenciesArray[getRandomInt(currenciesArray.length)]
  return {
    barChartDatasets: {
      labels: ["a", "b", "c", "d", "e", "f", "g"],
      datasets: convertData(
        ChartType.BAR,
        [
          {
            id: SALES_REVENUE_ID,
            data: [
              { y: 0 },
              { y: 3444 },
              { y: 900 },
              { y: 0 },
              { y: 4000 },
              { y: 700 },
              { y: 2000 },
            ],
            category: getEnvelopeById(SALES_REVENUE_ID),
            type: ChartType.BAR,
          },
          {
            id: OTHER_REVENUE_ID,
            data: [
              { y: 550 },
              { y: 1500 },
              { y: 0 },
              { y: 1600 },
              { y: 0 },
              { y: 400 },
              { y: 200 },
            ],
            category: getEnvelopeById(OTHER_REVENUE_ID),
            type: ChartType.BAR,
          },
        ],
        true,
      ),
    },
    pieChartDatasets: [
      {
        id: SALES_REVENUE_ID,
        value: 405500,
        category: getEnvelopeById(SALES_REVENUE_ID),
      },
      {
        id: OTHER_REVENUE_ID,
        value: 187900,
        category: getEnvelopeById(OTHER_REVENUE_ID),
      },
    ],
    referentialCurrency,
  }
}

function getPieCosts(currencies) {
  const currenciesArray = Object.values(currencies)
  const referentialCurrency = currenciesArray[getRandomInt(currenciesArray.length)]
  return {
    barChartDatasets: {
      labels: ["a", "b", "c", "d", "e", "f", "g"],
      datasets: convertData(
        ChartType.BAR,
        [
          {
            id: SPACE_EQUIPMENT_ID,
            data: [{ y: 0 }, { y: 3444 }, { y: 0 }, { y: 0 }, { y: 0 }, { y: 0 }, { y: 0 }],
            category: getEnvelopeById(SPACE_EQUIPMENT_ID),
            type: ChartType.BAR,
          },
          {
            id: INVENTORY_PURCHASE_ID,
            data: [{ y: 0 }, { y: 500 }, { y: 0 }, { y: 1600 }, { y: 0 }, { y: 400 }, { y: 200 }],
            category: getEnvelopeById(INVENTORY_PURCHASE_ID),
            type: ChartType.BAR,
          },
          {
            id: PAYROLL_TRAVEL_ID,
            data: [{ y: 500 }, { y: 200 }, { y: 0 }, { y: 0 }, { y: 700 }, { y: 0 }, { y: 0 }],
            category: getEnvelopeById(PAYROLL_TRAVEL_ID),
            type: ChartType.BAR,
          },
          {
            id: OTHER_BILLS_CHARGES_ID,
            data: [{ y: 0 }, { y: 0 }, { y: 0 }, { y: 0 }, { y: 300 }, { y: 0 }, { y: 900 }],
            category: getEnvelopeById(OTHER_BILLS_CHARGES_ID),
            type: ChartType.BAR,
          },
        ],
        true,
      ),
    },
    pieChartDatasets: [
      {
        id: SPACE_EQUIPMENT_ID,
        value: 205500,
        category: getEnvelopeById(SPACE_EQUIPMENT_ID),
      },
      {
        id: INVENTORY_PURCHASE_ID,
        value: 187900,
        category: getEnvelopeById(INVENTORY_PURCHASE_ID),
      },
      {
        id: PAYROLL_TRAVEL_ID,
        value: 127900,
        category: getEnvelopeById(PAYROLL_TRAVEL_ID),
      },
      {
        id: OTHER_BILLS_CHARGES_ID,
        value: 19941,
        category: getEnvelopeById(OTHER_BILLS_CHARGES_ID),
      },
    ],
    referentialCurrency,
  }
}

function getBalanceByCurrenciesDummyData(currencies) {
  const values = [937655, 524000, 128430]
  return Object.values(currencies)
    .filter((_currency, index) => index < values.length)
    .map((currency: Currency, index: number) => {
      return {
        balance: values[index] || 0,
        currencyId: currency._id,
        refBalance: values[index] || 0,
      }
    })
}

function getBalanceTrendDummyData(currencies) {
  const currenciesArray = Object.values(currencies)
  const currency = currenciesArray[getRandomInt(currenciesArray.length)]
  return {
    closingBalance: 1000000,
    labels: timeUtils.generateDateStrings(
      timeUtils.intervalTypeToPeriod(Interval.MONTH),
      IntervalGranularityType.DAY,
    ),
    currency,
    datasets: [
      {
        closingValue: 2700,
        data: [
          1000, 900, 900, 850, 850, 850, 700, 15000, 14000, 13500, 13400, 12000, 10000, 10000,
          10000, 10000, 9500, 9500, 6000, 5000, 5000, 4000, 3000, 3000, 3000, 2900, 2900, 2850,
          2850, 2700, 2600, 2600, 2600,
        ],
      },
    ],
    labelsPerChart: 6,
    type: ChartType.LINE,
  }
}

function getCostsDummyData(currencies) {
  const currenciesArray = Object.values(currencies)
  const currency = currenciesArray[getRandomInt(currenciesArray.length)]
  return {
    closingBalance: 1200000,
    labels: timeUtils.generateDateStrings(
      timeUtils.intervalTypeToPeriod(Interval.MONTH),
      IntervalGranularityType.DAY,
    ),
    currency,
    datasets: [
      {
        closingValue: 2700,
        data: [
          0, 0, -400, -400, -400, -400, -5000, -5000, -5000, -5000, -5000, -5000, -5500, -5500,
          -5500, -5500, -5500, -5500, -5500, -5500, -5500, -6000, -6000, -6000, -6000, -6000, -6000,
          -6000, -6000, -6000, -6000, -6000, -6000,
        ],
      },
      {
        closingValue: 2700,
        data: [
          0, 0, 0, 0, 0, 0, -100, -100, -100, -1000, -1000, -1000, -1000, -2500, -2500, -2500,
          -2500, -2500, -2500, -2500, -4000, -4000, -4000, -4000, -4000, -4000, -4000, -4000, -4500,
          -4500, -4500, -4500, -4500,
        ],
      },
      {
        closingValue: 2700,
        data: [
          0, 0, 0, 0, 0, 0, -100, -100, -100, -100, -100, -100, -100, -250, -250, -250, -250, -250,
          -250, -250, -400, -400, -400, -400, -400, -400, -400, -400, -450, -1000, -1000, -1000,
          -1000,
        ],
      },
    ],
    labelsPerChart: 6,
    type: ChartType.LINE,
  }
}

function getRevenuesDummyData(currencies) {
  const currenciesArray = Object.values(currencies)
  const currency = currenciesArray[getRandomInt(currenciesArray.length)]
  return {
    closingBalance: 1200000,
    labels: timeUtils.generateDateStrings(
      timeUtils.intervalTypeToPeriod(Interval.MONTH),
      IntervalGranularityType.DAY,
    ),
    currency,
    datasets: [
      {
        closingValue: 2700,
        data: [
          0, 0, 0, 0, 0, 0, 5000, 5000, 5000, 5000, 5000, 5000, 9500, 9500, 9500, 9500, 9500, 9500,
          9500, 9500, 9500, 12000, 12000, 12000, 12000, 12000, 12000, 12000, 12000, 12000, 12000,
          12000, 12000,
        ],
      },
      {
        closingValue: 2700,
        data: [
          0, 0, 0, 0, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000,
          6000, 6000, 6000, 6000, 6000, 6000, 6000, 6000, 6000, 9000, 9000, 9000, 9000, 9000, 9000,
          9000,
        ],
      },
      {
        closingValue: 2700,
        data: [
          0, 0, 0, 0, 3000, 3000, 3000, 3000, 3000, 3000, 3000, 3000, 3000, 4000, 4000, 4000, 4000,
          4000, 4000, 4000, 4000, 4000, 4000, 4000, 6000, 6000, 7000, 7000, 7000, 7000, 7000, 7000,
          7000,
        ],
      },
    ],
    labelsPerChart: 6,
    type: ChartType.LINE,
  }
}

function getPeriodToPeriodDummyData(currencies) {
  const currenciesArray = Object.values(currencies)
  const currency = currenciesArray[getRandomInt(currenciesArray.length)]
  return {
    closingBalance: 1000000,
    labels: timeUtils.generateDateStrings(
      timeUtils.intervalTypeToPeriod(Interval.MONTH),
      IntervalGranularityType.DAY,
    ),
    currency,
    datasets: [
      {
        closingValue: 2700,
        data: [
          1000, 900, 900, 850, 850, 850, 700, 15000, 14000, 13500, 13400, 12000, 10000, 10000,
          10000, 10000, 9500, 9500, 6000, 5000, 5000, 4000, 3000, 3000, 3000, 2900, 2900, 2850,
          2850, 2700, 2600, 2600, 2600,
        ],
      },
      {
        closingValue: 2700,
        data: [
          900, 900, 900, 850, 13000, 12000, 12500, 12400, 11000, 9000, 8000, 8000, 8000, 7500, 7500,
          7000, 7000, 4000, 4000, 4000, 4000, 2000, 1900, 1900, 1500, 850, 700, 600, 600, 600,
        ],
      },
      {
        closingValue: 2700,
        data: [
          500, 500, 500, 500, 500, 500, 900, 900, 850, 10000, 9000, 8500, 9400, 9000, 9000, 10000,
          10000, 10000, 9500, 9500, 6000, 5000, 5000, 4000, 3000, 3000, 3000, 2900, 2900, 2850,
          2850, 2700, 2600, 2600, 2600,
        ],
      },
    ],
    labelsPerChart: 6,
    type: ChartType.LINE,
  }
}

function getCashFlowTrendDummyData(currencies) {
  const currenciesArray = Object.values(currencies)
  const currency = currenciesArray[getRandomInt(currenciesArray.length)]
  return {
    closingBalance: 1000000,
    labels: timeUtils.generateDateStrings(
      timeUtils.intervalTypeToPeriod(Interval.MONTH),
      IntervalGranularityType.DAY,
    ),
    currency,
    datasets: [
      {
        closingValue: 2700,
        data: [
          0, 0, 0, 0, -5000, -4000, 0, 0, 15000, 9000, 0, -2000, -1000, 0, 0, 0, 0, 0, -5000, 0,
          -1001, 0, 0, 5000, 0, 0, 0, 0, 0,
        ],
      },
      {
        closingValue: 2700,
        data: [
          0, 0, 0, 0, 0, 0, 0, 0, 18000, 9000, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 5000, 0, 0, 0,
          0, 0,
        ],
      },
      {
        closingValue: 2700,
        data: [
          0, 0, 0, 0, -5000, -4000, 0, 0, -3000, 0, 0, -2000, -1000, 0, 0, 0, 0, 0, -5000, 0, -1001,
          0, 0, 0, 0, 0, 0, 0, 0,
        ],
      },
    ],
    labelsPerChart: 6,
    type: ChartType.BAR,
  }
}

function getBoardCashFlowTrendDummyData(currencies) {
  const currenciesArray = Object.values(currencies)
  const currency = currenciesArray[getRandomInt(currenciesArray.length)]
  return {
    closingBalance: 1000000,
    labels: timeUtils.generateDateStrings(
      timeUtils.intervalTypeToPeriod(Interval.MONTH),
      IntervalGranularityType.DAY,
    ),
    currency,
    datasets: [
      {
        closingValue: 2700,
        data: [
          0, 0, 0, 0, -7000, -5000, 0, 0, 10700, 13000, 0, -2000, -1000, -4000, 0, 0, 5500, 0,
          -5500, 0, -2000, 0, 0, 10500, 0, 0, 0, 0, 0,
        ],
      },
      {
        closingValue: 2700,
        data: [
          0, 0, 0, 0, 0, 0, 0, 0, 18000, 9000, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 5000, 0, 0, 0,
          0, 0,
        ],
      },
      {
        closingValue: 2700,
        data: [
          0, 0, 0, 0, 0, 0, 0, 0, 2700, 4000, 0, 0, 0, 0, 0, 0, 5500, 0, 0, 0, 0, 0, 0, 5500, 0, 0,
          0, 0, 0,
        ],
      },
      {
        closingValue: 2700,
        data: [
          0, 0, 0, 0, -5000, -4000, 0, 0, -3000, 0, 0, -2000, -1000, 0, 0, 0, 0, 0, -5000, 0, -1000,
          0, 0, 0, 0, 0, 0, 0, 0,
        ],
      },

      {
        closingValue: 2700,
        data: [
          0, 0, 0, 0, -2000, -1000, 0, 0, -7000, 0, 0, 0, 0, -4000, 0, 0, 0, 0, -500, 0, -1000, 0,
          0, 0, 0, 0, 0, 0, 0,
        ],
      },
    ],
    labelsPerChart: 6,
    type: ChartType.BAR,
  }
}

function getCreditUtilizationData(accounts) {
  const accountsArray: Account[] = Object.values(accounts)
  const account: Account = accountsArray.find(isCreditCard)

  if (!account) {
    return null
  }

  return {
    [account._id]: {
      accountId: account._id,
      endingCreditBalance: 2345553,
      endingCreditRefBalance: 2345553,
      maxValue: 2945553,
      avgValue: 1745553,
    },
  }
}

function getMoneySpentByTypeData() {
  return {
    barChartDatasets: {
      labels: ["a", "b", "c", "d", "e", "f", "g"],
      datasets: convertData(
        ChartType.BAR,
        [
          {
            id: EnvelopeCardinality.FIXED_COSTS,
            data: [{ y: 0 }, { y: 3444 }, { y: 0 }, { y: 0 }, { y: 0 }, { y: 0 }, { y: 0 }],
            type: ChartType.BAR,
          },
          {
            id: EnvelopeCardinality.VARIABLE_COSTS,
            data: [{ y: 0 }, { y: 500 }, { y: 0 }, { y: 1600 }, { y: 0 }, { y: 400 }, { y: 200 }],
            type: ChartType.BAR,
          },
          {
            id: EnvelopeCardinality.ASSETS_COSTS,
            data: [{ y: 500 }, { y: 200 }, { y: 0 }, { y: 0 }, { y: 700 }, { y: 0 }, { y: 0 }],
            type: ChartType.BAR,
          },
        ],
        true,
      ),
    },
  }
}

function getSpendingCardinalityData() {
  return {
    barChartDatasets: {
      labels: ["a", "b", "c", "d", "e", "f", "g"],
      datasets: convertData(
        ChartType.BAR,
        [
          {
            id: EnvelopeCardinality.MUST,
            data: [{ y: 0 }, { y: 3444 }, { y: 0 }, { y: 0 }, { y: 0 }, { y: 0 }, { y: 0 }],
            type: ChartType.BAR,
          },
          {
            id: EnvelopeCardinality.WANT,
            data: [{ y: 0 }, { y: 500 }, { y: 0 }, { y: 1600 }, { y: 0 }, { y: 400 }, { y: 200 }],
            type: ChartType.BAR,
          },
          {
            id: EnvelopeCardinality.NEED,
            data: [{ y: 500 }, { y: 200 }, { y: 0 }, { y: 0 }, { y: 700 }, { y: 0 }, { y: 0 }],
            type: ChartType.BAR,
          },
        ],
        true,
      ),
    },
  }
}

export function createDummyWidgets(accounts, currencies, accountsTotals) {
  return isAppBoard()
    ? [
        createWidgetWithData(
          WidgetId.CASH_FLOW_CARD_BOARD,
          getBoardCashFlowCardDummyData(currencies),
        ),
        createWidgetWithData(
          WidgetId.CASH_FLOW_CHART_CARD_BOARD,
          getBoardCashFlowTrendDummyData(currencies),
        ),
        createWidgetWithData(
          WidgetId.REVENUE_COST_GROWTH_CARD,
          getRevenuesDummyData(currencies),
          RevenueCostsGrowthWidgetVariant.REVENUE,
        ),
        createWidgetWithData(
          WidgetId.BALANCE_BY_CURRENCIES_CARD,
          getBalanceByCurrenciesDummyData(currencies),
        ),
        createWidgetWithData(
          WidgetId.LAST_RECORDS_CARD,
          getLastRecordsDummyData(accounts, currencies),
        ),
        createWidgetWithData(WidgetId.BALANCE_CHART_CARD, getBalanceTrendDummyData(currencies)),
        createWidgetWithData(WidgetId.ACCOUNTS_LIST),
        createWidgetWithData(
          WidgetId.PERIOD_2_PERIOD_CHART_CARD,
          getPeriodToPeriodDummyData(currencies),
        ),
        createWidgetWithData(
          WidgetId.REVENUE_COST_GROWTH_CARD,
          getCostsDummyData(currencies),
          RevenueCostsGrowthWidgetVariant.COSTS,
        ),
        createWidgetWithData(
          WidgetId.OPERATING_PROFIT_CARD,
          getBoardOperatingProfitDummyData(currencies),
        ),
        createWidgetWithData(
          WidgetId.SPENDING_BY_CATEGORIES_AND_LABELS_PIE_CHART_CARD,
          getPieRevenues(currencies),
          PieAndSpendingWidgetVariant.REVENUE,
        ),
        createWidgetWithData(
          WidgetId.SPENDING_BY_CATEGORIES_AND_LABELS_PIE_CHART_CARD,
          getPieCosts(currencies),
          PieAndSpendingWidgetVariant.COSTS,
        ),

        createWidgetWithData(WidgetId.CREDIT_UTILIZATION_CARD, getCreditUtilizationData(accounts)),
        createWidgetWithData(
          WidgetId.CASH_RATIO_LIQUIDITY_CARD,
          getCashLiquidityDummyData(accounts, accountsTotals),
        ),
        createWidgetWithData(
          WidgetId.CARDINALITY_SPENDING_CHART_CARD,
          getMoneySpentByTypeData(),
          SpendingByNatureVariant.COSTS,
        ),
        createWidgetWithData(WidgetId.COCKPIT_BOARD, BoardPerformanceDummyData),
      ]
    : [
        createWidgetWithData(WidgetId.PERFORMANCE_CARD, PerformanceDummyData),
        createWidgetWithData(
          WidgetId.SPENDING_BY_CATEGORIES_CARD,
          getSpendingByCategoriesDummyData(currencies),
        ),
        createWidgetWithData(
          WidgetId.SPENDING_BY_CATEGORIES_PIE_CHART_CARD,
          getSpendingByCategoriesDummyData(currencies),
        ),
        createWidgetWithData(WidgetId.CASH_FLOW_CARD, getCashFlowCardDummyData(currencies)),
        createWidgetWithData(
          WidgetId.SPENDING_BY_CATEGORIES_AND_LABELS_PIE_CHART_CARD,
          getPieSpending(currencies),
          PieAndSpendingWidgetVariant.SPENDING,
        ),
        createWidgetWithData(
          WidgetId.CARDINALITY_SPENDING_CHART_CARD,
          getSpendingCardinalityData(),
          SpendingByNatureVariant.SPENDING,
        ),
        createWidgetWithData(WidgetId.CASH_FLOW_CHART_CARD, getCashFlowTrendDummyData(currencies)),
        createWidgetWithData(
          WidgetId.UPCOMING_PLANNED_PAYMENTS,
          getPlannedPaymentsDummyData(accounts, currencies),
        ),
        createWidgetWithData(
          WidgetId.BALANCE_BY_CURRENCIES_CARD,
          getBalanceByCurrenciesDummyData(currencies),
        ),
        createWidgetWithData(
          WidgetId.LAST_RECORDS_CARD,
          getLastRecordsDummyData(accounts, currencies),
        ),
        createWidgetWithData(WidgetId.BALANCE_CHART_CARD, getBalanceTrendDummyData(currencies)),
        createWidgetWithData(WidgetId.ACCOUNTS_LIST),
        createWidgetWithData(WidgetId.CREDIT_UTILIZATION_CARD, getCreditUtilizationData(accounts)),
        createWidgetWithData(
          WidgetId.CASH_RATIO_LIQUIDITY_CARD,
          getCashLiquidityDummyData(accounts, accountsTotals),
        ),
        createWidgetWithData(
          WidgetId.PERIOD_2_PERIOD_CHART_CARD,
          getPeriodToPeriodDummyData(currencies),
        ),
      ]
}
