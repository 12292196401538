import {
  convertResultToEntity,
  convertResultToScalar,
  WalletSQLInMemory,
} from "src/backend/db/inMemorySqlDb"
import { inMemoryTableNames } from "src/backend/db/inMemorySqlDbSchemaBuilder"
import { AccountType } from "src/backend/enums"
import * as commonRepository from "src/backend/common/repository"
import { Account } from "src/types/Account"
import lf from "lovefield"
import { Id } from "src/types/CouchDb"
import _isEmpty from "lodash/isEmpty"
import { HashMap } from "src/types/common"
import { accountConverter } from "../converters/accountConverter"

export function findAll(): Promise<Account[]> {
  return commonRepository.findAll(inMemoryTableNames.ACCOUNT) as Promise<Account[]>
}

export function findAllAsHashMap(): Promise<HashMap<Account>> {
  return commonRepository.findAllAsHashMap(inMemoryTableNames.ACCOUNT)
}

export function findById(id): Promise<Account> {
  const sqlDb = WalletSQLInMemory.getInstance()

  const accountTable = sqlDb.getTable(inMemoryTableNames.ACCOUNT)

  console.log(sqlDb)
  console.log(accountTable)
  return sqlDb
    .select()
    .from(accountTable)
    .where(accountTable._id.eq(id))
    .exec()
    .then(convertResultToEntity(id, inMemoryTableNames.ACCOUNT)) as Promise<Account>
}

export function findByIds(ids): Promise<Account[]> {
  if (!Array.isArray(ids)) {
    return findAll() as Promise<Account[]>
  }
  const sqlDb = WalletSQLInMemory.getInstance()
  const accountTable = sqlDb.getTable(inMemoryTableNames.ACCOUNT)
  return sqlDb
    .getDatabase()
    .select()
    .from(accountTable)
    .where(accountTable._id.in(ids))
    .exec() as Promise<Account[]>
}

export function findByCurrencyId(currencyId: string): Promise<Account[]> {
  const sqlDb = WalletSQLInMemory.getInstance()
  const accountTable = sqlDb.getTable(inMemoryTableNames.ACCOUNT)
  return sqlDb
    .getDatabase()
    .select()
    .from(accountTable)
    .where(accountTable.currencyId.eq(currencyId))
    .exec() as Promise<Account[]>
}

export function findCreditOverDraftByIds(ids): Promise<Account[]> {
  const sqlDb = WalletSQLInMemory.getInstance()
  const accountTable = sqlDb.getTable(inMemoryTableNames.ACCOUNT)
  const conditions = [
    accountTable.accountType.in([AccountType.CREDIT_CARD, AccountType.OVERDRAFT]),
    accountTable.creditCard.isNotNull(),
  ]

  if (ids && !_isEmpty(ids)) {
    conditions.push(accountTable._id.in(ids))
  }

  return sqlDb
    .getDatabase()
    .select()
    .from(accountTable)
    .where(lf.op.and(...conditions))
    .exec() as Promise<Account[]>
}

export function getCountOfCashAccounts(): Promise<number> {
  const sqlDb = WalletSQLInMemory.getInstance()
  const accountTable = sqlDb.getTable(inMemoryTableNames.ACCOUNT)
  return sqlDb
    .select(lf.fn.count(accountTable._id).as("count"))
    .from(accountTable)
    .where(
      lf.op.and(accountTable.accountType.eq(AccountType.CASH), accountTable.archived.neq(true)),
    )
    .exec()
    .then(convertResultToScalar("count"))
}

export function findAllNonExcludedAsIds(): Promise<Id[]> {
  const sqlDb = WalletSQLInMemory.getInstance()
  const accountTable = sqlDb.getTable(inMemoryTableNames.ACCOUNT)
  return sqlDb
    .select(accountTable._id)
    .from(accountTable)
    .where(accountTable.excludeFromStats.neq(true))
    .orderBy(accountTable.position)
    .exec()
    .then((result) => result.map((document: Account) => document._id))
}

export async function updateBulk(accountDocuments: Account[]) {
  return commonRepository.updateBulk(
    accountDocuments,
    inMemoryTableNames.ACCOUNT,
    accountConverter(),
  ) as Promise<Account[]>
}
