import * as dashboardService from 'src/backend/dashboard/service'
import { receiveDashboardWidget } from 'src/frontend/scenes/dashboard/actions'
import { selectDashboardFilter, selectDashboardWidgets } from 'src/frontend/scenes/dashboard/selectors'
import { WidgetType } from 'src/frontend/scenes/dashboard/types'
import { FilterType } from 'src/types/Filter'
import { Id } from 'src/types/CouchDb'
import { findWidgetById } from 'src/frontend/scenes/dashboard/helpers'
import { RelativeIntervalType } from 'src/backend/enums'
import { getNextPeriodFilter } from 'src/backend/filters/helpers'
import { isRelativeInterval } from 'src/backend/time/time'
import { HashMap } from 'src/types/common'

export function changeSubject(id: Id, newSubject: string) {
  return async (dispatch: Function, getState: Function) => {
    const dashboardFilter: FilterType = selectDashboardFilter(getState())
    const widgets: HashMap<WidgetType> = selectDashboardWidgets(getState())

    const periodToPeriodWidget = findWidgetById(widgets, id)

    if (!periodToPeriodWidget) {
      return null
    }

    const mergedFilter = { ...dashboardFilter, ...periodToPeriodWidget.filter }
    const filter = isRelativeInterval(mergedFilter.period.interval)
      && periodToPeriodWidget.options.relativeIntervalValue === RelativeIntervalType.NEXT
        ? getNextPeriodFilter(mergedFilter)
        : mergedFilter

    const options = {
      ...periodToPeriodWidget.options,
      subject: newSubject,
    }

    const data = await dashboardService.fetchPeriodToPeriodChartData(filter, options)

    const updatedWidget = {
      ...periodToPeriodWidget,
      data,
      options,
    }

    return dispatch(receiveDashboardWidget(updatedWidget))
  }
}
