import React from 'react'
import PropTypes from 'prop-types'
import { Loader } from 'semantic-ui-react'
import { FormattedMessage } from 'src/frontend/modules/intl'

const LoadingSpinner = () => (<Loader active inline="centered" />)

const ConfirmQuestion = ({ accountName }) => (
  <div>
    <FormattedMessage
      id="settings.accounts.remove.account-text"
      values={{ accountName: <strong>{accountName.toUpperCase()}</strong> }}
    />
  </div>
)

ConfirmQuestion.propTypes = {
  accountName: PropTypes.string.isRequired,
}

const RemoveAccountContent = ({ accountName, canDelete, cantDeleteReason, processing }) => {
  if (canDelete) {
    return !processing ? <ConfirmQuestion accountName={accountName} /> : <LoadingSpinner />
  } else {
    return <FormattedMessage id={cantDeleteReason} />
  }
}

RemoveAccountContent.propTypes = {
  accountName: PropTypes.string.isRequired,
  canDelete: PropTypes.bool.isRequired,
  cantDeleteReason: PropTypes.string,
  processing: PropTypes.bool.isRequired,
}

RemoveAccountContent.defaultProps = { cantDeleteReason: 'form.yes' }

export default RemoveAccountContent
