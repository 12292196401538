export enum DeepLink {
  NEW_RECORD = 'new-record',
  OPEN_BILLING = 'open-billing',
  OPEN_TRIAL = 'open-trial',
  SET_DASHBOARD_ACCOUNT = 'set-dashboard-account',
  SETTINGS_USER_PROFILE = 'open-user-profile',
  ADD_ACCOUNT = 'add-account',
  GROUP = 'group',
  LANDING_PAGE = 'landing-page',
  OPEN_CONNECT_BANK_ACCOUNT = 'connect-bank-account',
  MODULE_BUDGETS = 'limit',
  MODULE_PLANNED_PAYMENTS = 'planned-payments',
  MODULE_DEBTS = 'debts',
  OPEN_GOOGLE_PLAY = 'google-play',
  MODULE_SHOPPING_LIST = 'shopping-list',
  SETTINGS_LABELS = 'settings-labels',
  SETTINGS_SMART_ASSISTANT = 'settings-smart-assistant',
  MODULE_IMPORTS = 'imports',
  MODULE_EXPORTS = 'exports',
  WATCH_YOUTUBE_VIDEO = 'watch-youtube-video',
  OPEN_WEB_PAGE = 'open-web-page',
  MODULE_LOYALTY_CARD = 'module-loyalty-card',
  VOUCHER = 'voucher',
  NOTIFICATION_CENTRE = 'notification-centre',
  WALLET_LIFE = 'wallet-life',
  GROUP_SHARING = 'open-group-settings',
  MODULE_GOALS = 'goals',
  BILLING_SPECIAL_OFFER = 'special-offer',
  GOAL_DISTRIBUTE = 'goal-distribute',
  REWARD_FEED = 'reward-feed',
  PLAY_GAME = 'play-game',
  WALLET_NOW = 'wallet-now',
  STATS_BALANCE = 'statistics-balance',
  STATS_OUTLOOK = 'statistics-outlook',
  STATS_SPENDING = 'statistics-spending',
  STATS_CASH_FLOW = 'statistics-cashflow',
  STATS_CREDIT = 'statistics-credit',
  STATS_REPORT = 'statistics-report',
  ACCOUNTS = 'accounts',
}
