import * as actions from './actions'
import { Action } from 'src/types/common'
import { Billing } from 'src/types/Billing'
import BraintreeProduct = Billing.BraintreeProduct
import { BUY_PLAN_END, BUY_PLAN_EXPIRE_PRODUCT } from 'src/frontend/scenes/billing/paymentMethod/actions'
import { BILLING_MODAL_CLOSE } from 'src/frontend/scenes/billing/billingModal/actions'
import { GET_VOUCHER_PRODUCT_SUCCESS } from 'src/frontend/scenes/billing/product/actions'
import VoucherTransactionResponse = Billing.VoucherTransactionResponse

export interface BillingProductState {
  recurringProducts: BraintreeProduct[],
  lifetimeProduct: BraintreeProduct,
  voucherProduct: VoucherTransactionResponse,
  voucherCode: string,
  selectedProductId: string,
  productsLoading: boolean,
  error: string,
}

const initialProductState: BillingProductState = {
  lifetimeProduct: null,
  recurringProducts: null,
  voucherProduct: null,
  voucherCode: null,
  productsLoading: false,
  selectedProductId: null,
  error: null,
}

function product(state: BillingProductState = initialProductState, action: Action): BillingProductState {
  switch (action.type) {
    case actions.GET_PRODUCTS_START: {
      return { ...state, productsLoading: true }
    }
    case actions.GET_PRODUCTS_SUCCESS: {
      return {
        ...state,
        productsLoading: false,
        recurringProducts: action.payload.recurringProducts,
        lifetimeProduct: action.payload.lifetimeProduct,
      }
    }

    case actions.SELECT_PRODUCT: {
      return { ...state, selectedProductId: action.payload }
    }

    case actions.GET_PRODUCTS_ERROR: {
      return { ...state, productsLoading: false }
    }

    case BILLING_MODAL_CLOSE:
    case BUY_PLAN_END: {
      return initialProductState
    }

    case BUY_PLAN_EXPIRE_PRODUCT: {
      return { ...initialProductState, error: 'billing.error.product-expired' }
    }

    case GET_VOUCHER_PRODUCT_SUCCESS: {
      const { voucherResponse: voucherProduct, voucherCode } = action.payload
      return { ...state, voucherProduct, voucherCode }
    }

    default:
      return state
  }
}

export default product
