import React from 'react'
import { Menu } from 'semantic-ui-react'
import { FormattedMessage } from 'src/frontend/modules/intl'
import {
  isExpense, isIncome, isTransfer, recordIsUncleared,
} from 'src/backend/records/helpers'
import { RecordType } from 'src/backend/enums'
import './SelectRecordType.less'
import { RecordFormValues } from 'src/frontend/scenes/records/recordForm/types'

interface Props {
  formValues: RecordFormValues,
  isAccountConnected: boolean,
  onRecordTypeChange: Function,
}

SelectRecordType.defaultProps = {
  isAccountConnected: false,
}

function SelectRecordType({
  formValues,
  isAccountConnected,
  onRecordTypeChange,
}: Props) {

  return (
    <Menu className="record-form-menu" fluid widths={3} compact inverted>
      <Menu.Item
        name="Expense"
        active={isExpense(formValues) && !isTransfer(formValues)}
        disabled={isAccountConnected && (!isExpense(formValues) || recordIsUncleared(formValues))}
        onClick={() => {
          if (!isAccountConnected || isExpense(formValues)) {
            onRecordTypeChange(RecordType.EXPENSE, false, formValues)
          }
        }}
      >
        <FormattedMessage id={{ W: 'expense', B: 'money-spent' }} />
      </Menu.Item>

      <Menu.Item
        name="Income"
        active={isIncome(formValues) && !isTransfer(formValues)}
        disabled={isAccountConnected && (!isIncome(formValues) || recordIsUncleared(formValues))}
        onClick={() => {
          if (!isAccountConnected || isIncome(formValues)) {
            onRecordTypeChange(RecordType.INCOME, false, formValues)
          }
        }}
      >
        <FormattedMessage id={{ W: 'income', B: 'money-received' }} />
      </Menu.Item>

      <Menu.Item
        name="Transfer"
        active={isTransfer(formValues)}
        disabled={isAccountConnected && recordIsUncleared(formValues)}
        onClick={() => {
          if (!isTransfer(formValues)) {
            onRecordTypeChange(formValues.type, true, formValues)
          }
        }}
      >
        <FormattedMessage id="record.form.transfer" />
      </Menu.Item>
    </Menu>
  )
}

export default SelectRecordType
