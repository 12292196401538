import { selectModules } from 'src/frontend/modules/selectors'
import { RootState } from 'src/types/State'
import { createSelector } from 'reselect'
import { ModuleStatus } from 'src/frontend/modules/moduleStatus/types'
import { ModuleStatusState } from 'src/frontend/modules/moduleStatus/reducer'

export const selectModuleStatus = (state: RootState): ModuleStatusState => selectModules(state).moduleStatus


export const selectDashboardStatus = (state: RootState): ModuleStatus => selectModuleStatus(state).dashboard

export const selectShouldRefreshDashboard = createSelector(
  selectDashboardStatus,
  (status) => status === ModuleStatus.EXPIRED,
)

export const selectIsDashboardLoading = createSelector(
  selectDashboardStatus,
  (status) => status === ModuleStatus.LOADING,
)

export const selectIsDashboardUpToDate = createSelector(
  selectDashboardStatus,
  (status) => status === ModuleStatus.UP_TO_DATE,
)


export const selectRecordsStatus = (state: RootState): ModuleStatus => selectModuleStatus(state).records

export const selectShouldRefreshRecords = createSelector(
  selectRecordsStatus,
  (status) => status === ModuleStatus.EXPIRED,
)

export const selectIsRecordsLoading = createSelector(
  selectRecordsStatus,
  (status) => status === ModuleStatus.LOADING,
)

export const selectIsRecordsUpToDate = createSelector(
  selectRecordsStatus,
  (status) => status === ModuleStatus.UP_TO_DATE,
)


export const selectDetailRecordsStatus = (state: RootState): ModuleStatus => selectModuleStatus(state).detailRecords

export const selectShouldRefreshDetailRecords = createSelector(
  selectDetailRecordsStatus,
  (status) => status === ModuleStatus.EXPIRED,
)

export const selectIsDetailRecordsLoading = createSelector(
  selectDetailRecordsStatus,
  (status) => status === ModuleStatus.LOADING,
)

export const selectIsDetailRecordsUpToDate = createSelector(
  selectDetailRecordsStatus,
  (status) => status === ModuleStatus.UP_TO_DATE,
)


export const selectAccountsStatus = (state: RootState): ModuleStatus => selectModuleStatus(state).accountsTotals

export const selectShouldRefreshAccounts = createSelector(
  selectAccountsStatus,
  (status) => status === ModuleStatus.EXPIRED,
)

export const selectIsAccountsLoading = createSelector(
  selectAccountsStatus,
  (status) => status === ModuleStatus.LOADING,
)

export const selectIsAccountsUpToDate = createSelector(
  selectAccountsStatus,
  (status) => status === ModuleStatus.UP_TO_DATE,
)


export const selectAnalyticsStatus = (state: RootState): ModuleStatus => selectModuleStatus(state).analytics

export const selectShouldRefreshAnalytics = createSelector(
  selectAnalyticsStatus,
  (status) => status === ModuleStatus.EXPIRED,
)

export const selectIsAnalyticsLoading = createSelector(
  selectAnalyticsStatus,
  (status) => status === ModuleStatus.LOADING,
)

export const selectIsAnalyticsUpToDate = createSelector(
  selectAnalyticsStatus,
  (status) => status === ModuleStatus.UP_TO_DATE,
)


export const selectImportsRecordListStatus = (state: RootState): ModuleStatus => selectModuleStatus(state).importsRecordList

export const selectShouldRefreshImportsRecordList = createSelector(
  selectImportsRecordListStatus,
  (status) => status === ModuleStatus.EXPIRED,
)

export const selectIsImportsRecordListLoading = createSelector(
  selectImportsRecordListStatus,
  (status) => status === ModuleStatus.LOADING,
)

export const selectIsImportsRecordListUpToDate = createSelector(
  selectImportsRecordListStatus,
  (status) => status === ModuleStatus.UP_TO_DATE,
)
