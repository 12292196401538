import React from 'react'
import Widget from 'src/frontend/scenes/dashboard/components/Widget/Widget'
import { WidgetView } from 'src/frontend/scenes/dashboard/types'
import LinearChart, { Props as LinearChartProps } from 'src/frontend/components/chart/LinearChart'
import { chartHasEqualProps } from 'src/frontend/scenes/dashboard/helpers'


interface Props {
  widget: WidgetView & { data: LinearChartProps },
}

function BalanceChart({ widget }: Props) {
  const { data, filter, options } = widget

  if (!data || !data.datasets || !data.datasets[0] || !options || !filter) {
    return <Widget.Placeholder />
  }

  const content = !data.datasets[0].isEmpty
    ? (
      <Widget.Content>
        <LinearChart {...data} />
      </Widget.Content>
    )
    : <Widget.EmptyContent type="line" />

  return (
    <Widget>
      <Widget.Header title={widget.title} />
      {data.closingBalance !== null ? (
          <Widget.Summary
            period={filter.period}
            value={data.closingBalance}
            currencyCode={data.currency.code}
            intervalType={options.relativeIntervalValue}
            changeValue={data.balanceChange}
          />
        )
        : null}
      {content}
    </Widget>
  )
}

export default React.memo(BalanceChart, chartHasEqualProps)
