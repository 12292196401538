import { getAdwordsSignUpId, getGATrackingId } from 'src/common/environment'
import * as logger from 'src/common/logger'

type EventArgs = {
  action: string,
  category?: string,
  label?: string,
  value?: number,
}

declare interface GaEventCall {
  (label?: string, value?: number): void
}

declare const window: CustomWindow

function initializeGA(gaTrackingId: string): Promise<void> {
  return new Promise((resolve, reject) => {
    console.log(`initializeGA(${gaTrackingId})`)
    const head = document.getElementsByTagName('head')[0]
    const script = document.createElement('script')
    script.async = true
    script.src = `https://www.googletagmanager.com/gtag/js?id=${gaTrackingId}`
    head.appendChild(script)
    script.onload = function () {
      console.log(`script for ${gaTrackingId} loaded`)
      window.dataLayer = window.dataLayer || []
      window.gtag = function () {
        window.dataLayer.push(arguments)
      }
      gtag('js', new Date())
      gtag('config', gaTrackingId)
      console.log(`tracker ${gaTrackingId} initialized`)
      return resolve()
    }
    script.onerror = function () {
      return reject(new Error('gtag.js not loaded'))
    }
  })
}


export function initialize(): Promise<void> {
  if (getGATrackingId()) {
    return initializeGA(getGATrackingId())
      .then(() => console.log(`GA initialized with tracking id=${getGATrackingId()}`))
      // don't prevent app from starting when GA failed
      .catch(error => {
        // create mock function to prevent calling gtag of undefined
        window.gtag = function (...args) {
          console.log(`Mock gtag because of gtag script loading error: gtag(${JSON.stringify(args)}`)
        }
        // intentionally not logged to Sentry since 5k users block GTM with AdBlock etc
        console.error(error)
      })
  } else {
    const error = 'Missing GA_TRACKING_ID, Google analytics not initialized.'
    console.warn(error)
    logger.captureException(new Error(error))
    return Promise.resolve()
  }
}

function event({ action, category, label, value }: EventArgs): void {
  return gtag('event', action, {
    send_to: getGATrackingId(),
    event_category: category,
    event_label: label,
    value,
  })
}

/*
 * Purchase events
 */
export const showProducts: GaEventCall = purchasing('Show products')

export const buyPlanStart: GaEventCall = purchasing('Buy plan start')

export const selectProduct: GaEventCall = purchasing('Product selected')

export const billingInfoFinished: GaEventCall = purchasing('Billing info finished')

export const buyPlanFinished: GaEventCall = purchasing('Buy plan finished')

function purchasing(action: string): GaEventCall {
  return (label?: string, value?: number) => event({
    action,
    category: 'purchasing',
    label,
    value,
  })
}

/*
 * Sign up & onboarding events
 */
export const signUpStart: GaEventCall = onBoarding('Sign Up start')

const signUpFinishedGa = onBoarding('Sign Up finished')

const signUpFinishedAd = () => {
  if (getAdwordsSignUpId()) {
    console.log(`signUpFinishedAd sending Adwords event to ${getAdwordsSignUpId()}`)
    gtag('event',
      'conversion',
      {
        send_to: getAdwordsSignUpId(),
        // to avoid duplicate events userId is suggested as a transaction ID
        transaction_id: undefined,
      })
  }
}

export const signUpFinished: GaEventCall = (l?: string, v?: number) => {
  signUpFinishedGa(l, v)
  signUpFinishedAd()
}

export const onboardingStart: GaEventCall = onBoarding('Onboarding start')

export const onboardingFinished: GaEventCall = onBoarding('Onboarding finished')

function onBoarding(action: string): GaEventCall {
  return (label?: string, value?: number) => event({ action, category: 'onboarding', label, value })
}

/*
 * Google analytics
 */
export function set(options): void {
  return gtag('set', options)
}

export function gaPageView() {
  return gtag('event', 'page_view', { send_to: getGATrackingId() })
}

/*
 * Android rating
 */
export function androidAppRating(rating: number) {
  return event({
    action: 'Rate',
    category: 'Android Rating',
    value: rating,
  })
}

/*
* Bank integrations
* */
export const bankIntegrationStart: GaEventCall = bankIntegration('Bank integration start')

export const bankFound: GaEventCall = bankIntegration('Bank found')

function bankIntegration(action: string): GaEventCall {
  return (label?: string, value?: number) => event({ action, category: 'Bank integration', label, value })
}
