import { BraintreeTransaction } from 'src/frontend/scenes/billing/transactionHistory/types'
import ReactPDF, { Text, View } from '@react-pdf/renderer'
import { formatMessage, formatNumber } from 'src/frontend/modules/intl'
import { formatDateYearShort } from 'src/frontend/modules/intl/i18n'
import React from 'react'
import { getAppName } from 'src/common/environment'

interface TableProps {
  transaction: BraintreeTransaction
}

export default function ReceiptTable({ transaction }: TableProps) {
  return (
    <View
      style={{
        display: 'table',
        width: 'auto',
        fontSize: 9,
        marginBottom: '30pt',
        border: '1pt solid #e5e9ed',
      } as unknown as ReactPDF.Style}
    >
      <View
        style={{
          fontFamily: 'Helvetica-Bold',
          padding: '10pt',
          display: 'flex',
          flexDirection: 'row',
          backgroundColor: '#eff0f2',
        }}
      >
        <Text style={{ width: '12%' }}>{formatMessage('billing.receipt.date')}</Text>
        <Text style={{ width: '28%' }}>{formatMessage('billing.receipt.description')}</Text>
        <Text style={{ width: '20%' }}>{formatMessage('billing.receipt.period')}</Text>
        <Text style={{ width: '10%', textAlign: 'right' }}>{formatMessage('billing.receipt.amount')}</Text>
        <Text style={{ width: '10%', textAlign: 'right' }}>{formatMessage('billing.receipt.vat')} %</Text>
        <Text style={{ width: '10%', textAlign: 'right', textTransform: 'capitalize' }}>
          {formatMessage('billing.receipt.vat')}
        </Text>
        <Text style={{ width: '10%', textAlign: 'right' }}>{formatMessage('billing.receipt.subtotal')}</Text>
      </View>
      <View
        style={{
          padding: '10pt 10pt 100pt 10pt',
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        {/* Payment date */}
        <Text style={{ width: '12%' }}>{formatDateYearShort(new Date(transaction.createdAt))}</Text>
        {/* Description (Product) */}
        {/* tslint:disable-next-line:jsx-use-translation-function */}
        <Text style={{ width: '28%' }}>{getAppName()} Premium</Text>
        {/* Subscription period */}
        <Text style={{ width: '20%' }}>
          {transaction.billingStartDate && transaction.billingEndDate && (
            <>
              {formatDateYearShort(new Date(transaction.billingStartDate))}
              &nbsp;
              -
              &nbsp;
              {formatDateYearShort(new Date(transaction.billingEndDate))}
            </>
          )}
        </Text>
        {/* Tax base with currency */}
        <Text style={{ width: '10%', textAlign: 'right' }}>
          {formatNumber(
            transaction.amount - transaction.taxAmount,
            { style: 'currency', currency: transaction.currencyCode },
          )}
        </Text>
        {/* Tax amount - percentage */}
        <Text style={{ width: '10%', textAlign: 'right' }}>
          {taxPercentage(transaction)}
        </Text>
        {/* Tax amount - with currency */}
        <Text style={{ width: '10%', textAlign: 'right' }}>
          {formatNumber(
            transaction.taxAmount,
            { style: 'currency', currency: transaction.currencyCode },
          )}
        </Text>
        {/* Subtotal: price including tax with currency */}
        <Text style={{ width: '10%', textAlign: 'right' }}>
          {formatNumber(transaction.amount, { style: 'currency', currency: transaction.currencyCode })}
        </Text>
      </View>
      <View
        style={{
          borderTop: '1pt solid #e5e9ed',
          padding: '10pt',
          display: 'flex',
          flexDirection: 'row',
          fontFamily: 'Helvetica-Bold',
        }}
      >
        <View style={{ marginLeft: 'auto', marginRight: '30pt' }}>
          <Text style={{ textTransform: 'capitalize' }}>
            {formatMessage('billing.receipt.total')}
          </Text>
        </View>
        <View>
          <Text>
            {formatNumber(transaction.amount, { style: 'currency', currency: transaction.currencyCode })}
          </Text>
        </View>
      </View>
    </View>
  )
}

function isDecimal(value: number): boolean {
  return /\.0+$/.test((value * 100).toFixed(2))
}

function taxPercentage(transaction: BraintreeTransaction) {
  const taxRatio = transaction.taxAmount / (transaction.amount - transaction.taxAmount)
  if (isDecimal(taxRatio)) {
    return formatNumber(taxRatio, { format: 'percentage' })
  } else {
    return formatNumber(taxRatio, { style: 'percent', maximumFractionDigits: 1 })
  }
}
