import React, { useEffect, useMemo } from 'react'
import styles from './HighlightedText.module.less'
import _isEqual from 'lodash/isEqual'
import { escapeStringRegexp } from 'src/common/utils'

interface Props {
  text: string,
  id: string | number
  minCharacters: number
  onValidChanged: (id: string | number, value: boolean) => void
  keywords: string[]
}

function HighlightedText({ text, keywords, id, minCharacters, onValidChanged }: Props) {
  if (keywords) {
    const byPartsRegExp = new RegExp(`(${keywords.map(escapeStringRegexp).join('|')})`, 'gi')
    const parts = useMemo(() => text.split(byPartsRegExp), [byPartsRegExp, text])

    const isSomethingValid = parts.some(
      part => part && keywords.some((highlight) => part.toLowerCase() === highlight.toLowerCase()))

    useEffect(() => {
      onValidChanged(id, isSomethingValid)
    }, [isSomethingValid, keywords, id])

    return (
      <p className={styles.line}>
        {parts.map((part, i) => (
          <span
            key={i}
            className={part && part.length >= minCharacters && keywords.some((highlight) => part.toLowerCase()
              === highlight.toLowerCase())
              ? styles.highlight
              : undefined}
          >
            {part}
          </span>
        ))}
      </p>
    )
  } else {
    return <p className={styles.line}><span>{text}</span></p>
  }
}

export default React.memo(HighlightedText, ((prevProps, nextProps) => {
  return prevProps.text === nextProps.text
    && _isEqual(prevProps.keywords, nextProps.keywords)
}))
