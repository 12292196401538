import React from 'react'
import { Icon } from 'semantic-ui-react'
import { Contact } from 'src/types/Contact'
import styles from 'src/frontend/scenes/records/components/ContactView.module.less'
import defaultProfileImage from 'src/frontend/assets/images/user_ic.svg'
import { mergeClasses } from 'src/common/utils'

interface Props {
  contact: Contact.ContactView
  compact?: boolean,
  inField?: boolean,
  showRemoveAction?: boolean
  onRemove?: Function
}

ContactView.defaultProps = {
  showRemoveAction: false,
  onRemove: undefined,
  compact: false,
  inField: false,
}

export default function ContactView({ contact, showRemoveAction, compact, inField, onRemove }: Props) {
  const compactClass = compact && styles.compact
  const inFieldClass = inField && styles.infield

  return contact ? (
    <div
      className={mergeClasses(styles.contactView, compactClass, inFieldClass)}
    >
      <div
        className={styles.avatar}
        style={{ backgroundImage: `url("${contact.avatarUrl || defaultProfileImage}")` }}
      />
      <div className={styles.name}>{contact.name}</div>
      {showRemoveAction && (
        <div className={styles.remove}>
          <Icon
            name="times"
            fitted
            onClick={onRemove}
          />
        </div>
      )}
    </div>
  ) : null
}
