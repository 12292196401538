export enum IntegrationErrorType {
  RemoteApiError = 0,
  SystemError = 1,
  DatabaseError = 2,
  ObjectNotFound = 3,
  IllegalState = 4,
  RefreshNotPossibleTemporary = 5,
  AccountAlreadyConnected = 6,
  AccountNotConnected = 7,
  ProtobufferMappingError = 8,
  LoginDuplicated = 9,
  ConsentExpired = 10,
  ConsentRevoked = 11,
  ConsentNotFound = 12,
}
