import React from 'react';
import { Dropdown } from 'semantic-ui-react';
import { getSupportedLanguages } from 'src/frontend/modules/intl';
import styles from './ChangeLanguage.module.less';
import { IntlConsumerType } from 'src/frontend/modules/intl/components/types';
import { withIntlConsumer } from 'src/frontend/modules/intl/components/withIntlConsumer';
import { mergeClasses } from 'src/common/utils';

const options = getSupportedLanguages().map((language) => {
  return { key: language.code, text: language.name, value: language.code };
});

interface Props extends IntlConsumerType {
  inverted?: boolean;
  inline?: boolean;
}

ChangeLanguage.defaultProps = {
  inverted: false,
  inline: false,
};

function ChangeLanguage({ code, inverted, inline, changeLanguage }: Props) {
  return (
    <div
      className={mergeClasses(
        styles.changeLanguage,
        inline && styles.inline,
        inverted && styles.inverted,
      )}
    >
      <Dropdown
        scrolling
        inline
        pointing="top right"
        value={code}
        options={options}
        onChange={changeLanguage}
      />
    </div>
  );
}

export default withIntlConsumer(ChangeLanguage);
