import React from 'react'
import { connect } from 'react-redux'
import { Divider, Modal } from 'semantic-ui-react'
import { FormattedMessage } from 'src/frontend/modules/intl/index'
import { closeModalAddVoucher, openModalAddVoucher } from 'src/frontend/modules/modals/actions'
import { selectAddVoucherModalStatus, selectIsAddVoucherModalOpen } from 'src/frontend/modules/modals/selectors'
import { EventCallback } from 'src/types/Semantic-ui'
import AddVoucherFormContainer from 'src/frontend/modules/modals/components/AddVoucherFormContainer'
import { AddVoucherModalStatus } from 'src/frontend/modules/modals/components/AddVoucherTypes'
import { PrimaryButton } from 'src/frontend/components/Buttons/Buttons'
import PictureIcon from 'src/frontend/components/PictureIcon/PictureIcon'
import styles from 'src/frontend/modules/modals/components/AddVoucherModal.module.less'

interface Actions {
  close: EventCallback,
  backToForm: () => void,
}

interface Props {
  modalOpen: boolean,
  modalStatus: AddVoucherModalStatus,
}

function mapStateToProps(state): Props {
  return ({
    modalOpen: selectIsAddVoucherModalOpen(state),
    modalStatus: selectAddVoucherModalStatus(state),
  })
}

const mapDispatchToProps: Actions = {
  close: closeModalAddVoucher,
  backToForm: () => openModalAddVoucher(''),
}

function ModalContentHoc({ status, close, backToForm }) {
  switch (status) {
    case AddVoucherModalStatus.FORM: {
      return (
        <AddVoucherFormContainer />
      )
    }

    case AddVoucherModalStatus.ERROR: {
      return (
        <div className={styles.content}>
          <h1><FormattedMessage id="app.error.generic_error_message" /></h1>
          <p><FormattedMessage id="modals.add_voucher.error" /></p>
          <Divider hidden section />
          <PrimaryButton size="big" fluid onClick={backToForm}>
            <FormattedMessage id="modals.add_voucher.try_again" />
          </PrimaryButton>
        </div>
      )
    }

    case AddVoucherModalStatus.SUCCESS: {
      return (
        <div className={styles.content}>
          <PictureIcon name="onboarding-finish" compact size="15rem" />
          <h1><FormattedMessage id="form.done" /></h1>
          <p><FormattedMessage id="modals.add_voucher.done" /></p>
          <Divider hidden section />
          <PrimaryButton size="big" fluid onClick={close}>
            <FormattedMessage id="form.ok" />
          </PrimaryButton>
        </div>
      )
    }

    default: {
      console.error(`ModalContentHoc unsupported status=${status}`)
      return null
    }
  }
}

function AddVoucherModal({ modalOpen, modalStatus, close, backToForm }: Props & Actions) {
  return (
    <Modal open={modalOpen} size="small">
      <Modal.Header>
        <FormattedMessage id="modals.add_voucher.header" />
        <span className="modal-close" onClick={close} />
      </Modal.Header>
      <Modal.Content>
        <ModalContentHoc status={modalStatus} close={close} backToForm={backToForm} />
      </Modal.Content>
    </Modal>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(AddVoucherModal)
