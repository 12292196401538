import React from 'react'
import styles from './ConfirmPanel.module.less'
import { Billing } from 'src/types/Billing'
import BraintreeProduct = Billing.BraintreeProduct
import { formatMessage, FormattedMessage, FormattedMoney } from 'src/frontend/modules/intl'
import { PrimaryButton } from 'src/frontend/components/Buttons/Buttons'
import PeriodType = Billing.PeriodType
import { isUndefinedOrNull } from 'src/common/utils'
import { isRecurringProduct } from 'src/frontend/scenes/billing/product/helpers'
import { PaymentMethodFormType } from 'src/frontend/scenes/billing/paymentMethod/components/types'

interface Props {
  selectedProduct: BraintreeProduct,
  disabled?: boolean,
  loading?: boolean,
  formType: PaymentMethodFormType
  onClick: () => void
}

ConfirmPanel.defaultProps = {
  disabled: false,
  loading: false,
}

export default function ConfirmPanel({ selectedProduct, formType, disabled, loading, onClick }: Props) {
  if (formType === PaymentMethodFormType.UPDATE) {
    return (
      <PrimaryButton
        disabled={disabled}
        fluid
        loading={loading}
        onClick={onClick}
      >
        <FormattedMessage id="billing.payment-method.update" />
      </PrimaryButton>
    )
  }

  return (
    <div className={styles.confirmPanel}>
      <div className={styles.details}>
        <div className={styles.leftPanel}>
          <div className={styles.price}>
            <FormattedMessage id="billing.amount.total" />:
          </div>
          {!isUndefinedOrNull(selectedProduct.taxAmount) && (
            <div className={styles.description}>
              <FormattedMessage
                id="billing.amount.vat-included"
              />
            </div>
          )}
        </div>
        <div className={styles.rightPanel}>
          <div className={styles.price}>
            <FormattedMoney
              value={selectedProduct.price * 100}
              currency={selectedProduct.currencyCode}
            />
          </div>
          {selectedProduct.product.period !== Billing.PeriodType.UNLIMITED && (
            <div className={styles.description}>
              <FormattedMessage
                id="billing.subscription.billed-frequency"
                values={{
                  frequency: formatMessage(`billing.subscription.billed-frequency.${PeriodType[selectedProduct.product.period]}`),
                }}
              />
            </div>
          )}
        </div>
      </div>
      <PrimaryButton
        disabled={disabled}
        fluid
        loading={loading}
        onClick={onClick}
      >
        <FormattedMessage
          id={isRecurringProduct(selectedProduct)
            ? 'billing.subscription.subscribe'
            : 'billing.pay-button'}
        />
      </PrimaryButton>
    </div>
  )
}
