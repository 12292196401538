import { combineReducers } from 'redux'

import accountsReducer from './modules/accounts/reducer'
import categoriesReducer from './modules/categories/reducer'
import currenciesReducer from './modules/currencies/reducer'
import labelsReducer from './modules/labels/reducer'
import standingOrdersReducer from './modules/standingOrders/reducer'
import templatesReducer from './modules/templates/reducer'
import userReducer from './modules/user/reducer'
import rewardPointsReducer from './modules/rewardPoints/reducer'
import filtersReducer from './modules/filters/reducer'
import filterReducer from './modules/filter/reducer'
import modalsReducer from './modules/modals/reducer'
import configuresReducer from './modules/configures/reducer'
import webConfigReducer from './modules/webConfig/reducer'
import contactsReducer from 'src/frontend/modules/contacts/reducer'
import contactsSceneReducer from 'src/frontend/scenes/contacts/reducer'
import integrationsReducer from 'src/frontend/scenes/integrations/reducer'
import moduleStatusReducer from './modules/moduleStatus/reducer'
import appReducer from './scenes/app/reducer'
import dashboardReducer from './scenes/dashboard/reducer'
import recordsReducer from './scenes/records/reducer'
import analyticsReducer from './scenes/analytics/reducer'
import settingsReducer from './scenes/settings/reducer'
import importsReducer from 'src/frontend/scenes/imports/reducer'
import gdprReducer from 'src/frontend/scenes/gdpr/reducer'
import onBoardingReducer from 'src/frontend/scenes/onBoarding/reducer'
import billingReducer from 'src/frontend/scenes/billing/reducer'
import magicRulesReducer from 'src/frontend/modules/magicRules/reducer'
import * as appActions from 'src/frontend/scenes/app/actions'
import accountsSceneReducer from 'src/frontend/scenes/accounts/reducer'

const modules = combineReducers({
  accounts: accountsReducer,
  categories: categoriesReducer,
  currencies: currenciesReducer,
  labels: labelsReducer,
  standingOrders: standingOrdersReducer,
  templates: templatesReducer,
  filters: filtersReducer,
  filter: filterReducer,
  user: userReducer,
  rewardPoints: rewardPointsReducer,
  modals: modalsReducer,
  configures: configuresReducer,
  webConfig: webConfigReducer,
  contacts: contactsReducer,
  moduleStatus: moduleStatusReducer,
  magicRules: magicRulesReducer,
})

const scenes = combineReducers({
  app: appReducer,
  dashboard: dashboardReducer,
  records: recordsReducer,
  analytics: analyticsReducer,
  imports: importsReducer,
  contacts: contactsSceneReducer,
  settings: settingsReducer,
  gdpr: gdprReducer,
  accounts: accountsSceneReducer,
  onBoarding: onBoardingReducer,
  billing: billingReducer,
  integrations: integrationsReducer,
})

const mainReducer = combineReducers({
  scenes, modules,
})

const rootReducer = (state, action) => {
  const appState = action.type === appActions.RESTORE_APP_STATE ? undefined : state
  return mainReducer(appState, action)
}

export default rootReducer
