import { useEffect } from 'react'

export function useToolTip() {


  useEffect(() => {
    window.addEventListener('resize', removeToolTip)
    // remove tooltip element, which is not managed by React
    return () => {
      removeToolTip()
      window.removeEventListener('resize', removeToolTip)
    }
  }, [])
}


function removeToolTip() {
  try {
    const tooltipElement = document.getElementById('chartjs-tooltip')
    if (tooltipElement && tooltipElement.parentElement && tooltipElement.parentElement.contains(tooltipElement)) {
      tooltipElement.parentElement.removeChild(tooltipElement)
    }
  } catch {
  }
}
