import React from 'react'
import styles from 'src/frontend/scenes/dashboard/components/Widget/Widget.module.less'
import { mergeClasses } from 'src/common/utils'

type Props = {
  children: React.ReactNode,
  className?: string,
}

Content.defaultProps = {
  className: undefined,
}

export default function Content({ children, className }: Props) {
  return (
    <div className={mergeClasses(styles.content, className)}>
      {children}
    </div>
  )
}
