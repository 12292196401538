import { reduceBy } from 'src/common/utils'
import * as service from 'src/backend/filters/service'

export const FILTERS_RECEIVE = 'FILTERS_RECEIVE'

export function receiveFilters(labelsArray) {
  const ID = '_id'
  const labels = labelsArray.reduce(reduceBy(ID), {})
  return { type: FILTERS_RECEIVE, payload: labels }
}

export function fetchFilters() {
  return dispatch => {
    return service.fetchFilters().then(filters => dispatch(receiveFilters(filters)))
  }
}
