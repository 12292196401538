import { countPercentChange } from 'src/common/helpers'
import { isNotHidden } from 'src/frontend/modules/categories/helpers'
import { Icon, Table } from 'semantic-ui-react'
import { CategoryIcon } from 'src/frontend/components/Icons/Icons'
import React from 'react'
import { FormattedMoney, FormattedPercentageChange } from 'src/frontend/modules/intl'
import { Currency } from 'src/types/Currency'
import { RecordCategoryLevel } from 'src/backend/categories/enums'
import { ReportEnvelope, ReportSubcategory, ReportSuperEnvelope } from 'src/frontend/scenes/analytics/Report/types'

interface Props {
  showIcon?: boolean
  namePadding?: string
  className?: string
  onClick?: Function
  onOpenCategoryPreview: Function
  shouldInvertPercent?: boolean
  referentialCurrency: Currency
  recordCategoryLevel: RecordCategoryLevel
  recordCategoryValue: number | string,
  data: ReportSuperEnvelope | ReportEnvelope | ReportSubcategory
}

ReportTableRow.defaultProps = {
  showIcon: false,
  namePadding: undefined,
  shouldInvertPercent: false,
  onClick: undefined,
  className: '',
}

export default function ReportTableRow({
  showIcon,
  data,
  referentialCurrency,
  namePadding,
  shouldInvertPercent,
  className,
  recordCategoryLevel,
  recordCategoryValue,
  onClick,
  onOpenCategoryPreview,
}: Props) {
  const refCashFlow = data.refIncome - data.refExpense
  const previousPeriodRefCashFlow = data.previousPeriodRefIncome - data.previousPeriodRefExpense
  const refCashFlowChange = shouldInvertPercent
    ? countPercentChange(-previousPeriodRefCashFlow, -refCashFlow)
    : countPercentChange(previousPeriodRefCashFlow, refCashFlow)


  const isZeroCurrentPeriod = data.refIncome === 0 && data.refExpense === 0
  const isZeroPreviousPeriod = data.previousPeriodRefIncome === 0 && data.previousPeriodRefExpense === 0
  const isHiddenRow = isZeroCurrentPeriod && !isNotHidden(data)
  const isZeroRow = isZeroCurrentPeriod && isZeroPreviousPeriod

  return !isHiddenRow
    ? (
      <Table.Row
        key={data.name}
        className={`report-row${isZeroRow ? ' zero' : ''} ${className}`}
        onClick={onClick}
      >
        <Table.Cell style={{ paddingLeft: namePadding }} width={5} singleLine>
          <div className="category-name-cell">
            {showIcon && <CategoryIcon color={data.color} icon={data.iconName} />}
            &nbsp;
            <span className="category-name">
          {data.name}
          </span>
          </div>
        </Table.Cell>
        <Table.Cell width={4} textAlign="right">
          <strong><FormattedMoney value={refCashFlow} currency={referentialCurrency.code} /></strong>
        </Table.Cell>
        <Table.Cell width={4} textAlign="right">
          <FormattedMoney value={previousPeriodRefCashFlow} currency={referentialCurrency.code} />
        </Table.Cell>
        <Table.Cell width={2} textAlign="right">
          <FormattedPercentageChange simple inverted={shouldInvertPercent} change={refCashFlowChange} />
        </Table.Cell>
        <Table.Cell width={1} textAlign="right" className="records-trigger" onClick={(e) => e.stopPropagation()}>
          {!isZeroCurrentPeriod && (
            <Icon
              className="records-preview-button"
              name="list ul"
              onClick={() => onOpenCategoryPreview(recordCategoryLevel, recordCategoryValue)}
            />
          )}
        </Table.Cell>
      </Table.Row>
    )
    : null
}
