import { createSelector } from "reselect"
import { isEnvelopeCategory } from "src/backend/categories/helpers"
import { selectNonArchivedAccountsAsArray } from "src/frontend/modules/accounts/selectors"
import { isNotHidden, sortByLocalizedCategoryName } from "src/frontend/modules/categories/helpers"
import { selectSortedCurrencies } from "src/frontend/modules/currencies/selectors"
import { getEnvelopeById, SYSTEM_CATEGORIES_UNKNOWN_ID } from "src/backend/categories/envelopes"
import { FilterRecordType, UserPaymentTypes, RecordState, UserRecordState } from "src/backend/enums"
import { formatMessage } from "src/frontend/modules/intl/i18n"
import _isEqual from "lodash/isEqual"
import _isEmpty from "lodash/isEmpty"
import { selectCompleteCategoriesHierarchy } from "src/frontend/modules/categories/selectors"
import { Option } from "src/frontend/components/Filter/types"
import { RootState } from "src/types/State"
import { FilterType } from "src/types/Filter"
import { Account } from "src/types/Account"
import { Id } from "src/types/CouchDb"
import { Currency } from "src/types/Currency"
import { Category, SuperEnvelope } from "src/types/Category"
import { HashMap } from "src/types/common"
import { isItemVisible } from "src/frontend/modules/filter/helpers"

export const selectFilterModule = (state: RootState) => state.modules.filter
export const selectDisplayOptions = (state: RootState) => selectFilterModule(state).display
export const selectFilter = (state: RootState) => selectFilterModule(state).filter
export const selectSelectedFilterId = (state: RootState): Id =>
  selectFilterModule(state).selectedFilterId
export const selectIsFilterLoading = (state: RootState): boolean =>
  selectFilterModule(state).loading
export const selectErrors = (state: RootState): HashMap<string> => selectFilterModule(state).errors
export const selectFilterFormOpen = (state: RootState) => selectFilterModule(state).filterFormOpen
export const selectIsRemoveFilterLoading = (state: RootState) =>
  selectFilterModule(state).removeLoading
export const selectInitialFilter = (state: RootState) => selectFilterModule(state).initialFilter

export const selectIsFilterChanged = createSelector(
  [selectFilter, selectInitialFilter],
  (filter, initialFilter) =>
    !_isEqual({ ...filter, period: null }, { ...initialFilter, period: null }),
)

export const selectCategoryOptions = createSelector(
  selectFilter,
  selectCompleteCategoriesHierarchy,
  getCategoryOptions,
)
export const selectAccountOptions = createSelector(
  selectFilter,
  selectNonArchivedAccountsAsArray,
  getAccountOptions,
)
export const selectCurrenciesOptions = createSelector(
  selectFilter,
  selectSortedCurrencies,
  getCurrenciesOptions,
)
export const selectRecordTypeOptions = createSelector(selectFilter, getRecordTypeOptions)
export const selectPaymentTypeOptions: (state: RootState) => Option[] = createSelector(
  selectFilter,
  getPaymentTypeOptions,
)
export const selectRecordStateOptions: (state: RootState) => Option[] = createSelector(
  selectFilter,
  getRecordStateOptions,
)

export enum CategoryFilterGroup {
  SUPER_ENVELOPE = "superEnvelopeIds",
  ENVELOPE = "envelopeIds",
  CATEGORY = "categoryId",
}

export function getCategoryOptions(
  filters: FilterType,
  completeCategoriesHierarchy: SuperEnvelope[],
) {
  return completeCategoriesHierarchy
    .filter(isNotHidden)
    .map((superEnvelope) =>
      envelopeToOptions(superEnvelope, filters, CategoryFilterGroup.SUPER_ENVELOPE),
    )
}

function envelopeToOptions(superEnvelope: SuperEnvelope, filters: FilterType, filterGroup) {
  const childrenOptions = superEnvelope?.envelopes
    ?.filter(isNotHidden)
    ?.map((envelope: Category) => secondLevelEnvelopeToOptions(envelope, filters, superEnvelope.id))
    ?.sort(sortByLocalizedCategoryName)

  const visible =
    (!childrenOptions && isItemVisible(filters, filterGroup, superEnvelope.id)) ||
    (childrenOptions && childrenOptions.every((option) => option.visible))

  return {
    _id: superEnvelope.id,
    name: superEnvelope.name,
    visible,
    group: filterGroup,
    childrenOptions,
  }
}

function secondLevelEnvelopeToOptions(
  envelope: Category,
  filters: FilterType,
  parentOptionId: number,
): Option {
  console.log("secondLevelToOptions - envelope", envelope)

  const childrenOptions =
    envelope.subcategories && !_isEmpty(envelope.subcategories)
      ? envelope.subcategories.map((customCategory) => {
          return thirdLevelEnvelopeToOptions(
            customCategory,
            filters,
            CategoryFilterGroup.CATEGORY,
            envelope._id,
            parentOptionId,
          )
        })
      : undefined

  const visible =
    isItemVisible(filters, CategoryFilterGroup.ENVELOPE, envelope._id) ||
    isItemVisible(filters, CategoryFilterGroup.CATEGORY, envelope._id)

  return {
    _id: envelope._id,
    name: envelope.name,
    isGeneral: envelope.isGeneral,
    visible,
    group: isEnvelopeCategory(envelope)
      ? CategoryFilterGroup.CATEGORY
      : CategoryFilterGroup.ENVELOPE,
    childrenOptions,
    parentOptionId,
    rootParentOptionId: parentOptionId,
  } as Option
}

function thirdLevelEnvelopeToOptions(
  category,
  filters: FilterType,
  filterGroup,
  parentOptionId,
  rootParentOptionId,
) {
  const visible = isItemVisible(filters, filterGroup, category._id)
  return {
    _id: category._id,
    name: category.name,
    visible,
    group: filterGroup,
    parentOptionId,
    rootParentOptionId,
  }
}

export function getAccountOptions(filters: FilterType, accounts: Account[]) {
  return accounts.map((account: Account): Option & Account => {
    const visible = isItemVisible(filters, "accountIds", account._id)
    return { ...account, visible } as Option & Account
  })
}

export function getCurrenciesOptions(filters: FilterType, currencies: Currency[]) {
  return currencies.map((currency): Option & Currency => {
    const visible = isItemVisible(filters, "currencyId", currency._id)
    return { ...currency, name: currency.code, visible } as Option & Currency
  })
}

export function getRecordTypeOptions(filters: FilterType) {
  return [
    { _id: FilterRecordType.INCOME, name: FilterRecordType.INCOME },
    { _id: FilterRecordType.EXPENSE, name: FilterRecordType.EXPENSE },
    { _id: FilterRecordType.TRANSFER, name: FilterRecordType.TRANSFER },
  ].map((recordType): Option => {
    const visible = isItemVisible(filters, "recordTypes", recordType._id)
    const name = formatMessage(recordType.name)
    return { ...recordType, name, visible } as Option
  })
}

export function getPaymentTypeOptions(filters: FilterType) {
  return Object.keys(UserPaymentTypes)
    .map((paymentTypeKey) => ({
      _id: UserPaymentTypes[paymentTypeKey],
      name: `record_payment_type.${paymentTypeKey}`,
    }))
    .map((paymentType): Option => {
      const visible = isItemVisible(filters, "paymentType", paymentType._id)
      const name = formatMessage(paymentType.name)
      return { ...paymentType, name, visible } as Option
    })
}

export function getRecordStateOptions(filters: FilterType) {
  return Object.keys(UserRecordState)
    .map((recordStateKey) => ({
      _id: RecordState[recordStateKey],
      name: `record_state.${recordStateKey}`,
    }))
    .map((recordState): Option => {
      const visible = isItemVisible(filters, "recordState", recordState._id)
      const name = formatMessage(recordState.name)
      return { ...recordState, name, visible } as Option
    })
}
