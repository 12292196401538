import { Action } from 'src/types/common'
import { BraintreeDetails } from 'src/frontend/scenes/billing/activeSubscription/types'
import {
  ACTIVE_SUBSCRIPTION_FETCH_ERROR,
  ACTIVE_SUBSCRIPTION_FETCH_START,
  ACTIVE_SUBSCRIPTION_FETCH_SUCCESS,
} from 'src/frontend/scenes/billing/activeSubscription/actions'


export interface ActiveSubscriptionState {
  loading: boolean
  subscriptionDetails: BraintreeDetails
}

const initialActiveSubscriptionState: ActiveSubscriptionState = {
  loading: false,
  subscriptionDetails: null,
}

export default function activeSubscription(
  state: ActiveSubscriptionState = initialActiveSubscriptionState,
  action: Action,
): ActiveSubscriptionState {
  switch (action.type) {
    case ACTIVE_SUBSCRIPTION_FETCH_START: {
      return { ...state, loading: true }
    }

    case ACTIVE_SUBSCRIPTION_FETCH_SUCCESS: {
      return { ...state, loading: false, subscriptionDetails: action.payload }
    }

    case ACTIVE_SUBSCRIPTION_FETCH_ERROR: {
      return { ...state, loading: false }
    }

    default: {
      return state
    }
  }

}
