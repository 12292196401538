import React, { useEffect, useState } from "react"
import { RootState } from "src/types/State"
import { connect } from "react-redux"
import styles from "../AccountDetailOverview.module.less"
import "src/frontend/scenes/settings/magicRules/components/MagicRules.less"
import { Id } from "src/types/CouchDb"
import {
  expireAnalyticsStatus,
  refreshAnalyticsStatus,
} from "src/frontend/modules/moduleStatus/actions"
import {
  selectIsAnalyticsLoading,
  selectShouldRefreshAnalytics,
} from "src/frontend/modules/moduleStatus/selectors"
import PageLoader from "src/frontend/PageLoader"
import { changeViewDefinition } from "src/frontend/scenes/analytics/actions"
import { ViewDefinition } from "src/frontend/scenes/analytics/types"
import { viewDefinitionInitialState } from "src/frontend/scenes/analytics/reducer"
import { ChartType } from "src/frontend/components/chart/ChartType"
import { ChartGroupByType, ChartSubjectType } from "src/frontend/scenes/analytics/enums"
import { Interval, IntervalCompareToType, IntervalGranularityType } from "src/backend/enums"
import { FilterType } from "src/types/Filter"
import LinearChart from "src/frontend/components/chart/LinearChart"
import {
  selectChartDatasets,
  selectChartLabels,
  selectChartType,
} from "src/frontend/scenes/analytics/selectors"
import { selectReferentialCurrency } from "src/frontend/modules/currencies/selectors"
import { LABELS_PER_CHART } from "src/frontend/scenes/analytics/constants"
import _isEmpty from "lodash/isEmpty"
import FormattedMoney from "src/frontend/modules/intl/components/FormattedMoney"
import { Dataset } from "src/frontend/components/chart/types"
import { Currency } from "src/types/Currency"
import FormattedMessage from "src/frontend/modules/intl/components/FormattedMessage"
import { intervalTypeToPeriod } from "src/backend/time/time"
import { formatMessage } from "src/frontend/modules/intl"

function mapStateToProps(state: RootState) {
  return {
    shouldRefreshAnalytics: selectShouldRefreshAnalytics(state),
    isAnalyticsLoading: selectIsAnalyticsLoading(state),
    type: selectChartType(state),
    labels: selectChartLabels(state),
    datasets: selectChartDatasets(state),
    currency: selectReferentialCurrency(state),
  }
}

const mapDispatchToProps = {
  refreshAnalytics: refreshAnalyticsStatus,
  expireAnalytics: expireAnalyticsStatus,
  updateViewDefinition: changeViewDefinition,
}

interface Props {
  accountId: Id
  shouldRefreshAnalytics: boolean
  isAnalyticsLoading: boolean

  type: ChartType
  labels: string[][]
  datasets: Dataset[]
  currency: Currency

  expireAnalytics: () => void
  refreshAnalytics: (filterOverride?: Partial<FilterType>) => void
  updateViewDefinition: (viewDefinition: Partial<ViewDefinition>) => void
}

function AccountDetailInvestmentOverview({
  accountId,
  shouldRefreshAnalytics,
  isAnalyticsLoading,
  type,
  labels,
  datasets,
  currency,
  expireAnalytics,
  refreshAnalytics,
  updateViewDefinition,
}: Props) {
  const [ready, setReady] = useState(false)

  useEffect(() => {
    if (shouldRefreshAnalytics) {
      const period = intervalTypeToPeriod(Interval.RELATIVE_30_DAYS)
      refreshAnalytics({ accountIds: [accountId], period })
    }
  }, [shouldRefreshAnalytics, accountId])

  useEffect(() => {
    setReady(false)
    expireAnalytics()
    updateViewDefinition({
      chartType: ChartType.LINE,
      groupBy: ChartGroupByType.INVESTMENTS,
      subject: ChartSubjectType.BALANCE,
      granularity: IntervalGranularityType.DAY,
      compareTo: IntervalCompareToType.NONE,
    })
    const period = intervalTypeToPeriod(Interval.RELATIVE_30_DAYS)
    refreshAnalytics({ accountIds: [accountId], period })
    setReady(true)
    return () => {
      updateViewDefinition(viewDefinitionInitialState)
      expireAnalytics()
    }
  }, [accountId])

  if (isAnalyticsLoading || _isEmpty(datasets) || !ready) {
    return <PageLoader />
  }

  const closingAvailableCash = datasets[0]?.data[datasets[0]?.data.length - 1] * 100 ?? 0
  const closingPortfolioBalance = datasets[1]?.data[datasets[1]?.data.length - 1] * 100 ?? 0

  return (
    <div className={styles.contactDetailOverview}>
      <div className={styles.totals}>
        <div className={styles.column}>
          <div className={styles.title}>
            <FormattedMessage id="today" />
          </div>
          <div className={styles.value}>
            <FormattedMoney
              value={closingAvailableCash + closingPortfolioBalance}
              currency={currency.code}
            />
          </div>
        </div>
      </div>
      <LinearChart
        type={type}
        labels={labels}
        datasets={[
          {
            ...datasets[0],
            label: formatMessage("availableCash"),
            borderColor: "rgb(254,60,45)",
            pointBackgroundColor: "rgb(254,60,45)",
          },
          { ...datasets[1], label: formatMessage("portfolioBalance"), fill: true },
        ]}
        currency={currency}
        labelsPerChart={LABELS_PER_CHART}
        options={{ aspectRatio: 6 }}
      />
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountDetailInvestmentOverview)
