import * as service from 'src/backend/categories/service'
import { reduceBy } from 'src/common/utils'

export const CATEGORIES_RECEIVE = 'CATEGORIES_RECEIVE'
export const SAVE_CATEGORY_SUCCESS = 'SAVE_CATEGORY_SUCCESS'

export function receiveCategories(categoriesArray) {
  const CATEGORY_ID = '_id'
  const categories = categoriesArray.reduce(reduceBy(CATEGORY_ID), {})
  return { type: CATEGORIES_RECEIVE, payload: categories }
}

export function fetchCategories() {
  return dispatch => {
    return Promise.resolve()
      .then(() => service.fetchCategories())
      .then(categories => dispatch(receiveCategories(categories)))
  }
}

export function saveCategorySuccess(category) {
  return { type: SAVE_CATEGORY_SUCCESS, payload: { ...category } }
}
