import React from "react"
import ColumnLayout from "src/frontend/components/ColumnLayout/ColumnLayout"
import { formatMessage, FormattedMessage } from "src/frontend/modules/intl"
import { Dropdown } from "semantic-ui-react"
import {
  selectAccountListAccounts,
  selectOrderBy,
} from "src/frontend/scenes/accounts/accountsList/selectors"
import * as actions from "src/frontend/scenes/accounts/accountsList/actions"
import { useDispatch, useSelector } from "react-redux"
import basicLayoutStyles from "src/frontend/components/ColumnLayout/BasicLayout.module.less"
import styles from "./Investments.module.less"
import { AccountsListOrderBy } from "src/frontend/scenes/accounts/accountsList/enums"
import AccountsList from "src/frontend/scenes/accounts/accountsList/components/AccountsList"
import AccountsFilter from "src/frontend/scenes/accounts/filter/components/AccountsFilter"
import PictureIcon from "src/frontend/components/PictureIcon/PictureIcon"

export function getOrderOptions() {
  return [
    {
      text: "Default",
      value: AccountsListOrderBy.POSITION_ASC,
    },
    {
      text: "A-Z",
      value: AccountsListOrderBy.NAME_ASC,
    },
    {
      text: "Z-A",
      value: AccountsListOrderBy.NAME_DESC,
    },
    {
      text: formatMessage("sort-by.balance_lowest_first"),
      value: AccountsListOrderBy.BALANCE_ASC,
    },
    {
      text: formatMessage("sort-by.balance_highest_first"),
      value: AccountsListOrderBy.BALANCE_DESC,
    },
  ]
}

function Investments() {
  const dispatch = useDispatch()
  const order = useSelector(selectOrderBy)
  const allAccounts = useSelector(selectAccountListAccounts)
  const investmentsAccounts = allAccounts.filter((account) => account.investmentInfo)

  const shouldDisplayInvestmentsInfo = investmentsAccounts.length === 0
  const disableInputs = investmentsAccounts.length < 2

  return (
    <>
      {shouldDisplayInvestmentsInfo ? (
        <div className={styles.noAccountsContainer}>
          <h1>
            <FormattedMessage id="menu.investments" />
          </h1>
          <div className={styles.iconWrapper}>
            <PictureIcon
              name="account-investments-circle"
              size="6rem"
              compact
            />
          </div>
          <p>
            <FormattedMessage id="accounts.list.empty.text" />
          </p>
          <p className={styles.callToAction}>
            <FormattedMessage id="accounts.list.empty.callToAction" />
          </p>
        </div>
      ) : (
        <ColumnLayout
          title={<FormattedMessage id="menu.investments" />}
          topPanel={
            <div className={styles.topPanel}>
              <div className={styles.infoBox}>
                <PictureIcon
                  name="info-icon-black"
                  compact
                  size="20px"
                ></PictureIcon>
                <FormattedMessage id="accounts.list.sellBuyInfo" />
              </div>
            </div>
          }
          leftPanel={
            <>
              <AccountsFilter disabled={disableInputs} />
              <div className={styles.sorting}>
                <span className={basicLayoutStyles.filterLabel}>
                  <FormattedMessage id="records.sort-by" />
                </span>
                <Dropdown
                  selection
                  value={order}
                  disabled={disableInputs}
                  options={getOrderOptions()}
                  onChange={(_e, { value }) =>
                    dispatch(actions.changeOrder(value as AccountsListOrderBy))
                  }
                />
              </div>
            </>
          }
          content={<AccountsList filterInvestmentsAccountsOnly={true} />}
        />
      )}
    </>
  )
}

export default Investments
