import React, { SyntheticEvent } from 'react'
import styles from './AddButton.module.less'
import { formatMessage } from 'src/frontend/modules/intl'
import { PrimaryButton } from 'src/frontend/components/Buttons/Buttons'

interface Props {
  onClick: (e: SyntheticEvent) => void
}

export default function AddButton({ onClick }: Props) {
  return (
    <PrimaryButton
      className={styles.addButton}
      onClick={onClick}
    >
      ＋&nbsp;{formatMessage('form.add')}
    </PrimaryButton>
  )
}
