import React from 'react'
import styles from 'src/frontend/components/Progress/Progress.module.less'
import widgetStyles from 'src/frontend/scenes/dashboard/components/Widget/Widget.module.less'
import { formatMessage, FormattedPercentageChange } from 'src/frontend/modules/intl'
import _isArray from 'lodash/isArray'
import { ProgressValue } from 'src/frontend/components/AnimatedBarTable/types'
import { getWidthPercentValue } from 'src/frontend/components/Progress/helpers'
import { Popup } from 'semantic-ui-react'
import { mergeClasses } from 'src/common/utils'

interface Props {
  label: string | React.ReactNode,
  valueLabel: string | React.ReactNode,
  leftBottomLabel?: string | React.ReactNode,
  rightBottomLabel?: string | React.ReactNode,
  avgValueLabel?: string | React.ReactNode,
  maxValueLabel?: string | React.ReactNode,
  valueChange?: number,
  valuesTotal: number,
  invertedChange?: boolean,
  barTotal: number,
  divided?: boolean,
  maxValue?: number,
  avgValue?: number,
  values: Array<ProgressValue> | ProgressValue,
}


Progress.defaultProps = {
  valueChange: undefined,
  invertedChange: false,
  leftBottomLabel: null,
  rightBottomLabel: null,
  avgValueLabel: undefined,
  divided: false,
  color: undefined,
  maxValue: undefined,
  avgValue: undefined,
}

export default function Progress({
  label,
  valueLabel,
  leftBottomLabel,
  rightBottomLabel,
  values,
  valueChange,
  valuesTotal,
  barTotal: total,
  invertedChange,
  divided,
  maxValue,
  maxValueLabel,
  avgValue,
  avgValueLabel,
}: Props) {
  const barTotal: number = Math.round(total * 100) / 100
  const totalOfValues: number = (Math.round(valuesTotal * 100) / 100)

  const bar = (_isArray(values) ? values as ProgressValue[] : [values])
    .filter(({ value }: ProgressValue) => value !== 0)
    .map(({ value, color, label: barLabel, valueLabel: barValueLabel }: ProgressValue, index: number, array) => {
      const widthValue = Math.round(value * 100) / 100

      const isDivided = divided && index < array.length - 1
      const dividedStyle = isDivided
        ? {
          borderRight: '3px solid rgba(255, 255, 255, 0.8)',
          marginLeft: '-3px',
        }
        : {}


      const barComponent = (
        <div
          key={`${color}-${value}`}
          className={styles.barPart}
          style={{
            backgroundColor: color,
            width: `calc(${getWidthPercentValue(totalOfValues, widthValue)}%${isDivided ? ' + 3px' : ''})`,
            ...dividedStyle,
          }}
        />
      )

      return barLabel
        ? (
          <Popup
            inverted
            key={`${color}-${value}`}
            size="tiny"
            position="top center"
            popperModifiers={{ preventOverflow: { boundariesElement: 'window' } }}
            content={(
              <div>
                <div>
                  <strong>{barValueLabel}</strong>
                </div>
                <div>
                  {formatMessage(barLabel)}
                </div>
              </div>
            )}
            trigger={barComponent}
          />
        )
        : barComponent

    })

  return (
    <div className={styles.progress}>
      <div className={mergeClasses(widgetStyles.row, styles.label)}>
        <span className={styles.name}>{label}</span>
        <span className={styles.value}>{valueLabel}</span>
        {!!valueChange && false && (
          <span className={styles.change}>
            <FormattedPercentageChange inverted={invertedChange} change={valueChange} />
          </span>
        )}
      </div>
      <div className={mergeClasses(widgetStyles.row, styles.barRow)}>
        <div className={styles.barWrapper}>
          <div
            className={styles.bar}
            style={{ width: `${getWidthPercentValue(barTotal, totalOfValues)}%` }}
          >
            {bar}
          </div>
          {avgValue ? (
            <Popup
              position="top center"
              size="tiny"
              popperModifiers={{ preventOverflow: { boundariesElement: 'window' } }}
              trigger={(
                <div
                  className={styles.barAvg}
                  style={{ left: `calc(${getWidthPercentValue(barTotal, avgValue)}% - 8px)` }}
                />
              )}
              content={avgValueLabel}
            />
          ) : null}
          {maxValue ? (
            <Popup
              position="top center"
              size="tiny"
              popperModifiers={{ preventOverflow: { boundariesElement: 'window' } }}
              trigger={(
                <div
                  className={styles.barMax}
                  style={{ left: `calc(${getWidthPercentValue(barTotal, maxValue)}% - 8px)` }}
                />
              )}
              content={maxValueLabel}
            />
          ) : null}
        </div>
      </div>
      {leftBottomLabel || rightBottomLabel
        ? (
          <div className={styles.bottomLabels}>
            {leftBottomLabel && <div>{leftBottomLabel}</div>}
            {rightBottomLabel && <div className={styles.right}>{rightBottomLabel}</div>}
          </div>
        )
        : null}
    </div>
  )
}
