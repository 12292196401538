import { createSelector } from "reselect"
import * as envelopesService from "src/backend/categories/envelopes"
import {
  isEnvelopeExpense,
  isEnvelopeIncome,
  isOperatingCost,
  isOperatingRevenue,
  isRecordUnknownEnvelope,
  isSystemEnvelope,
} from "src/backend/categories/helpers"
import { getEnvelopeType } from "src/backend/categories/service"
import { selectHiddenEnvelopes } from "src/frontend/modules/configures/hiddenEnvelopes/selectors"
import { AbstractEnvelope, Category, CategoryDocument, SuperEnvelope } from "src/types/Category"
import { RootState } from "src/types/State"
import { HiddenEnvelopesState } from "src/frontend/modules/configures/hiddenEnvelopes/reducer"
import {
  CATEGORY_ICON_CSS_PREFIX,
  UNASSIGNED_CATEGORY_ICON,
} from "src/frontend/scenes/records/recordList/constants"
import { HashMap } from "src/types/common"
import {
  formatCategoryName,
  isGeneralCategory,
  isGeneralCategory2,
  isGeneralEnvelope,
  isNotUnknownCategory,
} from "src/frontend/modules/categories/helpers"
import { isInColors } from "src/backend/colors/colors"
import { selectModules } from "src/frontend/modules/selectors"

export const selectCategories = (state: RootState): HashMap<CategoryDocument> =>
  selectModules(state).categories

const selectCategoriesAsArray: (state: RootState) => CategoryDocument[] = createSelector(
  selectCategories,
  Object.values,
)
const selectGeneralCategories = createSelector(selectCategoriesAsArray, (categories) =>
  categories.filter(isGeneralCategory2),
)

const selectGeneralCategoriesWithoutUnknownCategories = createSelector(
  selectCategoriesAsArray,
  (categories) => categories.filter(isGeneralCategory2).filter(isNotUnknownCategory),
)

export const selectFlattenedCategories: (RootState) => Category[] = createSelector(
  [
    selectCategoriesAsArray,
    () => envelopesService.getEnvelopes(),
    selectHiddenEnvelopes,
    selectGeneralCategories,
  ],
  categoriesToFlattenedCategories,
)

export const selectFlattenedCategoriesWithoutUnknownCategories: (RootState) => Category[] =
  createSelector(
    [
      selectCategoriesAsArray,
      () => envelopesService.getEnvelopes(),
      selectHiddenEnvelopes,
      selectGeneralCategoriesWithoutUnknownCategories,
    ],
    categoriesToFlattenedCategories,
  )

export const selectCompleteCategoriesHierarchy: (RootState) => SuperEnvelope[] = createSelector(
  [selectCategoriesAsArray, selectGeneralCategories, selectHiddenEnvelopes],
  superEnvelopesToEnvelopesHierarchy,
)

export const selectCategoriesHierarchy: (RootState) => SuperEnvelope[] = createSelector(
  selectCompleteCategoriesHierarchy,
  (superEnvelopes) =>
    superEnvelopes.filter((superEnvelope) => {
      return !isRecordUnknownEnvelope(superEnvelope)
    }),
)

export const selectOperatingCategoriesHierarchy: (RootState) => SuperEnvelope[] = createSelector(
  selectCompleteCategoriesHierarchy,
  (superEnvelopes) =>
    superEnvelopes.filter((superEnvelope) => {
      return isOperatingRevenue(superEnvelope) || isOperatingCost(superEnvelope)
    }),
)

export const selectOperatingRevenueCategoriesHierarchy: (RootState) => SuperEnvelope[] =
  createSelector(selectCompleteCategoriesHierarchy, (superEnvelopes) =>
    superEnvelopes.filter(isOperatingRevenue),
  )

export const selectOperatingCostCategoriesHierarchy: (RootState) => SuperEnvelope[] =
  createSelector(selectCompleteCategoriesHierarchy, (superEnvelopes) =>
    superEnvelopes.filter(isOperatingCost),
  )

export const selectIncomeCategoriesHierarchy: (RootState) => SuperEnvelope[] = createSelector(
  selectCompleteCategoriesHierarchy,
  (superEnvelopes) => superEnvelopes.filter(isEnvelopeIncome),
)

export const selectExpenseCategoriesHierarchy: (RootState) => SuperEnvelope[] = createSelector(
  selectCompleteCategoriesHierarchy,
  (superEnvelopes) => superEnvelopes.filter(isEnvelopeExpense),
)

export const selectNonHiddenSuperEnvelopes: (RootState) => AbstractEnvelope[] = createSelector(
  selectHiddenEnvelopes,
  filterHiddenEnvelopes,
)

function filterHiddenEnvelopes(hiddenEnvelopes: HiddenEnvelopesState): AbstractEnvelope[] {
  return envelopesService.getSuperEnvelopes().filter((superEnvelope: AbstractEnvelope) => {
    return !envelopesService.isSuperEnvelopeHidden(superEnvelope.id, hiddenEnvelopes)
  })
}

export function superEnvelopesToEnvelopesHierarchy(
  categories: CategoryDocument[],
  generalCategories: CategoryDocument[],
  hiddenEnvelopes: HiddenEnvelopesState,
) {
  const superEnvelopes = envelopesService.getSuperEnvelopes()

  return superEnvelopes.map((superEnvelope: AbstractEnvelope): SuperEnvelope => {
    const envelopes = categoriesToCategoriesHierarchy(
      categories,
      envelopesService.getEnvelopesBySuperEnvelopeId(superEnvelope.id),
      hiddenEnvelopes,
      generalCategories,
    )

    const generalCategory = envelopes.find(isGeneralCategory)

    return {
      ...superEnvelope,
      iconName:
        generalCategory?.iconName ||
        superEnvelope.iconName ||
        CATEGORY_ICON_CSS_PREFIX + UNASSIGNED_CATEGORY_ICON,
      name: formatCategoryName(
        generalCategory?.customName ? generalCategory?.name : superEnvelope.localizationString,
        false,
        generalCategory?.customName,
        false,
      ),
      color: isInColors(generalCategory?.color ? generalCategory?.color : "")
        ? generalCategory?.color
        : superEnvelope.color,
      defaultType: getEnvelopeType(superEnvelope),
      isHidden: envelopesService.isSuperEnvelopeHidden(superEnvelope.id, hiddenEnvelopes),
      envelopes,
    }
  })
}

export function categoriesToCategoriesHierarchy(
  categories: CategoryDocument[],
  envelopesArray: AbstractEnvelope[],
  hiddenEnvelopes: number[],
  generalCategories: CategoryDocument[],
): Category[] {
  return envelopesArray.map((envelope: AbstractEnvelope): Category => {
    const categoriesAssignedToEnvelope = categories.filter(isAssignedToEnvelope(envelope))
    const generalCategory = generalCategories.find(
      (category) => envelope.parentId === category.superEnvelopeId,
    )

    return createCategoryWithSubcategories(
      envelope,
      categoriesAssignedToEnvelope,
      hiddenEnvelopes,
      generalCategory,
    )
  })
}

function isAssignedToEnvelope(envelope: AbstractEnvelope) {
  return (category: Category): boolean => category?.envelopeId === envelope.id
}

function createCategoryWithSubcategories(
  envelope: AbstractEnvelope,
  categories: CategoryDocument[],
  hiddenEnvelopes: HiddenEnvelopesState,
  generalCategory?: CategoryDocument,
): Category {
  const secondLevelCategoryCandidate = categories.find((category) => !category.customCategory)

  const generalCandidateColor = generalCategory?.customColor && generalCategory?.color

  const thirdLevelCategories = categories
    .filter((category) => category.customCategory)
    .map((category: CategoryDocument) => convertCategory(envelope, hiddenEnvelopes, category))

  return secondLevelCategoryCandidate
    ? convertCategory(
        envelope,
        hiddenEnvelopes,
        secondLevelCategoryCandidate,
        thirdLevelCategories,
        generalCandidateColor,
      )
    : convertEnvelope(envelope, hiddenEnvelopes, thirdLevelCategories, generalCandidateColor)
}

function categoriesToFlattenedCategories(
  categories: CategoryDocument[],
  envelopesArray: AbstractEnvelope[],
  hiddenEnvelopes: HiddenEnvelopesState,
  generalCategories: CategoryDocument[],
) {
  return envelopesArray.reduce((flattenedCategories, envelope) => {
    const assignedCategories = categories.filter(isAssignedToEnvelope(envelope))

    const generalCategory = generalCategories.find(
      (category) => envelope.parentId === category.superEnvelopeId,
    )
    const generalCandidateColor = generalCategory?.customColor && generalCategory?.color

    const convertedSubCategories = assignedCategories
      .filter((category) => category.customCategory)
      .map((category) => convertCategory(envelope, hiddenEnvelopes, category))

    const [secondLevelCategoryCandidate] = assignedCategories.filter(
      (category) => !category.customCategory,
    )

    const convertedCategory = secondLevelCategoryCandidate
      ? convertCategory(
          envelope,
          hiddenEnvelopes,
          secondLevelCategoryCandidate,
          undefined,
          generalCandidateColor,
        )
      : convertEnvelope(envelope, hiddenEnvelopes, [], generalCandidateColor)

    return convertedSubCategories && convertedCategory
      ? [...flattenedCategories, convertedCategory, ...convertedSubCategories]
      : flattenedCategories
  }, [])
}

function convertCategory(
  envelope: AbstractEnvelope,
  hiddenEnvelopes: HiddenEnvelopesState,
  categoryCandidate: CategoryDocument,
  subcategories?: CategoryDocument[],
  generalCategoryColor?: string,
): CategoryDocument {
  const isGeneral = isGeneralEnvelope(envelope) && !categoryCandidate.customCategory
  const isHidden = hiddenEnvelopes.includes(envelope.id)

  const { customCategory, customName } = categoryCandidate

  const shouldBeLocalized = !customCategory && !customName
  const name = shouldBeLocalized ? envelope.localizationString : categoryCandidate.name
  const color =
    (categoryCandidate.customCategory && categoryCandidate.color) ||
    (categoryCandidate.customColor && categoryCandidate.color) ||
    generalCategoryColor ||
    envelope.color

  const categoryName = formatCategoryName(name, customCategory, customName, false)

  const iconName =
    (categoryCandidate.customCategory && categoryCandidate.iconName) ||
    categoryCandidate.iconName ||
    envelope.iconName ||
    CATEGORY_ICON_CSS_PREFIX + UNASSIGNED_CATEGORY_ICON

  const resultCategory = {
    ...categoryCandidate,
    superEnvelopeId: envelope.parentId,
    name: categoryName,
    color,
    iconName,
    isGeneral,
    isHidden,
  }

  return !subcategories ? resultCategory : { ...resultCategory, subcategories }
}

export function convertEnvelope(
  envelope: AbstractEnvelope,
  hiddenEnvelopes: HiddenEnvelopesState = [],
  subcategories?: CategoryDocument[],
  generalCategoryColor?: string,
): Category {
  const ENDS_WITH_OTHERS_REGEXP = /__OTHERS$/
  const isGeneral = ENDS_WITH_OTHERS_REGEXP.test(envelope.name)
  const isHidden = hiddenEnvelopes.includes(envelope.id)
  const iconName = envelope.iconName || CATEGORY_ICON_CSS_PREFIX + UNASSIGNED_CATEGORY_ICON
  const color = generalCategoryColor || envelope.color

  const categoryName = formatCategoryName(envelope.localizationString, false, false, false)

  // omits unnecessary attributes from envelope
  const { id, parentId, localizationString, ...envelopeAsCategory } = {
    ...envelope,
    _id: envelope.id,
    envelopeId: envelope.id,
    superEnvelopeId: envelope.parentId,
    name: categoryName,
    customCategory: false,
    customName: false,
    color,
    iconName,
    subcategories,
    isGeneral,
    isHidden,
  }

  return envelopeAsCategory
}
