import * as selectors from "./selectors"
import { selectUser } from "src/frontend/modules/user/selectors"
import * as validator from "src/backend/labels/validator"
import { getDefaultLabelColor, getRandomColor } from "src/backend/colors/colors"
import * as service from "src/backend/labels/service"
import { getUserFeatureValue } from "src/backend/user/service"
import * as labelsActions from "src/frontend/modules/labels/actions"
import * as settingsActions from "src/frontend/scenes/settings/actions"
import { inMemoryTableNames } from "src/backend/db/inMemorySqlDbSchemaBuilder"
import * as repository from "src/backend/labels/repository"
import { FormType } from "src/frontend/scenes/settings/enums"
import { Feature } from "src/backend/user/billingFeatures"
import { WalletFormValidationError } from "src/backend/errors"
import { captureException } from "src/common/logger"
import { Action, GetState, HashMap } from "src/types/common"
import { Id } from "src/types/CouchDb"
import { User } from "src/types/User"

export const SETTINGS_LABELS_SAVE_START = "SETTINGS_LABELS_SAVE_START"
export const SETTINGS_LABELS_SAVE_SUCCESS = "SETTINGS_LABELS_SAVE_SUCCESS"
export const SETTINGS_LABELS_SAVE_ERROR = "SETTINGS_LABELS_SAVE_ERROR"
export const SETTINGS_LABELS_CHANGE_FIELD_VALUE = "SETTINGS_LABELS_CHANGE_FIELD_VALUE"

export const SETTINGS_LABELS_OPEN_FORM = "SETTINGS_LABELS_OPEN_FORM"
export const SETTINGS_LABELS_CLOSE_FORM = "SETTINGS_LABELS_CLOSE_FORM"

export const SETTINGS_LABELS_OPEN_COLOR_PICKER = "SETTINGS_LABELS_OPEN_COLOR_PICKER"
export const SETTINGS_LABELS_CLOSE_COLOR_PICKER = "SETTINGS_LABELS_CLOSE_COLOR_PICKER"

export function reorderLabels(oldIndex: number, newIndex: number) {
  return (dispatch: Function) => {
    dispatch(
      settingsActions.reorder(
        oldIndex,
        newIndex,
        inMemoryTableNames.LABEL,
        labelsActions.fetchLabels,
      ),
    )
  }
}

export function saveLabel() {
  return async (dispatch: Function, getState: GetState) => {
    dispatch(saveLabelStart())

    const user = selectUser(getState())
    const { labelId, formValues, formType } = selectors.selectLabels(getState())

    try {
      const validationDependencies = await service.getValidationDependencies()
      validator.validate({ ...formValues, _id: labelId }, validationDependencies)

      await service.saveLabel(formValues, user, labelId)
      dispatch(saveLabelSuccess())
      dispatch(labelsActions.fetchLabels())
    } catch (error) {
      if (error instanceof WalletFormValidationError) {
        dispatch(saveLabelError(error.errors))
      } else {
        captureException(error, "labels.saveLabel", { formValues, formType, labelId })
      }
    }
  }
}

function saveLabelStart(): Action {
  return { type: SETTINGS_LABELS_SAVE_START }
}

function saveLabelSuccess(): Action {
  return { type: SETTINGS_LABELS_SAVE_SUCCESS }
}

function saveLabelError(errors: HashMap<string>): Action {
  return {
    type: SETTINGS_LABELS_SAVE_ERROR,
    payload: errors,
  }
}

export function openAddForm() {
  return (dispatch: Function, getState: GetState) => {
    const user = selectUser(getState())
    const { name } = selectors.selectLabels(getState()).formValues
    const predefinedName = name ? name.trim() : ""

    const formValues = {
      ...getInitialFormValues(user),
      name: predefinedName,
    }

    return dispatch({
      type: SETTINGS_LABELS_OPEN_FORM,
      payload: {
        formType: FormType.ADD,
        formValues,
        labelId: null,
      },
    })
  }
}

export function openEditForm(labelId: Id) {
  return async (dispatch: Function) => {
    const label = await repository.findById(labelId)
    const formValues = {
      name: label.name,
      color: label.color,
      archived: label.archived,
      autoAssign: label.autoAssign,
    }

    return dispatch({
      type: SETTINGS_LABELS_OPEN_FORM,
      payload: {
        formType: FormType.EDIT,
        labelId,
        formValues,
      },
    })
  }
}

export function closeForm(): Action {
  return {
    type: SETTINGS_LABELS_CLOSE_FORM,
  }
}

export function changeName(field: string, value: string): Action {
  return {
    type: SETTINGS_LABELS_CHANGE_FIELD_VALUE,
    payload: { [field]: value },
  }
}

export const changeFormFieldValue = (field: string, value: string | number | boolean) => {
  const payloadValue = (typeof value === "string" && value.trim()) || value
  return {
    type: SETTINGS_LABELS_CHANGE_FIELD_VALUE,
    payload: { [field]: payloadValue },
  }
}

export function deleteLabel(labelId: Id) {
  return async (dispatch: Function) => {
    await service.removeLabel(labelId)
    dispatch(labelsActions.fetchLabels())
  }
}

function getInitialFormValues(user: User) {
  const color = getUserFeatureValue(user, Feature.CUSTOM_LABELS_COLOR)
    ? getRandomColor()
    : getDefaultLabelColor()

  return {
    name: "",
    color,
    autoAssign: false,
  }
}

export function openColorPicker(): Action {
  return { type: SETTINGS_LABELS_OPEN_COLOR_PICKER }
}

export function closeColorPicker(): Action {
  return { type: SETTINGS_LABELS_CLOSE_COLOR_PICKER }
}
