import { RecordType } from 'src/backend/enums'
import { recordHasConnectedAccount, recordIsPlannedPayment } from 'src/backend/records/helpers'
import { isLessThan1Hour, isLessThan24Hours } from 'src/backend/time/time'
import { formatDate, formatDateTime, formatRelative } from 'src/frontend/modules/intl'
import styles from 'src/frontend/components/RecordList/RecordList.module.less'
import moment from 'moment'
import { RecordListItemRecord } from 'src/frontend/components/RecordList/types'

export function getRecordTypeClassName(record: RecordListItemRecord) {
  const { type } = record
  if (type === RecordType.EXPENSE) {
    return styles.expense
  } else if (type === RecordType.INCOME) {
    return styles.income
  } else {
    return null
  }
}

export function getFormattedRecordDate(record: RecordListItemRecord, showRelativeTime: boolean) {
  if (recordIsPlannedPayment(record) || (recordHasConnectedAccount(record) && !isLessThan24Hours(record.recordDate))) {
    return formatDate(record.recordDate)
  } else if (showRelativeTime && isLessThan24Hours(record.recordDate)) {
    const DIFF_UNIT = isLessThan1Hour(record.recordDate) ? 'minute' : 'hour'

    const diff = moment(record.recordDate).diff(moment(), DIFF_UNIT)
    return formatRelative(diff, DIFF_UNIT)
  }
  return formatDateTime(record.recordDate)
}

export function imageNavigationFormatter(baseStyles) {
  return {
    ...baseStyles,
    height: '40px',
    width: '40px',
    background: 'rgba(0, 0, 0, 0.2)',
    ':hover': { background: 'rgba(0, 0, 0, 0.3)' },
    '> svg': { marginTop: '-5px' },
  }
}
