import { pipe } from "ramda"
import { addUpdateAttributes } from "src/backend/common/helpers"
import { updateBulk } from "src/backend/common/repository"
import { updateAuditData } from "src/backend/common/service"
import {
  CONFIG_WEB_VERSION_1,
  CONFIG_WEB_VERSION_2,
  DefaultWebConfig,
  IntervalsToSave,
} from "src/backend/webConfig/constants"
import { findWebConfig } from "src/backend/webConfig/repository"
import { inMemoryTableNames } from "src/backend/db/inMemorySqlDbSchemaBuilder"
import * as timeUtils from "src/backend/time/time"
import { User } from "src/types/User"
import { Interval } from "src/backend/enums"
import {
  AbstractWidget,
  WidgetData,
  WidgetType,
  WidgetVariant,
} from "src/frontend/scenes/dashboard/types"
import _isEqual from "lodash/isEqual"
import {
  addDefaultWidgetOptions,
  addDefaultWidgetPeriod,
  convertWidgetToAbstractWidget,
  createRawWidget,
} from "src/backend/webConfig/converters"
import { Id } from "src/types/CouchDb"
import { FilterType } from "src/types/Filter"
import { WidgetId } from "src/backend/dashboard/enums"
import {
  mapAbstractWidgetToFetchWidgetData,
  mergeDataWithWidgets,
} from "src/backend/webConfig/helpers"
import { HashMap } from "src/types/common"
import { isUndefinedOrNull, reduceBy } from "src/common/utils"
import _omitBy from "lodash/omitBy"
import _isEmpty from "lodash/isEmpty"
import {
  ConfigValues,
  DEPRECATED_ConfigWebDashboardV1,
  WebConfigDocument,
} from "src/types/WebConfig"
import WebConfig from "src/backend/webConfig/WebConfig"
import { webConfigConverter } from "src/backend/converters/webConfigConverter"
import { DEPRECATED_findDashboardConfig } from "src/backend/DEPRECATED_configDashboard/repository"

function setDashboardConfigCurrentVersion(dashboardConfig) {
  return {
    ...dashboardConfig,
    version: CONFIG_WEB_VERSION_2,
  }
}

function createWebConfig(user) {
  return pipe(
    setDashboardConfigCurrentVersion,
    updateAuditData(user, timeUtils.utcDateAsISOString),
  )(WebConfig())
}

export function updateConfigValues(newConfigValues: ConfigValues) {
  return (config: WebConfigDocument) => {
    return {
      ...config,
      ...newConfigValues,
    }
  }
}

export async function updateOrCreateWebConfig(configValues: ConfigValues, user: User) {
  const config = (await findWebConfig()) || createWebConfig(user)

  const updatedConfig: WebConfigDocument = pipe(
    addUpdateAttributes,
    updateConfigValues(configValues),
  )(config)

  return updateBulk([updatedConfig], inMemoryTableNames.WEB_CONFIG, webConfigConverter())
}

export async function saveIntervalToWebConfig(interval: Interval, user: User) {
  if (await shouldSaveInterval(interval)) {
    return updateOrCreateWebConfig({ interval }, user)
  }
  return Promise.resolve()
}

export function getWebConfig() {
  return findWebConfig()
}

async function shouldSaveInterval(interval: Interval) {
  const dashboardConfig = await findWebConfig()
  return (
    (!dashboardConfig || dashboardConfig.interval !== interval) &&
    IntervalsToSave.includes(interval)
  )
}

export async function saveWidgetsToDashboardConfig(
  allWidgets: (WidgetType | AbstractWidget)[],
  user: User,
) {
  const abstractWidgets = allWidgets.map(convertWidgetToAbstractWidget)

  if (await shouldSaveDashboard(abstractWidgets)) {
    return updateOrCreateWebConfig({ widgets: abstractWidgets }, user)
  }
  return Promise.resolve()
}

async function shouldSaveDashboard(widgets: AbstractWidget[]) {
  const dashboardConfig = await findWebConfig()
  return !dashboardConfig || !_isEqual(dashboardConfig.widgets !== widgets)
}

export async function getDashboardValues(): Promise<{
  interval: Interval
  widgets: HashMap<AbstractWidget>
  widgetsOrder: Id[]
}> {
  const dashboardConfig = await findWebConfig()
  const interval: Interval =
    (dashboardConfig && dashboardConfig.interval) || DefaultWebConfig.interval

  const widgets: AbstractWidget[] = (
    (dashboardConfig && dashboardConfig.widgets) ||
    DefaultWebConfig.widgets ||
    []
  ).map(pipe(addDefaultWidgetOptions, addDefaultWidgetPeriod))

  const widgetsOrder: Id[] = widgets.map((widget) => widget.id)

  return {
    // We are converting "31-days" interval to "30-days", because it is outdated(no longer valid) and some users had configured it as default and it resulted in crashing the app. JIRA ticket: https://budgetbakers.atlassian.net/browse/WEB-317
    // @ts-ignore
    interval: interval === "31-days" ? "30-days" : interval,
    widgets: widgets.reduce(reduceBy("id"), {}),
    widgetsOrder,
  }
}

export function insertUpdatedWidgetToWidgets(
  widgets: HashMap<WidgetType>,
  updatedWidget: WidgetType,
): HashMap<WidgetType> {
  return updatedWidget.id in widgets
    ? {
        ...widgets,
        [updatedWidget.id]: updatedWidget,
      }
    : widgets
}

export function removeWidgetFromWidgetOrder(widgetIds: Id[], idToRemove: Id) {
  return widgetIds.filter((widgetId: Id) => widgetId !== idToRemove)
}

export function createWidget(widgetId: WidgetId, variant?: WidgetVariant): AbstractWidget {
  return pipe(
    addDefaultWidgetOptions,
    addDefaultWidgetPeriod,
  )(createRawWidget({ widgetId, variant }))
}

export function addWidgetIdToOrder(widgetIds: Id[], widgetId: Id): Id[] {
  return widgetId ? [...widgetIds, widgetId] : widgetIds
}

export function mergeWidgetsByOrder(widgetsHashMap: HashMap<WidgetType>, widgetIds: Id[]) {
  return widgetIds
    .filter((id: Id) => id in widgetsHashMap)
    .map((id: Id) => {
      return widgetsHashMap[id]
    })
}

export function removeWidgetFromHash(widgets: HashMap<WidgetType>, idToRemove: Id) {
  return _omitBy(widgets, (_value, key) => key === idToRemove)
}

export function fetchAllWidgetsWithData(
  widgets: HashMap<AbstractWidget>,
  commonFilter: FilterType,
): Promise<HashMap<WidgetType>> {
  const widgetsArray = Object.values(widgets)
  const promises = widgetsArray.map(mapAbstractWidgetToFetchWidgetData(commonFilter))

  return Promise.all(promises).then((widgetData: WidgetData[]) =>
    mergeDataWithWidgets(widgetsArray, widgetData),
  )
}

export async function upgradeDashboardToVersion2() {
  const deprecatedDashboard =
    (await DEPRECATED_findDashboardConfig()) as DEPRECATED_ConfigWebDashboardV1
  const config = await findWebConfig()

  if (
    !config &&
    deprecatedDashboard &&
    (isUndefinedOrNull(deprecatedDashboard.version) ||
      deprecatedDashboard.version === CONFIG_WEB_VERSION_1) &&
    deprecatedDashboard.widgets &&
    !_isEmpty(deprecatedDashboard.widgets) &&
    deprecatedDashboard.widgets.some((widgetColumn) => !_isEmpty(widgetColumn))
  ) {
    console.log("updating dashboard")

    const widgetsColumns = deprecatedDashboard.widgets
    const maxWidgetsLength = Math.max(
      ...widgetsColumns.map((widgetsColumn) => widgetsColumn.length),
    )

    const widgets = [...Array(maxWidgetsLength)].reduce((acc, _value, index) => {
      const newWidgets = widgetsColumns
        .map((widgetColumn) => widgetColumn[index])
        .filter(Boolean)
        .filter((widget) => !_isEmpty(widget))
        .filter((widget) => widget.widgetId in WidgetId)
      return [...acc, ...newWidgets]
    }, [])

    const updatedConfig: WebConfigDocument = pipe(
      addUpdateAttributes,
      updateConfigValues({ widgets }),
      setDashboardConfigCurrentVersion,
    )(WebConfig())

    return updateBulk([updatedConfig], inMemoryTableNames.WEB_CONFIG, webConfigConverter())
  }
  console.log("skipping dashboard update")
}

export function saveShowDecimalsToWebConfig(showDecimalPlaces: boolean, user: User) {
  return updateOrCreateWebConfig({ showDecimalPlaces }, user)
}

export function saveHideMobileAppsBanner(hideMobileAppsBanner: boolean, user: User) {
  return updateOrCreateWebConfig({ hideMobileAppsBanner }, user)
}

export function saveHideAdvertisement(advertisementHiddenAt: string, user: User) {
  return updateOrCreateWebConfig({ advertisementHiddenAt }, user)
}
