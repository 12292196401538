/* tslint:disable:space-in-parens */
import React from 'react'
import Loadable from 'react-loadable'
import { Redirect, Switch } from 'react-router'
import { BrowserRouter, Route } from 'react-router-dom'
import { IntlProvider } from 'src/frontend/modules/intl/components/IntlContext'
import PageLoader from 'src/frontend/PageLoader'
import { DeepLink } from 'src/frontend/RedirectDeepLink/enum'
import withTracker from 'src/frontend/scenes/app/withTracker'
import 'src/frontend/styles/semantic-ui/semantic.less'
import { isAppBoard } from 'src/common/environment'

const WebAppRoot = Loadable({
  loader: () => import(/* webpackChunkName: "webapp" */'src/frontend/WebAppRoot'),
  loading: PageLoader,
})

const AppRating = Loadable({
  loader: () => import(/* webpackChunkName: "android-rating" */'src/frontend/AppRating/AppRating'),
  loading: PageLoader,
})

const RedirectPage = Loadable({
  loader: () => import(/* webpackChunkName: "redirect-page" */'src/frontend/RedirectDeepLink/RedirectDeepLink'),
  loading: PageLoader,
})

const OfferPage = Loadable({
  loader: () => import(/* webpackChunkName: "offer-page" */'src/frontend/Offer/OfferPage'),
  loading: PageLoader,
})

const ResetPasswordPage = Loadable({
  loader: () => import(/* webpackChunkName: "reset-password" */'src/frontend/ResetPassword/ResetPasswordPage'),
  loading: PageLoader,
})

const KBLandingPage = Loadable({
  loader: () => import(/* webpackChunkName: "kb" */'src/frontend/scenes/kb/components/KBLandingPage'),
  loading: PageLoader,
})

export default function RootComponent() {
  return (
    <IntlProvider>
      <BrowserRouter>
        <Switch>
          <Route
            path={`/redirect/android/:redirectName(${Object.values(DeepLink).join('|')})`}
            component={RedirectPage}
          />
          <Route exact path="/billing/offer" component={withTracker(OfferPage)} />
          <Route exact path="/android/open-billing" component={() => <Redirect to="/settings/billing/choose-plan" />} />
          <Route exact path="/rate/android" component={withTracker(AppRating)} />
          <Route exact path="/reset-password/:token?" component={withTracker(ResetPasswordPage)} />
          {isAppBoard() && <Route exact path="/kb" component={withTracker(KBLandingPage)} />}
          <Route component={WebAppRoot} />
        </Switch>
      </BrowserRouter>
    </IntlProvider>
  )
}
