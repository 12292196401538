export type NumberFormatOptions = {
  localeMatcher?: 'best fit' | 'lookup',
  formatMatcher?: 'basic' | 'best fit',

  timeZone?: string,
  hour12?: boolean,

  weekday?: 'narrow' | 'short' | 'long', // T | Thu | Thursday
  era?: 'narrow' | 'short' | 'long', // 8 9, 2018 A | 8 9, 2018 AD | 8 9, 2018 Anno Domini
  year?: 'numeric' | '2-digit', // 2018 | 18
  month?: 'numeric' | '2-digit' | 'narrow' | 'short' | 'long', // 8 | 08 | A | Aug | August
  day?: 'numeric' | '2-digit',
  hour?: 'numeric' | '2-digit',
  minute?: 'numeric' | '2-digit',
  second?: 'numeric' | '2-digit',
  timeZoneName?: 'short' | 'long',
}

export const DATE_LONG: NumberFormatOptions = {
  day: 'numeric',
  month: 'long',
  year: 'numeric',
}

export const DATE_SHORT: NumberFormatOptions = {
  month: 'long',
  day: 'numeric',
}

export const DATE_TIME: NumberFormatOptions = {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
}

export const YEAR_SHORT: NumberFormatOptions = {
  year: 'numeric',
  month: 'short',
}

export const YEAR_LONG: NumberFormatOptions = {
  year: '2-digit',
  month: '2-digit',
  day: '2-digit',
}

export const YEAR_MONTH_LONG: NumberFormatOptions = {
  year: '2-digit',
  month: 'short',
  day: 'numeric',
  weekday: 'short',
}
