import { DatasetOptions } from 'src/frontend/components/chart/types'
import { LegendIconType } from 'src/frontend/scenes/analytics/enums'

/**
 * Shared ChartJS dataset option, common only for analytics components
 */
export const DATASET_DEFAULT_OPTIONS: DatasetOptions = {
  borderColor: '#149dff',
  fill: false,
  backgroundColor: 'rgba(54, 162, 235, 0.2)',
  pointBackgroundColor: '#149dff',
  legendIconType: LegendIconType.SOLID,
}

export const COMPARED_DATASET_DEFAULT_OPTIONS: DatasetOptions = {
  ...DATASET_DEFAULT_OPTIONS,
  borderDash: [6, 3],
  borderWidth: 1,
  backgroundColor: 'rgba(54, 162, 235, 0)',
}

/** Empirical value to fit labels in the Analytics, should be odd to cover start/end */
export const LABELS_PER_CHART: number = 13
