import React from 'react'
import { RouteComponentProps } from 'react-router'
import * as GTM from 'src/common/gtm'

export default function withTracker(WrappedComponent, options = {}) {
  const trackPage = (page) => {
    GTM.set({
      page_path: page,
      ...options,
    })
    GTM.gaPageView()
  }

  const EMPTY_URL = '/'

  return class extends React.PureComponent<RouteComponentProps<{}>> {
    componentDidMount() {
      const page = this.props.location && this.props.location.pathname

      if (page && page !== EMPTY_URL) {
        trackPage(page)
      }
    }

    componentWillReceiveProps(nextProps) {
      const currentPage = this.props.location.pathname
      const nextPage = nextProps.location.pathname

      if (currentPage !== nextPage && nextPage !== EMPTY_URL) {
        trackPage(nextPage)
      }
    }

    render() {
      return <WrappedComponent {...this.props} />
    }
  }
}
