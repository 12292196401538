import { IntegrationProviders } from 'src/frontend/scenes/integrations/providersList/types'
import InputType = IntegrationProviders.IntegrationRecipe.InputType
import IntegrationRecipeParam = IntegrationProviders.IntegrationRecipe.IntegrationRecipeParam
import IntegrationRecipeGroup = IntegrationProviders.IntegrationRecipe.IntegrationRecipeGroup

export function isOAuth(formAttributeGroups: IntegrationRecipeGroup[]): boolean {
  return formAttributeGroups && formAttributeGroups.some(integrationRecipeGroup => {
    return integrationRecipeGroup.params && integrationRecipeGroup.params.some((param: IntegrationRecipeParam) => {
      return param.type === InputType.OAUTH_REDIRECT_URL
    })
  })
}
