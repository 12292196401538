import { Id } from "src/types/CouchDb"
import * as recordsService from "src/backend/records/service"
import { Action, GetState, HashMap } from "src/types/common"
import { selectDashboardFilter } from "src/frontend/scenes/dashboard/selectors"
import { RecordType } from "src/backend/enums"
import * as currencyRepository from "src/backend/currencies/repository"
import * as userSelectors from "src/frontend/modules/user/selectors"
import { FormType } from "src/frontend/scenes/records/recordForm/enums"
import * as categoriesActions from "src/frontend/modules/categories/actions"
import {
  expireAccountsStatus,
  expireAnalyticsStatus,
  expireDetailRecordsStatus,
  expireDashboardStatus,
  expireImportsRecordListStatus,
  expireRecordsStatus,
} from "src/frontend/modules/moduleStatus/actions"
import { captureException } from "src/common/logger"
import { VIRTUAL_OUT_OF_WALLET_ACCOUNT_ID } from "src/backend/accounts/helpers"
import { selectTemplates } from "src/frontend/modules/templates/selectors"
import { getCurrencyFormObject, getTransferCategory, isTransfer } from "src/backend/records/helpers"
import { selectRecordForm } from "src/frontend/scenes/records/recordForm/selectors"
import { WalletFormValidationError } from "src/backend/errors"
import { RecordFormValues } from "src/frontend/scenes/records/recordForm/types"
import { adjustAmount } from "src/frontend/scenes/records/recordForm/helpers"
import { selectShouldDisplayDashboardOnBoarding } from "src/frontend/scenes/onBoarding/inApp/selectors"
import { openGoodJobModal } from "src/frontend/scenes/onBoarding/inApp/actions"

export const APPLY_TEMPLATE = "recordForm/APPLY_TEMPLATE"
export const RESET_TEMPLATE = "recordForm/RESET_TEMPLATE"

export const OPEN_FORM = "recordForm/OPEN_FORM"
export const CLOSE_FORM = "recordForm/CLOSE_FORM"

export const REVERT_CHANGES = "recordForm/REVERT_CHANGES"

export const CHANGE_CREATE_NEW_AFTER_SAVE = "recordForm/CHANGE_CREATE_NEW_AFTER_SAVE"

// --- Shared actions ---
export const CHANGE_FORM_CURRENCY = "recordForm/CHANGE_FORM_CURRENCY"
export const CHANGE_FORM_FIELD_VALUE = "recordForm/CHANGE_FORM_FIELD_VALUE"
export const CHANGE_FORM_ACCOUNT = "recordForm/CHANGE_FORM_ACCOUNT"
export const CHANGE_FORM_TYPE = "recordForm/CHANGE_FORM_TYPE"
export const SAVE_RECORD_START = "recordForm/SAVE_RECORD_START"
export const SAVE_RECORD_SUCCESS = "recordForm/SAVE_RECORD_SUCCESS"
export const SAVE_RECORD_ERROR = "recordForm/SAVE_RECORD_ERROR"

export function changeFieldValue(field: string, value: string | number | Date | boolean) {
  return {
    type: CHANGE_FORM_FIELD_VALUE,
    payload: { [field]: value },
  }
}

export function changeFields(changedFields: HashMap<string | number | Date | boolean>) {
  return {
    type: CHANGE_FORM_FIELD_VALUE,
    payload: { ...changedFields },
  }
}

export function changeTransferCurrency(
  fieldName: string,
  currencyId: Id,
  formValues: RecordFormValues,
  amountFieldName: string,
) {
  return async (dispatch: Function) => {
    const [fromCurrency, toCurrency] = await Promise.all([
      currencyRepository.findById(
        amountFieldName === "fromAmount" ? currencyId : formValues.fromCurrencyId,
      ),
      currencyRepository.findById(
        amountFieldName === "toAmount" ? currencyId : formValues.toCurrencyId,
      ),
    ])

    const oppositeAmount =
      amountFieldName === "fromAmount" ? formValues.toAmount : formValues.fromAmount

    let amount
    // clone amounts when both fields have same currency
    if (fromCurrency.ratioToReferential === toCurrency.ratioToReferential) {
      amount = oppositeAmount

      // calc opposite amount for From Amount
    } else if (amountFieldName === "fromAmount") {
      amount = (oppositeAmount * fromCurrency.ratioToReferential) / toCurrency.ratioToReferential
    } else {
      // calc opposite amount for To Amount
      amount = (oppositeAmount / fromCurrency.ratioToReferential) * toCurrency.ratioToReferential
    }

    // TODO refactor

    return dispatch({
      type: CHANGE_FORM_CURRENCY,
      payload: {
        formValues: {
          [fieldName]: currencyId,
          [amountFieldName]: amount,
        },
      },
    })
  }
}

export function changeTransferAmount(
  amountName: "fromAmount" | "toAmount",
  value: number,
  formValues: RecordFormValues,
  firstSelectedAmountName: string,
  isConnected: boolean,
) {
  return async (dispatch: Function) => {
    const amountObject = await recordsService.adjustTransferAmount(
      amountName,
      value,
      formValues,
      firstSelectedAmountName,
      isConnected,
    )

    return dispatch(changeFields(amountObject))
  }
}

export function saveRecord(createNewAfterSave?: boolean) {
  return async (dispatch: Function, getState: GetState) => {
    const { formValues, formType, recordIds } = selectRecordForm(getState())

    dispatch(saveRecordStart())

    const user = userSelectors.selectUser(getState())

    try {
      await recordsService.validateRecords(formValues, recordIds, formType)

      if (isTransfer(formValues) && formType !== FormType.MULTI_EDIT) {
        await recordsService.saveTransfer(formValues, user, recordIds && recordIds[0])
      } else if (recordIds && recordIds.length > 1) {
        await recordsService.saveMultipleRecords(formValues, user, recordIds)
      } else {
        await recordsService.saveRecord(formValues, formType, user, recordIds && recordIds[0])
      }

      const isDashboardOnBoardingShown = selectShouldDisplayDashboardOnBoarding(getState())
      if (isDashboardOnBoardingShown) {
        const [count] = await recordsService.getRecordsCount()

        const shouldDashboardOnBoardingHide = !count || count < 1

        if (!shouldDashboardOnBoardingHide) {
          dispatch(openGoodJobModal("onBoarding.success.text"))
        }
      }

      dispatch(saveRecordSuccess())
      if (formType !== FormType.MULTI_EDIT && formType !== FormType.EDIT && createNewAfterSave) {
        dispatch(openAddRecordForm())
      }

      await dispatch(categoriesActions.fetchCategories())
      dispatch(expireAccountsStatus())
      dispatch(expireDashboardStatus())
      dispatch(expireRecordsStatus())
      dispatch(expireAnalyticsStatus())
      dispatch(expireImportsRecordListStatus())
      dispatch(expireDetailRecordsStatus())
    } catch (error) {
      if (error instanceof WalletFormValidationError) {
        dispatch(saveRecordError(error.errors))
      } else {
        captureException(error, "records.saveRecord", {
          formValues,
          formType,
          recordIds,
          errors: error.errors,
        })
      }
    }
  }
}

function saveRecordStart() {
  return {
    type: SAVE_RECORD_START,
  }
}

function saveRecordSuccess() {
  return { type: SAVE_RECORD_SUCCESS }
}

function saveRecordError(errors: HashMap<string>): Action<{ errors: HashMap<string> }> {
  return {
    type: SAVE_RECORD_ERROR,
    payload: { errors },
  }
}

export function applyTemplate(templateId: Id) {
  return async (dispatch: Function, getState: GetState) => {
    const templates = selectTemplates(getState())
    const template = templates[templateId]

    const { formValues, availableCurrencies } = await recordsService.getTemplateRecordFormObject(
      template,
    )

    dispatch({
      type: APPLY_TEMPLATE,
      payload: {
        templateId,
        availableCurrencies,
        formValues,
      },
    })
  }
}

export function resetTemplate() {
  return async (dispatch: Function, getState: GetState) => {
    const dashboardFilter = selectDashboardFilter(getState())
    const { formValues, availableCurrencies } = await recordsService.getDefaultRecordFormObject(
      dashboardFilter,
    )

    dispatch({
      type: RESET_TEMPLATE,
      payload: {
        availableCurrencies,
        formValues,
      },
    })
  }
}

export function openAddRecordForm(predefinedValues?: Partial<RecordFormValues>) {
  return async (dispatch: Function, getState: GetState) => {
    const dashboardFilter = selectDashboardFilter(getState())
    const { formValues, availableCurrencies, toAvailableCurrencies, fromAvailableCurrencies } =
      await recordsService.getDefaultRecordFormObject(dashboardFilter, predefinedValues)

    dispatch({
      type: OPEN_FORM,
      payload: {
        availableCurrencies,
        toAvailableCurrencies,
        fromAvailableCurrencies,
        formValues,
        formType: FormType.ADD,
      },
    })
  }
}

export function openMultiEditForm(recordIds: Id[]) {
  return async (dispatch: Function) => {
    const { formValues, availableCurrencies, fromAvailableCurrencies, toAvailableCurrencies } =
      await recordsService.getMultiRecordFormObject(recordIds)

    return dispatch({
      type: OPEN_FORM,
      payload: {
        recordIds,
        availableCurrencies,
        toAvailableCurrencies,
        fromAvailableCurrencies,
        formValues,
        initialFormValues: formValues,
        formType: FormType.MULTI_EDIT,
      },
    })
  }
}

export function openEditRecordForm(recordId: Id) {
  return async (dispatch: Function) => {
    const { formValues, availableCurrencies, fromAvailableCurrencies, toAvailableCurrencies } =
      await recordsService.getExistingRecordFormObject(recordId)

    return dispatch({
      type: OPEN_FORM,
      payload: {
        recordIds: [recordId],
        availableCurrencies,
        toAvailableCurrencies,
        fromAvailableCurrencies,
        formValues,
        initialFormValues: formValues,
        formType: FormType.EDIT,
      },
    })
  }
}

export function revertChanges() {
  return {
    type: REVERT_CHANGES,
  }
}

export const changeRecordDate = (recordDate: Date) => (dispatch: Function) => {
  return dispatch({
    type: CHANGE_FORM_FIELD_VALUE,
    payload: { recordDate },
  })
}

export function closeForm(): Action {
  return {
    type: CLOSE_FORM,
  }
}

export function changeAccount(fieldName: string, accountId: Id) {
  return async (dispatch: Function, getState: GetState) => {
    const { formValues } = selectRecordForm(getState())
    const payload = await getChangeAccountActionPayload(fieldName, accountId, formValues)
    dispatch({
      type: CHANGE_FORM_ACCOUNT,
      payload,
    })
  }
}

function getChangeAccountActionPayload(
  fieldName: string,
  accountId: Id,
  formValues: RecordFormValues,
): Promise<any> {
  switch (fieldName) {
    case "fromAccountId": {
      return changeFromTransferAccountPayload(accountId, formValues)
    }

    case "toAccountId": {
      return changeToTransferAccountPayload(accountId, formValues)
    }

    default:
    case "accountId": {
      return changeAccountPayload(accountId, formValues)
    }
  }
}

async function changeAccountPayload(accountId: Id, formValues: RecordFormValues) {
  const { updatedCurrencyId, availableCurrencies } = await getCurrencyFormObject(
    accountId,
    formValues.currencyId,
  )
  const [previousCurrency, currency, referentialCurrency] = await Promise.all([
    currencyRepository.findById(formValues.currencyId),
    currencyRepository.findById(updatedCurrencyId),
    currencyRepository.getReferentialCurrency(),
  ])
  const amount =
    previousCurrency && currency && previousCurrency._id !== currency._id
      ? Math.round(
          (formValues.amount / previousCurrency.ratioToReferential) * currency.ratioToReferential,
        )
      : formValues.amount

  return {
    formValues: {
      accountId,
      currencyId: currency ? updatedCurrencyId : referentialCurrency._id,
      amount,
    },
    availableCurrencies,
  }
}

async function changeFromTransferAccountPayload(fromAccountId: Id, formValues: RecordFormValues) {
  const { availableCurrencies: fromAvailableCurrencies, updatedCurrencyId: fromCurrencyId } =
    await getCurrencyFormObject(fromAccountId, formValues.fromCurrencyId)

  const [fromCurrency, toCurrency] = await Promise.all([
    currencyRepository.findById(fromCurrencyId),
    currencyRepository.findById(formValues.toCurrencyId),
  ])

  const fromAmount = adjustAmount(
    fromCurrency,
    toCurrency,
    formValues.fromAmount,
    formValues.toAmount,
  )

  return {
    formValues: {
      fromAccountId,
      fromCurrencyId,
      fromAmount,
    },
    fromAvailableCurrencies,
  }
}

async function changeToTransferAccountPayload(toAccountId: Id, formValues: RecordFormValues) {
  const { availableCurrencies: toAvailableCurrencies, updatedCurrencyId: toCurrencyId } =
    await getCurrencyFormObject(toAccountId, formValues.toCurrencyId)

  const [fromCurrency, toCurrency] = await Promise.all([
    currencyRepository.findById(formValues.fromCurrencyId),
    currencyRepository.findById(toCurrencyId),
  ])

  const toAmount = adjustAmount(
    toCurrency,
    fromCurrency,
    formValues.toAmount,
    formValues.fromAmount,
  )

  return {
    formValues: {
      toAccountId,
      toCurrencyId,
      toAmount,
    },
    toAvailableCurrencies,
  }
}

export function changeRecordType(
  type: RecordType,
  transfer: boolean,
  formValues: RecordFormValues,
) {
  return async (dispatch, getState) => {
    const { accountId } = formValues
    const { availableCurrencies, updatedCurrencyId: currencyId } = await getCurrencyFormObject(
      accountId,
      formValues.currencyId,
    )
    const [previousCurrency, currency] = await Promise.all([
      currencyRepository.findById(formValues.currencyId),
      currencyRepository.findById(currencyId),
    ])
    const amount =
      previousCurrency && currency && previousCurrency._id !== currency._id
        ? Math.round(
            (formValues.amount / previousCurrency.ratioToReferential) * currency.ratioToReferential,
          )
        : formValues.amount

    let categoryId: { categoryId?: Id; superEnvelopeId?: number; envelopeId?: number } = {
      categoryId: null,
      superEnvelopeId: null,
      envelopeId: null,
    }
    let transferAttributes = {}
    let transferAvailableCurrencies = {}

    if (transfer) {
      const user = userSelectors.selectUser(getState())
      const transferCategory = await getTransferCategory(user)
      categoryId = { categoryId: transferCategory._id }
      const fromAccountId =
        type === RecordType.EXPENSE ? accountId : VIRTUAL_OUT_OF_WALLET_ACCOUNT_ID
      const toAccountId = type === RecordType.INCOME ? accountId : VIRTUAL_OUT_OF_WALLET_ACCOUNT_ID

      const [
        { availableCurrencies: fromAvailableCurrencies, updatedCurrencyId: fromCurrencyId },
        { availableCurrencies: toAvailableCurrencies, updatedCurrencyId: toCurrencyId },
      ] = await Promise.all([
        getCurrencyFormObject(fromAccountId, formValues.fromCurrencyId || currencyId),
        getCurrencyFormObject(toAccountId, formValues.toCurrencyId || currencyId),
      ])

      transferAvailableCurrencies = {
        fromAvailableCurrencies,
        toAvailableCurrencies,
      }

      transferAttributes = {
        fromAccountId,
        toAccountId,
        fromAmount: amount,
        toAmount: amount,
        fromCurrencyId,
        toCurrencyId,
      }
    }

    dispatch({
      type: CHANGE_FORM_TYPE,
      payload: {
        formValues: {
          type,
          transfer,
          ...categoryId,
          ...transferAttributes,
          currencyId,
          amount,
        },
        availableCurrencies,
        ...transferAvailableCurrencies,
      },
    })
  }
}

export function changeContactId(contactId: Id) {
  return {
    type: CHANGE_FORM_FIELD_VALUE,
    payload: { contactId },
  }
}
