import * as billing from 'src/backend/rest/backend/billing'
import { selectBraintreeLifetimeProduct } from 'src/frontend/scenes/billing/product/selectors'
import { Action, ThunkFunction } from 'src/types/common'
import { isAuthorizationError } from 'src/common/helpers'
import { expireUser } from 'src/frontend/modules/user/actions'
import { showErrorModal } from 'src/frontend/Error/actions'
import * as logger from 'src/common/logger'
import { Billing } from 'src/types/Billing'
import { BillingUIMethod } from 'src/frontend/scenes/billing/enums'
import { goToBillingStage } from 'src/frontend/scenes/billing/billingModal/actions'
import PeriodType = Billing.PeriodType
import PlanType = Billing.PlanType
import { BillingModalStage } from 'src/frontend/scenes/billing/billingModal/enums'
import BraintreeProduct = Billing.BraintreeProduct
import * as gtm from 'src/common/gtm'

export const GET_PRODUCTS_START = 'newBilling/GET_PRODUCTS_START'
export const GET_PRODUCTS_SUCCESS = 'newBilling/GET_PRODUCTS_SUCCESS'
export const GET_PRODUCTS_ERROR = 'newBilling/GET_PRODUCTS_ERROR'
export const SELECT_PRODUCT = 'newBilling/SELECT_PRODUCT'
export const GET_VOUCHER_PRODUCT_SUCCESS = 'newBilling/GET_VOUCHER_PRODUCT_SUCCESS'


export function getVoucherProductSuccess(voucherResponse: Billing.VoucherTransactionResponse, voucherCode: string) {
  return async (dispatch: Function) => {
    const product: Billing.Product = {
      period: voucherResponse.period,
      planType: PlanType.ADVANCED,
      productId: voucherResponse.productId,
    }
    const braintreeProduct: BraintreeProduct = {
      currencyCode: voucherResponse.currencyCode,
      price: voucherResponse.price,
      product,
    }

    if (voucherResponse.period !== PeriodType.UNLIMITED) {
      dispatch(getProductsSuccess([braintreeProduct], undefined))
    } else {
      dispatch(getProductsSuccess([], braintreeProduct))
    }
    // TODO do we need this for later use?
    return dispatch({
      type: GET_VOUCHER_PRODUCT_SUCCESS,
      payload: { voucherResponse, voucherCode },
    })
  }
}

export function getProducts(): Function {
  return async (dispatch: Function) => {
    dispatch(getProductsStart())
    try {
      const productInfo = await billing.fetchProducts()
      dispatch(getProductsSuccess(productInfo.recurrentProducts, productInfo.lifetimeProduct))
    } catch (error) {
      if (isAuthorizationError(error)) {
        dispatch(expireUser())
      } else {
        dispatch(showErrorModal(null, false))
      }
      dispatch(getProductsError(error))
      logger.captureException(error, 'billing.getProducts')
    }
  }
}

export function selectProduct(productId: string, method: BillingUIMethod): ThunkFunction {
  return (dispatch: Function) => {
    gtm.selectProduct(productId)
    if (method === BillingUIMethod.MODAL) {
      dispatch(goToBillingStage(BillingModalStage.PAYMENT_METHOD))
    }
    return dispatch({
      type: SELECT_PRODUCT,
      payload: productId,
    })
  }
}

export function selectLifetimeProduct() {
  return (dispatch: Function, getState: Function) => {
    const productId = selectBraintreeLifetimeProduct(getState()).product.productId
    return dispatch(selectProduct(productId, BillingUIMethod.PAGE))
  }
}

function getProductsStart(): Action {
  return {
    type: GET_PRODUCTS_START,
  }
}

function getProductsSuccess(
  recurringProducts,
  lifetimeProduct,
): Action<{ recurringProducts: BraintreeProduct[], lifetimeProduct: BraintreeProduct }> {
  return {
    type: GET_PRODUCTS_SUCCESS,
    payload: {
      recurringProducts,
      lifetimeProduct,
    },
  }
}

function getProductsError(error): Action {
  return {
    type: GET_PRODUCTS_ERROR,
    payload: error,
  }
}
