import {
  RECORD_LIST_RECORDS_REQUEST,
  RECORD_LIST_RECORDS_RECEIVE,
  RECORD_LIST_CHANGE_ORDER,
  RECORD_LIST_RECORDS_PREVIEW_REQUEST,
  RECORD_LIST_RECORDS_PREVIEW_RECEIVE,
  RECORD_LIST_RECORDS_PREVIEW_OPEN,
  RECORD_LIST_RECORDS_PREVIEW_CLOSE,
} from './actions'
import { RecordListOrderBy } from 'src/frontend/scenes/records/recordList/enums'
import { FilterType } from 'src/types/Filter'
import { Record } from 'src/types/Record'

export interface RecordListState {
  isFetching: boolean
  records: Record[]
  recordsPreview: Record[]
  plannedPayments: Record[]
  order: RecordListOrderBy
  recordsPreviewOpen: boolean
  recordsPreviewFilter: FilterType
  amountRange?: {
    min: number,
    max: number,
  }
}

const recordListInitialState: RecordListState = {
  isFetching: false,
  records: [],
  recordsPreviewOpen: false,
  recordsPreview: [],
  plannedPayments: [],
  recordsPreviewFilter: null,
  order: RecordListOrderBy.RECORD_DATE_DESC,
}

export function recordList(state: RecordListState = recordListInitialState, action): RecordListState {
  switch (action.type) {
    case RECORD_LIST_CHANGE_ORDER: {
      const order = action.payload
      const orderedItems = [...state.records]
      return { ...state, order, records: orderedItems }
    }

    case RECORD_LIST_RECORDS_REQUEST: {
      return { ...state, isFetching: true }
    }
    case RECORD_LIST_RECORDS_RECEIVE: {
      return { ...state, isFetching: false, ...action.payload }
    }

    case RECORD_LIST_RECORDS_PREVIEW_REQUEST: {
      return { ...state, recordsPreview: [], isFetching: true }
    }
    case RECORD_LIST_RECORDS_PREVIEW_RECEIVE: {
      return { ...state, recordsPreview: action.payload, isFetching: false }
    }

    case RECORD_LIST_RECORDS_PREVIEW_OPEN: {
      return { ...state, recordsPreviewOpen: true, isFetching: true, recordsPreviewFilter: action.payload }
    }

    case RECORD_LIST_RECORDS_PREVIEW_CLOSE: {
      return { ...state, recordsPreviewOpen: false, isFetching: false, recordsPreview: [], recordsPreviewFilter: null }
    }
    default:
      return state
  }
}
