import { Category } from 'src/types/Category'
import React from 'react'
import { Id } from 'src/types/CouchDb'
import {
  convertToCategoryItem,
  filterCategoriesByLatinizedName,
  sortByLocalizedCategoryName,
} from 'src/frontend/modules/categories/helpers'
import { CategoryLevel } from 'src/frontend/modules/categories/enums'
import { Dropdown } from 'semantic-ui-react'
import CategoryOption from 'src/frontend/components/Categories/CategoryOption'
import { FormattedMessage } from 'src/frontend/modules/intl'

interface Props {
  className?: string
  categories: Category[]
  fieldName: string
  searchValue: string
  value: string | number
  onSelect: (event: React.SyntheticEvent, values: { name: string, value: Id | number }) => void
}

SearchCategoriesList.defaultProps = {
  className: '',
}

export function SearchCategoriesList({ className, categories, fieldName, searchValue, value, onSelect }: Props) {

  const searchCategories = categories
    .filter(filterCategoriesByLatinizedName(searchValue))
    .sort(sortByLocalizedCategoryName)
    .map((envelope: Category) => {
      const { category, name: categoryName } = convertToCategoryItem(envelope, CategoryLevel.CATEGORY)
      return (
        <Dropdown.Item
          key={envelope._id}
          text={(
            <CategoryOption
              level={CategoryLevel.CATEGORY}
              color={category.color}
              icon={category.iconName}
              label={categoryName}
              size="23px"
            />
          )}
          active={value === envelope._id}
          as="li"
          onClick={(e) => onSelect(e, { name: fieldName, value: envelope._id })}
        />
      )
    })

  return searchCategories.length > 0
    ? (
      <ul className={className}>
        {searchCategories}
      </ul>
    )
    : (
      <div className="no-results">
        <FormattedMessage id="record.form.category.not-found" />
      </div>
    )
}
