import { activateImportAccount } from 'src/backend/imports/service'
import { expireImportsRecordListStatus } from 'src/frontend/modules/moduleStatus/actions'

export const IMPORTS_ACCOUNT_FILTER_CHANGED = 'imports/ACCOUNT_FILTER_CHANGED'
export const IMPORTS_ACTIVATED = 'imports/IMPORTS_ACTIVATED'

export const accountFilterChanged = accountId => dispatch => {
  console.log("accountFilterChanged called: ", accountId)
  dispatch({ type: IMPORTS_ACCOUNT_FILTER_CHANGED, accountId })
  dispatch(expireImportsRecordListStatus())
}

export const activateImports = (accountId) => async dispatch => {
  const importEmail = await activateImportAccount(accountId)
  console.log(`Imports for accountId=${accountId} activated, imports email=${importEmail}`)
  dispatch({ type: IMPORTS_ACTIVATED, accountId, importEmail })
}
