import React, { SyntheticEvent } from "react"
import { Button, Icon, Popup, SemanticICONS } from "semantic-ui-react"
import {
  isRelativeInterval,
  nextPeriod,
  previousPeriod,
  generateIntervalString,
} from "src/backend/time/time"
import { Interval, RelativeIntervalType } from "src/backend/enums"
import "./DateRangePicker.less"
import { Period } from "src/types/Filter"
import PickerContainer from "./PickerContainer"
import "src/frontend/styles/react-datepicker/datepicker.less"
import { isAppBoard } from "src/common/environment"
import { isIntervalPredefiedPeriod } from "../helpers"

type Props = {
  period: Period
  locale: string
  fluid?: boolean
  showAll?: boolean
  preferText?: boolean
  intervalType?: RelativeIntervalType
  onChange: Function
}

type State = {
  open: boolean
}

export default class DateRangePicker extends React.Component<Props, State> {
  static defaultProps = {
    fluid: false,
    showAll: false,
    inverted: false,
    preferText: false,
    intervalType: RelativeIntervalType.LAST,
  }

  state = { open: false }

  handleOpenPopup = () => this.setState({ open: true })

  handleClosePopup = () => this.setState({ open: false })

  handlePreviousClick = (e: any) => {
    e.nativeEvent.stopImmediatePropagation()
    const {
      period: { start, end, interval },
      onChange,
    } = this.props

    onChange({
      ...previousPeriod(start, end, interval),
      interval:
        !isRelativeInterval(interval) || isIntervalPredefiedPeriod(interval)
          ? interval
          : Interval.CUSTOM,
    })
  }

  handleNextClick = (e: any) => {
    e.nativeEvent.stopImmediatePropagation()
    const {
      period: { start, end, interval },
      onChange,
    } = this.props

    onChange({
      ...nextPeriod(start, end, interval),
      interval:
        !isRelativeInterval(interval) || isIntervalPredefiedPeriod(interval)
          ? interval
          : Interval.CUSTOM,
    })
  }

  render() {
    const { showAll, period, locale, fluid, preferText, intervalType, onChange } = this.props
    const { open } = this.state

    const trigger = (
      <div className="ui selection dropdown trigger">
        <div className="selection-inner">
          {generateIntervalString(period, locale, fluid, preferText, intervalType)}
        </div>
        <Icon
          name="dropdown"
          as="i"
        />
      </div>
    )

    const isAll = period.interval === Interval.ALL_THE_TIME

    return (
      <div className={`date-range-picker ${fluid ? "fluid" : ""}`}>
        <ArrowButton
          direction="left"
          disabled={isAll}
          onClick={this.handlePreviousClick}
        />
        <Popup
          trigger={trigger}
          content={
            <PickerContainer
              showAll={showAll}
              period={period}
              locale={locale}
              intervalType={intervalType}
              onChange={onChange}
              onClose={this.handleClosePopup}
            />
          }
          popperModifiers={{ preventOverflow: { boundariesElement: "window" } }}
          className="date-range-picker-popup"
          on="click"
          open={open}
          position="bottom center"
          hideOnScroll
          onClose={this.handleClosePopup}
          onOpen={this.handleOpenPopup}
        />
        <ArrowButton
          direction="right"
          disabled={isAll}
          onClick={this.handleNextClick}
        />
      </div>
    )
  }
}

type ArrowButtonProps = {
  direction: "left" | "right"
  disabled?: boolean
  onClick: (e: SyntheticEvent) => void
}

const ArrowButton = ({ direction, disabled, onClick }: ArrowButtonProps) => {
  const name: string = `${isAppBoard() ? "chevron" : "caret"} ${direction}`

  if (!isAppBoard()) {
    return (
      <Icon
        name={name as SemanticICONS}
        onClick={onClick}
        size="large"
        fitted
      />
    )
  }
  return (
    <Button
      icon
      disabled={disabled}
      onClick={onClick}
      style={{
        marginLeft: direction === "right" && "0.5rem",
        marginRight: direction === "left" && "0.5rem",
      }}
    >
      <Icon
        fitted
        name={name as SemanticICONS}
      />
    </Button>
  )
}

ArrowButton.defaultProps = {
  disabled: false,
}
