import { Interval } from 'src/backend/enums'
import { convertPeriodFilter, convertRecordTypesFilter } from 'src/backend/records/converters'
import * as timeUtils from 'src/backend/time/time'
import { FilterType } from 'src/types/Filter'

export function getPreviousPeriodFilter(filter: FilterType): FilterType {
  return {
    ...filter,
    period: filter.period.interval !== Interval.ALL_THE_TIME
      ? timeUtils.previousPeriod(filter.period.start, filter.period.end, filter.period.interval)
      : filter.period,
  }
}

export function getNextPeriodFilter(filter: FilterType): FilterType {
  return {
    ...filter,
    period: filter.period.interval !== Interval.ALL_THE_TIME
      ? timeUtils.nextPeriod(filter.period.start, filter.period.end, filter.period.interval)
      : filter.period,
  }
}

export function getYearAgoPeriodFilter(filter: FilterType): FilterType {
  return {
    ...filter,
    period: filter.period.interval !== Interval.ALL_THE_TIME
      ? timeUtils.getYearAgoPeriod(filter.period.start, filter.period.end, filter.period.interval)
      : filter.period,
  }
}

export function convertToFilter(filterVO: FilterType): FilterType {
  return {
    ...filterVO,
    period: convertPeriodFilter(filterVO.period),
    ...convertRecordTypesFilter(filterVO.recordTypes),
  }
}
