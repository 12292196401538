import React, { FC, useEffect, useState } from "react"
import { FormattedMoney, formatMessage } from "src/frontend/modules/intl"
import styles from "./AvailableCashTab.module.less"
import AssetListItem from "src/frontend/components/Investments/AssetListItem"
import { getAssetTransactionsForAccount } from "src/backend/investments/service"
import { transformAssetTransactionsToAssetListItems } from "src/backend/investments/helpers"
import { Modal } from "semantic-ui-react"
import AssetTransactionShortListItem from "src/frontend/components/Investments/AssetTransactionShortListItem"
import { AssetListItemType, AssetTransaction } from "src/types/investments.types"
import { useSelector } from "react-redux"
import { selectCurrencies } from "src/frontend/modules/currencies/selectors"

interface Props {
  portfolioBalance: number
  accountCurrencyCode: string
  accountId: string
}

const PortfolioTab: FC<Props> = ({ accountCurrencyCode, portfolioBalance, accountId }) => {
  const [assetListItems, setAssetListItems] = useState<AssetListItemType[]>([])
  const [transactionsModal, setTransactionsModal] = useState({ open: false, assetId: "" })
  const currencies = Object.values(useSelector(selectCurrencies))

  const openTransactionsModal = (assetId: string) => {
    setTransactionsModal({ open: true, assetId })
  }

  const closeTransactionsModal = () => {
    setTransactionsModal({ open: false, assetId: "" })
  }

  const fetchAndSetAssetListItems = async () => {
    const allAssetTransactions = await getAssetTransactionsForAccount(accountId)

    const assetTransactionsGroupedByAssetId = groupAssetTransactionsByAssetId(allAssetTransactions)

    const assetListItems = await Promise.all(
      assetTransactionsGroupedByAssetId.map((assetTransactions) => {
        return transformAssetTransactionsToAssetListItems(assetTransactions)
      }),
    )

    setAssetListItems(assetListItems)
  }

  useEffect(() => {
    fetchAndSetAssetListItems()
  }, [])

  const modalAsset = assetListItems.find(
    (assetListItem) => assetListItem._id === transactionsModal.assetId,
  )

  return (
    <div style={{ paddingBlock: "20px" }}>
      <div
        className={styles.overview}
        style={{ marginBottom: "20px" }}
      >
        <h2 className={styles.heading}>{formatMessage("portfolioBalance")}</h2>
        <span className={styles.value}>
          <FormattedMoney
            currency={accountCurrencyCode}
            value={portfolioBalance}
          />
        </span>
      </div>
      {assetListItems
        .sort((aListItem, bListItem) => {
          const aListCurrency = currencies.find(
            (currency) => currency.code === aListItem.currencyCode,
          )
          const bListCurrency = currencies.find(
            (currency) => currency.code === bListItem.currencyCode,
          )

          return (
            bListItem.totalValue / (bListCurrency?.ratioToReferential ?? 1) -
            aListItem.totalValue / (aListCurrency?.ratioToReferential ?? 1)
          )
        })
        .map((assetListItem) => (
          <AssetListItem
            openTransactionsModal={openTransactionsModal}
            key={assetListItem._id}
            assetListItem={assetListItem}
          />
        ))}
      <Modal
        size="tiny"
        open={transactionsModal.open}
        onClose={closeTransactionsModal}
        closeOnEscape
      >
        <Modal.Header>
          {modalAsset?.name ?? ""}
          <span
            className="modal-close"
            onClick={closeTransactionsModal}
            aria-label="Close modal"
          />
        </Modal.Header>
        <Modal.Content>
          <h2
            style={{
              display: "flex",
              justifyContent: "space-between",
              fontSize: "15px",
              fontWeight: 700,
              paddingBottom: "10px",
              borderBottom: "1px solid #e8e8e8",
            }}
          >
            <span>{formatMessage("billing.amount.total")}</span>
            <FormattedMoney
              value={
                modalAsset?.currencyMeta?.baseCurrencyCode
                  ? modalAsset?.totalValue / modalAsset?.currencyMeta.currencyScaleFactor ?? 0
                  : modalAsset?.totalValue ?? 0
              }
              currency={
                modalAsset?.currencyMeta?.baseCurrencyCode ?? modalAsset?.currencyCode ?? ""
              }
            />
          </h2>
          {modalAsset?.assetTransactions
            .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())
            .map((assetTransaction) => (
              <AssetTransactionShortListItem
                assetListItem={modalAsset}
                assetTransaction={assetTransaction}
                key={assetTransaction._id}
              />
            ))}
        </Modal.Content>
      </Modal>
    </div>
  )
}

export default PortfolioTab

function groupAssetTransactionsByAssetId(transactions: AssetTransaction[]): AssetTransaction[][] {
  const groupedTransactions: Record<string, AssetTransaction[]> = {}

  // Group transactions by assetId
  for (const transaction of transactions) {
    if (!groupedTransactions[transaction.assetId]) {
      groupedTransactions[transaction.assetId] = []
    }
    groupedTransactions[transaction.assetId].push(transaction)
  }

  // Convert the grouped object into an array of arrays
  const result: AssetTransaction[][] = Object.values(groupedTransactions)

  return result
}
