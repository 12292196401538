import * as helpers from 'src/common/helpers'
import { DATASET_DEFAULT_OPTIONS } from 'src/frontend/scenes/dashboard/components/constants'
import { BalanceChartData } from 'src/frontend/scenes/dashboard/widgets/BalanceChart/types'
import { setBackgroundGradient } from 'src/frontend/components/chart/helpers'
import { Dataset } from 'src/frontend/components/chart/types'
import { Currency } from 'src/types/Currency'
import { ChartType } from 'src/frontend/components/chart/ChartType'
import { formatMessage } from 'src/frontend/modules/intl'

export function aggregateBalanceChartData(referentialCurrency: Currency) {
  return (data: BalanceChartData) => {
    if (!data) {
      return null
    }

    const datasets = data && data.datasets
      ? data.datasets.map<Dataset>((dataset: Dataset) => {
        return {
          ...dataset,
          ...DATASET_DEFAULT_OPTIONS,
          borderWidth: 2,
          borderColor: '#149dff',
          backgroundColor: 'rgba(54, 162, 235, 0.2)',
          pointBackgroundColor: '#149dff',
          label: formatMessage('balance'),
          fill: 'origin',
          hideLegend: true,
          backgroundGradient: setBackgroundGradient(['rgba(20, 157, 255, 0.7)', 'rgba(20, 157, 255, 0)']),
          isEmpty: dataset.data.every((value => value === null || value === 0)),
          type: ChartType.LINE,
        }
      })
      : []

    return {
      ...data,
      datasets,
      height: 192,
      options: {
        aspectRatio: null,
        maintainAspectRatio: false,
      },
      currency: referentialCurrency,
      balanceChange: helpers.countPercentChange(data.openingBalance, data.closingBalance),
    }
  }
}
