import React from 'react'
import FormattedAbbreviatedNumber from 'src/frontend/modules/intl/components/FormattedAbbreviatedNumber'
import Gauge from 'src/frontend/scenes/dashboard/components/Gauge/Gauge'
import { PerformanceValues, WidgetView } from 'src/frontend/scenes/dashboard/types'
import Widget from 'src/frontend/scenes/dashboard/components/Widget/Widget'
import styles from './Performance.module.less'
import _isEmpty from 'lodash/isEmpty'
import { FormattedMessage } from 'src/frontend/modules/intl'
import { widgetHasDeepEqualProps } from 'src/frontend/scenes/dashboard/helpers'
import { mergeClasses } from 'src/common/utils'

type Props = {
  widget: WidgetView & {
    data: PerformanceValues,
  },
}

function Performance({ widget }: Props) {
  const { data } = widget || { data: null }
  if (!data || _isEmpty(data)) {
    return <Widget.Placeholder />
  }

  const { balance, cashFlow, spending } = data
  return (
    <Widget>
      <Widget.Header title={widget.title} />
      <Widget.Content>
        <div className={mergeClasses(styles.performanceCard, styles.wallet)}>
          <div>
            <Gauge key="balance" value={balance.performanceValue} diameter="100px" />
            <div className={styles.walletDescription}>
              <span><FormattedMessage id="balance" /></span>
              <span><FormattedAbbreviatedNumber value={balance.labelValue} /></span>
            </div>
          </div>
          <div>
            <Gauge value={cashFlow.performanceValue} diameter="100px" />
            <div className={styles.walletDescription}>
              <span><FormattedMessage id="cashFlow" /></span>
              <span><FormattedAbbreviatedNumber value={cashFlow.labelValue} /></span>
            </div>
          </div>
          <div>
            <Gauge value={spending.performanceValue} diameter="100px" />
            <div className={styles.walletDescription}>
              <span><FormattedMessage id="spending" /></span>
              <span><FormattedAbbreviatedNumber value={spending.labelValue} /></span>
            </div>
          </div>
        </div>
      </Widget.Content>
    </Widget>
  )
}

export default React.memo(Performance, widgetHasDeepEqualProps)
