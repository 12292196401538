import styles from 'src/frontend/scenes/dashboard/widgets/PieAndBarSpending/WidgetPieChartContainer.module.less'
import React from 'react'
import DepthControls from 'src/frontend/scenes/dashboard/widgets/PieAndBarSpending/DepthControls'
import { getCategoryId } from 'src/frontend/scenes/dashboard/widgets/PieAndBarSpending/helpers'
import { RecordCategoryLevel } from 'src/backend/categories/enums'
import { isUndefinedOrNull } from 'src/common/utils'
import WidgetPieChart from 'src/frontend/scenes/dashboard/widgets/PieAndBarSpending/WidgetPieChart'
import {
  SpendingPieData,
  SpendingPieOptions,
} from 'src/frontend/scenes/dashboard/widgets/PieAndBarSpending/types'
import { WidgetView } from 'src/frontend/scenes/dashboard/types'
import { Id } from 'src/types/CouchDb'
import { Currency } from 'src/types/Currency'

interface Props {
  data: SpendingPieData[],
  currency: Currency
  goBackId: number | string
  options: SpendingPieOptions
  selectedIndex: number
  widget: WidgetView
  onIndexChange: React.Dispatch<React.SetStateAction<number>>
  onChangeDepth: (
    id: Id,
    categoryId: number | string,
    categoryLevel: RecordCategoryLevel,
    direction: 1 | -1,
  ) => void
}

function WidgetPieChartContainer({
  data,
  goBackId,
  currency,
  selectedIndex,
  widget,
  options,
  onChangeDepth,
  onIndexChange,
}: Props) {

  function handleGoDeeper() {
    onChangeDepth(widget.id, getCategoryId(widget, selectedIndex), options.categoryLevel, 1)
    onIndexChange(null)
  }

  function handleGoBack() {
      onChangeDepth(widget.id, goBackId, options.categoryLevel, -1)
      /* 
      console.log('options.categoryLevel: ', options.categoryLevel)
      options.categoryLevel === 0
          ? onChangeDepth(widget.id, goBackId, options.categoryLevel, -1)
          : onChangeDepth(widget.id, goBackId, options.categoryLevel, 1)
      */
      onIndexChange(null)
  }

  const shouldShowBack =
      options.categoryLevel !== RecordCategoryLevel.SUPER_ENVELOPE ||
      (!isUndefinedOrNull(selectedIndex) &&
          (options.categoryLevel === RecordCategoryLevel.SUPER_ENVELOPE ||
              options.categoryLevel === RecordCategoryLevel.CATEGORY))
  const shouldShowDeeper = !isUndefinedOrNull(selectedIndex) && options.categoryLevel !== RecordCategoryLevel.CATEGORY

  return (
      <div className={styles.pieChart}>
          <DepthControls
              showBack={shouldShowBack}
              showDeeper={shouldShowDeeper}
              onGoDeeper={handleGoDeeper}
              onGoBack={handleGoBack}
          />
          <WidgetPieChart
              data={data}
              currency={currency}
              selectedIndex={selectedIndex}
              widget={widget}
              onIndexChange={onIndexChange}
          />
      </div>
  )
}


export default WidgetPieChartContainer
