import * as onboardingActions from 'src/frontend/scenes/onBoarding/firstRun/actions'
import * as addVoucherActions from 'src/frontend/modules/modals/actions'
import React, { useEffect } from 'react'
import { Redirect, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router'
import { RootState } from 'src/types/State'
import { selectUser } from 'src/frontend/modules/user/selectors'
import { UserLoginStatus } from 'src/frontend/modules/user/enums'
import {
  selectOnBoardingShow,
} from 'src/frontend/scenes/onBoarding/firstRun/selectors'

type Actions = {
  setShouldAddVoucher: Function,
  openAddVoucherModal: Function,
}

type Props = {
  authenticated: boolean,
  onboarding: boolean,
}

interface RouteProps extends RouteComponentProps<{ voucherCode: string }> {
}

interface AllProps extends RouteProps, Props, Actions {
}

function mapStateToProps(state: RootState): Props {
  const onboarding = selectOnBoardingShow(state)
  const user = selectUser(state)
  const authenticated: boolean = !user.loading && user.loginStatus === UserLoginStatus.AUTHENTICATED
  return { authenticated, onboarding }
}

const mapDispatchToProps: Actions = {
  setShouldAddVoucher: onboardingActions.setShouldAddVoucher,
  openAddVoucherModal: addVoucherActions.openModalAddVoucher,
}

function SignUpVoucher({
  match: { params: { voucherCode } },
  authenticated,
  onboarding,
  openAddVoucherModal,
  setShouldAddVoucher,
}: AllProps) {
  useEffect(() => {
    if (onboarding) {
      setShouldAddVoucher(voucherCode)
    } else {
      openAddVoucherModal(voucherCode)
    }
  }, [])

  return (authenticated
      ? <Redirect to="/" />
      : <Redirect to="/signup" />
  )
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SignUpVoucher))
