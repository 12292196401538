import _isArray from 'lodash/isArray'
import _isEmpty from 'lodash/isEmpty'
import _isPlainObject from 'lodash/isPlainObject'

export function reduceNonRecordsToHashMap(acc, value) {
  if (_isPlainObject(value) && value.reservedModelType) {
    return {
      ...acc,
      [value.reservedModelType]: value,
    }
  } else if (_isArray(value) && !_isEmpty(value)) {
    return {
      ...acc,
      [value[0].reservedModelType]: value,
    }
  }

  return acc
}

export function reduceChangesToDocuments(changes) {
  return changes.reduce((tempDocsByOperation, changeInfo) => {
    const docs = changeInfo.change && changeInfo.change.docs || changeInfo.docs

    return [
      ...tempDocsByOperation,
      ...docs,
    ]
  }, [])
}
