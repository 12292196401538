import { CouchDbDocument } from "./CouchDb"

export interface StocksFundsAsset extends CouchDbDocument {
  symbol: string
  exchange: string
  exchangeCode: string
  logo: string
  currencyCode: string
  currencyMeta?: MetaCurrency
  name: string
  exchangeName: string
  priceMinDate: string
}

export type StocksFundsPayload = Omit<StocksFundsAsset, keyof CouchDbDocument>

export interface StockPrice {
  date: Date
  stockIdentifier: string
  value: number
}

export interface StockIdentifier {
  symbol: string
  exchangeCode: string
}

export interface ExchangeRate {
  date: Date
  value: number
}

/**
 * @description This interface is used to store the exchange rate at single day using combined pair of currency codes in a single string
 * @example "USD:EUR" "<from currency code>:<to currency code>"
 */
export interface ExchangeRateWithIndetifier extends ExchangeRate {
  currencyCodes: string
}

export interface AssetTransactionDocument extends CouchDbDocument {
  date: string
  quantity: number
  cashAmount?: number
  assetType: number
  transactionType: number
  accountId: string
  assetId: string
  price: number
  priceCurrencyCode: string
  cashAmountCurrencyCode?: string
}

export interface AssetTransaction extends Omit<AssetTransactionDocument, "date"> {
  date: Date
  day: Date
  month: Date
  week: Date
}

export interface AssetTransactionListItemType extends AssetTransaction {
  symbol: string
  name: string
  logo: string
  currencyCode: string
  currencyMeta?: MetaCurrency
  accountName: string
  accountColor: string
}

export interface AssetListItemType extends StocksFundsAsset {
  totalQuantity: number
  totalValue: number
  assetTransactions: AssetTransaction[]
}

export enum TransactionType {
  BUY = 0,
  SELL = 1,
  PRICE_CHANGE = 2,
}

export interface MetaCurrency {
  baseCurrencyCode: string
  currencyScaleFactor: number
}
