import Push from 'push.js'
import walletLogo from 'src/frontend/assets/images/favicon.png'
import { formatMessage } from 'src/frontend/modules/intl/i18n'

export function requestPushPermissions() {
  try {
    Push.Permission.request()
  } catch {
  }
}

export function sendPushNotification(messageId: string) {
  try {
    if (Push.Permission.has()) {
      return Push.create(document.title, {
        body: formatMessage(messageId),
        icon: walletLogo,
        onClick: () => window.focus(),
      })
    }
  } catch {
  }
}
