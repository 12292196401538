import * as yup from 'yup'
import { MIN_PASSWORD_LENGTH, MAX_PASSWORD_LENGTH } from "src/frontend/scenes/auth/constants"

export function getValidationSchema(values) {
  return yup.object().shape({
    currentPassword: yup.string().required("auth.error.currentPassword.is_required"),
    newPassword: yup
      .string()
      .min(MIN_PASSWORD_LENGTH, "auth.error.password.too_short")
      .max(MAX_PASSWORD_LENGTH, "auth.error.password.too_long")
      .required("auth.error.newPassword.is_required"),
    newPasswordConfirmation: yup
      .string()
      .oneOf([values.newPassword], "auth.error.newPasswordConfirmation.is_not_same")
      .required("auth.error.newPasswordConfirmation.is_required"),
  })
}
