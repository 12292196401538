import { SystemLabelType } from 'src/backend/enums'

export const SystemLabels = {
  [SystemLabelType.EMO_NEGATIVE]: {
    name: '\uD83D\uDCA9',
    color: 'transparent',
  },
  [SystemLabelType.EMO_NEUTRAL]: {
    name: '\uD83D\uDE10',
    color: 'transparent',
  },
  [SystemLabelType.EMO_POSITIVE]: {
    name: '\u2764\ufe0f️️️',
    color: 'transparent',
  },
  // Todo goal system labels
  // [SystemLabelType.GOAL]: {
  //   name: '\uD83C\uDFAF',
  //   color: 'transparent',
  // },
}
