import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { formatMessage, FormattedMessage } from 'src/frontend/modules/intl/index'
import ImportsTutorialModal from 'src/frontend/scenes/imports/components/ImportsTutorialModal'
import * as importsActions from 'src/frontend/scenes/imports/actions'
import { selectTutorialOpen } from 'src/frontend/scenes/imports/selectors'
import './NoContent.less'
import { PrimaryButton } from 'src/frontend/components/Buttons/Buttons'

const mapDispatchToProps = {
  openTutorial: importsActions.openTutorial,
  closeTutorial: importsActions.closeTutorial,
}

function mapStateToProps(state) {
  return ({ tutorialOpen: selectTutorialOpen(state) })
}

NoRecordsContent.propTypes = {
  tutorialOpen: PropTypes.bool.isRequired,
  openTutorial: PropTypes.func.isRequired,
  closeTutorial: PropTypes.func.isRequired,
}

function NoRecordsContent({ tutorialOpen, openTutorial, closeTutorial }) {
  return (
    <div className="no-records-container">
      <div className="no-records-content">
        <div className="icons-container">
          <div className="icons" />
        </div>
        <div className="onboarding-big-text">
          <FormattedMessage id="imports.tutorial.no_records" />
        </div>
        <div className="onboarding-small-text">
          <FormattedMessage
            id="imports.tutorial.empty_placeholder"
            values={{ transactionsName: formatMessage({ W: 'menu.records', B: 'transactions' }) }}
          />
        </div>
        <ImportsTutorialModal open={tutorialOpen} modalClose={closeTutorial} />
        <PrimaryButton
          fluid
          onClick={openTutorial}
        >
          <FormattedMessage id="imports.tutorial.open_button" />
        </PrimaryButton>
      </div>
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(NoRecordsContent)
