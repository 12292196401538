import { DropdownItemProps } from "semantic-ui-react"
import latinize from "latinize"
import { HashMap } from "src/types/common"

export function reduceBy<T>(attribute: string | number) {
  return (acc: HashMap<T>, item: T): HashMap<T> => {
    acc[item[attribute]] = item
    return acc
  }
}

export function parseIntDecimal(stringNumber: any): number {
  return parseInt(stringNumber, 10)
}

export function isNumeric(value: any): boolean {
  return (
    (typeof value === "number" || (typeof value === "string" && value.trim() !== "")) &&
    !Number.isNaN(Number(value))
  )
}

export const isUndefined = (value: any): value is undefined => value === undefined
export const isNull = (value: any): value is null => value === null
export const isUndefinedOrNull = (value: any) => isUndefined(value) || isNull(value)

export function convertArrayToHashByField(array: any[], field: string | number) {
  return array.reduce((acc, current) => {
    acc[current[field]] = current
    return acc
  }, {})
}

export function getFileExtension(fileName: string) {
  const fileParts = (fileName || "").split(".")
  if (fileParts.length > 2 || (fileParts.length > 1 && fileParts[0] !== "")) {
    return fileParts.pop().toLowerCase()
  } else {
    return ""
  }
}

export function mapType({ type }: { type: number }) {
  return type
}

// TODO: import types to enum type
export function isType(requiredType: number) {
  return ({ type }) => type === requiredType
}

export function toLowercaseKeys(obj: HashMap<any>) {
  return Object.keys(obj).reduce((acc, value) => {
    acc[value.toLowerCase()] = obj[value]
    return acc
  }, {})
}

export function compareStrings(stringA: string, stringB: string) {
  if (stringA !== stringB) {
    if (stringA < stringB || !stringB) {
      return -1
    }
    if (stringA > stringB || !stringA) {
      return 1
    }
  }
  return 0
}

export function filterLatinizedOptions(options: DropdownItemProps[], value): DropdownItemProps[] {
  return options.filter((option) => matchLatinized(option.text as string, value))
}

export function matchLatinized(text: string, sample: string): boolean {
  const pattern = new RegExp(`.*${escapeStringRegexp(latinize(sample))}.*`, "i")
  return !!latinize(text).match(pattern)
}

export function mergeClasses(...classNames: string[]) {
  return classNames.filter(Boolean).join(" ")
}

export function sumValues(obj: HashMap<number>): number {
  return Object.values(obj).reduce((a, b) => a + b, 0)
}

export function escapeStringRegexp(text: string): string {
  const matchOperatorsRegex = /[|\\{}()[\]^$+*?.-]/g

  if (typeof text !== "string") {
    throw new TypeError("Expected a string")
  }

  return text.replace(matchOperatorsRegex, "\\$&")
}

/**
 * Moves array member from given index to given index
 * @param array
 * @param from
 * @param to
 */
export function arrayMove(array: any[], from: number, to: number) {
  const newArray = array.slice()
  arrayMoveMutate(newArray, from, to)
  return newArray
}

function arrayMoveMutate(array: any[], from: number, to: number) {
  array.splice(to < 0 ? array.length + to : to, 0, array.splice(from, 1)[0])
}

export function milisecondsToHumanTime(milliseconds: number) {
  const seconds = Math.floor((milliseconds / 1000) % 60)
  const minutes = Math.floor((milliseconds / 1000 / 60) << 0)

  const minutesString = minutes < 10 ? "0" + minutes : minutes.toString()
  const secondsString = seconds < 10 ? "0" + seconds : seconds.toString()

  return `${minutesString}:${secondsString}`
}

/**
 * Returns an array of dates(days) between the given dates, including the given dates. All dates are set to 00:00:00
 * @param from  - start date.
 * @param to - end date. If not provided, the current date is used.
 */
export function getDatesBetween(from: Date, to: Date = new Date()): Date[] {
  // Set the time for the "to" date to the start of the day (00:00:00)

  const dates: Date[] = []
  const currentDate = new Date(from)

  while (currentDate <= to) {
    dates.push(new Date(currentDate))
    currentDate.setDate(currentDate.getDate() + 1)
  }

  return dates
}

/**
 * Creates date pairs from an array of date strings where each pair represents a "from" and "to" date.
 * Dates in the input array should be sorted in ascending order.
 *
 * @param {string[]} dateStrings - An array of date strings (e.g., 'YYYY-MM-DD').
 * @returns {Array<[string, string]>} An array of date pairs where each pair is [fromDate, toDate].
 */
export function createDatePairs(dateStrings: string[]): [string, string][] {
  const datePairs: [string, string][] = []

  if (dateStrings.length === 0) {
    return datePairs
  }

  if (dateStrings.length === 1) {
    datePairs.push([dateStrings[0], dateStrings[0]])
    return datePairs
  }

  let fromDate = dateStrings[0]
  let toDate = dateStrings[0]

  for (let i = 1; i < dateStrings.length; i++) {
    const currentDate = dateStrings[i]
    const prevDate = dateStrings[i - 1]

    const currentDateObj = new Date(currentDate)
    const prevDateObj = new Date(prevDate)

    currentDateObj.setDate(currentDateObj.getDate() - 1)

    if (currentDateObj.toISOString().split("T")[0] === prevDateObj.toISOString().split("T")[0]) {
      toDate = currentDate
    } else {
      datePairs.push([fromDate, toDate])
      fromDate = currentDate
      toDate = currentDate
    }
  }

  datePairs.push([fromDate, toDate])

  return datePairs
}

export function omitFiledsFromObject<T, K extends keyof T>(obj: T, fields: K[]): Omit<T, K> {
  const result = { ...obj }
  fields.forEach((field) => {
    delete result[field]
  })
  return result
}