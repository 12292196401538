import React from 'react'
import styles from 'src/frontend/scenes/contacts/contactDetail/components/ContactDetailHeader.module.less'
import { Contact } from 'src/types/Contact'
import { formatMessage } from 'src/frontend/modules/intl/i18n'
import { isUndefinedOrNull, mergeClasses } from 'src/common/utils'
import defaultProfileImage from 'src/frontend/assets/images/user_ic.svg'
import FormattedMessage from 'src/frontend/modules/intl/components/FormattedMessage'

interface Props {
  contact: Contact.ContactView
}

export default function ContactDetailHeader({ contact }: Props) {
  return (
    <div className={styles.header}>
      <div
        className={styles.avatar}
        style={{ backgroundImage: `url("${contact.avatarUrl || defaultProfileImage}")` }}
      />
      <div className={styles.detailColumn}>
        <div className={styles.detailItem}>
          <div className={styles.title}>
            <FormattedMessage id="form.type" />
          </div>
          <div className={styles.value}>
            <div className={styles.contactType} style={{ backgroundColor: contact.color }}>
              {contact.contactTypeName}
            </div>
          </div>
        </div>
      </div>

      <div className={styles.detailColumn}>
        <div className={styles.detailItem}>
          <div className={styles.title}>
            <FormattedMessage id="contacts.form.phone" />
          </div>
          <div
            className={mergeClasses(styles.value, isUndefinedOrNull(contact.phone) && styles.valueNone)}
          >
            {contact.phone || formatMessage('contacts.detail.value.none')}
          </div>
        </div>
        <div className={styles.detailItem}>
          <div className={styles.title}>
            <FormattedMessage id="contacts.form.email" />
          </div>
          <div className={mergeClasses(styles.value, isUndefinedOrNull(contact.email) && styles.valueNone)}>
            {contact.email || formatMessage('contacts.detail.value.none')}
          </div>
        </div>
      </div>

      <div className={styles.detailColumn}>
        <div className={styles.detailItem}>
          <div className={styles.title}>
            <FormattedMessage id="contacts.form.web" />
          </div>
          <div className={mergeClasses(styles.value, isUndefinedOrNull(contact.web) && styles.valueNone)}>
            {contact.web || formatMessage('contacts.detail.value.none')}
          </div>
        </div>
        <div className={styles.detailItem}>
          <div className={styles.title}>
            <FormattedMessage id="contacts.form.note" />
          </div>
          <div className={mergeClasses(styles.value, isUndefinedOrNull(contact.note) && styles.valueNone)}>
            {contact.note || formatMessage('contacts.detail.value.none')}
          </div>
        </div>
      </div>
    </div>
  )
}
