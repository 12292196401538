import React, {SyntheticEvent} from 'react'
import {connect} from 'react-redux'
import {CheckboxProps, Button, Icon, Radio} from 'semantic-ui-react'
import { handleCheckboxChange } from 'src/frontend/styles/semantic-ui/helpers'
import { formatMessage, FormattedMessage } from 'src/frontend/modules/intl'
import { EmailingConsentModal } from 'src/frontend/scenes/settings/privacy/components/ConsentChangeRequestModal'
import * as actions from 'src/frontend/scenes/settings/privacy/actions'
import {
    selectConsentBankSynchronization,
    selectConsentEmailing,
    selectConsentSegmentation,
} from 'src/frontend/modules/user/selectors'
import { isAppBoard, getPrivacyPolicyUrl, getTOSContentUrl } from 'src/common/environment'
import {selectIsConsentLoading} from 'src/frontend/scenes/settings/privacy/selectors'
import styles from 'src/frontend/scenes/settings/privacy/components/Privacy.module.less'
import {PrimaryButton} from 'src/frontend/components/Buttons/Buttons'
import DeleteDataModal from "./DeleteDataModal";
import DeleteDataConfirmationModal from './DeleteDataConfirmationModal'
import DeleteUserAndDataModal from './DeleteUserAndDataModal'
import DeleteUserAndDataConfirmationModal from "./DeleteUserAndDataConfirmationModal";

const SUPPORT_EMAIL = 'support@budgetbakers.com'
const DATA_PROTECTION_OFFICER_EMAIL = 'dpo@budgetbakers.com'

function mapStateToProps(state) {
    return {
        loading: selectIsConsentLoading(state),
        segmentation: selectConsentSegmentation(state),
        emailing: selectConsentEmailing(state),
        bankSync: selectConsentBankSynchronization(state),
    }
}

const mapDispatchToProps = {
    handleEmailingChange: handleCheckboxChange(actions.emailingChanged),
    handleOpenConfirmDeleteUserAndDataDialog: actions.openDeleteUserAndDataDialog,
    handleOpenConfirmDeleteDataDialog: actions.openDeleteDataDialog,
}

interface Props {
    loading: boolean
    segmentation: boolean
    emailing: boolean
    bankSync: boolean

    handleEmailingChange: (event: React.FormEvent<HTMLInputElement>, data: CheckboxProps) => void
    handleOpenConfirmDeleteUserAndDataDialog: (e: SyntheticEvent) => void
    handleOpenConfirmDeleteDataDialog: (e: SyntheticEvent) => void
}

function Privacy({
                     loading,
                     emailing,
                     handleEmailingChange,
                     handleOpenConfirmDeleteUserAndDataDialog,
                     handleOpenConfirmDeleteDataDialog,
                 }: Props) {
    return (
        <div className={`${isAppBoard() ? 'boardApp' : 'walletApp'} ${styles.settingsPrivacy}`}>
            <div className={styles.item}>
                <div className={styles.label}>
                    <FormattedMessage id='settings.privacy.privacy.title'/>
                </div>
                <div className={styles.buttonGroup}>
                    {' '}
                    <a
                        href={getPrivacyPolicyUrl()}
                        className={styles.policyButtons}
                        style={{borderRadius: isAppBoard() ? '4px' : '4em'}}
                        target='_blank'
                        rel='noopener noreferrer'
                    >
                        <Icon name='shield' className='semantic-ui icon'/>
                        <FormattedMessage id='auth.signup.privacy_policy.link_text'/>
                    </a>
                    <a
                        href={getTOSContentUrl()}
                        className={styles.policyButtons}
                        style={{borderRadius: isAppBoard() ? '4px' : '4em'}}
                        target='_blank'
                        rel='noopener noreferrer'
                    >
                        <Icon name='file alternate outline' className='semantic-ui icon'/>
                        <FormattedMessage id='auth.signup.terms_of_service.link_text'/>
                    </a>
                </div>
            </div>
            <div className={styles.item}>
                <EmailingConsentModal/>
                <div className={styles.label}>
                    <FormattedMessage id='settings.privacy.messages.title'/>
                </div>
                <div className={styles.controls}>
                    <Radio
                        name='emailing'
                        checked={emailing}
                        disabled={loading}
                        toggle
                        label={formatMessage('settings.privacy.messages.title')}
                        onChange={handleEmailingChange}
                    />
                </div>
                <div className={styles.descriptionSmall}>
                    <FormattedMessage id='settings.privacy.messages.description'/>
                </div>
            </div>

            <div className={styles.item}>
                <div className={styles.label}>
                    <FormattedMessage id='settings.privacy.data.title'/>
                </div>
                <div className={styles.description}>
                    <p>
                        <FormattedMessage id='settings.privacy.data.paragraph_1'/>
                    </p>
                    <p>
                        <FormattedMessage
                            id='settings.privacy.data.paragraph_2'
                            values={{supportEmail: SUPPORT_EMAIL}}
                        />
                    </p>
                    <p>
                        <FormattedMessage
                            id='settings.privacy.data.paragraph_3'
                            values={{dataProtectionOfficerEmail: DATA_PROTECTION_OFFICER_EMAIL}}
                        />
                    </p>
                </div>
            </div>

            <div className={styles.item}>
                <PrimaryButton color='red' inverted onClick={handleOpenConfirmDeleteDataDialog}>
                    <FormattedMessage id='settings.privacy.delete_all_user_data.button'/>
                </PrimaryButton>
                <DeleteDataModal/>
                <DeleteDataConfirmationModal/>

                <PrimaryButton color='red' onClick={handleOpenConfirmDeleteUserAndDataDialog}>
                    <FormattedMessage id='settings.privacy.delete_all_data.button'/>
                </PrimaryButton>
                <DeleteUserAndDataModal/>
                <DeleteUserAndDataConfirmationModal/>
            </div>
        </div>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(Privacy)
