import React from 'react'
import ExtendedRecordListItem from 'src/frontend/components/RecordList/ExtendedRecordListItem'
import { RecordListItemRecord } from 'src/frontend/components/RecordList/types'
import { Id } from 'src/types/CouchDb'

interface Props {
  records: RecordListItemRecord[]
  selectedRecords: RecordListItemRecord[]
  isRemoving: boolean
  onRecordCheck: Function
  onRecordEdit: Function
  onAssignClick: (recordId: Id) => void,
  onRecordDelete: (records: RecordListItemRecord[]) => void
}

export function RecordListWrapper({
  records,
  selectedRecords,
  isRemoving,
  onRecordCheck,
  onRecordDelete,
  onRecordEdit,
  onAssignClick,
}: Props) {
  return (
    <>
      {records.map((record): React.ReactNode => {
        const selected = selectedRecords.some(selectedRecord => selectedRecord._id === record._id)
        return (
          <ExtendedRecordListItem
            key={record._id}
            record={record}
            isRemoving={isRemoving}
            selected={selected}
            onEditRecordClick={(e) => {
              e.stopPropagation()
              onRecordEdit(record._id)
            }}
            onAssignClick={() => onAssignClick(record._id)}
            onRecordDelete={onRecordDelete}
            onCheckBoxChange={onRecordCheck ? () => onRecordCheck(record._id, selected) : undefined}
          />
        )
      })}
    </>
  )
}
