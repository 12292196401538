import { ReferralSource } from 'src/types/User'
import Cookies from 'js-cookie'
import { isUndefinedOrNull, parseIntDecimal } from 'src/common/utils'

export const REFERRAL_COOKIE_NAME = 'referralSource'

export function useReferralSource() {
  const cookie = Cookies.get(REFERRAL_COOKIE_NAME)

  if (!isUndefinedOrNull(cookie) && parseIntDecimal(cookie) in ReferralSource) {
    return parseIntDecimal(cookie)
  }
}

export function removeReferralSource() {
  const cookie = Cookies.get(REFERRAL_COOKIE_NAME)
  if (!isUndefinedOrNull(cookie) && parseIntDecimal(cookie) in ReferralSource) {
    Cookies.remove(REFERRAL_COOKIE_NAME)
  }
}
