import React from "react"
import { isDesktop } from "src/common/runtime"
import { Redirect, Switch } from "react-router"
import { Route } from "react-router-dom"
import MobileNotSupported from "src/frontend/scenes/app/MobileNotSupported"
import LoginPage from "src/frontend/scenes/auth/login/LoginPage"
import SignupPage from "src/frontend/scenes/auth/signup/SignupPage"
import SignUpKB from "src/frontend/scenes/kb/components/SignUpKB"
import SignUpVoucher from "src/frontend/scenes/voucher/components/SignUpVoucher"
import { isAppBoard } from "src/common/environment"

export default function PublicContent() {
  return isDesktop ? (
    <Switch>
      <Route
        exact
        path="/settings/billing"
        component={LoginPage}
      />
      <Route
        path="/login"
        component={LoginPage}
      />
      <Route
        exact
        path="/signup"
        component={SignupPage}
      />
      {isAppBoard() && (
        <Route
          exact
          path="/kb/signup"
          component={SignUpKB}
        />
      )}
      <Route
        exact
        path="/addvoucher/:voucherCode?"
        component={SignUpVoucher}
      />
      <Route
        render={(match) => (
          <Redirect
            to={{
              pathname: "/login",
              state: { referrer: match.location.pathname },
            }}
          />
        )}
      />
    </Switch>
  ) : (
    // MobileNotSupported needs to be wrapped in Router because of Link used down in the tree
    <Switch>
      <Route render={() => <MobileNotSupported />} />
    </Switch>
  )
}
