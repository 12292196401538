import { getRecordsCount } from 'src/backend/records/service'
import { Action, GetState, HashMap } from 'src/types/common'
import { isAppBoard } from 'src/common/environment'
import { isUndefinedOrNull } from 'src/common/utils'
import { selectConnectedAccountsAsArray } from 'src/frontend/modules/accounts/selectors'
import { selectUser } from 'src/frontend/modules/user/selectors'
import { isLessThan3Days, isLessThan7Days, isMoreThan30Days } from 'src/backend/time/time'
import { selectAdvertisementHiddenAt, selectHideMobileAppBanner } from 'src/frontend/modules/webConfig/selectors'
import { getBannerShown, setBannerShown } from 'src/backend/db/localStorage'

export const IN_APP_ON_BOARDING_SET_SHOW = 'IN_APP_ON_BOARDING_SET_SHOW'

export const IN_APP_ON_BOARDING_GOOD_JOB_OPEN = 'IN_APP_ON_BOARDING_GOOD_JOB_OPEN'
export const IN_APP_ON_BOARDING_GOOD_JOB_CLOSE = 'IN_APP_ON_BOARDING_GOOD_JOB_CLOSE'

export function updateShouldDisplayOnBoarding() {
  return async (dispatch: Function, getState: GetState) => {
    if (!isAppBoard()) {
      return
    }

    const [count, unknownCount] = await getRecordsCount()
    const connectedAccounts = selectConnectedAccountsAsArray(getState())

    const dashboardNextState: boolean = (!count || count < 1) && connectedAccounts.length < 1 && !getBannerShown()

    const recordsNextState: boolean = !dashboardNextState
      && !isUndefinedOrNull(count)
      && !isUndefinedOrNull(unknownCount)
      && count === unknownCount

    const user = selectUser(getState())

    const isUserOldEnoughForAdvertisement = !isLessThan7Days(user.createdAt)
    const advertisementHiddenAt = selectAdvertisementHiddenAt(getState())

    const advertisementNextState: boolean = isUserOldEnoughForAdvertisement
      && !getBannerShown()
      && (!advertisementHiddenAt || isMoreThan30Days(advertisementHiddenAt))
      && user.countryCode?.toLowerCase() === 'cz'

    const isUserOldEnoughForMobileAppsBanner = !isLessThan3Days(user.createdAt)
    const shouldHideMobileAppBanner = selectHideMobileAppBanner(getState())

    const mobileAppsBannerNextState: boolean = !dashboardNextState
      && !getBannerShown()
      && !advertisementNextState
      && isUserOldEnoughForMobileAppsBanner
      && !shouldHideMobileAppBanner

    if (mobileAppsBannerNextState || dashboardNextState || advertisementNextState) {
      setBannerShown(true)
    }

    dispatch({
      type: IN_APP_ON_BOARDING_SET_SHOW,
      payload: {
        mobileAppsBanner: mobileAppsBannerNextState,
        dashboard: dashboardNextState,
        records: recordsNextState,
        advertisement: advertisementNextState,
      },
    })
  }
}

export function openGoodJobModal(text: string, values?: HashMap<any>): Action {
  return {
    type: IN_APP_ON_BOARDING_GOOD_JOB_OPEN,
    payload: { text, values },
  }
}

export function closeGoodJobModal(): Action {
  return {
    type: IN_APP_ON_BOARDING_GOOD_JOB_CLOSE,
  }
}
