import React from 'react'
import { connect } from 'react-redux'
import * as selectors from '../selectors'
import SuperEnvelopesList from './SuperEnvelopesList'
import CategoriesList from './CategoriesList'
import * as actions from '../actions'
import styles from './Categories.module.less'
import { SuperEnvelope } from 'src/types/Category'
import { selectCategoriesHierarchy } from 'src/frontend/modules/categories/selectors'

interface Props {
  superEnvelopes: SuperEnvelope[]
  selectedEnvelopeId: number
  handleEnvelopeChange: Function
}


const mapStateToProps = (state) => {
  return {
    superEnvelopes: selectCategoriesHierarchy(state),
    selectedEnvelopeId: selectors.selectEnvelopeId(state),
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    handleEnvelopeChange: (currentEnvelopeId) => (envelopeId) => {
      if (currentEnvelopeId !== envelopeId) {
        dispatch(actions.changeCategory(envelopeId))
      }
    },
  }
}

function Categories({ superEnvelopes, selectedEnvelopeId, handleEnvelopeChange }: Props) {
  const { envelopes } = superEnvelopes.find(
    (superEnvelope) => superEnvelope.id === selectedEnvelopeId,
  )

  return (
    <div className={styles.settingsCategories}>
      <SuperEnvelopesList
        superEnvelopes={superEnvelopes}
        onClick={handleEnvelopeChange(selectedEnvelopeId)}
        activeEnvelopeId={selectedEnvelopeId}
      />
      <CategoriesList categoriesHierarchy={envelopes} />
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(Categories)
