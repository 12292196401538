import { Doughnut } from 'react-chartjs-2'
import FormattedPercentage from 'src/frontend/modules/intl/components/FormattedPercentage'
import { FormattedMessage, FormattedMoney } from 'src/frontend/modules/intl'
import React, { useEffect } from 'react'
import { debtPieOptions, getDebtPieChartData } from 'src/frontend/components/DebtPieChart/helpers'
import radiusBackground from 'src/frontend/components/DebtPieChart/donutBackgroundPlugin'
import styles from './DebtPieChart.module.less'
import { CashFlowBarColors } from 'src/frontend/scenes/dashboard/constants'
import { useCountUp } from 'react-countup'
import { parseIntDecimal } from 'src/common/utils'

interface Props {
  currencyCode: string
  totalValue: number
  value: number
}

export default function DebtPieChart({ currencyCode, totalValue, value }: Props) {
  const percentValue = parseIntDecimal(value / totalValue * 100) / 100 || 0
  const color = percentValue <= 0.3 ? CashFlowBarColors.DARK_GREEN : CashFlowBarColors.DARK_RED
  const chartData = getDebtPieChartData(percentValue, color)

  const { countUp, update } = useCountUp({
    end: percentValue,
    decimals: 2,
  })

  useEffect(() => {
    update(percentValue)
  }, [percentValue])

  return (
    <div className={styles.debtPieChat}>
      <div className={styles.pie}>
        <Doughnut data={chartData} options={debtPieOptions} plugins={[radiusBackground()]} height={110} />
        <FormattedPercentage className={styles.percentage} value={countUp as number} style={{ color }} />
      </div>
      <div className={styles.numberValues}>
        <div>
          <div className={styles.title}>
            <FormattedMessage id="dashboard.widget.credit-balance" />
          </div>
          <div className={styles.value}>
            <FormattedMoney value={value} currency={currencyCode} />
          </div>
        </div>
        <div>
          <div className={styles.title}>
            <FormattedMessage id="dashboard.widget.total-credit-limit" />
          </div>
          <div className={styles.value}>
            <FormattedMoney value={totalValue} currency={currencyCode} />
          </div>
        </div>
      </div>
    </div>
  )
}
