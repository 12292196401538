import { combineReducers } from 'redux'
import firstRun, { OnBoardingFirstRunState } from 'src/frontend/scenes/onBoarding/firstRun/reducer'
import inApp, { InAppOnBoardingState } from 'src/frontend/scenes/onBoarding/inApp/reducer'

export interface OnBoardingState {
  firstRun: OnBoardingFirstRunState
  inApp: InAppOnBoardingState
}

export default combineReducers({ firstRun, inApp })
