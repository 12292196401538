import { createSelector } from "reselect"
import { selectAllAccounts } from "src/frontend/modules/accounts/selectors"
import { Interval, IntervalCompareToType, IntervalGranularityType } from "src/backend/enums"
import { ChartType } from "src/frontend/components/chart/ChartType"
import { selectFilter } from "src/frontend/modules/filter/selectors"
import {
  ChartGroupByType,
  ChartSubjectType,
  LegendIconType,
} from "src/frontend/scenes/analytics/enums"
import { Dataset } from "src/frontend/components/chart/types"
import { RootState } from "src/types/State"
import {
  AnalyticsState,
  ChartData,
  ChartPoint,
  ChartState,
  ReportState,
  ViewDefinition,
} from "src/frontend/scenes/analytics/types"
import { Account } from "src/types/Account"
import {
  COMPARED_DATASET_DEFAULT_OPTIONS,
  DATASET_DEFAULT_OPTIONS,
} from "src/frontend/scenes/analytics/constants"
import { convertData, reduceSuperEnvelopesToReport } from "src/frontend/scenes/analytics/helpers"
import * as categoriesSelectors from "src/frontend/modules/categories/selectors"
import { isAppBoard } from "src/common/environment"
import { CashFlowReport } from "src/frontend/scenes/analytics/Report/types"
import { isUndefinedOrNull, sumValues } from "src/common/utils"
import { countPercentChange } from "src/common/helpers"
import {
  calculateCashTransfers,
  calculateInvestments,
  calculateLoansRepayment,
  calculateNewFunding,
  calculateOperatingCosts,
  calculateOperatingRevenue,
  calculateUnknown,
  convertChartLabels,
} from "src/backend/analytics/helpers"
import { HashMap } from "src/types/common"
import { SuperEnvelope } from "src/types/Category"
import { formatMessage } from "src/frontend/modules/intl"

const selectAnalytics = (state: RootState): AnalyticsState => state.scenes.analytics
const selectChartState = (state: RootState): ChartState => selectAnalytics(state).chart

export const selectViewDefinition = (state: RootState): ViewDefinition =>
  selectAnalytics(state).viewDefinition

export const selectChartDatasets: (state: RootState) => Array<Dataset> = createSelector(
  [
    selectChartState,
    selectViewDefinition,
    selectAllAccounts,
    categoriesSelectors.selectCompleteCategoriesHierarchy,
  ],
  (
    chartState: ChartState,
    viewDefinition: ViewDefinition,
    accounts: HashMap<Account>,
    categoriesHierarchy: SuperEnvelope[],
  ): Array<Dataset> => {
    const datasets: Array<Dataset> = chartState.datasets.map((chartDataset: ChartData) => {
      const isComparisonDataset = !isUndefinedOrNull(chartDataset.comparisonIndex)

      let datasetOptions
      // check if chartDataset.accountId exist, because selectViewDefinition and selectView could be out of sync
      if (ChartGroupByType.ACCOUNTS === viewDefinition.groupBy && chartDataset.accountId) {
        const account = accounts[chartDataset.accountId]
        datasetOptions = {
          ...DATASET_DEFAULT_OPTIONS,
          borderColor: account && account.color,
          pointBackgroundColor: account && account.color,
          backgroundColor: account && account.color,
          label: account?.name,
          legendIconType: LegendIconType.ACCOUNT,
          legendIconValue: account && account.accountType,
        }
        // check if chartDataset.superEnvelopeId exist, because selectViewDefinition and selectView could be out of sync
      } else if (
        ChartGroupByType.CATEGORIES === viewDefinition.groupBy &&
        chartDataset.superEnvelopeId
      ) {
        const superEnvelope = categoriesHierarchy.find((envelope) => {
          return envelope.id === chartDataset.superEnvelopeId
        })

        datasetOptions = {
          ...DATASET_DEFAULT_OPTIONS,
          borderColor: superEnvelope?.color,
          pointBackgroundColor: superEnvelope?.color,
          backgroundColor: superEnvelope?.color,
          label: superEnvelope?.name,
          legendIconType: LegendIconType.CATEGORY,
          legendIconValue: superEnvelope?.iconName,
        }
      } else {
        datasetOptions = {
          ...(isComparisonDataset ? COMPARED_DATASET_DEFAULT_OPTIONS : DATASET_DEFAULT_OPTIONS),
          label: !isComparisonDataset ? formatMessage(viewDefinition.subject) : null,
          comparisonIndex: chartDataset.comparisonIndex,
        }
      }

      return {
        data: chartDataset.data,
        ...datasetOptions,
      }
    })

    return convertData(viewDefinition.chartType, datasets)
  },
)

export const selectViewDefinitionOptions = createSelector(
  [selectViewDefinition, selectFilter],
  (viewDefinition, filter) => {
    return {
      subject: allowedSubjectOptions(viewDefinition),
      groupBy: [ChartGroupByType.NONE, ChartGroupByType.ACCOUNTS, ChartGroupByType.CATEGORIES],
      granularity: allowedGranularityOptions(filter),
      chartType: [ChartType.LINE, ChartType.BAR, ChartType.PIE],
      compareTo: [
        IntervalCompareToType.NONE,
        IntervalCompareToType.PREVIOUS_WEEK,
        IntervalCompareToType.PREVIOUS_MONTH,
        IntervalCompareToType.PREVIOUS_QUARTER,
        IntervalCompareToType.PREVIOUS_YEAR,
      ],
    }
  },
)

function allowedSubjectOptions(viewDefinition) {
  const {
    BALANCE,
    CASH_FLOW,
    INCOME,
    EXPENSE,
    INCOMES_EXPENSES_REPORT,
    OPERATING_PROFIT_REPORT,
    BOARD_CASH_FLOW_REPORT,
    CUMULATIVE_CASH_FLOW,
    CUMULATIVE_EXPENSE,
    CUMULATIVE_INCOME,
  } = ChartSubjectType

  if (isAppBoard()) {
    return [ChartGroupByType.CATEGORIES].includes(viewDefinition.groupBy)
      ? [
          BOARD_CASH_FLOW_REPORT,
          OPERATING_PROFIT_REPORT,
          CASH_FLOW,
          CUMULATIVE_CASH_FLOW,
          INCOME,
          EXPENSE,
          CUMULATIVE_INCOME,
          CUMULATIVE_EXPENSE,
        ]
      : [
          BOARD_CASH_FLOW_REPORT,
          OPERATING_PROFIT_REPORT,
          BALANCE,
          CASH_FLOW,
          CUMULATIVE_CASH_FLOW,
          INCOME,
          EXPENSE,
          CUMULATIVE_INCOME,
          CUMULATIVE_EXPENSE,
        ]
  }

  return [ChartGroupByType.CATEGORIES].includes(viewDefinition.groupBy)
    ? [
        INCOMES_EXPENSES_REPORT,
        CASH_FLOW,
        CUMULATIVE_CASH_FLOW,
        INCOME,
        EXPENSE,
        CUMULATIVE_INCOME,
        CUMULATIVE_EXPENSE,
      ]
    : [
        INCOMES_EXPENSES_REPORT,
        BALANCE,
        CASH_FLOW,
        CUMULATIVE_CASH_FLOW,
        INCOME,
        EXPENSE,
        CUMULATIVE_INCOME,
        CUMULATIVE_EXPENSE,
      ]
}

function allowedGranularityOptions(filter) {
  const { DAY, WEEK, MONTH, ALL } = IntervalGranularityType
  if (!filter) {
    return [DAY, WEEK, MONTH, ALL]
  }

  if (Interval.WEEK === filter.period.interval) {
    return [DAY, ALL]
  } else if (Interval.MONTH === filter.period.interval) {
    return [DAY, WEEK, ALL]
  } else {
    return [DAY, WEEK, MONTH, ALL]
  }
}

export function selectChartType(state: RootState): string {
  return selectViewDefinition(state).chartType
}

export const selectChartLabels: (state: RootState) => string[][] | string[] = createSelector(
  [
    selectViewDefinition,
    selectChartState,
    selectAllAccounts,
    categoriesSelectors.selectCompleteCategoriesHierarchy,
  ],
  getChartLabels,
)

export function getChartLabels(
  viewDefinition: ViewDefinition,
  chartState: ChartState,
  accounts: HashMap<Account>,
  categoriesHierarchy: SuperEnvelope[],
): string[][] | string[] {
  const { chartType }: { chartType: string } = viewDefinition
  const inputData: Array<ChartData> = chartState.datasets

  if (inputData.length === 0) {
    return []
  }

  if (chartType === ChartType.PIE) {
    return inputData
      .map((chartData: ChartData): string | null => {
        if (chartData.data.some((chartPoint: ChartPoint): boolean => !!chartPoint.y)) {
          if (chartData.superEnvelopeId) {
            const superEnvelope = categoriesHierarchy.find((envelope) => {
              return envelope.id === chartData.superEnvelopeId
            })

            return superEnvelope.name
          } else if (chartData.accountId) {
            return accounts[chartData.accountId].name
          } else {
            return null
          }
        } else {
          return null
        }
      })
      .filter((value) => !!value)
  } else {
    return convertChartLabels(inputData)
  }
}

export const selectReport = (state: RootState): ReportState => selectAnalytics(state).report

export const selectCategoriesReportData = (state: RootState) =>
  selectReport(state).categoriesReportData

export const selectAllCategoriesWithReportData = createSelector(
  [categoriesSelectors.selectCompleteCategoriesHierarchy, selectCategoriesReportData],
  combineCategoriesReport,
)

export const selectOperatingProfitWithReportData = createSelector(
  [categoriesSelectors.selectOperatingCategoriesHierarchy, selectCategoriesReportData],
  combineCategoriesReport,
)

export const selectOperatingRevenueWithReportData = createSelector(
  [categoriesSelectors.selectOperatingRevenueCategoriesHierarchy, selectCategoriesReportData],
  combineCategoriesReport,
)

export const selectOperatingCostWithReportData = createSelector(
  [categoriesSelectors.selectOperatingCostCategoriesHierarchy, selectCategoriesReportData],
  combineCategoriesReport,
)

export const selectIncomeCategoriesWithReportData = createSelector(
  [categoriesSelectors.selectIncomeCategoriesHierarchy, selectCategoriesReportData],
  combineCategoriesReport,
)

export const selectExpenseCategoriesWithReportData = createSelector(
  [categoriesSelectors.selectExpenseCategoriesHierarchy, selectCategoriesReportData],
  combineCategoriesReport,
)

function combineCategoriesReport(categoriesHierarchy, categoriesReport) {
  if (!categoriesReport) {
    return null
  }

  const { currentPeriod, previousPeriod } = categoriesReport
  return reduceSuperEnvelopesToReport(categoriesHierarchy, currentPeriod, previousPeriod)
}

export const selectBoardCashFlowReportData = (state: RootState) =>
  selectReport(state).boardCashFlowReportData

export const selectBoardCashFlowReport: (state: RootState) => CashFlowReport = createSelector(
  [selectBoardCashFlowReportData],
  (cashFlowReportData) => {
    if (!cashFlowReportData) {
      return null
    }

    const {
      currentPeriod,
      previousPeriod,
      balance: currentPeriodOpeningBalance,
      previousPeriodBalance: previousPeriodOpeningBalance,
    } = cashFlowReportData

    const currentPeriodData = {
      operatingRevenue: calculateOperatingRevenue(currentPeriod),
      operatingCosts: calculateOperatingCosts(currentPeriod),
      newFunding: calculateNewFunding(currentPeriod),
      loansRepayment: calculateLoansRepayment(currentPeriod),
      investments: calculateInvestments(currentPeriod),
      cashTransfers: calculateCashTransfers(currentPeriod),
      unknown: calculateUnknown(currentPeriod),
    }

    const currentPeriodCashFlow = sumValues(currentPeriodData)
    const currentPeriodEndingBalance = currentPeriodOpeningBalance + currentPeriodCashFlow

    const previousPeriodData = {
      operatingRevenue: calculateOperatingRevenue(previousPeriod),
      operatingCosts: calculateOperatingCosts(previousPeriod),
      newFunding: calculateNewFunding(previousPeriod),
      loansRepayment: calculateLoansRepayment(previousPeriod),
      investments: calculateInvestments(previousPeriod),
      cashTransfers: calculateCashTransfers(previousPeriod),
      unknown: calculateUnknown(previousPeriod),
    }

    const percentageChanges = {
      operatingRevenue: countPercentChange(
        previousPeriodData.operatingRevenue,
        currentPeriodData.operatingRevenue,
      ),
      operatingCosts: countPercentChange(
        previousPeriodData.operatingCosts,
        currentPeriodData.operatingCosts,
      ),
      newFunding: countPercentChange(previousPeriodData.newFunding, currentPeriodData.newFunding),
      loansRepayment: countPercentChange(
        previousPeriodData.loansRepayment,
        currentPeriodData.loansRepayment,
      ),
      investments: countPercentChange(
        previousPeriodData.investments,
        currentPeriodData.investments,
      ),
      cashTransfers: countPercentChange(
        previousPeriodData.cashTransfers,
        currentPeriodData.cashTransfers,
      ),
      unknown: countPercentChange(previousPeriodData.unknown, currentPeriodData.unknown),
    }

    const previousPeriodCashFlow = sumValues(previousPeriodData)
    const previousPeriodEndingBalance = previousPeriodOpeningBalance + previousPeriodCashFlow

    const openingBalanceChange = countPercentChange(
      previousPeriodOpeningBalance,
      currentPeriodOpeningBalance,
    )

    const endingBalanceChange = countPercentChange(
      previousPeriodEndingBalance,
      currentPeriodEndingBalance,
    )

    return {
      currentPeriodOpeningBalance,
      currentPeriodCashFlow,
      currentPeriodData,
      currentPeriodEndingBalance,
      previousPeriodOpeningBalance,
      previousPeriodData,
      previousPeriodCashFlow,
      previousPeriodEndingBalance,
      percentageChanges,
      openingBalanceChange,
      endingBalanceChange,
    }
  },
)
