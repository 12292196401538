import React from 'react'
import Content from 'src/frontend/scenes/dashboard/components/Widget/Content'
import EmptyContent from 'src/frontend/scenes/dashboard/components/Widget/EmptyContent'
import Header from 'src/frontend/scenes/dashboard/components/Widget/Header'
import Summary from 'src/frontend/scenes/dashboard/components/Widget/Summary'
import styles from 'src/frontend/scenes/dashboard/components/Widget/Widget.module.less'
import WidgetPlaceholder from 'src/frontend/scenes/dashboard/components/Widget/WidgetPlaceholder'
import { mergeClasses } from 'src/common/utils'

type Props = {
  children: React.ReactNode,
  className?: string,
}

Widget.defaultProps = {
  className: undefined,
}

export default function Widget({ children, className }: Props) {
  return (
    <div className={mergeClasses(styles.dashboardWidget, className)}>
      {children}
    </div>
  )
}

Widget.Content = Content
Widget.EmptyContent = EmptyContent
Widget.Header = Header
Widget.Summary = Summary
Widget.Placeholder = WidgetPlaceholder
