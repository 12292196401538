import lf from "lovefield"

export const presistentTableNames = {
  STOCK_PRICE: "stock_price",
  EXCHANGE_RATE: "exchange_rate",
}

export class PresistentWalletSQL {
  private constructor() {}
  private static db: lf.Database
  private static instance: PresistentWalletSQL

  async init() {
    const schemaBuilder = lf.schema.create("wallet_presistent", 1)

    schemaBuilder
      .createTable(presistentTableNames.STOCK_PRICE)
      .addColumn("value", lf.Type.NUMBER)
      .addColumn("date", lf.Type.DATE_TIME)
      .addColumn("stockIdentifier", lf.Type.STRING)
      .addPrimaryKey(["stockIdentifier", "date"])

    schemaBuilder
      .createTable(presistentTableNames.EXCHANGE_RATE)
      .addColumn("value", lf.Type.NUMBER)
      .addColumn("date", lf.Type.DATE_TIME)
      .addColumn("currencyCodes", lf.Type.STRING)
      .addPrimaryKey(["currencyCodes", "date"])

    const db = await schemaBuilder.connect({ storeType: lf.schema.DataStoreType.INDEXED_DB })

    PresistentWalletSQL.db = db

    return db
  }

  static getInstance(): PresistentWalletSQL {
    if (!PresistentWalletSQL.instance) {
      PresistentWalletSQL.instance = new PresistentWalletSQL()
    }

    return PresistentWalletSQL.instance
  }

  getDatabase(): lf.Database {
    if (!PresistentWalletSQL.db) {
      throw new Error("WalletSQL: Trying to get uninitialized database")
    }
    return PresistentWalletSQL.db
  }
}
