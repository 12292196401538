import React, { useCallback } from 'react'
import { connect } from 'react-redux'
import { Radio } from 'semantic-ui-react'
import { FormattedMessage, FormattedMessageLabel } from 'src/frontend/modules/intl'
import { withIntlConsumer } from 'src/frontend/modules/intl/components/withIntlConsumer'
import { selectShowDecimalPlaces } from 'src/frontend/modules/webConfig/selectors'
import { setShowDecimals } from 'src/frontend/modules/webConfig/actions'

type Props = {
  changeShowDecimals: Function,
  showDecimals: boolean,
}

const mapStateToProps = state => ({
  showDecimals: selectShowDecimalPlaces(state),
})

const mapDispatchToProps = {
  changeShowDecimals: setShowDecimals,
}

function ChangeShowNumberDecimals({ showDecimals, changeShowDecimals }: Props) {

  const handleToggleRadio = useCallback((_e, { checked }) => {
    changeShowDecimals(!checked)
  }, [])

  return (
    <div className="item">
      <div className="label">
        <FormattedMessage id="settings.general.number-format" />
      </div>
      <div>
        <Radio
          toggle
          checked={!showDecimals}
          onChange={handleToggleRadio}
          label={<FormattedMessageLabel id="settings.general.number-format.hide-decimals" />}
        />
      </div>
    </div>
  )
}

export default withIntlConsumer(connect(mapStateToProps, mapDispatchToProps)(ChangeShowNumberDecimals))
