import { pipe } from 'ramda'
import _differenceBy from 'lodash/differenceBy'
import { createSelector } from 'reselect'
import { groupRecordsByTransactionId } from 'src/backend/imports/service'
import { selectFlattenedCategories } from 'src/frontend/modules/categories/selectors'
import {
  selectItemTransactionIdToDelete,
  selectVisibleImportedItems,
  selectVisibleTransactionIds,
} from 'src/frontend/scenes/imports/items/selectors'
import { selectImportsState } from 'src/frontend/scenes/imports/local-selectors'
import { selectPageMode } from 'src/frontend/scenes/imports/selectors'
import { aggregateRecords } from 'src/frontend/scenes/records/recordList/selectors'
import _isEmpty from 'lodash/isEmpty'
import { PageMode } from 'src/frontend/scenes/imports/constants'
import { selectAllAccounts } from 'src/frontend/modules/accounts/selectors'
import { selectCurrencies, selectReferentialCurrency } from 'src/frontend/modules/currencies/selectors'
import { selectAllLabels } from 'src/frontend/modules/labels/selectors'
import { selectContacts } from 'src/frontend/modules/contacts/selectors'

const selectRecordsState = importsState => importsState.records

export const selectRecordList = pipe(selectImportsState, selectRecordsState, records => records.recordList)

const selectRecordGroupStatus = pipe(selectImportsState, selectRecordsState, records => records.recordGroupStatus)

export const selectShowRecords = state => (
  selectPageMode(state) === PageMode.RECORDS && !_isEmpty(selectImportRecordsGrouped(state))
)

export const selectSelectedRecordsStatus = pipe(
  selectImportsState, selectRecordsState,
  record => record.selectedRecords,
)

const filterAndGroupRecords = (
  recordsDecorated,
  recordGroupStatus,
  importedItems,
  visibleTransactionIds,
  selectedRecordStatus,
) => {
  const records = recordsDecorated.map(record => {
    return { ...record, selected: selectedRecordStatus.getOrDefault(record._id, false) }
  })
  return groupRecordsByTransactionId(records, visibleTransactionIds)
    .map(group => {
      const active = recordGroupStatus.getOrDefault(group.transactionId, true)
      const { itemCreatedAt, itemId, transactionId, fileName } = importedItems
        .find(item => item.transactionId === group.transactionId)
      return {
        ...group,
        active,
        itemImportedDate: itemCreatedAt,
        itemName: fileName,
        itemId,
        transactionId,
      }
    })
}

// hardcoded selectOrderBy for imports
const dummyOrderSelector = () => 'recordDate-DESC'

// selector created from recordList selector in order to have the same data to reuse UI components
const selectImportRecords = createSelector(
  [
    selectAllAccounts,
    selectFlattenedCategories,
    selectCurrencies,
    selectAllLabels,
    selectContacts,
    selectReferentialCurrency,
    dummyOrderSelector,
    selectRecordList,
  ],
  aggregateRecords,
)

export const selectImportRecordsGrouped = createSelector(
  [selectImportRecords, selectRecordGroupStatus, selectVisibleImportedItems, selectVisibleTransactionIds,
    selectSelectedRecordsStatus],
  filterAndGroupRecords,
)

export const selectSelectedRecords = createSelector(
  [selectImportRecords, selectSelectedRecordsStatus],
  (recordList, selectedRecords) => {
    return recordList.filter(record => {
      return selectedRecords.getOrDefault(record._id, false)
    })
  },
)

export const selectVisibleRecords = createSelector(
  [selectVisibleTransactionIds, selectRecordList],
  (transactionIds, recordList) => {
    return recordList.filter(record => transactionIds.includes(record.transactionId))
  },
)

export const selectIsAllRecordsSelected = createSelector(
  [selectVisibleRecords, selectSelectedRecords],
  (visibleRecords, selectedRecords) => {
    const containsAll = (records1, records2) => _differenceBy(records1, records2, record => record._id).length === 0
    return containsAll(visibleRecords, selectedRecords)
  },
)

export const selectImportsRemoveRecordsForm = pipe(
  selectImportsState,
  selectRecordsState,
  recordsState => recordsState.removeForm,
)

// This selector seems to be item selector but it has dependency on records selector.
// It is here to avoid circular dependency
export const selectItemListDecorated = createSelector(
  [selectVisibleImportedItems, selectRecordList],
  (items, records) => {
    return items.map(item => {
      const { recordsCount, recordsFromDate, recordsToDate } = records.reduce((result, record) => {
        const { recordDate, transactionId } = record
        if (item.transactionId !== transactionId) {
          return result
        }
        result.recordsCount++
        if (!result.recordsFromDate || result.recordsFromDate.getTime() > recordDate.getTime()) {
          result.recordsFromDate = recordDate
        }
        if (!result.recordsToDate || result.recordsToDate.getTime() < recordDate.getTime()) {
          result.recordsToDate = recordDate
        }
        return result
      }, { recordsCount: 0, recordsFromDate: null, recordsToDate: null })
      return { ...item, recordsCount, recordsFromDate, recordsToDate }
    })
  },
)

// This selector seems to be item selector but it has dependency on records selector.
// It is here to avoid circular dependency
export const selectImportRecordsForRecordList = createSelector(
  [selectImportRecordsGrouped, selectItemListDecorated],
  (recordGroups, items) => {
    return recordGroups.map(recordGroup => {
      const { recordsCount, recordsFromDate, recordsToDate } = items.find(item => item.itemId === recordGroup.itemId)
      return { ...recordGroup, recordsCount, recordsFromDate, recordsToDate }
    })
  },
)

export const selectItemToDeleteRecordList = createSelector(
  [selectImportRecords, selectItemTransactionIdToDelete],
  (recordsObject, transactionId) => {
    return Object.values(recordsObject)
      .filter(record => !!record.transactionId && record.transactionId === transactionId)
  },
)
