import React, { useEffect } from "react"
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import {
  isBraintreeSubscriptionPremium,
  isLifetime,
  isPaidPremium,
  isPremium,
} from "src/backend/user/service"
import { getAppName } from "src/common/environment"
import { isUndefinedOrNull } from "src/common/utils"
import { SecondaryButton } from "src/frontend/components/Buttons/Buttons"
import { FormattedMessage } from "src/frontend/modules/intl"
import FormattedMoney from "src/frontend/modules/intl/components/FormattedMoney"
import { formatDateLong } from "src/frontend/modules/intl/i18n"
import {
  BraintreeSubscription,
  BraintreeSubscriptionStatus,
} from "src/frontend/scenes/billing/activeSubscription/types"

import * as productActions from "src/frontend/scenes/billing/product/actions"
import { getPlanLabel } from "src/frontend/scenes/billing/product/helpers"
import {
  selectBraintreeLifetimeProduct,
  selectProductsError,
  selectProductsLoading,
} from "src/frontend/scenes/billing/product/selectors"
import { SettingsItem } from "src/frontend/scenes/settings/billing/BillingItem"
import CancelSubscriptionModal from "src/frontend/scenes/settings/billing/CancelSubscriptionModal"
import styles from "src/frontend/scenes/settings/billing/components/YourPlan.module.less"
import { Billing } from "src/types/Billing"
import { EventCallback } from "src/types/Semantic-ui"
import { RootState } from "src/types/State"
import { User } from "src/types/User"
import BraintreeProduct = Billing.BraintreeProduct
import Product = Billing.Product

interface OwnProps {
  user: User
  subscription: BraintreeSubscription
}

interface StateProps {
  loading: boolean
  lifetimeProduct: BraintreeProduct
  error: string
}

interface Actions {
  selectLifetimeProduct: EventCallback
  getProducts: Function
}

function mapStateToProps(state: RootState): StateProps {
  return {
    lifetimeProduct: selectBraintreeLifetimeProduct(state),
    error: selectProductsError(state),
    loading: selectProductsLoading(state),
  }
}

const dispatchToProps: Actions = {
  selectLifetimeProduct: productActions.selectLifetimeProduct,
  getProducts: productActions.getProducts,
}

function createMockLifetime(user: User): Product {
  return {
    period: Billing.PeriodType.UNLIMITED,
    planType: user.activePlan.planType,
    productId: "mock_lifetime",
  }
}

function YourPlan({
  user,
  subscription,
  loading,
  lifetimeProduct,
  error,
  selectLifetimeProduct,
  getProducts,
}: StateProps & OwnProps & Actions) {
  useEffect(() => {
    if (!lifetimeProduct && !loading) {
      getProducts()
    }
  }, [lifetimeProduct])

  return (
    <SettingsItem
      title={<FormattedMessage id="billing.your-plan" />}
      action={getPlanActions(user, subscription, lifetimeProduct, selectLifetimeProduct)}
      icon={isPremium(user) ? "star" : undefined}
    >
      {getPlanContent(user, subscription)}
    </SettingsItem>
  )
}

export default connect<StateProps, Actions, OwnProps>(mapStateToProps, dispatchToProps)(YourPlan)

function getPlanActions(
  user: User,
  subscription: BraintreeSubscription,
  lifetimeProduct: BraintreeProduct,
  selectProduct: EventCallback,
) {
  if (!(isPaidPremium(user) || isLifetime(user))) {
    return (
      <SecondaryButton
        as={Link}
        to="/settings/billing/choose-plan"
      >
        <FormattedMessage id="premium_checker.upgrade_to_premium" />
      </SecondaryButton>
    )
  }

  return [
    getCancelAction(user, subscription),
    getUpgradeAction(user, lifetimeProduct, selectProduct),
  ]
}

function getCancelAction(user: User, subscription: BraintreeSubscription) {
  if (
    isBraintreeSubscriptionPremium(user) &&
    !isUndefinedOrNull(subscription) &&
    (subscription.status === BraintreeSubscriptionStatus.ACTIVE ||
      subscription.status === BraintreeSubscriptionStatus.PAST_DUE)
  ) {
    return <CancelSubscriptionModal />
  }
}

function getUpgradeAction(
  user: User,
  lifetimeProduct: BraintreeProduct,
  selectProduct: EventCallback,
) {
  // only when lifetime is available (delegate decision to BE) amd user has no lifetime yet
  if (lifetimeProduct && !isLifetime(user)) {
    return (
      <SecondaryButton
        as={Link}
        onClick={selectProduct}
        to="/settings/billing/payment-method"
      >
        <FormattedMessage id="premium_checker.upgrade_to_lifetime" />
      </SecondaryButton>
    )
  }
}

function getPlanContent(user: User, subscription: BraintreeSubscription) {
  if (!isPremium(user)) {
    return (
      <div>
        <div className={styles.bold}>
          <FormattedMessage id="billing.subscription.plan.free" />
        </div>
      </div>
    )
  }

  // subscription is in "expiring" state - cancelled but yet valid
  if (
    isBraintreeSubscriptionPremium(user) &&
    !isUndefinedOrNull(subscription) &&
    subscription.status === BraintreeSubscriptionStatus.CANCELED
  ) {
    return (
      <div>
        <div className={styles.bold}>
          {getAppName()} {getPlanLabel(user.activePlan.currentProduct)}
        </div>
        <div className={styles.description}>
          <FormattedMessage
            id="billing.subscription.expiring"
            values={{
              expirationDate: (
                <strong>{formatDateLong(new Date(subscription.nextBillingDate))}</strong>
              ),
            }}
          />
        </div>
      </div>
    )
  }

  if (
    isBraintreeSubscriptionPremium(user) &&
    !isUndefinedOrNull(subscription) &&
    subscription.status === BraintreeSubscriptionStatus.ACTIVE
  ) {
    return (
      <div>
        {/* tslint:disable-next-line:jsx-use-translation-function */}
        <div className={styles.bold}>
          {getAppName()} {getPlanLabel(user.activePlan.currentProduct)}
        </div>
        <div className={styles.description}>
          <FormattedMessage
            id="billing.subscription.active"
            values={{
              renewalDate: (
                <strong>{formatDateLong(new Date(subscription.nextBillingDate))}</strong>
              ),
              price: (
                <strong>
                  <FormattedMoney
                    value={subscription.nextBillingPeriodAmount * 100}
                    currency={subscription.currencyCode}
                  />
                </strong>
              ),
            }}
          />
        </div>
      </div>
    )
  }

  // ios nebo android nebo lifetime nebo voucher
  if (isPremium(user)) {
    const product =
      user.activePlan.currentProduct || (isLifetime(user) ? createMockLifetime(user) : null)
    const productLabel = product ? getPlanLabel(product) : null
    return (
      <div>
        <div className={styles.bold}>
          {getAppName()} {productLabel || "Premium"}
        </div>
      </div>
    )
  }
}
