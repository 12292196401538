/* eslint-disable react/prop-types */
import React from "react"
import { connect } from "react-redux"
import { Form, Menu, Message, Modal } from "semantic-ui-react"
import { NAME_MAX_LENGTH } from "src/backend/common/validator"
import { getRecordTypeClass } from "src/backend/records/helpers"
import SelectionComponent from "src/frontend/components/SelectionComponent"
import SelectCategory from "src/frontend/components/Categories/SelectCategory"
import AmountInput from "src/frontend/components/AmountInput"
import SelectMultiple from "src/frontend/components/SelectMultiple"
import { formatMessage, FormattedMessage, FormattedMessageLabel } from "src/frontend/modules/intl"
import { enumAsArray, UserPaymentTypes, RecordType } from "src/backend/enums"
import { FormType } from "src/frontend/scenes/settings/enums"
import * as selectors from "../selectors"
import * as categoriesSelectors from "src/frontend/modules/categories/selectors"
import * as labelsSelector from "src/frontend/modules/labels/selectors"
import * as accountsSelectors from "src/frontend/modules/accounts/selectors"
import { sortEntities } from "src/backend/common/helpers"
import * as actions from "../actions"
import "./TemplateForm.less"
import SelectAccount from "src/frontend/scenes/records/components/SelectAccount"
import { PrimaryButton } from "src/frontend/components/Buttons/Buttons"

const mapStateToProps = (state) => {
  const { form } = selectors.selectTemplates(state)
  const sortedAccounts = sortEntities(accountsSelectors.selectNonConnectedAccounts(state))
  return {
    currencies: form.availableCurrencies,
    formType: form.formType,
    formValues: form.formValues,
    formOpen: form.isOpen,
    errors: form.errors,
    template: form.template,
    selectOptions: {
      accounts: sortedAccounts,
      categories: categoriesSelectors.selectFlattenedCategoriesWithoutUnknownCategories(state),
      categoriesHierarchy: categoriesSelectors.selectCategoriesHierarchy(state),
      labels: labelsSelector.selectNonArchivedLabels(state),
    },
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    handleFieldChange: (event, { name, value }) =>
      dispatch(actions.changeFormFieldValue(name, value)),
    handleAccountChange:
      (currencyId) =>
      ({ name, value }) => {
        return dispatch(actions.changeAccount(name, value, currencyId))
      },
    handleCurrencyChange: (event, { name, value }) => {
      dispatch(actions.changeFormFieldValue(name, value))
    },
    handleRecordTypeChange: (event, type) => dispatch(actions.changeFormFieldValue("type", type)),
    handleCloseForm: () => dispatch(actions.closeForm()),
    handleSaveTemplate: (formValues, formType, template) => {
      return dispatch(actions.saveTemplate(formValues, formType, template))
    },
  }
}

const TemplateForm = (props) => {
  const {
    currencies,
    formValues,
    errors,
    formType,
    formOpen,
    template,
    selectOptions,
    handleCloseForm,
    handleFieldChange,
    handleAccountChange,
    handleCurrencyChange,
    handleRecordTypeChange,
    handleSaveTemplate,
  } = props

  const nameRow = (
    <div className="field">
      <Form.Group>
        <Form.Input
          label={formatMessage("form.name")}
          name="name"
          defaultValue={formValues.name}
          placeholder={formatMessage("settings.templates.form.name_placeholder")}
          width="16"
          maxLength={NAME_MAX_LENGTH}
          autoFocus
          error={!!errors.name}
          onChange={handleFieldChange}
        />
      </Form.Group>
      {errors.name && (
        <Message
          className="error-message"
          error
          content={<FormattedMessage id={errors.name} />}
        />
      )}
    </div>
  )

  const RecordTypeSelect = ({ value, onChange }) => {
    return (
      <div className="field">
        <Menu
          fluid
          widths={2}
        >
          <Menu.Item
            name="Expense"
            active={value === RecordType.EXPENSE}
            onClick={(e) => onChange(e, RecordType.EXPENSE)}
          >
            <FormattedMessage id="record.form.expense" />
          </Menu.Item>
          <Menu.Item
            name="Income"
            active={value === RecordType.INCOME}
            onClick={(e) => onChange(e, RecordType.INCOME)}
          >
            <FormattedMessage id="record.form.income" />
          </Menu.Item>
        </Menu>
      </div>
    )
  }

  const paymentTypeOptions = enumAsArray(UserPaymentTypes).map((recordPaymentType) => {
    return {
      text: <FormattedMessage id={`record_payment_type.${recordPaymentType.key}`} />,
      value: recordPaymentType.value,
    }
  })

  const formName = formatMessage(
    formType === FormType.ADD
      ? "settings.templates.form.add_template"
      : "settings.templates.form.edit_template",
  )

  const SaveButton = ({ children }) => (
    <div className="fields submit">
      <PrimaryButton
        type="submit"
        onClick={() => handleSaveTemplate(formValues, formType, template || {})}
        fluid
      >
        {children}
      </PrimaryButton>
    </div>
  )

  return (
    <Modal
      size="small"
      open={formOpen}
      onClose={handleCloseForm}
    >
      <Modal.Header>
        {formName}
        <span
          className="modal-close"
          onClick={handleCloseForm}
        />
      </Modal.Header>
      {formOpen && (
        <Modal.Content>
          <div className="add-template-form">
            <div className="content-panel">
              <Form error>
                {nameRow}

                <RecordTypeSelect
                  value={formValues.type}
                  onChange={handleRecordTypeChange}
                />

                <SelectAccount
                  formValues={formValues}
                  name="accountId"
                  value={formValues.accountId}
                  accounts={selectOptions.accounts}
                  errors={errors.accountId}
                  label={<FormattedMessageLabel id="record.form.account" />}
                  onAccountChange={handleAccountChange(formValues.currencyId)}
                />

                <AmountInput
                  amountName="amount"
                  currencyName="currencyId"
                  amount={formValues.amount}
                  currencyId={formValues.currencyId}
                  currencies={currencies}
                  amountError={errors.amount}
                  currencyError={errors.currencyId}
                  recordTypeClass={getRecordTypeClass(formValues)}
                  onAmountChange={handleFieldChange}
                  onCurrencyChange={handleCurrencyChange}
                />

                <SelectCategory
                  value={formValues.categoryId}
                  categoriesHierarchy={selectOptions.categoriesHierarchy}
                  categories={selectOptions.categories}
                  name="categoryId"
                  onFieldChange={handleFieldChange}
                  error={errors.categoryId}
                />

                <SelectMultiple
                  name="labels"
                  label={<FormattedMessageLabel id="record.form.labels" />}
                  options={selectOptions.labels}
                  value={formValues.labels}
                  onChange={handleFieldChange}
                  error={errors.labels}
                />

                <SelectionComponent
                  label={<FormattedMessageLabel id="record.form.payment_type" />}
                  name="paymentType"
                  value={formValues.paymentType}
                  options={paymentTypeOptions}
                  placeholder={formatMessage("record.form.select_payment_type")}
                  error={errors.paymentType}
                  onChange={handleFieldChange}
                />

                <Form.Input
                  label={
                    <FormattedMessageLabel
                      id={
                        formValues.type === RecordType.INCOME
                          ? "record.form.payer"
                          : "record.form.payee"
                      }
                    />
                  }
                  name="payee"
                  defaultValue={formValues.payee || ""}
                  placeholder=""
                  onChange={handleFieldChange}
                />

                <Form.TextArea
                  className="field-note"
                  name="note"
                  label={<FormattedMessageLabel id="record.form.note" />}
                  defaultValue={formValues.note ? formValues.note : ""}
                  onChange={handleFieldChange}
                  placeholder=""
                />

                <SaveButton>
                  <FormattedMessage id="form.save" />
                </SaveButton>
              </Form>
            </div>
          </div>
        </Modal.Content>
      )}
    </Modal>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(TemplateForm)
