import React from 'react'
import PremiumCheckerContent from 'src/frontend/components/PremiumChecker/PremiumCheckerContent'
import Widget from 'src/frontend/scenes/dashboard/components/Widget/Widget'
import { WidgetId } from 'src/backend/dashboard/enums'
import { Button } from 'semantic-ui-react'
import { FormattedMessage } from 'src/frontend/modules/intl'
import { Link } from 'react-router-dom'

type Props = {
  featureName: string,
  widgetId: WidgetId,
  enabled: boolean,
  children: React.ReactElement<{}>,
}

export default function DashboardWidgetWithPremiumChecker({ featureName, enabled, children }: Props) {

  const billingButton = (
    <Button
      compact
      icon={{ name: 'star', color: 'yellow' }}
      color="blue"
      content={<span><FormattedMessage id="premium_checker.upgrade" /></span>}
      as={Link}
      to="/settings/billing/choose-plan"
    />
  )

  return enabled
    ? children
    : (
      <Widget>
        <Widget.Header title="" />
        <Widget.Content>
          <PremiumCheckerContent featureString={featureName} button={billingButton} />
        </Widget.Content>
      </Widget>
    )
}
