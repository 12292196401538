import { Record } from 'src/types/Record'
import { Action } from 'src/types/common'
import {
  ACCOUNT_RECEIVE_RECORDS,
  ACCOUNT_REQUEST_RECORDS,
} from 'src/frontend/scenes/accounts/accountDetail/actions'
import { DETAIL_RECORDS_MODULE_STATUS_EXPIRED } from 'src/frontend/modules/moduleStatus/actions'


export interface AccountDetailState {
  records: Record[]
}

const initialState = {
  records: null,
}

export function accountDetail(state: AccountDetailState = initialState, action: Action): AccountDetailState {
  switch (action.type) {
    case DETAIL_RECORDS_MODULE_STATUS_EXPIRED:
    case ACCOUNT_REQUEST_RECORDS: {
      return { ...state, records: [] }
    }

    case ACCOUNT_RECEIVE_RECORDS: {
      return { ...state, records: action.payload }
    }

    default: {
      return state
    }
  }
}
