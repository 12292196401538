import React from 'react'
import { connect } from 'react-redux'
import { NAME_MAX_LENGTH } from 'src/backend/common/validator'
import { getIcons } from 'src/backend/icons'
import ColorIconPicker from 'src/frontend/components/ColorPicker/ColorIconPicker'
import * as actions from '../actions'
import { Message, Form } from 'semantic-ui-react'
import { getColors } from 'src/backend/colors/colors'
import { FormattedMessage, formatMessage } from 'src/frontend/modules/intl'
import * as categoriesSelectors from '../selectors'
import { CategoryIcon } from 'src/frontend/components/Icons/Icons'
import { RootState } from 'src/types/State'
import { Category } from 'src/types/Category'
import { FormType } from 'src/frontend/modules/categories/enums'
import { CategoriesFormValues } from 'src/frontend/scenes/settings/categories/types'
import { HashMap } from 'src/types/common'
import { PrimaryButton, SecondaryButton } from 'src/frontend/components/Buttons/Buttons'
import categoryItemStyles from 'src/frontend/scenes/settings/categories/components/CategoriesList.module.less'
import styles from './CategoryForm.module.less'
import { mergeClasses } from 'src/common/utils'


interface Props {
  envelopeName?: string
  categoryName: string
  category: Category

  formType: FormType
  formValues: CategoriesFormValues
  errors: HashMap<string>
  formCategory: Category
  isColorPickerOpen: boolean

  handleFieldChange: (MouseEvent) => void
  handleColorPick: (MouseEvent) => void
  handleIconPick: (MouseEvent) => void
  handleSaveCategory: Function
  handleResetCategory: Function
  handleOpenColorPicker: (MouseEvent) => void,
  handleCloseColorPicker: (MouseEvent) => void,
  handleCloseForm: (MouseEvent) => void,
}

function mapStateToProps(state: RootState) {
  const form = categoriesSelectors.selectForm(state)
  return {
    formType: form.formType,
    formValues: form.formValues,
    formCategory: form.category,
    errors: form.errors,
    isColorPickerOpen: form.colorPicker,
  }
}

function mapDispatchToProps(dispatch: Function) {
  return {
    handleFieldChange(event, field) {
      event.preventDefault()
      dispatch(actions.changeFormFieldValue(field.name, field.value))
    },
    handleColorPick(color: { hex: string }) {
      dispatch(actions.changeFormFieldValue('color', color.hex))
      dispatch(actions.closeColorPicker())
    },
    handleIconPick(iconName: string) {
      dispatch(actions.changeFormFieldValue('iconName', iconName))
      dispatch(actions.closeColorPicker())
    },
    handleSaveCategory(category, formValues, formType) {
      return () => {
        dispatch(actions.saveCategory(category, formValues, formType))
      }
    },
    handleResetCategory(category, formType) {
      return () => {
        dispatch(actions.saveCategory(category, {}, formType))
      }
    },
    handleOpenColorPicker() {
      dispatch(actions.openColorPicker())
    },
    handleCloseColorPicker() {
      dispatch(actions.closeColorPicker())
    },
    handleCloseForm: () => dispatch(actions.closeForm()),
  }
}

CategoryForm.defaultProps = {
  envelopeName: '',
}

function CategoryForm({
  categoryName,
  envelopeName,
  category,
  formCategory,
  formType,
  formValues,
  errors,
  isColorPickerOpen,
  handleCloseForm,
  handleSaveCategory,
  handleFieldChange,
  handleOpenColorPicker,
  handleCloseColorPicker,
  handleColorPick,
  handleIconPick,
  handleResetCategory,
}: Props) {
  const iconColor = formValues.color || category.color
  const iconName = formValues.iconName || category.iconName
  const trigger = <CategoryIcon className="editable" color={iconColor} icon={iconName} />

  const colorPicker = (
    <ColorIconPicker
      colors={getColors()}
      icons={getIcons()}
      icon={iconName}
      color={category.color}
      trigger={trigger}
      position="bottom left"
      open={isColorPickerOpen}
      onOpen={handleOpenColorPicker}
      onClose={handleCloseColorPicker}
      onColorSelect={handleColorPick}
      onIconSelect={handleIconPick}
    />
  )

  const formClass = formType === FormType.ADD ? styles.add : styles.edit
  const defaultValue = formType !== FormType.ADD && (category.customName || category.customCategory)
    ? categoryName
    : ''
  const placeholder = formType === FormType.RENAME ? envelopeName : formType === FormType.ADD
    ? formatMessage('settings.categories.add_category.name_placeholder')
    : ''
  const isRenameAndNotEmpty = formType === FormType.RENAME && defaultValue.length > 0

  return (
    <div className={mergeClasses(categoryItemStyles.item, styles.form, formClass)}>
      <Form error>
        <div className={categoryItemStyles.columnIcon}>
          {colorPicker}
        </div>
        <div className={styles.columnEdit}>
          <Form.Group>
            <Form.Input
              maxLength={NAME_MAX_LENGTH}
              name="name"
              className={mergeClasses(isRenameAndNotEmpty && styles.inputRename)}
              icon={(
                <span
                  className={styles.resetName}
                  onClick={handleResetCategory(formCategory, formType)}
                >
                  <FormattedMessage id="form.reset" />
                </span>
              )}
              defaultValue={defaultValue}
              width="6"
              autoFocus
              error={!!errors.name}
              onChange={handleFieldChange}
              placeholder={placeholder}
            />
            <div className="field six wide">
              <PrimaryButton
                type="submit"
                className={styles.editSaveButton}
                onClick={handleSaveCategory(formCategory, formValues, formType)}
              >
                <FormattedMessage id="form.save" />
              </PrimaryButton>
              <SecondaryButton className={styles.editCancelButton} onClick={handleCloseForm}>
                <FormattedMessage id="form.cancel" />
              </SecondaryButton>
            </div>
          </Form.Group>
          {errors.name && <Message className="error-message" error content={<FormattedMessage id={errors.name} />} />}
        </div>
      </Form>
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(CategoryForm)
