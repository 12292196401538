import React from 'react'
import { Form } from 'semantic-ui-react'
import { IntegrationProviders } from 'src/frontend/scenes/integrations/providersList/types'
import IntegrationRecipeParam = IntegrationProviders.IntegrationRecipe.IntegrationRecipeParam

export interface MFAFormSelectProps {
  param: IntegrationRecipeParam
  value?: string
  multiple?: boolean
  onChange: (field: string, value: string | string[]) => void
}

MFAFormSelect.defaultProps = {
  value: "",
}

export default function MFAFormSelect({ param, value, onChange, multiple }: MFAFormSelectProps) {
  return (
    <Form.Dropdown
      selection
      multiple={multiple ?? false}
      name={param.id}
      label={param.label}
      disabled={param.notEditable}
      options={param.selectOptions}
      value={value}
      onChange={(_e, data) => onChange(data.name, data.value as string | string[])}
    />
  )
}
