import * as service from 'src/backend/webConfig/service'
import { selectUser } from 'src/frontend/modules/user/selectors'
import { DefaultWebConfig } from 'src/backend/webConfig/constants'
import { Interval } from 'src/backend/enums'
import * as logger from 'src/common/logger'
import { showError } from 'src/frontend/scenes/app/actions'
import { WebConfig } from 'src/types/WebConfig'
import { updateShouldDisplayOnBoarding } from 'src/frontend/scenes/onBoarding/inApp/actions'
import moment from 'moment'

export const WEB_CONFIG_RECEIVE = 'CONFIG_DASHBOARD_RECEIVE'

export function receiveConfigWeb(webConfig: WebConfig) {
  return {
    type: WEB_CONFIG_RECEIVE,
    payload: webConfig,
  }
}

export function fetchWebConfig() {
  return async (dispatch: Function) => {
    const config = await service.getWebConfig() || DefaultWebConfig
    return dispatch(receiveConfigWeb(config))
  }
}


export function saveDashboardDefaultPeriod(interval: Interval) {
  return async (dispatch: Function, getState: Function) => {
    await service.saveIntervalToWebConfig(interval, selectUser(getState()))
    dispatch(fetchWebConfig())
  }
}

export function setShowDecimals(value: boolean) {
  return async (dispatch: Function, getState: Function) => {
    try {
      const user = selectUser(getState())
      await service.saveShowDecimalsToWebConfig(value, user)
      dispatch(fetchWebConfig())
    } catch (error) {
      logger.captureException(error, 'src.frontend.modules.configWeb.actions.setHideDecimals')
      dispatch(showError(['app.error.default_error_message', 'app.error.we_are_fixing_suffix']))
    }
  }
}

export function hideMobileAppsBanner() {
  return async (dispatch: Function, getState: Function) => {
    try {
      const user = selectUser(getState())

      const hideValue = true
      await service.saveHideMobileAppsBanner(hideValue, user)
      await dispatch(fetchWebConfig())
      await dispatch(updateShouldDisplayOnBoarding())
    } catch (error) {
      logger.captureException(error, 'src.frontend.modules.configWeb.actions.hideMobileAppsBanner')
      dispatch(showError(['app.error.default_error_message', 'app.error.we_are_fixing_suffix']))
    }
  }
}

export function hideAdvertisement() {
  return async (dispatch: Function, getState: Function) => {
    try {
      const user = selectUser(getState())

      const advertisementHiddenAt = moment().toISOString()
      await service.saveHideAdvertisement(advertisementHiddenAt, user)
      await dispatch(fetchWebConfig())
      await dispatch(updateShouldDisplayOnBoarding())
    } catch (error) {
      logger.captureException(error, 'src.frontend.modules.configWeb.actions.hideAdvertisement')
      dispatch(showError(['app.error.default_error_message', 'app.error.we_are_fixing_suffix']))
    }
  }
}
