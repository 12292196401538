import React from 'react'
import { Popup, Icon } from 'semantic-ui-react'
import styles from './InfoPopup.module.less'

interface Props {
  message: string | React.ReactNode
  label?: string | React.ReactNode
}

export default function InfoPopup({ message, label = null }: Props) {
  return (
    <Popup
      trigger={(
        <span className={styles.infoPopup}>
          <Icon name="question circle outline" className={styles.icon} />
          {label}
        </span>
      )}
      content={message}
    />
  )
}
