import React from 'react'
import styles from 'src/frontend/scenes/accounts/accountDetail/components/AccountDetailHeader.module.less'
import { formatMessage } from 'src/frontend/modules/intl/i18n'
import { isUndefinedOrNull } from 'src/common/utils'
import { Account } from 'src/types/Account'
import { AccountIcon } from 'src/frontend/components/Icons/Icons'
import IntegrationButton from 'src/frontend/scenes/settings/accounts/components/IntegrationButton'
import FormattedMessage from 'src/frontend/modules/intl/components/FormattedMessage'
import AccountLastUpdate from 'src/frontend/scenes/accounts/accountDetail/components/AccountLastUpdate'
import TruncatedText from 'src/frontend/components/TruncatedText'
import { RestrictedProviders } from 'src/frontend/scenes/integrations/restrictedProviders/types'
import RestrictedProvider = RestrictedProviders.RestrictedProvider
import BankRestrictionColumn from 'src/frontend/scenes/accounts/accountDetail/components/BankRestrictionColumn'

interface Props {
  account: Account
  restrictedProviders: RestrictedProvider[]
}

export default function AccountDetailHeader({ account, restrictedProviders }: Props) {

  const integrationConnection = account.reservedIntegrationConnection

  const restrictedProviderObject = React.useMemo(() => {
    if (integrationConnection) {
      return restrictedProviders.find((restrictedProvider) => {
        return restrictedProvider.loginIds.includes(integrationConnection.loginId)
      })
    }
  }, [account, restrictedProviders])


  return (
    <div className={styles.header}>
      <div className={styles.avatar}>
        <AccountIcon accountType={account.accountType} color={account.color} size="6rem" borderRadius="8px" />
      </div>

      <div className={styles.detailColumn}>
        <div className={styles.detailItem}>
          <div className={styles.title}>
            <FormattedMessage id="form.name" />
          </div>
          <div className={styles.value}>
            {account.name
              ? <TruncatedText text={account.name} length={35} showPopup />
              : formatMessage('contacts.detail.value.none')}
          </div>
        </div>
        <div className={styles.detailItem}>
          <div className={styles.title}>
            <FormattedMessage id="form.type" />
          </div>
          <div className={styles.value}>
            {formatMessage(`account.type.${account.accountType}`)}
          </div>
        </div>
      </div>

      <div className={styles.detailColumn}>
        {!isUndefinedOrNull(integrationConnection) && (
          <div className={styles.detailItem}>
            <div className={styles.title}>
              <FormattedMessage id="settings.accounts.table.last_update" />
            </div>
            <div className={styles.value}>
              {integrationConnection && (
                <div>
                  <div>
                    <AccountLastUpdate account={account} />
                  </div>
                  <div className={styles.integrationButton}>
                    <IntegrationButton account={account} />
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>

      <BankRestrictionColumn account={account} restrictedProviderObject={restrictedProviderObject} />
    </div>
  )
}
