import * as billing from 'src/backend/rest/backend/billing'
import { BraintreeBillingAddress } from 'src/frontend/scenes/billing/billingInformation/types'
import { isAuthorizationError } from 'src/common/helpers'
import { expireUser } from 'src/frontend/modules/user/actions'
import { WalletRestApiError } from 'src/backend/errors'
import { showErrorModal } from 'src/frontend/Error/actions'
import * as logger from 'src/common/logger'
import { getActiveSubscriptionDetails } from 'src/frontend/scenes/billing/activeSubscription/actions'

export function updateBillingAddress(billingAddress: BraintreeBillingAddress) {
  return async (dispatch: Function) => {
    try {
      await billing.updateBillingAddress(billingAddress)
      await dispatch(getActiveSubscriptionDetails())
    } catch (error) {
      if (isAuthorizationError(error)) {
        dispatch(expireUser())
        return
      } else if (error instanceof WalletRestApiError) {
        dispatch(showErrorModal(null, false))
      }
      logger.captureException(error, 'billing.updateBillingAddress')
    }
  }
}
