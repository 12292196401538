import spected from 'spected'
import * as commonValidator from 'src/backend/common/validator'

export function validate(record, validationDependencies) {
  const {
    currencies,
  } = validationDependencies

  const requiredFields = [
    'code',
    'ratioToReferential',
  ]

  const currencyWithRequiredFields = {
    ...commonValidator.addRequiredFieldsAsUndefined(record, requiredFields),
  }

  const currencySpec = {
    ratioToReferential: [
      [commonValidator.isNotEmpty, 'settings.currencies.form.enter_ratio'],
      [commonValidator.isNonZeroNumber, 'settings.currencies.form.ratio_must_be_a_nonzero_number'],
      [commonValidator.isNotInfinityNumber, 'settings.currencies.form.ratio_must_be_a_nonzero_number'],
      [commonValidator.isPositiveNumber, 'settings.currencies.form.ratio_must_be_a_nonzero_number'],
    ],
    code: [
      [commonValidator.isNotEmpty, 'settings.currencies.form.select_currency'],
      [commonValidator.valueExists(currencies), 'currency.form.invalid_currency_selected'],
    ],
  }

  try {
    const validationResult = spected(currencySpec, currencyWithRequiredFields)
    return commonValidator.convertValidationResultToErrors(validationResult)
  } catch (error) {
    console.error(error)
    return { general: 'Validation error!' }
  }
}
