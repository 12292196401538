import React from 'react'
import { formatAbbreviatedNumber } from 'src/frontend/modules/intl/i18n'

type Props = {
  value: number,
  className?: string,
}

FormattedAbbreviatedNumber.defaultProps = {
  className: '',
}

export default function FormattedAbbreviatedNumber({ value, className }: Props) {
  return (
    <span className={className}>
      {formatAbbreviatedNumber(value / 100)}
    </span>
  )
}
