import { Id } from 'src/types/CouchDb'
import { Action, HashMap } from 'src/types/common'
import {
  ASSIGN_FORM_CHANGE_VALUE,
  ASSIGN_FORM_CLOSE,
  ASSIGN_FORM_ERROR,
  ASSIGN_FORM_OPEN,
  ASSIGN_FORM_SAVE_START,
  ASSIGN_FORM_SHOW_PROPOSAL,
} from 'src/frontend/scenes/records/assignForm/actions'
import { AssignFormValues } from 'src/frontend/scenes/records/assignForm/types'
import _omitBy from 'lodash/omitBy'
import { isUndefined } from 'src/common/utils'
import { AssignFormContent } from 'src/frontend/scenes/records/assignForm/enums'

export interface AssignFormState {
  recordId: Id
  open: boolean
  formValues: AssignFormValues
  errors: HashMap<string>
  loading: boolean,
  contentType: AssignFormContent
}

const initialState: AssignFormState = {
  recordId: null,
  open: false,
  formValues: null,
  errors: {},
  loading: false,
  contentType: AssignFormContent.ASSIGN_FORM,
}

export function assignForm(state: AssignFormState = initialState, action: Action): AssignFormState {
  switch (action.type) {
    case ASSIGN_FORM_OPEN: {
      return { ...state, open: true, recordId: action.payload.recordId, formValues: action.payload.formValues }
    }

    case ASSIGN_FORM_CHANGE_VALUE: {
      const formValues = { ...state.formValues, ...action.payload }
      return { ...state, formValues: _omitBy(formValues, isUndefined) }
    }

    case ASSIGN_FORM_CLOSE: {
      return initialState
    }

    case ASSIGN_FORM_SAVE_START: {
      return { ...state, loading: true }
    }

    case ASSIGN_FORM_ERROR: {
      return { ...state, errors: { ...action.payload.errors }, loading: false }
    }

    case ASSIGN_FORM_SHOW_PROPOSAL: {
      return { ...state, contentType: AssignFormContent.RULE_ADD_PROPOSAL }
    }

    default: {
      return state
    }
  }
}
