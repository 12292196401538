import { WalletError } from 'src/backend/errors'
import { fetchWithRetry } from 'src/backend/rest/helpers'

export function fetchLatestAppInfo() {
  return fetchWithRetry('/version.json')
    .then(response => {
      if (response.status !== 200) {
        throw new WalletError(`version.json cannot be fetched: ${response.status}`)
      }
      return response
    })
    .then(response => response.json())
}
