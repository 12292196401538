import { reduceBy } from 'src/common/utils'
import * as service from 'src/backend/templates/service'

export const TEMPLATES_RECEIVE = 'TEMPLATES_RECEIVE'

export function receiveTemplates(templatesArray) {
  const TEMPLATE_ID = '_id'
  const templates = templatesArray.reduce(reduceBy(TEMPLATE_ID), {})
  return { type: TEMPLATES_RECEIVE, payload: templates }
}

export function fetchTemplates() {
  return dispatch => {
    return Promise.resolve()
      .then(() => service.fetchTemplates())
      .then(templates => dispatch(receiveTemplates(templates)))
  }
}
