import * as importsActions from 'src/frontend/scenes/imports/actions'
import * as actions from './actions'
import { combineReducers } from 'redux'
import { FormType } from 'src/frontend/scenes/settings/enums'
import { AccountFormValues } from 'src/frontend/scenes/settings/accounts/types'
import { Account } from 'src/types/Account'
import { AccountsTotals } from 'src/frontend/scenes/dashboard/types'
import { AnyDocument } from 'src/types/CouchDb'
import * as integrationsActions from 'src/frontend/scenes/integrations/connections/actions'
import { AccountFormType } from 'src/frontend/scenes/settings/accounts/enums'
import { HashMap } from 'src/types/common'

export interface AccountsFormState {
  formType: FormType
  accountFormType: AccountFormType,
  isOpen: boolean
  colorPicker: boolean
  archiveDisabled: boolean
  formValues: Partial<AccountFormValues>
  account: Account
  errors: any
  loading: boolean
}

const initialFormState = {
  formType: FormType.ADD,
  accountFormType: AccountFormType.SELECTION,
  isOpen: false,
  colorPicker: false,
  archiveDisabled: false,
  formValues: {},
  account: null,
  errors: {},
  loading: false,
}

function form(state: AccountsFormState = initialFormState, action) {
  switch (action.type) {
    case actions.SETTINGS_ACCOUNTS_OPEN_COLOR_PICKER: {
      return { ...state, colorPicker: true }
    }
    case actions.SETTINGS_ACCOUNTS_CLOSE_COLOR_PICKER: {
      return { ...state, colorPicker: false }
    }
    case actions.SETTINGS_ACCOUNTS_OPEN_FORM: {
      return { ...state, isOpen: true, ...action.payload, loading: false }
    }
    case actions.SETTINGS_ACCOUNTS_SAVE_START: {
      return { ...state, loading: true }
    }
    case actions.SETTINGS_ACCOUNTS_CLOSE_FORM: {
      return {
        ...state,
        ...action.payload,
        formValues: {},
        formType: FormType.ADD,
        accountFormType: AccountFormType.SELECTION,
        account: null,
        archiveDisabled: false,
        isOpen: false,
        colorPicker: false,
        errors: {},
        loading: false,
      }
    }
    case actions.SETTINGS_ACCOUNTS_CHANGE_FIELD_VALUE:
    case actions.SETTINGS_ACCOUNTS_CHANGE_NAME: {
      return { ...state, formValues: { ...state.formValues, ...action.payload } }
    }
    case actions.SETTINGS_ACCOUNTS_CREDIT_CARD_CHANGE_FIELD_VALUE: {
      return {
        ...state,
        formValues: {
          ...state.formValues,
          creditCard: { ...state.formValues.creditCard, ...action.payload },
        },
      }
    }
    case actions.SETTINGS_ACCOUNTS_SAVE_ERROR: {
      return { ...state, ...action.payload, loading: false }
    }
    case actions.SETTINGS_ACCOUNTS_DELETE_START:
    case actions.SETTINGS_ACCOUNTS_DELETE_SUCCESS:
    case actions.SETTINGS_ACCOUNTS_DELETE_ERROR: {
      return { ...state, ...action.payload }
    }

    case actions.SETTINGS_ACCOUNTS_SET_ACCOUNT_FORM_TYPE: {
      return { ...state, accountFormType: action.payload }
    }
    case importsActions.DEACTIVATE_IMPORTS: {
      return { ...state, account: { ...state.account, importSettings: null } }
    }
    case integrationsActions.DISCONNECT_ACCOUNT_SUCCESS: {
      return { ...state, account: { ...state.account, reservedIntegrationConnection: null } }
    }
    default: {
      return state
    }
  }
}

const initialDeleteFormState = { canDelete: false, references: [], referencedTypes: [] }

export interface AccountsDeleteFormState {
  canDelete: boolean
  references: AnyDocument[]
  referencedTypes: string[]
}

function deleteForm(state: AccountsDeleteFormState = initialDeleteFormState, action) {
  switch (action.type) {
    case actions.SETTINGS_ACCOUNTS_OPEN_DELETE_FORM: {
      return { ...state, ...action.payload }
    }
    case actions.SETTINGS_ACCOUNTS_CLOSE_DELETE_FORM: {
      return initialDeleteFormState
    }
    default: {
      return state
    }
  }
}

function accountsTotals(state: Partial<HashMap<AccountsTotals>> = {}, action) {
  switch (action.type) {
    case actions.SETTINGS_ACCOUNTS_CALC_TOTALS_SUCCESS: {
      return { ...state, ...action.payload }
    }
    default: {
      return state
    }
  }
}

export interface SettingsAccounts {
  form: AccountsFormState
  deleteForm: AccountsDeleteFormState
  accountsTotals: Partial<HashMap<AccountsTotals>>
}

export default combineReducers({ form, deleteForm, accountsTotals })
