import React from 'react'
import { SquareIcon } from 'src/frontend/components/Icons/Icons'
import IconOption from 'src/frontend/components/IconOption'
import { formatMessage } from 'src/frontend/modules/intl/i18n'
import styles from './BoardCashFlowLegend.module.less'
import { Bar, BarsData } from 'src/frontend/scenes/dashboard/widgets/BoardCashFlowBar/types'

interface Props {
  data: BarsData
}

function getLabelsLegend(bars: Bar[]) {
  return bars.filter((bar) => bar.values.some((value) => !!value.label))
    .map((bar) => {
      return (
        <div key={bar.label}>
          {bar.values.map((value) => (
            <IconOption
              key={value.label}
              label={formatMessage(value.label)}
              icon={<SquareIcon size="0.9rem" color={value.color} />}
            />
          ))}
        </div>
      )
    })
}

export default function BoardCashFlowLegend({ data }: Props) {
  const legend = getLabelsLegend(data.bars)

  return (
    <div className={styles.legend}>
      {legend}
    </div>
  )
}
