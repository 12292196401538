import { Record } from 'src/types/Record'
import { Action } from 'src/types/common'
import { DETAIL_RECORDS_MODULE_STATUS_EXPIRED } from 'src/frontend/modules/moduleStatus/actions'
import {
  RECORDS_DETAIL_RECEIVE_RECORDS,
  RECORDS_DETAIL_REQUEST_RECORDS,
} from 'src/frontend/scenes/records/recordsDetail/actions'

export interface RecordsDetailState {
  records: Record[]
}

const initialState: RecordsDetailState = {
  records: null,
}

export default function recordsDetail(state: RecordsDetailState = initialState, action: Action): RecordsDetailState {
  switch (action.type) {

    case DETAIL_RECORDS_MODULE_STATUS_EXPIRED:
    case RECORDS_DETAIL_REQUEST_RECORDS: {
      return { ...state, records: [] }
    }

    case RECORDS_DETAIL_RECEIVE_RECORDS: {
      return { ...state, records: action.payload }
    }

    default: {
      return state
    }
  }
}
