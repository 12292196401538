import { UserLoginStatus } from "src/frontend/modules/user/enums"
import { ModelType } from "src/types/common"
import { Id } from "src/types/CouchDb"
import { Billing } from "src/types/Billing"
import Product = Billing.Product
import PlanType = Billing.PlanType

export interface User {
  // Ribeez user fields
  countryCode?: string
  email: string
  userId: UserId
  lastName: string
  fullName: string
  firstName: string
  avatarUrl: string
  replication: ReplicationEndpoint
  activePlan: ActivePlan
  location: UserLocation
  groupsList: Group[]
  referralSource?: ReferralSource
  createdAt: number
  freePremium?: FreePremium
  supportEmail?: string

  // Login fields
  loginStatus: UserLoginStatus
  loading: boolean

  // gdpr consents
  consents: UserConsents

  // business info
  companyInfo?: CompanyInfo

  verifiedProfile?: VerifiedProfile
}

interface FreePremium {
  storeCountryCode: string
  premiumFeaturesForFree: boolean
  customerCare?: CustomerCare
  freemiumAccess: boolean
  freeAccountsLimit: number
}

export interface CustomerCare {
  url?: string
  phone?: string
}

export interface VerifiedProfile {
  fullName: string
  birthday?: number
  streetAddress?: string
  companyId?: string
  companyName?: string
}

export enum CouchType {
  COUCH_DB,
  COUCHBASE,
}

export interface UserConsents {
  policy: boolean
  gps: boolean
  segmentation: boolean
  emailing: boolean
  bankSynchronization: boolean
}

export interface CompanyInfo {
  industry?: string
  name?: string
  role?: string
}

export interface ReplicationEndpoint {
  couchType: CouchType
  dbName: string
  url: string
  login: string
  token: string
  ownerId: string
  pullFilter: string
  migrationFinished: boolean
}

export type UserLocation = {
  latitude: number
  longitude: number
}

export type ActivePlan = {
  planType: PlanType
  currentProduct?: Product
  activeProducts?: Product[]
  validUntil?: number
}

export type UserId = string

export interface Group {
  id: Id
  name?: string
  groupOwner?: GroupMember
  groupMember: GroupMember[]
  groupMemberAnonymous: GroupMemberAnonymous
  replication: ReplicationEndpoint
  activePlan: ActivePlan
}

interface GroupMember {
  user?: GroupUser
  modulePermission: ModulePermission[]
}

interface GroupMemberAnonymous {
  email: string
  modulePermission: ModulePermission
}

interface GroupUser {
  userId: string
  fullName?: string
  email: string
  avatarUrl?: string
}

interface ModulePermission {
  modulePermissionType: ModulePermissionType
  // NOTE: set only when modulePermissionType == COMPONENT
  componentType?: ComponentType // [default = NoneComponent]
  // NOTE: set only when modulePermissionType == MODEL
  modelType?: ModelType // [default = NoneType]
  modulePermission: GroupAccessPermission
  moduleObjectPermissions: ModuleObjectPermission[]
}

enum ModulePermissionType {
  COMPONENT,
  MODEL,
}

enum ComponentType {
  NoneComponent,
  DashboardComponent,
  OverviewComponent,
  ChartsComponent,
  ReportsComponent,
  LocationComponent,
  ExportsComponent,
  SettingsComponent,
  GroupsSettingsComponent,
  OtherSettingsComponent,
  WarrantiesComponent,
}

interface ModuleObjectPermission {
  objectId: string
  groupAccessPermission: GroupAccessPermission
}

enum GroupAccessPermission {
  NONE = 0, // NOTE: works like HIDDEN permission
  READ_ONLY,
  READ_WRITE,
  READ_WRITE_MODIFY,
}

export enum OAuthLoginMethod {
  FACEBOOK = "facebook",
  GOOGLE = "google",
}

export enum ReferralSource {
  KB = 0,
  MALL_PAY = 1,
}
