import React, { useEffect } from "react"
import basicLayoutStyles from "src/frontend/components/ColumnLayout/BasicLayout.module.less"
import ColumnLayout from "src/frontend/components/ColumnLayout/ColumnLayout"
import { FormattedMessage } from "src/frontend/modules/intl"
import Filter from "src/frontend/components/Filter/Filter"
import { Dropdown } from "semantic-ui-react"
import { changeOrder } from "src/frontend/scenes/records/recordList/actions"
import { connect, useDispatch } from "react-redux"
import * as selectors from "src/frontend/scenes/records/recordList/selectors"
import { RootState } from "src/types/State"
import { RecordListOrderBy } from "src/frontend/scenes/records/recordList/enums"
import RecordList from "src/frontend/scenes/records/recordList/components/RecordList"
import { openAddRecordForm } from "src/frontend/scenes/records/recordForm/actions"
import { selectShouldDisplayRecordsOnBoarding } from "src/frontend/scenes/onBoarding/inApp/selectors"
import { IntlConsumer } from "src/frontend/modules/intl/components/IntlContext"
import DateRangePicker from "src/frontend/components/DateRangePicker"
import { selectFilter } from "src/frontend/modules/filter/selectors"
import { changeFilter } from "src/frontend/modules/filter/actions"
import styles from "./Records.module.less"
import { expireRecordsStatus } from "src/frontend/modules/moduleStatus/actions"

function mapStateToProps(state: RootState) {
  return {
    filter: selectFilter(state),
    order: selectors.selectOrderBy(state),
    showOnBoarding: selectShouldDisplayRecordsOnBoarding(state),
  }
}

const mapDispatchToProps = {
  onChangeOrder: changeOrder,
  onAddRecord: openAddRecordForm,
  handleChangeFilter: changeFilter,
}

export const orderOptions = [
  {
    text: <FormattedMessage id="records.sort-by.time_newest_first" />,
    value: RecordListOrderBy.RECORD_DATE_DESC,
  },
  {
    text: <FormattedMessage id="records.sort-by.time_oldest_first" />,
    value: RecordListOrderBy.RECORD_DATE_ASC,
  },
  {
    text: <FormattedMessage id="records.sort-by.amount_lowest_first" />,
    value: RecordListOrderBy.REF_AMOUNT_ASC,
  },
  {
    text: <FormattedMessage id="records.sort-by.amount_highest_first" />,
    value: RecordListOrderBy.REF_AMOUNT_DESC,
  },
]

function Records({
  showOnBoarding,
  handleChangeFilter,
  filter,
  order,
  onChangeOrder,
  onAddRecord,
}) {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(expireRecordsStatus())
  }, [])

  return (
    <ColumnLayout
      title={<FormattedMessage id={{ W: "menu.records", B: "transactions" }} />}
      action
      onActionClick={() => onAddRecord()}
      leftPanel={<Filter />}
      topPanel={
        !showOnBoarding ? (
          <>
            <div />
            <div>
              <IntlConsumer>
                {({ locale }) => (
                  <DateRangePicker
                    period={filter.period}
                    locale={locale}
                    preferText
                    showAll
                    onChange={(period) => handleChangeFilter("period", period)}
                  />
                )}
              </IntlConsumer>
            </div>
            <div>
              <div className={styles.sort}>
                <span className={basicLayoutStyles.filterLabel}>
                  <FormattedMessage id="records.sort-by" />
                </span>
                <Dropdown
                  selection
                  value={order}
                  options={orderOptions}
                  onChange={(_e, { value }) => onChangeOrder(value)}
                />
              </div>
            </div>
          </>
        ) : undefined
      }
      content={<RecordList />}
    />
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(Records)
