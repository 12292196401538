import { getWidgetComponentByWidget } from 'src/frontend/scenes/dashboard/components/helpers'
import { Dropdown, Loader } from 'semantic-ui-react'
import DashboardWidgetWithPremiumChecker from 'src/frontend/components/PremiumChecker/DashboardWidgetWithPremiumChecker'
import { isPremium } from 'src/backend/user/service'
import { WidgetId, WidgetPremiumOption } from 'src/backend/dashboard/enums'
import React from 'react'
import _isEqual from 'lodash/isEqual'
import { Id } from 'src/types/CouchDb'
import { HashMap } from 'src/types/common'
import { WidgetType } from 'src/frontend/scenes/dashboard/types'
import { User } from 'src/types/User'
import { SortableElement, SortableHandle } from 'react-sortable-hoc'
import styles from './InnerDroppableList.module.less'
import { mergeClasses } from 'src/common/utils'
import { isAppBoard } from 'src/common/environment'
import AddWidget from 'src/frontend/scenes/dashboard/components/AddWidget'

interface Props {
  widgets: Id[]
  widgetsHashMap: HashMap<WidgetType>
  widgetsLoading: boolean
  user: User
  getContextMenuOptions: Function
}

const DragHandle = SortableHandle(() => <div className={styles.handle} />)

const AddWidgetItem = SortableElement(() => {
  return (
    <div className={mergeClasses(styles.innerDroppableList)}>
      <div className={styles.addWidget}>
        <AddWidget />
      </div>
    </div>
  )
})

const SortableItem = SortableElement(({
  widget,
  user,
  loading,
  getContextMenuOptions,
  WidgetComponent,
}: {
  widget: WidgetType,
  WidgetComponent: React.ComponentType<{ widget: WidgetType }>,
  user: User,
  loading: boolean,
  getContextMenuOptions: Function,
}) => {
  return (
    <div className={mergeClasses(styles.innerDroppableList)}>
      <div>
        <DragHandle />
        {loading
          ? (
            <div className={styles.loading}>
              <Loader size="tiny" inline active />
            </div>
          )
          : (
            <div className={styles.contextMenu}>
              <Dropdown
                closeOnBlur
                basic
                icon="ellipsis vertical"
                direction="left"
                text=" "
                options={getContextMenuOptions(widget.id)}
              />
            </div>
          )}
        <DashboardWidgetWithPremiumChecker
          widgetId={widget.widgetId}
          featureName="premium_checker.dashboard_widget"
          enabled={isPremium(user) || isAppBoard() || !WidgetPremiumOption[widget.widgetId]}
        >
          <WidgetComponent widget={widget} />
        </DashboardWidgetWithPremiumChecker>
      </div>
    </div>
  )
})

function InnerDroppableList({
  widgets,
  widgetsHashMap,
  widgetsLoading,
  user,
  getContextMenuOptions,
}: Props) {
  return (
    <>
      {[...widgets, 'widget-add']
      .filter((id) => !!widgetsHashMap[id] || id === 'widget-add')
      // filtering out upcoming planned payments and cash ratio liquidity widgets due to business requirements https://budgetbakers.atlassian.net/browse/WEB-516
      .filter((id) => {
        if (id === 'widget-add') return true
        return !(widgetsHashMap[id].widgetId === WidgetId.UPCOMING_PLANNED_PAYMENTS || widgetsHashMap[id].widgetId === WidgetId.CASH_RATIO_LIQUIDITY_CARD )
      })
      .map((id: Id, index) => {
        if (id === 'widget-add') {
          return (
            <AddWidgetItem
              index={index}
              key={id}
              disabled
            />
          )
        }

        const widget = widgetsHashMap[id]
        const WidgetComponent = getWidgetComponentByWidget(widget)
        return widget.widgetId ? (
          <SortableItem
            index={index}
            key={id}
            loading={widgetsLoading}
            widget={widget}
            user={user}
            getContextMenuOptions={getContextMenuOptions}
            WidgetComponent={WidgetComponent}
          />
        ) : null
      })}
    </>
  )
}

export default React.memo(InnerDroppableList, ((prevProps, nextProps) => {
  return _isEqual(prevProps.widgets, nextProps.widgets)
    && _isEqual(prevProps.user, nextProps.user)
    && prevProps.widgetsHashMap === nextProps.widgetsHashMap
    && prevProps.widgetsLoading === nextProps.widgetsLoading
}))
