import { Action, HashMap } from 'src/types/common'
import {
  IN_APP_ON_BOARDING_GOOD_JOB_CLOSE,
  IN_APP_ON_BOARDING_GOOD_JOB_OPEN,
  IN_APP_ON_BOARDING_SET_SHOW,
} from 'src/frontend/scenes/onBoarding/inApp/actions'

export interface InAppOnBoardingState {
  dashboard: boolean
  records: boolean
  mobileAppsBanner: boolean
  advertisement: boolean
  goodJobModalOpen: boolean
  goodJobModalText: string
  goodJobModalValues: HashMap<any>
}

const initialState: InAppOnBoardingState = {
  dashboard: false,
  records: false,
  advertisement: false,
  mobileAppsBanner: false,
  goodJobModalOpen: false,
  goodJobModalText: null,
  goodJobModalValues: undefined,

}

export default function inApp(
  state: InAppOnBoardingState = initialState,
  action: Action,
): InAppOnBoardingState {
  switch (action.type) {

    case IN_APP_ON_BOARDING_SET_SHOW: {
      return {
        ...state,
        dashboard: action.payload.dashboard,
        records: action.payload.records,
        mobileAppsBanner: action.payload.mobileAppsBanner,
        advertisement: action.payload.advertisement,
      }
    }

    case IN_APP_ON_BOARDING_GOOD_JOB_OPEN: {
      return {
        ...state,
        goodJobModalOpen: true,
        goodJobModalText: action.payload.text,
        goodJobModalValues: action.payload.values,
      }
    }

    case IN_APP_ON_BOARDING_GOOD_JOB_CLOSE: {
      return { ...state, goodJobModalOpen: false, goodJobModalText: null, goodJobModalValues: undefined }
    }

    default: {
      return state
    }
  }
}
