import { AccountType } from '../enums'
import { isConnected } from '../accounts/helpers'
import { isNull, isUndefined, isUndefinedOrNull } from 'src/common/utils'
import { DataDoc } from 'src/types/CouchDb'
import { Account } from 'src/types/Account'

export function accountConverter() {
  return (dataDoc: Account | DataDoc<Account>): Account => {
    const accountDoc: Account = (dataDoc as DataDoc<Account>).doc || dataDoc as Account

    let { accountType, initAmount, initRefAmount, gps, archived, color, reservedOwnerId } = accountDoc

    if (isUndefinedOrNull(accountType)) {
        accountType = isConnected(accountDoc) ? AccountType.CURRENT_ACCOUNT : AccountType.GENERAL
    }
    if (isUndefinedOrNull(initAmount) && isUndefinedOrNull(initRefAmount)) {
        initRefAmount = 0
        initAmount = 0
    }
    if (!isUndefined(initAmount) && !isNull(initAmount) && isUndefinedOrNull(initRefAmount)) {
        initRefAmount = initAmount
    }
    if (isUndefinedOrNull(gps)) {
        gps = false
    }
    if (isUndefinedOrNull(archived)) {
        archived = false
    }
    if (isUndefinedOrNull(color)) {
        color = '#5f7c8a'
    }
    if (isUndefinedOrNull(reservedOwnerId)) {
        reservedOwnerId = ''
    }

    return {
        ...accountDoc,
        accountType,
        initAmount,
        initRefAmount,
        gps,
        color,
        archived,
        reservedOwnerId,

        // @todo sets default currency ID to referential currency ID
        // @todo handle somehow when account has non-existing currency
    }
  }
}
