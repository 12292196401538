import { RootState } from 'src/types/State'
import { ProviderListState } from 'src/frontend/scenes/integrations/providersList/reducer'
import { Id } from 'src/types/CouchDb'
import { createSelector } from 'reselect'
import {
  IntegrationProviders,
} from 'src/frontend/scenes/integrations/providersList/types'
import IntegrationProviderDetail = IntegrationProviders.IntegrationProviderDetail
import IntegrationProvider = IntegrationProviders.IntegrationProvider
import IntegrationConnectedProvider = IntegrationProviders.IntegrationConnectedProvider
import ConnectedProviderStatus = IntegrationProviders.ConnectedProviderStatus
import { selectConnectedAccounts } from 'src/frontend/modules/accounts/selectors'
import { findProviderByAccount } from 'src/frontend/scenes/integrations/connections/selectors'
import { selectIntegrations } from 'src/frontend/scenes/integrations/selectors'

export const selectProvidersList = (state: RootState): ProviderListState => selectIntegrations(state).providersList

export const selectSelectedProviderCode = (state: RootState): Id => selectProvidersList(state).selectedProviderCode

export const selectSelectedCountry = (state: RootState): string => selectProvidersList(state).selectedCountry

export const selectCountryAvailableProviders = (state: RootState): IntegrationProvider[] => {
  return selectProvidersList(state).countryAvailableProviders
}

export const selectProviderDetail = (state: RootState): IntegrationProviderDetail => {
  return selectProvidersList(state).providerDetail
}

export const selectProviderDetailLoading = (state: RootState): boolean => {
  return selectProvidersList(state).providerDetailLoading
}

export const selectSelectedProvider = createSelector(
  [
    selectSelectedProviderCode,
    selectCountryAvailableProviders,
  ],
  (selectedProviderId, availableProviders) => {
    return availableProviders.find((provider) => provider.code === selectedProviderId)
  },
)

export const selectAlreadyConnectedProviders: (state: RootState) => IntegrationConnectedProvider[] =
  createSelector(
    [(state: RootState) => selectProvidersList(state).alreadyConnectedProviders],
    (providers) =>
      providers.filter((provider: IntegrationConnectedProvider) => {
        return provider.loginStatus === ConnectedProviderStatus.ACTIVE;
      }),
  );

export const selectAlreadyConnectedInactiveProviders: (
  state: RootState,
) => IntegrationConnectedProvider[] = createSelector(
  [(state: RootState) => selectProvidersList(state).alreadyConnectedProviders],
  (providers) => {
    return providers;
  },
);

export const selectReconnectLoginId = (state: RootState): Id => {
  return selectProvidersList(state).reconnectLoginId
}

export const selectReconnectProvider: (state: RootState) => IntegrationProvider = createSelector(
  [
    selectConnectedAccounts,
    selectReconnectLoginId,
  ],
  findProviderByAccount,
)

