import moment, { MomentInput } from 'moment'
import { SYSTEM_CATEGORIES_UNKNOWN_ID } from 'src/backend/categories/envelopes'
import _isPlainObject from 'lodash/isPlainObject'
import { isUndefinedOrNull } from 'src/common/utils'
import { HashMap } from 'src/types/common'
import { AbstractEnvelope, Category } from 'src/types/Category'
import { AnyDocument, Id } from 'src/types/CouchDb'
import _isEmpty from 'lodash/isEmpty'

export const NAME_MAX_LENGTH = 50

export const conditionalValidation = (condition: boolean, validationFn: Function) => {
  return condition ? validationFn : () => true
}

export const isNumber = (numberInput: number) => typeof numberInput === 'number'
export const isNonZeroNumber = (numberInput: number) => isNumber(numberInput) && (numberInput !== 0)
export const isPositiveNumber = (numberInput: number) => isNumber(numberInput) && (numberInput > 0)
export const isNotInfinityNumber = (numberInput: number) => isNumber(numberInput) && (numberInput !== Infinity)
export const isNotEmpty = (value: any) => exists(value) && (typeof value !== 'string' || value.trim() !== '')
export const isNotEqualTo = (valueA: any) => (valueB: any) => valueA !== valueB
export const isValidDate = (date: MomentInput) => date && moment(date).isValid()
export const isNotBefore = (currentDate: MomentInput) => date => !moment(date).isBefore(currentDate)
export const isNotAfter = (currentDate: MomentInput) => date => !moment(date).isAfter(currentDate)
export const isNotLongerThan = (maxLength: number) => (stringInput: string) => {
  return !stringInput || stringInput.length <= maxLength
}
export const exists = (value: any) => !isUndefinedOrNull(value)

export const isNotEmptyArray = (value: any) => !isUndefinedOrNull(value) && !_isEmpty(value)


export const isNotUnknownCategory = (categories: HashMap<Category>) => (categoryId: Id) => {
  const category = categories[categoryId]
  if (!category) {
    return true
  }
  return category && category.envelopeId !== SYSTEM_CATEGORIES_UNKNOWN_ID
}

export const entityExists = (existingEntities: HashMap<any>) => (entityId: Id | number) => {
  const existingIds = Object.keys(existingEntities)
  // object.keys converts keys to string, must compare numeral ids as string
  return existingIds.includes(typeof entityId === 'number' ? entityId.toString() : entityId)
}

export const entitiesExists = (entityHash: HashMap<any>) => (entityIds: Id[]) => {
  return !isNotEmpty(entityIds) || entityIds.every(entityExists(entityHash))
}

export const hasUniqueName = (entities: HashMap<AnyDocument>) => (name: string) => {
  if (!isNotEmpty(name)) {
    return true
  }
  const normalizedName = name.trim().toLowerCase()
  return !Object.values(entities).find((entity) => {
    return entity.name && entity.name.trim().toLowerCase() === normalizedName
  })
}

export const valueExists = (valuesArray: any[]) => (value: any) => {
  return isUndefinedOrNull(value) || valuesArray.includes(value)
}

export const categoryExists = (categories: HashMap<Category>, envelopes: HashMap<AbstractEnvelope>) => {
  return (categoryId: Id) => {
    return !categoryId || entityExists(categories)(categoryId) || entityExists(envelopes)(categoryId)
  }
}

export function addRequiredFieldsAsUndefined(entity: HashMap<any>, requiredFields: string[]) {
  return {
    ...requiredFields.reduce((acc, field) => {
      const recordContainsField = entity[field] !== undefined
      return !recordContainsField ? {
        ...acc,
        [field]: undefined,
      } : acc
    }, entity),
  }
}

export function convertValidationResultToErrors(validationResult) {
  const FIRST_ERROR = 0
  return Object.keys(validationResult).reduce((errors, field) => {

    // flatten deep errors
    if (_isPlainObject(validationResult[field])) {
      return { ...errors, ...convertValidationResultToErrors(validationResult[field]) }
    }

    return validationResult[field] !== true
      ? {
        ...errors,
        [field]: validationResult[field][FIRST_ERROR],
      }
      : errors
  }, {})
}
