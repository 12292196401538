import React from "react"
import {
  enumAsArray,
  RecordState,
  RecordType,
  UserPaymentTypes,
  UserRecordState,
} from "src/backend/enums"
import {
  formatMessage,
  FormattedMessage,
  FormattedMessageLabel,
} from "src/frontend/modules/intl/index"
import Place from "src/frontend/scenes/records/components/Place"
import SelectionComponent from "src/frontend/components/SelectionComponent"
import { DropdownProps, Form, Grid, InputProps } from "semantic-ui-react"
import { SelectOptions } from "src/frontend/scenes/records/recordForm/components/types"
import { RecordFormState } from "src/frontend/scenes/records/recordForm/reducer"
import { Account } from "src/types/Account"
import { isConnected } from "src/backend/accounts/helpers"
import Photos from "src/frontend/scenes/records/components/Photos"
import { FormType } from "src/frontend/scenes/records/recordForm/enums"
import _isEmpty from "lodash/isEmpty"
import SelectMultiple from "src/frontend/components/SelectMultiple"
import { isAppBoard } from "src/common/environment"

interface Props {
  selectOptions: SelectOptions
  recordForm: RecordFormState
  onFieldChange: (event: React.SyntheticEvent, data: DropdownProps | InputProps) => void
}

export default function RecordDetailForm({ recordForm, selectOptions, onFieldChange }: Props) {
  const { formValues, errors, formType } = recordForm

  const paymentTypeOptions = enumAsArray(UserPaymentTypes).map((recordPaymentType) => {
    return {
      text: <FormattedMessage id={`record_payment_type.${recordPaymentType.key}`} />,
      value: recordPaymentType.value,
    }
  })

  const account: Account = selectOptions.accounts.find(({ _id }) => _id === formValues.accountId)
  const fromAccount: Account = selectOptions.accounts.find(
    ({ _id }) => _id === formValues.fromAccountId,
  )
  const toAccount: Account = selectOptions.accounts.find(
    ({ _id }) => _id === formValues.toAccountId,
  )

  const isRecordConnected =
    isConnected(account) || isConnected(fromAccount) || isConnected(toAccount)

  const recordStateOptions = enumAsArray(UserRecordState)
    .filter(
      (recordState) =>
        !isRecordConnected || (isRecordConnected && recordState.value !== RecordState.UNCLEARED),
    )
    .map((recordState) => {
      return {
        text: <FormattedMessage id={`record_state.${recordState.key}`} />,
        value: recordState.value,
      }
    })

  const labelOptions =
    selectOptions &&
    selectOptions.labels &&
    selectOptions.labels.filter((label) => {
      return (
        !label.archived ||
        (formValues && formValues.labels && formValues.labels.includes(label._id))
      )
    })

  return (
    <Grid.Column
      computer={6}
      tablet={6}
      mobile={16}
      className="form-detail"
    >
      <Form error>
        {isAppBoard() && (
          <SelectMultiple
            name="labels"
            label={<FormattedMessageLabel id="record.form.labels" />}
            value={formValues.labels}
            options={labelOptions}
            error={errors.labels}
            onChange={onFieldChange}
          />
        )}

        <Form.Input
          label={
            <FormattedMessageLabel
              id={
                recordForm.formValues.type === RecordType.INCOME
                  ? "record.form.payer"
                  : "record.form.payee"
              }
            />
          }
          name={btoa("payee")}
          value={formValues.payee || ""}
          placeholder=""
          onChange={(e, data) => onFieldChange(e, { ...data, name: atob(data.name) })}
        />

        <Form.TextArea
          className="field-note"
          name="note"
          label={<FormattedMessageLabel id="record.form.note" />}
          value={formValues.note ? formValues.note : ""}
          onChange={onFieldChange}
        />

        <SelectionComponent
          label={<FormattedMessageLabel id="record.form.payment_type" />}
          name="paymentType"
          value={formValues.paymentType}
          options={paymentTypeOptions}
          placeholder={formatMessage("record.form.select_payment_type")}
          error={errors.paymentType}
          onChange={onFieldChange}
        />

        <SelectionComponent
          label={<FormattedMessageLabel id="record.form.status" />}
          name="recordState"
          value={formValues.recordState}
          options={recordStateOptions}
          disabled={isRecordConnected}
          placeholder={formatMessage("record.form.select_record_state")}
          error={errors.recordState}
          onChange={onFieldChange}
        />
        {formType === FormType.EDIT && !_isEmpty(formValues.photos) && (
          <div className="field">
            <label>
              <FormattedMessageLabel id="record.form.photos" />
            </label>
            <Photos photos={formValues.photos} />
          </div>
        )}
        <Place
          formValues={formValues}
          onChange={onFieldChange}
        />
      </Form>
    </Grid.Column>
  )
}
