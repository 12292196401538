import * as logger from 'src/common/logger'
import { getEnvelopeById } from 'src/backend/categories/envelopes'
import { DataDoc } from 'src/types/CouchDb'
import { CategoryDocument } from 'src/types/Category'

export function categoryConverter() {
  return (dataDoc: CategoryDocument | DataDoc<CategoryDocument>): CategoryDocument => {
    const categoryDoc: CategoryDocument = (dataDoc as DataDoc<CategoryDocument>).doc || dataDoc as CategoryDocument

    let { name } = categoryDoc
    if (!name) {
      if (categoryDoc.customCategory || categoryDoc.customName || !categoryDoc.envelopeId) {
        // This very bad, because in this case we would need category name to show it in app later!
        logger.info('There is missing required attribute `name`!', { doc: categoryDoc })
      }
      name = ''
    }
    console.log('categoryConverter-categoryDoc: ', categoryDoc)

    const envelope = getEnvelopeById(categoryDoc.envelopeId)
    const superEnvelopeId = envelope ? envelope.parentId : null

    return {
      ...categoryDoc,
      name,
      superEnvelopeId,
    }
  }
}
