import { Action } from 'src/types/common'
import { BillingModalStage } from 'src/frontend/scenes/billing/billingModal/enums'
import {
  BILLING_MODAL_CLOSE,
  BILLING_MODAL_GOTO_STAGE,
  BILLING_MODAL_OPEN,
} from 'src/frontend/scenes/billing/billingModal/actions'

export interface BillingModalState {
  open: boolean
  stage: BillingModalStage,
}

const initialState: BillingModalState = {
  open: false,
  stage: BillingModalStage.SELECT_PRODUCT,
}

export default function billingModal(state: BillingModalState = initialState, action: Action): BillingModalState {
  switch (action.type) {
    case BILLING_MODAL_GOTO_STAGE: {
      return { ...state, stage: action.payload }
    }

    case BILLING_MODAL_OPEN: {
      return { ...state, open: true }
    }

    case BILLING_MODAL_CLOSE: {
      return { ...state, open: false }
    }

    default: {
      return state
    }
  }
}
