import React from 'react'
import { FormattedMessage } from 'src/frontend/modules/intl'

interface Props {
  id: string | { W: string, B: string }
  values?: { [key: string]: string | number | boolean | JSX.Element }
}

FormattedMessageLabel.defaultProps = {
  values: undefined,
}

export default function FormattedMessageLabel({ id, values }: Props) {
  return (
    <FormattedMessage id={id} values={values} tagName="label" />
  )
}
