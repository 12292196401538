import { RootState } from 'src/types/State'
import { NewConnectionState } from 'src/frontend/scenes/integrations/newConnection/reducer'
import IntegrationProvider = IntegrationProviders.IntegrationProvider
import IntegrationRecipeGroup = IntegrationProviders.IntegrationRecipe.IntegrationRecipeGroup
import { IntegrationProviders } from 'src/frontend/scenes/integrations/providersList/types'
import { IntegrationConnection } from 'src/frontend/scenes/integrations/newConnection/types'
import IntegrationAccount = IntegrationConnection.IntegrationAccount
import { NewIntegrationsStatus } from 'src/frontend/scenes/integrations/enums'
import { Id } from 'src/types/CouchDb'
import { createSelector } from 'reselect'
import { selectAllAccounts } from 'src/frontend/modules/accounts/selectors'
import { Account } from 'src/types/Account'
import { selectIntegrations } from 'src/frontend/scenes/integrations/selectors'
import { VerifiedProfile } from 'src/types/User'

export const selectNewConnection = (state: RootState): NewConnectionState => {
  return selectIntegrations(state).newConnection
}

export const selectPendingConnectionProvider = (state: RootState): IntegrationProvider => {
  return selectNewConnection(state).integrationProvider
}

export const selectIsPendingConnectionInProgress = (state: RootState): boolean => {
  return selectNewConnection(state).pendingConnectionInProgress
}

export const selectPendingConnectionRemoteAccounts = (state: RootState): IntegrationAccount[] => {
  return selectNewConnection(state).integrationAccounts
}

export const selectAccountsLoading = (state: RootState): boolean => {
  return selectNewConnection(state).accountsLoading
}

export const selectPersonalDetailsLoading = (state: RootState): boolean => {
  return selectNewConnection(state).personalDetailsLoading
}

export const selectNewConnectionMFA = (state: RootState): IntegrationRecipeGroup[] => {
  return selectNewConnection(state).pendingConnectionMFA
}

export const selectIntegrationModalOpen = (state: RootState): boolean => {
  return selectNewConnection(state).modalOpen
}

export const selectIntegrationError = (state: RootState): string => {
  return selectNewConnection(state).error
}

export const selectIntegrationStatus = (state: RootState): NewIntegrationsStatus => {
  return selectNewConnection(state).integrationStatus
}

export const selectPersonalDetailsFormValues = (state: RootState): VerifiedProfile => {
  return selectNewConnection(state).personalDetailsFormValues
}

const selectAccountId = (state: RootState): Id => {
  return selectNewConnection(state).accountId
}

export const selectConnectionAccount: (state: RootState) => Account = createSelector(
  [
    selectAllAccounts,
    selectAccountId,
  ],
  (accounts, accountId) => accountId && accounts[accountId],
)
