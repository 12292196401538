import { RecordType, RecordState } from "src/backend/enums"
import { inMemoryTableNames } from "src/backend/db/inMemorySqlDbSchemaBuilder"
import _isEmpty from "lodash/isEmpty"
import { filter, sortBy, toLower } from "ramda"
import _isEqual from "lodash/isEqual"
import { AccountPair, Record, RecordTransfer } from "src/types/Record"
import { RecordListItemRecord } from "src/frontend/components/RecordList/types"
import { RecordFormValues } from "src/frontend/scenes/records/recordForm/types"
import { SYSTEM_CATEGORIES_UNKNOWN_ID } from "src/backend/categories/envelopes"
import { isConnected, VIRTUAL_OUT_OF_WALLET_ACCOUNT_ID } from "../accounts/helpers"
import { TransferAccountsPair } from "./types"
import { Account } from "src/types/Account"
import { Id } from "src/types/CouchDb"
import { User } from "src/types/User"
import { Currency } from "src/types/Currency"

import * as recordsRepository from "./repository"
import * as accountsRepository from "src/backend/accounts/repository"
import * as categoriesService from "src/backend/categories/service"
import * as currenciesRepository from "src/backend/currencies/repository"
import * as currenciesService from "src/backend/currencies/service"
import { sortByPosition } from "../common/helpers"
import { updateCurrencyForFormObject } from "./converters"

export const isIncome = (record: Record | RecordFormValues): boolean =>
  !!record && record.type === RecordType.INCOME

export const isExpense = (record: Record | RecordFormValues): boolean =>
  !!record && record.type === RecordType.EXPENSE

export const isTransfer = (record: Record | RecordFormValues): record is RecordTransfer =>
  !!record && !!record.transfer

export const isTransferExpense = (record: Record): boolean => {
  return !!record && !!record.transfer && record.type === RecordType.EXPENSE
}
export const isTransferIncome = (record: Record): boolean => {
  return !!record && !!record.transfer && record.type === RecordType.INCOME
}
export const hasUnassignedCategory = (record: Record): boolean =>
  !!record && !!record.categoryId && !record.envelopeId && !record.superEnvelopeId

export const hasUnknownCategory = (record: Record): boolean =>
  !!record && record.envelopeId === SYSTEM_CATEGORIES_UNKNOWN_ID

export const sign = (record: Record): string => (record.type === RecordType.EXPENSE ? "-" : "")

export const recordHasPhotos = (record: Record): boolean =>
  !!record.photos && record.photos.length > 0
export const recordHasLabels = (record: Record): boolean =>
  !!record.labels && record.labels.length > 0
export const recordHasWarranty = (record: Record): boolean => record.warrantyInMonth > 0
export const recordHasNote = (record: Record): boolean => !!record.note
export const recordHasPayee = (record: Record): boolean => !!record.payee
export const recordHasContact = (record: RecordListItemRecord): boolean => !!record.contact
export const recordHasAccountProperties = (record: RecordListItemRecord): boolean => {
  return !!record.accountName && !!record.accountColor
}
export const recordHasImplicitGPS = (record: Record | RecordFormValues): boolean =>
  record.accuracy > 0
export const recordHasGPS = (record: Record | RecordFormValues): boolean => {
  return !!record.place || recordHasImplicitGPS(record)
}
export const recordHasConnectedAccount = (record: RecordListItemRecord): boolean =>
  record.accountIsConnected
export const recordIsFromIntegration = (record: Record): boolean =>
  !!record.reservedIntegrationRemoteTransactionId
export const recordIsImported = (record: Record): boolean => !!record.transactionId
export const recordIsUncleared = (record: Record | RecordFormValues): boolean => {
  return record.recordState === RecordState.UNCLEARED
}
export const recordIsPlannedPayment = (record: Record): boolean => {
  return record.reservedModelType === inMemoryTableNames.STANDING_ORDER
}
export const recordIsInReferentialCurrency = (record: RecordListItemRecord): boolean =>
  !record.referentialCurrencyCode

export function getRecordTypeClass(
  formValues: RecordFormValues,
): "transfer" | "income" | "expense" | "" {
  if (isTransfer(formValues)) {
    return "transfer"
  } else if (isIncome(formValues)) {
    return "income"
  } else if (isExpense(formValues)) {
    return "expense"
  }
  return ""
}

export function intersectRecords(records: Record[]): Partial<RecordFormValues> {
  if (_isEmpty(records)) return {}

  const sample = records[0]
  const checkedKeys = [
    "categoryId",
    "labels",
    "payee",
    "contactId",
    "note",
    "paymentType",
    "recordState",
    "place",
    "transfer",
  ]

  const initialValuesByKey: Partial<RecordFormValues> = checkedKeys.reduce((result, key) => {
    return { ...result, [key]: sample[key] }
  }, {})

  const intersection = records.reduce(
    (previousIntersection: Partial<RecordFormValues>, object: Partial<RecordFormValues>) => {
      if (!object) {
        throw new Error("Input array contains null or undefined object")
      }

      const currentIntersection: Partial<RecordFormValues> = {}
      Object.keys(previousIntersection).forEach((key) => {
        if (Array.isArray(previousIntersection[key]) && Array.isArray(object[key])) {
          const previousIntersectionArray = sortBy(toLower, previousIntersection[key])
          const objectArray = sortBy(toLower, object[key])

          if (_isEqual(previousIntersectionArray, objectArray)) {
            currentIntersection[key] = previousIntersection[key]
          }
        } else if (_isEqual(previousIntersection[key], object[key])) {
          currentIntersection[key] = object[key]
        }
      })
      if (previousIntersection.transfer || object.transfer) {
        currentIntersection.transfer = previousIntersection.transfer || object.transfer
      }

      return currentIntersection
    },
    initialValuesByKey,
  )

  return filter((value) => value !== undefined, intersection)
}

export function isStrongTransfer({ fromAccount, toAccount }: AccountPair) {
  return !(isConnected(fromAccount) && isConnected(toAccount))
}

export function isWeakTransfer({ fromAccount, toAccount }: AccountPair) {
  return isConnected(fromAccount) && isConnected(toAccount)
}

export function getTransferAccountId(record: Record, { fromAccount, toAccount }: AccountPair) {
  return isExpense(record) ? toAccount._id : fromAccount._id
}

export async function wasConnectedAccountsChanged(
  selectedRecord: Record,
  relatedRecord: Record,
  { fromAccount, toAccount }: TransferAccountsPair,
) {
  let previousFromAccount
  let previousToAccount

  if (!selectedRecord || !isTransfer(selectedRecord)) {
    return false
  }

  if (isTransferExpense(selectedRecord)) {
    previousFromAccount = await accountsRepository.findById(selectedRecord.accountId)
    previousToAccount = relatedRecord
      ? await accountsRepository.findById(relatedRecord.accountId)
      : null
  } else if (isTransferIncome(selectedRecord)) {
    previousFromAccount = relatedRecord
      ? await accountsRepository.findById(relatedRecord.accountId)
      : null
    previousToAccount = await accountsRepository.findById(selectedRecord.accountId)
  } else {
    throw Error("Expected that selectedRecord is transfer of type Income or Expense!")
  }

  return (
    accountWasChanged(previousFromAccount, fromAccount) ||
    accountWasChanged(previousToAccount, toAccount)
  )
}

function accountWasChanged(previousAccount: Account, actualAccount: Account) {
  return (
    (isConnected(previousAccount) && !isConnected(actualAccount)) ||
    (!isConnected(previousAccount) && isConnected(actualAccount))
  )
}

export function addWeakTransferData(
  formValues: RecordFormValues,
  {
    transferAccountId,
    transferCategoryId,
  }: { transferAccountId: string; transferCategoryId: string },
) {
  return {
    ...formValues,
    transfer: true,
    transferAccountId,
    categoryId: transferCategoryId,
  }
}

export const addStrongTransferData =
  (transferId: Id, transferCategoryId: Id) => (recordEntity: Record) => {
    return {
      ...recordEntity,
      transferId,
      transfer: true,
      categoryId: transferCategoryId,
    }
  }

export async function getTransferCategory(user: User) {
  return (
    (await categoriesService.getTransferCategory()) ||
    categoriesService.createTransferCategory(user)
  )
}
export function findByCategoryId(categoryId: Id): Promise<Record[]> {
  return recordsRepository.findByCategoryId(categoryId)
}

export function calcRefAmount(amount: number, currency: Currency) {
  return Math.round(amount / currency.ratioToReferential)
}

export async function getValidCurrencyForAccount(account: Account, candidateCurrencyId: Id) {
  const availableCurrencies = await getAvailableCurrencies(account._id)
  const isCandidateCurrencyValid = availableCurrencies.find(
    (currency) => currency._id === candidateCurrencyId,
  )

  if ((!isCandidateCurrencyValid && account.currencyId) || isConnected(account)) {
    return currenciesRepository.findById(account.currencyId)
  }

  return currenciesRepository.findById(candidateCurrencyId)
}

export async function getAvailableCurrencies(accountId = null): Promise<Currency[]> {
  const account = accountId && (await accountsRepository.findById(accountId))

  if (account && account.currencyId) {
    const isReferentialCurrency = await currenciesService.isReferentialCurrency(account.currencyId)

    if (!isReferentialCurrency || isConnected(account)) {
      return currenciesRepository.findById(account.currencyId).then((currency) => {
        return currency
          ? [currency]
          : // in case that account has a currency that does not exist, return referential currency to the select
            currenciesService
              .getReferentialCurrency()
              .then((referentialCurrency) => (referentialCurrency ? [referentialCurrency] : []))
      })
    }
  }

  return currenciesRepository.findAll().then((currencies) => currencies.sort(sortByPosition))
}

export async function getCurrencyFormObject(
  accountId = null,
  currencyId,
): Promise<{
  updatedCurrencyId: Id
  availableCurrencies: Currency[]
}> {
  if (accountId === VIRTUAL_OUT_OF_WALLET_ACCOUNT_ID) {
    return {
      updatedCurrencyId: currencyId,
      availableCurrencies: [],
    }
  }

  const availableCurrencies = await getAvailableCurrencies(accountId)
  const updatedCurrencyId = await updateCurrencyForFormObject(accountId)

  return {
    updatedCurrencyId,
    availableCurrencies,
  }
}

export function roundAmounts(recordEntity: Record): Record {
  return {
    ...recordEntity,
    refAmount: Math.round(recordEntity.refAmount),
    amount: Math.round(recordEntity.amount),
  }
}
