import React from "react"
import { connect } from "react-redux"
import defaultProfileImage from "src/frontend/assets/images/user_ic.svg"
import { selectUser } from "src/frontend/modules/user/selectors"
import { User } from "src/types/User"
import { EventCallback } from "src/types/Semantic-ui"
import styles from "src/frontend/components/UserIcon/UserIcon.module.less"
import { Dropdown, DropdownItemProps } from "semantic-ui-react"
import { Link } from "react-router-dom"
import * as appActions from "src/frontend/scenes/app/actions"
import { openModalAddVoucher } from "src/frontend/modules/modals/actions"
import { formatMessage } from "src/frontend/modules/intl/i18n"
import UserIconItem from "src/frontend/components/UserIcon/UserIconItem"
import { getPremiumDaysLeft, isLifetime, isPremium, isTrial } from "src/backend/user/service"
import { FormattedMessage } from "src/frontend/modules/intl"

function mapStateToProps(state) {
  return {
    user: selectUser(state),
  }
}

const mapDispatchToProps = {
  logout: appActions.logoutHard,
  addVoucher: () => openModalAddVoucher(""),
}

type Props = {
  user: User
  logout: EventCallback
  addVoucher: () => void
}

function UserIcon({ user, logout, addVoucher }: Props) {
  const profileImage = user.avatarUrl || defaultProfileImage

  const EMPTY_NAME = "empty"
  const userName = user.lastName !== EMPTY_NAME ? user.fullName : user.firstName

  let imgElement: HTMLImageElement

  const trigger = (
    <div className={styles.userDropdown}>
      <div className={styles.profileImage}>
        <img
          src={profileImage}
          ref={(img) => (imgElement = img)}
          onError={() => {
            if (imgElement) {
              imgElement.src = defaultProfileImage
            }
          }}
        />
      </div>
      <div className={styles.userName}>
        <span className={styles.user}>{userName}</span>
        {/* tslint:disable-next-line:jsx-use-translation-function */}
        {isPremium(user) && <span className={styles.premium}>Premium</span>}
      </div>
    </div>
  )

  const options = buildOptions({ user, logout, addVoucher })

  return (
    <div className={styles.userIcon}>
      <Dropdown
        trigger={trigger}
        options={options}
        direction="left"
      />
    </div>
  )
}

function buildCommonOptions(logout: EventCallback): DropdownItemProps[] {
  const commonOptions = [
    {
      key: "menu.settings",
      text: (
        <UserIconItem
          text={formatMessage("menu.settings")}
          icon="settings"
        />
      ),
      as: Link,
      to: "/settings",
    },
    {
      key: "menu.settings.help",
      text: (
        <UserIconItem
          text={formatMessage("settings.help")}
          icon="help"
        />
      ),
      as: Link,
      to: "/settings/help",
    },
    {
      key: "auth.logout",
      text: (
        <UserIconItem
          text={formatMessage("auth.logout")}
          icon="logout"
        />
      ),
      onClick: logout,
    },
  ]

  return commonOptions
}

function buildOptions({ user, logout, addVoucher }: Props): DropdownItemProps[] {
  const commonOptions = buildCommonOptions(logout)

  const addVoucherProps: DropdownItemProps[] = !isPremium(user)
    ? [
        {
          key: "menu.add_voucher",
          text: (
            <UserIconItem
              text={formatMessage("menu.add_voucher")}
              icon="coupon"
            />
          ),
          onClick: addVoucher,
        },
      ]
    : []

  const manualVoucherProps: DropdownItemProps[] =
    isPremium(user) && isTrial(user) && !isLifetime(user)
      ? [
          {
            key: "trial",
            text: (
              <UserIconItem
                text={
                  <FormattedMessage
                    id="menu.premium.expires.days-left"
                    values={{ daysCount: getPremiumDaysLeft(user) }}
                  />
                }
                icon="star"
              />
            ),
            as: Link,
            to: "/settings/billing/choose-plan",
          },
        ]
      : []

  const premiumProps: DropdownItemProps[] =
    isLifetime(user) || (isPremium(user) && !isTrial(user))
      ? [
          {
            key: "premium",
            text: (
              <UserIconItem
                text="Premium"
                icon="star"
              />
            ),
            as: Link,
            to: "/settings/billing",
          },
        ]
      : []

  const upgradeProps: DropdownItemProps[] = !isPremium(user)
    ? [
        {
          key: "menu.premium",
          text: (
            <UserIconItem
              text={formatMessage("premium_checker.upgrade")}
              icon="star"
            />
          ),
          as: Link,
          to: "/settings/billing/choose-plan",
        },
      ]
    : []

  return [
    ...addVoucherProps,
    ...manualVoucherProps,
    ...premiumProps,
    ...upgradeProps,
    ...commonOptions,
  ]
}

export default connect(mapStateToProps, mapDispatchToProps)(UserIcon)
