import React, { SyntheticEvent } from 'react'
import TermsOfServiceLink from 'src/frontend/components/TermsOfServiceLink'
import PrivacyLink from 'src/frontend/components/PrivacyLink'
import { FormattedMessage, FormattedMessageLabel } from 'src/frontend/modules/intl'
import styles from './ConsentField.module.less'

export default function TermsConditions() {
    return (
        <div className={styles.consent}>
            <FormattedMessageLabel
                id='auth.signup.consent_agreement'
                values={{
                    privacyPolicyLink: <PrivacyLink />,
                    termsOfServiceLink: <TermsOfServiceLink />,
                }}
            />
        </div>
    )
}
