import React, { useCallback } from 'react'
import styles from 'src/frontend/scenes/onBoarding/firstRun/components/FinishedOnBoarding.module.less'
import PictureIcon from 'src/frontend/components/PictureIcon/PictureIcon'
import { Divider, Transition } from 'semantic-ui-react'
import { connect } from 'react-redux'
import { endOnBoarding } from 'src/frontend/scenes/onBoarding/firstRun/actions'
import { selectOnBoardingStage, selectOnBoardingShow } from 'src/frontend/scenes/onBoarding/firstRun/selectors'
import { RootState } from 'src/types/State'
import { OnBoardingStage } from 'src/frontend/scenes/onBoarding/firstRun/enums'
import FormattedMessage from 'src/frontend/modules/intl/components/FormattedMessage'
import { PrimaryButton } from 'src/frontend/components/Buttons/Buttons'

function mapStateToProps(state: RootState) {
  return {
    stage: selectOnBoardingStage(state),
    show: selectOnBoardingShow(state),
  }
}

const mapDispatchToProps = {
  onEndOnBoarding: endOnBoarding,
}

function FinishedOnBoarding({ stage, show, onEndOnBoarding }) {

  const handleClick = useCallback(() => onEndOnBoarding(), [])

  return (
    <Transition visible={stage === OnBoardingStage.FINISHED_ONBOARDING && show} mountOnShow unmountOnHide>
      <div className={styles.finishedOnBoarding}>
        <div>
          <PictureIcon name="onboarding-finish" compact size="15rem" />
          <h1><FormattedMessage id="onBoarding.firsRun.success.title" /></h1>
          <p><FormattedMessage id="onBoarding.firsRun.success.text" /></p>
          <Divider hidden section />
          <PrimaryButton size="big" fluid onClick={handleClick}>
            <FormattedMessage id="onBoarding.firsRun.success.action" />
          </PrimaryButton>
        </div>
      </div>
    </Transition>
  )
}


export default connect(mapStateToProps, mapDispatchToProps)(FinishedOnBoarding)
