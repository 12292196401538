import { applyMiddleware, compose, createStore } from 'redux'
import { createLogger } from 'redux-logger'
import thunkMiddleware from 'redux-thunk'
import {
  getDebugReduxDevTools,
  getDebugReduxLogger,
  isProduction,
  isTest,
} from 'src/common/environment'
import { isDebug } from 'src/common/logger'
import rootReducer from './rootReducer'

export default function configureWebAppStore() {

  // Redux devtools extension, configuration based on official setup:
  // https://github.com/zalmoxisus/redux-devtools-extension#12-advanced-store-setup
  const composeEnhancers = getDebugReduxDevTools() && !isTest()
    ? (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
    : compose

  const middleWares = [thunkMiddleware]
  if ((getDebugReduxLogger() || isDebug() || !isProduction()) && !isTest()) {
    middleWares.push(createLogger())
  }

  const store = createStore(rootReducer, composeEnhancers(applyMiddleware(...middleWares)))

  // Reducer HMR must be here to work properly. Don't join with index.jsx's if (module.hot) { ... }
  if (module.hot) {
    module.hot.accept('./rootReducer', () => {
      store.replaceReducer(rootReducer)
    })
  }

  return store
}
