import React from 'react'
import PropTypes from 'prop-types'
import { formatErrorTitle } from 'src/frontend/scenes/imports/mapping/helpers'

ErrorTitle.propTypes = {
  errorType: PropTypes.number.isRequired,
  headerName: PropTypes.string,
}

ErrorTitle.defaultProps = {
  headerName: '',
}

function ErrorTitle({ errorType, headerName }) {
  return (
    <div className="error-title">
      {formatErrorTitle(errorType, headerName)}
    </div>
  )
}

export default ErrorTitle
