import React, { useEffect } from "react"
import { connect } from "react-redux"
import "./Accounts.less"
import { Input } from "semantic-ui-react"
import moment from "moment"
import { SortableElement } from "react-sortable-hoc"
import { isCreditCard, isOverdraft } from "src/backend/accounts/helpers"
import DragHandle from "src/frontend/scenes/settings/components/DragHandle"
import SortableList from "src/frontend/scenes/settings/components/SortableList"
import { FormType } from "src/frontend/scenes/settings/enums"
import RemoveAccountForm from "./RemoveAccountForm"
import { AccountIcon } from "src/frontend/components/Icons/Icons"
import InfoPopup from "src/frontend/components/InfoPopup"
import {
  formatDateTime,
  formatMessage,
  FormattedMessage,
  FormattedMoney,
} from "src/frontend/modules/intl"
import * as selectors from "../selectors"
import { selectUser } from "src/frontend/modules/user/selectors"
import * as actions from "../actions"
import { getUserFeatureValue, isKBUser } from "src/backend/user/service"
import { Feature } from "src/backend/user/billingFeatures"
import { NAME_MAX_LENGTH } from "src/backend/common/validator"
import IntegrationButton, {
  Variant,
} from "src/frontend/scenes/settings/accounts/components/IntegrationButton"
import { AccountWithCurrencyCode } from "src/types/Account"
import { User } from "src/types/User"
import { AccountFormValues } from "src/frontend/scenes/settings/accounts/types"
import NewBankConnectionInfoMessage from "src/frontend/scenes/integrations/newConnection/components/NewBankConnectionInfoMessage"
import { selectShouldRefreshAccounts } from "src/frontend/modules/moduleStatus/selectors"
import { RootState } from "src/types/State"
import { refreshAccountsStatus } from "src/frontend/modules/moduleStatus/actions"
import { PrimaryButton } from "src/frontend/components/Buttons/Buttons"

const IntegrationStatus = {
  EXPIRED: "expired",
  DISCONNECTED: "disconnected",
}

interface Props {
  accounts: AccountWithCurrencyCode[]
  formType: FormType
  formValues: AccountFormValues
  user: User
  pendingConnection: boolean

  shouldRefreshAccounts: boolean
  refreshAccounts: Function

  handleChangePosition: Function
  handleNameChange: (e: React.SyntheticEvent) => void
  handleOpenForm: (e: React.MouseEvent, FormType, account?: AccountWithCurrencyCode) => void
}

const mapStateToProps = (state: RootState) => {
  const form = selectors.selectAccountsForm(state)

  return {
    accounts: selectors.selectAccountsWithTotals(state),
    formValues: form.formValues,
    formType: form.formType,
    user: selectUser(state),
    shouldRefreshAccounts: selectShouldRefreshAccounts(state),
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    handleChangePosition({ oldIndex, newIndex }) {
      dispatch(actions.reorderAccounts(oldIndex, newIndex))
    },
    handleNameChange(event, field) {
      console.log("handleNameChange", event, field)
      event.preventDefault()
      dispatch(actions.changeName(field.name, field.value))
    },
    handleOpenForm(event, formType, account = null) {
      console.log("handleOpenForm", event)
      dispatch(actions.openAccountForm(formType, account))
    },
    refreshAccounts: () => dispatch(refreshAccountsStatus()),
  }
}

const Accounts = ({
  accounts,
  formType,
  formValues,
  user,
  shouldRefreshAccounts,
  handleChangePosition,
  handleNameChange,
  handleOpenForm,
  refreshAccounts,
}: Props) => {
  useEffect(() => {
    if (shouldRefreshAccounts) {
      refreshAccounts()
    }
  }, [shouldRefreshAccounts])

  const addAccountInputValue = formType === FormType.ADD ? formValues.name : ""

  return (
    <div className="settings-accounts">
      <div className="settings-page">
        <div className="settings-page-add">
          <div className="title">
            <FormattedMessage id="settings.accounts.add_manual" />
          </div>
          <div className="container">
            <div className="item input">
              <InputWithAddButton
                name="name"
                value={addAccountInputValue || ""}
                placeholder={formatMessage("settings.accounts.form.name_placeholder")}
                onChange={handleNameChange}
                onClick={(e) => handleOpenForm(e, FormType.ADD)}
              />
            </div>
            <NewBankConnectionInfoMessage />
          </div>
        </div>
        <div className="settings-page-list">
          <div className="title">
            <FormattedMessage id="settings.accounts.your_accounts" />
          </div>
          <div className="accounts-row list-header">
            <div className="accounts-row-drag header" />
            <div className="accounts-row-type header">
              <FormattedMessage id="form.type" />
            </div>
            <div className="accounts-row-name header">
              <FormattedMessage id="form.name" />
            </div>
            <div className="accounts-row-balance header">
              <FormattedMessage id="settings.accounts.table.balance" />
            </div>
            <div className="accounts-row-last-update header">
              <FormattedMessage id="settings.accounts.table.last_update" />
            </div>
            <div className="accounts-row-toolbar" />
          </div>
          <AccountList
            items={accounts}
            user={user}
            handleReorder={handleChangePosition}
            handleOpenForm={handleOpenForm}
          />
        </div>
      </div>
    </div>
  )
}

interface InputWithAddButtonProps {
  name: string
  placeholder: string
  value: string
  onClick: (e: React.MouseEvent) => void
  onChange: (e: React.SyntheticEvent) => void
}

const InputWithAddButton = ({
  name,
  placeholder,
  value,
  onClick,
  onChange,
}: InputWithAddButtonProps) => (
  <div className="ui input">
    <div className="ui action input">
      <Input
        name={name}
        className="action"
        placeholder={placeholder}
        maxLength={NAME_MAX_LENGTH}
        value={value}
        onChange={onChange}
      />
      <PrimaryButton onClick={onClick}>＋&nbsp;{formatMessage("form.add")}</PrimaryButton>
    </div>
  </div>
)

interface AccountListProps {
  items: AccountWithCurrencyCode[]
  user: User
  handleOpenForm: (e: React.MouseEvent, FormType, account?: AccountWithCurrencyCode) => void
  handleReorder: Function
}

const AccountList: React.FunctionComponent<AccountListProps> = SortableList(
  ({ items, user, handleOpenForm }: AccountListProps) => (
    <ul>
      {items.map((account, index) => (
        <AccountItem
          key={account._id}
          index={index}
          account={account}
          user={user}
          onClick={handleOpenForm}
        />
      ))}
    </ul>
  ),
) as any

interface AccountItemProps {
  account: AccountWithCurrencyCode
  user: User
  onClick: (e: React.MouseEvent, FormType, account?: AccountWithCurrencyCode) => void
}

const AccountItem = SortableElement(({ account, user, onClick }: AccountItemProps) => {
  const integrationConnection = account.reservedIntegrationConnection

  const lastUpdateDate = integrationConnection ? (
    <div>
      {formatDateTime(
        moment(
          integrationConnection.lastSuccessRefresh || integrationConnection.createdAt,
        ).toDate(),
      )}
      &nbsp;
      <IntegrationButton
        account={account}
        variant={Variant.ICON}
      />
    </div>
  ) : null

  const archivedClass = account.archived || account.excludeFromStats ? "archived" : ""

  return (
    <li className="accounts-row">
      <div className="accounts-row-drag">
        <DragHandle />
      </div>
      <div className="accounts-row-type">
        <AccountIcon
          accountType={account.accountType}
          color={account.archived ? "#ccc" : account.color}
        />
      </div>
      <div className={`accounts-row-name ${archivedClass}`}>
        <span>{account.name}</span>
        {(account.archived || account.excludeFromStats) && (
          <div className={archivedClass}>
            {account.archived && <FormattedMessage id="archived" />}
            {account.excludeFromStats && <FormattedMessage id="settings.accounts.form.excluded" />}
          </div>
        )}
      </div>
      <div className="accounts-row-balance">
        <BalanceLabel
          account={account}
          user={user}
        />
        {(isCreditCard(account) || isOverdraft(account)) && account.creditCard ? (
          <div className="limit">
            {/* tslint:disable-next-line:jsx-use-translation-function */}
            (Limit &nbsp;
            <FormattedMoney
              value={account.creditCard.limit}
              currency={account.currencyCode}
            />
            )
          </div>
        ) : null}
      </div>
      <div className="accounts-row-last-update">{lastUpdateDate}</div>
      <div className="accounts-row-toolbar">
        {!account?.investmentInfo && (
          <div>
            <span onClick={(e) => onClick(e, FormType.EDIT, account)}>
              <FormattedMessage id="edit" />
            </span>
            <span className="delete">
              <RemoveAccountForm account={account} />
            </span>
          </div>
        )}
      </div>
    </li>
  )
})

const BalanceLabel = ({ account, user }) => {
  const integrationConnection = account.reservedIntegrationConnection || null
  const isActive = integrationConnection && integrationConnection.isActive

  let label = (
    <FormattedMoney
      value={account.balance}
      currency={account.currencyCode}
    />
  )

  if (integrationConnection && !isActive) {
    label = <IntegrationStatusLabel status={IntegrationStatus.DISCONNECTED.toString()} />
  }

  if (
    integrationConnection &&
    !getUserFeatureValue(user, Feature.INTEGRATIONS) &&
    !isKBUser(user)
  ) {
    label = <IntegrationStatusLabel status={IntegrationStatus.EXPIRED.toString()} />
  }

  return label
}

const IntegrationStatusLabel = ({ status }) => (
  <span className={`account-${status}`}>
    <FormattedMessage id={`settings.accounts.integration.${status}`} />
    <InfoPopup
      message={<FormattedMessage id={`settings.accounts.integration.${status}.popup`} />}
    />
  </span>
)

export default connect(mapStateToProps, mapDispatchToProps)(Accounts)
