import { getBackendUrl } from 'src/common/environment'
import {
  buildFetchOptions,
  fetchWithRetry,
  validateBillingSuccessResponse,
  validateSuccessResponse,
} from 'src/backend/rest/helpers'
import {
  buildTransactionSubmitRequest,
  buildUpdateBillingAddressRequest,
  buildUpdatePaymentMethodRequest,
  buildVoucherTransaction,
  deserializeProtobuffResponse,
  handleCaughtError,
} from 'src/backend/rest/ribeezHelpers'
import {
  BraintreePaymentInfo,
  BraintreeTransactionList,
  BraintreeDetails,
  VoucherTransactionResponse,
} from 'static/proto/ribeez_pb'
import { Billing } from 'src/types/Billing'
import Product = Billing.Product
import { BraintreeBillingAddress } from 'src/frontend/scenes/billing/billingInformation/types'
import { WalletRestApiError, WalletValidationError } from 'src/backend/errors'

function getBillingEndpoint(): string {
  return `${getBackendUrl()}/ribeez/billing`
}


const defaultPostOptions = buildFetchOptions('POST')
const defaultGetOptions = buildFetchOptions('GET')
const defaultDeleteOptions = buildFetchOptions('DELETE')
const defaultPutOptions = buildFetchOptions('PUT')

export function fetchActiveSubscriptionDetails(): Promise<any> {
  const url = `${getBillingEndpoint()}/braintree/v2/details`
  return fetchWithRetry(url, defaultGetOptions)
    .then(validateSuccessResponse)
    .then(deserializeProtobuffResponse(BraintreeDetails))
    .catch(handleCaughtError)
}

export function fetchProducts(): Promise<Billing.BraintreePaymentInfo> {
  const url = `${getBillingEndpoint()}/braintree/v2/products`
  return fetchWithRetry(url, defaultGetOptions)
    .then(validateSuccessResponse)
    .then(deserializeProtobuffResponse(BraintreePaymentInfo))
    .catch(handleCaughtError)
}

export function fetchToken(): Promise<string> {
  const url = `${getBillingEndpoint()}/braintree/v2/token`
  return fetchWithRetry(url, defaultGetOptions)
    .then(validateSuccessResponse)
    .then(response => response.text())
    .catch(handleCaughtError)
}

export function fetchTransactions(): Promise<any> {
  const url = `${getBillingEndpoint()}/braintree/v2/transaction`
  return fetchWithRetry(url, defaultGetOptions)
    .then(validateSuccessResponse)
    .then(deserializeProtobuffResponse(BraintreeTransactionList))
    .catch(handleCaughtError)
}

export function cancelSubscription() {
  const url = `${getBillingEndpoint()}/braintree/v2/subscription`
  return fetchWithRetry(url, defaultDeleteOptions)
    .then(validateSuccessResponse)
    .catch(handleCaughtError)
}


export function updatePaymentMethod(nonce: string) {
  const url = `${getBillingEndpoint()}/braintree/v2/payment-method`
  const body = buildUpdatePaymentMethodRequest(nonce)
  return fetchWithRetry(url, { ...defaultPostOptions, body })
    .then(validateSuccessResponse)
    .catch(handleCaughtError)
}

export function updateBillingAddress(billingAddress: BraintreeBillingAddress) {
  const url = `${getBillingEndpoint()}/braintree/v2/billing-address`
  const body = buildUpdateBillingAddressRequest(billingAddress)
  return fetchWithRetry(url, { ...defaultPutOptions, body })
    .then(validateSuccessResponse)
    .catch(handleCaughtError)
}

export function createSubscription(product: Product, nonce: string) {
  const url = `${getBillingEndpoint()}/braintree/v2/subscription`
  const body = buildTransactionSubmitRequest(product, nonce)
  return fetch(url, { ...defaultPostOptions, body })
    .then(validateBillingSuccessResponse)
    .catch(handleCaughtError)
}

export function createLifetimeTransaction(product: Product, nonce: string) {
  const url = `${getBillingEndpoint()}/braintree/v2/lifetime`
  const body = buildTransactionSubmitRequest(product, nonce)
  return fetch(url, { ...defaultPostOptions, body })
    .then(validateBillingSuccessResponse)
    .catch(handleCaughtError)
}

export function submitVoucherCode(voucherCode: string) {
  const url = `${getBillingEndpoint()}/v3/voucher`
  const body = buildVoucherTransaction(voucherCode)
  return fetch(url, { ...defaultPostOptions, body })
    .then(validateSubmitVoucherResponse)
    .then(deserializeProtobuffResponse(VoucherTransactionResponse))
    .catch(handleCaughtError)
}

export function getKbBannerPrice(): Promise<any> {
  const url = `${getBillingEndpoint()}/braintree/v2/kb-banner-price`
  return fetchWithRetry(url, { ...defaultGetOptions })
    .then(validateSuccessResponse)
    .then(response => response.json())
    // don't override initial values on error
    .catch(error => {})
}

function validateSubmitVoucherResponse(response: Response): Response {
  if (response.status >= 200 && response.status < 300) {
    return response
  } else if (response.status >= 400 && response.status < 500) {
    const errorMessage = `${response.status} - ${response.statusText}!`
    throw new WalletValidationError(errorMessage)
  } else {
    const errorMessage = `${response.status} - ${response.statusText}!`
    throw new WalletRestApiError(errorMessage, response)
  }
}
