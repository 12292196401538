import React from 'react'
import PropTypes from 'prop-types'
import { Modal, Transition } from 'semantic-ui-react'
import { FormattedMessage } from 'src/frontend/modules/intl'
import './ImportsTutorialModal.less'

ImportsTutorialModal.propTypes = {
  open: PropTypes.bool.isRequired,
  modalClose: PropTypes.func.isRequired,
}

export default function ImportsTutorialModal({ open, modalClose }) {
  return (
    <Transition
      visible={open}
      unmountOnHide
      directional
      onHide={modalClose}
      duration={{ show: 250, hide: 150 }}
      animation="modal-animation"
    >
      <Modal
        dimmer
        open
        size="small"
        className="imports-tutorial"
        onClose={modalClose}
      >
        <div className="close-tutorial">
          <span className="modal-close" onClick={modalClose} />
        </div>
        <Modal.Content>
          <div className="modal-content">
            <div className="section">
              <div className="img select-account" />
              <div className="text">
                <div className="section-title">
                  <FormattedMessage id="imports.tutorial.select_account_title" />
                </div>
                <div className="section-text">
                  <FormattedMessage id="imports.tutorial.select_account" />
                </div>
              </div>
            </div>

            <div className="section">
              <div className="img upload-files" />
              <div className="text">
                <div className="section-title">
                  <FormattedMessage id="imports.tutorial.upload_file_title" />
                </div>
                <div className="section-text">
                  <FormattedMessage id="imports.tutorial.upload_file" />
                </div>
              </div>
            </div>

            <div className="section">
              <div className="img set-rules" />
              <div className="text">
                <div className="section-title">
                  <FormattedMessage id="imports.tutorial.set_rules_title" />
                </div>
                <div className="section-text">
                  <FormattedMessage id="imports.tutorial.set_rules_text" />
                </div>
              </div>
            </div>
          </div>
        </Modal.Content>
      </Modal>
    </Transition>
  )
}
