import PropTypes from 'prop-types'
import React from 'react'
import { Modal } from 'semantic-ui-react'
import { FormattedMessage } from 'src/frontend/modules/intl'
import { connect } from 'react-redux'
import * as actions from 'src/frontend/scenes/gdpr/actions'
import { selectGdprCloseConfirmationOpen } from 'src/frontend/scenes/gdpr/selectors'
import { PrimaryButton, SecondaryButton } from 'src/frontend/components/Buttons/Buttons'

const mapDispatchToProps = {
  grantConsent: actions.grantConsent,
  close: actions.closeConfirmModal,
}

function mapStateToProps(state) {
  return {
    open: selectGdprCloseConfirmationOpen(state),
  }
}

function GdprCloseConfirmationModal({ open, grantConsent, close }) {
  return (
    <Modal open={open} size="small" dimmer>
      <Modal.Header>
        <FormattedMessage id="gdpr.close_modal_window.title" />
      </Modal.Header>
      <Modal.Content>
        <FormattedMessage id="gdpr.close_modal_window.content" />
      </Modal.Content>
      <Modal.Actions>
        <SecondaryButton onClick={close}>
          <FormattedMessage id="gdpr.modal_window.not_now_button" />
        </SecondaryButton>
        <PrimaryButton onClick={grantConsent}>
          <FormattedMessage id="gdpr.modal_window.agree_button" />
        </PrimaryButton>
      </Modal.Actions>
    </Modal>
  )
}

GdprCloseConfirmationModal.propTypes = {
  open: PropTypes.bool.isRequired,
  grantConsent: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(GdprCloseConfirmationModal)
