import React from 'react'
import { Accordion as SemanticAccordion } from 'semantic-ui-react'
import filterStyles from 'src/frontend/components/Filter/Filter.module.less'

interface Props {
  className?: string
  title: string | React.ReactNode
  content: string | React.ReactNode
  active: boolean
  name: string,
  onDisplayChange: Function,
}


Accordion.defaultProps = {
  className: undefined,
  active: false,
  onDisplayChange: () => {},
}

export default function Accordion({ className, name, title, content, active, onDisplayChange }: Props) {

  const panels = [{
    title: {
      key: 'title-key',
      content: title,
      className: filterStyles.filterTitle,
    },
    content: {
      key: 'content-key',
      content,
    },
    key: 'panel',
  }]

  return (
    <SemanticAccordion
      className={className}
      activeIndex={active ? 0 : -1}
      panels={panels}
      onTitleClick={() => onDisplayChange(name)}
    />
  )
}
