import React, { useCallback, useMemo } from 'react'
import { Form } from 'semantic-ui-react'
import { connect } from 'react-redux'
import { RootState } from 'src/types/State'
import styles from 'src/frontend/scenes/integrations/newConnection/components/PersonalDetailsForm.module.less'
import PictureIcon from 'src/frontend/components/PictureIcon/PictureIcon'
import {
  selectPersonalDetailsFormValues,
  selectPersonalDetailsLoading,
} from 'src/frontend/scenes/integrations/newConnection/selectors'
import { VerifiedProfile } from 'src/types/User'
import { PrimaryButton } from 'src/frontend/components/Buttons/Buttons'
import { isAppBoard } from 'src/common/environment'
import { changePersonalFormValue, savePersonalDetails } from 'src/frontend/scenes/integrations/newConnection/actions'
import BirthDatePicker from 'src/frontend/scenes/integrations/newConnection/components/BirthDatePicker'
import { formatMessage, FormattedMessage, FormattedMessageLabel } from 'src/frontend/modules/intl'
import { isLessThan10Years } from 'src/backend/time/time'
import moment from 'moment'


interface Props {
  formValues: VerifiedProfile,
  loading: boolean,
  onFieldChange: (name: string, value: string) => void
  onFormSubmit: () => void
}

function mapStateToProps(state: RootState) {
  return {
    formValues: selectPersonalDetailsFormValues(state),
    loading: selectPersonalDetailsLoading(state),
  }
}

const mapDispatchToProps = {
  onFieldChange: changePersonalFormValue,
  onFormSubmit: savePersonalDetails,
}


function PersonalDetailsForm({ formValues, loading, onFieldChange, onFormSubmit }: Props) {

  const handleChangeField = useCallback((_e, { name, value }) => onFieldChange(name, value), [])

  const fieldsData = useMemo(() => isAppBoard()
    ? (
      <>
        <Form.Input
          label={<FormattedMessageLabel id="integrations.personal-details.full-name" />}
          value={formValues.fullName}
          name="fullName"
          placeholder={formatMessage('integrations.personal-details.full-name.placeholder')}
          onChange={handleChangeField}
        />
        <Form.Input
          label={<FormattedMessageLabel id="integrations.personal-details.companyId" />}
          value={formValues.companyId}
          name="companyId"
          // tslint:disable-next-line:jsx-use-translation-function
          placeholder={'XXXXXX'}
          onChange={handleChangeField}
        />
        <Form.Input
          label={<FormattedMessageLabel id="integrations.personal-details.address" />}
          value={formValues.streetAddress}
          name="streetAddress"
          placeholder={formatMessage('integrations.personal-details.address.placeholder')}
          onChange={handleChangeField}
        />
        <Form.Input
          label={<FormattedMessageLabel id="integrations.personal-details.companyName" />}
          value={formValues.companyName}
          name="companyName"
          placeholder={formatMessage('integrations.personal-details.companyName.placeholder')}
          onChange={handleChangeField}
        />
      </>
    )
    : (
      <>
        <Form.Input
          label={<FormattedMessageLabel id="integrations.personal-details.full-name" />}
          value={formValues.fullName}
          name="fullName"
          placeholder={formatMessage('integrations.personal-details.full-name.placeholder')}
          onChange={handleChangeField}
        />
        <BirthDatePicker
          label={<FormattedMessageLabel id="integrations.personal-details.birthDate" />}
          value={formValues.birthday}
          name="birthday"
          onChange={handleChangeField}
        />
        <Form.Input
          label={<FormattedMessageLabel id="integrations.personal-details.address" />}
          value={formValues.streetAddress}
          name="streetAddress"
          placeholder={formatMessage('integrations.personal-details.address.placeholder')}
          onChange={handleChangeField}
        />
      </>
    ), [formValues])

  const stringFields = isAppBoard()
    ? [formValues.fullName, formValues.companyId, formValues.companyName, formValues.streetAddress]
    : [formValues.fullName, formValues.streetAddress]

  // every field must be filled, if wallet then birthday must be more than 10y
  const isValid = stringFields.every((field) => field?.trim()?.length >= 3)
    && (!isLessThan10Years(moment.utc(formValues.birthday)) || isAppBoard())

  return (
    <Form className={styles.personalDetails}>
      <div className={styles.description}>
        <PictureIcon name="law" compact inline size="120px" />
        <p><FormattedMessage id="integrations.personal-details.description" /></p>
      </div>
      {fieldsData}

      <PrimaryButton
        fluid
        disabled={!isValid || loading}
        loading={loading}
        onClick={onFormSubmit}
      >
        <FormattedMessage id="form.continue" />
      </PrimaryButton>
    </Form>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(PersonalDetailsForm)
