import * as actions from './actions'
import { Id } from 'src/types/CouchDb'
import { Action } from 'src/types/common'

export interface SolveDuplicitiesFormState {
  open: boolean
  loading: boolean
  selectedId: Id
}

const initState: SolveDuplicitiesFormState = { open: false, loading: false, selectedId: null }

export function solveDuplicitiesForm(state: SolveDuplicitiesFormState = initState, action: Action) {
  switch (action.type) {
    case actions.SOLVE_DUPLICITIES_FORM_CLOSE: {
      return { ...state, loading: false, open: false, selectedId: null }
    }
    case actions.SOLVE_DUPLICITIES_FORM_OPEN: {
      return { ...state, loading: false, open: true, selectedId: null }
    }
    case actions.SOLVE_DUPLICITIES_START: {
      return { ...state, loading: true }
    }
    case actions.SOLVE_DUPLICITIES_SELECT_ITEM: {
      return { ...state, selectedId: action.payload }
    }
    case actions.SOLVE_DUPLICITIES_SUCCESS: {
      return { ...state, loading: false, open: false, selectedId: null }
    }
    default:
      return state
  }
}
