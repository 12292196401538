import React from 'react'
import styles from './SuperEnvelopesList.module.less'
import { AbstractEnvelope } from 'src/types/Category'
import { mergeClasses } from 'src/common/utils'

interface Props {
  superEnvelopes: AbstractEnvelope[]
  activeEnvelopeId: number
  onClick: Function
}

export default function SuperEnvelopesList({ superEnvelopes, onClick, activeEnvelopeId }: Props) {

  const superEnvelopeItems = Object.values(superEnvelopes).map(envelope => {
    return (
      <li
        className={mergeClasses(envelope.id === activeEnvelopeId && styles.active)}
        key={envelope.id}
        onClick={() => onClick(envelope.id)}
      >
        {envelope.name}
      </li>
    )
  })

  return (
    <div className={styles.envelopesList}>
      <ul>
        {superEnvelopeItems}
      </ul>
    </div>
  )
}

