import { WidgetData } from 'src/frontend/scenes/dashboard/types'
import { BalanceByCurrencyData } from 'src/frontend/scenes/dashboard/widgets/BalanceByCurrencyBar/types'

export function composeBalanceByCurrenciesData(currencies) {
  return (data: WidgetData): Array<BalanceByCurrencyData> => {
    if (!data) {
      return null
    }

    return data.map((balanceData: BalanceByCurrencyData) => {
      const currency = currencies[balanceData.currencyId] || {}
      return {
        ...balanceData,
        ...currency,
      }
    }).sort((a, b) => b.refBalance - a.refBalance)
  }
}
