import * as timeUtils from "src/backend/time/time"
import { RRule } from "rrule"
import moment from "moment"
import uuid from "uuid"
import * as commonRepository from "src/backend/common/repository"
import * as recordRepository from "src/backend/records/repository"
import * as currencyRepository from "src/backend/currencies/repository"
import * as labelsRepository from "src/backend/labels/repository"
import { isFilterEmpty, recordDateIsWithinFilter } from "src/backend/standingOrders/helpers"
import { inMemoryTableNames } from "src/backend/db/inMemorySqlDbSchemaBuilder"
import { standingOrderConverter } from "src/backend/converters/standingOrderConverter"
import { getDefaultStandingOrder } from "src/backend/standingOrders/StandingOrder"
import { matchesFilter, updateAuditData } from "src/backend/common/service"
import _isEmpty from "lodash/isEmpty"
import _isEqual from "lodash/isEqual"
import * as repository from "src/backend/standingOrders/repository"
import * as categoriesRepository from "src/backend/categories/repository"
import { recordConverter } from "src/backend/converters/recordConverter"
import { convertRecordTypesFilter, convertToRecordEntity } from "src/backend/records/converters"
import { Interval } from "src/backend/enums"
import * as categoriesService from "src/backend/categories/service"
import { pipe } from "ramda"
import { getRRuleLocale, getRRuleText } from "src/frontend/modules/intl/rrule-localizations"
import { getInitialLanguageCode } from "src/frontend/modules/intl"

export const getRuleText = (rule, recurrenceMessages) => {
  const language = getInitialLanguageCode()
  return rule.toText(getRRuleText(recurrenceMessages), getRRuleLocale(language))
}

export async function saveStandingOrder(standingOrderData, record, initialRecord = {}, user) {
  const { recordDate } = record
  const recordDateDay = moment(recordDate).startOf("day")
  if (
    (_isEmpty(standingOrderData) && timeUtils.isToday(recordDateDay)) ||
    timeUtils.isBeforeToday(recordDateDay)
  ) {
    console.log("skip saveStandingOrder")
    return Promise.resolve()
  }

  const recordData = convertRecordToStandingOrder(record)

  const rrule = standingOrderData.recurrenceRule
    ? new RRule({ ...standingOrderData.recurrenceRule })
    : null
  const recurrenceRule = rrule ? rrule.toString() : null

  const _id = uuid.v4()
  const category = await categoriesService.getCategoryByCategoryId(recordData.categoryId, user)

  const standingOrder =
    record.reservedModelType === inMemoryTableNames.STANDING_ORDER
      ? await repository.findById(record._id)
      : updateAuditData(user, timeUtils.utcDateAsISOString)(getDefaultStandingOrder(_id))

  const recurrenceRuleChanged = !_isEqual(standingOrder.recurrenceRule, recurrenceRule)
  const recordDateChanged =
    _isEmpty(initialRecord) || !_isEqual(initialRecord.recordDate, record.recordDate)
  const shouldUpdateDate = recurrenceRuleChanged || recordDateChanged

  const generateFromDate = shouldUpdateDate
    ? recordDateDay.toDate()
    : standingOrder.generateFromDate
  const dueDate = shouldUpdateDate ? recordDateDay.toDate() : standingOrder.dueDate

  const standingOrderCandidate = {
    ...standingOrder,
    ...recordData,
    generateFromDate,
    recurrenceRule,
    dueDate,
    categoryId: category._id,
  }

  const shouldMoveDueDate = !shouldUpdateDate && recordDateDay.isSame(timeUtils.getStartOfToday())
  const updatedDueDate = shouldMoveDueDate
    ? getNextRecordDate(standingOrderCandidate)
    : standingOrderCandidate.dueDate

  const standingOrderDocument = pipe(
    convertToRecordEntity,
    convertStandingOrder,
    recordRepository.convertToRecordDocument,
  )({ ...standingOrderCandidate, dueDate: updatedDueDate })

  const [categories, labels] = await Promise.all([
    categoriesRepository.findAllAsHashMap(),
    labelsRepository.findAllAsHashMap(),
  ])

  return commonRepository
    .updateBulk(
      [standingOrderDocument],
      inMemoryTableNames.STANDING_ORDER,
      standingOrderConverter(categories, labels),
    )
    .then((response) => response[0])
}

export async function saveStandingOrders(standingOrders, user) {
  if (_isEmpty(standingOrders)) {
    return Promise.resolve([])
  }

  let standingOrderEntities = []
  for await (const standingOrder of standingOrders) {
    const category = await categoriesService.getCategoryByCategoryId(standingOrder.categoryId, user)
    const standingOrderEntity = pipe(
      convertToRecordEntity,
      convertStandingOrder,
    )({ ...standingOrder, categoryId: category._id })

    standingOrderEntities = [...standingOrderEntities, standingOrderEntity]
  }

  return repository.updateBulk(standingOrderEntities)
}

export async function removeStandingOrders(standingOrderEntities) {
  if (_isEmpty(standingOrderEntities)) {
    return Promise.resolve()
  }

  const standingOrdersToRemove = await Promise.all(
    standingOrderEntities.map((recordEntity) => repository.findById(recordEntity._id)),
  )

  return recordRepository.removeBulk(standingOrdersToRemove)
}

export function getNextRecordDate(standingOrder) {
  if (
    !standingOrder.dueDate ||
    (!standingOrder.generateFromDate && !standingOrder.reservedCreatedAt) ||
    !standingOrder.recurrenceRule
  ) {
    return null
  }

  const dueDate = moment.utc(standingOrder.dueDate).startOf("day")
  const generateFromDate =
    moment.utc(standingOrder.generateFromDate).startOf("day") ||
    moment.utc(standingOrder.reservedCreatedAt).startOf("day")

  if (dueDate.isBefore(generateFromDate)) return null

  let stopIndex = null
  const SAFE_STOP_COUNT = 5000

  const rule = createRecurrenceRule(standingOrder)
  const futureDates = rule.all((date, index) => {
    if (index >= SAFE_STOP_COUNT) return false

    const dateIsDueDate = moment.utc(date).isSame(dueDate)

    // index + 1 -> next index, index + 2 -> after next index (stop there)
    stopIndex = dateIsDueDate ? index + 2 : stopIndex

    return index !== stopIndex
  })

  if (futureDates.length >= SAFE_STOP_COUNT) {
    return null
  }

  return futureDates[futureDates.length - 1] || null
}

export async function generateVirtualRecordsByFilter(filter) {
  const [standingOrders, categories, labels] = await Promise.all([
    repository.findAllAsHashMap(),
    categoriesRepository.findAllAsHashMap(),
    labelsRepository.findAllAsHashMap(),
  ])
  const virtualRecords = await generateVirtualRecords(standingOrders, filter.period)

  const virtualRecordsFilter = {
    accountId: filter.accountIds,
    superEnvelopeId: filter.superEnvelopeIds,
    envelopeId: filter.envelopeIds,
    categoryId: filter.categoryId,
    labels: filter.labelIds,
    ...convertRecordTypesFilter(filter.recordTypes),
    paymentType: filter.paymentType,
  }

  const convert = recordConverter(categories, labels)
  return virtualRecords
    .map((virtualRecord) => convert(virtualRecord))
    .filter(matchesFilter(virtualRecordsFilter))
}

export function generateVirtualRecords(standingOrders, period) {
  const startOfTomorrow = timeUtils.getStartOfTomorrow()
  if (
    (period.start < startOfTomorrow &&
      period.end < startOfTomorrow &&
      period.interval !== Interval.ALL_THE_TIME) ||
    _isEmpty(standingOrders)
  ) {
    return Promise.resolve([])
  }

  return Object.values(standingOrders).reduce(
    async (partOfFuturePaymentsPromise, standingOrder) => {
      if (!standingOrder.dueDate) {
        return partOfFuturePaymentsPromise
      }

      let standingOrderFuturePayments = []

      const note = standingOrder.note || standingOrder.name
      const currency =
        (await currencyRepository.findById(standingOrder.currencyId)) ||
        (await currencyRepository.getReferentialCurrency())

      if (standingOrder.recurrenceRule) {
        if (!standingOrder.generateFromDate) {
          return partOfFuturePaymentsPromise
        }

        const dueDate = moment.utc(standingOrder.dueDate).startOf("day")

        const rule = createRecurrenceRule(standingOrder)

        const { ruleFromDate, ruleToDate } = getDateRange(dueDate, period)
        const standingOrderDates = rule.between(ruleFromDate.toDate(), ruleToDate.toDate(), true)

        standingOrderFuturePayments = standingOrderDates.map((date) => {
          return {
            ...standingOrder,
            note,
            recordDate: date,
            refAmount: standingOrder.amount / currency.ratioToReferential,
          }
        })
      } else {
        const recordDate = timeUtils.startOfDayUTC(standingOrder.dueDate)
        if (
          recordDate >= startOfTomorrow &&
          (recordDateIsWithinFilter(recordDate, period) || isFilterEmpty(period))
        ) {
          standingOrderFuturePayments.push({
            ...standingOrder,
            note,
            recordDate,
            refAmount: standingOrder.amount / currency.ratioToReferential,
          })
        }
      }

      const partOfFuturePayments = await partOfFuturePaymentsPromise
      return [...partOfFuturePayments, ...standingOrderFuturePayments]
    },
    Promise.resolve([]),
  )
}

function createRecurrenceRule(standingOrder) {
  const generateFromDate = timeUtils.startOfDayUTC(standingOrder.generateFromDate)
  let recurrenceRuleOptions = null
  recurrenceRuleOptions = RRule.parseString(standingOrder.recurrenceRule)
  return new RRule({ ...recurrenceRuleOptions, dtstart: generateFromDate })
}

export function getDateRange(dueDate, period = {}) {
  const startOfTomorrow = timeUtils.getStartOfTomorrowUTC()
  const filterStart = period.start && period.start >= dueDate ? moment.utc(period.start) : dueDate

  const ruleFromDate = filterStart >= startOfTomorrow ? filterStart : startOfTomorrow
  const ruleToDate =
    period.end && period.end >= moment.utc()
      ? moment.utc(period.end)
      : timeUtils.nextPeriodFromDate(startOfTomorrow.clone(), 1, "year") // ruleToDate limit

  return { ruleFromDate, ruleToDate }
}

export function fetchStandingOrders() {
  return repository.getAllStandingOrders()
}

function convertRecordToStandingOrder(record) {
  const {
    _id,
    _rev,
    recordDate,
    recordState,
    refAmount,
    photos,
    accuracy,
    latitude,
    longitude,
    warrantyInMonth,
    categoryChanged,
    superEnvelopeId,
    reservedModelType,
    reservedSource,
    reservedCreateAt,
    reservedOwnerId,
    reservedAuthorId,
    reservedUpdatedAt,
    ...convertedRecord
  } = record
  return convertedRecord
}

function convertStandingOrder(standingOrder) {
  const { ruleText, labelsView, ...convertedStandingOrder } = standingOrder
  return convertedStandingOrder
}
