import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { RootState } from 'src/types/State'
import { selectReferentialCurrency } from 'src/frontend/modules/currencies/selectors'
import { ChartType } from 'src/frontend/components/chart/ChartType'
import PieChart from 'src/frontend/components/chart/PieChart'
import { ChartGroupByType } from 'src/frontend/scenes/analytics/enums'
import { formatMessage } from 'src/frontend/modules/intl/i18n'
import { ViewDefinition } from 'src/frontend/scenes/analytics/types'
import LinearChart, { Props as LineChartProps } from 'src/frontend/components/chart/LinearChart'
import './AnalyticsChart.less'
import { LABELS_PER_CHART } from 'src/frontend/scenes/analytics/constants'
import {
  selectChartDatasets,
  selectChartLabels,
  selectChartType,
  selectViewDefinition,
} from 'src/frontend/scenes/analytics/selectors'
import { Loader } from 'semantic-ui-react'
import { selectIsAnalyticsLoading, selectShouldRefreshAnalytics } from 'src/frontend/modules/moduleStatus/selectors'
import { refreshAnalyticsStatus } from 'src/frontend/modules/moduleStatus/actions'

interface Props extends LineChartProps {
  viewDefinition: ViewDefinition

  shouldRefreshAnalytics: boolean
  isAnalyticsLoading: boolean

  refreshAnalytics: Function
}

function mapStateToProps(state: RootState) {
  return {
    type: selectChartType(state),
    labels: selectChartLabels(state),
    datasets: selectChartDatasets(state),
    currency: selectReferentialCurrency(state),
    viewDefinition: selectViewDefinition(state),
    labelsPerChart: LABELS_PER_CHART,
    shouldRefreshAnalytics: selectShouldRefreshAnalytics(state),
    isAnalyticsLoading: selectIsAnalyticsLoading(state),
  }
}

const mapDispatchToProps = {
  refreshAnalytics: refreshAnalyticsStatus,
}


function AnalyticsChart(props: Props) {
  useEffect(() => {
    if (props.shouldRefreshAnalytics) {
      props.refreshAnalytics()
    }
  }, [props.shouldRefreshAnalytics])

  if (props.isAnalyticsLoading) {
    return <Loader active inline="centered" />
  }

  switch (props.type) {
    case ChartType.LINE:
    case ChartType.BAR:
      return (
        <div className="analytics-view">
          <LinearChart {...props} />
        </div>
      )
    case ChartType.PIE: {
      const labels = props.labels.map((label) => {
        return Array.isArray(label) ? label[0] : label
      }).map((label: string): string => {
        switch (props.viewDefinition.groupBy) {
          default:
          case ChartGroupByType.NONE:
          case ChartGroupByType.ACCOUNTS:
            return label
          case ChartGroupByType.CATEGORIES:
            return formatMessage(label)
        }
      })
      return (
        <div className="analytics-view">
          <PieChart {...{ ...props, labels }} />
        </div>
      )
    }
    default:
      throw new Error(`Unsupported chart type: ${props.type}`)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AnalyticsChart)
