import * as integrationsBackend from 'src/backend/rest/backend/integrations'
import * as backend from 'src/backend/rest/backend'
import { IntegrationProviders as IP } from "src/frontend/scenes/integrations/providersList/types"
import { Id } from "src/types/CouchDb"
import { integrationSourceName } from "src/backend/integrations/enums"
import IntegrationProviderDetail = IntegrationProviders.IntegrationProviderDetail
import IntegrationProvider = IntegrationProviders.IntegrationProvider
import IntegrationConnectedProvider = IntegrationProviders.IntegrationConnectedProvider
import IntegrationLoginResponse = IntegrationProviders.IntegrationLoginResponse
import { FormAttributeFields } from "src/frontend/scenes/integrations/types"
import { IntegrationConnection } from "src/frontend/scenes/integrations/newConnection/types"
import IntegrationAccountCreationBlueprint = IntegrationConnection.IntegrationAccountCreationBlueprint
import { getRandomColor } from "src/backend/colors/colors"
import { IntegrationProviders } from "src/frontend/scenes/integrations/providersList/types"
import IntegrationLoginStatus = IntegrationProviders.IntegrationLoginStatus
import { NoRemoteAccountsError } from "src/backend/integrations/errors"
import IntegrationAccountConnectionPair = IntegrationConnection.IntegrationAccountConnectionPair
import IntegrationAccount = IntegrationConnection.IntegrationAccount
import { VerifiedProfile } from "src/types/User"

export function fetchIntegrationCountries(): Promise<string[]> {
  return integrationsBackend
    .fetchIntegrationsCountries()
    .then((response) =>
      response.countries ? response.countries.map((country) => country.code) : [],
    )
}

export function fetchIntegrationProvidersByCountry(
  countryCode: string,
): Promise<IntegrationProvider[]> {
  return integrationsBackend
    .fetchIntegrationProvidersByCountry(countryCode)
    .then((response) => response.providers || [])
}

export function fetchExistingIntegrations(userId: Id): Promise<IntegrationProvider[]> {
  return integrationsBackend
    .fetchExistingIntegrations(userId)
    .then((response) => response.providers || [])
}

export function fetchExistingConnectedIntegrations(
  userId: Id,
): Promise<IntegrationConnectedProvider[]> {
  return integrationsBackend
    .fetchExistingIntegrations(userId)
    .then((response) => response.providers || [])
}

export async function fetchIntegrationProvidersDetailByProviderId(
  userId: Id,
  provider: IntegrationProvider,
): Promise<IntegrationProviderDetail> {
  const sourceName = integrationSourceName[provider.integrationSource]

  const providerDetails = await integrationsBackend.fetchIntegrationProvidersDetailByProviderCode(
    userId,
    sourceName,
    provider.code,
  )

  return {
    ...providerDetails,
    isAutomatic: provider.isAutomatic,
  }
}

export async function fetchIntegrationProvidersDetailByProviderIdAndSourceName(
  userId: Id,
  providerCode: string,
  sourceName: string,
): Promise<IntegrationProviderDetail> {
  const providerDetails = await integrationsBackend.fetchIntegrationProvidersDetailByProviderCode(
    userId,
    sourceName,
    providerCode,
  )

  return {
    ...providerDetails,
  }
}

export function fetchIntegrationProvidersDetailByLoginId(
  userId: Id,
  provider: IntegrationProvider,
): Promise<IntegrationProviderDetail> {
  const sourceName = integrationSourceName[provider.integrationSource]

  return integrationsBackend.fetchIntegrationProvidersDetailByLoginId(
    userId,
    sourceName,
    provider.id,
  )
}

export function refreshIntegrationTransactions(
  userId: Id,
  provider: IntegrationProvider,
): Promise<IntegrationLoginResponse> {
  const sourceName = integrationSourceName[provider.integrationSource]

  return integrationsBackend.refreshIntegrationTransactions(userId, sourceName, provider.id)
}

export function finishOauth(
  userId: Id,
  provider: IntegrationProvider,
  loginId: Id,
  queryParams: string,
): Promise<IntegrationLoginResponse> {
  const sourceName = integrationSourceName[provider.integrationSource]

  return integrationsBackend.finishOauth(userId, sourceName, loginId, queryParams)
}

export function checkProviderStatus(
  userId: Id,
  provider: IntegrationProvider,
): Promise<IntegrationLoginResponse> {
  const sourceName = integrationSourceName[provider.integrationSource]

  return integrationsBackend.checkProviderStatus(userId, sourceName, provider.id)
}

export function loginToProvider(
  userId: Id,
  provider: IntegrationProvider,
  formFields: FormAttributeFields,
): Promise<IntegrationLoginResponse> {
  const sourceName = integrationSourceName[provider.integrationSource]
  const formValues = Object.values(formFields)

  // TODO: can be handled on BE
  return integrationsBackend
    .loginToProvider(userId, sourceName, provider.code, formValues)
    .then((loginResponse) => {
      return loginResponse.status === IntegrationLoginStatus.LOGIN_DUPLICATED
        ? integrationsBackend.reconnectProvider(
            userId,
            sourceName,
            loginResponse.loginId,
            formValues,
          )
        : loginResponse
    })
}

export function reconnectProvider(
  userId: Id,
  provider: IntegrationProvider,
  formFields: FormAttributeFields,
  loginId: string,
): Promise<IntegrationLoginResponse> {
  const sourceName = integrationSourceName[provider.integrationSource]
  const formValues = Object.values(formFields)

  return integrationsBackend.reconnectProvider(userId, sourceName, loginId, formValues)
}

export function sendMFA(
  userId: Id,
  provider: IntegrationProvider,
  formFields: FormAttributeFields,
  inputType?: IP.IntegrationRecipe.InputType,
  id?: string,
): Promise<IntegrationLoginResponse> {
  const sourceName = integrationSourceName[provider.integrationSource]
  const formValues = Object.values(formFields)

  const result = integrationsBackend.sendMFA(
    userId,
    sourceName,
    provider.id,
    formValues,
    inputType,
    id,
  )
  console.log("sendMFA - result: ", result)
  return result

}

export function fetchIntegrationAccounts(userId: Id, provider: IntegrationProvider) {
  const sourceName = integrationSourceName[provider.integrationSource]
  return integrationsBackend
    .fetchIntegrationAccounts(userId, sourceName, provider.id)
    .then((response) => {
      if (response.accounts) {
        return response.accounts
      } else {
        throw new NoRemoteAccountsError()
      }
    })
}

export function createIntegrationAccounts(
  userId: Id,
  provider: IntegrationProvider,
  accounts: IntegrationAccount[],
) {
  const accountBlueprints: IntegrationAccountCreationBlueprint[] = accounts
    .filter((account) => !account.connectedAppAccountId)
    .map(
      (account): IntegrationAccountCreationBlueprint => ({
        name: `${provider.name} ${account.name}`,
        color: getRandomColor(),
        remoteAccountId: account.id,
      }),
    )

  const sourceName = integrationSourceName[provider.integrationSource]
  return integrationsBackend.createIntegrationAccounts(
    userId,
    sourceName,
    provider.id,
    accountBlueprints,
  )
}

export function connectIntegrationAccount(
  userId: Id,
  provider: IntegrationProvider,
  remoteAccountId: string,
  accountId: Id,
) {
  const accountPair: IntegrationAccountConnectionPair[] = [
    {
      appAccountId: accountId,
      remoteAccountId,
    },
  ]

  const sourceName = integrationSourceName[provider.integrationSource]
  return integrationsBackend.connectIntegrationAccount(userId, sourceName, provider.id, accountPair)
}

export function disconnectIntegrationAccount(
  userId: Id,
  loginId: string,
  sourceName: string,
  remoteAccountId: string,
  accountId: Id,
) {
  const accountPair: IntegrationAccountConnectionPair[] = [
    {
      appAccountId: accountId,
      remoteAccountId,
    },
  ]

  return integrationsBackend.disconnectIntegrationAccount(userId, sourceName, loginId, accountPair)
}

export function deactivateIntegrationConnection(userId: Id, provider: IntegrationProvider) {
  const sourceName = integrationSourceName[provider.integrationSource]
  return integrationsBackend.deactivateIntegrationConnection(userId, sourceName, provider.id)
}

export function savePersonalDetails(formValues: VerifiedProfile) {
  return backend.savePersonalDetails(formValues)
}

function transformAccountsFormFields(formValues: FormAttributeFields): FormAttributeFields {
  if (formValues.account_names && Array.isArray(formValues.account_names.value)) {
    const selectedAccounts: FormAttributeFields = {}

    formValues.account_names.value.forEach((account: string) => {
      selectedAccounts[account] = {
        description: undefined,
        name: account,
        value: account,
      }
    })
    return selectedAccounts
  }
  return formValues
}