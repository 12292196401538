import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { connect } from 'react-redux'
import { Waypoint } from 'react-waypoint'
import { COUNT_INCREMENT } from 'src/frontend/scenes/records/recordList/constants'
import { RootState } from 'src/types/State'
import { Contact } from 'src/types/Contact'
import { ContactListOrderBy } from 'src/frontend/scenes/contacts/contactList/enums'
import { selectContactListContacts, selectOrderBy } from 'src/frontend/scenes/contacts/contactList/selectors'
import ContactView = Contact.ContactView
import ContactListItem from 'src/frontend/scenes/contacts/contactList/components/ContactListItem'
import panelStyles from 'src/frontend/components/ColumnLayout/PanelList.module.less'
import _isEmpty from 'lodash/isEmpty'
import EmptyState from 'src/frontend/scenes/settings/components/EmptyState'
import { selectContacts } from 'src/frontend/modules/contacts/selectors'
import { HashMap } from 'src/types/common'
import ContactDocument = Contact.ContactDocument
import FormattedMessage from 'src/frontend/modules/intl/components/FormattedMessage'

interface Props {
  contacts: ContactView[]
  allContacts: HashMap<ContactDocument>
  order: ContactListOrderBy
}

function mapStateToProps(state: RootState) {
  return {
    contacts: selectContactListContacts(state),
    allContacts: selectContacts(state),
    order: selectOrderBy(state),
  }
}

function AccountsList({
  contacts,
  allContacts,
}: Props) {
  const [visibleContactsCount, setVisibleContactsCount] = useState<number>(COUNT_INCREMENT)
  const [visibleContacts, setVisibleContacts] = useState<ContactView[]>(contacts.slice(0, COUNT_INCREMENT))

  useEffect(() => {
    setVisibleContactsCount(COUNT_INCREMENT)
    setVisibleContacts(contacts.slice(0, COUNT_INCREMENT))
  }, [contacts])

  const onWaypointEnter = useCallback(() => {
    const newOffset = visibleContactsCount + COUNT_INCREMENT
    setVisibleContactsCount(newOffset)
    setVisibleContacts(contacts.slice(0, newOffset))
  }, [contacts])

  const mapContactListItems = useMemo(() => {
    return visibleContacts.map((contact) => <ContactListItem key={contact._id} contact={contact} />)
  }, [visibleContacts])

  if (_isEmpty(allContacts)) {
    return (
      <EmptyState
        icon="white-user"
        title={<FormattedMessage id="contacts.empty.title" />}
        text={<FormattedMessage id="contacts.empty.text" />}
      />
    )
  }

  return (
    <div className={panelStyles.list}>
      {mapContactListItems}
      <Waypoint onEnter={onWaypointEnter} bottomOffset="-50%" />
    </div>
  )
}

export default connect(mapStateToProps)(AccountsList)
