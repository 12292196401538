/* tslint:disable:space-in-parens */
import enRecurrenceTranslations from "static/locales/translations/en-us/recurrence.json"
import moment from "moment"
import { IntlProvider, createIntl, IntlShape } from "react-intl/dist"
import { getRelativeIntervalValue } from "src/backend/time/time"
import {
  DATE_LONG,
  DATE_SHORT,
  DATE_TIME,
  YEAR_LONG,
} from "src/frontend/modules/intl/formatOptions"
import {
  getSupportedLanguage,
  saveLanguageToLocalStorage,
} from "src/frontend/modules/intl/languages"
import { Interval, RelativeIntervalType } from "src/backend/enums"
import { isAppBoard } from "src/common/environment"
import IntlRelativeTimeFormat from "@formatjs/intl-relativetimeformat"
import { HashMap } from "src/types/common"
import { FormatNumberOptions } from "react-intl/src/types"

export const DEFAULT_LANGUAGE = "en"
export const DEFAULT_LOCALE = "en"

export const WITH_DECIMAL_PLACES_VALUE = undefined // use currency's default settings
export const WITHOUT_DECIMAL_PLACES_VALUE = 0 // force 0 decimal places

export { IntlProvider }

const formats = {
  number: {
    currency: {
      style: "currency",
      currencyDisplay: "symbol",
    },
    percentage: {
      style: "percent",
      maximumFractionDigits: 0,
    },
  },
}

let intl: IntlShape = null
let rruleMessages = {}

export function getRRuleMessages() {
  return rruleMessages
}

export async function setLanguage(code) {
  const { iso, locale, localeUnderscore } = getSupportedLanguage(code)
  moment.locale(locale)
  saveLanguageToLocalStorage(code)

  const messagesObject =
    code === DEFAULT_LANGUAGE ? await getDefaultMessages() : await getMessages(code)

  const { messages, recurrenceMessages } = messagesObject

  intl = createIntl({
    locale: code,
    messages,
    formats: getDefaultFormats(),
  })

  rruleMessages = recurrenceMessages

  return { code, iso, localeUnderscore, locale, messages, recurrenceMessages }
}

export function setDecimalPlaces(decimalPlaces, locale, messages) {
  const newFormats = getFormatsWithDecimalPlaces(decimalPlaces)

  intl = createIntl({
    locale,
    messages,
    formats: newFormats,
  })

  return { formats: newFormats }
}

export function getCurrentLanguage() {
  const { locale: code, messages } = intl
  const { locale, iso, localeUnderscore } = getSupportedLanguage(code)

  return { code, iso, localeUnderscore, locale, messages, recurrenceMessages: rruleMessages }
}

export function getDefaultFormats() {
  return formats
}

function getFormatsWithDecimalPlaces(numberOfPlaces) {
  const defaultFormats = getDefaultFormats()
  return {
    ...defaultFormats,
    number: {
      ...defaultFormats.number,
      currency: {
        ...defaultFormats.number.currency,
        minimumFractionDigits: numberOfPlaces,
        maximumFractionDigits: numberOfPlaces,
      },
    },
  }
}

function getCustomMessagesFile(language?: string): Promise<HashMap<string>> {
  const files = isAppBoard()
    ? {
        cs: import(
          /* webpackChunkName: "locale-board" */ "static/locales/customTranslations/customStrings-board-cz.json"
        ).then((module) => module.default),
        [DEFAULT_LANGUAGE]: import(
          /* webpackChunkName: "locale-board" */ "static/locales/customTranslations/customStrings-board.json"
        ).then((module) => module.default),
      }
    : {
        cs: import(
          /* webpackChunkName: "locale-wallet" */ "static/locales/customTranslations/customStrings-wallet-cz.json"
        ).then((module) => module.default),
        [DEFAULT_LANGUAGE]: import(
          /* webpackChunkName: "locale-wallet" */ "static/locales/customTranslations/customStrings-wallet.json"
        ).then((module) => module.default),
      }

  return language in files ? files[language] : files[DEFAULT_LANGUAGE]
}

export async function getDefaultMessages() {
  const walletMessages = await import(
    /* webpackChunkName: "locale-wallet" */ "static/locales/translations/en-us/strings-wallet.json"
  ).then((module) => module.default)

  if (isAppBoard()) {
    const customMessages = await getCustomMessagesFile()

    const boardMessages = await import(
      /* webpackChunkName: "locale-board" */ "static/locales/translations/en-us/strings-board.json"
    ).then((module) => module.default)
    return {
      messages: { ...walletMessages, ...boardMessages, ...customMessages },
      recurrenceMessages: enRecurrenceTranslations,
    }
  }

  return { messages: walletMessages, recurrenceMessages: enRecurrenceTranslations }
}

export function getMessages(languageCode: string) {
  return selectTranslations(languageCode)
}

export function formatMessage(
  id: string | { W?: string; B?: string },
  formatOptions?: any,
): string {
  if (id) {
    const messageId = typeof id === "string" ? id : id[isAppBoard() ? "B" : "W"]

    return intl.formatMessage({ id: messageId }, formatOptions)
  }
  return ""
}

export function formatNumber(numberValue: number, formatOptions?: FormatNumberOptions): string {
  return intl.formatNumber(numberValue, formatOptions)
}

export function formatDate(date, formatOptions?: any): string {
  return intl.formatDate(date, formatOptions)
}

export function formatDateShort(date: Date): string {
  return intl.formatDate(date, DATE_SHORT)
}

export function formatDateYearShort(date: Date): string {
  return intl.formatDate(date, YEAR_LONG)
}

export function formatDateLong(date: Date): string {
  return intl.formatDate(date, DATE_LONG)
}

export function formatRelative(
  value: number,
  unit: Parameters<IntlRelativeTimeFormat["format"]>[1] = "day",
): string {
  return intl.formatRelativeTime(value, unit, { style: "long", numeric: "auto" })
}

export function formatDateTime(date: Date): string {
  return intl.formatDate(date, DATE_TIME)
}

export function formatAbbreviatedNumber(value: number): string {
  const numberValue = Math.abs(Math.floor(value))

  if (numberValue >= 1000) {
    const suffixes = ["", "k", "M", "B", "T"]
    /*
      suffixNum
      - division by 3 promotes hundred amount to higher rank (100k => 0.1M)
      - division by ~3.333 keeps hundred amount in lower rank (100k => 100k)
    */
    const suffixNum = Math.floor(`${numberValue}`.length / (10 / 3))
    const shortNumber = suffixNum !== 0 ? numberValue / 1000 ** suffixNum : numberValue
    const digitsCount = `${Math.floor(shortNumber)}`.length

    /*
     when single digit number, show no decimals, otherwise, fill decimals to have 3 digits. digitsCount can only
      have 3 digits from previous calculations
      - 1.23k
      - 12.3k
      - 123k
      - 1.23M ..... etc
    */

    const THRESHOLD_DIGITS_COUNT = 3
    const precision = digitsCount > 1 ? THRESHOLD_DIGITS_COUNT - digitsCount : 2

    const shortValue = formatNumber(Math.sign(value) * shortNumber, {
      style: "decimal",
      minimumFractionDigits: 0,
      maximumFractionDigits: precision,
    })

    return `${shortValue}${suffixes[suffixNum]}`
  }
  return (Math.sign(value) * numberValue).toString()
}

/**
 * Try full languageCode, try languageCode without region code, fallback to 'en'
 * @param languageCode
 * @returns {*}
 */
function selectTranslations(languageCode: string) {
  const parsedCode = languageCode.toLowerCase().split(/[_-]+/)[0]

  if (parsedCode === DEFAULT_LANGUAGE) {
    return getDefaultMessages()
  }

  const language = getSupportedLanguage(languageCode)

  return Promise.all([
    getTranslationsForLocale(language.locale),
    getRecurrenceTranslationsForLocale(language.locale),
    getCustomMessagesFile(language.code),
  ])
    .then(([messages, recurrenceMessages, customMessages]) => {
      return {
        messages: { ...messages, ...customMessages },
        recurrenceMessages: recurrenceMessages,
      }
    })
    .catch(() => {
      return getDefaultMessages()
    })
}

export function formatInterval(
  interval: Interval,
  intervalType: RelativeIntervalType = RelativeIntervalType.RELATIVE,
): string {
  switch (interval) {
    case Interval.TODAY: {
      return formatMessage("today")
    }
    case Interval.WEEK: {
      return formatMessage("thisWeek")
    }
    case Interval.MONTH: {
      return formatMessage("thisMonth")
    }
    case Interval.YEAR: {
      return formatMessage("thisYear")
    }
    default: {
      const relativeIntervalValues = getRelativeIntervalValue(interval)
      return relativeIntervalValues
        ? formatMessage(`${intervalType}-${relativeIntervalValues.unit}`, {
            count: relativeIntervalValues.value,
          })
        : formatMessage(interval)
    }
  }
}

async function getTranslationsForLocale(locale: string) {
  const walletMessages = await import(
    `static/locales/translations/${locale.toLowerCase()}/strings-wallet.json`
  ).then((module) => module.default)
  if (isAppBoard()) {
    const boardMessages = await import(
      `static/locales/translations/${locale.toLowerCase()}/strings-board.json`
    ).then((module) => module.default)
    return { ...walletMessages, ...boardMessages }
  }

  return walletMessages
}

function getRecurrenceTranslationsForLocale(locale: string) {
  return import(`static/locales/translations/${locale.toLowerCase()}/recurrence.json`).then(
    (module) => module.default,
  )
}