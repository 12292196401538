import { Currency } from "src/types/Currency"
import { Id } from "src/types/CouchDb"
import React, { Component } from "react"
import { countPercentChange } from "src/common/helpers"
import { Table } from "semantic-ui-react"
import { CategoryType } from "src/backend/enums"
import {
  FormattedMoney,
  FormattedPercentageChange,
  FormattedMessage,
} from "src/frontend/modules/intl"
import ReportTableRow from "src/frontend/scenes/analytics/Report/components/ReportTableRow"
import "./ReportTable.less"
import { RecordCategoryLevel } from "src/backend/categories/enums"
import {
  ReportData,
  ReportEnvelope,
  ReportSubcategory,
  ReportSuperEnvelope,
} from "src/frontend/scenes/analytics/Report/types"

interface Props {
  totalLabel: string | React.ReactNode
  categoriesReport: ReportData
  referentialCurrency: Currency
  onOpenCategoryPreview: Function
}

interface State {
  expandedSuperEnvelopeIds: Id[]
}

export default class ReportTable extends Component<Props, State> {
  state = { expandedSuperEnvelopeIds: [] }

  handleToggleSuperEnvelope = (superEnvelopeId) =>
    this.setState((prevState: State) => {
      const { expandedSuperEnvelopeIds } = prevState

      if (expandedSuperEnvelopeIds.includes(superEnvelopeId)) {
        return {
          expandedSuperEnvelopeIds: expandedSuperEnvelopeIds.filter((id) => id !== superEnvelopeId),
        }
      }
      return { expandedSuperEnvelopeIds: [...expandedSuperEnvelopeIds, superEnvelopeId] }
    })

  render() {
    const { categoriesReport, referentialCurrency, totalLabel, onOpenCategoryPreview } = this.props
    const { expandedSuperEnvelopeIds } = this.state

    const {
      reportsStructure,
      refIncome,
      refExpense,
      previousPeriodRefIncome,
      previousPeriodRefExpense,
    } = categoriesReport

    const totalRefCashFlow = refIncome - refExpense
    const totalPreviousPeriodRefCashFlow = previousPeriodRefIncome - previousPeriodRefExpense
    const shouldInvertPercent =
      reportsStructure &&
      reportsStructure[0] &&
      reportsStructure[0].defaultType === CategoryType.EXPENSE

    const totalRefCashFlowChange = shouldInvertPercent
      ? countPercentChange(-totalPreviousPeriodRefCashFlow, -totalRefCashFlow)
      : countPercentChange(totalPreviousPeriodRefCashFlow, totalRefCashFlow)

    return (
      <div className="report-container">
        <Table
          singleLine
          className="report-table"
          basic="very"
        >
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell />
              <Table.HeaderCell textAlign="right">
                <FormattedMessage id="period-current" />
              </Table.HeaderCell>
              <Table.HeaderCell textAlign="right">
                <FormattedMessage id="period-previous" />
              </Table.HeaderCell>
              <Table.HeaderCell />
              <Table.HeaderCell />
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row className="report-row-total">
              <Table.Cell width={5}>
                <strong>{totalLabel}</strong>
              </Table.Cell>
              <Table.Cell
                width={4}
                textAlign="right"
              >
                <strong>
                  <FormattedMoney
                    value={totalRefCashFlow}
                    currency={referentialCurrency.code}
                  />
                </strong>
              </Table.Cell>
              <Table.Cell
                width={4}
                textAlign="right"
              >
                <FormattedMoney
                  value={totalPreviousPeriodRefCashFlow}
                  currency={referentialCurrency.code}
                />
              </Table.Cell>
              <Table.Cell
                width={2}
                textAlign="right"
              >
                <FormattedPercentageChange
                  simple
                  change={totalRefCashFlowChange}
                  inverted={shouldInvertPercent}
                />
              </Table.Cell>
              <Table.Cell
                width={1}
                textAlign="right"
              />
            </Table.Row>
            {reportsStructure.map((superEnvelope: ReportSuperEnvelope) => {
              const expandedClass = expandedSuperEnvelopeIds.includes(superEnvelope.id)
                ? " expanded"
                : ""

              return (
                <React.Fragment key={superEnvelope.id}>
                  <ReportTableRow
                    showIcon
                    shouldInvertPercent={shouldInvertPercent}
                    data={superEnvelope}
                    referentialCurrency={referentialCurrency}
                    className="report-row-values clickable"
                    recordCategoryLevel={RecordCategoryLevel.SUPER_ENVELOPE}
                    recordCategoryValue={superEnvelope.id}
                    onOpenCategoryPreview={onOpenCategoryPreview}
                    onClick={() => this.handleToggleSuperEnvelope(superEnvelope.id)}
                  />
                  <Table.Row>
                    <Table.Cell
                      colSpan={8}
                      className="expandable-cell"
                    >
                      <div className={expandedClass}>
                        <Table basic="very">
                          <Table.Body>
                            {superEnvelope.envelopes.map((envelope: ReportEnvelope) => {
                              return (
                                <React.Fragment key={envelope.envelopeId}>
                                  <ReportTableRow
                                    shouldInvertPercent={shouldInvertPercent}
                                    data={envelope}
                                    referentialCurrency={referentialCurrency}
                                    namePadding="4rem"
                                    recordCategoryLevel={RecordCategoryLevel.ENVELOPE}
                                    recordCategoryValue={envelope.envelopeId}
                                    onOpenCategoryPreview={onOpenCategoryPreview}
                                  />
                                  {envelope.subcategories.map((subcategory: ReportSubcategory) => {
                                    return (
                                      <ReportTableRow
                                        shouldInvertPercent={shouldInvertPercent}
                                        key={subcategory._id}
                                        data={subcategory}
                                        referentialCurrency={referentialCurrency}
                                        namePadding="6rem"
                                        recordCategoryLevel={RecordCategoryLevel.CATEGORY}
                                        recordCategoryValue={subcategory._id}
                                        onOpenCategoryPreview={onOpenCategoryPreview}
                                      />
                                    )
                                  })}
                                </React.Fragment>
                              )
                            })}
                          </Table.Body>
                        </Table>
                      </div>
                    </Table.Cell>
                  </Table.Row>
                </React.Fragment>
              )
            })}
          </Table.Body>
        </Table>
      </div>
    )
  }
}
