import { BraintreePaymentMethod } from 'src/frontend/scenes/billing/paymentMethod/types'
import { getPaymentMethodImage, getPaymentMethodString } from 'src/frontend/scenes/settings/billing/helpers'
import { Image, Text, View } from '@react-pdf/renderer'
import React from 'react'
import { formatMessage } from 'src/frontend/modules/intl'

interface PaymentMethodProps {
  paymentMethod: BraintreePaymentMethod
}

export default function PaymentMethod({ paymentMethod }: PaymentMethodProps) {

  const paymentMethodImageName = getPaymentMethodImage(paymentMethod)

  const paymentMethodImage = paymentMethodImageName
    ? require(`src/frontend/assets/images/icons/credit-card-png/${paymentMethodImageName}.png`)
    : null

  return (
    <View
      style={{
        fontFamily: 'Helvetica-Bold',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      }}
    >
      <Text style={{ marginRight: '5pt' }}>{formatMessage('billing.payment-method')}:</Text>
      {paymentMethodImage
        ? <Image src={paymentMethodImage} style={{ marginRight: '10pt', height: '15pt', width: 'auto' }} />
        : null}
      <Text>{getPaymentMethodString(paymentMethod)}</Text>
    </View>
  )
}
