import _differenceBy from "lodash/differenceBy"
import _uniqBy from "lodash/uniqBy"
import { createSelector } from "reselect"
import { isConnected, isNotConnected } from "src/backend/accounts/helpers"
import { accountCurrencyCode, sortEntities } from "src/backend/common/helpers"
import { Feature } from "src/backend/user/billingFeatures"
import { getUserFeatureValue } from "src/backend/user/service"
import { selectRewardAccountIds } from "src/frontend/modules/rewardPoints/selectors"
import { selectUser } from "src/frontend/modules/user/selectors"
import _pickBy from "lodash/pickBy"
import { RootState } from "src/types/State"
import { selectModules } from "src/frontend/modules/selectors"
import { Account } from "src/types/Account"
import { User } from "src/types/User"
import { HashMap } from "src/types/common"
import { Currency } from "src/types/Currency"
import { AccountsTotals } from "src/frontend/scenes/dashboard/types"
import { AccountsState } from "src/frontend/modules/accounts/reducer"
import { Id } from "src/types/CouchDb"
import { reduceNonRecordsToHashMap } from "src/backend/sync/helpers"

export const selectAllAccounts = (state: RootState): AccountsState => selectModules(state).accounts

export const selectNonArchivedAccounts = createSelector(
  [selectAllAccounts],
  filterNonArchivedAccounts,
)
export const selectNonArchivedAccountsAsArray = createSelector(
  selectNonArchivedAccounts,
  sortAccounts,
)
export const selectAccountsAsArray = createSelector([selectAllAccounts], sortAccounts)
export const selectNonConnectedAccounts = createSelector(
  selectNonArchivedAccounts,
  filterNonConnectedAccounts,
)
export const selectConnectedAccounts = createSelector([selectAllAccounts], filterConnectedAccounts)
export const selectConnectedAccountsAsArray = createSelector(
  [selectConnectedAccounts],
  sortAccounts,
)

const selectRewardAccounts = createSelector(
  [selectNonArchivedAccountsAsArray, selectRewardAccountIds],
  filterRewardAccounts,
)

export const selectUserWritableAccounts = createSelector(
  selectAccountsAsArray,
  selectRewardAccounts,
  selectUser,
  getUserWritableAccounts,
)

export function getUserWritableAccounts(
  accounts: Account[],
  rewardAccounts: Account[],
  user: User,
): Account[] {
  const nonConnectedAccounts = accounts.filter(isNotConnected)
  const connectedAccounts = accounts.filter(isConnected)
  const maxAccountsCount = getUserFeatureValue(user, Feature.MAX_ACCOUNTS_COUNT)
  const nonRewardAccounts = _differenceBy(nonConnectedAccounts, rewardAccounts, "_id")
  const nonRewardPart = nonRewardAccounts.slice(0, maxAccountsCount)

  return sortEntities(
    reduceNonRecordsToHashMap(
      _uniqBy([...nonRewardPart, ...rewardAccounts, ...connectedAccounts], "_id"),
    ),
  )
}

export function filterRewardAccounts(
  accountsAsArray: Account[],
  rewardAccountIds: Id[],
): Account[] {
  return accountsAsArray.filter((account) => rewardAccountIds.includes(account._id))
}

function sortAccounts(accounts: HashMap<Account>) {
  return sortEntities(accounts)
}

function filterNonConnectedAccounts(accounts: HashMap<Account>): HashMap<Account> {
  return Object.values(accounts).reduce((filteredAccounts, account) => {
    return isConnected(account) ? filteredAccounts : { ...filteredAccounts, [account._id]: account }
  }, {})
}

function filterConnectedAccounts(accounts: HashMap<Account>): HashMap<Account> {
  return _pickBy(accounts, isConnected)
}

function filterNonArchivedAccounts(accounts: HashMap<Account>): HashMap<Account> {
  return Object.values(accounts).reduce((filteredAccounts, account) => {
    return account.archived ? filteredAccounts : { ...filteredAccounts, [account._id]: account }
  }, {})
}

export function mergeAccounts(
  referentialCurrency: Currency,
  currencies: HashMap<Currency>,
  accounts: Account[],
  accountsTotals: HashMap<AccountsTotals>,
) {
  return accounts.map((account) => {
    const balance =
      accountsTotals && accountsTotals[account._id] ? accountsTotals[account._id].balance : null
    const refBalance =
      accountsTotals && accountsTotals[account._id] ? accountsTotals[account._id].refBalance : null

    const currencyCode = accountCurrencyCode(account, referentialCurrency, currencies)
    const currencyId = account.currencyId || referentialCurrency._id

    return {
      ...account,
      balance,
      refBalance,
      currencyCode,
      currencyId,
    }
  })
}
