import commonStyles from "src/frontend/scenes/onBoarding/inApp/components/InAppTopBar.module.less"
import React, { useEffect, useState } from "react"
import { RootState } from "src/types/State"
import {
  selectShouldDisplayDashboardOnBoarding,
  selectShouldDisplayMobileAppsBanner,
} from "src/frontend/scenes/onBoarding/inApp/selectors"
import { connect, useDispatch, useSelector } from "react-redux"
import DashboardOnBoarding from "src/frontend/scenes/onBoarding/inApp/components/DashboardOnBoarding"
import { mergeClasses } from "src/common/utils"
import EmptySpace from "src/frontend/scenes/onBoarding/inApp/components/EmptySpace"
import { selectIsPendingConnectionInProgress } from "src/frontend/scenes/integrations/newConnection/selectors"
import NewBankConnectionMessage from "src/frontend/scenes/integrations/newConnection/components/NewBankConnectionInfoMessage"
import { isAppBoard } from "src/common/environment"
import AccountsGrid from "src/frontend/scenes/dashboard/components/AccountsGrid"
import MobileAppsOnBoarding from "src/frontend/scenes/onBoarding/inApp/components/MobileAppsOnBoarding"
import { Transition } from "semantic-ui-react"
import {
  isPendingReconnect,
  selectPendingReconnectProvider,
} from "../../integrations/connections/selectors"
import { selectAccountsWithTotals } from "src/frontend/scenes/settings/accounts/selectors"
import { AccountWithCurrencyCode } from "src/types/Account"
import { isConnected, isRevoked } from "src/backend/accounts/helpers"
import { setPendingReconnectEnd } from "../../integrations/connections/actions"
import DashboardWSBanner from "./DashboardWSBanner"
import { selectUser } from "src/frontend/modules/user/selectors"
import { getWScampaignClosed, setWScampaignClosed } from "src/backend/db/localStorage"

function mapStateToProps(state: RootState) {
  return {
    accounts: selectAccountsWithTotals(state),
    showOnBoarding: selectShouldDisplayDashboardOnBoarding(state),
    isPendingConnection: selectIsPendingConnectionInProgress(state),
    isPendingReconnect: isPendingReconnect(state),
    pendingReconnectProvider: selectPendingReconnectProvider(state),
    showMobileAppsBanner: selectShouldDisplayMobileAppsBanner(state),
  }
}

/**
 * This function checks, whether the reconnect is still in progress.
 * @returns {boolean} Reconnect is in progress.
 */
function checkIfPendingReconnectContinue(accounts, pendingReconnectProvider) {
  let anyAccountsIsNotReconnected: boolean = false
  const reconnectProviderAccounts = accounts.filter((account) => {
    return account.reservedIntegrationConnection?.providerCode === pendingReconnectProvider
  })
  reconnectProviderAccounts.forEach((account) => {
    const accountIsNotActive = !account.reservedIntegrationConnection.isActive
    if (isConnected(account) && (accountIsNotActive || isRevoked(account))) {
      anyAccountsIsNotReconnected = true
    }
  })
  return anyAccountsIsNotReconnected
}

interface Props {
  accounts: AccountWithCurrencyCode[]
  showOnBoarding: boolean
  isPendingConnection: boolean
  isPendingReconnect: boolean
  pendingReconnectProvider: string // Provider code
  showMobileAppsBanner: boolean
}

function DashboardTopBar({
  accounts,
  showOnBoarding,
  showMobileAppsBanner,
  isPendingConnection,
  isPendingReconnect,
  pendingReconnectProvider,
}: Props) {
  const dispatch = useDispatch()
  const [showWSBanner, setShowWSBanner] = useState(true)
  const user = useSelector(selectUser)
  const WScampaignAllowedCountries = ["en", "fr", "de", "nl", "it", "es"]

  useEffect(() => {
    if (
      isPendingReconnect &&
      !checkIfPendingReconnectContinue(accounts, pendingReconnectProvider)
    ) {
      dispatch(setPendingReconnectEnd())
    }
  }, [accounts])

  const shouldShowWSBanner =
    WScampaignAllowedCountries.includes(user.countryCode.toLowerCase() ?? "en") &&
    !getWScampaignClosed()

  const handleWSBannerClose = () => {
    setShowWSBanner(false)
    setWScampaignClosed()
  }

  if (!isAppBoard()) {
    return (
      <>
        <div className={mergeClasses(commonStyles.topBar, commonStyles.accounts)}>
          <AccountsGrid />

          {shouldShowWSBanner && (
            <Transition
              visible={showWSBanner}
              duration={300}
              unmountOnHide
              mountOnShow
              animation="top-banner"
              directional
            >
              <div className={mergeClasses(commonStyles.topBar, commonStyles.walletTopBar)}>
                <DashboardWSBanner handleClose={() => handleWSBannerClose()} />
              </div>
            </Transition>
          )}
        </div>
      </>
    )
  } else {
    const somePanelIsShown =
      isPendingConnection || showOnBoarding || showMobileAppsBanner || isPendingReconnect

    return (
      <>
        <Transition
          visible={isPendingConnection || isPendingReconnect}
          animation="top-banner"
          duration={300}
          directional
          mountOnShow
          unmountOnHide
          transitionOnMount
        >
          <div className={mergeClasses(commonStyles.topBar)}>
            <NewBankConnectionMessage basic />
          </div>
        </Transition>

        <Transition
          visible={showOnBoarding}
          duration={300}
          unmountOnHide
          mountOnShow
          animation="top-banner"
          directional
        >
          <div className={commonStyles.topBar}>
            <DashboardOnBoarding />
          </div>
        </Transition>

        <Transition
          visible={showMobileAppsBanner}
          animation="top-banner"
          directional
        >
          <div className={mergeClasses(commonStyles.topBar, commonStyles.noBottom)}>
            <MobileAppsOnBoarding />
          </div>
        </Transition>

        {!somePanelIsShown && <EmptySpace />}
      </>
    )
  }
}

export default connect(mapStateToProps)(DashboardTopBar)
