import React from 'react';
import MarketingMessage from '../MarketingMessage';
// Helpers
import { isAppBoard } from 'src/common/environment';

// Assets
import styles from './MarketingBanner.module.less';
import walletLogo from '../../../../assets/images/wallet-logo-dark-bg.svg';
import boardLogo from '../../../../assets/images/kb/board-logo-bb-dark-bg.svg';
import { mergeClasses } from 'src/common/utils';

const MarketingBanner = () => {
  const appLogo = isAppBoard() ? boardLogo : walletLogo;
  const appName = isAppBoard() ? 'Wallet' : 'Board';

  const boardModifierClassName = isAppBoard() ? styles.marketingBannerBoard : '';

  return (
    <div className={mergeClasses(styles.marketingBanner, boardModifierClassName)}>
      <a
        className={styles.logo}
        href="https://budgetbakers.com/"
        target="_blank"
      >
        &nbsp;
      </a>
      <MarketingMessage />
      <img
        className={styles.appLogo}
        src={appLogo}
        alt={`${appName} logo`}
      ></img>
    </div>
  );
};

export default MarketingBanner;
