import { formatDate, formatNumber } from 'src/frontend/modules/intl'
import moment from 'moment'
import { formatAbbreviatedNumber } from 'src/frontend/modules/intl/i18n'
import { Currency } from 'src/types/Currency'
import { Canvas, Context, Dataset, Gradient } from 'src/frontend/components/chart/types'
import {
    DATE_LONG,
    NumberFormatOptions,
    YEAR_LONG,
    YEAR_MONTH_LONG,
    YEAR_SHORT,
} from 'src/frontend/modules/intl/formatOptions'
import { ChartData, ChartTooltipItem } from 'chart.js'
import { isUndefinedOrNull } from 'src/common/utils'
import { countPercentChange } from 'src/common/helpers'
import ReactDOMServer from 'react-dom/server'
import FormattedPercentageChange from 'src/frontend/modules/intl/components/FormattedPercentageChange'

export function shouldDisplayLabel(labelsCount: number, indexOfLabel: number, labelsPerChart: number): boolean {
    if (indexOfLabel === -1) {
        return false
    } else if (labelsCount <= labelsPerChart || indexOfLabel === 0) {
        return true
    } else {
        const step = Math.ceil(labelsCount / labelsPerChart) + 1
        return (indexOfLabel + 1) % step === 0
    }
}

export function getFormatOptions(labels: Array<string>): NumberFormatOptions {
    const labelsDifference = moment.duration(moment(labels[labels.length - 1]).diff(moment(labels[0])))
    const shouldDisplayYear = labelsDifference.asYears() > 1 || !moment(labels[0]).isSame(moment(), 'year')

    if (Math.round(labelsDifference.asMonths()) >= 12) {
        return YEAR_SHORT
    } else if (labelsDifference.asMonths() > 3) {
        return shouldDisplayYear ? YEAR_LONG : removeYear(YEAR_LONG)
    } else if (labelsDifference.asDays() > 14) {
        return shouldDisplayYear ? YEAR_LONG : removeYear(YEAR_LONG)
    } else {
        return shouldDisplayYear ? YEAR_MONTH_LONG : removeYear(YEAR_MONTH_LONG)
    }
}

function removeYear(formatOptions: NumberFormatOptions): NumberFormatOptions {
    const { year, ...optionsWithoutYear } = formatOptions

    return optionsWithoutYear
}

export function xAxisDateTick(labels: string[][], labelsPerChart: number) {
    const labelz = labels.map((label) => (Array.isArray(label) ? label[0] : label))

    const formatOptions = getFormatOptions(labelz)
    return (label) => {
        return shouldDisplayLabel(labelz.length, labelz.indexOf(label), labelsPerChart)
            ? formatDate(label, formatOptions)
            : null
    }
}

export function yAxisTick(value: number): string {
    return formatAbbreviatedNumber(value)
}

export function filterExistingTooltip(item: ChartTooltipItem, data: ChartData) {
    // show label only if it's truthy value
    return !!data.datasets[item.datasetIndex].label
}

export function tooltipTitle(tooltipItems: Array<ChartTooltipItem>, data) {
    const tooltipItem: ChartTooltipItem = tooltipItems[0]
    if (tooltipItem && data) {
        return data.datasets[tooltipItem.datasetIndex].label
    }
}

export function pieTooltipTitle(currency) {
    return (tooltipItems, data) => {
        const tooltipItem = tooltipItems[0]
        return `${formatNumber(data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index])} ${currency.code}`
    }
}

export function tooltipTextLabel(labels: Array<string>): (ChartTooltipItem) => string {
    // return just label text because default tooltip renders also value which is already displayed by tooltip title
    return (tooltipItem: ChartTooltipItem): string => {
        return labels[tooltipItem.index]
    }
}

export function tooltipPercentage(tooltipItem: ChartTooltipItem[], data: ChartData): string {
    // get the concerned dataset
    const dataset = data.datasets && data.datasets[tooltipItem[0].datasetIndex]
    // calculate the total of this data set
    const total =
        dataset &&
        dataset.data &&
        (dataset.data as number[]).reduce((acc: number, value: number) => {
            return acc + Math.abs(value)
        }, 0)

    // get the current items value
    const currentValue = dataset.data[tooltipItem[0].index] as number
    const percentage = Math.round((Math.abs(currentValue) / total) * 100)
    return `${percentage}%`
}

export function setBackgroundGradient([color1, color2]: [string, string]): Function {
    return (canvas: Canvas): Gradient => {
        const ctx: Context = canvas.getContext('2d')
        const grd: Gradient = ctx.createLinearGradient(150, 0, 150, 180)
        grd.addColorStop(0, color1)
        grd.addColorStop(1, color2)
        return grd
    }
}

function checkComparison(el) {
    const check = el?.comparisonIndex !== undefined
    return check
}

export function customLabel(
    chart,
    tooltipModel,
    labels: string[][],
    datasets: Array<Dataset>,
    currency: Currency,
    filterIndex?: number
) {
    // Tooltip Element
    let tooltipEl = document.getElementById('chartjs-tooltip')

    const dataPoints = tooltipModel.dataPoints && tooltipModel.dataPoints[0]

    const datasetIndex = dataPoints && dataPoints.datasetIndex
    const pointIndex = dataPoints && dataPoints.index

    /* 
    console.log('tooltipModel: ', tooltipModel)
    console.log('dataPoints: ', dataPoints)
    console.log('datasetIndex: ', datasetIndex)
    console.log('pointIndex: ', pointIndex)
    console.log('filterIndex: ', filterIndex)
    console.log('labels: ', labels)
    console.log('datasets: ', datasets)
    */
    // Create element on first render
    if (!tooltipEl) {
        tooltipEl = document.createElement('div')
        tooltipEl.id = 'chartjs-tooltip'
        document.body.appendChild(tooltipEl)
    }

    // Hide if no tooltip
    if (
        tooltipModel.opacity === 0 ||
        (!isUndefinedOrNull(datasetIndex) && !isUndefinedOrNull(datasets[datasetIndex].comparisonIndex))
    ) {
        tooltipEl.style.opacity = '0'
        tooltipEl.style.zIndex = '-1'
        return
    }

    // Set Text
    if (tooltipModel.body && pointIndex in labels) {
        const dataSetsCount = datasets.length
        const titleLines =
            //tooltipModel.title ||
            datasets.map((element) => {
                return element.label ? element.label : 'Unknown'
            })
        tooltipModel.bodyFontSize = 10
        tooltipModel.bodySpacing = 0

        const valueDataset = datasets.find((_d, index) => {
            return index === datasetIndex
        })

        const comparisonDataset = datasets.find((dataset) => {
            return !isUndefinedOrNull(dataset.comparisonIndex) && dataset.comparisonIndex === datasetIndex
        })

        tooltipEl.innerHTML = composeComparisonTooltip(
            datasets,
            valueDataset,
            comparisonDataset,
            titleLines,
            labels,
            datasetIndex,
            pointIndex,
            currency,
            filterIndex
        )
    }

    // `this` will be the overall tooltip
    const position = chart.canvas.getBoundingClientRect()

    // Display, position, and set styles for font
    tooltipEl.style.opacity = '1'
    tooltipEl.style.zIndex = '1'
    if (position.left + window.pageXOffset + tooltipModel.caretX + tooltipEl.clientWidth + 10 > window.innerWidth) {
        tooltipEl.style.left = `${
            position.left + window.pageXOffset + tooltipModel.caretX - tooltipEl.clientWidth - 10
        }px`
    } else {
        tooltipEl.style.left = `${position.left + window.pageXOffset + tooltipModel.caretX + 10}px`
    }

    if (position.top + window.pageYOffset + tooltipModel.caretY + tooltipEl.clientHeight > window.innerHeight) {
        tooltipEl.style.top = `${
            position.top + window.pageYOffset + tooltipModel.caretY + 10 - tooltipEl.clientHeight
        }px`
    } else {
        tooltipEl.style.top = `${position.top + window.pageYOffset + tooltipModel.caretY + 10}px`
    }
}

function composeComparisonTooltip(
    datasets: Array<Dataset>,
    valueDataset,
    comparisonDataset,
    titleLines,
    labels: string[][],
    datasetIndex,
    pointIndex: number,
    currency: Currency,
    filterIndex: number = null
): string {
    let innerHtml = ''
    const isComparisonDataset = comparisonDataset && !isUndefinedOrNull(comparisonDataset.data[pointIndex])
    if (valueDataset) {
        const changeValue =
            !isUndefinedOrNull(comparisonDataset) &&
            !isUndefinedOrNull(comparisonDataset.data[pointIndex]) &&
            !isUndefinedOrNull(valueDataset.data[pointIndex])
                ? countPercentChange(comparisonDataset.data[pointIndex], valueDataset.data[pointIndex])
                : null

        const changeElement = !isUndefinedOrNull(changeValue)
            ? ReactDOMServer.renderToString(
                  FormattedPercentageChange({
                      change: changeValue,
                      simple: true,
                  })
              )
            : ''

        if (!isComparisonDataset) {
            // header - display date from first available dataPoint
            const valueDate = formatDate(moment(labels[pointIndex][0]).toDate(), DATE_LONG)
            innerHtml += `<div class="header groupDate">${valueDate}</div>`
        }

        datasets.forEach((element, index) => {
            const isComparison = checkComparison(element)
            const title = titleLines[index]
            if ((isComparison && index == 0) || !isComparison) {
                if (
                    (filterIndex === 0 && filterIndex === index) ||
                    (filterIndex && filterIndex === index) ||
                    filterIndex === null
                ) {
                    const valueDate = formatDate(moment(labels[pointIndex][index]).toDate(), DATE_LONG)
                    const value = formatNumber(element.data[pointIndex], {
                        style: 'currency',
                        currency: currency.code,
                        currencyDisplay: 'symbol',
                    })
                    const style = `
                background: ${element.pointBackgroundColor};
                border-color: ${element.borderColor};
                border-width: 2px;
                `
                    innerHtml += isComparisonDataset
                        ? composeComparisonTooltipElement(style, title, valueDate, value, changeElement)
                        : composeTooltipElement(style, title, value)
                }
            }
        })
    }

    if (isComparisonDataset) {
        innerHtml += '<div class="comparisonElement">'
        innerHtml += '<div class="data-row">'
        const bullet = '<div class="color-bullet"></div>'
        const comparisonDate = formatDate(moment(labels[pointIndex][datasetIndex + 1]).toDate(), DATE_LONG)
        const comparisonValue = formatNumber(comparisonDataset.data[pointIndex], {
            style: 'currency',
            currency: currency.code,
            currencyDisplay: 'symbol',
        })
        const date = `<div class="date">${comparisonDate}</div>`
        innerHtml += bullet
        innerHtml += date
        innerHtml += `<div class="number-value">${comparisonValue}</div>`
        innerHtml += '</div>' // end data-row
        innerHtml += '</div>' // end comparisonElement
    }

    return (innerHtml += '</div>')
}

function composeComparisonTooltipElement(style, title, valueDate, value, changeElement) {
    // comparsion dataset renders only once
    let elementHtml = ''
    elementHtml += `<div class="group">`
    elementHtml += `<div class="title"><span>${title}</span>${changeElement}</div>`
    elementHtml += '<div class="data-row">'
    elementHtml += `<div class="color-bullet" style="${style}"></div>`
    elementHtml += `<div class="date">${valueDate}</div>`
    elementHtml += `<div class="number-value">${value}</div>`
    elementHtml += `</div>` // dataRow end
    elementHtml += `</div>` // group end

    return elementHtml
}

function composeTooltipElement(style, title, value) {
    // comparsion dataset renders only once
    let elementHtml = ''
    elementHtml += `<div class="group">`
    elementHtml += `<div class="data-row combined-row">`
    elementHtml += `<div class="color-bullet" style="${style}"></div>`
    elementHtml += `<div class="title"><span>${title}</span></div>`
    elementHtml += `<div class="number-value">${value}</div>`
    elementHtml += `</div>` // combined-row end
    elementHtml += `</div>` // group end

    return elementHtml
}
