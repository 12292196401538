import React from 'react'
import AnimatedBarTable from 'src/frontend/components/AnimatedBarTable/AnimatedBarTable'
import { AnimatedBarTableData } from 'src/frontend/components/AnimatedBarTable/types'
import { FormattedMessage, FormattedMoney } from 'src/frontend/modules/intl'
import { WidgetView } from 'src/frontend/scenes/dashboard/types'
import Widget from 'src/frontend/scenes/dashboard/components/Widget/Widget'
import BoardCashFlowLegend from 'src/frontend/scenes/dashboard/widgets/BoardCashFlowBar/BoardCashFlowLegend'
import { Bar, BarsData } from 'src/frontend/scenes/dashboard/widgets/BoardCashFlowBar/types'
import { widgetHasDeepEqualProps } from 'src/frontend/scenes/dashboard/helpers'


type Props = {
  widget: WidgetView & { data: BarsData },
}

function BoardCashFlowBar({ widget }: Props) {
  const { data, filter, options } = widget
  if (!data || !filter) {
    return <Widget.Placeholder />
  }

  const tableData = convertBoardCashFlowToBarTableData(data)
  const maxValue = getMaxValue(data.bars)

  return (
    <Widget>
      <Widget.Header title={widget.title} />
      <Widget.Summary
        displayWholeDateRange
        period={filter.period}
        value={data.summary.value}
        currencyCode={data.referentialCurrency.code}
        intervalType={options.relativeIntervalValue}
        changeValue={data.summary.change}
      />
      <Widget.Content>
        <AnimatedBarTable
          // if both values are 0, then 1 is used as total, to show bar as 0% of both values
          total={maxValue}
          data={tableData}
        />
        <BoardCashFlowLegend data={data} />
      </Widget.Content>
    </Widget>
  )
}

function getMaxValue(bars: Bar[]): number {

  return bars?.reduce((acc, bar) => {
    return Math.max(acc, bar.values.reduce((barAcc, barValue) => barAcc + barValue.value, 0))
  }, 0)
}

function getTotalValue(bars: Bar[]): number {
  return bars?.reduce((acc, bar) => {
    return acc + bar.values.reduce((barAcc, barValue) => barAcc + barValue.value, 0)
  }, 0)
}

function convertBoardCashFlowToBarTableData(data: BarsData): Array<AnimatedBarTableData> {
  const { bars, referentialCurrency } = data

  const totalValue = getTotalValue(bars)

  return bars.map((bar: Bar) => {
    const valuesSum = bar.values.reduce((acc, value) => acc + value.value, 0)

    return {
      id: bar.label,
      values: bar.values.map((barValue) => ({
        ...barValue,
        valueLabel: <FormattedMoney value={barValue.value} currency={referentialCurrency.code} />,
      })),
      total: totalValue,
      valueChange: bar.change,
      invertedChange: bar.invertedChange,
      divided: true,
      label: <FormattedMessage id={bar.label} />,
      valueLabel: <FormattedMoney value={valuesSum} currency={referentialCurrency.code} />,
    }
  })
}

export default React.memo(BoardCashFlowBar, widgetHasDeepEqualProps)
