import React, { ComponentType } from 'react'
import { RootState } from 'src/types/State'
import {
  selectOnBoardingStage,
  selectOnBoardingStatus,
  selectPreferBankIntegration,
} from 'src/frontend/scenes/onBoarding/firstRun/selectors'
import { connect } from 'react-redux'
import { OnBoardingStage } from 'src/frontend/scenes/onBoarding/firstRun/enums'
import OnBoardingLayout from 'src/frontend/scenes/onBoarding/firstRun/components/OnBoardingLayout'
import BaseCurrencyForm from 'src/frontend/scenes/onBoarding/firstRun/baseCurrency/components/BaseCurrencyForm'
import AccountNameForm from 'src/frontend/scenes/onBoarding/firstRun/firstAccountName/components/AccountNameForm'
import { OnBoardingStatus } from 'src/backend/onBoarding/types'
import CashBalance from 'src/frontend/scenes/onBoarding/firstRun/cashBalance/components/CashBalance'
import { selectUser } from 'src/frontend/modules/user/selectors'
import { User } from 'src/types/User'
import KBAccountIntegration from 'src/frontend/scenes/onBoarding/firstRun/kb/components/KBAccountIntegration'
import { isAppBoard } from 'src/common/environment'
import { isKBUser } from 'src/backend/user/service'
import { selectIntegrationStatus } from 'src/frontend/scenes/integrations/newConnection/selectors'
import { NewIntegrationsStatus } from 'src/frontend/scenes/integrations/enums'


interface Props {
  stage: OnBoardingStage,
  status: OnBoardingStatus,
  preferBankIntegration: boolean
  integrationStatus: NewIntegrationsStatus,
  user: User
}

function mapStateToProps(state: RootState) {
  return {
    stage: selectOnBoardingStage(state),
    status: selectOnBoardingStatus(state),
    preferBankIntegration: selectPreferBankIntegration(state),
    integrationStatus: selectIntegrationStatus(state),
    user: selectUser(state),
  }
}

function OnBoardingPage({ integrationStatus, stage, user, status, preferBankIntegration }: Props) {
  const Component: ComponentType<any> = getOnBoardingComponent(stage)
  const stages = getStagesDefinition(user, preferBankIntegration)

  if (Component !== null) {
    return (
      <OnBoardingLayout
        hideContentHeader={integrationStatus === NewIntegrationsStatus.PERSONAL_INFORMATION}
        stages={stages}
        stage={stage}
        status={status}
      >
        <Component stage={stage} />
      </OnBoardingLayout>
    )
  }

  return null
}

function getStagesDefinition(user: User, preferBankIntegration: boolean): OnBoardingStage[] {
  if (isAppBoard() && isKBUser(user)) {
    return [
      OnBoardingStage.REGISTRATION,
      OnBoardingStage.BASE_CURRENCY,
      preferBankIntegration ? OnBoardingStage.KB_BANK_INTEGRATION : OnBoardingStage.ACCOUNT_NAME,
    ]
  }

  return isAppBoard()
    ? [
      OnBoardingStage.BASE_CURRENCY,
      OnBoardingStage.ACCOUNT_NAME,
    ]
    : [
      OnBoardingStage.BASE_CURRENCY,
      OnBoardingStage.CASH_BALANCE,
    ]
}

function getOnBoardingComponent(stage: OnBoardingStage) {
  switch (stage) {
    default: {
      return null
    }

    case OnBoardingStage.BASE_CURRENCY: {
      return BaseCurrencyForm
    }

    case OnBoardingStage.ACCOUNT_NAME: {
      return AccountNameForm
    }

    case OnBoardingStage.CASH_BALANCE: {
      return CashBalance
    }

    case OnBoardingStage.KB_BANK_INTEGRATION: {
      return KBAccountIntegration
    }
  }
}

export default connect(mapStateToProps)(OnBoardingPage)
