import { receiveDashboardWidget } from 'src/frontend/scenes/dashboard/actions'
import { selectDashboardFilter, selectDashboardWidgets } from 'src/frontend/scenes/dashboard/selectors'
import { WidgetType } from 'src/frontend/scenes/dashboard/types'
import { FilterType } from 'src/types/Filter'
import { Id } from 'src/types/CouchDb'
import { findWidgetById } from 'src/frontend/scenes/dashboard/helpers'
import { RelativeIntervalType } from 'src/backend/enums'
import { getNextPeriodFilter } from 'src/backend/filters/helpers'
import { isRelativeInterval } from 'src/backend/time/time'
import { RecordCategoryLevel } from 'src/backend/categories/enums'
import { fetchPieAndBarSpending } from 'src/backend/dashboard/service'
import {
  CATEGORY_LEVELS,
  isPossibleToChangeLevel,
} from 'src/frontend/scenes/dashboard/widgets/PieAndBarSpending/helpers'
import { HashMap } from 'src/types/common'

export function changeLevel(
  id: Id,
  categoryId: number | string,
  categoryLevel: RecordCategoryLevel,
  direction: 1 | -1,
) {
  return async (dispatch: Function, getState: Function) => {
    const dashboardFilter: FilterType = selectDashboardFilter(getState())
    const widgets: HashMap<WidgetType> = selectDashboardWidgets(getState())

    const widget = findWidgetById(widgets, id)

    if (!widget) {
      return null
    }

    const mergedFilter = { ...dashboardFilter, ...widget.filter }
    const filter = isRelativeInterval(mergedFilter.period.interval)
    && widget.options.relativeIntervalValue === RelativeIntervalType.NEXT
      ? getNextPeriodFilter(mergedFilter)
      : mergedFilter


    // move to the next category level
    const nextCategoryLevel = isPossibleToChangeLevel(categoryLevel, direction)
      ? CATEGORY_LEVELS[CATEGORY_LEVELS.indexOf(categoryLevel) + direction]
      : categoryLevel

    const options = {
      ...widget.options,
      categoryLevel: nextCategoryLevel,
    }

    const data = await fetchPieAndBarSpending(filter, options, widget.variant, categoryId)

    const updatedWidget = {
      ...widget,
      data,
      options,
    }

    return dispatch(receiveDashboardWidget(updatedWidget))
  }
}
