import { createDefaultDocument, getEntityReferences } from "src/backend/common/service"
import * as commonRepository from "src/backend/common/repository"
import { inMemoryTableNames } from "src/backend/db/inMemorySqlDbSchemaBuilder"
import * as repository from "./repository"
import { getColors, getDefaultLabelColor } from "src/backend/colors/colors"
import { labelConverter } from "src/backend/converters/labelConverter"
import { User } from "src/types/User"
import { Label } from "src/types/Label"
import { v4 as uuid } from "uuid"
import { Id } from "src/types/CouchDb"
import { LabelFormValues } from "src/frontend/scenes/settings/labels/types"

export async function saveLabel(formValues: Partial<LabelFormValues>, user: User, labelId?: Id) {
  const labelCandidate = labelId ? await repository.findById(labelId) : await getDefaultLabel(user)

  const labelDocument: Label = {
    ...labelCandidate,
    ...formValues,
  }

  return commonRepository.updateBulk([labelDocument], inMemoryTableNames.LABEL, labelConverter())
}

export async function getDefaultLabel(user: User): Promise<Label> {
  const modelType = inMemoryTableNames.LABEL
  const id = `-${modelType}_${uuid()}`
  const position =
    ((await commonRepository.findHighestPosition(inMemoryTableNames.LABEL)) || 0) + 1000
  return {
    ...createDefaultDocument(id, modelType, user),
    name: "",
    color: getDefaultLabelColor(),
    position,
  }
}

export async function removeLabelFromReferences(label) {
  const references = await getEntityReferences(label)
  return removeLabelReferences(references, label)
}

function removeLabelReferences(references, label: Label) {
  const entitiesWithoutReferences = references.map((entity) => {
    const { labels } = entity
    const modifiedLabels = labels.filter((labelId) => labelId !== label._id)
    return {
      ...entity,
      labels: modifiedLabels,
    }
  })
  return commonRepository.updateBulk(entitiesWithoutReferences)
}

export async function removeLabel(labelId: Id) {
  const label = await repository.findById(labelId)

  await removeLabelFromReferences(label)
  return commonRepository.removeBulk([label])
}

export function fetchLabels(): Promise<Label[]> {
  return repository.getAllLabels()
}

export async function getValidationDependencies() {
  const labels = await repository.findAllAsHashMap()
  const colors = getColors()

  return {
    labels,
    colors,
  }
}
