import React from 'react'
import { connect } from 'react-redux'
import Joyride, { EVENTS, STATUS, Step } from 'react-joyride'
import { formatMessage } from 'src/frontend/modules/intl/i18n'
import { TourGuideSteps } from 'src/frontend/components/TourGuide'
import { selectTourGuides } from 'src/frontend/modules/configures/tourGuides/selectors'
import { saveTourGuide } from 'src/frontend/modules/configures/tourGuides/actions'
import { RootState } from 'src/types/State'
import { FormattedMessage } from 'src/frontend/modules/intl'


const mapStateToProps = (state: RootState) => {
  return {
    tourGuides: selectTourGuides(state),
  }
}

const mapDispatchToProps = (dispatch: Function) => {
  return {
    onTourFinished(tourGuideName) {
      dispatch(saveTourGuide(tourGuideName))
    },
  }
}

type Props = {
  name: string,
  children: React.ReactNode,
  tourGuides: string[],
  onTourFinished: Function,
}

const options = {
  primaryColor: '#007aff',
}

function TourGuideWrapper({ name, children, tourGuides, onTourFinished }: Props) {
  const DEFAULT_STEP_PROPERTIES = {
    position: 'right',
    disableBeacon: true,
  }

  const steps = TourGuideSteps[name]
  const aggregatedSteps = steps.map((stepProperties: Step) => ({
    ...DEFAULT_STEP_PROPERTIES,
    ...stepProperties,
    title: <FormattedMessage id={stepProperties.title as string} />,
    content: <FormattedMessage id={stepProperties.content as string} />,
  }))

  const shouldRunTourGuide = !tourGuides.includes(name)

  return (
    <>
      <Joyride
        key="tourGuide"
        continuous
        run={shouldRunTourGuide}
        steps={aggregatedSteps}
        styles={{ options }}
        locale={{
          back: formatMessage('tour_guide.nav.prev'),
          close: formatMessage('tour_guide.nav.close'),
          last: formatMessage('tour_guide.nav.finish_got_it'),
          next: formatMessage('tour_guide.nav.next'),
          skip: formatMessage('tour_guide.nav.skip'),
        }}
        callback={(callback) => {
          return callback.status === STATUS.FINISHED && callback.type === EVENTS.TOUR_END && onTourFinished(name)
        }}
      />
      {children}
    </>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(TourGuideWrapper)
