import { Const } from "src/types/common"

export const Platform = {
  WEB: "web",
}

export enum RecordType {
  INCOME,
  EXPENSE,
}

export enum CategoryType {
  INCOME,
  EXPENSE,
}

export enum AssetType {
  STOCK = "stock",
  ETF = "etf",
}

export enum RecordIntegrationProviderSource {
  BUDGETBAKERS = "budgetbakers",
  SALTEDGE = "saltedge",
  SALTEDGE_PARTNERS = "saltedge_partners",
  CSAS = "csas",
  YODLEE = "yodlee",
  YODLEE_FASTLINK = "yodlee_fastlink",
  FASTLINK_COBRAND = "fastlink_cobrand",
}

// 0-cash, 1-debitCard, 2-creditCard, 3-transfer, 4-voucher, 5-mobilePayment, 6-webPayment
export enum PaymentType {
  CASH = 0,
  DEBIT_CARD = 1,
  CREDIT_CARD = 2,
  TRANSFER = 3,
  VOUCHER = 4,
  MOBILE_PAYMENT = 5,
  WEB_PAYMENT = 6,
}

export const UserPaymentTypes = getUserPaymentTypes()

function getUserPaymentTypes() {
  const { CASH, DEBIT_CARD, CREDIT_CARD, TRANSFER, VOUCHER, MOBILE_PAYMENT, WEB_PAYMENT } =
    PaymentType
  return { CASH, DEBIT_CARD, CREDIT_CARD, TRANSFER, VOUCHER, MOBILE_PAYMENT, WEB_PAYMENT }
}

export enum RecordState {
  RECONCILED = 0,
  CLEARED = 1,
  UNCLEARED = 2,
  VOID = 3,
  WAIT_FOR_ASSIGN = 4,
}

export const UserRecordState = getUserRecordStates()

export const IgnoredRecordStates = [RecordState.VOID, RecordState.WAIT_FOR_ASSIGN]

function getUserRecordStates() {
  const { RECONCILED, CLEARED, UNCLEARED } = RecordState
  return { RECONCILED, CLEARED, UNCLEARED }
}

export enum Interval {
  WEEK = "week",
  MONTH = "month",
  YEAR = "year",
  TODAY = "1-days",
  RELATIVE_7_DAYS = "7-days",
  RELATIVE_30_DAYS = "30-days",
  RELATIVE_90_DAYS = "90-days",
  RELATIVE_12_MONTHS = "12-months",
  ALL_THE_TIME = "all-the-time",
  CUSTOM = "custom",
}

export enum RelativeIntervalType {
  NEXT = "next",
  LAST = "last",
  RELATIVE = "relative",
}

export enum IntervalGranularityType {
  DAY = "day",
  WEEK = "week",
  MONTH = "month",
  ALL = "all",
}

export enum IntervalCompareToType {
  NONE,
  PREVIOUS_WEEK,
  PREVIOUS_MONTH,
  PREVIOUS_QUARTER,
  PREVIOUS_YEAR,
}

export const CompareToValue = {
  [IntervalCompareToType.PREVIOUS_WEEK]: "7-days",
  [IntervalCompareToType.PREVIOUS_MONTH]: "1-months",
  [IntervalCompareToType.PREVIOUS_QUARTER]: "3-months",
  [IntervalCompareToType.PREVIOUS_YEAR]: "1-years",
}

// 0-general, 1-cash, 2-current, 3-credit, 4-saving, 5-bonus, 6-insurance, 7-investment, 8-loan, 9-mortgage
export enum AccountType {
  GENERAL = 0,
  CASH = 1,
  CURRENT_ACCOUNT = 2,
  CREDIT_CARD = 3,
  SAVING_ACCOUNT = 4,
  BONUS = 5,
  INSURANCE = 6,
  INVESTMENT = 7,
  LOAN = 8,
  MORTGAGE = 9,
  OVERDRAFT = 10,
}

export const ManualAccountTypes = [
  AccountType.GENERAL,
  AccountType.CASH,
  AccountType.CURRENT_ACCOUNT,
  AccountType.CREDIT_CARD,
  AccountType.OVERDRAFT,
  AccountType.SAVING_ACCOUNT,
  AccountType.BONUS,
  AccountType.INSURANCE,
  AccountType.INVESTMENT,
  AccountType.LOAN,
  AccountType.MORTGAGE,
]

export const SyncedAccountTypes = [
  AccountType.GENERAL,
  AccountType.CURRENT_ACCOUNT,
  AccountType.CREDIT_CARD,
  AccountType.OVERDRAFT,
  AccountType.SAVING_ACCOUNT,
  AccountType.BONUS,
  AccountType.INSURANCE,
  AccountType.INVESTMENT,
  AccountType.LOAN,
  AccountType.MORTGAGE,
]

export enum FilterRecordType {
  INCOME = "income",
  EXPENSE = "expense",
  TRANSFER = "transfer",
}

export const AndroidFilterRecordType = {
  BOTH: 0,
  INCOME: 1,
  EXPENSE: 2,
}

export const AndroidFilterTransfers = {
  INCLUDE: 0,
  EXCLUDE: 1,
  ONLY: 2,
}

export enum RefObjectType {
  STANDING_ORDER = 0,
  DEBT = 1,
  FOURSQUARE_VENUE_ID = 2,
}

export enum SystemLabelType {
  EMO_NEGATIVE = 0,
  EMO_NEUTRAL = 1,
  EMO_POSITIVE = 2,
  GOAL = 3,
}

export enum BalanceDisplayType {
  CREDIT_BALANCE = 0,
  AVAILABLE_CREDIT = 1,
}

/**
 * Records import columns, sync code with ribeez.ImportMappingAttributeType.
 * Code duplication because of lack of code completion when reexported from proto buffers.
 */
export const ImportMappingAttributeType = {
  /** Unset(-1) special type which indicates that attribute should be unassigned */
  UNSET: -1,
  /** Currency code (0) */
  CURRENCYCODE: 0,
  /** Amount (1) amount or income when income/expense are in separate columns */
  AMOUNT: 1,
  /** Note (2) */
  NOTE: 2,
  /** Record date (3) */
  RECORDDATE: 3,
  /** Payee (4) */
  PAYEE: 4,
  /** Unknown (5) column attribute was not recognized */
  UNKNOWN: 5,
  /** Expense (6) */
  EXPENSE: 6,
  /** Fee (7) */
  FEE: 7,
  /** Category (8) */
  CATEGORYMAPPING: 8,
  /** Income (1000) special internal type: alias for AMOUNT when income/expense are in separate columns */
  INCOME: 1000,
}
/**
 * Ribeez enum ImportErrorType
 */
export const ImportErrorType = {
  /** ItemNotFound: 0 */
  ITEM_NOT_FOUND: 0,

  /** FileNotFound: 1 */
  FILE_NOT_FOUND: 1,

  /** CurrencyNotFound: 2 */
  CURRENCY_NOT_FOUND: 2,

  /** SettingsNotFound: 3 */
  SETTINGS_NOT_FOUND: 3,

  /** Conflict: 4 */
  CONFLICT: 4,

  /** PreconditionFailed: 5 */
  PRECONDITION_FAILED: 5,

  /** InternalError: 6 */
  INTERNAL_ERROR: 6,

  /** MissingRequiredField: 7 */
  MISSING_REQUIRED_FIELD: 7,

  /** BadDelimiter: 8 */
  BAD_DELIMITER: 8,

  /** NumberFormat: 9 */
  NUMBER_FORMAT: 9,

  /** DateFormat: 10 */
  DATE_FORMAT: 10,

  /** CurrencyFormat: 11 */
  CURRENCY_FORMAT: 11,

  /** GenerateEmailError: 12 */
  GENERATE_EMAIL_ERROR: 12,

  /** SavedSettingsNotFound: 13 */
  SAVED_SETTINGS_NOT_FOUND: 13,

  /** IncomeExpenseFilled: 14 */
  INCOME_EXPENSE_FILLED: 14,

  /** Fake error type, only to support collapsing error title */
  MULTIPLE_ERROR_TYPE: -1,
}

/**
 * Ribeez enum ImportInitResultType
 */
export const ImportInitResultType = {
  FIRST_TIME: 0,
  LAST_SUCCESS: 1,
  LAST_ERROR: 2,
}

export enum RepeatType {
  NEVER = "never",
  DAILY = 3,
  WEEKLY = 2,
  MONTHLY = 1,
  YEARLY = 0,
  CUSTOM = "custom",
}

export function enumValues(enumType: Const): any {
  return Object.values(enumType).filter((value) => typeof value !== "function")
}

export function enumAsArray(object: Const): Array<{ key: string; value: any }> {
  return Object.keys(object)
    .filter((key) => typeof object[key] !== "function")
    .map((key) => ({ key, value: object[key] }))
}

export const ConfigureKey = {
  PIN: "pin_to_app",
  WEBAPP_TOURGUIDE: "webapp_tourguide",
  HIDDEN_ENVELOPES: "hidden_envelopes",
}
