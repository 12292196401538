import { useEffect } from 'react'
import Timeout = NodeJS.Timeout
import { getBackendUrl } from 'src/common/environment'

export function useDetectOauthTabClose(OAuthWindowRef, callback) {
  useEffect(() => {
    if (OAuthWindowRef.current) {
      let timeout: Timeout
      const handleTabClose = () => {
        timeout = setTimeout(() => {
          if (OAuthWindowRef.current.closed) {
            callback()
          }
        }, 100)
      }

      window.addEventListener('focus', handleTabClose)

      return () => {
        clearTimeout(timeout)
        window.removeEventListener('focus', handleTabClose)
      }
    }
  }, [OAuthWindowRef.current])
}


export function useOauthTabCallback(OAuthWindowRef, callback) {
  useEffect(() => {
    const handleMessage = (message) => {
      if (message.origin === getBackendUrl()) {
        message && message.data && callback(JSON.parse(message.data))
        OAuthWindowRef.current.close()
      }
    }
    window.addEventListener('message', handleMessage)

    return function cleanup() {
      window.removeEventListener('message', handleMessage)
    }
  }, [])
}
