import React from 'react'
import { IntegrationProviders } from 'src/frontend/scenes/integrations/providersList/types'
import { getFormComponent } from 'src/frontend/scenes/integrations/helpers'
import './MFAFormGroup.less'
import { FormAttributeFields } from 'src/frontend/scenes/integrations/types'
import IntegrationRecipeParam = IntegrationProviders.IntegrationRecipe.IntegrationRecipeParam

interface Props {
  params: IntegrationRecipeParam[]
  title?: string
  values: FormAttributeFields
  onChange: (
    field: string,
    value: string | string[] | number | boolean,
    shouldSubmit?: boolean,
  ) => void
}

export default function MFAFormGroup({ params, title, values, onChange }: Props) {
  const formFields = params.map((param) => {
    const FormComponent = getFormComponent(param.type)
    return (
      <FormComponent
        key={param.id}
        value={
          isStringArray(values.value)
            ? values.value[0]
            : values[param.id]
            ? values[param.id].value
            : param.value
        }
        param={param}
        onChange={onChange}
      />
    )
  })

  return (
    <div className="integration-form-group">
      {title ? <h3>{title}</h3> : null}
      {formFields}
    </div>
  )
}

MFAFormGroup.defaultProps = {
  title: undefined,
}

function isStringArray(value: any): boolean {
  return Array.isArray(value) && value.every((item) => typeof item === "string")
}