import { Action } from 'src/types/common'
import { ContactListOrderBy } from 'src/frontend/scenes/contacts/contactList/enums'

export const CONTACT_LIST_CHANGE_ORDER = 'CONTACT_LIST_CHANGE_ORDER'


export function changeOrder(orderBy: ContactListOrderBy): Action<ContactListOrderBy> {
  return {
    type: CONTACT_LIST_CHANGE_ORDER,
    payload: orderBy,
  }
}
