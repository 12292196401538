import { Action } from 'src/types/common'
import * as billing from 'src/backend/rest/backend/billing'
import { isAuthorizationError } from 'src/common/helpers'
import { expireUser } from 'src/frontend/modules/user/actions'
import * as logger from 'src/common/logger'
import { showError } from 'src/frontend/scenes/app/actions'
import { DEFAULT_ERROR_MESSAGE_ID } from 'src/frontend/Error/actions'
import { BraintreeTransaction } from 'src/frontend/scenes/billing/transactionHistory/types'

export const TRANSACTION_HISTORY_FETCH_START = 'TRANSACTION_HISTORY_FETCH_START'
export const TRANSACTION_HISTORY_FETCH_SUCCESS = 'TRANSACTION_HISTORY_FETCH_SUCCESS'
export const TRANSACTION_HISTORY_FETCH_ERROR = 'TRANSACTION_HISTORY_FETCH_ERROR'


export function fetchTransactionHistory() {
  return async (dispatch: Function) => {
    try {
      dispatch(fetchTransactionHistoryStart())
      const { transactions } = await billing.fetchTransactions()
      dispatch(fetchTransactionHistorySuccess(transactions))
    } catch (error) {
      if (isAuthorizationError(error)) {
        dispatch(expireUser())
        return
      } else {
        logger.captureException(error, 'billing.transactionHistory.fetchTransactionHistory')
        dispatch(showError([DEFAULT_ERROR_MESSAGE_ID, 'app.error.we_are_fixing_suffix']))
      }
    }
  }
}

export function fetchTransactionHistoryStart(): Action {
  return {
    type: TRANSACTION_HISTORY_FETCH_START,
  }
}

export function fetchTransactionHistorySuccess(transactions: BraintreeTransaction[]): Action<BraintreeTransaction[]> {
  return {
    type: TRANSACTION_HISTORY_FETCH_SUCCESS,
    payload: transactions,
  }
}
