import moment from 'moment'
import { getTermsOfServices } from 'src/backend/rest/gdpr'
import { grantGeneralToSConsent } from 'src/backend/rest/backend'
import { captureException } from 'src/common/logger'
import { showError } from 'src/frontend/scenes/app/actions'

export const OPEN_CONSENT_MODAL = 'gdpr/OPEN_CONSENT_MODAL'
export const GRANT_CONSENT = 'gdpr/GRANT_CONSENT'
export const CLOSE_CONSENT_MODAL = 'gdpr/CLOSE_CONSENT_MODAL'
export const CLOSE_CONFIRM_MODAL = 'gdpr/CLOSE_CONFIRM_MODAL'


export function grantConsent() {
  return async dispatch => {
    try {
      await grantGeneralToSConsent()
      return dispatch({ type: GRANT_CONSENT })
    } catch (error) {
      captureException(error, "Can't grant consent")
      return dispatch(showError())
    }
  }
}

export function closeConsentModal() {
  // Temporary condition: user logged in after the date when GDPR take effect will se new ToS just once
  // and then automatically accepts it by closing the modal, generally by still using the Wallet.
  const gdprTakeEffect = moment().isAfter(moment('2018-05-25'))
  if (!gdprTakeEffect) {
    return { type: CLOSE_CONSENT_MODAL }
  } else {
    return grantConsent()
  }
}

export function closeConfirmModal() {
  return { type: CLOSE_CONFIRM_MODAL }
}

export function openGdprConsentModal() {
  return async dispatch => {
    try {
      // inject target="_blank" to stay in the Wallet if the user clicks a link
      const termsOfServicesHtml = (await getTermsOfServices()).replace(/<a/g, '<a target="_blank"')
      return dispatch({ type: OPEN_CONSENT_MODAL, payload: { termsOfServicesHtml } })
    } catch (error) {
      captureException(error, "Can't open Terms of Service modal window")
      return dispatch(showError())
    }
  }
}
