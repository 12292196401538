import { RootState } from 'src/types/State'
import { createSelector } from 'reselect'
import { selectAccountsFilter } from 'src/frontend/scenes/accounts/filter/selectors'
import { matchesFilter } from 'src/backend/common/service'
import { matchLatinized } from 'src/common/utils'
import { AccountWithCurrencyCode } from "src/types/Account"
import { AccountsListOrderBy } from "src/frontend/scenes/accounts/accountsList/enums"
import { AccountListState } from "src/frontend/scenes/accounts/accountsList/reducer"
import { selectAccounts } from "src/frontend/scenes/accounts/selectors"
import { selectAccountsWithTotals } from "src/frontend/scenes/settings/accounts/selectors"
import { AccountsTotals } from "src/frontend/scenes/dashboard/types"

export const selectAccountsList = (state: RootState): AccountListState =>
  selectAccounts(state).accountList
export const selectOrderBy = (state: RootState): AccountsListOrderBy =>
  selectAccountsList(state).order

export const selectAccountListAccounts: (
  RootState,
) => (AccountWithCurrencyCode & AccountsTotals)[] = createSelector(
  [selectAccountsWithTotals, selectOrderBy, selectAccountsFilter],
  (contacts, orderBy, filter) => {
    // value filters
    const virtualFilter = {}

    return contacts
      .filter(matchesFilter(virtualFilter))
      .filter((contact) => !filter.fulltext || matchLatinized(contact.name, filter.fulltext))
      .sort(createSortFunction(orderBy))
  },
)

function createSortFunction(orderBy: AccountsListOrderBy) {
  const orderByParams = orderBy.split('-')
  const attribute = orderByParams[0]
  const direction = orderByParams[1]

  const orderByFunction = createOrderByFunction(attribute, direction)

  return (a, b) => orderByFunction(a, b)
}

function createOrderByFunction(attribute: string | number, direction: string | number) {
  return (a, b) => {
    if (attribute === 'name') {
      const [nameA, nameB]: [string, string] = direction === 'ASC'
        ? [a[attribute], b[attribute]]
        : [b[attribute], a[attribute]]

      return new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' }).compare(nameA, nameB)
    }
    const [valueA, valueB]: [number, number] = direction === 'ASC'
      ? [a[attribute], b[attribute]]
      : [b[attribute], a[attribute]]

    return valueA - valueB
  }
}
