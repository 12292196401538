import { Feature, getBillingFeatures } from 'src/backend/user/billingFeatures'
import * as authApi from 'src/backend/rest/auth'
import { SignUpForm } from 'src/frontend/modules/user/types'
import { ReferralSource, User } from 'src/types/User'
import { UserPasswords } from 'src/backend/user/types'
import { Billing } from 'src/types/Billing'
import PlanType = Billing.PlanType
import _isEmpty from 'lodash/isEmpty'
import moment from 'moment'
import { UNIT_DAY } from 'src/backend/time/time'
import { limitValue } from 'src/common/helpers'

export function loginUser(username: string, password: string) {
  return authApi.login(username, password)
}

export function signUpUser(userForm: SignUpForm, referralSource?: ReferralSource) {
  return authApi.getSignUpToken(userForm.email)
    .then(token => authApi.signUpUser(token, userForm, referralSource))
}

export function changeUserPassword(passwords: UserPasswords) {
  return authApi.changePassword(passwords)
}

export function getUserFeatureValue(user: User, feature: Feature) {
  const userPlanType = user.activePlan && user.activePlan.planType || PlanType.FREE
  const BillingFeatures = getBillingFeatures()
  const allBillingPlans = Object.values(PlanType).sort() as Billing.PlanType[]

  return allBillingPlans.reduce((valueCandidate, billingPlanType) => {
    if (
      userPlanType >= (billingPlanType as unknown as number) &&
      billingPlanType in BillingFeatures &&
      feature in BillingFeatures[billingPlanType]
    ) {
      return BillingFeatures[billingPlanType][feature]
    }
    return valueCandidate
  }, undefined)
}

export function isPremiumPlan(planType: PlanType): boolean {
  return planType >= PlanType.BASIC
}

export function isPremium(user: User): boolean {
  return !!user.activePlan && isPremiumPlan(user.activePlan.planType)
}

export function isLifetime(user: User): boolean {
  return isPremium(user) && !user.activePlan.validUntil
}

export function isTrial(user: User): boolean {
  return isPremium(user) && _isEmpty(user.activePlan.activeProducts)
}

export function getPremiumDaysLeft(user: User): number {
  const dayDifference = moment(user?.activePlan?.validUntil).diff(moment(), UNIT_DAY)
  return isPremium(user) && limitValue(0, Infinity, dayDifference)
}

export function isPaidPremium(user: User): boolean {
  return !!user.activePlan
    && !!user.activePlan.activeProducts
    && user.activePlan.activeProducts.some((product) => isPremiumPlan(product.planType))
}

/**
 * Returns true only if active plan is active Braintree subscription.
 * Condition is so complex because of lack of testing product status
 * and must handle multiple active products including manual vouchers
 * and canceled products.
 * @param user
 */
export function isBraintreeSubscriptionPremium(user: User): boolean {
  return isPremium(user)
    && isPaidPremium(user)
    && !!user?.activePlan?.currentProduct?.productId
    && (user.activePlan.currentProduct.productId.startsWith('braintree_monthly')
      || user.activePlan.currentProduct.productId.startsWith('braintree_yearly'))
}

export function getCurrentProductId(user: User): string {
  return user?.activePlan?.currentProduct?.productId
}

export function isKBUser(user: User) {
  return user.referralSource === ReferralSource.KB
}

export function isCNBVerified(user: User) {
  return !!user.verifiedProfile
}

export { grantGeneralToSConsent } from 'src/backend/rest/backend'
