import { Action } from 'src/types/common'
import { AccountFilterType, AccountFilterDisplayOptions } from 'src/frontend/scenes/accounts/filter/types'
import * as actions from 'src/frontend/scenes/accounts/filter/actions'


export interface AccountFilterState {
  filter: AccountFilterType
  display: AccountFilterDisplayOptions
}


const initialState: AccountFilterState = {
  filter: {
    fulltext: '',
  },
  display: {},
}

export function accountFilter(state: AccountFilterState = initialState, action: Action): AccountFilterState {
  switch (action.type) {
    case actions.ACCOUNTS_FILTER_TOGGLE_DISPLAY_FILTER: {
      return { ...state, display: { ...state.display, [action.payload]: !state.display[action.payload] } }
    }
    case actions.ACCOUNTS_FILTER_SET_FILTER: {
      return { ...state, filter: { ...state.filter, ...action.payload } }
    }

    default: {
      return state
    }
  }
}
