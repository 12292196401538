/* eslint-disable react/require-default-props */
/* eslint-disable no-shadow */
import PropTypes from "prop-types"
import React from "react"
import { Form } from "semantic-ui-react"
import {
  selectCategoryAvailableColumnHeaders,
  selectCategoryColumnIndex,
  selectCurrencyAvailableColumnHeaders,
  selectCurrencyColumnIndex,
  selectNoteAvailableColumnHeaders,
  selectNoteColumnIndex,
  selectPayeeAvailableColumnHeaders,
  selectPayeeColumnIndex,
} from "src/frontend/scenes/imports/mapping/selectors"
import { connect } from "react-redux"
import {
  changeCategoryColumn,
  changeCurrencyColumn,
  changeNoteColumn,
  changePayeeColumn,
} from "src/frontend/scenes/imports/mapping/actions"
import { handleDropdownChange } from "src/frontend/styles/semantic-ui/helpers"
import {
  buildColumnOptions,
  formatAttributeName,
} from "src/frontend/scenes/imports/mapping/helpers"
import { ImportMappingAttributeType } from "src/backend/enums"
import { Columns } from "src/frontend/scenes/imports/mapping/components/commonProptypes"
import { FormattedMessage } from "src/frontend/modules/intl"

const { NOTE, PAYEE, CURRENCYCODE, CATEGORYMAPPING } = ImportMappingAttributeType

const mapStateToProps = (state) => ({
  noteColumn: selectNoteColumnIndex(state),
  availableNoteColumns: selectNoteAvailableColumnHeaders(state),
  payeeColumn: selectPayeeColumnIndex(state),
  availablePayeeColumns: selectPayeeAvailableColumnHeaders(state),
  currencyColumn: selectCurrencyColumnIndex(state),
  availableCurrencyColumns: selectCurrencyAvailableColumnHeaders(state),
  categoryColumn: selectCategoryColumnIndex(state),
  availableCategoryColumns: selectCategoryAvailableColumnHeaders(state),
})

const mapDispatchToProps = {
  changeNoteColumn: handleDropdownChange(changeNoteColumn),
  changePayeeColumn: handleDropdownChange(changePayeeColumn),
  changeCurrencyColumn: handleDropdownChange(changeCurrencyColumn),
  changeCategoryColumn: handleDropdownChange(changeCategoryColumn),
}

const MappingDateColumnPanel = ({
  noteColumn,
  availableNoteColumns,
  payeeColumn,
  availablePayeeColumns,
  currencyColumn,
  availableCurrencyColumns,
  categoryColumn,
  availableCategoryColumns,
  changeNoteColumn,
  changePayeeColumn,
  changeCurrencyColumn,
  changeCategoryColumn,
}) => (
  <div>
    <p className="info">
      <FormattedMessage id="imports.mapping.wizard.optional_description" />
    </p>
    <Form>
      <Form.Dropdown
        className="mapping-select"
        label={formatAttributeName(NOTE)}
        value={noteColumn}
        options={buildColumnOptions(availableNoteColumns)}
        selection
        selectOnBlur={false}
        onChange={changeNoteColumn}
      />
      <Form.Dropdown
        className="mapping-select"
        label={formatAttributeName(PAYEE)}
        value={payeeColumn}
        options={buildColumnOptions(availablePayeeColumns)}
        selection
        selectOnBlur={false}
        onChange={changePayeeColumn}
      />
      <Form.Dropdown
        className="mapping-select"
        label={formatAttributeName(CURRENCYCODE)}
        value={currencyColumn}
        options={buildColumnOptions(availableCurrencyColumns)}
        selection
        selectOnBlur={false}
        onChange={changeCurrencyColumn}
      />
      {/* <Form.Dropdown
        className="mapping-select"
        label={formatAttributeName(CATEGORYMAPPING)}
        value={categoryColumn}
        options={buildColumnOptions(availableCategoryColumns)}
        selection
        selectOnBlur={false}
        onChange={changeCategoryColumn}
      /> */}
    </Form>
  </div>
)

MappingDateColumnPanel.propTypes = {
  noteColumn: PropTypes.number.isRequired,
  availableNoteColumns: Columns,
  payeeColumn: PropTypes.number.isRequired,
  availablePayeeColumns: Columns,
  currencyColumn: PropTypes.number.isRequired,
  availableCurrencyColumns: Columns,
  categoryColumn: PropTypes.number.isRequired,
  availableCategoryColumns: Columns,
  changeNoteColumn: PropTypes.func.isRequired,
  changePayeeColumn: PropTypes.func.isRequired,
  changeCategoryColumn: PropTypes.func.isRequired,
  changeCurrencyColumn: PropTypes.func.isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(MappingDateColumnPanel)
