import _isEmpty from 'lodash/isEmpty'
import spected from 'spected'
import * as commonValidator from 'src/backend/common/validator'
import omit from 'lodash/omit'
import { WalletFormValidationError } from 'src/backend/errors'
import { ContactFormValues } from 'src/frontend/scenes/contacts/contactForm/types'
import { HashMap } from 'src/types/common'
import { Contact } from 'src/types/Contact'
import { Id } from 'src/types/CouchDb'

export function validate(
  formValues: ContactFormValues & { _id?: Id },
  validationDependencies: { contacts: HashMap<Contact.ContactDocument>, types: Contact.Type[] },
) {
  const {
    contacts,
    types,
  } = validationDependencies

  const requiredFields = [
    'name',
    'type',
  ]

  const valuesWithRequiredFields = commonValidator.addRequiredFieldsAsUndefined(formValues, requiredFields)

  const spec = {
    name: [
      [commonValidator.isNotEmpty, 'form.error.enter_name'],
      [commonValidator.hasUniqueName(omit(contacts, formValues._id)), 'form.error.name_exists'],
      [commonValidator.isNotLongerThan(commonValidator.NAME_MAX_LENGTH), 'form.error.name_too_long'],
    ],
    type: [
      [commonValidator.isNotEmpty, 'contacts.form.invalid_type'],
      [commonValidator.valueExists(types), 'contacts.form.invalid_type'],
    ],
  }

  const validationResult = spected(spec, valuesWithRequiredFields)
  const errors = commonValidator.convertValidationResultToErrors(validationResult)
  if (!_isEmpty(errors)) {
    throw new WalletFormValidationError(errors)
  }
}
