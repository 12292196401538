import * as recordsService from 'src/backend/records/service'
import { Action } from 'src/types/common'
import { Record } from 'src/types/Record'
import { FilterType } from 'src/types/Filter'


export const RECORDS_DETAIL_REQUEST_RECORDS = 'RECORDS_DETAIL_REQUEST_RECORDS'
export const RECORDS_DETAIL_RECEIVE_RECORDS = 'RECORDS_DETAIL_RECEIVE_RECORDS'

export function fetchDetailRecords(filter: Partial<FilterType>) {
  return (dispatch: Function) => {
    dispatch(fetchRecordsDetailStart())
    setTimeout(async () => {
      const records = await recordsService.fetchRecordsByFilter(filter)
      dispatch(fetchRecordsDetailFinish(records))
    }, 0)
  }
}

function fetchRecordsDetailStart(): Action {
  return {
    type: RECORDS_DETAIL_REQUEST_RECORDS,
  }
}

function fetchRecordsDetailFinish(records): Action<Record[]> {
  return {
    type: RECORDS_DETAIL_RECEIVE_RECORDS,
    payload: records,
  }
}
