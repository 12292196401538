import * as actions from './actions'
import { Label } from 'src/types/Label'

export interface LabelsState {
  [key: string]: Label
}

export default function labels(state: LabelsState = {}, action) {
  switch (action.type) {
    case actions.LABELS_RECEIVE: {
      return { ...action.payload }
    }
    default:
      return state
  }
}
