import React from 'react'
import { connect } from 'react-redux'
import * as actions from '../actions'
import {
  selectPaymentMethodSuccess,
} from 'src/frontend/scenes/billing/paymentMethod/selectors'
import { Redirect } from 'react-router'
import { isBraintreeSubscriptionPremium, isPremium } from 'src/backend/user/service'
import { selectUser } from 'src/frontend/modules/user/selectors'
import { User } from 'src/types/User'
import PaymentMethod from 'src/frontend/scenes/billing/paymentMethod/components/PaymentMethod'
import { PaymentMethodFormType } from 'src/frontend/scenes/billing/paymentMethod/components/types'
import { selectActiveSubscription } from 'src/frontend/scenes/billing/activeSubscription/selectors'
import {
  BraintreeSubscription,
  BraintreeSubscriptionStatus,
} from 'src/frontend/scenes/billing/activeSubscription/types'
import { isUndefinedOrNull } from 'src/common/utils'
import { BillingUIMethod } from 'src/frontend/scenes/billing/enums'


function mapStateToProps(state) {
  return {
    success: selectPaymentMethodSuccess(state),
    activeSubscription: selectActiveSubscription(state),
    user: selectUser(state),
  }
}

const mapDispatchToProps = {
  updatePaymentMethod: actions.updatePaymentMethod,
}

interface Props {
  success: boolean
  user: User
  activeSubscription: BraintreeSubscription,

  updatePaymentMethod: (nonce: string) => void
}

function UpdatePaymentMethod({
  success,
  activeSubscription,
  user,
  updatePaymentMethod,
}: Props) {
  // user is not premium and has not selected plan, has expiring subscription or sucessfully changed payment method
  if (success
    || (isBraintreeSubscriptionPremium(user) && !isUndefinedOrNull(activeSubscription)
      && activeSubscription.status === BraintreeSubscriptionStatus.CANCELED)
    || !isPremium(user)) {
    return <Redirect to="/settings/billing" />
  }

  return (
    <PaymentMethod
      method={BillingUIMethod.PAGE}
      onSubmit={updatePaymentMethod}
      formType={PaymentMethodFormType.UPDATE}
    />
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(UpdatePaymentMethod)
