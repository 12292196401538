import React from 'react'
import InputRange from 'react-input-range'
import { isNull } from 'src/common/utils'
import { FormattedMessage } from 'src/frontend/modules/intl'
import Accordion from './Accordion'
import styles from 'src/frontend/components/Filter/InputRangeFilter.module.less'
import filterStyles from 'src/frontend/components/Filter/Filter.module.less'
import _debounce from 'lodash/debounce'
import { Input } from 'semantic-ui-react'

const AMOUNT_PATTERN = /\d+/

interface Props {
  min?: number,
  max?: number,
  amountRange: { max: number },
  active?: boolean,
  onChange: Function,
  onDisplayChange: Function
}

interface State {
  values: {
    min: number,
    max: number,
  }
}

export default class InputRangeFilter extends React.Component<Props, State> {
  state = {
    values: {
      min: this.props.min || 0,
      max: this.props.max || this.props.amountRange.max,
    },
  }

  static defaultProps = {
    active: false,
    min: null,
    max: null,
  }

  static formatInputValue(value) {
    const formattedValue = value.match(AMOUNT_PATTERN)
    return (formattedValue && formattedValue[0] || '')
  }

  componentDidUpdate(prevProps) {
    const { min, max, amountRange, onChange } = this.props
    // reset amount filter on change amount range
    if (prevProps.amountRange.max !== amountRange.max && (min === prevProps.min || max === prevProps.max)) {
      if (!isNull(min) && !isNull(max)) {
        onChange(
          'amount',
          {
            min: undefined,
            max: undefined,
          },
        )
      }

      // eslint-disable-next-line react/no-did-update-set-state
      this.setState(() => ({
        values: {
          min: 0,
          max: amountRange.max,
        },
      }))
    }
  }

  handleChangeSlider = ({ min, max }) => this.setState((_prevState, props) => {
    const { amountRange } = props
    return {
      values: {
        min: min > 0 ? min * 100 : 0,
        max: max < amountRange.max ? max * 100 : amountRange.max,
      },
    }
  })

  handleSetRange = _debounce(({ min, max }) => this.props.onChange('amount', { min: min * 100, max: max * 100 }), 250)

  handleChangeInput = (_e, { value, name }) => {
    const formattedValue = InputRangeFilter.formatInputValue(value)
    this.setState((prevState) => ({ values: { ...prevState.values, [name]: formattedValue * 100 } }))
  }

  handleInputBlur = () => this.props.onChange('amount', { ...this.state.values })

  handleResetInput = () => {
    this.props.onChange('amount', { min: undefined, max: undefined })
    this.setState(() => ({ values: { min: 0, max: this.props.amountRange.max } }))
  }

  componentWillUnmount(): void {
    this.handleSetRange.cancel()
  }

  render() {
    const { amountRange, onDisplayChange, active, min: propsMin, max: propsMax } = this.props
    const { values } = this.state

    return (
      <Accordion
        className={filterStyles.filter}
        name="amountRange"
        active={active}
        title={[
          <span key="name" className={filterStyles.filterName}><FormattedMessage id="records.filter.amount" /></span>,
          (
            <span key="value" className={filterStyles.filterValue}>
              {propsMin !== null && propsMax !== null
                ? `${propsMin / 100} - ${propsMax / 100}`
                : ''}
            </span>
          ),
        ]}
        content={(
          <div className={styles.inputRangeFilter}>
            <div className={styles.inputsContainer}>
              <Input
                name="min"
                value={values.min / 100}
                onChange={this.handleChangeInput}
                onBlur={this.handleInputBlur}
              />
              <Input
                name="max"
                value={values.max / 100}
                onChange={this.handleChangeInput}
                onBlur={this.handleInputBlur}
              />
            </div>
            <div className={styles.rangeFilterContainer}>
              <InputRange
                maxValue={Math.max(amountRange.max, values.max) / 100}
                minValue={0}
                value={{
                  min: values.min / 100,
                  max: values.max / 100,
                }}
                onChange={this.handleChangeSlider}
                onChangeComplete={this.handleSetRange}
              />
            </div>
            {(!isNull(propsMax) || !isNull(propsMin)) && (
              <div className={styles.reset}>
              <span onClick={this.handleResetInput}>
                <FormattedMessage id="form.reset" />
              </span>
              </div>
            )}
          </div>
        )}
        onDisplayChange={onDisplayChange}
      />
    )
  }
}
