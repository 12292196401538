import React from 'react'
import { Form } from 'semantic-ui-react'
import { IntegrationProviders } from 'src/frontend/scenes/integrations/providersList/types'
import InputType = IntegrationProviders.IntegrationRecipe.InputType
import IntegrationRecipeParam = IntegrationProviders.IntegrationRecipe.IntegrationRecipeParam

interface Props {
  param: IntegrationRecipeParam
  value?: string
  onChange: (field: string, value: string) => void
}

MFAFormInput.defaultProps = {
  value: '',
}

export default function MFAFormInput({ param, value, onChange }: Props) {
  const inputType = getInputType(param.type)
  return (
    <Form.Input
      autoComplete={param.type === InputType.PASSWORD ? 'new-password' : 'off'}
      type={inputType}
      name={param.id}
      label={param.label}
      disabled={param.notEditable}
      value={value}
      onChange={(_e, data) => onChange(data.name, data.value)}
    />
  )
}


function getInputType(type: InputType): string {
  switch (type) {
    default:
    case InputType.TEXT:
      return 'text'
    case InputType.NUMBER:
      return 'number'
    case InputType.PASSWORD:
      return 'password'
  }
}
