import { Divider, Form, Grid, Message } from 'semantic-ui-react'
import { FormattedMessage, FormattedMessageLabel } from 'src/frontend/modules/intl'
import React from 'react'
import PasswordField from 'src/frontend/scenes/auth/components/PasswordField'
import { FormikProps } from 'formik'
import { PasswordsForm } from 'src/frontend/modules/user/types'
import { PrimaryButton, SecondaryButton } from 'src/frontend/components/Buttons/Buttons'
import UserLinks from 'src/frontend/scenes/auth/components/UserLinks'

interface Props extends FormikProps<PasswordsForm> {
    handleCancel: () => void
}

export function ChangePasswordForm({
    values,
    touched,
    errors,
    isSubmitting,
    handleChange,
    handleSubmit,
    handleCancel,
}: Props) {
    return (
        <>
            <Form error>
                {errors.general && (
                    <Message className='error-message' error content={<FormattedMessage id={errors.general} />} />
                )}

                <PasswordField
                    name='currentPassword'
                    label={<FormattedMessageLabel id='auth.currentPassword' />}
                    value={values.currentPassword}
                    error={touched.currentPassword && errors.currentPassword}
                    autoComplete='current-password'
                    onChange={handleChange}
                />

                <PasswordField
                    name='newPassword'
                    label={<FormattedMessageLabel id='auth.newPassword' />}
                    value={values.newPassword}
                    error={touched.newPassword && errors.newPassword}
                    autoComplete='new-password'
                    onChange={handleChange}
                />

                <PasswordField
                    name='newPasswordConfirmation'
                    label={<FormattedMessageLabel id='auth.newPasswordConfirmation' />}
                    value={values.newPasswordConfirmation}
                    error={touched.newPasswordConfirmation && errors.newPasswordConfirmation}
                    autoComplete='new-password'
                    onChange={handleChange}
                />

                <Divider hidden section />

                <Grid>
                    <Grid.Column width={8}>
                        <SecondaryButton disabled={isSubmitting} fluid onClick={handleCancel}>
                            <FormattedMessage id='form.cancel' />
                        </SecondaryButton>
                    </Grid.Column>
                    <Grid.Column width={8}>
                        <PrimaryButton
                            type='submit'
                            disabled={isSubmitting}
                            loading={isSubmitting}
                            fluid
                            onClick={handleSubmit}
                        >
                            <FormattedMessage id='auth.changePassword_button' />
                        </PrimaryButton>
                    </Grid.Column>
                </Grid>
            </Form>
            <UserLinks />
        </>
    )
}
