import { combineReducers } from 'redux'
import { error } from 'src/frontend/Error/reducer'
import * as actions from './actions'
import { AppType, InfoType } from 'src/frontend/scenes/app/types'
import {
  ON_BOARDING_START,
} from 'src/frontend/scenes/onBoarding/firstRun/actions'

const appInitialState: AppType = {
  loading: true,
  loadingText: 'app.loading',
  initialized: false,
  syncInitialized: false,
  walletLifeAvailable: false,
}

function app(state: AppType = appInitialState, action) {
  switch (action.type) {
    case actions.APP_LOGOUT:
    case actions.INIT_AUTHENTICATED_APP_START:
    case actions.INIT_AUTHENTICATED_APP_END:
    case actions.INIT_AUTHENTICATED_APP_ERROR:
    case actions.INIT_DATA_START:
    case actions.SETUP_SYNC_START:
    case actions.INIT_APP_START:
    case actions.INIT_APP_END:
    case actions.INIT_APP_ERROR:
    case actions.WALLET_LIFE_AVAILABLE_SET: {
      return { ...state, ...action.payload }
    }

    case ON_BOARDING_START: {
      return { ...state, loading: false }
    }

    default: {
      return state
    }
  }
}

const initialInfoState: InfoType = {
  messageId: null,
  headerId: null,
  closeable: false,
  dimmer: undefined,
  spinner: false,
}

function info(state: InfoType = initialInfoState, action) {
  switch (action.type) {
    case actions.SHOW_INFO:
      return { ...action.payload }

    case actions.CLOSE_INFO:
      return initialInfoState

    default:
      return state
  }
}

export default combineReducers({ error, info, app })
