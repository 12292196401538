import React, { useState } from 'react'
import { Modal } from 'semantic-ui-react'
import { WidgetType, WidgetVariant } from 'src/frontend/scenes/dashboard/types'
import { User } from 'src/types/User'
import { WidgetId } from 'src/backend/dashboard/enums'
import { HashMap } from 'src/types/common'
import { FormattedMessage } from 'src/frontend/modules/intl'
import AddWidgetContent from 'src/frontend/scenes/dashboard/components/AddWidgetContent'
import { connect } from 'react-redux'
import { selectDashboardDummyWidgetsWithData } from 'src/frontend/scenes/dashboard/selectors'
import { RootState } from 'src/types/State'
import { addWidget } from 'src/frontend/scenes/dashboard/actions'
import { selectUser } from 'src/frontend/modules/user/selectors'
import styles from './AddWidget.module.less'

interface Props {
  dummyWidgets: HashMap<WidgetType[]>
  onAddWidget: (id: WidgetId, variant: WidgetVariant) => void
  user: User
}

function mapStateToProps(state: RootState) {
  return {
    dummyWidgets: selectDashboardDummyWidgetsWithData(state),
    user: selectUser(state),
  }
}

const mapDispatchToProps = {
  onAddWidget: addWidget,
}


function AddWidget({ dummyWidgets, onAddWidget, user }: Props) {
  const [open, setOpen] = useState<boolean>(false)
  const handleOpenModal = () => setOpen(true)
  const handleCloseModal = () => setOpen(false)
  return (
    <Modal
      open={open}
      onClose={handleCloseModal}
      onOpen={handleOpenModal}
      className="add-widget"
      trigger={(
        <div className={styles.addWidget}>
          <div className={styles.plus} />
          <FormattedMessage id="dashboard.widget.add" />
        </div>
      )}
    >
      <Modal.Header>
        <FormattedMessage id="dashboard.widgets.gallery.header" />
        <span className="add-record-close" onClick={handleCloseModal} />
      </Modal.Header>
      <Modal.Content scrolling>
        <AddWidgetContent dummyWidgets={dummyWidgets} onAddWidget={onAddWidget} user={user} />
      </Modal.Content>
    </Modal>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(AddWidget)
