import { IMPORTS_ACCOUNT_FILTER_CHANGED } from './actions'

const initialState = {
  selectedAccountId: null,
}

export default (state = initialState, action) => {
  switch (action.type) {

    case IMPORTS_ACCOUNT_FILTER_CHANGED: {
      return { ...state, selectedAccountId: action.accountId }
    }

    default: {
      return state
    }
  }
}
