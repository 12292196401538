import mixpanel from "mixpanel-browser"
import { HashMap } from "src/types/common"
import { CONFIG_WEB_VERSION_2 } from "src/backend/webConfig/constants"
import { isUndefinedOrNull, parseIntDecimal } from "src/common/utils"
import Cookies from "js-cookie"
import { AB } from "src/frontend/scenes/kb/components/types"
import { BillingUIMethod } from "src/frontend/scenes/billing/enums"

// Tracking functions

export function trackAutomaticLogOut() {
  webAppTrack("Auto Logout/Logout")
}

export function trackAutomaticLogOutCancel() {
  webAppTrack("Auto Logout/Prolong")
}

export function trackWebAppStart() {
  webAppTrack("Start")
}

export function trackWebSignIn() {
  webAppTrack("Sign In")
}

export function trackWebAppUserDatabaseStartReplication() {
  webAppTrack("User Database Start Replication")
}

export function trackWebAppUserDatabaseInitialized() {
  webAppTrack("User Database Initialized")
}

export function trackWebAppDashboard() {
  webAppTrack("Dashboard")
}

export function trackProductsShow(method: BillingUIMethod) {
  webAppTrack("Billing Products show", { method: BillingUIMethod[method] })
}

export function trackPaymentMethodShow(method: BillingUIMethod) {
  webAppTrack("Billing Payment Method show", { method: BillingUIMethod[method] })
}

export function trackImportsSuccess() {
  webAppTrack("Imports/Success")
}

export function trackImportsUpload() {
  webAppTrack("Imports/Upload")
}

export function trackImportsEnter() {
  webAppTrack("Imports/Enter")
}

export function trackIntegrationsShowBank() {
  webAppTrack("Bank Integrations/Bank Search Show")
}

export function trackIntegrationsBankFound() {
  webAppTrack("Bank Integrations/Bank Search Select")
}

export function trackIntegrationsNewConnectionCredentialsSubmit() {
  webAppTrack("Bank Integrations/New Connection Credentials Submit")
}

export function trackIntegrationsNewConnectionOAuthGranted() {
  webAppTrack("Bank Integrations/New Connection Oauth Access Grant")
}

export function trackIntegrationsAccountsShow() {
  webAppTrack("Bank Integrations/Account Select Show")
}

export function trackIntegrationsSuccess() {
  webAppTrack("Bank Integrations/New Connection Success")
}

export function trackPersonalDetailsShow() {
  webAppTrack("Bank Integrations/Additional User Info Show")
}

export function trackPersonalDetailsSuccess() {
  webAppTrack("Bank Integrations/Additional User Info Success")
}

export function trackIntegrationsCancel() {
  webAppTrack("Bank Integrations/New Connection Cancel")
}

export function trackIntegrationsError(error: string) {
  webAppTrack("Bank Integrations/New Connection Error", { error })
}

export function trackIntegrationsRefreshError() {
  webAppTrack("Bank Integrations/Refresh Error")
}

export function trackIntegrationsDisconnect() {
  webAppTrack("Bank Integrations/Disconnect")
}

export function trackIntegrationsReconnect() {
  webAppTrack("Bank Integrations/Reconnect")
}

export function trackIntegrationsReconnectConnectionCredentialsSubmit() {
  webAppTrack("Bank Integrations/Reconnect Credentials Submit")
}

export function trackIntegrationsReconnectOAuthGranted() {
  webAppTrack("Bank Integrations/Reconnect Oauth Access Grant")
}

export function trackIntegrationsReconnectSuccess() {
  webAppTrack("Bank Integrations/Reconnect Success")
}

export function trackIntegrationsRefresh() {
  webAppTrack("Bank Integrations/Refresh")
}

export function trackIntegrationsRefreshSuccess() {
  webAppTrack("Bank Integrations/Refresh Success")
}

export function trackIntegrationsMFASent() {
  webAppTrack("Bank Integrations/MFA Sent")
}

export function trackSolveDuplicities(parameters) {
  webAppTrack("Solve Duplicities", parameters)
}

export function trackAddWidget(parameters) {
  webAppTrack("Widget/Add", parameters)
}

export function trackRemoveWidget(parameters) {
  webAppTrack("Widget/Remove", parameters)
}

export function trackUseFilter(filterType: string) {
  webAppTrack("Filter/set", { filterType })
}

export function trackSaveFilter() {
  webAppTrack("Filter/save")
}

export function trackBaseCurrencyEnter() {
  webAppTrack("Onboarding - Base currency/Enter")
}

export function trackBaseCurrencySet() {
  webAppTrack("Onboarding - Base currency/Set")
}

export function trackAccountNameEnter() {
  webAppTrack("Onboarding - Account Name/Enter")
}

export function trackAccountNameSet() {
  webAppTrack("Onboarding - Account Name/Set")
}

export function trackBusinessInformationEnter() {
  webAppTrack("Onboarding - Business Information/Enter")
}

export function trackBusinessInformationSet() {
  webAppTrack("Onboarding - Business Information/Set")
}

export function trackCashBalanceEnter() {
  webAppTrack("Onboarding - Cash Balance/Enter")
}

export function trackCashBalanceSet() {
  webAppTrack("Onboarding - Cash Balance/Set")
}

export function trackOnBoardingSuccess() {
  webAppTrack("Onboarding - Success")
}

export function trackWSCampaignDashboardBannerImpression() {
  webAppTrack("WoolSocks campaign - Dashboard banner impression")
}

export function trackInvalidIdsQuery() {
  webAppTrack("findByIds: ids is not an array")
}

export function trackWSCampaignModalBannerImpression() {
  webAppTrack("WoolSocks campaign - Modal banner impression")
}

export function trackWSCampaignModalCTAClickthrough() {
  webAppTrack("WoolSocks campaign - Modal CTA clickthrough")
}

// Delegate functions - Mixpanel functions needs theirs 'this'

export function identify(userId) {
  mixpanel?.wallet?.identify(userId)
}

export function init(token) {
  // init is asynchronous function and using it synchronously causes calling mixpanel tracking functions on undefined
  if (!mixpanel.wallet) {
    return new Promise((resolve) => {
      mixpanel.init(token, { loaded: resolve }, "wallet")
    })
  }
  return Promise.resolve()
}

/**
 * User profile properties are also passed to the tracking event where it is used
 * to identify user or to filter events.
 * @see {@link https://mixpanel.com/help/reference/javascript#storing-user-profiles}
 */
const UserProfileProperty = {
  // WebApp only property
  LAST_WEB_APP_USED: "Last WebApp used",
  WEB_APP_START_COUNT: "WebApp start count",
  CONFIG_VERSION: "WebApp config version",
  DEPRECATED_DASHBOARD_VERSION: "WebApp dashboard version",
  // Rest of the properties are shared with other platforms
  USER_ID: "User id",
  USER_EMAIL: "Email",
  LAST_USED: "Last used",
  KB_AB: "KB AB Group",
}

export function updateUserProfile(user) {
  const now = new Date()
  if (mixpanel.wallet) {
    mixpanel.wallet.people.set({
      [UserProfileProperty.USER_ID]: user.userId,
      [UserProfileProperty.USER_EMAIL]: user.email,
      [UserProfileProperty.LAST_WEB_APP_USED]: now,
      [UserProfileProperty.CONFIG_VERSION]: CONFIG_WEB_VERSION_2,
    })
    const kbVariantCookie = parseIntDecimal(Cookies.get("kbVariant"))

    if (!isUndefinedOrNull(kbVariantCookie) && kbVariantCookie in AB) {
      mixpanel.wallet.people.set_once({
        [UserProfileProperty.KB_AB]: AB[kbVariantCookie],
      })
    }

    // TODO remove deprecated dashboard
    mixpanel.wallet.people.unset(UserProfileProperty.DEPRECATED_DASHBOARD_VERSION)

    mixpanel.wallet.people.increment(UserProfileProperty.WEB_APP_START_COUNT)

    // identify must be called along with people.set to really update profile on Mixpanel server
    mixpanel.wallet.identify(user.userId)
  }
}

function webAppTrack(eventName: string, properties?: HashMap<string | number | boolean>): void {
  mixpanel.wallet && mixpanel.wallet.track(`WebApp - ${eventName}`, properties)
}
