import { WidgetType, WidgetView } from 'src/frontend/scenes/dashboard/types'
import { Id } from 'src/types/CouchDb'
import { isAppBoard } from 'src/common/environment'
import { BoardAddWidgetsList, WalletAddWidgetsList } from 'src/frontend/scenes/dashboard/constants'
import _isEqual from 'lodash/isEqual'
import _omit from 'lodash/omit'
import { HashMap } from 'src/types/common'

export function getNumberOfColumns(): number {
  const windowSize: number = window.innerWidth
  if (windowSize > 1600) {
    return 4
  } else if (windowSize > 1200) {
    return 3
  } else if (windowSize > 800) {
    return 2
  }
  return 1
}

export function findWidgetById(widgets: HashMap<WidgetType>, id: Id): WidgetType {
  return id in widgets && widgets[id]
}

export function getRandomInt(max) {
  return Math.floor(Math.random() * Math.floor(max))
}

export function getAddWidgets() {
  return isAppBoard()
    ? BoardAddWidgetsList
    : WalletAddWidgetsList
}

export function widgetHasDeepEqualProps(prevProps: { widget: WidgetView }, nextProps: { widget: WidgetView }) {
  if (!prevProps) {
    return false
  }

  return prevProps.widget.id === nextProps.widget.id
    && _isEqual(prevProps.widget.data, nextProps.widget.data)
    && _isEqual(prevProps.widget.filter, nextProps.widget.filter)
    && _isEqual(prevProps.widget.options, nextProps.widget.options)
}


export function chartHasEqualProps(prevProps: { widget: WidgetView }, nextProps: { widget: WidgetView }) {
  if (!prevProps) {
    return false
  }

  const prev = _omit(prevProps.widget.data, ['ref', 'plugins', 'options', 'datasets'])
  const next = _omit(nextProps.widget.data, ['ref', 'plugins', 'options', 'datasets'])

  const prevDatasets = prevProps.widget.data && prevProps.widget.data.datasets.map((dataset) => {
    return _omit(dataset, ['backgroundGradient'])
  })
  const nextDatasets = nextProps.widget.data && nextProps.widget.data.datasets.map((dataset) => {
    return _omit(dataset, ['backgroundGradient'])
  })

  return prevProps.widget.id === nextProps.widget.id
    && _isEqual(prev, next)
    && _isEqual(prevDatasets, nextDatasets)
    && _isEqual(prevProps.widget.options, nextProps.widget.options)
}
