import { startOfDayUTC } from "src/backend/time/time"
import { DataDoc } from "src/types/CouchDb"
import { StandingOrder } from "src/types/StandingOrder"
import { omitFiledsFromObject } from "src/common/utils"

export function standingOrderConverter(dataDoc: DataDoc<StandingOrder> | StandingOrder) {
  const doc: StandingOrder = (dataDoc as DataDoc<StandingOrder>).doc || (dataDoc as StandingOrder)

  const standingOrder = {
    ...omitFiledsFromObject(doc, ["reminder", "threeDaysBeforeNotificationEnabled"]),
    dueDate: doc.dueDate ? startOfDayUTC(doc.dueDate) : undefined,
    generateFromDate: doc.generateFromDate ? startOfDayUTC(doc.dueDate) : undefined,
  }

  return standingOrder
}
