import { RootState } from 'src/types/State'
import { InAppOnBoardingState } from 'src/frontend/scenes/onBoarding/inApp/reducer'
import { selectOnBoarding } from 'src/frontend/scenes/onBoarding/selectors'

const selectInAppOnBoarding = (state: RootState): InAppOnBoardingState => selectOnBoarding(state).inApp

export const selectShouldDisplayDashboardOnBoarding = (state: RootState) => selectInAppOnBoarding(state).dashboard
export const selectShouldDisplayRecordsOnBoarding = (state: RootState) => selectInAppOnBoarding(state).records
export const selectShouldDisplayMobileAppsBanner = (state: RootState) => selectInAppOnBoarding(state).mobileAppsBanner
export const selectShouldDisplayAdvertisement = (state: RootState) => selectInAppOnBoarding(state).advertisement

export const selectShouldDisplayGoodJob = (state: RootState) => selectInAppOnBoarding(state).goodJobModalOpen
export const selectGoodJobText = (state: RootState) => selectInAppOnBoarding(state).goodJobModalText
export const selectGoodJobValues = (state: RootState) => selectInAppOnBoarding(state).goodJobModalValues
