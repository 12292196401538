import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { FormattedMessage } from 'src/frontend/modules/intl';
import 'src/frontend/scenes/auth/signup/SignupPage.less';
import AuthPageLayout from 'src/frontend/scenes/auth/components/AuthPageLayout';
import SignupFormContainer from 'src/frontend/scenes/auth/signup/SignupFormContainer';
import styles from 'src/frontend/scenes/auth/components/Auth.module.less';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { REFERRAL_COOKIE_NAME } from 'src/frontend/scenes/auth/signup/hooks';
import { RootState } from 'src/types/State';
import { ReferralSource } from 'src/types/User';
import { isUndefinedOrNull } from 'src/common/utils';
import { selectShouldAddVoucher } from 'src/frontend/scenes/onBoarding/firstRun/selectors';
import * as gtm from 'src/common/gtm';
import LoginOAuthForm from '../login/LoginOAuthForm';

type Props = {
  shouldAddVoucher: boolean;
};

function mapStateToProps(state: RootState): Props {
  return {
    shouldAddVoucher: selectShouldAddVoucher(state),
  };
}

function SignUpPage({ shouldAddVoucher }: Props) {
  const [referralChecked, setReferralChecked] = useState(false);

  //const referrer = location && location.state && location.state.referrer

  useEffect(() => {
    setMallPayReferralCookie();
    setReferralChecked(true);
    gtm.signUpStart('Sign Up Page');
  }, []);

  return (
    referralChecked && (
      <AuthPageLayout>
        <h1>
          {shouldAddVoucher ? (
            <FormattedMessage id="auth.add_voucher_add_voucher" />
          ) : (
            <FormattedMessage id="auth.create_account" />
          )}
        </h1>
        <p className={styles.description}>
          <FormattedMessage id="auth.signup.sub_title" />
        </p>
        <SignupFormContainer />
        <div className={styles.oauth}>
          <LoginOAuthForm remember={true} />
        </div>
        <div className={styles.link}>
          <FormattedMessage id="auth.already_have_account" />
          &nbsp;
          <Link to="/login">
            <FormattedMessage id="auth.login_button" />
          </Link>
        </div>
      </AuthPageLayout>
    )
  );
}

export default connect(mapStateToProps)(SignUpPage);

const mallPayRe = new RegExp('mallpay', 'i');

function isMallPay(referrer: string): boolean {
  return mallPayRe.test(referrer);
}

function setMallPayReferralCookie() {
  const referrer = document.referrer;
  const referralCookie = Cookies.get(REFERRAL_COOKIE_NAME);
  if (isUndefinedOrNull(referralCookie) && isMallPay(referrer)) {
    Cookies.set(REFERRAL_COOKIE_NAME, ReferralSource.MALL_PAY);
  }
}
