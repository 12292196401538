import { Action } from 'src/types/common'
import {
  CANCEL_SUBSCRIPTION_CLOSE, CANCEL_SUBSCRIPTION_ERROR, CANCEL_SUBSCRIPTION_FINISH,
  CANCEL_SUBSCRIPTION_OPEN, CANCEL_SUBSCRIPTION_START,
} from 'src/frontend/scenes/billing/cancelSubscription/actions'

export interface CancelSubscriptionState {
  open: boolean,
  loading: boolean
}

const initialState = {
  open: false,
  loading: false,
}

export default function cancelSubscription(
  state: CancelSubscriptionState = initialState,
  action: Action,
): CancelSubscriptionState {
  switch (action.type) {

    case CANCEL_SUBSCRIPTION_OPEN: {
      return { ...state, open: true }
    }

    case CANCEL_SUBSCRIPTION_CLOSE: {
      return { ...state, open: false }
    }

    case CANCEL_SUBSCRIPTION_START: {
      return { ...state, loading: true }
    }

    case CANCEL_SUBSCRIPTION_FINISH:
    case CANCEL_SUBSCRIPTION_ERROR: {
      return { ...state, loading: false, open: false }
    }


    default: {
      return state
    }
  }
}
