import { createDefaultDocument } from "src/backend/common/service"
import * as commonRepository from "src/backend/common/repository"
import { inMemoryTableNames } from "src/backend/db/inMemorySqlDbSchemaBuilder"
import * as repository from "./repository"
import { User } from "src/types/User"
import { v4 as uuid } from "uuid"
import { Id } from "src/types/CouchDb"
import { MagicRule } from "src/types/MagicRule"
import { MagicRulesFormValues } from "src/frontend/scenes/settings/magicRules/types"
import * as categoriesRepository from "src/backend/categories/repository"
import * as envelopes from "src/backend/categories/envelopes"
import { RecordType } from "src/backend/enums"
import * as categoriesService from "src/backend/categories/service"
import * as contactsRepository from "src/backend/contacts/repository"
import * as accountsRepository from "src/backend/accounts/repository"
import * as recordsRepository from "src/backend/records/repository"
import { Record } from "src/types/Record"
import { FilterType } from "src/types/Filter"

export async function saveMagicRule(
  formValues: Partial<MagicRulesFormValues>,
  user: User,
  magicRuleId?: Id,
): Promise<MagicRule[]> {
  const magicRuleCandidate = magicRuleId
    ? await repository.findById(magicRuleId)
    : await getDefaultMagicRule(user)

  const category =
    formValues.categoryId &&
    (await categoriesService.getCategoryByCategoryId(formValues.categoryId, user))
  const contact = formValues.contactId && (await contactsRepository.findById(formValues.contactId))
  const account = formValues.accountId && (await accountsRepository.findById(formValues.accountId))

  const magicRuleDocument: MagicRule = {
    ...magicRuleCandidate,
    ...formValues,
    name: formValues.name && formValues.name.trim(),
    categoryId: category && category._id,
    contactId: contact && contact._id,
    accountIds: account && [account._id],
  }

  return commonRepository.updateBulk([magicRuleDocument], inMemoryTableNames.MAGIC_RULE) as Promise<
    MagicRule[]
  >
}

export async function getDefaultMagicRule(user: User): Promise<MagicRule> {
  const modelType = inMemoryTableNames.MAGIC_RULE
  const id = `-${modelType}_${uuid()}`
  return {
    ...createDefaultDocument(id, modelType, user),
    name: "",
  }
}

export async function removeMagicRule(magicRuleId: Id) {
  const magicRule = await repository.findById(magicRuleId)
  return commonRepository.removeBulk([magicRule])
}

export function fetchMagicRules(): Promise<MagicRule[]> {
  return repository.getAllMagicRules()
}

export async function getValidationDependencies() {
  const [categories, magicRules, accounts] = await Promise.all([
    categoriesRepository.findAllAsHashMap(),
    repository.findAllAsHashMap(),
    accountsRepository.findAllAsHashMap(),
  ])

  const envelopesMap = envelopes.getEnvelopesMap()

  return {
    magicRules,
    categories,
    accounts,
    envelopes: envelopesMap,
    recordTypes: [RecordType.EXPENSE, RecordType.INCOME],
  }
}

export function findSimilarToMagicRule(magicRule: MagicRule): Promise<Record[]> {
  if (
    magicRule.categoryId ||
    magicRule.contactId ||
    (magicRule.labelIds && magicRule.labelIds.length > 0)
  ) {
    const filter: Partial<FilterType> = {
      fulltext: magicRule.keywords && magicRule.keywords.join("|"),
      type: magicRule.recordType,
      transfer: false,
    }

    return recordsRepository.findRecordsByFilter(filter).then((records) => {
      return records.filter(ruleCanBeApplied(magicRule))
    })
  }
}

export function ruleCanBeApplied(magicRule: MagicRule) {
  return (record: Record) => {
    const haveDifferentCategory =
      !!magicRule.categoryId && magicRule.categoryId !== record.categoryId
    const haveDifferentContact = !!magicRule.contactId && magicRule.contactId !== record.contactId
    const haveSameLabels =
      !magicRule.labelIds ||
      (record.labels &&
        magicRule.labelIds &&
        magicRule.labelIds.every((labelId) => record.labels && record.labels.includes(labelId)))

    return haveDifferentCategory || haveDifferentContact || !haveSameLabels
  }
}
