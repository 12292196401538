import React from 'react'
import { Period } from 'src/types/Filter'
import YearContent from './YearContent'
import WeekContent from './WeekContent'
import RangeContent from './RangeContent'
import MonthContent from './MonthContent'
import { MenuType } from 'src/frontend/components/DateRangePicker/helpers'
import { RelativeIntervalType } from 'src/backend/enums'


type Props = {
  type: string,
  period: Period,
  locale: string,
  showAll: boolean,
  intervalType: RelativeIntervalType,
  onChange: Function,
  onClose: () => void,
}

export default function Content({ showAll, type, period, locale, intervalType, onChange, onClose }: Props) {
  switch (type) {
    case MenuType.YEAR.value: {
      return <YearContent period={period} onChange={onChange} onClose={onClose} />
    }
    case MenuType.MONTH.value: {
      return <MonthContent period={period} locale={locale} onChange={onChange} onClose={onClose} />
    }
    case MenuType.WEEK.value: {
      return <WeekContent period={period} onChange={onChange} onClose={onClose} />
    }
    default:
    case MenuType.RANGE.value: {
      return (
        <RangeContent
          period={period}
          showAll={showAll}
          intervalType={intervalType}
          onChange={onChange}
          onClose={onClose}
        />
      )
    }
  }
}
