import React from 'react'
import ChangeLanguage from 'src/frontend/scenes/auth/components/ChangeLanguage'
import styles from './PublicLayout.module.less'
import { mergeClasses } from 'src/common/utils'

interface Props {
  className?: string
  children: React.ReactNode | string
  size?: 'small' | 'medium' | 'large' | 'larger'
  transparent?: boolean
  inverted?: boolean
}

PublicLayout.defaultProps = {
  className: undefined,
  size: 'medium',
  transparent: false,
  inverted: false,
}

export default function PublicLayout({ children, inverted, size, transparent, className }: Props) {
  return (
    <div
      className={mergeClasses(
        styles.publicLayout,
        className,
        transparent && styles.transparent,
        inverted && styles.inverted,
      )}
    >
      <ChangeLanguage inverted={inverted} />
      <div className={styles.header}>
        <div className={styles.logo} />
      </div>

      <div className={mergeClasses(styles.content, size in styles && styles[size], transparent && styles.transparent)}>
        {children}
      </div>

      <div className={styles.footer} />
    </div>
  )
}
