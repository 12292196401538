import { ChartSubjectType } from 'src/frontend/scenes/analytics/enums'
import { WidgetId } from 'src/backend/dashboard/enums'
import { Interval, RelativeIntervalType } from 'src/backend/enums'
import { AddWidgetsListType } from 'src/frontend/scenes/dashboard/types'
import { RevenueCostsGrowthWidgetVariant } from 'src/frontend/scenes/dashboard/widgets/BoardRevenueCostsGrowth/enums'
import { RecordCategoryLevel } from 'src/backend/categories/enums'
import { PieAndSpendingWidgetVariant } from 'src/frontend/scenes/dashboard/widgets/PieAndBarSpending/enums'
import { SpendingByNatureVariant } from 'src/frontend/scenes/dashboard/widgets/SpendingByNatureBars/enums'

export const DefaultWidgetFilter = {
  [WidgetId.CASH_RATIO_LIQUIDITY_CARD]: {
    period: {
      interval: Interval.RELATIVE_7_DAYS,
    },
  },
}

export const DefaultWidgetOptions = {
  [WidgetId.PERIOD_2_PERIOD_CHART_CARD]: {
    subject: ChartSubjectType.CUMULATIVE_CASH_FLOW,
  },
  [WidgetId.CASH_FLOW_CHART_CARD]: {
    cumulative: false,
  },
  [WidgetId.CASH_FLOW_CHART_CARD_BOARD]: {
    cumulative: false,
  },
  [WidgetId.UPCOMING_PLANNED_PAYMENTS]: {
    relativeIntervalValue: RelativeIntervalType.NEXT,
  },
  [WidgetId.CASH_RATIO_LIQUIDITY_CARD]: {
    relativeIntervalValue: RelativeIntervalType.NEXT,
  },
  [WidgetId.REVENUE_COST_GROWTH_CARD]: {
    cumulative: true,
  },
  [WidgetId.SPENDING_BY_CATEGORIES_AND_LABELS_PIE_CHART_CARD]: {
    categoryLevel: RecordCategoryLevel.SUPER_ENVELOPE,
  },
}

export const WalletAddWidgetsList: AddWidgetsListType = {
  general: [
    { widgetId: WidgetId.LAST_RECORDS_CARD },
    { widgetId: WidgetId.PERFORMANCE_CARD },
    { widgetId: WidgetId.ACCOUNTS_LIST },
  ],
  balance: [
    { widgetId: WidgetId.BALANCE_CHART_CARD },
    { widgetId: WidgetId.BALANCE_BY_CURRENCIES_CARD },
  ],
  outlook: [
    { widgetId: WidgetId.UPCOMING_PLANNED_PAYMENTS },
    { widgetId: WidgetId.CASH_RATIO_LIQUIDITY_CARD },
  ],
  cashFlow: [
    { widgetId: WidgetId.CASH_FLOW_CARD },
    { widgetId: WidgetId.CASH_FLOW_CHART_CARD },
    { widgetId: WidgetId.PERIOD_2_PERIOD_CHART_CARD },
  ],
  spending: [
    { widgetId: WidgetId.SPENDING_BY_CATEGORIES_PIE_CHART_CARD },
    { widgetId: WidgetId.SPENDING_BY_CATEGORIES_CARD },
    {
      widgetId: WidgetId.SPENDING_BY_CATEGORIES_AND_LABELS_PIE_CHART_CARD,
      variant: PieAndSpendingWidgetVariant.SPENDING,
    },
    { widgetId: WidgetId.CARDINALITY_SPENDING_CHART_CARD, variant: SpendingByNatureVariant.SPENDING },
  ],
  credit: [
    { widgetId: WidgetId.CREDIT_UTILIZATION_CARD },
  ],
}

export const BoardAddWidgetsList: AddWidgetsListType = {
  cashFlow: [
    { widgetId: WidgetId.CASH_FLOW_CARD_BOARD },
    { widgetId: WidgetId.CASH_FLOW_CHART_CARD_BOARD },
    { widgetId: WidgetId.PERIOD_2_PERIOD_CHART_CARD },
  ],
  revenues: [
    { widgetId: WidgetId.REVENUE_COST_GROWTH_CARD, variant: RevenueCostsGrowthWidgetVariant.REVENUE },
    { widgetId: WidgetId.OPERATING_PROFIT_CARD },
    {
      widgetId: WidgetId.SPENDING_BY_CATEGORIES_AND_LABELS_PIE_CHART_CARD,
      variant: PieAndSpendingWidgetVariant.REVENUE,
    },
  ],
  costs: [
    {
      widgetId: WidgetId.SPENDING_BY_CATEGORIES_AND_LABELS_PIE_CHART_CARD,
      variant: PieAndSpendingWidgetVariant.COSTS,
    },
    { widgetId: WidgetId.REVENUE_COST_GROWTH_CARD, variant: RevenueCostsGrowthWidgetVariant.COSTS },
    { widgetId: WidgetId.CARDINALITY_SPENDING_CHART_CARD, variant: SpendingByNatureVariant.COSTS },
  ],
  general: [
    { widgetId: WidgetId.ACCOUNTS_LIST },
    { widgetId: WidgetId.LAST_RECORDS_CARD },
    { widgetId: WidgetId.COCKPIT_BOARD },
  ],
  balance: [
    { widgetId: WidgetId.BALANCE_CHART_CARD },
    { widgetId: WidgetId.BALANCE_BY_CURRENCIES_CARD },
  ],
  outlook: [
    // { widgetId: WidgetId.CASH_RATIO_LIQUIDITY_CARD },
  ],
  debts: [
    { widgetId: WidgetId.CREDIT_UTILIZATION_CARD },
  ],
}

export enum CashFlowBarColors {
  LIGHT_GREEN = '#28cb7c55',
  LIGHT_RED = '#e74c3c55',
  DARK_GREEN = '#28cb7c',
  DARK_RED = '#e74c3c',
}
