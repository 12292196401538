import React, { useCallback, useMemo } from 'react'
import { Formik } from 'formik'
import { connect } from 'react-redux'
import { authModuleErrorHandler } from 'src/frontend/scenes/auth/helpers'
import * as appActions from 'src/frontend/scenes/app/actions'
import * as userActions from 'src/frontend/modules/user/actions'
import SignupForm from 'src/frontend/scenes/auth/signup/SignupForm'
import { getValidationSchema } from 'src/frontend/scenes/auth/signup/helpers'
import { SignUpForm } from 'src/frontend/modules/user/types'
import { validate } from 'src/frontend/scenes/auth/validator'
import { ReferralSource } from 'src/types/User'
import { useReferralSource } from 'src/frontend/scenes/auth/signup/hooks'
import * as gtm from 'src/common/gtm'

const mapDispatchToProps = {
  signUp: userActions.signUp,
  initAuthenticatedApp: appActions.initAuthenticatedApp,
}

interface Props {
  signUp: (userForm: SignUpForm, referralSource?: ReferralSource) => void,
  initAuthenticatedApp: () => void
}

function SignupFormContainer({ signUp, initAuthenticatedApp }: Props) {

  const initialValues = {
      email: '',
      newPassword: '',
      consent: true,
  }

  const referralSource = useReferralSource()

  const validationSchema = useMemo(getValidationSchema, [])

  const handleValidate = useCallback((values) => validate(validationSchema, values), [])

  const onSubmit = useCallback(async (values, { setSubmitting, setErrors }) => {
    const { email, newPassword } = values

    try {
      await signUp({ email, newPassword }, referralSource)
      gtm.signUpFinished()
      await initAuthenticatedApp()

    } catch (error) {
      authModuleErrorHandler(setSubmitting, setErrors, 'Sign up failed')(error)
    }
  }, [])


  return (
    <Formik
      initialValues={initialValues}
      validate={handleValidate}
      onSubmit={onSubmit}
    >
      {(props) => <SignupForm {...props} />}
    </Formik>
  )
}


export default connect(null, mapDispatchToProps)(SignupFormContainer)

