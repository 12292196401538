import { Action } from 'src/types/common'
import { BillingModalStage } from 'src/frontend/scenes/billing/billingModal/enums'

export const BILLING_MODAL_OPEN = 'BILLING_MODAL_OPEN'
export const BILLING_MODAL_CLOSE = 'BILLING_MODAL_CLOSE'

export const BILLING_MODAL_GOTO_STAGE = 'BILLING_MODAL_GOTO_STAGE'

export function openBillingModal(): Action {
  return {
    type: BILLING_MODAL_OPEN,
  }
}

export function closeBillingModal(): Action {
  return {
    type: BILLING_MODAL_CLOSE,
  }
}

export function goToBillingStage(stage: BillingModalStage): Action<BillingModalStage> {
  return {
    type: BILLING_MODAL_GOTO_STAGE,
    payload: stage,
  }
}

