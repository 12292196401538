import React, { memo } from 'react'
import styles from 'src/frontend/scenes/onBoarding/firstRun/components/OnBoardingLayout.module.less'
import PictureIcon from 'src/frontend/components/PictureIcon/PictureIcon'
import { OnBoardingStage } from 'src/frontend/scenes/onBoarding/firstRun/enums'
import OnBoardingTopBar from 'src/frontend/scenes/onBoarding/firstRun/components/OnBoardingTopBar'
import FormattedMessage from 'src/frontend/modules/intl/components/FormattedMessage'
import { PictureIconNames } from 'src/frontend/components/PictureIcon/types'
import { Transition } from 'semantic-ui-react'
import { OnBoardingStatus } from 'src/backend/onBoarding/types'

interface Props {
  stage: OnBoardingStage,
  status: OnBoardingStatus,
  stages: OnBoardingStage[]
  hideContentHeader?: boolean
  children: string | React.ReactNode
}

function OnBoardingLayout({ hideContentHeader = false, stage, status, stages, children }: Props) {

  const icon = getStageIconName(stage)

  return (
    <div className={styles.onBoarding}>
      <div className={styles.topBar}>
        <OnBoardingTopBar stage={stage} status={status} stages={stages} />
      </div>

      <div className={styles.container}>
        <Transition.Group duration={500} animation={'onboarding-transition'} directional>
          <div key={stage}>
            {icon && !hideContentHeader && <PictureIcon name={icon} compact />}
            {!hideContentHeader && <h1><FormattedMessage id={`onBoarding.${OnBoardingStage[stage]}.title`} /></h1>}
            {children}
          </div>
        </Transition.Group>
      </div>
    </div>
  )
}

function getStageIconName(stage: OnBoardingStage): PictureIconNames {
  switch (stage) {
    case OnBoardingStage.ACCOUNT_NAME:
      return 'bank-account'

    case OnBoardingStage.BASE_CURRENCY:
      return 'base-currency'

    case OnBoardingStage.CASH_BALANCE:
      return 'cash-balance'

    case OnBoardingStage.KB_BANK_INTEGRATION:
      return 'kb-logo'

    case OnBoardingStage.REGISTRATION:
      return 'board-logo'

    default:
      return null
  }
}


export default memo(OnBoardingLayout, ((prevProps, nextProps) => {
  return prevProps.stage === nextProps.stage
    && prevProps.status === nextProps.status
    && prevProps.hideContentHeader === nextProps.hideContentHeader
}))
