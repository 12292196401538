import React from "react"
import { BraintreeBillingAddress } from "src/frontend/scenes/billing/billingInformation/types"
import { SettingsItem } from "src/frontend/scenes/settings/billing/BillingItem"
import { formatMessage, FormattedMessage } from "src/frontend/modules/intl"
import styles from "src/frontend/scenes/settings/billing/Billing.module.less"
import { getCountryName } from "src/backend/countries/countries"
import { SecondaryButton } from "src/frontend/components/Buttons/Buttons"
import { Link } from "react-router-dom"

interface Props {
  billingAddress?: BraintreeBillingAddress
}

export default function BillingAddress({ billingAddress }: Props) {
  return (
    <SettingsItem
      topAligned
      title={<FormattedMessage id="billing.billing-information" />}
      action={
        <SecondaryButton
          as={Link}
          to="/settings/billing/billing-information"
        >
          <FormattedMessage id="form.update" />
        </SecondaryButton>
      }
    >
      <div>
        <div className={styles.description}>
          <FormattedMessage id="billing.address.company" />
        </div>
        <div className={styles.bold}>
          {billingAddress?.company || formatMessage("billing.value.none")}
        </div>
      </div>

      <div>
        <div className={styles.description}>
          <FormattedMessage id="billing.address.vat-id" />
        </div>
        <div className={styles.bold}>
          {billingAddress?.vatNumber || formatMessage("billing.value.none")}
        </div>
      </div>

      <div>
        <div className={styles.description}>
          <FormattedMessage id="billing.address" />
        </div>
        {billingAddress &&
        (billingAddress.streetAddress ||
          billingAddress.extendedAddress ||
          billingAddress.city ||
          billingAddress.postalCode) ? (
          <>
            <div className={styles.bold}>{billingAddress?.streetAddress}</div>
            <div className={styles.bold}>{billingAddress?.extendedAddress}</div>
            <div className={styles.bold}>{billingAddress?.city}</div>
            <div className={styles.bold}>{billingAddress?.postalCode}</div>
            <div className={styles.bold}>{getCountryName(billingAddress?.countryCodeAlpha2)}</div>
          </>
        ) : (
          <div className={styles.bold}>{formatMessage("billing.value.none")}</div>
        )}
      </div>
    </SettingsItem>
  )
}
