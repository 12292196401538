import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { Modal, Grid } from "semantic-ui-react"
import { FormattedMessage } from "src/frontend/modules/intl"
import RemoveAccountModalContent from "src/frontend/scenes/settings/accounts/components/RemoveAccountModalContent"
import * as selectors from "src/frontend/scenes/settings/accounts/selectors"
import * as actions from "src/frontend/scenes/settings/accounts/actions"
import { PrimaryButton, SecondaryButton } from "src/frontend/components/Buttons/Buttons"

const mapStateToProps = (state) => {
  return {
    deleteForm: selectors.selectAccountsDeleteForm(state),
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    handleDeleteAccount(event, account, references) {
      dispatch(actions.deleteAccount(account, references))
    },
    handleDeleteFormOpen(event, account) {
      dispatch(actions.openDeleteForm(account))
    },
    handleDeleteFormClose() {
      dispatch(actions.closeDeleteForm())
    },
  }
}

class RemoveAccountForm extends React.Component {
  static propTypes = {
    account: PropTypes.shape({ name: PropTypes.string.isRequired }).isRequired,
    deleteForm: PropTypes.shape({
      canDelete: PropTypes.bool.isRequired,
      cantDeleteReason: PropTypes.string,
      references: PropTypes.oneOfType([PropTypes.array, PropTypes.bool]),
      referencedTypes: PropTypes.oneOfType([PropTypes.array, PropTypes.bool]),
    }).isRequired,
    as: PropTypes.node,
    handleDeleteFormClose: PropTypes.func.isRequired,
    handleDeleteFormOpen: PropTypes.func.isRequired,
    handleDeleteAccount: PropTypes.func.isRequired,
  }

  static defaultProps = {
    as: undefined,
  }

  constructor(props) {
    super(props)
    this.state = { processing: false, open: false }
  }

  handleConfirm = (e) => {
    this.setState({ processing: true })
    this.props.handleDeleteAccount(e, this.props.account, this.props.deleteForm.references)
  }

  handleOpen = (e) => {
    this.setState({ processing: false, open: true })
    this.props.handleDeleteFormOpen(e, this.props.account)
  }

  handleClose = (e) => {
    this.setState({ processing: false, open: false })
    this.props.handleDeleteFormClose(e)
  }

  render() {
    const { account, deleteForm, as } = this.props
    const { processing, open } = this.state

    const ModalActions = () => {
      return deleteForm.canDelete ? (
        <Modal.Actions>
          <Grid>
            <Grid.Column width={8}>
              <SecondaryButton
                fluid
                onClick={this.handleClose}
              >
                <FormattedMessage id="form.no" />
              </SecondaryButton>
            </Grid.Column>
            <Grid.Column width={8}>
              <PrimaryButton
                disabled={processing}
                fluid
                color="red"
                onClick={this.handleConfirm}
              >
                <FormattedMessage id="form.yes" />
              </PrimaryButton>
            </Grid.Column>
          </Grid>
        </Modal.Actions>
      ) : (
        <Modal.Actions>
          <Grid>
            <Grid.Column width={16}>
              <SecondaryButton
                fluid
                onClick={this.handleClose}
              >
                <FormattedMessage id="form.cancel" />
              </SecondaryButton>
            </Grid.Column>
          </Grid>
        </Modal.Actions>
      )
    }

    const trigger =
      as === "button" ? (
        <SecondaryButton
          color="red"
          onClick={this.handleOpen}
        >
          <FormattedMessage id="delete" />
        </SecondaryButton>
      ) : (
        <span onClick={this.handleOpen}>
          <FormattedMessage id="delete" />
        </span>
      )

    return (
      <Modal
        trigger={trigger}
        open={open}
        size="small"
        onClose={this.handleClose}
      >
        <Modal.Header>
          <FormattedMessage id="settings.accounts.remove.account" />
        </Modal.Header>
        <Modal.Content>
          <RemoveAccountModalContent
            accountName={account.name}
            canDelete={deleteForm.canDelete}
            cantDeleteReason={deleteForm.cantDeleteReason}
            processing={processing}
          />
        </Modal.Content>
        <ModalActions />
      </Modal>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RemoveAccountForm)
