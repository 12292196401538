import { StockIdentifier } from "src/types/investments.types"
import { startOfDay } from "../time/time"
import { buildFetchOptions } from "./helpers"
import { store } from "src/frontend/WebAppRoot"
import { getBackendProxyUrl } from "src/common/environment"

const defaultOptions = buildFetchOptions("GET")

export const fetchStockPriceHistoryFromApi = async (
  { exchangeCode, symbol }: StockIdentifier,
  fromDate: Date,
  toDate: Date = new Date(),
) => {
  const backendUrl = getBackendProxyUrl()
  const userId = store.getState().modules.user.userId

  const fromDateStr = fromDate.toISOString().split("T")[0]
  const toDateStr = toDate.toISOString().split("T")[0]

  const res = await fetch(
    `${backendUrl}/v1/financial/stocks/${exchangeCode}:${symbol}/price-history?from=${fromDateStr}&to=${toDateStr}`,
    {
      ...defaultOptions,
      headers: {
        ...defaultOptions.headers,
        "X-UserId": userId,
      },
    },
  )

  const data = (await res.json()) as { history: { date: string; value: number }[] }

  const stockPricesData = data.history.map(({ date, value }) => ({
    date: startOfDay(date),
    stockIdentifier: `${exchangeCode}:${symbol}`,
    value,
  }))

  return stockPricesData
}

export const fetchExchangeRateFromApi = async (
  currencyCodes: string,
  fromDate: Date,
  toDate: Date = new Date(),
) => {
  const backendUrl = getBackendProxyUrl()
  const userId = store.getState().modules.user.userId

  const fromDateStr = fromDate.toISOString().split("T")[0]
  const toDateStr = toDate.toISOString().split("T")[0]

  const res = await fetch(
    `${backendUrl}/v1/financial/forex/${currencyCodes}/price-history?from=${fromDateStr}${
      toDate ? `&to=${toDateStr}` : ""
    }`,
    {
      ...defaultOptions,
      headers: {
        ...defaultOptions.headers,
        "X-UserId": userId,
      },
    },
  )

  const data = (await res.json()) as { history: { date: string; value: number }[] }

  const currencyExchangeRatesData = data.history.map(({ date, value }) => ({
    date: startOfDay(date),
    value,
    currencyCodes,
  }))

  return currencyExchangeRatesData
}
