import { createDefaultDocument } from "src/backend/common/service"
import * as recordsRepository from "./repository"
import * as accountsRepository from "src/backend/accounts/repository"
import * as categoriesRepository from "src/backend/categories/repository"
import * as categoriesService from "src/backend/categories/service"
import * as currenciesRepository from "src/backend/currencies/repository"
import * as currenciesService from "src/backend/currencies/service"
import * as labelsRepository from "src/backend/labels/repository"
import * as commonRepository from "src/backend/common/repository"
import * as contactsRepository from "src/backend/contacts/repository"
import * as envelopes from "src/backend/categories/envelopes"
import {
  addStrongTransferData,
  addWeakTransferData,
  calcRefAmount,
  getAvailableCurrencies,
  getTransferAccountId,
  getTransferCategory,
  getValidCurrencyForAccount,
  intersectRecords,
  isExpense,
  isIncome,
  isStrongTransfer,
  isTransfer,
  isWeakTransfer,
  roundAmounts,
} from "src/backend/records/helpers"
import {
  convertPeriodFilter,
  convertRecordTypesFilter,
  convertToRecordEntity,
  convertToTransferRecordVO,
  createTransferRecord,
  removeTransferAccountId,
  removeTransferId,
  updateCategoryId,
  updateOwnRefAmount,
} from "src/backend/records/converters"
import {
  isConnected,
  isNotConnected,
  isOutOfWallet,
  VIRTUAL_OUT_OF_WALLET_ACCOUNT_ID,
} from "src/backend/accounts/helpers"
import * as validator from "src/backend/records/validator"
import { roundMomentMinutesToFloor } from "src/backend/time/time"
import {
  enumValues,
  PaymentType,
  RecordIntegrationProviderSource,
  RecordState,
  RecordType,
  UserPaymentTypes,
  UserRecordState,
} from "src/backend/enums"
import { sortByPosition } from "src/backend/common/helpers"
import { pipe } from "ramda"
import { isUndefined, isUndefinedOrNull } from "src/common/utils"
import _isEmpty from "lodash/isEmpty"
import _sumBy from "lodash/sumBy"
import { Id } from "src/types/CouchDb"
import { AccountPair, Record, RecordTransfer } from "src/types/Record"
import { eachHasCompatibleDuplicity } from "src/frontend/scenes/records/solveDuplicitiesForm/helpers"
import { requestIntegrationDuplicity } from "src/backend/rest/backend"
import { User } from "src/types/User"
import { Account } from "src/types/Account"
import { updateAccountInitAmount } from "src/backend/accounts/service"
import { FilterType } from "src/types/Filter"
import { FormType } from "src/frontend/scenes/records/recordForm/enums"
import { CategoryDocument } from "src/types/Category"
import { TransferAccountsPair } from "src/backend/records/types"
import { Currency } from "src/types/Currency"
import _omitBy from "lodash/omitBy"
import _isObject from "lodash/isObject"
import { isAppBoard } from "src/common/environment"
import { inMemoryTableNames } from "src/backend/db/inMemorySqlDbSchemaBuilder"
import { v4 as uuid } from "uuid"
import { RecordFormObject, RecordFormValues } from "src/frontend/scenes/records/recordForm/types"
import _omit from "lodash/omit"
import { HashMap } from "src/types/common"
import { AssignFormValues } from "src/frontend/scenes/records/assignForm/types"

export { deleteRecordsByTransactionId, findByTransactionIds } from "./repository"

export async function getDefaultRecordFormObject(
  filter?: FilterType,
  predefinedValues?: Partial<RecordFormValues>,
): Promise<RecordFormObject> {
  const formValues = await getNewRecordFormValues(filter, predefinedValues)
  const availableCurrencies = await getAvailableCurrencies(formValues.accountId)
  const toAvailableCurrencies = []
  const fromAvailableCurrencies = []

  return {
    formValues,
    availableCurrencies,
    toAvailableCurrencies,
    fromAvailableCurrencies,
  }
}

export async function getNewRecordFormValues(
  filter?: FilterType,
  predefinedValues: Partial<RecordFormValues> = {},
): Promise<Partial<RecordFormValues>> {
  const accountId =
    filter && filter.accountIds && filter.accountIds.length === 1 ? filter.accountIds[0] : undefined

  const lastRecordAttributes = await getLastRecordFormObject(accountId)

  const labels = await labelsRepository
    .findAutoAssignLabels()
    .then((labelEntities) => labelEntities.map((label) => label._id))

  return {
    recordDate: roundMomentMinutesToFloor(15).toDate(),
    type: RecordType.EXPENSE,
    recordState: RecordState.CLEARED,
    paymentType: PaymentType.CASH,
    amount: 0,
    labels,
    ...lastRecordAttributes,
    ...predefinedValues,
  }
}

export async function getExistingRecordFormObject(recordId: Id): Promise<RecordFormObject> {
  const selectedRecord = await recordsRepository.findById(recordId)
  const commonFormValues: Partial<RecordFormValues> = {
    categoryId: selectedRecord.categoryId,
    note: selectedRecord.note,
    payee: selectedRecord.payee,
    contactId: selectedRecord.contactId,
    place: selectedRecord.place,
    latitude: selectedRecord.latitude,
    longitude: selectedRecord.longitude,
    accuracy: selectedRecord.accuracy,
    photos: selectedRecord.photos,
    paymentType: selectedRecord.paymentType,
    recordState: selectedRecord.recordState,
    labels: selectedRecord.labels,
    type: selectedRecord.type,
    recordDate: selectedRecord.recordDate,
    currencyId: selectedRecord.currencyId,
    accountId: selectedRecord.accountId,
    amount: selectedRecord.amount,
  }

  if (isTransfer(selectedRecord)) {
    const relatedRecord = await findRelatedByTransfer(selectedRecord as Record)

    const { fromAccountId, toAccountId, fromAmount, toAmount, fromCurrencyId, toCurrencyId } =
      convertToTransferRecordVO({ selectedRecord, relatedRecord })
    const [fromAvailableCurrencies, toAvailableCurrencies] = await Promise.all([
      getAvailableCurrencies(fromAccountId),
      getAvailableCurrencies(toAccountId),
    ])

    const formValues = {
      ...commonFormValues,
      fromAccountId,
      toAccountId,
      fromAmount,
      toAmount,
      fromCurrencyId,
      toCurrencyId,
      transfer: true,
    }

    return {
      availableCurrencies: [],
      fromAvailableCurrencies,
      toAvailableCurrencies,
      formValues: _omitBy(formValues, isUndefined),
    }
  }

  const availableCurrencies = await getAvailableCurrencies(selectedRecord.accountId)

  return {
    availableCurrencies,
    fromAvailableCurrencies: [],
    toAvailableCurrencies: [],
    formValues: _omitBy(commonFormValues, isUndefined),
  }
}

export async function getMultiRecordFormObject(recordIds: Id[]): Promise<RecordFormObject> {
  const selectedRecords = await recordsRepository.findByIds(recordIds)
  const formValues = intersectRecords(selectedRecords)

  return {
    availableCurrencies: [],
    fromAvailableCurrencies: [],
    toAvailableCurrencies: [],
    formValues: _omitBy(formValues, isUndefined),
  }
}

export async function getTemplateRecordFormObject(template): Promise<RecordFormObject> {
  const formValues = await getTemplateFormValues(template)
  const availableCurrencies = await getAvailableCurrencies(template.accountId)
  const toAvailableCurrencies = []
  const fromAvailableCurrencies = []

  return {
    availableCurrencies,
    toAvailableCurrencies,
    fromAvailableCurrencies,
    formValues,
  }
}

async function getTemplateFormValues(template) {
  const account: Account = await accountsRepository.findById(template.accountId)
  const currency: Currency = await getValidCurrencyForAccount(account, template.currencyId)
  const currencyId = currency._id

  const category: CategoryDocument =
    template && template.categoryId && (await categoriesService.getCategory(template.categoryId))

  const superEnvelopeId = category && category.superEnvelopeId

  const { amount, accountId, categoryId, type, paymentType, payee, note, labels, place } = template

  const filteredAttributes = _omitBy(
    {
      currencyId,
      accountId,
      categoryId,
      superEnvelopeId,
      type,
      paymentType,
      payee,
      note,
      labels,
      place,
      transfer: false,
    },
    (value) => isUndefinedOrNull(value) || (_isObject(value) && _isEmpty(value)),
  )

  const filteredAmount = _omitBy({ amount }, (value) => value === 0)

  return {
    ...filteredAttributes,
    ...filteredAmount,
  }
}

async function getLastRecordFormObject(accountId: Id) {
  const lastRecord = await recordsRepository.getLastRecords().then((records) => {
    const [record] = records.sort((recordA, recordB) => {
      return (
        recordB.recordDate.valueOf() - recordA.recordDate.valueOf() ||
        (recordB.reservedUpdatedAt as Date).valueOf() -
          (recordA.reservedUpdatedAt as Date).valueOf()
      )
    })

    return record
  })

  const account =
    lastRecord && (await accountsRepository.findById(accountId || lastRecord.accountId))
  const currency = account && (await getValidCurrencyForAccount(account, lastRecord.currencyId))
  const referentialCurrency = await currenciesRepository.getReferentialCurrency()

  if (lastRecord && account && !isConnected(account) && currency) {
    return {
      accountId: account._id,
      currencyId: currency._id,
    }
  }

  const defaultAccount = await accountsRepository.findAll().then((accounts) => {
    return accounts.sort(sortByPosition).find(isNotConnected)
  })

  const defaultCurrency =
    defaultAccount && (await getValidCurrencyForAccount(defaultAccount, referentialCurrency._id))

  return defaultAccount && defaultCurrency
    ? {
        currencyId: defaultCurrency._id,
        accountId: defaultAccount && defaultAccount._id,
      }
    : {
        currencyId: referentialCurrency._id,
      }
}

export async function saveMultipleRecords(
  formValues: RecordFormValues,
  user: User,
  recordsIds: Id[],
) {
  if (_isEmpty(recordsIds)) {
    return Promise.resolve()
  }

  const records = await recordsRepository.findByIds(recordsIds).catch()
  const relatedTransferRecords = await findMultipleRelatedByTransfer(records.filter(isTransfer))

  const updatedRecords = [...records, ...relatedTransferRecords].map((record) => ({
    ...record,
    ..._omit(formValues, ["transfer"]), // remove helper attributes, that won't be saved to all records
  }))

  let recordEntities = []
  for (const record of updatedRecords) {
    const category = await categoriesService.getCategoryByCategoryId(record.categoryId, user)
    const categoryChanged = await isCategoryChanged(record, record._id)
    // record.categoryId might be envelope id therefore it must be replaced with real category id
    // when the category is created and therefore there is no easy way to get rid of await in loop.
    const recordEntity = pipe(
      convertToRecordEntity,
      roundAmounts,
    )({ ...record, categoryId: category._id, categoryChanged })
    recordEntities = [...recordEntities, recordEntity]
  }

  return recordsRepository.updateBulk(recordEntities)
}

export async function validateRecords(
  formValues: RecordFormValues | AssignFormValues,
  recordIds: Id[],
  formType: FormType,
): Promise<void> {
  const validationDependencies = await getValidationDependencies(recordIds)

  if (formType === FormType.ADD) {
    validator.validate(formValues, validationDependencies, formType)
  } else {
    validationDependencies.records.forEach((record) => {
      const updatedRecord = { ...record, ...formValues }

      validator.validate(updatedRecord, validationDependencies, formType)
    })
  }
}

async function getValidationDependencies(recordIds: Id[]) {
  const [accounts, currencies, categories] = await Promise.all([
    accountsRepository.findAllAsHashMap(),
    currenciesRepository.findAllAsHashMap(),
    categoriesRepository.findAllAsHashMap(),
  ])
  const records = recordIds && (await recordsRepository.findByIds(recordIds))

  const envelopesMap = envelopes.getEnvelopesMap()

  return {
    accounts,
    currencies,
    categories,
    envelopes: envelopesMap,
    records,
    paymentTypes: enumValues(UserPaymentTypes),
    recordStates: enumValues(UserRecordState),
  }
}

export async function saveRecord(formValues, formType: FormType, user, recordId?: Id) {
  const record = recordId ? await recordsRepository.findById(recordId) : getDefaultRecord(user)

  if (formType === FormType.EDIT && !record) {
    throw new Error("Edit Record: record not found")
  }

  const category = await categoriesService.getCategoryByCategoryId(formValues.categoryId, user)
  const categoryChanged = await isCategoryChanged(formValues, recordId)

  const contact = formValues.contactId && (await contactsRepository.findById(formValues.contactId))

  const contactAttributes = isAppBoard()
    ? {
        contactId: contact ? contact._id : undefined,
      }
    : {}

  const recordEntity = convertToRecordEntity({
    ...record,
    ...formValues,
    ...contactAttributes,
    categoryId: category._id,
    categoryChanged,
  })

  // remove residual second part of transfer, when the new entity is not a transfer
  if (isTransfer(record) && !isTransfer(recordEntity)) {
    const pairedRecord = await recordsRepository.findRelatedByTransferId(
      record.transferId,
      record._id,
    )
    const pairedAccount =
      pairedRecord && (await accountsRepository.findById(pairedRecord.accountId))
    if (pairedRecord && !isConnected(pairedAccount)) {
      await recordsRepository.remove(pairedRecord)
    }
  }

  const currency = await currenciesRepository.findById(recordEntity.currencyId)
  // create a new record
  if (formType === FormType.ADD) {
    return createRecord(recordEntity, currency)
  }

  // update existing record
  return updateRecord(recordEntity, currency, record)
}

function createRecord(recordEntity, currency) {
  const recordEntityToCreate = pipe(updateOwnRefAmount(currency), roundAmounts)(recordEntity)

  return recordsRepository.update(recordEntityToCreate)
}

function updateRecord(recordEntity, currency, originalRecord) {
  const recordEntityToUpdate = shouldUpdateRefAmount(originalRecord, recordEntity)
    ? updateOwnRefAmount(currency)(recordEntity)
    : recordEntity

  return recordsRepository.update(roundAmounts(recordEntityToUpdate))
}

/**
 * Returns true, when record amount or currency have changed
 *
 * @param originalRecord
 * @param updatedRecord
 * @return {boolean}
 */
export function shouldUpdateRefAmount(originalRecord: Record, updatedRecord: Record) {
  return (
    originalRecord.amount !== updatedRecord.amount ||
    originalRecord.currencyId !== updatedRecord.currencyId
  )
}

async function isCategoryChanged(formValues, recordId) {
  if (!recordId) {
    return undefined
  }

  const prevRecord = await recordsRepository.findById(recordId)
  if (!prevRecord) {
    console.error(`getCategoryChanged: Record not found for record._id=${recordId}`)
  }
  return !!(
    prevRecord &&
    (prevRecord.categoryChanged || formValues.categoryId !== prevRecord.categoryId)
  )
}

export function assignRecordsIntoCategory(category: CategoryDocument, records: Record[]) {
  const recordEntities = records.reduce(updateCategoryId(category._id), [])
  return recordsRepository.updateBulk(recordEntities)
}

export async function solveDuplicities(records: Record[], recordIdToKeep: Id, user: User) {
  const recordsToRemove: Record[] = records.filter(
    (record: Record) => record._id !== recordIdToKeep,
  )

  if (eachHasCompatibleDuplicity(recordsToRemove)) {
    const account: Account = await accountsRepository.findById(recordsToRemove[0].accountId)
    const recordsTransactionIds = recordsToRemove.map((record: Record): Id => {
      return record.reservedIntegrationRemoteTransactionId
    })

    const shouldRequestDuplicity = recordsToRemove.every((record) => {
      return record.reservedIntegrationSource === RecordIntegrationProviderSource.SALTEDGE
    })

    const recordAmountSum: number = isExpense(recordsToRemove[0])
      ? -_sumBy(recordsToRemove, "amount")
      : _sumBy(recordsToRemove, "amount")

    const newInitAmount = account.initAmount + recordAmountSum

    return Promise.all([
      updateAccountInitAmount(newInitAmount, account._id),
      recordsRepository.removeBulk(recordsToRemove),
      shouldRequestDuplicity
        ? requestIntegrationDuplicity(
            recordsTransactionIds,
            user.userId,
            account.reservedIntegrationConnection.loginId,
            RecordIntegrationProviderSource.SALTEDGE,
          )
        : Promise.resolve(""),
    ])
  } else {
    throw new Error("Records are not compatible duplicities")
  }
}

export async function removeRecords(recordEntities: Record[]) {
  if (_isEmpty(recordEntities)) {
    return Promise.resolve()
  }

  const [selectedRecords, relatedRecords, accounts] = await Promise.all([
    recordsRepository.findByIds(recordEntities.map((recordEntity) => recordEntity._id)),
    findMultipleRelatedByTransfer(recordEntities.filter(isTransfer)),
    accountsRepository.findAllAsHashMap(),
  ])

  const recordsToRemove = [
    ...selectedRecords.filter((selectedRecord) => {
      return (
        selectedRecord.accountId in accounts && !isConnected(accounts[selectedRecord.accountId])
      )
    }),
    ...relatedRecords.filter((relatedRecord) => {
      return (
        !isUndefined(relatedRecord) &&
        relatedRecord.accountId in accounts &&
        !isConnected(accounts[relatedRecord.accountId])
      )
    }),
  ]

  return recordsRepository.removeBulk(recordsToRemove)
}

export function findRelatedByTransfer(record: Record): Promise<Record> {
  return recordsRepository.findRelatedByTransferId(record.transferId, record._id)
}

export function findMultipleRelatedByTransfer(records: Record[]): Promise<Record[]> {
  return recordsRepository.findMultipleRelatedByTransfer(records)
}

export function fetchRecordsByFilter(filterVO): Promise<Record[]> {
  const filter = {
    ...filterVO,
    period: convertPeriodFilter(filterVO.period),
    ...convertRecordTypesFilter(filterVO.recordTypes),
  }

  return recordsRepository.findRecordsByFilter(filter)
}

export function fetchMinMaxAmountByFilter(filterVO) {
  const filter = {
    ...filterVO,
    period: convertPeriodFilter(filterVO.period),
    ...convertRecordTypesFilter(filterVO.recordTypes),
    amount: undefined,
  }

  return commonRepository.findMinMaxAmountByFilter(filter)
}

export async function saveTransfer(formValues: RecordFormValues, user: User, recordId?: Id) {
  const allAccounts = await accountsRepository.findAllAsHashMap()
  const OOWAccount = { _id: VIRTUAL_OUT_OF_WALLET_ACCOUNT_ID } as Account

  // Transfer accounts
  const fromAccount = allAccounts[formValues.fromAccountId] || OOWAccount
  const toAccount = allAccounts[formValues.toAccountId] || OOWAccount
  const accountsPair = {
    fromAccount,
    toAccount,
  }

  // Original record selected by user to edit
  const originalRecord: Record = recordId
    ? await recordsRepository.findById(recordId)
    : getDefaultRecord(user)

  // Paired record(by transferId) if exists, if not - undefined
  const pairedRecord = (await recordsRepository.findRelatedByTransferId(
    originalRecord.transferId,
    originalRecord._id,
  )) as RecordTransfer | undefined

  // Disabled when implementing weak transfers (transferAccountId), because we are "editing" connected account - JIRA ticket: https://budgetbakers.atlassian.net/browse/WEB-332
  // if (await wasConnectedAccountsChanged(record, pairedRecord, accountsPair)) {
  //   throw Error("It's not possible to change from/to connected account")
  // }

  interface TransferCategory {
    _id: string
  }

  // Transfer attributes
  const { _id: transferCategoryId } = (await getTransferCategory(user)) as TransferCategory
  const transferId = originalRecord?.transferId || recordsRepository.newTransferId()
  const transferAccountId = getTransferAccountId(originalRecord, accountsPair)

  let enrichedTransferRecord: RecordTransfer

  // Update and save strong transfer
  if (isStrongTransfer(accountsPair)) {
    enrichedTransferRecord = pipe(addStrongTransferData(transferId, transferCategoryId))(formValues)
    return updateStrongTransferPair(
      enrichedTransferRecord,
      removeTransferAccountId(originalRecord),
      pairedRecord,
      accountsPair,
      user,
    )
  }

  // Update and save weak transfer
  if (isWeakTransfer(accountsPair)) {
    enrichedTransferRecord = addWeakTransferData(formValues, {
      transferAccountId,
      transferCategoryId,
    }) as RecordTransfer

    return saveOrRemoveWeakTransferPair(
      enrichedTransferRecord,
      removeTransferId(originalRecord),
      pairedRecord,
      accountsPair,
      user,
    )
  }
}

async function saveOrRemoveWeakTransferPair(
  enrichedRecord: RecordTransfer,
  originalRecord: Record | RecordTransfer,
  pairedRecord: RecordTransfer | undefined,
  { fromAccount, toAccount }: AccountPair,
  user: User,
) {
  const commonRefAmount = await getCommonRefAmount(enrichedRecord, { fromAccount, toAccount })
  const recordAccount = isExpense(originalRecord) ? fromAccount : toAccount

  const recordsToUpdate = []
  const recordsToDelete = []

  // handle expense/income -> transfer(weak)
  const newEnrichedTransferRecord = await createTransferRecord(
    enrichedRecord,
    recordAccount,
    enrichedRecord.currencyId,
    enrichedRecord.amount,
    commonRefAmount,
    originalRecord.type,
  )(originalRecord || getDefaultRecord(user))

  // Create new record with enriched weak transfer attributes
  recordsToUpdate.push(newEnrichedTransferRecord)

  // handle transfer(strong) -> transfer(weak)
  if (pairedRecord) {
    // Remove paired record
    recordsToDelete.push(pairedRecord)
  }

  return Promise.all([
    recordsRepository.updateBulk(recordsToUpdate.map(convertToRecordEntity)),
    recordsRepository.removeBulk(recordsToDelete.map(convertToRecordEntity)),
  ]).then(([updatedTransfers]) => updatedTransfers)
}

async function saveOrRemoveStrongTransferPair(
  enrichedRecord: RecordTransfer,
  fromTransferRecord: Record | RecordTransfer | undefined,
  toTransferRecord: Record | RecordTransfer | undefined,
  accountsPair: TransferAccountsPair,
  user: User,
) {
  const { fromAccount, toAccount } = accountsPair
  const recordsToUpdate = []
  const recordsToDelete = []

  const commonRefAmount = await getCommonRefAmount(enrichedRecord, accountsPair)

  if (!isOutOfWallet(fromAccount)) {
    const newFromTransferRecord = await createTransferRecord(
      enrichedRecord,
      fromAccount,
      enrichedRecord.fromCurrencyId,
      enrichedRecord.fromAmount,
      commonRefAmount,
      RecordType.EXPENSE,
    )(fromTransferRecord || getDefaultRecord(user))

    recordsToUpdate.push(newFromTransferRecord)
  } else if (fromTransferRecord) {
    recordsToDelete.push(fromTransferRecord)
  }

  if (!isOutOfWallet(toAccount)) {
    const newToTransferRecord = await createTransferRecord(
      enrichedRecord,
      toAccount,
      enrichedRecord.toCurrencyId,
      enrichedRecord.toAmount,
      commonRefAmount,
      RecordType.INCOME,
    )(toTransferRecord || getDefaultRecord(user))

    recordsToUpdate.push(newToTransferRecord)
  } else if (toTransferRecord) {
    recordsToDelete.push(toTransferRecord)
  }

  return Promise.all([
    recordsRepository.updateBulk(recordsToUpdate.map(convertToRecordEntity)),
    recordsRepository.removeBulk(recordsToDelete.map(convertToRecordEntity)),
  ]).then(([updatedTransfers]) => updatedTransfers)
}

function updateStrongTransferPair(
  enrichedRecord: RecordTransfer,
  originalRecord: Record | RecordTransfer,
  pairedRecord: RecordTransfer | undefined,
  accountsPair: TransferAccountsPair,
  user: User,
) {
  if (!isTransfer(originalRecord) && pairedRecord) {
    console.warn("selectedRecord: %o, relatedRecord: %o", originalRecord, pairedRecord)
    throw Error(
      "Data inconsistency error: There should not be relatedRecord when selectedRecord is not transfer!",
    )
  }

  const fromTransferRecord = isExpense(originalRecord) ? originalRecord : pairedRecord
  const toTransferRecord = isIncome(originalRecord) ? originalRecord : pairedRecord

  return saveOrRemoveStrongTransferPair(
    enrichedRecord,
    fromTransferRecord,
    toTransferRecord,
    accountsPair,
    user,
  )
}

/**
 * Returns amount and currency used to calculate refAmount for a transfer.
 *
 * Determined in order: 1) any connected account 2) any refCurrency, 3) fromAmount 4) toAmount
 * @param record
 * @param fromAccount
 * @param toAccount
 * @return {Promise<*[]>}
 */
async function getCommonRefAmount(
  record: RecordTransfer,
  { fromAccount, toAccount }: TransferAccountsPair,
) {
  const { _id: refCurrencyId } = await currenciesService.getReferentialCurrency()

  const isFromAmountInRefCurrency =
    !isOutOfWallet(fromAccount) && record.fromCurrencyId === refCurrencyId
  const isToAmountInRefCurrency = !isOutOfWallet(toAccount) && record.toCurrencyId === refCurrencyId

  if (isConnected(fromAccount)) {
    return getAmountAndCurrency(record.fromAmount, record.fromCurrencyId)
  } else if (isConnected(toAccount)) {
    return getAmountAndCurrency(record.toAmount, record.toCurrencyId)
  } else if (isFromAmountInRefCurrency) {
    return getAmountAndCurrency(record.fromAmount, record.fromCurrencyId)
  } else if (isToAmountInRefCurrency) {
    return getAmountAndCurrency(record.toAmount, record.toCurrencyId)
  } else if (!isOutOfWallet(fromAccount)) {
    return getAmountAndCurrency(record.fromAmount, record.fromCurrencyId)
  } else if (!isOutOfWallet(toAccount)) {
    return getAmountAndCurrency(record.toAmount, record.toCurrencyId)
  }

  return getAmountAndCurrency(record.fromAmount, record.fromCurrencyId)
}

async function getAmountAndCurrency(amount: number, currencyId: Id): Promise<number> {
  const currency = await currenciesRepository.findById(currencyId)
  return calcRefAmount(amount, currency)
}

export function getDefaultRecord(user: User): RecordTransfer {
  const modelType = inMemoryTableNames.RECORD
  // Record document id does not start with '-'
  const id = `${modelType}_${uuid()}`

  return createDefaultDocument(id, modelType, user) as unknown as RecordTransfer
}

export async function adjustTransferAmount(
  amountName: "fromAmount" | "toAmount",
  value: number,
  formValues: RecordFormValues,
  firstSelectedAmountName: string,
  isRecordConnected: boolean,
): Promise<HashMap<number>> {
  // clone amounts when both fields have same currency
  if (
    formValues.fromCurrencyId === formValues.toCurrencyId ||
    (!isRecordConnected && value === 0)
  ) {
    return {
      fromAmount: value,
      toAmount: value,
    }

    // to force currency ratio
  } else if (amountName === firstSelectedAmountName && !isRecordConnected) {
    const [fromCurrency, toCurrency] = await Promise.all([
      currenciesRepository.findById(formValues.fromCurrencyId),
      currenciesRepository.findById(formValues.toCurrencyId),
    ])

    const oppositeAmountName = amountName === "fromAmount" ? "toAmount" : "fromAmount"

    const currencyRate =
      formValues.fromAmount && formValues.toAmount
        ? formValues.toAmount / formValues.fromAmount
        : (toCurrency.ratioToReferential || 1) / (fromCurrency.ratioToReferential || 1)

    return amountName === "fromAmount"
      ? {
          [amountName]: value,
          [oppositeAmountName]: value * currencyRate,
        }
      : {
          [amountName]: value,
          [oppositeAmountName]: value / currencyRate,
        }

    // to edit only selected field
  } else {
    return { [amountName]: value }
  }
}

export function getRecordsCount(): Promise<[number, number]> {
  return Promise.all([
    recordsRepository.fetchRecordsCount(),
    recordsRepository.fetchUnknownRecordsCount(),
  ])
}
