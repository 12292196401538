import React from "react"
import styles from "./AuthPageLayout.module.less"
import { mergeClasses } from "src/common/utils"
import MarketingBanner from "./MarketingBanner/MarketingBanner"
import AuthSectionLayout from "./AuthSectionLayout"
import { getAppleOAuthId } from "src/common/environment"

interface Props {
  className?: string
  children: React.ReactNode | string
  size?: "small" | "medium" | "large" | "larger"
  transparent?: boolean
  inverted?: boolean
}

AuthPageLayout.defaultProps = {
  className: undefined,
  size: "medium",
  transparent: false,
  inverted: false,
}

export default function AuthPageLayout({
  children,
  inverted: invertedStyle,
  transparent,
  className,
}: Props) {
  /** Init configuration for Apple sign in. 
  For more check the following article: https://dev.to/heyitsarpit/how-to-add-signin-with-apple-on-your-website-43m9  */
  const AppleID = (window as any)?.AppleID

  AppleID.auth.init({
    clientId: getAppleOAuthId(), // This is the service ID we created.
    scope: "name email", // To tell apple we want the user name and emails fields in the response it sends us.
    redirectURI: `${
      window.location.hostname === "localhost"
        ? "https://web-dev.budgetbakers.com"
        : window.location.origin
    }/dashboard`, // As registered along with our service ID
    usePopup: true, // Important if we want to capture the data apple sends on the client side.
  })

  return (
    <div
      className={mergeClasses(
        styles.authPageLayout,
        className,
        transparent && styles.transparent,
        invertedStyle && styles.inverted,
      )}
    >
      <MarketingBanner />
      <AuthSectionLayout
        children={children}
        invertedStyle={invertedStyle}
      />
    </div>
  )
}
