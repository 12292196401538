/* eslint-disable no-undef */
import isMobile from 'ismobilejs/dist/isMobile.min'

export const isPhone = isMobile.phone

export const isAndroid = isMobile.android.device

export const isDesktop = !isPhone

export const isOpera = (!!window.opr && !!window.opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0

// Firefox 1.0+
export const isFirefox = typeof InstallTrigger !== 'undefined'

// Safari 3.0+ "[object HTMLElementConstructor]"
export const isSafari = /constructor/i.test(window.HTMLElement)
  || (function checkSafari(p) {
    return p.toString() === '[object SafariRemoteNotification]'
  }(!window.safari || (typeof safari !== 'undefined' && safari.pushNotification)))

// Internet Explorer 6-11
export const isIE = /* @cc_on!@ */false || !!document.documentMode

// Edge 20+
export const isEdge = !isIE && !!window.StyleMedia

// Chrome 1+
export const isChrome = !!window.chrome && !!window.chrome.webstore

// Blink engine detection
export const isBlink = (isChrome || isOpera) && !!window.CSS

export const isSupported = !isPhone && (isChrome || isFirefox || isSafari)

export const isMac = navigator && navigator.platform && navigator.platform.toLowerCase().startsWith('mac')
