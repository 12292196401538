import { Id } from 'src/types/CouchDb'
import { Label, LabelView } from 'src/types/Label'
import { isSystemLabel } from 'src/backend/labels/helpers'

export function convertLabelsView(labelIds: Array<Id>, labels: { [key: string]: Label }): Array<LabelView> {
  return labelIds
    ? labelIds
      .filter((labelId: Id): boolean => !!labels[labelId])
      .filter((labelId: Id): boolean => !isSystemLabel(labels[labelId]))
      .map((labelId: Id): LabelView => {
        const { _id, name, color, systemLabelType } = labels[labelId]
        return { _id, name, color, systemLabelType }
      })
    : []
}
