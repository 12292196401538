import { RootState } from 'src/types/State'
import { createSelector } from 'reselect'
import { selectBilling } from 'src/frontend/scenes/billing/selectors'
import { Billing } from 'src/types/Billing'
import BraintreeProduct = Billing.BraintreeProduct
import { isRecurringProduct } from 'src/frontend/scenes/billing/product/helpers'

export const selectBillingProduct = (state: RootState) => selectBilling(state).product
export const selectBraintreeRecurringProducts = (state: RootState) => selectBillingProduct(state).recurringProducts
export const selectBraintreeLifetimeProduct = (state: RootState) => selectBillingProduct(state).lifetimeProduct
export const selectSelectedProductId = (state: RootState) => selectBillingProduct(state).selectedProductId
export const selectProductsLoading = (state: RootState) => selectBillingProduct(state).productsLoading
export const selectProductsError = (state: RootState) => selectBillingProduct(state).error

export const selectSelectedProduct: (state: RootState) => BraintreeProduct = createSelector(
  [selectBraintreeRecurringProducts, selectBraintreeLifetimeProduct, selectSelectedProductId],
  (recurringProducts, lifetimeProduct, selectedProductId) => {
    if (lifetimeProduct?.product?.productId === selectedProductId) {
      return lifetimeProduct
    }

    return recurringProducts?.find((braintreeProduct) => {
      return braintreeProduct.product.productId === selectedProductId
    })
  },
)

export const selectIsSelectedProductRecurring = createSelector([selectSelectedProduct], isRecurringProduct)

export const selectAllBraintreeProducts = createSelector([
  selectBraintreeRecurringProducts,
  selectBraintreeLifetimeProduct,
], (recurringProducts, lifetimeProduct) => {
  return [
    ...(recurringProducts || []),
    ...(lifetimeProduct ? [lifetimeProduct] : []),
  ]
})
