import spected from 'spected'
import * as commonValidator from 'src/backend/common/validator'
import _omit from 'lodash/omit'
import _isEmpty from 'lodash/isEmpty'
import { WalletFormValidationError } from 'src/backend/errors'


export function validate(filter, validationDependencies) {
  const {
    filters,
  } = validationDependencies

  const requiredFields = [
    'name',
  ]

  const filterWithRequiredFields = {
    ...commonValidator.addRequiredFieldsAsUndefined(filter, requiredFields),
  }

  const FilterSpec = {
    name: [
      [commonValidator.isNotEmpty, 'form.error.enter_name'],
      [commonValidator.hasUniqueName(_omit(filters, filter._id)), 'form.error.name_exists'],
      [commonValidator.isNotLongerThan(commonValidator.NAME_MAX_LENGTH), 'form.error.name_too_long'],
    ],
  }

  const validationResult = spected(FilterSpec, filterWithRequiredFields)
  const errors = commonValidator.convertValidationResultToErrors(validationResult)
  if (!_isEmpty(errors)) {
    throw new WalletFormValidationError(errors)
  }
}
