import { createSelector } from 'reselect'
import { selectUser } from 'src/frontend/modules/user/selectors'
import { getUserFeatureValue } from 'src/backend/user/service'
import { Feature } from 'src/backend/user/billingFeatures'
import { selectSettings } from 'src/frontend/scenes/settings/selectors'
import { RootState } from 'src/types/State'
import { LabelsFormState } from 'src/frontend/scenes/settings/labels/reducer'
import { User } from 'src/types/User'
import { isAppBoard } from 'src/common/environment'

export const selectLabels = (state: RootState): LabelsFormState => selectSettings(state).labels

const getLabelsColorEnabledFlag = (user: User): boolean => {
  return isAppBoard() || getUserFeatureValue(user, Feature.CUSTOM_LABELS_COLOR) as boolean
}

export const selectLabelsColorEnabled: (RootState) => boolean = createSelector(selectUser, getLabelsColorEnabledFlag)
