import React, { useEffect } from "react"
import styles from "./Billing.module.less"
import { Loader } from "semantic-ui-react"
import _isEmpty from "lodash/isEmpty"
import { fetchTransactionHistory } from "src/frontend/scenes/billing/transactionHistory/actions"
import { RootState } from "src/types/State"
import {
  selectReceiptsTransactions,
  selectTransactionHistoryLoading,
} from "src/frontend/scenes/billing/transactionHistory/selectors"
import { connect } from "react-redux"
import { BraintreeTransaction } from "src/frontend/scenes/billing/transactionHistory/types"
import { User } from "src/types/User"
import { selectUser } from "src/frontend/modules/user/selectors"
import YourPlan from "src/frontend/scenes/settings/billing/components/YourPlan"
import { isBraintreeSubscriptionPremium } from "src/backend/user/service"
import { getActiveSubscriptionDetails } from "src/frontend/scenes/billing/activeSubscription/actions"
import {
  selectActiveBillingAddress,
  selectActivePaymentMethod,
  selectActiveSubscription,
  selectActiveSubscriptionLoading,
} from "src/frontend/scenes/billing/activeSubscription/selectors"
import {
  BraintreeSubscription,
  BraintreeSubscriptionStatus,
} from "src/frontend/scenes/billing/activeSubscription/types"
import PaymentMethod from "src/frontend/scenes/settings/billing/components/PaymentMethod"
import { isUndefinedOrNull } from "src/common/utils"
import { BraintreePaymentMethod } from "src/frontend/scenes/billing/paymentMethod/types"
import { BraintreeBillingAddress } from "src/frontend/scenes/billing/billingInformation/types"
import * as actions from "src/frontend/scenes/billing/paymentMethod/actions"
import { selectPaymentMethodSuccess } from "src/frontend/scenes/billing/paymentMethod/selectors"
import { isAppBoard } from "src/common/environment"
import BillingAddress from "src/frontend/scenes/settings/billing/components/BillingAddress"
import TransactionsTable from "src/frontend/scenes/settings/billing/components/TransactionsTable"
import { BrowserStorageAction, redirectAfterInitUrlLocalStorage } from "src/backend/db/localStorage"
import { Redirect } from "react-router"

interface Props {
  user: User
  transactions: BraintreeTransaction[]
  activeSubscriptionLoading: boolean
  transactionHistoryLoading: boolean
  paymentMethodSuccess: boolean

  billingAddress: BraintreeBillingAddress
  activeSubscription: BraintreeSubscription
  activePaymentMethod: BraintreePaymentMethod
  fetchTransactions: Function
  fetchActiveSubscription: Function
  clearToken: Function
}

function mapStateToProps(state: RootState) {
  return {
    user: selectUser(state),
    activePaymentMethod: selectActivePaymentMethod(state),
    activeSubscription: selectActiveSubscription(state),
    transactions: selectReceiptsTransactions(state),
    billingAddress: selectActiveBillingAddress(state),
    paymentMethodSuccess: selectPaymentMethodSuccess(state),
    activeSubscriptionLoading: selectActiveSubscriptionLoading(state),
    transactionHistoryLoading: selectTransactionHistoryLoading(state),
  }
}

const mapDispatchToProps = {
  fetchTransactions: fetchTransactionHistory,
  fetchActiveSubscription: getActiveSubscriptionDetails,
  clearToken: actions.clearToken,
}

function Billing({
  user,
  activeSubscription,
  activePaymentMethod,
  billingAddress,
  transactions,
  paymentMethodSuccess,
  activeSubscriptionLoading,
  transactionHistoryLoading,
  fetchTransactions,
  fetchActiveSubscription,
  clearToken,
}: Props) {
  useEffect(() => {
    fetchTransactions()

    if (!activeSubscriptionLoading) {
      fetchActiveSubscription()
    }
  }, [])

  useEffect(() => {
    if (paymentMethodSuccess) {
      clearToken()
    }
  }, [paymentMethodSuccess])

  const shouldRedirect = () => {
    if (window.location.href.includes("/settings/billing?origin=banner")) return true
    return redirectAfterInitUrlLocalStorage(BrowserStorageAction.GET_ITEM)
  }

  if (shouldRedirect()) {
    window.history.pushState("settings", "Settings", "/settings/billing/")
    window.history.pushState("dashboard", "Dashboard", "/dashboard")
    return <Redirect to="/settings/billing/choose-plan" />
  }

  if (
    (activeSubscriptionLoading && !activeSubscription) ||
    (transactionHistoryLoading && _isEmpty(transactions))
  ) {
    return (
      <div className={styles.loadingContainer}>
        <Loader
          inline="centered"
          active
        />
      </div>
    )
  }

  return (
    <div className={styles.billing}>
      <YourPlan
        user={user}
        subscription={activeSubscription}
      />

      {isBraintreeSubscriptionPremium(user) &&
        !isUndefinedOrNull(activeSubscription) &&
        (activeSubscription.status === BraintreeSubscriptionStatus.ACTIVE ||
          activeSubscription.status === BraintreeSubscriptionStatus.PAST_DUE) && (
          <PaymentMethod
            paymentMethod={activePaymentMethod}
            activeSubscription={activeSubscription}
          />
        )}

      {isAppBoard() && isBraintreeSubscriptionPremium(user) && (
        <BillingAddress billingAddress={billingAddress} />
      )}

      <TransactionsTable transactions={transactions} />
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(Billing)
