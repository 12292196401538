/* eslint-disable react/prop-types */
import React from "react"
import { connect } from "react-redux"
import { Modal, Grid, Loader } from "semantic-ui-react"
import { FormattedMessage, formatMessage } from "src/frontend/modules/intl"
import * as selectors from "../selectors"
import * as actions from "../actions"
import { PrimaryButton, SecondaryButton } from "src/frontend/components/Buttons/Buttons"

const mapStateToProps = (state) => {
  return {
    currenciesForm: selectors.selectCurrenciesForm(state),
    deleteForm: selectors.selectCurrenciesDeleteForm(state),
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    handleDeleteCurrency(event, currencyId) {
      console.log("handleDeleteCurrency", event)
      dispatch(actions.deleteCurrency(currencyId))
    },
    handleDeleteFormOpen(event, currency) {
      console.log("handleDeleteFormOpen", event)
      dispatch(actions.deleteFormOpen(currency))
    },
    handleDeleteFormClose(event) {
      console.log("handleDeleteFormClose", event)
      dispatch(actions.deleteFormClose())
    },
  }
}

class RemoveCurrencyForm extends React.Component {
  state = { processing: false, open: false }

  handleConfirm = (e) => {
    this.setState({ processing: true })
    this.props.handleDeleteCurrency(e, this.props.currency._id)
  }

  handleOpen = (e) => {
    this.setState({ processing: false, open: true })
    this.props.handleDeleteFormOpen(e, this.props.currency)
  }

  handleClose = (e) => {
    this.setState({ processing: false, open: false })
    this.props.handleDeleteFormClose(e, this.props.currency)
  }

  render() {
    const { currency, deleteForm } = this.props
    const { processing, open } = this.state

    const confirmQuestion = (
      <div>
        <p>
          <FormattedMessage
            id="settings.currencies.remove_currency_text"
            values={{
              currencyCode: <strong>{currency.code.toUpperCase()}</strong>,
            }}
          />
        </p>
      </div>
    )

    const ReferencesList = () => {
      return deleteForm.referencedTypes ? (
        <span>
          &nbsp;
          {deleteForm.referencedTypes.map((reference) => formatMessage(reference)).join(", ")}
        </span>
      ) : null
    }

    const referencesNotice = (
      <div>
        <FormattedMessage id="settings.currencies.references_exist" />
        <strong>
          <ReferencesList />
        </strong>
      </div>
    )

    const loading = (
      <Loader
        active
        inline="centered"
      />
    )

    const ModalActions = () => {
      return deleteForm.canDelete ? (
        <Modal.Actions>
          <Grid>
            <Grid.Column width={8}>
              <SecondaryButton
                fluid
                onClick={this.handleClose}
              >
                <FormattedMessage id="form.no" />
              </SecondaryButton>
            </Grid.Column>
            <Grid.Column width={8}>
              <PrimaryButton
                disabled={processing}
                fluid
                color="red"
                onClick={this.handleConfirm}
              >
                <FormattedMessage id="form.yes" />
              </PrimaryButton>
            </Grid.Column>
          </Grid>
        </Modal.Actions>
      ) : (
        <Modal.Actions>
          <Grid>
            <Grid.Column width={16}>
              <SecondaryButton
                fluid
                onClick={this.handleClose}
              >
                <FormattedMessage id="form.cancel" />
              </SecondaryButton>
            </Grid.Column>
          </Grid>
        </Modal.Actions>
      )
    }

    return (
      <Modal
        trigger={
          <span onClick={this.handleOpen}>
            <FormattedMessage id="delete" />
          </span>
        }
        open={open}
        size="small"
        onClose={this.handleClose}
      >
        <Modal.Header>
          <FormattedMessage id="settings.currencies.remove_currency" />
        </Modal.Header>
        <Modal.Content>
          {!deleteForm.canDelete ? referencesNotice : !processing ? confirmQuestion : loading}
        </Modal.Content>
        <ModalActions />
      </Modal>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RemoveCurrencyForm)
