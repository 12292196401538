import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import _isEmpty from 'lodash/isEmpty'
import {
  selectAttributeMappingError,
  selectColumnHeaders,
  selectColumns,
  selectIsMultipleAttributeMappingErrors,
} from 'src/frontend/scenes/imports/mapping/selectors'
import { selectSelectedAccountCurrencyCode } from 'src/frontend/scenes/imports/account/selectors'
import ErrorMessage from 'src/frontend/scenes/imports/mapping/components/ErrorMessage'
import ErrorTitle from 'src/frontend/scenes/imports/mapping/components/ErrorTitle'
import { filterTokens, formatErrorTitle, getHeaderName } from 'src/frontend/scenes/imports/mapping/helpers'
import { ImportErrorType } from 'src/backend/enums'
import { MappingError } from 'src/frontend/scenes/imports/mapping/components/commonProptypes'
import './MappingErrorPanel.less'
import { FormattedMessage } from 'src/frontend/modules/intl'

const mapStateToProps = state => ({
  attributeMappingError: selectAttributeMappingError(state),
  columnHeaders: selectColumnHeaders(state),
  assignedColumns: selectColumns(state),
  accountCurrencyCode: selectSelectedAccountCurrencyCode(state),
  multipleErrors: selectIsMultipleAttributeMappingErrors(state),
})

const MappingErrorPanel = ({
  multipleErrors,
  attributeMappingError,
  columnHeaders,
  assignedColumns,
  accountCurrencyCode,
}) => (
  <div className="mapping-error-panel">
    {!_isEmpty(attributeMappingError) && (
      <div>
        {multipleErrors && (
        <ErrorTitle errorType={ImportErrorType.MULTIPLE_ERROR_TYPE} />
        )}
        {filterTokens(attributeMappingError.tokens).map(({ type, value, colIndex }, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <div key={index}>

            {index === 0 && multipleErrors && (
              <div className="error-text">
                <FormattedMessage id="imports.importErrorType.unexpected_format" />
              </div>
            )}

            {!multipleErrors && !_isEmpty(attributeMappingError) && (
            <ErrorTitle errorType={type} headerName={getHeaderName(columnHeaders, colIndex)} />
            )}

            <div className="error-text">
              {!multipleErrors ? (
                <ErrorMessage
                  columns={assignedColumns}
                  headers={columnHeaders}
                  errorLine={attributeMappingError.line}
                  errorColumn={colIndex}
                  errorType={type}
                  value={value}
                  accountCurrencyCode={accountCurrencyCode}
                />
              )
                : formatErrorTitle(type, getHeaderName(columnHeaders, colIndex))}
            </div>
          </div>
        ))}
      </div>
    )}
  </div>
)

MappingErrorPanel.propTypes = {
  multipleErrors: PropTypes.bool.isRequired,
  attributeMappingError: MappingError.isRequired,
  columnHeaders: PropTypes.arrayOf(PropTypes.shape({ colName: PropTypes.string.isRequired }).isRequired).isRequired,
  assignedColumns: PropTypes.arrayOf(PropTypes.shape({ type: PropTypes.number.isRequired }).isRequired).isRequired,
  accountCurrencyCode: PropTypes.string.isRequired,
}

export default connect(mapStateToProps)(MappingErrorPanel)
