import React, { useEffect } from "react"
import styles from "src/frontend/scenes/dashboard/components/Dashboard.module.less"
import Filter from "src/frontend/scenes/dashboard/components/Filter/Filter"
import WidgetContainer from "src/frontend/scenes/dashboard/components/WidgetContainer"
import { RootState } from "src/types/State"
import {
  selectShouldRefreshAccounts,
  selectShouldRefreshDashboard,
} from "src/frontend/modules/moduleStatus/selectors"
import * as addVoucherActions from "src/frontend/modules/modals/actions"
import {
  refreshAccountsStatus,
  refreshDashboardStatus,
} from "src/frontend/modules/moduleStatus/actions"
import { connect, useDispatch, useSelector } from "react-redux"
import { RouteComponentProps } from "react-router"
import FinishedOnBoarding from "src/frontend/scenes/onBoarding/firstRun/components/FinishedOnBoarding"
import { handleProviderConnection } from "src/frontend/scenes/integrations/newConnection/actions"
import { BrowserStorageAction, redirectAfterInitUrlLocalStorage } from "src/backend/db/localStorage"
import { Redirect } from "react-router"
import * as localStorageService from "src/backend/db/localStorage"
import { trackWebAppDashboard } from "src/common/mixpanel"

type Actions = {
  openAddVoucherModal: Function
}
interface Props {
  shouldRefreshDashboard: boolean
  shouldRefreshAccounts: boolean
  refreshDashboard: Function
  refreshAccounts: Function
}

function mapStateToProps(state: RootState) {
  return {
    shouldRefreshDashboard: selectShouldRefreshDashboard(state),
    shouldRefreshAccounts: selectShouldRefreshAccounts(state),
  }
}

interface RouteProps
  extends RouteComponentProps<{ loginId: string; location: any; history: any }> {}

interface AllProps extends RouteProps, Props, Actions {}

const mapDispatchToProps = {
  refreshDashboard: refreshDashboardStatus,
  refreshAccounts: refreshAccountsStatus,
  openAddVoucherModal: addVoucherActions.openModalAddVoucher,
}

function Dashboard({
  shouldRefreshDashboard,
  shouldRefreshAccounts,
  refreshDashboard,
  refreshAccounts,
}: AllProps) {
  const dispatch = useDispatch()
  const billingRedirectPromo = redirectAfterInitUrlLocalStorage(BrowserStorageAction.GET_ITEM)

  useEffect(() => {
    if (shouldRefreshDashboard) {
      refreshDashboard()
    }
    if (shouldRefreshAccounts) {
      refreshAccounts()
    }
  }, [shouldRefreshDashboard, shouldRefreshAccounts])

  // Handle return to application from integrator(SaltEdge, etc.) when connecting bank account
  useEffect(() => {
    if (
      !(window.location.pathname === "/dashboard/connection/finish" ||
      window.location.pathname === "/integration")
    )
      return

    const queryParams = new URLSearchParams(window.location.search)
    const loginIdFromQuery = queryParams.get("loginId") || queryParams.get("connection_id") // difference between BB, SE and Yodlee_fastlink

    if (!loginIdFromQuery) return

    dispatch(handleProviderConnection(loginIdFromQuery))
    window.history.pushState("dashboard", "Dashboard", "/dashboard")
    if (localStorageService.getAppStart()) {
      localStorageService.setAppStart(false)
      trackWebAppDashboard()
    }
  }, [])

  if (billingRedirectPromo) {
    return <Redirect to="/settings/billing/choose-plan" />
  }

  const user = useSelector((state: RootState) => state.modules.user)

  return (
    <div className={styles.dashboard}>
      <header className={styles.filterPanel}>
        <Filter />
      </header>
      <div>
        <WidgetContainer />
      </div>
      <FinishedOnBoarding />
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)
