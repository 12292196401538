export enum IntegrationSource {
  BUDGETBAKERS,
  SALTEDGE,
  CSAS,
  YODLEE,
  SALTEDGE_PARTNERS,
  YODLEE_FASTLINK,
  FASTLINK_COBRAND,
}

export const integrationSourceName = {
  [IntegrationSource.BUDGETBAKERS]: "budgetbakers",
  [IntegrationSource.SALTEDGE]: "saltedge",
  [IntegrationSource.CSAS]: "csas",
  [IntegrationSource.YODLEE]: "yodlee",
  [IntegrationSource.SALTEDGE_PARTNERS]: "saltedge_partners",
  [IntegrationSource.YODLEE_FASTLINK]: "yodlee_fastlink",
  [IntegrationSource.FASTLINK_COBRAND]: "fastlink_cobrand",
} as const

export type IntegrationSourceName = typeof integrationSourceName[IntegrationSource]