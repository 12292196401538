import { RootState } from 'src/types/State'
import { selectBilling } from 'src/frontend/scenes/billing/selectors'
import { createSelector } from 'reselect'

export const selectActiveSubscriptionState = (state: RootState) => selectBilling(state).activeSubscription
export const selectActiveSubscriptionLoading = (state: RootState) => selectActiveSubscriptionState(state).loading

export const selectActiveSubscriptionDetails = (state: RootState) => {
  return selectActiveSubscriptionState(state).subscriptionDetails
}

export const selectActiveSubscription = createSelector([selectActiveSubscriptionDetails], (subscriptionDetails) => {
  return subscriptionDetails?.subscription
})

export const selectActivePaymentMethod = createSelector([selectActiveSubscriptionDetails], (subscriptionDetails) => {
  return subscriptionDetails?.defaultPaymentMethod
})

export const selectActiveBillingAddress = createSelector([selectActiveSubscriptionDetails], (subscriptionDetails) => {
  return subscriptionDetails?.billingAddress
})
