import {
  BraintreeError,
  Client,
  HostedFields,
  HostedFieldsFieldDataFields,
  HostedFieldsTokenizePayload,
  PayPal,
  ThreeDSecure,
} from 'braintree-web'
import { MutableRefObject, useCallback, useEffect, useState } from 'react'
import { create as createPaypal } from 'braintree-web/paypal-checkout'
import { create as createBraintreeClient } from 'braintree-web/client'
import { create as createBraintreeHostedFields } from 'braintree-web/hosted-fields'
import { create as createThreeDSecure } from 'braintree-web/three-d-secure'
import { isRecurringProduct } from 'src/frontend/scenes/billing/product/helpers'
import { Billing } from 'src/types/Billing'
import BraintreeProduct = Billing.BraintreeProduct
import { PaymentMethodFormType } from 'src/frontend/scenes/billing/paymentMethod/components/types'
import { getBraintreeErrorMessage } from 'src/frontend/scenes/billing/paymentMethod/helpers'

export function usePaypalInstance(braintreeClient: Client): PayPal {
  const [paypalCheckoutInstance, setPaypalCheckoutInstance] = useState<PayPal>(null)

  useEffect(() => {
    if (braintreeClient) {
      createPaypal({ client: braintreeClient }).then(setPaypalCheckoutInstance)

      return () => {
        if (paypalCheckoutInstance) {
          paypalCheckoutInstance.teardown()
          setPaypalCheckoutInstance(null)
        }
      }
    }
  }, [braintreeClient])

  return paypalCheckoutInstance
}

export function useBraintreeClient(clientToken: string): Client {
  const [braintreeInstance, setBraintreeInstance] = useState<Client>(null)

  useEffect(() => {
    if (clientToken) {
      createBraintreeClient({
        authorization: clientToken,
      }).then(setBraintreeInstance)
    }
  }, [clientToken])

  return braintreeInstance
}

export function useHostedFields(
  braintreeClient: Client,
  htmlModalRef: MutableRefObject<HTMLDivElement>,
  onTokenize: (nonce: string) => void,
  onError: (message: string) => void,
  formType: PaymentMethodFormType,
  selectedProduct?: BraintreeProduct,
) {
  const [hostedFieldsInstance, setHostedFieldsInstance] = useState<HostedFields>(null)
  const [threeDInstance, setThreeDInstance] = useState<ThreeDSecure>(null)
  const [fieldsValidity, setFieldsValidity] = useState<HostedFieldsFieldDataFields>(null)
  const [selectedCardType, setSelectedCardType] = useState(null)
  const [showThreeDModal, setShowThreeDModal] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [cardholderName, setCardholderName] = useState('')

  const isFormReady = !!braintreeClient && !!hostedFieldsInstance

  const handleSubmit = useCallback(async () => {
    if (isFormReady) {
      setLoading(true)

      try {
        const tokenizePayload = await hostedFieldsInstance.tokenize({
          vault: isRecurringProduct(selectedProduct),
          cardholderName,
        })
          .then((payload: HostedFieldsTokenizePayload) => {
            if (formType === PaymentMethodFormType.UPDATE) {
              // skip 3d secure lookup when updating card
              return payload
            }

            return threeDInstance.verifyCard({
              nonce: payload.nonce,
              amount: selectedProduct.price,
              // @ts-ignore wrong typings
              bin: (payload.details as any).bin,
              addFrame: (err?: BraintreeError, iframe?: HTMLIFrameElement) => {
                if (!err) {
                  setShowThreeDModal(true)
                  htmlModalRef?.current?.appendChild(iframe)
                } else {
                  setLoading(false)
                }
              },
              removeFrame: () => {
                setLoading(false)
                setShowThreeDModal(null)
              },
              onLookupComplete: (_data, next) => {
                // use `data` here, then call `next()`
                next()
              },
            })
          })

        setLoading(false)
        onTokenize(tokenizePayload.nonce)
      } catch (error) {
        setLoading(false)
        onError(getBraintreeErrorMessage(error))
      }
    }
  }, [isFormReady, cardholderName, hostedFieldsInstance, selectedProduct, threeDInstance, htmlModalRef])

  useEffect(() => {
    if (braintreeClient) {
      createBraintreeHostedFields({
        client: braintreeClient,
        styles: {
          input: {
            'font-size': '16px',
            color: '#354052',
          },
          '::placeholder': {
            color: '#7f8fa4',
          },
          ':focus::placeholder': {
            color: '#8898b2',
          },
          // '.invalid': {
          //   'color': '#FF0000',
          // },
        },
        fields: {
          number: {
            selector: '#card-number',
            placeholder: '•••• •••• •••• ••••',
          },
          cvv: {
            selector: '#cvv',
            placeholder: '•••',
            maskInput: true,
          },
          expirationDate: {
            selector: '#expiration-date',
            placeholder: 'MM/YY',
          },
        },
      }).then((btHostedFields: HostedFields) => {
        btHostedFields.on('validityChange', (event) => setFieldsValidity(event.fields))
        btHostedFields.on('inputSubmitRequest', handleSubmit)
        btHostedFields.on('cardTypeChange', (event) => {
          // card type is narrowed down to one
          if (event.cards.length === 1 && event.cards[0] && (event.cards[0] as any).supported) {
            setSelectedCardType(event.cards[0])
          } else {
            setSelectedCardType(null)
          }
        })

        setHostedFieldsInstance(btHostedFields)
      })

      createThreeDSecure({ client: braintreeClient, version: 2 }).then(setThreeDInstance)
    }

    return () => {
      if (hostedFieldsInstance) {
        hostedFieldsInstance.teardown()
        setHostedFieldsInstance(null)
      }
      if (threeDInstance) {
        threeDInstance.teardown()
        setThreeDInstance(null)
      }
    }
  }, [braintreeClient])

  return [
    hostedFieldsInstance,
    threeDInstance,
    selectedCardType,
    fieldsValidity,
    isFormReady,
    showThreeDModal,
    loading,
    setLoading,
    cardholderName,
    setCardholderName,
    setShowThreeDModal,
    handleSubmit,
  ]
}
