import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import {
  selectPaymentMethodSuccess,
} from 'src/frontend/scenes/billing/paymentMethod/selectors'
import { Billing } from 'src/types/Billing'
import BraintreeProduct = Billing.BraintreeProduct
import { selectSelectedProduct } from 'src/frontend/scenes/billing/product/selectors'
import { isPaidPremium } from 'src/backend/user/service'
import { selectUser } from 'src/frontend/modules/user/selectors'
import { User } from 'src/types/User'
import PaymentMethod from 'src/frontend/scenes/billing/paymentMethod/components/PaymentMethod'
import { PaymentMethodFormType } from 'src/frontend/scenes/billing/paymentMethod/components/types'
import * as mixpanel from 'src/common/mixpanel'
import * as billingAactions from 'src/frontend/scenes/billing/paymentMethod/actions'
import * as modalActions from 'src/frontend/scenes/billing/billingModal/actions'
import { BillingModalStage } from 'src/frontend/scenes/billing/billingModal/enums'
import { BillingUIMethod } from 'src/frontend/scenes/billing/enums'


function mapStateToProps(state) {
  return {
    selectedProduct: selectSelectedProduct(state),
    success: selectPaymentMethodSuccess(state),
    user: selectUser(state),
  }
}

const mapDispatchToProps = {
  submitTransaction: billingAactions.submitTransaction,
  closeModal: modalActions.closeBillingModal,
  gotoStage: modalActions.goToBillingStage,
}

interface Props {
  selectedProduct: BraintreeProduct,
  success: boolean
  user: User

  closeModal: () => void
  gotoStage: (stage: BillingModalStage) => void
  submitTransaction: (nonce: string) => void
}

function PaymentMethodModalContent({
  selectedProduct,
  success,
  user,
  closeModal,
  gotoStage,
  submitTransaction,
}: Props) {
  useEffect(() => {
    mixpanel.trackPaymentMethodShow(BillingUIMethod.MODAL)
  }, [])

  if (success || isPaidPremium(user)) {
    closeModal()
    return <div />
  }

  if (!isPaidPremium(user) && !selectedProduct) {
    gotoStage(BillingModalStage.SELECT_PRODUCT)
    return <div />
  }

  return (
    <PaymentMethod
      method={BillingUIMethod.MODAL}
      onSubmit={submitTransaction}
      selectedProduct={selectedProduct}
      formType={PaymentMethodFormType.ADD}
    />
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentMethodModalContent)
