import * as repository from "./repository"
import { Contact } from "src/types/Contact"
import { ContactFormValues } from "src/frontend/scenes/contacts/contactForm/types"
import { User } from "src/types/User"
import { AnyDocument, Id } from "src/types/CouchDb"
import {
  createDefaultDocument,
  getEntityReferences,
  referencesModel,
  ReferenceType,
} from "src/backend/common/service"
import { v4 as uuid } from "uuid"
import { inMemoryTableNames } from "src/backend/db/inMemorySqlDbSchemaBuilder"
import _omitBy from "lodash/omitBy"
import { isUndefinedOrNull } from "src/common/utils"
import * as commonRepository from "src/backend/common/repository"
import _groupBy from "lodash/groupBy"
import { ConverterMap } from "src/backend/db/inMemorySqlDb"

export function findAllContacts(): Promise<Contact.ContactDocument[]> {
  return repository.findAll()
}

export function convertToContactDocument(contact): Contact.ContactDocument {
  return _omitBy(contact, (field) => isUndefinedOrNull(field) || field === "")
}

export async function saveContact(formValues: ContactFormValues, user: User, contactId?: Id) {
  const contactCandidate = contactId
    ? await repository.findById(contactId)
    : getDefaultContact(user)

  const contactDocument: Contact.ContactDocument = {
    ...contactCandidate,
    ...convertToContactDocument(formValues),
  }

  return commonRepository.updateBulk(
    [contactDocument as Contact.ContactDocument],
    inMemoryTableNames.CONTACT,
  )
}

export function getDefaultContact(user: User): Contact.ContactDocument {
  const modelType = inMemoryTableNames.CONTACT
  const id = `-${modelType}_${uuid()}`
  return {
    ...createDefaultDocument(id, modelType, user),
    name: "",
    type: Contact.Type.OTHER,
  }
}

export async function removeContactFromReferences(contact: Contact.ContactDocument) {
  const references = await getEntityReferences(contact)
  return removeContactReferences(references, contact)
}

function removeContactReferences(references, contact: Contact.ContactDocument) {
  const entitiesWithoutReferences = references.map((entity) => {
    const contactModel = referencesModel[inMemoryTableNames.CONTACT]

    const model = contactModel.find((refModel) => refModel.name === entity.reservedModelType)

    if (model.type === ReferenceType.ARRAY) {
      const modifiedValues =
        entity[model.foreignKey] &&
        entity[model.foreignKey].filter((value) => value !== contact._id)
      return {
        ...entity,
        [model.foreignKey]: modifiedValues,
      }
    }

    if (model.type === ReferenceType.SCALAR) {
      return {
        ...entity,
        [model.foreignKey]: undefined,
      }
    }

    return entity
  })

  const groupedReferences = _groupBy(entitiesWithoutReferences, "reservedModelType")

  return Promise.all(
    Object.values(groupedReferences).map((group: AnyDocument[]) => {
      const { reservedModelType } = group[0]

      const converter =
        typeof ConverterMap[reservedModelType] === "function"
          ? ConverterMap[reservedModelType]()
          : undefined

      return commonRepository.updateBulk(group, reservedModelType, converter)
    }),
  )
}

export async function removeLContact(contactId: Id) {
  const contact = await repository.findById(contactId)

  await removeContactFromReferences(contact)
  return commonRepository.removeBulk([contact])
}
