import { ReferralSource } from 'src/types/User'

export interface CarouselItem {
  name: string
  description: string
  iconName: 'bank' | 'rules' | 'contacts' | 'planned' | 'plan' | 'notifications' | 'stats'
}

export type BankName = 'kb' |
  'csas' |
  'rb' |
  'csob' |
  'fio' |
  'unicredit' |
  'sberbank' |
  'moneta' |
  'equa' |
  'airbank' |
  'mbank' |
  'expobank'


export enum AB {
  A = 0,
  B = 1,
}


export namespace Tracking {
  export enum SeenFrom {
    TIP = 0,
    CARD = 1,
  }

  export enum ProductType {
    LOAN = 0,
    ACCOUNT = 1,
  }

  export interface Impression {
    referralSource?: ReferralSource
    seenFrom?: SeenFrom
    productType?: ProductType
  }
}
