export function selectGdprConsentModalOpen(state) {
  return state.scenes.gdpr.gdprConsentModalOpen
}

export function selectGdprInformAboutConsentModalOpen(state) {
  return state.scenes.gdpr.gdprConsentModalOpen;
}

export function selectGdprCloseConfirmationOpen(state) {
  return state.scenes.gdpr.gdprConfirmModalOpen
}

export function selectGdprTermsOfServicesHtml(state) {
  return state.scenes.gdpr.termsOfServicesHtml
}
