import { BraintreeBillingAddress } from 'src/frontend/scenes/billing/billingInformation/types'
import * as yup from 'yup'
import { isEuCountry, isValidCountry } from 'src/backend/countries/countries'

export function getInitialFormValues(userCountryCode: string): BraintreeBillingAddress {
  return {
    company: '',
    streetAddress: '',
    extendedAddress: '',
    city: '',
    postalCode: '',
    countryCodeAlpha2: userCountryCode.toLocaleLowerCase() ?? '',
    vatNumber: '',
    businessId: '',
  }
}

export function getValidationSchema() {
  return yup.object().shape({
    company: yup.string()
      .required('form.error.required'),
    streetAddress: yup.string()
      .required('form.error.required'),
    extendedAddress: yup.string(),
    city: yup.string()
      .required('form.error.required'),
    postalCode: yup.string()
      .required('form.error.required'),
    countryCodeAlpha2: yup.string()
      .required('form.error.required')
      .test('countryCodeAlpha2', 'form.error.wrong_country', isValidCountry),
    vatNumber: yup.string().when('countryCodeAlpha2', {
      is: (countryCode) => !isEuCountry(countryCode),
      then: yup.string().length(0, 'form.error.wrong_vat_country'),
    }),
    businessId: yup.string(),
  })
}
