import React, { useCallback, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Divider, Form } from 'semantic-ui-react'
import { FormattedMessage } from 'src/frontend/modules/intl'
import { RootState } from 'src/types/State'
import { changeValue, createFirstAccount, gotoNextStage } from 'src/frontend/scenes/onBoarding/firstRun/actions'
import {
  selectOnBoardingFormValues,
  selectOnBoardingLoading,
  selectOnBoardingStage,
} from 'src/frontend/scenes/onBoarding/firstRun/selectors'
import { OnBoardingFormValues } from 'src/frontend/scenes/onBoarding/firstRun/types'
import { Account } from 'src/types/Account'
import { selectAccountsAsArray } from 'src/frontend/modules/accounts/selectors'
import { OnBoardingStage } from 'src/frontend/scenes/onBoarding/firstRun/enums'
import FormattedMessageLabel from 'src/frontend/modules/intl/components/FormattedMessageLabel'
import { PrimaryButton } from 'src/frontend/components/Buttons/Buttons'
import { isUndefinedOrNull } from 'src/common/utils'
import * as mixpanel from 'src/common/mixpanel'

interface Props {
  loading: boolean
  accounts: Account[]
  formValues: OnBoardingFormValues,
  stage: OnBoardingStage,
  onFieldChange: (name: string, value: number) => void
  onGotoNext: () => void
  onCreateAccount: () => void
}

const mapStateToProps = (state: RootState) => ({
  accounts: selectAccountsAsArray(state),
  formValues: selectOnBoardingFormValues(state),
  loading: selectOnBoardingLoading(state),
  stage: selectOnBoardingStage(state),
})

const mapDispatchToProps = {
  onFieldChange: changeValue,
  onGotoNext: gotoNextStage,
  onCreateAccount: createFirstAccount,
}

const BALANCE_STRING_PATTERN = /^(-?\d+[.,]?(\d{0,2})?|[.,]\d{0,2})$/

function CashBalance({
  loading,
  accounts,
  formValues,
  stage,
  onFieldChange,
  onGotoNext,
  onCreateAccount,
}: Props) {

  const [balanceValue, setBalanceValue] = useState<string>('0')

  useEffect(() => {
    if (accounts && accounts.length > 0 && stage === OnBoardingStage.CASH_BALANCE) {
      onGotoNext()
    }
  }, [accounts, stage])

  useEffect(() => {
    mixpanel.trackCashBalanceEnter()
  }, [])

  const formatInputValue = (value: string) => {
    const formattedValue = value.match(BALANCE_STRING_PATTERN)
    return (formattedValue && formattedValue[0] || '').replace(',', '.')
  }

  const handleChangeName = useCallback((_e, field: { value: string, name: string }) => {
    // don't clear the input when user starts typing number in the format .50
    if (field.value === ',' || field.value === '.' || field.value === '-') {
      setBalanceValue(field.value)
    } else if (formatInputValue(field.value) || field.value === '') {
      onFieldChange(
        field.name,
        parseFloat(formatInputValue(field.value) || '0'),
      )
      setBalanceValue(formatInputValue(field.value))
    }
  }, [])

  const handleCreateAccount = useCallback(() => onCreateAccount(), [])

  const cashBalanceInvalid = isUndefinedOrNull(formValues.cashBalance)
    || isNaN(parseFloat(formValues.cashBalance as unknown as string))

  return (
    <>
      <Form>
        <Form.Input
          autoFocus
          name="cashBalance"
          label={<FormattedMessageLabel id="onBoarding.CASH_BALANCE.label" />}
          value={balanceValue}
          onChange={handleChangeName}
        />
        <p>
          <FormattedMessage id="onBoarding.CASH_BALANCE.info_message" />
        </p>
        <Divider hidden section />
        <PrimaryButton
          loading={loading}
          disabled={loading || cashBalanceInvalid}
          fluid
          type="submit"
          size="big"
          onClick={handleCreateAccount}
        >
          <FormattedMessage id="onBoarding.CASH_BALANCE.confirm" />
        </PrimaryButton>
      </Form>
    </>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(CashBalance)
