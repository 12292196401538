import { convertCurrencyToDocument } from "src/backend/currencies/converter"
import * as repository from "./repository"
import { addUpdateAttributes } from "src/backend/common/helpers"
import { updateAuditData } from "src/backend/common/service"
import * as commonRepository from "src/backend/common/repository"
import { utcDateAsISOString } from "src/backend/time/time"
import uuid from "uuid"
import { inMemoryTableNames } from "src/backend/db/inMemorySqlDbSchemaBuilder"
import { currencyConverter } from "src/backend/converters/currencyConverter"
import { getCurrenciesCodes } from "src/backend/currencies/currencies"
import { Platform } from "src/backend/enums"

export async function isReferentialCurrency(currencyId) {
  const referentialCurrency = await repository.getReferentialCurrency()
  return referentialCurrency && referentialCurrency._id === currencyId
}

export function getReferentialCurrency() {
  return repository.getReferentialCurrency()
}

export async function saveCurrency(formValues, user, currencyId = null) {
  let currencyData = {}
  if (currencyId) {
    const currency = await repository.findById(currencyId)
    currencyData = { ...addUpdateAttributes(currency) }
  } else {
    const position =
      ((await commonRepository.findHighestPosition(inMemoryTableNames.CURRENCY)) || 0) + 1000

    currencyData = {
      ...updateAuditData(user, utcDateAsISOString)(Currency()),
      position,
    }
  }

  const currencyDocument = convertCurrencyToDocument({
    ...currencyData,
    ...formValues,
  })

  return commonRepository.updateBulk(
    [currencyDocument],
    inMemoryTableNames.CURRENCY,
    currencyConverter(),
  )
}

export async function removeCurrency(currencyId) {
  const currency = await repository.findById(currencyId)
  return commonRepository.removeBulk([currency])
}

function Currency() {
  return {
    _id: `-Currency_${uuid.v4()}`,
    code: "",
    position: 0,
    ratioToReferential: 1,
    referential: false,
    reservedAuthorId: null,
    reservedCreatedAt: null,
    reservedModelType: "Currency",
    reservedOwnerId: null,
    reservedSource: Platform.WEB,
  }
}

export function fetchCurrencies() {
  return repository.getAllCurrencies()
}

export function getValidationDependencies() {
  const currencies = getCurrenciesCodes()

  return {
    currencies,
  }
}
