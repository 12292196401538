import { Divider, Form, Message } from 'semantic-ui-react'
import { FormattedMessage } from 'src/frontend/modules/intl'
import React from 'react'
import PasswordField from 'src/frontend/scenes/auth/components/PasswordField'
import EmailField from 'src/frontend/scenes/auth/components/EmailField'
import { FormikProps } from 'formik'
import { PrimaryButton } from 'src/frontend/components/Buttons/Buttons'

export default function SignupForm({
    values,
    touched,
    errors,
    isSubmitting,
    handleChange,
    handleSubmit,
}: FormikProps<{ general: string; email: string; newPassword: string; consent: boolean }>) {
    return (
        <Form className='auth-form' error>
            {errors.general && (
                <Message className='error-message' error content={<FormattedMessage id={errors.general} />} />
            )}

            <EmailField value={values.email as string} error={touched.email && errors.email} onChange={handleChange} />

            <PasswordField
                name='newPassword'
                value={values.newPassword as string}
                error={touched.newPassword && errors.newPassword}
                autoComplete='new-password'
                onChange={handleChange}
            />

            <Divider hidden />

            <PrimaryButton
                type='submit'
                size='large'
                fluid
                loading={isSubmitting}
                disabled={isSubmitting}
                onClick={handleSubmit}
            >
                <FormattedMessage id='auth.signup.action' />
            </PrimaryButton>
        </Form>
    )
}
