import spected from 'spected'
import moment from 'moment'
import * as commonValidator from 'src/backend/common/validator'
import { VIRTUAL_OUT_OF_WALLET_ACCOUNT_ID } from 'src/backend/accounts/helpers'
import { isAddRecordRepeatFormEnabled } from 'src/common/features'
import { isTransfer } from 'src/backend/records/helpers'
import { FormType } from 'src/frontend/scenes/records/recordForm/enums'
import _isEmpty from 'lodash/isEmpty'
import { WalletFormValidationError } from 'src/backend/errors'
import { RecordFormValues } from 'src/frontend/scenes/records/recordForm/types'
import { HashMap } from 'src/types/common'
import { Currency } from 'src/types/Currency'
import { AbstractEnvelope, Category } from 'src/types/Category'
import { Account } from 'src/types/Account'
import { PaymentType, RecordState } from 'src/backend/enums'
import { Record, RecordTransfer } from 'src/types/Record'
import { AssignFormValues } from 'src/frontend/scenes/records/assignForm/types'

interface ValidationDependencies {
  currencies: HashMap<Currency>
  categories: HashMap<Category>
  envelopes: HashMap<AbstractEnvelope>
  accounts: HashMap<Account>
  paymentTypes: PaymentType[],
  recordStates: RecordState[],
}

export function validate(
  entity: RecordFormValues | AssignFormValues | RecordTransfer | Record,
  validationDependencies: ValidationDependencies,
  recordFormType: FormType,
): void {
  const {
    currencies,
    categories,
    envelopes,
    accounts,
    paymentTypes,
    recordStates,
  } = validationDependencies

  const virtualOutOfWalletAccount = { _id: VIRTUAL_OUT_OF_WALLET_ACCOUNT_ID }
  const accountsWithOutOfWallet = {
    ...accounts,
    [VIRTUAL_OUT_OF_WALLET_ACCOUNT_ID]: virtualOutOfWalletAccount,
  }

  const isTransferRecord = isTransfer(entity as RecordTransfer)
  const isEditForm = recordFormType === FormType.EDIT
  const isMultiEdit = recordFormType === FormType.MULTI_EDIT
  const currentDate = moment()

  const requiredFields = [
    'recordDate',
    'paymentType',
    'recordState',
  ]

  if (isTransferRecord && !isMultiEdit) {
    requiredFields.push('fromAccountId')
    requiredFields.push('fromAmount')
    requiredFields.push('fromCurrencyId')
    requiredFields.push('toAccountId')
    requiredFields.push('toAmount')
    requiredFields.push('toCurrencyId')
  } else {
    requiredFields.push('accountId')
    requiredFields.push('categoryId')
    requiredFields.push('currencyId')
    requiredFields.push('amount')
  }

  // Setup null for properties with array type, because of Spected library is currently not able
  // to validate such properties.
  const recordWithRequiredFields: Partial<RecordTransfer> = {
    ...commonValidator.addRequiredFieldsAsUndefined(entity, requiredFields),
    photos: null,
    refObjects: null,
  }

  const recordSpec: any = {
    currencyId: [
      [commonValidator.isNotEmpty, 'record.form.select_currency'],
      [commonValidator.entityExists(currencies), 'record.form.currency_does_not_exist'],
    ],
    categoryId: [
      [commonValidator.isNotEmpty, 'record.form.select_category'],
      [
        commonValidator.conditionalValidation(!isTransferRecord, commonValidator.categoryExists(categories, envelopes)),
        'record.form.category_does_not_exist',
      ],
      [commonValidator.isNotUnknownCategory(categories), 'record.form.category_can_not_be_unknown'],
    ],
    recordDate: [
      [commonValidator.isValidDate, 'record.form.select_valid_date'],
    ],
    paymentType: [
      [commonValidator.isNotEmpty, 'record.form.select_payment_type'],
      [commonValidator.isNumber, 'record.form.payment_type_does_not_exist'],
      [commonValidator.valueExists(paymentTypes), 'record.form.payment_type_does_not_exist'],
    ],
  }

  recordSpec.recordDate.push(isAddRecordRepeatFormEnabled()
    ? [
      commonValidator.conditionalValidation(
        isEditForm,
        commonValidator.isNotAfter(currentDate),
      ),
      'record.form.future_date_not_allowed',
    ]
    : [commonValidator.isNotAfter(currentDate), 'record.form.future_date_not_allowed'])


  recordSpec.recordState = [
    [commonValidator.isNotEmpty, 'record.form.select_record_state'],
    [commonValidator.isNumber, 'record.form.record_state_does_not_exist'],
    [commonValidator.valueExists(recordStates), 'record.form.record_state_does_not_exist'],
  ]


  if (isTransfer(entity as RecordTransfer)) {
    const isFromOutOFWallet = (entity as RecordTransfer).fromAccountId === VIRTUAL_OUT_OF_WALLET_ACCOUNT_ID
    const isToOutOFWallet = (entity as RecordTransfer).toAccountId === VIRTUAL_OUT_OF_WALLET_ACCOUNT_ID


    recordSpec.fromAmount = [
      [
        commonValidator.conditionalValidation(!isFromOutOFWallet, commonValidator.isNonZeroNumber),
        'record.form.enter_non_zero_amount',
      ],
    ]

    recordSpec.toAmount = [
      [
        commonValidator.conditionalValidation(!isToOutOFWallet, commonValidator.isNonZeroNumber),
        'record.form.enter_non_zero_amount',
      ],
    ]

    recordSpec.fromAccountId = [
      [
        commonValidator.isNotEmpty,
        'record.form.select_account',
      ],
      [
        commonValidator.entityExists(accountsWithOutOfWallet),
        'record.form.account_does_not_exist',
      ],
      [
        commonValidator.isNotEqualTo(recordWithRequiredFields.toAccountId),
        'record.form.same_transfer_accounts',
      ],
    ]

    recordSpec.toAccountId = [
      [
        commonValidator.isNotEmpty,
        'record.form.select_account',
      ],
      [
        commonValidator.entityExists(accountsWithOutOfWallet),
        'record.form.account_does_not_exist',
      ],
      [
        commonValidator.isNotEqualTo(recordWithRequiredFields.fromAccountId),
        'record.form.same_transfer_accounts',
      ],
    ]

    recordSpec.fromCurrencyId = [
      [
        commonValidator.conditionalValidation(!isFromOutOFWallet, commonValidator.isNotEmpty),
        'record.form.select_currency',
      ],
      [
        commonValidator.conditionalValidation(!isFromOutOFWallet, commonValidator.entityExists(currencies)),
        'record.form.currency_does_not_exist',
      ],
    ]

    recordSpec.toCurrencyId = [
      [
        commonValidator.conditionalValidation(!isToOutOFWallet, commonValidator.isNotEmpty),
        'record.form.select_currency',
      ],
      [
        commonValidator.conditionalValidation(!isToOutOFWallet, commonValidator.entityExists(currencies)),
        'record.form.currency_does_not_exist',
      ],
    ]

  } else {
    recordSpec.amount = [
      [commonValidator.isNonZeroNumber, 'record.form.enter_non_zero_amount'],
    ]

    recordSpec.accountId = [
      [commonValidator.isNotEmpty, 'record.form.select_account'],
      [commonValidator.entityExists(accountsWithOutOfWallet), 'record.form.account_does_not_exist'],
    ]
  }

  const validationResult = spected(recordSpec, recordWithRequiredFields)
  const errors = commonValidator.convertValidationResultToErrors(validationResult)
  if (!_isEmpty(errors)) {
    throw new WalletFormValidationError(errors)
  }
}
