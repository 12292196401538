import { AccountType, BalanceDisplayType } from "src/backend/enums"
import { Account } from "src/types/Account"
import { Id } from "src/types/CouchDb"
import { AccountFormValues } from "src/frontend/scenes/settings/accounts/types"
import { isAppBoard } from "src/common/environment"

export const VIRTUAL_ADD_ACCOUNT_ID = "ADD_ACCOUNT"
export const VIRTUAL_OUT_OF_WALLET_ACCOUNT_ID = "OutOfWallet"
export const VIRTUAL_OUT_OF_WALLET_ACCOUNT_TYPE = 999
export const VIRTUAL_OUT_OF_WALLET_COLOR = isAppBoard() ? "#455a64" : "#00AA70"

export const isOutOfWallet = (account: Account) =>
  !!account && account._id === VIRTUAL_OUT_OF_WALLET_ACCOUNT_ID
export const isOutOfWalletId = (accountId: Id) => accountId === VIRTUAL_OUT_OF_WALLET_ACCOUNT_ID

export const isConnected = (account: Account) =>
  !!(account && account.reservedIntegrationConnection)
export const isNotConnected = (account: Account) => !isConnected(account)

export const isArchived = (account: Account) => !!(account && account.archived)

export const isImportsActivated = (account: Account) => !!(account && account.importSettings)

export const isGeneral = (account: Account) => account.accountType === AccountType.GENERAL

export const isCreditCard = (account: Account | AccountFormValues) =>
  account.accountType === AccountType.CREDIT_CARD
export const isOverdraft = (account: Account | AccountFormValues) =>
  account.accountType === AccountType.OVERDRAFT

export const isRevoked = (account: Account) => {
  return (
    isConnected(account) &&
    account.reservedIntegrationConnection.consent &&
    !account.reservedIntegrationConnection.consent.granted
  )
}

export function isProviderCreditReverse(account: Account) {
  return isConnected(account) && account.reservedIntegrationConnection.providerCreditCardReverse
}

export const positionComparator = (account1: Account, account2: Account) =>
  account1.position - account2.position

export const filterNotConnectedAccountOptions = (recordAccount: Account) => (account: Account) => {
  if (isConnected(recordAccount)) {
    return recordAccount._id === account._id
  }

  return (
    (!isConnected(account) && !isArchived(account)) ||
    (isArchived(recordAccount) && recordAccount._id === account._id)
  )
}

export function calcCreditCardLimitValue(
  account: Account,
  forceBalanceDisplayType?: BalanceDisplayType,
): number {
  if (account.creditCard) {
    const balanceDisplayOption: BalanceDisplayType =
      forceBalanceDisplayType || account.creditCard.balanceDisplayOption

    if (isCreditCard(account)) {
      if (
        isProviderCreditReverse(account) &&
        balanceDisplayOption === BalanceDisplayType.CREDIT_BALANCE
      ) {
        return -account.creditCard.limit
      } else if (
        !isProviderCreditReverse(account) &&
        balanceDisplayOption === BalanceDisplayType.AVAILABLE_CREDIT
      ) {
        return account.creditCard.limit
      }
    }

    if (isOverdraft(account)) {
      if (isConnected(account) && balanceDisplayOption === BalanceDisplayType.CREDIT_BALANCE) {
        return -account.creditCard.limit
      } else if (
        !isConnected(account) &&
        balanceDisplayOption === BalanceDisplayType.AVAILABLE_CREDIT
      ) {
        return account.creditCard.limit
      }
    }
  }
  return 0
}
