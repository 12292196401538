import { FilterType } from 'src/types/Filter'
import { BoardPerformanceValues, GaugeValues, WidgetData, WidgetOptions } from 'src/frontend/scenes/dashboard/types'
import { ViewDefinition } from 'src/frontend/scenes/analytics/types'
import { ChartGroupByType, ChartSubjectType } from 'src/frontend/scenes/analytics/enums'
import { getDashboardGranularity } from 'src/backend/dashboard/helpers'
import { ChartType } from 'src/frontend/components/chart/ChartType'
import { convertGranularityToGroupByAttribute } from 'src/backend/common/service'
import { calcTotalsByAttribute, getChartData, getOpeningBalance, prepareDataset } from 'src/backend/analytics/service'
import { HashMap } from 'src/types/common'
import { Totals } from 'src/backend/analytics/types'
import {
  calculateFixedCosts,
  calculateLoansRepayment,
  calculateOperatingCosts,
  calculateOperatingRevenue,
  calculateOtherCashInflow,
  calculateOtherCashOutFlow,
  calculateOtherRevenue,
  calculateSalesRevenue,
  calculateVariableCosts, convertChartLabels,
  reduceOtherCostsTotals,
  reduceOtherRevenuesTotals,
  reduceTotalsByCategories,
  sumTotals,
} from 'src/backend/analytics/helpers'
import {
  OPERATING_COSTS_SUPER_ENVELOPE_IDS,
  OPERATING_REVENUE_SUPER_ENVELOPE_IDS,
  OTHER_CASH_OUTFLOW_SUPER_ENVELOPE_IDS,
  OTHER_REVENUE_SUPER_ENVELOPE_IDS,
  SYSTEM_CATEGORIES_TRANSFER_ID,
  SYSTEM_CATEGORIES_UNKNOWN_ID,
} from 'src/backend/categories/envelopes'
import { prepareLinearChartData } from 'src/backend/dashboard/chartDataConverter'
import { LABELS_PER_CHART } from 'src/frontend/scenes/dashboard/components/constants'
import { getPreviousPeriodFilter, getYearAgoPeriodFilter } from 'src/backend/filters/helpers'
import { RevenueCostsGrowthWidgetVariant } from 'src/frontend/scenes/dashboard/widgets/BoardRevenueCostsGrowth/enums'
import * as helpers from 'src/common/helpers'
import moment from 'moment'
import * as recordsRepository from 'src/backend/records/repository'
import * as timeUtils from 'src/backend/time/time'
import { IntervalCompareToType } from 'src/backend/enums'

export async function fetchBoardCashFlowTrendChartData(
  filter: FilterType,
  options: WidgetOptions,
): Promise<WidgetData> {

  const viewDefinition: ViewDefinition = {
    subject: ChartSubjectType.CASH_FLOW,
    groupBy: ChartGroupByType.NONE,
    granularity: getDashboardGranularity(filter.period),
    chartType: ChartType.LINE,
    compareTo: IntervalCompareToType.NONE,
  }

  const groupByGranularity = convertGranularityToGroupByAttribute(viewDefinition.granularity)
  const GROUP_BY = [groupByGranularity, 'superEnvelopeId', 'envelopeId']

  const data = await calcTotalsByAttribute(filter, GROUP_BY)

  const { cumulative } = options

  const cashFlowSubject = cumulative
    ? ChartSubjectType.CUMULATIVE_CASH_FLOW
    : ChartSubjectType.CASH_FLOW

  const operatingRevenue = (totals: HashMap<Totals>) => reduceTotalsByCategories(
    totals,
    OPERATING_REVENUE_SUPER_ENVELOPE_IDS,
    'superEnvelopeId',
  )
  const otherCashInflow = (totals: HashMap<Totals>) => reduceOtherRevenuesTotals(totals)
  const operatingCosts = (totals: HashMap<Totals>) => reduceTotalsByCategories(
    totals,
    OPERATING_COSTS_SUPER_ENVELOPE_IDS,
    'superEnvelopeId',
  )
  const otherCosts = (totals: HashMap<Totals>) => reduceOtherCostsTotals(totals)

  const totalCashFlow = (totals: HashMap<Totals>) => {
    return sumTotals(
      reduceTotalsByCategories(
        totals,
        [
          ...OPERATING_REVENUE_SUPER_ENVELOPE_IDS,
          ...OTHER_REVENUE_SUPER_ENVELOPE_IDS,
          ...OPERATING_COSTS_SUPER_ENVELOPE_IDS,
          ...OTHER_CASH_OUTFLOW_SUPER_ENVELOPE_IDS,
        ],
        'superEnvelopeId',
      ),
      reduceTotalsByCategories(totals, [SYSTEM_CATEGORIES_TRANSFER_ID, SYSTEM_CATEGORIES_UNKNOWN_ID], 'envelopeId'),
    )
  }

  const datasets = [
    // Cash Flow dataset
    prepareDataset({ ...viewDefinition, subject: cashFlowSubject }, filter, data, 0, false, totalCashFlow),
    // Operating Revenue dataset
    prepareDataset(
      { ...viewDefinition, subject: ChartSubjectType.CASH_FLOW },
      filter,
      data,
      0,
      false,
      operatingRevenue,
    ),
    // Other cash inflow dataset
    prepareDataset(
      { ...viewDefinition, subject: ChartSubjectType.CASH_FLOW },
      filter,
      data,
      0,
      false,
      otherCashInflow,
    ),
    // Operating Cost dataset
    prepareDataset(
      { ...viewDefinition, subject: ChartSubjectType.CASH_FLOW },
      filter,
      data,
      0,
      false,
      operatingCosts,
    ),
    // Other cash outflow dataset
    prepareDataset({ ...viewDefinition, subject: ChartSubjectType.CASH_FLOW }, filter, data, 0, false, otherCosts),
  ]

  return {
    ...prepareLinearChartData(datasets, filter.period, viewDefinition.granularity),
    labels: convertChartLabels(datasets),
    labelsPerChart: LABELS_PER_CHART,
    type: ChartType.BAR,
  }
}

export async function fetchBoardCashFlow(filter: FilterType): Promise<WidgetData> {

  const GROUP_BY = ['superEnvelopeId', 'envelopeId']

  const previousPeriodTotals = calcTotalsByAttribute(getPreviousPeriodFilter(filter), GROUP_BY)
  const currentPeriodTotals = calcTotalsByAttribute(filter, GROUP_BY)

  const [
    previousPeriod,
    currentPeriod,
  ] = await Promise.all([previousPeriodTotals, currentPeriodTotals])


  return {
    currentPeriod: {
      operatingRevenue: calculateOperatingRevenue(currentPeriod),
      otherCashInflow: calculateOtherCashInflow(currentPeriod),
      operatingCosts: calculateOperatingCosts(currentPeriod),
      otherCashOutFlow: calculateOtherCashOutFlow(currentPeriod),
    },
    previousPeriod: {
      operatingRevenue: calculateOperatingRevenue(previousPeriod),
      otherCashInflow: calculateOtherCashInflow(previousPeriod),
      operatingCosts: calculateOperatingCosts(previousPeriod),
      otherCashOutFlow: calculateOtherCashOutFlow(previousPeriod),
    },
  }
}

export async function fetchBoardOperatingProfit(filter: FilterType): Promise<WidgetData> {

  const GROUP_BY = ['superEnvelopeId', 'envelopeId']

  const previousPeriodTotals = calcTotalsByAttribute(getPreviousPeriodFilter(filter), GROUP_BY)
  const currentPeriodTotals = calcTotalsByAttribute(filter, GROUP_BY)

  const [
    previousPeriod,
    currentPeriod,
  ] = await Promise.all([previousPeriodTotals, currentPeriodTotals])


  return {
    currentPeriod: {
      salesRevenue: calculateSalesRevenue(currentPeriod),
      otherRevenue: calculateOtherRevenue(currentPeriod),
      variableCosts: calculateVariableCosts(currentPeriod),
      fixedCosts: calculateFixedCosts(currentPeriod),
    },
    previousPeriod: {
      salesRevenue: calculateSalesRevenue(previousPeriod),
      otherRevenue: calculateOtherRevenue(previousPeriod),
      variableCosts: calculateVariableCosts(previousPeriod),
      fixedCosts: calculateFixedCosts(previousPeriod),
    },
  }
}

export async function fetchOperatingRevenueGrowthChartData(
  filter: FilterType,
  options: WidgetOptions,
  variant: RevenueCostsGrowthWidgetVariant,
): Promise<WidgetData> {

  const superEnvelopeIds = variant === RevenueCostsGrowthWidgetVariant.REVENUE
    ? OPERATING_REVENUE_SUPER_ENVELOPE_IDS
    : OPERATING_COSTS_SUPER_ENVELOPE_IDS


  const operatingRevenueFilter = {
    ...filter,
    superEnvelopeIds,
  }

  const { cumulative } = options
  const cashFlowSubject = cumulative
    ? ChartSubjectType.CUMULATIVE_CASH_FLOW
    : ChartSubjectType.CASH_FLOW

  const viewDefinition: ViewDefinition = {
    subject: cashFlowSubject,
    groupBy: ChartGroupByType.NONE,
    granularity: getDashboardGranularity(filter.period),
    chartType: ChartType.LINE,
    compareTo: IntervalCompareToType.NONE,
  }

  const previousPeriodFilter = getPreviousPeriodFilter(operatingRevenueFilter)
  const yearAgoPeriodFilter = getYearAgoPeriodFilter(operatingRevenueFilter)

  const [currentPeriodData, previousPeriodData, yearAgoData] = await Promise.all([
    getChartData(viewDefinition, operatingRevenueFilter),
    getChartData(viewDefinition, previousPeriodFilter),
    getChartData(viewDefinition, yearAgoPeriodFilter),
  ])

  const datasets = [...currentPeriodData, ...previousPeriodData, ...yearAgoData]

  return {
    ...prepareLinearChartData(datasets, operatingRevenueFilter.period, viewDefinition.granularity),
    labels: convertChartLabels(datasets),
    openingBalance: currentPeriodData[0].openingBalance,
    closingBalance: currentPeriodData[0].closingValue,
    labelsPerChart: LABELS_PER_CHART,
    type: ChartType.LINE,
  }
}

export async function fetchBoardPerformance(filter: FilterType): Promise<BoardPerformanceValues> {
  const data = await calcTotalsByAttribute(filter, ['superEnvelopeId', 'envelopeId'])

  return Promise.all([
    fetchRevenuePerformanceValue(filter, data),
    fetchBoardCashFlowPerformanceValue(filter, data),
    fetchBoardBalancePerformanceValue(filter, data),
  ]).then(([
    revenues,
    cashFlow,
    balance,
  ]) => ({
    revenues,
    cashFlow,
    balance,
  }))
}

export async function fetchRevenuePerformanceValue(filter: FilterType, data): Promise<GaugeValues> {

  const PreviousPeriodFilter = getPreviousPeriodFilter(filter)

  if (moment().isBefore(filter.period.start)) {
    return {
      performanceValue: 0,
      labelValue: 0,
    }
  }

  const previousPeriodData = await calcTotalsByAttribute(PreviousPeriodFilter, ['superEnvelopeId', 'envelopeId'])

  const operatingRevenues = calculateOperatingRevenue(data)
  const PreviousPeriodOperatingRevenues = calculateOperatingRevenue(previousPeriodData)

  const diff = operatingRevenues / PreviousPeriodOperatingRevenues
  // (diff - 0.9) / 0.35

  const LOW_BOUND = 0.9
  const HIGH_BOUND = 1.25
  const intervalSize = HIGH_BOUND - LOW_BOUND


  const result = helpers.limitValue(LOW_BOUND, HIGH_BOUND, diff)


  return {
    performanceValue: (result - LOW_BOUND) / intervalSize,
    labelValue: operatingRevenues,
  }
}

async function fetchBoardCashFlowPerformanceValue(filter: FilterType, data): Promise<GaugeValues> {

  const operatingRevenues = calculateOperatingRevenue(data)
  const operatingCosts = calculateOperatingCosts(data)
  const otherCashIn = calculateOtherCashInflow(data)
  const otherCashOut = calculateOtherCashOutFlow(data)

  const cashFlow = operatingRevenues + otherCashIn + operatingCosts + otherCashOut

  // zero values return 50% value

  if ((operatingCosts === 0 && operatingRevenues === 0) || moment().isBefore(filter.period.start)) {
    return {
      performanceValue: 0,
      labelValue: cashFlow,
    }
  }


  const diff = operatingRevenues / Math.abs(operatingCosts)

  const LOW_BOUND = 0.9
  const HIGH_BOUND = 1.25
  const intervalSize = HIGH_BOUND - LOW_BOUND


  const result = helpers.limitValue(LOW_BOUND, HIGH_BOUND, diff)


  return {
    performanceValue: (result - LOW_BOUND) / intervalSize,
    labelValue: cashFlow,
  }
}

export async function fetchBoardBalancePerformanceValue(filter: FilterType, data): Promise<GaugeValues> {
  const balance = await getOpeningBalance(filter)


  let operatingRevenues = calculateOperatingRevenue(data)
  let operatingCosts = calculateOperatingCosts(data)
  let loansRepayment = calculateLoansRepayment(data)

  const firstRecordDate = await recordsRepository.findFirstRecordDate().then(date => date && moment(date) || moment())
  // if user's data are 'younger' than the full period interval, we average expenses of the period from the fist record
  if (firstRecordDate.isAfter(filter.period.start)) {
    const dayIntervalSize = timeUtils.getDayIntervalSize(firstRecordDate, moment())
    const filterIntervalSize = timeUtils.getDayIntervalSize(filter.period.start, filter.period.end)

    operatingRevenues = operatingRevenues / dayIntervalSize * filterIntervalSize
    operatingCosts = operatingCosts / dayIntervalSize * filterIntervalSize
    loansRepayment = loansRepayment / dayIntervalSize * filterIntervalSize
  }

  // zero values return 50% value
  if ((operatingRevenues === 0
    && operatingCosts === 0
    && loansRepayment === 0
    && balance === 0)
    || moment().isBefore(filter.period.start)) {
    return {
      performanceValue: 0,
      labelValue: balance,
    }
  }

  const diff = (balance + operatingRevenues - operatingCosts) / (Math.abs(operatingCosts) + Math.abs(loansRepayment))

  const LOW_BOUND = 1.5
  const HIGH_BOUND = 2.0
  const intervalSize = HIGH_BOUND - LOW_BOUND


  const result = helpers.limitValue(LOW_BOUND, HIGH_BOUND, diff)

  return {
    performanceValue: (result - LOW_BOUND) / intervalSize,
    labelValue: balance,
  }
}
