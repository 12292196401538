import React from 'react'
import styles from 'src/frontend/components/PictureIcon/PictureIcon.module.less'
import { PictureIconNames } from 'src/frontend/components/PictureIcon/types'
import { mergeClasses } from 'src/common/utils'


interface Props {
  name: PictureIconNames
  compact?: boolean
  size?: string
  width?: string
  height?: string
  inline?: boolean
  customMargin?: string
}

PictureIcon.defaultProps = {
  compact: false,
  size: undefined,
  width: undefined,
  height: undefined,
  inline: false,
  customMargin: undefined,
}

export default function PictureIcon({
  name,
  compact,
  width,
  height,
  size,
  inline,
  customMargin,
}: Props) {
  return (
    <div
      className={mergeClasses(
        styles.circularIcon,
        name in styles && styles[name],
        inline && styles.inline,
      )}
      style={{
        margin: !!customMargin ? customMargin : compact && !inline ? "0 auto" : undefined,
        width: width ?? size,
        height: height ?? size,
      }}
    >
      {name === "onboarding-finish" && <div className={styles.checkMark} />}
    </div>
  )
}
