/* eslint-disable no-shadow */
import * as selectors from "./selectors"
import { expireDashboardStatus } from "src/frontend/modules/moduleStatus/actions"
import { selectUser } from "src/frontend/modules/user/selectors"
import * as validator from "src/backend/currencies/validator"
import * as service from "src/backend/currencies/service"
import * as commonService from "src/backend/common/service"
import * as commonHelpers from "src/backend/common/helpers"
import { getExchangeRate } from "src/backend/rest/backend"
import * as currenciesActions from "src/frontend/modules/currencies/actions"
import * as settingsActions from "src/frontend/scenes/settings/actions"
import { inMemoryTableNames } from "src/backend/db/inMemorySqlDbSchemaBuilder"
import _isEmpty from "lodash/isEmpty"
import { FormType } from "src/frontend/scenes/settings/enums"

export const SETTINGS_CURRENCIES_SAVE_START = "SETTINGS_CURRENCIES_SAVE_START"
export const SETTINGS_CURRENCIES_SAVE_SUCCESS = "SETTINGS_CURRENCIES_SAVE_SUCCESS"
export const SETTINGS_CURRENCIES_SAVE_ERROR = "SETTINGS_CURRENCIES_SAVE_ERROR"
export const SETTINGS_CURRENCIES_CHANGE_FIELD_VALUE = "SETTINGS_CURRENCIES_CHANGE_FIELD_VALUE"
export const SETTINGS_CURRENCIES_CHANGE_CODE_VALUE = "SETTINGS_CURRENCIES_CHANGE_CODE_VALUE"

export const SETTINGS_CURRENCIES_EXCHANGE_RATE_START = "SETTINGS_CURRENCIES_EXCHANGE_RATE_START"
export const SETTINGS_CURRENCIES_EXCHANGE_RATE_FINISH = "SETTINGS_CURRENCIES_EXCHANGE_RATE_FINISH"
export const SETTINGS_CURRENCIES_EXCHANGE_RATE_ERROR = "SETTINGS_CURRENCIES_EXCHANGE_RATE_ERROR"

export const SETTINGS_CURRENCIES_OPEN_FORM = "SETTINGS_CURRENCIES_OPEN_FORM"
export const SETTINGS_CURRENCIES_CLOSE_FORM = "SETTINGS_CURRENCIES_CLOSE_FORM"

export const SETTINGS_CURRENCIES_OPEN_DELETE_FORM = "SETTINGS_CURRENCIES_OPEN_DELETE_FORM"
export const SETTINGS_CURRENCIES_CLOSE_DELETE_FORM = "SETTINGS_CURRENCIES_CLOSE_DELETE_FORM"

export const SETTINGS_CURRENCIES_DELETE_START = "SETTINGS_CURRENCIES_DELETE_START"
export const SETTINGS_CURRENCIES_DELETE_SUCCESS = "SETTINGS_CURRENCIES_DELETE_SUCCESS"
export const SETTINGS_CURRENCIES_DELETE_ERROR = "SETTINGS_CURRENCIES_DELETE_ERROR"

export function reorderCurrencies(oldIndex, newIndex) {
  return (dispatch) => {
    dispatch(
      settingsActions.reorder(
        oldIndex,
        newIndex,
        inMemoryTableNames.CURRENCY,
        currenciesActions.fetchCurrencies,
      ),
    )
  }
}

export function saveCurrency(formValues, formType, currency = {}) {
  return async (dispatch, getState) => {
    dispatch(saveCurrencyStart(currency))

    console.log("saveCurrency", formValues, formType, currency)

    const user = selectUser(getState())

    const validationData = { ...currency, ...formValues }
    const validationDependencies = service.getValidationDependencies()

    const errors = await validator.validate(validationData, validationDependencies)

    if (!_isEmpty(errors)) {
      dispatch(saveCurrencyError(errors))
    } else {
      try {
        console.log("saveCurrency", validationData)
        const currency = await service.saveCurrency(formValues, user, validationData._id)
        dispatch(saveCurrencySuccess(currency))
        await dispatch(currenciesActions.fetchCurrencies())
        dispatch(expireDashboardStatus())
      } catch (error) {
        console.error(error)
        const currencyErrors = { general: errorMessage(error) }
        dispatch(saveCurrencyError(currencyErrors))
      }
    }
  }
}

function saveCurrencyStart(currency) {
  return { type: SETTINGS_CURRENCIES_SAVE_START, payload: { currency } }
}

function saveCurrencySuccess(currency) {
  return (dispatch) => {
    dispatch({ type: SETTINGS_CURRENCIES_SAVE_SUCCESS, payload: { currency } })
    return dispatch(closeForm())
  }
}

function errorMessage(error) {
  // FIXME WALLET-6385 similar issue like labels/actions, account/actions: duplicit and not i18n
  return error.name === "conflict" ? "Currency conflict!" : "Saving currency failed!"
}

function saveCurrencyError(errors) {
  return {
    type: SETTINGS_CURRENCIES_SAVE_ERROR,
    payload: { errors },
  }
}

export function openForm(formType, currency = null) {
  return (dispatch, getState) => {
    const { code, ratioToReferential } = selectors.selectCurrenciesForm(getState()).formValues
    const predefinedCode = formType === FormType.ADD && code ? { code } : {}
    const predefinedRatioToReferential =
      formType === FormType.ADD && ratioToReferential ? { ratioToReferential } : {}

    const formValues =
      formType === FormType.ADD
        ? {
            ...getInitialFormValues(),
            ...predefinedCode,
            ...predefinedRatioToReferential,
          }
        : {
            code: currency.code,
            ratioToReferential: currency.ratioToReferential,
          }

    return dispatch({
      type: SETTINGS_CURRENCIES_OPEN_FORM,
      payload: {
        formType,
        currency,
        formValues,
      },
    })
  }
}

export function closeForm() {
  return { type: SETTINGS_CURRENCIES_CLOSE_FORM }
}

export const changeFormFieldValue = (field, value) => {
  const payloadValue = (typeof value === "string" && value.trim()) || value
  return {
    type: SETTINGS_CURRENCIES_CHANGE_FIELD_VALUE,
    payload: { [field]: payloadValue },
  }
}

export const changeCurrencyCode = (code, referentialCurrencyCode) => {
  return (dispatch) => {
    dispatch(loadExchangeRate(code, referentialCurrencyCode))
    dispatch({
      type: SETTINGS_CURRENCIES_CHANGE_CODE_VALUE,
      payload: { code },
    })
  }
}

export const loadExchangeRate = (currencyFromCode, currencyToCode) => {
  return (dispatch) => {
    dispatch(loadExchangeRateStart())
    Promise.resolve()
      .then(() => getExchangeRate(currencyToCode, currencyFromCode))
      .then((rate) => {
        dispatch(changeFormFieldValue("ratioToReferential", rate))
        dispatch(loadExchangeRateSuccess())
      })
      .catch(() => dispatch(loadExchangeRateError()))
  }
}

export function deleteCurrency(currencyId) {
  return (dispatch) => {
    console.log(currencyId)
    dispatch(deleteCurrencyStart(currencyId))
    Promise.resolve()
      .then(() => service.removeCurrency(currencyId))
      .then((currency) => {
        dispatch(deleteCurrencySuccess(currency))
        dispatch(currenciesActions.fetchCurrencies())
      })
      .catch((error) => {
        dispatch(deleteCurrencyError(error, currencyId))
        throw error
      })
  }
}

export function deleteFormOpen(currencyData) {
  return (dispatch) => {
    return Promise.resolve()
      .then(() => commonService.getEntityReferences(currencyData))
      .then((references) => {
        let payload
        if (references.length === 0) {
          payload = { canDelete: true }
        } else {
          const referencedTypes = commonHelpers.getTypesOfEntities(references)
          payload = { canDelete: false, referencedTypes }
        }
        dispatch({ type: SETTINGS_CURRENCIES_OPEN_DELETE_FORM, payload })
      })
  }
}

export function deleteFormClose() {
  return { type: SETTINGS_CURRENCIES_CLOSE_DELETE_FORM }
}

function deleteCurrencyStart(currency) {
  return {
    type: SETTINGS_CURRENCIES_DELETE_START,
    payload: { currency },
  }
}

function deleteCurrencyError(error, currency) {
  // FIXME WALLET-6385 similar issue like labels/actions, account/actions: duplicit and not i18n
  const errorMessage =
    error.name === "conflict" ? "Currency conflict!" : "Deleting currency failed!"
  return {
    type: SETTINGS_CURRENCIES_DELETE_ERROR,
    payload: { errors: { general: errorMessage }, currency },
  }
}

function deleteCurrencySuccess() {
  return { type: SETTINGS_CURRENCIES_DELETE_SUCCESS }
}

function loadExchangeRateStart() {
  return { type: SETTINGS_CURRENCIES_EXCHANGE_RATE_START }
}

function loadExchangeRateSuccess() {
  return { type: SETTINGS_CURRENCIES_EXCHANGE_RATE_FINISH }
}

function loadExchangeRateError() {
  return { type: SETTINGS_CURRENCIES_EXCHANGE_RATE_ERROR }
}

function getInitialFormValues() {
  return {
    code: "",
    ratioToReferential: "",
  }
}
