import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { isAppBoard } from "src/common/environment"
import { SecondaryButton } from "src/frontend/components/Buttons/Buttons"
import { RootState } from "src/types/State"
import { SettingsItem } from "../billing/BillingItem"
import { getCustomerCareUrl, getSettingItems } from "./helpers"

const HelpPage = () => {
  const [helpSettingsItemsState, setHelpSettingsItemsState] = useState([])
  const user = useSelector((state: RootState) => state.modules.user)

  const setHelpSettingsItems = async () => {
    const helpCenterUrl = await getCustomerCareUrl()

    const helpSettingsItems = getSettingItems(helpCenterUrl)

    setHelpSettingsItemsState(helpSettingsItems)
  }

  const constructUrlWithUserParams = (shouldPassParams: boolean, url: string) => {
    if (!shouldPassParams) return url

    const userParams = {
      userId: user.userId,
      platform: "web",
      userEmail: user.email,
      userFullName: user.fullName,
      planType: user.activePlan?.planType,
      flavor: isAppBoard() ? "board" : "wallet",
      country: user.countryCode,
      createdAt: user.createdAt,
    }
    const encodedUserParams = encodeURIComponent(JSON.stringify(userParams))

    return `${url}?user=${encodedUserParams}`
  }

  useEffect(() => {
    setHelpSettingsItems()
  }, [])

  if (!helpSettingsItemsState.length) return <div className="ui text active loader">&nbsp;</div>

  return (
    <>
      {helpSettingsItemsState.map(({ title, href, ctaText, describtion }, index) => (
        <SettingsItem
          key={title}
          title={title}
          action={
            <SecondaryButton
              as="a"
              href={constructUrlWithUserParams(index === 0, href)}
              target="_blank"
            >
              {ctaText}
            </SecondaryButton>
          }
        >
          {describtion}
        </SettingsItem>
      ))}
    </>
  )
}

export default HelpPage
