import React from 'react'
import { Checkbox } from 'semantic-ui-react'
import { recordHasConnectedAccount } from 'src/backend/records/helpers'
import { formatMessage } from 'src/frontend/modules/intl/i18n'
import RemoveRecordForm from 'src/frontend/scenes/records/removeForm/components/RemoveRecordForm'
import _isEmpty from 'lodash/isEmpty'
import { FormattedMessage, FormattedMoney } from 'src/frontend/modules/intl'
import SolveDuplicitiesForm from 'src/frontend/scenes/records/solveDuplicitiesForm/components/SolveDuplicitiesForm'
import { SolveDuplicitiesFormState } from 'src/frontend/scenes/records/solveDuplicitiesForm/reducer'
import { RemoveFormState } from 'src/frontend/scenes/records/removeForm/reducer'
import { RecordListItemRecord } from 'src/frontend/components/RecordList/types'
import { connect } from 'react-redux'
import * as recordFormActions from 'src/frontend/scenes/records/recordForm/actions'
import styles from 'src/frontend/components/RecordList/SelectRecords.module.less'
import { mergeClasses } from 'src/common/utils'
import { Id } from 'src/types/CouchDb'
import recordListStyles from 'src/frontend/components/RecordList/ExtendedRecordList.module.less'
import { ComplementaryButton } from 'src/frontend/components/Buttons/Buttons'
import { RootState } from 'src/types/State'
import * as removeFormSelectors from 'src/frontend/scenes/records/removeForm/selectors'
import { selectSolveDuplicitiesForm } from 'src/frontend/scenes/records/solveDuplicitiesForm/selectors'
import * as filterSelectors from 'src/frontend/modules/filter/selectors'
import { selectReferentialCurrency } from 'src/frontend/modules/currencies/selectors'
import { Currency } from 'src/types/Currency'
import * as removeFormActions from 'src/frontend/scenes/records/removeForm/actions'
import * as solveDuplicitiesFormActions from 'src/frontend/scenes/records/solveDuplicitiesForm/actions'

function EditIcon({ onClick }: { onClick: (MouseEvent) => void }) {
  return (
    <ComplementaryButton color="blue" onClick={onClick}>
      <FormattedMessage id="edit" />
    </ComplementaryButton>
  )
}

function MultiRemoveIcon({ onClick }: { onClick: (MouseEvent) => void }) {
  return (
    <ComplementaryButton color="red" onClick={onClick}>
      <FormattedMessage id="delete" />
    </ComplementaryButton>
  )
}

function SolveDuplicitiesIcon({ onClick }: { onClick: (MouseEvent) => void }) {
  return (
    <ComplementaryButton color="blue" onClick={onClick}>
      <FormattedMessage id="records.solve_duplicities" />
    </ComplementaryButton>
  )
}

function mapStateToProps(state: RootState) {
  return {
    removeForm: removeFormSelectors.selectRemoveForm(state),
    solveDuplicitiesForm: selectSolveDuplicitiesForm(state),
    filters: filterSelectors.selectFilter(state),
    referentialCurrency: selectReferentialCurrency(state),
  }
}

const mapDispatchToProps = (dispatch: Function) => {
  return {
    onOpenEditForm(recordIds) {
      if (recordIds.length > 1) {
        dispatch(recordFormActions.openMultiEditForm(recordIds))
      } else {
        dispatch(recordFormActions.openEditRecordForm(recordIds[0]))
      }
    },
    onConfirmRemoveRecords: (records: RecordListItemRecord[]) => dispatch(removeFormActions.remove(records)),
    onOpenRemoveForm: () => dispatch(removeFormActions.openRemoveForm()),
    onCloseRemoveForm: () => dispatch(removeFormActions.closeRemoveForm()),
    onCloseSolveDuplicitiesForm: () => dispatch(solveDuplicitiesFormActions.closeSolveDuplicitiesForm()),
    onOpenSolveDuplicitiesForm: () => dispatch(solveDuplicitiesFormActions.openSolveDuplicitiesForm()),
    onSelectSolveDuplicity: (recordId: Id) => {
      return dispatch(solveDuplicitiesFormActions.solveDuplicitiesSelectItem(recordId))
    },
    onConfirmSolveDuplicities: (selectedDuplicities: Id[], recordIdToKeep: Id) => {
      return dispatch(solveDuplicitiesFormActions.solveDuplicities(selectedDuplicities, recordIdToKeep))
    },
  }
}


interface Props {
  removeForm: RemoveFormState
  solveDuplicitiesForm: SolveDuplicitiesFormState
  selectedRecords: RecordListItemRecord[]
  recordsSum: number
  allRecordsSelected: boolean
  transparent: boolean
  referentialCurrency: Currency,
  selectAllDisabled: boolean
  onOpenRemoveForm: (MouseEvent) => void
  onAllRecordsCheckBoxChange: Function
  onCloseRemoveForm: Function
  onConfirmRemoveRecords: (records: RecordListItemRecord[]) => void
  onCloseSolveDuplicitiesForm: Function
  onOpenSolveDuplicitiesForm: (MouseEvent) => void
  onSelectSolveDuplicity: (recordId: Id) => void
  onConfirmSolveDuplicities: (selectedDuplicities: Id[], recordIdToKeep: Id) => void
  onOpenEditForm: Function
}

function SelectRecords({
  removeForm,
  solveDuplicitiesForm,
  selectedRecords,
  recordsSum,
  transparent,
  referentialCurrency,
  allRecordsSelected,
  selectAllDisabled,
  onOpenRemoveForm,
  onAllRecordsCheckBoxChange,
  onCloseRemoveForm,
  onConfirmRemoveRecords,
  onSelectSolveDuplicity,
  onCloseSolveDuplicitiesForm,
  onOpenSolveDuplicitiesForm,
  onConfirmSolveDuplicities,
  onOpenEditForm,
}: Props) {
  const noneRecordsSelected = _isEmpty(selectedRecords)
  const isIndeterminate = !allRecordsSelected && !noneRecordsSelected
  const referentialCurrencyCode = referentialCurrency.code

  const shouldShowDuplicitiesButton = solveDuplicitiesForm
    && selectedRecords
    && selectedRecords.length > 1
    && selectedRecords.every(recordHasConnectedAccount)

  const recordIds = selectedRecords.map((record) => record._id)

  return (
    <div
      className={mergeClasses(
        styles.recordsListInfoPanel,
        recordListStyles.topPanel,
        !noneRecordsSelected && styles.active,
        transparent && styles.transparent,
      )}
    >
      <div className={styles.multiSelectionBox}>
        <Checkbox
          className={styles.multiSelectionCheckbox}
          checked={allRecordsSelected}
          indeterminate={isIndeterminate}
          disabled={selectAllDisabled}
          label={formatMessage(noneRecordsSelected
            ? 'records.select_all'
            : 'records.deselect_all')}
          onChange={() => onAllRecordsCheckBoxChange(noneRecordsSelected)}
        />
      </div>
      <div>
        {selectedRecords.length > 0 ? (
          <FormattedMessage
            id="records.selected_records"
            values={{ selectedRecordsCount: selectedRecords.length }}
          />
        ) : <div />}
        {!noneRecordsSelected && (
          <EditIcon onClick={() => onOpenEditForm(recordIds)} />
        )}

        {!noneRecordsSelected && (
          <RemoveRecordForm
            items={selectedRecords}
            open={removeForm.open}
            processing={removeForm.isRemoving}
            trigger={<MultiRemoveIcon onClick={onOpenRemoveForm} />}
            onCancelClick={onCloseRemoveForm}
            onConfirmClick={
              () => onConfirmRemoveRecords(selectedRecords.filter(record => {
                return !recordHasConnectedAccount(record)
              }))
            }
          />
        )}
        {shouldShowDuplicitiesButton && (
          <SolveDuplicitiesForm
            items={selectedRecords}
            open={solveDuplicitiesForm.open}
            processing={solveDuplicitiesForm.loading}
            trigger={<SolveDuplicitiesIcon onClick={onOpenSolveDuplicitiesForm} />}
            selectedItemId={solveDuplicitiesForm.selectedId}
            onCancelClick={onCloseSolveDuplicitiesForm}
            onConfirmClick={onConfirmSolveDuplicities}
            onSelectClick={onSelectSolveDuplicity}
          />
        )}
      </div>
      {!!recordsSum ? (
        <div className={styles.sum}>
          <FormattedMoney
            value={recordsSum}
            currency={referentialCurrencyCode}
          />
        </div>
      ) : <div>&nbsp;</div>}
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectRecords)
