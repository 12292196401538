import React, { useCallback, useState } from 'react'
import { Button, Modal, Transition } from 'semantic-ui-react'
import { FormattedMessage } from 'src/frontend/modules/intl'
import styles from './PremiumChecker.module.less'
import PremiumCheckerContent from 'src/frontend/components/PremiumChecker/PremiumCheckerContent'
import { connect } from 'react-redux'
import { openBillingModal } from 'src/frontend/scenes/billing/billingModal/actions'

const mapDispatchToProps = {
  handleOpenBilling: openBillingModal,
}

interface Props {
  featureName: string,
  enabled: boolean,
  children: React.ReactNode,
  handler?: 'onClick' | 'onOpen',
  handleOpenBilling: () => void,
}

function PremiumChecker({ children, featureName, enabled, handler = 'onClick', handleOpenBilling }: Props) {

  const [open, setOpen] = useState(false)

  const handleClosePremiumModal = useCallback((e?: React.SyntheticEvent) => {
    e?.stopPropagation()
    setOpen(false)
  }, [])

  const handleOpenPremiumModal = useCallback((e?: React.SyntheticEvent) => {
    e?.stopPropagation()
    setOpen(true)
  }, [])

  const handleClickBillingButton = () => {
    handleClosePremiumModal()
    handleOpenBilling()
  }

  const childrenWithProps = React.Children.map(
    children,
    (child: React.ReactElement<any>) => React.cloneElement(child, {
      [handler]: handleOpenPremiumModal,
      key: 'children',
    }),
  )

  const billingButton = (
    <Button
      compact
      icon={{ name: 'star', color: 'yellow' }}
      color="blue"
      content={<span><FormattedMessage id="premium_checker.upgrade" /></span>}
      onClick={handleClickBillingButton}
    />
  )

  return enabled
    ? children as React.ReactElement<any>
    : (
      <>
        {childrenWithProps}
        <Transition
          key="modal"
          directional
          visible={open}
          unmountOnHide
          onHide={handleClosePremiumModal}
          duration={{ show: 250, hide: 150 }}
          animation="modal-animation"
        >
          <Modal
            open
            size="small"
            onClose={handleClosePremiumModal}
          >
            <Modal.Header>
              <FormattedMessage id="premium_checker.premium_feature" />
              <span className="modal-close" onClick={handleClosePremiumModal} />
            </Modal.Header>
            <Modal.Content className={styles.premiumCheckerContent}>
              <PremiumCheckerContent featureString={featureName} button={billingButton} />
            </Modal.Content>
          </Modal>
        </Transition>
      </>
    )
}

export default connect(null, mapDispatchToProps)(PremiumChecker)
