import { FormType } from 'src/frontend/scenes/settings/enums'
import * as actions from './actions'
import { combineReducers } from 'redux'

const initialFormState = {
  formType: FormType.ADD,
  isOpen: false,
  formValues: { name: '' },
  template: null,
  availableCurrencies: [],
  errors: {},
}

function form(state = initialFormState, action) {
  switch (action.type) {
    case actions.SETTINGS_TEMPLATES_OPEN_FORM: {
      return { ...state, isOpen: true, ...action.payload }
    }
    case actions.SETTINGS_TEMPLATES_SAVE_START:
    case actions.SETTINGS_TEMPLATES_SAVE_SUCCESS:
    case actions.SETTINGS_TEMPLATES_SAVE_ERROR:
    case actions.SETTINGS_TEMPLATES_DELETE_START:
    case actions.SETTINGS_TEMPLATES_DELETE_SUCCESS:
    case actions.SETTINGS_TEMPLATES_DELETE_ERROR: {
      return { ...state, ...action.payload }
    }
    case actions.SETTINGS_TEMPLATES_CLOSE_FORM: {
      return {
        ...state,
        formValues: { name: '' },
        formType: FormType.ADD,
        template: null,
        availableCurrencies: [],
        isOpen: false,
        errors: {},
      }
    }
    case actions.SETTINGS_TEMPLATES_CHANGE_FIELD_VALUE:
    case actions.SETTINGS_TEMPLATES_CHANGE_NAME: {
      return { ...state, formValues: { ...state.formValues, ...action.payload } }
    }
    case actions.SETTINGS_TEMPLATES_CHANGE_ACCOUNT: {
      return {
        ...state,
        formValues: { ...state.formValues, ...action.payload.formValues },
        availableCurrencies: [...action.payload.availableCurrencies],
      }
    }
    default: {
      return state
    }
  }
}

const rootReducer = combineReducers({ form })

export default rootReducer
