import React, { useEffect, useState } from "react"
import styles from "src/frontend/scenes/accounts/accountDetail/components/AccountDetailContent.module.less"
import AccountDetailHeader from "src/frontend/scenes/accounts/accountDetail/components/AccountDetailHeader"
import AccountDetailTabs from "src/frontend/scenes/accounts/accountDetail/components/AccountDetailTabs"
import { Account } from "src/types/Account"
import { RestrictedProviders } from "src/frontend/scenes/integrations/restrictedProviders/types"
import RestrictedProvider = RestrictedProviders.RestrictedProvider
import AccountDetailInvestmentsHeader from "./AccountDetailInvestmentsHeader"
import { useSelector } from "react-redux"
import { RootState } from "src/types/State"
import { selectCurrencies } from "src/frontend/modules/currencies/selectors"
import { selectChartDatasets } from "src/frontend/scenes/analytics/selectors"
import { useTotals } from "./hooks"
import { calcInvestmentsToDate } from "src/backend/analytics/service"
interface Props {
  account: Account
  restrictedProviders: RestrictedProvider[]
}

function AccountDetailContent({ account, restrictedProviders }: Props) {
  const datasets = useSelector((state: RootState) => selectChartDatasets(state))
  const currencies = useSelector((state: RootState) => selectCurrencies(state))

  const todaysAvailableCash = datasets[0]?.data[datasets[0].data.length - 1] * 100 ?? 0

  const [availableCash, setAvailableCash] = useState(0)
  const [portfolioBalance, setPortfolioBalance] = useState(0)

  useEffect(() => {
    calcInvestmentsToDate({ accountIds: [account._id] }).then(({ portfolioValue }) => {
      const portfolioValueInAccountCurrency =
        portfolioValue * 100 * currencies[account.currencyId].ratioToReferential

      const availableCashInAccountCurrency =
        todaysAvailableCash * currencies[account.currencyId].ratioToReferential

      setPortfolioBalance(portfolioValueInAccountCurrency)

      setAvailableCash(availableCashInAccountCurrency)
    })
  }, [account, currencies, todaysAvailableCash])

  return (
    <div className={styles.contactDetail}>
      {account?.investmentInfo ? (
        <AccountDetailInvestmentsHeader
          account={account}
          restrictedProviders={restrictedProviders}
          availableCash={availableCash}
          portfolioBalance={portfolioBalance}
        />
      ) : (
        <AccountDetailHeader
          account={account}
          restrictedProviders={restrictedProviders}
        />
      )}
      <AccountDetailTabs
        account={account}
        availableCash={availableCash}
        portfolioBalance={portfolioBalance}
      />
    </div>
  )
}

export default AccountDetailContent
