import {
    CONFIRM_REMOVE_CONSENT,
    LOADING,
    CANCEL_CHANGE_CONSENT,
    RESET_STATE,
    DELETE_DATA_CONFIRM
} from 'src/frontend/scenes/settings/privacy/actions'
import { UserConsentType, UserDeleteDataType } from 'src/frontend/scenes/settings/privacy/enums'

const initialState: PrivacyState = {
    confirmModalOpen: false,
    deleteDataModalOpen: false,
    privacyType: null,
    deleteType: null,
    loading: false,
}

export interface PrivacyState {
    confirmModalOpen: boolean
    deleteDataModalOpen: boolean
    privacyType: UserConsentType
    deleteType: UserDeleteDataType
    loading: boolean
}

export default function privacy(state: PrivacyState = initialState, action): PrivacyState {
    switch (action.type) {
        case CONFIRM_REMOVE_CONSENT: {
            return { ...state, confirmModalOpen: true, privacyType: action.payload.type }
        }

        case DELETE_DATA_CONFIRM: {
            return { ...state, deleteDataModalOpen: true, deleteType: action.payload.type }
        }

        case RESET_STATE: {
            return initialState
        }

        case CANCEL_CHANGE_CONSENT: {
            return initialState
        }

        case LOADING: {
            return { ...state, loading: true }
        }

        default:
            return state
    }
}
