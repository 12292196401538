import React from 'react'
import styles from './LastRecords.module.less'
import BasicRecordList from 'src/frontend/components/RecordList/BasicRecordList'
import Widget from 'src/frontend/scenes/dashboard/components/Widget/Widget'
import { WidgetView } from 'src/frontend/scenes/dashboard/types'
import { Record } from 'src/types/Record'
import { connect } from 'react-redux'
import { openEditRecordForm } from 'src/frontend/scenes/records/recordForm/actions'
import { Id } from 'src/types/CouchDb'
import { widgetHasDeepEqualProps } from 'src/frontend/scenes/dashboard/helpers'
import { isAppBoard } from 'src/common/environment'
import { Link } from 'react-router-dom'
import FormattedMessage from 'src/frontend/modules/intl/components/FormattedMessage'
import { ComplementaryButton } from 'src/frontend/components/Buttons/Buttons'

type Props = {
  widget: WidgetView & { data: Array<Record> },
  handleEditRecord: (recordId: Id) => void,
}

const mapDispatchToProps = {
  handleEditRecord: openEditRecordForm,
}

function LastRecords({ widget, handleEditRecord }: Props) {
  const { data } = widget

  if (!data) {
    return <Widget.Placeholder />
  }

  const widgetAction = (
    <ComplementaryButton
      as={Link}
      to={isAppBoard() ? '/transactions' : '/records'}
    >
      <FormattedMessage id={{ B: 'transactions', W: 'menu.records' }} />
    </ComplementaryButton>
  )

  const content = (data.length > 0)
    ? (
      <Widget.Content>
        <div className={styles.lastRecords}>
          <BasicRecordList
            showRelativeTime
            records={data}
            onEdit={handleEditRecord}
            editable
            noAccounts={isAppBoard()}
            noNotes
          />
        </div>
      </Widget.Content>
    )
    : <Widget.EmptyContent type="records" />

  return (
    <Widget>
      <Widget.Header
        title={widget.title}
        action={widgetAction}
      />
      {content}
    </Widget>
  )
}


export default connect(null, mapDispatchToProps)(React.memo(LastRecords, widgetHasDeepEqualProps))
