import { path } from "ramda"
import * as logger from "src/common/logger"
import { trackImportsSuccess } from "src/common/mixpanel"
import { getErrorText, isAuthorizationError } from "src/common/helpers"
import { saveCustomMapping, validateCustomMapping } from "src/backend/imports/service"
import { expireUser } from "src/frontend/modules/user/actions"
import { reloadItems, showInvalidFileModal } from "src/frontend/scenes/imports/actions"
import {
  selectImportingItemId,
  selectIsMappingValidationRequired,
  selectMappingState,
  selectMissingRequiredAttributeTypes,
} from "src/frontend/scenes/imports/mapping/selectors"
import { showError, closeInfoDimmer, showInfoDimmer } from "src/frontend/scenes/app/actions"
import { ImportInitResultType, ImportMappingAttributeType } from "src/backend/enums"
import { COLUMN_UNSET_INDEX } from "src/frontend/scenes/imports/mapping/constants"
import {
  buildMissingRequiredAttributesError,
  isRecoverableError,
} from "src/frontend/scenes/imports/mapping/helpers"
import { openGoodJobModal } from "src/frontend/scenes/onBoarding/inApp/actions"
import { expireImportsRecordListStatus } from "src/frontend/modules/moduleStatus/actions"

export const OPEN_MAPPING = "imports/OPEN_MAPPING"
export const CHANGE_FIRST_RECORD_POSITION = "imports/CHANGE_FIRST_RECORD_POSITION"
export const CHANGE_HAS_HEADER = "imports/HAS_HEADER_CHANGED"
export const CHANGE_LAST_RECORD_POSITION = "imports/CHANGE_LAST_RECORD_POSITION"
export const CHANGE_SEPARATE_FEES = "imports/CHANGE_SEPARATE_FEES"
export const CHANGE_SEPARATE_INCOME_EXPENSE = "imports/CHANGE_SEPARATE_INCOME_EXPENSE"
export const CLOSE_MAPPING = "imports/CLOSE_MAPPING"
export const CLOSE_PREVIEW = "imports/CLOSE_PREVIEW"
export const CHANGE_COLUMN_ATTRIBUTE = "imports/CHANGE_COLUMN_ATTRIBUTE"
export const GET_INITIAL_MAPPING_SUCCESS = "imports/GET_INITIAL_MAPPING_SUCCESS"
export const HIDE_ATTRIBUTE_MAPPING_ERROR = "imports/HIDE_ATTRIBUTE_MAPPING_ERROR"
export const SHOW_ATTRIBUTE_MAPPING_ERROR = "imports/SHOW_ATTRIBUTE_MAPPING_ERROR"
export const SET_SETTINGS = "imports/SET_SETTINGS"

// Mapping wizard actions
export const GO_WIZARD_NEXT = "imports/GO_WIZARD_NEXT"
export const GO_WIZARD_PREV = "imports/GO_WIZARD_PREV"
export const SHOW_PREVIEW = "imports/SHOW_PREVIEW"
export const SET_VALIDATED = "imports/SET_VALIDATED"

export const SAVE_MAPPING_START = "imports/SAVE_MAPPING_START"
export const SAVE_MAPPING_SUCCESS = "imports/SAVE_MAPPING_SUCCESS"
export const SAVE_MAPPING_ERROR = "imports/SAVE_MAPPING_ERROR"

export const separateIncomeExpenseChanged = (hasSeparateIncomeExpense) => async (dispatch) => {
  if (!hasSeparateIncomeExpense) {
    // when hiding extra column for expense also unset it
    await dispatch(expenseColumnChanged(COLUMN_UNSET_INDEX))
  }
  dispatch({
    type: CHANGE_SEPARATE_INCOME_EXPENSE,
    hasSeparateIncomeExpense,
  })
  if (!hasSeparateIncomeExpense) {
    return dispatch(validateMappingAndHandleResult())
  } else {
    return Promise.resolve()
  }
}

export const amountColumnChanged = (index) => (dispatch) => {
  dispatch({
    type: CHANGE_COLUMN_ATTRIBUTE,
    attributeType: ImportMappingAttributeType.AMOUNT,
    columnIndex: index,
    validationRequired: true,
  })
  return dispatch(validateMappingAndHandleResult())
}

export const expenseColumnChanged = (index) => (dispatch) => {
  dispatch({
    type: CHANGE_COLUMN_ATTRIBUTE,
    attributeType: ImportMappingAttributeType.EXPENSE,
    columnIndex: index,
    validationRequired: true,
  })
  return dispatch(validateMappingAndHandleResult())
}

export const separateFeesChanged = (hasSeparateFees) => (dispatch) => {
  if (!hasSeparateFees) {
    // when hiding extra column for fee also unset it
    dispatch(feeColumnChanged(COLUMN_UNSET_INDEX))
  }
  dispatch({
    type: CHANGE_SEPARATE_FEES,
    hasSeparateFees,
  })
  if (!hasSeparateFees) {
    dispatch(validateMappingAndHandleResult())
  }
}

export const feeColumnChanged = (index) => (dispatch) => {
  dispatch({
    type: CHANGE_COLUMN_ATTRIBUTE,
    attributeType: ImportMappingAttributeType.FEE,
    columnIndex: index,
    validationRequired: true,
  })
  return dispatch(validateMappingAndHandleResult())
}

export const dateColumnChanged = (index) => (dispatch) => {
  dispatch({
    type: CHANGE_COLUMN_ATTRIBUTE,
    attributeType: ImportMappingAttributeType.RECORDDATE,
    columnIndex: index,
    validationRequired: true,
  })
  return dispatch(validateMappingAndHandleResult())
}

export const changeNoteColumn = (colIndex) => ({
  type: CHANGE_COLUMN_ATTRIBUTE,
  attributeType: ImportMappingAttributeType.NOTE,
  columnIndex: colIndex,
})

export const changePayeeColumn = (colIndex) => ({
  type: CHANGE_COLUMN_ATTRIBUTE,
  attributeType: ImportMappingAttributeType.PAYEE,
  columnIndex: colIndex,
})

export const changeCurrencyColumn = (colIndex) => (dispatch) => {
  dispatch({
    type: CHANGE_COLUMN_ATTRIBUTE,
    attributeType: ImportMappingAttributeType.CURRENCYCODE,
    columnIndex: colIndex,
    validationRequired: true,
  })
  return dispatch(validateMappingAndHandleResult())
}

export const changeCategoryColumn = (colIndex) => ({
  type: CHANGE_COLUMN_ATTRIBUTE,
  attributeType: ImportMappingAttributeType.CATEGORYMAPPING,
  columnIndex: colIndex,
})

export const changeFirstRecordPosition = (index) => (dispatch) => {
  dispatch({
    type: CHANGE_FIRST_RECORD_POSITION,
    index,
  })
  return dispatch(validateMappingAndHandleResult())
}

export const changeHasHeader = (hasHeader) => (dispatch) => {
  dispatch({
    type: CHANGE_HAS_HEADER,
    hasHeader,
  })
  return dispatch(validateMappingAndHandleResult())
}

const showAttributeMappingError = (error) => ({
  type: SHOW_ATTRIBUTE_MAPPING_ERROR,
  error,
})

const hideAttributeMappingError = () => ({
  type: HIDE_ATTRIBUTE_MAPPING_ERROR,
})

export const lastRecordPositionChanged = (index) => (dispatch) => {
  dispatch({
    type: CHANGE_LAST_RECORD_POSITION,
    index,
  })
  return dispatch(validateMappingAndHandleResult())
}

export const close = () => ({ type: CLOSE_MAPPING })

export const closePreview = () => ({ type: CLOSE_PREVIEW })

export const getInitialMappingSuccess = (itemId, initialMapping) => (dispatch) => {
  dispatch({
    type: GET_INITIAL_MAPPING_SUCCESS,
    itemId,
    initialMapping,
  })
  if (initialMapping.initResult === ImportInitResultType.LAST_SUCCESS) {
    dispatch(showPreview())
  }
}

export const openMapping = () => ({ type: OPEN_MAPPING })

export const validateAndOpenMapping = () => async (dispatch) => {
  try {
    await dispatch(validateMapping())
    dispatch(openMapping())
    dispatch(closeInfoDimmer())
  } catch (error) {
    console.log("validateAndOpenMapping error", error)
    dispatch(handleOpenMappingError(error))
  }
}

const handleEditMappingError = (error) => (dispatch) => {
  if (isAuthorizationError(error)) {
    dispatch(expireUser())
  } else if (isRecoverableError(error)) {
    dispatch(showAttributeMappingError(error.response.error))
    dispatch(closeInfoDimmer())
  } else {
    logger.error(`action=handleEditMappingError unexpected error: ${getErrorText(error)}`, {
      error,
    })
    dispatch(showError(["imports.mapping.error_validating", "app.error.we_are_fixing_suffix"]))
  }
}

const handleSaveMappingError = (error) => (dispatch) => {
  const responseStatus = path(["response", "status"], error)
  if (isAuthorizationError(error)) {
    dispatch(expireUser())
  } else if (isRecoverableError(error)) {
    dispatch(handleEditMappingError(error))
  } else if (responseStatus === 404) {
    dispatch(close())
    dispatch(showError("imports.mapping.error_invalid_file"))
  } else if (responseStatus === 409) {
    dispatch(close())
    dispatch(showError("imports.mapping.error_conflict"))
  } else {
    logger.error(`action=handleSaveMappingError unexpected error: ${getErrorText(error)}`, {
      error,
    })
    dispatch(showError(["imports.mapping.error_saving", "app.error.we_are_fixing_suffix"]))
  }
}

const handleOpenMappingError = (error) => (dispatch) => {
  const responseStatus = path(["response", "status"], error)
  if (isAuthorizationError(error)) {
    dispatch(expireUser())
  } else if (isRecoverableError(error)) {
    dispatch(handleEditMappingError(error))
    dispatch(openMapping())
  } else if (responseStatus === 404) {
    dispatch(showInvalidFileModal())
  } else {
    logger.error(`action=handleOpenMappingError ${getErrorText(error)}`, { error })
    dispatch(showError(["imports.mapping.error_validating", "app.error.we_are_fixing_suffix"]))
  }
}

const validateMapping = () => (dispatch, getState) => {
  const currentItemId = selectImportingItemId(getState())
  const { settings, columns } = selectMappingState(getState())
  dispatch(showInfoDimmer("imports.validating_imports"))
  return validateCustomMapping(currentItemId, settings, convertColumns(columns)).then(
    (response) => {
      // Set dateFormatterPattern to the state because it is missing in getInitialMapping response.
      const { dateFormatterPattern } = response.settings
      const updatedSettings = { ...settings, dateFormatterPattern }
      console.log("updatedSettings", updatedSettings, settings)
      dispatch(setMappingSettings(updatedSettings))
      dispatch(hideAttributeMappingError())
      dispatch(setValidated())
    },
  )
}

const validateMappingAndHandleResult = () => (dispatch, getState) => {
  const missingRequiredAttributes = selectMissingRequiredAttributeTypes(getState())

  if (missingRequiredAttributes.length > 0) {
    dispatch(
      showAttributeMappingError(buildMissingRequiredAttributesError(missingRequiredAttributes)),
    )
    return Promise.resolve()
  } else {
    return dispatch(validateMapping())
      .then(() => {
        dispatch(closeInfoDimmer())
      })
      .catch((error) => {
        dispatch(handleEditMappingError(error))
      })
  }
}

function saveMappingStart() {
  return {
    type: SAVE_MAPPING_START,
  }
}

function saveMappingSuccess() {
  return {
    type: SAVE_MAPPING_SUCCESS,
  }
}

function saveMappingError() {
  return {
    type: SAVE_MAPPING_ERROR,
  }
}

export const saveMapping = () => (dispatch, getState) => {
  dispatch(saveMappingStart())
  const mappingsState = selectMappingState(getState())

  const reloadItemsChain = () =>
    Promise.resolve()
      .then(() => dispatch(reloadItems()))
      .then(() => {
        dispatch(close())
      })
      .catch((error) => {
        dispatch(showError(["imports.items.error_reloading", "app.error.we_are_fixing_suffix"]))
        logger.error(
          `action=saveMapping - Unexpected error while reloading items: ${getErrorText(error)}`,
          { error },
        )
      })

  saveCustomMapping(
    mappingsState.currentItemId,
    mappingsState.settings,
    convertColumns(mappingsState.columns),
  )
    .then((result) => {
      console.log("saveMapping success", result)
      dispatch(saveMappingSuccess())
      dispatch(closePreview())
      dispatch(openGoodJobModal("imports.success.modal"))
      trackImportsSuccess()
      return reloadItemsChain()
    })
    .catch((error) => {
      dispatch(saveMappingError())
      dispatch(closePreview())
      dispatch(showError("imports.mapping.file_contains_errors"))
      dispatch(handleSaveMappingError(error))
    })
}

const setValidated = () => ({ type: SET_VALIDATED })

const setMappingSettings = (settings) => ({ type: SET_SETTINGS, settings })

export const wizardGoNext = () => (dispatch, getState) => {
  const goNextAction = { type: GO_WIZARD_NEXT }

  if (selectIsMappingValidationRequired(getState())) {
    return dispatch(validateMappingAndHandleResult()).then(() => {
      return dispatch(goNextAction)
    })
  } else {
    return Promise.resolve(dispatch(goNextAction))
  }
}

export const wizardGoPrev = () => ({ type: GO_WIZARD_PREV })

export const showPreview = () => ({ type: SHOW_PREVIEW })

function convertColumns(columns) {
  return columns
    .filter(({ type }) => type !== ImportMappingAttributeType.UNKNOWN)
    .map(({ type, colIndex }) => ({ type, colIndex }))
}
