import { convertToCategoryItem, sortByLocalizedCategoryName } from 'src/frontend/modules/categories/helpers'
import { Category } from 'src/types/Category'
import React from 'react'
import { Dropdown } from 'semantic-ui-react'
import CategoryOption from 'src/frontend/components/Categories/CategoryOption'
import { CategoryLevel } from 'src/frontend/modules/categories/enums'
import { Id } from 'src/types/CouchDb'

interface Props {
  categories?: Category[]
  level?: CategoryLevel
  className?: string
  fieldName?: string
  value: string | number
  onSelect: (event: React.SyntheticEvent, values: { name: string, value: Id | number }) => void
}

EnvelopesList.defaultProps = {
  level: CategoryLevel.CATEGORY,
  categories: [],
  className: undefined,
  fieldName: undefined,
}

export function EnvelopesList({ categories, value, level, fieldName, className, onSelect }: Props) {
  const envelopesOptions = categories
    .filter((envelope: Category) => !envelope.isHidden)
    .sort(sortByLocalizedCategoryName)
    .map((envelope: Category) => {
      const { category, name: categoryName } = convertToCategoryItem(envelope, level)
      return (
        <React.Fragment key={envelope._id}>
          <Dropdown.Item
            text={(
              <CategoryOption
                level={level}
                color={category.color}
                icon={category.iconName}
                label={categoryName}
                size="23px"
              />
            )}
            value={envelope._id}
            active={value === envelope._id}
            as="li"
            onClick={(e) => onSelect(e, { name: fieldName, value: envelope._id })}
          />
          {category.subcategories && category.subcategories.length > 0 && (
            <EnvelopesList
              categories={category.subcategories}
              level={CategoryLevel.SUBCATEGORY}
              fieldName={fieldName}
              value={value}
              onSelect={onSelect}
            />
          )}
        </React.Fragment>
      )
    })

  return (
    <ul className={className}>
      {envelopesOptions}
    </ul>
  )
}
