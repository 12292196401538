import * as React from "react"
import { formatMessage, FormattedMessage } from "src/frontend/modules/intl"
import Accordion from "./Accordion"
import { Icon } from "semantic-ui-react"
import styles from "./MultiSelectFilter.module.less"
import filterStyles from "src/frontend/components/Filter/Filter.module.less"
import "src/frontend/scenes/dashboard/components/Icons.less"
import _isEmpty from "lodash/isEmpty"
import { Option, OptionId } from "src/frontend/components/Filter/types"
import {
  filterVisibleOptions,
  flattenOptionsRecursively,
  isCategoryGroup,
  isEnvelopeGroup,
  isSuperEnvelopeGroup,
  updateVisibility,
} from "src/frontend/components/Filter/helpers"
import { CategoryFilterGroup } from "src/frontend/modules/filter/selectors"
import { mergeClasses } from "src/common/utils"
import TruncatedText from "src/frontend/components/TruncatedText"

type Props = {
  options: Array<Option>
  name: string
  label: string
  labelAll: string
  groups?: Array<string>
  displayAll: boolean
  active: boolean
  onFilterChange: Function
  onDisplayChange: Function
}

type State = {
  [key: string]: boolean
}

export default class MultiSelectFilter extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = this.getInitStateValues()
  }

  static defaultProps = {
    groups: undefined,
  }

  getInitStateValues = () => {
    return (
      this.props.options &&
      this.props.options.reduce((acc, value) => {
        if (value.childrenOptions) {
          return {
            ...acc,
            [value._id]: false,
          }
        } else {
          return acc
        }
      }, {})
    )
  }

  collapseAll = () => {
    this.setState(() => ({ ...this.getInitStateValues() }))
  }

  expandGroup = (id: OptionId) => {
    this.setState({ [id]: true })
  }

  toggleGroup = (id: OptionId) => {
    this.setState((prevState) => ({ [id]: !prevState[id] }))
  }

  selectAll = () => {
    this.collapseAll()
    const { name, groups, onFilterChange } = this.props

    if (groups) {
      const newValues = groups.reduce((acc, value) => ({ ...acc, [value]: undefined }), {})
      onFilterChange(newValues)
    } else {
      onFilterChange({ [name]: undefined })
    }
  }

  deselectAll = () => {
    const { name, groups, onFilterChange } = this.props

    if (groups) {
      const newValues = groups.reduce((acc, value) => ({ ...acc, [value]: [] }), {})
      onFilterChange(newValues)
    } else {
      onFilterChange({ [name]: [] })
    }
  }

  getUpdateFilterValues = (itemId: OptionId) => {
    const { options } = this.props
    const flattenedOptions = flattenOptionsRecursively(options)
    const item = flattenedOptions.find((option) => option._id === itemId)

    if (item) {
      if (item.group && isSuperEnvelopeGroup(item.group)) {
        // EXPAND on click
        this.expandGroup(item._id)

        const updatedOptions = item.childrenOptions
          ? item.childrenOptions.reduce(
              (acc, childrenOption) => {
                const customCategoryOptions = childrenOption.childrenOptions
                  ? childrenOption.childrenOptions.map(updateVisibility(null, null, !item.visible))
                  : []

                const updatedOption = updateVisibility(null, null, !item.visible)(childrenOption)

                return {
                  envelope: [
                    ...acc.envelope,
                    ...(!isCategoryGroup(updatedOption.group) ? [updatedOption] : []),
                  ],
                  category: [
                    ...acc.category,
                    ...customCategoryOptions,
                    ...(isCategoryGroup(updatedOption.group) ? [updatedOption] : []),
                  ],
                }
              },
              {
                envelope: [],
                category: [],
              },
            )
          : {
              envelope: [],
              category: [],
            }

        const otherEnvelopeOptions = flattenedOptions.filter((option) => {
          return (
            option.group &&
            isEnvelopeGroup(option.group) &&
            item.childrenOptions &&
            !item.childrenOptions.includes(option)
          )
        })

        const otherCategoryOptions = flattenedOptions.filter((option) => {
          return (
            option.group &&
            isCategoryGroup(option.group) &&
            item.childrenOptions &&
            !item.childrenOptions
              .map((childrenOption) => childrenOption._id)
              .includes(option.parentOptionId) &&
            !item.childrenOptions.includes(option)
          )
        })

        return {
          [CategoryFilterGroup.ENVELOPE]: filterVisibleOptions([
            ...otherEnvelopeOptions,
            ...updatedOptions.envelope,
          ]),
          [CategoryFilterGroup.CATEGORY]: filterVisibleOptions([
            ...otherCategoryOptions,
            ...updatedOptions.category,
          ]),
        }

        // ENVELOPE OR CATEGORY WITH CHILDREN
      } else if (
        item.group &&
        (isEnvelopeGroup(item.group) || isCategoryGroup(item.group)) &&
        item.childrenOptions
      ) {
        const updatedChildrenOptions = item.childrenOptions.map(
          updateVisibility(null, null, !item.visible),
        )
        const updatedOption = updateVisibility(null, null, !item.visible)(item)
        const isOptionCategory = isCategoryGroup(updatedOption.group)

        const updatedOptions = {
          envelope: [...(!isOptionCategory ? [updatedOption] : [])],
          category: [...(isOptionCategory ? [updatedOption] : [])],
        }

        const otherCategoryOptions = flattenedOptions.filter((option) => {
          return (
            option.group &&
            isCategoryGroup(option.group) &&
            item.childrenOptions &&
            !item.childrenOptions.includes(option) &&
            option._id !== item._id
          )
        })
        const otherEnvelopeOptions = flattenedOptions.filter((option) => {
          return option.group && isEnvelopeGroup(option.group) && option._id !== item._id
        })

        return {
          [CategoryFilterGroup.ENVELOPE]: filterVisibleOptions([
            ...otherEnvelopeOptions,
            ...updatedOptions.envelope,
          ]),
          [CategoryFilterGroup.CATEGORY]: filterVisibleOptions([
            ...otherCategoryOptions,
            ...updatedOptions.category,
            ...updatedChildrenOptions,
          ]),
        }

        // CATEGORY OR ENVELOPE WITHOUT CHILDREN
      } else {
        const updatedOptions = flattenedOptions
          .filter((option) => option.group === item.group)
          .map(updateVisibility(itemId))

        if (item.group) {
          return {
            [item.group]: filterVisibleOptions(updatedOptions),
          }
        } else {
          return {}
        }
      }
    } else {
      return {}
    }
  }

  toggleFilterItem = (itemId: OptionId) => {
    const { name, options, groups, displayAll, onFilterChange } = this.props

    if (groups) {
      const updatedFilter = this.getUpdateFilterValues(itemId)
      // remove filters when both filters are empty
      if (
        _isEmpty(updatedFilter[CategoryFilterGroup.ENVELOPE]) &&
        _isEmpty(updatedFilter[CategoryFilterGroup.CATEGORY])
      ) {
        return onFilterChange({
          [CategoryFilterGroup.ENVELOPE]: undefined,
          [CategoryFilterGroup.CATEGORY]: undefined,
        })
      } else {
        return onFilterChange(updatedFilter)
      }
    } else {
      const updatedOptions = options.map(updateVisibility(itemId, displayAll))
      const filteredAccountIds = filterVisibleOptions(updatedOptions)

      return onFilterChange({ [name]: filteredAccountIds.length ? filteredAccountIds : undefined })
    }
  }

  getFilterValueLabel = () => {
    const { options, displayAll } = this.props
    const visibleOptions = flattenOptionsRecursively(options).filter((option) => option.visible)

    if (displayAll) {
      return <FormattedMessage id="filter.all" />
    } else if (visibleOptions.length === 1) {
      return visibleOptions[0].name
    } else if (visibleOptions.length === 0) {
      return <FormattedMessage id="filter.none" />
    } else {
      return visibleOptions.length
    }
  }

  getOptions = (options: Array<Option>): Array<React.ReactNode> => {
    const flattenedOptions = flattenOptionsRecursively(options)
    return options.map((option: Option): React.ReactNode => {
      const childrenOptions = option.childrenOptions
        ? this.getOptions(option.childrenOptions)
        : null

      const hasChildFilter =
        !this.props.displayAll &&
        flattenedOptions.some((flattenedOption) => {
          return flattenedOption.rootParentOptionId === option._id && flattenedOption.visible
        })
      const stateKeys: OptionId[] = Object.keys(this.state)
      // Object.keys returns all keys as strings, _id has to be compared as string as well
      const isCollapsible =
        this.state && stateKeys.includes(option._id.toString()) && !hasChildFilter
      const isExpanded = isCollapsible && this.state[option._id]

      return (
        <div
          key={option._id}
          className={styles.optionsGroup}
        >
          <MultiSelectFilterItem
            key={option._id}
            label={option.name}
            visible={option.visible}
            isCollapsible={isCollapsible}
            isExpanded={isExpanded}
            onExpandClick={() => isCollapsible && this.toggleGroup(option._id)}
            onClick={() => this.toggleFilterItem(option._id)}
          />
          {(!isCollapsible || (isCollapsible && isExpanded)) && childrenOptions}
        </div>
      )
    })
  }

  render() {
    const { label, labelAll, options, active, name, displayAll, onDisplayChange } = this.props

    const accordionTitle = [
      <span
        key="name"
        className={filterStyles.filterName}
      >
        <FormattedMessage id={label} />
      </span>,
      <span
        key="value"
        className={filterStyles.filterValue}
      >
        {this.getFilterValueLabel()}
      </span>,
    ]

    const accordionContent = (
      <div className={styles.options}>
        <div className={mergeClasses(styles.options, styles.all)}>
          <MultiSelectFilterItem
            key="all"
            label={formatMessage(labelAll)}
            visible={displayAll}
            onClick={() => this.selectAll()}
          />
        </div>

        {this.getOptions(options)}
      </div>
    )

    return (
      <Accordion
        name={name}
        className={mergeClasses(styles.multiSelectFilter, filterStyles.filter)}
        title={accordionTitle}
        content={accordionContent}
        active={active}
        onDisplayChange={onDisplayChange}
      />
    )
  }
}

type itemProps = {
  label: string
  visible: boolean
  isCollapsible?: boolean
  isExpanded?: boolean
  onExpandClick?: (e: React.SyntheticEvent) => void
  onClick: (e: React.SyntheticEvent) => void
}

MultiSelectFilterItem.defaultProps = {
  isCollapsible: false,
  isExpanded: false,
  onExpandClick: () => {},
}

function MultiSelectFilterItem({
  label,
  visible,
  isCollapsible,
  isExpanded,
  onExpandClick,
  onClick,
}: itemProps) {
  const expandedClass = isExpanded && styles.active
  return (
    <div className={mergeClasses(styles.selectItem, expandedClass)}>
      <div
        className={mergeClasses(styles.filterItem, !visible && filterStyles.invisible)}
        onClick={onClick}
      >
        <span className={mergeClasses(styles.icon, filterStyles.iconVisibility, "ic_visibility")} />
        <TruncatedText
          text={label}
          length={30}
        />
      </div>
      {isCollapsible && (
        <Icon
          name="dropdown"
          className={styles.collapseButton}
          onClick={onExpandClick}
          rotated={!isExpanded ? "counterclockwise" : undefined}
        />
      )}
    </div>
  )
}
