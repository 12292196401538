import React, { useState } from 'react'
import Widget from 'src/frontend/scenes/dashboard/components/Widget/Widget'
import { WidgetView } from 'src/frontend/scenes/dashboard/types'
import {
  PieAndBarSpendingData,
  SpendingPieOptions,
} from 'src/frontend/scenes/dashboard/widgets/PieAndBarSpending/types'
import { RecordCategoryLevel } from 'src/backend/categories/enums'
import * as actions from './actions'
import { connect } from 'react-redux'
import { Id } from 'src/types/CouchDb'
import WidgetBarChart from 'src/frontend/scenes/dashboard/widgets/PieAndBarSpending/WidgetBarChart'
import {
  convertPieDataToCategoriesFilter,
  filterBarDatasets,
} from 'src/frontend/scenes/dashboard/widgets/PieAndBarSpending/helpers'
import styles from './PieAndBarSpending.module.less'
import { openRecordsPreview } from 'src/frontend/scenes/records/recordList/actions'
import { FilterType } from 'src/types/Filter'
import { FormattedMessage } from 'src/frontend/modules/intl'
import WidgetPieChartContainer from 'src/frontend/scenes/dashboard/widgets/PieAndBarSpending/WidgetPieChartContainer'
import { ComplementaryButton } from 'src/frontend/components/Buttons/Buttons'

interface Props {
  widget: WidgetView & { data: PieAndBarSpendingData, options: SpendingPieOptions },
  handleChangeDepth: (
    id: Id,
    categoryId: number | string,
    categoryLevel: RecordCategoryLevel,
    direction: 1 | -1,
  ) => void
  showRecords: (filter: FilterType) => void
}

const mapDispatchToProps = {
  handleChangeDepth: actions.changeLevel,
  showRecords: openRecordsPreview,
}


function PieAndBarSpending(props: Props) {
  const [selectedIndex, setSelectedIndex] = useState<number>(null)
  const { widget, handleChangeDepth, showRecords } = props
  const { data, filter, options } = widget


  if (!data || !filter || !options) {
    return <Widget.Placeholder />
  }

  const { barData, pieData } = data

  const widgetAction = data.pieData.length > 0 && (
    <ComplementaryButton
      onClick={() => showRecords(convertPieDataToCategoriesFilter(filter, pieData, selectedIndex))}
    >
      <FormattedMessage id={{ B: 'transactions', W: 'menu.records' }} />
    </ComplementaryButton>
  )

  return (
      <Widget>
          <Widget.Header title={widget.title} action={widgetAction} />
          <Widget.Content className={styles.spendingCategoriesPie}>
              <WidgetPieChartContainer
                  data={data.pieData}
                  currency={data.referentialCurrency}
                  goBackId={data.goBackId}
                  widget={widget}
                  options={options}
                  selectedIndex={selectedIndex}
                  onIndexChange={setSelectedIndex}
                  onChangeDepth={handleChangeDepth}
              />
              {pieData.length > 0 && (
                  <div>
                      <WidgetBarChart
                          currency={data.referentialCurrency}
                          labels={barData.labels}
                          height={75}
                          filter={filter}
                          datasets={filterBarDatasets(barData.datasets, selectedIndex)}
                          filterIndex={selectedIndex}
                      />
                  </div>
              )}
          </Widget.Content>
      </Widget>
  )
}


export default connect(null, mapDispatchToProps)(PieAndBarSpending)
