export enum WidgetId {
  ACCOUNTS_BALANCE_CARD = 1,
  PERIODIC_DEBTS_CARD = 2,
  DEBT_VERSUS_INCOME_CARD = 3,
  ACCOUNT_TRANSFERS_CARD = 4,
  CASH_FLOW_CARD = 5,
  PLANNED_PAYMENTS_BY_CATEGORIES_CARD = 6,
  SIMPLE_BALANCE_CARD = 7,
  BALANCE_CHART_CARD = 8,
  PERIOD_2_PERIOD_CHART_CARD = 9,
  LAST_RECORDS_CARD = 10,
  SPENDING_BY_CATEGORIES_AND_LABELS_PIE_CHART_CARD = 11,
  CARDINALITY_SPENDING_CHART_CARD = 12,
  SPENDING_IN_TIME_CARD = 13,
  PLANNED_PAYMENTS_BY_DATE_CARD = 14,
  BALANCE_BY_CURRENCIES_CARD = 15,
  CREDIT_LINE_UTILIZATION_CARD = 16,
  CREDIT_UTILIZATION_CARD = 17,
  CASH_FLOW_CHART_CARD = 18,
  BALANCE_FORECAST = 19,
  REPORT_CF_CARD = 20,
  REPORT_INCOME_EXPENSE_CARD = 21,
  SIMPLE_CASH_FLOW_CARD = 22,
  SPENDING_BY_CATEGORIES_CARD = 23,
  PERFORMANCE_CARD = 24,
  SPENDING_BY_CATEGORIES_PIE_CHART_CARD = 25,
  UPCOMING_PLANNED_PAYMENTS = 26,
  CASH_RATIO_LIQUIDITY_CARD = 27,
  CASH_FLOW_CARD_BOARD = 28,
  CASH_FLOW_CHART_CARD_BOARD = 29,
  CASH_FLOW_TABLE = 30,
  KPI_CARD = 31,
  PLANNED_PAYMENT_CASH_FLOW_CARD = 40,
  OPERATING_PROFIT_CARD = 45,
  REVENUE_COST_GROWTH_CARD = 50,
  OUTSTANDING_DEBT_CARD = 55,
  COCKPIT_BOARD = 1000,
  ACCOUNTS_LIST = 1100,
  BUDGET_LIST = 1200,
  BALANCE_WITH_FORECAST_CHART_CARD = 1300,
  INCOME_BY_CATEGORIES_AND_LABELS_PIE_CHART_CARD = 1400,
  OTHER = 100000,
}

export const WidgetPremiumOption = {
  // [WidgetId.ACCOUNTS_BALANCE_CARD]: true,
  // [WidgetId.PERIODIC_DEBTS_CARD]: true,
  // [WidgetId.DEBT_VERSUS_INCOME_CARD]: true,
  // [WidgetId.ACCOUNT_TRANSFERS_CARD]: true,
  [WidgetId.CASH_FLOW_CARD]: false,  // mame
  // [WidgetId.PLANNED_PAYMENTS_BY_CATEGORIES_CARD]: true,
  // [WidgetId.SIMPLE_BALANCE_CARD]: false,
  [WidgetId.BALANCE_CHART_CARD]: false, // mame
  [WidgetId.PERIOD_2_PERIOD_CHART_CARD]: false, // mame
  [WidgetId.LAST_RECORDS_CARD]: false, // mame
  [WidgetId.SPENDING_BY_CATEGORIES_AND_LABELS_PIE_CHART_CARD]: true,
  [WidgetId.CARDINALITY_SPENDING_CHART_CARD]: true,
  // [WidgetId.SPENDING_IN_TIME_CARD]: true,
  // [WidgetId.PLANNED_PAYMENTS_BY_DATE_CARD]: true, // mame
  [WidgetId.BALANCE_BY_CURRENCIES_CARD]: false, // mame
  // [WidgetId.CREDIT_LINE_UTILIZATION_CARD]: true,
  [WidgetId.CREDIT_UTILIZATION_CARD]: true,
  [WidgetId.CASH_FLOW_CHART_CARD]: true, // mame
  [WidgetId.CASH_FLOW_CHART_CARD_BOARD]: true, // mame
  // [WidgetId.BALANCE_FORECAST]: false,
  // [WidgetId.REPORT_CF_CARD]: true,
  // [WidgetId.REPORT_INCOME_EXPENSE_CARD]: true,
  // [WidgetId.SIMPLE_CASH_FLOW_CARD]: false,
  [WidgetId.SPENDING_BY_CATEGORIES_CARD]: true, // mame
  [WidgetId.PERFORMANCE_CARD]: false, // mame
  [WidgetId.SPENDING_BY_CATEGORIES_PIE_CHART_CARD]: false, // mame
  [WidgetId.UPCOMING_PLANNED_PAYMENTS]: false,
  [WidgetId.CASH_RATIO_LIQUIDITY_CARD]: true, // mame
  [WidgetId.REVENUE_COST_GROWTH_CARD]: true,
  [WidgetId.ACCOUNTS_LIST]: false,
}

