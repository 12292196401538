import { contactForm, ContactFormState } from 'src/frontend/scenes/contacts/contactForm/reducer'
import { combineReducers } from 'redux'
import { contactList, ContactListState } from 'src/frontend/scenes/contacts/contactList/reducer'
import { contactFilter, ContactFilterState } from 'src/frontend/scenes/contacts/filter/reducer'

export interface ContactsState {
  contactForm: ContactFormState,
  contactList: ContactListState,
  contactFilter: ContactFilterState,
}

export default combineReducers({ contactForm, contactList, contactFilter })
