import * as React from 'react'
import { connect } from 'react-redux'
import _isEmpty from 'lodash/isEmpty'
import {
  Dimmer, Dropdown, Icon,
} from 'semantic-ui-react'
import IconOption from 'src/frontend/components/IconOption'
import { selectSortedFilters } from 'src/frontend/modules/filters/selectors'
import { formatMessage, FormattedMessage } from 'src/frontend/modules/intl'
import { Id } from 'src/types/CouchDb'
import { FilterDocument, FilterType } from 'src/types/Filter'
import styles from 'src/frontend/components/Filter/FilterSelect.module.less'
import SaveFilterPopup from 'src/frontend/components/Filter/SaveFilterPopup'
import SelectionComponent from 'src/frontend/components/SelectionComponent'
import { FilterIcon } from 'src/frontend/components/Icons/Icons'
import {
  selectErrors,
  selectFilter, selectFilterFormOpen,
  selectIsFilterChanged,
  selectIsFilterLoading, selectSelectedFilterId,
} from 'src/frontend/modules/filter/selectors'
import {
  addFilter,
  applyFilter, closeFilterForm, openFilterForm,
  resetFilter,
  setFilterValues,
  updateSelectedFilter,
} from 'src/frontend/modules/filter/actions'
import { RootState } from 'src/types/State'
import { PrimaryButton, SecondaryButton } from 'src/frontend/components/Buttons/Buttons'

type Props = {
  filter: FilterType,
  filters: Array<FilterDocument>,
  isFilterChanged: boolean,
  loading: boolean,
  selectedFilterId?: Id,
  errors: {
    name?: string,
  },
  filterFormOpen: boolean,

}

type Actions = {
  handleChangeFilterName: Function,
  handleResetFilter: (MouseEvent) => void,
  handleSelectFilter: (e: React.SyntheticEvent, data: { value: Id }) => void,
  handleAddFilter: Function,
  handleUpdateFilter: Function,
  handleOpenFilterForm: (MouseEvent) => void,
  handleCloseFilterForm: (MouseEvent) => void,
}

function mapStateToProps(state: RootState): Props {
  return {
    filters: selectSortedFilters(state),
    filter: selectFilter(state),
    isFilterChanged: selectIsFilterChanged(state),
    loading: selectIsFilterLoading(state),
    errors: selectErrors(state),
    selectedFilterId: selectSelectedFilterId(state),
    filterFormOpen: selectFilterFormOpen(state),
  }
}

const mapDispatchToProps = (dispatch): Actions => {
  return {
    handleChangeFilterName: (value) => dispatch(setFilterValues({ name: value })),
    handleResetFilter: () => dispatch(resetFilter()),
    handleSelectFilter: ((_e, { value }) => dispatch(applyFilter(value))),
    handleAddFilter: () => dispatch(addFilter()),
    handleUpdateFilter: () => dispatch(updateSelectedFilter()),
    handleOpenFilterForm: () => dispatch(openFilterForm()),
    handleCloseFilterForm: () => dispatch(closeFilterForm()),
  }
}

type State = {
  showSaveDropdown: boolean,
}

class FilterSelect extends React.Component<Props & Actions, State> {
  static defaultProps = {
    selectedFilterId: null,
  }

  state = {
    showSaveDropdown: false,
  }

  handleShowDropdown = () => this.setState(() => ({ showSaveDropdown: true }))

  handleHideDropdown = () => this.setState(() => ({ showSaveDropdown: false }))

  toggleDropdown = () => this.setState(prevState => ({ showSaveDropdown: !prevState.showSaveDropdown }))

  handleAddFilter = () => {
    this.handleHideDropdown()
    this.props.handleAddFilter()
  }

  handleUpdateFilter = () => {
    this.handleHideDropdown()
    this.props.handleUpdateFilter()
  }

  render() {
    const {
      filter,
      filters,
      isFilterChanged,
      selectedFilterId,
      loading,
      errors,
      filterFormOpen,
      handleChangeFilterName,
      handleResetFilter,
      handleSelectFilter,
      handleOpenFilterForm,
      handleCloseFilterForm,
    }: Props & Actions = this.props

    const {
      showSaveDropdown,
    }: State = this.state

    interface FilterListOption {
      key: (string | number),
      value: (string | number),
      text: React.ReactNode,
      content: React.ReactNode,
    }

    const filterList = filters.map((filterOption: FilterDocument): FilterListOption => ({
      key: filterOption._id,
      value: filterOption._id,
      text: (
        <IconOption
          icon={<FilterIcon size="1.8rem" />}
          label={filterOption.name}
        />
      ),
      content: (
        <IconOption
          icon={<FilterIcon size="1.8rem" />}
          label={filterOption.name}
        />
      ),
    }))

    return [
      (
        <Dimmer key="dimmer" page active={filterFormOpen} />
      ),
      (
        <div key="filter-select" className={styles.filterSelect}>
          <SelectionComponent
            fluid
            name="selectFilter"
            className={styles.iconSelect}
            placeholder={formatMessage(`filter.${_isEmpty(filterList) ? 'no-filters' : 'select-filter'}`)}
            value={selectedFilterId}
            disabled={_isEmpty(filterList) || loading}
            options={filterList}
            onChange={handleSelectFilter}
          />
          {(isFilterChanged || selectedFilterId) && (
            <div className={styles.filterActions} key="filter-actions">
              <SecondaryButton
                fluid
                onClick={handleResetFilter}
              >
                <FormattedMessage id="filter.no-filter" />
              </SecondaryButton>
              {selectedFilterId ? (
                <Dropdown
                  trigger={(
                    <PrimaryButton fluid onClick={this.toggleDropdown}>
                      <FormattedMessage id="form.save" />
                      <Icon name="dropdown" />
                    </PrimaryButton>
                  )}
                  open={showSaveDropdown && isFilterChanged && !loading}
                  onClose={this.handleHideDropdown}
                  fluid
                  basic
                  icon={false}
                  closeOnChange={false}
                  loading={loading}
                  disabled={!isFilterChanged || loading}
                >
                  <Dropdown.Menu>
                    <Dropdown.Item
                      text={`${formatMessage('form.update')} ${filter.name || ''}`}
                      onClick={this.handleUpdateFilter}
                    />
                    <SaveFilterPopup
                      trigger={(
                        <Dropdown.Item
                          onClick={this.handleHideDropdown}
                          text={formatMessage('form.save-as-new')}
                        />
                      )}
                      open={filterFormOpen}
                      name={filter.name}
                      errors={errors}
                      onClose={handleCloseFilterForm}
                      onOpen={handleOpenFilterForm}
                      onChange={handleChangeFilterName}
                      onSave={this.handleAddFilter}
                    />
                  </Dropdown.Menu>
                </Dropdown>
              ) : (
                <SaveFilterPopup
                  trigger={(
                    <PrimaryButton
                      fluid
                      disabled={!isFilterChanged || loading || filterFormOpen}
                      loading={loading}
                    >
                      <FormattedMessage id="form.save" />
                    </PrimaryButton>
                  )}
                  errors={errors}
                  open={filterFormOpen}
                  name={filter.name}
                  onClose={handleCloseFilterForm}
                  onOpen={handleOpenFilterForm}
                  onChange={handleChangeFilterName}
                  onSave={this.handleAddFilter}
                />
              )}
            </div>
          )}
        </div>
      ),
    ]
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FilterSelect)
