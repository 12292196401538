import { NewIntegrationsStatus } from 'src/frontend/scenes/integrations/enums'
import CountryAndProviderSearch
  from 'src/frontend/scenes/integrations/providersList/components/CountryAndProviderSearch'
import LoginCredentials from 'src/frontend/scenes/integrations/newConnection/components/LoginCredentials'
import NewBankConnectionMFA from 'src/frontend/scenes/integrations/newConnection/components/NewBankConnectionMFA'
import { Loader } from 'semantic-ui-react'
import { FormattedMessage } from 'src/frontend/modules/intl'
import SelectProviderAccount from 'src/frontend/scenes/integrations/newConnection/components/SelectProviderAccount'
import React from 'react'
import PersonalDetailsForm from 'src/frontend/scenes/integrations/newConnection/components/PersonalDetailsForm'

interface Props {
  integrationStatus: NewIntegrationsStatus
  hideProviderLogo?: boolean
}

NewIntegrationContent.defaultProps = {
  hideProviderLogo: false,
}

export function NewIntegrationContent({ integrationStatus, hideProviderLogo }: Props) {
  switch (integrationStatus) {
    default:
    case NewIntegrationsStatus.PROVIDER_SELECT: {
      return <CountryAndProviderSearch />
    }

    case NewIntegrationsStatus.CREDENTIALS_FORM: {
      return <LoginCredentials hideProviderLogo={hideProviderLogo} />
    }


    case NewIntegrationsStatus.MFA_FORM: {
      return <NewBankConnectionMFA />
    }

    case NewIntegrationsStatus.PERSONAL_INFORMATION: {
      return <PersonalDetailsForm />
    }

    case NewIntegrationsStatus.LOADING: {
      return (
        <div className="connection-loading">
          <Loader active inline="centered" />
          <h3>
            <FormattedMessage id="integrations.newConnection.connecting.title" />
          </h3>
          <p>
            <FormattedMessage id="integrations.newConnection.connecting.text" />
          </p>
        </div>
      )
    }

    case NewIntegrationsStatus.INTEGRATED_ACCOUNT_SELECT: {
      return <SelectProviderAccount />
    }
  }
}
