import { CashFlowBarColors } from 'src/frontend/scenes/dashboard/constants'

export const debtPieOptions = {
  maintainAspectRatio: false,
  cutoutPercentage: 75,
  animation: {
    animationRotate: true,
    duration: 2000,
  },
  legend: {
    display: false,
  },
  tooltips: {
    enabled: false,
  },
  plugins: {
    labels: {
      render: () => {
        return ''
      },
    },
  },
  radiusBackground: {
    color: '#f3f3f3', // Set your color per instance if you like
  },
}


export function getDebtPieChartData(value: number, color: CashFlowBarColors) {
  return {
    // labels: [
    //   'Value',
    // ],
    datasets: [
      {
        data: [value, 1 - value],
        backgroundColor: [
          color,
          'transparent',
        ],
        hoverBackgroundColor: [
          color,
          'transparent',
        ],
        borderWidth: [
          0, 0,
        ],
      },
    ],
  }
}
