import * as commonService from 'src/backend/common/service'
import * as logger from 'src/common/logger'

export const SETTINGS_REORDER_START = 'SETTINGS_REORDER_START'
export const SETTINGS_REORDER_SUCCESS = 'SETTINGS_REORDER_SUCCESS'

export function reorder(oldIndex, newIndex, tableName, fetchCallback) {
  return async (dispatch) => {
    dispatch(reorderStart())
    try {
      await commonService.reorderEntities(oldIndex, newIndex, tableName)
      dispatch(fetchCallback())
      return dispatch(reorderSuccess())

    } catch (error) {
      logger.captureException(error, 'settings.reorder', { oldIndex, newIndex, tableName })
    }
  }
}

function reorderStart() {
  return { type: SETTINGS_REORDER_START }
}

function reorderSuccess() {
  return { type: SETTINGS_REORDER_SUCCESS }
}
