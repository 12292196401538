import { Platform } from 'src/backend/enums'
import { WalletBillingError, WalletRestApiError } from 'src/backend/errors'
import promiseRetry from 'promise-retry'
import { getAppFlavor, getVersion } from 'src/common/environment'
import { IntegrationError } from 'src/backend/integrations/errors'
import { deserializeProtobuffResponse } from 'src/backend/rest/ribeezHelpers'
import { BraintreeErrorResponse, IntegrationError as IntegrationErrorMessage } from 'static/proto/ribeez_pb'
import { getUserLanguage } from 'src/backend/db/localStorage'

export function validateSuccessResponse(response: Response): Response {
  if (!(response.status >= 200 && response.status < 300)) {
    const errorMessage = `${response.status} - ${response.statusText}!`
    throw new WalletRestApiError(errorMessage, response)
  }
  return response
}

export async function validateIntegrationsSuccessResponse(response: Response): Promise<Response> {
  if (response.status === 500) {
    const { type } = await deserializeProtobuffResponse(IntegrationErrorMessage)(response)

    throw new IntegrationError(type)
  }

  if (!(response.status >= 200 && response.status < 300)) {
    const errorMessage = `${response.status} - ${response.statusText}!`
    throw new WalletRestApiError(errorMessage, response)
  }
  return response
}

export async function validateBillingSuccessResponse(response: Response): Promise<Response> {
  if (response.status === 422) {
    const { code } = await deserializeProtobuffResponse(BraintreeErrorResponse)(response)
    throw new WalletBillingError(code)
  }

  if (!(response.status >= 200 && response.status < 300)) {
    const errorMessage = `${response.status} - ${response.statusText}!`
    throw new WalletRestApiError(errorMessage, response)
  }
  return response
}

export function validateCorsSuccessResponse(response: Response): Response {
  if (!((response.status >= 200 && response.status < 300) || response.status === 303 || response.status === 0)) {
    const errorMessage = `${response.status} - ${response.statusText}!`
    throw new WalletRestApiError(errorMessage, response)
  }
  return response
}


export function withRetry(callback: () => Promise<Response>) {
  return promiseRetry(retry => {
    return callback()
      .catch(error => {
        if (error.code === 'ETIMEDOUT') {
          return retry(error)
        }
        throw error
      })
  }, { retries: 5 })
}

export function fetchWithRetry(input: RequestInfo, init?: RequestInit): Promise<Response> {
  return withRetry(() => fetch(input, init)) as Promise<Response>
}


export const buildFetchOptions = (method: 'GET' | 'POST' | 'PUT' | 'DELETE'): RequestInit => ({
  method,
  headers: {
    Accept: 'application/json, text/plain, text/html, */*',
    'Web-Version-Code': getVersion(),
    Platform: Platform.WEB,
    flavor: getAppFlavor(),
  } as unknown as HeadersInit,
  credentials: 'include',
})

export function withXLocaleHeader(request: RequestInit): RequestInit {
  return { ...request, headers: { ...request.headers, 'x-locale': getUserLanguage() } }
}
