import {
  CANCEL_DELETE_RECORDS,
  CONFIRM_DELETE_RECORDS,
  DELETE_RECORDS_DONE,
  DELETING_RECORDS,
  IMPORTS_RECORDS_GROUP_TOGGLE,
  IMPORTS_RECORDS_RECEIVED,
  RECORDS_SELECT_TOGGLE,
  SELECT_RECORD,
} from 'src/frontend/scenes/imports/records/actions'

const initialState = {
  recordList: [],
  recordGroupStatus: new Map(),
  selectedRecords: new Map(),
  removeForm: { open: false, isRemoving: false },
}

export default (state = initialState, action) => {
  switch (action.type) {

    case IMPORTS_RECORDS_RECEIVED: {
      return { ...state, recordList: action.payload }
    }

    case IMPORTS_RECORDS_GROUP_TOGGLE: {
      state.recordGroupStatus.set(
        action.transactionId,
        !state.recordGroupStatus.getOrDefault(action.transactionId, true),
      )
      return { ...state, recordGroupStatus: new Map(state.recordGroupStatus) }
    }

    case RECORDS_SELECT_TOGGLE: {
      const selectedRecords = new Map(state.selectedRecords)
      action.visibleRecordIds.forEach(recordId => selectedRecords.set(recordId, action.select))
      return { ...state, selectedRecords }
    }

    case SELECT_RECORD: {
      const selectedRecords = new Map(state.selectedRecords)
      action.recordIds.forEach(recordId => {
        selectedRecords.set(recordId, !selectedRecords.getOrDefault(recordId, false))
      })
      return { ...state, selectedRecords }
    }

    case CONFIRM_DELETE_RECORDS: {
      return { ...state, removeForm: { ...state.removeForm, open: true } }
    }

    case DELETING_RECORDS: {
      return { ...state, removeForm: { ...state.removeForm, isRemoving: true } }
    }

    case DELETE_RECORDS_DONE:
    case CANCEL_DELETE_RECORDS: {
      return {
        ...state,
        selectedRecords: new Map(),
        removeForm: {
          ...state.removeForm,
          open: false,
          isRemoving: false,
        },
      }
    }

    default: {
      return state
    }
  }
}
