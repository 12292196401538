// Enumeration of 0-debt, 1-transfer, 2-shoppinglist, 3-oneclick. It's historically required for all system categories.
export enum SystemCategoryType {
  DEBT = 0,
  TRANSFER = 1,
  SHOPPING_LIST = 2,
  ONE_CLICK = 3,
  BANK_STATEMENT = 4,
  UNKNOWN = 5,
}

export enum RecordCategoryLevel {
  SUPER_ENVELOPE = "SUPER_ENVELOPE",
  ENVELOPE = "ENVELOPE",
  CATEGORY = "CATEGORY",
}

export enum EnvelopeCardinality {
  MUST,
  NEED,
  WANT,
  FIXED_COSTS,
  VARIABLE_COSTS,
  ASSETS_COSTS,
  REVENUE,
  NONE,
}
