import React, { useEffect } from "react"
import { connect } from "react-redux"
import AccountContent from "src/frontend/scenes/imports/account/components/AccountContent"
import NoAccountContent from "src/frontend/scenes/imports/records/components/NoAccountContent"
import * as importsActions from "src/frontend/scenes/imports/actions"
import { PageMode } from "src/frontend/scenes/imports/constants"
import ItemsPanel from "src/frontend/scenes/imports/items/components/ItemsPanel"
import { selectShowRecords } from "src/frontend/scenes/imports/records/selectors"
import { selectIsImportsInitialized, selectPageMode } from "src/frontend/scenes/imports/selectors"
import MappingWizard from "src/frontend/scenes/imports/mapping/components/MappingWizard"
import MappingAssignPanel from "src/frontend/scenes/imports/mapping/components/MappingAssignPanel"
import MappingPreviewPanel from "src/frontend/scenes/imports/mapping/components/MappingPreviewPanel"
import { TourGuideType, TourGuideWrapper } from "src/frontend/components/TourGuide"
import { selectShouldRefreshImportsRecordList } from "src/frontend/modules/moduleStatus/selectors"
import * as moduleStatusActions from "src/frontend/modules/moduleStatus/actions"
import ColumnLayout from "src/frontend/components/ColumnLayout/ColumnLayout"
import ImportAccountFilter from "src/frontend/scenes/imports/account/components/ImportAccountFilter"
import MappingAssignTopPanel from "src/frontend/scenes/imports/mapping/components/MappingAssignTopPanel"
import { FormattedMessage } from "src/frontend/modules/intl"

type Props = {
  showRecords: boolean
  pageMode: string
  initialized: boolean
  shouldRefreshImportsRecordsList: boolean
}

type Actions = {
  initImportsLayout: Function
  refreshImportsRecordsList: Function
}

const mapStateToProps = (state) => ({
  showRecords: selectShowRecords(state),
  pageMode: selectPageMode(state),
  initialized: selectIsImportsInitialized(state),
  shouldRefreshImportsRecordsList: selectShouldRefreshImportsRecordList(state),
})

const mapDispatchToProps = {
  initImportsLayout: importsActions.initImportsLayout,
  refreshImportsRecordsList: moduleStatusActions.refreshImportsRecordListStatus,
}

function Imports({
  pageMode,
  initialized,
  shouldRefreshImportsRecordsList,
  refreshImportsRecordsList,
  initImportsLayout,
  showRecords,
}: Props & Actions) {
  useEffect(() => {
    initImportsLayout()
  }, [])

  useEffect(() => {
    if (shouldRefreshImportsRecordsList) {
      refreshImportsRecordsList()
    }
  }, [shouldRefreshImportsRecordsList])

  if (!initialized) {
    return null
  }

  switch (pageMode) {
    case PageMode.CREATE_ACCOUNT: {
      return (
        <ColumnLayout
          title={<FormattedMessage id="imports.page_title" />}
          leftPanel={null}
          content={<NoAccountContent />}
        />
      )
    }

    case PageMode.RECORDS: {
      return (
        <TourGuideWrapper name={TourGuideType.IMPORTS_MAIN}>
          <ColumnLayout
            title={<FormattedMessage id="imports.page_title" />}
            topPanel={<ImportAccountFilter />}
            leftPanel={<ItemsPanel />}
            content={<AccountContent hasRecords={showRecords} />}
          />
        </TourGuideWrapper>
      )
    }

    case PageMode.MAPPING: {
      return (
        <TourGuideWrapper name={TourGuideType.IMPORTS_MAPPING}>
          <ColumnLayout
            leftPanel={<MappingWizard />}
            topPanel={<MappingAssignTopPanel />}
            content={<MappingAssignPanel />}
          />
          <MappingPreviewPanel />
        </TourGuideWrapper>
      )
    }

    default: {
      console.error(`Unknown pageMode=${pageMode}`)
      return null
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Imports)
