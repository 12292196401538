import _range from "lodash/range"
import _uniqBy from "lodash/uniqBy"
import { path, pipe } from "ramda"
import { ImportErrorType, ImportMappingAttributeType } from "src/backend/enums"
import { COLUMN_UNSET_INDEX } from "src/frontend/scenes/imports/mapping/constants"
import { isUndefinedOrNull, mapType } from "src/common/utils"
import { formatMessage } from "src/frontend/modules/intl/i18n"

export function formatAttributeName(type) {
  return formatMessage(`imports.attributeType.${type}`)
}

export const buildRowPositionOptions = (from, to) =>
  _range(from, to + 1).map((element) => ({ text: element.toString(), value: element }))

export const buildColumnOptions = (availableColumns) => {
  return availableColumns.map(({ colIndex, colName, type }) => {
    return type !== ImportMappingAttributeType.UNSET
      ? {
          value: colIndex,
          text: colName,
        }
      : {
          value: COLUMN_UNSET_INDEX,
          text: formatMessage("imports.column.unset"),
        }
  })
}

export const LETTERS_IN_ALPHABET = 26
const A_ASCII_POSITION = 65

export const createColumnName = (columnIndex) => {
  const remainder = columnIndex % LETTERS_IN_ALPHABET
  const columnName = String.fromCharCode(A_ASCII_POSITION + remainder)
  const multiplier = Math.floor(columnIndex / LETTERS_IN_ALPHABET)
  return multiplier > 0 ? `${createColumnName(multiplier - 1)}${columnName}` : columnName
}

export const getErrorType = (error) => path(["response", "type"], error)

export const isRecoverableError = pipe(
  getErrorType,
  (errorType) => errorType === ImportErrorType.PRECONDITION_FAILED,
)

export const buildMissingRequiredAttributesError = (missingRequiredAttributes) => ({
  line: 1,
  tokens: [
    {
      type: ImportErrorType.MISSING_REQUIRED_FIELD,
      value: missingRequiredAttributes,
    },
  ],
})

export function getHeaderName(headers = [], columnIndex = -1) {
  const header = headers[columnIndex] || {}
  if (!header.colName) {
    console.warn(`Returning colName=${header.colName} for columnIndex=${columnIndex}`)
  }
  return header.colName
}

export function formatErrorTitle(errorType, headerName) {
  switch (errorType) {
    case ImportErrorType.NUMBER_FORMAT:
      return formatMessage("imports.importErrorType.question", {
        attributeName: formatAttributeName(ImportMappingAttributeType.AMOUNT),
        headerName,
      })

    case ImportErrorType.DATE_FORMAT:
      return formatMessage("imports.importErrorType.question", {
        attributeName: formatAttributeName(ImportMappingAttributeType.RECORDDATE),
        headerName,
      })

    case ImportErrorType.CURRENCY_FORMAT:
      return formatMessage("imports.importErrorType.currency_title")

    case ImportErrorType.INCOME_EXPENSE_FILLED:
      return formatMessage("imports.importErrorType.something_not_right")

    case ImportErrorType.MISSING_REQUIRED_FIELD:
      return formatMessage("imports.importErrorType.just_a_moment")

    case ImportErrorType.MULTIPLE_ERROR_TYPE:
      return formatMessage("imports.importErrorType.something_not_right")

    default:
      console.warn(`ErrorTitle: unsupported errorType=${errorType}`)
      return formatMessage("imports.importErrorType.something_not_right")
  }
}

// Don't display the same error twice, for instance income/expense on the same row
// that backend reports twice for each column
export function filterTokens(tokens) {
  return _uniqBy(tokens, mapType)
}

export function getColumnErrorRow(colIndex, tableErrors) {
  return tableErrors.findIndex((errorRow) => {
    return Array.isArray(errorRow) && !isUndefinedOrNull(errorRow[colIndex])
  })
}

export function hasColumnError(colIndex, tableErrors) {
  return getColumnErrorRow(colIndex, tableErrors) > -1
}

export function getColumnErrorType(colIndex, tableErrors) {
  return tableErrors[getColumnErrorRow(colIndex, tableErrors)][colIndex].type
}
