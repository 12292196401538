import { Action } from 'src/types/common'
import {
  TRANSACTION_HISTORY_FETCH_START,
  TRANSACTION_HISTORY_FETCH_SUCCESS,
} from 'src/frontend/scenes/billing/transactionHistory/actions'
import { BraintreeTransaction } from 'src/frontend/scenes/billing/transactionHistory/types'

export interface TransactionHistoryState {
  loading: boolean,
  transactions: BraintreeTransaction[]
}

const initialState: TransactionHistoryState = {
  loading: false,
  transactions: null,
}

export default function transactionHistory(
  state: TransactionHistoryState = initialState,
  action: Action,
): TransactionHistoryState {
  switch (action.type) {
    case TRANSACTION_HISTORY_FETCH_START: {
      return { ...state, loading: true }
    }

    case TRANSACTION_HISTORY_FETCH_SUCCESS: {
      return { ...state, loading: false, transactions: action.payload }
    }

    default: {
      return state
    }
  }
}
