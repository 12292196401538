import { createSelector } from 'reselect'
import { selectFlattenedCategories } from '../categories/selectors'
import * as currenciesSelectors from '../currencies/selectors'
import * as accountsSelectors from '../accounts/selectors'
import { sortEntities } from 'src/backend/common/helpers'
import {
  getRecordAccount,
  getRecordCategory,
  getRecordCurrency,
} from 'src/frontend/scenes/records/recordList/selectors'


export const selectTemplates = state => state.modules.templates

export const selectSortedTemplates = createSelector(
  selectTemplates,
  selectFlattenedCategories,
  currenciesSelectors.selectCurrencies,
  accountsSelectors.selectAllAccounts,
  aggregateTemplates(sortEntities),
)

function aggregateTemplates(sortEntitiesFn) {
  return (templates, categories, currencies, accounts) => {
    const templatesWithIconAndColor = Object.values(templates).map(template => {

      const category = getRecordCategory(template, categories)
      const account = getRecordAccount(template, accounts)
      const currency = getRecordCurrency(template, currencies)
      return {
        ...template,
        icon: category.categoryIcon,
        color: category.color,
        accountName: account.name,
        currencyCode: currency.code,
      }
    })

    return sortEntitiesFn(templatesWithIconAndColor)
  }
}
