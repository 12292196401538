import * as billing from 'src/backend/rest/backend/billing'
import { Action } from 'src/types/common'
import { isAuthorizationError } from 'src/common/helpers'
import { expireUser } from 'src/frontend/modules/user/actions'
import * as logger from 'src/common/logger'
import { BraintreeDetails } from 'src/frontend/scenes/billing/activeSubscription/types'


export const ACTIVE_SUBSCRIPTION_FETCH_START = 'ACTIVE_SUBSCRIPTION_FETCH_START'
export const ACTIVE_SUBSCRIPTION_FETCH_SUCCESS = 'ACTIVE_SUBSCRIPTION_FETCH_SUCCESS'
export const ACTIVE_SUBSCRIPTION_FETCH_ERROR = 'ACTIVE_SUBSCRIPTION_FETCH_ERROR'


export function getActiveSubscriptionDetails() {
  return async (dispatch: Function) => {
    dispatch(getActiveSubscriptionDetailsStart())
    try {
      const subscriptionDetails: BraintreeDetails = await billing.fetchActiveSubscriptionDetails()
      dispatch(getActiveSubscriptionDetailsSuccess(countryCodeToLowercase(subscriptionDetails)))
    } catch (error) {
      dispatch(getActiveSubscriptionDetailsError())
      if (isAuthorizationError(error)) {
        dispatch(expireUser())
        return
      } else {
        logger.captureException(error, 'billing.getActiveSubscriptionDetails')
      }
    }
  }
}

function countryCodeToLowercase(braintreeDetails: BraintreeDetails): BraintreeDetails {
  if (braintreeDetails?.billingAddress?.countryCodeAlpha2) {
    return {
      ...braintreeDetails,
      billingAddress: {
        ...braintreeDetails.billingAddress,
        countryCodeAlpha2: braintreeDetails.billingAddress.countryCodeAlpha2.toLowerCase(),
      },
    }
  } else {
    return braintreeDetails
  }
}

function getActiveSubscriptionDetailsStart(): Action {
  return {
    type: ACTIVE_SUBSCRIPTION_FETCH_START,
  }
}

function getActiveSubscriptionDetailsSuccess(subscriptionDetails: BraintreeDetails): Action<BraintreeDetails> {
  return {
    type: ACTIVE_SUBSCRIPTION_FETCH_SUCCESS,
    payload: subscriptionDetails,
  }
}

function getActiveSubscriptionDetailsError(): Action {
  return {
    type: ACTIVE_SUBSCRIPTION_FETCH_ERROR,
  }
}
