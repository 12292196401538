import React from "react"
import DatePicker from "react-datepicker/dist/react-datepicker"
import moment from "moment"
import "./WeekContent.less"
import { Interval } from "src/backend/enums"
import { Period } from "src/types/Filter"

type Props = {
  period: Period
  onChange: Function
  onClose: () => void
}

export default class WeekContent extends React.Component<Props> {
  handleChangeWeek = (date: Date) => {
    this.props.onChange({
      interval: Interval.WEEK,
      start: moment(date).startOf("week"),
      end: moment(date).endOf("week"),
    })
    this.props.onClose()
  }

  render() {
    const { start, end, interval } = this.props.period
    return (
      <div className="week-datepicker">
        <DatePicker
          selected={interval === Interval.WEEK ? start : null}
          startDate={interval === Interval.WEEK ? start : null}
          endDate={interval === Interval.WEEK ? end : null}
          inline
          onChange={this.handleChangeWeek}
        />
      </div>
    )
  }
}
