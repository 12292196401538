import { reduceBy } from 'src/common/utils'
import * as service from 'src/backend/labels/service'
import { Label } from 'src/types/Label'
import { Action } from 'src/types/common'

export const LABELS_RECEIVE = 'LABELS_RECEIVE'

export function receiveLabels(labelsArray: Label[]): Action {
  const LABEL_ID = '_id'
  const labels = labelsArray.reduce(reduceBy(LABEL_ID), {})
  return { type: LABELS_RECEIVE, payload: labels }
}

export function fetchLabels() {
  return async (dispatch: Function) => {
    const labels = await service.fetchLabels()
    dispatch(receiveLabels(labels))
  }
}
