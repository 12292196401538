import { Action } from 'src/types/common'
import { AccountsListOrderBy } from 'src/frontend/scenes/accounts/accountsList/enums'

export const ACCOUNTS_LIST_CHANGE_ORDER = 'ACCOUNTS_LIST_CHANGE_ORDER'


export function changeOrder(orderBy: AccountsListOrderBy): Action<AccountsListOrderBy> {
  return {
    type: ACCOUNTS_LIST_CHANGE_ORDER,
    payload: orderBy,
  }
}
