import React from 'react'
import { IntegrationProviders } from 'src/frontend/scenes/integrations/providersList/types'
import MFAFormInput from 'src/frontend/scenes/integrations/components/MFAFormInput'
import MFAFormSelect, {
  MFAFormSelectProps,
} from "src/frontend/scenes/integrations/components/MFAFormSelect"
import MFAFormCheckbox from 'src/frontend/scenes/integrations/components/MFAFormCheckbox'
import MFAFormHTML from 'src/frontend/scenes/integrations/components/MFAFormHTML'
import { IntegrationSource } from 'src/backend/integrations/enums'
import InputType = IntegrationProviders.IntegrationRecipe.InputType
import IntegrationRecipeParam = IntegrationProviders.IntegrationRecipe.IntegrationRecipeParam
import MFAOAuthRedirect from 'src/frontend/scenes/integrations/components/MFAOauthRedirect'


interface Props {
  param: IntegrationRecipeParam
  value?: string | boolean
  onChange: (
    field: string,
    value: string | string[] | number | boolean,
    shouldSubmit?: boolean,
  ) => void
}

export function getFormComponent(type: InputType): React.FunctionComponent<Props> {
  switch (type) {
    default:
    case InputType.TEXT:
    case InputType.PASSWORD:
    case InputType.NUMBER:
      return MFAFormInput

    // case InputType.DROPDOWN:
    case InputType.SELECT:
    case InputType.LIST:
      return MFAFormSelect

    case InputType.MULTI_SELECT:
      return (props: MFAFormSelectProps) => MFAFormSelect({ ...props, multiple: true })

    case InputType.BOOLEAN:
      return MFAFormCheckbox

    case InputType.HTML:
      return MFAFormHTML

    case InputType.OAUTH_REDIRECT_URL:
      return MFAOAuthRedirect
  }
}

export function getIntegrationSourcePollingTimeout(integrationSource: IntegrationSource): number {
  switch (integrationSource) {
    default: {
      return 4000
    }

    case IntegrationSource.BUDGETBAKERS: {
      return 1000
    }
  }
}
