import { convertResultToEntity, fetchAllFromTable } from "src/backend/db/inMemorySqlDb"
import { inMemoryTableNames } from "src/backend/db/inMemorySqlDbSchemaBuilder"
import * as commonRepository from "src/backend/common/repository"
import { Contact } from "src/types/Contact"
import { HashMap } from "src/types/common"

export function findAll(): Promise<Contact.ContactDocument[]> {
  return fetchAllFromTable<Contact.ContactDocument>(inMemoryTableNames.CONTACT)
}

export function findById(id): Promise<Contact.ContactDocument> {
  return commonRepository
    .findBy(inMemoryTableNames.CONTACT, "_id", id)
    .then(convertResultToEntity())
}

export function findAllAsHashMap(): Promise<HashMap<Contact.ContactDocument>> {
  return commonRepository.findAllAsHashMap(inMemoryTableNames.CONTACT)
}
