import React from 'react'
import { getFileExtension, mergeClasses } from 'src/common/utils'
import { RootState } from 'src/types/State'
import { selectImportingItemName } from 'src/frontend/scenes/imports/mapping/selectors'
import { connect } from 'react-redux'
import styles from './MappingAssignTopPanel.module.less'

function getIconClassName(fileName: string): string {
  switch (getFileExtension(fileName)) {
    default:
    case 'csv':
      return 'csv_ic'
    case 'xls':
      return 'xls_ic'
    case 'xlsx':
      return 'xlsx_ic'
    case 'ofx':
      return 'ofx_ic'
  }
}

function mapStateToProps(state: RootState) {
  return {
    importItemName: selectImportingItemName(state),
    itemExtClassName: getIconClassName(selectImportingItemName(state)),
  }
}

function MappingAssignTopPanel({ itemExtClassName, importItemName }) {
  return (
    <h2 className={styles.mappingTopPanel}>
      <span className={mergeClasses(styles.icon, styles[itemExtClassName])} />
      {importItemName}
    </h2>
  )
}

export default connect(mapStateToProps)(MappingAssignTopPanel)
