import React from 'react'
import { connect } from 'react-redux'
import { Loader, Modal } from 'semantic-ui-react'
import BasicRecordList from 'src/frontend/components/RecordList/BasicRecordList'
import { RecordListItemRecord } from 'src/frontend/components/RecordList/types'
import { FormattedMessage } from 'src/frontend/modules/intl'
import {
  selectIsDeleteItemModalInProgress,
  selectIsDeleteItemModalOpen,
  selectIsItemToDeleteImported,
  selectItemNameToDelete,
} from 'src/frontend/scenes/imports/items/selectors'
import { selectItemToDeleteRecordList } from 'src/frontend/scenes/imports/records/selectors'
import * as itemActions from 'src/frontend/scenes/imports/items/actions'
import './DeleteItemModal.less'
import { PrimaryButton, SecondaryButton } from 'src/frontend/components/Buttons/Buttons'

const mapStateToProps = state => ({
  itemName: selectItemNameToDelete(state),
  open: selectIsDeleteItemModalOpen(state),
  progress: selectIsDeleteItemModalInProgress(state),
  isItemImported: selectIsItemToDeleteImported(state),
  records: selectItemToDeleteRecordList(state),
})

const mapDispatchToProps = {
  cancelDeleteItem: itemActions.cancelDeleteItem,
  deleteItem: itemActions.deleteItem,
}

type Props = {
  itemName: string,
  records?: Array<RecordListItemRecord>,
  isItemImported: boolean,
  open: boolean,
  progress: true | 'blurring' | 'inverted',
  cancelDeleteItem: (e: React.SyntheticEvent) => void,
  deleteItem: Function,
}

DeleteItemModal.defaultProps = {
  records: [],
}

function DeleteItemModal({ itemName, isItemImported, records, open, progress, cancelDeleteItem, deleteItem }: Props) {
  return (
    <Modal open={open} size="small" className="delete-item-modal" dimmer={progress}>
      <Modal.Header>
        <FormattedMessage id="imports.items.remove_items.header" values={{ isItemImported }} />
        {progress && <Loader active inline="centered" />}
      </Modal.Header>
      <Modal.Content>
        <FormattedMessage id="imports.items.remove_items.info" values={{ itemName, isItemImported }} />
        {records && records.length > 0 && (
          <BasicRecordList scrollable records={records} />
        )}
      </Modal.Content>
      {!progress && (
        <Modal.Actions>
          <SecondaryButton fluid onClick={cancelDeleteItem}><FormattedMessage id="form.no" /></SecondaryButton>
          <PrimaryButton
            fluid
            color="red"
            onClick={() => deleteItem()}
          >
            <FormattedMessage id="form.yes" />
          </PrimaryButton>
        </Modal.Actions>
      )
      }
    </Modal>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(DeleteItemModal)
