import { Form, Grid, Message } from 'semantic-ui-react'
import { FormattedMessage } from 'src/frontend/modules/intl'
import React, { SyntheticEvent } from 'react'
import { PrimaryButton } from 'src/frontend/components/Buttons/Buttons'

interface Props {
  onClose: (e: SyntheticEvent) => void,
}

export default function ChangePasswordInfoMessage({ onClose }: Props) {
  return (
    <Form error>
      <Message
        className="info-message"
        info
        content={<FormattedMessage id="auth.changePassword.success_info" />}
      />
      <Grid>
        <Grid.Column>
          <PrimaryButton
            type="submit"
            fluid
            onClick={onClose}
          >
            <FormattedMessage id="auth.close_button" />
          </PrimaryButton>
        </Grid.Column>
      </Grid>
    </Form>
  )
}
