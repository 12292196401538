import { Action } from 'src/types/common'
import {
  DISCONNECT_ACCOUNT_START,
  DISCONNECT_ACCOUNT_SUCCESS,
  REFRESH_HIDE_MFA,
  EXISTING_INTEGRATIONS_FORM_CLOSE,
  EXISTING_INTEGRATIONS_FORM_OPEN,
  REFRESH_SHOW_MFA,
  REFRESH_START,
  REFRESH_END,
  SET_ACTIVE_MFA,
  REFRESH_ERROR,
  RECONNECT_START,
  RECONNECT_END,
} from "src/frontend/scenes/integrations/connections/actions"
import { IntegrationSource } from "src/backend/integrations/enums"
import { ExistingIntegrationStatus } from "src/frontend/scenes/integrations/enums"
import { IntegrationProviders } from "src/frontend/scenes/integrations/providersList/types"
import IntegrationRecipeGroup = IntegrationProviders.IntegrationRecipe.IntegrationRecipeGroup
import _omitBy from "lodash/omitBy"
import Timer = NodeJS.Timer

export interface ActiveRefresh {
  loginId: string
  integrationStatus: ExistingIntegrationStatus
  refreshMFA: IntegrationRecipeGroup[]
  providerName?: string
  MFATimeoutId: Timer
}

export interface ExistingConnectionsState {
  disconnectAccountLoading: boolean
  disconnectLoginId: string

  activeRefreshes: {
    [key: string]: ActiveRefresh
  }

  activeMFALoginId: string

  pendingRefresh: boolean
  pendingReconnect: string
  modalOpen: boolean
}

const initExistingConnectionsState = {
  disconnectAccountLoading: false,
  disconnectLoginId: null,

  activeRefreshes: {},

  activeMFALoginId: null,

  pendingRefresh: false,
  pendingReconnect: null,
  modalOpen: false,
}

export default function connections(
  state: ExistingConnectionsState = initExistingConnectionsState,
  action: Action,
): ExistingConnectionsState {
  switch (action.type) {
    case DISCONNECT_ACCOUNT_START: {
      return { ...state, disconnectAccountLoading: true, disconnectLoginId: action.payload }
    }

    case DISCONNECT_ACCOUNT_SUCCESS: {
      return { ...state, disconnectAccountLoading: false, disconnectLoginId: null }
    }

    case REFRESH_START: {
      return {
        ...state,
        activeRefreshes: {
          ...state.activeRefreshes,
          [action.payload.loginId]: {
            loginId: action.payload.loginId,
            providerName: action.payload.providerName,
            integrationStatus: ExistingIntegrationStatus.LOADING,
            refreshMFA: null,
            MFATimeoutId: null,
          },
        },
        pendingRefresh: true,
      }
    }

    case REFRESH_END: {
      const activeRefreshes = _omitBy(
        state.activeRefreshes,
        (_value, key) => key === action.payload,
      )
      return {
        ...state,
        activeRefreshes,
      }
    }

    case REFRESH_ERROR: {
      return {
        ...state,
        activeRefreshes: {
          ...state.activeRefreshes,
          [action.payload]: {
            ...state.activeRefreshes[action.payload],
            integrationStatus: ExistingIntegrationStatus.ERROR,
          },
        },
        activeMFALoginId: state.activeMFALoginId === action.payload ? null : state.activeMFALoginId,
      }
    }

    case REFRESH_SHOW_MFA: {
      return {
        ...state,
        activeRefreshes: {
          ...state.activeRefreshes,
          [action.payload.loginId]: {
            ...state.activeRefreshes[action.payload.loginId],
            loginId: action.payload.loginId,
            refreshMFA: action.payload.formAttributeGroups,
            MFATimeoutId: action.payload.MFATimeoutId,
            integrationStatus: ExistingIntegrationStatus.MFA_FORM,
          },
        },
        // if activeMFAloginId is null, add loginId
        activeMFALoginId: state.activeMFALoginId || action.payload.loginId,
      }
    }

    case SET_ACTIVE_MFA: {
      return { ...state, activeMFALoginId: action.payload }
    }

    case REFRESH_HIDE_MFA: {
      return {
        ...state,
        activeRefreshes: {
          ...state.activeRefreshes,
          [action.payload]: {
            ...state.activeRefreshes[action.payload],
            loginId: action.payload,
            refreshMFA: null,
            MFATimeoutId: null,
            integrationStatus: ExistingIntegrationStatus.LOADING,
          },
        },
        // if mfa to hide is set as active, null it
        activeMFALoginId: state.activeMFALoginId === action.payload ? null : state.activeMFALoginId,
      }
    }

    case EXISTING_INTEGRATIONS_FORM_OPEN: {
      return {
        ...state,
        modalOpen: true,
      }
    }

    case EXISTING_INTEGRATIONS_FORM_CLOSE: {
      return { ...state, modalOpen: false }
    }

    case RECONNECT_START: {
      return { ...state, pendingReconnect: action.payload }
    }

    case RECONNECT_END: {
      return { ...state, pendingReconnect: null }
    }

    default: {
      return state
    }
  }
}
