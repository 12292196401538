import React from 'react'
import { IntlConsumer } from 'src/frontend/modules/intl/components/IntlContext'
import { IntlConsumerType } from 'src/frontend/modules/intl/components/types'

export function withIntlConsumer<P extends object>(
  WrappedComponent: React.ComponentType<P>,
): React.FunctionComponent<Pick<P, Exclude<keyof P, keyof IntlConsumerType>>> {
  return (props: P): React.ReactElement<IntlConsumerType & P> => {
    return (
      <IntlConsumer>
        {(consumerProps): React.ReactElement<Pick<P, Exclude<keyof P, keyof IntlConsumerType>>> => {
          return <WrappedComponent {...consumerProps as IntlConsumerType} {...props as P} />
        }}
      </IntlConsumer>
    )
  }
}
