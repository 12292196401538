import { GetState } from "src/types/common"
import * as selectors from "src/frontend/modules/moduleStatus/selectors"
import { fetchDashboardWidgetsWithData } from "src/frontend/scenes/dashboard/actions"
import { fetchRecords } from "src/frontend/scenes/records/recordList/actions"
import { fetchStandingOrders } from "src/frontend/modules/standingOrders/actions"
import { fetchAccountsData } from "src/frontend/modules/accounts/actions"
import { fetchAnalyticsData } from "src/frontend/scenes/analytics/actions"
import { updateImportedRecords } from "src/frontend/scenes/imports/records/actions"
import { FilterType } from "src/types/Filter"
import { fetchDetailRecords } from "src/frontend/scenes/records/recordsDetail/actions"
import { updateShouldDisplayOnBoarding } from "src/frontend/scenes/onBoarding/inApp/actions"

export const DASHBOARD_MODULE_STATUS_EXPIRED = "DASHBOARD_MODULE_STATUS_EXPIRED"
export const DASHBOARD_MODULE_STATUS_UP_TO_DATE = "DASHBOARD_MODULE_STATUS_UP_TO_DATE"
export const DASHBOARD_MODULE_STATUS_LOADING = "DASHBOARD_MODULE_STATUS_LOADING"

export const RECORDS_MODULE_STATUS_EXPIRED = "RECORDS_MODULE_STATUS_EXPIRED"
export const RECORDS_MODULE_STATUS_UP_TO_DATE = "RECORDS_MODULE_STATUS_UP_TO_DATE"
export const RECORDS_MODULE_STATUS_LOADING = "RECORDS_MODULE_STATUS_LOADING"

export const DETAIL_RECORDS_MODULE_STATUS_EXPIRED = "DETAIL_RECORDS_MODULE_STATUS_EXPIRED"
export const DETAIL_RECORDS_MODULE_STATUS_UP_TO_DATE = "DETAIL_RECORDS_MODULE_STATUS_UP_TO_DATE"
export const DETAIL_RECORDS_MODULE_STATUS_LOADING = "DETAIL_RECORDS_MODULE_STATUS_LOADING"

export const ACCOUNTS_MODULE_STATUS_EXPIRED = "ACCOUNTS_MODULE_STATUS_EXPIRED"
export const ACCOUNTS_MODULE_STATUS_UP_TO_DATE = "ACCOUNTS_MODULE_STATUS_UP_TO_DATE"
export const ACCOUNTS_MODULE_STATUS_LOADING = "ACCOUNTS_MODULE_STATUS_LOADING"

export const ANALYTICS_MODULE_STATUS_EXPIRED = "ANALYTICS_MODULE_STATUS_EXPIRED"
export const ANALYTICS_MODULE_STATUS_UP_TO_DATE = "ANALYTICS_MODULE_STATUS_UP_TO_DATE"
export const ANALYTICS_MODULE_STATUS_LOADING = "ANALYTICS_MODULE_STATUS_LOADING"

export const IMPORTS_RECORD_LIST_EXPIRED = "IMPORTS_RECORD_LIST_EXPIRED"
export const IMPORTS_RECORD_LIST_UP_TO_DATE = "IMPORTS_RECORD_LIST_UP_TO_DATE"
export const IMPORTS_RECORD_LIST_LOADING = "IMPORTS_RECORD_LIST_LOADING"

/// --- Dashboard ---
export function expireDashboardStatus() {
  return (dispatch: Function, getState: GetState) => {
    if (selectors.selectIsDashboardUpToDate(getState())) {
      dispatch({
        type: DASHBOARD_MODULE_STATUS_EXPIRED,
      })
    }
  }
}

export function refreshDashboardStatus() {
  return async (dispatch: Function, getState: GetState) => {
    if (selectors.selectShouldRefreshDashboard(getState())) {
      dispatch(refreshDashboardStatusStart())

      setTimeout(async () => {
        await Promise.all([
          dispatch(fetchDashboardWidgetsWithData()),
          dispatch(updateShouldDisplayOnBoarding()),
        ])
        dispatch(refreshDashboardStatusFinish())
      }, 100)
    }
  }
}

function refreshDashboardStatusStart() {
  return {
    type: DASHBOARD_MODULE_STATUS_LOADING,
  }
}

function refreshDashboardStatusFinish() {
  return {
    type: DASHBOARD_MODULE_STATUS_UP_TO_DATE,
  }
}

/// --- Records ---

export function expireRecordsStatus() {
  return (dispatch: Function) => {
    dispatch({
      type: RECORDS_MODULE_STATUS_EXPIRED,
    })
  }
}

export function refreshRecordsStatus() {
  return (dispatch: Function, getState: GetState) => {
    if (selectors.selectShouldRefreshRecords(getState())) {
      dispatch(refreshRecordsStatusStart())
      setTimeout(async () => {
        await Promise.all([
          dispatch(fetchRecords()),
          dispatch(fetchStandingOrders()),
          dispatch(updateShouldDisplayOnBoarding()),
        ])
        dispatch(refreshRecordsStatusFinish())
      }, 0)
    }
  }
}

function refreshRecordsStatusStart() {
  return {
    type: RECORDS_MODULE_STATUS_LOADING,
  }
}

function refreshRecordsStatusFinish() {
  return {
    type: RECORDS_MODULE_STATUS_UP_TO_DATE,
  }
}

/// --- Account/Contact Detail Records ---

export function expireDetailRecordsStatus() {
  return (dispatch: Function, getState: GetState) => {
    if (selectors.selectIsDetailRecordsUpToDate(getState())) {
      dispatch({
        type: DETAIL_RECORDS_MODULE_STATUS_EXPIRED,
      })
    }
  }
}

export function refreshDetailRecordsStatus(filter: Partial<FilterType>) {
  return (dispatch: Function) => {
    dispatch(refreshDetailRecordsStatusStart())
    setTimeout(async () => {
      await dispatch(fetchDetailRecords(filter))
      dispatch(refreshDetailRecordsStatusFinish())
    }, 0)
  }
}

function refreshDetailRecordsStatusStart() {
  return {
    type: DETAIL_RECORDS_MODULE_STATUS_LOADING,
  }
}

function refreshDetailRecordsStatusFinish() {
  return {
    type: DETAIL_RECORDS_MODULE_STATUS_UP_TO_DATE,
  }
}

/// --- Accounts Totals ---

export function expireAccountsStatus() {
  return (dispatch: Function, getState: GetState) => {
    if (selectors.selectIsAccountsUpToDate(getState())) {
      dispatch({
        type: ACCOUNTS_MODULE_STATUS_EXPIRED,
      })
    }
  }
}

export function refreshAccountsStatus() {
  return (dispatch: Function, getState: GetState) => {
    if (selectors.selectShouldRefreshAccounts(getState())) {
      dispatch(refreshAccountsStatusStart())
      setTimeout(async () => {
        await dispatch(fetchAccountsData())
        dispatch(refreshAccountsStatusFinish())
      }, 0)
    }
  }
}

function refreshAccountsStatusStart() {
  return {
    type: ACCOUNTS_MODULE_STATUS_LOADING,
  }
}

function refreshAccountsStatusFinish() {
  return {
    type: ACCOUNTS_MODULE_STATUS_UP_TO_DATE,
  }
}

/// --- Analytics ---

export function expireAnalyticsStatus() {
  return (dispatch: Function, getState: GetState) => {
    if (selectors.selectIsAnalyticsUpToDate(getState())) {
      dispatch({
        type: ANALYTICS_MODULE_STATUS_EXPIRED,
      })
    }
  }
}

export function refreshAnalyticsStatus(filterOverride?: Partial<FilterType>) {
  return (dispatch: Function, getState: GetState) => {
    if (selectors.selectShouldRefreshAnalytics(getState())) {
      dispatch(refreshAnalyticsStatusStart())
      setTimeout(async () => {
        await dispatch(fetchAnalyticsData(filterOverride))
        dispatch(refreshAnalyticsStatusFinish())
      }, 0)
    }
  }
}

function refreshAnalyticsStatusStart() {
  return {
    type: ANALYTICS_MODULE_STATUS_LOADING,
  }
}

function refreshAnalyticsStatusFinish() {
  return {
    type: ANALYTICS_MODULE_STATUS_UP_TO_DATE,
  }
}

/// --- Imports RecordList ---

export function expireImportsRecordListStatus() {
  return (dispatch: Function, getState: GetState) => {
    dispatch({
      type: IMPORTS_RECORD_LIST_EXPIRED,
    })
  }
}

export function refreshImportsRecordListStatus() {
  return (dispatch: Function, getState: GetState) => {
    if (selectors.selectShouldRefreshImportsRecordList(getState())) {
      dispatch(refreshImportsRecordListStatusStart())
      setTimeout(async () => {
        await Promise.all([
          dispatch(updateImportedRecords()),
          dispatch(updateShouldDisplayOnBoarding()),
        ])
        dispatch(refreshImportsRecordListStatusFinish())
      }, 0)
    }
  }
}

function refreshImportsRecordListStatusStart() {
  return {
    type: IMPORTS_RECORD_LIST_LOADING,
  }
}

function refreshImportsRecordListStatusFinish() {
  return {
    type: IMPORTS_RECORD_LIST_UP_TO_DATE,
  }
}
