import React from 'react'
import { Dropdown } from 'semantic-ui-react'
import { FormattedMessage, getSupportedLanguages } from 'src/frontend/modules/intl'
import { IntlConsumerType } from 'src/frontend/modules/intl/components/types'
import { withIntlConsumer } from 'src/frontend/modules/intl/components/withIntlConsumer'

const options = getSupportedLanguages().map(language => {
  return { key: language.code, text: language.name, value: language.code }
})

function ChangeLanguage({ code, changeLanguage }: IntlConsumerType) {
  return (
    <div className="item">
      <div className="label">
        <FormattedMessage id="settings.general.webapp_language" />
      </div>
      <div>
        <Dropdown selection value={code} options={options} onChange={changeLanguage} />
      </div>
    </div>
  )
}

export default withIntlConsumer(ChangeLanguage)
