import React from 'react'
import { withGoogleMap, GoogleMap, Marker } from 'react-google-maps'
import withScriptjs from 'react-google-maps/lib/withScriptjs'
import { Segment, Dimmer, Loader } from 'semantic-ui-react'
import _isEmpty from 'lodash/isEmpty'
import { FormattedMessageLabel, formatMessage } from 'src/frontend/modules/intl'
import AutoCompleteInput from 'src/frontend/scenes/records/components/AutoCompleteInput'
import { recordHasImplicitGPS, recordHasGPS } from 'src/backend/records/helpers'
import * as placesApi from 'src/backend/rest/places'
import './Place.less'
import { getGoogleMapUrl } from 'src/common/environment'
import LatLngLiteral = google.maps.LatLngLiteral

import { RecordFormValues } from 'src/frontend/scenes/records/recordForm/types'

interface Props {
  formValues: RecordFormValues,
  disabled?: boolean,
  onChange: Function,
  className?: string
}

interface State {
  browserGeoLocation: {
    latitude: number,
    longitude: number,
  }
}

class Place extends React.Component<Props, State> {
  static defaultProps = {
    disabled: false,
    className: '',
  }


  state = {
    browserGeoLocation: null,
  }

  geoLocation: number

  componentDidMount() {
    if (navigator.geolocation) {
      this.geoLocation = navigator.geolocation.watchPosition(position => {
        this.setState({
          browserGeoLocation: position.coords,
        })
      })
    }
  }

  componentWillUnmount() {
    if (navigator.geolocation) {
      navigator.geolocation.clearWatch(this.geoLocation)
    }
  }

  render() {
    const { formValues, disabled, onChange, className } = this.props
    const { browserGeoLocation } = this.state
    const recordLocation = recordHasImplicitGPS(formValues)
      ? { latitude: formValues.latitude, longitude: formValues.longitude }
      : null

    const disabledClass = disabled ? 'disabled' : ''

    return (
      <div className={`field ${disabledClass}`}>
        <label><FormattedMessageLabel id="record.form.place" /></label>
        <PlaceAutoComplete
          className={className}
          userLocation={recordLocation || browserGeoLocation}
          formValues={formValues}
          onChange={onChange}
        />
        <PlaceMap formValues={formValues} />
      </div>
    )
  }
}

interface PlaceAutoCompleteProps {
  className: string,
  userLocation: {
    latitude: number,
    longitude: number,
  },
  formValues: RecordFormValues,
  onChange: Function
}

PlaceAutoComplete.defaultProps = {
  className: '',
}

function PlaceAutoComplete({ className, userLocation, formValues, onChange }: PlaceAutoCompleteProps) {

  const address = getAddress(formValues)
  return (
    <AutoCompleteInput
      fetchOnMount
      fetch={placesApi.fetchPlaces(userLocation)}
      className={`${address && 'input-with-map '}${className}`}
      value={address}
      input={{ placeholder: formatMessage('record.form.place.placeholder') }}
      onChange={(event, result) => {
        const place = convertAutoCompleteResultToPlace(result)
        if (place) {
          onChange(event, { name: 'place', value: place })
        }
      }}
    />
  )
}

const getAddress = (formValues: RecordFormValues) => {
  if (recordHasGPS(formValues)) {
    const { latitude, longitude } = formValues.place ? formValues.place : formValues
    return formValues.place
      ? `${formValues.place.name || ''}${formValues.place.name && formValues.place.address ? ', ' : ''}${formValues.place.address || ''}`
      : `${latitude}, ${longitude}`
  }
  return ''
}

const convertAutoCompleteResultToPlace = (result) => {
  return _isEmpty(result) ? undefined : {
    id: result.value.id,
    address: result.value.address,
    latitude: result.value.lat,
    longitude: result.value.lng,
    name: result.value.name,
  }
}


interface PlaceMapProps {
  formValues: RecordFormValues,
}

function PlaceMap({ formValues }: PlaceMapProps) {
  const DEFAULT_ZOOM = 15

  if (recordHasGPS(formValues)) {
    const { latitude, longitude } = formValues.place ? formValues.place : formValues
    const center: LatLngLiteral = { lat: latitude, lng: longitude }

    const containerComponent = <div className="map-container" />
    const mapComponent = <div className="map-component" />
    const loadingComponent = <MapLoader />

    return (
      <AsyncGoogleMapContainer
        googleMapURL={getGoogleMapUrl()}
        loadingElement={loadingComponent}
        containerElement={containerComponent}
        mapElement={mapComponent}
        center={center}
        zoom={DEFAULT_ZOOM}
      />
    )
  }

  return null
}

const MapLoader = () => {
  return (
    <Segment className="map-loader">
      <Dimmer active inverted>
        <Loader inverted />
      </Dimmer>
    </Segment>
  )
}

interface AsyncGoogleMapContainerProps {
  center: LatLngLiteral
  zoom: number
}

const AsyncGoogleMapContainer = withScriptjs(withGoogleMap((props: AsyncGoogleMapContainerProps) => {
  const mapOptions = {
    scrollwheel: false,
    navigationControl: false,
    mapTypeControl: false,
    streetViewControl: false,
  }
  return (
    <GoogleMap center={props.center} zoom={props.zoom} defaultOptions={mapOptions}>
      <Marker position={props.center} />
    </GoogleMap>
  )
}))


export default Place
