import {
  AndroidFilterRecordType,
  AndroidFilterTransfers,
  FilterRecordType,
} from "src/backend/enums"
import _isEqual from "lodash/isEqual"
import {
  WebFilter,
  AndroidFilter,
  FilterEnvelopes,
  AndroidFilterEnvelopes,
  AmountFilter,
} from "src/types/Filter"
import { isEnvelopeId, isSuperEnvelopeId } from "src/backend/categories/envelopes"

export function convertFromAndroidFilter(androidFilter: AndroidFilter): WebFilter {
  return {
    superEnvelopeIds: mapSuperEnvelopes(androidFilter),
    envelopeIds: mapEnvelopes(androidFilter),
    categoryId: androidFilter.categories,
    currencyId: androidFilter.currencies,
    paymentType: androidFilter.payment_types,
    recordTypes: convertRecordTypesFromAndroid(androidFilter),
    recordState: androidFilter.record_states,
    fulltext: androidFilter.description_tokens,
    labelIds: androidFilter.labels,
    accountIds: androidFilter.accounts,
    name: androidFilter.name,
    position: androidFilter.position || 0,
    amount: convertAmountFromAndroid(androidFilter),
    contactIds: androidFilter.contacts,
    // currently unsupported filters, only passing with changed attribute name
    debts: androidFilter.debts,
    standingOrders: androidFilter.standing_orders,
  }
}

export function convertToAndroidFilter(filter: WebFilter): AndroidFilter {
  return {
    envelopes: mergeEnvelopes(filter),
    categories: filter.categoryId,
    currencies: filter.currencyId,
    payment_types: filter.paymentType,
    record_type: convertRecordTypesToAndroid(filter),
    transfers: convertTransfersToAndroid(filter),
    record_states: filter.recordState,
    description_tokens: filter.fulltext,
    labels: filter.labelIds,
    accounts: filter.accountIds,
    name: filter.name,
    position: filter.position,
    contacts: filter.contactIds,
    minAmount: filter.amount && filter.amount.min,
    maxAmount: filter.amount && filter.amount.max,
    // currently unsupported filters, only passing with changed attribute name
    debts: filter.debts,
    standing_orders: filter.standingOrders,
  }
}

export function convertRecordTypesFromAndroid(androidFilter: AndroidFilter): FilterRecordType[] {
  if (androidFilter === undefined) {
    return undefined
  }

  /* eslint-disable camelcase */
  const { record_type } = androidFilter

  const filterByRecordType = {
    [AndroidFilterRecordType.BOTH]: [FilterRecordType.INCOME, FilterRecordType.EXPENSE],
    [AndroidFilterRecordType.INCOME]: [FilterRecordType.INCOME],
    [AndroidFilterRecordType.EXPENSE]: [FilterRecordType.EXPENSE],
  }

  return mapTransferToRecordType(
    androidFilter,
    record_type !== null && record_type !== undefined && record_type in filterByRecordType
      ? filterByRecordType[record_type]
      : [],
  )
  /* eslint-enable */
}

export function mapTransferToRecordType(
  androidFilter: AndroidFilter,
  recordType: FilterRecordType[],
): FilterRecordType[] {
  /* eslint-disable camelcase */
  const { transfers, record_type } = androidFilter

  if (
    (transfers === undefined || transfers === null) &&
    (record_type === null || record_type === undefined)
  ) {
    return undefined
  }

  const filterByTransfer = {
    [AndroidFilterTransfers.INCLUDE]: [...recordType, FilterRecordType.TRANSFER],
    [AndroidFilterTransfers.EXCLUDE]: [...recordType],
    [AndroidFilterTransfers.ONLY]: [FilterRecordType.TRANSFER],
  }

  return transfers !== null && transfers !== undefined && transfers in filterByTransfer
    ? filterByTransfer[transfers]
    : recordType
  /* eslint-enable */
}

export function mapSuperEnvelopes(androidFilter: AndroidFilter): FilterEnvelopes {
  return androidFilter && androidFilter.envelopes
    ? androidFilter.envelopes.filter(isSuperEnvelopeId)
    : undefined
}

export function mapEnvelopes(androidFilter: AndroidFilter): FilterEnvelopes {
  return androidFilter && androidFilter.envelopes
    ? androidFilter.envelopes.filter(isEnvelopeId)
    : undefined
}

export function mergeEnvelopes(filter: WebFilter): AndroidFilterEnvelopes {
  if (!filter || (!filter.superEnvelopeIds && !filter.envelopeIds)) {
    return undefined
  }

  return filter && [...(filter.superEnvelopeIds || []), ...(filter.envelopeIds || [])]
}

export function convertTransfersToAndroid(filter: WebFilter): number {
  if (!filter) {
    return undefined
  } else if (_isEqual(filter.recordTypes, [FilterRecordType.TRANSFER])) {
    return AndroidFilterTransfers.ONLY
  } else if (!filter.recordTypes || filter.recordTypes.includes(FilterRecordType.TRANSFER)) {
    return AndroidFilterTransfers.INCLUDE
  } else {
    return AndroidFilterTransfers.EXCLUDE
  }
}

export function convertRecordTypesToAndroid(filter: WebFilter): number {
  const recordTypes = filter.recordTypes

  const onlyTransferRecordType =
    recordTypes?.length === 1 && recordTypes?.includes(FilterRecordType.TRANSFER)

  const bothRecordsTypes =
    recordTypes?.includes(FilterRecordType.INCOME) &&
    recordTypes?.includes(FilterRecordType.EXPENSE)

  if (!recordTypes || bothRecordsTypes || onlyTransferRecordType) {
    return AndroidFilterRecordType.BOTH
  } else if (recordTypes?.includes(FilterRecordType.EXPENSE)) {
    return AndroidFilterRecordType.EXPENSE
  } else if (recordTypes?.includes(FilterRecordType.INCOME)) {
    return AndroidFilterRecordType.INCOME
  } else {
    throw new Error(
      `convertRecordTypesToAndroid: Unsupported record type: [${recordTypes?.join(", ")}]`,
    )
  }
}

export function convertAmountFromAndroid(androidFilter: AndroidFilter): AmountFilter {
  return androidFilter.minAmount || androidFilter.maxAmount
    ? { min: androidFilter.minAmount || undefined, max: androidFilter.maxAmount || undefined }
    : undefined
}
