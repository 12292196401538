import { RootState } from 'src/types/State'
import { selectBilling } from 'src/frontend/scenes/billing/selectors'

export const selectPaymentMethod = (state: RootState) => selectBilling(state).paymentMethod

export const selectClientToken = (state: RootState) => selectPaymentMethod(state).clientToken
export const selectLoading = (state: RootState) => selectPaymentMethod(state).loading
export const selectTokenLoading = (state: RootState) => selectPaymentMethod(state).tokenLoading
export const selectPaymentMethodSuccess = (state: RootState) => selectPaymentMethod(state).success
export const selectPaymentMethodError = (state: RootState) => selectPaymentMethod(state).error
