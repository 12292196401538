import React from 'react'
import PropTypes from 'prop-types'
import { FormattedDate } from 'react-intl/dist'
import { connect } from 'react-redux'
import { Accordion, Icon } from 'semantic-ui-react'
import { FormattedMessage } from 'src/frontend/modules/intl'
import * as itemActions from 'src/frontend/scenes/imports/items/actions'
import './ImportedItem.less'
import { PrimaryButton } from 'src/frontend/components/Buttons/Buttons'

const mapDispatchToProps = {
  confirmDeleteItem: itemActions.confirmDeleteItem,
  itemDetailToggle: itemActions.itemDetailToggle,
  itemVisibilityToggle: itemActions.itemVisibilityToggle,
}

const ImportedItem = ({ item, open, visible, confirmDeleteItem, itemDetailToggle, itemVisibilityToggle }) => (
  <li>
    <div className={visible ? 'imported-item' : 'imported-item invisible'}>
      <div>
        <span className="icon icon-visibility ic_visibility" onClick={itemVisibilityToggle.bind(null, item.itemId)} />
        <Accordion fluid>
          <Accordion.Title
            index={0}
            active={open}
            onClick={itemDetailToggle.bind(null, item.itemId, open)}
            className="imported-item-accordion-title"
          >
            <div className="header-text">
              <p className="name">{item.fileName}</p>
              <p className="date"><FormattedDate value={item.itemCreatedAt} /></p>
            </div>
            <div className="arrow">
              <Icon name="dropdown" />
            </div>
          </Accordion.Title>
          <Accordion.Content active={open} index={0} onClick={itemDetailToggle.bind(null, item.itemId, open)}>
            <div className="imported-item-content">
              <p className="label">
                <FormattedMessage
                  id="imports.items.item_list.records"
                  values={{ recordsCount: item.recordsCount }}
                />
                :
              </p>
              <p className="value">{item.recordsCount}</p>
              <p className="label">
                <FormattedMessage id="imports.items.item_list.period" />
                :
              </p>
              <p className="value">
                {item.recordsFromDate && <FormattedDate value={item.recordsFromDate} />}
                {' - '}
                {item.recordsToDate && <FormattedDate value={item.recordsToDate} />}
              </p>
            </div>
          </Accordion.Content>
        </Accordion>
      </div>
      {/* Hack to stretch button outside the accordion */}
      {open && (
        <PrimaryButton
          color="red"
          fluid
          onClick={event => {
            event.stopPropagation()
            confirmDeleteItem(item.itemId)
          }}
        >
          <FormattedMessage id="delete" />
        </PrimaryButton>
      )}
    </div>
  </li>
)

ImportedItem.propTypes = {
  item: PropTypes.shape({
    itemId: PropTypes.string.isRequired,
    fileName: PropTypes.string.isRequired,
    recordsCount: PropTypes.number.isRequired,
    recordsFromDate: PropTypes.instanceOf(Date),
    recordsToDate: PropTypes.instanceOf(Date),
    itemCreatedAt: PropTypes.number.isRequired,
  }).isRequired,
  open: PropTypes.bool.isRequired,
  visible: PropTypes.bool.isRequired,
  confirmDeleteItem: PropTypes.func.isRequired,
  itemDetailToggle: PropTypes.func.isRequired,
  itemVisibilityToggle: PropTypes.func.isRequired,
}

export default connect(null, mapDispatchToProps)(ImportedItem)
