import React from 'react'
import { Segment, Dimmer, Loader, Icon } from 'semantic-ui-react'
import './Gallery.less'
import { Photo } from 'src/types/Record'

interface PhotoView extends Photo {
  src: string
}

interface Props {
  photos: PhotoView[]
  onPhotoClick: Function
}

Gallery.defaultProps = {
  photos: [],
}

function Gallery({ photos, onPhotoClick }: Props) {
  return (
    <div className="gallery">
      {photos.map((photo, index) => {
        return (
          <div className="image-container" key={photo.src} onClick={(event) => onPhotoClick(event, index)}>
            <ImageWithLoader src={photo.src} />
          </div>
        )
      })}
    </div>
  )
}

interface ImageWithLoaderProps {
  src: string
}

class ImageWithLoader extends React.Component<ImageWithLoaderProps> {
  state = {
    loading: true,
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.src !== this.props.src) {
      this.setState({
        loading: true,
      })
    }
  }

  onImageLoad = () => this.setState({ loading: false })

  onImageError = () => this.setState({ loading: false })

  render() {
    const { src } = this.props
    const { loading } = this.state
    const backgroundImage = !loading && `url(${src})` || 'none'

    return (
      <Segment className="image-component" style={{ backgroundImage }}>
        <Dimmer active={loading}>
          <Loader />
        </Dimmer>
        {!loading && <div className="browse-icon"><Icon size="large" name="search" /></div>}
        <img className="hide" src={src} onLoad={this.onImageLoad} onError={this.onImageError} />
      </Segment>
    )
  }
}

export default Gallery
