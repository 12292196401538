import * as recordsService from 'src/backend/records/service'
import { Id } from 'src/types/CouchDb'
import { Action, GetState } from 'src/types/common'
import { selectUser } from 'src/frontend/modules/user/selectors'
import { captureException } from 'src/common/logger'
import { showError } from 'src/frontend/scenes/app/actions'
import { DEFAULT_ERROR_MESSAGE_ID } from 'src/frontend/Error/actions'
import { trackSolveDuplicities } from 'src/common/mixpanel'
import {
  expireAccountsStatus, expireAnalyticsStatus, expireDetailRecordsStatus,
  expireDashboardStatus,
  expireRecordsStatus,
} from 'src/frontend/modules/moduleStatus/actions'
import { findByIds } from 'src/backend/records/repository'

export const SOLVE_DUPLICITIES_FORM_OPEN = 'SOLVE_DUPLICITIES_FORM_OPEN'
export const SOLVE_DUPLICITIES_FORM_CLOSE = 'SOLVE_DUPLICITIES_FORM_CLOSE'

export const SOLVE_DUPLICITIES_START = 'SOLVE_DUPLICITIES_START'
export const SOLVE_DUPLICITIES_SUCCESS = 'SOLVE_DUPLICITIES_SUCCESS'

export const SOLVE_DUPLICITIES_SELECT_ITEM = 'SOLVE_DUPLICITIES_SELECT_ITEM'

export function solveDuplicities(selectedDuplicities: Id[], recordIdToKeep: Id) {
  return async (dispatch: Function, getState: GetState) => {
    const user = selectUser(getState())

    dispatch(solveDuplicitiesStart())
    const selectedRecords = await findByIds(selectedDuplicities)
    try {
      await recordsService.solveDuplicities(selectedRecords, recordIdToKeep, user)
      const provider: string = selectedRecords && selectedRecords[0].reservedIntegrationSource
      trackSolveDuplicities({ provider })
      dispatch(solveDuplicitiesSuccess())
    } catch (error) {
      dispatch(showError([DEFAULT_ERROR_MESSAGE_ID, 'app.error.we_are_fixing_suffix']))
      captureException(error, 'src.frontend.scenes.records.recordList.solveDuplicitiesForm.actions', {
        selectedRecords,
        recordIdToKeep,
      })
    }
    dispatch(expireAccountsStatus())
    dispatch(expireDashboardStatus())
    dispatch(expireRecordsStatus())
    dispatch(expireAnalyticsStatus())
    dispatch(expireDetailRecordsStatus())
  }
}

export function solveDuplicitiesSelectItem(recordId: Id): Action {
  return { type: SOLVE_DUPLICITIES_SELECT_ITEM, payload: recordId }
}

function solveDuplicitiesStart(): Action {
  return { type: SOLVE_DUPLICITIES_START }
}

function solveDuplicitiesSuccess(): Action {
  return { type: SOLVE_DUPLICITIES_SUCCESS }
}

export function openSolveDuplicitiesForm(): Action {
  return { type: SOLVE_DUPLICITIES_FORM_OPEN }
}

export function closeSolveDuplicitiesForm(): Action {
  return { type: SOLVE_DUPLICITIES_FORM_CLOSE }
}
