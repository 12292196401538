import { getColors } from "src/backend/colors/colors"
import { getTypesOfEntities, isNotModelType } from "src/backend/common/helpers"
import { getEntityReferences, updateAuditData } from "src/backend/common/service"
import { accountConverter } from "src/backend/converters/accountConverter"
import * as currencyRepository from "src/backend/currencies/repository"
import { inMemoryTableNames } from "src/backend/db/inMemorySqlDbSchemaBuilder"
import { AccountType, Platform } from "src/backend/enums"
import * as timeUtils from "src/backend/time/time"
import { Account } from "src/types/Account"
import { Budget } from "src/types/Budget"
import { AnyDocument, Id } from "src/types/CouchDb"
import { User } from "src/types/User"
import uuid from "uuid"
import * as commonRepository from "../common/repository"
import { isConnected } from "./helpers"
import * as repository from "./repository"
import { AccountFormValues } from "src/frontend/scenes/settings/accounts/types"
import { FilterDocument } from "src/types/Filter"
import _isEmpty from "lodash/isEmpty"
import _groupBy from "lodash/groupBy"
import { filterConverter } from "src/backend/converters/filterConverter"

async function createAccount(user: User) {
  const position =
    ((await commonRepository.findHighestPosition(inMemoryTableNames.ACCOUNT)) || 0) + 1000

  const account = await updateAuditData(
    user,
    timeUtils.utcDateAsISOString,
  )(getDefaultAccount(position))

  console.log("createAccount", account, user)

  return account
}

export async function saveAccount(accountFormData: AccountFormValues, user: User, accountId?: Id) {
  const account = accountId ? await repository.findById(accountId) : await createAccount(user)

  console.log("saveAccount", accountFormData, account)

  const currency =
    accountFormData.currencyId || (account && account.currencyId)
      ? await currencyRepository.findById(accountFormData.currencyId || account.currencyId)
      : await currencyRepository.getReferentialCurrency()

  console.log(account, currency)
  // this should never happen -- Well it just did 2022
  if (!account || !currency) {
    return Promise.resolve()
  }

  const initAmount = Math.round(accountFormData.initAmount * 100)
  const initRefAmount = Math.round(initAmount / currency.ratioToReferential)

  const creditCard =
    accountFormData.creditCard &&
    [AccountType.CREDIT_CARD, AccountType.OVERDRAFT].includes(accountFormData.accountType)
      ? {
          limit: accountFormData.creditCard.limit * 100 || 0,
          dueDay: accountFormData.creditCard.dueDay,
          balanceDisplayOption: accountFormData.creditCard.balanceDisplayOption,
        }
      : undefined

  const currencyId = currency._id

  const accountDocument = {
    ...account,
    ...accountFormData,
    currencyId,
    creditCard,
    initAmount,
    initRefAmount,
  }

  return commonRepository.updateBulk(
    [accountDocument as Account],
    inMemoryTableNames.ACCOUNT,
    accountConverter(),
  )
}

export async function updateAccountInitAmount(initAmount: number, accountId: Id) {
  const account: Account = await repository.findById(accountId)

  const updatedAccount = {
    ...account,
    initAmount,
  }

  return commonRepository.updateBulk(
    [updatedAccount as Account],
    inMemoryTableNames.ACCOUNT,
    accountConverter(),
  )
}

const getCantDeleteReason = (isLast, isConnectedAccount) => {
  if (isLast) {
    return "settings.accounts.remove.last_cash"
  } else if (isConnectedAccount) {
    return "settings.accounts.remove.integration-disconnect"
  } else {
    return null
  }
}

export async function getRemoveAccountProperties(account: Account) {
  const isConnectedAccount = isConnected(account)
  const isLast = await isLastCashAccount(account)
  const accountReferences = !isConnectedAccount && !isLast && (await getEntityReferences(account))
  const accountReferencedTypes = accountReferences && getTypesOfEntities(accountReferences)
  const cantDeleteReason = getCantDeleteReason(isLast, isConnectedAccount)

  return {
    cantDeleteReason,
    canDelete: !cantDeleteReason,
    references: accountReferences,
    referencedTypes: accountReferencedTypes,
  }
}

export function isLastCashAccount(account: Account) {
  return repository
    .getCountOfCashAccounts()
    .then((count) => count === 1 && account && account.accountType === AccountType.CASH)
}

export function removeAccountReferences(references: any, account: Account) {
  const referencesArray: Array<AnyDocument> = Object.values(references)
  const promises = []

  promises.push(removeAccountReference(referencesArray, account))
  promises.push(
    commonRepository.removeBulk(
      referencesArray.filter(
        isNotModelType(inMemoryTableNames.BUDGET && inMemoryTableNames.FILTER),
      ),
    ),
  )

  return Promise.all(promises)
}

function removeAccountReference(filteredReferences: Array<AnyDocument>, account: Account) {
  const { Filter: filters, Budget: budgets } = _groupBy(filteredReferences, "reservedModelType")

  const filtersWithoutReferences = filters
    ? filters.map((filter: FilterDocument) => {
        const { accounts } = filter
        const modifiedAccountIds = accounts.filter((accountId) => accountId !== account._id)
        return {
          ...filter,
          accounts: !_isEmpty(modifiedAccountIds) ? modifiedAccountIds : undefined,
        }
      })
    : []

  const budgetsWithoutReferences = budgets
    ? budgets.map((budget: Budget) => {
        const { accountIds } = budget
        const modifiedAccountIds = accountIds.filter((accountId) => accountId !== account._id)
        return {
          ...budget,
          accountIds: modifiedAccountIds,
        }
      })
    : []

  return Promise.all([
    commonRepository.updateBulk(
      filtersWithoutReferences,
      inMemoryTableNames.FILTER,
      filterConverter(),
    ),
    commonRepository.updateBulk(budgetsWithoutReferences, inMemoryTableNames.BUDGET),
  ])
}

export function removeAccount(account: Account) {
  return commonRepository.removeBulk([account])
}

function getDefaultAccount(position: number = 0): Account {
  return {
    _id: `-${inMemoryTableNames.ACCOUNT}_${uuid.v4()}`,
    color: "#cccccc",
    excludeFromStats: false,
    gps: false,
    initAmount: 0,
    initRefAmount: 0,
    name: "",
    position,
    accountType: AccountType.GENERAL,
    currencyId: "",
    reservedAuthorId: "",
    reservedCreatedAt: "",
    reservedUpdatedAt: "",
    reservedModelType: inMemoryTableNames.ACCOUNT,
    reservedOwnerId: "",
    reservedSource: Platform.WEB,
  }
}

export function fetchAccounts() {
  return repository.findAll()
}

export async function getValidationDependencies() {
  const accounts = await repository.findAllAsHashMap()
  const colors = getColors()

  return {
    accounts,
    colors,
  }
}
