import * as actions from './actions'

export default function rewardPoints(state = {}, action) {
  switch (action.type) {
    case actions.REWARD_POINTS_RECEIVE: {
      return { ...action.payload }
    }
    default:
      return state
  }
}
