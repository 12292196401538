export default function radiusBackground() {
  const draw = (chartInstance) => {
    if (chartInstance.options.radiusBackground) {
      const x = chartInstance.chart.canvas.clientWidth / 2
      const y = chartInstance.chart.canvas.clientHeight / 2
      const ctx = chartInstance.chart.ctx

      ctx.beginPath()
      ctx.arc(x, y, chartInstance.outerRadius - (chartInstance.radiusLength / 2), 0, 2 * Math.PI)
      ctx.lineWidth = chartInstance.radiusLength
      ctx.strokeStyle = chartInstance.options.radiusBackground.color || '#d1d1d1'
      ctx.stroke()
    }
  }

  // see http://www.chartjs.org/docs/#advanced-usage-creating-plugins for plugin interface
  return {
    beforeDatasetsDraw: draw,
    onResize: draw,
  }
}
