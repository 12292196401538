import React from "react"
import { Interval } from "src/backend/enums"
import moment, { Moment } from "moment"
import _range from "lodash/range"
import { Icon } from "semantic-ui-react"
import "./MonthYearContent.less"
import { parseIntDecimal } from "src/common/utils"
import { Period } from "src/types/Filter"

type Props = {
  period: Period
  onChange: Function
  onClose: () => void
}

type State = {
  centerYear: number
}

export default class YearContent extends React.Component<Props, State> {
  state = { centerYear: parseIntDecimal(moment().format("Y")) }

  static NUM_OF_YEARS = 9

  handleAddYear = (increment: number) => () =>
    this.setState((prevState) => {
      return { centerYear: prevState.centerYear + increment }
    })

  handleChangeYear = (year: number) => () => {
    const date = moment({
      d: 1,
      M: 1,
      y: year,
    })

    const start = date.clone().startOf("year")
    const end = start.clone().endOf("year")

    this.props.onChange({
      interval: Interval.YEAR,
      start,
      end,
    })
    this.props.onClose()
  }

  generateYearsAsItems = () => {
    const { centerYear } = this.state
    const { start, interval } = this.props.period

    const lesserHalfOfYears = Math.floor(YearContent.NUM_OF_YEARS / 2)
    const startYear = centerYear - lesserHalfOfYears
    const endYear = centerYear + lesserHalfOfYears
    const years = _range(startYear, endYear + 1)

    return years.map((year) => {
      const isThisYear = parseIntDecimal(moment().format("Y")) === year
      const isSelectedYear =
        interval === Interval.YEAR && start && parseIntDecimal(start.format("Y")) === year
      const className = `${isSelectedYear ? "active" : ""} ${isThisYear ? "current" : ""}`

      return (
        <li
          className={className}
          onClick={this.handleChangeYear(year)}
          key={year}
        >
          {year}
        </li>
      )
    })
  }

  moveToYearsPage(momentInstance: Moment) {
    this.setState((prevState) => {
      const year = parseIntDecimal(momentInstance.format("Y"))
      const diff = year - prevState.centerYear - Math.floor(YearContent.NUM_OF_YEARS / 2)
      const steps = Math.ceil(diff / YearContent.NUM_OF_YEARS)
      return { centerYear: prevState.centerYear + steps * YearContent.NUM_OF_YEARS }
    })
  }

  componentWillMount() {
    const { start } = this.props.period
    this.moveToYearsPage(start || moment())
  }

  componentWillReceiveProps(nextProps: Props) {
    const { start } = nextProps.period
    this.moveToYearsPage(start || moment())
  }

  render() {
    const { centerYear } = this.state
    const lesserHalfOfYears = Math.floor(YearContent.NUM_OF_YEARS / 2)
    return (
      <div
        className="date-range-picker-month-year-content"
        data-e2e="date-range-picker-month-year-content"
      >
        <div className="header">
          <Icon
            name="caret left"
            size="large"
            onClick={this.handleAddYear(-YearContent.NUM_OF_YEARS)}
          />
          <div>{`${centerYear - lesserHalfOfYears} - ${centerYear + lesserHalfOfYears}`}</div>
          <Icon
            name="caret right"
            size="large"
            onClick={this.handleAddYear(+YearContent.NUM_OF_YEARS)}
          />
        </div>
        <ul>{this.generateYearsAsItems()}</ul>
      </div>
    )
  }
}
