import { Action } from 'src/types/common'
import { ModuleStatus } from 'src/frontend/modules/moduleStatus/types'
import {
  ACCOUNTS_MODULE_STATUS_EXPIRED,
  ACCOUNTS_MODULE_STATUS_LOADING,
  ACCOUNTS_MODULE_STATUS_UP_TO_DATE,
  ANALYTICS_MODULE_STATUS_EXPIRED,
  ANALYTICS_MODULE_STATUS_LOADING,
  ANALYTICS_MODULE_STATUS_UP_TO_DATE,
  DETAIL_RECORDS_MODULE_STATUS_EXPIRED,
  DETAIL_RECORDS_MODULE_STATUS_LOADING,
  DETAIL_RECORDS_MODULE_STATUS_UP_TO_DATE,
  DASHBOARD_MODULE_STATUS_EXPIRED,
  DASHBOARD_MODULE_STATUS_LOADING,
  DASHBOARD_MODULE_STATUS_UP_TO_DATE,
  IMPORTS_RECORD_LIST_EXPIRED,
  IMPORTS_RECORD_LIST_LOADING,
  IMPORTS_RECORD_LIST_UP_TO_DATE,
  RECORDS_MODULE_STATUS_EXPIRED,
  RECORDS_MODULE_STATUS_LOADING,
  RECORDS_MODULE_STATUS_UP_TO_DATE,
} from 'src/frontend/modules/moduleStatus/actions'

export interface ModuleStatusState {
  dashboard: ModuleStatus,
  records: ModuleStatus,
  accountsTotals: ModuleStatus,
  analytics: ModuleStatus,
  importsRecordList: ModuleStatus,
  detailRecords: ModuleStatus,
}

const initModuleStatusState = {
  dashboard: ModuleStatus.EXPIRED,
  records: ModuleStatus.EXPIRED,
  accountsTotals: ModuleStatus.EXPIRED,
  analytics: ModuleStatus.EXPIRED,
  importsRecordList: ModuleStatus.EXPIRED,
  detailRecords: ModuleStatus.EXPIRED,
}

export default function moduleStatus(
  state: ModuleStatusState = initModuleStatusState,
  action: Action,
): ModuleStatusState {
  switch (action.type) {
    case DASHBOARD_MODULE_STATUS_EXPIRED: {
      return { ...state, dashboard: ModuleStatus.EXPIRED }
    }
    case DASHBOARD_MODULE_STATUS_UP_TO_DATE: {
      return { ...state, dashboard: ModuleStatus.UP_TO_DATE }
    }
    case DASHBOARD_MODULE_STATUS_LOADING: {
      return { ...state, dashboard: ModuleStatus.LOADING }
    }


    case RECORDS_MODULE_STATUS_EXPIRED: {
      return { ...state, records: ModuleStatus.EXPIRED }
    }
    case RECORDS_MODULE_STATUS_UP_TO_DATE: {
      return { ...state, records: ModuleStatus.UP_TO_DATE }
    }
    case RECORDS_MODULE_STATUS_LOADING: {
      return { ...state, records: ModuleStatus.LOADING }
    }

    case DETAIL_RECORDS_MODULE_STATUS_EXPIRED: {
      return { ...state, detailRecords: ModuleStatus.EXPIRED }
    }
    case DETAIL_RECORDS_MODULE_STATUS_UP_TO_DATE: {
      return { ...state, detailRecords: ModuleStatus.UP_TO_DATE }
    }
    case DETAIL_RECORDS_MODULE_STATUS_LOADING: {
      return { ...state, detailRecords: ModuleStatus.LOADING }
    }


    case ACCOUNTS_MODULE_STATUS_EXPIRED: {
      return { ...state, accountsTotals: ModuleStatus.EXPIRED }
    }
    case ACCOUNTS_MODULE_STATUS_UP_TO_DATE: {
      return { ...state, accountsTotals: ModuleStatus.UP_TO_DATE }
    }
    case ACCOUNTS_MODULE_STATUS_LOADING: {
      return { ...state, accountsTotals: ModuleStatus.LOADING }
    }


    case ANALYTICS_MODULE_STATUS_EXPIRED: {
      return { ...state, analytics: ModuleStatus.EXPIRED }
    }
    case ANALYTICS_MODULE_STATUS_UP_TO_DATE: {
      return { ...state, analytics: ModuleStatus.UP_TO_DATE }
    }
    case ANALYTICS_MODULE_STATUS_LOADING: {
      return { ...state, analytics: ModuleStatus.LOADING }
    }


    case IMPORTS_RECORD_LIST_EXPIRED: {
      return { ...state, importsRecordList: ModuleStatus.EXPIRED }
    }
    case IMPORTS_RECORD_LIST_UP_TO_DATE: {
      return { ...state, importsRecordList: ModuleStatus.UP_TO_DATE }
    }
    case IMPORTS_RECORD_LIST_LOADING: {
      return { ...state, importsRecordList: ModuleStatus.LOADING }
    }

    default: {
      return state
    }
  }
}
