import React from 'react'
import { Icon } from 'semantic-ui-react'
import { FormattedMessage } from 'src/frontend/modules/intl'
import { getPrivacyPolicyUrl } from 'src/common/environment'

export default function PrivacyLink() {
    return (
        <a href={getPrivacyPolicyUrl()} target='_blank' rel='noopener noreferrer'>
            <FormattedMessage id='auth.signup.privacy_policy.link_text' />
        </a>
    )
}