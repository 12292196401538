import { setBackgroundGradient } from 'src/frontend/components/chart/helpers'
import { DATASET_DEFAULT_OPTIONS } from 'src/frontend/scenes/dashboard/components/constants'
import { PeriodToPeriodChartData } from 'src/frontend/scenes/dashboard/widgets/PeriodToPeriodChart/types'
import { Currency } from 'src/types/Currency'
import { RevenueCostsGrowthWidgetVariant } from 'src/frontend/scenes/dashboard/widgets/BoardRevenueCostsGrowth/enums'
import { formatMessage } from 'src/frontend/modules/intl'

const PERIOD_TO_PERIOD_DEFAULT_OPTIONS = {
  borderWidth: 2,
  fill: 'origin',
  hideLegend: false,
  reverseLegend: true,
  isEmpty: false,
  backgroundColor: 'transparent',
}

export function aggregateRevenuesCostsChartData(
  referentialCurrency: Currency,
  widgetVariant: RevenueCostsGrowthWidgetVariant,
) {
  return (data: PeriodToPeriodChartData) => {
    if (!data) {
      return null
    }

    const shouldNegValues = widgetVariant === RevenueCostsGrowthWidgetVariant.COSTS


    const datasets = data.datasets
      ? [
        // Year Ago period
        {
          ...data.datasets[2],
          data: data.datasets[2].data.map((value) => shouldNegValues ? -1 * value : value),
          ...DATASET_DEFAULT_OPTIONS,
          ...PERIOD_TO_PERIOD_DEFAULT_OPTIONS,
          borderColor: '#ffb300',
          pointBackgroundColor: '#ffb300',
          label: formatMessage('period-yearago'),
        },
        // Last period
        {
          ...data.datasets[1],
          data: data.datasets[1].data.map((value) => shouldNegValues ? -1 * value : value),
          ...DATASET_DEFAULT_OPTIONS,
          ...PERIOD_TO_PERIOD_DEFAULT_OPTIONS,
          borderColor: '#f06292',
          pointBackgroundColor: '#f06292',
          label: formatMessage('period-previous'),
        },
        // Current Period
        {
          ...data.datasets[0],
          data: data.datasets[0].data.map((value) => shouldNegValues ? -1 * value : value),
          ...DATASET_DEFAULT_OPTIONS,
          ...PERIOD_TO_PERIOD_DEFAULT_OPTIONS,
          borderColor: '#149dff',
          pointBackgroundColor: '#149dff',
          label: formatMessage('period-current'),
          backgroundGradient: setBackgroundGradient(['rgba(20, 157, 255, 0.7)', 'rgba(20, 157, 255, 0)']),
          isEmpty: data.datasets
            && !!data.datasets[0]
            && data.datasets[0].data.every((value => value === null || value === 0)),
        },
      ]
      : []

    return {
      ...data,
      currency: referentialCurrency,
      height: 174,
      options: {
        aspectRatio: null,
        maintainAspectRatio: false,
        tooltips: { mode: 'nearest' },
      },
      datasets,
    }
  }
}
