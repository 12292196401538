import React from 'react'
import { Button, Modal, SemanticCOLORS } from 'semantic-ui-react'
import { FormattedMessage } from 'src/frontend/modules/intl'
import { isAppBoard } from 'src/common/environment'

interface ModalAction {
    loading?: boolean
    disabled?: boolean
    primary: boolean
    messageId: string
    callback: Function
    color?: SemanticCOLORS
    inverted: boolean
}

interface Props {
    open: boolean
    contentMessageId: string
    contentSecondMessageId?: string
    headerMessageId: string
    actions: ModalAction[]
    close: () => void
}

function getButtonColor(primary: boolean = false, color: SemanticCOLORS): SemanticCOLORS {
    if (color) {
        return color
    } else if (primary) {
        return 'green'
    } else {
        return undefined
    }
}

function GenericConfirmationModal({
    open,
    headerMessageId,
    contentMessageId,
    contentSecondMessageId,
    actions,
    close,
}: Props) {
    return (
        <Modal open={open} onClose={close} dimmer size='small'>
            <Modal.Header>
                <FormattedMessage id={headerMessageId} />
                <span className='modal-close' onClick={close} />
            </Modal.Header>
            <Modal.Content>
                <p>
                    <FormattedMessage id={contentMessageId} />
                </p>
                {contentSecondMessageId && (
                    <p>
                        <FormattedMessage id={contentSecondMessageId} />
                    </p>
                )}
            </Modal.Content>
            <Modal.Actions>
                {actions.map(({ loading, disabled, primary, messageId, color, callback, inverted }) => (
                    <Button
                        key={messageId}
                        inverted={inverted}
                        loading={loading}
                        disabled={disabled}
                        color={getButtonColor(primary, color)}
                        onClick={() => callback()}
                        circular={isAppBoard() ? false : true}
                    >
                        <FormattedMessage id={messageId} />
                    </Button>
                ))}
            </Modal.Actions>
        </Modal>
    )
}


export default GenericConfirmationModal
