import { RootState } from 'src/types/State'
import { selectBilling } from 'src/frontend/scenes/billing/selectors'
import {
  BraintreeTransaction,
  BraintreeTransactionStatus,
} from 'src/frontend/scenes/billing/transactionHistory/types'
import { createSelector } from 'reselect'

export const selectTransactionHistory = (state: RootState) => selectBilling(state).transactionHistory

export const selectTransactionHistoryLoading = (state: RootState) => selectTransactionHistory(state).loading

export const selectTransactionHistoryTransactions = (state: RootState): BraintreeTransaction[] => {
  return selectTransactionHistory(state).transactions
}

export const selectReceiptsTransactions = createSelector([selectTransactionHistoryTransactions], (transactions) => {
  return transactions?.filter((transaction) => {
    return [
      BraintreeTransactionStatus.AUTHORIZED,
      BraintreeTransactionStatus.AUTHORIZING,
      BraintreeTransactionStatus.SUBMITTED_FOR_SETTLEMENT,
      BraintreeTransactionStatus.SETTLEMENT_CONFIRMED,
      BraintreeTransactionStatus.SETTLEMENT_PENDING,
      BraintreeTransactionStatus.SETTLED,
      BraintreeTransactionStatus.SETTLING,
    ].includes(transaction.status)
  }) ?? []
})
