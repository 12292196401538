import { selectSettings } from 'src/frontend/scenes/settings/selectors'
import { RootState } from 'src/types/State'
import { MagicRulesFormState } from 'src/frontend/scenes/settings/magicRules/reducer'
import { createSelector } from 'reselect'
import * as labelsSelectors from 'src/frontend/modules/labels/selectors'
import { selectMagicRulesAsArray } from 'src/frontend/modules/magicRules/selectors'
import * as categoriesSelectors from 'src/frontend/modules/categories/selectors'
import * as contactsSelectors from 'src/frontend/modules/contacts/selectors'
import { convertLabelsView } from 'src/backend/converters/helpers'
import {
  aggregateRecords,
  getRecordAccount,
  getRecordCategory,
  getRecordContact,
} from 'src/frontend/scenes/records/recordList/selectors'
import { Record } from 'src/types/Record'
import { MagicRuleView } from 'src/frontend/scenes/settings/magicRules/types'
import { User } from 'src/types/User'
import { getUserFeatureValue, isKBUser } from 'src/backend/user/service'
import { Feature } from 'src/backend/user/billingFeatures'
import { selectUser } from 'src/frontend/modules/user/selectors'
import * as accountsSelectors from 'src/frontend/modules/accounts/selectors'
import { RecordListItemRecord } from 'src/frontend/components/RecordList/types'
import * as currenciesSelectors from 'src/frontend/modules/currencies/selectors'
import { RecordListOrderBy } from 'src/frontend/scenes/records/recordList/enums'

export const selectMagicRules = (state: RootState): MagicRulesFormState => selectSettings(state).magicRules

export const selectMagicRulesView: (state: RootState) => MagicRuleView[] = createSelector(
  [
    selectMagicRulesAsArray,
    labelsSelectors.selectAllLabels,
    categoriesSelectors.selectFlattenedCategories,
    contactsSelectors.selectContacts,
    accountsSelectors.selectAllAccounts,
  ],
  (magicRules, labels, categories, contacts, accounts) => {
    return magicRules.map((magicRule) => {
      const labelsView = magicRule.labelIds
        ? convertLabelsView(magicRule.labelIds, labels)
        : []

      const categoryView = magicRule.categoryId
        && getRecordCategory({ categoryId: magicRule.categoryId } as Record, categories)

      const contactView = magicRule.contactId
        && getRecordContact({ contactId: magicRule.contactId } as Record, contacts)

      const accountsView = magicRule.accountIds
        ? magicRule.accountIds.map((accountId) => getRecordAccount({ accountId } as Record, accounts))
        : []

      return {
        ...magicRule,
        labelsView,
        categoryView,
        contactView,
        accountsView,
      }
    })
  },
)


const getCanAddRule = (user: User): boolean => {
  return getUserFeatureValue(user, Feature.AUTOMATIC_RULES) as boolean || isKBUser(user)
}

export const selectCanAddEditRule: (state: RootState) => boolean = createSelector(selectUser, getCanAddRule)

export const selectSimilarRecords = (state: RootState) => selectMagicRules(state).similarRecords


export const selectAggregatedSimilarRecords: (state: RootState) => RecordListItemRecord[] = createSelector(
  [
    accountsSelectors.selectAllAccounts,
    categoriesSelectors.selectFlattenedCategories,
    currenciesSelectors.selectCurrencies,
    labelsSelectors.selectAllLabels,
    contactsSelectors.selectContacts,
    currenciesSelectors.selectReferentialCurrency,
    () => RecordListOrderBy.RECORD_DATE_DESC,
    selectSimilarRecords,
  ],
  aggregateRecords,
)
