import { ImportMappingAttributeType } from 'src/backend/enums'
import { isType } from 'src/common/utils'

const { EXPENSE, FEE } = ImportMappingAttributeType

export function hasExpense(parsedExample) {
  return parsedExample.some(isType(EXPENSE))
}

export function hasFee(parsedExample) {
  return parsedExample.some(isType(FEE))
}
