import React, { SyntheticEvent } from "react"
import { connect } from "react-redux"
import { Dropdown, DropdownProps } from "semantic-ui-react"
import { FormattedMessage } from "src/frontend/modules/intl"
import { IntervalsToSave } from "src/backend/webConfig/constants"
import { Interval } from "src/backend/enums"
import { saveDashboardDefaultPeriod } from "src/frontend/modules/webConfig/actions"
import { formatInterval } from "src/frontend/modules/intl/i18n"
import { selectDefaultInterval } from "src/frontend/modules/webConfig/selectors"
import { withIntlConsumer } from "src/frontend/modules/intl/components/withIntlConsumer"
import { intervalTypeToPeriod } from "src/backend/time/time"
import { changeFilter } from "src/frontend/scenes/dashboard/actions"
import { RootState } from "src/types/State"

interface Props {
  defaultInterval: Interval
  saveModulesDefaultPeriod: Function //typeof saveDashboardDefaultPeriod
  saveScenesDefaultPeriod: typeof changeFilter
}

function mapStateToProps(state: RootState) {
  return {
    defaultInterval: selectDefaultInterval(state),
  }
}

const mapDispatchToProps = {
  // Updates store at modules.webConfig.interval
  saveModulesDefaultPeriod: saveDashboardDefaultPeriod,
  // Update store at scenes.dashboard.filter.period
  saveScenesDefaultPeriod: changeFilter,
}

const DefaultDashboardPeriod: React.FC<Props> = ({
  defaultInterval,
  saveModulesDefaultPeriod,
  saveScenesDefaultPeriod,
}) => {
  const defaultPeriodOptions = IntervalsToSave.map((interval: Interval) => {
    return { key: interval, text: formatInterval(interval), value: interval }
  })

  const handlePeriodChange = (_e: SyntheticEvent, { value }: DropdownProps) => {
    const interval = value as Interval
    const period = intervalTypeToPeriod(interval)

    saveModulesDefaultPeriod(interval)
    saveScenesDefaultPeriod({ period })
  }

  return (
    <div className="item">
      <div className="label">
        <FormattedMessage id="settings.general.default_dashboard_interval" />
      </div>
      <div>
        <Dropdown
          selection
          value={defaultInterval}
          options={defaultPeriodOptions}
          onChange={handlePeriodChange}
        />
      </div>
    </div>
  )
}

export default withIntlConsumer(
  connect(mapStateToProps, mapDispatchToProps)(DefaultDashboardPeriod),
)
