import React, { useMemo } from 'react'
import { Doughnut } from 'react-chartjs-2'
import { ChartType } from 'src/frontend/components/chart/ChartType'
import {
  pieTooltipTitle,
  tooltipPercentage,
  tooltipTextLabel,
} from 'src/frontend/components/chart/helpers'
import { Currency } from 'src/types/Currency'
import { DATASET_DEFAULT_OPTIONS } from 'src/frontend/components/chart/constants'
import _merge from 'lodash/merge'
import { ChartDataSets, ChartOptions } from 'chart.js'
import { Dataset } from 'src/frontend/components/chart/types'

export interface Props {
  type: string,
  labels: Array<string>,
  datasets: Array<Dataset>,
  currency: Currency,
  options?: any,
  height?: number,
  getElementAtEvent?: (e: any) => void,
  passRef?: React.RefObject<HTMLDivElement>
}

PieChart.defaultProps = {
  options: {},
  height: undefined,
  getElementAtEvent: undefined,
}

export default function PieChart({
  type,
  datasets,
  labels,
  currency,
  options,
  height,
  getElementAtEvent,
  passRef,
}: Props) {
  if (type !== ChartType.PIE) {
    throw new Error(`Unsupported chart type: '${type}'`)
  }

  const DEFAULT_OPTIONS: ChartOptions = {
    legend: {
      display: true,
      position: 'bottom',
    },
    tooltips: {
      titleFontColor: '#FFFFFF',
      bodyFontColor: '#cccccc',
      cornerRadius: 4,
      callbacks: {
        title: pieTooltipTitle(currency),
        afterTitle: tooltipPercentage,
        label: tooltipTextLabel(labels),
      },
    },
  }

  const chartOptions = useMemo(() => _merge(DEFAULT_OPTIONS, options), [options, currency, labels])

  const data = useMemo(() => ({
    labels,
    datasets: datasets.map((dataset: Dataset): ChartDataSets => {
      return {
        // shared chart options
        ...DATASET_DEFAULT_OPTIONS,

        // specific dataset options (can override shared options)
        ...dataset,

        data: dataset.data,
      } as ChartDataSets
    }),
  }), [datasets, labels])

  return (
    <div ref={passRef}>
      <Doughnut
        data={data}
        options={chartOptions}
        height={height}
        getElementAtEvent={getElementAtEvent}
      />
    </div>
  )
}
