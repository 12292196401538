import { RECEIVE_TOUR_GUIDES } from 'src/frontend/modules/configures/tourGuides/actions'
import { Action } from 'src/types/common'

export type TourGuidesState = Array<string>

export default function tourGuides(state: TourGuidesState = [], action: Action) {
  switch (action.type) {
    case RECEIVE_TOUR_GUIDES: {
      return action.payload
    }
    default: {
      return state
    }
  }
}
