import { Billing } from 'src/types/Billing'
import BraintreeProduct = Billing.BraintreeProduct
import PeriodType = Billing.PeriodType
import { isAppBoard } from 'src/common/environment'
import { formatMessage } from 'src/frontend/modules/intl'
import { isUndefinedOrNull } from 'src/common/utils'
import Product = Billing.Product

export function isRecurringProduct(braintreeProduct: BraintreeProduct): boolean {
  return braintreeProduct?.product?.period !== PeriodType.UNLIMITED
}

export function getFeaturesList() {
  return isAppBoard()
    ? [
      { icon: 'bank', text: 'premium.features.bank-connect', color: undefined },
      { icon: 'sync', text: 'premium.features.sync', color: undefined },
      { icon: 'uptodate', text: 'premium.features.cash-flow', color: undefined },
      { icon: 'rule', text: 'premium.features.automation', color: undefined },
    ]
    : [
      { icon: 'star', text: 'premium.features.unlimited-accounts', color: 'orange' },
      { icon: 'star', text: 'premium.features.unlimited-bank-sync', color: 'orange' },
      { icon: 'star', text: 'premium.features.multi-users', color: 'orange' },
      { icon: 'star', text: 'premium.features.advanced-charts', color: 'orange' },
      { icon: 'star', text: 'premium.features.tools-and-widgets', color: 'orange' },
    ]
}


const PLAN_PERIOD_NAME = {
  [Billing.PeriodType.UNLIMITED]: 'billing.subscription.plan.lifetime',
  [Billing.PeriodType.MONTH]: 'billing.subscription.plan.monthly',
  [Billing.PeriodType.YEAR]: 'billing.subscription.plan.yearly',
}

export function getPlanLabel(product: Product): string {
  if (product?.period in PLAN_PERIOD_NAME) {
    return formatMessage(PLAN_PERIOD_NAME[product.period])
  }

  return null
}


const planPeriodCoefficient = {
  [Billing.PeriodType.UNLIMITED]: 1,
  [Billing.PeriodType.MONTH]: 1,
  [Billing.PeriodType.YEAR]: 12,
}


function getMonthValue(product: BraintreeProduct): number {
  if (product.product.period in planPeriodCoefficient) {
    return product.price / planPeriodCoefficient[product.product.period]
  }
}

export function getMonthYearDifference(products: BraintreeProduct[]): number {
  const monthProduct = products.find((product) => product.product.period === Billing.PeriodType.MONTH)
  const yearProduct = products.find((product) => product.product.period === Billing.PeriodType.YEAR)

  const yearValue = yearProduct && getMonthValue(yearProduct)

  return !isUndefinedOrNull(monthProduct) && !isUndefinedOrNull(yearProduct) && !isUndefinedOrNull(yearValue)
    ? 1 - (yearValue / monthProduct.price)
    : null
}
