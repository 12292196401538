import {
  calcRefAmount,
  getValidCurrencyForAccount,
  isExpense,
  isIncome,
  isTransfer,
  isTransferExpense,
  isTransferIncome,
  roundAmounts,
} from "src/backend/records/helpers"
import { VIRTUAL_OUT_OF_WALLET_ACCOUNT_ID } from "src/backend/accounts/helpers"
import { FilterRecordType, RecordType, Interval } from "src/backend/enums"
import { intervalTypeToPeriod } from "src/backend/time/time"
import _isEmpty from "lodash/isEmpty"
import { Record, RecordTransfer } from "src/types/Record"
import { HashMap } from "src/types/common"
import { RecordListItemRecord } from "src/frontend/components/RecordList/types"
import { FilterType, Period } from "src/types/Filter"
import { Currency } from "src/types/Currency"
import { pipe } from "ramda"
import { Account } from "src/types/Account"
import { Id } from "src/types/CouchDb"

import { createDefaultDocument } from "src/backend/common/service"
import * as recordsRepository from "./repository"
import * as accountsRepository from "src/backend/accounts/repository"
import * as categoriesRepository from "src/backend/categories/repository"
import * as categoriesService from "src/backend/categories/service"
import * as currenciesRepository from "src/backend/currencies/repository"
import * as currenciesService from "src/backend/currencies/service"
import * as labelsRepository from "src/backend/labels/repository"
import * as commonRepository from "src/backend/common/repository"
import * as contactsRepository from "src/backend/contacts/repository"
import * as envelopes from "src/backend/categories/envelopes"
import { sortByPosition } from "../common/helpers"

export function convertToTransferRecordVO({
  selectedRecord,
  relatedRecord,
}: HashMap<Record>): RecordTransfer {
  const fromRecord = isTransferExpense(selectedRecord) ? selectedRecord : relatedRecord
  const toRecord = isTransferIncome(selectedRecord) ? selectedRecord : relatedRecord

  // falling back to zero/virtual OOW values if related record does not exist (transfer Out of Wallet)
  const transferAttributes = {
    fromAccountId:
      fromRecord?.accountId ??
      selectedRecord?.transferAccountId ??
      VIRTUAL_OUT_OF_WALLET_ACCOUNT_ID,
    fromCurrencyId: fromRecord ? fromRecord.currencyId : selectedRecord.currencyId,
    fromAmount: fromRecord ? fromRecord.amount : selectedRecord.amount,
    toAccountId:
      toRecord?.accountId ?? selectedRecord.transferAccountId ?? VIRTUAL_OUT_OF_WALLET_ACCOUNT_ID,
    toCurrencyId: toRecord ? toRecord.currencyId : selectedRecord.currencyId,
    toAmount: toRecord ? toRecord.amount : selectedRecord.amount,
  }

  return { ...selectedRecord, ...transferAttributes } as RecordTransfer
}

function removeAllTransferAttributes(record: Record): Record {
  const { transferId, transfer, transferAccountId, ...recordWithoutTransferAttributes } = record

  return recordWithoutTransferAttributes
}

export function removeTransferAccountId(record: Record) {
  const { transferAccountId, ...transferWithoutTransferAccountId } = record

  return transferWithoutTransferAccountId
}

export function removeTransferId(record: Record) {
  const { transferId, ...transferWithoutTransferId } = record

  return transferWithoutTransferId
}

export function convertToRecordEntity(recordVO: RecordListItemRecord): Record {
  const {
    accountName,
    accountColor,
    accountIsConnected,
    categoryIcon,
    categoryName,
    contact,
    color,
    currencyCode,
    referentialCurrencyCode,
    fulltextString,
    standingOrderId,
    labelsView,
    ...record
  } = recordVO

  if (isTransfer(record)) {
    return record
  } else {
    return removeAllTransferAttributes(record)
  }
}

export function convertRecordTypesFilter(
  recordTypes: FilterRecordType[] = [],
): Partial<FilterType> {
  if (_isEmpty(recordTypes)) return {}

  const filter: Partial<FilterType> = {}

  if (
    recordTypes.includes(FilterRecordType.INCOME) &&
    !recordTypes.includes(FilterRecordType.EXPENSE)
  ) {
    filter.type = RecordType.INCOME
  }
  if (
    recordTypes.includes(FilterRecordType.EXPENSE) &&
    !recordTypes.includes(FilterRecordType.INCOME)
  ) {
    filter.type = RecordType.EXPENSE
  }
  if (!recordTypes.includes(FilterRecordType.TRANSFER)) filter.transfer = false
  if (recordTypes.includes(FilterRecordType.TRANSFER) && recordTypes.length === 1)
    filter.transfer = true

  return filter
}

export function convertPeriodFilter(period: Period): Partial<Period> {
  if (period) {
    if (Interval.ALL_THE_TIME === period.interval) {
      return { interval: period.interval }
    }

    const start = period.start || intervalTypeToPeriod(period.interval).start
    const end = period.end || intervalTypeToPeriod(period.interval).end
    return { start, end, interval: period.interval }
  }

  return {}
}

export function createTransferRecord(
  updatedRecord: RecordTransfer,
  account: Account,
  currencyId: Id,
  amount: number,
  refAmount: number,
  recordType: RecordType,
) {
  return async (originalRecord: RecordTransfer | Record) => {
    const currency = await getValidCurrencyForAccount(account, currencyId)

    return pipe(
      updateOriginalRecordWith(updatedRecord),
      updateAccountId(account),
      updateAmountAndCurrency(amount, currency),
      addRefAmount(refAmount),
      updateRecordType(recordType),
      roundAmounts,
    )(originalRecord)
  }
}

/**
 * Returns function which takes originalRecord and merges its attributes with updatedRecord.
 * Merging function leaves out all system attributes from updatedRecord, so it will be still same in originalRecord.
 *
 * @param updatedRecord
 * @returns {function(originalRecord)}
 */
function updateOriginalRecordWith(updatedRecord: Record) {
  return (originalRecord: Record) => {
    // noinspection JSUnusedLocalSymbols - spread operator is used to remove attributes
    const {
      _id,
      _rev,
      reservedAuthorId,
      reservedCreatedAt,
      reservedModelType,
      reservedOwnerId,
      reservedSource,
      reservedUpdatedAt,
      reservedIntegrationDate,
      reservedIntegrationId,
      reservedIntegrationRecipeId,
      reservedIntegrationRemoteTransactionId,
      reservedIntegrationSource,
      reservedIntegrationTransactionId,
      type,
      ...updatedRecordWithoutSystemAttributes
    } = updatedRecord

    return {
      ...originalRecord,
      ...updatedRecordWithoutSystemAttributes,
    }
  }
}

export const updateAmountAndCurrency =
  (amount: number, currency: Currency) => (recordEntity: Record) => {
    return {
      ...recordEntity,
      currencyId: currency._id,
      amount,
    }
  }

export function updateCategoryId(categoryId: Id) {
  return (updatedRecords: Record[], record: Record): Record[] => {
    const updatedRecord = {
      ...record,
      categoryId,
    }
    return [...updatedRecords, updatedRecord]
  }
}

export const updateOwnRefAmount =
  (currency: Currency) =>
  (recordEntity: Record): Record => {
    return {
      ...recordEntity,
      refAmount: calcRefAmount(recordEntity.amount, currency),
    }
  }

export function addRefAmount(refAmount: number) {
  return (recordEntity: Record): Record => {
    return {
      ...recordEntity,
      refAmount,
    }
  }
}

const updateAccountId =
  (account: Account) =>
  (recordEntity: Record): Record => {
    return {
      ...recordEntity,
      accountId: account ? account._id : null,
    }
  }

const updateRecordType =
  (type: RecordType) =>
  (recordEntity: Record): Record => {
    return {
      ...recordEntity,
      type,
    }
  }

export async function updateCurrencyForFormObject(accountId: Id): Promise<Id> {
  const account = await accountsRepository.findById(accountId)
  const currencyId = account && account.currencyId

  if (currencyId) {
    return currencyId
  }

  const { _id: referentialCurrencyId } = await currenciesRepository.getReferentialCurrency()
  return referentialCurrencyId
}
