import _isEmpty from "lodash/isEmpty"
import _isEqual from "lodash/isEqual"
import moment from "moment"
import { getTypesOfEntities } from "src/backend/common/helpers"
import * as localStorageService from "src/backend/db/localStorage"
import { inMemoryTableNames } from "src/backend/db/inMemorySqlDbSchemaBuilder"
import { fetchLatestAppInfo } from "src/backend/rest/app"
import { reduceChangesToDocuments } from "src/backend/sync/helpers"
import * as syncService from "src/backend/sync/service"
import { isBeforeToday } from "src/backend/time/time"
import { getMixPanelToken, getVersion } from "src/common/environment"
import { isAuthorizationError, isQuotaExceededError } from "src/common/helpers"
import * as logger from "src/common/logger"
import * as mixpanel from "src/common/mixpanel"
import { DEFAULT_ERROR_MESSAGE_ID, SHOW_ERROR, showErrorModal } from "src/frontend/Error/actions"
import * as accountsActions from "src/frontend/modules/accounts/actions"
import * as categoriesActions from "src/frontend/modules/categories/actions"
import * as webConfigActions from "src/frontend/modules/webConfig/actions"
import { applyConfigureObjects } from "src/frontend/modules/configures/actions"
import * as currenciesActions from "src/frontend/modules/currencies/actions"
import { setInitialFilter } from "src/frontend/modules/filter/actions"
import * as filtersActions from "src/frontend/modules/filters/actions"
import * as labelsActions from "src/frontend/modules/labels/actions"
import * as rewardPointsActions from "src/frontend/modules/rewardPoints/actions"
import * as standingOrdersActions from "src/frontend/modules/standingOrders/actions"
import * as templatesActions from "src/frontend/modules/templates/actions"
import * as userActions from "src/frontend/modules/user/actions"
import { selectUser } from "src/frontend/modules/user/selectors"
import * as appSelectors from "src/frontend/scenes/app/selectors"
import * as dashBoardActions from "src/frontend/scenes/dashboard/actions"
import { RootState } from "src/types/State"
import { User } from "src/types/User"
import { Record } from "src/types/Record"
import { Action, GetState } from "src/types/common"
import { AppInfo, NonRecordDocumentGroups, SyncedChanges } from "src/frontend/scenes/app/types"
import {
  expireAccountsStatus,
  expireAnalyticsStatus,
  expireDetailRecordsStatus,
  expireDashboardStatus,
  expireImportsRecordListStatus,
  expireRecordsStatus,
} from "src/frontend/modules/moduleStatus/actions"
import { receiveContacts } from "src/frontend/modules/contacts/actions"
import { receiveMagicRules } from "src/frontend/modules/magicRules/actions"
import { upgradeDashboardToVersion2 } from "src/backend/webConfig/service"
import { onBoardingStart } from "src/frontend/scenes/onBoarding/firstRun/actions"
import { initOnBoardingStatus } from "src/backend/onBoarding/service"
import { shouldShowOnBoarding } from "src/backend/onBoarding/helpers"
import { selectReferentialCurrency } from "src/frontend/modules/currencies/selectors"
import { selectAccountsAsArray } from "src/frontend/modules/accounts/selectors"
import { selectOnBoardingStatus } from "src/frontend/scenes/onBoarding/firstRun/selectors"
import { fetchRestrictedProviders } from "src/frontend/scenes/integrations/restrictedProviders/actions"
import { getActiveSubscriptionDetails } from "src/frontend/scenes/billing/activeSubscription/actions"

import { sanitizeTourGuides } from "src/backend/configures/service"
import { Currency } from "src/types/Currency"
import * as currenciesRepository from "src/backend/currencies/repository"
import * as recordsRepository from "src/backend/records/repository"
import * as accountsRepository from "src/backend/accounts/repository"
import * as templatesRepository from "src/backend/templates/repository"
import * as standingOrdersRepository from "src/backend/standingOrders/repository"

import { Account } from "src/types/Account"
import { Template } from "src/types/Template"

export const INIT_APP_START = "app/INIT_APP_START"
export const INIT_APP_END = "app/INIT_APP_END"
export const INIT_APP_ERROR = "app/INIT_APP_ERROR"
export const RESTORE_APP_STATE = "app/RESTORE_APP_STATE"
export const APP_LOGOUT = "app/APP_LOGOUT"

export const INIT_AUTHENTICATED_APP_START = "app/INIT_AUTHENTICATED_APP_START"
export const INIT_AUTHENTICATED_APP_ERROR = "app/INIT_AUTHENTICATED_APP_ERROR"
export const INIT_AUTHENTICATED_APP_END = "app/INIT_AUTHENTICATED_APP_END"
export const INIT_DATA_START = "app/INIT_DATA_START"
export const SETUP_SYNC_START = "app/SETUP_SYNC_START"
export const LOVEFIELD_DATA_REQUEST = "app/LOVEFIELD_DATA_REQUEST"

export const SYNC_CHANGES_INTO_MEMORY_DB_START = "app/SYNC_CHANGES_INTO_MEMORY_DB_START"
export const SYNC_CHANGES_INTO_MEMORY_DB_END = "app/SYNC_CHANGES_INTO_MEMORY_DB_END"

export const WALLET_LIFE_AVAILABLE_SET = "WALLET_LIFE_AVAILABLE_SET"

export let syncEventEmitter: PouchDB.Replication.Sync<{}>

export function initApp() {
  return async (dispatch: Function, getState: Function) => {
    const state: RootState = getState()

    if (appSelectors.selectApp(state).initialized) {
      console.log("initApp - already initialized.")
      return
    }

    dispatch(initAppStart())

    dispatch(checkAppVersion())
    console.log("check app version done")

    try {
      await initMixpanel()
      console.log("mixpanel init done")
      const user: User = await dispatch(userActions.getUser())
      console.log("user: ", user)
      identifySentry(user)
      console.log("Sentry indetify")

      dispatch(initAppEnd())
      await dispatch(initAuthenticatedApp())
    } catch (error) {
      if (isAuthorizationError(error)) {
        dispatch(initAppError())
      } else {
        dispatch(initAppError())
        // Open non-closable error message modal
        dispatch({
          type: SHOW_ERROR,
          errorMessageId: [DEFAULT_ERROR_MESSAGE_ID, "app.error.we_are_fixing_suffix"],
          errorRecoverable: false,
        })
        logger.captureException(error, "app.actions.initApp")
      }
    }
  }
}

function initAppStart(): Action {
  return {
    type: INIT_APP_START,
    payload: {
      loading: true,
      loadingText: "app.loading",
    },
  }
}

function initAppEnd(): Action {
  return {
    type: INIT_APP_END,
    payload: { initialized: true },
  }
}

function initAppError(): Action {
  return {
    type: INIT_APP_ERROR,
    payload: { loading: false },
  }
}

export function checkAppVersion() {
  return (dispatch: Function) => {
    const version: string = getVersion()
    return fetchLatestAppInfo()
      .then((appInfo: AppInfo) => {
        const newVersionExists: boolean = version !== appInfo.version
        console.log(
          `actualVersion=${version}, latestVersion=${appInfo.version}, newVersionExists=${newVersionExists}`,
        )
        // POC stage, test if we can reliably check if the new version exists in order to display some popup or
        // force reload the page in browser
        if (newVersionExists) {
          logger.info(
            `New version exists: actualVersion=${version}, latestVersion=${appInfo.version}`,
          )
        }
        dispatch({
          type: "APP_INFO",
          actualVersion: version,
          latestVersion: appInfo.version,
          newVersionExists,
        })
      })
      .catch((error) => {
        logger.captureException(error, "Cannot check application version")
        dispatch({
          type: "APP_INFO",
          actualVersion: version,
          latestVersion: version,
          newVersionExists: false,
        })
      })
  }
}

// FIXME should be moved to src/frontend/module/app
export function initAuthenticatedApp() {
  return async (dispatch: Function, getState: GetState) => {
    const user = selectUser(getState())

    if (appSelectors.selectApp(getState()).syncInitialized) {
      console.log("initAuthenticatedApp - already initialized.")
      return Promise.resolve()
    }

    dispatch(initAuthenticatedAppStart())

    try {
      identifyMixpanel(user)
      trackWebAppStartEvent(user)
      localStorageService.setAppStart(true)
      await Promise.all([
        // syncService.initRemoteDatabase(user),
        syncService.initLocalDatabase(user),
      ])

      mixpanel.trackWebAppUserDatabaseStartReplication()

      dispatch(setUpSyncStart())
      await setUpRemoteDatabasePull(user)

      dispatch(initDataStart())
      await dispatch(initData())

      await dispatch(startRemoteDatabaseSync(user))

      mixpanel.trackWebAppUserDatabaseInitialized()

      await Promise.all([
        dispatch(userActions.checkGdprConsent(user)),
        dispatch(fetchRestrictedProviders()),
        dispatch(getActiveSubscriptionDetails()),
      ])

      const referentialCurrency = selectReferentialCurrency(getState())
      const accounts = selectAccountsAsArray(getState())
      const onBoardingStatus =
        selectOnBoardingStatus(getState()) ||
        initOnBoardingStatus(user, referentialCurrency, accounts)

      if (shouldShowOnBoarding(onBoardingStatus)) {
        dispatch(onBoardingStart(onBoardingStatus))
      } else {
        dispatch(initAuthenticatedAppEnd())
      }
    } catch (error) {
      dispatch(initAuthenticatedAppError())
      console.warn(error)

      if (isQuotaExceededError(error)) {
        dispatch(quotaExceededError())
        logger.captureException(error, "app.actions.initAuthenticatedApp.quotaExceeded", { user })
      } else {
        // Open non-closable error message modal
        dispatch({
          type: SHOW_ERROR,
          errorMessageId: [DEFAULT_ERROR_MESSAGE_ID, "app.error.we_are_fixing_suffix"],
          errorRecoverable: false,
        })
        logger.captureException(error, "app.actions.initAuthenticatedApp")
      }
    }

    return Promise.resolve()
  }
}

function initAuthenticatedAppStart(): Action {
  return {
    type: INIT_AUTHENTICATED_APP_START,
    payload: {
      loading: true,
      loadingText: "app.init",
    },
  }
}

export function initAuthenticatedAppEnd(): Action {
  return {
    type: INIT_AUTHENTICATED_APP_END,
    payload: {
      loading: false,
      syncInitialized: true,
    },
  }
}

function quotaExceededError() {
  return (dispatch: Function) => {
    dispatch(initAppError())
    // Open non-closable error message modal
    dispatch({
      type: SHOW_ERROR,
      errorMessageId: ["app.error.disk_space"],
      errorRecoverable: false,
    })
  }
}

function initAuthenticatedAppError(): Action {
  return {
    type: INIT_AUTHENTICATED_APP_ERROR,
    payload: { loading: false },
  }
}

function setUpSyncStart(): Action {
  return {
    type: SETUP_SYNC_START,
    payload: { loadingText: "app.sync-data" },
  }
}

function initDataStart(): Action {
  return {
    type: INIT_DATA_START,
    payload: { loadingText: "app.init-data" },
  }
}

export function trackWebAppStartEvent(user) {
  const lastStart = localStorageService.getLastStart()
  mixpanel.updateUserProfile(user)
  if (!lastStart || isBeforeToday(moment(lastStart))) {
    localStorageService.setLastStart(moment().toISOString())
    mixpanel.trackWebAppStart()
  }

  return user
}

async function initMixpanel() {
  await mixpanel.init(getMixPanelToken())
}

function identifyMixpanel({ userId }: User) {
  mixpanel.identify(userId)
}

function identifySentry(user: User) {
  logger.sentryUserContext({
    email: user.email,
    userId: user.userId,
  })
}

export function logoutSoft() {
  return async (dispatch: Function) => {
    try {
      // Logout user from backend
      await dispatch(userActions.logout())
      dispatch(appLogout())
      localStorageService.logoutAllTabs()
      await syncService.softCloseDatabases()
    } catch (error) {
      logger.captureException(error, "logoutSoft unexpected error")
    }
  }
}

export function logoutHard() {
  return async (dispatch: Function) => {
    try {
      await dispatch(userActions.logout())
      dispatch(appLogout())
      localStorageService.logoutAllTabs()
      await syncService.hardCloseDatabases()
    } catch (error) {
      logger.captureException(error, "logoutHard unexpected error")
    }
  }
}

export function clearLocalData() {
  return async (dispatch: Function) => {
    try {
      await syncService.hardCloseDatabases()
    } catch (error) {
      logger.captureException(error, "logoutHard unexpected error")
    }
  }
}

function appLogout(): Action {
  return {
    type: APP_LOGOUT,
    payload: { loading: false },
  }
}

/**
 * Resolves duplicated currencies
 * 1. Find all currencies
 * 2. Group currencies by code(CZK, EUR, USD, ...)
 * 3. For each group:
 *   - If there is referential currency, use it for all records with duplicated currency
 *  - If there is no referential currency, use newest currency from duplicated currencies
 * 4. Update all records, accounts, templates and standing orders with new currency
 * 5. Remove all duplicated currencies
 * 6. Update data in store
 */
async function resolveDuplicatedCurrencies(dispatch: Function) {
  const currencies = await currenciesRepository.findAll()

  const duplicatedCurrencies = currencies
    .reduce<Currency[][]>((currenciesGroup, currency) => {
      const currencyCode = currency.code
      const currencyWithSameCode = currencies.filter(
        (filteredCurrency) => filteredCurrency.code === currencyCode,
      )

      const currencyGroupExists = currenciesGroup.some((group) =>
        group.some((groupCurrency) => groupCurrency.code === currencyCode),
      )

      if (!currencyGroupExists && currencyWithSameCode.length > 1) {
        currenciesGroup.push(currencyWithSameCode)
      }

      return currenciesGroup
    }, [])
    .map((currencyGroup) =>
      currencyGroup.sort(
        (a, b) => Date.parse(b.reservedCreatedAt) - Date.parse(a.reservedCreatedAt),
      ),
    )

  duplicatedCurrencies.forEach(async (currencyGroup) => {
    const hasReferentialCurrency = currencyGroup.some((currency) => currency.referential)

    // Currency that will be used(replaced) for all records with duplicated currency
    let futureCurrency: Currency | undefined

    if (hasReferentialCurrency) {
      futureCurrency = currencyGroup.find((currency) => currency.referential)
    } else {
      // Newest currency from duplicated currencies
      futureCurrency = currencyGroup[0]
    }

    const recordsToUpdatePromises: Promise<Record[]>[] = currencyGroup.map((currency) =>
      recordsRepository.findByCurrecyId(currency._id),
    )
    const accountsToUpdatePromises: Promise<Account[]>[] = currencyGroup.map((currency) =>
      accountsRepository.findByCurrencyId(currency._id),
    )
    const templatesToUpdatePromises: Promise<Template[]>[] = currencyGroup.map(
      (currency) => templatesRepository.findByCurrencyId(currency._id) as Promise<Template[]>,
    )
    const standingOrdersToUpdatePromises: Promise<Template[]>[] = currencyGroup.map(
      (currency) => standingOrdersRepository.findByCurrencyId(currency._id) as Promise<Template[]>,
    )

    const recordsToUpdate = (await Promise.all(recordsToUpdatePromises)).reduce<Record[]>(
      (records, recordsGroup) => [...records, ...recordsGroup],
      [],
    )
    const accountsToUpdate = (await Promise.all(accountsToUpdatePromises)).reduce<Account[]>(
      (accounts, accountsGroup) => [...accounts, ...accountsGroup],
      [],
    )
    const templatesToUpdate = (await Promise.all(templatesToUpdatePromises)).reduce<Template[]>(
      (templates, templatesGroup) => [...templates, ...templatesGroup],
      [],
    )
    const standingOrdersToUpdate = (await Promise.all(standingOrdersToUpdatePromises)).reduce<
      Template[]
    >((templates, templatesGroup) => [...templates, ...templatesGroup], [])

    const updatedRecords = recordsToUpdate.map((record) => ({
      ...record,
      currencyId: futureCurrency._id,
    }))

    const updatedAccounts = accountsToUpdate.map((account) => ({
      ...account,
      currencyId: futureCurrency._id,
    }))

    const updatedTemplates = templatesToUpdate.map((template) => ({
      ...template,
      currencyId: futureCurrency._id,
    }))

    const updatedStandingOrders = standingOrdersToUpdate.map((standingOrder) => ({
      ...standingOrder,
      currencyId: futureCurrency._id,
    }))

    await recordsRepository.updateBulk(updatedRecords)
    await accountsRepository.updateBulk(updatedAccounts)
    await templatesRepository.updateBulk(updatedTemplates)
    await standingOrdersRepository.updateBulk(updatedStandingOrders)

    const currencyIdsToDelete = currencyGroup
      .filter((currency) => currency._id !== futureCurrency._id)
      .map((currency) => currency._id)

    const { errors } = await currenciesRepository.removeBulk(currencyIdsToDelete)

    if (errors.length > 0) {
      logger.captureException(new Error("Error while removing duplicated currencies"))
    }

    // Update data in store
    dispatch(loadDataIntoAppState())
  })
}

function initData() {
  return async (dispatch: Function) => {
    await syncService.initInMemoryDatabase()
    await syncService.initPresistentSQLDatabase()
    await syncService.initInvestmentsCache()

    // TODO: Temporary upgrade to dashboard v2, remove after some time
    await upgradeDashboardToVersion2()

    // Todo: Sanitize broken configure object
    await sanitizeTourGuides()

    await resolveDuplicatedCurrencies(dispatch)

    await Promise.all([dispatch(setInitialFilter()), dispatch(dashBoardActions.initDashboard())])

    return dispatch(loadDataIntoAppState())
  }
}

async function setUpRemoteDatabasePull(user: User) {
  // TODO: user stuck in db replication
  const documents = await syncService.remoteDatabaseReplicationPull(user.replication)
  await syncService.solveConflicts()
  return documents
}

/**
 * active - replicate resumed (e.g. new changes replicating, user went back online)
 * change - handle change
 * paused - replication paused (e.g. replication up to date, user went offline)
 * denied - a document failed to replicate (e.g. due to permissions)
 * complete - handle complete
 * error - handle error
 */
function startRemoteDatabaseSync(user: User) {
  console.log("startRemoteDatabaseSync")
  return (dispatch: Function, getState: Function) => {
    let syncChanges: PouchDB.Replication.SyncResult<{}>[] = []
    if (syncEventEmitter) {
      syncEventEmitter.cancel()
    }

    syncEventEmitter = syncService.startRemoteDatabaseSync(user.replication)

    syncEventEmitter.on("change", (info: PouchDB.Replication.SyncResult<{}>) => {
      console.log("Sync change", info)
      if (info.direction === "pull") {
        syncChanges.push(info)
      }
    })

    syncEventEmitter.on("paused", async (err) => {
      console.log("Sync paused", err)
      console.log("changes", syncChanges)
      if (syncChanges.length > 0) {
        dispatch({
          type: SYNC_CHANGES_INTO_MEMORY_DB_START,
          payload: syncChanges,
        })

        const syncedChanges: SyncedChanges = await syncService.syncChanges([...syncChanges])

        if (syncService.containsUserConfigure(syncedChanges)) {
          await dispatch(userActions.getUser())
          const newUser: User = selectUser(getState())
          if (newUser.userId !== user.userId) {
            return dispatch(logoutHard())
          } else if (!_isEqual(user.replication, newUser.replication)) {
            // Delete all data, CouchDb migration
            await syncService.hardCloseDatabases()
            dispatch(userActions.restoreAppState())
            const result = dispatch(initApp())
            logger.info("User migrated while logged", { userId: user.userId })
            return result
          }
        }

        dispatch({ type: SYNC_CHANGES_INTO_MEMORY_DB_END })
        const syncedModelTypes: string[] = getTypesOfEntities(
          reduceChangesToDocuments([...syncChanges]),
        )
        syncChanges = []
        return dispatch(loadDataIntoAppState(syncedModelTypes))
      }
      return {}
    })

    syncEventEmitter.on("active", () => console.log("Sync active"))
    syncEventEmitter.on("complete", (info) => console.log("Sync complete", info))
    syncEventEmitter.on("denied", (err) => console.log("Sync denied", err))
    syncEventEmitter.on("error", (err) => console.log("Sync error", err))
  }
}

const ReceiveAction = {
  [inMemoryTableNames.ACCOUNT]: accountsActions.receiveAccounts,
  [inMemoryTableNames.CATEGORY]: categoriesActions.receiveCategories,
  [inMemoryTableNames.CURRENCY]: currenciesActions.receiveCurrencies,
  [inMemoryTableNames.LABEL]: labelsActions.receiveLabels,
  [inMemoryTableNames.TEMPLATE]: templatesActions.receiveTemplates,
  [inMemoryTableNames.STANDING_ORDER]: standingOrdersActions.receiveStandingOrders,
  [inMemoryTableNames.REWARD_POINTS]: rewardPointsActions.receiveRewardPoints,
  [inMemoryTableNames.FILTER]: filtersActions.receiveFilters,
  [inMemoryTableNames.CONFIGURE]: applyConfigureObjects,
  [inMemoryTableNames.WEB_CONFIG]: webConfigActions.receiveConfigWeb,
  [inMemoryTableNames.CONTACT]: receiveContacts,
  [inMemoryTableNames.MAGIC_RULE]: receiveMagicRules,
}

function loadDataIntoAppState(strictModelTypes: string[] = []) {
  return async (dispatch: Function) => {
    dispatch({ type: LOVEFIELD_DATA_REQUEST })

    const nonRecordDocumentGroups: NonRecordDocumentGroups = await syncService.fetchNonRecords(
      strictModelTypes,
    )
    const modelTypes: string[] = Object.keys(nonRecordDocumentGroups)

    modelTypes
      .filter((modelType) => !!nonRecordDocumentGroups[modelType])
      .filter((modelType) => !!ReceiveAction[modelType])
      // @ts-ignore
      .forEach((modelType) =>
        dispatch(ReceiveAction[modelType](nonRecordDocumentGroups[modelType])),
      )

    if (_isEmpty(strictModelTypes) || strictModelTypes.includes(inMemoryTableNames.RECORD)) {
      dispatch(expireRecordsStatus())
      dispatch(expireAnalyticsStatus())
      dispatch(expireImportsRecordListStatus())
      dispatch(expireDetailRecordsStatus())
    }

    // Model types that affect dashboard widgets and their change should cause to recalculate it
    const DASHBOARD_WIDGETS_REFRESH_MODEL_TYPES = [
      inMemoryTableNames.RECORD,
      inMemoryTableNames.STANDING_ORDER,
      inMemoryTableNames.ACCOUNT,
      inMemoryTableNames.CURRENCY,
      inMemoryTableNames.CATEGORY,
      inMemoryTableNames.WEB_CONFIG,
    ]

    // Model types that affect balance and their change should cause to recalculate it
    const ACCOUNTS_TOTALS_MODEL_TYPES = [
      inMemoryTableNames.ACCOUNT,
      inMemoryTableNames.CURRENCY,
      inMemoryTableNames.RECORD,
    ]

    if (
      _isEmpty(strictModelTypes) ||
      // TODO: pass model types to fetchDashboardWidgetsWithData and refresh only widgets that need it
      strictModelTypes.some((modelType) =>
        DASHBOARD_WIDGETS_REFRESH_MODEL_TYPES.includes(modelType),
      )
    ) {
      dispatch(expireDashboardStatus())
    }
    if (
      _isEmpty(strictModelTypes) ||
      strictModelTypes.some((modelType) => ACCOUNTS_TOTALS_MODEL_TYPES.includes(modelType))
    ) {
      dispatch(expireAccountsStatus())
      dispatch(expireAnalyticsStatus())
    }
  }
}

// --- Error and Info modals actions ----------------------------------------

export const SHOW_INFO = "errorModals/SHOW_INFO"
export const CLOSE_INFO = "errorModals/CLOSE_INFO"
const DEFAULT_INFO_MESSAGE_ID = "app.info.default_info_message"
export const showInfoModal = (messageId: string, headerId: string = null, dimmer?: boolean) => {
  if (!messageId) {
    console.error(
      `Action '${SHOW_INFO}': messageId not provided,` +
        ` using default info message key: ${DEFAULT_INFO_MESSAGE_ID}.`,
    )
  }

  return {
    type: SHOW_INFO,
    payload: {
      messageId: messageId || DEFAULT_INFO_MESSAGE_ID,
      closeable: true,
      headerId,
      spinner: false,
      dimmer,
    },
  }
}

export function closeInfoModal() {
  return { type: CLOSE_INFO }
}

export function showInfoDimmer(messageId, headerId = null) {
  const defaultModal = showInfoModal(messageId)
  return {
    ...defaultModal,
    payload: {
      ...defaultModal.payload,
      headerId,
      closeable: false,
      spinner: true,
      dimmer: true,
    },
  }
}

export function closeOtherModals() {
  return (dispatch: Function) => {
    dispatch(closeInfoModal())
  }
}

export { closeInfoModal as closeInfoDimmer }

export function showError(errorMessageId?: string | string[], errorRecoverable: boolean = true) {
  return (dispatch: Function) => {
    // Ease transition from happy path to error path and avoid manually closing other modals
    dispatch(closeOtherModals())
    dispatch(showErrorModal(errorMessageId, errorRecoverable))
  }
}

export function setTodayFilters() {
  return (dispatch: Function, getState: GetState) => {
    // Caused weird bug behavior described here: https://budgetbakers.atlassian.net/browse/WEB-325
    // const dashboardFilter = selectDashboardFilter(getState())
    // if (
    //   dashboardFilter &&
    //   isRelativeInterval(dashboardFilter.period.interval) &&
    //   !isToday(dashboardFilter.period.end)
    // ) {
    //   const period = intervalTypeToPeriod(dashboardFilter.period.interval)
    //   dispatch(dashBoardActions.changePeriod(period))
    // }
    //
    // const filter = selectFilter(getState())
    // if (filter && isRelativeInterval(filter.period.interval) && !isToday(filter.period.end)) {
    //   const period = intervalTypeToPeriod(filter.period.interval)
    //   dispatch(changeFilterValues({ period }))
    // }
  }
}
