import React from 'react'
import FormattedAbbreviatedNumber from 'src/frontend/modules/intl/components/FormattedAbbreviatedNumber'
import Gauge from 'src/frontend/scenes/dashboard/components/Gauge/Gauge'
import { BoardPerformanceValues, WidgetView } from 'src/frontend/scenes/dashboard/types'
import Widget from 'src/frontend/scenes/dashboard/components/Widget/Widget'
import styles from './Performance.module.less'
import widgetStyles from 'src/frontend/scenes/dashboard/components/Widget/Widget.module.less'
import _isEmpty from 'lodash/isEmpty'
import { FormattedMessage } from 'src/frontend/modules/intl'
import { widgetHasDeepEqualProps } from 'src/frontend/scenes/dashboard/helpers'

type Props = {
  widget: WidgetView & {
    data: BoardPerformanceValues,
  },
}

function BoardPerformance({ widget }: Props) {
  const { data } = widget || { data: null }
  if (!data || _isEmpty(data)) {
    return <Widget.Placeholder />
  }

  const { revenues, cashFlow, balance } = data
  return (
    <Widget className={widgetStyles.blue}>
      <Widget.Header title={widget.title} />
      <Widget.Content>
        <div className={styles.performanceCard}>
          <div>
            <div className={styles.gauges}>
              <Gauge value={data.revenues.performanceValue} variant="fancy" diameter="100px" />
              <Gauge value={data.cashFlow.performanceValue} variant="fancy" diameter="120px" />
              <Gauge value={data.balance.performanceValue} variant="fancy" diameter="100px" />
            </div>
            <div className={styles.performanceDescription}>
              <div>
                <span><FormattedMessage id="revenues" /></span>
                <span><FormattedAbbreviatedNumber value={revenues.labelValue} /></span>
              </div>

              <div>
                <span><FormattedMessage id="cashFlow" /></span>
                <span><FormattedAbbreviatedNumber value={cashFlow.labelValue} /></span>
              </div>

              <div>
                <span><FormattedMessage id="balance" /></span>
                <span><FormattedAbbreviatedNumber value={balance.labelValue} /></span>
              </div>
            </div>
          </div>
        </div>
      </Widget.Content>
    </Widget>
  )
}

export default React.memo(BoardPerformance, widgetHasDeepEqualProps)
