import React, { useState } from "react"
import { connect } from "react-redux"
import { RootState } from "src/types/State"
import { selectProvidersList } from "src/frontend/scenes/integrations/providersList/selectors"
import { IntegrationProviders } from "src/frontend/scenes/integrations/providersList/types"
import "./LoginCredentials.less"
import {
  submitProviderLogin,
  createOAuthLogin,
  clearConnectionError,
} from "src/frontend/scenes/integrations/newConnection/actions"
import MFAForm from "src/frontend/scenes/integrations/components/MFAForm"
import IntegrationProviderDetail = IntegrationProviders.IntegrationProviderDetail
import { FormAttributeFields } from "src/frontend/scenes/integrations/types"
import { isOAuth } from "src/backend/integrations/helpers"
import {
  selectIntegrationError,
  selectNewConnection,
} from "src/frontend/scenes/integrations/newConnection/selectors"
import { isUndefinedOrNull } from "src/common/utils"
import { Message } from "semantic-ui-react"
import OAuthForm from "src/frontend/scenes/integrations/components/OAuthForm"
import { FormattedMessage } from "src/frontend/modules/intl"

interface Props {
  providerDetail: IntegrationProviderDetail
  showConsent?: boolean
  error: string
  hideProviderLogo?: boolean
  handleSubmitCredentials: (fields: FormAttributeFields) => void
  handleOAuthCallback: (authCallback: {
    loginId: string
    success: boolean
    queryParams?: string
  }) => void
  clearError: Function
}

function mapStateToProps(state: RootState) {
  let providerDetail = selectProvidersList(state).providerDetail
    ? selectProvidersList(state).providerDetail
    : selectNewConnection(state).integrationProvider

  // const { integrationProvider: providerDetail } =

  return {
    providerDetail,
    error: selectIntegrationError(state),
  }
}

const mapDispatchToProps = {
  handleSubmitCredentials: submitProviderLogin,
  handleOAuthCallback: createOAuthLogin,
  clearError: clearConnectionError,
}

LoginCredentials.defaultProps = {
  hideProviderLogo: false,
}

function LoginCredentials({
  providerDetail,
  error,
  clearError,
  hideProviderLogo,
  handleSubmitCredentials,
  handleOAuthCallback,
}: Props) {
  const [imgErrored, setImgErrored] = useState(false)
  const [imgLoaded, setImgLoaded] = useState(false)

  const { params, title } = providerDetail?.formAttributeGroups[0] || {}

  const imgClass = imgLoaded ? " loaded" : ""

  const imgUrl = providerDetail.logoUrl
    ? providerDetail.logoUrl
    : `https://s3.amazonaws.com/bb-integration-bank-icons/${providerDetail.providerIcon}`

  return (
    <div className="integration-credentials-content">
      {!imgErrored && !hideProviderLogo && (
        <div className={`provider-logo${imgClass}`}>
          <img
            /* tslint:disable-next-line:jsx-use-translation-function */
            alt="logo"
            src={imgUrl}
            onError={() => setImgErrored(true)}
            onLoad={() => setImgLoaded(true)}
          />
        </div>
      )}
      {!isOAuth(providerDetail.formAttributeGroups) ? (
        <div>
          {providerDetail.instruction && <p>{providerDetail.instruction}</p>}
          {!isUndefinedOrNull(error) && (
            <Message error>
              <FormattedMessage id={error} />
            </Message>
          )}
          <MFAForm
            key={providerDetail.id}
            showConsent={!error}
            params={params}
            title={title}
            onSubmit={(...args) => {
              clearError()
              handleSubmitCredentials(...args)
            }}
          />
        </div>
      ) : (
        <div>
          {!isUndefinedOrNull(error) && (
            <Message error>
              <FormattedMessage id={error} />
            </Message>
          )}
          <OAuthForm
            params={params}
            onOAuthCallback={(...args) => {
              clearError()
              handleOAuthCallback(...args)
            }}
          />
        </div>
      )}
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginCredentials)
