import React from 'react'
import { HashMap } from 'src/types/common'
import { FormattedMessage } from 'src/frontend/modules/intl'
import styles from 'src/frontend/scenes/dashboard/components/Widget/Widget.module.less'

type Props = {
  title: string,
  values?: HashMap<any>,
  action?: React.ReactNode,
}

Header.defaultProps = {
  values: undefined,
  action: undefined,
}

export default function Header({ title, values, action }: Props) {
  return (
    <div className={styles.title}>
      <h2><FormattedMessage id={title} values={values} /></h2>
      {action}
    </div>
  )
}
