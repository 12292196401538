import React, { SyntheticEvent } from 'react'
import { connect } from 'react-redux'
import './MagicRules.less'
import { FormType } from 'src/frontend/scenes/settings/magicRules/enums'
import RemoveMagicRuleForm from 'src/frontend/scenes/settings/magicRules/components/RemoveMagicRuleForm'
import { formatMessage, FormattedMessage } from 'src/frontend/modules/intl'
import * as selectors from '../selectors'
import * as actions from '../actions'
import { Id } from 'src/types/CouchDb'
import { MagicRulesFormValues, MagicRuleView } from 'src/frontend/scenes/settings/magicRules/types'
import { RecordType } from 'src/backend/enums'
import PremiumChecker from 'src/frontend/components/PremiumChecker/PremiumChecker'
import { CategoryIcon } from 'src/frontend/components/Icons/Icons'
import Labels from 'src/frontend/scenes/records/components/Labels'
import ContactView from 'src/frontend/scenes/records/components/ContactView'
import { isAppBoard } from 'src/common/environment'
import EmptyState from 'src/frontend/scenes/settings/components/EmptyState'
import _isEmpty from 'lodash/isEmpty'
import { PrimaryButton } from 'src/frontend/components/Buttons/Buttons'

const mapStateToProps = (state) => {
  const form = selectors.selectMagicRules(state)
  return {
    magicRules: selectors.selectMagicRulesView(state),
    canEditRules: selectors.selectCanAddEditRule(state),
    formValues: form.formValues,
    formType: form.formType,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    handleOpenEditForm(magicRuleId: Id) {
      dispatch(actions.openEditForm(magicRuleId))
    },
    handleOpenAddForm() {
      dispatch(actions.openAddForm())
    },
  }
}

interface Props {
  magicRules: MagicRuleView[]
  formType: FormType
  canEditRules: boolean
  formValues: MagicRulesFormValues
  handleOpenAddForm: (e: SyntheticEvent) => void
  handleOpenEditForm: (id: Id) => void
}

function MagicRules({
  magicRules,
  canEditRules,
  handleOpenAddForm,
  handleOpenEditForm,
}: Props) {
  return (
    <div className="settings-page">
      <div className="settings-page-add">
        <div className="title">
          <FormattedMessage id="settings.rules.add_new" />
        </div>
        <div className="container">
          <div className="item input">
            <PrimaryButton onClick={handleOpenAddForm}>
              ＋
              &nbsp;
              {formatMessage('form.add')}
            </PrimaryButton>
          </div>
          {/*<div className="item notice">
              <h3>What are triggers and actions?</h3>
              <p>Using rules, you can automatically update your bank transactions.</p>
              <p>
                Trigger fires every time when transactions from your bank with specific keywords in note and type
                appear in Board.
              </p>
              <p>Actions will assign to these transactions selected contact, category or labels.</p>
            </div>*/}
        </div>
      </div>
      <div className="settings-page-list">
        <div className="title">
          <FormattedMessage id="settings.rules.your_rules" />
        </div>
        <div className="rules-row list-header">
          <div className="rules-row-category list-header" />
          <div className="rules-row-name header"><FormattedMessage id="form.name" /></div>
          {isAppBoard() && (
            <div className="rules-row-contact header"><FormattedMessage id="record.form.contact" /></div>
          )}
          <div className="rules-row-keywords header"><FormattedMessage id="form.keywords" /></div>
          <div className="rules-row-record-type header"><FormattedMessage id="settings.rules.recordType" /></div>
          <div className="rules-row-labels header"><FormattedMessage id="record.form.labels" /></div>
          <div className="rules-row-toolbar" />
        </div>
        {!_isEmpty(magicRules)
          ? (
            <RulesList
              showContact={isAppBoard()}
              canEditRules={canEditRules}
              items={magicRules}
              handleOpenForm={handleOpenEditForm}
            />
          ) : (
            <EmptyState
              title={<FormattedMessage id="settings.rules.empty.title" />}
              text={(
                <FormattedMessage
                  id="settings.rules.empty.text"
                  values={{ addButton: `(＋ ${formatMessage('form.add')})` }}
                />
              )}
            />
          )}
      </div>
    </div>
  )
}

interface RulesListProps {
  items: MagicRuleView[]
  canEditRules: boolean
  showContact?: boolean
  handleOpenForm: (id: Id) => void
}

RulesList.defaultProps = {
  showContact: false,
}

export function RulesList({ items, showContact, canEditRules, handleOpenForm }: RulesListProps) {
  return (
    <ul>
      {items.map((rule) => (
          <RuleItem
            showContact={showContact}
            key={rule._id}
            canEditRules={canEditRules}
            rule={rule}
            onEditClick={handleOpenForm}
          />
        ),
      )}
    </ul>
  )
}

interface RuleItemProps {
  rule: MagicRuleView,
  canEditRules: boolean
  showContact?: boolean
  onEditClick: (id: Id) => void
}

RuleItem.defaultProps = {
  showContact: false,
}

function RuleItem({ rule, showContact, canEditRules, onEditClick }: RuleItemProps) {
  return (
    <li className="rules-row">
      <div className="rules-row-category">
        {rule.categoryView && (
          <CategoryIcon icon={rule.categoryView.categoryIcon} color={rule.categoryView.color} />
        )}
      </div>
      <div className="rules-row-name">
        <span>{rule.name}</span>
      </div>
      {showContact && (
        <div className="rules-row-contact">
          {rule.contactView && <ContactView contact={rule.contactView} compact />}
        </div>
      )}
      <div className="rules-row-keywords">
        <span>{rule.keywords && rule.keywords.join(', ')}</span>
      </div>
      <div className="rules-row-record-type">
        <span>{formatMessage(getRecordTypeString(rule.recordType))}</span>
      </div>
      <div className="rules-row-labels">
        {rule.labelsView && <Labels labelsView={rule.labelsView} />}
      </div>
      <div className="rules-row-toolbar">
        <div>
          <PremiumChecker featureName="premium_checker.automatic_rules" enabled={canEditRules}>
            <span
              onClick={() => onEditClick(rule._id)}
            >
              <FormattedMessage id="edit" />
            </span>
          </PremiumChecker>
          <span className="delete"><RemoveMagicRuleForm magicRule={rule} /></span>
        </div>
      </div>
    </li>
  )
}

function getRecordTypeString(recordType: RecordType): string | { W?: string, B?: string } {
  if (recordType === RecordType.EXPENSE) {
    return { W: 'expense', B: 'money-spent' }
  }
  if (recordType === RecordType.INCOME) {
    return { W: 'income', B: 'money-received' }
  }
  return 'record_type.any'
}

export default connect(mapStateToProps, mapDispatchToProps)(MagicRules)
