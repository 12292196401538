import { receiveDashboardWidget } from 'src/frontend/scenes/dashboard/actions'
import { selectDashboardFilter, selectDashboardWidgets } from 'src/frontend/scenes/dashboard/selectors'
import { WidgetType } from 'src/frontend/scenes/dashboard/types'
import { FilterType } from 'src/types/Filter'
import { Id } from 'src/types/CouchDb'
import { findWidgetById } from 'src/frontend/scenes/dashboard/helpers'
import { RelativeIntervalType } from 'src/backend/enums'
import { getNextPeriodFilter } from 'src/backend/filters/helpers'
import { isRelativeInterval } from 'src/backend/time/time'
import { fetchBoardCashFlowTrendChartData } from 'src/backend/dashboard/board/service'
import { HashMap } from 'src/types/common'

export function changeBoardCashFlowSubject(id: Id, cumulative: boolean) {
  return async (dispatch: Function, getState: Function) => {
    const dashboardFilter: FilterType = selectDashboardFilter(getState())
    const widgets: HashMap<WidgetType> = selectDashboardWidgets(getState())

    const cashFlowTrendWidget = findWidgetById(widgets, id)

    if (!cashFlowTrendWidget) {
      return null
    }

    const mergedFilter = { ...dashboardFilter, ...cashFlowTrendWidget.filter }
    const filter = isRelativeInterval(mergedFilter.period.interval)
      && cashFlowTrendWidget.options.relativeIntervalValue === RelativeIntervalType.NEXT
        ? getNextPeriodFilter(mergedFilter)
        : mergedFilter

    const options = {
      ...cashFlowTrendWidget.options,
      cumulative,
    }

    const data = await fetchBoardCashFlowTrendChartData(filter, options)

    const updatedWidget = {
      ...cashFlowTrendWidget,
      data,
      options,
    }

    return dispatch(receiveDashboardWidget(updatedWidget))
  }
}
