import { Divider, Form, Message } from 'semantic-ui-react'
import { FormattedMessage, FormattedMessageLabel } from 'src/frontend/modules/intl'
import React from 'react'
import { FormikProps } from 'formik'
import { PrimaryButton } from 'src/frontend/components/Buttons/Buttons'
import { EventCallback } from 'src/types/Semantic-ui'

export interface AddVoucherFormData {
  voucherCode: string,
}

interface Props extends FormikProps<AddVoucherFormData> {
  handleCancel: EventCallback,
}

export function AddVoucherForm({
  values,
  touched,
  errors,
  isSubmitting,
  handleChange,
  handleSubmit,
  handleCancel,
}: Props) {

  return (
    <Form error>
      <Form.Input
        autoFocus
        name="voucherCode"
        label={<FormattedMessageLabel id="modals.add_voucher.voucher_code" />}
        value={values.voucherCode}
        onChange={handleChange}
      />

      {touched.voucherCode && errors.voucherCode && (
        <Message
          className="error-message"
          error
          content={<FormattedMessage id={errors.voucherCode} />}
        />
      )}

      <Divider hidden section />

      <PrimaryButton
        type="submit"
        disabled={isSubmitting}
        loading={isSubmitting}
        fluid
        onClick={handleSubmit}
      >
        <FormattedMessage id="form.apply" />
      </PrimaryButton>
    </Form>
  )
}
