import * as React from "react"
import { connect } from "react-redux"
import {
  CheckboxProps,
  Form,
  Icon,
  InputOnChangeData,
  Label,
  Message,
  Radio,
} from "semantic-ui-react"
import { NAME_MAX_LENGTH } from "src/backend/common/validator"
import {
  AccountType,
  BalanceDisplayType,
  ManualAccountTypes,
  SyncedAccountTypes,
} from "src/backend/enums"
import ColorPicker from "src/frontend/components/ColorPicker/ColorPicker"
import IconOption from "src/frontend/components/IconOption"
import { AccountIcon } from "src/frontend/components/Icons/Icons"
import InfoPopup from "src/frontend/components/InfoPopup"
import FormattedMessageLabelWithDescription from "src/frontend/modules/intl/components/FormattedMessageLabelWithDescription"
import DeactivateImportsModal from "src/frontend/scenes/imports/components/DeactivateImportsModal"
import { getColors } from "src/backend/colors/colors"
import { isConnected, isImportsActivated } from "src/backend/accounts/helpers"
import { formatMessage, FormattedMessage, FormattedMessageLabel } from "src/frontend/modules/intl"
import * as currenciesSelectors from "src/frontend/modules/currencies/selectors"
import { selectIsDeactivateImportsModalOpen } from "src/frontend/scenes/imports/selectors"
import { AccountFormValues } from "src/frontend/scenes/settings/accounts/types"
import { Account } from "src/types/Account"
import { Currency } from "src/types/Currency"
import * as selectors from "../selectors"
import * as actions from "../actions"
import * as importsActions from "src/frontend/scenes/imports/actions"
import "./AccountForm.less"
import _range from "lodash/range"
import { Id } from "src/types/CouchDb"
import { FormType } from "src/frontend/scenes/settings/enums"
import { RootState } from "src/types/State"
import { disconnectIntegratedAccount } from "src/frontend/scenes/integrations/connections/actions"
import {
  selectDisconnectAccountLoading,
  selectDisconnectLoginId,
} from "src/frontend/scenes/integrations/connections/selectors"
import { HashMap } from "src/types/common"
import PremiumChecker from "src/frontend/components/PremiumChecker/PremiumChecker"
import { ComplementaryButton, PrimaryButton } from "src/frontend/components/Buttons/Buttons"
import { AccountFormType } from "src/frontend/scenes/settings/accounts/enums"

const mapStateToProps = (state: RootState): StateProps => {
  const form = selectors.selectAccountsForm(state)

  return {
    currencies: currenciesSelectors.selectSortedCurrencies(state),
    formType: form.formType,
    formValues: form.formValues as AccountFormValues,
    colorPickerOpen: form.colorPicker,
    errors: form.errors,
    archiveDisabled: form.archiveDisabled,
    account: form.account,
    loading: form.loading,
    accountFormType: form.accountFormType,
    disconnectAccountLoading: selectDisconnectAccountLoading(state),
    disconnectLoginId: selectDisconnectLoginId(state),
    deactivateImportsModalOpen: selectIsDeactivateImportsModalOpen(state),
    canAddAccount: selectors.selectCanAddAccount(state),
  }
}

const mapDispatchToProps = (dispatch: Function): Actions => {
  return {
    handleInitAmountChange: (_event, field) =>
      dispatch(actions.changeInitAmountValue(field.name, field.value)),
    handleFieldChange: (_event, field) =>
      dispatch(actions.changeFormFieldValue(field.name, field.value)),
    handleCreditCardFieldChange: (_event, field) => {
      return dispatch(actions.changeCreditCardFormFieldValue(field.name, field.value))
    },
    handleRadioChange: (_event, field) =>
      dispatch(actions.changeFormFieldValue(field.name, field.checked)),
    handleAutomaticImportToggle: (_event, { checked }) =>
      dispatch(actions.changeAutomaticImport(checked)),
    handleConfirmDeactivateImports: (importsSettingsId) => {
      return dispatch(importsActions.confirmDeactivateImports(importsSettingsId))
    },
    handleSaveAccount: (_event, formValues, formType, accountId) => {
      return dispatch(actions.saveAccount(formValues, formType, accountId))
    },
    handleOpenColorPicker: () => dispatch(actions.openColorPicker()),
    handleCloseColorPicker: () => dispatch(actions.closeColorPicker()),
    handleColorPick: (color) => {
      dispatch(actions.changeFormFieldValue("color", color.hex))
      dispatch(actions.closeColorPicker())
    },
    handleConnectToBank: (accountId: Id) => dispatch(actions.openConnectBankForm(accountId)),
    handleDisconnectBank: (account: Account) => {
      const { loginId, integrationSource, remoteAccountId } = account.reservedIntegrationConnection
      dispatch(
        disconnectIntegratedAccount(loginId, remoteAccountId, account._id, integrationSource),
      )
    },
  }
}

type Actions = {
  handleInitAmountChange: (e: React.SyntheticEvent, data: InputOnChangeData) => void
  handleFieldChange: (e: React.SyntheticEvent, data: InputOnChangeData) => void
  handleCreditCardFieldChange: (e: React.SyntheticEvent, field: any) => void
  handleRadioChange: (e: React.SyntheticEvent, data: CheckboxProps) => void
  handleAutomaticImportToggle: (e: React.SyntheticEvent, data: CheckboxProps) => void
  handleConfirmDeactivateImports: Function
  handleSaveAccount: (
    e: React.SyntheticEvent,
    formValues: AccountFormValues,
    formType: string,
    accountId: Id,
  ) => void
  handleOpenColorPicker: (e: React.SyntheticEvent) => void
  handleCloseColorPicker: (e: React.SyntheticEvent) => void
  handleColorPick: (color: { hex: string }) => void
  handleConnectToBank: Function
  handleDisconnectBank: Function
}

type StateProps = {
  currencies: Array<Currency>
  errors: HashMap<string>
  account: Account
  colorPickerOpen: boolean
  archiveDisabled: boolean
  deactivateImportsModalOpen: boolean
  formType: string
  loading: boolean
  disconnectAccountLoading: boolean
  disconnectLoginId: string
  formValues: AccountFormValues
  canAddAccount: boolean
  accountFormType: AccountFormType
}

function AccountForm({
  currencies,
  formValues,
  errors,
  account,
  archiveDisabled,
  formType,
  colorPickerOpen,
  accountFormType,
  loading,
  canAddAccount,
  disconnectAccountLoading,
  disconnectLoginId,
  deactivateImportsModalOpen,
  handleOpenColorPicker,
  handleCloseColorPicker,
  handleColorPick,
  handleFieldChange,
  handleCreditCardFieldChange,
  handleInitAmountChange,
  handleRadioChange,
  handleAutomaticImportToggle,
  handleConfirmDeactivateImports,
  handleSaveAccount,
  handleConnectToBank,
  handleDisconnectBank,
}: StateProps & Actions) {
  const accountTypes: AccountType[] = isConnected(account) ? SyncedAccountTypes : ManualAccountTypes
  const accountTypeOptions = accountTypes.map((accountType: AccountType) => {
    return {
      key: accountType,
      value: accountType,
      text: (
        <IconOption
          icon={
            <AccountIcon
              accountType={accountType}
              color="#cccccc"
              size="1.8rem"
            />
          }
          label={formatMessage(`account.type.${accountType}`)}
        />
      ),
      content: (
        <IconOption
          icon={
            <AccountIcon
              accountType={accountType}
              color="#cccccc"
              size="1.8rem"
            />
          }
          label={formatMessage(`account.type.${accountType}`)}
        />
      ),
    }
  })

  const colorPickerTrigger = (
    <div className="ui selection dropdown">
      <div
        className="color-inner"
        style={{ background: formValues.color }}
      />
      <Icon
        name="dropdown"
        as="i"
      />
    </div>
  )
  const nameAndColorPickerRow = (
    <div className="field">
      <Form.Group>
        <Form.Input
          label={<FormattedMessageLabel id="form.name" />}
          name="name"
          defaultValue={formValues.name}
          placeholder={formatMessage("settings.accounts.form.name_placeholder")}
          width="11"
          maxLength={NAME_MAX_LENGTH}
          autoFocus
          error={!!errors.name}
          onChange={handleFieldChange}
        />
        <div className="five wide field small-select">
          <label>
            <FormattedMessage id="form.color" />
          </label>
          <ColorPicker
            colors={getColors()}
            color={formValues.color}
            trigger={colorPickerTrigger}
            position="bottom right"
            open={colorPickerOpen}
            onOpen={handleOpenColorPicker}
            onClose={handleCloseColorPicker}
            onSelect={handleColorPick}
          />
        </div>
      </Form.Group>
      {errors.name && (
        <Message
          className="error-message"
          error
          content={<FormattedMessage id={errors.name} />}
        />
      )}
    </div>
  )

  const currencyOptions = currencies.map((currency: Currency): { text: string; value: string } => {
    return { text: currency.code, value: currency._id }
  })

  const accountTypeRow = (
    <div className="field">
      <Form.Group>
        <Form.Select
          className="icon-select"
          label={<FormattedMessageLabel id="settings.accounts.form.accountType" />}
          name="accountType"
          width="16"
          disabled={isImportsActivated(account) || accountFormType === AccountFormType.IMPORTS}
          defaultValue={formValues.accountType}
          options={accountTypeOptions}
          onChange={handleFieldChange}
        />
      </Form.Group>
    </div>
  )

  type DueDayOption = { text: number; value: number }

  const dueDayOptions: Array<DueDayOption> = [
    {
      text: formatMessage("form.dropdown.not-set"),
      value: 0,
    },
    ..._range(1, 32).map((dayNumber: number): DueDayOption => {
      return {
        text: dayNumber,
        value: dayNumber,
      }
    }),
  ]

  const creditCardRow = [AccountType.CREDIT_CARD, AccountType.OVERDRAFT].includes(
    formValues.accountType,
  ) && (
    <div>
      <div className="field">
        <Form.Group>
          <Form.Input
            label={
              <FormattedMessageLabel
                id={
                  formValues.accountType === AccountType.CREDIT_CARD
                    ? "settings.accounts.form.credit-limit"
                    : "settings.accounts.form.overdraft-limit"
                }
              />
            }
            name="limit"
            defaultValue={formValues.creditCard.limit}
            width={11}
            error={!!errors.limit}
            onChange={handleCreditCardFieldChange}
          />
          <Form.Select
            className="small-select"
            label={<FormattedMessageLabel id="settings.accounts.form.due-day" />}
            name="dueDay"
            options={dueDayOptions}
            defaultValue={formValues.creditCard.dueDay}
            width={5}
            onChange={handleCreditCardFieldChange}
          />
        </Form.Group>
        {errors.limit && (
          <Message
            className="error-message"
            error
            content={<FormattedMessage id={errors.limit} />}
          />
        )}
      </div>
      <div className="field">
        <ul className="radio-select">
          <li>
            <Radio
              name="balanceDisplayOption"
              value={BalanceDisplayType.CREDIT_BALANCE}
              checked={
                formValues.creditCard.balanceDisplayOption === BalanceDisplayType.CREDIT_BALANCE
              }
              label={
                <FormattedMessageLabelWithDescription
                  id={`settings.accounts.form.${
                    formValues.accountType === AccountType.CREDIT_CARD ? "credit" : "actual"
                  }-balance`}
                />
              }
              onChange={handleCreditCardFieldChange}
            />
          </li>
          <li>
            <Radio
              name="balanceDisplayOption"
              value={BalanceDisplayType.AVAILABLE_CREDIT}
              checked={
                formValues.creditCard.balanceDisplayOption === BalanceDisplayType.AVAILABLE_CREDIT
              }
              label={
                <FormattedMessageLabelWithDescription
                  id={`settings.accounts.form.available-${
                    formValues.accountType === AccountType.CREDIT_CARD ? "credit" : "balance"
                  }`}
                />
              }
              onChange={handleCreditCardFieldChange}
            />
          </li>
        </ul>
      </div>
    </div>
  )

  const initAmountRow = !isConnected(account) && (
    <div className="field">
      <Form.Group>
        <Form.Input
          label={<FormattedMessageLabel id="settings.accounts.form.starting_amout" />}
          name="initAmount"
          defaultValue={formValues.initAmount}
          width="11"
          error={!!errors.initAmount}
          onChange={handleInitAmountChange}
        />
        <Form.Select
          className="small-select"
          label={<FormattedMessageLabel id="record.form.currency" />}
          name="currencyId"
          disabled={formType === FormType.EDIT}
          options={currencyOptions}
          defaultValue={formValues.currencyId}
          width="5"
          onChange={handleFieldChange}
        />
      </Form.Group>
      {errors.initAmount && (
        <Message
          className="error-message"
          error
          content={<FormattedMessage id={errors.initAmount} />}
        />
      )}
    </div>
  )

  const excludeFromStatsRow = (
    <div className="field">
      <Form.Group>
        <Radio
          name="excludeFromStats"
          toggle
          defaultChecked={formValues.excludeFromStats}
          label={<FormattedMessageLabel id="settings.accounts.form.exclude" />}
          onChange={handleRadioChange}
        />
        <InfoPopup message={<FormattedMessage id="settings.accounts.form.exclude-popup" />} />
      </Form.Group>
    </div>
  )

  const archiveRow = formType === FormType.EDIT && (
    <div className="field">
      <Form.Group>
        <Radio
          name="archived"
          toggle
          disabled={archiveDisabled}
          defaultChecked={formValues.archived}
          label={<FormattedMessageLabel id="archive" />}
          onChange={handleRadioChange}
        />
        <InfoPopup message={<FormattedMessage id="settings.accounts.form.archive.popup" />} />
      </Form.Group>
    </div>
  )

  const importsRow = isImportsActivated(account) && formType === FormType.EDIT && (
    <div className="field">
      {deactivateImportsModalOpen && <DeactivateImportsModal />}
      <div className="notice">
        <FormattedMessage id="imports.page_title" />
        <div>
          <Label color="blue">{formValues.importEmail}</Label>
          <InfoPopup
            message={<FormattedMessage id="settings.accounts.form.imports_email_popup" />}
          />
        </div>
        <Radio
          name="importIsAutomatic"
          toggle
          checked={formValues.importIsAutomatic}
          label={<FormattedMessageLabel id="settings.accounts.form.automatic_imports" />}
          onChange={handleAutomaticImportToggle}
        />
        <InfoPopup
          message={<FormattedMessage id="settings.accounts.form.automatic_imports_popup" />}
        />
        <ComplementaryButton
          color="red"
          className="deactivate-imports-button"
          onClick={() => {
            handleConfirmDeactivateImports(formValues.importSettingsId)
          }}
        >
          <FormattedMessage id="imports.deactivate.title" />
        </ComplementaryButton>
      </div>
    </div>
  )

  const bankConnectRow = !account?.investmentInfo &&
    !isConnected(account) &&
    !isImportsActivated(account) &&
    account &&
    account.accountType !== AccountType.CASH &&
    formType === FormType.EDIT && (
      <ComplementaryButton
        className="connect-button"
        onClick={() => handleConnectToBank(account._id)}
      >
        <FormattedMessage id="integrations.newConnection.existing-account.action" />
      </ComplementaryButton>
    )

  const bankDisconnectRow = !account?.investmentInfo && isConnected(account) && (
    <ComplementaryButton
      onClick={() => handleDisconnectBank(account)}
      disabled={
        disconnectLoginId === account.reservedIntegrationConnection.loginId &&
        disconnectAccountLoading
      }
      loading={
        disconnectLoginId === account.reservedIntegrationConnection.loginId &&
        disconnectAccountLoading
      }
    >
      <FormattedMessage
        id="integrations.connections.disconnect-action"
        values={{ providerName: account.reservedIntegrationConnection.remoteProviderName }}
      />
    </ComplementaryButton>
  )

  const SaveButton = ({
    children,
    isLoading,
  }: {
    children: React.ReactNode
    isLoading: boolean
  }) => (
    <div className="field submit">
      <PremiumChecker
        enabled={canAddAccount}
        featureName="premium_checker.unlimited_accounts"
      >
        <PrimaryButton
          type="submit"
          loading={isLoading}
          disabled={isLoading}
          fluid
          onClick={(e) => handleSaveAccount(e, formValues, formType, account && account._id)}
        >
          {children}
        </PrimaryButton>
      </PremiumChecker>
    </div>
  )

  return (
    <div className="add-account-form">
      <div className="content-panel">
        <Form error>
          {nameAndColorPickerRow}
          {accountTypeRow}
          {creditCardRow}
          {initAmountRow}
          {excludeFromStatsRow}
          {archiveRow}
          {importsRow}
          <div className="connect">
            {bankConnectRow}
            {bankDisconnectRow}
          </div>
          <SaveButton isLoading={loading}>
            <FormattedMessage id="form.save" />
          </SaveButton>
        </Form>
      </div>
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountForm)
