import { createSelector } from 'reselect'
import { sortEntities } from 'src/backend/common/helpers'
import { pipe } from 'ramda'
import { HashMap } from 'src/types/common'
import { Currency } from 'src/types/Currency'
import { RootState } from 'src/types/State'
import { selectModules } from 'src/frontend/modules/selectors'

export const selectCurrencies = (state: RootState) => selectModules(state).currencies

export const selectSortedCurrencies: (state: RootState) => Currency[] = createSelector(
  selectCurrencies,
  pipe(sortEntities),
)

export const selectReferentialCurrency: (state: RootState) => Currency = createSelector(
  selectCurrencies,
  getReferentialCurrency,
)

function getReferentialCurrency(currencies: HashMap<Currency>) {
  const referentialCurrencies = Object.keys(currencies)
    .map(currencyId => currencies[currencyId])
    .filter(currency => currency.referential)

  if (referentialCurrencies.length >= 1) {
    if (referentialCurrencies.length > 1) {
      console.log('There is more than one referential currency which is probably caused by data inconsistency. Taking the first one...')
    }
    return referentialCurrencies[0]
  }

  return undefined
}
