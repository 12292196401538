import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Modal, Transition } from 'semantic-ui-react'
import * as actions from 'src/frontend/scenes/records/recordForm/actions'
import * as selectors from 'src/frontend/scenes/records/recordForm/selectors'
import './RecordForm.less'
import 'src/frontend/scenes/records/components/DateTimePickerWithRepeat.less'
import { RootState } from 'src/types/State'
import _isEmpty from 'lodash/isEmpty'
import _debounce from 'lodash/debounce'
import { HashMap } from 'src/types/common'
import RecordFormModalContent from 'src/frontend/scenes/records/recordForm/components/RecordFormModalContent'


const mapStateToProps = (state: RootState) => {
  const { open, errors } = selectors.selectRecordForm(state)
  return {
    open,
    errors,
  }
}

const mapDispatchToProps = {
  handleCloseForm: actions.closeForm,
}

interface Props {
  errors: HashMap<string>,
  open: boolean,
  handleCloseForm: (SyntheticEvent) => void,
}

function RecordFormModal({
  open,
  errors,
  handleCloseForm,
}: Props) {
  const [isError, setIsError] = useState(false)
  const resetIsError = _debounce(() => setIsError(false), 1000)

  useEffect(() => {
    if (!_isEmpty(errors)) {
      setIsError(true)
      resetIsError()
    }

    return () => {
      resetIsError.cancel()
      setIsError(false)
    }

  }, [errors])

  const errorClass = isError ? 'error-animate' : ''


  return (
    <Transition
      directional
      visible={open}
      unmountOnHide
      onHide={handleCloseForm}
      duration={{ show: 250, hide: 0 }}
      animation="modal-animation"
    >
      <Modal
        className={`add-record-form-window ${errorClass}`}
        open
        onClose={handleCloseForm}
      >
        <RecordFormModalContent />
      </Modal>
    </Transition>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(RecordFormModal)
