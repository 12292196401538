import React from 'react'
import styles from 'src/frontend/scenes/billing/product/components/PlanList.module.less'
import { Loader } from 'semantic-ui-react'
import { Id } from 'src/types/CouchDb'
import { Billing } from 'src/types/Billing'
import BraintreeProduct = Billing.BraintreeProduct
import { isUndefinedOrNull, mergeClasses } from 'src/common/utils'
import { formatMessage, FormattedMessage, FormattedMoney } from 'src/frontend/modules/intl'
import PeriodType = Billing.PeriodType
import {
  getMonthYearDifference,
  getPlanLabel,
  isRecurringProduct,
} from 'src/frontend/scenes/billing/product/helpers'
import FormattedPercentage from 'src/frontend/modules/intl/components/FormattedPercentage'

interface Props {
  loading: boolean,
  products: BraintreeProduct[]
  error: string

  onSelectProduct: (productId: Id) => void
}

const NUM_PRODUCTS_CLASS = {
  2: styles.twoProducts,
  3: styles.threeProducts,
}

export default function PlanList({ products, error, loading, onSelectProduct }: Props) {
  const monthYearRatio = getMonthYearDifference(products)

  return (
    <div className={styles.planList}>
      <div className={styles.title}>
        <FormattedMessage id="billing.choose-plan" />
      </div>
      {!isUndefinedOrNull(error) && (
        <div className={styles.error}>
          <FormattedMessage id={error} />
        </div>
      )}
      <div
        className={mergeClasses(
          styles.products,
          NUM_PRODUCTS_CLASS?.[products?.length],
          loading && styles.loading,
        )}
      >
        {!loading
          ? products?.map((productObject) => (
              <div
                className={styles.product}
                key={productObject.product.productId}
                style={{ width: `calc(${100 / products.length}% - 1rem)` }}
                onClick={() => onSelectProduct(productObject.product.productId)}
              >
                {productObject.product.period === Billing.PeriodType.YEAR && !isUndefinedOrNull(monthYearRatio) && (
                  <div className={styles.discount}>
                    <div className={styles.save}><FormattedMessage id="billing.subscription.plan.save-percent" /></div>
                    <div className={styles.amount}>
                      <FormattedPercentage value={monthYearRatio} />
                    </div>
                  </div>
                )}
                <div className={styles.productContent}>
                  <div className={styles.periodName}>
                    {getPlanLabel(productObject.product)}
                  </div>
                  <div className={styles.price}>
                    <FormattedMoney
                      value={productObject.price * 100}
                      currency={productObject.currencyCode}
                    />
                  </div>
                  <div className={styles.frequency}>
                    {isRecurringProduct(productObject)
                      ? (
                        <FormattedMessage
                          id="billing.subscription.billed-frequency"
                          values={{
                            frequency: formatMessage(`billing.subscription.billed-frequency.${PeriodType[productObject.product.period]}`),
                          }}
                        />
                      )
                      : <FormattedMessage id="billing.subscription.pay-once" />}
                  </div>
                </div>
              </div>
            ),
          )
          : <Loader active inline />}
      </div>
      {/*<div className={styles.trial}>Try 14 days free</div>*/}
    </div>
  )
}
