import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import {
  Modal,
  ModalActions,
  ModalContent,
  ModalHeader,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableRow,
} from "semantic-ui-react"
import {
  selectIsPreviewOpen,
  selectPreviewHeaders,
  selectPreviewData,
  selectIsMappingError,
  selectIsMappingSaving,
} from "src/frontend/scenes/imports/mapping/selectors"
import * as actions from "src/frontend/scenes/imports/mapping/actions"
import { formatAttributeName } from "src/frontend/scenes/imports/mapping/helpers"
import "./MappingPreviewPanel.less"
import { FormattedMessage } from "src/frontend/modules/intl"
import { PrimaryButton, SecondaryButton } from "src/frontend/components/Buttons/Buttons"

const mapStateToProps = (state) => ({
  open: selectIsPreviewOpen(state),
  hasErrors: selectIsMappingError(state),
  previewHeaders: selectPreviewHeaders(state),
  previewRows: selectPreviewData(state),
  loading: selectIsMappingSaving(state),
})

const mapDispatchToProps = {
  importRecords: actions.saveMapping,
  closePreview: actions.closePreview,
}

const COLUMNS_PER_LARGE = 4

function MappingPreviewPanel({
  open,
  loading,
  hasErrors,
  previewHeaders,
  previewRows,
  importRecords,
  closePreview,
}) {
  return (
    open && (
      <Modal
        open={open}
        size={previewRows[0].length > COLUMNS_PER_LARGE ? "fullscreen" : "large"}
        dimmer
        className="mapping-preview"
      >
        <ModalHeader>
          <FormattedMessage id="imports.mapping.preview.title" />
          <span
            className="modal-close"
            onClick={closePreview}
          />
        </ModalHeader>
        <ModalContent scrolling>
          <Table className="mapping-table">
            <TableHeader>
              <TableRow>
                {previewHeaders.map((attributeType) => (
                  <TableHeaderCell key={attributeType}>
                    {formatAttributeName(attributeType)}
                  </TableHeaderCell>
                ))}
              </TableRow>
            </TableHeader>
            <TableBody>
              {previewRows.map((row, rowIndex) => (
                // eslint-disable-next-line react/no-array-index-key
                <TableRow key={rowIndex}>
                  {row.map((cell, cellIndex) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <TableCell key={cellIndex}>{cell}</TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </ModalContent>
        <ModalActions className="buttons">
          <div>
            <SecondaryButton
              color="red"
              disabled={loading}
              onClick={closePreview}
            >
              <FormattedMessage id="imports.mapping.cancel_import_button" />
            </SecondaryButton>
          </div>
          <div>
            {!hasErrors && (
              <PrimaryButton
                disabled={loading}
                loading={loading}
                onClick={importRecords}
              >
                <FormattedMessage id="imports.mapping.import_button" />
              </PrimaryButton>
            )}
          </div>
        </ModalActions>
      </Modal>
    )
  )
}

MappingPreviewPanel.propTypes = {
  open: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  hasErrors: PropTypes.bool.isRequired,
  previewHeaders: PropTypes.arrayOf(PropTypes.number.isRequired).isRequired,
  previewRows: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)).isRequired,
  importRecords: PropTypes.func.isRequired,
  closePreview: PropTypes.func.isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(MappingPreviewPanel)
