import { Platform } from 'src/backend/enums'
import { formatMessage } from 'src/frontend/modules/intl/i18n'
import { FilterDocument } from 'src/types/Filter'
import uuid from 'uuid/v4'


export function filterDocument(): FilterDocument {
  const MODEL_TYPE = 'Filter'
  const _id = `-${MODEL_TYPE}_${uuid()}`
  return {
    _id,
    reservedModelType: MODEL_TYPE,
    name: formatMessage('filter.default-name'),
    categories: null,
    envelopes: null,
    currencies: null,
    payment_types: null,
    record_type: 0,
    record_states: null,
    description_tokens: null,
    transfers: 0,
    debts: 0,
    standing_orders: null,
    labels: null,
    position: 0,
    accounts: null,
    minAmount: null,
    maxAmount: null,
    reservedSource: Platform.WEB,
    reservedAuthorId: '',
    reservedOwnerId: '',
    reservedCreatedAt: '',
    reservedUpdatedAt: '',
  }
}
