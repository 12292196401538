import PropTypes from "prop-types"
import React from "react"
import { connect } from "react-redux"
import { Icon, Popup, Table } from "semantic-ui-react"
import {
  selectAssignedColumnsIndexes,
  selectAttributeMappingError,
  selectColumnHeaders,
  selectColumns,
  selectFirstRecordRowPosition,
  selectGeneratedColumnHeaders,
  selectHeaderRowPosition,
  selectImportingValues,
  selectLastRecordRowPosition,
  selectRowHeaderProperties,
  selectTableErrors,
} from "src/frontend/scenes/imports/mapping/selectors"
import ErrorMessage from "src/frontend/scenes/imports/mapping/components/ErrorMessage"
import { selectSelectedAccountCurrencyCode } from "src/frontend/scenes/imports/account/selectors"
import { MappingError } from "src/frontend/scenes/imports/mapping/components/commonProptypes"
import { getColumnErrorType, hasColumnError } from "src/frontend/scenes/imports/mapping/helpers"
import "./MappingAssignPanel.less"

const isRowDimmed = (rowIndex, firstRecordRowPosition, lastRecordRowPosition) =>
  firstRecordRowPosition > rowIndex + 1 || lastRecordRowPosition <= rowIndex

const isHeaderRow = (rowIndex, hasHeader, headerRowPosition) =>
  hasHeader && rowIndex + 1 === headerRowPosition

const getRowClassName = (
  rowIndex,
  firstRecordRowPosition,
  lastRecordRowPosition,
  hasHeader,
  headerRowPosition,
) => {
  if (isHeaderRow(rowIndex, hasHeader, headerRowPosition)) {
    return "header-row"
  } else if (isRowDimmed(rowIndex, firstRecordRowPosition, lastRecordRowPosition)) {
    return "dimmed"
  } else {
    return ""
  }
}

const ErrorIcon = () => (
  <Icon
    name="warning circle"
    className="error"
  />
)

const mapStateToProps = (state) => ({
  rowValues: selectImportingValues(state),
  tableErrors: selectTableErrors(state),
  generatedColumnHeaders: selectGeneratedColumnHeaders(state),
  columnHeaders: selectColumnHeaders(state),
  hasHeader: selectRowHeaderProperties(state).hasHeader,
  headerRowPosition: selectHeaderRowPosition(state),
  firstRecordRowPosition: selectFirstRecordRowPosition(state),
  lastRecordRowPosition: selectLastRecordRowPosition(state),
  assignedColumnIndexes: selectAssignedColumnsIndexes(state),
  attributeMappingError: selectAttributeMappingError(state),
  assignedColumns: selectColumns(state),
  accountCurrencyCode: selectSelectedAccountCurrencyCode(state),
})

const MappingAssignPanel = ({
  assignedColumnIndexes,
  assignedColumns,
  attributeMappingError,
  columnHeaders,
  firstRecordRowPosition,
  generatedColumnHeaders,
  hasHeader,
  headerRowPosition,
  lastRecordRowPosition,
  rowValues,
  tableErrors,
  accountCurrencyCode,
}) => {
  console.log("rowValues", rowValues)

  return (
    <div className="scroll-wrapper">
      <Table
        basic="very"
        className="mapping-table"
      >
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell />
            {generatedColumnHeaders.map((column) => (
              <Table.HeaderCell key={column.colIndex}>
                {hasColumnError(column.colIndex, tableErrors) ? (
                  <Popup
                    on="click"
                    hideOnScroll
                    content={
                      <ErrorMessage
                        columns={assignedColumns}
                        headers={columnHeaders}
                        errorLine={attributeMappingError.line}
                        errorColumn={column.colIndex}
                        errorType={getColumnErrorType(column.colIndex, tableErrors)}
                        accountCurrencyCode={accountCurrencyCode}
                      />
                    }
                    trigger={
                      <span className="error">
                        {column.colName}
                        &nbsp;
                        <ErrorIcon />
                      </span>
                    }
                  />
                ) : (
                  column.colName
                )}
              </Table.HeaderCell>
            ))}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {rowValues.map((row, rowIndex) => (
            <Table.Row
              // eslint-disable-next-line react/no-array-index-key
              key={rowIndex}
              className={getRowClassName(
                rowIndex,
                firstRecordRowPosition,
                lastRecordRowPosition,
                hasHeader,
                headerRowPosition,
              )}
            >
              <Table.Cell>{rowIndex + 1}</Table.Cell>
              {row.value.map((value, colIndex) => (
                // eslint-disable-next-line react/no-array-index-key
                <Table.Cell
                  key={colIndex}
                  className={assignedColumnIndexes.includes(colIndex) ? "selected" : ""}
                >
                  {value}
                </Table.Cell>
              ))}
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </div>
  )
}

const errorPropType = PropTypes.shape({ type: PropTypes.number.isRequired })

MappingAssignPanel.propTypes = {
  headerRowPosition: PropTypes.number.isRequired,
  hasHeader: PropTypes.bool.isRequired,
  firstRecordRowPosition: PropTypes.number.isRequired,
  lastRecordRowPosition: PropTypes.number.isRequired,
  generatedColumnHeaders: PropTypes.arrayOf(
    PropTypes.shape({
      colName: PropTypes.string.isRequired,
    }),
  ).isRequired,
  rowValues: PropTypes.arrayOf(PropTypes.shape({ value: PropTypes.arrayOf(PropTypes.string) }))
    .isRequired,
  tableErrors: PropTypes.arrayOf(PropTypes.arrayOf(errorPropType)).isRequired,
  assignedColumnIndexes: PropTypes.arrayOf(PropTypes.number).isRequired,
  attributeMappingError: MappingError.isRequired,
  assignedColumns: PropTypes.arrayOf(
    PropTypes.shape({ type: PropTypes.number.isRequired }).isRequired,
  ).isRequired,
  columnHeaders: PropTypes.arrayOf(
    PropTypes.shape({ colName: PropTypes.string.isRequired }).isRequired,
  ).isRequired,
  accountCurrencyCode: PropTypes.string.isRequired,
}

export default connect(mapStateToProps)(MappingAssignPanel)
