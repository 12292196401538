import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import {
  selectBoardCashFlowReport,
} from 'src/frontend/scenes/analytics/selectors'
import { selectReferentialCurrency } from 'src/frontend/modules/currencies/selectors'
import { Currency } from 'src/types/Currency'
import './ReportContent.less'
import PageLoader from 'src/frontend/PageLoader'
import { FormattedMessage, FormattedMoney, FormattedPercentageChange } from 'src/frontend/modules/intl'
import { generateIntervalString } from 'src/backend/time/time'
import { selectFilter } from 'src/frontend/modules/filter/selectors'
import { FilterType } from 'src/types/Filter'
import { withIntlConsumer } from 'src/frontend/modules/intl/components/withIntlConsumer'
import { countPercentChange } from 'src/common/helpers'
import { CashFlowReport } from 'src/frontend/scenes/analytics/Report/types'
import {
  selectIsAnalyticsLoading,
  selectShouldRefreshAnalytics,
} from 'src/frontend/modules/moduleStatus/selectors'
import { refreshAnalyticsStatus } from 'src/frontend/modules/moduleStatus/actions'
import { openCategoryPreview } from 'src/frontend/scenes/analytics/actions'
import CashFlowReportTable from 'src/frontend/scenes/analytics/Report/components/CashFlowReportTable'

interface Props {
  reportData: CashFlowReport,
  referentialCurrency: Currency,
  filter: FilterType
  locale: string,
  shouldRefreshAnalytics: boolean
  isAnalyticsLoading: boolean

  refreshAnalytics: Function
  onOpenCategoryPreview: Function
}

function mapStateToProps(state) {
  return {
    reportData: selectBoardCashFlowReport(state),
    referentialCurrency: selectReferentialCurrency(state),
    filter: selectFilter(state),
    shouldRefreshAnalytics: selectShouldRefreshAnalytics(state),
    isAnalyticsLoading: selectIsAnalyticsLoading(state),
  }
}

const mapDispatchToProps = {
  refreshAnalytics: refreshAnalyticsStatus,
  onOpenCategoryPreview: openCategoryPreview,
}

function CashFlowReportContent({
  reportData,
  referentialCurrency,
  filter,
  locale,
  shouldRefreshAnalytics,
  refreshAnalytics,
  isAnalyticsLoading,
  onOpenCategoryPreview,
}: Props) {
  useEffect(() => {
    if (shouldRefreshAnalytics) {
      refreshAnalytics()
    }
  }, [shouldRefreshAnalytics])

  if (isAnalyticsLoading && !reportData) {
    return (
      <PageLoader />
    )
  }

  if (!reportData) {
    return null
  }

  const periodText = generateIntervalString(filter.period, locale, true)

  const { previousPeriodCashFlow, currentPeriodCashFlow } = reportData

  const totalChange = countPercentChange(previousPeriodCashFlow, currentPeriodCashFlow)

  return (
    <div className="report-content">
      <h1>
        <FormattedMessage id="report.cash_flow" />
      </h1>
      <div className="totals">
        <div>
          <p className="title">
            {periodText}
          </p>
          <p className="value">
            <FormattedMoney
              currency={referentialCurrency.code}
              value={currentPeriodCashFlow}
            />
          </p>
        </div>
        <div>
          <p className="title">
            {totalChange !== null && <FormattedMessage id="period-vs-previous" />}
          </p>
          <p className="value">
            {totalChange !== null && <FormattedPercentageChange simple change={totalChange} />}
          </p>
        </div>
      </div>
      <CashFlowReportTable
        reportData={reportData}
        referentialCurrency={referentialCurrency}
        onOpenCategoryPreview={onOpenCategoryPreview}
      />
    </div>
  )
}

export default withIntlConsumer(connect(mapStateToProps, mapDispatchToProps)(CashFlowReportContent))
