import { combineReducers } from 'redux'
import categories, { SettingsCategories } from './categories/reducer'
import accounts, { SettingsAccounts } from './accounts/reducer'
import templates from './templates/reducer'
import currencies from './currencies/reducer'
import labels, { LabelsFormState } from './labels/reducer'
import privacy, { PrivacyState } from './privacy/reducer'
import magicRules, { MagicRulesFormState } from 'src/frontend/scenes/settings/magicRules/reducer'

export interface SettingsState {
  categories: SettingsCategories
  accounts: SettingsAccounts
  labels: LabelsFormState,
  magicRules: MagicRulesFormState
  privacy: PrivacyState
}

export default combineReducers({ categories, accounts, templates, currencies, labels, privacy, magicRules })

