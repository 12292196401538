import * as configuresService from 'src/backend/configures/service'
import * as logger from 'src/common/logger'
import { selectUser } from 'src/frontend/modules/user/selectors'
import { fetchConfigures } from 'src/frontend/modules/configures/actions'
import { Dispatch } from 'redux'

export const RECEIVE_HIDDEN_ENVELOPES = 'configures/RECEIVE_HIDDEN_ENVELOPES'
export const SAVE_HIDDEN_ENVELOPES_START = 'configures/SAVE_HIDDEN_ENVELOPES_START'
export const SAVE_HIDDEN_ENVELOPES_SUCCESS = 'configures/SAVE_HIDDEN_ENVELOPES_SUCCESS'
export const SAVE_HIDDEN_ENVELOPES_ERROR = 'configures/SAVE_HIDDEN_ENVELOPES_ERROR'

export function receiveHiddenEnvelopes(envelopeIds: string) {
  return (dispatch: Dispatch) => {
    try {
      const payload = JSON.parse(`[${envelopeIds}]`)
      dispatch({
        type: RECEIVE_HIDDEN_ENVELOPES,
        payload,
      })
    } catch (error) {
      logger.info('Unable to parse hidden envelopes Configure document', { envelopeIds })
    }
  }
}

export function setHideEnvelope(envelopeId: number, isHidden: boolean) {
  return async (dispatch: Function, getState: Function) => {
    dispatch({ type: SAVE_HIDDEN_ENVELOPES_START })
    try {
      const user = selectUser(getState())
      await configuresService.saveHiddenEnvelopes(envelopeId, isHidden, user)
      dispatch(fetchConfigures())
      dispatch({ type: SAVE_HIDDEN_ENVELOPES_SUCCESS })
    } catch (error) {
      dispatch({ type: SAVE_HIDDEN_ENVELOPES_ERROR })
      logger.captureException(error, 'configures.hiddenEnvelopes.saveHiddenEnvelopes')
    }
  }
}
