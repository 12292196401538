import { isImportsActivated } from "src/backend/accounts/helpers"
import * as accountsService from "src/backend/accounts/service"
import { getRandomColor } from "src/backend/colors/colors"
import * as currencyService from "src/backend/currencies/service"
import { inMemoryTableNames } from "src/backend/db/inMemorySqlDbSchemaBuilder"
import { AccountType, BalanceDisplayType } from "src/backend/enums"
import * as importsService from "src/backend/imports/service"
import { isAuthorizationError } from "src/common/helpers"
import * as logger from "src/common/logger"
import { isNumeric } from "src/common/utils"
import * as accountsActions from "src/frontend/modules/accounts/actions"
import { selectUser } from "src/frontend/modules/user/selectors"
import { closeInfoDimmer, showError, showInfoDimmer } from "src/frontend/scenes/app/actions"
import { setAllAccountsFilter } from "src/frontend/scenes/dashboard/actions"
import { AccountsTotals } from "src/frontend/scenes/dashboard/types"
import { AccountFormValues } from "src/frontend/scenes/settings/accounts/types"
import * as settingsActions from "src/frontend/scenes/settings/actions"
import { Account, CreditCardValues } from "src/types/Account"
import { Action, GetState, HashMap } from "src/types/common"
import { CouchDbDocument, Id } from "src/types/CouchDb"
import { selectAccountsForm } from "./selectors"
import { WalletFormValidationError } from "src/backend/errors"
import { validateAccount } from "src/backend/accounts/validator"
import { fetchFilters } from "src/frontend/modules/filters/actions"
import { expireUser } from "src/frontend/modules/user/actions"
import { User } from "src/types/User"
import { openIntegrationsForm } from "src/frontend/scenes/integrations/newConnection/actions"
import { accountFilterChanged } from "src/frontend/scenes/imports/account/actions"
import {
  expireAccountsStatus,
  expireAnalyticsStatus,
  expireDashboardStatus,
  expireDetailRecordsStatus,
  expireImportsRecordListStatus,
  expireRecordsStatus,
} from "src/frontend/modules/moduleStatus/actions"
import { FormType } from "src/frontend/scenes/settings/enums"
import { isAppBoard } from "src/common/environment"
import { AccountFormType } from "src/frontend/scenes/settings/accounts/enums"
import { changeAccount } from "src/frontend/scenes/records/recordForm/actions"
import { selectRecordForm } from "src/frontend/scenes/records/recordForm/selectors"

export const SETTINGS_ACCOUNTS_SAVE_START = "SETTINGS_ACCOUNTS_SAVE_START"
export const SETTINGS_ACCOUNTS_SAVE_ERROR = "SETTINGS_ACCOUNTS_SAVE_ERROR"
export const SETTINGS_ACCOUNTS_CHANGE_FIELD_VALUE = "SETTINGS_ACCOUNTS_CHANGE_FIELD_VALUE"
export const SETTINGS_ACCOUNTS_CREDIT_CARD_CHANGE_FIELD_VALUE =
  "SETTINGS_ACCOUNTS_CREDIT_CARD_CHANGE_FIELD_VALUE"
export const SETTINGS_ACCOUNTS_CHANGE_NAME = "SETTINGS_ACCOUNTS_CHANGE_NAME"

export const SETTINGS_ACCOUNTS_SET_ACCOUNT_FORM_TYPE = "SETTINGS_ACCOUNTS_SET_ACCOUNT_FORM_TYPE"
export const SETTINGS_ACCOUNTS_OPEN_FORM = "SETTINGS_ACCOUNTS_OPEN_FORM"
export const SETTINGS_ACCOUNTS_CLOSE_FORM = "SETTINGS_ACCOUNTS_CLOSE_FORM"

export const SETTINGS_ACCOUNTS_OPEN_DELETE_FORM = "SETTINGS_ACCOUNTS_OPEN_DELETE_FORM"
export const SETTINGS_ACCOUNTS_CLOSE_DELETE_FORM = "SETTINGS_ACCOUNTS_CLOSE_DELETE_FORM"

export const SETTINGS_ACCOUNTS_DELETE_START = "SETTINGS_ACCOUNTS_DELETE_START"
export const SETTINGS_ACCOUNTS_DELETE_SUCCESS = "SETTINGS_ACCOUNTS_DELETE_SUCCESS"
export const SETTINGS_ACCOUNTS_DELETE_ERROR = "SETTINGS_ACCOUNTS_DELETE_ERROR"

export const SETTINGS_ACCOUNTS_OPEN_COLOR_PICKER = "SETTINGS_ACCOUNTS_OPEN_COLOR_PICKER"
export const SETTINGS_ACCOUNTS_CLOSE_COLOR_PICKER = "SETTINGS_ACCOUNTS_CLOSE_COLOR_PICKER"

export const SETTINGS_ACCOUNTS_CALC_TOTALS_SUCCESS = "SETTINGS_ACCOUNTS_CALC_TOTALS_SUCCESS"

export function reorderAccounts(oldIndex: number, newIndex: number): Function {
  return (dispatch: Function) => {
    return dispatch(
      settingsActions.reorder(
        oldIndex,
        newIndex,
        inMemoryTableNames.ACCOUNT,
        accountsActions.fetchAccounts,
      ),
    )
  }
}

export function saveAccount(formValues: AccountFormValues, formType: string, accountId?: Id) {
  return async (dispatch: Function, getState: GetState) => {
    dispatch(saveAccountStart())

    const user: User = selectUser(getState())

    try {
      const validationDependencies = await accountsService.getValidationDependencies()
      validateAccount({ ...formValues, _id: accountId }, validationDependencies)

      const [savedAccount] = (await accountsService.saveAccount(
        formValues,
        user,
        accountId,
      )) as Account[]
      dispatch(saveAccountSuccess())
      await dispatch(accountsActions.fetchAccounts())
      dispatch(expireAnalyticsStatus())
      dispatch(expireAccountsStatus())
      await dispatch(setAllAccountsFilter())
      dispatch(expireDashboardStatus())

      console.log(savedAccount)
      if (savedAccount.accountType === AccountType.GENERAL) {
        dispatch(accountFilterChanged(savedAccount._id))
      }
      console.log(selectRecordForm(getState()))
      if (selectRecordForm(getState()).open && savedAccount) {
        dispatch(changeAccount("accountId", savedAccount._id))
      }
    } catch (error) {
      if (error instanceof WalletFormValidationError) {
        dispatch(saveAccountError(error.errors))
      } else {
        logger.captureException(error, "accounts.saveAccount", { formValues, formType, accountId })
      }
    }
  }
}

function saveAccountStart(): Action {
  return {
    type: SETTINGS_ACCOUNTS_SAVE_START,
  }
}

function saveAccountSuccess(): Function {
  return (dispatch) => {
    return dispatch(closeForm())
  }
}

function saveAccountError(errors): Action {
  return {
    type: SETTINGS_ACCOUNTS_SAVE_ERROR,
    payload: { errors },
  }
}

export function selectAccountFormType(accountFormType: AccountFormType) {
  return {
    type: SETTINGS_ACCOUNTS_SET_ACCOUNT_FORM_TYPE,
    payload: accountFormType,
  }
}

export function openAccountForm(
  formType: FormType,
  account: Account = null,
  accountFormType: AccountFormType = AccountFormType.SELECTION,
) {
  return async (dispatch: Function, getState: GetState) => {
    const { name } = selectAccountsForm(getState()).formValues
    const referentialCurrency = await currencyService.getReferentialCurrency()
    const currencyId = account ? account.currencyId : referentialCurrency._id
    const archiveDisabled =
      account && isAppBoard()
        ? !account.archived && (await accountsService.isLastCashAccount(account))
        : false

    const importSettings = account ? account.importSettings : null

    const formValues: AccountFormValues = !account
      ? {
          ...getInitialFormValues(),
          currencyId,
          name: name ? name.trim() : "",
        }
      : {
          currencyId,
          name: account.name,
          color: account.color,
          initAmount: account.initAmount / 100,
          gps: account.gps,
          excludeFromStats: account.excludeFromStats,
          archived: account.archived,
          accountType: account.accountType,
          importEmail: importSettings && importSettings.email,
          importIsAutomatic: importSettings && importSettings.isAutomatic,
          importSettingsId: importSettings && importSettings.settingsId,
          creditCard: account.creditCard
            ? {
                limit: account.creditCard.limit / 100,
                dueDay: account.creditCard.dueDay,
                balanceDisplayOption: account.creditCard.balanceDisplayOption,
              }
            : getInitialCreditCardValues(),
        }

    return dispatch({
      type: SETTINGS_ACCOUNTS_OPEN_FORM,
      payload: {
        formType,
        account,
        accountFormType: formType === FormType.EDIT ? AccountFormType.MANUAL : accountFormType,
        formValues,
        archiveDisabled,
      },
    })
  }
}

export function closeForm(): Action {
  return { type: SETTINGS_ACCOUNTS_CLOSE_FORM }
}

export function openConnectBankForm(accountId?: Id) {
  return (dispatch: Function) => {
    dispatch(closeForm())
    dispatch(openIntegrationsForm(accountId))
  }
}

export const changeName = (field: string, value: string): Action => {
  return {
    type: SETTINGS_ACCOUNTS_CHANGE_NAME,
    payload: { [field]: value },
  }
}

export const changeFormFieldValue = (field: string, value: string | number | boolean) => {
  const payloadValue = (typeof value === "string" && value.trim()) || value
  return {
    type: SETTINGS_ACCOUNTS_CHANGE_FIELD_VALUE,
    payload: { [field]: payloadValue },
  }
}

export const changeCreditCardFormFieldValue = (field: string, value: any) => {
  return {
    type: SETTINGS_ACCOUNTS_CREDIT_CARD_CHANGE_FIELD_VALUE,
    payload: { [field]: isNumeric(value) ? parseFloat(value) : value },
  }
}

export const changeAutomaticImport =
  (enabled: boolean) => (dispatch: Function, getState: GetState) => {
    const {
      formValues: { importSettingsId },
    } = selectAccountsForm(getState())
    dispatch(showInfoDimmer("imports.loading_imports"))
    const handleSuccess = () => {
      return Promise.resolve().then(() => {
        dispatch(changeFormFieldValue("importIsAutomatic", enabled))
        dispatch(closeInfoDimmer())
      })
    }
    const handleError = (error) => {
      if (isAuthorizationError(error)) {
        dispatch(expireUser())
      } else {
        logger.error("action=changeAutomaticImport unexpected error", {
          errorMessage: error.message,
          accountName: selectAccountsForm(getState()).formValues.name,
          importSettingsId,
          error,
        })
        dispatch(showError(["app.error.default_error_message", "app.error.we_are_fixing_suffix"]))
      }
    }

    if (enabled) {
      importsService
        .activateAutomaticImport(importSettingsId)
        .then(() => handleSuccess())
        .catch(handleError)
    } else {
      importsService
        .deactivateAutomaticImport(importSettingsId)
        .then(() => handleSuccess())
        .catch(handleError)
    }
  }

export const changeInitAmountValue = (field: string, value: string) => {
  return {
    type: SETTINGS_ACCOUNTS_CHANGE_FIELD_VALUE,
    payload: { [field]: isNumeric(value) ? parseFloat(value) : value },
  }
}

export function openDeleteForm(account: Account) {
  return (dispatch: Function) => {
    accountsService.getRemoveAccountProperties(account).then((payload) => {
      dispatch({
        type: SETTINGS_ACCOUNTS_OPEN_DELETE_FORM,
        payload,
      })
    })
  }
}

export function closeDeleteForm(): Action {
  return {
    type: SETTINGS_ACCOUNTS_CLOSE_DELETE_FORM,
  }
}

export function deleteAccount(account: Account, references: Array<CouchDbDocument>) {
  return async (dispatch: Function) => {
    dispatch(deleteAccountStart(account))
    try {
      await accountsService.removeAccountReferences(references, account)
      await accountsService.removeAccount(account)

      if (isImportsActivated(account)) {
        await importsService.deleteImportSettings(account.importSettings.settingsId)
      }

      dispatch(deleteAccountSuccess())
      await dispatch(accountsActions.fetchAccounts())
      dispatch(fetchFilters())
      dispatch(setAllAccountsFilter())
      dispatch(expireAccountsStatus())
      dispatch(expireDashboardStatus())
      dispatch(expireRecordsStatus())
      dispatch(expireAnalyticsStatus())
      dispatch(expireImportsRecordListStatus())
      dispatch(expireDetailRecordsStatus())
    } catch (error) {
      if (isAuthorizationError(error)) {
        // Handling authorization error on the backend does not make sense since the account already deleted
        // at this stage so the user cannot fix it after login
        logger.error("action=deleteAccount authorization error, imports did not deleted", {
          error: "Imports did not deleted ",
          importsSettingsId: account.importSettings && account.importSettings.settingsId,
          accountName: account.name,
          accountId: account._id,
          importEmail: account.importSettings && account.importSettings.email,
        })
      } else {
        logger.error("action=deleteAccount unexpected error", { error })
        dispatch(deleteAccountError(error, account))
      }
    }
  }
}

function deleteAccountStart(account): Action {
  return {
    type: SETTINGS_ACCOUNTS_DELETE_START,
    payload: { account },
  }
}

function deleteAccountError(error, account): Action {
  // FIXME WALLET-6385 message is not localized, is error.name set somewhere?
  // FIXME error is not handled, should be used errorMessageId because of ErrorMessageModal API
  const errorMessage = error.name === "conflict" ? "Record conflict!" : "Deleting account failed!"
  return {
    type: SETTINGS_ACCOUNTS_DELETE_ERROR,
    payload: {
      errors: { general: errorMessage },
      account,
    },
  }
}

function deleteAccountSuccess(): Action {
  return {
    type: SETTINGS_ACCOUNTS_DELETE_SUCCESS,
    payload: { account: null },
  }
}

export function openColorPicker(): Action {
  return { type: SETTINGS_ACCOUNTS_OPEN_COLOR_PICKER }
}

export function closeColorPicker(): Action {
  return { type: SETTINGS_ACCOUNTS_CLOSE_COLOR_PICKER }
}

function getInitialFormValues(): AccountFormValues {
  return {
    name: "",
    currencyId: "",
    color: getRandomColor(),
    initAmount: 0,
    accountType: AccountType.GENERAL,
    gps: true,
    excludeFromStats: false,
    creditCard: getInitialCreditCardValues(),
  }
}

export function getInitialCreditCardValues(): CreditCardValues {
  return {
    limit: 0,
    dueDay: 0,
    balanceDisplayOption: BalanceDisplayType.CREDIT_BALANCE,
  }
}

export function receiveSettingsAccountsTotals(
  accountsTotals: HashMap<AccountsTotals>,
): Action<HashMap<AccountsTotals>> {
  return {
    type: SETTINGS_ACCOUNTS_CALC_TOTALS_SUCCESS,
    payload: accountsTotals,
  }
}
