import { Id } from 'src/types/CouchDb'
import * as recordsRepository from 'src/backend/records/repository'
import { AssignFormValues } from 'src/frontend/scenes/records/assignForm/types'
import { Action, GetState, HashMap } from 'src/types/common'
import * as userSelectors from 'src/frontend/modules/user/selectors'
import * as recordsService from 'src/backend/records/service'
import { FormType } from 'src/frontend/scenes/records/recordForm/enums'
import * as categoriesActions from 'src/frontend/modules/categories/actions'
import {
  expireAccountsStatus,
  expireAnalyticsStatus,
  expireDetailRecordsStatus,
  expireDashboardStatus,
  expireImportsRecordListStatus,
  expireRecordsStatus,
} from 'src/frontend/modules/moduleStatus/actions'
import { WalletFormValidationError } from 'src/backend/errors'
import { captureException } from 'src/common/logger'
import { selectAssignForm } from 'src/frontend/scenes/records/assignForm/selectors'
import { recordIsFromIntegration, recordIsImported } from 'src/backend/records/helpers'
import { isNotEmpty } from 'src/backend/common/validator'

export const ASSIGN_FORM_OPEN = 'assignForm/OPEN'
export const ASSIGN_FORM_CLOSE = 'assignForm/CLOSE'
export const ASSIGN_FORM_CHANGE_VALUE = 'assingForm/CHANGE_VALUE'

export const ASSIGN_FORM_SAVE_START = 'assignForm/SAVE_START'
export const ASSIGN_FORM_ERROR = 'assignForm/ERROR'

export const ASSIGN_FORM_SHOW_PROPOSAL = 'assignForm/HOW_PROPOSAL'


export function openAssignForm(recordId: Id) {
  return async (dispatch: Function) => {
    const record = await recordsRepository.findById(recordId)

    if (record) {
      const { contactId, labels } = record
      const formValues: AssignFormValues = {
        categoryId: undefined,
        contactId,
        labels,
      }

      return dispatch({
        type: ASSIGN_FORM_OPEN,
        payload: {
          recordId: record._id,
          formValues,
        },
      })
    }
  }
}

export function changeFieldValue(
  field: string,
  value: string | number | Date | boolean,
): Action<{ [key: string]: string | number | Date | boolean }> {
  return {
    type: ASSIGN_FORM_CHANGE_VALUE,
    payload: { [field]: value },
  }
}

export function changeContactId(contactId: Id) {
  return {
    type: ASSIGN_FORM_CHANGE_VALUE,
    payload: { contactId },
  }
}

export function closeAssignForm(): Action {
  return {
    type: ASSIGN_FORM_CLOSE,
  }
}

export function showAddRuleProposal() {
  return {
    type: ASSIGN_FORM_SHOW_PROPOSAL,
  }
}

export function saveAssignRecord() {
  return async (dispatch: Function, getState: GetState) => {

    dispatch(saveAssignRecordStart())
    const { formValues, recordId } = selectAssignForm(getState())

    const user = userSelectors.selectUser(getState())

    try {
      await recordsService.validateRecords(formValues, [recordId], FormType.EDIT)
      const savedRecord = await recordsService.saveRecord(formValues, FormType.EDIT, user, recordId)

      if ((recordIsFromIntegration(savedRecord) || recordIsImported(savedRecord))
        && (isNotEmpty(savedRecord.payee) || isNotEmpty(savedRecord.note))) {
        setTimeout(() => dispatch(showAddRuleProposal()), 0)
      } else {
        setTimeout(() => dispatch(closeAssignForm()), 0)
      }


      dispatch(categoriesActions.fetchCategories())
      dispatch(expireAccountsStatus())
      dispatch(expireDashboardStatus())
      dispatch(expireRecordsStatus())
      dispatch(expireAnalyticsStatus())
      dispatch(expireImportsRecordListStatus())
      dispatch(expireDetailRecordsStatus())


    } catch (error) {
      if (error instanceof WalletFormValidationError) {
        dispatch(saveRecordError(error.errors))
      } else {
        captureException(error, 'records.saveAssignRecord', { formValues, recordId, errors: error.errors })
      }
    }
  }
}

function saveAssignRecordStart() {
  return {
    type: ASSIGN_FORM_SAVE_START,
  }
}

function saveRecordError(errors: HashMap<string>): Action<{ errors: HashMap<string> }> {
  return {
    type: ASSIGN_FORM_ERROR,
    payload: { errors },
  }
}
