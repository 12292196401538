import { Action, GetState, ThunkFunction } from 'src/types/common'
import {
  selectIsSelectedProductRecurring,
  selectSelectedProduct,
} from 'src/frontend/scenes/billing/product/selectors'
import * as billing from 'src/backend/rest/backend/billing'
import { WalletBillingError, WalletRestApiError } from 'src/backend/errors'
import { showErrorModal } from 'src/frontend/Error/actions'
import * as logger from 'src/common/logger'
import { isAuthorizationError } from 'src/common/helpers'
import { expireUser } from 'src/frontend/modules/user/actions'
import { getActiveSubscriptionDetails } from 'src/frontend/scenes/billing/activeSubscription/actions'
import { isProductExpiredError } from 'src/frontend/scenes/billing/paymentMethod/helpers'
import { openGoodJobModal } from 'src/frontend/scenes/onBoarding/inApp/actions'
import { fetchTransactionHistory } from 'src/frontend/scenes/billing/transactionHistory/actions'
import * as gtm from 'src/common/gtm'

export const BUY_PLAN_START = 'billing/BUY_PLAN_START'
export const BUY_PLAN_END = 'billing/BUY_PLAN_END'
export const BUY_PLAN_ERROR = 'billing/BUY_PLAN_ERROR'

export const UPDATE_PAYMENT_METHOD_START = 'billing/UPDATE_PAYMENT_METHOD_START'
export const UPDATE_PAYMENT_METHOD_SUCCESS = 'billing/UPDATE_PAYMENT_METHOD_SUCCESS'
export const UPDATE_PAYMENT_METHOD_ERROR = 'billing/UPDATE_PAYMENT_METHOD_ERROR'

export const GET_TOKEN_START = 'billing/GET_TOKEN_START'
export const GET_TOKEN_SUCCESS = 'billing/GET_TOKEN_SUCCESS'
export const GET_TOKEN_ERROR = 'billing/GET_TOKEN_ERROR'

export const CLEAR_TOKEN = 'billing/CLEAR_TOKEN'

export const BUY_PLAN_EXPIRE_PRODUCT = 'billing/BUY_PLAN_EXPIRE_PRODUCT'

export function submitTransaction(nonce: string) {
  return async (dispatch: Function, getState: GetState) => {
    dispatch(buyPlanStart())
    try {
      const selectedProduct = selectSelectedProduct(getState())
      const isSelectedProductRecurring = selectIsSelectedProductRecurring(getState())

      if (isSelectedProductRecurring) {
        await billing.createSubscription(selectedProduct.product, nonce)
      } else {
        await billing.createLifetimeTransaction(selectedProduct.product, nonce)
      }

      await Promise.all([
        dispatch(getActiveSubscriptionDetails()),
        dispatch(fetchTransactionHistory()),
      ])
      dispatch(buyPlanEnd())
      dispatch(openGoodJobModal('billing.purchase.success.text'))
    } catch (error) {
      if (isAuthorizationError(error)) {
        dispatch(expireUser())
        return
      } else if (isProductExpiredError(error)) {
        dispatch(expireProduct())
      } else if (error instanceof WalletRestApiError) {
        dispatch(showErrorModal(null, false))
      } else if (error instanceof WalletBillingError) {
        dispatch(buyPlanError(error.messageId))
      }
      logger.captureException(error, 'billing.submitTransaction')
    }
  }
}

function buyPlanStart(): ThunkFunction {
  return (dispatch: Function, getState: GetState) => {
    gtm.buyPlanStart()
    return dispatch({
      type: BUY_PLAN_START,
    })
  }
}

function buyPlanEnd(): ThunkFunction {
  return (dispatch: Function, getState: GetState) => {
    gtm.buyPlanFinished()
    return dispatch({
      type: BUY_PLAN_END,
    })
  }
}

function buyPlanError(error: string = null): Action {
  return {
    type: BUY_PLAN_ERROR,
    payload: error,
  }
}

export function updatePaymentMethod(nonce: string) {
  return async (dispatch: Function) => {
    dispatch(updatePaymentMethodStart())

    try {
      await billing.updatePaymentMethod(nonce)
      await dispatch(getActiveSubscriptionDetails())
      dispatch(updatePaymentMethodSuccess())
    } catch (error) {
      if (isAuthorizationError(error)) {
        dispatch(expireUser())
        return
      } else if (error instanceof WalletRestApiError) {
        dispatch(showErrorModal(null, false))
      } else if (error instanceof WalletBillingError) {
        dispatch(updatePaymentMethodError(error.messageId))
      }
      logger.captureException(error, 'billing.updatePaymentMethod')
    }
  }
}

export function updatePaymentMethodStart(): Action {
  return {
    type: UPDATE_PAYMENT_METHOD_START,
  }
}

export function updatePaymentMethodSuccess(): Action {
  return {
    type: UPDATE_PAYMENT_METHOD_SUCCESS,
  }
}

export function updatePaymentMethodError(error): Action {
  return {
    type: UPDATE_PAYMENT_METHOD_ERROR,
    payload: error,
  }
}

export function getToken() {
  return async (dispatch: Function) => {
    dispatch(getTokenStart())

    try {
      const token = await billing.fetchToken()
      dispatch(getTokenSuccess(token))
    } catch (error) {
      if (isAuthorizationError(error)) {
        dispatch(expireUser())
      } else {
        dispatch(showErrorModal(null, false))
      }
      dispatch(getTokenError())
      logger.captureException(error, 'billing.getToken')
    }
  }
}

function getTokenStart(): Action {
  return {
    type: GET_TOKEN_START,
  }
}

function getTokenSuccess(token: string): Action<string> {
  return {
    type: GET_TOKEN_SUCCESS,
    payload: token,
  }
}

function getTokenError() {
  return {
    type: GET_TOKEN_ERROR,
  }
}

export function clearToken() {
  return {
    type: CLEAR_TOKEN,
  }
}


export function expireProduct() {
  return {
    type: BUY_PLAN_EXPIRE_PRODUCT,
  }
}
