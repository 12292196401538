import React from "react"
import { connect } from "react-redux"
import { Waypoint } from "react-waypoint"
import * as removeFormSelectors from "src/frontend/scenes/records/removeForm/selectors"
import * as filterSelectors from "src/frontend/modules/filter/selectors"
import _isEmpty from "lodash/isEmpty"
import _isEqual from "lodash/isEqual"
import styles from "./ExtendedRecordList.module.less"
import SelectRecords from "src/frontend/components/RecordList/SelectRecords"
import { selectReferentialCurrency } from "src/frontend/modules/currencies/selectors"
import { FilterDocument } from "src/types/Filter"
import { Currency } from "src/types/Currency"
import { RecordListItemRecord } from "src/frontend/components/RecordList/types"
import ExtendedRecordListContent from "./ExtendedRecordListContent"
import { COUNT_INCREMENT } from "src/frontend/scenes/records/recordList/constants"
import { RootState } from "src/types/State"
import { Id } from "src/types/CouchDb"
import { reduceBy } from "src/common/utils"
import { getRecordsSum } from "src/frontend/scenes/records/recordList/helpers"
import { RecordListOrderBy } from "src/frontend/scenes/records/recordList/enums"

interface Props {
  records: RecordListItemRecord[]
  loading?: boolean
  disablePeriodSums?: boolean
  transparentSelect?: boolean
  sortBy?: RecordListOrderBy

  // Mapped State
  isRecordRemoving: boolean
  filters: FilterDocument
  referentialCurrency: Currency
}

ExtendedRecordList.defaultProps = {
  loading: false,
  disablePeriodSums: false,
  transparentSelect: false,
  sortBy: RecordListOrderBy.RECORD_DATE_DESC,
}

function mapStateToProps(state: RootState) {
  return {
    isRecordRemoving: removeFormSelectors.selectIsRecordRemoving(state),
    filters: filterSelectors.selectFilter(state),
    referentialCurrency: selectReferentialCurrency(state),
  }
}

function ExtendedRecordList({
  records,
  loading,
  isRecordRemoving,
  filters,
  transparentSelect,
  disablePeriodSums,
  referentialCurrency,
  sortBy,
}: Props) {
  const [visibleRecordsCount, setVisibleRecordsCount] = React.useState<number>(COUNT_INCREMENT)
  const [visibleRecords, setVisibleRecords] = React.useState<RecordListItemRecord[]>(
    records.slice(0, COUNT_INCREMENT),
  )

  const [selectedRecordIds, setSelectedRecordIds] = React.useState<Id[]>([])

  React.useEffect(() => {
    setVisibleRecordsCount(COUNT_INCREMENT)
  }, [filters])

  React.useEffect(() => {
    deselectAll()
    setVisibleRecords(records.slice(0, visibleRecordsCount))
  }, [records])

  const recordsMap = React.useMemo(() => records.reduce(reduceBy("_id"), {}), [records])

  const selectedRecords = selectedRecordIds
    .filter((recordId) => recordId in recordsMap)
    .map((recordId) => recordsMap[recordId])

  const recordsSum = React.useMemo(() => getRecordsSum(records), [records])
  const selectedRecordsSum = React.useMemo(() => getRecordsSum(selectedRecords), [selectedRecords])

  const deselectAll = React.useCallback(() => {
    setSelectedRecordIds([])
  }, [])

  const selectAll = React.useCallback(() => {
    setSelectedRecordIds(records.map((record) => record._id))
  }, [records])

  const onRecordCheck = React.useCallback(
    (recordId: Id, isSelected: boolean) => {
      if (isSelected) {
        const nextSelectedRecordIds = selectedRecordIds.filter(
          (selectedRecordId: Id) => selectedRecordId !== recordId,
        )

        if (nextSelectedRecordIds.length < 1) {
          deselectAll()
        } else if (nextSelectedRecordIds.length === 1) {
          setSelectedRecordIds([nextSelectedRecordIds[0]])
        } else if (nextSelectedRecordIds.length > 1) {
          setSelectedRecordIds(nextSelectedRecordIds)
        }
      } else {
        setSelectedRecordIds([...selectedRecordIds, recordId])
      }
    },
    [selectedRecordIds],
  )

  function onAllRecordsCheckBoxChange(isNoneSelected) {
    if (!isNoneSelected) {
      deselectAll()
    } else {
      selectAll()
    }
  }

  const onWaypointEnter = React.useCallback(() => {
    const newOffset = visibleRecordsCount + COUNT_INCREMENT
    setVisibleRecordsCount(newOffset)
    setVisibleRecords(records.slice(0, newOffset))
  }, [records, visibleRecordsCount])

  const allRecordsSelected: boolean =
    !_isEmpty(selectedRecords) && selectedRecords.length === records.length

  return (
    <div className={styles.recordsContainer}>
      <SelectRecords
        transparent={transparentSelect}
        selectedRecords={selectedRecords}
        recordsSum={_isEmpty(selectedRecords) ? recordsSum : selectedRecordsSum}
        allRecordsSelected={allRecordsSelected}
        selectAllDisabled={_isEmpty(records)}
        onAllRecordsCheckBoxChange={onAllRecordsCheckBoxChange}
      />
      <ExtendedRecordListContent
        loading={loading}
        disablePeriodSums={disablePeriodSums}
        records={visibleRecords}
        sortBy={sortBy}
        isRemoving={isRecordRemoving}
        selectedRecords={selectedRecords}
        referentialCurrencyCode={referentialCurrency.code}
        onRecordCheck={onRecordCheck}
      />
      <Waypoint
        onEnter={onWaypointEnter}
        bottomOffset="-50%"
      />
    </div>
  )
}

export default connect(mapStateToProps)(
  React.memo(ExtendedRecordList, (prevProps, nextProps) => {
    return (
      prevProps.loading === nextProps.loading &&
      prevProps.disablePeriodSums === nextProps.disablePeriodSums &&
      prevProps.filters === nextProps.filters &&
      prevProps.isRecordRemoving === nextProps.isRecordRemoving &&
      prevProps.referentialCurrency === nextProps.referentialCurrency &&
      prevProps.transparentSelect === nextProps.transparentSelect &&
      prevProps.sortBy === nextProps.sortBy &&
      _isEqual(nextProps.records, prevProps.records)
    )
  }),
)
