import COUNTRIES from 'static/countries.json'
import { reduceBy } from 'src/common/utils'
import EU_COUNTRIES from 'static/euCountries.json'

const AllCountries = getCountriesAsHashMap()

export function getAllCountries() {
  return COUNTRIES
}

export function getCountryCodes(): string[] {
  return getAllCountries().map(({ code }) => code)
}

export function isValidCountry(countryCode: string) {
  return getCountryCodes().includes(countryCode?.toLowerCase())
}

export function getCountriesAsHashMap() {
  return getAllCountries().reduce(reduceBy<{ name: string, code: string }>('code'), {})
}


export function getCountryName(countryCode: string): string {
  const lowerCountryCode = countryCode?.toLowerCase()
  return lowerCountryCode in AllCountries ? AllCountries[lowerCountryCode].name : countryCode
}

export function getCountriesOptions() {
  return getAllCountries().map((country) => {
    return {
      id: country.code,
      key: country.code,
      value: country.code,
      text: country.name,
    }
  })
}

export function isEuCountry(countryCode: string) {
  return EU_COUNTRIES.includes(countryCode?.toLowerCase())
}
