import { BillingModalStage } from 'src/frontend/scenes/billing/billingModal/enums'
import PlanSelectModalContent from 'src/frontend/scenes/billing/billingModal/components/PlanSelectModalContent'
import PaymentMethodModalContent from 'src/frontend/scenes/billing/billingModal/components/PaymentMethodModalContent'

export function getBillingModalComponent(stage: BillingModalStage) {
  switch (stage) {

    case BillingModalStage.SELECT_PRODUCT: {
      return PlanSelectModalContent
    }

    case BillingModalStage.PAYMENT_METHOD: {
      return PaymentMethodModalContent
    }

    default: {
      return () => null
    }
  }
}
