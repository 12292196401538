import { Action } from 'src/types/common'
import { FETCH_RESTRICTED_PROVIDERS_SUCCESS } from 'src/frontend/scenes/integrations/restrictedProviders/actions'
import { RestrictedProviders } from 'src/frontend/scenes/integrations/restrictedProviders/types'
import RestrictedProvider = RestrictedProviders.RestrictedProvider

export type RestrictedProvidersState = RestrictedProvider[]

const initialProviders: RestrictedProvidersState = []

export default function restrictedProviders(
  state: RestrictedProvidersState = initialProviders,
  action: Action,
): RestrictedProvidersState {
  switch (action.type) {
    case FETCH_RESTRICTED_PROVIDERS_SUCCESS: {
      return action.payload || []
    }

    default: {
      return state
    }
  }
}

