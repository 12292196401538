import { RootState } from 'src/types/State'
import { OnBoardingStage } from 'src/frontend/scenes/onBoarding/firstRun/enums'
import { OnBoardingFormValues } from 'src/frontend/scenes/onBoarding/firstRun/types'
import { OnBoardingStatus } from 'src/backend/onBoarding/types'
import { createSelector } from 'reselect'
import { OnBoardingFirstRunState } from 'src/frontend/scenes/onBoarding/firstRun/reducer'
import { selectOnBoarding } from 'src/frontend/scenes/onBoarding/selectors'


export const selectFirstRunOnBoarding = (state: RootState): OnBoardingFirstRunState => selectOnBoarding(state).firstRun
export const selectOnBoardingShow = (state: RootState): boolean => selectFirstRunOnBoarding(state).show
export const selectOnBoardingStage = (state: RootState): OnBoardingStage => selectFirstRunOnBoarding(state).stage
export const selectOnBoardingStatus =
  (state: RootState): OnBoardingStatus => selectFirstRunOnBoarding(state).onBoardingStatus
export const selectOnBoardingLoading = (state: RootState): boolean => selectFirstRunOnBoarding(state).loading

export const selectPreferBankIntegration = (state: RootState): boolean => {
  return selectFirstRunOnBoarding(state).preferBankIntegration
}

export const selectOnBoardingFormValues =
  (state: RootState): OnBoardingFormValues => selectFirstRunOnBoarding(state).formValues

export const selectShouldShowOnBoarding = createSelector(
  selectOnBoardingShow,
  selectOnBoardingStage,
  (shouldShow, stage) => {
    return shouldShow && stage !== OnBoardingStage.FINISHED_ONBOARDING
  },
)

export function selectShouldAddVoucher(state: RootState): boolean {
  return selectFirstRunOnBoarding(state).shouldAddVoucher
}

export function selectVoucherCode(state: RootState): string | undefined {
  return selectFirstRunOnBoarding(state).voucherCode
}
