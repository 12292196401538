import {changeEmailingConsent, deleteUser, deleteUserData} from 'src/backend/rest/backend'
import * as userActions from 'src/frontend/modules/user/actions'
import {logoutHard, clearLocalData, showError} from 'src/frontend/scenes/app/actions'
import {captureException} from 'src/common/logger'
import { UserConsentType, UserDeleteDataType } from 'src/frontend/scenes/settings/privacy/enums'
import { Action } from 'src/types/common'

export const CANCEL_CHANGE_CONSENT = 'user/privacy/CONSENT_CHANGE_CANCEL'
export const CONFIRM_REMOVE_CONSENT = 'user/privacy/CONFIRM_REMOVE_CONSENT'
export const DELETE_DATA_CONFIRM = 'user/privacy/DELETE_DATA_CONFIRM'
export const LOADING = 'user/privacy/LOADING'
export const RESET_STATE = 'user/privacy/RESET_STATE'

export function closeConsentConfirmModal() {
    return { type: CANCEL_CHANGE_CONSENT }
}

export const removeEmailingConsent = removeConsent(UserConsentType.EMAILING, changeEmailingConsent)

export const emailingChanged = consentChange(UserConsentType.EMAILING, changeEmailingConsent)

function consentChange(type: UserConsentType, apiCall: (value?: boolean) => Promise<Response>) {
    return (enabled: boolean) => (dispatch: Function) => {
        if (enabled) {
            dispatch({ type: LOADING })
            return apiCall(enabled)
                .then(() => {
                    return dispatch(changeConsentDone(type, enabled))
                })
                .catch((error) => {
                    captureException(error, 'Consent Change failed')
                    return dispatch(showError())
                })
        } else {
            return dispatch(confirmRemoveConsent(type))
        }
    }
}

function removeConsent(type: UserConsentType, apiCall: (value?: boolean) => Promise<Response>) {
    return () => async (dispatch: Function) => {
        dispatch({ type: LOADING })
        await apiCall(false)
        return dispatch(changeConsentDone(type, false))
    }
}

function changeConsentDone(type: UserConsentType, value: boolean) {
    return (dispatch: Function) => {
        dispatch({ type: RESET_STATE })
        dispatch({ type: userActions.SET_USER_CONSENT, payload: { type, value } })
    }
}

function confirmRemoveConsent(type): Action<{ type: UserConsentType }> {
    return { type: CONFIRM_REMOVE_CONSENT, payload: { type } }
}

//--------------------//

export function openDeleteUserAndDataDialog() {
    return (dispatch: Function) => {
        dispatch(dataDeleteConfirm(UserDeleteDataType.DELETE_USER_AND_DATA))
    }
}

export function openDeleteUserAndDataConfirmDialog() {
    return (dispatch: Function) => {
        dispatch(dataDeleteConfirm(UserDeleteDataType.DELETE_USER_AND_DATA_CONFIRM))
    }
}

export function openDeleteDataDialog() {
    return (dispatch: Function) => {
        dispatch(dataDeleteConfirm(UserDeleteDataType.DELETE_DATA))
    }
}

export function openDeleteDataConfirmDialog() {
    return (dispatch: Function) => {
        dispatch(dataDeleteConfirm(UserDeleteDataType.DELETE_DATA_CONFIRM))
    }
}

export function cancelDeleteUserAndDataDialog() {
    return { type: RESET_STATE }
}

export function cancelDeleteDataDialog() {
    return { type: RESET_STATE }
}

export function deleteUserAndData() {
    return (dispatch: Function) => {
        console.log('deleteUserAndData called')
        dispatch({ type: RESET_STATE })
        dispatch({ type: LOADING })
        return deleteUser()
            .then(() => dispatch(logoutHard()))
            .catch((error) => {
                captureException(error, 'deleteData failed')
                return dispatch(showError())
            })
    }
}

export function deleteData() {
    return (dispatch: Function) => {
        console.log('deleteData called')
        dispatch({ type: RESET_STATE })
        dispatch({ type: LOADING })
        return deleteUserData()
            .then(() => dispatch(logoutHard())) //dispatch(clearLocalData()))
            .catch((error) => {
                captureException(error, 'deleteData failed')
                return dispatch(showError())
            })
    }
}

function dataDeleteConfirm(type): Action<{ type: UserDeleteDataType }> {
    return { type: DELETE_DATA_CONFIRM, payload: { type } }
}


// TODO add these functions to some utility medule for early dev state?
//
// function wait(timeout = 0) {
//   return new Promise(resolve => { setTimeout(resolve, timeout) })
// }
//
// function mockAction(name) {
//   return (...args) => {
//     console.log(`mockAction ${name}(`, args, ')')
//     return { type: `MOCK_${name}` }
//   }
// }
