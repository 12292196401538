import React, { SyntheticEvent } from 'react'
import { Divider, Form, Grid, Modal, Transition } from 'semantic-ui-react'
import { FormattedMessage, FormattedMessageLabel } from 'src/frontend/modules/intl'
import { connect } from 'react-redux'
import { RootState } from 'src/types/State'
import { selectAssignForm } from 'src/frontend/scenes/records/assignForm/selectors'
import { changeFieldValue, closeAssignForm, saveAssignRecord } from 'src/frontend/scenes/records/assignForm/actions'
import SelectContact from 'src/frontend/scenes/records/components/SelectContact'
import { selectSortedContacts } from 'src/frontend/modules/contacts/selectors'
import * as categoriesSelectors from 'src/frontend/modules/categories/selectors'
import SelectCategory from 'src/frontend/components/Categories/SelectCategory'
import SelectMultiple from 'src/frontend/components/SelectMultiple'
import { isAppBoard } from 'src/common/environment'
import * as labelsSelectors from 'src/frontend/modules/labels/selectors'
import { HashMap } from 'src/types/common'
import { AssignFormValues } from 'src/frontend/scenes/records/assignForm/types'
import { AssignFormContent } from 'src/frontend/scenes/records/assignForm/enums'
import { Contact } from 'src/types/Contact'
import { Category, SuperEnvelope } from 'src/types/Category'
import { Label } from 'src/types/Label'
import { SemanticData } from 'src/types/Semantic-ui'
import styles from './RecordAssignForm.module.less'
import PictureIcon from 'src/frontend/components/PictureIcon/PictureIcon'
import { openAddFromAssignForm } from 'src/frontend/scenes/settings/magicRules/actions'
import { PrimaryButton, SecondaryButton } from 'src/frontend/components/Buttons/Buttons'

function mapStateToProps(state: RootState) {
  const { open, formValues, errors, loading, contentType } = selectAssignForm(state)
  return {
    open,
    formValues,
    errors,
    loading,
    contentType,
    selectOptions: {
      contacts: selectSortedContacts(state),
      categoriesHierarchy: categoriesSelectors.selectCategoriesHierarchy(state),
      categories: categoriesSelectors.selectFlattenedCategoriesWithoutUnknownCategories(state),
      labels: labelsSelectors.selectSortedLabels(state),
    },
  }
}

const mapDispatchToProps = {
  handleCloseModal: closeAssignForm,
  handleFieldChange: (_e, { name, value }) => changeFieldValue(name, value),
  handleSaveRecord: saveAssignRecord,
  handleOpenRuleForm: openAddFromAssignForm,
}

interface Props {
  open: boolean,
  errors: HashMap<string>
  loading: boolean
  formValues: AssignFormValues
  contentType: AssignFormContent
  selectOptions: {
    contacts: Contact.ContactView[],
    categoriesHierarchy: SuperEnvelope[],
    categories: Category[],
    labels: Label[],
  }

  handleCloseModal: (e: SyntheticEvent) => void
  handleFieldChange: (e: SyntheticEvent, data: SemanticData) => void
  handleSaveRecord: (e: SyntheticEvent) => void
  handleOpenRuleForm: (e: SyntheticEvent) => void
}

function RecordAssignForm({
  open,
  errors,
  loading,
  formValues,
  contentType,
  selectOptions,
  handleCloseModal,
  handleFieldChange,
  handleSaveRecord,
  handleOpenRuleForm,
}: Props) {

  const labelOptions = selectOptions && selectOptions.labels && selectOptions.labels.filter((label) => {
    return !label.archived || formValues && formValues.labels && formValues.labels.includes(label._id)
  })

  return (
    <Transition
      directional
      visible={open}
      unmountOnHide
      onHide={handleCloseModal}
      duration={{ show: 250, hide: contentType !== AssignFormContent.ASSIGN_FORM ? 150 : 0 }}
      animation="modal-animation"
    >
      <Modal
        size="small"
        onClose={handleCloseModal}
        open
      >
        {contentType === AssignFormContent.ASSIGN_FORM ? (
          <>
            <Modal.Header>
              <FormattedMessage id="form.assign" />
              <span className="modal-close" onClick={handleCloseModal} />
            </Modal.Header>
            {formValues && (
              <Modal.Content>
                <Form>
                  {isAppBoard() && (
                    <SelectContact
                      allowAdd
                      contacts={selectOptions.contacts}
                      contactId={formValues.contactId}
                      onChange={(e, data) => handleFieldChange(e, { ...data, name: atob(data.name) })}
                    />
                  )}

                  <SelectCategory
                    value={formValues.categoryId}
                    categoriesHierarchy={selectOptions.categoriesHierarchy}
                    categories={selectOptions.categories}
                    name="categoryId"
                    error={errors.categoryId}
                    onFieldChange={handleFieldChange}
                  />

                  <SelectMultiple
                    name="labels"
                    label={<FormattedMessageLabel id="record.form.labels" />}
                    value={formValues.labels}
                    options={labelOptions}
                    error={errors.labels}
                    onChange={handleFieldChange}
                  />

                  <Divider hidden section />

                  <PrimaryButton loading={loading} disabled={loading} fluid type="submit" onClick={handleSaveRecord}>
                    <FormattedMessage id="form.save" />
                  </PrimaryButton>
                </Form>
              </Modal.Content>
            )}
          </>
        ) : (
          <Modal.Content>
            <div className={styles.addRuleProposal}>
              <PictureIcon name="magic-rules" />
              <h2><FormattedMessage id="settings.rules.teaser.title" /></h2>
              <p><FormattedMessage id="settings.rules.teaser.content_1" /></p>
              <p><FormattedMessage id="settings.rules.teaser.content_2" /></p>

              <Divider hidden section />

              <Grid>
                <Grid.Column width={8}>
                  <SecondaryButton fluid onClick={handleCloseModal}>
                    <FormattedMessage id="form.not-now" />
                  </SecondaryButton>
                </Grid.Column>
                <Grid.Column width={8}>
                  <PrimaryButton fluid onClick={handleOpenRuleForm}>
                    <FormattedMessage id="form.ok" />
                  </PrimaryButton>
                </Grid.Column>
              </Grid>
            </div>
          </Modal.Content>
        )}
      </Modal>
    </Transition>
  )
}


export default connect(mapStateToProps, mapDispatchToProps)(RecordAssignForm)
