import React, { useCallback, useMemo } from 'react'
import { RootState } from 'src/types/State'
import { connect } from 'react-redux'
import { Id } from 'src/types/CouchDb'
import { RulesList } from 'src/frontend/scenes/settings/magicRules/components/MagicRules'
import { MagicRulesFormValues, MagicRuleView } from 'src/frontend/scenes/settings/magicRules/types'
import * as magicRulesSelectors from 'src/frontend/scenes/settings/magicRules/selectors'
import * as magicRulesActions from 'src/frontend/scenes/settings/magicRules/actions'
import styles from './ContactDetailRules.module.less'
import 'src/frontend/scenes/settings/magicRules/components/MagicRules.less'
import { isUndefinedOrNull } from 'src/common/utils'
import { FormattedMessage } from 'src/frontend/modules/intl'
import AddButton from 'src/frontend/components/ColumnLayout/AddButton'
import _isEmpty from 'lodash/isEmpty'
import EmptyState from 'src/frontend/scenes/settings/components/EmptyState'

function mapStateToProps(state: RootState) {
  return {
    magicRules: magicRulesSelectors.selectMagicRulesView(state),
    canEditRules: magicRulesSelectors.selectCanAddEditRule(state),
  }
}

const mapDispatchToProps = {
  openEditForm: magicRulesActions.openEditForm,
  openAddForm: magicRulesActions.openAddForm,
}

interface Props {
  contactId: Id
  canEditRules: boolean
  magicRules: MagicRuleView[]

  openEditForm: (magicRuleId: Id) => void
  openAddForm: (predefinedValues?: Partial<MagicRulesFormValues>) => void
}

function ContactDetailRules({
  magicRules,
  contactId,
  canEditRules,
  openEditForm,
  openAddForm,
}: Props) {

  const contactRules = useMemo(() => {
    return magicRules.filter((magicRule) => {
      return !isUndefinedOrNull(magicRule.contactId) && magicRule.contactId === contactId
    })
  }, [magicRules, contactId])

  const handleOpenForm = useCallback(() => openAddForm({ contactId }), [contactId])

  return (
    <div className={styles.contactDetailRuleList}>
      {!_isEmpty(contactRules)
        ? (
          <div className="settings-page-list">
            <div className="rules-row list-header">
              <div className="rules-row-category list-header" />
              <div className="rules-row-name header"><FormattedMessage id="form.name" /></div>
              <div className="rules-row-keywords header"><FormattedMessage id="form.keywords" /></div>
              <div className="rules-row-record-type header"><FormattedMessage id="settings.rules.recordType" /></div>
              <div className="rules-row-labels header"><FormattedMessage id="record.form.labels" /></div>
              <div className="rules-row-toolbar" />
            </div>
            <RulesList
              canEditRules={canEditRules}
              items={contactRules}
              handleOpenForm={openEditForm}
            />
          </div>
        )
        : (
          <EmptyState
            title={<FormattedMessage id="contact.rules.empty.title" />}
            text={<FormattedMessage id="contact.rules.empty.text" />}
          />
        )}
      <AddButton
        onClick={handleOpenForm}
      />
    </div>
  )
}


export default connect(mapStateToProps, mapDispatchToProps)(ContactDetailRules)
