import React, { useCallback, useEffect } from 'react'
import styles from 'src/frontend/scenes/billing/components/Billing.module.less'
import { connect } from 'react-redux'
import { RootState } from 'src/types/State'
import FeaturesList from 'src/frontend/scenes/billing/product/components/FeaturesList'
import PlanList from 'src/frontend/scenes/billing/product/components/PlanList'
import { Id } from 'src/types/CouchDb'
import {
  selectAllBraintreeProducts,
  selectProductsError,
  selectProductsLoading,
} from 'src/frontend/scenes/billing/product/selectors'
import * as actions from 'src/frontend/scenes/billing/product/actions'
import { RouteChildrenProps, withRouter } from 'react-router'
import _isEmpty from 'lodash/isEmpty'
import { Billing } from 'src/types/Billing'
import * as mixpanel from 'src/common/mixpanel'
import { BillingUIMethod } from 'src/frontend/scenes/billing/enums'
import BraintreeProduct = Billing.BraintreeProduct
import { isAppBoard } from 'src/common/environment'
import * as gtm from 'src/common/gtm'
import { BrowserStorageAction, redirectAfterInitUrlLocalStorage } from "src/backend/db/localStorage"
import { selectAppSyncInitialized } from "src/frontend/scenes/app/selectors"

function mapStateToProps(state: RootState) {
  return {
    products: selectAllBraintreeProducts(state),
    error: selectProductsError(state),
    loading: selectProductsLoading(state),
    appInitialized: selectAppSyncInitialized(state),
  }
}

const mapDispatchToProps = {
  getProducts: actions.getProducts,
  selectProduct: actions.selectProduct,
}

interface Props extends RouteChildrenProps {
  loading: boolean
  products: BraintreeProduct[]
  error: string
  method: BillingUIMethod
  appInitialized: boolean
  selectProduct: (productId: Id, method: BillingUIMethod) => void
  getProducts: Function
}

function PlanSelect({
  loading,
  error,
  products,
  getProducts,
  selectProduct,
  appInitialized,
  history,
  method,
}: Props) {
  useEffect(() => {
    if (redirectAfterInitUrlLocalStorage(BrowserStorageAction.GET_ITEM) && appInitialized) {
      redirectAfterInitUrlLocalStorage(BrowserStorageAction.CLEAR_ITEM)
    }
  }, [])

  useEffect(() => {
    if (_isEmpty(products) && !loading) {
      mixpanel.trackProductsShow(method)
      gtm.showProducts()
      getProducts()
    }
  }, [products])

  const handleSelectProduct = useCallback((productId: Id) => {
    selectProduct(productId, method)
    if (method === BillingUIMethod.PAGE) {
      if (isAppBoard()) {
        history.push("/settings/billing/billing-information")
      } else {
        history.push("/settings/billing/payment-method")
      }
    }
  }, [])

  return (
    <div className={styles.fluid}>
      <div className={styles.features}>
        <FeaturesList />
      </div>
      <div className={styles.products}>
        <PlanList
          loading={loading}
          products={products}
          error={error}
          onSelectProduct={handleSelectProduct}
        />
      </div>
    </div>
  )
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PlanSelect))
