import { Dataset } from 'src/frontend/components/chart/types'
import { useMemo } from 'react'
import _isEmpty from 'lodash/isEmpty'
import { countPercentChange } from 'src/common/helpers'

export function useTotals(datasets: Dataset[]) {
  return useMemo(() => {
    if (!datasets || !datasets[0] || !datasets[1] || !datasets[0].data || !datasets[1].data) {
      return [null, null]
    }

    const [thisDataset, previousDataset] = datasets

    const totalValue = thisDataset.data[thisDataset.data.length - 1]
    const previousTotalValue = previousDataset.data[previousDataset.data.length - 1]
    const changeValue = countPercentChange(previousTotalValue, totalValue)

    return [
      totalValue * 100,
      changeValue,
    ]
  }, [datasets])
}
