import React from "react"
import { Store } from "redux"
import { FeatureToggleProvider } from "react-feature-toggles/lib"
import { Provider } from "react-redux"
import { isProduction } from "src/common/environment"
import { getFeatures } from "src/common/features"
import configureWebAppStore from "src/frontend/configureWebAppStore"
import App from "src/frontend/scenes/app/App"
import "src/frontend/styles/semantic-ui/semantic-web-app.less"

declare const window: CustomWindow

export const store: Store = (function () {
  if (isProduction()) {
    return configureWebAppStore()
  } else if (window.store) {
    return window.store
  } else {
    window.store = configureWebAppStore()
    return window.store
  }
})()

export default function WebAppRoot() {
  return (
    <Provider store={store}>
      <FeatureToggleProvider featureToggleList={getFeatures()}>
        <App />
      </FeatureToggleProvider>
    </Provider>
  )
}
