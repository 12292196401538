import React, { useState } from 'react'
import CirclePicker from 'react-color/lib/Circle'
import { Menu } from 'semantic-ui-react'
import { FormattedMessage } from 'src/frontend/modules/intl'
import './ColorIconPickerContent.less'

enum PickerPage {
  COLOR = 'color',
  ICON = 'icon',
}

interface Props {
  colors: string[]
  icons: string[]
  icon: string
  color: string
  onColorSelect: (MouseEvent) => void
  onIconSelect: (MouseEvent) => void
}

export default function ColorIconPickerContent({
  icons,
  icon,
  colors,
  color,
  onColorSelect,
  onIconSelect,
}: Props) {
  const [pickerPage, setPickerPage] = useState(PickerPage.COLOR)

  const menu = (
    <Menu fluid widths={2} compact>
      <Menu.Item
        name={PickerPage.COLOR}
        active={pickerPage === PickerPage.COLOR}
        onClick={(_e, { name }) => setPickerPage(name as PickerPage)}
      >
        <FormattedMessage id="form.color" />
      </Menu.Item>
      <Menu.Item
        name={PickerPage.ICON}
        active={pickerPage === PickerPage.ICON}
        onClick={(_e, { name }) => setPickerPage(name as PickerPage)}
      >
        <FormattedMessage id="form.icon" />
      </Menu.Item>
    </Menu>
  )

  switch (pickerPage) {
    default:
    case PickerPage.COLOR:
      return (
        <div className="color-icon-picker">
          {menu}
          <CirclePicker
            colors={colors}
            color={color}
            width="336px"
            onChangeComplete={onColorSelect}
          />
        </div>
      )

    case PickerPage.ICON:
      return (
        <div className="color-icon-picker">
          {menu}
          <ul className="icon-list">
            {icons.map((iconName) => (
              <li
                key={iconName}
                style={{ backgroundColor: iconName === icon ? color : undefined }}
                className={`icon-picker category-icon category-icon-${iconName}${iconName === icon ? ' selected' : ''}`}
                onClick={() => onIconSelect(iconName)}
              />
            ))}
          </ul>
        </div>
      )
  }
}
