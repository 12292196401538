import React from "react"
import { connect } from "react-redux"
import AnimatedBarTable from "src/frontend/components/AnimatedBarTable/AnimatedBarTable"
import { AnimatedBarTableData } from "src/frontend/components/AnimatedBarTable/types"
import Widget from "src/frontend/scenes/dashboard/components/Widget/Widget"
import { WidgetView } from "src/frontend/scenes/dashboard/types"
import { CashLiquidityData } from "src/frontend/scenes/dashboard/widgets/CashRatioLiquidity/types"
import { Menu, Modal } from "semantic-ui-react"
import { FormattedMessage, FormattedMoney } from "src/frontend/modules/intl"
import styles from "src/frontend/scenes/dashboard/widgets/CashRatioLiquidity/CashRatioLiquidity.module.less"
import { AccountType, Interval } from "src/backend/enums"
import { changeInterval as changeIntervalAction } from "src/frontend/scenes/dashboard/widgets/CashRatioLiquidity/actions"
import CashRatioLiquidityValueLabel from "src/frontend/scenes/dashboard/widgets/CashRatioLiquidity/CashRatioLiquidityValueLabel"
import { getCashLiquidityValues } from "src/frontend/scenes/dashboard/widgets/CashRatioLiquidity/helpers"
import { widgetHasDeepEqualProps } from "src/frontend/scenes/dashboard/helpers"
import { ComplementaryButton } from "src/frontend/components/Buttons/Buttons"
import widgetStyles from "src/frontend/scenes/dashboard/components/Widget/Widget.module.less"
import TruncatedText from "src/frontend/components/TruncatedText"

type Props = {
  widget: WidgetView & { data: CashLiquidityData }
  changeInterval: Function
}

const mapDispatchToProps = {
  changeInterval: changeIntervalAction,
}

const NUM_OF_ACCOUNTS = 3

function CashRatioLiquidity({ widget, changeInterval }: Props) {
  const { data, filter, id } = widget

  if (!data || !filter) {
    return <Widget.Placeholder />
  }

  const widgetAction = data.length > NUM_OF_ACCOUNTS && (
    <Modal
      size="small"
      trigger={
        <ComplementaryButton>
          <FormattedMessage id="show-more" />
        </ComplementaryButton>
      }
    >
      <Modal.Content className={widgetStyles.dashboardWidget}>
        <AnimatedBarTable data={convertCategoriesToBarTableData(data)} />
      </Modal.Content>
    </Modal>
  )

  const sampleData = React.useMemo(() => data.slice(0, NUM_OF_ACCOUNTS), [data])

  const content =
    data.length > 0 ? (
      <Widget.Content className={styles.cashRatioLiquidity}>
        <Menu
          fluid
          widths={3}
          compact
        >
          <Menu.Item
            name={Interval.RELATIVE_7_DAYS}
            active={filter.period.interval === Interval.RELATIVE_7_DAYS}
            onClick={(_e, { name }) => changeInterval(id, name)}
          >
            <FormattedMessage
              id="next-days"
              values={{ count: 7 }}
            />
          </Menu.Item>
          <Menu.Item
            name={Interval.RELATIVE_30_DAYS}
            active={filter.period.interval === Interval.RELATIVE_30_DAYS}
            onClick={(_e, { name }) => changeInterval(id, name)}
          >
            <FormattedMessage
              id="next-days"
              values={{ count: 31 }}
            />
          </Menu.Item>
          <Menu.Item
            name={Interval.RELATIVE_90_DAYS}
            active={filter.period.interval === Interval.RELATIVE_90_DAYS}
            onClick={(_e, { name }) => changeInterval(id, name)}
          >
            <FormattedMessage
              id="next-days"
              values={{ count: 90 }}
            />
          </Menu.Item>
        </Menu>

        <AnimatedBarTable data={convertCategoriesToBarTableData(sampleData)} />
      </Widget.Content>
    ) : (
      <Widget.EmptyContent type="bar" />
    )

  return (
    <Widget>
      <Widget.Header
        title={widget.title}
        action={widgetAction}
      />
      {content}
    </Widget>
  )
}

function convertCategoriesToBarTableData(data: CashLiquidityData[]): Array<AnimatedBarTableData> {
  return data
    .map((cashLiquidity: CashLiquidityData) => {
      return {
        id: cashLiquidity.accountId,
        values: getCashLiquidityValues(cashLiquidity),
        total: cashLiquidity.availableBalance,
        divided: true,
        label: (
          <TruncatedText
            text={cashLiquidity.accountName}
            length={25}
            showPopup
          />
        ),
        valueLabel: <CashRatioLiquidityValueLabel cashLiquidity={cashLiquidity} />,
        leftBottomLabel: (
          <p>
            <span className={styles.valueHeader}>
              <FormattedMessage id="planned_spending" />
            </span>
            &nbsp;
            <FormattedMoney
              value={cashLiquidity.expenses}
              currency={cashLiquidity.currencyCode}
            />
          </p>
        ),
        rightBottomLabel: (
          <p>
            <span className={styles.valueHeader}>
              <FormattedMessage
                id={
                  cashLiquidity.accountType !== AccountType.CREDIT_CARD
                    ? "dashboard.widget.current-balance"
                    : "dashboard.widget.available-credit"
                }
              />
            </span>
            &nbsp;
            <FormattedMoney
              value={cashLiquidity.availableBalance}
              currency={cashLiquidity.currencyCode}
            />
          </p>
        ),
      }
    })
    .sort((a, b) => a.values[1].value - b.values[1].value)
}

export default connect(
  null,
  mapDispatchToProps,
)(React.memo(CashRatioLiquidity, widgetHasDeepEqualProps))
