import { inMemoryTableNames } from "src/backend/db/inMemorySqlDbSchemaBuilder"
import * as commonRepository from "src/backend/common/repository"
import { convertToRecordDocument } from "src/backend/records/repository"
import * as categoriesRepository from "src/backend/categories/repository"
import * as labelsRepository from "src/backend/labels/repository"
import { standingOrderConverter } from "src/backend/converters/standingOrderConverter"
import { fetchAllFromTable, WalletSQLInMemory } from "src/backend/db/inMemorySqlDb"

export function findById(id) {
  const sqlDb = WalletSQLInMemory.getInstance()
  const standingOrderTable = sqlDb.getTable(inMemoryTableNames.STANDING_ORDER)
  return sqlDb
    .select()
    .from(standingOrderTable)
    .where(standingOrderTable._id.eq(id))
    .exec()
    .then((result) => result[0])
}

export function findAllAsHashMap() {
  return commonRepository.findAllAsHashMap(inMemoryTableNames.STANDING_ORDER)
}

export function getAllStandingOrders() {
  return fetchAllFromTable(inMemoryTableNames.STANDING_ORDER)
}

const convertStandingOrderToDocument = (standingOrderEntity) => {
  const convertedAsRecord = convertToRecordDocument(standingOrderEntity)
  const { recordDate, ...standingOrder } = convertedAsRecord

  return standingOrder
}

export async function updateBulk(recordEntities) {
  const standingOrderDocuments = recordEntities.map(convertStandingOrderToDocument)
  const [categories, labels] = await Promise.all([
    categoriesRepository.findAllAsHashMap(),
    labelsRepository.findAllAsHashMap(),
  ])

  return commonRepository.updateBulk(
    standingOrderDocuments,
    inMemoryTableNames.STANDING_ORDER,
    standingOrderConverter(categories, labels),
  )
}

export function findByCurrencyId(currencyId) {
  const sqlDb = WalletSQLInMemory.getInstance()
  const standingOrderTable = sqlDb.getTable(inMemoryTableNames.STANDING_ORDER)
  return sqlDb
    .select()
    .from(standingOrderTable)
    .where(standingOrderTable.currencyId.eq(currencyId))
    .exec()
}
