import { RECEIVE_HIDDEN_ENVELOPES } from 'src/frontend/modules/configures/hiddenEnvelopes/actions'
import { Action } from 'src/types/common'

const initState = []

export type HiddenEnvelopesState = Array<number>

export default function hiddenEnvelopes(state: HiddenEnvelopesState = initState, action: Action) {
  switch (action.type) {
    case RECEIVE_HIDDEN_ENVELOPES: {
      return action.payload
    }
    default: {
      return state
    }
  }
}
