import { getAppFlavor, isAppBoard } from 'src/common/environment'
import ENVELOPES from 'static/envelopes.json'
import BOARD_ENVELOPES from 'static/board-envelopes.json'
import { AbstractEnvelope } from "src/types/Category"
import { isUndefinedOrNull, reduceBy } from "src/common/utils"
import { HashMap } from "src/types/common"
import { EnvelopeCardinality } from "src/backend/categories/enums"

export const SYSTEM_CATEGORIES_ID = 200

const VIRTUAL_OTHERS_ID = 210
const SAVINGS_ID = 220
const RESERVES_ID = 230

export const SYSTEM_CATEGORIES_TRANSFER_ID = 20001
export const SYSTEM_CATEGORIES_UNKNOWN_ID = 20003
export const SYSTEM_CATEGORIES_ONE_CLICK_WIDGET = 20004
export const SYSTEM_CATEGORIES_BANK_STATEMENT = 20005

const SUPER_ENVELOPE_ID_THRESHOLD = 1000

const allowedToEditSystemEnvelopeIds = [SYSTEM_CATEGORIES_UNKNOWN_ID]

const INCOME_ID = 100
export const SALES_REVENUE_ID = 300
export const OTHER_REVENUE_ID = 310
export const NEW_FUNDING_ID = 320

export const SPACE_EQUIPMENT_ID = 330
export const PAYROLL_TRAVEL_ID = 340
export const INVENTORY_PURCHASE_ID = 350
const OPERATIONAL_SERVICES_ID = 360
export const OTHER_BILLS_CHARGES_ID = 370
export const LOANS_REPAYMENT_ID = 380
export const ASSETS_PURCHASE_ID = 390

export const INVENTORY_PURCHASE__MATERIAL = 30500

export const SALES_REVENUE__SERVICES = 30001
export const NEW_FUNDING__NEW_NOTES_BONDS = 30201

export const ASSETS_PURCHASE__LICENSES = 30904

const FOOD_AND_DRINKS__OTHERS = 1003
const SHOPPING__OTHERS = 2010
const HOUSING__OTHERS = 3005
const TRANSPORTATION__OTHERS = 4004
const VEHICLE__OTHERS = 5004
const LIFE_ENTERTAINMENT__OTHERS = 6013
const COMMUNICATION_PC__OTHERS = 7005
const FINANCIAL_EXPENSES__OTHERS = 8008
const INVESTMENTS__OTHERS = 9005
const INCOME__OTHERS = 10011
const OTHERS__OTHERS = 11000
const OTHERS__MISSING = 11001

const SALES_REVENUE__OTHERS = 30020
const OTHER_REVENUE__OTHERS = 30120
const NEW_FUNDING__OTHERS = 30220
const SPACE_EQUIPMENT__OTHERS = 30320
const PAYROLL_EXPENSES__OTHERS = 30420
const INVENTORY_PURCHASE__OTHERS = 30520
const OPERATIONAL_SERVICES__OTHERS = 30620
const OTHER_BILLS_CHARGES__OTHERS = 30720
const LOANS_REPAYMENT__OTHERS = 30820
const ASSETS_PURCHASE__OTHERS = 30920

export const generalEnvelopeIds = [
  FOOD_AND_DRINKS__OTHERS,
  SHOPPING__OTHERS,
  HOUSING__OTHERS,
  TRANSPORTATION__OTHERS,
  VEHICLE__OTHERS,
  LIFE_ENTERTAINMENT__OTHERS,
  COMMUNICATION_PC__OTHERS,
  FINANCIAL_EXPENSES__OTHERS,
  INVESTMENTS__OTHERS,
  INCOME__OTHERS,
  OTHERS__OTHERS,
  OTHERS__MISSING,

  // BOARD
  SALES_REVENUE__OTHERS,
  OTHER_REVENUE__OTHERS,
  NEW_FUNDING__OTHERS,
  SPACE_EQUIPMENT__OTHERS,
  PAYROLL_EXPENSES__OTHERS,
  INVENTORY_PURCHASE__OTHERS,
  SPACE_EQUIPMENT__OTHERS,
  PAYROLL_EXPENSES__OTHERS,
  INVENTORY_PURCHASE__OTHERS,
  OPERATIONAL_SERVICES__OTHERS,
  OTHER_BILLS_CHARGES__OTHERS,
  LOANS_REPAYMENT__OTHERS,
  ASSETS_PURCHASE__OTHERS,
]

export const incomeSuperEnvelopeIds = [
  INCOME_ID,
  SALES_REVENUE_ID,
  OTHER_REVENUE_ID,
  NEW_FUNDING_ID,
]

export const UNKNOWN_RECORDS_ENVELOPE_ID = 130

export const UNKNOWN_RECORDS_INCOME_ENVELOPE_ID = 13000
export const UNKNOWN_RECORDS_EXPENSE_ENVELOPE_ID = 13001

export const OPERATING_REVENUE_SUPER_ENVELOPE_IDS = [SALES_REVENUE_ID, OTHER_REVENUE_ID]

export const OTHER_REVENUE_SUPER_ENVELOPE_IDS = [NEW_FUNDING_ID]

export const OTHER_CASH_OUTFLOW_SUPER_ENVELOPE_IDS = [LOANS_REPAYMENT_ID, ASSETS_PURCHASE_ID]

export const FIXED_COSTS_SUPER_ENVELOPE_IDS = [
  SPACE_EQUIPMENT_ID,
  PAYROLL_TRAVEL_ID,
  OTHER_BILLS_CHARGES_ID,
]

export const VARIABLE_COSTS_SUPER_ENVELOPE_IDS = [INVENTORY_PURCHASE_ID, OPERATIONAL_SERVICES_ID]

export const OPERATING_COSTS_SUPER_ENVELOPE_IDS = [
  ...VARIABLE_COSTS_SUPER_ENVELOPE_IDS,
  ...FIXED_COSTS_SUPER_ENVELOPE_IDS,
]

export const INVESTMENTS_SUPER_ENVELOPE_IDS = [ASSETS_PURCHASE_ID]


const isSuperEnvelope = (envelope: AbstractEnvelope) => {
  return envelope.parentId === null
}

const isSystemEnvelope = (envelope: AbstractEnvelope): boolean => {
  const systemEnvelopeIds = [SYSTEM_CATEGORIES_ID, VIRTUAL_OTHERS_ID, SAVINGS_ID, RESERVES_ID]

  return systemEnvelopeIds.some(
    (systemEnvelopeId: number) =>
      systemEnvelopeId === envelope.id || envelope.parentId === SYSTEM_CATEGORIES_ID,
  )
}

export const isNotUnknownEnvelope = (envelope: AbstractEnvelope): boolean => {
  return (
    envelope.id !== UNKNOWN_RECORDS_ENVELOPE_ID || envelope.parentId !== UNKNOWN_RECORDS_ENVELOPE_ID
  )
}

function getAllEnvelopes(): HashMap<AbstractEnvelope> {
  return isAppBoard()
    ? {
        ...convertCardinality(ENVELOPES),
        ...convertCardinality(BOARD_ENVELOPES),
      }
    : convertCardinality(ENVELOPES)
}

export function getSuperEnvelopes(): AbstractEnvelope[] {
  return Object.values(getAllEnvelopes())
    .filter(
      (envelope: AbstractEnvelope) => isSuperEnvelope(envelope) && !isSystemEnvelope(envelope),
    )
    .filter((superEnvelope: AbstractEnvelope) => superEnvelope.app === getAppFlavor())
}

export function isAllowedToEditSystemEnvelope(envelope: AbstractEnvelope): boolean {
  return !!allowedToEditSystemEnvelopeIds.find(
    (systemEnvelopeId) => systemEnvelopeId === envelope.id,
  )
}

export function getAllowedToEditSystemEnvelopes(): AbstractEnvelope[] {
  return Object.values(getAllEnvelopes()).filter((envelope: AbstractEnvelope) => {
    return allowedToEditSystemEnvelopeIds.find((systemEnvelopeId: number) => {
      return systemEnvelopeId === envelope.id
    })
  })
}

export function getSuperEnvelopeIdByEnvelopeId(envelopeId: number): number {
  return getAllEnvelopes()[envelopeId].parentId
}

export function getSuperEnvelopesWithoutUnknown(): AbstractEnvelope[] {
  return getSuperEnvelopes().filter(isNotUnknownEnvelope)
}

export function getEnvelopes(): AbstractEnvelope[] {
  return Object.values(getAllEnvelopes())
    .filter((envelope: AbstractEnvelope) => {
      return (
        (!isSuperEnvelope(envelope) && !isSystemEnvelope(envelope)) ||
        allowedToEditSystemEnvelopeIds.includes(envelope.id)
      )
    })
    .filter((envelope: AbstractEnvelope) => {
      const superEnvelope = getEnvelopeById(envelope.parentId)
      return isUndefinedOrNull(superEnvelope.app) || superEnvelope.app === getAppFlavor()
    })
    .map((envelope: AbstractEnvelope) => {
      const superEnvelope = getEnvelopeById(envelope.parentId)
      return { ...envelope, color: envelope.color || superEnvelope.color }
    })
}

export function getEnvelopesBySuperEnvelopeId(superEnvelopeId: number): AbstractEnvelope[] {
  return getEnvelopes().filter(
    (envelope: AbstractEnvelope) => envelope.parentId === superEnvelopeId,
  )
}

export function getEnvelopesBySuperEnvelopeIds(superEnvelopeIds: number[]): AbstractEnvelope[] {
  return getEnvelopes().filter((envelope: AbstractEnvelope) =>
    superEnvelopeIds.includes(envelope.parentId),
  )
}

export function getEnvelopesByCardinality(cardinality: EnvelopeCardinality): AbstractEnvelope[] {
  return getEnvelopes().filter((envelope: AbstractEnvelope) => envelope.cardinality === cardinality)
}

export function getEnvelopeById(envelopeId: number): AbstractEnvelope {
  const envelope = getAllEnvelopes()[envelopeId && envelopeId.toString()]
  if (envelope && !envelope.color) {
    const superEnvelope = getEnvelopeById(envelope.parentId)
    return { ...envelope, color: superEnvelope.color }
  }

  return envelope
}

export function getEnvelopeByIds(envelopeIds: number[]): AbstractEnvelope[] {
  return Object.values(getAllEnvelopes())
    .filter((envelope) => envelopeIds.includes(envelope.id))
    .map((envelope) => {
      if (envelope && !envelope.color) {
        const superEnvelope = getEnvelopeById(envelope.parentId)
        return { ...envelope, color: superEnvelope.color }
      }

      return envelope
    })
}

export function getEnvelopesMap(): HashMap<AbstractEnvelope> {
  return Object.values(getAllEnvelopes())
    .filter((envelope: AbstractEnvelope) => !isSuperEnvelope(envelope))
    .reduce(reduceBy("id"), {})
}

export function getTransferSystemEnvelope(): AbstractEnvelope {
  return getEnvelopeById(SYSTEM_CATEGORIES_TRANSFER_ID)
}

export function getSystemEnvelope(): AbstractEnvelope {
  return getEnvelopeById(SYSTEM_CATEGORIES_ID)
}

export function isSuperEnvelopeId(envelopeId: number): boolean {
  return envelopeId < SUPER_ENVELOPE_ID_THRESHOLD
}

export function isEnvelopeId(envelopeId: number): boolean {
  return envelopeId >= SUPER_ENVELOPE_ID_THRESHOLD
}

export function isSuperEnvelopeHidden(superEnvelopeId, hiddenEnvelopes) {
  const childEnvelopes = getEnvelopesBySuperEnvelopeId(superEnvelopeId)

  return (
    childEnvelopes.length > 0 &&
    childEnvelopes.every((envelope) => hiddenEnvelopes.includes(envelope.id))
  )
}

export function isUnknownEnvelopeByEnvelopeId(envelopeId: number): boolean {
  return envelopeId === SYSTEM_CATEGORIES_UNKNOWN_ID
}

function convertCardinality(envelopes: any): HashMap<AbstractEnvelope> {

  const envelopesArr = Object.entries(envelopes).map(([key, value]) => {
    return [key, envelopeFromObject(value)]
  })

  return Object.fromEntries(envelopesArr)
}

function envelopeFromObject(obj: any): AbstractEnvelope {
  switch (obj.cardinality) {
    case "must":
      return { ...obj, cardinality: EnvelopeCardinality.MUST }
    case "need":
      return { ...obj, cardinality: EnvelopeCardinality.NEED }
    case "want":
      return { ...obj, cardinality: EnvelopeCardinality.WANT }
    case "fixed-costs":
      return { ...obj, cardinality: EnvelopeCardinality.FIXED_COSTS }
    case "variable-costs":
      return { ...obj, cardinality: EnvelopeCardinality.VARIABLE_COSTS }
    case "assets-costs":
      return { ...obj, cardinality: EnvelopeCardinality.ASSETS_COSTS }
    case "revenue":
      return { ...obj, cardinality: EnvelopeCardinality.REVENUE }
    default:
      return { ...obj, cardinality: EnvelopeCardinality.NONE }
  }
}

export function getSystemCategoryTypeByEnvelopeId(envelopeId: number): number {
  // DEBT and SHOPPING_LIST are not used anymore
  switch (envelopeId) {
    case SYSTEM_CATEGORIES_TRANSFER_ID:
      return 1
    case SYSTEM_CATEGORIES_ONE_CLICK_WIDGET:
      return 3
    case SYSTEM_CATEGORIES_BANK_STATEMENT:
      return 4
    case SYSTEM_CATEGORIES_UNKNOWN_ID:
      return 5
  }
}