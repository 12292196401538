import React from 'react'
import { FormattedMessage } from 'src/frontend/modules/intl'
import styles from './PremiumChecker.module.less'

interface Props {
  featureString: string
  handleClose?: Function,
  button: React.ReactNode
}

export default function PremiumCheckerContent({ featureString, button }: Props) {
  return (
    <div className={styles.premiumCheckerContent}>
      <div className={styles.premiumIcon} />
      <div className={styles.premiumText}>
        <p>
          <strong><FormattedMessage id="premium_checker.upgrade_to_premium" /></strong>
        </p>
        <p>
          <FormattedMessage id={featureString} />
        </p>
      </div>
      <div className={styles.premiumButton}>
        {button}
      </div>
    </div>
  )
}
