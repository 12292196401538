export function buildAccountOptions(accountList) {
  // eslint-disable-next-line no-

  const accountsOptions = accountList.map((account) => ({
    text: account.name,
    value: account._id,
  }))
  console.log(accountsOptions)
  return accountsOptions
}
