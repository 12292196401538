import React from 'react'
import { Dropdown } from 'semantic-ui-react'
import styles from './ViewDefinitionForm.module.less'

type DropDownLabel = string | React.ReactNode

type DropDownOption = {
  value: any,
  text: DropDownLabel,
}

type Props = {
  name: string,
  label: DropDownLabel,
  value: string,
  showIcon?: boolean,
  disabled?: boolean,
  options: Array<DropDownOption>,
  onChange: Function,
}

ViewDefinitionItem.defaultProps = {
  showIcon: false,
  disabled: false,
}

export default function ViewDefinitionItem({ name, showIcon, label, value, options, disabled, onChange }: Props) {


  return (
    <div className={styles.analyticsViewDefinitionItem}>
      <span className={styles.analyticsViewDefinitionLabel}>
        {label}
      </span>
      <Dropdown
        inline
        disabled={disabled}
        name={name}
        value={value}
        options={options}
        icon={showIcon ? 'dropdown' : null}
        onChange={(_e, data) => {
          if (data.value !== value) {
            onChange(data.name, data.value)
          } else {
            console.warn(`ViewDefinitionItem: Calling onChange on the same value: ${value}`)
          }
        }}
      />
    </div>
  )
}
