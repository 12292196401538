import { createSelector } from 'reselect'
import { pipe } from 'ramda'
import _isEmpty from 'lodash/isEmpty'
import _difference from 'lodash/difference'
import { selectImportsState } from 'src/frontend/scenes/imports/local-selectors'
import { ImportMappingAttributeType } from 'src/backend/enums'
import {
  COLUMN_UNSET_INDEX,
  IMPORT_MAPPING_ATTRIBUTE_ORDER,
  WIZARD_STEPS_ORDER, WizardStepAttributes,
} from 'src/frontend/scenes/imports/mapping/constants'
import { createColumnName } from 'src/frontend/scenes/imports/mapping/helpers'
import { selectUploadedItems } from 'src/frontend/scenes/imports/items/selectors'
import { isType, mapType } from 'src/common/utils'

const {
  AMOUNT, EXPENSE, RECORDDATE, PAYEE, NOTE, CURRENCYCODE, CATEGORYMAPPING, FEE, UNKNOWN, UNSET,
} = ImportMappingAttributeType

const selectMapping = importsState => importsState.mapping

const selectWizard = mappingState => mappingState.wizard

export const selectMappingState = pipe(selectImportsState, selectMapping)

export const selectWizardState = pipe(selectImportsState, selectMapping, selectWizard)

// Wizard selectors

export const selectIsMappingValidationRequired = state => selectMappingState(state).validationRequired

export const selectWizardCurrentStep = state => selectWizardState(state).wizardStep

export const selectWizardCurrentStepPosition = state => (
  WIZARD_STEPS_ORDER.indexOf(selectWizardCurrentStep(state)) + 1
)

export const selectWizardGoPrevEnabled = state => !selectWizardState(state).firstStep

export const selectWizardGoNextEnabled = state => !selectWizardState(state).lastStep

export const selectWizardShowPreviewEnabled = state => !!selectWizardState(state).lastStep

// Selecting columns, attributes

export const selectColumns = pipe(selectMappingState, mappingState => mappingState.columns)

const generateColumnHeaderName = (columns) => columns.map((value, index) => ({
  colName: createColumnName(index),
  colIndex: index,
}))

export const selectColumnHeaders = createSelector(
  [selectMappingState],
  ({ settings, rowValues, parsedExample }) => {
    const headerRowIndex = settings.firstRecord - 1
    if (settings.skipHeader) {
      return rowValues[headerRowIndex].value.map((value, index) => ({
        // also generate header identification in case of value is missing (not defined or '')
        colName: value || createColumnName(index),
        colIndex: index,
      }))
    } else {
      return generateColumnHeaderName(parsedExample)
    }
  },
)

export const selectGeneratedColumnHeaders = createSelector(
  [selectMappingState],
  ({ settings, rowValues, parsedExample }) => {
    if (settings.skipHeader) {
      const headerRowIndex = settings.firstRecord - 1
      return generateColumnHeaderName(rowValues[headerRowIndex].value)
    } else {
      return generateColumnHeaderName(parsedExample)
    }
  },
)

export const selectImportingValues = pipe(selectMappingState, mappingState => mappingState.rowValues)

// This part is hack to ensure that all required attributes are always assigned otherwise
// backend responds with 404 (yes intentionally 404). If user wants to swap amount columns then client
// must ensure that at least one amount columns is assigned before we call backend. Also we must ensure
// that user cannot assign Date column to Amount column (even just for fun) because it means that Date is
// not assigned at time of transition from Amount to Date
const attributesInDateStep = [RECORDDATE]
const attributesInAmountStep = [AMOUNT, EXPENSE, FEE]
const attributesBeforeOptionalStep = [...attributesInAmountStep, ...attributesInDateStep]

const buildIncludeTypeFilter = columnType => {
  if (attributesInAmountStep.includes(columnType)) {
    return ({ type }) => !attributesInDateStep.includes(type)
  } else if (attributesInDateStep.includes(columnType)) {
    return ({ type }) => !attributesInAmountStep.includes(type)
  } else {
    return ({ type }) => !attributesBeforeOptionalStep.includes(type)
  }
}

export const buildAvailableColumnAggregator = columnType => {
  return (assignedColumns, columnHeaders, requiredAttributes) => {
    const availableColumnIndexes = assignedColumns
      .filter(buildIncludeTypeFilter(columnType))
      .map(column => column.colIndex)
    const columns = columnHeaders.filter(column => (availableColumnIndexes.includes(column.colIndex)))
    if (!requiredAttributes.includes(columnType)) {
      columns.unshift({
        type: UNSET,
        colIndex: COLUMN_UNSET_INDEX,
        colName: '',
      })
    }
    return columns
  }
}

export const selectRequiredAttributeTypes = createSelector(
  [selectMappingState],
  mappingState => {
    const { requiredAttributeTypes, settings: { hasSeparateFees, hasSeparateIncomeExpense } } = mappingState
    const result = [...requiredAttributeTypes]
    if (hasSeparateIncomeExpense) {
      result.push(ImportMappingAttributeType.EXPENSE)
    }
    if (hasSeparateFees) {
      result.push(ImportMappingAttributeType.FEE)
    }
    return result
  },
)

export const selectAmountAvailableColumnHeaders = createSelector(
  [selectColumns, selectColumnHeaders, selectRequiredAttributeTypes],
  buildAvailableColumnAggregator(AMOUNT),
)

export const selectExpenseAvailableColumnHeaders = createSelector(
  [selectColumns, selectColumnHeaders, selectRequiredAttributeTypes],
  buildAvailableColumnAggregator(EXPENSE),
)

export const selectFeeAvailableColumnHeaders = createSelector(
  [selectColumns, selectColumnHeaders, selectRequiredAttributeTypes],
  buildAvailableColumnAggregator(FEE),
)

export const selectDateAvailableColumnHeaders = createSelector(
  [selectColumns, selectColumnHeaders, selectRequiredAttributeTypes],
  buildAvailableColumnAggregator(RECORDDATE),
)

export const selectNoteAvailableColumnHeaders = createSelector(
  [selectColumns, selectColumnHeaders, selectRequiredAttributeTypes],
  buildAvailableColumnAggregator(NOTE),
)

export const selectPayeeAvailableColumnHeaders = createSelector(
  [selectColumns, selectColumnHeaders, selectRequiredAttributeTypes],
  buildAvailableColumnAggregator(PAYEE),
)

export const selectCurrencyAvailableColumnHeaders = createSelector(
  [selectColumns, selectColumnHeaders, selectRequiredAttributeTypes],
  buildAvailableColumnAggregator(CURRENCYCODE),
)

export const selectCategoryAvailableColumnHeaders = createSelector(
  [selectColumns, selectColumnHeaders, selectRequiredAttributeTypes],
  buildAvailableColumnAggregator(CATEGORYMAPPING),
)

const buildColumnTypeSelector = columnType => (columns, requiredTypes) => {
  const unsetValue = requiredTypes.includes(columnType) ? undefined : UNSET
  const column = columns.find(isType(columnType))
  return column !== undefined ? column.colIndex : unsetValue
}

export const selectDateColumnIndex = createSelector(
  [selectColumns, selectRequiredAttributeTypes],
  buildColumnTypeSelector(RECORDDATE),
)

export const selectAmountColumnIndex = createSelector(
  [selectColumns, selectRequiredAttributeTypes],
  buildColumnTypeSelector(AMOUNT),
)

export const selectExpenseColumnIndex = createSelector(
  [selectColumns, selectRequiredAttributeTypes],
  buildColumnTypeSelector(EXPENSE),
)

export const selectFeeColumnIndex = createSelector(
  [selectColumns, selectRequiredAttributeTypes],
  buildColumnTypeSelector(FEE),
)

export const selectNoteColumnIndex = createSelector(
  [selectColumns, selectRequiredAttributeTypes],
  buildColumnTypeSelector(NOTE),
)

export const selectPayeeColumnIndex = createSelector(
  [selectColumns, selectRequiredAttributeTypes],
  buildColumnTypeSelector(PAYEE),
)

export const selectCurrencyColumnIndex = createSelector(
  [selectColumns, selectRequiredAttributeTypes],
  buildColumnTypeSelector(CURRENCYCODE),
)

export const selectCategoryColumnIndex = createSelector(
  [selectColumns, selectRequiredAttributeTypes],
  buildColumnTypeSelector(CATEGORYMAPPING),
)

export const selectAssignedAttributeTypes = createSelector(
  [selectColumns],
  (columns) => columns
    .filter(column => column.type !== UNKNOWN)
    .map(column => column.type),
)

export const selectMissingRequiredAttributeTypes = createSelector(
  [selectAssignedAttributeTypes, selectRequiredAttributeTypes],
  (assignedAttributes, requiredAttributes) => _difference(requiredAttributes, assignedAttributes),
)

export const selectAssignedColumnsIndexes = createSelector(
  [selectColumns, selectWizardCurrentStep],
  (columns, wizardStep) => {
    return columns.filter(({ type }) => WizardStepAttributes[wizardStep].includes(type)).map(({ colIndex }) => colIndex)
  },
)

export const selectTableErrors = createSelector(
  [selectMappingState],
  ({ rowValues, error }) => {
    const tableErrors = Array(rowValues.length)
    if (_isEmpty(error)) {
      return tableErrors
    }
    const errorLineIndex = error.line - 1
    tableErrors[errorLineIndex] = Array(rowValues[errorLineIndex].length)
    error.tokens.forEach(token => { tableErrors[errorLineIndex][token.colIndex] = token })
    return tableErrors
  },
)

export const selectSeparateIncomeExpenses = createSelector(
  [selectMappingState],
  (mappingState) => (mappingState.settings.hasSeparateIncomeExpense),
)

export const selectSeparateFees = createSelector(
  [selectMappingState],
  (mappingState) => (mappingState.settings.hasSeparateFees),
)

// Mapping error handling errors

export const selectAttributeMappingError = pipe(selectMappingState, mappingState => mappingState.error)

export const selectIsMappingError = createSelector(
  [selectAttributeMappingError],
  (attributeMappingError) => (!_isEmpty(attributeMappingError)),
)

export const selectIsMultipleAttributeMappingErrors = createSelector(
  [selectAttributeMappingError],
  (attributeMappingError = {}) => {
    if (!Array.isArray(attributeMappingError.tokens)) {
      return false
    } else {
      const errorTypes = attributeMappingError.tokens.map(mapType)
      return new Set(errorTypes).size > 1
    }
  },
)

// First row, Last row, Has header selectors

export const selectRowHeaderProperties = createSelector(
  [selectMappingState],
  (mappingState) => {
    const { firstRecord, lastRecord, lastPossibleRecord, skipHeader } = mappingState.settings
    return {
      firstRecord,
      hasHeader: skipHeader,
      couldHasHeader: mappingState.rowValues.length > 1 && firstRecord !== mappingState.rowValues.length,
      lastPossibleRecord,
      lastRecord,
    }
  },
)

export const selectHeaderRowPosition = pipe(
  selectRowHeaderProperties,
  (rowHeaderProperties) => rowHeaderProperties.firstRecord,
)

export const selectFirstRecordRowPosition = createSelector(
  [selectRowHeaderProperties],
  (rowHeaderProperties) => {
    const { hasHeader, firstRecord } = rowHeaderProperties
    return hasHeader ? firstRecord + 1 : firstRecord
  },
)

export const selectLastRecordRowPosition = pipe(selectRowHeaderProperties, properties => properties.lastRecord)


// Preview selectors


export const selectPreviewHeaders = createSelector(
  [selectAssignedAttributeTypes],
  (assignedAttributeTypes) => (
    IMPORT_MAPPING_ATTRIBUTE_ORDER.filter(attributeType => assignedAttributeTypes.includes(attributeType))
  ),
)

export const selectPreviewData = createSelector(
  [selectMappingState, selectColumns, selectPreviewHeaders],
  (mappingState, columns, headers) => {
    const { firstRecord, lastRecord, skipHeader } = mappingState.settings
    const columnIndexesToShow = headers.map(type => columns.findIndex(column => column.type === type))
    return mappingState.rowValues
      .slice(firstRecord - (skipHeader ? 0 : 1), lastRecord)
      .map(rowValue => columnIndexesToShow.map(columnIndex => rowValue.value[columnIndex]))
  },
)

export const selectIsPreviewOpen = createSelector(
  [selectMappingState],
  ({ previewOpen }) => previewOpen,
)

export const selectImportingItemId = pipe(selectMappingState, mappingState => mappingState.currentItemId)

export const selectImportingItemName = createSelector(
  [selectUploadedItems, selectImportingItemId],
  (uploadedItems, importingItemId) => {
    return (uploadedItems.filter(item => item.itemId === importingItemId)[0] || { fileName: '' }).fileName
  },
)

export const selectIsMappingSaving = (state) => selectMappingState(state).saveLoading
