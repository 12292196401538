import { Action } from 'src/types/common'
import { AccountsListOrderBy } from 'src/frontend/scenes/accounts/accountsList/enums'
import { ACCOUNTS_LIST_CHANGE_ORDER } from 'src/frontend/scenes/accounts/accountsList/actions'

export interface AccountListState {
  order: AccountsListOrderBy
}

const initState: AccountListState = {
  order: AccountsListOrderBy.POSITION_ASC,
}

export default function accountList(state: AccountListState = initState, action: Action): AccountListState {
  switch (action.type) {

    case ACCOUNTS_LIST_CHANGE_ORDER: {
      return { ...state, order: action.payload }
    }

    default: {
      return state
    }
  }
}
