import { combineReducers } from 'redux'
import accountList, { AccountListState } from 'src/frontend/scenes/accounts/accountsList/reducer'
import { accountFilter, AccountFilterState } from 'src/frontend/scenes/accounts/filter/reducer'
import { accountDetail, AccountDetailState } from 'src/frontend/scenes/accounts/accountDetail/reducer'


export interface AccountsState {
  accountList: AccountListState,
  accountFilter: AccountFilterState,
  accountDetail: AccountDetailState,
}

export default combineReducers({ accountList, accountFilter, accountDetail })
