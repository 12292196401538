import * as mixpanel from 'src/common/mixpanel'
import { Action, GetState, ThunkFunction } from 'src/types/common'
import { OnBoardingStatus } from 'src/backend/onBoarding/types'
import { OnBoardingStage } from 'src/frontend/scenes/onBoarding/firstRun/enums'
import { parseIntDecimal } from 'src/common/utils'
import { selectUser } from 'src/frontend/modules/user/selectors'
import { getCurrencyCodeByCountry } from 'src/backend/currencies/countriesCurrencies'
import {
  selectOnBoardingFormValues,
  selectOnBoardingStage,
  selectOnBoardingStatus,
  selectPreferBankIntegration,
  selectShouldAddVoucher,
  selectVoucherCode,
} from 'src/frontend/scenes/onBoarding/firstRun/selectors'
import * as accountsService from 'src/backend/accounts/service'
import * as currenciesService from 'src/backend/currencies/service'
import * as currenciesActions from 'src/frontend/modules/currencies/actions'
import {
  expireAccountsStatus,
  expireAnalyticsStatus,
  expireDashboardStatus,
  expireDetailRecordsStatus,
  expireImportsRecordListStatus,
  expireRecordsStatus,
} from 'src/frontend/modules/moduleStatus/actions'
import { initAuthenticatedAppEnd } from 'src/frontend/scenes/app/actions'
import { AccountType } from 'src/backend/enums'
import * as accountsActions from 'src/frontend/modules/accounts/actions'
import { getRandomColor } from 'src/backend/colors/colors'
import { AccountFormValues } from 'src/frontend/scenes/settings/accounts/types'
import { isAppBoard } from 'src/common/environment'
import { initDashboard } from 'src/frontend/scenes/dashboard/actions'
import { formatMessage } from 'src/frontend/modules/intl'
import { isKBUser } from 'src/backend/user/service'
import { clearAndCloseForm } from 'src/frontend/scenes/integrations/providersList/actions'
import { RootState } from 'src/types/State'
import { openModalAddVoucher } from 'src/frontend/modules/modals/actions'
import * as gtm from 'src/common/gtm'

export const ON_BOARDING_STAGE_SET_START = 'onBoarding/ON_BOARDING_STAGE_SET_START'
export const ON_BOARDING_STAGE_SET_END = 'onBoarding/ON_BOARDING_STAGE_SET_END'
export const ON_BOARDING_CHANGE_VALUE = 'onBoarding/ON_BOARDING_CHANGE_VALUE'
export const ON_BOARDING_GOTO_NEXT = 'onBoarding/ON_BOARDING_GOTO_NEXT'
export const ON_BOARDING_START = 'onBoarding/ON_BOARDING_START'
export const ON_BOARDING_FINISH_SCREEN = 'onBoarding/ON_BOARDING_FINISH_SCREEN'
export const ON_BOARDING_END = 'onBoarding/ON_BOARDING_END'
export const ON_BOARDING_TOGGLE_PREFER_BANK_INTEGRATION = 'onBoarding/ON_BOARDING_TOGGLE_PREFER_BANK_INTEGRATION'
export const SET_SHOULD_ADD_VOUCHER = 'onBoarding/SET_SHOULD_ADD_VOUCHER'

export function changeValue(name: string, value: string | number): Action<{ name: string, value: string | number }> {
  return {
    type: ON_BOARDING_CHANGE_VALUE,
    payload: {
      name,
      value,
    },
  }
}

function setStageStart() {
  return async (dispatch: Function, getState: GetState) => {
    gtm.onboardingStart()
    return dispatch({
      type: ON_BOARDING_STAGE_SET_START,
    })
  }
}

function setStageEnd(): ThunkFunction {
  return (dispatch: Function, getState: GetState) => {
    gtm.onboardingFinished()
    return dispatch({
      type: ON_BOARDING_STAGE_SET_END,
    })
  }
}

export function createBaseCurrency() {
  return async (dispatch: Function, getState: GetState) => {
    const user = selectUser(getState())
    const { currencyCode } = selectOnBoardingFormValues(getState())
    dispatch(setStageStart())

    await currenciesService.saveCurrency({ code: currencyCode, referential: true, ratioToReferential: 1 }, user)
    await dispatch(currenciesActions.fetchCurrencies())
    dispatch(expireDashboardStatus())
    dispatch(expireAccountsStatus())
    dispatch(expireAnalyticsStatus())
    dispatch(expireRecordsStatus())
    dispatch(expireDetailRecordsStatus())
    dispatch(expireImportsRecordListStatus())
    mixpanel.trackBaseCurrencySet()

    dispatch(setStageEnd())
  }
}


export function createFirstAccount() {
  return async (dispatch: Function, getState: GetState) => {
    const user = selectUser(getState())
    const { accountName, cashBalance } = selectOnBoardingFormValues(getState())
    dispatch(setStageStart())

    // in Board, user sets name of the first account, in Wallet, user sets init balance of the first cash account
    const initAmount = isAppBoard() ? 0 : cashBalance
    const name = isAppBoard() ? accountName.trim() : formatMessage('account.type.1')
    const accountType = isAppBoard() ? AccountType.GENERAL : AccountType.CASH
    const color = getRandomColor()

    await accountsService.saveAccount({
      color,
      name,
      accountType,
      initAmount,
    } as AccountFormValues, user)

    isAppBoard() ? mixpanel.trackAccountNameSet() : mixpanel.trackCashBalanceSet()

    await dispatch(accountsActions.fetchAccounts())
    dispatch(expireDashboardStatus())
    dispatch(expireAccountsStatus())
    dispatch(expireAnalyticsStatus())
    dispatch(expireRecordsStatus())
    dispatch(expireDetailRecordsStatus())
    dispatch(expireImportsRecordListStatus())

    dispatch(setStageEnd())
  }
}

export function onBoardingStart(onBoardingStatus: OnBoardingStatus) {
  return (dispatch: Function, getState: GetState) => {
    const user = selectUser(getState())

    const preferBankIntegration = isAppBoard() && isKBUser(user)

    const initialCurrencyCode = getCurrencyCodeByCountry(user.countryCode)

    dispatch({
      type: ON_BOARDING_START,
      payload: {
        onBoardingStatus,
        stage: getOnBoardingStage(onBoardingStatus),
        currencyCode: initialCurrencyCode,
        preferBankIntegration,
      },
    })
  }
}

export function gotoNextStage() {
  return (dispatch: Function, getState: GetState) => {
    const stage = selectOnBoardingStage(getState())
    const status = selectOnBoardingStatus(getState())

    const nextStatus = {
      ...status,
      [stage]: true,
    }

    const nextStage = getOnBoardingStage(nextStatus)

    if (!nextStage) {
      return dispatch(gotoFinishStage())
    }

    dispatch(goToNext(nextStage, nextStatus))
  }
}

export function gotoFinishStage() {
  return (dispatch: Function) => {
    dispatch(onBoardingFinishScreen())
    dispatch(clearAndCloseForm())

    return dispatch(initAuthenticatedAppEnd())
  }
}

export function togglePreferBankIntegrationStage() {
  return (dispatch: Function, getState: GetState) => {
    const stage = selectOnBoardingStage(getState())
    const status = selectOnBoardingStatus(getState())
    const preferBankIntegration = selectPreferBankIntegration(getState())
    if (stage === OnBoardingStage.ACCOUNT_NAME || stage === OnBoardingStage.KB_BANK_INTEGRATION) {
      const newStage = preferBankIntegration
        ? OnBoardingStage.ACCOUNT_NAME
        : OnBoardingStage.KB_BANK_INTEGRATION

      const newOnBoardingStatus = {
        ...status,
        [OnBoardingStage.KB_BANK_INTEGRATION]: OnBoardingStage.KB_BANK_INTEGRATION !== newStage,
        [OnBoardingStage.ACCOUNT_NAME]: OnBoardingStage.ACCOUNT_NAME !== newStage,
      }

      return dispatch({
        type: ON_BOARDING_TOGGLE_PREFER_BANK_INTEGRATION,
        payload: {
          stage: newStage,
          onBoardingStatus: newOnBoardingStatus,
        },
      })
    }
  }
}

function goToNext(nextStage: OnBoardingStage, nextStatus: OnBoardingStatus) {
  return {
    type: ON_BOARDING_GOTO_NEXT,
    payload: {
      stage: nextStage,
      onBoardingStatus: nextStatus,
    },
  }
}

export function onBoardingFinishScreen() {
  return (dispatch: Function) => {
    dispatch(initDashboard())
    mixpanel.trackOnBoardingSuccess()
    dispatch({
      type: ON_BOARDING_FINISH_SCREEN,
    })
  }
}

export function endOnBoarding() {
  return (dispatch: Function, getState: () => RootState) => {
    if (selectShouldAddVoucher(getState())) {
      dispatch(openModalAddVoucher(selectVoucherCode(getState())))
    }
    return dispatch({
      type: ON_BOARDING_END,
    })
  }
}

/**
 * Store "shouldAddVoucher" flag to app state in order to open Add voucher modal after onboarding finished.
 */
export function setShouldAddVoucher(voucherCode: string) {
  return ({
    type: SET_SHOULD_ADD_VOUCHER,
    payload: { voucherCode },
  })
}

function getOnBoardingStage(onBoardingStatus: OnBoardingStatus): OnBoardingStage {
  return Object.keys(onBoardingStatus).map(parseIntDecimal).find((status) => !onBoardingStatus[status])
}
