import { combineReducers } from 'redux'
import * as actions from 'src/frontend/modules/modals/actions'
import { AddVoucherModalStatus } from 'src/frontend/modules/modals/components/AddVoucherTypes'

export type UserSessionState = {
  expired: boolean,
}

const sessionExpiredInitialState: UserSessionState = {
  expired: false,
}

function userSession(state = sessionExpiredInitialState, action) {
  if (action.type === actions.SESSION_EXPIRED) {
    return { ...state, expired: true }
  } else {
    return state
  }
}

export type AddVoucherModalState = {
  modalStatus: AddVoucherModalStatus,
  voucherCode: string,
}

const addVoucherInitialState: AddVoucherModalState = {
  modalStatus: AddVoucherModalStatus.CLOSED,
  voucherCode: '',
}

function addVoucher(state = addVoucherInitialState, action): AddVoucherModalState {
  if (action.type === actions.SET_ADD_VOUCHER_MODAL_STATUS) {
    return { ...state, ...action.payload }
  } else {
    return state
  }
}

export type ModalsState = {
  userSession: UserSessionState,
  addVoucher: AddVoucherModalState,
}

export default combineReducers({ userSession, addVoucher })
