import React, { useCallback, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import styles from 'src/frontend/scenes/billing/paymentMethod/components/PaymentMethod.module.less'
import * as actions from '../actions'
import { withIntlConsumer } from 'src/frontend/modules/intl/components/withIntlConsumer'
import { IntlConsumerType } from 'src/frontend/modules/intl/components/types'
import { PaymentMethodType } from 'src/frontend/scenes/billing/paymentMethod/enums'
import { mergeClasses } from 'src/common/utils'
import {
  selectClientToken,
  selectLoading,
  selectPaymentMethodError,
  selectTokenLoading,
} from 'src/frontend/scenes/billing/paymentMethod/selectors'
import { Billing } from 'src/types/Billing'
import { selectUser } from 'src/frontend/modules/user/selectors'
import { User } from 'src/types/User'
import { FormattedMessage } from 'src/frontend/modules/intl'
import { getPaymentMethodComponent } from 'src/frontend/scenes/billing/paymentMethod/helpers'
import { useBraintreeClient } from 'src/frontend/scenes/billing/paymentMethod/hooks'
import { PaymentMethodFormType } from 'src/frontend/scenes/billing/paymentMethod/components/types'
import { selectBillingModalStage } from 'src/frontend/scenes/billing/billingModal/selectors'
import { BillingUIMethod } from 'src/frontend/scenes/billing/enums'
import BraintreeProduct = Billing.BraintreeProduct


function mapStateToProps(state) {
  return {
    clientToken: selectClientToken(state),
    loading: selectLoading(state),
    tokenLoading: selectTokenLoading(state),
    user: selectUser(state),
    error: selectPaymentMethodError(state),
    stage: selectBillingModalStage(state),
  }
}

const mapDispatchToProps = {
  getToken: actions.getToken,
  clearToken: actions.clearToken,
}

interface Props extends IntlConsumerType {
  clientToken: string,
  loading: boolean,
  tokenLoading: boolean,
  selectedProduct?: BraintreeProduct,
  user: User
  error: string
  formType: PaymentMethodFormType
  method: BillingUIMethod

  getToken: Function,
  clearToken: Function,
  onSubmit: (nonce: string) => void
}


function PaymentMethod({
  clientToken,
  loading,
  selectedProduct = undefined,
  onSubmit,
  getToken,
  formType,
  clearToken,
  method,
  error,
}: Props) {
  const [selectedMethod, setSelectedMethod] = useState<PaymentMethodType>(PaymentMethodType.CARD)
  const [errorString, setErrorString] = useState(error)

  useEffect(() => {
    if (!clientToken) {
      getToken()
    }

    return () => clearToken()
  }, [])

  useEffect(() => {
    setErrorString(error)
  }, [error])

  useEffect(() => {
    setErrorString(null)
  }, [selectedMethod])


  const braintreeInstance = useBraintreeClient(clientToken)

  const isSelected = useCallback((paymentMethod: PaymentMethodType) => {
    return paymentMethod === selectedMethod
  }, [selectedMethod])


  const PaymentComponent = getPaymentMethodComponent(selectedMethod)

  return (
    <div className={mergeClasses(styles.paymentMethod, method === BillingUIMethod.MODAL && styles.modal)}>
      <div>
        <div className={styles.methodSelector}>
          <div
            className={mergeClasses(styles.card, isSelected(PaymentMethodType.CARD) && styles.active)}
            onClick={() => setSelectedMethod(PaymentMethodType.CARD)}
          >
            <FormattedMessage id="billing.payment-method.card" />
          </div>
          <div
            className={mergeClasses(styles.paypal, isSelected(PaymentMethodType.PAYPAL) && styles.active)}
            onClick={() => setSelectedMethod(PaymentMethodType.PAYPAL)}
          >
            {/* tslint:disable-next-line:jsx-use-translation-function */}
            PayPal
          </div>
        </div>
        <div className={styles.content}>
          {errorString && (
            <div className={styles.error}>
              <FormattedMessage id={errorString} />
            </div>
          )}
          <PaymentComponent
            loading={loading}
            formType={formType}
            selectedProduct={selectedProduct}
            braintreeClient={braintreeInstance}
            onTokenize={onSubmit}
            onError={setErrorString}
          />
        </div>
      </div>
    </div>
  )
}

export default withIntlConsumer(connect(mapStateToProps, mapDispatchToProps)(PaymentMethod))
