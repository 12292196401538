import { FilterType } from 'src/types/Filter'
import { calcTotalsByAttribute, getOpeningBalance } from 'src/backend/analytics/service'
import { getPreviousPeriodFilter } from 'src/backend/filters/helpers'
import { CashFlowReportRaw, ReportRaw } from 'src/frontend/scenes/analytics/Report/types'

export function fetchReportIncomesAndExpenses(
  filter: FilterType,
): Promise<ReportRaw> {
  const filterWithoutTransfers = {
    ...filter,
    transfer: false,
  }

  const GROUP_BY = ['categoryId']

  const previousPeriodTotals = calcTotalsByAttribute(getPreviousPeriodFilter(filterWithoutTransfers), GROUP_BY)
  const currentPeriodTotals = calcTotalsByAttribute(filterWithoutTransfers, GROUP_BY)

  return Promise.all([previousPeriodTotals, currentPeriodTotals])
    .then(([previousPeriod, currentPeriod]) => {
      return {
        previousPeriod,
        currentPeriod,
      }
    })
}

export async function fetchBoardCashFlow(filter: FilterType): Promise<CashFlowReportRaw> {
  const GROUP_BY = ['superEnvelopeId', 'envelopeId']

  const openingBalance = getOpeningBalance(getPreviousPeriodFilter(filter))
  const previousPeriodOpeningBalance = getOpeningBalance(getPreviousPeriodFilter(getPreviousPeriodFilter(filter)))
  const currentPeriodTotals = calcTotalsByAttribute(filter, GROUP_BY)
  const previousPeriodTotals = calcTotalsByAttribute(getPreviousPeriodFilter(filter), GROUP_BY)

  return Promise.all([openingBalance, previousPeriodOpeningBalance, previousPeriodTotals, currentPeriodTotals])
    .then(([balance, previousPeriodBalance, previousPeriod, currentPeriod]) => {
      return {
        balance,
        previousPeriodBalance,
        previousPeriod,
        currentPeriod,
      }
    })
}
