import React from 'react'
import DefaultDashboardPeriod from 'src/frontend/scenes/settings/general/components/DefaultDashboardPeriod'
import SafeLogout from 'src/frontend/scenes/settings/general/components/SafeLogout'
import ChangePassword from 'src/frontend/scenes/settings/general/components/ChangePassword'
import ChangeLanguage from './ChangeLanguage'
import './General.less'
import ChangeShowNumberDecimals from 'src/frontend/scenes/settings/general/components/ChangeShowNumberDecimals'

const General = () => {
  return (
    <div className="settings-general">
      <ChangeLanguage />
      <DefaultDashboardPeriod />
      <ChangeShowNumberDecimals />
      <ChangePassword />
      <SafeLogout />
    </div>
  )
}

export default General
