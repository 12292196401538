import { Action, HashMap } from 'src/types/common'
import { Period } from 'src/types/Filter'

export const ACCOUNTS_FILTER_TOGGLE_DISPLAY_FILTER = 'ACCOUNTS_FILTER_TOGGLE_DISPLAY_FILTER'
export const ACCOUNTS_FILTER_SET_FILTER = 'ACCOUNTS_FILTER_SET_FILTER'

export function toggleDisplayFilter(name: string): Action {
  return {
    type: ACCOUNTS_FILTER_TOGGLE_DISPLAY_FILTER,
    payload: name,
  }
}

export function setFilterValues(
  values: HashMap<string | Array<string | number> | Period>,
): Action<HashMap<string | Array<string | number> | Period>> {
  return {
    type: ACCOUNTS_FILTER_SET_FILTER,
    payload: values,
  }
}
