import { combineReducers } from 'redux'
import hiddenEnvelopes, { HiddenEnvelopesState } from 'src/frontend/modules/configures/hiddenEnvelopes/reducer'
import tourGuides, { TourGuidesState } from 'src/frontend/modules/configures/tourGuides/reducer'

export type ConfiguresState = {
  hiddenEnvelopes: HiddenEnvelopesState,
  tourGuides: TourGuidesState,
}

export default combineReducers({ tourGuides, hiddenEnvelopes })
