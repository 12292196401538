import React from 'react'
import { connect } from 'react-redux'
import './Labels.less'
import { Input } from 'semantic-ui-react'
import { SortableElement } from 'react-sortable-hoc'
import DragHandle from 'src/frontend/scenes/settings/components/DragHandle'
import SortableList from 'src/frontend/scenes/settings/components/SortableList'
import { FormType } from 'src/frontend/scenes/settings/enums'
import RemoveLabelForm from './RemoveLabelForm'
import { CircleIcon } from 'src/frontend/components/Icons/Icons'
import LabelForm from './LabelForm'
import { FormattedMessage, formatMessage } from 'src/frontend/modules/intl'
import * as labelSelectors from 'src/frontend/modules/labels/selectors'
import * as selectors from '../selectors'
import * as actions from '../actions'
import { isSystemLabel } from 'src/backend/labels/helpers'
import { Id } from 'src/types/CouchDb'
import { Label } from 'src/types/Label'
import { LabelFormValues } from 'src/frontend/scenes/settings/labels/types'
import { AccountWithCurrencyCode } from 'src/types/Account'
import _isEmpty from 'lodash/isEmpty'
import EmptyState from 'src/frontend/scenes/settings/components/EmptyState'
import { PrimaryButton } from 'src/frontend/components/Buttons/Buttons'

const mapStateToProps = (state) => {
  const form = selectors.selectLabels(state)
  return {
    labels: labelSelectors.selectSortedLabels(state),
    formValues: form.formValues,
    formType: form.formType,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    handleChangePosition({ oldIndex, newIndex }) {
      dispatch(actions.reorderLabels(oldIndex, newIndex))
    },
    handleChangeName(event, field) {
      event.preventDefault()
      dispatch(actions.changeName(field.name, field.value))
    },
    handleOpenEditForm(labelId: Id) {
      dispatch(actions.openEditForm(labelId))
    },
    handleOpenAddForm() {
      dispatch(actions.openAddForm())
    },
  }
}

interface Props {
  labels: Label[]
  formType: FormType
  formValues: LabelFormValues
  handleChangePosition: Function
  handleChangeName: Function
  handleOpenEditForm: Function
  handleOpenAddForm: Function
}

function Labels({
  labels,
  formType,
  formValues,
  handleChangePosition,
  handleChangeName,
  handleOpenAddForm,
  handleOpenEditForm,
}: Props) {

  const addAccountInputValue = formType === FormType.ADD ? formValues.name : ''
  return (
    <div className="settings-page">
      <div className="settings-page-add">
        <div className="title">
          <FormattedMessage id="settings.labels.add_new" />
        </div>
        <div className="container">
          <div className="item input">
            <InputWithAddButton
              name="name"
              value={addAccountInputValue || ''}
              placeholder={formatMessage('settings.labels.form.name_placeholder')}
              onChange={handleChangeName}
              onClick={handleOpenAddForm}
            />
            <LabelForm />
          </div>
        </div>
      </div>
      <div className="settings-page-list">
        <div className="title">
          <FormattedMessage id="settings.labels.your_labels" />
        </div>
        <div className="labels-row list-header">
          <div className="labels-row-drag header" />
          <div className="labels-row-icon header" />
          <div className="labels-row-name header"><FormattedMessage id="form.name" /></div>
          <div className="labels-row-toolbar" />
        </div>
        {!_isEmpty(labels)
          ? (
            <LabelsList
              items={labels}
              handleReorder={handleChangePosition}
              handleOpenForm={handleOpenEditForm}
            />
          ) : (
            <EmptyState
              title={<FormattedMessage id="settings.labels.empty.title" />}
              text={(
                <FormattedMessage
                  id="settings.labels.empty.text"
                  values={{ addButton: `(＋ ${formatMessage('form.add')})` }}
                />
              )}
            />
          )}
      </div>
    </div>
  )
}

const InputWithAddButton = ({ name, placeholder, value, onClick, onChange }) => (
  <div className="ui input">
    <div className="ui action input">
      <Input
        name={name}
        className="action"
        placeholder={placeholder}
        maxLength="20"
        value={value}
        onChange={onChange}
      />
      <PrimaryButton onClick={onClick}>
        ＋
        &nbsp;
        {formatMessage('form.add')}
      </PrimaryButton>
    </div>
  </div>
)

interface LabelsListProps {
  items: Label[]
  handleOpenForm: (e: React.MouseEvent, FormType, account?: AccountWithCurrencyCode) => void
}

const LabelsList = SortableList(({ items, handleOpenForm }: LabelsListProps) => (
  <ul>
    {items.map((label, index) => (
      <LabelItem key={label._id} index={index} label={label} onClick={handleOpenForm} />
    ))}
  </ul>
))

interface LabelItemProps {
  label: Label,
  onClick: Function
}

const LabelItem = SortableElement(({ label, onClick }: LabelItemProps) => {

  const archivedClass = label.archived ? 'archived' : ''
  const systemLabelClass = isSystemLabel(label) ? 'fade' : ''

  return (
    <li className="labels-row">
      <div className="labels-row-drag"><DragHandle /></div>
      <div className="labels-row-icon"><CircleIcon color={label.color} /></div>
      <div className={`labels-row-name ${archivedClass}`}>
        <span>{label.name}</span>
        <div className={archivedClass}>
          {label.autoAssign && (
            <span className="auto-assign"><FormattedMessage id="settings.labels.form.autoAssign" /></span>
          )}
          {label.archived && <FormattedMessage id="archived" />}
        </div>
      </div>
      <div className={`labels-row-toolbar ${systemLabelClass}`}>
        <div>
          <span
            onClick={() => {
              if (!isSystemLabel(label)) {
                onClick(label._id)
              }
            }}
          >
            <FormattedMessage id="edit" />
          </span>
          <span className="delete"><RemoveLabelForm label={label} /></span>
        </div>
      </div>
    </li>
  )
})

export default connect(mapStateToProps, mapDispatchToProps)(Labels)
