import { setBackgroundGradient } from 'src/frontend/components/chart/helpers'
import { DATASET_DEFAULT_OPTIONS } from 'src/frontend/scenes/dashboard/components/constants'
import { PeriodToPeriodChartData } from 'src/frontend/scenes/dashboard/widgets/PeriodToPeriodChart/types'
import { Currency } from 'src/types/Currency'
import { ChartType } from 'src/frontend/components/chart/ChartType'
import { formatMessage } from 'src/frontend/modules/intl'

const PERIOD_TO_PERIOD_DEFAULT_OPTIONS = {
  borderWidth: 2,
  fill: 'origin',
  hideLegend: false,
  reverseLegend: true,
  isEmpty: false,
  backgroundColor: 'transparent',
}

export function aggregatePeriodToPeriodChartData(referentialCurrency: Currency) {
  return (data: PeriodToPeriodChartData) => {
    if (!data) {
      return null
    }

    const datasets = data.datasets
      ? [
        // Current Period
        {
          ...data.datasets[0],
          ...DATASET_DEFAULT_OPTIONS,
          ...PERIOD_TO_PERIOD_DEFAULT_OPTIONS,
          borderColor: '#149dff',
          pointBackgroundColor: '#149dff',
          label: formatMessage('period-current'),
          backgroundGradient: setBackgroundGradient(['rgba(20, 157, 255, 0.7)', 'rgba(20, 157, 255, 0)']),
          type: ChartType.LINE,
          isEmpty: data.datasets
            && !!data.datasets[0]
            && data.datasets[0].data.every((value => value === null || value === 0)),
        },
        // Last period
        {
          ...data.datasets[1],
          ...DATASET_DEFAULT_OPTIONS,
          ...PERIOD_TO_PERIOD_DEFAULT_OPTIONS,
          borderColor: '#f06292',
          pointBackgroundColor: '#f06292',
          label: formatMessage('period-previous'),
          type: ChartType.LINE,
        },
        // Year Ago period
        {
          ...data.datasets[2],
          ...DATASET_DEFAULT_OPTIONS,
          ...PERIOD_TO_PERIOD_DEFAULT_OPTIONS,
          borderColor: '#ffb300',
          pointBackgroundColor: '#ffb300',
          label: formatMessage('period-yearago'),
          type: ChartType.LINE,
        },
        
        
      ]
      : []

    return {
      ...data,
      height: 174,
      currency: referentialCurrency,
      options: {
        aspectRatio: null,
        maintainAspectRatio: false,
        tooltips: { mode: 'nearest' },
      },
      datasets,
    }
  }
}
