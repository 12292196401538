import React from 'react'
import { FormattedMessage } from 'src/frontend/modules/intl'
import ChangePasswordModal from './ChangePasswordModal'

export default function ChangePassword() {
  return (
    <div className="item">
      <div className="label">
        <FormattedMessage id="settings.general.user_password" />
      </div>
      <div>
        <ChangePasswordModal />
      </div>
    </div>
  )
}
