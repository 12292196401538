import { AddVoucherModalStatus } from 'src/frontend/modules/modals/components/AddVoucherTypes'
import { Action } from 'src/types/common'

export const SESSION_EXPIRED = 'modals/SESSION_EXPIRED'
export const SET_ADD_VOUCHER_MODAL_STATUS = 'modals/SET_ADD_VOUCHER_MODAL_STATUS'

export function sessionExpired(): Action {
  return {
    type: SESSION_EXPIRED,
  }
}

/* Add voucher actions */

export function openModalAddVoucher(voucherCode?: string) {
  return setAddVoucherModalStatus(AddVoucherModalStatus.FORM, voucherCode)
}

export function closeModalAddVoucher() {
  return setAddVoucherModalStatus(AddVoucherModalStatus.CLOSED)
}

export function addVoucherSuccess() {
  return setAddVoucherModalStatus(AddVoucherModalStatus.SUCCESS)
}

export function addVoucherError() {
  return setAddVoucherModalStatus(AddVoucherModalStatus.ERROR)
}

function setAddVoucherModalStatus(modalStatus: AddVoucherModalStatus, voucherCode?: string): Action {
  return {
    type: SET_ADD_VOUCHER_MODAL_STATUS,
    payload: { modalStatus, voucherCode },
  }
}
