import React from 'react'
import { IntegrationProviders } from 'src/frontend/scenes/integrations/providersList/types'
import IntegrationRecipeParam = IntegrationProviders.IntegrationRecipe.IntegrationRecipeParam

interface Props {
  param: IntegrationRecipeParam
  value?: string
  onChange: (field: string, value: string) => void
}

MFAFormHTML.defaultProps = {
  value: '',
}

export default function MFAFormHTML({ value }: Props) {
  return (
    <div dangerouslySetInnerHTML={{ __html: value }} />
  )
}
