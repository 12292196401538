import { CouchDbDocument } from "src/types/CouchDb"

export namespace Contact {
  export enum Type {
    VENDOR,
    CUSTOMER,
    INTERNAL,
    OTHER,
  }

  export interface ContactDocument extends CouchDbDocument {
    name: string
    type: Type
    email?: string
    phone?: string
    web?: string
    note?: string
    avatarUrl?: string
  }

  export interface ContactView extends ContactDocument {
    contactTypeName: string
    color: string
  }

  export interface ContactOption extends ContactView {
    description: string
  }
}
