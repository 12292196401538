import { reduceBy } from 'src/common/utils'
import * as service from 'src/backend/rewardPoints/service'

export const REWARD_POINTS_RECEIVE = 'REWARD_POINTS_RECEIVE'

export function receiveRewardPoints(labelsArray) {
  const REWARD_POINT_ID = '_id'
  const rewardPoints = labelsArray.reduce(reduceBy(REWARD_POINT_ID), {})
  return { type: REWARD_POINTS_RECEIVE, payload: rewardPoints }
}

export function fetchRewardPoints() {
  return dispatch => {
    return service.fetchRewardPoints().then(rewardPoints => dispatch(receiveRewardPoints(rewardPoints)))
  }
}
