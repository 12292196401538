import _isEmpty from "lodash/isEmpty"
import moment from "moment"
import { pipe } from "ramda"
import { addDeletedAttribute, addUpdateAttributes } from "src/backend/common/helpers"
import * as documentDatabase from "src/backend/db/documentDb"
import * as sqlDatabase from "src/backend/db/inMemorySqlDb"
import { reduceNonRecordsToHashMap } from "src/backend/sync/helpers"
import { NonRecordDocumentGroups, SyncedChanges } from "src/frontend/scenes/app/types"
import { AnyDocument, Id } from "src/types/CouchDb"
import { ReplicationEndpoint, User } from "src/types/User"
import AllDocsMeta = PouchDB.Core.AllDocsMeta
import AllDocsResponse = PouchDB.Core.AllDocsResponse
import ExistingDocument = PouchDB.Core.ExistingDocument
import { PresistentWalletSQL } from "../db/presistentSqlDb"
import { inMemoryTableNames } from "../db/inMemorySqlDbSchemaBuilder"
import lf from "lovefield"
import { fetchExchangeRateFromApi, fetchStockPriceHistoryFromApi } from "../rest/financial_service"
import { insertOrUpdateExchangeRates, insertOrUpdateStockPrices } from "../investments/service"

export function initInMemoryDatabase() {
  const sqlDb = sqlDatabase.WalletSQLInMemory.getInstance()
  return sqlDb
    .init()
    .then(() => documentDatabase.fetchAll())
    .then((couchDbDocs) => sqlDatabase.insertDocuments(couchDbDocs.rows))
}

export function initPresistentSQLDatabase() {
  const sqlDB = PresistentWalletSQL.getInstance()

  return sqlDB.init()
}

export const initInvestmentsCache = async () => {
  const inMemoryDb = sqlDatabase.WalletSQLInMemory.getInstance()

  const assetTransactionsTable = inMemoryDb.getTable(inMemoryTableNames.ASSET_TRANSACTION)
  const assetTable = inMemoryDb.getTable(inMemoryTableNames.STOCKS_FUNDS_ASSET)
  const accountsTable = inMemoryDb.getTable(inMemoryTableNames.ACCOUNT)
  const currencyTable = inMemoryDb.getTable(inMemoryTableNames.CURRENCY)

  const assetsWithOldestDate = (await inMemoryDb
    .select(
      lf.fn.min(assetTransactionsTable.date).as("minDate"),
      assetTable.exchangeCode.as("exchangeCode"),
      assetTable.symbol.as("symbol"),
    )
    .from(assetTransactionsTable)
    .innerJoin(assetTable, assetTransactionsTable.assetId.eq(assetTable._id))
    .groupBy(assetTable.exchangeCode, assetTable.symbol)
    .exec()) as { minDate: string; exchangeCode: string; symbol: string }[]

  const allAssetPrices = await Promise.all(
    assetsWithOldestDate.map(async (asset) => {
      const { minDate, exchangeCode, symbol } = asset

      const assetPrices = await fetchStockPriceHistoryFromApi(
        { exchangeCode, symbol },
        moment(minDate).subtract(14, "day").toDate(),
      ).catch((err) => {
        console.error(`Stock price fetch error for stock: ${exchangeCode}:${symbol}`, err)
        return []
      })

      return assetPrices
    }),
  )

  await insertOrUpdateStockPrices(allAssetPrices.flat()).catch((err) => {
    console.error(`Stock price insert error`, err)
  })

  const currecyPairsWithOldestDate = (await inMemoryDb
    .select(
      lf.fn.min(assetTransactionsTable.date).as("minDate"),
      assetTransactionsTable.priceCurrencyCode.as("fromCurrencyCode"),
      currencyTable.code.as("toCurrencyCode"),
    )
    .from(assetTransactionsTable)
    .innerJoin(accountsTable, assetTransactionsTable.accountId.eq(accountsTable._id))
    .innerJoin(currencyTable, accountsTable.currencyId.eq(currencyTable._id))
    .groupBy(assetTransactionsTable.priceCurrencyCode, currencyTable.code)
    .where(assetTransactionsTable.priceCurrencyCode.neq(currencyTable.code))
    .exec()) as { minDate: string; fromCurrencyCode: string; toCurrencyCode: string }[]

  const allCurrencyExchangeRates = await Promise.all(
    currecyPairsWithOldestDate.map(async (currencyPair) => {
      const { minDate, fromCurrencyCode, toCurrencyCode } = currencyPair

      const currencyExchangeRates = await fetchExchangeRateFromApi(
        `${fromCurrencyCode}:${toCurrencyCode}`,
        moment(minDate).subtract(14, "day").toDate(),
      ).catch((err) => {
        console.error(
          `Currency exchange rate fetch error for currency pair: ${fromCurrencyCode}:${toCurrencyCode}`,
          err,
        )
        return []
      })

      return currencyExchangeRates
    }),
  )

  await insertOrUpdateExchangeRates(allCurrencyExchangeRates.flat()).catch((err) => {
    console.error(`Currency exchange rate insert error`, err)
  })
}

export function initLocalDatabase(user: User) {
  return documentDatabase.connectToLocalDB(user.replication)
}

export function remoteDatabaseReplicationPull(
  replication: ReplicationEndpoint,
): PouchDB.Replication.Replication<{}> {
  return documentDatabase.replicate(replication)
}

export function startRemoteDatabaseSync(
  userReplication: ReplicationEndpoint,
): PouchDB.Replication.Sync<{}> {
  return documentDatabase.sync(userReplication)
}

export async function syncChanges(
  changes: PouchDB.Replication.SyncResult<{}>[],
): Promise<SyncedChanges> {
  const changesDocuments: AnyDocument[] = changes.reduce((tempDocsByOperation, changeInfo) => {
    const { docs } = changeInfo.change
    return [...tempDocsByOperation, ...docs]
  }, [])

  console.log("changesDocuments", changesDocuments)

  // unique document ids
  const keys = [...new Set(changesDocuments.map((doc) => doc._id))]

  await solveConflicts(keys)

  // get all documents from this change, with newest revision
  const { rows }: AllDocsResponse<AnyDocument> = await documentDatabase.localDb.allDocs({
    include_docs: true,
    keys,
  })

  // allDocs doesn't include _deleted documents, only its meta data, map delete documents from changes
  const docsToDelete = changesDocuments.filter((document) => document._deleted)

  const docsToInsert = rows.filter((row) => !row.value.deleted).map((row) => row.doc)

  // first remove documents, because we're not sure if conflict solving, would not override delete
  await sqlDatabase.deleteDocuments(docsToDelete)
  await sqlDatabase.insertDocuments(docsToInsert)

  return {
    docsToDelete,
    docsToInsert,
  }
}

export function containsUserConfigure({ docsToInsert }: SyncedChanges): boolean {
  return (
    docsToInsert &&
    docsToInsert.some(({ reservedModelType }) => reservedModelType === "UserConfigure")
  )
}

export function fetchNonRecords(strictModelTypes): Promise<NonRecordDocumentGroups> {
  return sqlDatabase.fetchNonRecords(strictModelTypes).then((documentArrays) => {
    return documentArrays.reduce(reduceNonRecordsToHashMap, {})
  })
}

export async function solveConflicts(keys?: Id[]) {
  // fetch documents with conflicts array, by ids from changes
  const documentsWithConflicts: ExistingDocument<AnyDocument & AllDocsMeta>[] =
    await documentDatabase.localDb
      .allDocs({
        conflicts: true,
        include_docs: true,
        keys,
      })
      .then((result: AllDocsResponse<any>): ExistingDocument<AnyDocument & AllDocsMeta>[] => {
        return result.rows
          .filter((row) => !row.value.deleted)
          .filter((row) => !_isEmpty(row.doc._conflicts))
          .map((row) => row.doc)
      })

  // get all documents that are in conflict
  const conflictDocuments: (Partial<AnyDocument> & PouchDB.Core.GetMeta & PouchDB.Core.IdMeta)[] =
    await Promise.all(
      documentsWithConflicts.reduce((acc, value) => {
        return [
          ...acc,
          ...value._conflicts.map((rev) => documentDatabase.localDb.get(value._id, { rev })),
        ]
      }, []),
    )

  // keep the newest document and remove the rest
  return Promise.all(
    // @ts-ignore
    documentsWithConflicts.reduce((acc, value) => {
      const documentsToDelete = getConflictedDocumentsToDelete([
        value,
        ...conflictDocuments.filter((document) => document._id === value._id),
      ])
      console.warn("documentsToDelete", documentsToDelete)

      return [...acc, documentsToDelete]
    }, []),
  )
}

function getConflictedDocumentsToDelete(
  documents: (Partial<AnyDocument> & PouchDB.Core.GetMeta & PouchDB.Core.IdMeta)[],
) {
  const sortedDocuments = documents.sort((a, b) => {
    return moment(b.reservedUpdatedAt).valueOf() - moment(a.reservedUpdatedAt).valueOf()
  })
  const [
    // @ts-ignore ignore the newest documents, remove the rest
    newestDocument,
    ...documentsToDelete
  ] = sortedDocuments
  return documentDatabase.localDb.bulkDocs(
    documentsToDelete.map(pipe(addDeletedAttribute, addUpdateAttributes)),
  )
}

export function softCloseDatabases(): Promise<void[]> {
  const sqlDb = sqlDatabase.WalletSQLInMemory.getInstance()
  return Promise.all([sqlDb.closeDatabase(), documentDatabase.closeLocalDatabase()])
}

export function hardCloseDatabases(): Promise<void[]> {
  const sqlDb = sqlDatabase.WalletSQLInMemory.getInstance()
  return Promise.all([sqlDb.closeDatabase(), documentDatabase.destroyLocalDatabase()])
}
