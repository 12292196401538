import { RootState } from 'src/types/State'
import { selectContacts as selectScenesContacts } from 'src/frontend/scenes/contacts/selectors'
import { ContactListState } from 'src/frontend/scenes/contacts/contactList/reducer'
import { createSelector } from 'reselect'
import { selectSortedContacts } from 'src/frontend/modules/contacts/selectors'
import { Contact } from 'src/types/Contact'
import ContactView = Contact.ContactView
import { ContactListOrderBy } from 'src/frontend/scenes/contacts/contactList/enums'
import { selectContactFilter } from 'src/frontend/scenes/contacts/filter/selectors'
import { matchesFilter } from 'src/backend/common/service'
import { matchLatinized } from 'src/common/utils'

export const selectContactList = (state: RootState): ContactListState => selectScenesContacts(state).contactList
export const selectOrderBy = (state: RootState): ContactListOrderBy => selectContactList(state).order


export const selectContactListContacts: (RootState) => ContactView[] = createSelector(
  [
    selectSortedContacts,
    selectOrderBy,
    selectContactFilter,
  ],
  (contacts, orderBy, filter) => {

    // value filters
    const virtualFilter = {
      type: filter.contactTypeIds,
    }


    return contacts
      .filter(matchesFilter(virtualFilter))
      .filter((contact) => !filter.fulltext || matchLatinized(contact.name, filter.fulltext))
      .sort(createSortFunction(orderBy))
  },
)


function createSortFunction(orderBy: ContactListOrderBy) {
  const orderByParams = orderBy.split('-')
  const attribute = orderByParams[0]
  const direction = orderByParams[1]

  const orderByFunction = createOrderByFunction(attribute, direction)

  return (a, b) => orderByFunction(a, b)
}

function createOrderByFunction(attribute: string, direction: string) {
  return (a, b) => {
    const [nameA, nameB]: [string, string] = direction === 'ASC'
      ? [a[attribute], b[attribute]]
      : [b[attribute], a[attribute]]

    return new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' }).compare(nameA, nameB)
  }
}
