import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { selectFilter } from 'src/frontend/modules/filter/selectors'
import { FormattedMessage } from 'src/frontend/modules/intl'
import * as actions from 'src/frontend/scenes/analytics/actions'
import * as selectors from 'src/frontend/scenes/analytics/selectors'
import Filter from 'src/frontend/components/Filter/Filter'
import { Period, WebFilter } from 'src/types/Filter'
import { ViewDefinition, ViewDefinitionOptions } from 'src/frontend/scenes/analytics/types'
import ViewDefinitionForm from 'src/frontend/scenes/analytics/ViewDefinitionForm'
import { Location } from 'history'
import ColumnLayout from 'src/frontend/components/ColumnLayout/ColumnLayout'
import { getReportComponent } from 'src/frontend/scenes/analytics/helpers'
import { IntlConsumer } from 'src/frontend/modules/intl/components/IntlContext'
import DateRangePicker from 'src/frontend/components/DateRangePicker'
import { changeFilter } from 'src/frontend/modules/filter/actions'
import styles from './Analytics.module.less'
import { Interval } from 'src/backend/enums'

type Props = {
  viewDefinition: ViewDefinition,
  viewDefinitionOptions: ViewDefinitionOptions,
  location: Location<{ params: { interval: Interval, intervalValue: string }}>,
  filter: WebFilter & { period: Period },
}

type Actions = {
  updateViewDefinition: Function,
  setFilterView: (interval: Interval, intervalValue: string) => void,
  handleChangeFilter: Function,
}

function mapStateToProps(state) {
  return {
    viewDefinition: selectors.selectViewDefinition(state),
    viewDefinitionOptions: selectors.selectViewDefinitionOptions(state),
    filter: selectFilter(state),
  }
}

const mapDispatchToProps: Actions = {
  updateViewDefinition: actions.updateViewDefinition,
  setFilterView: actions.setFilterView,
  handleChangeFilter: changeFilter,
}

function Analytics({
  viewDefinition,
  viewDefinitionOptions,
  filter,
  location,
  updateViewDefinition,
  setFilterView,
  handleChangeFilter,
}: Props & Actions) {

  useEffect(() => {
    if (location && location.state && location.state.params) {

      const { interval, intervalValue } = location.state.params
      setFilterView(interval, intervalValue)
    }
  }, [])

  const ViewComponent = getReportComponent(viewDefinition.subject)

  return (
    <ColumnLayout
      title={<FormattedMessage id="menu.analytics" />}
      leftPanel={<Filter />}
      topPanel={(
        <div className={styles.sort}>
          <IntlConsumer>
            {({ locale }) => (
              <DateRangePicker
                period={filter.period}
                locale={locale}
                showAll
                preferText
                onChange={period => handleChangeFilter('period', period)}
              />
            )}
          </IntlConsumer>
        </div>
      )}
      content={(
        <div>
          <ViewDefinitionForm
            filter={filter}
            values={viewDefinition}
            options={viewDefinitionOptions}
            onChange={updateViewDefinition}
          />
          <ViewComponent />
        </div>
      )}
    />
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(Analytics)
