import * as selectors from './selectors'
import { selectUser } from 'src/frontend/modules/user/selectors'
import * as validator from 'src/backend/magicRules/validator'
import * as service from 'src/backend/magicRules/service'
import * as magicRulesActions from 'src/frontend/modules/magicRules/actions'
import * as repository from 'src/backend/magicRules/repository'
import { FormType } from 'src/frontend/scenes/settings/magicRules/enums'
import { WalletFormValidationError } from 'src/backend/errors'
import { captureException } from 'src/common/logger'
import { Action, GetState, HashMap } from 'src/types/common'
import { Id } from 'src/types/CouchDb'
import { MagicRulesFormValues } from 'src/frontend/scenes/settings/magicRules/types'
import { isUndefinedOrNull } from 'src/common/utils'
import _omitBy from 'lodash/omitBy'
import * as categoriesActions from 'src/frontend/modules/categories/actions'
import { Dispatch } from 'redux'
import * as recordsRepository from 'src/backend/records/repository'
import { selectAssignForm } from 'src/frontend/scenes/records/assignForm/selectors'
import { isNotEmpty } from 'src/backend/common/validator'
import { Record } from 'src/types/Record'
import * as recordsService from 'src/backend/records/service'
import {
  expireAccountsStatus, expireAnalyticsStatus, expireDetailRecordsStatus,
  expireDashboardStatus, expireImportsRecordListStatus,
  expireRecordsStatus,
} from 'src/frontend/modules/moduleStatus/actions'
import { closeAssignForm } from 'src/frontend/scenes/records/assignForm/actions'

export const SETTINGS_MAGIC_RULES_SAVE_START = 'SETTINGS_MAGIC_RULES_SAVE_START'
export const SETTINGS_MAGIC_RULES_SAVE_SUCCESS = 'SETTINGS_MAGIC_RULES_SAVE_SUCCESS'
export const SETTINGS_MAGIC_RULES_SAVE_ERROR = 'SETTINGS_MAGIC_RULES_SAVE_ERROR'
export const SETTINGS_MAGIC_RULES_CHANGE_FIELD_VALUE = 'SETTINGS_MAGIC_RULES_CHANGE_FIELD_VALUE'

export const SETTINGS_MAGIC_RULES_SHOW_SIMILAR_RECORDS = 'SETTINGS_MAGIC_RULES_SHOW_SIMILAR_RECORDS'
export const SETTINGS_MAGIC_RULES_SIMILAR_RECORDS_SAVE = 'SETTINGS_MAGIC_RULES_SIMILAR_RECORDS_SAVE'

export const SETTINGS_MAGIC_RULES_OPEN_FORM = 'SETTINGS_MAGIC_RULES_OPEN_FORM'
export const SETTINGS_MAGIC_RULES_CLOSE_FORM = 'SETTINGS_MAGIC_RULES_CLOSE_FORM'

export function saveMagicRule() {
  return async (dispatch: Function, getState: GetState) => {
    dispatch(saveMagicRuleStart())

    const user = selectUser(getState())
    const { magicRuleId, formValues, formType } = selectors.selectMagicRules(getState())

    try {
      const validationDependencies = await service.getValidationDependencies()
      validator.validate(_omitBy({ ...formValues, _id: magicRuleId }, isUndefinedOrNull), validationDependencies)

      const [magicRule] = await service.saveMagicRule(formValues, user, magicRuleId)

      const similarRecords = await service.findSimilarToMagicRule(magicRule)

      if (similarRecords && similarRecords.length > 0) {
        dispatch(showSimilarForm(similarRecords))
      } else {
        dispatch(saveMagicRuleSuccess())
      }

      dispatch(categoriesActions.fetchCategories())
      dispatch(magicRulesActions.fetchMagicRules())
    } catch (error) {
      if (error instanceof WalletFormValidationError) {
        dispatch(saveMagicRuleError(error.errors))
      } else {
        captureException(error, 'magicRules.saveMagicRule', { formValues, formType, magicRuleId })
      }
    }
  }
}

function saveMagicRuleStart(): Action {
  return { type: SETTINGS_MAGIC_RULES_SAVE_START }
}

function saveMagicRuleSuccess(): Action {
  return { type: SETTINGS_MAGIC_RULES_SAVE_SUCCESS }
}

function saveMagicRuleError(errors: HashMap<string>): Action {
  return {
    type: SETTINGS_MAGIC_RULES_SAVE_ERROR,
    payload: errors,
  }
}

export function openAddForm(predefinedValues?: Partial<MagicRulesFormValues>): Action<{
  formType: FormType,
  formValues: MagicRulesFormValues,
  keywordsGuide?: string[],
}> {
  const formValues = getInitialFormValues(predefinedValues)

  return {
    type: SETTINGS_MAGIC_RULES_OPEN_FORM,
    payload: {
      formType: FormType.ADD,
      formValues,
    },
  }
}

function showSimilarForm(records: Record[]): Action<Record[]> {
  return {
    type: SETTINGS_MAGIC_RULES_SHOW_SIMILAR_RECORDS,
    payload: records,
  }
}

export function openAddFromAssignForm() {
  return async (dispatch: Function, getState: GetState) => {

    const { recordId } = selectAssignForm(getState())
    const { payee, note, contactId, categoryId, labels } = await recordsRepository.findById(recordId)

    const formValues = getInitialFormValues({ contactId, categoryId, labelIds: labels })

    dispatch(closeAssignForm())
    return dispatch({
      type: SETTINGS_MAGIC_RULES_OPEN_FORM,
      payload: {
        formType: FormType.ADD_FROM_ASSIGN,
        formValues,
        keywordsGuide: [payee, note].filter(isNotEmpty),
      },
    })
  }
}


export function openEditForm(magicRuleId: Id) {
  return async (
    dispatch: Dispatch<Action<{ formType: FormType, formValues: MagicRulesFormValues, magicRuleId: Id }>>,
  ) => {
    const {
      name,
      labelIds,
      keywords,
      categoryId,
      contactId,
      recordType,
      sendPushNotification,
      accountIds,
    } = await repository.findById(magicRuleId)
    const formValues = {
      name,
      labelIds,
      keywords,
      categoryId,
      contactId,
      recordType,
      sendPushNotification,
      accountId: accountIds && accountIds[0],
    }

    return dispatch({
      type: SETTINGS_MAGIC_RULES_OPEN_FORM,
      payload: {
        formType: FormType.EDIT,
        magicRuleId,
        formValues,
      },
    })
  }
}

export function applyRuleToExistingRecords(recordIds: Id[]) {
  return async (dispatch: Function, getState: GetState) => {

    dispatch(applyRuleStart())
    const user = selectUser(getState())
    const { formValues } = selectors.selectMagicRules(getState())

    try {
      const recordValues = _omitBy({
        categoryId: formValues.categoryId,
        labels: formValues.labelIds,
        contactId: formValues.contactId,
      }, isUndefinedOrNull)

      await recordsService.saveMultipleRecords(recordValues, user, recordIds)

      dispatch(categoriesActions.fetchCategories())
      dispatch(expireAccountsStatus())
      dispatch(expireDashboardStatus())
      dispatch(expireRecordsStatus())
      dispatch(expireAnalyticsStatus())
      dispatch(expireImportsRecordListStatus())
      dispatch(expireDetailRecordsStatus())

      dispatch(saveMagicRuleSuccess())
    } catch (error) {
      captureException(error, 'magicRules.applyRule', { formValues, recordIds })
    }
  }
}

function applyRuleStart(): Action {
  return {
    type: SETTINGS_MAGIC_RULES_SIMILAR_RECORDS_SAVE,
  }
}

export function closeForm(): Action {
  return {
    type: SETTINGS_MAGIC_RULES_CLOSE_FORM,
  }
}

export function changeFormFieldValue(field: string, value: string | number | boolean, trim?: boolean) {
  const payloadValue = typeof value === 'string' && trim && value.trim() || value
  return {
    type: SETTINGS_MAGIC_RULES_CHANGE_FIELD_VALUE,
    payload: { [field]: payloadValue },
  }
}

export function deleteMagicRule(magicRuleId: Id) {
  return async (dispatch: Function) => {
    await service.removeMagicRule(magicRuleId)
    dispatch(magicRulesActions.fetchMagicRules())
  }
}


function getInitialFormValues(predefinedValues?: Partial<MagicRulesFormValues>): MagicRulesFormValues {
  return {
    name: '',
    categoryId: undefined,
    recordType: undefined,
    labelIds: undefined,
    contactId: undefined,
    keywords: undefined,
    accountId: undefined,
    sendPushNotification: false,
    ...predefinedValues,
  }
}
