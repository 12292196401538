import React from 'react'
import styles from 'src/frontend/scenes/billing/product/components/FeaturesList.module.less'
import { BasicIcon } from 'src/frontend/components/Icons/Icons'
import { getFeaturesList } from 'src/frontend/scenes/billing/product/helpers'
import { getAppName } from 'src/common/environment'
import { FormattedMessage } from 'src/frontend/modules/intl'
import { BasicIconColors, BasicIcons } from 'src/frontend/components/Icons/types'

export default function FeaturesList() {
  return (
    <div className={styles.featuresList}>
      {/* tslint:disable-next-line:jsx-use-translation-function */}
      <h2>{getAppName()} Premium</h2>
      <ul className={styles.features}>
        {getFeaturesList().map((row) => {
          return (
            <li key={row.text}>
              <BasicIcon name={row.icon as BasicIcons} color={row.color as BasicIconColors} />
              <FormattedMessage id={row.text} />
            </li>
          )
        })}
      </ul>
    </div>
  )
}
