import { path } from "ramda"
import * as sentry from "src/common/logger"
import { trackImportsEnter, trackImportsSuccess, trackImportsUpload } from "src/common/mixpanel"
import { getInitialImportLayout, uploadCsvFiles } from "src/backend/imports/service"
import { expireUser } from "src/frontend/modules/user/actions"
import { activateImports } from "src/frontend/scenes/imports/account/actions"
import { importedItemsReceived, importItem } from "src/frontend/scenes/imports/items/actions"
import {
  selectImportEmail,
  selectSelectedAccountAutomaticImportsEnabled,
  selectSelectedAccount,
} from "src/frontend/scenes/imports/account/selectors"
import { selectUser } from "src/frontend/modules/user/selectors"
import { selectLatestUploadItem } from "src/frontend/scenes/imports/items/selectors"
import { isImportsActivated } from "src/backend/accounts/helpers"
import {
  closeInfoDimmer,
  showInfoDimmer,
  showInfoModal,
  showError,
} from "src/frontend/scenes/app/actions"
import { selectIsImportsInitialized, selectPageMode } from "src/frontend/scenes/imports/selectors"
import { getErrorText, isAuthorizationError } from "src/common/helpers"
import { deleteImportSettings } from "src/backend/rest/backend"
import { selectEditingAccount } from "src/frontend/scenes/settings/accounts/selectors"
import { getFileExtension } from "src/common/utils"
import {
  expireImportsRecordListStatus,
  expireRecordsStatus,
} from "src/frontend/modules/moduleStatus/actions"
import { selectIsRecordListFetching } from "src/frontend/scenes/records/recordList/selectors"

export const CSV_FILES_CHANGED_ERROR = "imports/CSV_FILES_CHANGED_ERROR"
export const IMPORTS_INITIALIZED = "imports/INITIALIZED"
export const CONFIRM_DEACTIVATE_IMPORTS = "imports/CONFIRM_DEACTIVATE_IMPORTS"
export const DEACTIVATE_IMPORTS = "imports/DEACTIVATE_IMPORTS"
export const DEACTIVATE_IMPORTS_PROGRESS = "imports/DEACTIVATE_IMPORTS_PROGRESS"
export const CANCEL_DEACTIVATE_IMPORTS = "imports/CANCEL_DEACTIVATE_IMPORTS"
export const SET_TUTORIAL_OPEN_STATUS = "imports/SET_TUTORIAL_OPEN_STATUS"

export const initImportsLayout = () => async (dispatch, getState) => {
  if (!selectUser(getState()).userId) {
    // User is not initialized - can't load imports initial data yet.
    // For some good unknown reason the Imports.jsx#componentDidMount is called again
    // when user is already initialized so that we could exit this method
    return
  }

  trackImportsEnter()

  const isInitialized = selectIsImportsInitialized(getState())
  dispatch(showInfoDimmer(isInitialized ? "imports.updating_imports" : "imports.loading_imports"))

  try {
    const initialImportsData = await getInitialImportLayout()
    const { toUploadItems, itemList, groupedItemList } = initialImportsData
    console.log(toUploadItems, itemList, groupedItemList)
    // eslint-disable-next-line
    dispatch(importedItemsReceived({ toUploadItems, itemList, groupedItemList }))
    const currentPageMode = selectPageMode(getState())
    console.log("initImportsLayout currentPageMode: ", currentPageMode)
    if (!selectIsRecordListFetching(getState())) dispatch(expireImportsRecordListStatus())
    dispatch({ type: IMPORTS_INITIALIZED }) // dev failing here
    dispatch(closeInfoDimmer())
  } catch (error) {
    if (isAuthorizationError(error)) {
      dispatch(expireUser())
    } else {
      dispatch(showError(["app.error.default_error_message", "app.error.we_are_fixing_suffix"]))
      sentry.error(`action=initImportsLayout unexpected error: ${getErrorText(error)}`, { error })
    }
  }
}

export const reloadItems = () => (dispatch) =>
  Promise.resolve()
    .then(() => getInitialImportLayout())
    .then((initialImportsData) => {
      // eslint-disable-next-line
      console.log("reloadItems - initialImportsData: ", initialImportsData)
      const { toUploadItems, itemList, groupedItemList } = initialImportsData
      dispatch(importedItemsReceived({ toUploadItems, itemList, groupedItemList }))
      dispatch(expireImportsRecordListStatus())
      dispatch(expireRecordsStatus())
    })

export const uploadFiles = (files) => async (dispatch, getState) => {
  trackImportsUpload()
  const file = files[0]
  dispatch(showInfoDimmer("imports.info.uploading_files"))
  const selectedAccount = selectSelectedAccount(getState())
  try {
    if (!isImportsActivated(selectedAccount)) {
      await dispatch(activateImports(selectedAccount._id))
    }
  } catch (error) {
    if (isAuthorizationError(error)) {
      dispatch(expireUser())
      return
    } else {
      dispatch(showError(["imports.error.activation_failed", "app.error.we_are_fixing_suffix"]))
      console.error(`Imports activation failed on accountId=${selectedAccount._id}`, error)
      sentry.error(`action=uploadFiles Imports activation failed: ${getErrorText(error)}`, {
        error,
        accountId: selectedAccount._id,
      })
      return
    }
  }

  try {
    const importEmail = selectImportEmail(getState())
    const { userId } = selectUser(getState())

    // save automatic import status since parsing error disables it
    const automaticImportsEnabled = selectSelectedAccountAutomaticImportsEnabled(getState())

    await uploadCsvFiles(file, userId, importEmail)

    await dispatch(reloadItems())

    // Find the latest item id since (uploading files API does not return itemId of created item)
    // TODO docs service is synchronous and could return itemId as well as status from the backend
    const latestItem = selectLatestUploadItem(getState())

    if (
      (getFileExtension(file.name) === "ofx" || automaticImportsEnabled) &&
      latestItem.transactionId
    ) {
      dispatch(closeInfoDimmer())
      trackImportsSuccess()
      return
    } else if (automaticImportsEnabled) {
      // TODO show some info about error during automatic import therefore opening the mapping wizard
      // automatic imports was disabled (backend behavior)
      console.log(
        `action=uploadFiles automatic imports is enabled but the file ${file.name} contains an error`,
      )
    }

    // Load and open the mapping dialog: regular manual upload or automatic import with error
    dispatch(importItem(latestItem.itemId))
  } catch (error) {
    if (isAuthorizationError(error)) {
      dispatch(expireUser())
    } else if (path(["response", "status"], error) === 400) {
      dispatch(showInvalidFileModal())
      sentry.error(`action=uploadFiles bad request: ${getErrorText(error)}`, { error })
    } else {
      console.error("Uploading files failed", error.response, error)
      dispatch(showError(["imports.error.uploading_failed", "app.error.we_are_fixing_suffix"]))
      sentry.error(`action=uploadFiles unexpected error: ${getErrorText(error)}`, { error })
    }
  }
}

export const confirmDeactivateImports = (importsSettingsId) => ({
  type: CONFIRM_DEACTIVATE_IMPORTS,
  settingsId: importsSettingsId,
})

export const cancelDeactivateImports = () => ({ type: CANCEL_DEACTIVATE_IMPORTS })

export function deactivateImports() {
  return (dispatch, getState) => {
    const {
      name,
      _id: accountId,
      importSettings: { settingsId },
    } = selectEditingAccount(getState())
    dispatch({ type: DEACTIVATE_IMPORTS_PROGRESS })
    deleteImportSettings(settingsId)
      .then(() => {
        dispatch({ type: DEACTIVATE_IMPORTS })
      })
      .catch((error) => {
        if (isAuthorizationError(error)) {
          dispatch(expireUser())
        } else {
          console.error("action=deactivateImports unexpected error", error)
          sentry.error(`action=deactivateImports unexpected error: ${getErrorText(error)}`, {
            error,
            accountName: name,
            accountId,
            settingsId,
          })
          dispatch(showError(["imports.deactivate_error", "app.error.we_are_fixing_suffix"]))
          dispatch(cancelDeactivateImports())
        }
      })
  }
}

// FIXME this action is not handled, maybe just use showError()
export const showFilesUploadError = (error) => ({ type: CSV_FILES_CHANGED_ERROR, error })

export function openTutorial() {
  return { type: SET_TUTORIAL_OPEN_STATUS, payload: { tutorialOpen: true } }
}

export function closeTutorial() {
  return { type: SET_TUTORIAL_OPEN_STATUS, payload: { tutorialOpen: false } }
}

export function showInvalidFileModal() {
  return showInfoModal(
    "imports.errors.invalid_file_message",
    "imports.errors.invalid_file_header",
    true,
  )
}
