import PropTypes from 'prop-types'
import React from 'react'
import { Modal } from 'semantic-ui-react'
import { FormattedMessage } from 'src/frontend/modules/intl'
import { connect } from 'react-redux'
import * as actions from 'src/frontend/scenes/gdpr/actions'
import { selectGdprConsentModalOpen, selectGdprTermsOfServicesHtml } from 'src/frontend/scenes/gdpr/selectors'
import { PrimaryButton } from 'src/frontend/components/Buttons/Buttons'

const mapDispatchToProps = {
  grantConsent: actions.grantConsent,
  close: actions.closeConsentModal,
}

function mapStateToProps(state) {
  return {
    open: selectGdprConsentModalOpen(state),
    htmlContent: selectGdprTermsOfServicesHtml(state),
  }
}

function GdprConsentModal({ open, htmlContent, grantConsent, close }) {
  return (
    <Modal open={open} size="large" dimmer>
      <Modal.Header>
        <FormattedMessage id="gdpr.modal_window.title" />
        <span className="modal-close" onClick={close} />
      </Modal.Header>
      <Modal.Content scrolling>
        {/* eslint-disable-next-line react/no-danger */}
        <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
      </Modal.Content>
      <Modal.Actions>
        <PrimaryButton onClick={grantConsent}>
          <FormattedMessage id="gdpr.modal_window.agree_button" />
        </PrimaryButton>
      </Modal.Actions>
    </Modal>
  )
}

GdprConsentModal.propTypes = {
  open: PropTypes.bool.isRequired,
  htmlContent: PropTypes.string,
  grantConsent: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
}

GdprConsentModal.defaultProps = {
  htmlContent: null,
}

export default connect(mapStateToProps, mapDispatchToProps)(GdprConsentModal)
