import * as analyticsService from "src/backend/analytics/service"
import { getEndOfToday } from "src/backend/time/time"
import { reduceBy } from "src/common/utils"
import * as service from "src/backend/accounts/service"
import { receiveDashboardAccountsTotals } from "src/frontend/scenes/dashboard/actions"
import { receiveSettingsAccountsTotals } from "src/frontend/scenes/settings/accounts/actions"

export const ACCOUNTS_RECEIVE = "ACCOUNTS_RECEIVE"

export function receiveAccounts(accountsArray) {
  return (dispatch: Function) => {
    const ACCOUNT_ID = "_id"
    const accounts = accountsArray.reduce(reduceBy(ACCOUNT_ID), {})
    return dispatch({
      type: ACCOUNTS_RECEIVE,
      payload: accounts,
    })
  }
}

export function fetchAccounts() {
  return async (dispatch: Function) => {
    const accounts = await service.fetchAccounts()
    console.log("fetchAccounts", accounts)

    dispatch(receiveAccounts(accounts))
  }
}

export function fetchAccountsData() {
  return async (dispatch: Function) => {
    const filter = {
      period: {
        end: getEndOfToday(),
      },
    }
    // TODO make types for totally empty Filter
    const accountsTotals = await analyticsService.fetchAccountsTotals(filter)
    dispatch(receiveSettingsAccountsTotals(accountsTotals))
    dispatch(receiveDashboardAccountsTotals(accountsTotals))
  }
}
