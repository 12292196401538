import * as actions from './actions'
import { Currency } from 'src/types/Currency'
import { HashMap } from 'src/types/common'
import { Id } from 'src/types/CouchDb'
import { FormType } from 'src/frontend/scenes/records/recordForm/enums'
import _omitBy from 'lodash/omitBy'
import { isUndefined } from 'src/common/utils'
import { RecordFormValues } from 'src/frontend/scenes/records/recordForm/types'

export interface RecordFormState {
  formType: FormType
  formValues: RecordFormValues,
  initialFormValues: RecordFormValues,
  errors: HashMap<string>,
  open: boolean,
  loading: boolean,
  templateId: Id,
  recordIds: Id[],
  availableCurrencies: Currency[],
  fromAvailableCurrencies: Currency[],
  toAvailableCurrencies: Currency[],
}

const recordFormInitialState = {
  formType: FormType.ADD,
  recordIds: null,
  formValues: null,
  initialFormValues: null,
  errors: {},
  open: false,
  loading: false,
  templateId: null,
  availableCurrencies: [],
  fromAvailableCurrencies: [],
  toAvailableCurrencies: [],
}

export function recordForm(state: RecordFormState = recordFormInitialState, action): RecordFormState {
  switch (action.type) {
    case actions.SAVE_RECORD_SUCCESS: {
      return { ...recordFormInitialState }
    }
    case actions.CLOSE_FORM: {
      return recordFormInitialState
    }
    case actions.OPEN_FORM: {
      return {
        ...state,
        open: true,
        formValues: action.payload.formValues,
        initialFormValues: action.payload.initialFormValues,
        availableCurrencies: action.payload.availableCurrencies,
        fromAvailableCurrencies: action.payload.fromAvailableCurrencies,
        toAvailableCurrencies: action.payload.toAvailableCurrencies,
        recordIds: action.payload.recordIds,
        formType: action.payload.formType,
        errors: {},
      }
    }
    case actions.APPLY_TEMPLATE: {
      return {
        ...state,
        ...action.payload,
        templateId: action.payload.templateId,
        errors: {},
        formValues: { ...state.formValues, ...action.payload.formValues },
        availableCurrencies: action.payload.availableCurrencies,
      }
    }

    case actions.RESET_TEMPLATE: {
      return {
        ...state,
        templateId: null,
        errors: {},
        formValues: action.payload.formValues,
        availableCurrencies: action.payload.availableCurrencies,
      }
    }

    case actions.CHANGE_FORM_FIELD_VALUE: {
      const formValues = { ...state.formValues, ...action.payload }
      return { ...state, formValues: _omitBy(formValues, isUndefined) }
    }

    case actions.CHANGE_FORM_TYPE:
    case actions.CHANGE_FORM_CURRENCY:
    case actions.CHANGE_FORM_ACCOUNT: {
      return {
        ...state,
        formValues: { ...state.formValues, ...action.payload.formValues },
        availableCurrencies: action.payload.availableCurrencies || state.availableCurrencies,
        fromAvailableCurrencies: action.payload.fromAvailableCurrencies || state.fromAvailableCurrencies,
        toAvailableCurrencies: action.payload.toAvailableCurrencies || state.toAvailableCurrencies,
      }
    }

    case actions.REVERT_CHANGES: {
      return { ...state, formValues: state.initialFormValues }
    }

    case actions.SAVE_RECORD_START: {
      return { ...state, loading: true }
    }
    case actions.SAVE_RECORD_ERROR: {
      return { ...state, errors: { ...action.payload.errors }, loading: false }
    }
    default: {
      return state
    }
  }
}
