import { inMemoryTableNames } from "../db/inMemorySqlDbSchemaBuilder"
import { Platform } from "../enums"
import { CouchDbDocument } from "src/types/CouchDb"

export default function WebConfig(): CouchDbDocument {
  const CONFIG_ID = inMemoryTableNames.WEB_CONFIG
  return {
    reservedOwnerId: "",
    _id: CONFIG_ID,
    reservedModelType: CONFIG_ID,
    reservedSource: Platform.WEB,
    reservedUpdatedAt: "",
    reservedCreatedAt: "",
  }
}
