import React from 'react'
import ContactDetailHeader from 'src/frontend/scenes/contacts/contactDetail/components/ContactDetailHeader'
import { Contact } from 'src/types/Contact'
import ContactDetailTabs from 'src/frontend/scenes/contacts/contactDetail/components/ContactDetailTabs'
import styles from './ContactDetailContent.module.less'

interface Props {
  contact: Contact.ContactView
}

export default function ContactDetailContent({ contact }: Props) {
  return (
    <div className={styles.contactDetail}>
      <ContactDetailHeader contact={contact} />
      <ContactDetailTabs contact={contact} />
    </div>
  )
}
