import spected from 'spected'
import * as commonValidator from 'src/backend/common/validator'
import { Id } from 'src/types/CouchDb'
import _isEmpty from 'lodash/isEmpty'
import { WalletFormValidationError } from 'src/backend/errors'
import omit from 'lodash/omit'
import { MagicRulesFormValues } from 'src/frontend/scenes/settings/magicRules/types'

export function validate(formValues: Partial<MagicRulesFormValues> & { _id?: Id }, validationDependencies) {
  const {
    categories,
    magicRules,
    envelopes,
    recordTypes,
    accounts,
  } = validationDependencies

  const requiredFields = ['name', 'keywords']

  const valuesWithRequiredFields = {
    ...commonValidator.addRequiredFieldsAsUndefined(formValues, requiredFields),
  }

  const spec = {
    name: [
      [commonValidator.isNotEmpty, 'form.error.enter_name'],
      [commonValidator.hasUniqueName(omit(magicRules, formValues._id)), 'form.error.name_exists'],
      [commonValidator.isNotLongerThan(commonValidator.NAME_MAX_LENGTH), 'form.error.name_too_long'],
    ],
    categoryId: [
      [
        commonValidator.categoryExists(categories, envelopes),
        'record.form.category_does_not_exist',
      ],
      [commonValidator.isNotUnknownCategory(categories), 'record.form.category_can_not_be_unknown'],
    ],
    keywords: [
      [commonValidator.isNotEmptyArray, 'settings.rules.error.keyword.enter_keyword'],
    ],
    recordType: [
      [commonValidator.isNumber, 'record.form.record_state_does_not_exist'],
      [commonValidator.valueExists(recordTypes), 'record.form.record_state_does_not_exist'],
    ],
    accountId: [
      [commonValidator.isNotEmpty, 'record.form.select_account'],
      [commonValidator.entityExists(accounts), 'record.form.account_does_not_exist'],
    ],
  }

  const validationResult = spected(spec, valuesWithRequiredFields)
  const errors = commonValidator.convertValidationResultToErrors(validationResult)
  if (!_isEmpty(errors)) {
    throw new WalletFormValidationError(errors)
  }
}

