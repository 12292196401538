import React from 'react'
import WalletDownloadIcons from 'src/frontend/components/WalletDownloadIcons'
import './MaintenanceInProgress.less'
import PictureIcon from 'src/frontend/components/PictureIcon/PictureIcon'
import { FormattedMessage } from 'src/frontend/modules/intl'
import PublicLayout from 'src/frontend/scenes/auth/components/PublicLayout'

export default function MaintenanceInProgress() {

  return (
    <PublicLayout>
      <div className="auth-content small maintenance-page">
        <div>
          <PictureIcon name="maintenance" />
          <div>
            <h2><FormattedMessage id="maintenance.header" /></h2>
            <p>
              <FormattedMessage id="maintenance.text" />
            </p>
          </div>
        </div>
      </div>
      <WalletDownloadIcons />
    </PublicLayout>
  )
}
