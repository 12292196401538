import React, { FC } from "react"
import { mergeClasses } from "src/common/utils"
import RecordStyles from "src/frontend/components/RecordList/RecordList.module.less"
import TruncatedText from "../TruncatedText"
import { formatMessage, formatNumber } from "src/frontend/modules/intl"
import { AssetTransactionListItemType, TransactionType } from "src/types/investments.types"
import AssetIcon from "./AssetIcon"

interface Props {
  assetTransaction: AssetTransactionListItemType
}

const AssetTransactionListItem: FC<Props> = ({ assetTransaction }) => {
  return (
    <div
      className={mergeClasses(RecordStyles.recordListItem, RecordStyles.extended)}
      style={{ boxShadow: "none" }}
    >
      <div
        className={RecordStyles.columnContent}
        style={{ cursor: "auto" }}
      >
        <div className={RecordStyles.columnCategory}>
          <AssetIcon src={assetTransaction.logo} />
          <div
            className={mergeClasses(RecordStyles.recordCategory)}
            data-e2e="record-category-extended"
          >
            {`${assetTransaction.transactionType === TransactionType.BUY ? "+" : ""}${
              assetTransaction.quantity
            } ${assetTransaction.symbol}`}
          </div>
        </div>

        <div
          style={{ flex: "1" }}
          className={RecordStyles.columnAccount}
        >
          <div className={RecordStyles.recordAccount}>
            <span
              className={RecordStyles.accountBullet}
              style={{ backgroundColor: assetTransaction.accountColor }}
            />
            &nbsp;
            <TruncatedText
              text={formatMessage(
                assetTransaction.transactionType === TransactionType.BUY
                  ? "investments.assetTransaction.primaryLabel"
                  : "investments.assetTransaction.primaryLabelInverted",
                {
                  amount: formatNumber(assetTransaction.cashAmount / 100, {
                    format: "currency",
                    currency:
                      assetTransaction.cashAmountCurrencyCode ?? assetTransaction.priceCurrencyCode,
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }),
                  assetName: assetTransaction.name,
                },
              )}
              length={60}
              showPopup
            />
          </div>
        </div>

        <div
          className={RecordStyles.columnPayee}
          style={{ justifyContent: "end" }}
        >
          <div className={RecordStyles.recordPayee}>
            <TruncatedText
              text={formatMessage("investments.assetTransaction.secondaryLabel", {
                price: formatNumber(assetTransaction.price / 100, {
                  format: "currency",
                  currency: assetTransaction.priceCurrencyCode,
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }),
              })}
              length={40}
              showPopup
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default AssetTransactionListItem
