import {
  AssetTransaction,
  AssetTransactionDocument,
  TransactionType,
} from "src/types/investments.types"
import { startOfDay, startOfMonth, startOfWeek } from "../time/time"
export const assetTransactionConverter =
  () =>
  (assetTransaction: AssetTransactionDocument): AssetTransaction => {
    const date = new Date(assetTransaction.date)
    return {
      ...assetTransaction,
      date,
      day: startOfDay(date),
      month: startOfMonth(date),
      week: startOfWeek(date),
      quantity:
        assetTransaction.quantity *
        (assetTransaction.transactionType === TransactionType.BUY ? 1 : -1),
      cashAmount: assetTransaction.cashAmount
        ? assetTransaction.cashAmount *
          (assetTransaction.transactionType === TransactionType.BUY ? -1 : 1)
        : undefined,
    }
  }
