import React from 'react'
import { connect } from 'react-redux'
import { RootState } from 'src/types/State'
import { selectAccountsWithBalance } from 'src/frontend/scenes/dashboard/selectors'
import { AccountWithBalance, WidgetView } from 'src/frontend/scenes/dashboard/types'
import Widget from 'src/frontend/scenes/dashboard/components/Widget/Widget'
import { selectIsAccountsLoading } from 'src/frontend/modules/moduleStatus/selectors'
import styles from './AccountList.module.less'
import { Link } from 'react-router-dom'
import FormattedMessage from 'src/frontend/modules/intl/components/FormattedMessage'
import { BalanceByCurrencyWidget } from 'src/frontend/scenes/dashboard/widgets/BalanceByCurrencyBar/types'
import BasicAccountList from 'src/frontend/scenes/dashboard/widgets/AccountList/BasicAccountList'
import { ComplementaryButton } from 'src/frontend/components/Buttons/Buttons'

const NUM_OF_ACCOUNTS = 4

interface Props {
  widget: WidgetView & { data: Array<BalanceByCurrencyWidget> },
  accounts: AccountWithBalance[],
  loading: boolean,
}

function mapStateToProps(state: RootState) {
  return {
    accounts: selectAccountsWithBalance(state),
    loading: selectIsAccountsLoading(state),
  }
}

function AccountList({ widget, accounts }: Props) {
  const sampleAccounts = React.useMemo(() => accounts.slice(0, NUM_OF_ACCOUNTS), [accounts])

  const widgetAction = (
    <ComplementaryButton
      as={Link}
      to="/accounts"
    >
      <FormattedMessage id="menu.accounts" />
    </ComplementaryButton>
  )

  return (
    <Widget>
      <Widget.Header title={widget.title} action={widgetAction} />
      <Widget.Content>
        <div className={styles.accountList}>
          <BasicAccountList accounts={sampleAccounts} />
        </div>
      </Widget.Content>
    </Widget>
  )
}

export default connect(mapStateToProps)(AccountList)
