import React from 'react'
import './MobileNotSupported.less'
import WalletDownloadIcons from 'src/frontend/components/WalletDownloadIcons'
import { FormattedMessage } from 'src/frontend/modules/intl'
import PublicLayout from 'src/frontend/scenes/auth/components/PublicLayout'
import { isAppBoard } from 'src/common/environment'
import { isPhone } from 'src/common/runtime'

const WEB_URL = isAppBoard()
  ? 'web-board.budgetbakers.com'
  : 'web.budgetbakers.com'

export default function MobileNotSupported() {
  const webAppLink = <a href={`https://${WEB_URL}`} target="_blank" rel="noopener noreferrer">{WEB_URL}</a>

  return (
    <PublicLayout transparent={isPhone}>
      <div className="auth-content mobile-content">
        <div className="smartphone-tablet-circle" />
        <div className="not-supported-text">
          <p>
            <FormattedMessage
              id="app.info.mobile_device_unsupported_1"
              values={{
                url: webAppLink,
              }}
            />
          </p>
          <p>
            <FormattedMessage id="app.info.mobile_device_unsupported_2" />
          </p>
        </div>
        <WalletDownloadIcons />
      </div>
    </PublicLayout>
  )
}
