import React from 'react'
import { connect } from 'react-redux'
import { Divider, Form, Icon, Menu, Message, Modal } from 'semantic-ui-react'
import { formatMessage, FormattedMessage, FormattedMessageLabel } from 'src/frontend/modules/intl/index'
import * as selectors from 'src/frontend/scenes/contacts/contactForm/selectors'
import * as actions from 'src/frontend/scenes/contacts/contactForm/actions'
import { FormType } from 'src/frontend/scenes/settings/enums'
import { ContactFormValues } from 'src/frontend/scenes/contacts/contactForm/types'
import { Action, HashMap } from 'src/types/common'
import { RootState } from 'src/types/State'
import { Contact } from 'src/types/Contact'
import AutoCompleteInput from 'src/frontend/scenes/records/components/AutoCompleteInput'
import { fetchCompanies } from 'src/backend/rest/company'
import './ContactForm.less'
import { NAME_MAX_LENGTH } from 'src/backend/common/validator'
import { PrimaryButton } from 'src/frontend/components/Buttons/Buttons'

function mapStateToProps(state: RootState) {
  return {
    formType: selectors.selectFormType(state),
    formValues: selectors.selectFormValues(state),
    formOpen: selectors.selectFormOpen(state),
    errors: selectors.selectErrors(state),
    loading: selectors.selectLoading(state),
  }
}

const mapDispatchToProps = {
  handleOpenForm: actions.openAddContactForm,
  handleCloseForm: actions.closeNewContactForm,
  handleFieldChange: actions.changeFieldValue,
  handleFieldsChange: actions.changeFieldValues,
  handleSaveContact: actions.saveContact,
}

interface Props {
  formType: FormType
  formValues: ContactFormValues
  formOpen: boolean
  errors: HashMap<string>
  loading: boolean

  handleOpenForm: (contactName: string) => Action
  handleCloseForm: () => Action
  handleFieldChange: (fieldName: string, value: any) => Action
  handleFieldsChange: (fields: HashMap<any>) => Action
  handleSaveContact: () => void
}


function ContactForm({
  formType,
  formValues,
  formOpen,
  errors,
  loading,
  handleCloseForm,
  handleFieldChange,
  handleFieldsChange,
  handleSaveContact,
}: Props) {

  if (!formValues) {
    return null
  }

  const imageRow = formValues.avatarUrl && (
    <div className="contact-image">
      <div style={{ backgroundImage: `url('${formValues.avatarUrl}')` }}>
        <Icon name="times circle" onClick={() => handleFieldChange('avatarUrl', undefined)} />
      </div>
    </div>
  )

  const nameRow = (
    <>
      <div className="field contact-select">
        <FormattedMessageLabel id="form.name" />
        <AutoCompleteInput
          fetchOnMount={formType === FormType.ADD}
          fetch={fetchCompanies}
          changeOnTyping
          value={formValues.name}
          showNoResults={false}
          input={{
            placeholder: formatMessage('form.name'),
            autoFocus: true,
            maxLength: NAME_MAX_LENGTH,
            autoComplete: 'new-password',
            iconPosition: 'left',
          }}
          onChange={(_e, result) => {
            if (typeof result === 'string') {
              handleFieldChange('name', result)
            } else {
              handleFieldsChange({ avatarUrl: result.image, name: result.title, web: result.web })
            }
          }}
        />
      </div>
      <Message
        visible={!!errors.name}
        className="error-message"
        error
        content={<FormattedMessage id={errors.name} />}
      />
    </>
  )

  const webRow = (
    <div className="field">
      <Form.Input
        label={<FormattedMessageLabel id="contacts.form.web" />}
        value={formValues.web}
        placeholder={formatMessage('contacts.form.web')}
        error={!!errors.web}
        autoComplete="new-password"
        onChange={(_e, { value }) => handleFieldChange('web', value)}
      />
      {errors.web && <Message className="error-message" error content={<FormattedMessage id={errors.web} />} />}
    </div>
  )

  const emailRow = (
    <div className="field">
      <Form.Input
        label={<FormattedMessageLabel id="contacts.form.email" />}
        value={formValues.email}
        placeholder={formatMessage('contacts.form.email')}
        error={!!errors.email}
        autoComplete="new-password"
        onChange={(_e, { value }) => handleFieldChange('email', value)}
      />
      {errors.email && <Message className="error-message" error content={<FormattedMessage id={errors.email} />} />}
    </div>
  )

  const phoneRow = (
    <div className="field">
      <Form.Input
        label={<FormattedMessageLabel id="contacts.form.phone" />}
        value={formValues.phone}
        placeholder={formatMessage('contacts.form.phone')}
        error={!!errors.phone}
        autoComplete="new-password"
        onChange={(_e, { value }) => handleFieldChange('phone', value)}
      />
      {errors.phone && <Message className="error-message" error content={<FormattedMessage id={errors.phone} />} />}
    </div>
  )

  const noteRow = (
    <div className="field">
      <Form.TextArea
        name="note"
        label={<FormattedMessageLabel id="record.form.note" />}
        value={formValues.note ? formValues.note : ''}
        onChange={(_e, { value }) => handleFieldChange('note', value)}
      />
      {errors.note && <Message className="error-message" error content={<FormattedMessage id={errors.note} />} />}
    </div>
  )

  const typeRow = (
    <div className="field">
      <Menu fluid widths={4}>
        <Menu.Item
          active={formValues.type === Contact.Type.VENDOR}
          onClick={() => handleFieldChange('type', Contact.Type.VENDOR)}
        >
          <FormattedMessage id="contacts.type.VENDOR" />
        </Menu.Item>
        <Menu.Item
          active={formValues.type === Contact.Type.CUSTOMER}
          onClick={() => handleFieldChange('type', Contact.Type.CUSTOMER)}
        >
          <FormattedMessage id="contacts.type.CUSTOMER" />
        </Menu.Item>
        <Menu.Item
          active={formValues.type === Contact.Type.INTERNAL}
          onClick={() => handleFieldChange('type', Contact.Type.INTERNAL)}
        >
          <FormattedMessage id="contacts.type.INTERNAL" />
        </Menu.Item>
        <Menu.Item
          active={formValues.type === Contact.Type.OTHER}
          onClick={() => handleFieldChange('type', Contact.Type.OTHER)}
        >
          <FormattedMessage id="contacts.type.OTHER" />
        </Menu.Item>
      </Menu>
    </div>
  )

  const formName = formatMessage(formType === FormType.ADD
    ? 'contacts.add-contact'
    : 'contacts.edit-contact')

  const SaveButton = ({ children, isLoading }) => (
    <div className="field submit">
      <PrimaryButton
        onClick={handleSaveContact}
        disabled={isLoading}
        loading={isLoading}
        fluid
      >
        {children}
      </PrimaryButton>
    </div>
  )

  return (
    <Modal
      className="contact-form"
      size="small"
      open={formOpen}
      onClose={handleCloseForm}
    >
      <Modal.Header>
        {formName}
        <span className="modal-close" onClick={handleCloseForm} />
      </Modal.Header>
      <Modal.Content>
        <Form error autoComplete="off">
          <input type="hidden" value={1} />
          {imageRow}
          {nameRow}
          {typeRow}
          {emailRow}
          {phoneRow}
          {webRow}
          {noteRow}
          <Divider hidden section />
          <SaveButton isLoading={loading}>
            <FormattedMessage id="form.save" />
          </SaveButton>
        </Form>
      </Modal.Content>
    </Modal>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactForm)
