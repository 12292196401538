import React, { useCallback, useRef, useState } from 'react'
import { Divider, Form } from 'semantic-ui-react'
import { IntegrationProviders } from 'src/frontend/scenes/integrations/providersList/types'
import IntegrationRecipeParam = IntegrationProviders.IntegrationRecipe.IntegrationRecipeParam
import { FormattedMessage } from 'src/frontend/modules/intl'
import { PrimaryButton } from 'src/frontend/components/Buttons/Buttons'
import { useOauthTabCallback } from "src/frontend/scenes/integrations/components/hooks"
import { useSelector } from "react-redux"
import {
  setNewConnectionToLocaleStorage,
  setConnectionType,
  issetConnectionType,
  setIntegrationSourceNameToLocalStorage,
} from "src/backend/db/localStorage"
import { integrationSourceName } from "src/backend/integrations/enums"
import { selectNewConnection } from "../newConnection/selectors"
import { selectProviderDetail } from "../providersList/selectors"
import { IntegrationConnection } from "src/frontend/scenes/integrations/newConnection/types"
import ConnectionType = IntegrationConnection.ConnectionType
interface Props {
  param: IntegrationRecipeParam
  onChange: (field: string, value: string | number | boolean, shouldSubmit?: boolean) => void
}

export default function MFAOAuthRedirect({ param, onChange }: Props) {
  const [showOAuth, setShowOAuth] = useState(false)
  const OAuthWindowRef: React.MutableRefObject<Window> = useRef(null)
  const newConnectionState = useSelector(selectNewConnection)
  const provider = useSelector(selectProviderDetail)

  const onOAuthCallback = useCallback(({ queryParams }) => {
    onChange("queryParams", queryParams, true)
  }, [])

  useOauthTabCallback(OAuthWindowRef, onOAuthCallback)

  function handleSubmit() {
    const connectionType = !newConnectionState.accountId
      ? ConnectionType.NEW_ACCOUNT_CONNECTION
      : ConnectionType.MANUAL_CONNECTION
    if (!issetConnectionType()) setConnectionType(connectionType)

    newConnectionState.integrationProvider = provider
    setNewConnectionToLocaleStorage(newConnectionState)
    setIntegrationSourceNameToLocalStorage(integrationSourceName[provider.integrationSource])
    setShowOAuth(true)
    window.location.assign(param.value as string)
  }

  return !showOAuth ? (
    <Form>
      <Divider
        section
        hidden
      />
      <PrimaryButton
        fluid
        type="submit"
        onClick={handleSubmit}
      >
        <FormattedMessage id="integrations.newConnection.oauth.grant-access" />
      </PrimaryButton>
    </Form>
  ) : (
    <div>
      <p>
        <FormattedMessage id="integrations.newConnection.oauth.grant-access-notice" />
      </p>
    </div>
  )
}
