import React from 'react'
import {
  recordHasContact,
  recordHasNote, recordIsPlannedPayment, recordIsUncleared, sign,
} from 'src/backend/records/helpers'
import { FormattedMessage, FormattedMoney } from 'src/frontend/modules/intl'
import { RecordListItemRecord } from 'src/frontend/components/RecordList/types'
import { getFormattedRecordDate, getRecordTypeClassName } from 'src/frontend/components/RecordList/helpers'
import { CategoryIcon } from 'src/frontend/components/Icons/Icons'
import { isAppBoard } from 'src/common/environment'
import RecordContact from 'src/frontend/components/RecordList/RecordContact'
import styles from 'src/frontend/components/RecordList/RecordList.module.less'
import { mergeClasses } from 'src/common/utils'
import { Checkbox, Popup } from 'semantic-ui-react'
import TruncatedText from 'src/frontend/components/TruncatedText'

interface Props {
  record: RecordListItemRecord,
  showRelativeTime: boolean,
  className: string,
  noAccount?: boolean
  noNote?: boolean
  onClick?: (MouseEvent) => void
  onEditClick?: Function
  onCheck?: (MouseEvent) => void
  selected?: boolean
  editable?: boolean
}

BasicRecordListItem.defaultProps = {
  onClick: undefined,
  editable: false,
  selected: false,
  noAccount: false,
  noNote: false,
  className: '',
  onEditClick: undefined,
  onCheck: undefined,
}

export default function BasicRecordListItem({
  record,
  selected,
  className,
  showRelativeTime,
  editable,
  noAccount,
  noNote,
  onEditClick,
  onClick,
  onCheck,
}: Props) {
  const unclearedClass = recordIsUncleared(record) && styles.uncleared
  return (
    <li className={mergeClasses(styles.recordListItem, styles.basic, unclearedClass, className)} onClick={onClick}>
      {onCheck && (
        <div className={styles.columnCheckbox}>
          <Checkbox checked={selected} onChange={onCheck} />
        </div>
      )}
      <div className={styles.columnIcon}>
        <CategoryIcon
          icon={record.categoryIcon}
          color={record.color}
          hoverIcon={editable ? 'pencil' : undefined}
          onClick={() => editable && onEditClick && onEditClick()}
        />
      </div>
      <div className={styles.columnCategoryAccount}>
        <div className={styles.recordCategory} data-e2e="record-category-simple">
          {record.categoryName}
        </div>
        {!noNote && recordHasNote(record) && (
          <div className={styles.recordNote}>
            {record.note}
          </div>
        )}
        {recordHasContact(record) && isAppBoard() && (
          <div className={styles.recordContact}>
            <RecordContact record={record} />
          </div>
        )}
        {!noAccount && (
          <div className={styles.recordAccount}>
            <span className={styles.accountBullet} style={{ backgroundColor: record.accountColor }} />
            &nbsp;
            <TruncatedText text={record.accountName} length={30} showPopup />
          </div>
        )}
      </div>
      <div className={styles.columnAmountDate}>
        <div className={mergeClasses(styles.recordAmount, getRecordTypeClassName(record), unclearedClass)}>
          <FormattedMoney value={record.amount} currency={record.currencyCode} sign={sign(record)} />
        </div>

        <div className={styles.recordDate}>
          {getFormattedRecordDate(record, showRelativeTime)}
          {recordIsUncleared(record) && (
            <>
              &nbsp;
              <Popup
                trigger={<span className={mergeClasses(styles.icon, styles.uncleared_ic)} />}
                basic
                content={<div><FormattedMessage id="record_state.UNCLEARED" /></div>}
              />
            </>
          )}
          {recordIsPlannedPayment(record) && (
            <>
              &nbsp;
              <Popup
                trigger={<span className={mergeClasses(styles.icon, styles.planned_ic)} />}
                basic
                content={<div>{record.ruleText}</div>}
              />
            </>
          )}
        </div>
      </div>
    </li>
  )
}
