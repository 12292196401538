import * as helpers from 'src/common/helpers'
import { Currency } from 'src/types/Currency'
import {
  Bar,
  BarsData,
  BoardCashFlowData,
  BoardOperatingProfitData,
} from 'src/frontend/scenes/dashboard/widgets/BoardCashFlowBar/types'
import { CashFlowBarColors } from 'src/frontend/scenes/dashboard/constants'

export function getBoardCashFlow(referentialCurrency: Currency) {
  return (data: BoardCashFlowData): BarsData => {
    if (!data) {
      return null
    }

    const {
      operatingRevenue,
      otherCashInflow,
      operatingCosts,
      otherCashOutFlow,
    } = data.currentPeriod

    const previousPeriodRevenues = data.previousPeriod.operatingRevenue + data.previousPeriod.otherCashInflow
    const previousPeriodCosts = data.previousPeriod.operatingCosts + data.previousPeriod.otherCashOutFlow

    const previousPeriodCashFlow = previousPeriodRevenues + previousPeriodCosts
    const currentPeriodCashFlow = operatingRevenue + otherCashInflow + operatingCosts + otherCashOutFlow

    const bars = [
      {
        label: 'money-received',
        invertedChange: false,
        change: helpers.countPercentChange(previousPeriodRevenues, operatingRevenue + otherCashInflow),
        values: [
          { value: operatingRevenue, color: CashFlowBarColors.DARK_GREEN, label: 'operating-revenue' },
          { value: otherCashInflow, color: CashFlowBarColors.LIGHT_GREEN, label: 'other-cash-inflow' },
        ],
      },
      {
        label: 'money-spent',
        invertedChange: true,
        change: helpers.countPercentChange(previousPeriodCosts, operatingCosts + otherCashOutFlow),
        values: [
          { value: -operatingCosts, color: CashFlowBarColors.DARK_RED, label: 'operating-costs' },
          { value: -otherCashOutFlow, color: CashFlowBarColors.LIGHT_RED, label: 'other-cash-outflow' },
        ],
      },
    ]

    return {
      bars,
      total: getMaxValue(bars),
      summary: {
        value: currentPeriodCashFlow,
        change: helpers.countPercentChange(previousPeriodCashFlow, currentPeriodCashFlow),
      },
      referentialCurrency,
    }
  }
}

export function getBoardOperatingProfit(referentialCurrency: Currency) {
  return (data: BoardOperatingProfitData): BarsData => {
    if (!data) {
      return null
    }

    const {
      salesRevenue,
      otherRevenue,
      variableCosts,
      fixedCosts,
    } = data.currentPeriod

    const previousPeriodRevenues = data.previousPeriod.salesRevenue + data.previousPeriod.otherRevenue
    const previousPeriodCosts = data.previousPeriod.variableCosts + data.previousPeriod.fixedCosts

    const previousPeriodCashFlow = previousPeriodRevenues + previousPeriodCosts
    const currentPeriodCashFlow = salesRevenue + otherRevenue + variableCosts + fixedCosts

    const bars: Bar[] = [
      {
        label: 'operating-revenue',
        invertedChange: false,
        change: helpers.countPercentChange(previousPeriodRevenues, salesRevenue + otherRevenue),
        values: [
          { value: salesRevenue, color: CashFlowBarColors.DARK_GREEN, label: 'sales-revenue' },
          { value: otherRevenue, color: CashFlowBarColors.LIGHT_GREEN, label: 'other-revenue' },
        ],
      },
      {
        label: 'operating-costs',
        invertedChange: true,
        change: helpers.countPercentChange(previousPeriodCosts, variableCosts + fixedCosts),
        values: [
          { value: -variableCosts, color: CashFlowBarColors.DARK_RED, label: 'variable-costs' },
          { value: -fixedCosts, color: CashFlowBarColors.LIGHT_RED, label: 'fixed-costs' },
        ],
      },
    ]

    return {
      bars,
      total: getMaxValue(bars),
      summary: {
        value: currentPeriodCashFlow,
        change: helpers.countPercentChange(previousPeriodCashFlow, currentPeriodCashFlow),
      },
      referentialCurrency,
    }
  }
}

function getMaxValue(bars: Bar[]): number {

  return bars && bars.reduce((acc, bar) => {
    return Math.max(acc, ...bar.values.map((value) => value.value))
  }, 0)
}
