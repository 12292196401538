import { utcDateAsISOString } from 'src/backend/time/time'
import { isUndefinedOrNull } from 'src/common/utils'
import { Platform } from 'src/backend/enums'
import { AnyDocument } from 'src/types/CouchDb'
import { Account } from 'src/types/Account'
import { Currency } from 'src/types/Currency'
import { HashMap } from 'src/types/common'

export function isEachOfSameType(type: string, entities: AnyDocument[]) {
  return entities.every(entity => entity.reservedModelType === type)
}

export function getTypesOfEntities(entities: AnyDocument[]) {
  const flattenedTypes = entities.reduce((accumulatedTypes, entity) => {
    return entity.reservedModelType ? [...accumulatedTypes, entity.reservedModelType] : accumulatedTypes
  }, [])
  return [...new Set(flattenedTypes)]
}

export function sortEntities<T>(entities: HashMap<T>) {
  return (Object.values(entities) as T[]).sort(sortByPosition)
}

export function sortByPosition(a, b) {
  if (isUndefinedOrNull(a.position) && isUndefinedOrNull(b.position)) {
    return 0
  }
  if (isUndefinedOrNull(a.position)) {
    return 1
  }
  if (isUndefinedOrNull(b.position)) {
    return -1
  }
  return a.position - b.position
}

export const isModelType = modelType => entity => entity.reservedModelType === modelType

export const isNotModelType = modelType => entity => !isModelType(modelType)(entity)

export function accountCurrencyCode(
  account: Account,
  referentialCurrency: Currency,
  currencies: HashMap<Currency>,
) {
  const UNKNOWN_CURRENCY_CODE = '---'
  const accountHasCurrency = (account.currencyId && account.currencyId.length > 0)
  const currency = accountHasCurrency ? currencies[account.currencyId] : null

  if (accountHasCurrency && !currency) {
    console.log(`Account's currency with ID ${account.currencyId} does not exists!`)
    return UNKNOWN_CURRENCY_CODE
  }

  return currency ? currency.code : referentialCurrency.code
}

export function addDeletedAttribute(entity: AnyDocument) {
  return {
    ...entity,
    _deleted: true,
    reservedDeletedAt: utcDateAsISOString(),
    reservedDeletedSource: Platform.WEB,
  }
}

export function addUpdateAttributes(entity: AnyDocument) {
  return {
    ...entity,
    reservedUpdatedAt: utcDateAsISOString(),
    reservedSource: Platform.WEB,
  }
}
