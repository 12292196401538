import React, { useEffect, useState } from "react"
import styles from "src/frontend/scenes/accounts/accountDetail/components/AccountDetailTabs.module.less"
import { Tab } from "semantic-ui-react"
import { formatMessage } from "src/frontend/modules/intl/i18n"
import { Account } from "src/types/Account"
import AccountDetailRecordList from "src/frontend/scenes/accounts/accountDetail/components/AccountDetailRecordList"
import AccountDetailOverview from "src/frontend/scenes/accounts/accountDetail/components/AccountDetailOverview"
import { isConnected } from "src/backend/accounts/helpers"
import AvailableCashTab from "./Investments/AvailableCashTab"
import { useSelector } from "react-redux"
import { RootState } from "src/types/State"
import PortfolioTab from "./Investments/PortfolioTab"
import AccountDetailInvestmentOverview from "./Investments/AccountDetailInvestmentOverview"

interface Props {
  account: Account
  availableCash: number
  portfolioBalance: number
}

export default function AccountDetailTabs({ account, availableCash, portfolioBalance }: Props) {
  const currencies = useSelector((state: RootState) => state.modules.currencies)
  const panes = [
    {
      menuItem: formatMessage({ B: "account.overview", W: "balance" }),
      render: () => <AccountDetailOverview accountId={account._id} />,
    },
    {
      menuItem: formatMessage({ B: "transactions", W: "menu.records" }),
      render: () => (
        <AccountDetailRecordList
          accountId={account._id}
          isConnected={isConnected(account)}
        />
      ),
    },
  ]

  const investmentPanes = [
    {
      menuItem: formatMessage({ B: "account.overview", W: "balance" }),
      render: () => <AccountDetailInvestmentOverview accountId={account._id} />,
    },
    {
      menuItem: formatMessage("availableCash"),
      render: () => (
        <AvailableCashTab
          accountId={account._id}
          availableCash={availableCash}
          accountCurrencyCode={currencies[account.currencyId].code}
        />
      ),
    },
    {
      menuItem: formatMessage("account.portfolio"),
      render: () => (
        <PortfolioTab
          accountId={account._id}
          portfolioBalance={portfolioBalance}
          accountCurrencyCode={currencies[account.currencyId].code}
        />
      ),
    },
  ]

  return (
    <div className={styles.contactTabs}>
      <Tab
        menu={{ secondary: true, pointing: true, className: styles.menu }}
        panes={account?.investmentInfo ? investmentPanes : panes}
      />
    </div>
  )
}
