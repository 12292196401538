import { reduceBy } from 'src/common/utils'
import * as service from 'src/backend/standingOrders/service'

export const STANDING_ORDERS_RECEIVE = 'STANDING_ORDERS_RECEIVE'

export function receiveStandingOrders(standingOrdersArray) {
  const STANDING_ORDER_ID = '_id'
  const standingOrders = standingOrdersArray.reduce(reduceBy(STANDING_ORDER_ID), {})
  return { type: STANDING_ORDERS_RECEIVE, payload: standingOrders }
}

export function fetchStandingOrders() {
  return dispatch => {
    service.fetchStandingOrders().then(standingOrders => dispatch(receiveStandingOrders(standingOrders)))
  }
}
