import React, { useEffect, useState } from 'react'
import { useScript } from 'src/frontend/scenes/billing/components/hooks'
import { Loader } from 'semantic-ui-react'
import paymentMethodStyles from 'src/frontend/scenes/billing/paymentMethod/components/PaymentMethod.module.less'
import { PaymentMethodFormType, PaymentProps } from 'src/frontend/scenes/billing/paymentMethod/components/types'
import { usePaypalInstance } from 'src/frontend/scenes/billing/paymentMethod/hooks'
import { getPaypalKey, isAppBoard } from 'src/common/environment'
import { FormattedMessage } from 'src/frontend/modules/intl'
import { maskEmail } from 'src/frontend/scenes/billing/paymentMethod/helpers'
import { isRecurringProduct } from 'src/frontend/scenes/billing/product/helpers'
import { isUndefinedOrNull } from 'src/common/utils'
import ConfirmPanel from 'src/frontend/scenes/billing/paymentMethod/components/ConfirmPanel'
import { withIntlConsumer } from 'src/frontend/modules/intl/components/withIntlConsumer'

function PaypalPayment({
  braintreeClient,
  localeUnderscore,
  loading,
  formType,
  selectedProduct,
  onTokenize,
}: PaymentProps) {
  const locale = localeUnderscore === 'en'
    ? 'en_US'
    : localeUnderscore

  // update payment method is always recurring
  const shouldVault = (formType === PaymentMethodFormType.UPDATE || isRecurringProduct(selectedProduct)).toString()

  const [loaded, error] = useScript(`https://www.paypal.com/sdk/js?client-id=${getPaypalKey()}&vault=${shouldVault}&commit=false&locale=${locale}&currency=${selectedProduct?.currencyCode?.toUpperCase()}`)

  const [paymentDetails, setPaymentDetails] = useState(null)
  const [nonce, setNonce] = useState<string>(null)

  const paypalCheckoutInstance = usePaypalInstance(braintreeClient)

  useEffect(() => {
    if (loaded && paypalCheckoutInstance) {
      (window as any).paypal.Buttons({
        style: {
          layout: 'horizontal',
          height: 50,
          fundingicons: false,
          shape: isAppBoard() ? 'rect' : 'pill',
        },

        // create order for lifetime (on-time) payment
        createOrder: !isUndefinedOrNull(selectedProduct) && !isRecurringProduct(selectedProduct)
          ? () => (paypalCheckoutInstance as any).createPayment({
            flow: 'checkout',
            currency: selectedProduct.currencyCode,
            amount: selectedProduct.price.toFixed(2),
            intent: 'capture',
          })
          : undefined,

        // create billing agreement for recurring subscriptions
        createBillingAgreement: isUndefinedOrNull(selectedProduct) || isRecurringProduct(selectedProduct)
          ? () => (paypalCheckoutInstance as any).createPayment({
            flow: 'vault',
          })
          : undefined,

        onApprove: (data) => (paypalCheckoutInstance as any).tokenizePayment(data)
          .then((payload) => {
            setNonce(payload.nonce)
            setPaymentDetails(payload.details)
          }),

        onCancel: (data) => {
          console.log('data', data)
        },

        onError: (err) => {
          console.error('error', err)
        },
      }).render('#paypal-button')
    }
  }, [loaded, paypalCheckoutInstance])

  const isFormReady = !!braintreeClient && !!paypalCheckoutInstance && loaded

  return (
    <>
      <h2>
        <FormattedMessage
          id={formType === PaymentMethodFormType.ADD
            ? 'billing.payment-method.paypal.header'
            : 'billing.payment-method.update'}
        />
      </h2>
      {error && <div><FormattedMessage id="billing.error.network-error" /></div>}
      {!nonce || !paymentDetails ? (
        <>
          {!isFormReady && (
            <div className={paymentMethodStyles.innerContent}>
              <Loader active inline="centered" />
            </div>
          )}
          <div className={!isFormReady ? paymentMethodStyles.loading : undefined}>
            <div className={paymentMethodStyles.innerContent}>
              <div>
                <p><FormattedMessage id="billing.payment-method.paypal.description" /></p>
                <div id="paypal-button" />
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className={paymentMethodStyles.innerContent}>
          <div>
            <h3>
              <FormattedMessage id="billing.payment-method.paypal.account" />
            </h3>
            <p>
              {maskEmail(paymentDetails.email)}
            </p>
          </div>
        </div>
      )}

      <ConfirmPanel
        formType={formType}
        selectedProduct={selectedProduct}
        disabled={!nonce}
        loading={loading}
        onClick={() => onTokenize(nonce)}
      />
    </>
  )
}

export default withIntlConsumer(PaypalPayment)
