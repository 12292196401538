import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { Modal } from 'semantic-ui-react'
import { formatMessage, FormattedMessage } from 'src/frontend/modules/intl/index'
import { clearError as clearErrorAction } from 'src/frontend/Error/actions'
import { PrimaryButton, SecondaryButton } from 'src/frontend/components/Buttons/Buttons'

const mapDispatchToProps = { clearError: clearErrorAction }

const createFormattedMessage = (messageIds) => {
  return (Array.isArray(messageIds) ? messageIds : [messageIds])
    .map(msgId => formatMessage(msgId)).join(' ')
}

class ErrorMessageModal extends React.Component {
  // Click handlers

  onModalCloseButtonClick = (e) => {
    const { closeable, clearError, onClose } = this.props

    if (closeable) {
      clearError()
      if (onClose) {
        onClose(e)
      }
    }
  }

  static onReloadPageButtonClick() {
    window.location.reload()
  }

  render() {

    const { messageId, okButton, reloadButton, closeable, dimmer } = this.props

    const formattedMessage = createFormattedMessage(messageId)

    return (
      <Modal open={!!formattedMessage} size="small" dimmer={dimmer}>
        <Modal.Header>
          <FormattedMessage id="app.error.header" />
          {closeable && <span className="modal-close" onClick={this.onModalCloseButtonClick} />}
        </Modal.Header>
        <Modal.Content>
          {formattedMessage}
        </Modal.Content>
        <Modal.Actions>

          {okButton && closeable && (
            <SecondaryButton onClick={this.onModalCloseButtonClick}>
              <FormattedMessage className="capitalize" id="form.ok" />
            </SecondaryButton>
          )}
          {reloadButton && (
            <PrimaryButton onClick={ErrorMessageModal.onReloadPageButtonClick}>
              <FormattedMessage id="app.error.reload_page" />
            </PrimaryButton>
          )}
        </Modal.Actions>
      </Modal>
    )
  }
}

ErrorMessageModal.propTypes = {
  messageId: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  closeable: PropTypes.bool,
  okButton: PropTypes.bool,
  reloadButton: PropTypes.bool,
  dimmer: PropTypes.bool,
  onClose: PropTypes.func,
  clearError: PropTypes.func.isRequired,
}

ErrorMessageModal.defaultProps = {
  messageId: null,
  okButton: false,
  reloadButton: false,
  dimmer: true,
  closeable: true,
  onClose: null,
}

export default connect(null, mapDispatchToProps)(ErrorMessageModal)
