import React from 'react'
import Address from 'src/frontend/scenes/settings/billing/Receipt/components/Address'
import { BraintreeBillingAddress } from 'src/frontend/scenes/billing/billingInformation/types'
import { getCountryName } from 'src/backend/countries/countries'

export default function CustomerAddress({
  company,
  streetAddress,
  extendedAddress,
  city,
  postalCode,
  countryCodeAlpha2,
  vatNumber,
}: BraintreeBillingAddress) {
  return (
    <Address
      companyName={company ?? ''}
      streetCity={[streetAddress, extendedAddress, city].filter(item => !!item).join(', ')}
      countryZip={[postalCode, countryCodeAlpha2 && getCountryName(countryCodeAlpha2)].join(' ')}
      VAT={vatNumber ?? ''}
    />
  )
}
