import React from 'react'
import { FormattedMessage } from 'src/frontend/modules/intl'
import { getTOSContentUrl } from 'src/common/environment'

export default function TermsOfServiceLink() {
    const termsOfServiceLink = getTOSContentUrl()

    return (
        <a href={termsOfServiceLink} target='_blank' rel='noopener noreferrer'>
            <FormattedMessage id='auth.signup.terms_of_service.link_text' />
        </a>
    )
}
