import _truncate from 'lodash/truncate'
import { Popup } from 'semantic-ui-react'
import React from 'react'

interface Props {
  length?: number,
  showPopup?: boolean,
  text: string,
}

TruncatedText.defaultProps = {
  length: 50,
  showPopup: false,
}

export default function TruncatedText({ length, showPopup, text }: Props) {
  return showPopup && text.length > length
    ? (
      <Popup
        hoverable
        trigger={<span>{_truncate(text, { length })}</span>}
        content={text}
        basic
        size="tiny"
        popperModifiers={{ preventOverflow: { boundariesElement: 'window' } }}
        position="top center"
      />
    )
    : <span>{_truncate(text, { length })}</span>
}
