import React from 'react'
import { Modal, Grid } from 'semantic-ui-react'
import { FormattedMessage } from 'src/frontend/modules/intl'
import { FilterType } from 'src/types/Filter'
import styles from './RemoveFilterModal.module.less'
import { ComplementaryButton, PrimaryButton, SecondaryButton } from 'src/frontend/components/Buttons/Buttons'

type Props = {
  onConfirm: Function,
  filter: FilterType,
}

type State = {
  processing: boolean,
  open: boolean,
}

export default class RemoveFilterModal extends React.Component<Props, State> {

  state = { processing: false, open: false }

  handleConfirm = () => {
    this.props.onConfirm()
    this.setState({ processing: true })
  }

  handleClose = () => {
    this.setState({ processing: false, open: false })
  }

  handleOpen = () => {
    this.setState({ processing: false, open: true })
  }

  render() {
    const { filter } = this.props
    const { processing, open } = this.state

    const trigger = (
      <ComplementaryButton
        color="red"
        className={styles.styles}
      >
        <FormattedMessage id="delete" />
      </ComplementaryButton>
    )

    return (
      <Modal
        trigger={trigger}
        open={open}
        size="small"
        onClose={this.handleClose}
        onOpen={this.handleOpen}
      >
        <Modal.Header><FormattedMessage id="filter.remove-filter" /></Modal.Header>
        <Modal.Content>
          <div>
            <FormattedMessage
              id="filter.remove-prompt"
              values={{ filterName: filter.name ? (<strong>{filter.name.toUpperCase()}</strong>) : '' }}
            />
          </div>
        </Modal.Content>
        <Modal.Actions>
          <Grid>
            <Grid.Column width={8}>
              <SecondaryButton fluid onClick={this.handleClose}>
                <FormattedMessage id="form.no" />
              </SecondaryButton>
            </Grid.Column>
            <Grid.Column width={8}>
              <PrimaryButton disabled={processing} loading={processing} fluid color="red" onClick={this.handleConfirm}>
                <FormattedMessage id="form.yes" />
              </PrimaryButton>
            </Grid.Column>
          </Grid>
        </Modal.Actions>
      </Modal>
    )
  }
}
