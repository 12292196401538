import React, { useEffect, useState } from "react"
import { formatMessage, FormattedMessage } from "src/frontend/modules/intl"
import { useReferralSource } from "src/frontend/scenes/auth/signup/hooks"
import * as userActions from "src/frontend/modules/user/actions"
import { OAuthLoginMethod, ReferralSource } from "src/types/User"
import { GoogleAuth, AppleId, Oauth2User } from "src/frontend/scenes/auth/types"
import { getFacebookAppID, getGoogleAppID } from "src/common/environment"
import { connect } from "react-redux"
import OAuthButton from "src/frontend/scenes/auth/login/OAuthButton"
import { withRouter } from "react-router"
import { mergeClasses } from "src/common/utils"
import { RouteComponentProps } from "react-router-dom"
import iconFacebook from "src/frontend/assets/images/icons/icon-login-facebook.svg"
import iconGoogle from "src/frontend/assets/images/icons/icon-login-google.svg"
import iconApple from "src/frontend/assets/images/icons/icon-login-apple.svg"
import stylesOauth from "src/frontend/scenes/auth/login/LoginOAuthForm.module.less"
import { Button } from "semantic-ui-react"

interface Props extends RouteComponentProps {
  remember?: boolean
  referrer?: string
  loginOAuth: (
    userPayload: Oauth2User,
    method: OAuthLoginMethod,
    referralSource: ReferralSource,
  ) => Promise<void>
  loginApple: typeof userActions.loginApple
}

const mapDispatchToProps = {
  loginOAuth: userActions.loginOAuth,
  loginApple: userActions.loginApple,
}

function LoginOAuthForm({ referrer, history, loginOAuth, loginApple }: Props) {
  const consent = true
  const referralSource = useReferralSource()
  const appleId: AppleId.rootObject = (window as any)?.AppleID

  const signInGoogle = () => {
    try {
      const client: GoogleAuth.TokenClientConfig = (
        google as unknown as GoogleAuth.rootObject
      ).accounts.oauth2.initTokenClient({
        client_id: getGoogleAppID(),
        scope: "openid email profile",
        callback: (tokenResponse: GoogleAuth.TokenResponse) => {
          fetch("https://www.googleapis.com/oauth2/v1/userinfo", {
            method: "GET",
            headers: { Authorization: `Bearer ${tokenResponse.access_token}` },
          }).then(async (response) => {
            console.log("Google token response: ", tokenResponse)
            const responseJson = await response.json()
            const userPayload: Oauth2User = {
              profile: {
                email: responseJson.email,
              },
              token: {
                accessToken: tokenResponse.access_token,
                expiresIn: tokenResponse.expires_in,
                tokenType: tokenResponse.token_type,
              },
            }
            try {
              loginOAuth(userPayload, OAuthLoginMethod.GOOGLE, referralSource).then(
                () => referrer && history.push(referrer),
              )
            } catch {
              console.log("error during BB API auth")
            }
          })
        },
      })
      client.requestAccessToken()
    } catch (error) {
      console.warn("Error during Google authentication:", error)
    }
  }

  const signInApple = async () => {
    if (!appleId) return

    try {
      const {
        authorization: { id_token },
      } = await appleId.auth.signIn()
      try {
        loginApple(id_token, referralSource)
      } catch {
        console.log("error during BB API auth")
      }
    } catch (error) {
      console.warn("Error during Apple authentication:", error)
    }
  }

  const handleOauthClick = (method: OAuthLoginMethod) => (userPayload: Oauth2User) => {
    return loginOAuth(userPayload, method, referralSource).then(
      () => referrer && history.push(referrer),
    )
  }

  return (
    <div className={stylesOauth.loginOauthForm}>
      <div className={stylesOauth.divider}>
        <div className={stylesOauth.dividerMessage}>
          <FormattedMessage id="auth.login.continue.oauth" />
        </div>
      </div>
      <div className={stylesOauth.buttonsWrapper}>
        <OAuthButton
          className={mergeClasses(stylesOauth.oauthButton, OAuthLoginMethod.FACEBOOK)}
          loginEnabled={consent}
          layout={OAuthLoginMethod.FACEBOOK}
          provider={OAuthLoginMethod.FACEBOOK}
          appId={getFacebookAppID()}
          onLoginSuccess={handleOauthClick(OAuthLoginMethod.FACEBOOK)}
          onLoginFailure={(error) => console.warn("error", error)}
        >
          <img
            src={iconFacebook}
            alt={OAuthLoginMethod.FACEBOOK}
          />
          <FormattedMessage id="auth.login_with.facebook" />
        </OAuthButton>
        <Button
          aria-label={formatMessage("auth.login_with.google")}
          onClick={signInGoogle}
        >
          <img
            src={iconGoogle}
            alt="Google sign in"
            aria-hidden="true"
          />
          <span>Google</span>
        </Button>
        <Button
          aria-label={formatMessage("auth.signup.withApple")}
          onClick={signInApple}
        >
          <img
            src={iconApple}
            alt="Apple sign in"
            aria-hidden="true"
          />
          <span>Apple</span>
        </Button>
      </div>
    </div>
  )
}

export default withRouter(connect(null, mapDispatchToProps)(LoginOAuthForm))
