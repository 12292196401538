import React from 'react'
import { FormattedMessage } from 'src/frontend/modules/intl'
import styles from './ViewDefinitionForm.module.less'
import { ViewDefinition, ViewDefinitionOptions } from 'src/frontend/scenes/analytics/types'
import ViewDefinitionItem from 'src/frontend/scenes/analytics/ViewDefinitionItem'
import { ChartGroupByType, ChartSubjectType } from 'src/frontend/scenes/analytics/enums'
import { Interval, IntervalCompareToType } from 'src/backend/enums'
import { ChartType } from 'src/frontend/components/chart/ChartType'
import { FilterType } from 'src/types/Filter'

type Props = {
  values: ViewDefinition,
  options: ViewDefinitionOptions,
  filter: FilterType,
  onChange: Function,
}

export default function ViewDefinitionForm({ values, options, filter, onChange }: Props) {

  const SUBJECT = {
    name: 'subject',
    label: <FormattedMessage id="analytics.view_definition.show" />,
    options: options.subject.map(valueToOption()),
  }

  const GROUP_BY = {
    name: 'groupBy',
    label: <FormattedMessage id="analytics.view_definition.group_by" />,
    options: options.groupBy.map(valueToOption('analytics.view_definition.group_by.')),
  }

  const GRANULARITY = {
    name: 'granularity',
    label: <FormattedMessage id="analytics.view_definition.by" />,
    options: options.granularity.map(valueToOption('analytics.granularity_type.')),
  }

  const CHART_TYPE = {
    name: 'chartType',
    label: <FormattedMessage id="analytics.view_definition.as" />,
    options: options.chartType.map(valueToOption('analytics.chart_type.')),
  }

  const COMPARE_TO = {
    name: 'compareTo',
    label: <FormattedMessage id="analytics.view_definition.compare_to" />,
    options: options.compareTo.map(enumValueToOption(IntervalCompareToType, 'analytics.compare_to.')),
  }

  const viewDefinitionItems = values.subject === ChartSubjectType.INCOMES_EXPENSES_REPORT
  || values.subject === ChartSubjectType.BOARD_CASH_FLOW_REPORT
  || values.subject === ChartSubjectType.OPERATING_PROFIT_REPORT
    ? [SUBJECT]
    : [
      SUBJECT,
      GROUP_BY,
      GRANULARITY,
      CHART_TYPE,
      COMPARE_TO,
    ]


  return (
    <div className={styles.analyticsViewDefinition}>
      {viewDefinitionItems.map(item => {
        return (
          <ViewDefinitionItem
            key={item.name}
            showIcon={viewDefinitionItems.length === 1}
            name={item.name}
            label={item.label}
            disabled={isDisabled(item, values, filter)}
            value={values[item.name]}
            options={item.options}
            onChange={onChange}
          />
        )
      })}
    </div>
  )
}


function valueToOption(prefix = '') {
  return (value: string | number) => ({ text: <FormattedMessage id={`${prefix}${value}`} />, value })
}

function enumValueToOption(enumerator, prefix = '') {
  return (value: string | number) => ({ text: <FormattedMessage id={`${prefix}${enumerator[value]}`} />, value })
}

function isDisabled(item, viewDefinition: ViewDefinition, filter: FilterType) {
  if (item.name === 'compareTo'
    && (viewDefinition.groupBy !== ChartGroupByType.NONE
      || viewDefinition.chartType !== ChartType.LINE
      || filter.period.interval === Interval.ALL_THE_TIME)) {
    return true
  }

  return false
}
