import React from 'react'
import './FormattedMessageLabelWithDescription.less'
import { FormattedMessage } from 'src/frontend/modules/intl'

interface Props {
  id: string | { W: string, B: string }
}

function FormattedMessageLabelWithDescription({ id }: Props) {
  return (
    <label className="formatted-label-with-description">
      <div className="label"><FormattedMessage id={id} /></div>
      <div className="description"><FormattedMessage id={`${id}.description`} /></div>
    </label>
  )
}

export default FormattedMessageLabelWithDescription
