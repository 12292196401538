import * as templatesService from "src/backend/templates/service"
import { fetchCategories } from "src/frontend/modules/categories/actions"
import * as selectors from "./selectors"
import { selectUser } from "src/frontend/modules/user/selectors"
import * as validator from "src/backend/templates/validator"
import { UserPaymentTypes, RecordType } from "src/backend/enums"
import * as currencyService from "src/backend/currencies/service"
import * as categoryService from "src/backend/categories/service"
import * as envelopes from "src/backend/categories/envelopes"
import * as templatesActions from "src/frontend/modules/templates/actions"
import * as settingsActions from "src/frontend/scenes/settings/actions"
import { inMemoryTableNames } from "src/backend/db/inMemorySqlDbSchemaBuilder"
import _isEmpty from "lodash/isEmpty"
import { FormType } from "src/frontend/scenes/settings/enums"
import { getAvailableCurrencies, getCurrencyFormObject } from "src/backend/records/helpers"

export const SETTINGS_TEMPLATES_SAVE_START = "SETTINGS_TEMPLATES_SAVE_START"
export const SETTINGS_TEMPLATES_SAVE_SUCCESS = "SETTINGS_TEMPLATES_SAVE_SUCCESS"
export const SETTINGS_TEMPLATES_SAVE_ERROR = "SETTINGS_TEMPLATES_SAVE_ERROR"
export const SETTINGS_TEMPLATES_CHANGE_FIELD_VALUE = "SETTINGS_TEMPLATES_CHANGE_FIELD_VALUE"
export const SETTINGS_TEMPLATES_CHANGE_NAME = "SETTINGS_TEMPLATES_CHANGE_NAME"

export const SETTINGS_TEMPLATES_OPEN_FORM = "SETTINGS_TEMPLATES_OPEN_FORM"
export const SETTINGS_TEMPLATES_CLOSE_FORM = "SETTINGS_TEMPLATES_CLOSE_FORM"

export const SETTINGS_TEMPLATES_DELETE_START = "SETTINGS_TEMPLATES_DELETE_START"
export const SETTINGS_TEMPLATES_DELETE_SUCCESS = "SETTINGS_TEMPLATES_DELETE_SUCCESS"
export const SETTINGS_TEMPLATES_DELETE_ERROR = "SETTINGS_TEMPLATES_DELETE_ERROR"

export const SETTINGS_TEMPLATES_CHANGE_ACCOUNT = "SETTINGS_TEMPLATES_CHANGE_ACCOUNT"

export function reorderTemplates(oldIndex, newIndex) {
  return settingsActions.reorder(
    oldIndex,
    newIndex,
    inMemoryTableNames.TEMPLATE,
    templatesActions.fetchTemplates,
  )
}

export function saveTemplate(formValues, formType, template = {}) {
  return async (dispatch, getState) => {
    dispatch(saveTemplateStart(template))

    const validationDependencies = await templatesService.getValidationDependencies()

    const validationData = { ...template, ...formValues }
    const errors = validator.validate(validationData, validationDependencies)

    if (!_isEmpty(errors)) {
      dispatch(saveTemplateError(errors))
    } else {
      const user = selectUser(getState())
      Promise.resolve()
        .then(() => templatesService.saveTemplate(formValues, user, template._id))
        .then((savedTemplate) => {
          dispatch(saveTemplateSuccess(savedTemplate))
          dispatch(templatesActions.fetchTemplates())
          dispatch(fetchCategories())
        })
        .catch((error) => {
          const templateErrors = { general: errorMessage(error) }
          dispatch(saveTemplateError(templateErrors))
          throw error
        })
    }
  }
}

function saveTemplateStart(template) {
  return { type: SETTINGS_TEMPLATES_SAVE_START, payload: { template } }
}

function saveTemplateSuccess(template) {
  return (dispatch) => {
    dispatch({ type: SETTINGS_TEMPLATES_SAVE_SUCCESS, payload: { template } })
    return dispatch(closeForm())
  }
}

function errorMessage(error) {
  // FIXME WALLET-6385 similar issue like labels/actions, account/actions: duplicit and not i18n
  return error.name === "conflict" ? "Template conflict!" : "Saving template failed!"
}

function saveTemplateError(errors) {
  // FIXME WALLET-6385 inspect if handled
  return {
    type: SETTINGS_TEMPLATES_SAVE_ERROR,
    payload: { errors },
  }
}

export function openForm(formType, template = null) {
  return async (dispatch, getState) => {
    const { name } = selectors.selectTemplatesForm(getState()).formValues
    const predefinedName = formType === FormType.ADD && name ? { name: name.trim() } : {}
    const referentialCurrency = await currencyService.getReferentialCurrency()
    const accountId = (template && template.accountId) || null
    const availableCurrencies = await getAvailableCurrencies(accountId)
    const currencyId = (template && template.currencyId) || referentialCurrency._id
    const category =
      template && template.categoryId && (await categoryService.getCategory(template.categoryId))
    const superEnvelopeId =
      category && envelopes.getSuperEnvelopeIdByEnvelopeId(category.envelopeId)

    const formValues =
      formType === FormType.ADD
        ? {
            ...getInitialFormValues(),
            currencyId,
            ...predefinedName,
          }
        : {
            name: template.name,
            currencyId,
            superEnvelopeId,
            type: template.type,
            amount: template.amount,
            categoryId: template.categoryId,
            accountId: template.accountId,
            labels: template.labels,
            paymentType: template.paymentType,
            payee: template.payee,
            note: template.note,
          }

    return dispatch({
      type: SETTINGS_TEMPLATES_OPEN_FORM,
      payload: {
        formType,
        template,
        formValues,
        availableCurrencies,
      },
    })
  }
}

export function closeForm() {
  return { type: SETTINGS_TEMPLATES_CLOSE_FORM }
}

export const changeName = (field, value) => {
  return {
    type: SETTINGS_TEMPLATES_CHANGE_NAME,
    payload: { [field]: value },
  }
}

export const changeFormFieldValue = (field, value) => {
  const payloadValue = (typeof value === "string" && value.trim()) || value
  return {
    type: SETTINGS_TEMPLATES_CHANGE_FIELD_VALUE,
    payload: { [field]: payloadValue },
  }
}

export const changeAccount = (field, value, currencyId) => {
  return (dispatch) => {
    Promise.resolve()
      .then(() => getCurrencyFormObject(value, currencyId))
      .then((payload) => {
        return dispatch({
          type: SETTINGS_TEMPLATES_CHANGE_ACCOUNT,
          payload: {
            formValues: { [field]: value, currencyId: payload.updatedCurrencyId },
            availableCurrencies: payload.availableCurrencies,
          },
        })
      })
  }
}

export function deleteTemplate(templateId) {
  return (dispatch) => {
    console.log(templateId)
    dispatch(deleteTemplateStart(templateId))
    Promise.resolve()
      .then(() => templatesService.removeTemplate(templateId))
      .then((template) => {
        dispatch(deleteTemplateSuccess(template))
        dispatch(templatesActions.fetchTemplates())
      })
      .catch((error) => {
        dispatch(deleteTemplateError(error, templateId))
        throw error
      })
  }
}

function deleteTemplateStart(templateId) {
  return {
    type: SETTINGS_TEMPLATES_DELETE_START,
    payload: { template: templateId },
  }
}

function deleteTemplateError(error, template) {
  // FIXME WALLET-6385 similar issue like labels/actions, account/actions: duplicit and not i18n
  return {
    type: SETTINGS_TEMPLATES_DELETE_ERROR,
    payload: { errors: { general: errorMessage(error) }, template },
  }
}

function deleteTemplateSuccess() {
  return {
    type: SETTINGS_TEMPLATES_DELETE_SUCCESS,
    payload: { template: null },
  }
}

function getInitialFormValues() {
  return {
    name: "",
    type: RecordType.EXPENSE,
    amount: 0,
    accountId: "",
    categoryId: "",
    labels: [],
    paymentType: UserPaymentTypes.CASH,
    payee: "",
    note: "",
  }
}
