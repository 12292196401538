import React, { useState } from "react"
import { Checkbox, Dropdown, Icon, Popup } from "semantic-ui-react"
import Carousel from "react-images"
import {
  hasUnknownCategory,
  recordHasAccountProperties,
  recordHasConnectedAccount,
  recordHasContact,
  recordHasGPS,
  recordHasLabels,
  recordHasNote,
  recordHasPayee,
  recordHasPhotos,
  recordHasWarranty,
  recordIsInReferentialCurrency,
  recordIsPlannedPayment,
  recordIsUncleared,
  sign,
} from "src/backend/records/helpers"
import { formatMessage, FormattedMessage, FormattedMoney } from "src/frontend/modules/intl"
import styles from "src/frontend/components/RecordList/RecordList.module.less"
import { RecordListItemRecord } from "src/frontend/components/RecordList/types"
import {
  getRecordTypeClassName,
  imageNavigationFormatter,
} from "src/frontend/components/RecordList/helpers"
import { CategoryIcon } from "src/frontend/components/Icons/Icons"
import Labels from "src/frontend/scenes/records/components/Labels"
import { isAppBoard } from "src/common/environment"
import RecordContact from "src/frontend/components/RecordList/RecordContact"
import { mergeClasses } from "src/common/utils"
import RemoveRecordForm from "src/frontend/scenes/records/removeForm/components/RemoveRecordForm"
import { isUnknownEnvelopeByEnvelopeId } from "src/backend/categories/envelopes"
import TruncatedText from "src/frontend/components/TruncatedText"
import { PrimaryButton } from "src/frontend/components/Buttons/Buttons"

type Props = {
  record: RecordListItemRecord
  selected?: boolean
  isRemoving?: boolean
  onRecordDelete: (records: RecordListItemRecord[]) => void
  onCheckBoxChange?: (SyntheticEvent) => void
  onEditRecordClick: (SyntheticEvent) => void
  onAssignClick?: (SyntheticEvent) => void
}

export default function ExtendedRecordListItem({
  record,
  selected,
  isRemoving,
  onCheckBoxChange,
  onEditRecordClick,
  onRecordDelete,
  onAssignClick,
}: Props) {
  const selectedClassName = selected && styles.selected
  const plannedPaymentClassName = recordIsPlannedPayment(record) && styles.planned

  const unclearedClassName = recordIsUncleared(record) && styles.uncleared

  const onCheckboxChangeUndefined = onCheckBoxChange === undefined
  const canEditRecord =
    (!recordHasConnectedAccount(record) || !recordIsUncleared(record)) &&
    !recordIsPlannedPayment(record)

  const [removeFormOpen, setRemoveFormOpen] = useState<boolean>(false)

  const options = [
    { key: "edit", text: formatMessage("edit"), onClick: onEditRecordClick },
    !recordHasConnectedAccount(record) && {
      key: "remove",
      text: formatMessage("delete"),
      onClick: () => setRemoveFormOpen(true),
    },
  ].filter(Boolean)

  const trigger = (
    <div className={styles.toolbar}>
      <Icon
        name="ellipsis vertical"
        fitted
      />
    </div>
  )

  return (
    <div
      className={mergeClasses(
        styles.recordListItem,
        styles.extended,
        selectedClassName,
        plannedPaymentClassName,
        unclearedClassName,
      )}
    >
      {!onCheckboxChangeUndefined && canEditRecord && (
        <div className={styles.columnCheckbox}>
          <Checkbox
            checked={selected}
            onChange={onCheckBoxChange}
          />
        </div>
      )}
      <div
        className={styles.columnContent}
        onClick={canEditRecord ? onEditRecordClick : undefined}
      >
        <div className={styles.columnCategory}>
          <CategoryIcon
            color={record.color}
            icon={record.categoryIcon}
          />
          <div
            className={mergeClasses(
              styles.recordCategory,
              hasUnknownCategory(record) && styles.unknownCategory,
            )}
            data-e2e="record-category-extended"
          >
            {record.categoryName}
          </div>
        </div>

        {isAppBoard() && (
          <div className={styles.recordContact}>
            {recordHasContact(record) && <RecordContact record={record} />}
          </div>
        )}

        {recordHasAccountProperties(record) && (
          <div className={styles.columnAccount}>
            <div className={styles.recordAccount}>
              <span
                className={styles.accountBullet}
                style={{ backgroundColor: record.accountColor }}
              />
              &nbsp;
              <TruncatedText
                text={record.accountName}
                length={35}
                showPopup
              />
            </div>
          </div>
        )}

        {!isAppBoard() && recordHasPayee(record) && (
          <div className={styles.columnPayee}>
            <div className={styles.recordPayee}>
              <TruncatedText
                text={record.payee}
                length={40}
                showPopup
              />
            </div>
          </div>
        )}

        {recordHasNote(record) && (
          <div className={styles.columnNote}>
            <div className={styles.recordNote}>
              <TruncatedText
                text={record.note}
                length={40}
                showPopup
              />
            </div>
          </div>
        )}
        {recordHasLabels(record) && (
          <div className={styles.columnLabels}>
            <Labels labelsView={record.labelsView} />
          </div>
        )}

        <div className={styles.columnAmount}>
          <div
            className={mergeClasses(
              styles.recordAmount,
              getRecordTypeClassName(record),
              unclearedClassName,
            )}
          >
            <FormattedMoney
              value={record.amount}
              currency={record.currencyCode}
              sign={sign(record)}
              isTransfer={record.transfer}
              recordType={record.type}
              colored
            />
          </div>
          <div className={styles.recordAmountRefCurrency}>
            {!recordIsInReferentialCurrency(record) && (
              <span>
                ~
                <FormattedMoney
                  value={record.refAmount}
                  currency={record.referentialCurrencyCode}
                />
              </span>
            )}
          </div>
          <div className={styles.recordIcons}>
            {recordHasGPS(record) && record.place && (
              <Popup
                hoverable
                trigger={<span className={mergeClasses(styles.icon, styles.marker_ic)} />}
                content={`${record.place.name || ""}${
                  record.place.name && record.place.address ? ", " : ""
                }${record.place.address || ""}`}
                basic
                position="top center"
              />
            )}
            {recordHasPhotos(record) && (
              <Popup
                style={{ width: "20rem", height: "20rem" }}
                hoverable
                trigger={<span className={mergeClasses(styles.icon, styles.slrCamera)} />}
                content={
                  <Carousel
                    components={{ Footer: null }}
                    styles={{
                      navigationNext: imageNavigationFormatter,
                      navigationPrev: imageNavigationFormatter,
                    }}
                    views={record.photos.map((photo) => ({ ...photo, source: photo.url }))}
                  />
                }
                basic
                position="top center"
              />
            )}
            {recordHasWarranty(record) && (
              <span className={mergeClasses(styles.icon, styles.securityChecked)} />
            )}
          </div>
          {recordIsUncleared(record) && (
            <div className={styles.recordUncleared}>
              <span className={mergeClasses(styles.icon, styles.uncleared_ic)} />
              <FormattedMessage id="record_state.UNCLEARED" />
            </div>
          )}
        </div>
        {isAppBoard() &&
          !recordIsUncleared(record) &&
          isUnknownEnvelopeByEnvelopeId(record.envelopeId) && (
            <div className={styles.assignButton}>
              <PrimaryButton
                onClick={(e) => {
                  e.stopPropagation()
                  return onAssignClick(e)
                }}
              >
                <FormattedMessage id="form.assign" />
              </PrimaryButton>
            </div>
          )}
        <Dropdown
          className={styles.columnToolbar}
          trigger={trigger}
          options={options}
          disabled={recordHasConnectedAccount(record) && recordIsUncleared(record)}
          icon={null}
          direction="left"
          closeOnChange
        />
        <RemoveRecordForm
          items={[record]}
          open={removeFormOpen}
          processing={isRemoving}
          onCancelClick={() => setRemoveFormOpen(false)}
          onConfirmClick={() => onRecordDelete(!recordHasConnectedAccount(record) ? [record] : [])}
        />
      </div>
    </div>
  )
}
