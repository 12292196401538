import * as commonRepository from "src/backend/common/repository"
import { getCategoryByCategoryId } from "src/backend/categories/service"
import { utcDateAsISOString } from "src/backend/time/time"
import { addUpdateAttributes } from "src/backend/common/helpers"
import { updateAuditData } from "src/backend/common/service"
import * as repository from "./repository"
import { inMemoryTableNames } from "src/backend/db/inMemorySqlDbSchemaBuilder"
import uuid from "uuid"
import { enumValues, Platform, UserPaymentTypes } from "src/backend/enums"
import * as accountsRepository from "src/backend/accounts/repository"
import * as categoriesRepository from "src/backend/categories/repository"
import * as currenciesRepository from "src/backend/currencies/repository"
import * as envelopes from "src/backend/categories/envelopes"

export function getTemplate(templateId) {
  return repository.findById(templateId)
}

export async function removeTemplate(templateId) {
  const template = await repository.findById(templateId)
  return commonRepository.removeBulk([template])
}

export async function saveTemplate(formValues, user, templateId) {
  let templateData = {}
  if (templateId) {
    const template = await repository.findById(templateId)
    templateData = { ...addUpdateAttributes(template) }
  } else {
    const defaultTemplate = getDefaultTemplate()
    const position =
      ((await commonRepository.findHighestPosition(inMemoryTableNames.TEMPLATE)) || 0) + 1000

    templateData = {
      ...updateAuditData(user, utcDateAsISOString)(defaultTemplate),
      position,
    }
  }

  const category = await getCategoryByCategoryId(formValues.categoryId, user)
  const categoryId = category && category._id

  const templateDocument = {
    ...templateData,
    ...formValues,
    categoryId,
  }

  return commonRepository.updateBulk([templateDocument], inMemoryTableNames.TEMPLATE)
}

function getDefaultTemplate() {
  return {
    _id: `-Template_${uuid.v4()}`,
    accountId: "",
    amount: 0,
    categoryId: "",
    currencyId: "",
    name: "",
    paymentType: 0,
    position: 0,
    type: 0,
    reservedCreatedAt: null,
    reservedModelType: "Template",
    reservedOwnerId: null,
    reservedAuthorId: null,
    reservedSource: Platform.WEB,
  }
}

export function fetchTemplates() {
  return repository.getAllTemplates()
}

export async function getValidationDependencies() {
  const templates = await repository.findAllAsHashMap()
  const accounts = await accountsRepository.findAllAsHashMap()
  const currencies = await currenciesRepository.findAllAsHashMap()
  const categories = await categoriesRepository.findAllAsHashMap()
  const envelopesMap = envelopes.getEnvelopesMap()

  return {
    templates,
    accounts,
    currencies,
    categories,
    envelopes: envelopesMap,
    paymentTypes: enumValues(UserPaymentTypes),
  }
}
