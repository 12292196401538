import React, { SyntheticEvent } from 'react'
import { Form, Message } from 'semantic-ui-react'
import { FormattedMessage, FormattedMessageLabel } from 'src/frontend/modules/intl'

interface Props {
  value: string,
  error: string
  onChange: (e: SyntheticEvent) => void
}

export default function EmailField({ value, error, onChange }: Props) {
  return (
    <>
      <Form.Input
        type="email"
        autoFocus
        label={<FormattedMessageLabel id="auth.email" />}
        name="email"
        value={value}
        autoComplete="username"
        onChange={onChange}
      />

      {error && (
        <Message className="error-message" error content={<FormattedMessage id={error} />} />
      )}
    </>
  )
}
