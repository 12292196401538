import { Action } from 'src/types/common'
import {
  CONTACT_LIST_CHANGE_ORDER,
} from 'src/frontend/scenes/contacts/contactList/actions'
import { ContactListOrderBy } from 'src/frontend/scenes/contacts/contactList/enums'

export interface ContactListState {
  order: ContactListOrderBy
}

const initState: ContactListState = {
  order: ContactListOrderBy.NAME_ASC,
}

export function contactList(state: ContactListState = initState, action: Action): ContactListState {
  switch (action.type) {

    case CONTACT_LIST_CHANGE_ORDER: {
      return { ...state, order: action.payload }
    }

    default: {
      return state
    }
  }
}
