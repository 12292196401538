import { HashMap } from 'src/types/common'
import { Account } from 'src/types/Account'
import { Category, RecordCategory } from 'src/types/Category'
import { Currency } from 'src/types/Currency'
import * as recordsSelectors from 'src/frontend/scenes/records/recordList/selectors'
import { isAppBoard } from 'src/common/environment'
import { Contact } from 'src/types/Contact'
import ContactDocument = Contact.ContactDocument

export function aggregateLastRecordsData(
  accounts: HashMap<Account>,
  categories: Category[],
  currencies: HashMap<Currency>,
  contacts: HashMap<ContactDocument>,
) {
  return (widgetData) => {
    return widgetData ?
      widgetData.map(record => {
        const category: RecordCategory = recordsSelectors.getRecordCategory(record, categories)
        const account: Account = recordsSelectors.getRecordAccount(record, accounts)
        const currency: Currency = recordsSelectors.getRecordCurrency(record, currencies)

        // BOARD only
        const contact = isAppBoard() ? recordsSelectors.getRecordContact(record, contacts) : undefined

        return {
          ...record,
          ...category,
          contact,
          accountName: account.name,
          accountColor: account.color,
          currencyCode: currency.code,
        }
      })
      : null
  }
}
