import React from 'react'
import ColumnLayout from 'src/frontend/components/ColumnLayout/ColumnLayout'
import { formatMessage, FormattedMessage } from 'src/frontend/modules/intl'
import { Dropdown } from 'semantic-ui-react'
import {
  selectAccountListAccounts,
  selectOrderBy,
} from "src/frontend/scenes/accounts/accountsList/selectors"
import * as actions from "src/frontend/scenes/accounts/accountsList/actions"
import { useDispatch, useSelector } from "react-redux"
import basicLayoutStyles from "src/frontend/components/ColumnLayout/BasicLayout.module.less"
import styles from "./Accounts.module.less"
import { openAccountForm } from "src/frontend/scenes/settings/accounts/actions"
import { FormType } from "src/frontend/scenes/settings/enums"
import { AccountsListOrderBy } from "src/frontend/scenes/accounts/accountsList/enums"
import AccountsList from "src/frontend/scenes/accounts/accountsList/components/AccountsList"
import AccountsFilter from "src/frontend/scenes/accounts/filter/components/AccountsFilter"

export function getOrderOptions() {
  return [
    {
      text: "Default",
      value: AccountsListOrderBy.POSITION_ASC,
    },
    {
      text: "A-Z",
      value: AccountsListOrderBy.NAME_ASC,
    },
    {
      text: "Z-A",
      value: AccountsListOrderBy.NAME_DESC,
    },
    {
      text: formatMessage("sort-by.balance_lowest_first"),
      value: AccountsListOrderBy.BALANCE_ASC,
    },
    {
      text: formatMessage("sort-by.balance_highest_first"),
      value: AccountsListOrderBy.BALANCE_DESC,
    },
  ]
}

const Accounts = () => {
  const dispatch = useDispatch()
  const order = useSelector(selectOrderBy)
  const allAccounts = useSelector(selectAccountListAccounts)

  const disableInputs = allAccounts.length < 2

  return (
    <ColumnLayout
      title={<FormattedMessage id="menu.accounts" />}
      action
      onActionClick={() => dispatch(openAccountForm(FormType.ADD))}
      topPanel
      leftPanel={
        <>
          <AccountsFilter disabled={disableInputs} />
          <div className={styles.sorting}>
            <span className={basicLayoutStyles.filterLabel}>
              <FormattedMessage id="records.sort-by" />
            </span>
            <Dropdown
              selection
              value={order}
              disabled={disableInputs}
              options={getOrderOptions()}
              onChange={(_e, { value }) =>
                dispatch(actions.changeOrder(value as AccountsListOrderBy))
              }
            />
          </div>
        </>
      }
      content={<AccountsList />}
    />
  )
}

export default Accounts
