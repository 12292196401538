import { RootState } from 'src/types/State'
import { AddVoucherModalState, UserSessionState } from 'src/frontend/modules/modals/reducer'
import { AddVoucherModalStatus } from 'src/frontend/modules/modals/components/AddVoucherTypes'

export function selectUserSession(state: RootState): UserSessionState {
  return state.modules.modals.userSession
}

export function selectIsAddVoucherModalOpen(state: RootState): boolean {
  return selectAddVoucherModalStatus(state) !== AddVoucherModalStatus.CLOSED
}

export function selectAddVoucherModalStatus(state: RootState): AddVoucherModalStatus {
  return selectAddVoucher(state).modalStatus
}

export function selectInitialVoucherCode(state: RootState): string | undefined {
  return selectAddVoucher(state).voucherCode
}

function selectAddVoucher(state: RootState): AddVoucherModalState {
  return state.modules.modals.addVoucher
}
