
/**
 * Values corresponds to ribeez.proto UserConsent
 * @type {{GEOLOCATION: string, EMAILING: string}}
 */
export enum UserConsentType {
    EMAILING = 'emailing',
    POLICY = 'policy',
}

export enum UserDeleteDataType {
    DELETE_DATA = 'delete_data',
    DELETE_DATA_CONFIRM = 'delete_data_confirm',
    DELETE_USER_AND_DATA = 'delete_user_and_data',
    DELETE_USER_AND_DATA_CONFIRM = 'delete_user_and_data_confirm',
}