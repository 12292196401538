/**
 * Defines onboarding stages and also their order.
 */
export enum OnBoardingStage {
  REGISTRATION,
  BASE_CURRENCY,
  KB_BANK_INTEGRATION,
  ACCOUNT_NAME,
  CASH_BALANCE,
  FINISHED_ONBOARDING,
}
