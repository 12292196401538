import { Id } from 'src/types/CouchDb'
import { IntegrationProviders } from 'src/frontend/scenes/integrations/providersList/types'
import IntegrationProviderDetail = IntegrationProviders.IntegrationProviderDetail
import { IntegrationSource, integrationSourceName } from "src/backend/integrations/enums"
import * as integrationsBackend from 'src/backend/rest/backend/integrations'
import { KB_PROVIDER_CODE } from 'src/backend/integrations/kb/constants'

export function fetchKBIntegrationProvider(userId: Id): Promise<IntegrationProviderDetail> {
  const SOURCE_NAME = integrationSourceName[IntegrationSource.BUDGETBAKERS]

  return integrationsBackend.fetchIntegrationProvidersDetailByProviderCode(userId, SOURCE_NAME, KB_PROVIDER_CODE)
}
