import { User } from 'src/types/User'
import {
  SYSTEM_CATEGORIES_ONE_CLICK_WIDGET,
  SYSTEM_CATEGORIES_TRANSFER_ID,
  SYSTEM_CATEGORIES_UNKNOWN_ID,
  SYSTEM_CATEGORIES_BANK_STATEMENT,
} from "src/backend/categories/envelopes"
import { getCategoryByEnvelopeId } from "src/backend/categories/service"
import { OnBoardingStage } from "src/frontend/scenes/onBoarding/firstRun/enums"
import { OnBoardingStatus } from "src/backend/onBoarding/types"
import { Account } from "src/types/Account"
import { Currency } from "src/types/Currency"
import { isAppBoard } from "src/common/environment"
import { isKBUser } from "src/backend/user/service"

export function initOnBoardingStatus(
  user: User,
  referentialCurrency: Currency,
  accounts: Account[],
): OnBoardingStatus {
  const MANDATORY_ENVELOPE_IDS = [
    SYSTEM_CATEGORIES_ONE_CLICK_WIDGET,
    SYSTEM_CATEGORIES_TRANSFER_ID,
    SYSTEM_CATEGORIES_UNKNOWN_ID,
    SYSTEM_CATEGORIES_BANK_STATEMENT,
  ]

  MANDATORY_ENVELOPE_IDS.forEach((envelopeId) => getCategoryByEnvelopeId(envelopeId, user, true))

  const hasReferentialCurrency = !!referentialCurrency
  const hasAccounts = accounts && accounts.length > 0

  return {
    [OnBoardingStage.REGISTRATION]: !!user,
    [OnBoardingStage.BASE_CURRENCY]: hasReferentialCurrency,
    [OnBoardingStage.KB_BANK_INTEGRATION]: !isAppBoard() || !isKBUser(user) || hasAccounts,
    [OnBoardingStage.ACCOUNT_NAME]: !isAppBoard() || hasAccounts,
    [OnBoardingStage.CASH_BALANCE]: isAppBoard() || hasAccounts,
  }
}
