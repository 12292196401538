import { createSelector } from 'reselect'
import { RootState } from 'src/types/State'
import { selectModules } from 'src/frontend/modules/selectors'
import { MagicRuleState } from 'src/frontend/modules/magicRules/reducer'
import { MagicRule } from 'src/types/MagicRule'
import { compareStrings } from 'src/common/utils'
import { flattenKeywords } from 'src/backend/magicRules/helpers'

export const selectMagicRules = (state: RootState): MagicRuleState => selectModules(state).magicRules
export const selectMagicRulesAsArray: (RootState) => MagicRule[] = createSelector(
  selectMagicRules,
  (magicRules) => {
    return Object.values(magicRules)
      .sort((magicRule1, magicRule2) => {
        const stringA = magicRule1 && magicRule1.name ? magicRule1.name.toLowerCase() : ''
        const stringB = magicRule2 && magicRule2.name ? magicRule2.name.toLowerCase() : ''
        return compareStrings(stringA, stringB)
      })
  },
)

export const selectFlattenedMagicRulesKeywords = createSelector(selectMagicRules, (magicRules) => {
  return flattenKeywords(magicRules)
})
