import React from 'react'
import {connect} from 'react-redux'
import GenericConfirmationModal from 'src/frontend/scenes/settings/privacy/components/GenericConfirmationModal'
import {cancelDeleteDataDialog, deleteData} from 'src/frontend/scenes/settings/privacy/actions'
import {
    selectDeleteDataConfirmModalOpen,
    selectIsConsentLoading,
} from 'src/frontend/scenes/settings/privacy/selectors'

function mapStateToProps(state) {
    return {
        open: selectDeleteDataConfirmModalOpen(state),
        loading: selectIsConsentLoading(state),
    }
}

const mapDispatchToProps = {
    handleClose: cancelDeleteDataDialog,
    handleConfirm: deleteData,
}

interface Props {
    open: boolean
    handleConfirm: Function
    loading: boolean
    handleClose: () => void
}

function DeleteDataConfirmationModal({open, handleConfirm, loading, handleClose}: Props) {
    return (
        <GenericConfirmationModal
            open={open}
            headerMessageId='settings.privacy.delete_all_user_data_confirmation.title'
            contentMessageId='settings.privacy.delete_all_user_data_confirmation.description'
            actions={[
                {
                    loading,
                    disabled: loading,
                    primary: true,
                    color: 'green',
                    messageId: 'settings.privacy.delete_all_user_data_confirmation.confirm_button',
                    callback: handleConfirm,
                    inverted: false,
                },
            ]}
            close={handleClose}
        />
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(DeleteDataConfirmationModal)
