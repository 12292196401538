import { getUserInfo } from "src/backend/rest/backend"
import { isAppBoard } from "src/common/environment"
import { formatMessage } from "src/frontend/modules/intl"

export const getCustomerCareUrl = async () => {
  const user = await getUserInfo()
  const FALLBACK_CUSTOMER_CARE_URL_WALLET = "https://support.budgetbakers.com/hc/"
  const FALLBACK_CUSTOMER_CARE_URL_BOARD = "https://boardsupport.budgetbakers.com/hc/"

  const customerCareUrl = isAppBoard()
    ? FALLBACK_CUSTOMER_CARE_URL_BOARD
    : FALLBACK_CUSTOMER_CARE_URL_WALLET

  return customerCareUrl
}

export const getSettingItems = (helpCenterUrl: string) => {
  const settingsItems = [
    {
      title: formatMessage("settings.help.helpCenter.title"),
      href: helpCenterUrl,
      ctaText: formatMessage("settings.help.helpCenter.cta"),
      describtion: formatMessage("settings.help.helpCenter.description"),
    },
    {
      title: formatMessage("settings.help.feedback.title"),
      href: "https://feedback.budgetbakers.com/",
      ctaText: formatMessage("settings.help.feedback.cta"),
      describtion: formatMessage("settings.help.feedback.description"),
    },
  ]
  return settingsItems
}
