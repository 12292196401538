import { Interval } from "src/backend/enums"
import { WidgetId } from "src/backend/dashboard/enums"
import { createRawWidget } from "src/backend/webConfig/converters"
import { isAppBoard } from "src/common/environment"
import { PrimitiveWidget } from "src/frontend/scenes/dashboard/types"
import { WebConfig } from "src/types/WebConfig"

export const CONFIG_WEB_VERSION_2 = 2
export const CONFIG_WEB_VERSION_1 = 1

export const IntervalsToSave: Interval[] = [
  Interval.TODAY,
  Interval.WEEK,
  Interval.MONTH,
  Interval.YEAR,
  Interval.RELATIVE_7_DAYS,
  Interval.RELATIVE_30_DAYS,
  Interval.RELATIVE_90_DAYS,
  Interval.RELATIVE_12_MONTHS,
]

export const DefaultWalletWidgets: PrimitiveWidget[] = [
  { widgetId: WidgetId.PERFORMANCE_CARD },
  { widgetId: WidgetId.BALANCE_CHART_CARD },
  { widgetId: WidgetId.CASH_FLOW_CARD },
  { widgetId: WidgetId.SPENDING_BY_CATEGORIES_PIE_CHART_CARD },
  { widgetId: WidgetId.LAST_RECORDS_CARD },
]

export const DefaultBoardWidgets: PrimitiveWidget[] = [
  { widgetId: WidgetId.COCKPIT_BOARD },
  { widgetId: WidgetId.BALANCE_CHART_CARD },
  { widgetId: WidgetId.ACCOUNTS_LIST },
  { widgetId: WidgetId.CASH_FLOW_CARD_BOARD },
]

export const DefaultWebConfig: WebConfig = {
  showDecimalPlaces: true,
  version: CONFIG_WEB_VERSION_2,
  interval: Interval.MONTH,
  widgets: getDefaultWidgets().map(createRawWidget),
  hideMobileAppsBanner: false,
  advertisementHiddenAt: undefined,
}

export function getDefaultWidgets() {
  return isAppBoard() ? DefaultBoardWidgets : DefaultWalletWidgets
}
