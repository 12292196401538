import { PageMode } from 'src/frontend/scenes/imports/constants'
import { selectPageMode } from 'src/frontend/scenes/imports/selectors'
import { RootState } from 'src/types/State'
import { InfoType } from 'src/frontend/scenes/app/types'

const localState = (state: RootState) => state.scenes.app

export const selectApp = (state: RootState) => localState(state).app
export const selectError = (state: RootState) => localState(state).error
export const selectAppInfoState = (state: RootState): InfoType => localState(state).info
export const selectWarnBeforeLeaveRouteMessageId = (state: RootState) => {
  if (selectPageMode(state) === PageMode.MAPPING) {
    return 'imports.mapping.leave_confirmation'
  } else {
    return null
  }
}

export const selectIsWalletLifeAvailable = (state: RootState) => selectApp(state).walletLifeAvailable

export const selectAppLoading = (state: RootState) => selectApp(state).loading
export const selectAppLoadingText = (state: RootState) => selectApp(state).loadingText

export const selectAppSyncInitialized = (state: RootState) => selectApp(state).syncInitialized
