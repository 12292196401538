import React from 'react'
import _isEqual from 'lodash/isEqual'
import styles from 'src/frontend/components/AnimatedBarTable/AnimatedBarTable.module.less'
import { AnimatedBarTableData, ProgressValue } from 'src/frontend/components/AnimatedBarTable/types'
import Progress from 'src/frontend/components/Progress/Progress'
import _sumBy from 'lodash/sumBy'
import _pick from 'lodash/pick'
import { HashMap } from 'src/types/common'

type Props = {
  total?: number,
  data: Array<AnimatedBarTableData>,
}

type State = HashMap<number>

// ms
const ANIMATION_TIMEOUT_DURATION = 250

export default class AnimatedBarTable extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = { ...this.resetState() }
  }

  static defaultProps = {
    total: undefined,
  }

  static mapDataToComparisonObject = (data: AnimatedBarTableData) => {
    const comparisonObject = _pick(data, ['total', 'values', 'valueChange', 'maxValue', 'avgValue', 'id'])

    return {
      ...comparisonObject,
      values: Array.isArray(comparisonObject.values)
        ? comparisonObject.values.map((valueObject) => valueObject.value)
        : comparisonObject.values.value,
    }
  }

  animationRefs = []

  resetState = () => {
    if (this.props.data.length > 0) {

      // set initial zeros to values
      return this.props.data.reduce((acc, value) => {
        if (value.id) {
          return {
            ...acc,
            [value.id]: 0,
          }
        } else {
          return acc
        }
      }, {})
    } else {
      return {}
    }
  }

  setValuesWithAnimation = () => {
    if (this.props.data) {
      this.setState(() => ({ ...this.resetState() }))
      this.animationRefs.push(this.animationTick())
    }
  }

  animationTick = (index: number = 0) => {
    const { data } = this.props
    return setTimeout(() => {
      if (data[index]) {
        const value = _sumBy(data[index].values, 'value')
          || (data[index].values && (data[index].values as ProgressValue).value || 0)

        this.setState(() => ({
          [data[index].id]: value,
        }))
      }
      if (index < data.length - 1) {
        this.animationRefs.push(this.animationTick(index + 1))
      }
    }, ANIMATION_TIMEOUT_DURATION)
  }

  componentDidMount() {
    if (this.props.data.length > 0) {
      this.setValuesWithAnimation()
    }
  }

  componentDidUpdate(prevProps: Props) {
    const prevPropsData = prevProps.data.length > 0 && prevProps.data.map(AnimatedBarTable.mapDataToComparisonObject)
    const propsData = this.props.data.length > 0 && this.props.data.map(AnimatedBarTable.mapDataToComparisonObject)

    if (!_isEqual(prevPropsData, propsData)) {
      this.setValuesWithAnimation()
    }
  }

  componentWillUnmount() {
    if (this.animationRefs.length > 0) {
      this.animationRefs.forEach(ref => {
        clearTimeout(ref)
      })

      this.animationRefs = []
    }
  }

  render() {
    const { data, total } = this.props
    return (
      <div className={styles.animatedBarTable}>
        {data.length > 0
          ? data.map<React.ReactNode>((valueObject: AnimatedBarTableData) => {
            return (
              <Progress
                key={valueObject.id}
                barTotal={total ?? valueObject.total}
                maxValue={valueObject.maxValue}
                avgValue={valueObject.avgValue}
                valuesTotal={this.state[valueObject.id]}
                values={valueObject.values}
                valueChange={valueObject.valueChange || undefined}
                invertedChange={valueObject.invertedChange}
                label={valueObject.label}
                valueLabel={valueObject.valueLabel}
                leftBottomLabel={valueObject.leftBottomLabel}
                rightBottomLabel={valueObject.rightBottomLabel}
                avgValueLabel={valueObject.avgValueLabel}
                maxValueLabel={valueObject.maxValueLabel}
                divided={valueObject.divided}
              />
            )
          })
          : null}
      </div>
    )
  }
}
