import lf, { query, schema } from "lovefield"
import moment from "moment"
import * as categoriesRepository from "src/backend/categories/repository"
import { findWebConfig } from "src/backend/webConfig/repository"
import * as labelsRepository from "src/backend/labels/repository"
import { accountConverter } from "src/backend/converters/accountConverter"
import { categoryConverter } from "src/backend/converters/categoryConverter"
import { contactConverter } from "src/backend/converters/contactConverter"
import { currencyConverter } from "src/backend/converters/currencyConverter"
import { filterConverter } from "src/backend/converters/filterConverter"
import { labelConverter } from "src/backend/converters/labelConverter"
import { recordConverter } from "src/backend/converters/recordConverter"
import { standingOrderConverter } from "src/backend/converters/standingOrderConverter"
import { templateConverter } from "src/backend/converters/templateConverter"
import { RecordType } from "src/backend/enums"
import * as logger from "src/common/logger"
import { createSchema, inMemoryTableNames } from "src/backend/db/inMemorySqlDbSchemaBuilder"
import _groupBy from "lodash/groupBy"
import _isEmpty from "lodash/isEmpty"
import { Budget } from "src/types/Budget"
import { AnyDocument, DataDoc } from "src/types/CouchDb"
import { WebConfigDocument } from "src/types/WebConfig"
import { webConfigConverter } from "src/backend/converters/webConfigConverter"
import { assetTransactionConverter } from "../converters/assetTransactionConverter"

const budgetTypes = {
  BUDGET_INTERVAL_WEEK: 0,
  BUDGET_INTERVAL_MONTH: 1,
  BUDGET_INTERVAL_YEAR: 2,
  BUDGET_ALL: 3,
}

export const ConverterMap = {
  [inMemoryTableNames.CURRENCY]: currencyConverter,
  [inMemoryTableNames.ACCOUNT]: accountConverter,
  [inMemoryTableNames.LABEL]: labelConverter,
  [inMemoryTableNames.CATEGORY]: categoryConverter,
  [inMemoryTableNames.CONTACT]: contactConverter,
  [inMemoryTableNames.TEMPLATE]: templateConverter,
  [inMemoryTableNames.FILTER]: filterConverter,
  [inMemoryTableNames.WEB_CONFIG]: webConfigConverter,
  [inMemoryTableNames.ASSET_TRANSACTION]: assetTransactionConverter,
}

export class WalletSQLInMemory {
  private static instance: WalletSQLInMemory
  private static db: lf.Database

  private constructor() {}

  async init(): Promise<lf.Database> {
    const connectOptions = { storeType: lf.schema.DataStoreType.MEMORY }
    const walletSqlSchema = createSchema()

    await walletSqlSchema.connect(connectOptions).then((db) => {
      console.log("WalletSQL: Database connection established", db)

      WalletSQLInMemory.db = db
    })
    return this.getDatabase()
  }

  static getInstance(): WalletSQLInMemory {
    if (!WalletSQLInMemory.instance) {
      WalletSQLInMemory.instance = new WalletSQLInMemory()
    }

    return WalletSQLInMemory.instance
  }

  getDatabase(): lf.Database {
    if (!WalletSQLInMemory.db) {
      throw new Error("WalletSQL: Trying to get uninitialized database")
    }
    return WalletSQLInMemory.db
  }

  select(...columns: schema.Column[]): query.Select {
    return this.getDatabase().select(...columns)
  }

  async closeDatabase(): Promise<void> {
    if (WalletSQLInMemory.instance && WalletSQLInMemory.db) {
      await WalletSQLInMemory.db.close()
      WalletSQLInMemory.instance = undefined
      WalletSQLInMemory.db = undefined
    }

    return Promise.resolve()
  }

  getTable(tableName: string): lf.schema.Table {
    return this.getDatabase().getSchema().table(tableName)
  }
}

const tableNameNames = Object.values(inMemoryTableNames)

const modelTypeExistsInTableNames = (doc) => {
  return tableNameNames.includes(doc.reservedModelType)
}

const tableNameFromIdRegexp = tableNameNames.map((name) => new RegExp(`^-?(${name}).*$`))

function getTableNameFromId(docId) {
  const regexp = tableNameFromIdRegexp.find((r) => r.test(docId))
  if (!regexp) {
    return null
  }
  return regexp.exec(docId)[1]
}

function isUserConfigure(doc) {
  return (
    (doc.reservedModelType && doc.reservedModelType === "UserConfigure") ||
    (doc._id && /^-?UserConfigure.*/.test(doc._id))
  )
}

export function deleteDocuments(deleteDocs) {
  const unknownModelTypes = deleteDocs
    .filter((doc) => !modelTypeExistsInTableNames(doc))
    .reduce(
      (acc, doc) => {
        const modelType = getTableNameFromId(doc._id)
        if (modelType) {
          acc.converted = [
            ...acc.converted,
            {
              ...doc,
              reservedModelType: modelType,
            },
          ]
        } else if (!isUserConfigure(doc)) {
          acc.unknown = [...acc.unknown, doc]
        }
        return acc
      },
      {
        unknown: [],
        converted: [],
      },
    )

  if (unknownModelTypes.unknown.length !== 0) {
    logger.warning(
      `Don't know how to delete ids=[${unknownModelTypes.unknown.join(", ")}]`,
      unknownModelTypes.unknown,
    )
  }

  const deleteDocPromises = [
    ...deleteDocs.filter(modelTypeExistsInTableNames),
    ...unknownModelTypes.converted,
  ].map((doc) => {
    const sqlDb = WalletSQLInMemory.getInstance()
    const table = sqlDb.getTable(doc.reservedModelType)
    console.log(`DELETE from ${table} id = ${doc._id}`)
    return sqlDb.getDatabase().delete().from(table).where(table._id.eq(doc._id)).exec()
  })

  return Promise.all(deleteDocPromises)
}

export async function insertDocuments(insertDocs) {
  checkDatabaseConnection()

  console.log("insertDocuments", insertDocs)

  await insertNonRecordDocuments(insertDocs)
  const [categories, labels] = await Promise.all([
    categoriesRepository.findAllAsHashMap(),
    labelsRepository.findAllAsHashMap(),
  ])

  return insertRecordDocuments(insertDocs, categories, labels)
}

function excludeRecordDocuments(modelType) {
  return modelType !== inMemoryTableNames.RECORD && modelType !== inMemoryTableNames.STANDING_ORDER
}

export function insertNonRecordDocuments(dataDocs) {
  const documentGroups = _groupBy(
    dataDocs.map((document) => document.doc || document),
    "reservedModelType",
  )
  const supportedModelTypes = Object.values(inMemoryTableNames)

  console.log("supportedModelTypes", supportedModelTypes)

  const promises = supportedModelTypes
    .filter(excludeRecordDocuments)
    .filter((modelType) => !!documentGroups[modelType])
    .map((modelType) => {
      const rows = documentGroups[modelType]
        .map((document) => {
          const converter = ConverterMap[modelType] ? ConverterMap[modelType]() : undefined
          return createRowFromDocument(modelType, document, converter)
        })
        .filter(Boolean)

      return insertInto(modelType, rows)
    })

  return Promise.all(promises)
}

export function insertRecordDocuments(dataDocs, categories, labels) {
  const records = []
  const standingOrders = []
  dataDocs.forEach((dataDoc) => {
    // TODO validate dataDoc
    // Here (or somewhere else) we should validate dataDoc, for example validate whether it has some reservedModelType.
    const doc = dataDoc.doc || dataDoc
    const modelType = doc.reservedModelType
    if (modelType === inMemoryTableNames.RECORD) {
      const converter = recordConverter(categories, labels)
      const row = createRowFromDocument(modelType, doc, converter)
      if (row) {
        records.push(row)
      }
    } else if (modelType === inMemoryTableNames.STANDING_ORDER) {
      const row = createRowFromDocument(modelType, doc, standingOrderConverter)
      if (row) {
        standingOrders.push(row)
      }
    }
  })

  return Promise.all([
    insertInto(inMemoryTableNames.RECORD, records),
    insertInto(inMemoryTableNames.STANDING_ORDER, standingOrders),
  ])
}

export function createRowFromDocument(
  tableName: string,
  doc: DataDoc<Record<string, any>>,
  convert?: (doc: any) => any,
) {
  const document = convert ? convert(doc) : doc

  if (document) {
    const sqlDb = WalletSQLInMemory.getInstance()
    const table = sqlDb.getTable(tableName)
    return table.createRow(document)
  }

  return undefined
}

export function insertInto(tableName: string, rows: lf.Row[]) {
  console.log(`Create INSERT into ${tableName} of ${rows.length} rows...`)
  const sqlDb = WalletSQLInMemory.getInstance()
  const table = sqlDb.getTable(tableName)
  return sqlDb.getDatabase().insertOrReplace().into(table).values(rows).exec()
}

export function remove(entity) {
  if (!entity.reservedModelType) {
    throw new Error("Entity does not have attribute `reservedModelType`")
  }
  const sqlDb = WalletSQLInMemory.getInstance()
  const table = sqlDb.getTable(entity.reservedModelType)
  return sqlDb
    .getDatabase()
    .delete()
    .from(table)
    .where(table._id.eq(entity._id))
    .exec()
    .then((result) => console.log("Lovefield delete", result))
}

export function fetchNonRecords(strictModelTypes = []) {
  checkDatabaseConnection()

  const supportedModelTypes = Object.values(inMemoryTableNames)

  const promises = supportedModelTypes
    .filter((modelType) => _isEmpty(strictModelTypes) || strictModelTypes.includes(modelType))
    // Exclude Records since we're looking for non-records
    .filter(excludeRecordDocuments)
    .map((modelType): Promise<AnyDocument[] | WebConfigDocument> => {
      // Config documents are called manually, because they can't be called using fetchAllFromTable
      // TODO: Fix this nicely ^^^
      if (modelType === inMemoryTableNames.WEB_CONFIG) {
        return findWebConfig()
      }

      return fetchAllFromTable<AnyDocument>(modelType)
    })

  return Promise.all(promises)
}

export function fetchAllFromTable<T>(tableName: string): Promise<T[]> {
  console.debug(`Create SELECT from ${tableName}`)
  const sqlDb = WalletSQLInMemory.getInstance()
  const table = sqlDb.getTable(tableName)
  return sqlDb.select().from(table).exec() as unknown as Promise<T[]>
}

export function sumAlias(type) {
  return type === RecordType.INCOME ? "Income" : "Expense"
}

export function checkDatabaseConnection() {
  const sqlDb = WalletSQLInMemory.getInstance()
  if (!sqlDb.getDatabase()) {
    throw new Error("SQL database connection has not been initialized!")
  }
}

/**
 * Returns limits for given interval (limit type must conform given interval) with sum of record expenses
 * - only expenses and no transfers
 * - record category must be in some of limit categories and record account must be in some of limit accounts
 * - record must be in time period given by start of interval and current date
 */
export function fetchLimits(currentDate, interval) {
  const start = new Date(moment(currentDate.valueOf()).startOf(interval).valueOf())
  const end = new Date(currentDate.valueOf())
  const budgetType = selectBudgetType(interval)

  const sqlDb = WalletSQLInMemory.getInstance()

  const budgetTable = sqlDb.getTable(inMemoryTableNames.BUDGET)
  const budgetsQuery = sqlDb
    .getDatabase()
    .select(
      budgetTable._id.as("_id"),
      budgetTable.type.as("type"),
      budgetTable.amount.as("amount"),
      budgetTable.name.as("name"),
      budgetTable.categoryIds.as("categoryIds"),
      budgetTable.accountIds.as("accountIds"),
    )
    .from(budgetTable)
    .where(budgetTable.type.eq(budgetType))

  return budgetsQuery
    .exec()
    .then((budgets) => {
      const recordTable = sqlDb.getTable(inMemoryTableNames.RECORD)

      const recordsQueries = budgets.map((budget: Budget) => {
        const conditions = [
          recordTable.categoryId.in(budget.categoryIds),
          recordTable.type.eq(RecordType.EXPENSE),
          recordTable.transfer.neq(true),
          recordTable.recordDate.between(start, end),
        ]

        if (budget.accountIds) {
          conditions.push(recordTable.accountId.in(budget.accountIds))
        }

        const recordsQuery = sqlDb
          .getDatabase()
          .select(lf.fn.sum(recordTable.refAmount).as("spent"))
          .from(recordTable)
          .where(lf.op.and(...conditions))

        return recordsQuery.exec()
      })

      return {
        budgets,
        recordsQueries,
      }
    })
    .then((budgetsAndRecordsQueries) => {
      return Promise.all(budgetsAndRecordsQueries.recordsQueries).then((recordsResult) => {
        return budgetsAndRecordsQueries.budgets.map((budget, index) => {
          return Object.assign({}, budget, recordsResult[index][0])
        })
      })
    })
}

function selectBudgetType(interval) {
  switch (interval) {
    case "week":
      return budgetTypes.BUDGET_INTERVAL_WEEK
    case "isoWeek":
      return budgetTypes.BUDGET_INTERVAL_WEEK
    case "month":
      return budgetTypes.BUDGET_INTERVAL_MONTH
    case "year":
      return budgetTypes.BUDGET_INTERVAL_YEAR
    default:
      throw "Unsupported interval"
  }
}

export function deleteAll() {
  const promises = Object.values(inMemoryTableNames).map((tableName) => deleteFrom(tableName))
  return Promise.all(promises)
}

function deleteFrom(tableName: string) {
  const sqlDb = WalletSQLInMemory.getInstance()
  const table = sqlDb.getTable(tableName)
  return sqlDb.getDatabase().delete().from(table).exec()
}

export const convertResultToEntity =
  (id = undefined, entity = undefined) =>
  (result) => {
    if (result.length > 1) {
      console.warn(
        "There is more than one entry with ID %s of %s entity. Taking the first one...",
        id,
        entity,
      )
    } else {
      console.log("SELECT entity", result[0] && result[0]._id, result[0])
    }

    return result[0]
  }

export const convertResultToScalar = (key) => (result) => {
  if (result.length > 1) {
    logger.error("There is more than one entry with given key. The first one was taken.", {
      key,
      result,
    })
  } else {
    console.log("SELECT %s", key, result[0])
  }
  return result[0] ? result[0][key] : null
}
