import {
  convertResultToEntity,
  fetchAllFromTable,
  WalletSQLInMemory,
} from "src/backend/db/inMemorySqlDb"
import { inMemoryTableNames } from "src/backend/db/inMemorySqlDbSchemaBuilder"
import * as commonRepository from "src/backend/common/repository"
import { MagicRule } from "src/types/MagicRule"

export function findById(id): Promise<MagicRule> {
  const sqlDb = WalletSQLInMemory.getInstance()
  const table = sqlDb.getTable(inMemoryTableNames.MAGIC_RULE)
  return sqlDb.select().from(table).where(table._id.eq(id)).exec().then(convertResultToEntity())
}

export function findAllAsHashMap(): Promise<{ [key: string]: MagicRule }> {
  return commonRepository.findAllAsHashMap(inMemoryTableNames.MAGIC_RULE)
}

export function getAllMagicRules(): Promise<MagicRule[]> {
  return fetchAllFromTable<MagicRule>(inMemoryTableNames.MAGIC_RULE) as Promise<MagicRule[]>
}
