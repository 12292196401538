import React from "react"
import { formatNumber } from "src/frontend/modules/intl/i18n"
import _omitBy from "lodash/omitBy"
import { isUndefined, mergeClasses } from "src/common/utils"
import styles from "./FormattedMoney.module.less"
import { PaymentType, RecordType } from "src/backend/enums"

interface Props {
  className?: string
  sign?: string
  value: number
  currency: string
  numOfDigits?: number
  colored: boolean
  isTransfer?: boolean
  recordType?: RecordType
}

FormattedMoney.defaultProps = {
  className: "",
  sign: "",
  numOfDigits: undefined,
  colored: false,
  isTransfer: false,
}

export default function FormattedMoney({
  className,
  colored,
  value,
  currency,
  sign,
  numOfDigits,
  isTransfer,
  recordType,
}: Props) {
  return currency ? (
    <span
      className={mergeClasses(getColoredClass(value, colored, isTransfer, recordType), className)}
    >
      {sign}
      {formatNumber(
        value / 100,
        _omitBy(
          {
            format: "currency",
            currency,
            minimumFractionDigits: numOfDigits,
            maximumFractionDigits: numOfDigits,
          },
          isUndefined,
        ),
      )}
    </span>
  ) : null
}

function getColoredClass(
  value: number,
  colored: boolean,
  isTransfer: boolean,
  type: RecordType,
): string {
  if (!colored || value === 0) {
    return null
  }
  if (isTransfer) {
    return styles.normal
  }

  return type === RecordType.INCOME ? styles.positive : styles.negative
}
