import React, { useMemo, useState } from 'react'
import { Totals } from 'src/backend/analytics/types'
import Widget from 'src/frontend/scenes/dashboard/components/Widget/Widget'
import { WidgetView } from 'src/frontend/scenes/dashboard/types'
import { Id } from 'src/types/CouchDb'
import { Currency } from 'src/types/Currency'
import PieChart from 'src/frontend/components/chart/PieChart'
import { ChartType } from 'src/frontend/components/chart/ChartType'
import './SpendingByCategoriesPie.less'
import _isEqual from 'lodash/isEqual'
import { FormattedMessage, FormattedMoney } from 'src/frontend/modules/intl'
import 'src/frontend/assets/css/wallet-icons.css'
import { widgetHasDeepEqualProps } from 'src/frontend/scenes/dashboard/helpers'
import { ChartOptions } from 'chart.js'

type Props = {
  widget: WidgetView & { data: CategoriesData },
}

type CategoriesData = {
  spendingCategories: Array<Totals & { color: string, categoryId: Id }>,
  referentialCurrency: Currency,
  expenseSum: number,
  expenseChange: number,
}

function SpendingByCategoriesPie(props: Props) {
  const { widget } = props
  const { data, filter, options } = widget

  if (!data || !filter) {
    return <Widget.Placeholder />
  }

  const [state, setState] = useState({ label: '', value: '', percentage: '' })
  const { value, label } = state

  const resetToolTipValues = () => setState(() => ({ label: '', value: '', percentage: '' }))

  const setTooltipValue = (tooltipModel) => setState((prevState) => {
    const { body, title } = tooltipModel

    const newLabel = body && body[0] && body[0].lines && body[0].lines[0] || ''
    const newValue = title && title[0] || ''
    const newPercentage = title && title[1] || ''

    if (!_isEqual(prevState, { label: newLabel, value: newValue, percentage: newPercentage })) {
      return {
        label: newLabel,
        value: newValue,
        percentage: newPercentage,
      }
    }

    return prevState
  })


  const pieOptions: ChartOptions = useMemo(() => ({
    aspectRatio: null,
    maintainAspectRatio: false,
    rotation: 2 * Math.PI,
    cutoutPercentage: 60,
    legend: {
      display: false,
    },
    tooltips: {
      enabled: false,
      custom: setTooltipValue,
    },
  }), [])

  const content = (data.spendingCategories.length > 0)
    ? (
      <Widget.Content className="spending-categories-pie">
        <div className="custom-tooltip">
          <div>{label || <FormattedMessage id="all-categories" />}</div>
          <div>{value || <FormattedMoney value={-data.expenseSum} currency={data.referentialCurrency.code} />}</div>
        </div>
        <div onMouseLeave={resetToolTipValues}>
          <PieChart
            type={ChartType.PIE}
            labels={convertLabels(data)}
            datasets={convertDatasets(data)}
            currency={data.referentialCurrency}
            options={pieOptions}
            height={192}
          />
        </div>
      </Widget.Content>
    )
    : <Widget.EmptyContent type="pie" />

  return (
    <Widget>
      <Widget.Header title={widget.title} />
      {data.expenseSum > 0
        ? (
          <Widget.Summary
            displayWholeDateRange
            period={filter.period}
            value={-data.expenseSum}
            currencyCode={data.referentialCurrency.code}
            invertedChange
            intervalType={options.relativeIntervalValue}
            changeValue={data.expenseChange}
          />
        )
        : null}
      {content}
    </Widget>
  )
}

function convertLabels(data: CategoriesData) {
  return data.spendingCategories.map((category) => category.name)
}

function convertDatasets(data: CategoriesData) {
  return [
    {
      data: data.spendingCategories.map((category) => -category.refExpense / 100),
      backgroundColor: data.spendingCategories.map((category) => category.color),
      type: ChartType.PIE,
    },
  ]
}

export default React.memo(SpendingByCategoriesPie, widgetHasDeepEqualProps)
