import React from 'react'
import { Contact } from 'src/types/Contact'
import styles from './ContactDetailTabs.module.less'
import { Tab } from 'semantic-ui-react'
import ContactDetailRecordList from 'src/frontend/scenes/contacts/contactDetail/components/ContactDetailRecordList'
import { formatMessage } from 'src/frontend/modules/intl/i18n'
import ContactDetailRules from 'src/frontend/scenes/contacts/contactDetail/components/ContactDetailRules'

interface Props {
  contact: Contact.ContactView
}


export default function ContactDetailTabs({ contact }: Props) {
  const panes = [
    { menuItem: formatMessage('transactions'), render: () => <ContactDetailRecordList contactId={contact._id} /> },
    // { menuItem: formatMessage('planned-payments'), render: () => <div>&nbsp;</div> },
    { menuItem: formatMessage('settings.rules'), render: () => <ContactDetailRules contactId={contact._id} /> },
  ]
  return (
    <div className={styles.contactTabs}>
      <Tab menu={{ secondary: true, pointing: true, className: styles.menu }} panes={panes} />
    </div>
  )
}
