import React from 'react'
import styles from './Labels.module.less'
import { isSystemLabel } from 'src/backend/labels/helpers'
import { LabelView } from 'src/types/Label'
import { SystemLabels } from 'src/backend/labels/systemLabels'
import { mergeClasses } from 'src/common/utils'

type Props = {
  labelsView: Array<LabelView>,
}

export default function Labels({ labelsView }: Props) {
  return (
    <div className={styles.labels}>
      {labelsView.map((labelView => <Label key={`label-${labelView._id}`} label={labelView} />))}
    </div>
  )
}

Label.defaultProps = {
  systemLabelType: null,
}

function Label({ label }: { label: LabelView }) {
  const systemLabelClass = isSystemLabel(label) && styles.systemLabel
  const name = isSystemLabel(label) && label.systemLabelType in SystemLabels
    ? SystemLabels[label.systemLabelType].name
    : label.name
  const color = isSystemLabel(label) && label.systemLabelType in SystemLabels
    ? SystemLabels[label.systemLabelType].color
    : label.color

  return (
    <span className={mergeClasses(styles.labelsLabel, systemLabelClass)} style={{ backgroundColor: color }}>
      {name}
    </span>
  )
}

