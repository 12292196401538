import React from 'react'
import PropTypes from 'prop-types'
import ImportRecordList from 'src/frontend/scenes/imports/records/components/ImportRecordList'
import NoRecordsContent from 'src/frontend/scenes/imports/records/components/NoRecordsContent'

const propTypes = {
  hasRecords: PropTypes.bool.isRequired,
}

const AccountContent = ({ hasRecords }) => {
  return hasRecords
    ? (
      <div className="records-container">
        <ImportRecordList />
      </div>
    )
    : (
      <NoRecordsContent />
    )
}

AccountContent.propTypes = propTypes

export default AccountContent
