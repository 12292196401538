import { WidgetData } from 'src/frontend/scenes/dashboard/types'
import { getRecordsSum } from 'src/frontend/scenes/records/recordList/helpers'
import * as recordsSelectors from 'src/frontend/scenes/records/recordList/selectors'
import { Account } from 'src/types/Account'
import { Category, RecordCategory } from 'src/types/Category'
import { Currency } from 'src/types/Currency'
import { HashMap } from 'src/types/common'

export function getPlannedPayments(
  accounts: HashMap<Account>,
  categories: Category[],
  currencies: HashMap<Currency>,
  referentialCurrency: Currency,
) {
  return (data: WidgetData) => {
    if (!data) {
      return null
    }
    return {
      referentialCurrencyCode: referentialCurrency.code,
      refAmountSum: getRecordsSum(data),
      plannedPayments: data.map((futurePayment) => {
        const category: RecordCategory = recordsSelectors.getRecordCategory(futurePayment, categories)
        const account: Account = recordsSelectors.getRecordAccount(futurePayment, accounts)
        const currency: Currency = recordsSelectors.getRecordCurrency(futurePayment, currencies)

        return {
          ...futurePayment,
          ...category,
          accountName: account.name,
          accountColor: account.color,
          currencyCode: currency.code,
        }
      }),
    }
  }
}
