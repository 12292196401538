import { fetchAllFromTable, WalletSQLInMemory } from "src/backend/db/inMemorySqlDb"
import { inMemoryTableNames } from "src/backend/db/inMemorySqlDbSchemaBuilder"
import * as commonRepository from "src/backend/common/repository"
import { templateConverter } from "../converters/templateConverter"

export function findById(id) {
  const sqlDb = WalletSQLInMemory.getInstance()
  const templateTable = sqlDb.getTable(inMemoryTableNames.TEMPLATE)
  return sqlDb
    .select()
    .from(templateTable)
    .where(templateTable._id.eq(id))
    .exec()
    .then((result) => result[0])
}

export function findAllAsHashMap() {
  return commonRepository.findAllAsHashMap(inMemoryTableNames.TEMPLATE)
}

export function getAllTemplates() {
  return fetchAllFromTable(inMemoryTableNames.TEMPLATE)
}

export function findByCurrencyId(currencyId) {
  const sqlDb = WalletSQLInMemory.getInstance()
  const templateTable = sqlDb.getTable(inMemoryTableNames.TEMPLATE)
  return sqlDb.select().from(templateTable).where(templateTable.currencyId.eq(currencyId)).exec()
}

export async function updateBulk(templateDocuments) {
  const updatedTemplateDocuments = await commonRepository.updateBulk(
    templateDocuments,
    inMemoryTableNames.TEMPLATE,
    templateConverter(),
  )

  return updatedTemplateDocuments
}
