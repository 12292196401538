import _sumBy from "lodash/sumBy"
import * as accountsRepository from "src/backend/accounts/repository"
import {
  composeBalance,
  convertDateAttributesToStrings,
  getDateRange,
  getDateRangeForAllDatasets,
  groupTotals,
} from "src/backend/analytics/helpers"
import { AmountValues, AnalyticsData, InvestmentData, Totals } from "src/backend/analytics/types"
import * as commonRepository from "src/backend/common/repository"
import { convertGranularityToGroupByAttribute } from "src/backend/common/service"
import * as currenciesRepository from "src/backend/currencies/repository"
import { prepareChartData } from "src/backend/dashboard/chartDataConverter"
import {
  BalanceDisplayType,
  Interval,
  IntervalCompareToType,
  IntervalGranularityType,
} from "src/backend/enums"
import { convertToFilter, getPreviousPeriodFilter } from "src/backend/filters/helpers"
import * as timeUtils from "src/backend/time/time"
import { reduceBy } from "src/common/utils"
import { ChartSubjectType } from "src/frontend/scenes/analytics/enums"
import { Account, AccountWithOpeningBalance } from "src/types/Account"
import { FilterType, Period } from "src/types/Filter"
import { ChartData, ViewDefinition } from "src/frontend/scenes/analytics/types"
import { AccountsTotals } from "src/frontend/scenes/dashboard/types"
import { HashMap } from "src/types/common"
import {
  getAssetQuanities,
  getAssetQuanitiesToDate,
  getAssetTransactionsCashFlows,
  getAssetTransactionsCashFlowsToDate,
  getExchangeRate,
  getLatestStockPrice,
  getLatestStockPriceFromAssetTransaction,
} from "../investments/service"
import moment from "moment"

/**
 * @description Core function which fetches expenses & incomes depending on account currency, fallbacks to Ref currency
 * returns flattened results
 * @param filter
 * @param groupBy
 * @return {Promise<Array<AmountValues>>}
 */
export async function fetchSummarizedRecords(
  filter: FilterType,
  groupBy: Array<string> = [],
): Promise<Array<AmountValues>> {
  const FIXED_GROUP_BY = "accountId"

  const [sumOfAmountExpenses, sumOfAmountIncomes, sumOfRefAmountExpenses, sumOfRefAmountIncomes] =
    await Promise.all([
      commonRepository.fetchGroupedSumOfAmountExpenses(filter, [FIXED_GROUP_BY, ...groupBy]),
      commonRepository.fetchGroupedSumOfAmountIncomes(filter, [FIXED_GROUP_BY, ...groupBy]),
      commonRepository.fetchGroupedSumOfRefAmountExpenses(filter, [FIXED_GROUP_BY, ...groupBy]),
      commonRepository.fetchGroupedSumOfRefAmountIncomes(filter, [FIXED_GROUP_BY, ...groupBy]),
    ])

  return [
    ...sumOfRefAmountIncomes,
    ...sumOfRefAmountExpenses,
    ...sumOfAmountIncomes,
    ...sumOfAmountExpenses,
  ]
}

export const calcInvestmentsToDate = async (filter: FilterType) => {
  const initialAssetQuntities = await getAssetQuanitiesToDate(
    filter,
    filter.period?.end?.toDate() ?? new Date(),
  )
  const refCurrency = await currenciesRepository.getReferentialCurrency()

  const assetPrices = await Promise.all(
    initialAssetQuntities.map(
      async ({
        exchangeCode,
        quantity,
        symbol,
        currencyCode,
        accountCurrencyCode,
        accountCurrencyRatioToReferential,
        assetId,
      }) => {
        let price = await getLatestStockPrice(
          { exchangeCode, symbol },
          filter.period?.end?.toDate() ?? new Date(),
        )

        if (price === undefined) {
          const assetPriceFromLatestAsset = await getLatestStockPriceFromAssetTransaction(
            assetId,
            filter.period?.end?.toDate() ?? new Date(),
          )

          price = {
            value: (assetPriceFromLatestAsset?.value ?? 100) / 100,
            date: assetPriceFromLatestAsset?.date,
            stockIdentifier: `${exchangeCode}:${symbol}`,
          }
        }

        return {
          value: (price?.value ?? 1) * quantity,
          currencyCode,
          accountCurrencyCode,
          accountCurrencyRatioToReferential,
        }
      },
    ),
  )

  const assetValues = await Promise.all(
    assetPrices.map(
      async ({ value, currencyCode, accountCurrencyCode, accountCurrencyRatioToReferential }) => {
        if (currencyCode === accountCurrencyCode) {
          if (refCurrency.code === accountCurrencyCode) {
            return value
          } else {
            return value / accountCurrencyRatioToReferential
          }
        } else {
          const rate = await getExchangeRate(
            currencyCode,
            accountCurrencyCode,
            filter.period?.end?.toDate() ?? new Date(),
          )
          if (refCurrency.code === accountCurrencyCode) {
            return value * rate
          } else {
            return (value * rate) / accountCurrencyRatioToReferential
          }
        }
      },
    ),
  )

  const portfolioValue = assetValues.reduce((acc, curr) => acc + curr, 0)

  const initialAssetTransactionCashFlows = await getAssetTransactionsCashFlowsToDate(
    filter,
    filter.period?.end?.toDate() ?? new Date(),
  )
  const initialAssetCashFlow = (
    await Promise.all(
      initialAssetTransactionCashFlows.map(
        async ({
          currencyCode,
          accountCurrencyCode,
          accountCurrencyRatioToReferential,
          cashAmount,
        }) => {
          if (currencyCode === accountCurrencyCode) {
            if (refCurrency.code === accountCurrencyCode) {
              return cashAmount
            } else {
              return cashAmount / accountCurrencyRatioToReferential
            }
          } else {
            const rate = await getExchangeRate(
              currencyCode,
              accountCurrencyCode,
              filter.period?.end?.toDate() ?? new Date(),
            )
            if (refCurrency.code === accountCurrencyCode) {
              return cashAmount * rate
            } else {
              return (cashAmount * rate) / accountCurrencyRatioToReferential
            }
          }
        },
      ),
    )
  ).reduce((acc, curr) => acc + curr, 0)

  return { portfolioValue, initialAssetCashFlow }
}

export const calcInvestmentsByDates = async (
  filter: FilterType,
  granularity: IntervalGranularityType,
  dates: Array<string>,
) => {
  const initialAssetQuntities = await getAssetQuanitiesToDate(
    filter,
    filter.period?.start?.toDate() ?? new Date(),
  )

  const refCurrency = await currenciesRepository.getReferentialCurrency()
  const assetQuantitiesGroupedByGranularity = await getAssetQuanities(filter, granularity)
  let assetQuntities = initialAssetQuntities

  const quantitiesByDates = dates.map((date) => {
    const dataForDate = assetQuantitiesGroupedByGranularity.filter(
      (assetQuantity) => moment(assetQuantity[granularity]).format() === date,
    )

    const updatedQuantities = assetQuntities.map((assetQuantity) => {
      const quantitiesByAsset = dataForDate.find(
        (data) =>
          data.assetId === assetQuantity.assetId &&
          data.accountCurrencyCode === assetQuantity.accountCurrencyCode,
      )

      const quantity = quantitiesByAsset
        ? assetQuantity.quantity + quantitiesByAsset.quantity
        : assetQuantity.quantity

      return {
        ...assetQuantity,
        quantity,
      }
    })

    dataForDate.forEach((data) => {
      const assetQuantity = assetQuntities.find(
        (assetQuantity) =>
          assetQuantity.assetId === data.assetId &&
          assetQuantity.accountCurrencyCode === data.accountCurrencyCode,
      )

      if (!assetQuantity && data) {
        updatedQuantities.push(data)
      }
    })

    assetQuntities = [...updatedQuantities]
    return { date, quantities: updatedQuantities }
  })

  const portfolioValueByDates = (
    await Promise.all(
      quantitiesByDates.map(async ({ date, quantities }) => {
        const assetPrices = await Promise.all(
          quantities.map(
            async ({
              exchangeCode,
              quantity,
              symbol,
              currencyCode,
              accountCurrencyCode,
              accountCurrencyRatioToReferential,
              assetId,
            }) => {
              let price = await getLatestStockPrice({ exchangeCode, symbol }, new Date(date))

              if (price === undefined) {
                const assetPriceFromLatestAsset = await getLatestStockPriceFromAssetTransaction(
                  assetId,
                  new Date(date),
                )

                price = {
                  value: (assetPriceFromLatestAsset?.value ?? 100) / 100,
                  date: assetPriceFromLatestAsset?.date,
                  stockIdentifier: `${exchangeCode}:${symbol}`,
                }
              }

              return {
                value: (price?.value ?? 1) * quantity,
                currencyCode,
                accountCurrencyCode,
                accountCurrencyRatioToReferential,
              }
            },
          ),
        )

        const assetValues = await Promise.all(
          assetPrices.map(
            async ({
              value,
              currencyCode,
              accountCurrencyCode,
              accountCurrencyRatioToReferential,
            }) => {
              if (currencyCode === accountCurrencyCode) {
                if (refCurrency.code === accountCurrencyCode) {
                  return value
                } else {
                  return value / accountCurrencyRatioToReferential
                }
              } else {
                const rate = await getExchangeRate(
                  currencyCode,
                  accountCurrencyCode,
                  new Date(date),
                )
                if (refCurrency.code === accountCurrencyCode) {
                  return value * rate
                } else {
                  return (value * rate) / accountCurrencyRatioToReferential
                }
              }
            },
          ),
        )

        return {
          date,
          value: assetValues.reduce((acc, curr) => acc + curr, 0),
        }
      }),
    )
  ).reduce(reduceBy("date"), {}) as HashMap<{ date: string; value: number }>

  const initialAssetTransactionCashFlows = await getAssetTransactionsCashFlowsToDate(
    filter,
    filter.period?.start?.toDate() ?? new Date(),
  )

  const assetTransactionsCashFlowsGroupedByGranularity = await getAssetTransactionsCashFlows(
    filter,
    granularity,
  )

  const initialAssetCashFlow = (
    await Promise.all(
      initialAssetTransactionCashFlows.map(
        async ({
          currencyCode,
          accountCurrencyCode,
          accountCurrencyRatioToReferential,
          cashAmount,
        }) => {
          if (currencyCode === accountCurrencyCode) {
            if (refCurrency.code === accountCurrencyCode) {
              return cashAmount
            } else {
              return cashAmount / accountCurrencyRatioToReferential
            }
          } else {
            const rate = await getExchangeRate(
              currencyCode,
              accountCurrencyCode,
              filter.period?.start?.toDate() ?? new Date(),
            )
            if (refCurrency.code === accountCurrencyCode) {
              return cashAmount * rate
            } else {
              return (cashAmount * rate) / accountCurrencyRatioToReferential
            }
          }
        },
      ),
    )
  ).reduce((acc, curr) => acc + curr, 0)

  const assetCashFlows = await Promise.all(
    assetTransactionsCashFlowsGroupedByGranularity.map(
      async ({
        currencyCode,
        accountCurrencyCode,
        accountCurrencyRatioToReferential,
        cashAmount,
        ...rest
      }) => {
        let value: number
        if (currencyCode === accountCurrencyCode) {
          if (refCurrency.code === accountCurrencyCode) {
            value = cashAmount
          } else {
            value = cashAmount / accountCurrencyRatioToReferential
          }
        } else {
          const rate = await getExchangeRate(
            currencyCode,
            accountCurrencyCode,
            filter.period?.start?.toDate() ?? new Date(),
          )
          if (refCurrency.code === accountCurrencyCode) {
            value = cashAmount * rate
          } else {
            value = (cashAmount * rate) / accountCurrencyRatioToReferential
          }
        }

        return { value, ...rest }
      },
    ),
  )

  const uniqueDates = [
    ...new Set(assetCashFlows.map((cashFlow) => moment(cashFlow[granularity]).format())),
  ]

  const assetCashFlowsByDates = uniqueDates
    .map((date) => {
      const cashFlowsForDate = assetCashFlows.filter(
        (cashFlow) => moment(cashFlow[granularity]).format() === date,
      )

      return {
        date,
        value: cashFlowsForDate.reduce((acc, curr) => acc + curr.value, 0),
      }
    })
    .reduce(reduceBy("date"), {}) as HashMap<{ date: string; value: number }>

  return {
    portfolioValueByDates,
    assetCashFlowsByDates,
    initialAssetCashFlow,
  }
}

/**
 * @description Returns groupped Totals data by attribute, if no attribute provided, data will be flattened
 * totals are computed by accounts with respect to account currency
 * @param filter
 * @param groupBy
 * @return {Promise<AnalyticsData>}
 */
export async function calcTotalsByAttribute(
  filter: FilterType,
  groupBy: Array<string> = [],
): Promise<Totals> {
  const filteredGroupBy = groupBy.filter(Boolean)
  const DATE_ATTRIBUTES = ["recordDate", "recordDateDay", "recordDateWeek", "recordDateMonth"]
  const dateAttribute = filteredGroupBy.find((groupByAttribute) =>
    DATE_ATTRIBUTES.includes(groupByAttribute),
  )

  const totals: Array<AmountValues> = await fetchSummarizedRecords(filter, filteredGroupBy)

  const withFormattedDates = dateAttribute
    ? totals.map(convertDateAttributesToStrings(dateAttribute))
    : totals

  return groupTotals(withFormattedDates, filteredGroupBy)
}

/**
 * @description Wrapper for calcTotalsByAttribute, calculated data with no starting date, from beginning
 * @param filter
 * @param groupBy
 * @return {Promise<AnalyticsData>}
 */
export function calcTotalsByAttributeToDate(
  filter: FilterType,
  groupBy?: Array<string>,
): Promise<Totals> {
  const { end } = filter.period
  const toDateFilter = {
    ...filter,
    period: { end },
  }

  return calcTotalsByAttribute(toDateFilter, groupBy)
}

export async function getChartDataGroupByAccount(
  viewDefinition: ViewDefinition,
  filterVO: FilterType,
): Promise<Array<ChartData>> {
  const filter = convertToFilter(filterVO)
  const previousPeriodFilter = getPreviousPeriodFilter(filter)

  const accountsWithOpeningBalance = await getAccountsWithOpeningBalance(previousPeriodFilter).then(
    (accounts) => accounts.reduce(reduceBy("_id"), {}),
  )

  const groupByGranularity = convertGranularityToGroupByAttribute(viewDefinition.granularity)
  const data = await calcTotalsByAttribute(filter, ["accountId", groupByGranularity])

  const dateRange = getDateRangeForAllDatasets(data, filter.period)
  const dates = timeUtils.generateDateStrings(dateRange, viewDefinition.granularity)

  // if BALANCE, iterate all accounts, otherwise iterate over only accounts with data
  if (viewDefinition.subject === ChartSubjectType.BALANCE) {
    const investmentDataByAccounts = await Promise.all(
      Object.values(accountsWithOpeningBalance).map(async (account: AccountWithOpeningBalance) => {
        if (!account?.investmentInfo) return { accountId: account._id, investmentData: undefined }

        const investmentData = await calcInvestmentsByDates(
          { ...filter, accountIds: [account._id] },
          viewDefinition.granularity,
          dates,
        )

        return { accountId: account._id, investmentData }
      }),
    )

    return Object.values(accountsWithOpeningBalance).map((account: AccountWithOpeningBalance) => {
      const accountData = data[account._id]
      const investmentAccountData = investmentDataByAccounts.find(
        (investmentData) => investmentData.accountId === account._id,
      )?.investmentData

      const chartData = calcChartData(
        viewDefinition.granularity,
        account.refBalance,
        accountData,
        dateRange,
        undefined,
        investmentAccountData,
      )
      return {
        accountId: account._id,
        data: chartData.map((item) => ({
          x: item.date,
          y: item[viewDefinition.subject] / 100,
        })),
      }
    })
  } else {
    return Object.values(data).map((accountData: Totals) => {
      const { accountId } = groupByGranularity ? Object.values(accountData)[0] : accountData

      const openingBalance = accountsWithOpeningBalance[accountId].refBalance
      const chartData = calcChartData(
        viewDefinition.granularity,
        openingBalance,
        accountData,
        dateRange,
      )

      return {
        accountId,
        data: chartData.map((item) => ({
          x: item.date,
          y: item[viewDefinition.subject] / 100,
        })),
      }
    })
  }
}

export async function getChartDataGroupByCategory(
  viewDefinition: ViewDefinition,
  filterVO: FilterType,
): Promise<Array<ChartData>> {
  const filter = convertToFilter(filterVO)

  const groupByGranularity = convertGranularityToGroupByAttribute(viewDefinition.granularity)
  const data = await calcTotalsByAttribute(filter, ["superEnvelopeId", groupByGranularity])

  const dateRange = getDateRangeForAllDatasets(data, filter.period)

  // iterate over categories with data
  return Object.values(data).map((superEnvelopeData: Totals) => {
    const { superEnvelopeId }: AnalyticsData = groupByGranularity
      ? Object.values(superEnvelopeData)[0]
      : superEnvelopeData

    const chartData = calcChartData(viewDefinition.granularity, 0, superEnvelopeData, dateRange)

    return {
      superEnvelopeId,
      data: chartData.map((item) => ({
        x: item.date,
        y: item[viewDefinition.subject] / 100,
      })),
    }
  })
}

export async function getChartData(
  viewDefinition: ViewDefinition,
  filterVO: FilterType,
): Promise<Array<ChartData>> {
  const filter = convertToFilter(filterVO)
  // skip calculating opening balance if not asking for balance and get some performance back
  const openingBalance =
    viewDefinition.subject === ChartSubjectType.BALANCE
      ? await getOpeningBalance(getPreviousPeriodFilter(filterVO))
      : 0

  const groupByGranularity = convertGranularityToGroupByAttribute(viewDefinition.granularity)
  const data = await calcTotalsByAttribute(filter, [groupByGranularity])

  const dateRange: Period = getDateRange(data, filter.period)
  const dates = timeUtils.generateDateStrings(dateRange, viewDefinition.granularity)

  const investmentsData = await calcInvestmentsByDates(filter, viewDefinition.granularity, dates)

  if (
    viewDefinition.compareTo !== IntervalCompareToType.NONE &&
    filter.period.interval !== Interval.ALL_THE_TIME
  ) {
    const previousPeriodFilter = {
      ...filter,
      period: timeUtils.previousPeriod(
        filter.period.start,
        filter.period.end,
        filter.period.interval,
        viewDefinition.compareTo,
      ),
    }

    const previousPeriodOpeningBalance =
      viewDefinition.subject === ChartSubjectType.BALANCE
        ? await getOpeningBalance(getPreviousPeriodFilter(previousPeriodFilter))
        : 0

    const previousPeriodData = await calcTotalsByAttribute(previousPeriodFilter, [
      groupByGranularity,
    ])

    const previousPeriodDates = timeUtils.generateDateStrings(
      getDateRange(previousPeriodData, previousPeriodFilter.period),
      viewDefinition.granularity,
    )

    const previousPeriodInvestmentsData = await calcInvestmentsByDates(
      previousPeriodFilter,
      viewDefinition.granularity,
      previousPeriodDates,
    )
    return [
      prepareDataset(
        viewDefinition,
        filter,
        data,
        openingBalance,
        undefined,
        undefined,
        investmentsData,
      ),
      {
        ...prepareDataset(
          viewDefinition,
          previousPeriodFilter,
          previousPeriodData,
          previousPeriodOpeningBalance,
          undefined,
          undefined,
          previousPeriodInvestmentsData,
        ),
        comparisonIndex: 0, // index of dataset which is compared to
      },
    ]
  }

  return [
    prepareDataset(
      viewDefinition,
      filter,
      data,
      openingBalance,
      undefined,
      undefined,
      investmentsData,
    ),
  ]
}

export const getChartDataGroupByInvestment = async (
  viewDefinition: ViewDefinition,
  filterVO: FilterType,
): Promise<ChartData[]> => {
  const filter = convertToFilter(filterVO)
  // skip calculating opening balance if not asking for balance and get some performance back
  const openingBalance =
    viewDefinition.subject === ChartSubjectType.BALANCE
      ? await getOpeningBalance(getPreviousPeriodFilter(filterVO))
      : 0

  const groupByGranularity = convertGranularityToGroupByAttribute(viewDefinition.granularity)
  const data = await calcTotalsByAttribute(filter, [groupByGranularity])

  const dateRange: Period = getDateRange(data, filter.period)
  const dates = timeUtils.generateDateStrings(dateRange, viewDefinition.granularity)

  const investmentsData = await calcInvestmentsByDates(filter, viewDefinition.granularity, dates)

  return [
    prepareDataset(viewDefinition, filter, data, openingBalance, undefined, undefined, {
      ...investmentsData,
      portfolioValueByDates: {},
    }),
    prepareDataset(viewDefinition, filter, {}, 0, undefined, undefined, {
      ...investmentsData,
      assetCashFlowsByDates: {},
      initialAssetCashFlow: 0,
    }),
  ]
}

export function prepareDataset(
  viewDefinition: ViewDefinition,
  filter: FilterType,
  data: Totals | HashMap<Totals>,
  openingBalance: number = 0,
  negative: boolean = false,
  customAggregator?: (data: HashMap<Totals>) => Totals,
  investmentsData?: InvestmentData,
): ChartData {
  const dateRange: Period = getDateRange(data, filter.period)
  const chartData = calcChartData(
    viewDefinition.granularity,
    openingBalance,
    data,
    dateRange,
    customAggregator,
    investmentsData,
  )

  // find last data that have any value
  const lastData = [...chartData].reverse().find((value) => value[viewDefinition.subject] !== null)

  return {
    openingBalance,
    closingValue: lastData ? lastData[viewDefinition.subject] : null,
    data: chartData.map((item) => ({
      x: item.date,
      y:
        item[viewDefinition.subject] !== null
          ? ((negative ? -1 : 1) * item[viewDefinition.subject]) / 100
          : null,
    })),
  }
}

export async function getAccountsWithOpeningBalance(
  filter: FilterType,
): Promise<AccountWithOpeningBalance[]> {
  const [accounts, currencies, totals] = await Promise.all([
    accountsRepository.findByIds(filter.accountIds),
    currenciesRepository.findAllAsHashMap(),
    calcTotalsByAttributeToDate(filter, ["accountId"]),
  ])

  return accounts.map((currentAccount) => {
    const balanceToDate = totals[currentAccount._id]
      ? totals[currentAccount._id].income - totals[currentAccount._id].expense
      : 0

    let balance = composeBalance(
      currentAccount,
      filter.period.interval !== Interval.ALL_THE_TIME ? balanceToDate : 0,
    )
    let refBalance =
      balance /
      (currencies[currentAccount.currencyId]
        ? currencies[currentAccount.currencyId].ratioToReferential
        : 1)

    return {
      ...currentAccount,
      balance,
      refBalance,
    }
  })
}

export function getOpeningBalance(filter: FilterType): Promise<number> {
  return getAccountsWithOpeningBalance(filter).then((accountsWithOpeningBalance) => {
    return _sumBy(accountsWithOpeningBalance, "refBalance")
  })
}

function calcChartData(
  granularity: IntervalGranularityType,
  initialBalance: number = 0,
  data: Totals | HashMap<Totals>,
  dateRange: Period,
  customAggregator?: (data: HashMap<Totals>) => Totals,
  investmentsData?: InvestmentData,
): Array<AnalyticsData> {
  const dates = timeUtils.generateDateStrings(dateRange, granularity)

  return prepareChartData(
    data,
    dates,
    initialBalance,
    granularity,
    customAggregator,
    investmentsData,
  )
}

export async function fetchAccountsTotals(filter: FilterType): Promise<HashMap<AccountsTotals>> {
  const [accounts, currencies, accountsTotalsToDate] = await Promise.all([
    accountsRepository.findByIds(filter.accountIds),
    currenciesRepository.findAllAsHashMap(),
    calcTotalsByAttributeToDate(filter, ["accountId"]),
  ])

  const investmentTotalsByAccount = await Promise.all(
    accounts
      .filter((account) => account.investmentInfo)
      .map(async (account) => {
        const { portfolioValue, initialAssetCashFlow } = await calcInvestmentsToDate({
          ...filter,
          accountIds: [account._id],
        })

        return {
          accountId: account._id,
          refInvestmentTotalForAccount: portfolioValue * 100 + initialAssetCashFlow,
          investmentTotalForAccount:
            (portfolioValue * 100 + initialAssetCashFlow) *
            (currencies[account.currencyId].ratioToReferential ?? 1),
        }
      }),
  )

  const totalsArray = accounts.map((account: Account) => {
    const balanceToDate = accountsTotalsToDate[account._id]
      ? accountsTotalsToDate[account._id].income - accountsTotalsToDate[account._id].expense
      : 0

    const currency = currencies[account._id]

    const investmentTotals = investmentTotalsByAccount.find(
      (investmentTotal) => investmentTotal.accountId === account._id,
    )

    const balance =
      composeBalance(account, balanceToDate) + (investmentTotals?.investmentTotalForAccount ?? 0)
    const refBalance = balance * (currency ? currency.ratioToReferential : 1)

    const availableBalance = composeBalance(
      account,
      balanceToDate,
      BalanceDisplayType.AVAILABLE_CREDIT,
    )
    const availableRefBalance = availableBalance * (currency ? currency.ratioToReferential : 1)

    return {
      accountId: account._id,
      balance: balance,
      refBalance: refBalance,
      availableBalance,
      availableRefBalance,
    }
  })

  return totalsArray.reduce(reduceBy<AccountsTotals>("accountId"), {})
}
