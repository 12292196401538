import PouchDB from 'pouchdb'
import * as WorkerPouch from 'worker-pouch'
import { CouchType, ReplicationEndpoint } from 'src/types/User'
import { isEdge } from 'src/common/runtime'

// FIXME don't let somebody import not initialized localDb
// eslint-disable-next-line import/no-mutable-exports
export let localDb: PouchDB.Database

// PouchDB.debug.enable('*')

export function connectToLocalDB(userReplication: ReplicationEndpoint) {
  const db = userReplicationToConnectionSettings(userReplication)
  // db.name is common for all users therefore use db.user to identify local DB
  const dbName = userReplication.couchType === CouchType.COUCHBASE ? db.user : db.name
  if (isEdge) {
    localDb = new PouchDB(dbName, { revs_limit: 50, auto_compaction: true })
  } else {
    (PouchDB as any).adapter('worker', WorkerPouch)
    localDb = new PouchDB(dbName, { adapter: 'worker', revs_limit: 50, auto_compaction: true })
  }
}

function userReplicationToConnectionSettings(userReplication) {
  return {
    name: userReplication.dbName,
    user: userReplication.login,
    pass: userReplication.token,
    url: userReplication.url,
  }
}

export function sync(userReplication: ReplicationEndpoint): PouchDB.Replication.Sync<{}> {
  const db = userReplicationToConnectionSettings(userReplication)
  checkLocalDatabaseConnection()
  console.log('Normal pull replication from remote couchdb')
  const dbName = userReplication.couchType === CouchType.COUCH_DB ? `/${db.name}` : ''
  const connectionString = db.url.replace(new RegExp('^(https?)(://)(.*)$'), `$1$2${db.user}:${db.pass}@$3${dbName}`)
  return localDb.sync(
    connectionString,
    {
      batch_size: 1000,
      live: true,
      retry: true,
      checkpoint: 'source',
      back_off_function: (delay: number) => {
        if (delay === 0) {
          // First return delay 1 second
          return 1000
        }

        // prevent longer delay than 10 seconds
        if (delay >= 10000) {
          return 10000
        }

        // return 1,5 multiplied delay (it roughly counts up to 5 times before 10 seconds cap)
        return delay * 1.5
      },
    },
  )
}

export function replicate(userReplication: ReplicationEndpoint) {
  const db = userReplicationToConnectionSettings(userReplication)
  checkLocalDatabaseConnection()
  console.log('Normal pull replication from remote couchdb')
  const dbName = userReplication.couchType === CouchType.COUCH_DB ? `/${db.name}` : ''
  const connectionString = db.url.replace(new RegExp('^(https?)(://)(.*)$'), `$1$2${db.user}:${db.pass}@$3${dbName}`)
  return localDb.replicate.from(
    connectionString,
    {
      batch_size: 1000,
      timeout: 10000,
      retry: true,
      checkpoint: 'target',
    },
  )
}


export function fetchAll(): Promise<PouchDB.Core.AllDocsResponse<{}>> {
  checkLocalDatabaseConnection()
  return localDb.allDocs({ include_docs: true })
}

function checkLocalDatabaseConnection() {
  if (!localDb) {
    throw 'Local document database connection has not been initialized!'
  }
}

export async function closeLocalDatabase() {
  if (localDb) {
    await localDb.close()
    localDb = null
  }
}

export async function destroyLocalDatabase() {
  if (localDb) {
    await localDb.destroy()
    localDb = null
  }
}
