import BasicRecordList from 'src/frontend/components/RecordList/BasicRecordList'
import React, { useCallback, useState } from 'react'
import { RootState } from 'src/types/State'
import * as selectors from 'src/frontend/scenes/settings/magicRules/selectors'
import { connect } from 'react-redux'
import { RecordListItemRecord } from 'src/frontend/components/RecordList/types'
import { Id } from 'src/types/CouchDb'
import { Divider, Grid } from 'semantic-ui-react'
import { FormattedMessage } from 'src/frontend/modules/intl'
import styles from './MagicRuleUpdateSimilar.module.less'
import { applyRuleToExistingRecords } from 'src/frontend/scenes/settings/magicRules/actions'
import { PrimaryButton, SecondaryButton } from 'src/frontend/components/Buttons/Buttons'

interface Props {
  similarRecords: RecordListItemRecord[],
  loading: boolean,
  onSubmit: (recordIds: Id[]) => void
}

function mapStateToProps(state: RootState) {
  const form = selectors.selectMagicRules(state)
  return {
    similarRecords: selectors.selectAggregatedSimilarRecords(state),
    loading: form.loading,
  }
}

const mapDispatchToProps = {
  onSubmit: applyRuleToExistingRecords,
}

function MagicRuleUpdateSimilar({ similarRecords, loading, onSubmit }: Props) {
  const [selectedRecordIds, setSelectedRecordIds] = useState<Id[]>([])

  const handleSelect = useCallback((recordId: Id) => {
    if (selectedRecordIds.includes(recordId)) {
      setSelectedRecordIds(selectedRecordIds.filter((id) => recordId !== id))
    } else {
      setSelectedRecordIds([...selectedRecordIds, recordId])
    }

  }, [selectedRecordIds])

  const handleSelectAll = useCallback(() => {
    setSelectedRecordIds(similarRecords.map((record) => record._id))
  }, [similarRecords])

  const handleDeselectAll = useCallback(() => {
    setSelectedRecordIds([])
  }, [])

  const handleSubmit = useCallback(() => {
    onSubmit(selectedRecordIds)
  }, [selectedRecordIds])


  return (
    <div>
      <p>
        <FormattedMessage
          id="settings.rules.update-similar.text"
          values={{ similarRecordsCount: similarRecords.length }}
        />
      </p>
      <div>
        {selectedRecordIds.length === 0
          ? (
            <div className={styles.selectAction} onClick={handleSelectAll}>
              <FormattedMessage id="records.select_all" />
            </div>
          )
          : (
            <div className={styles.selectAction} onClick={handleDeselectAll}>
              <FormattedMessage id="records.deselect_all" />
            </div>
          )}
      </div>
      <BasicRecordList
        scrollable
        records={similarRecords}
        selectable
        selectedIds={selectedRecordIds}
        selectMethod="checkbox"
        onSelect={handleSelect}
      />
      <Divider hidden section />
      <Grid>
        <Grid.Column width={8}>
          <SecondaryButton fluid onClick={handleSubmit}>
            <FormattedMessage id="form.skip" />
          </SecondaryButton>
        </Grid.Column>
        <Grid.Column width={8}>
          <PrimaryButton loading={loading} disabled={loading} fluid onClick={handleSubmit}>
            <FormattedMessage id="form.save" />
          </PrimaryButton>
        </Grid.Column>
      </Grid>
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(MagicRuleUpdateSimilar)
