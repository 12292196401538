import React from "react"
import { getShortMonthsForLocale } from "src/backend/time/time"
import { Interval } from "src/backend/enums"
import moment from "moment"
import { Icon } from "semantic-ui-react"
import "./MonthYearContent.less"
import { parseIntDecimal } from "src/common/utils"
import { Period } from "src/types/Filter"

type Props = {
  period: Period
  locale: string
  onChange: Function
  onClose: () => void
}

type State = {
  year: number
}

export default class MonthContent extends React.Component<Props, State> {
  state = {
    year: parseIntDecimal(
      this.props.period.end ? this.props.period.end.format("Y") : moment().format("Y"),
    ),
  }

  handleAddYear = (increment: number) => () =>
    this.setState((prevState) => {
      return { year: parseIntDecimal(prevState.year) + increment }
    })

  handleChangeMonth = (monthNumber: number) => () => {
    const month = moment({ d: 1, M: monthNumber, y: this.state.year })

    const start = month.clone().startOf("month")
    const end = start.clone().endOf("month")

    this.props.onChange({
      interval: Interval.MONTH,
      start,
      end,
    })
    this.props.onClose()
  }

  generateMonthsAsItems = (locale: string) => {
    return getShortMonthsForLocale(locale).map((monthName, index) => {
      const { start, interval } = this.props.period

      const isSelectedMonth =
        interval === Interval.MONTH &&
        start &&
        start.format("Y-M") === `${this.state.year}-${index + 1}`
      const isThisMonth = moment().format("Y-M") === `${this.state.year}-${index + 1}`
      const className = `${isSelectedMonth ? "active" : ""} ${isThisMonth ? "current" : ""}`

      return (
        <li
          className={className}
          onClick={this.handleChangeMonth(index)}
          key={monthName}
        >
          {monthName}
        </li>
      )
    })
  }

  componentWillReceiveProps(nextProps: Props) {
    this.setState({
      year: parseIntDecimal(
        nextProps.period.end ? nextProps.period.end.format("Y") : moment().format("Y"),
      ),
    })
  }

  render() {
    const { year } = this.state
    const { locale } = this.props
    return (
      <div
        className="date-range-picker-month-year-content"
        data-e2e="date-range-picker-month-year-content"
      >
        <div className="header">
          <Icon
            name="caret left"
            size="large"
            onClick={this.handleAddYear(-1)}
          />
          <div>{year}</div>
          <Icon
            name="caret right"
            size="large"
            onClick={this.handleAddYear(+1)}
          />
        </div>
        <ul>{this.generateMonthsAsItems(locale)}</ul>
      </div>
    )
  }
}
