import * as categoriesService from "src/backend/categories/service"
import * as validator from "src/backend/categories/validator"
import { captureException } from "src/common/logger"
import * as commonService from "src/backend/common/service"
import * as commonHelpers from "src/backend/common/helpers"
import { fetchFilters } from "src/frontend/modules/filters/actions"
import * as userSelectors from "src/frontend/modules/user/selectors"
import * as categoriesActions from "src/frontend/modules/categories/actions"
import _isEmpty from "lodash/isEmpty"
import { inMemoryTableNames } from "src/backend/db/inMemorySqlDbSchemaBuilder"
import { Category, CategoryDocument } from "src/types/Category"
import { CategoriesFormValues } from "src/frontend/scenes/settings/categories/types"
import { FormType } from "src/frontend/modules/categories/enums"
import { Id } from "src/types/CouchDb"
import { GetState, HashMap } from "src/types/common"

export const SETTINGS_CATEGORY_CHANGE = "SETTINGS_CATEGORY_CHANGE"

export const SETTINGS_CATEGORY_OPEN_FORM = "SETTINGS_CATEGORY_OPEN_FORM"
export const SETTINGS_CATEGORY_CLOSE_FORM = "SETTINGS_CATEGORY_CLOSE_FORM"

export const SETTINGS_CATEGORY_SAVE_START = "SETTINGS_CATEGORY_SAVE_START"
export const SETTINGS_CATEGORY_SAVE_SUCCESS = "SETTINGS_CATEGORY_SAVE_SUCCESS"
export const SETTINGS_CATEGORY_SAVE_ERROR = "SETTINGS_CATEGORY_SAVE_ERROR"
export const SETTINGS_CATEGORY_CHANGE_FIELD_VALUE = "SETTINGS_CATEGORY_CHANGE_FIELD_VALUE"

export const SETTINGS_CATEGORY_OPEN_DELETE_FORM = "SETTINGS_CATEGORY_OPEN_DELETE_FORM"
export const SETTINGS_CATEGORY_CLOSE_DELETE_FORM = "SETTINGS_CATEGORY_CLOSE_DELETE_FORM"

export const SETTINGS_CATEGORY_OPEN_COLOR_PICKER = "SETTINGS_CATEGORY_OPEN_COLOR_PICKER"
export const SETTINGS_CATEGORY_CLOSE_COLOR_PICKER = "SETTINGS_CATEGORY_CLOSE_COLOR_PICKER"

export function changeCategory(envelopeId: number) {
  return {
    type: SETTINGS_CATEGORY_CHANGE,
    payload: { selectedEnvelopeId: envelopeId },
  }
}

export function saveCategory(
  category: Category,
  formValues: CategoriesFormValues,
  formType: FormType,
) {
  return async (dispatch: Function, getState: GetState) => {
    const categoryData =
      formType !== FormType.EDIT
        ? {
            _id: category._id,
            envelopeId: category.envelopeId,
          }
        : category

    dispatch(saveCategoryStart(categoryData as Category))

    const user = userSelectors.selectUser(getState())
    const validationDependencies = await categoriesService.getValidationDependencies()

    const validationData =
      formType === FormType.ADD
        ? { envelopeId: categoryData.envelopeId, ...formValues }
        : { ...categoryData, ...formValues }

    const errors = validator.validate(validationData, validationDependencies, formType)

    if (!_isEmpty(errors)) {
      dispatch(saveCategoryError(errors))
    } else {
      Promise.resolve()
        .then(() => {
          if (formType === FormType.ADD) {
            return categoriesService.createCustomCategoryFromEnvelope(
              categoryData.envelopeId,
              formValues as Category,
              user,
            )
          } else if (formType === FormType.EDIT) {
            return categoriesService.updateCategory(categoryData._id as Id, formValues as Category)
          } else if (formType === FormType.RENAME) {
            return categoriesService.renameCategory(categoryData._id, formValues as Category, user)
          } else {
            throw new Error(`Unsupported formType=${formType}`)
          }
        })
        .then((savedCategory) => {
          dispatch(saveCategorySuccess(savedCategory as Category))
          dispatch(categoriesActions.fetchCategories())
        })
        .catch((error) => {
          captureException(error, "categories.saveCategory", { category, formType, formValues })
        })
    }
  }
}

export const changeFormFieldValue = (field: string, value: string) => {
  return {
    type: SETTINGS_CATEGORY_CHANGE_FIELD_VALUE,
    payload: { [field]: value.trim() },
  }
}

const saveCategoryStart = (category: Category) => {
  return {
    type: SETTINGS_CATEGORY_SAVE_START,
    payload: {
      category,
      errors: {},
    },
  }
}

const saveCategorySuccess = (category: Category) => {
  return (dispatch: Function) => {
    dispatch({
      type: SETTINGS_CATEGORY_SAVE_SUCCESS,
      payload: {
        errors: {},
        category,
      },
    })
    return dispatch(closeForm())
  }
}

function saveCategoryError(errors: HashMap<string>) {
  return {
    type: SETTINGS_CATEGORY_SAVE_ERROR,
    payload: { errors },
  }
}

export function deleteFormOpen(category: CategoryDocument) {
  return (dispatch: Function) => {
    return Promise.resolve()
      .then(() => commonService.getEntityReferences(category))
      .then((references) => {
        let payload
        if (commonHelpers.isEachOfSameType("Record", references)) {
          payload = {
            canDelete: true,
            category,
          }
        } else {
          const referencedTypes = commonHelpers
            .getTypesOfEntities(references)
            .filter((modelType) => modelType !== inMemoryTableNames.RECORD)

          payload = {
            canDelete: false,
            referencedTypes,
            category,
          }
        }
        dispatch({
          type: SETTINGS_CATEGORY_OPEN_DELETE_FORM,
          payload,
        })
      })
  }
}

export function deleteFormClose() {
  return { type: SETTINGS_CATEGORY_CLOSE_DELETE_FORM }
}

export function deleteCategory(category: CategoryDocument) {
  return async (dispatch, getState) => {
    const user = userSelectors.selectUser(getState())
    try {
      await categoriesService.removeCategory(category, user)
      dispatch(categoriesActions.fetchCategories())
      dispatch(fetchFilters())
    } catch (error) {
      captureException(error, "categories.deleteCategory", { category })
    }
  }
}

export function openForm(category: Category, formType: FormType) {
  const name = category.customCategory || category.customName ? category.name : ""
  const iconName = !!category.iconName ? category.iconName : undefined
  const color = category.customColor ? category.color : undefined

  return {
    type: SETTINGS_CATEGORY_OPEN_FORM,
    payload: {
      category,
      formType,
      formValues: {
        name,
        iconName,
        color,
      },
    },
  }
}

export function closeForm() {
  return { type: SETTINGS_CATEGORY_CLOSE_FORM }
}

export function openColorPicker() {
  return { type: SETTINGS_CATEGORY_OPEN_COLOR_PICKER }
}

export function closeColorPicker() {
  return { type: SETTINGS_CATEGORY_CLOSE_COLOR_PICKER }
}
