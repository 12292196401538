import { isUndefinedOrNull } from 'src/common/utils'
import { WebConfig } from 'src/types/WebConfig'

export function webConfigConverter() {
  return (dataDoc) => {
    const webConfig: WebConfig = dataDoc.doc || dataDoc

    const showDecimalPlaces = !isUndefinedOrNull(webConfig.showDecimalPlaces)
      ? webConfig.showDecimalPlaces
      : true

    const hideMobileAppsBanner = !isUndefinedOrNull(webConfig.hideMobileAppsBanner)
      ? webConfig.hideMobileAppsBanner
      : false

    // upgrade widgets to new version
    return {
      ...webConfig,
      showDecimalPlaces,
      hideMobileAppsBanner,
    }
  }
}

