import React from 'react'
import { RecordIntegrationProviderSource } from 'src/backend/enums'
import { RootState } from 'src/types/State'
import { connect } from 'react-redux'
import { selectUser } from 'src/frontend/modules/user/selectors'
import { User } from 'src/types/User'
import { Account } from 'src/types/Account'
import {
  selectActiveRefreshes,
  selectExistingConnectionsModalOpen,
  isPendingReconnect,
  selectPendingReconnectProvider,
} from "src/frontend/scenes/integrations/connections/selectors"
import { ActiveRefresh } from "src/frontend/scenes/integrations/connections/reducer"
import { isConnected, isRevoked } from "src/backend/accounts/helpers"
import { ExistingIntegrationStatus } from "src/frontend/scenes/integrations/enums"
import moment from "moment"
import { getUserFeatureValue } from "src/backend/user/service"
import { Feature } from "src/backend/user/billingFeatures"
import { Button, Icon, SemanticICONS } from "semantic-ui-react"
import {
  openExistingIntegrationsForm,
  refreshBank,
  setActiveMFALoginId,
} from "src/frontend/scenes/integrations/connections/actions"
import { reconnectBank } from "src/frontend/scenes/integrations/providersList/actions"
import { openIntegrationsForm } from "src/frontend/scenes/integrations/newConnection/actions"
import PremiumChecker from "src/frontend/components/PremiumChecker/PremiumChecker"
import { selectReconnectLoginId } from "src/frontend/scenes/integrations/providersList/selectors"
import { HashMap } from "src/types/common"
import { formatMessage, FormattedMessage } from "src/frontend/modules/intl"
import { BasicIcon } from "src/frontend/components/Icons/Icons"
import { isAppBoard } from "src/common/environment"
import { RestrictedProviders } from "src/frontend/scenes/integrations/restrictedProviders/types"
import { selectRestrictedProviders } from "src/frontend/scenes/integrations/restrictedProviders/selectors"
import { isUndefinedOrNull } from "src/common/utils"
import RestrictedProvider = RestrictedProviders.RestrictedProvider
import ProviderStatus = RestrictedProviders.ProviderStatus
import { isMoreThan2Days } from "src/backend/time/time"

export enum Variant {
  ICON,
  SUBTLE,
  NORMAL,
}

interface Props {
  variant?: Variant
  user: User
  account: Account
  activeRefreshes: HashMap<ActiveRefresh>
  modalOpen: boolean
  subtle?: boolean
  reconnectLoginId: string
  restrictedProviders: RestrictedProvider[]
  pendingReconnect: boolean
  pendingReconnectProvider: string
  handleOpenRefreshModal: Function
  handleRefreshBank: (loginId: string) => void
  handleReconnectBank: (loginId: string) => void
  handleSetActiveMFALoginId: (loginId: string) => void
  onClick?: Function
}

function mapStateToProps(state: RootState) {
  return {
    user: selectUser(state),
    activeRefreshes: selectActiveRefreshes(state),
    modalOpen: selectExistingConnectionsModalOpen(state),
    reconnectLoginId: selectReconnectLoginId(state),
    restrictedProviders: selectRestrictedProviders(state),
    pendingReconnect: isPendingReconnect(state),
    pendingReconnectProvider: selectPendingReconnectProvider(state),
  }
}

const mapDispatchToProps = {
  handleRefreshBank: refreshBank,
  handleReconnectBank: reconnectBank,
  handleOpenIntegrationsModal: openIntegrationsForm,
  handleOpenRefreshModal: openExistingIntegrationsForm,
  handleSetActiveMFALoginId: setActiveMFALoginId,
}

IntegrationButton.defaultProps = {
  variant: Variant.NORMAL,
}

function getIcon(integrationStatus: ExistingIntegrationStatus): {
  icon: SemanticICONS
  text: string
} {
  switch (integrationStatus) {
    case ExistingIntegrationStatus.MFA_FORM:
      return { icon: "lock", text: formatMessage("integrations.connections.mfa.header") }
    case ExistingIntegrationStatus.ERROR:
      return { icon: "exclamation", text: "error" }
    default:
      return { icon: "refresh", text: formatMessage("integrations.connections.refresh-action") }
  }
}

function IntegrationButton({
  variant,
  user,
  account,
  activeRefreshes,
  modalOpen,
  onClick,
  reconnectLoginId,
  restrictedProviders,
  pendingReconnect,
  pendingReconnectProvider,
  handleRefreshBank,
  handleOpenRefreshModal,
  handleReconnectBank,
  handleSetActiveMFALoginId,
}: Props) {
  if (!isConnected(account)) {
    return null
  }

  const integrationConnection = account.reservedIntegrationConnection
  const { isActive, isAutomaticRefreshPossible } = integrationConnection

  const restrictedProviderObject = React.useMemo(() => {
    if (integrationConnection) {
      return restrictedProviders.find((restrictedProvider) => {
        return restrictedProvider.loginIds.includes(integrationConnection.loginId)
      })
    }
  }, [account, restrictedProviders])

  if (
    !isUndefinedOrNull(restrictedProviderObject) &&
    restrictedProviderObject.status !== ProviderStatus.Active
  ) {
    return null
  }
  const userHasIntegrationsFeature = !!getUserFeatureValue(user, Feature.INTEGRATIONS)

  // reconnect button
  if (!isActive || isRevoked(account)) {
    return (
      <PremiumChecker
        enabled={userHasIntegrationsFeature}
        featureName="premium_checker.bank-syncs"
      >
        <Button
          circular={!isAppBoard()}
          className="integration-button"
          size={variant === Variant.ICON ? "mini" : "medium"}
          color={variant === Variant.NORMAL ? "blue" : undefined}
          compact={variant === Variant.ICON}
          disabled={!!reconnectLoginId}
          loading={
            reconnectLoginId === integrationConnection.loginId ||
            (pendingReconnect && pendingReconnectProvider === integrationConnection.providerCode)
          }
          inverted={variant === Variant.SUBTLE}
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
            if (userHasIntegrationsFeature) {
              handleReconnectBank(integrationConnection.loginId)
            }
          }}
        >
          <FormattedMessage id="integrations.connections.reconnect-action" />
        </Button>
      </PremiumChecker>
    )
  }

  const activeRefresh: ActiveRefresh = activeRefreshes[integrationConnection.loginId]

  const integrationStatus = activeRefresh && activeRefresh.integrationStatus
  const isLoading = integrationStatus === ExistingIntegrationStatus.LOADING
  const isMFA = integrationStatus === ExistingIntegrationStatus.MFA_FORM
  const isError = integrationStatus === ExistingIntegrationStatus.ERROR
  const isCredentials = integrationStatus === ExistingIntegrationStatus.CREDENTIALS_FORM
  const isIntegrationProviderBudgetBakers =
    integrationConnection?.integrationSource === RecordIntegrationProviderSource.BUDGETBAKERS

  const isPossibleRefresh = moment(integrationConnection.nextRefreshPossible).isSameOrBefore(
    moment(),
  )
  const isRecentlyRefreshed = moment(integrationConnection.lastSuccessRefresh).isBetween(
    moment().subtract(5, "minute"),
    moment().add(5, "minute"),
  )

  // refresh button
  if (
    integrationConnection &&
    !isRecentlyRefreshed &&
    // for BB provider enable only MFA modal and hide refresh which is not supported
    (!isIntegrationProviderBudgetBakers ||
      (isIntegrationProviderBudgetBakers && !!activeRefresh)) &&
    ((isPossibleRefresh &&
      isActive &&
      (!isAutomaticRefreshPossible ||
        (isAutomaticRefreshPossible &&
          isMoreThan2Days(integrationConnection.lastSuccessRefresh)))) ||
      isLoading ||
      isMFA ||
      isError ||
      isCredentials)
  ) {
    return (
      <PremiumChecker
        enabled={userHasIntegrationsFeature}
        featureName="premium_checker.bank-syncs"
      >
        {variant === Variant.SUBTLE ? (
          <Icon
            className="integration-button"
            loading={isLoading}
            disabled={isLoading || modalOpen || !!reconnectLoginId}
            name={getIcon(integrationStatus).icon}
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
              if (!!activeRefresh) {
                handleOpenRefreshModal()
                if (isMFA) {
                  handleSetActiveMFALoginId(integrationConnection.loginId)
                }
              } else {
                handleRefreshBank(integrationConnection.loginId)
              }
            }}
          />
        ) : (
          <Button
            className="integration-button"
            basic={variant === Variant.ICON}
            icon={
              variant === Variant.ICON || isLoading || isError ? (
                <Icon
                  name={getIcon(integrationStatus).icon}
                  loading={isLoading}
                />
              ) : (
                false
              )
            }
            size={variant === Variant.ICON ? "mini" : "medium"}
            color={variant === Variant.NORMAL ? "blue" : undefined}
            compact={variant === Variant.ICON}
            circular={!isAppBoard()}
            content={
              variant === Variant.NORMAL && !isError && !isLoading
                ? getIcon(integrationStatus).text
                : undefined
            }
            inverted={variant === Variant.NORMAL}
            disabled={isLoading || modalOpen || !!reconnectLoginId}
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
              if (!!activeRefresh) {
                handleOpenRefreshModal()
                handleSetActiveMFALoginId(integrationConnection.loginId)
              } else {
                handleRefreshBank(integrationConnection.loginId)
              }
            }}
          />
        )}
      </PremiumChecker>
    )
  }

  if (isActive && variant === Variant.ICON) {
    return (
      <BasicIcon
        name="checkmark"
        size="1em"
      />
    )
  }

  return null
}


export default connect(mapStateToProps, mapDispatchToProps)(IntegrationButton)
