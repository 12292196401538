import CenterTooltip from 'src/frontend/scenes/dashboard/widgets/PieAndBarSpending/CenterTooltip'
import PieChart from 'src/frontend/components/chart/PieChart'
import { ChartType } from 'src/frontend/components/chart/ChartType'
import {
  convertPieDatasets,
  convertLabels,
} from 'src/frontend/scenes/dashboard/widgets/PieAndBarSpending/helpers'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { ChartOptions } from 'chart.js'
import _isEqual from 'lodash/isEqual'
import { SpendingPieData } from 'src/frontend/scenes/dashboard/widgets/PieAndBarSpending/types'
import { WidgetView } from 'src/frontend/scenes/dashboard/types'
import { Currency } from 'src/types/Currency'
import Widget from 'src/frontend/scenes/dashboard/components/Widget/Widget'
import _isEmpty from 'lodash/isEmpty'

interface State {
  label: string,
  value: string,
  percentage: string
}

interface Props {
  data: SpendingPieData[],
  currency: Currency,
  selectedIndex: number,
  widget: WidgetView
  onIndexChange: React.Dispatch<React.SetStateAction<number>>
}

function WidgetPieChart({ data, currency, selectedIndex, onIndexChange }: Props) {
  const initState: State = { label: '', value: '', percentage: '' }
  const pieRef = useRef<HTMLDivElement>()
  const [state, setState] = useState<State>(initState)

  const { label, value, percentage } = state

  const resetToolTipValues = () => setState(initState)


  useEffect(() => {
    resetToolTipValues()
  }, [data])

  function getPieOptions(): ChartOptions {
    return {
      cutoutPercentage: 65,
      maintainAspectRatio: false,
      rotation: 2 * Math.PI,
      legend: {
        display: false,
      },
      tooltips: {
        enabled: false,
        custom: setTooltipValue,
      },
      onClick: (_e, elements: any[]) => {
        if (elements && elements[0]) {
          const newValue = elements[0]._index
          onIndexChange(selectedIndex === newValue ? null : newValue)
        }
      },
      hover: {
        onHover: (_event: MouseEvent, activeElements) => {
          if (activeElements && pieRef.current) {
            pieRef.current.style.cursor = activeElements[0] ? 'pointer' : 'default'
          }
        },
      },
    }
  }

  function setTooltipValue(tooltipModel) {
    const { body, title } = tooltipModel

    const newLabel = body && body[0] && body[0].lines && body[0].lines[0] || ''
    const newValue = title && title[0] || ''
    const newPercentage = title && title[1] || ''

    if (!_isEqual(state, { label: newLabel, value: newValue, percentage: newPercentage })) {
      setState({ label: newLabel, value: newValue, percentage: newPercentage })
    }
  }

  const datasets = useMemo(() => convertPieDatasets(data, selectedIndex), [selectedIndex, data])
  const options = useMemo(getPieOptions, [])
  const labels = useMemo(() => convertLabels(data), [data])

  return !_isEmpty(data) ? (
      <div onMouseLeave={resetToolTipValues}>
        <CenterTooltip
          data={data}
          currency={currency}
          value={value}
          percentage={percentage}
          label={label}
          selectedIndex={selectedIndex}
          onClick={() => onIndexChange(null)}
        />
        <PieChart
          type={ChartType.PIE}
          labels={labels}
          datasets={datasets}
          currency={currency}
          options={options}
          height={160}
          passRef={pieRef}
        />
      </div>
    )
    : <Widget.EmptyContent type="pie" />
}

export default WidgetPieChart
