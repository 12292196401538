import * as yup from 'yup'
import { MIN_PASSWORD_LENGTH, MAX_PASSWORD_LENGTH } from "src/frontend/scenes/auth/constants"

export function getValidationSchema() {
  return yup.object().shape({
    email: yup
      .string()
      .email("auth.error.email.is_invalid")
      .required("auth.error.email.is_required"),
    newPassword: yup
      .string()
      .min(MIN_PASSWORD_LENGTH, "auth.error.password.too_short")
      .max(MAX_PASSWORD_LENGTH, "auth.error.password.too_long")
      .required("auth.error.password.is_required"),
    consent: yup
      .bool()
      .test("consent", "auth.error.consent.is_missing", (value) => value === true)
      .required("auth.error.consent.is_missing"),
  })
}

