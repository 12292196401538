import { FormType } from 'src/frontend/scenes/settings/magicRules/enums'

export function getFormTitle(formType: FormType) {
  switch (formType) {
    default:
    case FormType.ADD_FROM_ASSIGN:
    case FormType.ADD: {
      return 'settings.rules.form.add_rule'
    }

    case FormType.EDIT: {
      return 'settings.rules.form.edit_rule'
    }

    case FormType.SELECT_SIMILAR_RECORDS: {
      return 'Update transactions'
    }
  }
}
