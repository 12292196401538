import React from 'react'
import _sumBy from 'lodash/sumBy'
import { isUndefinedOrNull } from 'src/common/utils'
import { formatMessage, formatNumber, FormattedMessage, FormattedMoney } from 'src/frontend/modules/intl'
import styles from './CenterTooltip.module.less'
import { SpendingPieData } from 'src/frontend/scenes/dashboard/widgets/PieAndBarSpending/types'
import { Currency } from 'src/types/Currency'


interface Props {
  data: SpendingPieData[]
  currency: Currency
  selectedIndex: number
  value: string | React.ReactNode
  label: string | React.ReactNode
  percentage: string | React.ReactNode
  onClick: (e: React.SyntheticEvent) => void
}

export default function CenterTooltip({ data, currency, selectedIndex, value, label, percentage, onClick }: Props) {
  const sumOfAll = _sumBy(data, (pieData) => pieData && pieData.value || 0)
  const sum = isUndefinedOrNull(selectedIndex) || !data[selectedIndex]
    ? sumOfAll
    : data[selectedIndex].value

  const centerLabel = label
    || (!isUndefinedOrNull(selectedIndex) && data[selectedIndex]
      && formatMessage(data[selectedIndex].name))
    || <FormattedMessage id="all-categories" />

  const centerValue = value || <FormattedMoney value={sum} currency={currency.code} />

  const centerPercentage = percentage
    || (!isUndefinedOrNull(selectedIndex)
      && data[selectedIndex] && formatNumber(data[selectedIndex].value
        / sumOfAll || 1, { style: 'percent' }))

  return (
    <div className={styles.customTooltip} onClick={onClick}>
      <div className={styles.label}>{centerLabel}</div>
      <div className={styles.value}>{centerValue}</div>
      <div className={styles.percentage}>{centerPercentage}</div>
    </div>
  )
}
