import { Transition } from "semantic-ui-react"
import React from "react"
import DetailLayout from "src/frontend/components/ColumnLayout/DetailLayout"
import { useDispatch, useSelector } from "react-redux"
import { Redirect } from "react-router"
import { formatMessage, FormattedMessage } from "src/frontend/modules/intl"
import { openAccountForm } from "src/frontend/scenes/settings/accounts/actions"
import { FormType } from "src/frontend/scenes/settings/enums"
import RemoveAccountForm from "src/frontend/scenes/settings/accounts/components/RemoveAccountForm"
import AccountDetailContent from "src/frontend/scenes/accounts/accountDetail/components/AccountDetailContent"
import { selectAllAccounts } from "src/frontend/modules/accounts/selectors"
import { SecondaryButton } from "src/frontend/components/Buttons/Buttons"
import { selectRestrictedProviders } from "src/frontend/scenes/integrations/restrictedProviders/selectors"
import PictureIcon from "src/frontend/components/PictureIcon/PictureIcon"
import styles from "./AccountDetail.module.less"
import { Account } from "src/types/Account"

interface Props {
  history
}

function AccountDetail({ history }: Props) {
  const accountId = window.location.pathname.split("/")[3]
  const accounts = useSelector(selectAllAccounts)
  const account = accountId in accounts && accounts[accountId]
  const restrictedProviders = useSelector(selectRestrictedProviders)
  const dispatch = useDispatch()

  if (!account) {
    return <Redirect to="/accounts" />
  }

  return (
    <Transition
      transitionOnMount
      animation="fade"
      duration={200}
    >
      <DetailLayout
        history={history}
        title={formatMessage("account.detail")}
        actions={
          <div style={{ display: "flex", gap: "1rem" }}>
            {account?.investmentInfo && (
              <div className={styles.topPanel}>
                <div className={styles.infoBox}>
                  <PictureIcon
                    name="info-icon-black"
                    compact
                    size="20px"
                  ></PictureIcon>
                  <p>
                    <FormattedMessage id="accounts.list.sellBuyInfo" />
                  </p>
                </div>
              </div>
            )}
            <SecondaryButton
              onClick={() => {
                dispatch(openAccountForm(FormType.EDIT, account))
              }}
            >
              <FormattedMessage id="edit" />
            </SecondaryButton>
            <RemoveAccountForm
              account={account}
              as="button"
            />
          </div>
        }
        content={
          <AccountDetailContent
            account={account}
            restrictedProviders={restrictedProviders}
          />
        }
      />
    </Transition>
  )
}

export default AccountDetail
