import * as service from "src/backend/analytics/service"
import * as reportsService from "src/backend/reports/service"
import { setFilterValues } from "src/frontend/modules/filter/actions"
import { CategoryFilterGroup, selectFilter } from "src/frontend/modules/filter/selectors"
import { selectViewDefinition } from "./selectors"
import { ChartGroupByType, ChartSubjectType } from "src/frontend/scenes/analytics/enums"
import { ChartData } from "src/frontend/scenes/analytics/types"
import {
  CHART_TYPE_VIEW_DEFINITION_ITEM,
  GRANULARITY_VIEW_DEFINITION_ITEM,
  GROUP_BY_VIEW_DEFINITION_ITEM,
  ViewDefinition,
} from "src/frontend/scenes/analytics/types"
import { ChartType } from "src/frontend/components/chart/ChartType"
import {
  FilterRecordType,
  Interval,
  IntervalCompareToType,
  IntervalGranularityType,
} from "src/backend/enums"
import { FilterType, Period } from "src/types/Filter"
import * as logger from "src/common/logger"
import { viewDefinitionInitialState } from "src/frontend/scenes/analytics/reducer"
import { Action, GetState } from "src/types/common"
import { RecordCategoryLevel } from "src/backend/categories/enums"
import { openRecordsPreview } from "src/frontend/scenes/records/recordList/actions"
import moment from "moment"
import { CashFlowReportRaw, ReportRaw } from "src/frontend/scenes/analytics/Report/types"
import { isAppBoard } from "src/common/environment"
import StartOf = moment.unitOfTime.StartOf

export const CHANGE_VIEW_DEFINITION = "analytics/CHANGE_VIEW_DEFINITION"
export const FETCH_CHART_DATA_START = "analytics/FETCH_CHART_DATA_START"
export const FETCH_CHART_DATA_SUCCESS = "analytics/FETCH_CHART_DATA_SUCCESS"
export const FETCH_CHART_DATA_ERROR = "analytics/FETCH_CHART_DATA_ERROR"

export const REPORTS_CATEGORIES_DATA_START = "analytics/REPORTS_CATEGORIES_DATA_START"
export const REPORTS_CATEGORIES_DATA_SUCCESS = "analytics/REPORTS_CATEGORIES_DATA_SUCCESS"

export const REPORTS_BOARD_CASHFLOW_DATA_START = "analytics/REPORTS_BOARD_CASHFLOW_DATA_START"
export const REPORTS_BOARD_CASHFLOW_DATA_SUCCESS = "analytics/REPORTS_BOARD_CASHFLOW_DATA_SUCCESS"

export function updateViewDefinition(name: string, value: string) {
  return (dispatch: Function, getState: Function): Promise<void> => {
    const { CATEGORIES } = ChartGroupByType
    const viewDefinition: ViewDefinition = selectViewDefinition(getState())

    if (name === GRANULARITY_VIEW_DEFINITION_ITEM && viewDefinition.chartType === ChartType.PIE) {
      // ignore granularity change when pie chart is selected, granularity must be ALL
      return Promise.resolve()
    }

    if (
      name === GROUP_BY_VIEW_DEFINITION_ITEM &&
      value === CATEGORIES &&
      viewDefinition.subject === ChartSubjectType.BALANCE
    ) {
      // balance grouped by categories does not make sense so chage it to cash flow
      dispatch(
        changeViewDefinition({
          groupBy: value,
          subject: ChartSubjectType.CASH_FLOW,
          compareTo: IntervalCompareToType.NONE,
        }),
      )
    } else if (name === CHART_TYPE_VIEW_DEFINITION_ITEM && value === ChartType.PIE) {
      // pie chart make sense only for whole interval so disable granularity
      dispatch(
        changeViewDefinition({
          chartType: ChartType.PIE,
          granularity: IntervalGranularityType.ALL,
          compareTo: IntervalCompareToType.NONE,
        }),
      )
    } else {
      dispatch(changeViewDefinition({ [name]: value }))
    }

    if (
      viewDefinition.granularity === IntervalGranularityType.ALL &&
      name === CHART_TYPE_VIEW_DEFINITION_ITEM &&
      value === ChartType.LINE
    ) {
      dispatch(
        changeViewDefinition({
          granularity: viewDefinitionInitialState.granularity,
          compareTo: IntervalCompareToType.NONE,
        }),
      )
    }

    const newViewDefinition = selectViewDefinition(getState())
    const filter = selectFilter(getState())

    if (!newViewDefinition.chartType) {
      dispatch(changeViewDefinition({ chartType: viewDefinitionInitialState.chartType }))
    }

    if (
      (newViewDefinition.groupBy !== ChartGroupByType.NONE ||
        newViewDefinition.chartType !== ChartType.LINE ||
        filter.period.interval === Interval.ALL_THE_TIME) &&
      newViewDefinition.compareTo !== IntervalCompareToType.NONE
    ) {
      dispatch(changeViewDefinition({ compareTo: IntervalCompareToType.NONE }))
    }

    // set filter to exclude transfers when EXPENSE/INCOME/CASHFLOW SubjectType
    let recordTypes
    if (newViewDefinition.subject !== ChartSubjectType.BALANCE && !isAppBoard()) {
      recordTypes =
        filter.recordTypes !== undefined
          ? filter.recordTypes.filter((recordType) => recordType !== FilterRecordType.TRANSFER)
          : [FilterRecordType.INCOME, FilterRecordType.EXPENSE]

      dispatch(setFilterValues({ recordTypes }))
    } else {
      dispatch(setFilterValues({ recordTypes: undefined }))
    }

    if (
      newViewDefinition.subject === ChartSubjectType.INCOMES_EXPENSES_REPORT ||
      newViewDefinition.subject === ChartSubjectType.OPERATING_PROFIT_REPORT
    ) {
      return dispatch(fetchCategoriesReport())
    }

    if (newViewDefinition.subject === ChartSubjectType.BOARD_CASH_FLOW_REPORT) {
      return dispatch(fetchBoardCashFlowReport())
    }

    return dispatch(fetchChartData())
  }
}

export function setFilterView(interval: Interval, intervalValue: string) {
  return (dispatch: Function) => {
    const IntervalToIntervalValuePattern = {
      month: /(\d{4})-(0*(3[01]|[12][0-9]|[1-9]))/,
      year: /\d{4}/,
      week: /(\d{4})-(0?(5[0-3]|[1-4][0-9]|[1-9]))/,
    }

    const IntervalToIntervalFormat = {
      month: "Y-M",
      year: "Y",
      week: "Y-W",
    }

    const [parsedDate] = intervalValue.match(IntervalToIntervalValuePattern[interval])

    if (interval && parsedDate) {
      const start = moment(parsedDate, IntervalToIntervalFormat[interval]).startOf(
        interval as StartOf,
      )
      const end = start.clone().endOf(interval as StartOf)
      const period: Period = {
        interval,
        start,
        end,
      }

      if (start && end) {
        dispatch(setFilterValues({ period }))
      }
      return dispatch(changeViewDefinition({ subject: ChartSubjectType.INCOMES_EXPENSES_REPORT }))
    }
  }
}

export function fetchAnalyticsData(filterOverride?: Partial<FilterType>) {
  return (dispatch: Function, getState: GetState) => {
    const viewDefinition = selectViewDefinition(getState())

    if (
      viewDefinition.subject === ChartSubjectType.INCOMES_EXPENSES_REPORT ||
      viewDefinition.subject === ChartSubjectType.OPERATING_PROFIT_REPORT
    ) {
      return dispatch(fetchCategoriesReport())
    } else if (viewDefinition.subject === ChartSubjectType.BOARD_CASH_FLOW_REPORT) {
      return dispatch(fetchBoardCashFlowReport())
    } else {
      return dispatch(fetchChartData(filterOverride))
    }
  }
}

export function changeViewDefinition(viewDefinition: Partial<ViewDefinition>) {
  return {
    type: CHANGE_VIEW_DEFINITION,
    payload: viewDefinition,
  }
}

export function fetchChartData(filterOverride?: Partial<FilterType>) {
  return async (dispatch: Function, getState: GetState): Promise<void> => {
    dispatch(fetchChartDataStart())
    const viewDefinition = selectViewDefinition(getState())
    const filter = filterOverride || selectFilter(getState())
    try {
      let chartData: ChartData[] = []
      if (viewDefinition.groupBy === ChartGroupByType.ACCOUNTS) {
        chartData = await service.getChartDataGroupByAccount(viewDefinition, filter)
      } else if (viewDefinition.groupBy === ChartGroupByType.CATEGORIES) {
        chartData = await service.getChartDataGroupByCategory(viewDefinition, filter)
      } else if (viewDefinition.groupBy === ChartGroupByType.INVESTMENTS) {
        chartData = await service.getChartDataGroupByInvestment(viewDefinition, filter)
      } else {
        chartData = await service.getChartData(viewDefinition, filter)
      }

      return dispatch(fetchChartDataSuccess(chartData))
    } catch (error) {
      logger.captureException(error, "analytics.actions.fetchChartData unexpected error")
      return dispatch(fetchChartDataError(error.toString()))
    }
  }
}

function fetchChartDataStart() {
  return {
    type: FETCH_CHART_DATA_START,
    payload: { isLoading: true, datasets: [] },
  }
}

function fetchChartDataSuccess(datasets) {
  return {
    type: FETCH_CHART_DATA_SUCCESS,
    payload: { isLoading: false, datasets: [...datasets] },
  }
}

function fetchChartDataError(error) {
  return {
    type: FETCH_CHART_DATA_ERROR,
    payload: { isLoading: false, error, datasets: [] },
  }
}

export function fetchCategoriesReport() {
  return async (dispatch: Function, getState: GetState) => {
    dispatch(requestCategoriesReport())
    const filter: FilterType = selectFilter(getState())

    const reportData = await reportsService.fetchReportIncomesAndExpenses(filter)

    dispatch(receiveCategoriesReport(reportData))
  }
}

export function fetchBoardCashFlowReport() {
  return async (dispatch: Function, getState: GetState) => {
    dispatch(requestBoardCashFlowReport())
    const filter: FilterType = selectFilter(getState())

    const reportData = await reportsService.fetchBoardCashFlow(filter)

    dispatch(receiveBoardCashFlowReport(reportData))
  }
}

function receiveBoardCashFlowReport(cashFlowData: CashFlowReportRaw): Action<CashFlowReportRaw> {
  return {
    type: REPORTS_BOARD_CASHFLOW_DATA_SUCCESS,
    payload: cashFlowData,
  }
}

function requestBoardCashFlowReport() {
  return {
    type: REPORTS_BOARD_CASHFLOW_DATA_START,
  }
}

function receiveCategoriesReport(categoriesData: ReportRaw): Action<ReportRaw> {
  return {
    type: REPORTS_CATEGORIES_DATA_SUCCESS,
    payload: categoriesData,
  }
}

function requestCategoriesReport() {
  return {
    type: REPORTS_CATEGORIES_DATA_START,
  }
}

export function openCategoryPreview(
  categoryLevel: RecordCategoryLevel,
  categoryLevelValue: number | string | string[] | number[],
) {
  return async (dispatch: Function) => {
    const requestRecordsFilter = {
      [CategoryFilterGroup[RecordCategoryLevel[categoryLevel]]]: [
        ...(Array.isArray(categoryLevelValue) ? categoryLevelValue : [categoryLevelValue]),
      ],
    }

    dispatch(openRecordsPreview(requestRecordsFilter))
  }
}
