import _isEmpty from "lodash/isEmpty"
import { pipe } from "ramda"
import { addUpdateAttributes } from "src/backend/common/helpers"
import * as commonRepository from "src/backend/common/repository"
import { updateAuditData } from "src/backend/common/service"
import { updateHiddenEnvelopesValue } from "src/backend/configures/helpers"
import { inMemoryTableNames } from "src/backend/db/inMemorySqlDbSchemaBuilder"
import { ConfigureKey } from "src/backend/enums"
import { utcDateAsISOString } from "src/backend/time/time"
import { ChangeSource, ChangeType, ConfigureDocument } from "src/types/Configure"
import { CouchDbDocument, Id } from "src/types/CouchDb"
import { User } from "src/types/User"
import uuid from "uuid/v4"
import * as repository from "./repository"

export async function saveTourGuide(
  tourGuideName: string,
  user: User,
): Promise<Array<CouchDbDocument>> {
  const defaultConfigureObject = DefaultTourGuideConfigure(uuid())

  const tourGuidesConfigure: ConfigureDocument =
    (await repository.findByKey(ConfigureKey.WEBAPP_TOURGUIDE)) ||
    updateAuditData(user, utcDateAsISOString)(defaultConfigureObject)

  const tourGuideValue = JSON.parse(tourGuidesConfigure.value)

  if (!tourGuideValue.includes(tourGuideName)) {
    tourGuideValue.push(tourGuideName)
  }

  const configureDocument: ConfigureDocument = pipe(
    addUpdateAttributes,
    updateConfigureValue(tourGuideValue),
  )(tourGuidesConfigure)

  return commonRepository.updateBulk([configureDocument], inMemoryTableNames.CONFIGURE)
}

export async function saveHiddenEnvelopes(
  envelopeId: number,
  isHidden: boolean,
  user: User,
): Promise<Array<CouchDbDocument>> {
  const hiddenEnvelopesConfigure: ConfigureDocument =
    (await repository.findByKey(ConfigureKey.HIDDEN_ENVELOPES)) ||
    updateAuditData(user, utcDateAsISOString)(DefaultHiddenEnvelopesConfigure(uuid()))

  const hiddenEnvelopesValue: Array<number> = JSON.parse(`[${hiddenEnvelopesConfigure.value}]`)

  const configureDocument: ConfigureDocument = pipe(
    addUpdateAttributes,
    updateHiddenEnvelopesValue(hiddenEnvelopesValue, envelopeId, isHidden),
  )(hiddenEnvelopesConfigure)

  return commonRepository.updateBulk([configureDocument], inMemoryTableNames.CONFIGURE)
}

function updateConfigureValue(newValue) {
  return (tourGuideConfigure) => ({
    ...tourGuideConfigure,
    value: JSON.stringify(newValue),
  })
}

function DefaultConfigure(id: Id): ConfigureDocument {
  return {
    _id: `-Configure_${id}`,
    reservedModelType: inMemoryTableNames.CONFIGURE,
    reservedSource: "backend",
    reservedOwnerId: "ca2d23dc-22cc-46a7-b0c4-596f495b35f5",
    reservedAuthorId: "ca2d23dc-22cc-46a7-b0c4-596f495b35f5",
    reservedCreatedAt: "2020-03-19T19:30:08.957+00:00",
    reservedUpdatedAt: "2020-03-23T11:50:55.519+00:00",
    changeSource: ChangeSource.ProfileInfo,
    changeType: ChangeType.Modify,
    key: "",
    value: "",
  }
}

function DefaultHiddenEnvelopesConfigure(id: Id): ConfigureDocument {
  return {
    ...DefaultConfigure(id),
    key: ConfigureKey.HIDDEN_ENVELOPES,
    value: "",
  }
}

function DefaultTourGuideConfigure(id: Id): ConfigureDocument {
  return {
    ...DefaultConfigure(id),
    key: ConfigureKey.WEBAPP_TOURGUIDE,
    value: JSON.stringify([]),
  }
}

/**
 * Function to remove extra tour guides created by wring implementation of new version of react-joyride
 */
export async function sanitizeTourGuides() {
  const [validConfigure, ...redundantConfigures] = await repository.findAllByKey(
    ConfigureKey.WEBAPP_TOURGUIDE,
  )

  if (validConfigure && redundantConfigures && !_isEmpty(redundantConfigures)) {
    return commonRepository.removeBulk(redundantConfigures as ConfigureDocument[])
  }
  return
}
