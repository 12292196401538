import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { selectWizardCurrentStep, selectWizardCurrentStepPosition } from 'src/frontend/scenes/imports/mapping/selectors'
import './WizardProgressBar.less'
import { FormattedMessage } from 'src/frontend/modules/intl'

const renderStep = (position, currentPosition) => {
  const activeClass = position === currentPosition ? 'active' : ''
  return (
    <li>
      <div className={`step ${activeClass}`}>{position}</div>
    </li>
  )
}

const mapStateToProps = state => ({
  position: selectWizardCurrentStepPosition(state),
  currentStep: selectWizardCurrentStep(state),
})

const WizardProgressBar = ({ currentStep, position }) => (
  <div className="wizard">
    <ul className="progress-bar">
      {renderStep(1, position)}
      {renderStep(2, position)}
      {renderStep(3, position)}
      {renderStep(4, position)}
    </ul>

    <h2><FormattedMessage id={`imports.mapping.wizard.${currentStep}_step`} /></h2>
  </div>
)

WizardProgressBar.propTypes = {
  position: PropTypes.number.isRequired,
  currentStep: PropTypes.string.isRequired,
}

export default connect(mapStateToProps)(WizardProgressBar)
