import React, { useRef, useState } from "react"
import { Divider, Form } from "semantic-ui-react"
import { IntegrationProviders } from "src/frontend/scenes/integrations/providersList/types"
import IntegrationRecipeParam = IntegrationProviders.IntegrationRecipe.IntegrationRecipeParam
import InputType = IntegrationProviders.IntegrationRecipe.InputType
import { FormattedMessage } from "src/frontend/modules/intl"
import { PrimaryButton } from "src/frontend/components/Buttons/Buttons"
import {
  useDetectOauthTabClose,
  useOauthTabCallback,
} from "src/frontend/scenes/integrations/components/hooks"
import FormattedMessageLabel from "src/frontend/modules/intl/components/FormattedMessageLabel"
import TermsOfServiceLink from "src/frontend/components/TermsOfServiceLink"
import PrivacyLink from "src/frontend/components/PrivacyLink"
import { useSelector } from "react-redux"
import {
  issetConnectionType,
  setConnectionType,
  setIntegrationSourceNameToLocalStorage,
  setNewConnectionToLocaleStorage,
  setProviderDetailToLocaleStorage,
} from "src/backend/db/localStorage"
import { integrationSourceName } from "src/backend/integrations/enums"
import { selectNewConnection } from "../newConnection/selectors"
import { selectProviderDetail } from "../providersList/selectors"
import { IntegrationConnection } from "src/frontend/scenes/integrations/newConnection/types"
import ConnectionType = IntegrationConnection.ConnectionType
import { fetchIntegrationProvidersDetailByProviderIdAndSourceName } from "src/backend/integrations/service"
import { selectUser } from "src/frontend/modules/user/selectors"
import { selectAccountListAccounts } from "../../accounts/accountsList/selectors"
import { isAppBoard } from "src/common/environment"
interface Props {
  params: IntegrationRecipeParam[]
  loginId?: string
  onOAuthCallback: (authCallback: {
    loginId: string
    success: boolean
    queryParams?: string
  }) => void
}

export default function OAuthForm({ params, loginId, onOAuthCallback }: Props) {
  const [consentGranted, setConsentGranted] = useState(true)
  const [showOAuth, setShowOAuth] = useState(false)
  const OAuthWindowRef: React.MutableRefObject<Window> = useRef(null)
  const newConnectionState = useSelector(selectNewConnection)
  const provider = useSelector(selectProviderDetail)
  const user = useSelector(selectUser)
  const accounts = useSelector(selectAccountListAccounts)

  useOauthTabCallback(OAuthWindowRef, onOAuthCallback)
  useDetectOauthTabClose(OAuthWindowRef, () => setShowOAuth(false))

  async function handleSubmit() {
    const OAuthParamRedirectUrl = params.find(
      (param) => param.type === InputType.OAUTH_REDIRECT_URL,
    )
    const connectionType = !newConnectionState.accountId
      ? ConnectionType.NEW_ACCOUNT_CONNECTION
      : ConnectionType.MANUAL_CONNECTION

    if (!issetConnectionType()) setConnectionType(connectionType)
    if (!provider && loginId) {
      const account = accounts.find(
        (account) => account.reservedIntegrationConnection?.loginId === loginId,
      )
      if (account) {
        const providerDetail = await fetchIntegrationProvidersDetailByProviderIdAndSourceName(
          user.userId,
          account.reservedIntegrationConnection.providerCode,
          account.reservedIntegrationConnection.integrationSource,
        )
        setIntegrationSourceNameToLocalStorage(
          integrationSourceName[providerDetail.integrationSource],
        )
        setProviderDetailToLocaleStorage(providerDetail)
      }
    } else {
      newConnectionState.integrationProvider = provider
      setNewConnectionToLocaleStorage(newConnectionState)
      setIntegrationSourceNameToLocalStorage(integrationSourceName[provider.integrationSource])
    }
    setShowOAuth(true)
    window.location.assign(OAuthParamRedirectUrl.value as string)
  }

  return !showOAuth ? (
    <>
      <p>
        <FormattedMessageLabel id="integrations.newConnection.oauth.redirect-to-auth" />
      </p>
      <Form>
        <PrimaryButton
          fluid
          type="submit"
          disabled={!consentGranted}
          onClick={handleSubmit}
        >
          <FormattedMessage id="form.submit" />
        </PrimaryButton>
        <Divider
          section
          hidden
        />
        <FormattedMessageLabel
          id="integrations.newConnection.consent"
          values={{
            appName: isAppBoard ? "Wallet" : "Board",
            privacyPolicyLink: <PrivacyLink />,
            termsOfServiceLink: <TermsOfServiceLink />,
          }}
        />
      </Form>
    </>
  ) : (
    <div>
      <p>
        <FormattedMessage id="integrations.newConnection.oauth.grant-access-notice" />
      </p>
    </div>
  )
}
