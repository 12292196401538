import React from 'react'
import { LegendIconType } from 'src/frontend/scenes/analytics/enums'
import { Dataset } from 'src/frontend/components/chart/types'
import { AccountIcon, CategoryIcon, SquareIcon } from 'src/frontend/components/Icons/Icons'
import { AccountType } from 'src/backend/enums'

type Props = {
  dataset: Dataset,
}

export default function LegendIcon({ dataset }: Props) {
  const color = getFillColor(dataset)

  switch (dataset.legendIconType) {
    default:
    case LegendIconType.SOLID: {
      return (
        <SquareIcon color={color} size="0.9rem" />
      )
    }
    case LegendIconType.ACCOUNT: {
      return (
        <AccountIcon
          accountType={dataset.legendIconValue as AccountType}
          color={color}
          size="2rem"
        />
      )
    }
    case LegendIconType.CATEGORY: {
      return (
        <CategoryIcon
          color={color}
          icon={dataset.legendIconValue}
          size="2rem"
        />
      )
    }
  }
}

function getFillColor(dataset) {
  if (dataset.comparison) {
    return 'transparent'
  }

  return dataset.borderColor !== 'transparent'
    ? dataset.borderColor
    : dataset.backgroundColor as string
}
