import _isEmpty from 'lodash/isEmpty'
import { Interval } from 'src/backend/enums'
import { getStartOfTomorrow, nextPeriodFromDate } from 'src/backend/time/time'

export function recordDateIsWithinFilter(recordDate, period) {
  const nextYear = nextPeriodFromDate(getStartOfTomorrow(), 1, 'year')
  const filterToDate = period.interval === Interval.ALL_THE_TIME ? nextYear : period.end

  return recordDate >= period.start && recordDate <= filterToDate
}

export function isFilterEmpty(filter = {}) {
  return !filter || _isEmpty(filter.start) || _isEmpty(filter.end)
}
