import colors from 'static/colors.json'

const DEFAULT_LABEL_COLOR = '#26c6da'

type Color = string

export function getColors(): Array<Color> {
  return colors
}

export function getRandomColor(): Color {
  const allColors = getColors()
  return allColors[Math.floor(Math.random() * allColors.length)]
}

export function getColorByIndex(index: number): Color {
  const allColors = getColors()
  return allColors[index % allColors.length]
}

export function getDefaultLabelColor(): Color {
  return DEFAULT_LABEL_COLOR
}

export function isInColors(color: string): boolean {
  return color.includes(color)
}
