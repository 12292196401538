import React, { useCallback, useState } from 'react'
import { connect } from 'react-redux'
import { Formik } from 'formik'

import { authModuleErrorHandler } from 'src/frontend/scenes/auth/helpers'
import * as userActions from 'src/frontend/modules/user/actions'
import ChangePasswordInfoMessage from 'src/frontend/scenes/auth/changePassword/ChangePasswordInfoMessage'
import { ChangePasswordForm } from 'src/frontend/scenes/auth/changePassword/ChangePasswordForm'
import { PasswordsForm } from 'src/frontend/modules/user/types'
import { getValidationSchema } from 'src/frontend/scenes/auth/changePassword/helpers'
import { validate } from 'src/frontend/scenes/auth/validator'

const mapDispatchToProps = {
  changePassword: userActions.changePassword,
}

interface Props {
  onSuccess: () => void,
  onCancel: () => void,
  changePassword: (form: PasswordsForm) => void,
}

function ChangePasswordFormContainer({ changePassword, onSuccess, onCancel }: Props) {
  const [passwordChanged, setPasswordChanged] = useState(false)

  const initialValues: PasswordsForm = {
    currentPassword: '',
    newPassword: '',
    newPasswordConfirmation: '',
  }

  const handleValidate = useCallback((values) => {
    return validate(getValidationSchema(values), values)
  }, [])


  const onSubmit = useCallback(async (values, { setSubmitting, setErrors }) => {
    const { currentPassword, newPassword, newPasswordConfirmation } = values
    try {
      await changePassword({ currentPassword, newPassword, newPasswordConfirmation })

      setSubmitting(false)
      setPasswordChanged(true)

    } catch (error) {
      authModuleErrorHandler(setSubmitting, setErrors, 'Change password failed')(error)
    }
  }, [])

  if (passwordChanged) {
    return (
      <ChangePasswordInfoMessage onClose={onSuccess} />
    )
  }

  return (
    <Formik
      initialValues={initialValues}
      validate={handleValidate}
      onSubmit={onSubmit}
    >
      {(props) => <ChangePasswordForm {...props} handleCancel={onCancel} />}
    </Formik>
  )
}


export default connect(null, mapDispatchToProps)(ChangePasswordFormContainer)
