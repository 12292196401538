import React, { useEffect } from "react"
import { Link, RouteProps } from "react-router-dom"
import { FormattedMessage } from "src/frontend/modules/intl"
import { Message } from "semantic-ui-react"
import AuthPageLayout from "src/frontend/scenes/auth/components/AuthPageLayout"
import LoginUserPassFormContainer from "src/frontend/scenes/auth/login/LoginUserPassFormContainer"
import styles from "src/frontend/scenes/auth/components/Auth.module.less"
import { Location } from "history"
import LoginOAuthForm from "./LoginOAuthForm"
import { getDebugConsoleLogs } from "src/common/environment"
import { redirectAfterInitUrlLocalStorage, BrowserStorageAction } from "src/backend/db/localStorage"
interface Props extends RouteProps {
  location: Location<{ referrer: string; thankYouMessageId: string }>
}

export default function LoginPage({ location }: Props) {
  const referrer = location && location.state && location.state.referrer

  // Handle user accessing app from banner promo from BB homepage
  useEffect(() => {
    if (window.location.pathname !== "/settings/billing") return
    redirectAfterInitUrlLocalStorage(BrowserStorageAction.SET_ITEM, window.location.pathname)
  }, [])

  return (
    <AuthPageLayout>
      <h1>
        <FormattedMessage id="auth.welcome" />
      </h1>

      {location && location.state && location.state.thankYouMessageId && (
        <Message
          className="info-message"
          info
          content={<FormattedMessage id={location.state.thankYouMessageId} />}
        />
      )}

      <LoginUserPassFormContainer referrer={referrer} />
      <div className={styles.oauth}>
        <LoginOAuthForm
          referrer={referrer}
          remember={true}
        />
      </div>
      <div className={styles.link}>
        <FormattedMessage id="auth.dont_have_account" />
        &nbsp;
        <Link to="/signup">
          <FormattedMessage id="auth.signup.action" />
        </Link>
      </div>
    </AuthPageLayout>
  )
}
