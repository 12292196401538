import { inMemoryTableNames } from "../db/inMemorySqlDbSchemaBuilder"
import { WalletSQLInMemory } from "../db/inMemorySqlDb"
import {
  AssetTransaction,
  StockIdentifier,
  StockPrice,
  StocksFundsAsset,
  ExchangeRate,
  AssetTransactionListItemType,
  MetaCurrency,
} from "src/types/investments.types"
import lf from "lovefield"
import {} from "src/backend/rest/financial_service"
import { PresistentWalletSQL, presistentTableNames } from "../db/presistentSqlDb"
import { FilterType } from "src/types/Filter"
import { findAll } from "../currencies/repository"
import { Interval, IntervalGranularityType } from "../enums"
import { startOfDay } from "../time/time"

export const getAssetTransactionsForAccount = (accountId: string) => {
  const sqlDb = WalletSQLInMemory.getInstance()

  const assetTransactionsTable = sqlDb.getTable(inMemoryTableNames.ASSET_TRANSACTION)

  return sqlDb
    .getDatabase()
    .select()
    .from(assetTransactionsTable)
    .where(assetTransactionsTable.accountId.eq(accountId))
    .exec() as Promise<AssetTransaction[]>
}

export const getStocksFundsAssets = (stocksFundsAssetIds?: string[]) => {
  const sqlDb = WalletSQLInMemory.getInstance()

  const stocksFundsAssetTable = sqlDb.getTable(inMemoryTableNames.STOCKS_FUNDS_ASSET)

  return sqlDb
    .getDatabase()
    .select()
    .from(stocksFundsAssetTable)
    .where(
      stocksFundsAssetIds
        ? stocksFundsAssetTable._id.in(stocksFundsAssetIds)
        : stocksFundsAssetTable._id.isNotNull(),
    )
    .exec() as Promise<StocksFundsAsset[]>
}

export const getAssetTransactionListItems = async (
  accountId?: string,
): Promise<AssetTransactionListItemType[]> => {
  const sqlDb = WalletSQLInMemory.getInstance()

  const assetTransactionsTable = sqlDb.getTable(inMemoryTableNames.ASSET_TRANSACTION)
  const stocksFundsAssetTable = sqlDb.getTable(inMemoryTableNames.STOCKS_FUNDS_ASSET)
  const accountsTable = sqlDb.getTable(inMemoryTableNames.ACCOUNT)

  const assetTransactionListItems: AssetTransactionListItemType[] = (await sqlDb
    .getDatabase()
    .select(
      assetTransactionsTable._id.as("id"),
      assetTransactionsTable.date.as("date"),
      assetTransactionsTable.quantity.as("quantity"),
      assetTransactionsTable.price.as("price"),
      assetTransactionsTable.priceCurrencyCode.as("priceCurrencyCode"),
      assetTransactionsTable.cashAmount.as("cashAmount"),
      assetTransactionsTable.cashAmountCurrencyCode.as("cashAmountCurrencyCode"),
      assetTransactionsTable.accountId.as("accountId"),
      assetTransactionsTable.assetId.as("assetId"),
      assetTransactionsTable.reservedModelType.as("reservedModelType"),
      assetTransactionsTable.transactionType.as("transactionType"),
      stocksFundsAssetTable.exchangeCode.as("exchangeCode"),
      stocksFundsAssetTable.symbol.as("symbol"),
      stocksFundsAssetTable.name.as("name"),
      stocksFundsAssetTable.logo.as("logo"),
      stocksFundsAssetTable.currencyCode.as("currencyCode"),
      stocksFundsAssetTable.currencyMeta.as("currencyMeta"),
      accountsTable.name.as("accountName"),
      accountsTable.color.as("accountColor"),
    )
    .from(assetTransactionsTable)
    .innerJoin(stocksFundsAssetTable, stocksFundsAssetTable._id.eq(assetTransactionsTable.assetId))
    .innerJoin(accountsTable, accountsTable._id.eq(assetTransactionsTable.accountId))
    .where(
      accountId
        ? assetTransactionsTable.accountId.eq(accountId)
        : assetTransactionsTable._id.isNotNull(),
    )
    .exec()) as AssetTransactionListItemType[]

  return assetTransactionListItems
}

export const getLatestStockPrice = async (
  stockIdentifier: StockIdentifier,
  date: Date = new Date(),
) => {
  const presistentSQL = PresistentWalletSQL.getInstance()
  const db = presistentSQL.getDatabase()

  const stockPriceTable = db.getSchema().table(presistentTableNames.STOCK_PRICE)

  const [stockPrice] = (await db
    .select()
    .from(stockPriceTable)
    .where(
      lf.op.and(
        stockPriceTable.stockIdentifier.eq(
          `${stockIdentifier.exchangeCode}:${stockIdentifier.symbol}`,
        ),
        stockPriceTable.date.lte(startOfDay(date)),
      ),
    )
    .orderBy(stockPriceTable.date, lf.Order.DESC)
    .limit(1)
    .exec()) as StockPrice[]

  return stockPrice
}

export const getLatestStockPriceFromAssetTransaction = async (
  assetId: string,
  date: Date = new Date(),
) => {
  const inMemoryDb = WalletSQLInMemory.getInstance()

  const assetTransactionsTable = inMemoryDb.getTable(inMemoryTableNames.ASSET_TRANSACTION)

  const [assetTransactionPrice] = (await inMemoryDb
    .select(
      assetTransactionsTable.price.as("value"),
      assetTransactionsTable.date.as("date"),
      assetTransactionsTable.assetId.as("assetId"),
    )
    .from(assetTransactionsTable)
    .where(
      lf.op.and(assetTransactionsTable.assetId.eq(assetId), assetTransactionsTable.date.lte(date)),
    )
    .orderBy(assetTransactionsTable.date, lf.Order.DESC)
    .limit(1)
    .exec()) as { value: number; date: Date; assetId: string }[]

  return assetTransactionPrice
}

export const getLatestExchangeRate = async (currencyCodes: string, date: Date = new Date()) => {
  const presistentSQL = PresistentWalletSQL.getInstance()
  const db = presistentSQL.getDatabase()

  const exchangeRatesTable = db.getSchema().table(presistentTableNames.EXCHANGE_RATE)

  const [exchangeRate] = (await db
    .select()
    .from(exchangeRatesTable)
    .where(
      lf.op.and(
        exchangeRatesTable.currencyCodes.eq(currencyCodes),
        exchangeRatesTable.date.lte(startOfDay(date)),
      ),
    )
    .orderBy(exchangeRatesTable.date, lf.Order.DESC)
    .limit(1)
    .exec()) as ExchangeRate[]

  return exchangeRate
}

export const insertOrUpdateStockPrices = async (stockPrices: StockPrice[]) => {
  const presistentSQL = PresistentWalletSQL.getInstance()
  const db = presistentSQL.getDatabase()

  const stockPriceTable = db.getSchema().table(presistentTableNames.STOCK_PRICE)

  const stockPriceRows = stockPrices.map((stockPrice) => stockPriceTable.createRow(stockPrice))

  const insertOrUpdateQuery = db.insertOrReplace().into(stockPriceTable).values(stockPriceRows)

  await insertOrUpdateQuery.exec()
}

export const insertOrUpdateExchangeRates = async (exchangeRates: ExchangeRate[]) => {
  const presistentSQL = PresistentWalletSQL.getInstance()
  const db = presistentSQL.getDatabase()

  const exchangeRatesTable = db.getSchema().table(presistentTableNames.EXCHANGE_RATE)

  const exchangeRatesRows = exchangeRates.map((exchangeRate) =>
    exchangeRatesTable.createRow(exchangeRate),
  )

  const insertOrUpdateQuery = db
    .insertOrReplace()
    .into(exchangeRatesTable)
    .values(exchangeRatesRows)

  await insertOrUpdateQuery.exec()
}

export const getExchangeRate = async (
  fromCurrenyCode: string,
  toCurrencyCode: string,
  date: Date,
) => {
  return getLatestExchangeRate(`${fromCurrenyCode}:${toCurrencyCode}`, date)
    .then((rate) => rate.value)
    .catch(async () => {
      console.error(
        "Exchange rate not found:",
        `${fromCurrenyCode}:${toCurrencyCode},`,
        date.toISOString().split("T")[0],
      )

      // fallback to the couchDB currencies
      const currencies = await findAll()
      const fromCurrency = currencies.find((currency) => currency.code === fromCurrenyCode)
      const toCurrency = currencies.find((currency) => currency.code === toCurrencyCode)

      return 1 / (fromCurrency?.ratioToReferential / toCurrency?.ratioToReferential) ?? 1
    })
}

type AssetQuantity = Pick<AssetTransaction, "quantity" | "assetId"> & {
  exchangeCode: string
  symbol: string
  currencyCode: string
  currencyMeta?: MetaCurrency
  accountCurrencyCode: string
  accountCurrencyRatioToReferential: number
}

export const getAssetQuanitiesToDate = async (filter: FilterType, toDate: Date) => {
  const inMemoryDb = WalletSQLInMemory.getInstance()

  const assetTransactionsTable = inMemoryDb.getTable(inMemoryTableNames.ASSET_TRANSACTION)
  const assetTable = inMemoryDb.getTable(inMemoryTableNames.STOCKS_FUNDS_ASSET)
  const accountsTable = inMemoryDb.getTable(inMemoryTableNames.ACCOUNT)
  const currencyTable = inMemoryDb.getTable(inMemoryTableNames.CURRENCY)

  return inMemoryDb
    .select(
      lf.fn.sum(assetTransactionsTable.quantity).as("quantity"),
      assetTransactionsTable.assetId.as("assetId"),
      assetTable.exchangeCode.as("exchangeCode"),
      assetTable.symbol.as("symbol"),
      assetTransactionsTable.priceCurrencyCode.as("currencyCode"),
      currencyTable.code.as("accountCurrencyCode"),
      currencyTable.ratioToReferential.as("accountCurrencyRatioToReferential"),
    )
    .from(assetTransactionsTable)
    .innerJoin(assetTable, assetTable._id.eq(assetTransactionsTable.assetId))
    .innerJoin(accountsTable, accountsTable._id.eq(assetTransactionsTable.accountId))
    .innerJoin(currencyTable, currencyTable._id.eq(accountsTable.currencyId))
    .where(
      lf.op.and(
        assetTransactionsTable.date.lte(toDate),
        filter.accountIds
          ? assetTransactionsTable.accountId.in(filter.accountIds)
          : assetTransactionsTable._id.isNotNull(),
      ),
    )
    .groupBy(assetTransactionsTable.assetId, currencyTable.code)
    .exec() as Promise<AssetQuantity[]>
}

type AssetQuantityWithGranularity = Pick<AssetTransaction, "quantity" | "assetId"> &
  Partial<Pick<AssetTransaction, "day" | "month" | "week">> & {
    exchangeCode: string
    symbol: string
    currencyCode: string
    currencyMeta?: MetaCurrency
    accountCurrencyCode: string
    accountCurrencyRatioToReferential: number
  }

export const getAssetQuanities = async (filter: FilterType, groupBy: IntervalGranularityType) => {
  const inMemoryDb = WalletSQLInMemory.getInstance()

  const assetTransactionsTable = inMemoryDb.getTable(inMemoryTableNames.ASSET_TRANSACTION)
  const assetTable = inMemoryDb.getTable(inMemoryTableNames.STOCKS_FUNDS_ASSET)
  const accountsTable = inMemoryDb.getTable(inMemoryTableNames.ACCOUNT)
  const currencyTable = inMemoryDb.getTable(inMemoryTableNames.CURRENCY)

  const selectRows = [
    lf.fn.sum(assetTransactionsTable.quantity).as("quantity"),
    assetTransactionsTable.assetId.as("assetId"),
    groupBy !== IntervalGranularityType.ALL
      ? assetTransactionsTable[groupBy].as(groupBy)
      : undefined,
    assetTransactionsTable.priceCurrencyCode.as("currencyCode"),
    assetTable.exchangeCode.as("exchangeCode"),
    assetTable.symbol.as("symbol"),
    currencyTable.code.as("accountCurrencyCode"),
    currencyTable.ratioToReferential.as("accountCurrencyRatioToReferential"),
  ].filter(Boolean)

  const groupByRows = [
    assetTransactionsTable.assetId,
    groupBy !== IntervalGranularityType.ALL ? assetTransactionsTable[groupBy] : undefined,
    currencyTable.code,
  ].filter(Boolean)

  return inMemoryDb
    .select(...selectRows)
    .from(assetTransactionsTable)
    .innerJoin(assetTable, assetTable._id.eq(assetTransactionsTable.assetId))
    .innerJoin(accountsTable, accountsTable._id.eq(assetTransactionsTable.accountId))
    .innerJoin(currencyTable, currencyTable._id.eq(accountsTable.currencyId))
    .where(
      lf.op.and(
        filter.period && filter.period.interval !== Interval.ALL_THE_TIME
          ? assetTransactionsTable.date.between(
              filter.period.start.toDate(),
              filter.period.end.toDate(),
            )
          : assetTransactionsTable._id.isNotNull(),
        filter.accountIds
          ? assetTransactionsTable.accountId.in(filter.accountIds)
          : assetTransactionsTable._id.isNotNull(),
      ),
    )
    .groupBy(...groupByRows)
    .exec() as Promise<AssetQuantityWithGranularity[]>
}

type AssetCashFlow = Pick<AssetTransaction, "cashAmount"> & {
  currencyCode: string
  accountCurrencyCode: string
  accountCurrencyRatioToReferential: number
}

export const getAssetTransactionsCashFlowsToDate = async (filter: FilterType, toDate: Date) => {
  const inMemoryDb = WalletSQLInMemory.getInstance()

  const assetTransactionsTable = inMemoryDb.getTable(inMemoryTableNames.ASSET_TRANSACTION)
  const accountsTable = inMemoryDb.getTable(inMemoryTableNames.ACCOUNT)
  const currencyTable = inMemoryDb.getTable(inMemoryTableNames.CURRENCY)

  return inMemoryDb
    .select(
      lf.fn.sum(assetTransactionsTable.cashAmount).as("cashAmount"),
      assetTransactionsTable.cashAmountCurrencyCode.as("currencyCode"),
      currencyTable.code.as("accountCurrencyCode"),
      currencyTable.ratioToReferential.as("accountCurrencyRatioToReferential"),
    )
    .from(assetTransactionsTable)
    .innerJoin(accountsTable, accountsTable._id.eq(assetTransactionsTable.accountId))
    .innerJoin(currencyTable, currencyTable._id.eq(accountsTable.currencyId))
    .where(
      lf.op.and(
        assetTransactionsTable.cashAmount.isNotNull(),
        assetTransactionsTable.cashAmountCurrencyCode.isNotNull(),
        assetTransactionsTable.date.lte(toDate),
        filter.accountIds
          ? assetTransactionsTable.accountId.in(filter.accountIds)
          : assetTransactionsTable._id.isNotNull(),
      ),
    )
    .groupBy(assetTransactionsTable.cashAmountCurrencyCode, currencyTable.code)
    .exec() as Promise<AssetCashFlow[]>
}

type AssetCashFlowWithGranularity = Partial<Pick<AssetTransaction, "day" | "month" | "week">> & {
  cashAmount: number
  currencyCode: string
  accountCurrencyCode: string
  accountCurrencyRatioToReferential: number
}

export const getAssetTransactionsCashFlows = async (
  filter: FilterType,
  groupBy: IntervalGranularityType,
) => {
  const inMemoryDb = WalletSQLInMemory.getInstance()

  const assetTransactionsTable = inMemoryDb.getTable(inMemoryTableNames.ASSET_TRANSACTION)
  const accountsTable = inMemoryDb.getTable(inMemoryTableNames.ACCOUNT)
  const currencyTable = inMemoryDb.getTable(inMemoryTableNames.CURRENCY)

  const selectRows = [
    lf.fn.sum(assetTransactionsTable.cashAmount).as("cashAmount"),
    assetTransactionsTable.cashAmountCurrencyCode.as("currencyCode"),
    groupBy !== IntervalGranularityType.ALL
      ? assetTransactionsTable[groupBy].as(groupBy)
      : undefined,
    currencyTable.code.as("accountCurrencyCode"),
    currencyTable.ratioToReferential.as("accountCurrencyRatioToReferential"),
  ].filter(Boolean)

  const groupByRows = [
    assetTransactionsTable.cashAmountCurrencyCode,
    groupBy !== IntervalGranularityType.ALL ? assetTransactionsTable[groupBy] : undefined,
    currencyTable.code,
  ].filter(Boolean)

  return inMemoryDb
    .select(...selectRows)
    .from(assetTransactionsTable)
    .innerJoin(accountsTable, accountsTable._id.eq(assetTransactionsTable.accountId))
    .innerJoin(currencyTable, currencyTable._id.eq(accountsTable.currencyId))
    .where(
      lf.op.and(
        assetTransactionsTable.cashAmount.isNotNull(),
        assetTransactionsTable.cashAmountCurrencyCode.isNotNull(),
        filter.period && filter.period.interval !== Interval.ALL_THE_TIME
          ? assetTransactionsTable.date.between(
              filter.period.start.toDate(),
              filter.period.end.toDate(),
            )
          : assetTransactionsTable._id.isNotNull(),
        filter.accountIds
          ? assetTransactionsTable.accountId.in(filter.accountIds)
          : assetTransactionsTable._id.isNotNull(),
      ),
    )
    .groupBy(...groupByRows)
    .exec() as Promise<AssetCashFlowWithGranularity[]>
}
