import React from 'react'
import { Bar, Line } from 'react-chartjs-2'
import Legend from 'src/frontend/components/chart/Legend'
import { ChartType } from 'src/frontend/components/chart/ChartType'
import {
  customLabel,
  filterExistingTooltip,
  tooltipTitle, xAxisDateTick,
  yAxisTick,
} from 'src/frontend/components/chart/helpers'
import { Currency } from 'src/types/Currency'
import { COMPARISON_DATASET_DEFAULT_OPTIONS, DATASET_DEFAULT_OPTIONS } from 'src/frontend/components/chart/constants'
import { Canvas, Dataset } from 'src/frontend/components/chart/types'
import { ChartDataSets, ChartOptions } from 'chart.js'
import _merge from 'lodash/merge'
import './LinearChart.less'
import { isUndefinedOrNull } from 'src/common/utils'
import { useToolTip } from 'src/frontend/components/chart/hooks'

export type Props = {
  type: string,
  labels: string[][],
  labelsPerChart: number,
  datasets: Array<Dataset>,
  currency: Currency,
  hideHorizontalLines?: boolean,
  plainValues?: boolean,
  height?: number,
  width?: number,
  hideLegend?: boolean,
  options?: ChartOptions,
  plugins?: any[],
  ref?: React.RefObject<any>,
}

LinearChart.defaultProps = {
  LinearChart: false,
  plainValues: false,
  height: null,
  width: null,
  hideLegend: false,
  options: {},
  plugins: undefined,
}

export default function LinearChart({
  type,
  plainValues,
  datasets,
  labels,
  currency,
  labelsPerChart,
  hideHorizontalLines,
  height,
  width,
  hideLegend,
  ref,
  plugins,
  options,
}: Props) {
  useToolTip()

  const data = datasets.length > 0 ? (canvas: Canvas) => {
    return {
      labels: labels.map((label) => Array.isArray(label) ? label[0] : label),
      datasets: datasets.map((dataset: Dataset): ChartDataSets => {
        return {
          // shared chart options
          ...(isUndefinedOrNull(dataset.comparisonIndex)
            ? DATASET_DEFAULT_OPTIONS
            : COMPARISON_DATASET_DEFAULT_OPTIONS),

          // specific dataset options (can override shared options)
          ...dataset,

          data: dataset.data,

          label: dataset.label ?? null,

          backgroundColor: dataset.backgroundGradient
            ? dataset.backgroundGradient(canvas)
            : dataset.backgroundColor,
        } as ChartDataSets
      }),
    }
  } : {}

  const defaultOptions: ChartOptions = {
    hover: {
      mode: 'x-axis',
    },
    legend: {
      display: false,
    },
    scales: {
      xAxes: [
        {
          stacked: type === ChartType.BAR,
          scaleLabel: {
            display: false,
          },
          gridLines: {
            display: false,
            drawBorder: !hideHorizontalLines,
          },
          ticks: {
            autoSkip: false,
            maxRotation: 0,
            minRotation: 0,
            callback: !plainValues ? xAxisDateTick(labels, labelsPerChart) : () => undefined,
          },
        },
      ],
      yAxes: [
        {
          stacked: type === ChartType.BAR,
          gridLines: {
            display: !hideHorizontalLines,
            drawBorder: !hideHorizontalLines,
            zeroLineWidth: 1,
          },
          ticks: {
            callback: !plainValues ? yAxisTick : () => undefined,
            maxTicksLimit: 9,
          },
        },
      ],
    },
    tooltips: {
      mode: 'x-axis',
      filter: filterExistingTooltip,
      callbacks: {
        title: tooltipTitle,
      },
      enabled: false,

      custom(tooltipModel) {
        return customLabel(this._chart, tooltipModel, labels, datasets, currency)
      },
    },
    plugins: {
      labels: {
        render: () => {
          return undefined
        },
      },
    },
  }

  if (type === ChartType.BAR) {
    return (
      <>
        <div style={{ height, width }}>
          <Bar
            data={data}
            options={_merge({ ...defaultOptions }, options)}
            datasetKeyProvider={(dataset) => dataset.id}
            height={height}
            width={width}
            ref={ref}
            plugins={plugins}
          />
        </div>
        {!hideLegend && <Legend datasets={datasets} />}
      </>
    )
  } else if (type === ChartType.LINE) {
    return (
      <>
        <div style={{ height, width }}>
          <Line
            data={data}
            options={_merge({ ...defaultOptions }, options)}
            height={height}
            width={width}
            plugins={plugins}
            datasetKeyProvider={(dataset) => dataset.id || dataset.label}
          />
        </div>
        {!hideLegend && <Legend datasets={datasets} />}
      </>
    )
  } else {
    throw new Error(`Unsupported chart type: '${type}'`)
  }
}
