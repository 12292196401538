import React, { FC } from "react"
import { mergeClasses } from "src/common/utils"
import TruncatedText from "../TruncatedText"
import styles from "src/frontend/components/RecordList/RecordList.module.less"
import { formatDate, formatMessage, formatNumber } from "src/frontend/modules/intl"
import { AssetListItemType, AssetTransaction, TransactionType } from "src/types/investments.types"
import AssetIcon from "./AssetIcon"

interface Props {
  assetListItem: AssetListItemType
  assetTransaction: AssetTransaction
}

const AssetTransactionShortListItem: FC<Props> = ({ assetListItem, assetTransaction }) => {
  return (
    <li className={mergeClasses(styles.recordListItem, styles.basic)}>
      <div className={styles.columnIcon}>
        <AssetIcon src={assetListItem.logo} />
      </div>
      <div className={styles.columnCategoryAccount}>
        <div
          className={styles.recordCategory}
          data-e2e="record-category-simple"
        >
          {`${assetTransaction.transactionType === TransactionType.BUY ? "+" : ""}${
            assetTransaction.quantity
          } ${assetListItem.symbol}`}
        </div>

        {assetTransaction.cashAmount && (
          <div
            style={{ maxWidth: "30rem" }}
            className={styles.recordAccount}
          >
            <TruncatedText
              text={formatMessage(
                assetTransaction.transactionType === TransactionType.BUY
                  ? "investments.assetTransaction.primaryLabel"
                  : "investments.assetTransaction.primaryLabelInverted",
                {
                  amount: formatNumber(assetTransaction.cashAmount / 100, {
                    format: "currency",
                    currency: assetTransaction.cashAmountCurrencyCode ?? "",
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }),
                  assetName: assetListItem.name,
                },
              )}
              length={60}
              showPopup
            />
          </div>
        )}
        <div className={styles.recordAccount}>
          <TruncatedText
            text={formatMessage("investments.assetTransaction.secondaryLabel", {
              price: formatNumber(
                assetListItem.currencyMeta?.baseCurrencyCode
                  ? assetTransaction.price / assetListItem.currencyMeta.currencyScaleFactor / 100
                  : assetTransaction.price / 100,
                {
                  format: "currency",
                  currency:
                    assetListItem.currencyMeta?.baseCurrencyCode ??
                    assetListItem.currencyCode ??
                    assetTransaction.priceCurrencyCode,
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                },
              ),
            })}
            length={30}
            showPopup
          />
        </div>
      </div>
      <div className={styles.columnAmountDate}>
        <div className={styles.recordDate}>{formatDate(assetTransaction.date)}</div>
      </div>
    </li>
  )
}

export default AssetTransactionShortListItem
