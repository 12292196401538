import { pipe } from 'ramda'
import { createSelector } from 'reselect'
import { sortEntities } from 'src/backend/common/helpers'
import { WidgetId } from 'src/backend/dashboard/enums'
import * as accountsSelectors from 'src/frontend/modules/accounts/selectors'
import * as categoriesSelectors from 'src/frontend/modules/categories/selectors'
import * as currenciesSelectors from 'src/frontend/modules/currencies/selectors'
import { DashboardState } from 'src/frontend/scenes/dashboard/reducer'
import {
  AccountsTotals,
  WidgetType,
  WidgetView,
} from 'src/frontend/scenes/dashboard/types'
import { composeBalanceByCurrenciesData } from 'src/frontend/scenes/dashboard/widgets/BalanceByCurrencyBar/selectors'
import { aggregateBalanceChartData } from 'src/frontend/scenes/dashboard/widgets/BalanceChart/selectors'
import { getCashFlow } from 'src/frontend/scenes/dashboard/widgets/CashFlowBar/selectors'
import { aggregateCashFlowTrendChartData } from 'src/frontend/scenes/dashboard/widgets/CashFlowTrend/selectors'
import { aggregatePeriodToPeriodChartData } from 'src/frontend/scenes/dashboard/widgets/PeriodToPeriodChart/selectors'
import { getPlannedPayments } from 'src/frontend/scenes/dashboard/widgets/PlannedPaymentsList/selectors'
import { getSpendingByCategoriesBar } from 'src/frontend/scenes/dashboard/widgets/SpendingByCategoriesBar/selectors'
import { Account } from 'src/types/Account'
import { Currency } from 'src/types/Currency'
import { FilterType } from 'src/types/Filter'
import { RootState } from 'src/types/State'
import _isEmpty from 'lodash/isEmpty'
import { getAddWidgets } from 'src/frontend/scenes/dashboard/helpers'
import { combineCashRatioLiquidity } from 'src/frontend/scenes/dashboard/widgets/CashRatioLiquidity/selectors'
import { Contact } from 'src/types/Contact'
import * as contactsSelectors from 'src/frontend/modules/contacts/selectors'
import { HashMap } from 'src/types/common'
import { createDummyWidgets } from 'src/frontend/scenes/dashboard/dummyData'
import {
  getBoardCashFlow,
  getBoardOperatingProfit,
} from 'src/frontend/scenes/dashboard/widgets/BoardCashFlowBar/selectors'
import { aggregateBoardCashFlowTrendChartData } from 'src/frontend/scenes/dashboard/widgets/BoardCashFlowTrend/selectors'
import { aggregateRevenuesCostsChartData } from 'src/frontend/scenes/dashboard/widgets/BoardRevenueCostsGrowth/selectors'
import { RevenueCostsGrowthWidgetVariant } from 'src/frontend/scenes/dashboard/widgets/BoardRevenueCostsGrowth/enums'
import { getCreditLimitUtilization } from 'src/frontend/scenes/dashboard/widgets/CreditLimitsUtilization/selectors'
import { AccountType, BalanceDisplayType } from 'src/backend/enums'
import { reduceBy } from 'src/common/utils'
import { getSpendingPieAndBar } from 'src/frontend/scenes/dashboard/widgets/PieAndBarSpending/selectors'
import { getPieBarSpendingHeader } from 'src/frontend/scenes/dashboard/widgets/PieAndBarSpending/helpers'
import { getSpendingByNature } from 'src/frontend/scenes/dashboard/widgets/SpendingByNatureBars/selectors'
import { getNatureOfSpendingHeader } from 'src/frontend/scenes/dashboard/widgets/SpendingByNatureBars/helpers'
import { Id } from 'src/types/CouchDb'
import { aggregateLastRecordsData } from 'src/frontend/scenes/dashboard/widgets/LastRecords/selectors'

export const selectDashboard = (state: RootState): DashboardState => state.scenes.dashboard
export const selectDashboardFilter = (state: RootState): FilterType => selectDashboard(state).filter
export const selectDashboardWidgets = (state: RootState): HashMap<WidgetType> => selectDashboard(state).widgets
export const selectDashboardWidgetsOrder = (state: RootState): Id[] => selectDashboard(state).widgetsOrder
export const selectDashboardAccountsTotals = (state: RootState): HashMap<AccountsTotals> => {
  return selectDashboard(state).accountsTotals
}

export const selectAccountsWithBalance = createSelector(
  currenciesSelectors.selectReferentialCurrency,
  currenciesSelectors.selectCurrencies,
  accountsSelectors.selectNonArchivedAccountsAsArray,
  selectDashboardAccountsTotals,
  pipe(accountsSelectors.mergeAccounts, sortEntities),
)

export const selectDashboardWidgetsWithData: (state: RootState) => HashMap<WidgetType> = createSelector([
  selectDashboardWidgets,
  selectDashboardFilter,
  accountsSelectors.selectAllAccounts,
  categoriesSelectors.selectFlattenedCategories,
  currenciesSelectors.selectCurrencies,
  contactsSelectors.selectContacts,
  currenciesSelectors.selectReferentialCurrency,
  selectDashboardAccountsTotals,
], combineDashboardData)

export const selectDashboardDummyWidgetsWithData = createSelector([
  selectDashboardWidgets,
  selectDashboardFilter,
  accountsSelectors.selectAllAccounts,
  categoriesSelectors.selectFlattenedCategories,
  currenciesSelectors.selectCurrencies,
  contactsSelectors.selectContacts,
  currenciesSelectors.selectReferentialCurrency,
  selectDashboardAccountsTotals,
], combineDashboardDummyWidgets)


export function combineDashboardDummyWidgets(
  widgets: HashMap<WidgetType>,
  filter: FilterType,
  accounts: HashMap<Account>,
  flattenedCategoriesHierarchy: any,
  currencies: HashMap<Currency>,
  contacts: HashMap<Contact.ContactDocument>,
  referentialCurrency: Currency,
  accountsTotals: AccountsTotals,
) {
  if (!widgets) {
    return []
  }

  const mockedCreditAccounts = Object.values(accounts)
    .map((account: Account, index): Account => {
      return index === 0
        ? {
          ...account,
          accountType: AccountType.CREDIT_CARD,
          creditCard: { limit: 8000000, dueDay: 0, balanceDisplayOption: BalanceDisplayType.AVAILABLE_CREDIT },
        }
        : { ...account, accountType: AccountType.GENERAL }
    }).reduce(reduceBy('_id'), {})

  const dummyWidgets = createDummyWidgets(mockedCreditAccounts, currencies, accountsTotals)

  const dummyWidgetsWithData: HashMap<WidgetType> = combineDashboardData(
    dummyWidgets,
    filter,
    mockedCreditAccounts,
    flattenedCategoriesHierarchy,
    currencies,
    contacts,
    referentialCurrency,
    accountsTotals,
  )

  return Object.entries(getAddWidgets())
    .reduce((acc, [key, value]) => {
      // filter out already used widgets
      const newValue = value.filter((widget) => {
        return !Object.values(widgets).some((existingWidget) => {
          return existingWidget.widgetId === widget.widgetId && existingWidget.variant === widget.variant
        })
      })

      // filter out empty widget categories
      if (_isEmpty(newValue)) {
        return acc
      }

      return {
        ...acc,
        [key]: newValue.map((widget) => {
          return Object.values(dummyWidgetsWithData).find((dummyWidget) => {
            return dummyWidget.widgetId === widget.widgetId && dummyWidget.variant === widget.variant
          })
        }),
      }
    }, {})
}

export function combineDashboardData(
  widgets,
  filter,
  accounts: HashMap<Account>,
  categoriesHierarchy,
  currencies,
  contacts,
  referentialCurrency,
  accountsTotals,
): HashMap<WidgetType> {

  if (!widgets) {
    return {}
  }

  return Object.keys(widgets).map((widgetId: Id): WidgetType => {
    const widget = widgets[widgetId]
    switch (widget.widgetId) {
      case WidgetId.BALANCE_CHART_CARD: {
        return updateWidgetData(widget, filter, aggregateBalanceChartData(referentialCurrency))
      }

      case WidgetId.LAST_RECORDS_CARD: {
        return updateWidgetData(
          widget,
          filter,
          aggregateLastRecordsData(accounts, categoriesHierarchy, currencies, contacts),
        )
      }

      case WidgetId.SPENDING_BY_CATEGORIES_PIE_CHART_CARD:
      case WidgetId.SPENDING_BY_CATEGORIES_CARD: {
        return updateWidgetData(widget, filter, getSpendingByCategoriesBar(referentialCurrency, categoriesHierarchy))
      }

      case WidgetId.UPCOMING_PLANNED_PAYMENTS: {
        return updateWidgetData(
          widget,
          filter,
          getPlannedPayments(accounts, categoriesHierarchy, currencies, referentialCurrency),
        )
      }

      case WidgetId.CASH_FLOW_CARD: {
        return updateWidgetData(
          widget,
          filter,
          getCashFlow(referentialCurrency),
        )
      }

      case WidgetId.CASH_FLOW_CARD_BOARD: {
        return updateWidgetData(
          widget,
          filter,
          getBoardCashFlow(referentialCurrency),
        )
      }

      case WidgetId.OPERATING_PROFIT_CARD: {
        return updateWidgetData(
          widget,
          filter,
          getBoardOperatingProfit(referentialCurrency),
        )
      }

      case WidgetId.PERIOD_2_PERIOD_CHART_CARD: {
        return updateWidgetData(widget, filter, aggregatePeriodToPeriodChartData(referentialCurrency))
      }

      case WidgetId.BALANCE_BY_CURRENCIES_CARD: {
        return updateWidgetData(widget, filter, composeBalanceByCurrenciesData(currencies))
      }

      case WidgetId.CASH_FLOW_CHART_CARD: {
        return updateWidgetData(widget, filter, aggregateCashFlowTrendChartData(referentialCurrency))
      }

      case WidgetId.CASH_FLOW_CHART_CARD_BOARD: {
        return updateWidgetData(widget, filter, aggregateBoardCashFlowTrendChartData(referentialCurrency))
      }

      case WidgetId.CASH_RATIO_LIQUIDITY_CARD: {
        return updateWidgetData(
          widget,
          filter,
          combineCashRatioLiquidity(accounts, accountsTotals, currencies, referentialCurrency),
        )
      }

      case WidgetId.REVENUE_COST_GROWTH_CARD: {
        const title = widget.variant === RevenueCostsGrowthWidgetVariant.REVENUE
          ? 'dashboard.widget.operating-revenue.header'
          : 'dashboard.widget.operating-cost.header'


        return updateWidgetData(
          widget,
          filter,
          aggregateRevenuesCostsChartData(referentialCurrency, widget.variant),
          title,
        )
      }

      case WidgetId.CREDIT_UTILIZATION_CARD: {
        return updateWidgetData(
          widget,
          filter,
          getCreditLimitUtilization(accounts, referentialCurrency),
        )
      }

      case WidgetId.SPENDING_BY_CATEGORIES_AND_LABELS_PIE_CHART_CARD: {
        const title = getPieBarSpendingHeader(widget.variant)

        return updateWidgetData(
          widget,
          filter,
          getSpendingPieAndBar(referentialCurrency, categoriesHierarchy, widget.variant),
          title,
        )
      }

      case WidgetId.CARDINALITY_SPENDING_CHART_CARD: {
        const title = getNatureOfSpendingHeader(widget.variant)

        return updateWidgetData(
          widget,
          filter,
          getSpendingByNature(referentialCurrency),
          title,
        )
      }

      default: {
        return updateWidgetData(
          widget,
          filter,
          (data) => data,
        )
      }
    }
  }).reduce(reduceBy('id'), {})
}

function updateWidgetData(
  widget: WidgetType,
  dashboardFilter: FilterType,
  updateDataFunction: Function,
  titleOverride?: string,
): WidgetView {
  return {
    ...widget,
    title: titleOverride || `dashboard.widget.${widget.widgetId}.header`,
    filter: { ...dashboardFilter, ...widget.filter },
    data: updateDataFunction(widget.data),
  }
}
