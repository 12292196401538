import spected from 'spected'
import * as commonValidator from 'src/backend/common/validator'
import omit from 'lodash/omit'
import { LabelFormValues } from 'src/frontend/scenes/settings/labels/types'
import { Id } from 'src/types/CouchDb'
import _isEmpty from 'lodash/isEmpty'
import { WalletFormValidationError } from 'src/backend/errors'

export function validate(formValues: Partial<LabelFormValues> & { _id?: Id }, validationDependencies) {
  const {
    labels,
    // colors,
  } = validationDependencies

  const requiredFields = [
    'name',
    'color',
  ]

  const valuesWithRequiredFields = {
    ...commonValidator.addRequiredFieldsAsUndefined(formValues, requiredFields),
  }

  const spec = {
    name: [
      [commonValidator.isNotEmpty, 'form.error.enter_name'],
      [commonValidator.hasUniqueName(omit(labels, formValues._id)), 'form.error.name_exists'],
      [commonValidator.isNotLongerThan(commonValidator.NAME_MAX_LENGTH), 'form.error.name_too_long'],
    ],
    // fixme Temporarily disabled
    // color: [
    //   [commonValidator.valueExists(colors), 'settings.labels.form.invalid_color_selected'],
    // ],
  }

  const validationResult = spected(spec, valuesWithRequiredFields)
  const errors = commonValidator.convertValidationResultToErrors(validationResult)
  if (!_isEmpty(errors)) {
    throw new WalletFormValidationError(errors)
  }
}
