import * as actions from './actions'

export function currencies(state = {}, action) {
  switch (action.type) {
    case actions.CURRENCIES_RECEIVE: {
      return { ...action.payload }
    }
    default:
      return state
  }
}

const rootReducer = currencies

export default rootReducer
