import React from 'react'
import AnimatedBarTable from 'src/frontend/components/AnimatedBarTable/AnimatedBarTable'
import { AnimatedBarTableData } from 'src/frontend/components/AnimatedBarTable/types'
import { FormattedMessage, FormattedMoney } from 'src/frontend/modules/intl/index'
import { WidgetView } from 'src/frontend/scenes/dashboard/types'
import { Currency } from 'src/types/Currency'
import Widget from 'src/frontend/scenes/dashboard/components/Widget/Widget'
import { CashFlowBarColors } from 'src/frontend/scenes/dashboard/constants'
import { widgetHasDeepEqualProps } from 'src/frontend/scenes/dashboard/helpers'

type CashFlowBarData = {
  refIncome: number,
  refIncomeChange: number,
  refExpense: number,
  refExpenseChange: number,
  refCashFlow: number,
  refCashFlowChange: number,
  referentialCurrency: Currency,
}

type Props = {
  widget: WidgetView & { data: CashFlowBarData },
}

function CashFlowBar({ widget }: Props) {
  const { data, filter, options } = widget
  if (!data || !filter) {
    return <Widget.Placeholder />
  }

  const tableData = convertCashFlowToBarTableData(data as CashFlowBarData)


  const totalValue = Math.max(data.refExpense, data.refIncome) || 1

  return (
    <Widget>
      <Widget.Header title={widget.title} />
      <Widget.Summary
        displayWholeDateRange
        period={filter.period}
        value={data.refCashFlow}
        currencyCode={data.referentialCurrency.code}
        intervalType={options.relativeIntervalValue}
        changeValue={data.refCashFlowChange}
      />
      <Widget.Content>
        <AnimatedBarTable
          // if both values are 0, then 1 is used as total, to show bar as 0% of both values
          total={totalValue}
          data={tableData}
        />
      </Widget.Content>
    </Widget>
  )
}

function convertCashFlowToBarTableData(data: CashFlowBarData): Array<AnimatedBarTableData> {
  const { refIncome, refIncomeChange, refExpense, refExpenseChange, referentialCurrency } = data
  return [
    {
      id: 'income',
      values: { value: refIncome, color: CashFlowBarColors.DARK_GREEN },
      valueChange: refIncomeChange,
      invertedChange: false,
      label: <FormattedMessage id="income" />,
      valueLabel: <FormattedMoney value={refIncome} currency={referentialCurrency.code} />,
    },
    {
      id: 'expense',
      values: { value: refExpense, color: CashFlowBarColors.DARK_RED },
      valueChange: refExpenseChange,
      invertedChange: true,
      label: <FormattedMessage id="expense" />,
      valueLabel: <FormattedMoney value={-refExpense} currency={referentialCurrency.code} />,
    },
  ]
}


export default React.memo(CashFlowBar, widgetHasDeepEqualProps)
