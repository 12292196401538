import * as accountRepository from 'src/backend/accounts/repository'
import {
  addWidgetIdToOrder,
  createWidget,
  fetchAllWidgetsWithData,
  getDashboardValues,
  removeWidgetFromWidgetOrder,
  mergeWidgetsByOrder,
  saveWidgetsToDashboardConfig,
  removeWidgetFromHash,
} from 'src/backend/webConfig/service'
import { WidgetId } from 'src/backend/dashboard/enums'
import { intervalTypeToPeriod } from 'src/backend/time/time'
import {
  selectDashboardFilter,
  selectDashboardWidgets, selectDashboardWidgetsOrder,
} from 'src/frontend/scenes/dashboard/selectors'
import {
  AbstractWidget,
  AccountsTotals,
  WidgetType,
  WidgetVariant,
} from 'src/frontend/scenes/dashboard/types'
import { Action, GetState, HashMap } from 'src/types/common'
import { FilterType, Period } from 'src/types/Filter'
import { Id } from 'src/types/CouchDb'
import { captureException } from 'src/common/logger'
import { User } from 'src/types/User'
import { selectUser } from 'src/frontend/modules/user/selectors'
import * as mixpanel from 'src/common/mixpanel'
import { expireDashboardStatus } from 'src/frontend/modules/moduleStatus/actions'
import { mapAbstractWidgetToFetchWidgetData } from 'src/backend/webConfig/helpers'

export const DASHBOARD_CHANGE_FILTER = 'DASHBOARD_CHANGE_FILTER'
export const DASHBOARD_RECEIVE_WIDGETS = 'DASHBOARD_RECEIVE_WIDGETS'
export const DASHBOARD_RECEIVE_WIDGET = 'DASHBOARD_RECEIVE_WIDGET'
export const DASHBOARD_RECEIVE_ACCOUNTS = 'DASHBOARD_RECEIVE_ACCOUNTS'

export const DASHBOARD_RECEIVE_ORDER = 'DASHBOARD_RECEIVE_ORDER'

export function initDashboard() {
  return async (dispatch: Function) => {

    const { interval, widgetsOrder, widgets } = await getDashboardValues()
    const { start, end } = intervalTypeToPeriod(interval)

    const period = {
      interval,
      start,
      end,
    }

    dispatch(receiveDashboardWidgets(widgets))
    dispatch(receiveDashboardOrder(widgetsOrder))
    const accountIds = await accountRepository.findAllNonExcludedAsIds()

    dispatch(setDashboardFilterValues({
      period,
      accountIds,
    }))
  }
}

export function changePeriod(period: Period) {
  return async (dispatch: Function) => {
    dispatch(changeFilter({ period }))
  }
}

export function setAllAccountsFilter() {
  return async (dispatch: Function) => {
    const accountIds = await accountRepository.findAllNonExcludedAsIds()
    dispatch(changeFilter({ accountIds }))
  }
}

export function addAccountsToFilter(newAccountIds: Id[]) {
  return async (dispatch: Function, getState: GetState) => {
    const { accountIds: currentAccountIds } = selectDashboardFilter(getState())
    const accountIds = [...currentAccountIds, ...newAccountIds]
    return dispatch(changeFilter({ accountIds }))
  }
}

export function setDashboardFilterValues(filter: FilterType) {
  return (dispatch: Function) => {
    dispatch({
      type: DASHBOARD_CHANGE_FILTER,
      payload: filter,
    })
  }
}

export function changeFilter(filter: FilterType) {
  return (dispatch: Function) => {
    dispatch({
      type: DASHBOARD_CHANGE_FILTER,
      payload: filter,
    })
    dispatch(expireDashboardStatus())
  }
}

export function receiveDashboardAccountsTotals(
  accountsTotals: HashMap<AccountsTotals>,
): Action<HashMap<AccountsTotals>> {
  return {
    type: DASHBOARD_RECEIVE_ACCOUNTS,
    payload: accountsTotals,
  }
}

export function receiveDashboardWidgets(
  widgets: HashMap<WidgetType | AbstractWidget>,
): Action<HashMap<WidgetType | AbstractWidget>> {
  return {
    type: DASHBOARD_RECEIVE_WIDGETS,
    payload: widgets,
  }
}

export function receiveDashboardOrder(widgetOrder: Id[]): Action<Id[]> {
  return {
    type: DASHBOARD_RECEIVE_ORDER,
    payload: widgetOrder,
  }
}

export function receiveDashboardWidget(
  updatedWidget: WidgetType | AbstractWidget,
): Action<WidgetType | AbstractWidget> {
  return {
    type: DASHBOARD_RECEIVE_WIDGET,
    payload: updatedWidget,
  }
}

export function fetchDashboardWidgetsWithData() {
  return async (dispatch: Function, getState: Function) => {
    // FIXME: performance test
    const timeStart = performance.now()
    const dashboardFilter: FilterType = selectDashboardFilter(getState())

    try {
      const { widgets, widgetsOrder } = await getDashboardValues()

      // dispatch new order
      dispatch(receiveDashboardOrder(widgetsOrder))

      const widgetsWithData: HashMap<WidgetType> = await fetchAllWidgetsWithData(widgets, dashboardFilter)
      setTimeout(async () => {
        dispatch(receiveDashboardWidgets(widgetsWithData))
      }, 80)
    } catch (error) {
      captureException(error, 'dashboard.fetchDashboardWidgetsWithData')
    }

    // FIXME: performance test
    console.log('fetchDashboardWidgetsWithData TIME ELAPSED', (performance.now() - timeStart) / 1000, 'seconds')
  }
}

export function reorderWidgets(widgetIds: Id[]) {
  return async (dispatch: Function, getState: GetState) => {
    const widgetsHashMap = selectDashboardWidgets(getState())
    const user: User = selectUser(getState())

    try {
      // dispatch new order
      dispatch(receiveDashboardOrder(widgetIds))

      // save order to the config
      const updatedWidgetsConfig: WidgetType[] = mergeWidgetsByOrder(widgetsHashMap, widgetIds)
      saveWidgetsToDashboardConfig(updatedWidgetsConfig, user)
    } catch (error) {
      captureException(error, 'dashboard.reorderWidgets')
    }
  }
}

export function addWidget(widgetId: WidgetId, variant?: WidgetVariant) {
  return async (dispatch: Function, getState: GetState) => {
    try {
      const widgetOrder: Id[] = selectDashboardWidgetsOrder(getState())
      const user: User = selectUser(getState())
      const dashboardFilter: FilterType = selectDashboardFilter(getState())

      const widget = createWidget(widgetId, variant)
      const updatedWidgetOrder = addWidgetIdToOrder(widgetOrder, widget.id)
      dispatch(receiveDashboardOrder(updatedWidgetOrder))
      dispatch(receiveDashboardWidget(widget))
      const widgets = selectDashboardWidgets(getState())

      setTimeout(async () => {
        const widgetData = await mapAbstractWidgetToFetchWidgetData(dashboardFilter)(widget)
        dispatch(receiveDashboardWidget({ ...widget, data: widgetData }))
      }, 0)

      const updatedWidgetsConfig = mergeWidgetsByOrder(widgets, updatedWidgetOrder)
      saveWidgetsToDashboardConfig(updatedWidgetsConfig, user)

      mixpanel.trackAddWidget({ widgetId: WidgetId[widgetId] })
    } catch (error) {
      captureException(error, 'dashboard.addWidget', { widgetId })
      console.error(error)
    }
  }
}

export function removeWidget(idToRemove: Id) {
  return async (dispatch: Function, getState: GetState) => {
    const widgets: HashMap<WidgetType> = selectDashboardWidgets(getState())
    const widgetsOrder: Id[] = selectDashboardWidgetsOrder(getState())
    const user: User = selectUser(getState())

    try {
      const updatedWidgetOrder = removeWidgetFromWidgetOrder(widgetsOrder, idToRemove)
      const updatedWidgets = removeWidgetFromHash(widgets, idToRemove)

      dispatch(receiveDashboardWidgets(updatedWidgets))
      dispatch(receiveDashboardOrder(updatedWidgetOrder))


      const updatedWidgetsConfig = mergeWidgetsByOrder(updatedWidgets, updatedWidgetOrder)
      saveWidgetsToDashboardConfig(updatedWidgetsConfig, user)
      mixpanel.trackRemoveWidget({ widgetId: WidgetId[widgets[idToRemove].widgetId] })
    } catch (error) {
      captureException(error, 'dashboard.removeWidget')
    }
  }
}

