import { Interval, IntervalGranularityType } from "src/backend/enums"
import { ViewDefinition } from "src/frontend/scenes/analytics/types"
import { FilterType } from "src/types/Filter"
import { ChartType } from "src/frontend/components/chart/ChartType"
import { Dataset, DatasetItem } from "src/frontend/components/chart/types"
import { Category, CategoryDocument, SuperEnvelope } from "src/types/Category"
import { sortByLocalizedCategoryName } from "src/frontend/modules/categories/helpers"
import { ReportData, ReportRawData } from "src/frontend/scenes/analytics/Report/types"
import { ChartSubjectType } from "src/frontend/scenes/analytics/enums"
import AnalyticsChart from "src/frontend/scenes/analytics/Charts/components/AnalyticsChart"
import CashFlowReportContent from "src/frontend/scenes/analytics/Report/components/CashFlowReportContent"
import IncomesExpensesReportContent from "src/frontend/scenes/analytics/Report/components/IncomesExpensesReportContent"
import OperatingProfitReportContent from "src/frontend/scenes/analytics/Report/components/OperatingProfitReportContent"

export function updateViewDefinitionByFilter(
  viewDefinition: ViewDefinition,
  filter: FilterType,
): ViewDefinition {
  const { DAY, WEEK, MONTH, ALL } = IntervalGranularityType
  const { granularity } = viewDefinition
  const { period } = filter

  if (Interval.MONTH === period.interval && granularity === MONTH) {
    return {
      ...viewDefinition,
      granularity: WEEK,
    }
  }

  if (Interval.WEEK === period.interval && granularity !== DAY && granularity !== ALL) {
    return {
      ...viewDefinition,
      granularity: DAY,
    }
  }

  return viewDefinition
}

export function convertData(
  chartType: string,
  data: Array<Dataset & { [key: string]: any }>,
  onlyPositive?: boolean,
): Array<Dataset & { [key: string]: any }> {
  switch (chartType) {
    case ChartType.PIE:
      return convertDataForPieChart(data)
    case ChartType.LINE:
    case ChartType.BAR:
      return convertDataForLineChart(data, onlyPositive)
    default:
      throw new Error(`Unsupported chart type: ${chartType}`)
  }
}

export function convertDataForLineChart(
  inputData: Array<Dataset>,
  onlyPositive?: boolean,
): Array<Dataset> {
  return inputData.map((dataset: Dataset): Dataset => {
    return {
      ...dataset,
      data: dataset.data.map((d: DatasetItem): number => {
        return onlyPositive ? Math.abs(d.y) : d.y
      }),
    }
  })
}

function filterEmptyData(datasets: Array<Dataset>): Array<Dataset> {
  return datasets.filter((dataset: Dataset, index: number): boolean => {
    if (dataset.data.length > 1) {
      throw new Error(
        `Invalid dataset[${index}] for pie chart, must be 0 or 1 sized but is ${dataset.data.length}`,
      )
    }
    return !!dataset.data[0].y
  })
}

export function convertDataForPieChart(
  datasets: Array<Dataset & { [key: string]: any }>,
): Array<Dataset & { [key: string]: any }> {
  const filteredDatasets: Array<Dataset> = filterEmptyData(datasets)
  const result: Dataset = {
    data: filteredDatasets.map((dataset: Dataset): number => dataset.data[0].y),
    backgroundColor: convertBackgroundColorForPieChart(datasets),
    type: ChartType.PIE,
  }
  return [result]
}

export function convertBackgroundColorForPieChart(
  datasets: Array<Dataset>,
): Array<Array<string> | string> {
  return filterEmptyData(datasets).map(
    (dataset: Dataset): Array<string> | string => dataset.backgroundColor as string,
  )
}

export function reduceSubcategoriesToReport(
  subcategories: CategoryDocument[],
  currentPeriod: ReportRawData,
  previousPeriod: ReportRawData,
) {
  return subcategories.reduce(
    (subcategoryAcc, subcategoryValue: CategoryDocument) => {
      const refIncome = currentPeriod[subcategoryValue._id]
        ? currentPeriod[subcategoryValue._id].refIncome
        : 0

      const refExpense = currentPeriod[subcategoryValue._id]
        ? currentPeriod[subcategoryValue._id].refExpense
        : 0

      const previousPeriodRefIncome = previousPeriod[subcategoryValue._id]
        ? previousPeriod[subcategoryValue._id].refIncome
        : 0

      const previousPeriodRefExpense = previousPeriod[subcategoryValue._id]
        ? previousPeriod[subcategoryValue._id].refExpense
        : 0

      return {
        subcategories: [
          ...subcategoryAcc.subcategories,
          {
            ...subcategoryValue,
            refIncome,
            refExpense,
            previousPeriodRefIncome,
            previousPeriodRefExpense,
          },
        ],
        subcategoriesRefIncome: subcategoryAcc.subcategoriesRefIncome + refIncome,
        subcategoriesRefExpense: subcategoryAcc.subcategoriesRefExpense + refExpense,
        subcategoriesPreviousPeriodRefIncome:
          subcategoryAcc.subcategoriesPreviousPeriodRefIncome + previousPeriodRefIncome,
        subcategoriesPreviousPeriodRefExpense:
          subcategoryAcc.subcategoriesPreviousPeriodRefExpense + previousPeriodRefExpense,
      }
    },
    {
      subcategories: [],
      subcategoriesRefIncome: 0,
      subcategoriesPreviousPeriodRefIncome: 0,
      subcategoriesRefExpense: 0,
      subcategoriesPreviousPeriodRefExpense: 0,
    },
  )
}

export function reduceEnvelopesToReport(
  envelopes: Category[],
  currentPeriod: ReportRawData,
  previousPeriod: ReportRawData,
) {
  return envelopes.sort(sortByLocalizedCategoryName).reduce(
    (envelopeAcc, envelopeValue: Category) => {
      const {
        subcategories,
        subcategoriesRefIncome,
        subcategoriesRefExpense,
        subcategoriesPreviousPeriodRefIncome,
        subcategoriesPreviousPeriodRefExpense,
      } = reduceSubcategoriesToReport(envelopeValue.subcategories, currentPeriod, previousPeriod)

      const refIncome = currentPeriod[envelopeValue._id]
        ? currentPeriod[envelopeValue._id].refIncome
        : 0

      const refExpense = currentPeriod[envelopeValue._id]
        ? currentPeriod[envelopeValue._id].refExpense
        : 0

      const previousPeriodRefIncome = previousPeriod[envelopeValue._id]
        ? previousPeriod[envelopeValue._id].refIncome
        : 0

      const previousPeriodRefExpense = previousPeriod[envelopeValue._id]
        ? previousPeriod[envelopeValue._id].refExpense
        : 0

      return {
        envelopes: [
          ...envelopeAcc.envelopes,
          {
            ...envelopeValue,
            subcategories,
            refIncome: refIncome + subcategoriesRefIncome,
            refExpense: refExpense + subcategoriesRefExpense,
            previousPeriodRefIncome: previousPeriodRefIncome + subcategoriesPreviousPeriodRefIncome,
            previousPeriodRefExpense:
              previousPeriodRefExpense + subcategoriesPreviousPeriodRefExpense,
          },
        ],
        envelopesRefIncome: envelopeAcc.envelopesRefIncome + refIncome + subcategoriesRefIncome,
        envelopesRefExpense: envelopeAcc.envelopesRefExpense + refExpense + subcategoriesRefExpense,
        envelopesPreviousPeriodRefIncome:
          envelopeAcc.envelopesPreviousPeriodRefIncome +
          previousPeriodRefIncome +
          subcategoriesPreviousPeriodRefIncome,
        envelopesPreviousPeriodRefExpense:
          envelopeAcc.envelopesPreviousPeriodRefExpense +
          previousPeriodRefExpense +
          subcategoriesPreviousPeriodRefExpense,
      }
    },
    {
      envelopes: [],
      envelopesRefIncome: 0,
      envelopesPreviousPeriodRefIncome: 0,
      envelopesRefExpense: 0,
      envelopesPreviousPeriodRefExpense: 0,
    },
  )
}

export function reduceSuperEnvelopesToReport(
  superEnvelopes: SuperEnvelope[],
  currentPeriod: ReportRawData,
  previousPeriod: ReportRawData,
): ReportData {
  return superEnvelopes.reduce(
    (superEnvelopeAcc, superEnvelopeValue: SuperEnvelope): ReportData => {
      const {
        envelopes,
        envelopesRefIncome,
        envelopesRefExpense,
        envelopesPreviousPeriodRefIncome,
        envelopesPreviousPeriodRefExpense,
      } = reduceEnvelopesToReport(superEnvelopeValue.envelopes, currentPeriod, previousPeriod)

      return {
        reportsStructure: [
          ...superEnvelopeAcc.reportsStructure,
          {
            ...superEnvelopeValue,
            envelopes,
            refIncome: envelopesRefIncome,
            refExpense: envelopesRefExpense,
            previousPeriodRefIncome: envelopesPreviousPeriodRefIncome,
            previousPeriodRefExpense: envelopesPreviousPeriodRefExpense,
          },
        ],
        refIncome: superEnvelopeAcc.refIncome + envelopesRefIncome,
        refExpense: superEnvelopeAcc.refExpense + envelopesRefExpense,
        previousPeriodRefIncome:
          superEnvelopeAcc.previousPeriodRefIncome + envelopesPreviousPeriodRefIncome,
        previousPeriodRefExpense:
          superEnvelopeAcc.previousPeriodRefExpense + envelopesPreviousPeriodRefExpense,
      }
    },
    {
      reportsStructure: [],
      refIncome: 0,
      refExpense: 0,
      previousPeriodRefIncome: 0,
      previousPeriodRefExpense: 0,
    },
  )
}

export function getReportComponent(subject: ChartSubjectType) {
  switch (subject) {
    case ChartSubjectType.BOARD_CASH_FLOW_REPORT: {
      return CashFlowReportContent
    }

    case ChartSubjectType.OPERATING_PROFIT_REPORT: {
      return OperatingProfitReportContent
    }

    case ChartSubjectType.INCOMES_EXPENSES_REPORT: {
      return IncomesExpensesReportContent
    }

    default:
    case ChartSubjectType.BALANCE:
    case ChartSubjectType.CASH_FLOW:
    case ChartSubjectType.CUMULATIVE_CASH_FLOW:
    case ChartSubjectType.CUMULATIVE_EXPENSE:
    case ChartSubjectType.CUMULATIVE_INCOME:
    case ChartSubjectType.EXPENSE:
    case ChartSubjectType.INCOME: {
      return AnalyticsChart
    }
  }
}
