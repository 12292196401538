import React from 'react'
import styles from 'src/frontend/components/chart/Legend.module.less'
import { Dataset } from 'src/frontend/components/chart/types'
import LegendIcon from 'src/frontend/components/chart/LegendIcon'

type Props = {
  datasets: Array<Dataset>,
}

export default function Legend({ datasets }: Props) {
  const shouldHideLegend = datasets.some((dataset) => dataset.hideLegend)
  const shouldReverseLegend = datasets.some((dataset) => dataset.reverseLegend)

  return !shouldHideLegend
    ? (
      <ul className={styles.chartLegend}>
        {(shouldReverseLegend ? [...datasets].reverse() : datasets)
          .filter((dataset) => dataset.label)
          .map(dataset => {
            return (
              <li key={dataset.label}>
                <LegendIcon dataset={dataset} />
                {dataset.label}
              </li>
            )
          })}
      </ul>
    )
    : null
}
