import { SpendingByNatureVariant } from 'src/frontend/scenes/dashboard/widgets/SpendingByNatureBars/enums'

export function getNatureOfSpendingHeader(variant: SpendingByNatureVariant): string {
  switch (variant) {
    default:
    case SpendingByNatureVariant.SPENDING:
      return 'dashboard.widget.nature-of-spending.header'


    case SpendingByNatureVariant.COSTS:
      return 'dashboard.widget.money-spent-by-type.header'
  }
}
