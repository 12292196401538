/* eslint-disable react/prop-types */
/* eslint-disable no-shadow */
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import MappingAmountColumnsPanel from 'src/frontend/scenes/imports/mapping/components/MappingAmountColumnsPanel'
import MappingDateColumnPanel from 'src/frontend/scenes/imports/mapping/components/MappingDateColumnPanel'
import MappingErrorPanel from 'src/frontend/scenes/imports/mapping/components/MappingErrorPanel'
import MappingOptionalColumnsPanel from 'src/frontend/scenes/imports/mapping/components/MappingOptionalColumnsPanel'
import MappingRowHeaderPanel from 'src/frontend/scenes/imports/mapping/components/MappingRowHeaderPanel'
import WizardProgressBar from 'src/frontend/scenes/imports/mapping/components/WizardProgressBar'

import {
  close, showPreview, wizardGoNext, wizardGoPrev,
} from 'src/frontend/scenes/imports/mapping/actions'
import {
  selectWizardCurrentStep, selectWizardGoNextEnabled, selectWizardGoPrevEnabled,
  selectWizardShowPreviewEnabled,
} from 'src/frontend/scenes/imports/mapping/selectors'
import { WizardSteps } from 'src/frontend/scenes/imports/mapping/constants'
import { selectSelectedAccountName } from 'src/frontend/scenes/imports/account/selectors'
import './MappingWizard.less'
import { FormattedMessage } from 'src/frontend/modules/intl'
import { PrimaryButton, SecondaryButton } from 'src/frontend/components/Buttons/Buttons'

const mapDispatchToProps = {
  cancel: close,
  showPreview,
  wizardGoNext,
  wizardGoPrev,
}

const mapStateToProps = state => ({
  accountName: selectSelectedAccountName(state),
  currentStep: selectWizardCurrentStep(state),
  goNextEnabled: selectWizardGoNextEnabled(state),
  goPrevEnabled: selectWizardGoPrevEnabled(state),
  showPreviewEnabled: selectWizardShowPreviewEnabled(state),
})

const MappingWizard = ({
  accountName, currentStep, goNextEnabled, goPrevEnabled, showPreviewEnabled, cancel, wizardGoNext, wizardGoPrev,
  showPreview,
}) => (
  <div className="mapping-wizard">
    <div className="header tour-guide_imports-mapping_1">
      <div className="title"><FormattedMessage id="imports.mapping.wizard.title" /></div>
      <div className="account-name">{accountName}</div>
    </div>

    <WizardProgressBar />

    <div>{currentStep === WizardSteps.CHOOSE_HEADER_AND_ROWS && <MappingRowHeaderPanel />}</div>
    <div>{currentStep === WizardSteps.CHOOSE_AMOUNT && <MappingAmountColumnsPanel />}</div>
    <div>{currentStep === WizardSteps.CHOOSE_DATE && <MappingDateColumnPanel />}</div>
    <div>{currentStep === WizardSteps.CHOOSE_OPTIONAL && <MappingOptionalColumnsPanel />}</div>

    <div className="errors">
      <MappingErrorPanel />
    </div>

    <div className="buttons">
      <div className="step-buttons">
        {goPrevEnabled && (
          <SecondaryButton fluid onClick={wizardGoPrev}>
            <FormattedMessage id="imports.mapping.wizard.prev_button" />
          </SecondaryButton>
        )}
        {goNextEnabled && (
          <PrimaryButton fluid onClick={wizardGoNext}>
            <FormattedMessage id="imports.mapping.wizard.next_button" />
          </PrimaryButton>
        )}
        {showPreviewEnabled && (
          <PrimaryButton fluid onClick={showPreview}>
            <FormattedMessage id="imports.mapping.wizard.preview_button" />
          </PrimaryButton>
        )}
      </div>
      <div>
        <SecondaryButton color="red" fluid onClick={cancel}>
          <FormattedMessage id="form.cancel" />
        </SecondaryButton>
      </div>
    </div>
  </div>
)

MappingWizard.propTypes = {
  accountName: PropTypes.string.isRequired,
  currentStep: PropTypes.string.isRequired,
  goNextEnabled: PropTypes.bool.isRequired,
  goPrevEnabled: PropTypes.bool.isRequired,
  showPreviewEnabled: PropTypes.bool.isRequired,
  wizardGoNext: PropTypes.func.isRequired,
  wizardGoPrev: PropTypes.func.isRequired,
  showPreview: PropTypes.func.isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(MappingWizard)
