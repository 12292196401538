import React from 'react'
import { getColorByIndex } from 'src/backend/colors/colors'
import AnimatedBarTable from 'src/frontend/components/AnimatedBarTable/AnimatedBarTable'
import { FormattedMoney } from 'src/frontend/modules/intl/index'
import Widget from 'src/frontend/scenes/dashboard/components/Widget/Widget'
import { WidgetView } from 'src/frontend/scenes/dashboard/types'
import { BalanceByCurrencyWidget } from 'src/frontend/scenes/dashboard/widgets/BalanceByCurrencyBar/types'
import { AnimatedBarTableData } from 'src/frontend/components/AnimatedBarTable/types'
import { widgetHasDeepEqualProps } from 'src/frontend/scenes/dashboard/helpers'
import styles from './BalanceByCurrenciesBar.module.less'
import { Modal } from 'semantic-ui-react'
import FormattedMessage from 'src/frontend/modules/intl/components/FormattedMessage'
import { ComplementaryButton } from 'src/frontend/components/Buttons/Buttons'


const NUM_OF_CURRENCIES = 4

type Props = {
  widget: WidgetView & { data: Array<BalanceByCurrencyWidget> },
}

function BalanceByCurrencyBar({ widget }: Props) {
  const { data, filter } = widget

  if (!data || !filter) {
    return <Widget.Placeholder />
  }

  const widgetAction = data.length > NUM_OF_CURRENCIES && (
    <Modal
      size="small"
      trigger={(
        <ComplementaryButton>
          <FormattedMessage id="show-more" />
        </ComplementaryButton>
      )}
    >
      <Modal.Content>
        <AnimatedBarTable
          total={data[0].refBalance}
          data={convertCurrenciesToBarTableData(data)}
        />
      </Modal.Content>
    </Modal>
  )

  const sampleData = React.useMemo(() => data.slice(0, NUM_OF_CURRENCIES), [data])

  const content = (data.length > 0)
    ? (
      <Widget.Content>
        <div className={styles.balanceByCurrencies}>
          <AnimatedBarTable
            total={sampleData[0].refBalance}
            data={convertCurrenciesToBarTableData(sampleData)}
          />
        </div>
      </Widget.Content>
    )
    : <Widget.EmptyContent type="bar" />

  return (
    <Widget>
      <Widget.Header title={widget.title} action={widgetAction} />
      {content}
    </Widget>
  )
}

function convertCurrenciesToBarTableData(data: Array<BalanceByCurrencyWidget>): Array<AnimatedBarTableData> {
  return data.map((currency: BalanceByCurrencyWidget, index: number) => {
    return {
      id: currency.currencyId,
      values: { value: currency.refBalance, color: getColorByIndex(index) },
      valueChange: null,
      invertedChange: true,
      label: currency.name,
      valueLabel: <FormattedMoney value={currency.balance} currency={currency.code} />,
    }
  })
}

export default React.memo(BalanceByCurrencyBar, widgetHasDeepEqualProps)
