import * as actions from './actions'
import { CategoryDocument } from 'src/types/Category'
import { HashMap } from 'src/types/common'

export type CategoriesState = HashMap<CategoryDocument>

export default function categories(state: CategoriesState = {}, action) {
  switch (action.type) {
    case actions.CATEGORIES_RECEIVE: {
      return { ...action.payload }
    }
    case actions.SAVE_CATEGORY_SUCCESS: {
      return { ...state, [action.payload._id]: action.payload }
    }
    default:
      return state
  }
}
