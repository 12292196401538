import React, { useEffect } from 'react'
import { Button, Form, Message } from 'semantic-ui-react'
import { connect } from 'react-redux'
import { RootState } from 'src/types/State'
import {
  selectAlreadyConnectedProviders,
  selectProvidersList,
} from 'src/frontend/scenes/integrations/providersList/selectors'
import {
  changeSelectedCountry,
  changeSelectedProvider, chooseBankProvider,
  fetchProviderCountries,
} from 'src/frontend/scenes/integrations/providersList/actions'
import { getCountryName } from 'src/backend/countries/countries'
import { compareStrings, filterLatinizedOptions } from 'src/common/utils'
import {
  IntegrationProviders,
} from 'src/frontend/scenes/integrations/providersList/types'
import IntegrationProvider = IntegrationProviders.IntegrationProvider
import IntegrationConnectedProvider = IntegrationProviders.IntegrationConnectedProvider
import {
  clearConnectionError,
  createAlreadyConnectedProvider,
} from 'src/frontend/scenes/integrations/newConnection/actions'
import _isEmpty from 'lodash/isEmpty'
import styles from 'src/frontend/scenes/integrations/providersList/components/CountryAndProviderSearch.module.less'
import {
  selectAccountsLoading, selectIntegrationError,
  selectPendingConnectionProvider,
} from 'src/frontend/scenes/integrations/newConnection/selectors'
import ConnectedProviderStatus = IntegrationProviders.ConnectedProviderStatus
import { getUserFeatureValue, isKBUser } from 'src/backend/user/service'
import { Feature } from 'src/backend/user/billingFeatures'
import { selectUser } from 'src/frontend/modules/user/selectors'
import { User } from 'src/types/User'
import PremiumChecker from 'src/frontend/components/PremiumChecker/PremiumChecker'
import PictureIcon from 'src/frontend/components/PictureIcon/PictureIcon'
import { FormattedMessage, FormattedMessageLabel } from 'src/frontend/modules/intl'
import { formatMessage } from 'src/frontend/modules/intl/i18n'
import { IntegrationConnection } from 'src/frontend/scenes/integrations/newConnection/types'
import { PrimaryButton } from 'src/frontend/components/Buttons/Buttons'
import { showKBIntegration } from 'src/frontend/scenes/integrations/providersList/kb/actions'
import { KB_PROVIDER_CODE } from 'src/backend/integrations/kb/constants'
import { isAppBoard } from 'src/common/environment'

interface Props {
  countries: string[]
  countriesLoading: boolean
  providersLoading: boolean
  providerDetailLoading: boolean
  selectedCountry: string
  selectedProviderCode: string
  countryAvailableProviders: IntegrationProvider[]
  alreadyConnectedProviders: IntegrationConnectedProvider[]
  error: string
  pendingConnectionProvider: IntegrationProvider
  remoteAccountsLoading: boolean
  fetchCountries: Function
  changeProvider: Function
  user: User,
  changeCountry: Function
  submitForm: Function
  submitWithKB: Function
  submitAlreadyConnected: Function
  clearError: Function
}

function mapStateToProps(state: RootState) {
  const {
    countries,
    selectedCountry,
    selectedProviderCode,
    countryAvailableProviders,
    countriesLoading,
    providersLoading,
    providerDetailLoading,
  } = selectProvidersList(state)

  return {
    countries,
    selectedCountry,
    selectedProviderCode,
    countryAvailableProviders,
    countriesLoading,
    providersLoading,
    providerDetailLoading,
    error: selectIntegrationError(state),
    remoteAccountsLoading: selectAccountsLoading(state),
    pendingConnectionProvider: selectPendingConnectionProvider(state),
    alreadyConnectedProviders: selectAlreadyConnectedProviders(state),
    user: selectUser(state),
  }
}

const mapDispatchToProps = {
  fetchCountries: fetchProviderCountries,
  changeCountry: changeSelectedCountry,
  changeProvider: changeSelectedProvider,
  submitForm: chooseBankProvider,
  submitAlreadyConnected: createAlreadyConnectedProvider,
  submitWithKB: showKBIntegration,
  clearError: clearConnectionError,
}

function isSelectedFreeProvider(providers: IntegrationProvider[], code: string): boolean {
  return !!providers.find(provider => provider.code === code && !provider.needPremiumToConnect)
}

function CountryAndProviderSearch({
  countries,
  countriesLoading,
  providersLoading,
  providerDetailLoading,
  selectedCountry,
  error,
  selectedProviderCode,
  countryAvailableProviders,
  alreadyConnectedProviders,
  remoteAccountsLoading,
  pendingConnectionProvider,
  user,
  fetchCountries,
  changeCountry,
  changeProvider,
  submitForm,
  submitWithKB,
  submitAlreadyConnected,
  clearError,
}: Props) {
  useEffect(() => {
    fetchCountries()
  }, [])

  const countryOptions = countries.map((countryCode) => ({
    key: countryCode,
    text: getCountryName(countryCode),
    value: countryCode,
  })).sort((a, b) => compareStrings(a.text, b.text))

  const providerOptions = countryAvailableProviders.map((provider: IntegrationProvider) => {
    return {
      key: provider.code,
      text: provider.name,
      className: styles.bankSelectItem,
      value: provider.code,
      description: provider.supportedAccountTypes && provider.supportedAccountTypes
        .map((type) => formatMessage(`integrations.supported-account.${IntegrationConnection.Type[type]}`))
        .join(', '),
    }
  }).sort((a, b) => compareStrings(a.text, b.text))

  const canConnectIntegrations = !!getUserFeatureValue(user, Feature.INTEGRATIONS)
    || isSelectedFreeProvider(countryAvailableProviders, selectedProviderCode)

  return (
    <Form className={styles.newIntegrationCountryProvider}>
      <PictureIcon name="bank" />
      <Message error visible={!!error}><FormattedMessage id={error} /></Message>
      <Form.Dropdown
        label={<FormattedMessageLabel id="integrations.newConnection.select-country" />}
        placeholder={formatMessage('integrations.newConnection.select-country')}
        search={filterLatinizedOptions}
        selection
        options={countryOptions}
        value={selectedCountry}
        loading={countriesLoading}
        onChange={(_e, { value }) => changeCountry(value)}
      />
      <Form.Dropdown
        label={<FormattedMessageLabel id="integrations.newConnection.select-bank" />}
        placeholder={formatMessage('integrations.newConnection.select-bank.placeholder')}
        search={filterLatinizedOptions}
        selection
        options={providerOptions}
        value={selectedProviderCode}
        loading={providersLoading}
        onChange={(_e, { value }) => changeProvider(value)}
      />
      <PremiumChecker
        enabled={canConnectIntegrations}
        featureName="premium_checker.bank-syncs"
      >
        <PrimaryButton
          fluid
          type="submit"
          disabled={providerDetailLoading || !selectedProviderCode || remoteAccountsLoading}
          loading={providerDetailLoading}
          onClick={() => {
            clearError()
            submitForm()
          }}
        >
          <FormattedMessage id="form.continue" />
        </PrimaryButton>
      </PremiumChecker>

      {(!_isEmpty(alreadyConnectedProviders) || (isAppBoard() && isKBUser(user))) && (
        <div className={styles.alreadyConnected}>
          <div className={styles.alreadyConnectedText}>
            <p><FormattedMessage id="integrations.newConnection.or" /></p>
            {!_isEmpty(alreadyConnectedProviders) && (
              <p><FormattedMessage id="integrations.newConnection.select-connected-bank" /></p>
            )}
          </div>
          {!_isEmpty(alreadyConnectedProviders) && (
            <Button.Group vertical fluid compact>
              {alreadyConnectedProviders.map((connectedProvider) => {
                const isLoading = remoteAccountsLoading && pendingConnectionProvider.id === connectedProvider.loginId
                return (
                  <PremiumChecker
                    key={connectedProvider.loginId}
                    enabled={canConnectIntegrations}
                    featureName="premium_checker.bank-syncs"
                  >
                    <Button
                      size="mini"
                      compact
                      basic
                      className={styles.alreadyConnectedButton}
                      disabled={remoteAccountsLoading
                      || connectedProvider.loginStatus !== ConnectedProviderStatus.ACTIVE}
                      loading={isLoading}
                      onClick={() => {
                        clearError()
                        submitAlreadyConnected(connectedProvider.loginId)
                      }}
                    >
                      {connectedProvider.name}
                    </Button>
                  </PremiumChecker>
                )
              })}
            </Button.Group>
          )}
          {isAppBoard()
          && isKBUser(user)
          && !alreadyConnectedProviders.some((provider) => provider.code === KB_PROVIDER_CODE)
          && (
            <Button
              className={styles.kbButton}
              fluid
              onClick={() => {
                clearError()
                submitWithKB()
              }}
            >
              <FormattedMessage id="kb.connect-button" />
            </Button>
          )}
        </div>
      )}
    </Form>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(CountryAndProviderSearch)
