import SocialLogin from "react-social-login"
import { Button } from "semantic-ui-react"
import React, { useCallback } from "react"
import { OAuthLoginMethod } from "src/types/User"

interface Props {
  loginEnabled: boolean
  onDisabledLoginClick?: Function
  triggerLogin: Function
  layout: OAuthLoginMethod
  children: React.ReactNode
}

OAuthButton.defaultProps = {
  onDisabledLoginClick: () => {},
}

function OAuthButton(props: Props) {
  const handleClick = useCallback(() => {
    return props.loginEnabled ? props.triggerLogin() : props.onDisabledLoginClick()
  }, [props.loginEnabled])

  return <Button onClick={handleClick}>{props.children}</Button>
}

export default SocialLogin(OAuthButton)
