import React from 'react'
import { Category, CategoryDocument } from 'src/types/Category'
import { CategoryLevel, FormType } from 'src/frontend/modules/categories/enums'
import { formatEnvelopeNameByCategory } from 'src/frontend/modules/categories/helpers'
import { FormattedMessage } from 'src/frontend/modules/intl'
import styles from 'src/frontend/scenes/settings/categories/components/CategoriesList.module.less'
import RemoveCategoryForm from 'src/frontend/scenes/settings/categories/components/RemoveCategoryForm'
import CategoryForm from 'src/frontend/scenes/settings/categories/components/CategoryForm'
import { mergeClasses } from 'src/common/utils'
import { CategoryIcon } from 'src/frontend/components/Icons/Icons'
import CategoriesListContainer from 'src/frontend/scenes/settings/categories/components/CategoriesList'
import { isAppBoard } from 'src/common/environment'


interface Props {
  subcategories: CategoryDocument[]
  level: CategoryLevel
  name: string
  category: Category
  parentCategoryName: string
  formOpen: boolean
  formType: FormType
  formCategory: Category
  onOpenForm: Function
  onCloseForm: Function
  onHideEnvelope: Function
}

export default function CategoriesListItem({
  subcategories,
  level,
  name,
  category,
  parentCategoryName,
  formOpen,
  formType,
  formCategory,
  onOpenForm,
  onCloseForm,
  onHideEnvelope,
}: Props) {


  const envelopeName = formatEnvelopeNameByCategory(category)
  const categoryName = category?.name

  const subcategoriesList = subcategories && (
    <CategoriesListContainer
      categoryLevel={CategoryLevel.SUBCATEGORY}
      parentCategoryName={categoryName}
      categoriesHierarchy={subcategories as Category[]}
    />
  )

  const toolbar = level === CategoryLevel.CATEGORY
    ? (
      <div>
        <span onClick={() => onHideEnvelope(category.envelopeId, !category.isHidden)}>
          <FormattedMessage id={category.isHidden ? 'show' : 'hide'} />
        </span>
        {!isAppBoard() && (
          <span onClick={() => onOpenForm(category, FormType.RENAME)}>
            <FormattedMessage id="edit" />
          </span>
        )}
        {!category.isGeneral && (
          <span onClick={() => onOpenForm(category, FormType.ADD)}>
            <FormattedMessage id="settings.categories.add_category" />
          </span>
        )}
      </div>
    )
    : (
      <div>
        <span onClick={() => onOpenForm(category, FormType.EDIT)}>
          <FormattedMessage id="edit" />
        </span>
        <span className={styles.delete}>
          <RemoveCategoryForm envelopeName={parentCategoryName} category={category} />
        </span>
      </div>
    )

  const isThisCategoryForm = formCategory && formCategory._id === category._id
  const isCategory = level === CategoryLevel.CATEGORY

  const editOrRenameForm = isThisCategoryForm && formOpen && formType !== FormType.ADD
    && <CategoryForm envelopeName={envelopeName} categoryName={name} category={category} />

  const addForm = isThisCategoryForm && formOpen && isCategory && formType === FormType.ADD
    && <CategoryForm categoryName={name} category={category} />

  const closeOnClickOutside = formOpen && !isThisCategoryForm
    ? e => onCloseForm(e)
    : undefined

  const isHiddenForm = isThisCategoryForm && formOpen && formType !== FormType.ADD
  const isHiddenClass = category.isHidden && styles.dimmed

  return (
    <li>
      {!isHiddenForm && (
        <div onClick={closeOnClickOutside} className={mergeClasses(styles.item, category.isGeneral && styles.general)}>
          <div className={mergeClasses(styles.columnIcon, isHiddenClass)}>
            <CategoryIcon color={category.color} icon={category.iconName} />
          </div>
          <div className={mergeClasses(styles.columnCategory, isHiddenClass)}>
            <div className={styles.categoryName}>
              {name}
            </div>
            {/*<div className={styles.categorySubtitle}>*/}
            {/*  {category.customName && envelopeName}*/}
            {/*</div>*/}
          </div>
          <div className={styles.columnToolbar}>
            {toolbar}
          </div>
        </div>
      )}
      {addForm}
      {editOrRenameForm}
      {subcategoriesList}
    </li>
  )
}
