import React from "react"
import regularHeaderStyles from "src/frontend/scenes/accounts/accountDetail/components/AccountDetailHeader.module.less"
import { formatMessage } from "src/frontend/modules/intl/i18n"
import { mergeClasses } from "src/common/utils"
import { Account } from "src/types/Account"
import { AccountIcon } from "src/frontend/components/Icons/Icons"
import FormattedMessage from "src/frontend/modules/intl/components/FormattedMessage"
import TruncatedText from "src/frontend/components/TruncatedText"
import { RestrictedProviders } from "src/frontend/scenes/integrations/restrictedProviders/types"
import RestrictedProvider = RestrictedProviders.RestrictedProvider
import BankRestrictionColumn from "src/frontend/scenes/accounts/accountDetail/components/BankRestrictionColumn"
import { FormattedMoney } from "src/frontend/modules/intl"
import { useSelector } from "react-redux"
import { RootState } from "src/types/State"
import AccountLastUpdate from "./AccountLastUpdate"

interface Props {
  account: Account
  restrictedProviders: RestrictedProvider[]
  availableCash: number
  portfolioBalance: number
}

export default function AccountDetailInvestmentsHeader({
  account,
  restrictedProviders,
  availableCash,
  portfolioBalance,
}: Props) {
  const integrationConnection = account.reservedIntegrationConnection
  const currencies = useSelector((state: RootState) => state.modules.currencies)

  const restrictedProviderObject = React.useMemo(() => {
    if (integrationConnection) {
      return restrictedProviders.find((restrictedProvider) => {
        return restrictedProvider.loginIds.includes(integrationConnection.loginId)
      })
    }
  }, [account, restrictedProviders])

  return (
    <div className={regularHeaderStyles.header}>
      <div className={regularHeaderStyles.avatar}>
        <AccountIcon
          accountType={account.accountType}
          isInvestments={true}
          color={account.color}
          size="6rem"
          borderRadius="8px"
        />
      </div>

      <div className={regularHeaderStyles.detailColumn}>
        <div className={regularHeaderStyles.detailItem}>
          <div className={regularHeaderStyles.title}>
            <FormattedMessage id="form.name" />
          </div>
          <div className={regularHeaderStyles.value}>
            {account.name ? (
              <TruncatedText
                text={account.name}
                length={35}
                showPopup
              />
            ) : (
              formatMessage("contacts.detail.value.none")
            )}
          </div>
        </div>
        <div className={regularHeaderStyles.detailItem}>
          <div className={regularHeaderStyles.title}>
            <FormattedMessage id="form.type" />
          </div>
          <div className={regularHeaderStyles.value}>
            {formatMessage(`account.type.${account.accountType}`)}
          </div>
        </div>
      </div>

      <div className={regularHeaderStyles.detailColumn}>
        <div className={regularHeaderStyles.detailItem}>
          <div className={regularHeaderStyles.title}>
            <FormattedMessage id="account.account_balance" />
          </div>
          <div className={mergeClasses(regularHeaderStyles.value, regularHeaderStyles.larger)}>
            <FormattedMoney
              value={availableCash + portfolioBalance}
              currency={currencies[account.currencyId].code}
            />
          </div>
        </div>
        <div className={regularHeaderStyles.detailColumnChildren}>
          <div className={regularHeaderStyles.detailItem}>
            <div className={regularHeaderStyles.title}>
              <FormattedMessage id="availableCash" />
            </div>
            <div className={regularHeaderStyles.value}>
              <FormattedMoney
                value={availableCash}
                currency={currencies[account.currencyId].code}
              />
            </div>
          </div>
          <div className={regularHeaderStyles.detailItem}>
            <div className={regularHeaderStyles.title}>
              <FormattedMessage id="portfolioBalance" />
            </div>
            <div className={regularHeaderStyles.value}>
              <FormattedMoney
                value={portfolioBalance}
                currency={currencies[account.currencyId].code}
              />
            </div>
          </div>
        </div>
      </div>

      <div
        style={{ marginLeft: "10rem" }}
        className={regularHeaderStyles.detailColumn}
      >
        <div className={regularHeaderStyles.detailItem}>
          <div className={regularHeaderStyles.title}>
            <FormattedMessage id="settings.accounts.table.last_update" />
          </div>
          <div className={regularHeaderStyles.value}>
            <AccountLastUpdate account={account} />
          </div>
        </div>
      </div>

      <BankRestrictionColumn
        account={account}
        restrictedProviderObject={restrictedProviderObject}
      />
    </div>
  )
}
