import React, { useCallback, useEffect, useMemo, useState } from "react"
import { connect, useSelector } from "react-redux"
import { Waypoint } from "react-waypoint"
import { COUNT_INCREMENT } from "src/frontend/scenes/records/recordList/constants"
import { RootState } from "src/types/State"
import AccountsListItem from "src/frontend/scenes/accounts/accountsList/components/AccountsListItem"
import {
  selectAccountListAccounts,
  selectOrderBy,
} from "src/frontend/scenes/accounts/accountsList/selectors"
import { AccountsListOrderBy } from "src/frontend/scenes/accounts/accountsList/enums"
import { selectShouldRefreshAccounts } from "src/frontend/modules/moduleStatus/selectors"
import { refreshAccountsStatus } from "src/frontend/modules/moduleStatus/actions"
import panelStyles from "src/frontend/components/ColumnLayout/PanelList.module.less"

interface Props {
  order: AccountsListOrderBy
  shouldRefreshAccounts: boolean
  filterInvestmentsAccountsOnly?: boolean
  refreshAccounts: () => void
}

function mapStateToProps(state: RootState) {
  return {
    order: selectOrderBy(state),
    shouldRefreshAccounts: selectShouldRefreshAccounts(state),
  }
}

const mapDispatchToProps = {
  refreshAccounts: refreshAccountsStatus,
}

function AccountsList({
  shouldRefreshAccounts,
  filterInvestmentsAccountsOnly = false,
  refreshAccounts,
}: Props) {
  const allAccounts = useSelector(selectAccountListAccounts)

  useEffect(() => {
    if (shouldRefreshAccounts) {
      refreshAccounts()
    }
  }, [shouldRefreshAccounts])

  const [visibleAccountsCount, setVisibleAccountsCount] = useState<number>(COUNT_INCREMENT)

  const onWaypointEnter = useCallback(() => {
    const newOffset = visibleAccountsCount + COUNT_INCREMENT
    setVisibleAccountsCount(newOffset)
  }, [allAccounts])

  const items = useMemo(() => {
    const accounts = filterInvestmentsAccountsOnly
      ? allAccounts.filter((account) => account?.investmentInfo)
      : allAccounts
    return accounts.slice(0, visibleAccountsCount).map((account) => {
      return (
        <AccountsListItem
          key={account._id}
          account={account}
          urlParent={filterInvestmentsAccountsOnly ? "investments" : "accounts"}
        />
      )
    })
  }, [allAccounts, visibleAccountsCount])

  return (
    <div className={panelStyles.list}>
      {items}
      <Waypoint
        onEnter={onWaypointEnter}
        bottomOffset="-50px"
      />
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountsList)
