import * as actions from './actions'
import { Action } from 'src/types/common'
import { BILLING_MODAL_CLOSE } from 'src/frontend/scenes/billing/billingModal/actions'

export interface BillingPaymentMethodState {
  clientToken: string
  loading: boolean
  tokenLoading: boolean
  error: string
  success: boolean
}

const initialPaymentMethodState: BillingPaymentMethodState = {
  clientToken: null,
  loading: false,
  tokenLoading: false,
  error: null,
  success: false,
}

function paymentMethod(
  state: BillingPaymentMethodState = initialPaymentMethodState,
  action: Action,
): BillingPaymentMethodState {
  switch (action.type) {

    case actions.GET_TOKEN_SUCCESS: {
      return { ...state, clientToken: action.payload, tokenLoading: false }
    }

    case actions.GET_TOKEN_START: {
      return { ...state, tokenLoading: true, success: false }
    }

    case actions.GET_TOKEN_ERROR: {
      return { ...state, tokenLoading: false }
    }

    case actions.UPDATE_PAYMENT_METHOD_START:
    case actions.BUY_PLAN_START: {
      return { ...state, loading: true, error: null }
    }

    case actions.UPDATE_PAYMENT_METHOD_SUCCESS:
    case actions.BUY_PLAN_END: {
      return {
        ...state,
        loading: false,
        success: true,
      }
    }

    case actions.BUY_PLAN_EXPIRE_PRODUCT:
    case BILLING_MODAL_CLOSE:
    case actions.CLEAR_TOKEN: {
      return initialPaymentMethodState
    }

    case actions.UPDATE_PAYMENT_METHOD_ERROR:
    case actions.BUY_PLAN_ERROR: {
      return { ...state, loading: false, error: action.payload, success: false }
    }

    default:
      return state
  }
}

export default paymentMethod
