import React from 'react'
import styles from './EmptyState.module.less'
import FormattedMessage from 'src/frontend/modules/intl/components/FormattedMessage'
import { formatMessage } from 'src/frontend/modules/intl'
import PictureIcon from 'src/frontend/components/PictureIcon/PictureIcon'
import { PictureIconNames } from 'src/frontend/components/PictureIcon/types'

interface Props {
  title: string | React.ReactNode
  text: string | React.ReactNode
  icon?: PictureIconNames
  hideAddNotice?: boolean
}

EmptyState.defaultProps = {
  icon: undefined,
  hideAddNotice: false,
}

export default function EmptyState({ title, text, icon, hideAddNotice }: Props) {
  return (
    <div className={styles.emptyState}>
      <div>
        {icon && <PictureIcon name={icon} />}
        <div className={styles.title}>{title}</div>
        <div className={styles.text}>{text}</div>
        {!hideAddNotice && (
          <div className={styles.text}>
            <FormattedMessage
              id="empty-state.create-first"
              values={{ addButton: `(＋ ${formatMessage('form.add')})` }}
            />
          </div>
        )}
      </div>
    </div>
  )
}
