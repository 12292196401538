import PropTypes from 'prop-types'
import React from 'react'
import Files from 'react-files'
import { connect } from 'react-redux'
import { FormattedMessage } from 'src/frontend/modules/intl'
import { uploadFiles, showFilesUploadError } from '../actions'
import './FileDropZone.less'
import { PrimaryButton } from 'src/frontend/components/Buttons/Buttons'

const mapDispatchToProps = dispatch => ({
  handleFilesChanged: files => dispatch(uploadFiles(files)),
  handleFilesChangedError: error => dispatch(showFilesUploadError(error)),
})

class DropZone extends React.Component {

  static buildFilesChangeHandler(handleFilesChanged) {
    // need to return function because of calling removeFiles() on Files component
    return function handleFilesInternal(files) {
      // removeFiles() reset state and therefore onChange callback is called.
      // Need to handle files changed only when some files are added and ignore changes when files are removed.
      if (files.length > 0) {
        handleFilesChanged(files)
        // removeFiles is internal method of Files. We need to remove files so that maxFiles condition is met
        this.removeFiles()
      }
    }
  }

  render() {
    return (
      <Files
        className="files-dropzone tour-guide_imports-main_2"
        onChange={DropZone.buildFilesChangeHandler(this.props.handleFilesChanged)}
        onError={this.props.handleFilesChangedError}
        // tempoary disabled because client on Windows can't detect file type properly
        // update: cannot reproduce but it cannot properly set file type in Open file dialog,
        // both on Windows, Linux
        // accepts={[
        //   'text/csv',
        //   'application/vnd.ms-excel',
        //   'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        // ]
        multiple={false}
        maxFiles={1}
        maxFileSize={10000000}
        minFileSize={0}
        clickable
      >
        <div>
          <PrimaryButton fluid>
            <FormattedMessage id="imports.dropzone.choose_file" />
          </PrimaryButton>
        </div>
        <div className="helper-text">
          <p><FormattedMessage id="imports.dropzone.or" /></p>
          <p><FormattedMessage id="imports.dropzone.drop_here" /></p>
        </div>
      </Files>
    )
  }
}

DropZone.propTypes = {
  handleFilesChanged: PropTypes.func.isRequired,
  handleFilesChangedError: PropTypes.func.isRequired,
}

export default connect(() => ({}), mapDispatchToProps)(DropZone)
