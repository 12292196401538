import { HashMap } from 'src/types/common'
import { Step } from 'react-joyride'

export const TourGuideType = {
  IMPORTS_MAIN: 'IMPORTS_MAIN',
  IMPORTS_MAPPING: 'IMPORTS_MAPPING',
}

export const TourGuideSteps: HashMap<Step[]> = {
  [TourGuideType.IMPORTS_MAIN]: [
    {
      title: 'tour_guide.steps.imports_main.1.title',
      content: 'tour_guide.steps.imports_main.1.text',
      target: '.tour-guide_imports-main_1',
      placement: 'bottom',
    },
    {
      title: 'tour_guide.steps.imports_main.2.title',
      content: 'tour_guide.steps.imports_main.2.text',
      target: '.tour-guide_imports-main_2',
    },
  ],

  [TourGuideType.IMPORTS_MAPPING]: [
    {
      title: 'tour_guide.steps.imports_mapping.1.title',
      content: 'tour_guide.steps.imports_mapping.1.text',
      target: '.tour-guide_imports-mapping_1',
      placement: 'bottom',
    },
  ],
}
