import { fetchWithRetry } from 'src/backend/rest/helpers'
import { getPlacesApiClientId, getPlacesApiClientSecret, getPlacesApiUrl } from 'src/common/environment'

export function fetchPlaces(location: { latitude: number, longitude: number}) {
  return (query: string) => {
    const CLIENT_ID = getPlacesApiClientId()
    const CLIENT_SECRET = getPlacesApiClientSecret()

    const locationParameters = location ? `ll=${location.latitude},${location.longitude}&radius=100000` : 'intent=global'
    const queryParams = `query=${query}&${locationParameters}&client_id=${CLIENT_ID}&client_secret=${CLIENT_SECRET}&v=20170817`

    return fetchWithRetry(`${getPlacesApiUrl()}/v2/venues/search?${queryParams}`)
      .then(response => response.json())
      .then(json => {
        const statusCode = json.meta.code

        if (statusCode === 200) {
          return json.response.venues.map(venue => {
            const value = {
              id: venue.id,
              name: venue.name,
              ...venue.location,
            }
            return { key: venue.id, value, title: venue.name, description: venue.location.address }
          })
        }

        return []
      })
  }
}
