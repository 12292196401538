export const CLEAR_ERROR = 'errorModals/CLEAR_ERROR'
export const SHOW_ERROR = 'errorModals/SHOW_ERROR'
export const DEFAULT_ERROR_MESSAGE_ID = 'app.error.default_error_message'

export function clearError() {
  return ({ type: CLEAR_ERROR })
}

export function showErrorModal(errorMessageId?: string | string[], errorRecoverable: boolean = true) {
  return (dispatch: Function) => {

    if (errorMessageId) {
      dispatch({
        type: SHOW_ERROR,
        errorMessageId,
        errorRecoverable,
      })
    } else {
      dispatch({
        type: SHOW_ERROR,
        errorMessageId: DEFAULT_ERROR_MESSAGE_ID,
        errorRecoverable,
      })
    }
  }
}
