import { ChartDataSets } from 'chart.js'

/**
 * Shared ChartJS dataset option, common for all components (dashboard, analytics)
 */

export const DATASET_DEFAULT_OPTIONS: ChartDataSets = {
  borderWidth: 3,
  pointRadius: 0,
  pointHitRadius: 10,
  lineTension: 0.3,
  borderJoinStyle: 'round',
  cubicInterpolationMode: 'monotone',
}

export const COMPARISON_DATASET_DEFAULT_OPTIONS = {
  ...DATASET_DEFAULT_OPTIONS,
  pointHitRadius: 1,
}
