import React, { useEffect } from "react"
import { Modal } from "semantic-ui-react"
import { RootState } from "src/types/State"
import { connect } from "react-redux"
import { selectProviderDetail } from "src/frontend/scenes/integrations/providersList/selectors"
import {
  selectIntegrationError,
  selectIntegrationModalOpen,
  selectIntegrationStatus,
} from "src/frontend/scenes/integrations/newConnection/selectors"
import { IntegrationProviders } from "src/frontend/scenes/integrations/providersList/types"
import {
  cancelPendingConnection,
  closeIntegrationsForm,
} from "src/frontend/scenes/integrations/newConnection/actions"
import { NewIntegrationsStatus } from "src/frontend/scenes/integrations/enums"
import { FormattedMessage } from "src/frontend/modules/intl"
import { NewIntegrationContent } from "src/frontend/scenes/integrations/newConnection/components/NewIntegrationContent"
import IntegrationProviderDetail = IntegrationProviders.IntegrationProviderDetail
import styles from "src/frontend/scenes/integrations/newConnection/components/NewBankConnectionModal.module.less"

interface Props {
  providerDetail: IntegrationProviderDetail
  integrationStatus: NewIntegrationsStatus
  hasError: boolean
  open: boolean
  handleCloseForm: Function
  handleCancelConnection: (MouseEvent) => void
}

function mapStateToProps(state: RootState) {
  return {
    integrationStatus: selectIntegrationStatus(state),
    providerDetail: selectProviderDetail(state),
    hasError: !!selectIntegrationError(state),
    open: selectIntegrationModalOpen(state),
  }
}

const mapDispatchToProps = {
  handleCancelConnection: cancelPendingConnection,
  handleCloseForm: closeIntegrationsForm,
}

function NewBankConnectionModal({
  providerDetail,
  open,
  integrationStatus,
  hasError,
  handleCloseForm,
  handleCancelConnection,
}: Props) {
  useEffect(() => {
    console.log("NewBankConnectionModal - Modal open prop: ", open)
  }, [open])
  useEffect(() => {
    console.log("NewBankConnectionModal - Modal integrationStatus prop: ", integrationStatus)
  }, [integrationStatus])
  const isAccountSelect = integrationStatus === NewIntegrationsStatus.INTEGRATED_ACCOUNT_SELECT
  return (
    <Modal
      size="small"
      open={open}
      className="integrations-modal"
      onClose={() => !isAccountSelect && handleCloseForm()}
    >
      <Modal.Header>
        {getModalHeader(integrationStatus, providerDetail)}
        {!isAccountSelect && (
          <span
            className="modal-close"
            onClick={() => handleCloseForm()}
          />
        )}
        {isAccountSelect && (
          <span
            className="modal-close"
            onClick={handleCancelConnection}
          />
        )}
      </Modal.Header>
      <Modal.Content className={styles.modalContent}>
        <NewIntegrationContent integrationStatus={integrationStatus} />
      </Modal.Content>
      {integrationStatus !== NewIntegrationsStatus.PROVIDER_SELECT &&
        integrationStatus !== NewIntegrationsStatus.PERSONAL_INFORMATION && (
          <div className={styles.modalFooter}>
            <p className={styles.footerTitle}>
              <strong>
                <FormattedMessage id="integrations.newConnection.security.title" />
              </strong>
            </p>
            <p>
              <FormattedMessage id="integrations.newConnection.security.text" />
            </p>
          </div>
        )}
    </Modal>
  )
}

function getModalHeader(
  integrationStatus: NewIntegrationsStatus,
  providerDetail: IntegrationProviderDetail,
) {
  if (providerDetail) {
    return providerDetail.name
  }

  return integrationStatus === NewIntegrationsStatus.PERSONAL_INFORMATION ? (
    <FormattedMessage id="integrations.newConnection.personal-information.header" />
  ) : (
    <FormattedMessage id="integrations.newConnection.search-bank.header" />
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(NewBankConnectionModal)
