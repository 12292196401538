import * as React from 'react'
import { connect } from 'react-redux'
import styles from "src/frontend/components/Filter/Filter.module.less"
import { RootState } from 'src/types/State'
import {
  selectAccountsFilter,
  selectAccountsFilterDisplayOption,
} from 'src/frontend/scenes/accounts/filter/selectors'
import { AccountFilterType } from "src/frontend/scenes/accounts/filter/types"
import { setFilterValues, toggleDisplayFilter } from 'src/frontend/scenes/accounts/filter/actions'
import SearchFilter from 'src/frontend/components/Filter/SearchFilter'

const mapStateToProps = (state: RootState) => {
  return {
    filter: selectAccountsFilter(state),
    display: selectAccountsFilterDisplayOption(state),
    options: {},
  }
}

const mapDispatchToProps = (dispatch: Function) => {
  return {
    handleChangeFilter: (name, value) => dispatch(setFilterValues({ [name]: value })),
    handleChangeMultipleFilter: (values) => dispatch(setFilterValues(values)),
    handleToggleDisplayFilter: (name) => dispatch(toggleDisplayFilter(name)),
  }
}

export type Props = {
  filter: AccountFilterType
  handleChangeFilter: Function
  disabled?: boolean
}

function AccountsFilter({ filter, handleChangeFilter, disabled = false }: Props) {
  return (
    filter && (
      <div className={styles.filters}>
        <SearchFilter
          defaultValue={filter.fulltext}
          disabled={disabled}
          onChange={(_e, { value }) => handleChangeFilter("fulltext", value)}
        />
      </div>
    )
  )
}


export default connect(mapStateToProps, mapDispatchToProps)(AccountsFilter)
