import React from 'react'
import { generateIntervalString, generateEndOfIntervalString } from 'src/backend/time/time'
import { FormattedMessage, FormattedMoney, FormattedPercentageChange } from 'src/frontend/modules/intl'
import { withIntlConsumer } from 'src/frontend/modules/intl/components/withIntlConsumer'
import { Period } from 'src/types/Filter'
import styles from 'src/frontend/scenes/dashboard/components/Widget/Widget.module.less'
import { RelativeIntervalType } from 'src/backend/enums'
import { isUndefinedOrNull, mergeClasses } from 'src/common/utils'

type Props = {
  period?: Period,
  value?: number,
  currencyCode?: string,
  changeValue?: number,
  invertedChange?: boolean,
  // if true, widget data is valid in the whole range, if false, data is valid to the end date
  displayWholeDateRange?: boolean,
  locale?: string,
  intervalType: RelativeIntervalType,
}

Summary.defaultProps = {
  period: {},
  changeValue: null,
  value: undefined,
  invertedChange: false,
  displayWholeDateRange: false,
}

function Summary({
  period,
  value,
  currencyCode,
  changeValue,
  displayWholeDateRange,
  invertedChange,
  locale,
  intervalType,
}: Props) {

  return (
    <div className={styles.summary}>
      <div className={mergeClasses(styles.row, styles.description)}>
        {period
        && (
          <div className={styles.date}>
            {displayWholeDateRange
              ? generateIntervalString(period, locale, false, true, intervalType)
              : generateEndOfIntervalString(period, locale)}
          </div>
        )}
        {!isUndefinedOrNull(changeValue) && (
          <div className={styles.vsPrevious}><FormattedMessage id="period-vs-previous" /></div>
        )}
      </div>
      <div className={mergeClasses(styles.row, styles.kpi)}>
        {!isUndefinedOrNull(value) && !isUndefinedOrNull(currencyCode) && (
          <div className={styles.value}><FormattedMoney value={value} currency={currencyCode} /></div>
        )}
        {!isUndefinedOrNull(changeValue) && (
          <div className={styles.change}>
            <FormattedPercentageChange change={changeValue} inverted={invertedChange} />
          </div>
        )}
      </div>
    </div>
  )
}

export default withIntlConsumer(Summary)
