import React from 'react'
import { Form } from 'semantic-ui-react'
import { IntegrationProviders } from 'src/frontend/scenes/integrations/providersList/types'
import IntegrationRecipeParam = IntegrationProviders.IntegrationRecipe.IntegrationRecipeParam

interface Props {
  param: IntegrationRecipeParam
  value?: boolean
  onChange: (field: string, value: boolean) => void
}

MFAFormCheckbox.defaultProps = {
  value: false,
}

export default function MFAFormCheckbox({ param, value, onChange }: Props) {
  return (
    <Form.Checkbox
      name={param.id}
      label={param.label}
      disabled={param.notEditable}
      checked={value}
      onChange={(_e, { name, checked }) => onChange(name, checked)}
    />
  )
}
