import { Transition } from 'semantic-ui-react'
import React, { useMemo } from 'react'
import DetailLayout from 'src/frontend/components/ColumnLayout/DetailLayout'
import { RootState } from 'src/types/State'
import { connect } from 'react-redux'
import { selectSortedContacts } from 'src/frontend/modules/contacts/selectors'
import { Contact } from 'src/types/Contact'
import { Redirect, RouteComponentProps } from 'react-router'
import ContactDetailContent from 'src/frontend/scenes/contacts/contactDetail/components/ContactDetailContent'
import RemoveContactForm from 'src/frontend/scenes/contacts/contactForm/components/RemoveContactForm'
import { FormattedMessage } from 'src/frontend/modules/intl'
import { openEditContactForm } from 'src/frontend/scenes/contacts/contactForm/actions'
import { Id } from 'src/types/CouchDb'
import { SecondaryButton } from 'src/frontend/components/Buttons/Buttons'

function mapStateToProps(state: RootState) {
  return {
    contacts: selectSortedContacts(state),
  }
}

const mapDispatchToProps = {
  onEditClick: openEditContactForm,
}

interface Props extends RouteComponentProps<{ contactId: Id }> {
  contacts: Contact.ContactView[]
  onEditClick: (contactId: Id) => void
}

function ContactDetail({ contacts, history, match, onEditClick }: Props) {
  const contactId = match && match.params && match.params.contactId

  const contact = useMemo(() => contacts.find((contactView) => contactView._id === contactId), [contactId, contacts])

  if (!contact) {
    return (
      <Redirect to="/contacts" />
    )
  }

  return (
    <Transition transitionOnMount animation="fade" duration={200}>
      <DetailLayout
        history={history}
        title={contact.name}
        actions={(
          <>
            <SecondaryButton onClick={() => onEditClick(contact._id)}>
              <FormattedMessage id="edit" />
            </SecondaryButton>
            <RemoveContactForm
              contact={contact}
              trigger={(
                <SecondaryButton color="red">
                  <FormattedMessage id="delete" />
                </SecondaryButton>
              )}
            />
          </>
        )}
        content={<ContactDetailContent contact={contact} />}
      />
    </Transition>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactDetail)
