import { ConfigureKey } from 'src/backend/enums'
import { receiveHiddenEnvelopes } from 'src/frontend/modules/configures/hiddenEnvelopes/actions'
import { receiveTourGuides } from 'src/frontend/modules/configures/tourGuides/actions'
import * as repository from 'src/backend/configures/repository'
import { ConfigureDocument } from 'src/types/Configure'

export function applyConfigureObjects(configures: ConfigureDocument[]) {
  return (dispatch: Function) => {
    configures.forEach(configureObject => {
      switch (configureObject.key) {
        case ConfigureKey.WEBAPP_TOURGUIDE: {
          dispatch(receiveTourGuides(configureObject.value))
          break
        }
        case ConfigureKey.HIDDEN_ENVELOPES: {
          dispatch(receiveHiddenEnvelopes(configureObject.value))
          break
        }
        default:
      }
    })
  }
}

export function fetchConfigures() {
  return async (dispatch: Function) => {
    const configures = await repository.findAll()
    dispatch(applyConfigureObjects(configures))
  }
}
