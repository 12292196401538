import { Account } from 'src/types/Account'
import { isLessThan24Hours } from 'src/backend/time/time'
import moment from 'moment'
import { RestrictedProviders } from 'src/frontend/scenes/integrations/restrictedProviders/types'
import { isUndefinedOrNull } from 'src/common/utils'
import RestrictedProvider = RestrictedProviders.RestrictedProvider
import ProviderStatus = RestrictedProviders.ProviderStatus

export function getIntegrationIcon(account: Account, restrictedProviderObject?: RestrictedProvider) {
  if (account.reservedIntegrationConnection) {
    if (!account.reservedIntegrationConnection.isActive
      || !isUndefinedOrNull(restrictedProviderObject) && restrictedProviderObject.status !== ProviderStatus.Active) {
      return 'warning-red'
    }

    if (isLessThan24Hours(moment(account.reservedIntegrationConnection.lastSuccessRefresh))
      && isUndefinedOrNull(restrictedProviderObject)) {
      return 'checkmark'
    }

    return 'warning-orange'
  }
}
