import _sumBy from 'lodash/sumBy'
import { Totals } from 'src/backend/analytics/types'
import * as envelopes from 'src/backend/categories/envelopes'
import * as helpers from 'src/common/helpers'
import { Currency } from 'src/types/Currency'
import { isGeneralCategory } from 'src/frontend/modules/categories/helpers'
import { formatMessage } from 'src/frontend/modules/intl'

export function getSpendingByCategoriesBar(referentialCurrency: Currency, flattenedCategoriesHierarchy) {
  return (data: {
    previousPeriod: Totals,
    currentPeriod: Totals,
  }) => {
    if (!data) {
      return null
    }

    const { previousPeriod, currentPeriod } = data
    if (!previousPeriod || !currentPeriod || !referentialCurrency) {
      return null
    }

    const previousByCategoryValues: Array<Totals> = Object.values(previousPeriod)
    const byCategoryValues: Array<Totals> = Object.values(helpers.withChanges(
      previousPeriod,
      currentPeriod,
      ['refExpense'],
    ))

    if (byCategoryValues.length < 1) {
      return {
        referentialCurrency,
        spendingCategories: [],
      }
    }

    const expenseSum = _sumBy(byCategoryValues, 'refExpense') || 0
    const previousExpenseSum = _sumBy(previousByCategoryValues, 'refExpense') || 0
    const expenseChange = helpers.countPercentChange(previousExpenseSum, expenseSum)

    const UNASSIGNED_CATEGORY_NAME = 'unassigned'
    const UNASSIGNED_CATEGORY_COLOR = '#aaa'

    return {
      referentialCurrency,
      expenseSum,
      expenseChange,
      spendingCategories: byCategoryValues
        .filter(expenseCategory => expenseCategory.refExpense > 0)
        .sort((categoryA, categoryB) => categoryB.refExpense - categoryA.refExpense)
        .map<Totals>((expenseCategory) => {
          let name
          let color
          let categoryId

          const superEnvelope = flattenedCategoriesHierarchy.find((category) => {
            return category.superEnvelopeId === expenseCategory.superEnvelopeId && isGeneralCategory(category)
          })

          const envelope = envelopes.getEnvelopeById(expenseCategory.superEnvelopeId)
            || envelopes.getEnvelopeById(expenseCategory.envelopeId)

          if (superEnvelope) {
            name = superEnvelope?.customName ? superEnvelope.name : formatMessage(envelope.localizationString)
            color = superEnvelope?.color ?? envelope.color
            categoryId = superEnvelope?._id ?? envelope.id
          } else {
            name = UNASSIGNED_CATEGORY_NAME
            color = UNASSIGNED_CATEGORY_COLOR
            categoryId = UNASSIGNED_CATEGORY_NAME
          }

          return {
            ...expenseCategory,
            name,
            color,
            categoryId,
          }
        }),
    }
  }
}
