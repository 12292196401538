import { Action } from 'src/types/common'
import {
  ON_BOARDING_CHANGE_VALUE,
  ON_BOARDING_END,
  ON_BOARDING_FINISH_SCREEN,
  ON_BOARDING_GOTO_NEXT,
  SET_SHOULD_ADD_VOUCHER,
  ON_BOARDING_STAGE_SET_END,
  ON_BOARDING_STAGE_SET_START,
  ON_BOARDING_START,
  ON_BOARDING_TOGGLE_PREFER_BANK_INTEGRATION,
} from 'src/frontend/scenes/onBoarding/firstRun/actions'
import { OnBoardingStage } from 'src/frontend/scenes/onBoarding/firstRun/enums'
import { OnBoardingFormValues } from 'src/frontend/scenes/onBoarding/firstRun/types'
import { OnBoardingStatus } from 'src/backend/onBoarding/types'


export interface OnBoardingFirstRunState {
  formValues: OnBoardingFormValues,
  loading: boolean
  stage: OnBoardingStage
  onBoardingStatus: OnBoardingStatus,
  show: boolean
  preferBankIntegration: boolean,
  shouldAddVoucher: boolean,
  voucherCode?: string,
}

const initialState: OnBoardingFirstRunState = {
  formValues: {
    currencyCode: undefined,
    accountName: undefined,
    cashBalance: 0,
  },
  onBoardingStatus: null,
  loading: false,
  stage: null,
  show: false,
  preferBankIntegration: false,
  shouldAddVoucher: false,
  voucherCode: undefined,
}

export default function firstRun(
  state: OnBoardingFirstRunState = initialState,
  action: Action,
): OnBoardingFirstRunState {
  switch (action.type) {
    case ON_BOARDING_START: {
      return {
        ...state,
        show: true,
        stage: action.payload.stage,
        onBoardingStatus: action.payload.onBoardingStatus,
        formValues: { ...state.formValues, currencyCode: action.payload.currencyCode },
        preferBankIntegration: action.payload.preferBankIntegration,
      }
    }

    case ON_BOARDING_FINISH_SCREEN: {
      return { ...state, stage: OnBoardingStage.FINISHED_ONBOARDING }
    }

    case ON_BOARDING_END: {
      return initialState
    }

    case ON_BOARDING_GOTO_NEXT: {
      return {
        ...state,
        stage: action.payload.stage,
        onBoardingStatus: action.payload.onBoardingStatus,
      }
    }

    case ON_BOARDING_STAGE_SET_START: {
      return { ...state, loading: true }
    }

    case ON_BOARDING_STAGE_SET_END: {
      return { ...state, loading: false }
    }

    case ON_BOARDING_CHANGE_VALUE: {
      return { ...state, formValues: { ...state.formValues, [action.payload.name]: action.payload.value } }
    }

    case ON_BOARDING_TOGGLE_PREFER_BANK_INTEGRATION: {
      return {
        ...state,
        stage: action.payload.stage,
        onBoardingStatus: action.payload.onBoardingStatus,
        preferBankIntegration: !state.preferBankIntegration,
      }
    }

    case SET_SHOULD_ADD_VOUCHER: {
      return {
        ...state,
        shouldAddVoucher: true,
        voucherCode: action.payload.voucherCode,
      }
    }

    default: {
      return state
    }
  }
}
