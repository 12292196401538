import spected from 'spected'
import * as commonValidator from 'src/backend/common/validator'
import omit from 'lodash/omit'
import { FormType } from 'src/frontend/modules/categories/enums'

export function validate(category, validationDependencies, formType) {
  const {
    categories,
  } = validationDependencies

  const requiredFields = [
    'name',
  ]

  const categoryWithRequiredFields = {
    ...commonValidator.addRequiredFieldsAsUndefined(category, requiredFields),
  }

  const categorySpec = {
    name: [
      // eslint-disable-next-line max-len
      [commonValidator.conditionalValidation(formType !== FormType.RENAME, commonValidator.isNotEmpty), 'form.error.enter_name'],
      [commonValidator.hasUniqueName(omit(categories, category._id)), 'form.error.name_exists'],
      [commonValidator.isNotLongerThan(commonValidator.NAME_MAX_LENGTH), 'form.error.name_too_long'],
    ],
  }

  try {
    const validationResult = spected(categorySpec, categoryWithRequiredFields)
    return commonValidator.convertValidationResultToErrors(validationResult)
  } catch (error) {
    console.error(error)
    return { general: 'Validation error!' }
  }
}
