import { SystemCategoryType } from "src/backend/categories/enums"
import * as recordsService from "src/backend/records/service"
import * as repository from "./repository"
import { utcDateAsISOString } from "src/backend/time/time"
import { getDefaultCategory } from "./Category"
import { CategoryType } from "src/backend/enums"
import uuid from "uuid"
import { updateAuditData } from "src/backend/common/service"
import * as commonRepository from "src/backend/common/repository"
import { formatMessage, getDefaultMessages } from "src/frontend/modules/intl/i18n"
import { inMemoryTableNames } from "../db/inMemorySqlDbSchemaBuilder"
import { categoryConverter } from "src/backend/converters/categoryConverter"
import { getColors, isInColors } from "src/backend/colors/colors"
import { convertToCategoryEntity } from "src/backend/categories/converters"
import { parseIntDecimal } from "src/common/utils"
import { User } from "src/types/User"
import { Id } from "src/types/CouchDb"
import { AbstractEnvelope, Category, CategoryDocument } from "src/types/Category"
import { Record } from "src/types/Record"
import * as envelopes from "src/backend/categories/envelopes"
import { convertResultToEntity } from "src/backend/db/inMemorySqlDb"
import { findByCategoryId } from "../records/helpers"
import { defaultEnvelopeNames } from "./defaultEnvelopesNames"

export async function getCategoryByEnvelopeId(
  envelopeId: number,
  user: User,
  isSystemEnvelope?: boolean,
): Promise<CategoryDocument> {
  return (
    (await getEnvelopeCategory(envelopeId)) ||
    (await createCategoryFromEnvelope(envelopeId, user, isSystemEnvelope))
  )
}

export async function getCategoryByCategoryId(
  categoryId: Id | number,
  user: User,
): Promise<CategoryDocument> {
  if (!categoryId) {
    return getCategoryByEnvelopeId(envelopes.SYSTEM_CATEGORIES_UNKNOWN_ID, user)
  }

  return (
    (await getCategory(categoryId)) ||
    (await getEnvelopeCategory(categoryId as number)) ||
    createCategoryFromEnvelope(categoryId as number, user)
  )
}

export function getCategory(categoryId: Id | number): Promise<CategoryDocument> {
  return repository.findById(categoryId.toString())
}

function getEnvelopeCategory(envelopeId: number): Promise<CategoryDocument> {
  return repository.findCategoryForEnvelopeId(envelopeId)
}

export async function createCategoryFromEnvelope(
  envelopeId: number,
  user: User,
  isSystemEnvelope?: boolean,
): Promise<CategoryDocument> {
  const envelope = envelopes.getEnvelopeById(envelopeId)
  const systemCategory = isSystemEnvelope
    ? envelopes.getSystemCategoryTypeByEnvelopeId(envelopeId)
    : undefined
  const defaultCategory = getDefaultCategory(uuid.v4())
  const envelopeCategory = convertToEnvelopeCategory(envelope, systemCategory)(defaultCategory)
  const envelopeCategoryWithAuditData = updateAuditData(user, utcDateAsISOString)(envelopeCategory)

  const categories = await commonRepository.updateBulk(
    [envelopeCategoryWithAuditData],
    inMemoryTableNames.CATEGORY,
    categoryConverter(),
  )

  return categories[0] as CategoryDocument
}

export function createTransferCategory(user: User) {
  const transferSystemEnvelope = envelopes.getTransferSystemEnvelope()
  const systemEnvelope = envelopes.getSystemEnvelope()

  const transferProps = {
    color: systemEnvelope.color,
    envelopeId: parseIntDecimal(transferSystemEnvelope.id),
    name: getDefaultCategoryName(transferSystemEnvelope.localizationString),
    systemCategory: SystemCategoryType.TRANSFER,
  }

  const transferCategory = { ...getDefaultCategory(uuid.v4()), ...transferProps }
  const transferCategoryWithAuditData = updateAuditData(user, utcDateAsISOString)(transferCategory)

  return commonRepository
    .updateBulk([transferCategoryWithAuditData], inMemoryTableNames.CATEGORY, categoryConverter())
    .then(([category]) => category)
}

export async function createCustomCategoryFromEnvelope(
  envelopeId: number,
  categoryData: Category,
  user: User,
) {
  const envelope = envelopes.getEnvelopes().find(({ id }) => id === envelopeId)
  const defaultCategory = getDefaultCategory(uuid.v4())
  const envelopeCategory = convertToEnvelopeCategory(envelope)(defaultCategory)
  const envelopeCategoryWithAuditData = updateAuditData(user, utcDateAsISOString)(envelopeCategory)
  const customCategory = {
    ...envelopeCategoryWithAuditData,
    color: envelope.color,
    name: getDefaultCategoryName(envelope.localizationString),
    customCategory: true,
    ...categoryData,
  }
  return commonRepository
    .updateBulk([customCategory], inMemoryTableNames.CATEGORY, categoryConverter())
    .then(([category]) => category)
}

export async function updateCategory(categoryId: Id, formValues: Category) {
  const selectedCategory = await repository.findById(categoryId)
  const updatedCategory = convertToCategoryEntity({ ...selectedCategory, ...formValues })

  return commonRepository
    .updateBulk([updatedCategory], inMemoryTableNames.CATEGORY, categoryConverter())
    .then(([category]) => category)
}

export async function removeCategory(category: CategoryDocument, user: User) {
  const { envelopeId } = category
  const associatedRecords: Record[] = await findByCategoryId(category._id)

  if (associatedRecords.length > 0) {
    const parentCategory = await getCategoryByEnvelopeId(envelopeId, user)
    recordsService.assignRecordsIntoCategory(parentCategory, associatedRecords)
  }

  return commonRepository.removeBulk([convertToCategoryEntity(category)])
}

export async function renameCategory(categoryId: Id | number, formValues: Category, user: User) {
  const category = await getCategoryByCategoryId(categoryId, user)
  return setOrResetCategoryName(category, formValues)
}

function setOrResetCategoryName(category: Category, formValues: Category) {
  const envelope = envelopes.getEnvelopeById(category.envelopeId)
  const name = formValues.name || formatMessage(envelope.localizationString)
  const color = formValues.color || envelope.color
  const customName = !!formValues.name
  const customColor =
    !!formValues.color &&
    formValues.color !== envelope.color &&
    (category.color !== formValues.color || category.customColor) &&
    isInColors(formValues.color)
  const iconName =
    !!formValues.iconName && formValues.iconName !== envelope.iconName
      ? formValues.iconName
      : undefined
  const updatedCategory = convertToCategoryEntity({
    ...category,
    name,
    customName,
    color,
    customColor,
    iconName,
  })

  return commonRepository
    .updateBulk([updatedCategory], inMemoryTableNames.CATEGORY, categoryConverter())
    .then(convertResultToEntity())
}

export function convertToEnvelopeCategory(envelope: AbstractEnvelope, systemCategory?: number) {
  return (category: CategoryDocument): CategoryDocument => {
    return {
      ...category,
      systemCategory,
      name: getDefaultCategoryName(envelope.localizationString),
      defaultType: getEnvelopeType(envelope),
      envelopeId: envelope.id,
    }
  }
}

export function getEnvelopeType(envelope: AbstractEnvelope) {
  return envelope && envelopes.incomeSuperEnvelopeIds.includes(envelope.parentId || envelope.id)
    ? CategoryType.INCOME
    : CategoryType.EXPENSE
}

export async function getTransferCategory(): Promise<CategoryDocument> {
  const transferCategoryByEnvelopeId = await repository.getTransferCategoryByEnvelopeId(
    envelopes.SYSTEM_CATEGORIES_TRANSFER_ID,
  )

  if (transferCategoryByEnvelopeId) {
    return transferCategoryByEnvelopeId
  }

  return repository.getTransferCategoryBySystemCategoryType(SystemCategoryType.TRANSFER)
}

export function fetchCategories(): Promise<CategoryDocument[]> {
  return repository.getAllCategories()
}

export async function getValidationDependencies() {
  const categories = await repository.findAllAsHashMap()
  const colors = getColors()
  return {
    categories,
    colors,
  }
}

function getDefaultCategoryName(localizationString: string): string {
  const message = defaultEnvelopeNames[localizationString]
  return typeof message === "string" ? message : ""
}