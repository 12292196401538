import React from 'react'
import { Divider, Modal, Transition } from 'semantic-ui-react'
import PictureIcon from 'src/frontend/components/PictureIcon/PictureIcon'
import { RootState } from 'src/types/State'
import {
  selectGoodJobText,
  selectGoodJobValues,
  selectShouldDisplayGoodJob,
} from 'src/frontend/scenes/onBoarding/inApp/selectors'
import { connect } from 'react-redux'
import { closeGoodJobModal } from 'src/frontend/scenes/onBoarding/inApp/actions'
import styles from './GoodJob.module.less'
import FormattedMessage from 'src/frontend/modules/intl/components/FormattedMessage'
import { PrimaryButton } from 'src/frontend/components/Buttons/Buttons'
import { HashMap } from 'src/types/common'

interface Props {
  open: boolean,
  text: string,
  values: HashMap<any>
  handleClose: () => void
}

function mapStateToProps(state: RootState) {
  return {
    open: selectShouldDisplayGoodJob(state),
    text: selectGoodJobText(state),
    values: selectGoodJobValues(state),
  }
}

const mapDispatchToProps = {
  handleClose: closeGoodJobModal,
}

function GoodJobModal({ open, text, values, handleClose }: Props) {
  return (
    <Transition
      directional
      visible={open}
      unmountOnHide
      onHide={handleClose}
      duration={{ show: 250, hide: 150 }}
      animation="modal-animation"
    >
      <Modal size="small" open onClose={handleClose}>
        <Modal.Content>
          <div className={styles.goodJob}>
            <PictureIcon name="onboarding-finish" compact size="15rem" />
            <h2><FormattedMessage id="onBoarding.success.title" /></h2>
            <p><FormattedMessage id={text} values={values} /></p>

            <Divider hidden section />

            <PrimaryButton fluid size="large" onClick={handleClose}>
              <FormattedMessage id="form.ok" />
            </PrimaryButton>
          </div>
        </Modal.Content>
      </Modal>
    </Transition>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(GoodJobModal)
