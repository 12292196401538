import currencies from 'static/currencies.json'
import { Currency, PredefinedCurrency } from 'src/types/Currency'

export function getCurrencies(): PredefinedCurrency[] {
  return currencies
}

export function getCurrenciesCodes(): string[] {
  const allCurrencies = getCurrencies()
  return allCurrencies.map(currency => currency.code)
}

export function getUnusedCurrencies(usedCurrencies: Currency[]): PredefinedCurrency[] {
  const allCurrencies = getCurrencies()

  return allCurrencies.filter(currency => {
    return !usedCurrencies.some(currencyObject => currencyObject.code === currency.code)
  })
}
