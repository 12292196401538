import React from 'react'
import { connect } from 'react-redux'
import { CategoryLevel, FormType } from 'src/frontend/modules/categories/enums'
import {
  convertToCategoryItem,
  sortByLocalizedCategoryName,
} from 'src/frontend/modules/categories/helpers'
import { setHideEnvelope } from 'src/frontend/modules/configures/hiddenEnvelopes/actions'
import * as categoriesSelectors from '../selectors'
import * as actions from '../actions'
import styles from './CategoriesList.module.less'
import { RootState } from 'src/types/State'
import { Category, CategoryDocument } from 'src/types/Category'
import CategoriesListItem from 'src/frontend/scenes/settings/categories/components/CategoriesListItem'

const mapStateToProps = (state: RootState) => {
  const form = categoriesSelectors.selectForm(state)
  return {
    selectedEnvelopeId: form.selectedEnvelopeId,
    formOpen: form.isOpen,
    formCategory: form.category,
    formType: form.formType,
  }
}
const mapDispatchToProps = {
  handleOpenForm: actions.openForm,
  handleCloseForm: actions.closeForm,
  handleHideEnvelope: setHideEnvelope,
}

interface Props {
  formOpen: boolean
  formCategory: Category
  formType: FormType
  categoriesHierarchy: Category[] | CategoryDocument[]
  selectedEnvelopeId: number
  categoryLevel?: CategoryLevel
  parentCategoryName?: string

  handleCloseForm: Function
  handleOpenForm: Function
  handleHideEnvelope: Function
}

class CategoriesList extends React.Component<Props> {
  static defaultProps = {
    categoryLevel: CategoryLevel.CATEGORY,
    parentCategoryName: '',
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.selectedEnvelopeId !== this.props.selectedEnvelopeId
      && this.props.formOpen
      && this.props.categoryLevel === CategoryLevel.CATEGORY) {
      this.props.handleCloseForm()
    }
  }

  componentWillUnmount() {
    if (this.props.formOpen && this.props.categoryLevel === CategoryLevel.CATEGORY) {
      this.props.handleCloseForm()
    }
  }

  render() {
    const {
      categoryLevel,
      categoriesHierarchy,
      parentCategoryName,
      formOpen,
      formType,
      formCategory,
      handleOpenForm,
      handleCloseForm,
      handleHideEnvelope,
    } = this.props

    const categories = categoriesHierarchy
      .sort(sortByLocalizedCategoryName)
      .map(category => convertToCategoryItem(category, categoryLevel))
      .map((flattenedCategoryItem) => (
        <CategoriesListItem
          key={flattenedCategoryItem.category._id}
          subcategories={flattenedCategoryItem.subcategories}
          level={flattenedCategoryItem.level}
          name={flattenedCategoryItem.name}
          category={flattenedCategoryItem.category as Category}
          parentCategoryName={parentCategoryName}
          formOpen={formOpen}
          formType={formType}
          formCategory={formCategory}
          onOpenForm={handleOpenForm}
          onCloseForm={handleCloseForm}
          onHideEnvelope={handleHideEnvelope}
        />
      ))

    return (
      <div className={styles.categoriesList}>
        <ul>
          {categories}
        </ul>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CategoriesList)
