import { CategoryIcon } from 'src/frontend/components/Icons/Icons'
import React from 'react'
import IconOption from 'src/frontend/components/IconOption'
import { CategoryLevel } from 'src/frontend/modules/categories/enums'

interface Props {
  level: CategoryLevel
  color: string
  icon: string
  label: string
  size?: string
}

CategoryOption.defaultProps = {
  size: '25px',
}

export default function CategoryOption({ level, color, icon, label, size }: Props) {
  const categoryClassName = `${CategoryLevel.CATEGORY}-option`
  const subcategoryClassName = `${categoryClassName} ${CategoryLevel.SUBCATEGORY}-option`

  const className = level === CategoryLevel.CATEGORY ? categoryClassName : subcategoryClassName

  return (
    <IconOption icon={<CategoryIcon color={color} icon={icon} size={size} />} label={label} className={className} />
  )
}
