import React from 'react'
import PropTypes from 'prop-types'
import { Loader, Modal } from 'semantic-ui-react'
import { FormattedMessage } from 'src/frontend/modules/intl'
import {
  selectIsDeactivateImportsModalInProgress,
  selectIsDeactivateImportsModalOpen,
} from 'src/frontend/scenes/imports/selectors'
import { selectEditingAccountName } from 'src/frontend/scenes/settings/accounts/selectors'
import { connect } from 'react-redux'
import * as importsActions from 'src/frontend/scenes/imports/actions'
import './DeactivateImportsModal.less'
import { PrimaryButton, SecondaryButton } from 'src/frontend/components/Buttons/Buttons'

function mapStateToProps(state) {
  return ({
    accountName: selectEditingAccountName(state),
    open: selectIsDeactivateImportsModalOpen(state),
    progress: selectIsDeactivateImportsModalInProgress(state),
  })
}

const mapDispatchToProps = {
  cancelDeactivateImports: importsActions.cancelDeactivateImports,
  deactivateImports: importsActions.deactivateImports,
}

DeactivateImportsModal.propTypes = {
  accountName: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  progress: PropTypes.bool.isRequired,
  cancelDeactivateImports: PropTypes.func.isRequired,
  deactivateImports: PropTypes.func.isRequired,
}

function DeactivateImportsModal({ accountName, open, progress, cancelDeactivateImports, deactivateImports }) {
  return (
    <Modal open={open} size="small" className="delete-item-modal" dimmer>
      <Modal.Header>
        <FormattedMessage id="imports.deactivate.title" />
        {progress && <Loader active inline="centered" />}
      </Modal.Header>
      <Modal.Content>
        <FormattedMessage id="imports.deactivate_modal.text" values={{ accountName }} />
      </Modal.Content>
      {!progress && (
        <Modal.Actions>
          <SecondaryButton fluid onClick={cancelDeactivateImports}><FormattedMessage id="form.no" /></SecondaryButton>
          <PrimaryButton fluid color="red" onClick={deactivateImports}><FormattedMessage id="form.yes" /></PrimaryButton>
        </Modal.Actions>
      )}
    </Modal>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(DeactivateImportsModal)
