import { FormattedMessageLabel } from 'src/frontend/modules/intl'
import { Search } from 'semantic-ui-react'
import defaultProfileImage from 'src/frontend/assets/images/user_ic.svg'
import { formatMessage } from 'src/frontend/modules/intl/i18n'
import { Contact } from 'src/types/Contact'
import React, { SyntheticEvent, useState } from 'react'
import { openAddContactForm } from 'src/frontend/scenes/contacts/contactForm/actions'
import { connect } from 'react-redux'
import { SemanticData } from 'src/types/Semantic-ui'
import { Id } from 'src/types/CouchDb'
import latinize from 'latinize'
import ContactView from 'src/frontend/scenes/records/components/ContactView'
import './SelectContact.less'
import { escapeStringRegexp } from 'src/common/utils'

export const mapDispatchToProps = {
  openContactForm: openAddContactForm,
}

SelectContact.defaultProps = {
  disabled: false,
  className: '',
  allowAdd: false,
}

interface Props {
  contactId: Id
  contacts: Contact.ContactView[]
  disabled?: boolean
  className?: string
  allowAdd?: boolean

  onChange: (e: SyntheticEvent, data: SemanticData) => void

  openContactForm: Function
}


function SelectContact({ contactId, contacts, className, allowAdd, openContactForm, onChange }: Props) {
  const [searchValue, setSearchValue] = useState('')

  if (!!contactId) {
    const selectedContact = contacts.find((contact) => contact._id === contactId)
    return (
      <div className={`field ${className}`}>
        <FormattedMessageLabel id="record.form.contact" />
        <div className="ui selection dropdown contact-badge">
          <ContactView
            inField
            contact={selectedContact}
            showRemoveAction
            onRemove={(e) => onChange(e, { name: btoa('contactId'), value: undefined })}
          />
        </div>
      </div>
    )
  }

  // group results by type and shape it to semantic Search component
  const contactsResults = Object.values(contacts.filter((contact) => {
    const pattern = new RegExp(`.*${escapeStringRegexp(latinize(searchValue))}.*`, 'i')
    return latinize(contact.name).match(pattern)
  }))

  const AddNewName = formatMessage('contacts.add-new-contact-name', { contactName: `"${searchValue}"` })
  const AddNewId = 'ADD_NEW'

  const addNewContact = allowAdd ? [
    {
      _id: AddNewId,
      color: 'transparent',
      name: AddNewName,
      avatarUrl: defaultProfileImage,
    },
  ] : []

  return (
    <div className={`field contact-select ${className}`}>
      <FormattedMessageLabel id="record.form.contact" />
      <Search
        input={{ fluid: true, autoComplete: 'false', placeholder: formatMessage('search.action') }}
        results={[...contactsResults, ...addNewContact]}
        icon={null}
        aligned="right"
        resultRenderer={resultRenderer}
        value={searchValue}
        onSearchChange={(_e, { value }) => setSearchValue(value)}
        onResultSelect={(e, { result }) => {
          if (result._id === AddNewId) {
            openContactForm(searchValue)
            setSearchValue('')
          } else {
            onChange(e, { name: btoa('contactId'), value: result._id })
          }
        }}
      />
    </div>
  )
}

const resultRenderer = (contact) => <ContactView contact={contact} />

export default connect(null, mapDispatchToProps)(SelectContact)
