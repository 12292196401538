import React, { useCallback } from 'react'
import { connect } from 'react-redux'
import { Formik } from 'formik'
import { AddVoucherForm, AddVoucherFormData } from 'src/frontend/modules/modals/components/AddVoucherForm'
import { selectInitialVoucherCode } from 'src/frontend/modules/modals/selectors'
import { WalletValidationError } from 'src/backend/errors'
import * as logger from 'src/common/logger'
import * as actions from 'src/frontend/modules/modals/actions'
import * as billing from 'src/backend/rest/backend/billing'
import * as productActions from 'src/frontend/scenes/billing/product/actions'
import { RootState } from 'src/types/State'
import * as billingModalActions from 'src/frontend/scenes/billing/billingModal/actions'
import { Billing } from 'src/types/Billing'
import VoucherType = Billing.VoucherType
import VoucherTransactionResponse = Billing.VoucherTransactionResponse

type Props = {
  initialVoucherCode?: string,
}

function mapStateToProps(state: RootState): Props {
  return {
    initialVoucherCode: selectInitialVoucherCode(state),
  }
}

interface Actions {
  addVoucherSuccess: () => void,
  addVoucherError: () => void,
  addVoucherClose: () => void,
  openBillingModal: () => void,
  getVoucherProductSuccess: (voucher: VoucherTransactionResponse, voucherCode: string) => void,
}

const mapDispatchToProps: Actions = {
  addVoucherSuccess: actions.addVoucherSuccess,
  addVoucherError: actions.addVoucherError,
  addVoucherClose: actions.closeModalAddVoucher,
  openBillingModal: billingModalActions.openBillingModal,
  getVoucherProductSuccess: productActions.getVoucherProductSuccess,
}

function AddVoucherFormContainer({
  initialVoucherCode,
  addVoucherClose,
  addVoucherSuccess,
  addVoucherError,
  openBillingModal,
  getVoucherProductSuccess,
}: Props & Actions) {

  const initialValues: AddVoucherFormData = {
    voucherCode: initialVoucherCode,
  }

  const onSubmit = useCallback(async (values, { setSubmitting, setErrors }) => {
    const { voucherCode } = values
    try {
      const voucherResponse: VoucherTransactionResponse = await billing.submitVoucherCode(voucherCode)
      setSubmitting(false)
      if (voucherResponse.voucherType === VoucherType.PREMIUM) {
        return addVoucherSuccess()
      } else {
        addVoucherClose()
        await getVoucherProductSuccess(voucherResponse, voucherCode)
        return openBillingModal()
      }
    } catch (error) {
      setSubmitting(false)
      if (error instanceof WalletValidationError) {
        addVoucherError()
      } else {
        addVoucherError()
        const message = 'Could not apply voucher code'
        logger.captureException(error, message)
        console.error(message, error)
      }
    }
  }, [])

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      {(props) => <AddVoucherForm {...props} handleCancel={addVoucherClose} />}
    </Formik>
  )
}

AddVoucherFormContainer.defaultProps = {
  initialVoucherCode: '',
}

export default connect(mapStateToProps, mapDispatchToProps)(AddVoucherFormContainer)
