import React, { useEffect } from 'react'
import { RootState } from 'src/types/State'
import * as selectors from 'src/frontend/scenes/records/recordList/selectors'
import { connect } from 'react-redux'
import { selectIsRecordsLoading, selectShouldRefreshRecords } from 'src/frontend/modules/moduleStatus/selectors'
import { refreshRecordsStatus } from 'src/frontend/modules/moduleStatus/actions'
import ExtendedRecordList from 'src/frontend/components/RecordList/ExtendedRecordList'
import { RecordListItemRecord } from 'src/frontend/components/RecordList/types'
import { RecordListOrderBy } from 'src/frontend/scenes/records/recordList/enums'

function mapStateToProps(state: RootState) {
  return {
    records: selectors.selectAggregatedRecords(state),
    sortBy: selectors.selectOrderBy(state),
    plannedPayments: selectors.selectAggregatedPlannedPayments(state),
    shouldRefreshRecords: selectShouldRefreshRecords(state),
    loading: selectIsRecordsLoading(state),
  }
}

const mapDispatchToProps = {
  refreshRecords: refreshRecordsStatus,
}

interface Props {
  records: RecordListItemRecord[]
  shouldRefreshRecords: boolean
  loading: boolean
  sortBy: RecordListOrderBy

  refreshRecords: Function
}

function RecordList({ records, shouldRefreshRecords, loading, refreshRecords, sortBy }: Props) {
  useEffect(() => {
    if (shouldRefreshRecords) {
      refreshRecords()
    }
  }, [shouldRefreshRecords])

  return (
    <ExtendedRecordList records={records} loading={loading} sortBy={sortBy} />
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(RecordList)
