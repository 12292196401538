import { ImportMappingAttributeType } from 'src/backend/enums'

export const WizardSteps = {
  CHOOSE_HEADER_AND_ROWS: 'header_and_rows',
  CHOOSE_AMOUNT: 'amount',
  CHOOSE_DATE: 'date',
  CHOOSE_OPTIONAL: 'optional',
}

export const WIZARD_STEPS_ORDER = [
  WizardSteps.CHOOSE_HEADER_AND_ROWS,
  WizardSteps.CHOOSE_AMOUNT,
  WizardSteps.CHOOSE_DATE,
  WizardSteps.CHOOSE_OPTIONAL,
]

export const WizardStepAttributes = {
  [WizardSteps.CHOOSE_HEADER_AND_ROWS]: [],
  [WizardSteps.CHOOSE_AMOUNT]: [
    ImportMappingAttributeType.AMOUNT,
    ImportMappingAttributeType.EXPENSE,
    ImportMappingAttributeType.FEE,
  ],
  [WizardSteps.CHOOSE_DATE]: [ImportMappingAttributeType.RECORDDATE],
  [WizardSteps.CHOOSE_OPTIONAL]: [
    ImportMappingAttributeType.NOTE,
    ImportMappingAttributeType.PAYEE,
    ImportMappingAttributeType.CURRENCYCODE,
    ImportMappingAttributeType.CATEGORYMAPPING,
  ],
}

export const COLUMN_UNSET_INDEX = -1

export const IMPORT_MAPPING_ATTRIBUTE_ORDER = [
  ImportMappingAttributeType.AMOUNT,
  ImportMappingAttributeType.EXPENSE,
  ImportMappingAttributeType.FEE,
  ImportMappingAttributeType.RECORDDATE,
  ImportMappingAttributeType.NOTE,
  ImportMappingAttributeType.PAYEE,
  ImportMappingAttributeType.CURRENCYCODE,
  ImportMappingAttributeType.CATEGORYMAPPING,
]

export const IMPORT_FORMAT_DOCS_URL = 'https://support.budgetbakers.com/hc/en-us/articles/115003828685-What-format-and-information-should-my-IMPORT-file-have-'
