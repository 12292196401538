import * as actions from './actions'
import { MagicRule } from 'src/types/MagicRule'

export interface MagicRuleState {
  [key: string]: MagicRule
}

export default function magicRules(state: MagicRuleState = {}, action) {
  switch (action.type) {
    case actions.MAGIC_RULES_RECEIVE: {
      return { ...action.payload }
    }
    default:
      return state
  }
}
