import { pipe } from 'ramda'
import { createSelector } from 'reselect'
import { sortEntities } from 'src/backend/common/helpers'
import { Feature } from 'src/backend/user/billingFeatures'
import { getUserFeatureValue } from 'src/backend/user/service'
import {
  mergeAccounts,
  selectAccountsAsArray,
  selectNonArchivedAccountsAsArray,
} from 'src/frontend/modules/accounts/selectors'
import { selectCurrencies, selectReferentialCurrency } from 'src/frontend/modules/currencies/selectors'
import { selectUser } from 'src/frontend/modules/user/selectors'
import { RootState } from 'src/types/State'
import { selectSettings } from 'src/frontend/scenes/settings/selectors'
import { Account, AccountWithCurrencyCode } from "src/types/Account"
import { User } from "src/types/User"
import {
  AccountsDeleteFormState,
  AccountsFormState,
  SettingsAccounts,
} from "src/frontend/scenes/settings/accounts/reducer"
import { AccountsTotals } from "src/frontend/scenes/dashboard/types"
import { isAppBoard } from "src/common/environment"

const selectSettingsAccounts = (state: RootState): SettingsAccounts =>
  selectSettings(state).accounts

const selectAccountsTotals = (state: RootState): Partial<AccountsTotals> =>
  selectSettingsAccounts(state).accountsTotals
export const selectAccountsForm = (state: RootState): AccountsFormState =>
  selectSettingsAccounts(state).form
export const selectAccountsDeleteForm = (state: RootState): AccountsDeleteFormState => {
  return selectSettingsAccounts(state).deleteForm
}

export const selectAccountFormType = (state: RootState) => selectAccountsForm(state).accountFormType

export const selectAccountsWithTotals: (
  state: RootState,
) => (AccountWithCurrencyCode & AccountsTotals)[] = createSelector(
  selectReferentialCurrency,
  selectCurrencies,
  selectAccountsAsArray,
  selectAccountsTotals,
  pipe(mergeAccounts, sortEntities),
)

export const selectCanAddAccount: (RootState) => boolean = createSelector(
  [
    selectNonArchivedAccountsAsArray,
    selectUser,
  ],
  canAddAccount,
)

export function canAddAccount(accounts: Account[], user: User): boolean {
  const maxAccountsCount = getUserFeatureValue(user, Feature.MAX_ACCOUNTS_COUNT)

  return isAppBoard() || accounts.length < maxAccountsCount
}

export function selectEditingAccountName(state: RootState): string {
  return selectEditingAccount(state).name
}

export function selectEditingAccount(state: RootState): Account {
  return selectAccountsForm(state).account
}
