import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Form } from 'semantic-ui-react'
import styles from './BirthDatePicker.module.less'
import moment from 'moment'
import { parseIntDecimal } from 'src/common/utils'
import { withIntlConsumer } from 'src/frontend/modules/intl/components/withIntlConsumer'
import { IntlConsumerType } from 'src/frontend/modules/intl/components/types'
import { getShortMonthsForLocale } from 'src/backend/time/time'
import { FormattedMessageLabel } from 'src/frontend/modules/intl'

interface Props extends IntlConsumerType {
  value: number
  name: string
  label: React.ReactNode | string
  onChange: (event: any, data: { value: number, name: string }) => void
}

function BirthDatePicker({ name, value, label, locale, onChange }: Props) {
  const [selectedDate, setSelectedDate] = useState(moment.utc(value))

  useEffect(() => {
    if (value !== selectedDate.valueOf()) {
      setSelectedDate(moment.utc(value))
    }
  }, [value])


  const handleChangeDay = useCallback((e, data) => {
    const newDate = moment.utc(selectedDate).clone().date(data.value)
    setSelectedDate(newDate)
    onChange(e, { name, value: newDate.valueOf() })
  }, [selectedDate])

  const handleChangeMonth = useCallback((e, data) => {
    const newDate = moment.utc(selectedDate).clone().month(data.value)
    setSelectedDate(newDate)
    onChange(e, { name, value: newDate.valueOf() })
  }, [selectedDate])

  const handleChangeYear = useCallback((e, data) => {
    const newDate = moment.utc(selectedDate).clone().year(data.value)
    setSelectedDate(newDate)
    onChange(e, { name, value: newDate.valueOf() })
  }, [selectedDate])

  const dayOptions = useMemo(() => {
    return [...Array(31)]
      .map((_value, index) => index + 1)
      .map((dayNumber) => ({ value: dayNumber, text: dayNumber }))
  }, [])


  const yearOptions = useMemo(() => {
    const actualYear = parseIntDecimal(moment.utc().format('Y'))

    return [...Array(150)]
      .map((_value, index) => actualYear - index)
      .map((yearNumber) => ({ value: yearNumber, text: yearNumber }))
  }, [])

  const monthOptions = useMemo(() => {

    return getShortMonthsForLocale(locale)
      .map((text, index) => ({ value: index, text }))
  }, [])

  return (
    <Form.Group widths={3} className={styles.picker} unstackable>
      <Form.Select options={dayOptions} label={label} value={selectedDate.date()} onChange={handleChangeDay} />
      <Form.Select
        options={monthOptions}
        label={<FormattedMessageLabel id="month" />}
        value={selectedDate.month()}
        onChange={handleChangeMonth}
      />
      <Form.Select
        options={yearOptions}
        label={<FormattedMessageLabel id="year" />}
        value={selectedDate.year()}
        onChange={handleChangeYear}
      />
    </Form.Group>
  )
}

export default withIntlConsumer(BirthDatePicker)
