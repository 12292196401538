import React, { SyntheticEvent } from 'react'
import { Modal } from 'semantic-ui-react'
import { RootState } from 'src/types/State'
import { selectBillingModalOpen, selectBillingModalStage } from 'src/frontend/scenes/billing/billingModal/selectors'
import { connect } from 'react-redux'
import { BillingModalStage } from 'src/frontend/scenes/billing/billingModal/enums'
import { closeBillingModal } from 'src/frontend/scenes/billing/billingModal/actions'
import { getBillingModalComponent } from 'src/frontend/scenes/billing/billingModal/helpers'
import styles from './BillingModal.module.less'
import { selectLoading } from 'src/frontend/scenes/billing/paymentMethod/selectors'

interface Props {
  open: boolean,
  stage: BillingModalStage,
  isPurchaseLoading: boolean
  handleClose: (e: SyntheticEvent) => void
}

function mapStateToProps(state: RootState) {
  return {
    open: selectBillingModalOpen(state),
    stage: selectBillingModalStage(state),
    isPurchaseLoading: selectLoading(state),
  }
}

const mapDispatchToProps = {
  handleClose: closeBillingModal,
}

function BillingModal({ open, stage, isPurchaseLoading, handleClose }: Props) {

  const ContentComponent = getBillingModalComponent(stage)

  return (
    <Modal
      className={styles.modal}
      size={stage === BillingModalStage.SELECT_PRODUCT ? 'large' : 'small'}
      open={open}
      closeOnDimmerClick={false}
      closeOnDocumentClick={false}
      closeOnEscape={!isPurchaseLoading}
      onClose={handleClose}
    >
      {!isPurchaseLoading && <span className="modal-close" onClick={handleClose} />}
      {open && <ContentComponent />}
    </Modal>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(BillingModal, ((prevProps, nextProps) => {
  return prevProps.open === nextProps.open && prevProps.stage === nextProps.stage
})))
