import { CouchType, ReplicationEndpoint, User } from 'src/types/User'
import { Billing } from 'src/types/Billing'
import BillingPeriodType = Billing.PeriodType

export const PlanPeriodName = {
  [BillingPeriodType.MONTH]: 'monthly',
  [BillingPeriodType.UNLIMITED]: 'lifetime',
  [BillingPeriodType.YEAR]: 'yearly',
}

export function getReplication(user: User): ReplicationEndpoint {
  // @ts-ignore, common replication object
  if (user.replicationCouchbase) {
    // @ts-ignore
    const { url, login, password, migrationFinished } = user.replicationCouchbase
    return {
      url,
      login,
      token: password,
      migrationFinished,
      couchType: CouchType.COUCHBASE,
      dbName: '',
      ownerId: user.userId,
      pullFilter: '',
    }
  } else {
    return {
      ...user.replication,
      couchType: CouchType.COUCH_DB,
    }
  }
}
