import React from 'react'
import { connect } from 'react-redux'
import { Menu } from 'semantic-ui-react'
import LinearChart, { Props as LinearChartProps } from 'src/frontend/components/chart/LinearChart'
import Widget from 'src/frontend/scenes/dashboard/components/Widget/Widget'
import { WidgetView } from 'src/frontend/scenes/dashboard/types'
import {
  changeCashFlowSubject as changeCashFlowSubjectAction,
} from 'src/frontend/scenes/dashboard/widgets/CashFlowTrend/actions'
import styles from 'src/frontend/scenes/dashboard/widgets/CashFlowTrend/CashFlowTrend.module.less'
import { FormattedMessage } from 'src/frontend/modules/intl'
import { chartHasEqualProps } from 'src/frontend/scenes/dashboard/helpers'
import barBackgroundPlugin from 'src/frontend/scenes/dashboard/widgets/PieAndBarSpending/barBackgroundPlugin'

type Props = {
  widget: WidgetView & { data: LinearChartProps },
  changeCashFlowSubject: Function,
}

const mapDispatchToProps = {
  changeCashFlowSubject: changeCashFlowSubjectAction,
}

function CashFlowTrend({ widget, changeCashFlowSubject }: Props) {
  const { id, data, filter, options } = widget

  if (!data || !data.datasets || !data.datasets[0] || !filter) {
    return <Widget.Placeholder />
  }

  const content = !data.datasets[0].isEmpty
    ? (
      <Widget.Content className={styles.cashFlowTrend}>
        <Menu fluid widths={2} compact>
          <Menu.Item
            active={options.cumulative === false}
            onClick={() => changeCashFlowSubject(id, false)}
          >
            <FormattedMessage id="cashFlow-trend" />
          </Menu.Item>
          <Menu.Item
            active={options.cumulative === true}
            onClick={() => changeCashFlowSubject(id, true)}
          >
            <FormattedMessage id="cashFlow-cumulative" />
          </Menu.Item>
        </Menu>
        <LinearChart {...data} plugins={[barBackgroundPlugin]} />
      </Widget.Content>
    )
    : <Widget.EmptyContent type="line" />

  return (
    <Widget>
      <Widget.Header title={widget.title} />
      {content}
    </Widget>
  )
}

export default connect(null, mapDispatchToProps)(React.memo(CashFlowTrend, chartHasEqualProps))
