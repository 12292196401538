import _debounce from "lodash/debounce"
import * as React from "react"
import { connect } from "react-redux"
import { Icon } from "semantic-ui-react"
import { isUndefined, isUndefinedOrNull } from "src/common/utils"
import { FormattedMessage } from "src/frontend/modules/intl"
import * as labelsSelectors from "src/frontend/modules/labels/selectors"
import * as contactsSelectors from "src/frontend/modules/contacts/selectors"
import { selectAmountRange } from "src/frontend/scenes/records/recordList/selectors"
import { Id } from "src/types/CouchDb"
import { FilterType } from "src/types/Filter"
import styles from "src/frontend/components/Filter/Filter.module.less"
import InputRangeFilter from "./InputRangeFilter"
import MultipleLabelsFilter from "./MultipleLabelsFilter"
import MultiSelectFilter from "./MultiSelectFilter"
import RemoveFilterModal from "src/frontend/components/Filter/RemoveFilterModal"
import FilterSelect from "src/frontend/components/Filter/FilterSelect"
import { Option } from "src/frontend/components/Filter/types"
import {
  changeFilter,
  changeFilterValues,
  removeSelectedFilter,
  toggleDisplayFilter,
} from "src/frontend/modules/filter/actions"
import {
  CategoryFilterGroup,
  selectAccountOptions,
  selectCategoryOptions,
  selectCurrenciesOptions,
  selectDisplayOptions,
  selectFilter,
  selectIsRemoveFilterLoading,
  selectPaymentTypeOptions,
  selectRecordStateOptions,
  selectRecordTypeOptions,
  selectSelectedFilterId,
} from "src/frontend/modules/filter/selectors"
import { FilterDisplayOptions } from "src/frontend/modules/filter/types"
import { Label } from "src/types/Label"
import { RootState } from "src/types/State"
import { isAppBoard } from "src/common/environment"
import { Contact } from "src/types/Contact"
import ContactView = Contact.ContactView
import _omitBy from "lodash/omitBy"
import SearchFilter from "src/frontend/components/Filter/SearchFilter"
import { trackUseFilter } from "src/common/mixpanel"

const mapStateToProps = (state: RootState) => {
  return {
    filter: selectFilter(state),
    display: selectDisplayOptions(state),
    selectedFilterId: selectSelectedFilterId(state),
    removeLoading: selectIsRemoveFilterLoading(state),
    options: {
      categories: selectCategoryOptions(state),
      accounts: selectAccountOptions(state),
      labels: labelsSelectors.selectSortedLabels(state),
      contacts: contactsSelectors.selectSortedContactsOptions(state),
      currencies: selectCurrenciesOptions(state),
      paymentTypes: selectPaymentTypeOptions(state),
      recordStates: selectRecordStateOptions(state),
      recordTypes: selectRecordTypeOptions(state),
      amountRange: selectAmountRange(state),
    },
  }
}

const mapDispatchToProps = (dispatch: Function) => {
  return {
    handleChangeFilter: (name, value) => dispatch(changeFilter(name, value)),
    handleChangeMultipleFilter: (values) => dispatch(changeFilterValues(values)),
    handleToggleDisplayFilter: (name) => dispatch(toggleDisplayFilter(name)),
    handleRemoveFilter: () => dispatch(removeSelectedFilter()),
  }
}

export type Props = {
  filter: FilterType
  selectedFilterId?: Id
  display: FilterDisplayOptions
  removeLoading: boolean
  options: {
    labels: Label[]
    contacts: ContactView[]
    categories: Array<Option>
    accounts: Array<Option>
    currencies: Array<Option>
    paymentTypes: Array<Option>
    recordStates: Array<Option>
    recordTypes: Array<Option>
    amountRange: {
      min: number
      max: number
    }
  }
  handleChangeFilter: Function
  handleChangeMultipleFilter: Function
  handleToggleDisplayFilter: Function
  handleRemoveFilter: Function
}

class Filter extends React.Component<Props> {
  static defaultProps = {
    selectedFilterId: null,
  }

  handleTrack = (filterType) => () => trackUseFilter(filterType)

  trackFilter = {
    categories: _debounce(this.handleTrack("Category"), 5000),
    accounts: _debounce(this.handleTrack("Account"), 5000),
    labels: _debounce(this.handleTrack("Label"), 5000),
    contacts: _debounce(this.handleTrack("Contact"), 5000),
    currencies: _debounce(this.handleTrack("Currency"), 5000),
    recordTypes: _debounce(this.handleTrack("Record Type"), 5000),
    paymentTypes: _debounce(this.handleTrack("Payment Type"), 5000),
    recordStates: _debounce(this.handleTrack("Payment Status"), 5000),
    fulltext: _debounce(this.handleTrack("Fulltext"), 5000),
    amountRange: _debounce(this.handleTrack("Amount Range"), 5000),
  }

  handleDebouncedFilter = _debounce((_e, { value }) => {
    this.trackFilter.fulltext()
    this.props.handleChangeFilter("fulltext", value)
  }, 1000)

  componentWillUnmount(): void {
    this.handleDebouncedFilter.cancel()
    Object.values(this.trackFilter).forEach((debounce) => debounce.flush())
  }

  shouldComponentUpdate(nextProps: Readonly<Props>): boolean {
    const nextPropsWithoutHandlers = _omitBy(nextProps, (value) => typeof value !== "function")
    const propsWithoutHandlers = _omitBy(this.props, (value) => typeof value !== "function")

    return nextPropsWithoutHandlers !== propsWithoutHandlers
  }

  render() {
    const {
      filter,
      display,
      options,
      removeLoading,
      selectedFilterId,
      handleChangeFilter,
      handleChangeMultipleFilter,
      handleToggleDisplayFilter,
      handleRemoveFilter,
    }: Props = this.props

    return (
      filter && (
        <div className={styles.filters}>
          <SearchFilter
            defaultValue={filter.fulltext}
            onChange={this.handleDebouncedFilter}
          />

          <h3>
            <FormattedMessage id="filter.filter" />
            {selectedFilterId &&
              (!removeLoading ? (
                <RemoveFilterModal
                  onConfirm={handleRemoveFilter}
                  filter={filter}
                />
              ) : (
                <Icon
                  loading
                  disabled
                  name="circle notch"
                  className={styles.iconRemoveLoader}
                />
              ))}
          </h3>

          <FilterSelect />

          <MultiSelectFilter
            key="accounts"
            label="records.filter.accounts"
            labelAll="records.filter.accounts.all"
            name="accountIds"
            options={options.accounts}
            active={display.accountIds}
            displayAll={isUndefined(filter.accountIds)}
            onFilterChange={(...args) => {
              this.trackFilter.accounts()
              handleChangeMultipleFilter(...args)
            }}
            onDisplayChange={handleToggleDisplayFilter}
          />

          <MultiSelectFilter
            key="categories"
            label="records.filter.categories"
            labelAll="records.filter.categories.all"
            name="categories"
            active={display.categories}
            options={options.categories}
            groups={[
              CategoryFilterGroup.SUPER_ENVELOPE,
              CategoryFilterGroup.ENVELOPE,
              CategoryFilterGroup.CATEGORY,
            ]}
            displayAll={
              isUndefined(filter.superEnvelopeIds) &&
              isUndefined(filter.categoryId) &&
              isUndefined(filter.envelopeIds)
            }
            onFilterChange={(...args) => {
              this.trackFilter.categories()
              handleChangeMultipleFilter(...args)
            }}
            onDisplayChange={handleToggleDisplayFilter}
          />

          {isAppBoard() && (
            <MultipleLabelsFilter
              label="records.filter.contacts"
              name="contactIds"
              active={display.contactIds}
              options={options.contacts}
              selectedOptions={filter.contactIds}
              onChange={(...args) => {
                this.trackFilter.contacts()
                handleChangeFilter(...args)
              }}
              onDisplayChange={handleToggleDisplayFilter}
            />
          )}

          <MultipleLabelsFilter
            label="record.filter.labels"
            name="labelIds"
            active={display.labelIds}
            options={options.labels}
            selectedOptions={filter.labelIds}
            onChange={(...args) => {
              this.trackFilter.labels()
              handleChangeFilter(...args)
            }}
            onDisplayChange={handleToggleDisplayFilter}
          />

          <MultiSelectFilter
            key="currencyId"
            label="records.filter.currencies"
            labelAll="records.filter.currencies.all"
            name="currencyId"
            active={display.currencyId}
            options={options.currencies}
            displayAll={isUndefined(filter.currencyId)}
            onFilterChange={(...args) => {
              this.trackFilter.currencies()
              handleChangeMultipleFilter(...args)
            }}
            onDisplayChange={handleToggleDisplayFilter}
          />

          <MultiSelectFilter
            key="recordTypes"
            label="records.filter.recordTypes"
            labelAll="records.filter.recordTypes.all"
            name="recordTypes"
            active={display.recordTypes}
            options={options.recordTypes}
            displayAll={isUndefined(filter.recordTypes)}
            onFilterChange={(...args) => {
              this.trackFilter.recordTypes()
              handleChangeMultipleFilter(...args)
            }}
            onDisplayChange={handleToggleDisplayFilter}
          />

          {!isUndefinedOrNull(options.amountRange) &&
            !isUndefinedOrNull(options.amountRange.min) &&
            options.amountRange.min > 0 &&
            !isUndefinedOrNull(options.amountRange.max) &&
            options.amountRange.min !== options.amountRange.max && (
              <InputRangeFilter
                min={filter.amount && filter.amount.min}
                max={filter.amount && filter.amount.max}
                amountRange={options.amountRange}
                active={display.amountRange}
                onChange={(...args) => {
                  this.trackFilter.amountRange()
                  handleChangeFilter(...args)
                }}
                onDisplayChange={handleToggleDisplayFilter}
              />
            )}

          <MultiSelectFilter
            key="paymentType"
            label="records.filter.paymentTypes"
            labelAll="records.filter.paymentTypes.all"
            name="paymentType"
            active={display.paymentType}
            options={options.paymentTypes}
            displayAll={isUndefined(filter.paymentType)}
            onFilterChange={(...args) => {
              this.trackFilter.paymentTypes()
              handleChangeMultipleFilter(...args)
            }}
            onDisplayChange={handleToggleDisplayFilter}
          />

          <MultiSelectFilter
            key="recordState"
            label="records.filter.recordStates"
            labelAll="records.filter.recordStates.all"
            name="recordState"
            active={display.recordState}
            options={options.recordStates}
            displayAll={isUndefined(filter.recordState)}
            onFilterChange={(...args) => {
              this.trackFilter.recordStates()
              handleChangeMultipleFilter(...args)
            }}
            onDisplayChange={handleToggleDisplayFilter}
          />
        </div>
      )
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Filter)
