import { Grid, Modal } from 'semantic-ui-react'
import React, { useState } from 'react'
import { FormattedMessage } from 'src/frontend/modules/intl'
import { connect } from 'react-redux'
import { removeContact } from 'src/frontend/scenes/contacts/contactForm/actions'
import { PrimaryButton, SecondaryButton } from 'src/frontend/components/Buttons/Buttons'

const mapDispatchToProps = {
  onRemove: removeContact,
}

function RemoveContactForm({ contact, trigger, onRemove }) {
  const [processing, setProcessing] = useState(false)
  const [open, setOpen] = useState(false)

  const handleClose = () => setOpen(false)
  const handleOpen = () => setOpen(true)
  const handleConfirm = () => {
    setProcessing(true)
    onRemove(contact._id)
  }

  const ModalActions = () => {
    return (
      <Modal.Actions>
        <Grid>
          <Grid.Column width={8}>
            <SecondaryButton fluid onClick={handleClose}>
              <FormattedMessage id="form.no" />
            </SecondaryButton>
          </Grid.Column>
          <Grid.Column width={8}>
            <PrimaryButton disabled={processing} fluid color="red" onClick={handleConfirm}>
              <FormattedMessage id="form.yes" />
            </PrimaryButton>
          </Grid.Column>
        </Grid>
      </Modal.Actions>
    )
  }

  return (
    <Modal
      trigger={trigger}
      open={open}
      size="small"
      onClose={handleClose}
      onOpen={handleOpen}
    >
      <Modal.Header><FormattedMessage id="contacts.remove" /></Modal.Header>
      <Modal.Content>
        <FormattedMessage id="contacts.remove.prompt" values={{ contactName: <strong>{contact.name}</strong> }} />
      </Modal.Content>
      <ModalActions />
    </Modal>
  )
}

export default connect(null, mapDispatchToProps)(RemoveContactForm)
