import * as recordsService from "src/backend/records/service"
import * as standingOrdersService from "src/backend/standingOrders/service"
import * as recordsSelectors from "./selectors"
import * as filterSelectors from "src/frontend/modules/filter/selectors"
import { FilterType } from "src/types/Filter"
import { Action, GetState } from "src/types/common"
import { Record } from "src/types/Record"
import { RecordListOrderBy } from "src/frontend/scenes/records/recordList/enums"

export const RECORD_LIST_CHANGE_ORDER = "recordList/CHANGE_ORDER"

export const RECORD_LIST_RECORDS_REQUEST = "recordList/RECORDS_REQUEST"
export const RECORD_LIST_RECORDS_RECEIVE = "recordList/RECORDS_RECEIVE"

export const RECORD_LIST_RECORDS_PREVIEW_REQUEST = "recordList/RECORDS_PREVIEW_REQUEST"
export const RECORD_LIST_RECORDS_PREVIEW_RECEIVE = "recordList/RECORDS_PREVIEW_RECEIVE"
export const RECORD_LIST_RECORDS_PREVIEW_OPEN = "recordList/RECORD_LIST_RECORDS_PREVIEW_OPEN"
export const RECORD_LIST_RECORDS_PREVIEW_CLOSE = "recordList/RECORD_LIST_RECORDS_PREVIEW_CLOSE"

export function fetchRecords() {
  return (dispatch: Function, getState: GetState) => {
    dispatch({ type: RECORD_LIST_RECORDS_REQUEST })
    const recordsFilter = filterSelectors.selectFilter(getState())
    return Promise.all([
      recordsService.fetchRecordsByFilter(recordsFilter),
      standingOrdersService.generateVirtualRecordsByFilter(recordsFilter),
      recordsService.fetchMinMaxAmountByFilter(recordsFilter),
    ]).then(([records, plannedPayments, { min, max }]) => {
      console.log("Records", records)

      dispatch({
        type: RECORD_LIST_RECORDS_RECEIVE,
        payload: {
          records,
          plannedPayments,
          amountRange: {
            min,
            max,
          },
        },
      })
      return records
    })
  }
}

export function changeOrder(orderBy: RecordListOrderBy) {
  return {
    type: RECORD_LIST_CHANGE_ORDER,
    payload: orderBy,
  }
}

export function fetchRecordsPreview() {
  return async (dispatch: Function, getState: GetState) => {
    dispatch(requestRecordsPreviewStart())
    const analyticsCategoryFilter = recordsSelectors.selectIsPreviewFilter(getState())
    const genericRecordsFilter = filterSelectors.selectFilter(getState())

    if (analyticsCategoryFilter) {
      const records = await recordsService.fetchRecordsByFilter({
        ...genericRecordsFilter,
        ...analyticsCategoryFilter,
      })
      dispatch(requestRecordsPreviewSuccess(records))
    }
  }
}

function requestRecordsPreviewStart(): Action {
  return {
    type: RECORD_LIST_RECORDS_PREVIEW_REQUEST,
  }
}

function requestRecordsPreviewSuccess(records: Record[]): Action {
  return {
    type: RECORD_LIST_RECORDS_PREVIEW_RECEIVE,
    payload: records,
  }
}

export function openRecordsPreview(recordsPreviewFilter: FilterType): Action {
  return {
    type: RECORD_LIST_RECORDS_PREVIEW_OPEN,
    payload: recordsPreviewFilter,
  }
}

export function closeRecordsPreview(): Action {
  return {
    type: RECORD_LIST_RECORDS_PREVIEW_CLOSE,
  }
}
