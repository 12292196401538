import React from "react"
import {
  isConnected,
  isOutOfWalletId,
  VIRTUAL_OUT_OF_WALLET_ACCOUNT_ID,
  VIRTUAL_OUT_OF_WALLET_ACCOUNT_TYPE,
  VIRTUAL_OUT_OF_WALLET_COLOR,
} from "src/backend/accounts/helpers"
import { getRecordTypeClass, isTransfer, recordIsUncleared } from "src/backend/records/helpers"
import {
  formatMessage,
  FormattedMessage,
  FormattedMessageLabel,
} from "src/frontend/modules/intl/index"
import AmountInput from "src/frontend/components/AmountInput"
import SelectCategory from "src/frontend/components/Categories/SelectCategory"
import SelectRecordType from "src/frontend/scenes/records/components/SelectRecordType"
import SelectAccount from "src/frontend/scenes/records/components/SelectAccount"
import { Form, Grid, Icon, Message, InputProps, DropdownProps, Transition } from "semantic-ui-react"
import { Account } from "src/types/Account"
import { Currency } from "src/types/Currency"
import { RecordType } from "src/backend/enums"
import { RecordTransfer } from "src/types/Record"
import SelectMultiple from "src/frontend/components/SelectMultiple"
import DateTimePicker, {
  DatePickerType,
} from "src/frontend/scenes/records/components/DateTimePicker"
import { RecordFormState } from "src/frontend/scenes/records/recordForm/reducer"
import { FormType } from "src/frontend/scenes/records/recordForm/enums"
import _isEqual from "lodash/isEqual"
import moment from "moment"
import { isAppBoard } from "src/common/environment"
import SelectContact from "src/frontend/scenes/records/components/SelectContact"
import { SelectOptions } from "src/frontend/scenes/records/recordForm/components/types"
import { PrimaryButton, SecondaryButton } from "src/frontend/components/Buttons/Buttons"

interface Props {
  recordForm: RecordFormState
  selectOptions: SelectOptions

  handleRecordTypeChange: (type: RecordType, transfer: boolean, record: RecordTransfer) => any
  handleFieldChange: (event: React.SyntheticEvent, data: DropdownProps | InputProps) => void
  handleDateTimeChange: Function
  handleAccountChange: Function
  handleTransferCurrencyChange: Function
  handleTransferAmountChange: Function
  handleSaveRecord: (createNew?: boolean) => void
  handleRevertChanges: (MouseEvent) => void
}

export default class RecordMainForm extends React.Component<Props> {
  firstTransferAmountName: string

  handleTransferAmountChange =
    (isRecordConnected: boolean) => (_e: React.SyntheticEvent, field: InputProps) => {
      const shouldResetState = field.value === 0
      const firstTransferAmountName = this.firstTransferAmountName || field.name

      this.props.handleTransferAmountChange(
        field,
        this.props.recordForm.formValues,
        firstTransferAmountName,
        isRecordConnected,
      )
      this.firstTransferAmountName = !shouldResetState ? firstTransferAmountName : undefined
    }

  render() {
    const {
      recordForm,
      selectOptions,
      handleRecordTypeChange,
      handleAccountChange,
      handleFieldChange,
      handleDateTimeChange,
      handleTransferCurrencyChange,
      handleSaveRecord,
      handleRevertChanges,
    } = this.props

    const { formValues, errors, formType } = recordForm

    const labelOptions =
      selectOptions &&
      selectOptions.labels &&
      selectOptions.labels.filter((label) => {
        return (
          !label.archived ||
          (formValues && formValues.labels && formValues.labels.includes(label._id))
        )
      })

    const VIRTUAL_OUT_OF_WALLET_ACCOUNT = {
      _id: VIRTUAL_OUT_OF_WALLET_ACCOUNT_ID,
      accountType: VIRTUAL_OUT_OF_WALLET_ACCOUNT_TYPE,
      name: formatMessage({ W: "record.form.out_of_wallet", B: "transaction.form.out_of_board" }),
      color: VIRTUAL_OUT_OF_WALLET_COLOR,
    }

    const account: Account = selectOptions.accounts.find(({ _id }) => _id === formValues.accountId)
    const accountColor: string = account && account.color

    const transferAccountOptions = [
      ...selectOptions.accounts,
      VIRTUAL_OUT_OF_WALLET_ACCOUNT as Account,
    ]

    const fromAccount: Account = transferAccountOptions.find(
      ({ _id }) => _id === formValues.fromAccountId,
    )
    const fromAccountColor: string = fromAccount && fromAccount.color
    const background: string = !isTransfer(formValues) ? accountColor : fromAccountColor

    const toAccount: Account = transferAccountOptions.find(
      ({ _id }) => _id === formValues.toAccountId,
    )

    const fromCurrency: Currency = selectOptions.fromCurrencies.find(
      ({ _id }) => _id === formValues.fromCurrencyId,
    )
    const toCurrency: Currency = selectOptions.toCurrencies.find(
      ({ _id }) => _id === formValues.toCurrencyId,
    )

    const isAddForm = formType === FormType.ADD
    const isEditForm = formType === FormType.EDIT
    const shouldDisplayEditSaveButton = !_isEqual(
      recordForm.formValues,
      recordForm.initialFormValues,
    )

    const isAccountConnected = isConnected(account)
    const isRecordConnected =
      isAccountConnected || isConnected(fromAccount) || isConnected(toAccount)
    const isRecordUncleared = recordIsUncleared(formValues)

    return (
      <Grid.Column
        computer={10}
        tablet={10}
        mobile={16}
        className="form-main"
      >
        {errors.general && (
          <Message
            error
            content={<FormattedMessage id={errors.general} />}
          />
        )}
        <Form error>
          {(isAddForm || isEditForm) && (
            <div
              className="main-color-panel"
              style={{ background }}
            >
              <Grid centered>
                <Grid.Row>
                  <Grid.Column
                    computer={10}
                    tablet={10}
                    mobile={15}
                  >
                    <SelectRecordType
                      formValues={formValues}
                      isAccountConnected={isAccountConnected}
                      onRecordTypeChange={handleRecordTypeChange}
                    />
                  </Grid.Column>
                </Grid.Row>

                {!isTransfer(formValues) ? (
                  <Grid.Row>
                    <Grid.Column
                      computer={8}
                      tablet={8}
                      mobile={15}
                    >
                      <SelectAccount
                        formValues={formValues}
                        name="accountId"
                        value={formValues.accountId}
                        accounts={selectOptions.accounts}
                        errors={errors.accountId}
                        disabled={isAccountConnected}
                        label={<FormattedMessageLabel id="record.form.account" />}
                        onAccountChange={handleAccountChange}
                      />
                      <AmountInput
                        amountName="amount"
                        currencyName="currencyId"
                        amount={formValues.amount}
                        currencyId={formValues.currencyId}
                        recordTypeClass={getRecordTypeClass(formValues)}
                        currencies={selectOptions.currencies}
                        amountError={errors.amount}
                        currencyError={errors.currencyId}
                        amountDisabled={isAccountConnected}
                        currencyDisabled={isAccountConnected}
                        autoFocus
                        onAmountChange={handleFieldChange}
                        onCurrencyChange={handleFieldChange}
                      />
                    </Grid.Column>
                  </Grid.Row>
                ) : (
                  [
                    <Grid.Row key="input">
                      <Grid.Column
                        computer={7}
                        tablet={7}
                        mobile={15}
                      >
                        <SelectAccount
                          formValues={formValues}
                          name="fromAccountId"
                          value={formValues.fromAccountId}
                          accounts={transferAccountOptions}
                          excludeAccountId={isConnected(toAccount) ? toAccount?._id : undefined}
                          errors={errors.fromAccountId}
                          includeBankAccounts={formType === FormType.EDIT}
                          disabled={
                            (isConnected(fromAccount) &&
                              formValues.accountId === fromAccount._id) ||
                            (isRecordConnected && isRecordUncleared)
                          }
                          label={<FormattedMessageLabel id="record.form.from_account" />}
                          onAccountChange={handleAccountChange}
                        />
                        <AmountInput
                          amountName="fromAmount"
                          currencyName="fromCurrencyId"
                          amount={
                            !isOutOfWalletId(formValues.fromAccountId) && formValues.fromAmount
                          }
                          currencyId={
                            !isOutOfWalletId(formValues.fromAccountId) && formValues.fromCurrencyId
                          }
                          currencies={selectOptions.fromCurrencies}
                          amountError={errors.fromAmount}
                          currencyError={errors.fromCurrencyId}
                          recordTypeClass={getRecordTypeClass(formValues)}
                          amountDisabled={
                            isOutOfWalletId(formValues.fromAccountId) ||
                            isConnected(fromAccount) ||
                            (isConnected(toAccount) &&
                              formValues.fromCurrencyId === formValues.toCurrencyId)
                          }
                          currencyDisabled={
                            isOutOfWalletId(formValues.fromAccountId) || isConnected(fromAccount)
                          }
                          onAmountChange={this.handleTransferAmountChange(isRecordConnected)}
                          onCurrencyChange={handleTransferCurrencyChange(formValues, "fromAmount")}
                        />
                      </Grid.Column>
                      <div className="transfer-arrow">
                        <Icon name="arrow right" />
                      </div>
                      <Grid.Column
                        computer={7}
                        tablet={7}
                        mobile={15}
                      >
                        <SelectAccount
                          formValues={formValues}
                          name="toAccountId"
                          value={formValues.toAccountId}
                          accounts={transferAccountOptions}
                          excludeAccountId={fromAccount?._id}
                          errors={errors.toAccountId}
                          includeBankAccounts={formType === FormType.EDIT}
                          disabled={
                            (isConnected(toAccount) && formValues.accountId === toAccount._id) ||
                            (isRecordConnected && isRecordUncleared)
                          }
                          label={<FormattedMessageLabel id="record.form.to_account" />}
                          onAccountChange={handleAccountChange}
                        />
                        <AmountInput
                          amountName="toAmount"
                          currencyName="toCurrencyId"
                          amount={!isOutOfWalletId(formValues.toAccountId) && formValues.toAmount}
                          currencyId={
                            !isOutOfWalletId(formValues.toAccountId) && formValues.toCurrencyId
                          }
                          currencies={selectOptions.toCurrencies}
                          amountError={errors.toAmount}
                          currencyError={errors.currencyId}
                          recordTypeClass={getRecordTypeClass(formValues)}
                          amountDisabled={
                            isOutOfWalletId(formValues.toAccountId) ||
                            isConnected(toAccount) ||
                            (isConnected(fromAccount) &&
                              formValues.fromCurrencyId === formValues.toCurrencyId)
                          }
                          currencyDisabled={
                            isOutOfWalletId(formValues.toAccountId) || isConnected(toAccount)
                          }
                          onAmountChange={this.handleTransferAmountChange(isRecordConnected)}
                          onCurrencyChange={handleTransferCurrencyChange(formValues, "toAmount")}
                        />
                      </Grid.Column>
                    </Grid.Row>,
                    formValues &&
                      formValues.fromCurrencyId !== formValues.toCurrencyId &&
                      formValues.fromAmount > 0 &&
                      formValues.toAmount > 0 &&
                      fromCurrency &&
                      toCurrency && (
                        <Grid.Row
                          key="currency-rate"
                          className="currency-rate"
                        >
                          {/* tslint:disable-next-line:jsx-use-translation-function */}1 &nbsp;
                          {fromCurrency.code}
                          &nbsp; ≃ &nbsp;
                          {(formValues.toAmount / formValues.fromAmount).toFixed(4)}
                          &nbsp;
                          {toCurrency.code}
                        </Grid.Row>
                      ),
                  ]
                )}
              </Grid>
            </div>
          )}
          <div className="main-white-panel">
            <Grid centered>
              <Grid.Column
                computer={15}
                tablet={15}
              >
                <Form.Group widths="equal">
                  {!isTransfer(formValues) && (
                    <div className="field">
                      <SelectCategory
                        value={formValues.categoryId}
                        categoriesHierarchy={selectOptions.categoriesHierarchy}
                        categories={selectOptions.categories}
                        name="categoryId"
                        error={errors.categoryId}
                        onFieldChange={handleFieldChange}
                      />
                    </div>
                  )}
                  {!isAppBoard() ? (
                    <SelectMultiple
                      name="labels"
                      label={<FormattedMessageLabel id="record.form.labels" />}
                      value={formValues.labels}
                      options={labelOptions}
                      error={errors.labels}
                      onChange={handleFieldChange}
                    />
                  ) : (
                    <div className="field">
                      <SelectContact
                        allowAdd
                        contacts={selectOptions.contacts}
                        contactId={formValues.contactId}
                        onChange={(e, data) =>
                          handleFieldChange(e, { ...data, name: atob(data.name) })
                        }
                      />
                    </div>
                  )}
                </Form.Group>
                {(isAddForm || isEditForm) && (
                  <Form.Group widths="equal">
                    <DateTimePicker
                      label={<FormattedMessageLabel id="record.form.date" />}
                      name="recordDate"
                      disabled={isRecordConnected}
                      selectedDate={formValues.recordDate}
                      error={errors.recordDate}
                      maxDate={moment()}
                      type={DatePickerType.DATE}
                      onChange={handleDateTimeChange}
                    />
                    <DateTimePicker
                      label={<FormattedMessageLabel id="time" />}
                      name="recordDate"
                      disabled={isRecordConnected}
                      selectedDate={formValues.recordDate}
                      error={errors.recordDate}
                      type={DatePickerType.TIME}
                      onChange={handleDateTimeChange}
                    />
                  </Form.Group>
                )}
              </Grid.Column>
              <Grid.Column
                computer={10}
                tablet={10}
                mobile={15}
              >
                {isAddForm ? (
                  <>
                    <PrimaryButton
                      fluid
                      loading={recordForm.loading}
                      disabled={recordForm.loading}
                      onClick={() => handleSaveRecord()}
                    >
                      <FormattedMessage id="record.form.addRecord" />
                    </PrimaryButton>
                    <div
                      className="create-new-after-save"
                      onClick={() => handleSaveRecord(true)}
                    >
                      <FormattedMessage id="record.form.create-another-checkbox" />
                    </div>
                  </>
                ) : (
                  <div className="edit-buttons">
                    <Transition
                      visible={shouldDisplayEditSaveButton}
                      animation="fade"
                      duration={200}
                    >
                      <div>
                        <SecondaryButton
                          fluid
                          disabled={recordForm.loading}
                          onClick={handleRevertChanges}
                        >
                          <FormattedMessage id="form.revert" />
                        </SecondaryButton>
                        <PrimaryButton
                          fluid
                          loading={recordForm.loading}
                          disabled={recordForm.loading}
                          onClick={() => handleSaveRecord()}
                        >
                          <FormattedMessage id="form.save" />
                        </PrimaryButton>
                      </div>
                    </Transition>
                  </div>
                )}
              </Grid.Column>
            </Grid>
          </div>
        </Form>
      </Grid.Column>
    )
  }
}
