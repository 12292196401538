import {
  convertResultToEntity,
  convertResultToScalar,
  createRowFromDocument,
  insertInto,
  remove,
  sumAlias,
  WalletSQLInMemory,
} from "src/backend/db/inMemorySqlDb"
import { inMemoryTableNames } from "src/backend/db/inMemorySqlDbSchemaBuilder"
import { localDb } from "src/backend/db/documentDb"
import { AnyDocument, CouchDbResponse, CouchDbDocument, Id } from "src/types/CouchDb"
import { FilterType } from "src/types/Filter"
import { addDeletedAttribute, addUpdateAttributes } from "./helpers"
import { reduceBy } from "src/common/utils"
import { pipe } from "ramda"
import { RecordType } from "src/backend/enums"
import _isEmpty from "lodash/isEmpty"
import lf from "lovefield"
import latinize from "latinize"
import { findRecordIdsByLabelIds } from "src/backend/records/repository"
import Column = lf.schema.Column
import { AmountValues } from "src/backend/analytics/types"

export function findBy(tableName: string, key: string, value: string | number) {
  const sqlDb = WalletSQLInMemory.getInstance()
  const table = sqlDb.getTable(tableName)
  return sqlDb.select().from(table).where(table[key].eq(value)).exec()
}

export function findAll(tableName: string): Promise<AnyDocument[]> {
  const sqlDb = WalletSQLInMemory.getInstance()
  const table = sqlDb.getTable(tableName)
  return sqlDb.select().from(table).exec() as Promise<AnyDocument[]>
}

export function findAllAsHashMap<T extends Object>(tableName: string) {
  const sqlDb = WalletSQLInMemory.getInstance()
  const table = sqlDb.getTable(tableName)
  return sqlDb
    .select()
    .from(table)
    .exec()
    .then((entities: T[]) => entities.reduce(reduceBy<T>("_id"), {}))
}

/*
function omitEmptyAttributes(entity) {
  return _omitBy(entity, (entity) => isUndefinedOrNull(entity) || entity === '')
}
*/
export async function updateBulk(
  entities: Array<AnyDocument> = [],
  tableName: string = null,
  converter: Function = null,
): Promise<Array<any>> {
  if (_isEmpty(entities)) {
    return Promise.resolve([])
  }

  const entitiesWithSystemData = entities.map(addUpdateAttributes)

  const responses = await localDb.bulkDocs(entitiesWithSystemData)

  const conflictResponses = responses.filter((response): response is PouchDB.Core.Error => {
    return (response as PouchDB.Core.Error).status === 409
  })

  let entitiesWithResolvedConflicts = []
  if (conflictResponses.length > 0) {
    const conflictIds: Array<Id> = conflictResponses.map((response): Id => response.id)
    const updatedRevisions = await findLastRevs(conflictIds)
    const updatedConflictEntities = conflictResponses.map(
      updateConflictEntityToLastRevision(entities, updatedRevisions),
    )
    entitiesWithResolvedConflicts = await updateBulk(updatedConflictEntities)
  }

  const successResponses = responses.filter((response): response is PouchDB.Core.Response => {
    return (response as PouchDB.Core.Response).ok
  })
  const updatedEntities = successResponses.map(updateEntityToRevision(entitiesWithSystemData))
  const successEntities = await insertOrReplaceEntities(updatedEntities, tableName, converter)
  return [...successEntities, ...entitiesWithResolvedConflicts]
}

export async function removeBulk(entities: Array<CouchDbDocument> = []): Promise<{
  success?: (PouchDB.Core.Response | PouchDB.Core.Error)[]
  errors?: (PouchDB.Core.Response | PouchDB.Core.Error)[]
}> {
  if (_isEmpty(entities)) {
    return Promise.resolve({})
  }

  const entitiesWithSystemData: CouchDbDocument[] = entities.map(
    pipe(addDeletedAttribute, addUpdateAttributes),
  )
  const responses = await localDb.bulkDocs(entitiesWithSystemData)

  const success: PouchDB.Core.Response[] = responses.filter(
    (response): response is PouchDB.Core.Response => {
      return (response as PouchDB.Core.Response).ok
    },
  )
  const errors: PouchDB.Core.Error[] = responses.filter(
    (response) => (response as PouchDB.Core.Error).error,
  )

  await Promise.all(
    success.map((response) => {
      const entityToRemove = entitiesWithSystemData.find((entity) => entity._id === response.id)
      return remove(entityToRemove)
    }),
  )

  return { success, errors }
}

export async function insertOrReplaceEntities(
  entities: Array<CouchDbDocument>,
  tableName: string,
  converter: Function,
): Promise<Array<CouchDbDocument>> {
  if (!tableName) {
    return []
  }
  const createRow = await createRowFactory(tableName, converter)
  return insertInto(tableName, entities.map(createRow).filter(Boolean)) as Promise<
    Array<CouchDbDocument>
  >
}

function createRowFactory(tableName: string, converter: Function) {
  return (entity) => {
    return createRowFromDocument(tableName, entity, converter)
  }
}

export function findHighestPosition(tableName: string) {
  const sqlDb = WalletSQLInMemory.getInstance()
  const table = sqlDb.getTable(tableName)
  return sqlDb
    .select(lf.fn.max(table.position).as("position"))
    .from(table)
    .exec()
    .then(convertResultToScalar("position"))
}

export function createOrUpdateDocument(document: CouchDbDocument) {
  return localDb.put(document)
}

export function updateRevision(responses: Array<PouchDB.Core.Response>) {
  return (entity: CouchDbDocument) => {
    const entityResponse = responses.find((response) => response.id === entity._id)
    return { ...entity, _rev: entityResponse && entityResponse.rev }
  }
}

export function updateEntityToRevision(entities: Array<CouchDbDocument>) {
  return (response: PouchDB.Core.Response): CouchDbDocument => {
    const entityToUpdate = entities.find((entity) => entity._id === response.id)
    return { ...entityToUpdate, _rev: response.rev }
  }
}

export function updateConflictEntityToLastRevision(
  entities: Array<CouchDbDocument>,
  updatedRevisions: Array<{ _rev: Id; id: Id }>,
) {
  return (response: CouchDbResponse): CouchDbDocument => {
    const entityToUpdate = entities.find((entity) => entity._id === response.id)
    const revision = updatedRevisions.find((entity) => entity.id === response.id)
    return { ...entityToUpdate, _rev: revision && revision._rev }
  }
}

export function findLastRevs(documentIds: Array<Id>): Promise<Array<{ id: Id; _rev: Id }>> {
  return localDb
    .allDocs({ keys: documentIds })
    .then(
      (responses) =>
        responses &&
        responses.rows &&
        responses.rows.map((response) => ({ id: response.id, _rev: response.value.rev })),
    )
}

export function fetchGroupedSumOfRefAmountExpenses(
  filter: FilterType,
  groupBy: string[],
): Promise<AmountValues[]> {
  return fetchSummarizedData(
    RecordType.EXPENSE,
    "refAmount",
    filter,
    groupBy,
    AccountCurrencyOperand.NEQ,
  ) as Promise<AmountValues[]>
}

export function fetchGroupedSumOfRefAmountIncomes(
  filter: FilterType,
  groupBy: string[],
): Promise<AmountValues[]> {
  return fetchSummarizedData(
    RecordType.INCOME,
    "refAmount",
    filter,
    groupBy,
    AccountCurrencyOperand.NEQ,
  ) as Promise<AmountValues[]>
}

export function fetchGroupedSumOfAmountExpenses(
  filter: FilterType,
  groupBy: string[],
): Promise<AmountValues[]> {
  return fetchSummarizedData(
    RecordType.EXPENSE,
    "amount",
    filter,
    groupBy,
    AccountCurrencyOperand.EQ,
  ) as Promise<AmountValues[]>
}

export function fetchGroupedSumOfAmountIncomes(
  filter: FilterType,
  groupBy: string[],
): Promise<AmountValues[]> {
  return fetchSummarizedData(
    RecordType.INCOME,
    "amount",
    filter,
    groupBy,
    AccountCurrencyOperand.EQ,
  ) as Promise<AmountValues[]>
}

export const AccountCurrencyOperand = {
  EQ: "eq",
  NEQ: "neq",
}

async function fetchSummarizedData(
  type: number,
  columnName: string,
  inputFilter: FilterType,
  groupBy: string[],
  accountCurrencyOperand: string = AccountCurrencyOperand.EQ,
) {
  const sqlDb = WalletSQLInMemory.getInstance()
  const recordTable = sqlDb.getTable(inMemoryTableNames.RECORD)
  const currencyTable = sqlDb.getTable(inMemoryTableNames.CURRENCY)
  const accountTable = sqlDb.getTable(inMemoryTableNames.ACCOUNT)

  const filter = {
    ...inputFilter,
    type,
  }

  const recordIds = await findRecordIdsByLabelIds(filter.labelIds)

  const conditions = [
    accountCurrencyOperand === AccountCurrencyOperand.EQ
      ? accountTable.currencyId.eq(recordTable.currencyId)
      : accountTable.currencyId.neq(recordTable.currencyId),
    ...convertFilterToConditions(recordTable, filter, recordIds),
  ]

  let groupByFields: Array<Column> = []
  if (groupBy) {
    groupByFields = Array.isArray(groupBy)
      ? groupBy.map((field) => recordTable[field].as(field))
      : [recordTable[groupBy].as(groupBy)]
  }

  const query = sqlDb.select(
    ...groupByFields,
    lf.fn.sum(recordTable[columnName]).as(columnName + sumAlias(filter.type)),
    currencyTable.ratioToReferential.as("ratioToReferential"),
  )

  query.from(recordTable)

  query.innerJoin(accountTable, recordTable.accountId.eq(accountTable._id))
  query.leftOuterJoin(currencyTable, accountTable.currencyId.eq(currencyTable._id))

  if (conditions.length > 0) {
    query.where(lf.op.and(...conditions))
  }
  if (groupBy) {
    query.groupBy(...groupByFields)
  }
  query.orderBy(recordTable.recordDate)

  return query.exec()
}

export async function findMinMaxAmountByFilter(filter: FilterType) {
  const sqlDb = WalletSQLInMemory.getInstance()
  const recordTable = sqlDb.getTable(inMemoryTableNames.RECORD)

  const recordIds = await findRecordIdsByLabelIds(filter.labelIds)

  // filtering by type doesn't make any sense
  const conditions = [...convertFilterToConditions(recordTable, filter, recordIds)]

  const query = sqlDb
    .select(lf.fn.min(recordTable.amount).as("min"), lf.fn.max(recordTable.amount).as("max"))
    .from(recordTable)

  if (conditions.length > 0) {
    query.where(lf.op.and(...conditions))
  }

  return query.exec().then(convertResultToEntity())
}

export function convertFilterToConditions(
  recordTable: any,
  filter: FilterType,
  recordIds: Array<Id> = [],
) {
  const conditions = []
  const { start, end } = filter.period || { start: undefined, end: undefined }

  const MIN_FULLTEXT_LENGTH = 3

  if (start && end) {
    conditions.push(
      lf.op.and(
        recordTable.recordDate.gte(start.toDate()),
        recordTable.recordDate.lte(end.toDate()),
      ),
    )

    if (start) {
      conditions.push(recordTable.recordDate.gte(start.toDate()))
    }
    if (end) {
      conditions.push(recordTable.recordDate.lte(end.toDate()))
    }
  } else {
    if (start) {
      conditions.push(recordTable.recordDate.gte(start.toDate()))
    }
    if (end) {
      conditions.push(recordTable.recordDate.lte(end.toDate()))
    }
  }

  if (filter.type === RecordType.INCOME || filter.type === RecordType.EXPENSE) {
    conditions.push(recordTable.type.eq(filter.type))
  }
  if (!filter.transfer && filter.transfer !== undefined) {
    conditions.push(recordTable.transfer.neq(true))
  }
  if (filter.transfer) {
    conditions.push(recordTable.transfer.eq(true))
  }
  if (filter.accountIds) {
    conditions.push(recordTable.accountId.in(filter.accountIds))
  }
  if (filter.contactIds) {
    conditions.push(recordTable.contactId.in(filter.contactIds))
  }
  if (filter.currencyId) {
    conditions.push(recordTable.currencyId.in(filter.currencyId))
  }
  if (filter.paymentType) {
    conditions.push(recordTable.paymentType.in(filter.paymentType))
  }
  if (filter.recordState) {
    conditions.push(recordTable.recordState.in(filter.recordState))
  }
  if (filter.labelIds) {
    conditions.push(recordTable._id.in(recordIds))
  }

  const envelopesConditions = []
  if (filter.envelopeIds) {
    envelopesConditions.push(recordTable.envelopeId.in(filter.envelopeIds))
  }
  if (filter.superEnvelopeIds) {
    envelopesConditions.push(recordTable.superEnvelopeId.in(filter.superEnvelopeIds))
  }
  if (filter.categoryId) {
    envelopesConditions.push(recordTable.categoryId.in(filter.categoryId))
  }
  if (envelopesConditions.length > 0) {
    conditions.push(lf.op.or(...envelopesConditions))
  }

  if (filter.fulltext && filter.fulltext.length >= MIN_FULLTEXT_LENGTH) {
    try {
      const pattern = new RegExp(latinize(filter.fulltext).toLowerCase())
      conditions.push(recordTable.fulltextString.match(pattern))
    } catch (error) {
      // swallow error, no need to do anything
    }
  }

  if (filter.amount && filter.amount.min !== undefined) {
    conditions.push(recordTable.amount.gte(filter.amount.min))
  }
  if (filter.amount && filter.amount.max !== undefined) {
    conditions.push(recordTable.amount.lte(filter.amount.max))
  }

  return conditions
}
