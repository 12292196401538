import React, { Component } from 'react'
import { RootState } from 'src/types/State'
import { connect } from 'react-redux'
import {
  selectAccountsLoading,
  selectPendingConnectionRemoteAccounts,
  selectPendingConnectionProvider,
  selectConnectionAccount,
  selectIntegrationError,
} from 'src/frontend/scenes/integrations/newConnection/selectors'
import { Form, Message } from 'semantic-ui-react'
import styles from './SelectProviderAccount.module.less'
import { FormattedDate, FormattedMessage, FormattedMoney } from 'src/frontend/modules/intl'
import { Id } from 'src/types/CouchDb'
import _isEmpty from 'lodash/isEmpty'
import { createIntegratedAccounts } from 'src/frontend/scenes/integrations/newConnection/actions'
import { IntegrationConnection } from 'src/frontend/scenes/integrations/newConnection/types'
import IntegrationAccount = IntegrationConnection.IntegrationAccount
import { IntegrationProviders } from 'src/frontend/scenes/integrations/providersList/types'
import IntegrationProvider = IntegrationProviders.IntegrationProvider
import { Account } from 'src/types/Account'
import { PrimaryButton } from 'src/frontend/components/Buttons/Buttons'
import moment from 'moment'
import InfoPopup from 'src/frontend/components/InfoPopup'
import { getNoAccountsErrorText } from 'src/frontend/scenes/integrations/newConnection/helpers'

interface Props {
  remoteAccounts: IntegrationAccount[]
  loading: boolean
  provider: IntegrationProvider
  error: string,

  handleSubmit: Function
  appAccount: Account
}

function mapStateToProps(state: RootState) {
  return {
    remoteAccounts: selectPendingConnectionRemoteAccounts(state),
    provider: selectPendingConnectionProvider(state),
    loading: selectAccountsLoading(state),
    appAccount: selectConnectionAccount(state),
    error: selectIntegrationError(state),
  }
}

const mapDispatchToProps = {
  handleSubmit: createIntegratedAccounts,
}

interface State {
  [key: string]: boolean
}

class SelectProviderAccount extends Component<Props, State> {
  constructor(props) {
    super(props)
    if (props.remoteAccounts) {
      this.state = { ...this.props.remoteAccounts.reduce((acc, value) => ({ ...acc, [value.id]: false }), {}) }
    }
  }

  toggleAccountSelect = (remoteAccountId) => this.props.remoteAccounts && this.setState((prevState) => ({
    [remoteAccountId]: !prevState[remoteAccountId],
  }))

  exclusiveAccountSelect = (remoteAccountId) => this.props.remoteAccounts && this.setState((prevState) => ({
    ...this.props.remoteAccounts.reduce((acc, value) => ({ ...acc, [value.id]: false }), {}),
    [remoteAccountId]: !prevState[remoteAccountId],
  }))

  getCheckedSelectedAccountIds = (): Id[] => {
    return this.props.remoteAccounts ? Object.keys(this.state).filter((id) => this.state[id]) : []
  }

  render() {
    const { remoteAccounts, error, loading, provider, appAccount, handleSubmit } = this.props

    const accountsList = remoteAccounts && remoteAccounts.map((account) => {
      const FormComponent = appAccount
        ? Form.Radio
        : Form.Checkbox

      const changeHandler = appAccount
        ? this.exclusiveAccountSelect
        : this.toggleAccountSelect

      return (
        <li key={account.id}>
          <FormComponent
            checked={this.state[account.id]}
            onChange={() => changeHandler(account.id)}
            disabled={!!account.connectedAppAccountId}
            label={(
              <label>
                <div>{account.name}</div>
                <div>
                  {!account.connectedAppAccountId
                    ? <FormattedMoney currency={account.currencyCode} value={account.balance * 100} />
                    : <FormattedMessage id="integrations.newConnection.account-selection.already-connected" />}
                </div>
              </label>
            )}
          />
        </li>
      )
    })

    const errorMessage = error && <Message error><FormattedMessage id={error} /></Message>
    const accountSelectMessage = appAccount
      ? (
        <FormattedMessage
          id="integrations.newConnection.account-selection.connect-account"
          values={{
            providerName: <strong>{provider.name}</strong>,
            appAccountName: <strong>{appAccount.name}</strong>,
          }}
        />
      )
      : (
        <FormattedMessage
          id="integrations.newConnection.account-selection.create-account"
          values={{
            providerName: <strong>{provider.name}</strong>,
          }}
        />
      )

    return (
      <div className={styles.selectProviderAccount}>
        <p>
          {!error
            ? accountSelectMessage
            : errorMessage}
        </p>
        <Form>
          <ul>
            {accountsList}
          </ul>
          <div className={styles.infoNotice}>
            <InfoPopup
              label={<FormattedMessage id="integrations.error.no-accounts.header" />}
              message={(
                <div>
                  {getNoAccountsErrorText().map((errorString) => {
                    return (
                      <p key={errorString}>
                        <FormattedMessage
                          id={errorString}
                          values={{
                            emailAddress: 'support@budgetbakers.com',
                            date: <FormattedDate value={moment('2019-12-06').toDate()} />,
                          }}
                        />
                      </p>
                    )
                  })}
                </div>
              )}
            />
          </div>
          {remoteAccounts && (
            <PrimaryButton
              fluid
              type="submit"
              loading={loading}
              disabled={_isEmpty(this.getCheckedSelectedAccountIds()) || loading}
              onClick={() => handleSubmit(this.getCheckedSelectedAccountIds())}
            >
              <FormattedMessage id="integrations.newConnection.account-selection.action" />
            </PrimaryButton>
          )}

        </Form>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectProviderAccount)
