import React from 'react'
import {
  DropdownItemProps, DropdownProps, Form, Message, SemanticShorthandItem, HtmlLabelProps, DropdownOnSearchChangeData,
} from 'semantic-ui-react'
import { FormattedMessage } from 'src/frontend/modules/intl'
import './SelectionComponent.less'

interface Props {
  label?: SemanticShorthandItem<HtmlLabelProps>,
  className?: string,
  fluid?: boolean,
  disabled?: boolean,
  upward?: boolean,
  name: string,
  value: string | number,
  options: DropdownItemProps[],
  placeholder: string,
  error?: string,
  search?: boolean | ((options: DropdownItemProps[], value: string) => DropdownItemProps[])
  noResultsMessage?: React.ReactNode
  onSearchChange?: (
    event: React.SyntheticEvent<HTMLElement>,
    data: DropdownOnSearchChangeData,
  ) => void
  onChange: (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => void,
}

SelectionComponent.defaultProps = {
  disabled: false,
  upward: false,
  fluid: false,
  className: '',
  search: false,
  onSearchChange: undefined,
  noResultsMessage: undefined,
  error: undefined,
  label: undefined,
}

export default function SelectionComponent({
  label,
  className,
  name,
  fluid,
  value,
  options,
  placeholder,
  upward,
  error,
  disabled,
  search,
  noResultsMessage,
  onSearchChange,
  onChange,
}: Props) {
  return (
    <div className="field">
      <Form.Dropdown
        className={className}
        label={label}
        name={name}
        value={value}
        options={options}
        placeholder={placeholder}
        error={!!error}
        disabled={disabled}
        upward={upward}
        fluid={fluid}
        search={search}
        selection
        noResultsMessage={noResultsMessage}
        onSearchChange={onSearchChange}
        onChange={onChange}
      />
      {error
        && <Message className="error-message" error content={<FormattedMessage id={error} />} />}
    </div>
  )
}
