import React from "react"
import { connect } from "react-redux"
import { NavLink, Switch } from "react-router-dom"
import { FormattedMessage } from "src/frontend/modules/intl"
import { selectConsentPolicy } from "src/frontend/modules/user/selectors"
import Categories from "src/frontend/scenes/settings/categories/components/Categories"
import General from "src/frontend/scenes/settings/general/components/General"
import Accounts from "src/frontend/scenes/settings/accounts/components/Accounts"
import Templates from "src/frontend/scenes/settings/templates/components/Templates"
import Currencies from "src/frontend/scenes/settings/currencies/components/Currencies"
import Labels from "src/frontend/scenes/settings/labels/components/Labels"
import Privacy from "src/frontend/scenes/settings/privacy/components/Privacy"
import MagicRules from "src/frontend/scenes/settings/magicRules/components/MagicRules"
import styles from "src/frontend/scenes/settings/components/Settings.module.less"
import { Redirect, Route, RouteComponentProps, withRouter } from "react-router"
import ColumnLayout from "src/frontend/components/ColumnLayout/ColumnLayout"
import Billing from "src/frontend/scenes/settings/billing/Billing"
import { isAppBoard } from "src/common/environment"
import HelpPage from "../Help/Help"
import { mergeClasses } from "src/common/utils"

function mapStateToProps(state) {
  return {
    policy: selectConsentPolicy(state),
  }
}

interface Props extends RouteComponentProps<{ settingsPage?: string }> {
  policy: boolean
}

const Header = {
  general: "settings.general",
  categories: "settings.categories",
  currencies: "settings.currencies",
  accounts: "settings.accounts",
  labels: "settings.labels",
  templates: "settings.templates",
  privacy: "settings.privacy",
  rules: "settings.rules",
  billing: "settings.billing",
  help: "settings.help",
}

function Settings({ policy, match }: Props) {
  return (
    <ColumnLayout
      topPanel={<div />}
      content={
        <div className={mergeClasses(styles.content, "relative")}>
          <h2 className={styles.title}>
            {match.params.settingsPage && match.params.settingsPage in Header && (
              <FormattedMessage id={Header[match.params.settingsPage]} />
            )}
          </h2>
          <Switch>
            <Route
              exact
              path="/settings/accounts"
              component={Accounts}
            />
            <Route
              exact
              path="/settings/billing"
              component={Billing}
            />
            <Route
              exact
              path="/settings/categories"
              component={Categories}
            />
            <Route
              exact
              path="/settings/currencies"
              component={Currencies}
            />
            <Route
              exact
              path="/settings/templates"
              component={Templates}
            />
            <Route
              exact
              path="/settings/labels"
              component={Labels}
            />
            <Route
              exact
              path="/settings/rules"
              component={MagicRules}
            />
            <Route
              exact
              path="/settings/general"
              component={General}
            />
            <Route
              exact
              path="/settings/privacy"
              component={Privacy}
            />
            <Route
              exact
              path="/settings/help"
              component={HelpPage}
            />
            <Route
              path="/settings"
              component={() => <Redirect to="/settings/accounts" />}
            />
          </Switch>
        </div>
      }
      title={<FormattedMessage id="menu.settings" />}
      leftPanel={
        <div>
          <div className={styles.group}>
            {/* tslint:disable-next-line:jsx-use-translation-function */}
            <h2>{isAppBoard() ? "Board Control" : "Wallet"}</h2>
            <ul>
              <li>
                <NavLink to="/settings/accounts">
                  <FormattedMessage id="settings.accounts" />
                </NavLink>
              </li>

              <li>
                <NavLink to="/settings/categories">
                  <FormattedMessage id="settings.categories" />
                </NavLink>
              </li>

              <li>
                <NavLink to="/settings/currencies">
                  <FormattedMessage id="settings.currencies" />
                </NavLink>
              </li>

              <li>
                <NavLink to="/settings/templates">
                  <FormattedMessage id="settings.templates" />
                </NavLink>
              </li>

              <li>
                <NavLink to="/settings/labels">
                  <FormattedMessage id="settings.labels" />
                </NavLink>
              </li>
              <li>
                <NavLink to="/settings/rules">
                  <FormattedMessage id="settings.rules" />
                </NavLink>
              </li>
            </ul>
          </div>
          <div className={styles.group}>
            <h2>
              <FormattedMessage id="settings.general" />
            </h2>
            <ul>
              <li>
                <NavLink to="/settings/billing">
                  <FormattedMessage id="settings.billing" />
                </NavLink>
              </li>
              <li>
                <NavLink to="/settings/general">
                  <FormattedMessage id="settings.general" />
                </NavLink>
              </li>

              {policy && (
                <li>
                  <NavLink to="/settings/privacy">
                    <FormattedMessage id="settings.privacy" />
                  </NavLink>
                </li>
              )}
              <li>
                <NavLink to="/settings/help">
                  <FormattedMessage id="settings.help" />
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      }
    />
  )
}

export default withRouter(connect(mapStateToProps)(Settings))
