import React from 'react'
import styles from 'src/frontend/components/ColumnLayout/DetailLayout.module.less'
import { mergeClasses } from 'src/common/utils'
import { History } from 'history'

interface Props {
  title?: string | React.ReactNode
  actions?: string | React.ReactNode
  content: string | React.ReactNode
  history: History,
  className?: string
  transparent?: boolean
}

DetailLayout.defaultProps = {
  title: undefined,
  className: undefined,
  actions: undefined,
  transparent: false,
}

export default function DetailLayout({ history, transparent, title, actions, content, className }: Props) {
  return (
    <div className={mergeClasses(styles.detail, transparent && styles.transparent, className)}>
      <div>
        <div className={styles.topPanel}>
          <div className={styles.backButton} onClick={() => history.goBack()} />
          {title && <h2>{title}</h2>}
          {actions && (
            <div className={styles.actionButtons}>
              {actions}
            </div>
          )}
        </div>
        <div className={styles.contentPanel}>
          {content}
        </div>
      </div>
    </div>
  )
}
