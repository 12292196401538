export function convertToCategoryEntity(categoryVO) {
  const {
    superEnvelopeId,
    shouldBeLocalized,
    isGeneral,
    isHidden,
    ...category
  } = categoryVO

  return category
}
