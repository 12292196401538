import React from 'react'
import {connect} from 'react-redux'
import GenericConfirmationModal from 'src/frontend/scenes/settings/privacy/components/GenericConfirmationModal'
import {
    cancelDeleteUserAndDataDialog,
    openDeleteUserAndDataConfirmDialog,
} from 'src/frontend/scenes/settings/privacy/actions'
import {
    selectDeleteUserAndDataModalOpen,
    selectIsConsentLoading,
} from 'src/frontend/scenes/settings/privacy/selectors'

function mapStateToProps(state) {
    return {
        open: selectDeleteUserAndDataModalOpen(state),
        loading: selectIsConsentLoading(state),
    }
}

const mapDispatchToProps = {
    handleClose: cancelDeleteUserAndDataDialog,
    handleConfirm: openDeleteUserAndDataConfirmDialog,
}

interface Props {
    open: boolean
    handleConfirm: Function
    loading: boolean
    handleClose: () => void
}

function DeleteUserAndDataModal({open, handleConfirm, loading, handleClose}: Props) {
    return (
        <GenericConfirmationModal
            open={open}
            headerMessageId='settings.privacy.delete_all_data.title'
            contentMessageId='settings.privacy.delete_all_data.description'
            contentSecondMessageId='settings.privacy.delete_all_data.descriptionSecondPart'
            actions={[
                {
                    primary: false,
                    messageId: 'settings.privacy.remove_consent_cancel_button',
                    callback: handleClose,
                    inverted: true,
                    color: 'blue',
                },
                {
                    loading,
                    disabled: loading,
                    primary: true,
                    color: 'red',
                    messageId: 'settings.privacy.delete_all_data.confirm_button',
                    callback: handleConfirm,
                    inverted: false,
                },
            ]}
            close={handleClose}
        />
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(DeleteUserAndDataModal)
