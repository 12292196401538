import React, { useEffect, useState } from 'react'
import styles from 'src/frontend/scenes/dashboard/components/Gauge/Gauge.module.less'
import { limitValue } from 'src/common/helpers'
import { mergeClasses } from 'src/common/utils'

type Props = {
  value: number,
  variant: 'basic' | 'fancy',
  diameter?: string,
}

Gauge.defaultProps = {
  variant: 'basic',
  diameter: undefined,
}

export default function Gauge(props: Props) {

  useEffect(() => {
    const timeout = setTimeout(() => setValue(props.value), 100)

    return () => clearTimeout(timeout)
  }, [props.value])

  const [value, setValue] = useState(0)
  const START_VALUE_DEG = props.variant === 'basic' ? -225 : -224 // degrees of 0 value
  const RADIUS_DEG = props.variant === 'basic' ? 270 : 268 // degrees between 0 and 1 values
  const degreesValue = Math.round(START_VALUE_DEG + (limitValue(0, 1, value) * RADIUS_DEG))

  return (
    <div
      className={mergeClasses(styles.gauge, props.variant === 'fancy' ? styles.fancy : styles.basic)}
      style={props.diameter && { fontSize: props.diameter }}
    >
      <div className={styles.needle} style={{ transform: `rotate(${degreesValue}deg)` }} />
    </div>
  )
}
