import * as dashboardService from 'src/backend/dashboard/service'
import { receiveDashboardWidget } from 'src/frontend/scenes/dashboard/actions'
import { selectDashboardFilter, selectDashboardWidgets } from 'src/frontend/scenes/dashboard/selectors'
import { WidgetType } from 'src/frontend/scenes/dashboard/types'
import { FilterType } from 'src/types/Filter'
import { Id } from 'src/types/CouchDb'
import { findWidgetById } from 'src/frontend/scenes/dashboard/helpers'
import { Interval, RelativeIntervalType } from 'src/backend/enums'
import { intervalTypeToPeriod, isRelativeInterval } from 'src/backend/time/time'
import { getNextPeriodFilter } from 'src/backend/filters/helpers'
import { HashMap } from 'src/types/common'

export function changeInterval(id: Id, interval: Interval) {
  return async (dispatch: Function, getState: Function) => {
    const dashboardFilter: FilterType = selectDashboardFilter(getState())
    const widgets: HashMap<WidgetType> = selectDashboardWidgets(getState())

    const cashRatioLiquidityWidget = findWidgetById(widgets, id)

    if (!cashRatioLiquidityWidget) {
      return null
    }

    const { start, end } = intervalTypeToPeriod(interval)
    const period = { start, end, interval }
    const mergedFilter = { ...dashboardFilter, ...cashRatioLiquidityWidget.filter, period }
    const filter = isRelativeInterval(mergedFilter.period.interval)
      && cashRatioLiquidityWidget.options.relativeIntervalValue === RelativeIntervalType.NEXT
        ? getNextPeriodFilter(mergedFilter)
        : mergedFilter

    const data = await dashboardService.fetchCashRatioLiquidity(filter)

    const updatedWidget = {
      ...cashRatioLiquidityWidget,
      data,
      filter,
    }

    return dispatch(receiveDashboardWidget(updatedWidget))
  }
}
