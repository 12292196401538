import styles from 'src/frontend/scenes/settings/billing/BillingItem.module.less'
import FormattedMoney from 'src/frontend/modules/intl/components/FormattedMoney'
import { PDFDownloadLink } from '@react-pdf/renderer'
import Receipt from 'src/frontend/scenes/settings/billing/Receipt/components/Receipt'
import React from 'react'
import { getAppName } from 'src/common/environment'
import { formatDateYearShort } from 'src/frontend/modules/intl/i18n'
import {
  BraintreeTransaction,
  BraintreeTransactionStatus,
} from 'src/frontend/scenes/billing/transactionHistory/types'
import { getPaymentMethodImage, getPaymentMethodString } from 'src/frontend/scenes/settings/billing/helpers'
import { BasicIcon } from 'src/frontend/components/Icons/Icons'
import moment from 'moment'
import { FormattedMessage } from 'src/frontend/modules/intl'
import _isEmpty from 'lodash/isEmpty'
import _isEqual from 'lodash/isEqual'

interface Props {
  transactions: BraintreeTransaction[]
}

function TransactionsTable({ transactions }: Props) {
  return !_isEmpty(transactions)
    ? (
      <div className={styles.item}>
        <h3><FormattedMessage id="billing.receipts" /></h3>
        <div className={styles.transactionsTable}>
          {transactions && transactions.map((transaction) => {

            const nameDateString = moment(new Date(transaction.createdAt)).format('Y-MMM-DD')
            const pdfName = `${getAppName()}_receipt_${nameDateString}_${transaction.id}.pdf`

            const paymentMethodIconName = getPaymentMethodImage(transaction.paymentMethod)

            return (
              <div key={transaction.id} className={styles.row}>
                <div>{formatDateYearShort(new Date(transaction.createdAt))}</div>
                <div className={styles.paymentMethod}>
                  <BasicIcon name={paymentMethodIconName} />
                  <div className={styles.text}>{getPaymentMethodString(transaction.paymentMethod)}</div>
                </div>
                <div className={styles.amount}>
                  <FormattedMoney value={transaction.amount * 100} currency={transaction.currencyCode} />
                </div>
                {transaction.status === BraintreeTransactionStatus.AUTHORIZING
                || transaction.status === BraintreeTransactionStatus.AUTHORIZED
                || transaction.status === BraintreeTransactionStatus.SETTLEMENT_PENDING
                  ? <div><FormattedMessage id="billing.payment-processing" /></div>
                  : (
                    <div>
                      <PDFDownloadLink document={<Receipt transaction={transaction} />} fileName={pdfName}>
                        {({ loading }) => <FormattedMessage id={loading ? 'app.loading' : 'form.download'} />}
                      </PDFDownloadLink>
                    </div>
                  )}
              </div>
            )
          })}
        </div>
      </div>
    )
    : null
}

export default React.memo(TransactionsTable, (prevProps, nextProps) => {
  return _isEqual(prevProps.transactions, nextProps.transactions)
})
