import React from 'react'
import { Loader, Transition } from 'semantic-ui-react'
import { FormattedMessage } from 'src/frontend/modules/intl'
import styles from 'src/frontend/scenes/app/LoadingPage.module.less'

const DEFAULT_LOADING_TEXT = 'app.loading'
const FADE_DURATION = 1500


interface Props {
  isLoading: boolean,
  loadingText?: string,
  children
}

LoadingPage.defaultProps = {
  // This is preventing error thrown when component tries to render `FormattedMessage`
  // with empty string as `id` property.
  loadingText: DEFAULT_LOADING_TEXT,
}

function LoadingPage({ isLoading, loadingText, children }: Props) {

  if (isLoading) {
    return (
      <div className={styles.loadingPage}>
        {loadingText !== DEFAULT_LOADING_TEXT && (
          <Transition reactKey={loadingText} transitionOnMount duration={FADE_DURATION}>
            <div>
              <div className={styles.bbLogo} />
            </div>
          </Transition>
        )}
        <div className={styles.loadingText}>
          <Loader active inline />
          <Transition transitionOnMount duration={FADE_DURATION}>
            <div>
              <FormattedMessage id={loadingText} />
            </div>
          </Transition>
        </div>
        {loadingText !== DEFAULT_LOADING_TEXT && <div className={styles.offset} />}
      </div>
    )
  }

  return children
}

export default LoadingPage
