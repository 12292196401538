import React, { useEffect, useState } from "react"
import { AccountIcon } from "../Icons/Icons"
import { AccountType } from "src/backend/enums"

interface Props {
  src: string
  fallback?: React.ReactNode
}

const AssetIcon: React.FC<Props> = ({
  src,
  fallback = (
    <AccountIcon
      accountType={AccountType.INVESTMENT}
      isInvestments={true}
      size="36px"
    />
  ),
}) => {
  const [state, setState] = useState("loading")
  useEffect(() => {
    const img = new Image()
    img.onload = () => setState("success")
    img.onerror = () => setState("error")
    img.src = src
  }, [])

  return (
    <div
      style={{ width: 36, height: 36, borderRadius: 18, objectFit: "contain", overflow: "hidden" }}
    >
      {state === "error" && fallback}
      {state === "success" && (
        <img
          src={src}
          width={36}
          height={36}
          style={{ borderRadius: 18, objectFit: "contain" }}
        />
      )}
    </div>
  )
}

export default AssetIcon
