import React from 'react'
import BasicRecordList from 'src/frontend/components/RecordList/BasicRecordList'
import { FormattedMessage } from 'src/frontend/modules/intl'
import Widget from 'src/frontend/scenes/dashboard/components/Widget/Widget'
import { WidgetView } from 'src/frontend/scenes/dashboard/types'
import { Record } from 'src/types/Record'
import styles from 'src/frontend/scenes/dashboard/widgets/PlannedPaymentsList/PlannedPaymentsList.module.less'
import { widgetHasDeepEqualProps } from 'src/frontend/scenes/dashboard/helpers'
import { ComplementaryButton } from 'src/frontend/components/Buttons/Buttons'
import { Modal } from 'semantic-ui-react'

type Props = {
  widget: WidgetView & {
    data: {
      plannedPayments: Array<Record>,
      referentialCurrencyCode: string,
      refAmountSum: number,
    },
  },
}

const FUTURE_PAYMENTS_DEFAULT_COUNT = 3

function PlannedPaymentsList(props: Props) {
  const { widget } = props
  const { data, filter, options } = widget
  if (!data || !data.plannedPayments || !filter) {
    return <Widget.Placeholder />
  }

  const widgetAction = data.plannedPayments.length > FUTURE_PAYMENTS_DEFAULT_COUNT && (
    <Modal
      size="small"
      trigger={(
        <ComplementaryButton>
          <FormattedMessage id="show-more" />
        </ComplementaryButton>
      )}
    >
      <Modal.Content>
        <BasicRecordList
          noNotes
          records={data.plannedPayments}
        />
      </Modal.Content>
    </Modal>
  )

  const sampleData = React.useMemo(
    () => data.plannedPayments.slice(0, FUTURE_PAYMENTS_DEFAULT_COUNT),
    [data.plannedPayments],
  )

  const content = (data.plannedPayments.length > 0)
    ? (
      <Widget.Content className={styles.plannedPaymentsWidgetContent}>
        <BasicRecordList
          noNotes
          records={sampleData}
        />
      </Widget.Content>
    )
    : <Widget.EmptyContent type="planned" />
  return (
    <Widget>
      <Widget.Header title={widget.title} action={widgetAction} />
      <Widget.Summary
        displayWholeDateRange
        period={filter.period}
        value={data.refAmountSum}
        intervalType={options.relativeIntervalValue}
        currencyCode={data.referentialCurrencyCode}
      />
      {content}
    </Widget>
  )
}

export default React.memo(PlannedPaymentsList, widgetHasDeepEqualProps)
