import { logMissingAttributes } from 'src/common/logger'
import { isNull } from 'src/common/utils'
import { DataDoc } from 'src/types/CouchDb'
import { Template } from 'src/types/Template'

export function templateConverter() {
  return (dataDoc: Template | DataDoc<Template>): Template => {
    const doc: Template = (dataDoc as DataDoc<Template>).doc || dataDoc as Template

    const name = doc.name || ''

    logMissingAttributes(['name'], doc)

    let { place } = doc

    if (isNull(place) || place && !place.id) {
      place = undefined
    }

    return {
      ...doc,
      name,
      place,
    }
  }
}
