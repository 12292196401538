import React, { useEffect, useState } from 'react'
import { Category, SuperEnvelope } from 'src/types/Category'
import { Button, Dropdown, Form, Icon, Input, Message, Transition } from 'semantic-ui-react'
import { formatMessage } from 'src/frontend/modules/intl/i18n'
import CategoryOption from 'src/frontend/components/Categories/CategoryOption'
import { CategoryLevel } from 'src/frontend/modules/categories/enums'
import './SelectCategory.less'
import { FormattedMessage, FormattedMessageLabel } from 'src/frontend/modules/intl'
import { Id } from 'src/types/CouchDb'
import { SuperEnvelopesList } from 'src/frontend/components/Categories/SuperEnvelopesList'
import { EnvelopesList } from 'src/frontend/components/Categories/EnvelopesList'
import { SearchCategoriesList } from 'src/frontend/components/Categories/SearchCategoriesList'

interface Props {
  categoriesHierarchy: SuperEnvelope[]
  categories: Category[]
  name?: string
  value: string | number
  error?: string
  className?: string
  onFieldChange: (event: React.SyntheticEvent, values: { name: string, value: Id | number }) => void
}

SelectCategory.defaultProps = {
  name: undefined,
  error: undefined,
  className: '',
}

export default function SelectCategory({
  value,
  name,
  className,
  categoriesHierarchy,
  categories,
  error,
  onFieldChange,
}: Props) {
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const [searchValue, setSearchValue] = useState('')
  const [superEnvelopeId, setSuperEnvelopeId] = useState(null)

  const resetSuperEnvelopeId = () => setSuperEnvelopeId(null)
  const resetSearchValue = () => setSearchValue('')

  const selectedCategory = categories.find((category: Category) => category._id === value)
  const selectedSuperEnvelope = categoriesHierarchy.find((superEnvelope) => superEnvelope.id === superEnvelopeId)

  useEffect(() => {
    if (value && selectedCategory) {
      setSuperEnvelopeId(selectedCategory.superEnvelopeId)
    }
  }, [dropdownOpen])

  const handleSelectCategory = (e, values) => {
    onFieldChange(e, values)
    setDropdownOpen(false)
    resetSuperEnvelopeId()
    resetSearchValue()
  }

  const categoryName = selectedCategory?.name

  const renderText = (value && selectedCategory && !dropdownOpen)
    ? (
      <CategoryOption
        level={CategoryLevel.CATEGORY}
        color={selectedCategory.color}
        icon={selectedCategory.iconName}
        label={categoryName}
        size="26px"
      />
    )
    : (
      <div className="search-field" onClick={(e) => e.stopPropagation()}>
        {superEnvelopeId && selectedSuperEnvelope
          ? (
            <div className="back-button" onClick={resetSuperEnvelopeId}>
              <Button
                compact
                size="mini"
                onClick={resetSuperEnvelopeId}
                icon={<Icon name="chevron left" />}
              />
              <strong>{selectedSuperEnvelope?.name}</strong>
            </div>
          )
          : (
            <Input
              fluid
              size="mini"
              icon="search"
              value={searchValue}
              onChange={(_e, data) => setSearchValue(data.value)}
              placeholder={formatMessage('search.action')}
            />
          )}
      </div>
    )

  return (
    <div>
      <Form.Dropdown
        className={`select-category ${className}`}
        label={<FormattedMessageLabel id="record.form.envelope" />}
        fluid
        value={value || ''}
        selection
        open={dropdownOpen}
        error={!!error && !dropdownOpen}
        closeOnChange={false}
        selectOnBlur={false}
        placeholder={formatMessage('record.form.multiple.placeholder')}
        text={selectedCategory && !dropdownOpen || dropdownOpen ? renderText as unknown as string : undefined}
        onOpen={() => setDropdownOpen(true)}
        onClose={() => {
          setDropdownOpen(false)
          resetSuperEnvelopeId()
          resetSearchValue()
        }}
      >
        <Dropdown.Menu onClick={(e) => e.stopPropagation()}>
          <div className="selector-content">
            {searchValue.length < 3
              ? (
                <>
                  <Transition
                    unmountOnHide
                    visible={!(superEnvelopeId && selectedSuperEnvelope)}
                    animation="fade right"
                    duration={{ hide: 1, show: 600 }}
                  >
                    <SuperEnvelopesList
                      categoriesHierarchy={categoriesHierarchy}
                      onSelect={setSuperEnvelopeId}
                    />
                  </Transition>
                  <Transition
                    unmountOnHide
                    visible={!!(superEnvelopeId && selectedSuperEnvelope)}
                    animation="fade left"
                    duration={{ hide: 1, show: 600 }}
                  >
                    <EnvelopesList
                      categories={selectedSuperEnvelope && selectedSuperEnvelope.envelopes}
                      onSelect={handleSelectCategory}
                      fieldName={name}
                      value={value}
                    />
                  </Transition>
                </>
              )
              : (
                <SearchCategoriesList
                  searchValue={searchValue}
                  value={value}
                  fieldName={name}
                  categories={categories}
                  onSelect={handleSelectCategory}
                />
              )}
          </div>
        </Dropdown.Menu>
      </Form.Dropdown>
      <Message visible={!!error} error content={<FormattedMessage id={error} />} />
    </div>
  )
}
