import { PaymentMethodType } from 'src/frontend/scenes/billing/paymentMethod/enums'
import CardPayment from 'src/frontend/scenes/billing/paymentMethod/components/CardPayment'
import PaypalPayment from 'src/frontend/scenes/billing/paymentMethod/components/PaypalPayment'
import { getErrorCode } from 'src/common/helpers'
import { BraintreeError } from 'braintree-web'

export function getPaymentMethodComponent(paymentMethod: PaymentMethodType) {
  switch (paymentMethod) {
    default:
    case PaymentMethodType.CARD: {
      return CardPayment
    }

    case PaymentMethodType.PAYPAL: {
      return PaypalPayment
    }
  }
}

export function maskEmail(email: string): string {
  if (email) {
    const [firstPart, secondPart] = email.split('@')

    return firstPart && secondPart
      ? `${firstPart[0]}********${firstPart[firstPart.length - 1]}@${secondPart}`
      : email
  }

  return email
}


export const isProductExpiredError = (error) => {
  return getErrorCode(error) === 410
}

export function getBraintreeErrorMessage(error: BraintreeError): string {
  switch (error.code) {
    case 'HOSTED_FIELDS_TOKENIZATION_NETWORK_ERROR':
    case 'CLIENT_GATEWAY_NETWORK':
    case 'CLIENT_REQUEST_TIMEOUT':
    case 'CLIENT_RATE_LIMITED':
      return 'billing.error.network-error'

    case 'THREEDS_LOOKUP_ERROR':
    case 'THREEDS_LOOKUP_VALIDATION_ERROR':
      return 'billing.error.three-d-authentication-failed'

    default:
      return 'billing.error.failed'
  }
}
