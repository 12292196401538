import React from 'react'
import { AccountType } from 'src/backend/enums'
import { Icon, Popup } from 'semantic-ui-react'
import { formatMessage, FormattedMessage, FormattedMoney } from 'src/frontend/modules/intl'
import { CashLiquidityData } from 'src/frontend/scenes/dashboard/widgets/CashRatioLiquidity/types'

interface Props {
  cashLiquidity: CashLiquidityData,
}

export default function CashRatioLiquidityValueLabel({ cashLiquidity }: Props) {
  return (
    <span style={{ color: cashLiquidity.expenses > cashLiquidity.availableBalance ? cashLiquidity.color : null }}>
      <span className="value-header">
        <FormattedMessage
          id={cashLiquidity.accountType !== AccountType.CREDIT_CARD
            ? 'dashboard.widget.expected-balance'
            : 'dashboard.widget.expected-available-credit'}
        />
      </span>
      &nbsp;
      <FormattedMoney
        value={cashLiquidity.availableBalance - cashLiquidity.expenses}
        currency={cashLiquidity.currencyCode}
      />
      &nbsp;
      {cashLiquidity.expenses > cashLiquidity.availableBalance
        ? (
          <Popup
            trigger={<Icon color="red" name="warning sign" />}
            inverted
            content={formatMessage('dashboard.widget.overspending.text')}
          />
        )
        : null}
    </span>
  )
}
