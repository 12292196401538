import { selectImportsState } from 'src/frontend/scenes/imports/local-selectors'
import { selectSelectedAccountId } from 'src/frontend/scenes/imports/account/selectors'
import { isItemImported } from 'src/backend/imports/service'
import { createSelector } from 'reselect'
import { pipe } from 'ramda'
import { isItemVisible } from './helpers'

const selectItemsState = state => selectImportsState(state).items

export const selectOpenItems = state => selectItemsState(state).openItemIds

export const selectVisibleItems = state => selectItemsState(state).visibleItemIds

export const selectIsImportLoading = state => selectItemsState(state).importLoading

export const selectItemList = state => {
  const { itemList } = selectItemsState(state)
  return (itemList && itemList.length > 0) ? itemList : []
}

export const selectWaitingImportItems = state => {
  const itemList = selectItemList(state)
  const selectedAccountId = selectSelectedAccountId(state)
  return itemList.filter(item => item.accountId === selectedAccountId && !isItemImported(item))
}

export const selectUploadedItems = createSelector(
  [selectItemList, selectSelectedAccountId],
  (itemList, selectedAccountId) => {
    return itemList.filter(item => item.accountId === selectedAccountId)
  },
)

export const selectAccountImportedItems = createSelector(
  [selectUploadedItems],
  (uploadedItems) => uploadedItems.filter(isItemImported),
)

const selectVisibleImportedItemsCount = state => selectItemsState(state).visibleImportedItems

export const selectVisibleImportedItems = createSelector(
  [selectAccountImportedItems, selectVisibleImportedItemsCount],
  (importedItems, visibleImportedItemsCount) => {
    if (visibleImportedItemsCount >= importedItems.length) {
      return importedItems
    } else {
      return importedItems.slice(0, visibleImportedItemsCount)
    }
  },
)

export const selectIsAllImportedItemsVisible = createSelector(
  [selectAccountImportedItems, selectVisibleImportedItems],
  (allImportedItems, visibleImportedItems) => visibleImportedItems.length === allImportedItems.length,
)

export const selectIsFirstImport = createSelector(
  [selectAccountImportedItems],
  importedItems => importedItems.length === 0,
)

export const selectIsDeleteItemModalOpen = pipe(selectItemsState, itemsState => itemsState.deleteItemForm.open)

export const selectIsDeleteItemModalInProgress = pipe(
  selectItemsState,
  itemsState => itemsState.deleteItemForm.progress,
)

export const selectItemIdToDelete = pipe(selectItemsState, itemsState => itemsState.deleteItemForm.itemId)

export const selectIsItemToDeleteImported = createSelector(
  [selectItemIdToDelete, selectItemList],
  (itemId, itemList) => {
    const itemToDelete = itemList.find(item => item.itemId === itemId)
    return itemToDelete && isItemImported(itemToDelete)
  },
)

export const selectItemTransactionIdToDelete = pipe(
  selectItemsState,
  itemsState => itemsState.deleteItemForm.itemTransactionId,
)

export const selectItemNameToDelete = createSelector([selectItemIdToDelete, selectItemList], (itemId, itemList) => {
  const itemToDelete = itemList.find(item => item.itemId === itemId)
  return itemToDelete && itemToDelete.fileName
})

const selectVisibleTransactionIdAggregator = (importedItems, visibleItemIds) => {
  return importedItems
    .filter(isItemVisible(visibleItemIds))
    .map(item => item.transactionId)
}

export const selectVisibleTransactionIds = createSelector(
  [selectVisibleImportedItems, selectVisibleItems],
  selectVisibleTransactionIdAggregator,
)

export const selectLatestUploadItem = pipe(selectUploadedItems, (items) => items[0])
