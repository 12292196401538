import * as React from 'react'
import { Form, Message, Popup } from 'semantic-ui-react'
import { NAME_MAX_LENGTH } from 'src/backend/common/validator'
import { formatMessage, FormattedMessage } from 'src/frontend/modules/intl'

type Props = {
  trigger: React.ReactNode,
  open: boolean,
  name?: string,
  errors: {
    name?: string,
  },
  onChange: Function,
  onSave: Function,
  onOpen: (e: React.SyntheticEvent) => void,
  onClose: (e: React.SyntheticEvent) => void,
}

SaveFilterPopup.defaultProps = {
  name: '',
}

export default function SaveFilterPopup({ trigger, open, name, errors, onChange, onSave, onOpen, onClose }: Props) {
  return (
    <Popup
      basic
      trigger={trigger}
      on="click"
      position="top right"
      open={open}
      onClose={onClose}
      onOpen={onOpen}
      closeOnBlur
    >
      <h4><FormattedMessage id="filter.name-filter" /></h4>
      <Form>
        <Form.Group>
          <Form.Input
            name="name"
            placeholder={formatMessage('filter.default-name')}
            defaultValue={name}
            autoFocus
            maxLength={NAME_MAX_LENGTH}
            action={{ color: 'blue', content: formatMessage('form.save'), onClick: onSave }}
            error={!!errors.name}
            onChange={(_e, { value }) => onChange(value)}
          />
        </Form.Group>
      </Form>
      {errors.name && <Message className="error-message" error content={<FormattedMessage id={errors.name} />} />}
    </Popup>
  )
}
