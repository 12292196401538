/* eslint-disable react/prop-types */
/* eslint-disable no-shadow */
import React from 'react'
import { connect } from 'react-redux'
import {
  selectIsAllImportedItemsVisible,
  selectOpenItems,
  selectVisibleItems,
} from 'src/frontend/scenes/imports/items/selectors'
import ImportedItem from './ImportedItem'
import { isItemVisible } from '../helpers'
import { selectItemListDecorated } from 'src/frontend/scenes/imports/records/selectors'
import { Waypoint } from 'react-waypoint'
import { bindActionCreators } from 'redux'
import { showNext } from '../actions'

const mapStateToProps = state => ({
  importedItems: selectItemListDecorated(state),
  openItems: selectOpenItems(state),
  visibleItems: selectVisibleItems(state),
  allImportedItemsVisible: selectIsAllImportedItemsVisible(state),
})

const dispatchToProps = (dispatch) => bindActionCreators({ showNext }, dispatch)

const ImportedItemList = ({ importedItems, openItems, visibleItems, allImportedItemsVisible, showNext }) => {
  return importedItems.length > 0
    ? (
      <ul>
        {importedItems.map((item) => (
          <ImportedItem
            key={item.itemId}
            item={item}
            open={openItems.getOrDefault(item.itemId, false)}
            visible={isItemVisible(visibleItems)(item)}
          />
        ))}
        {!allImportedItemsVisible && (
        <li>
          <Waypoint onEnter={showNext} bottomOffset="-20%" />
        </li>
        )}
      </ul>
    )
    : null
}

export default connect(mapStateToProps, dispatchToProps)(ImportedItemList)
