import React, { CSSProperties } from 'react'

interface Props {
  value: number
  className?: string
  style?: CSSProperties
}

FormattedPercentage.defaultProps = {
  className: undefined,
  style: undefined,
}

export default function FormattedPercentage({ value, className, style }: Props) {
  return (
    <span className={className} style={style}>
      {Math.round((value || 0) * 100)}
      %
    </span>
  )
}
