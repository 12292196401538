export enum ChartSubjectType {
  BALANCE = "balance",
  CASH_FLOW = "cashFlow",
  CUMULATIVE_CASH_FLOW = "cumulativeCashFlow",
  INCOME = "income",
  CUMULATIVE_INCOME = "cumulativeIncome",
  CUMULATIVE_EXPENSE = "cumulativeExpense",
  EXPENSE = "expense",
  OPERATING_PROFIT_REPORT = "report.operating_profit",
  INCOMES_EXPENSES_REPORT = "report.incomes_expenses",
  BOARD_CASH_FLOW_REPORT = "report.cash_flow",
}

export const ChartGroupByType = {
  NONE: "none",
  ACCOUNTS: "accounts",
  CATEGORIES: "categories",
  INVESTMENTS: "investments",
}

export const LegendIconType = {
  SOLID: "solid",
  CATEGORY: "category",
  ACCOUNT: "account",
}
