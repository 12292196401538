import { ACCOUNTS_RECEIVE } from './actions'
import { IMPORTS_ACTIVATED } from 'src/frontend/scenes/imports/account/actions'
import { HashMap } from 'src/types/common'
import { Account } from 'src/types/Account'

export type AccountsState = HashMap<Account>

export function accounts(state: AccountsState = {}, action): AccountsState {
  switch (action.type) {
    case ACCOUNTS_RECEIVE: {
      return { ...action.payload }
    }
    case IMPORTS_ACTIVATED: {
      // Import email is required immediately, don't wait/polling until ACCOUNTS_RECEIVE
      // which is triggered later and full update is done
      const { accountId, importEmail } = action
      const updatedAccount = { ...state[accountId], importSettings: { email: importEmail } }
      return { ...state, [accountId]: updatedAccount }
    }
    default:
      return state
  }
}

export default accounts
