import React from 'react'
import styles from 'src/frontend/scenes/billing/components/Billing.module.less'
import PlanSelect from 'src/frontend/scenes/billing/product/components/PlanSelect'
import { BillingUIMethod } from 'src/frontend/scenes/billing/enums'


export default function PlanSelectPage() {
  return (
    <div className={styles.billingContainer}>
      <PlanSelect method={BillingUIMethod.PAGE} />
    </div>
  )
}
