import React from 'react'
import { connect } from 'react-redux'
import { DropdownProps, Grid, InputProps, Modal } from 'semantic-ui-react'
import SelectionComponent from 'src/frontend/components/SelectionComponent'
import { formatMessage, FormattedMessage } from 'src/frontend/modules/intl/index'
import * as actions from 'src/frontend/scenes/records/recordForm/actions'
import * as selectors from 'src/frontend/scenes/records/recordForm/selectors'
import * as accountsSelectors from 'src/frontend/modules/accounts/selectors'
import * as categoriesSelectors from 'src/frontend/modules/categories/selectors'
import * as templatesSelectors from 'src/frontend/modules/templates/selectors'
import * as labelsSelectors from 'src/frontend/modules/labels/selectors'
import { CategoryIcon } from 'src/frontend/components/Icons/Icons'
import IconOption from 'src/frontend/components/IconOption'
import './RecordForm.less'
import 'src/frontend/scenes/records/components/DateTimePickerWithRepeat.less'
import RecordMainForm from './RecordMainForm'
import RecordDetailForm from './RecordDetailForm'
import { RootState } from 'src/types/State'
import { Id } from 'src/types/CouchDb'
import { RecordType } from 'src/backend/enums'
import { RecordTransfer } from 'src/types/Record'
import { selectSortedContacts } from 'src/frontend/modules/contacts/selectors'
import { SelectOptions } from 'src/frontend/scenes/records/recordForm/components/types'
import { RecordFormState } from 'src/frontend/scenes/records/recordForm/reducer'
import { FormType } from 'src/frontend/scenes/records/recordForm/enums'
import { SecondaryButton } from 'src/frontend/components/Buttons/Buttons'


const mapStateToProps = (state: RootState) => {
  const recordForm = selectors.selectRecordForm(state)
  return {
    recordForm,
    selectOptions: {
      contacts: selectSortedContacts(state),
      currencies: recordForm.availableCurrencies,
      fromCurrencies: recordForm.fromAvailableCurrencies,
      toCurrencies: recordForm.toAvailableCurrencies,
      categoriesHierarchy: categoriesSelectors.selectCategoriesHierarchy(state),
      categories: categoriesSelectors.selectFlattenedCategoriesWithoutUnknownCategories(state),
      accounts: accountsSelectors.selectUserWritableAccounts(state),
      templates: templatesSelectors.selectSortedTemplates(state),
      labels: labelsSelectors.selectSortedLabels(state),
    },
  }
}

const mapDispatchToProps = (dispatch: Function) => ({
  boundActions: {
    handleApplyTemplate: (_event, { value }: { value: Id }) => dispatch(actions.applyTemplate(value)),
    handleResetTemplate: () => dispatch(actions.resetTemplate()),
    handleFieldChange: (_event, { name, value }) => dispatch(actions.changeFieldValue(name, value)),
    handleAccountChange: ({ name, value }) => dispatch(actions.changeAccount(name, value)),
    handleTransferCurrencyChange: (formValues, amountName) => {
      return (_event, { name, value }) => dispatch(actions.changeTransferCurrency(name, value, formValues, amountName))
    },
    handleTransferAmountChange: ({ name, value }, formValues, firstAmountName, isConnected) => {
      return dispatch(actions.changeTransferAmount(name, value, formValues, firstAmountName, isConnected))
    },
    handleDateTimeChange: (_event, { value }) => value && dispatch(actions.changeRecordDate(value.toDate())),
    handleRecordTypeChange: (type, transfer, formValues) => {
      return dispatch(actions.changeRecordType(type, transfer, formValues))
    },
    handleSaveRecord: (saveNext: boolean) => dispatch(actions.saveRecord(saveNext)),
    handleCloseForm: () => dispatch(actions.closeForm()),
    handleRevertChanges: () => dispatch(actions.revertChanges()),
  },
})

interface Props {
  recordForm: RecordFormState,
  userLocation?: {
    latitude: number,
    longitude: number,
  },
  selectOptions: SelectOptions,
  boundActions: {
    handleCloseForm: (SyntheticEvent) => void,
    handleApplyTemplate: (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => void,
    handleResetTemplate: (SyntheticEvent) => void,
    handleRecordTypeChange: (type: RecordType, transfer: boolean, record: RecordTransfer) => any,
    handleFieldChange: (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps | InputProps) => void,
    handleDateTimeChange: Function,
    handleAccountChange: Function,
    handleTransferCurrencyChange: Function,
    handleTransferAmountChange: Function,
    handleSaveRecord: (MouseEvent) => void,
    handleRevertChanges: (MouseEvent) => void,
  },
}

RecordFormModalContent.defaultProps = {
  userLocation: null,
}

function RecordFormModalContent({
  recordForm,
  selectOptions,
  boundActions,
}: Props) {
  const templatesOptions = selectOptions.templates.map(template => {
    return {
      key: template._id,
      text: template.name,
      value: template._id,
      content: (
        <IconOption
          label={template.name}
          icon={<CategoryIcon color={template.color} icon={template.icon} size="1.8rem" />}
        />
      ),
    }
  })

  const templateButton = recordForm.templateId
    ? (
      <SecondaryButton className="template-action" onClick={boundActions.handleResetTemplate}>
        <FormattedMessage id="record.form.reset_template" />
      </SecondaryButton>
    ) : null


  const templatePanel = (
    <>
      <SelectionComponent
        name="template"
        className="select-template"
        value={recordForm.templateId || null}
        options={templatesOptions}
        disabled={templatesOptions.length === 0}
        placeholder={formatMessage(templatesOptions.length !== 0
          ? 'record.form.select_template'
          : 'record.form.no_templates')}
        onChange={boundActions.handleApplyTemplate}
      />
      {templateButton}
    </>
  )

  const isAddRecord = recordForm.formType === FormType.ADD

  return recordForm.formValues && (
    <>
      <Modal.Header>
        <FormattedMessage
          id={isAddRecord
            ? { W: 'record.form.addRecord', B: 'transaction.form.add' }
            : { W: 'record.form.editRecords', B: 'transaction.form.edit' }}
          values={recordForm.recordIds && { recordsCount: recordForm.recordIds.length }}
        />
        {isAddRecord && templatePanel}
        <span className="add-record-close" onClick={boundActions.handleCloseForm} />
      </Modal.Header>
      <Modal.Content className="add-record-content">
        <Grid stretched className="add-record-form">
          <RecordMainForm
            recordForm={recordForm}
            selectOptions={selectOptions}
            {...boundActions}
          />
          <RecordDetailForm
            recordForm={recordForm}
            selectOptions={selectOptions}
            onFieldChange={boundActions.handleFieldChange}
          />
        </Grid>
      </Modal.Content>
    </>
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(React.memo(RecordFormModalContent, ((prevProps, nextProps) => {
  return prevProps.selectOptions === nextProps.selectOptions
    && prevProps.recordForm === nextProps.recordForm
    && prevProps.userLocation === nextProps.userLocation
})))
