import { Record } from 'src/types/Record'
import { RecordState } from 'src/backend/enums'
import moment from 'moment'
import _isEmpty from 'lodash/isEmpty'

export const MAX_DAYS_DIFFERENCE = 10

export function eachHasCompatibleDuplicity(records: Record[]): boolean {
  return !!records && !_isEmpty(records) && records.reduce((acc, value): boolean => {
    const valueHasDuplicities: boolean = records
      .filter((record) => record._id !== value._id)
      .every((record) => {
        return record.recordState === RecordState.CLEARED
          && record.amount === value.amount
          && record.currencyId === value.currencyId
          && record.accountId === value.accountId
          && record.type === value.type
          && record.reservedIntegrationSource === value.reservedIntegrationSource
          && record.recordDateDay
          && value.recordDateDay
          && Math.abs(moment(record.recordDateDay).diff(moment(value.recordDateDay), 'day')) <= MAX_DAYS_DIFFERENCE
      })

    return acc && valueHasDuplicities
  }, true)
}
