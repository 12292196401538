import React from 'react'
import { connect } from 'react-redux'
import { FormattedMessage } from 'src/frontend/modules/intl'
import { logoutHard } from 'src/frontend/scenes/app/actions'
import { PrimaryButton } from 'src/frontend/components/Buttons/Buttons'

const mapDispatchToProps = {
  handleLogoutHard: logoutHard,
}

interface Props {
  handleLogoutHard: (e: React.SyntheticEvent) => void,
}

function SafeLogout({ handleLogoutHard }: Props) {
  return (
    <div className="item">
      <div className="label">
        <FormattedMessage id="settings.general.remove_local_data" />
      </div>
      <p>
        <FormattedMessage id="settings.general.remove_local_data.text" />
      </p>
      <div>
        <PrimaryButton onClick={handleLogoutHard}>
          <FormattedMessage id="settings.general.remove_local_data" />
        </PrimaryButton>
      </div>
    </div>
  )
}

export default connect(null, mapDispatchToProps)(SafeLogout)
