import React, { SyntheticEvent } from 'react'
import { Form, FormInputProps, Message } from 'semantic-ui-react'
import { FormattedMessage, FormattedMessageLabel } from 'src/frontend/modules/intl'
import { MIN_PASSWORD_LENGTH, MAX_PASSWORD_LENGTH } from "src/frontend/scenes/auth/constants"


interface Props {
  name?: string,
  label?: React.ReactNode | string
  error?: string
  autoComplete?: 'new-password' | 'current-password'
  value: string
  onChange: (e: SyntheticEvent, data: FormInputProps) => void
}

PasswordField.defaultProps = {
  name: 'password',
  label: <FormattedMessageLabel id="auth.password" />,
  error: '',
  autoComplete: 'new-password',
}

export default function PasswordField({ name, label, value, error, autoComplete, onChange }: Props) {
  return (
    <>
      <Form.Input
        type="password"
        name={name}
        label={label}
        value={value}
        autoComplete={autoComplete}
        onChange={onChange}
      />

      {error && (
        <Message
          className="error-message"
          error
          content={
            <FormattedMessage
              id={error}
              values={{ minLength: MIN_PASSWORD_LENGTH, maxLength: MAX_PASSWORD_LENGTH }}
            />
          }
        />
      )}
    </>
  )
}
