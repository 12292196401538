import _capitalize from 'lodash/capitalize'
import _isEmpty from 'lodash/isEmpty'
import { isUndefinedOrNull } from 'src/common/utils'
import { IntegrationError } from 'src/backend/integrations/errors'
import { BaseError } from 'make-error'

export function withChanges(previousGroupedData, currentGroupedData, fields) {
  const result = {}
  const groupedData = currentGroupedData || previousGroupedData

  if (_isEmpty(groupedData)) {
    return result
  }

  Object.keys(groupedData).forEach(key => {
    if (Array.isArray(fields)) {
      result[key] = { ...groupedData[key] }
      fields.forEach(field => {
        const previousValue = (previousGroupedData && previousGroupedData[key]) ? previousGroupedData[key][field] : 0
        const currentValue = groupedData[key][field]
        result[key][`previous${_capitalize(field)}`] = previousValue
        result[key][`${field}Change`] = countPercentChange(previousValue, currentValue)
      })
    } else {
      result[key] = { ...groupedData[key] }
      const previousValue = (previousGroupedData && previousGroupedData[key]) ? previousGroupedData[key][fields] : 0
      const currentValue = groupedData[key][fields]
      result[key][`previous${_capitalize(fields)}`] = previousValue
      result[key][`${fields}Change`] = countPercentChange(previousValue, currentValue)
    }
  })

  return result
}

export function countPercentChange(previousValue: number = 0, currentValue: number = 0): number {
  const previous = previousValue || 0
  const current = currentValue || 0

  if (previous !== 0 && previous === current) {
    return 0
  }
  return (previous !== 0) ? (current - previous) / Math.abs(previous) : null
}

export const isAuthorizationError = (error) => {
  return getErrorCode(error) === 401
}

export const isQuotaExceededError = (error) => {
  return error.name === 'indexed_db_went_bad' && error.reason === 'QuotaExceededError'
}

export const isUserNotFoundError = (error) => {
  return getErrorCode(error) === 404
}

export const isServerError = (error) => {
  return getErrorCode(error) === 500
}

export const isServiceUnavailableError = (error) => {
  return getErrorCode(error) === 503
}

export const isIntegrationError = (error: BaseError): error is IntegrationError => {
  return error instanceof IntegrationError
}

export function getErrorCode(error) {
  return error && error.response && error.response.status
}

export function getErrorText(error) {
  if (isUndefinedOrNull(error)) {
    const UNKNOWN = 'Unknown'
    console.warn(`getErrorText(): error is null/undefined, returning '${UNKNOWN}'`)
    return UNKNOWN
  } else if (typeof error === 'string') {
    return error
  } else if (typeof error.reason === 'string') {
    return `${error.name}: ${error.reason}`
  } else if (typeof error.message === 'string') {
    if (typeof error.name === 'string' && error.message.includes(error.name)) {
      return error.message
    } else if (typeof error.name === 'string' && !error.message.includes(error.name)) {
      return `${error.name}: ${error.message}`
    } else {
      return error.message
    }
  } else {
    const strippedErrorLength = 40
    console.warn(
      `getErrorText(): Unknown error type, returning the first ${strippedErrorLength} characters of serialized error.`,
      error,
    )
    const errorJson = JSON.stringify(error)
    return errorJson.substr(0, Math.min(strippedErrorLength, errorJson.length))
  }
}

export function limitValue(min: number = -Infinity, max: number = Infinity, value: number): number {
  if (value < min) {
    return min
  }

  if (value > max) {
    return max
  }

  return value
}

export function onEnterKey(eventHandler) {
  return (e, ...attrs) => {
    if (e.key === 'Enter') {
      eventHandler(e, ...attrs)
    }
  }
}
