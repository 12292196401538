import { CouchDbDocument } from "src/types/CouchDb"

export type ConfigureDocument = CouchDbDocument & Configure

export enum ChangeSource {
  General,
  Sharing,
  Billing,
  ProfileInfo,
  CouchDb,
}

export enum ChangeType {
  Add,
  Modify,
  Remove,
}

export type Configure = {
  key: string
  value: string
  changeSource: ChangeSource
  changeType: ChangeType
}
