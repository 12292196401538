import React from 'react'
import { Icon } from 'semantic-ui-react'
import { AccountWithBalance } from 'src/frontend/scenes/dashboard/types'
import { FilterType } from 'src/types/Filter'
import { FormattedMessage } from 'src/frontend/modules/intl'
import styles from './SelectAllAccounts.module.less'
import { ComplementaryButton } from 'src/frontend/components/Buttons/Buttons'

type Props = {
  areAllAccountsSelected: boolean,
  filter: FilterType,
  accounts: Array<AccountWithBalance>,
  onSelectAll: (e: React.SyntheticEvent) => void,
}

export default function SelectAllAccounts({ areAllAccountsSelected, filter, accounts, onSelectAll }: Props) {
  const hasExcludedAccounts: boolean = accounts.some(account => account.excludeFromStats)
  return !areAllAccountsSelected && (
    <div className={styles.selectRow}>
      <div>
        {!areAllAccountsSelected && filter.accountIds && (
          <ComplementaryButton className={styles.deselectAccounts} color="blue" onClick={onSelectAll}>
            {filter.accountIds.length > 1
              ? (
                <span>
                  <FormattedMessage
                    id="dashboard.selected-accounts"
                    values={{ accountsCount: filter.accountIds.length }}
                  />
                  &nbsp;
                  <Icon name="close" />
                </span>
              )
              : (
                <FormattedMessage
                  id={!hasExcludedAccounts
                    ? 'dashboard.selected-all-accounts'
                    : 'dashboard.selected-all-non-excluded-accounts'}
                />
              )
            }
          </ComplementaryButton>
        )}
      </div>
    </div>
  )
}
