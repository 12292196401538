import {
  convertResultToEntity,
  fetchAllFromTable,
  WalletSQLInMemory,
} from "src/backend/db/inMemorySqlDb"
import { inMemoryTableNames } from "src/backend/db/inMemorySqlDbSchemaBuilder"
import * as commonRepository from "src/backend/common/repository"
import { Label } from "src/types/Label"
import lf from "lovefield"

export function findById(id): Promise<Label> {
  const sqlDb = WalletSQLInMemory.getInstance()
  const labelTable = sqlDb.getTable(inMemoryTableNames.LABEL)
  return sqlDb
    .select()
    .from(labelTable)
    .where(labelTable._id.eq(id))
    .exec()
    .then(convertResultToEntity())
}

export function findAllAsHashMap(): Promise<{ [key: string]: Label }> {
  return commonRepository.findAllAsHashMap(inMemoryTableNames.LABEL)
}

export function findAutoAssignLabels(): Promise<Label[]> {
  const sqlDb = WalletSQLInMemory.getInstance()
  const labelTable = sqlDb.getTable(inMemoryTableNames.LABEL)
  return sqlDb
    .select()
    .from(labelTable)
    .where(lf.op.and(labelTable.autoAssign.eq(true), labelTable.archived.eq(false)))
    .exec() as Promise<Label[]>
}

export function getAllLabels(): Promise<Label[]> {
  return fetchAllFromTable<Label>(inMemoryTableNames.LABEL) as Promise<Label[]>
}
