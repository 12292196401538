import React from 'react'
import { connect } from 'react-redux'
import { Segment } from 'semantic-ui-react'
import { FormattedMessage } from 'src/frontend/modules/intl'
import * as actions from '../actions'
import './WaitingImportItem.less'
import { RootState } from 'src/types/State'
import { selectIsImportLoading } from 'src/frontend/scenes/imports/items/selectors'
import { PrimaryButton, SecondaryButton } from 'src/frontend/components/Buttons/Buttons'

function mapStateToProps(state: RootState) {
  return {
    loading: selectIsImportLoading(state),
  }
}

const mapDispatchToProps = {
  confirmDeleteItem: actions.confirmDeleteItem,
  importItem: actions.importItem,
}

interface Props {
  item: {
    fileName: string,
    itemId: string,
  },
  loading: boolean,
  importItem: Function
  confirmDeleteItem: Function
}

function WaitingImportItem({ item, loading, importItem, confirmDeleteItem }: Props) {

  return (
    <li className="waiting-import-item">
      <Segment>
        <div className="item-name">{item.fileName}</div>
        <div className="item-status"><FormattedMessage id="imports.items.item_list.waiting_item" /></div>
        <div className="item-buttons">
          <SecondaryButton
            disabled={loading}
            onClick={() => confirmDeleteItem(item.itemId)}
          >
            <FormattedMessage id="form.cancel" />
          </SecondaryButton>
          <PrimaryButton
            disabled={loading}
            loading={loading}
            onClick={() => importItem(item.itemId)}
          >
            <FormattedMessage id="imports.items.item_list.import_button" />
          </PrimaryButton>
        </div>
      </Segment>
    </li>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(WaitingImportItem)
