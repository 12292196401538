import * as logger from 'src/common/logger'
import { WalletValidationError } from 'src/backend/errors'

export function buildLocalizedErrorMessage(error: { messageId?: string }): string {
  if (error.messageId) {
    return error.messageId
  } else {
    return 'app.error.default_error_message'
  }
}

export function authModuleErrorHandler(setSubmitting, setErrors, contextMessage = 'Unexpected error') {
  return (error) => {
    if (!(error instanceof WalletValidationError)) {
      logger.captureException(error, contextMessage)
    }
    setSubmitting(false)
    setErrors({ general: buildLocalizedErrorMessage(error) })
  }
}
