import { IntegrationProviders } from "src/frontend/scenes/integrations/providersList/types"
import {
  BraintreeBillingAddress as BraintreeBillingAddressMessage,
  BraintreeNonce,
  ImportActivateRequest,
  ImportActivationAutomaticRequest,
  ImportDeactivationAutomaticRequest,
  ImportParseStatementRequest,
  IntegrationAccountConnectionRequest,
  IntegrationAccountCreationRequest,
  IntegrationLoginOAuthFinish,
  IntegrationLoginRequest,
  IntegrationProvidersByCountrySearchRequest,
  IntegrationTransactionDuplicityRequest,
  Tracking as TrackingMessage,
  Transaction,
  VerifiedProfile as UserVerifiedProfile,
  VoucherTransaction,
} from "static/proto/ribeez_pb"
import { Id } from "src/types/CouchDb"
import { WalletAuthError, WalletError, WalletRestApiError } from "src/backend/errors"
import { validateSuccessResponse } from "src/backend/rest/helpers"
import { BaseError } from "make-error"
import { FormAttributeField } from "src/frontend/scenes/integrations/types"
import { IntegrationConnection } from "src/frontend/scenes/integrations/newConnection/types"
import IntegrationAccountCreationBlueprint = IntegrationConnection.IntegrationAccountCreationBlueprint
import IntegrationAccountConnectionPair = IntegrationConnection.IntegrationAccountConnectionPair
import { Billing } from "src/types/Billing"
import Product = Billing.Product
import { BraintreeBillingAddress } from "src/frontend/scenes/billing/billingInformation/types"
import { VerifiedProfile } from "src/types/User"
import { Tracking } from "src/frontend/scenes/kb/components/types"
import Impression = Tracking.Impression

const buildAndEncodeMessage = (messageType, messagePayload) => {
  const message = messageType.fromObject(messagePayload)
  const error = messageType.verify(message)
  if (error) {
    throw new Error("Message is not valid")
  }
  return messageType.encode(message).finish()
}

export const buildImportParseStatementRequest = (settings, mapping) => {
  return buildAndEncodeMessage(ImportParseStatementRequest, { settings, mapping })
}

export const buildImportActivationAutomaticRequest = (settingsId) => {
  return buildAndEncodeMessage(ImportActivationAutomaticRequest, { settingsId })
}

export const buildImportDeactivationAutomaticRequest = (settingsId) => {
  return buildAndEncodeMessage(ImportDeactivationAutomaticRequest, { settingsId })
}

export const buildImportActivateRequest = (accountId: Id) => {
  return buildAndEncodeMessage(ImportActivateRequest, { accountId })
}

export const buildIntegrationDuplicityRequest = (remoteTrxId: Id[]) => {
  return buildAndEncodeMessage(IntegrationTransactionDuplicityRequest, { remoteTrxId })
}

export const buildIntegrationProvidersByCountryRequest = (countryCode: string) => {
  return buildAndEncodeMessage(IntegrationProvidersByCountrySearchRequest, { countryCode })
}

export const buildIntegrationFinishOauthRequest = (queryParams: string) => {
  return buildAndEncodeMessage(IntegrationLoginOAuthFinish, { queryParams })
}

export const buildIntegrationLoginRequest = (
  values: FormAttributeField[],
  id?: string,
  inputType?: IntegrationProviders.IntegrationRecipe.InputType,
) => {
  const typeAsKey = inputType
    ? getEnumKeyByValue(IntegrationProviders.IntegrationRecipe.InputType, inputType)
    : undefined
    return buildAndEncodeMessage(IntegrationLoginRequest, {
      values: values,
      id: id,
      type: typeAsKey,
    })
}

export const buildIntegrationAccountCreationRequest = (
  accounts: IntegrationAccountCreationBlueprint[],
) => {
  return buildAndEncodeMessage(IntegrationAccountCreationRequest, { accounts })
}

export const buildIntegrationAccountConnectionRequest = (
  accounts: IntegrationAccountConnectionPair[],
) => {
  return buildAndEncodeMessage(IntegrationAccountConnectionRequest, { accounts })
}

export const buildTransactionSubmitRequest = (product: Product, token: string) => {
  return buildAndEncodeMessage(Transaction, { product, token, transactionId: "" })
}

export const buildUpdateBillingAddressRequest = (billingAddress: BraintreeBillingAddress) => {
  return buildAndEncodeMessage(BraintreeBillingAddressMessage, billingAddress)
}

export const buildUpdatePaymentMethodRequest = (nonce: string) => {
  return buildAndEncodeMessage(BraintreeNonce, { nonce })
}

export const buildVerifiedProfileRequest = (verifiedProfile: VerifiedProfile) => {
  return buildAndEncodeMessage(UserVerifiedProfile, verifiedProfile)
}

export const buildImpressionRequest = (impression: Impression) => {
  return buildAndEncodeMessage(TrackingMessage.Impression, impression)
}

export const buildVoucherTransaction = (voucherCode: string) => {
  return buildAndEncodeMessage(VoucherTransaction, { code: voucherCode })
}

export const deserializeProtobuffResponse = (protobuffEntity) => {
  return (response) => {
    return Promise.resolve()
      .then(() => response.arrayBuffer())
      .then((data) => new Uint8Array(data))
      .then((data) => protobuffEntity.toObject(protobuffEntity.decode(data)))
      .catch((error) => {
        throw new WalletError(
          `Can't deserialize Protobuffer entity: ${protobuffEntity.name}`,
          error,
        )
      })
  }
}

export const validateProtobuffResponse = (entity: any) => (response: Response) => {
  if (response.status === 401) {
    throw new WalletAuthError("Not authorized on backend", response)
  } else if (response.status === 412) {
    const errorMessage = `${response.status} - ${response.statusText}!`
    return deserializeProtobuffResponse(entity)(response).then((error) => {
      // FIXME For early development when guessing how returns errors
      console.log("Deserialized error:", error)
      throw new WalletRestApiError(errorMessage, { ...error, status: response.status })
    }) as unknown as Response
  } else {
    return validateSuccessResponse(response)
  }
}

export const handleCaughtError = (error) => {
  if (error instanceof BaseError) {
    // FIXME For early development when guessing how returns errors
    console.log(`Rethrowing the exception ${error.name}: ${error.message}`)
    throw error
  }

  throw new WalletError(error.message ? error.message : error, error)
}

function getEnumKeyByValue<T extends { [key: string]: string | number }>(
  enumObject: T,
  value: string | number,
): keyof T | undefined {
  return Object.keys(enumObject).find((key) => enumObject[key] === value) as keyof T | undefined
}
