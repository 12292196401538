import moment, { Moment, MomentInput, unitOfTime } from "moment"
import {
  CompareToValue,
  Interval,
  IntervalCompareToType,
  IntervalGranularityType,
  RelativeIntervalType,
} from "src/backend/enums"
import { isUndefinedOrNull, parseIntDecimal } from "src/common/utils"
import { formatMessage, formatInterval } from "src/frontend/modules/intl/i18n"
import { Period } from "src/types/Filter"

const { WEEK: WEEK, MONTH: MONTH, YEAR: YEAR } = Interval

export const UNIT_DAY = "day"
const UNIT_WEEK = "week"
const UNIT_HOUR = "hour"
const UNIT_MONTH = "month"
const UNIT_YEAR = "year"

const UNIT_DAYS = "days"

export function getYearAgoPeriod(start: Moment, end: Moment, interval: Interval) {
  return {
    start: start ? start.clone().subtract(1, UNIT_YEAR) : undefined,
    end: end ? end.clone().subtract(1, UNIT_YEAR) : undefined,
    interval: interval || undefined,
  }
}

export function previousPeriod(
  start: Moment,
  end: Moment,
  interval: Interval,
  compareToType?: IntervalCompareToType,
) {
  let tmpStart: Moment
  let tmpEnd: Moment

  if (
    interval &&
    (interval === WEEK || interval === MONTH || interval === YEAR) &&
    isUndefinedOrNull(compareToType)
  ) {
    tmpStart = start.clone().subtract(1, interval).startOf(interval)
    tmpEnd = end.clone().subtract(1, interval).endOf(interval)
  } else {
    const intervalSize = end.diff(start, UNIT_DAY)

    if (!isUndefinedOrNull(compareToType) && compareToType !== IntervalCompareToType.NONE) {
      const compareToValue = CompareToValue[compareToType]
      const intervalArray: unknown[] = compareToValue.split("-")
      tmpEnd = start
        .clone()
        .add(intervalSize, UNIT_DAY)
        .subtract(...intervalArray)
        .endOf(UNIT_DAY)
    } else {
      tmpEnd = start.clone().subtract(1, UNIT_DAY).endOf(UNIT_DAY)
    }

    tmpStart = tmpEnd.clone().startOf(UNIT_DAY)
    if (intervalSize > 0) {
      tmpStart.subtract(intervalSize, UNIT_DAY)
    }
  }

  return {
    start: tmpStart,
    end: tmpEnd,
    interval: interval || undefined,
  }
}

export function nextPeriod(start: Moment, end: Moment, interval: Interval) {
  let tmpStart: Moment
  let tmpEnd: Moment

  if (interval && (interval === WEEK || interval === MONTH || interval === YEAR)) {
    tmpStart = start.clone().add(1, interval).startOf(interval)
    tmpEnd = end.clone().add(1, interval).endOf(interval)
  } else {
    const intervalSize = end.diff(start, UNIT_DAY)
    tmpStart = end.clone().add(1, UNIT_DAY).startOf(UNIT_DAY)
    tmpEnd = tmpStart.clone().endOf(UNIT_DAY)
    if (intervalSize > 0) {
      tmpEnd.add(intervalSize, UNIT_DAY)
    }
  }

  return {
    start: tmpStart,
    end: tmpEnd,
    interval,
  }
}

export function getDayIntervalSize(moment1: Moment, moment2: Moment) {
  return Math.abs(moment1.diff(moment2, UNIT_DAY)) + 1
}

export function nextPeriodFromDate(
  date: Moment,
  quantity: number,
  granularity: IntervalGranularityType,
) {
  return date.clone().add(quantity, granularity as unitOfTime.DurationConstructor)
}

export function roundMomentMinutesToFloor(numOfMinutes: number, moment1: Moment = moment()) {
  const minutes = Math.floor(moment1.minute() / numOfMinutes) * numOfMinutes
  return moment1.clone().minute(minutes).second(0).milliseconds(0)
}

/**
 * Returns array of dates as strings in ISO 8601 format generated from `period.start` to `period.end` with given
 * `granularity`.
 *
 * @param period {Object} with `start` and `end` date attribute
 * @param granularity {String} from IntervalGranularityType
 * @returns {Array}
 */
export function generateDateStrings(
  period: Period,
  granularity: IntervalGranularityType,
): Array<string> {
  const { start, end } = period

  if (!start || !end) {
    return []
  }

  if (granularity === (IntervalGranularityType.ALL as unitOfTime.All)) {
    return [end.clone().endOf(UNIT_DAY).format()]
  }

  const momentUnits = moment.normalizeUnits(granularity as unitOfTime.All)
  if (!momentUnits) {
    throw new Error(
      `Given granularity parameter '${granularity}' is not possible to convert to moment.js units!`,
    )
  }

  const startDate = start.clone().startOf(momentUnits as unitOfTime.StartOf)
  const endDate = end.clone().startOf(momentUnits as unitOfTime.StartOf)

  const result = []
  while (startDate.isSameOrBefore(endDate)) {
    result.push(startDate.format())
    startDate
      .add(1, momentUnits as unitOfTime.DurationConstructor)
      .startOf(momentUnits as unitOfTime.StartOf)
  }

  return result
}

export function startOfDay(recordDate: MomentInput): Date {
  return moment(recordDate).startOf(UNIT_DAY).toDate()
}

export function startOfDayUTC(recordDate?: MomentInput): Date {
  return moment.utc(recordDate).startOf(UNIT_DAY).toDate()
}

export function startOfWeek(recordDate: MomentInput): Date {
  return moment(recordDate).startOf(UNIT_WEEK).toDate()
}

export function startOfMonth(recordDate: MomentInput): Date {
  return moment(recordDate).startOf(UNIT_MONTH).toDate()
}

export function utcDateAsISOString(): string {
  return moment.utc().toISOString()
}

export function isToday(moment1: Moment): boolean {
  return moment1.clone().startOf(UNIT_DAY).isSame(getStartOfToday(), UNIT_DAY)
}

export function isBeforeToday(moment1: Moment): boolean {
  return moment1.clone().isBefore(getStartOfToday())
}

/**
 * Returns true if the argument date is within 24 hours from now
 * @param date {Date,moment}
 * @return {boolean}
 */
export function isLessThan24Hours(date: MomentInput): boolean {
  return moment(date).isAfter(moment().subtract(1, UNIT_DAY))
}

export function isLessThan10Years(date: MomentInput): boolean {
  return moment(date).isAfter(moment().subtract(10, UNIT_YEAR))
}

export function isLessThan3Days(date: MomentInput): boolean {
  return moment(date).isAfter(moment().subtract(3, UNIT_DAY))
}

export function isLessThan7Days(date: MomentInput): boolean {
  return moment(date).isAfter(moment().subtract(7, UNIT_DAY))
}

export function isLessThan1Hour(date: MomentInput): boolean {
  return moment(date).isAfter(moment().subtract(1, UNIT_HOUR))
}

export function isMoreThan30Days(date: MomentInput): boolean {
  return moment(date).add(30, UNIT_DAY).isBefore(moment())
}

export function isMoreThan2Days(date: MomentInput): boolean {
  return moment(date).add(2, UNIT_DAY).isBefore(moment())
}

export function isAfterToday(momentInstance: Moment): boolean {
  return momentInstance.isAfter(getStartOfToday())
}

export function firstDayOfWeek(locale: string): number {
  return moment.localeData(locale).firstDayOfWeek()
}

export function getStartOfYesterday(): Moment {
  return moment().startOf(UNIT_DAY).subtract(1, UNIT_DAY)
}

export function getStartOfToday(): Moment {
  return moment().startOf(UNIT_DAY)
}

export function getEndOfToday(): Moment {
  return moment().endOf(UNIT_DAY)
}

export function getStartOfTomorrow(): Moment {
  return moment().startOf(UNIT_DAY).add(1, UNIT_DAY)
}

export function getStartOfTomorrowUTC(): Moment {
  return moment.utc().startOf(UNIT_DAY).add(1, UNIT_DAY)
}

export function getEndOfTomorrow(): Moment {
  return moment().endOf(UNIT_DAY).add(1, UNIT_DAY)
}

export const weekOfMonth = (moment1: Moment): number => {
  return moment1.week() - moment(moment1).startOf(UNIT_MONTH).week() + 1
}

export const toTimePeriod =
  (intervalTypes: Array<Interval>) =>
  (intervalType: Interval): Period => {
    if (!intervalTypes.find((type) => type === intervalType)) {
      throw Error(`IntervalType ${intervalType} is not possible to convert to time period`)
    }

    if (intervalType.includes("-")) {
      const currentDate = moment()
      const intervalArray: unknown[] = intervalType.split("-")
      const intervalSize: number = parseIntDecimal(intervalArray[0])
      const intervalUnits: string = intervalArray[1] as string
      const subtrahend: number =
        intervalSize > 0 && intervalUnits === UNIT_DAYS ? intervalSize - 1 : intervalSize
      // @ts-ignore TS is trying to match deprecated definitions
      const start = moment(currentDate).subtract(subtrahend, intervalUnits).startOf(UNIT_DAY)
      const end = moment(currentDate).endOf(UNIT_DAY)
      return {
        start,
        end,
        interval: intervalType,
      }
    } else {
      return {
        start: moment().startOf(intervalType as unitOfTime.StartOf),
        end: moment().endOf(intervalType as unitOfTime.StartOf),
        interval: intervalType,
      }
    }
  }

export const intervalTypeToPeriod = toTimePeriod([
  Interval.WEEK,
  Interval.MONTH,
  Interval.YEAR,
  Interval.TODAY,
  Interval.RELATIVE_7_DAYS,
  Interval.RELATIVE_30_DAYS,
  Interval.RELATIVE_90_DAYS,
  Interval.RELATIVE_12_MONTHS,
  Interval.ALL_THE_TIME,
  Interval.CUSTOM,
])

export const getWeekDaysForLocale = (locale: string) => moment.localeData(locale).weekdays()
export const getShortWeekDaysForLocale = (locale: string) =>
  moment.localeData(locale).weekdaysShort()
export const getMonthsForLocale = (locale: string) => moment.localeData(locale).months()
export const getShortMonthsForLocale = (locale: string) => moment.localeData(locale).monthsShort()

export const relativeIntervalTypes = [
  Interval.RELATIVE_7_DAYS,
  Interval.RELATIVE_30_DAYS,
  Interval.RELATIVE_90_DAYS,
  Interval.RELATIVE_12_MONTHS,
  Interval.TODAY,
  Interval.WEEK,
  Interval.MONTH,
  Interval.YEAR,
]

export function getRelativeIntervalValue(interval: Interval): {
  value: number
  unit: "months" | "days"
} {
  if (relativeIntervalTypes.includes(interval)) {
    const [value, unit] = interval.split("-") as [string, "months" | "days"]
    return { value: parseIntDecimal(value), unit }
  }
  return null
}

export const isRelativeInterval = (interval: Interval) => relativeIntervalTypes.includes(interval)

export const removeYear = (format: string): string => format.replace(/,?\W?(Y+|W+)年?\W?/, "")
export const createRangeFormat = (firstDay: string, format: string): string =>
  format.replace(/D{1}/, `[${firstDay}-]D`)

export const generateIntervalString = (
  period: Period,
  locale: string,
  stripTexts: boolean = false,
  preferTexts: boolean = false,
  intervalType: RelativeIntervalType = RelativeIntervalType.RELATIVE,
  forceFullInterval: boolean = false,
): string => {
  const { start, end, interval } = period

  if (interval === Interval.ALL_THE_TIME) {
    return formatMessage("all-the-time")
  }

  const localeFormat = moment.localeData(locale).longDateFormat("ll")

  const shortDateWithYear = localeFormat
  const shortDateWithoutYear = removeYear(localeFormat)
  const startDayNumber = (start && start.format("D")) || "0"
  const isSameMonth = start && start.isSame(end, "month")
  const isSameDay = start && start.isSame(end, "day")

  const datesAreThisYear =
    start && start.isSame(moment(), "year") && end && end.isSame(moment(), "year")
  const datesAreThisMonth =
    start && start.isSame(moment(), "month") && end && end.isSame(moment(), "month")
  const datesAreThisWeek =
    start && start.isSame(moment(), "week") && end && end.isSame(moment(), "week")
  const isToday = start && start.isSame(moment(), "day") && end && end.isSame(moment(), "day")

  const yearFormat = datesAreThisYear ? shortDateWithoutYear : shortDateWithYear
  const inlineRange = createRangeFormat(startDayNumber, yearFormat)

  if (forceFullInterval) {
    return `${start.format(yearFormat)}-${end.format(yearFormat)}`
  }

  const dateRangeString = isSameMonth
    ? (end && end.format(inlineRange)) || ""
    : (start && end && `${start.format(yearFormat)}-${end.format(yearFormat)}`) || ""

  switch (interval) {
    case Interval.RELATIVE_7_DAYS:
    case Interval.RELATIVE_90_DAYS:
    case Interval.RELATIVE_30_DAYS:
    // case Interval.THIS_MONTH:
    // case Interval.THIS_WEEK:
    // case Interval.THIS_YEAR:
    case Interval.TODAY:
    case Interval.RELATIVE_12_MONTHS: {
      if (stripTexts) {
        return dateRangeString
      }
      if (preferTexts) {
        return formatInterval(interval, intervalType)
      }
      return `${formatInterval(interval, intervalType)} (${dateRangeString})`
    }
    case Interval.MONTH: {
      if (datesAreThisMonth) return formatMessage("thisMonth")
      return datesAreThisYear
        ? (start && start.format("MMMM")) || ""
        : (start && start.format("MMMM Y")) || ""
    }
    case Interval.WEEK: {
      if (datesAreThisWeek) return formatMessage("thisWeek")
      return dateRangeString
    }
    case Interval.YEAR: {
      if (datesAreThisYear) return formatMessage("thisYear")
      return start ? start.format("Y") : ""
    }
    case Interval.CUSTOM: {
      if (isToday) return formatMessage("today")
      return isSameDay ? (start && start.format(yearFormat)) || "" : dateRangeString
    }
    default: {
      return dateRangeString
    }
  }
}

export function generateEndOfIntervalString(period: Period, locale: string) {
  const { start, end } = period

  if (start && end && moment().isBetween(start, end)) {
    return formatMessage("today")
  }

  const localeFormat = moment.localeData(locale).longDateFormat("ll")

  const shortDateWithYear = localeFormat
  const shortDateWithoutYear = removeYear(localeFormat)

  const isThisYear = end && end.isSame(moment(), "year")

  const yearFormat = isThisYear ? shortDateWithoutYear : shortDateWithYear

  return end ? end.format(yearFormat) : ""
}
