import { getEnabledFeatures } from 'src/common/environment'
import FeatureName from 'src/common/feature-name'

let envFeatures

export function getFeatures() {
  if (envFeatures) {
    return envFeatures
  }
  envFeatures = (getEnabledFeatures() || [])
    .reduce((acc, value) => {
      acc[value] = true
      return acc
    }, {})
  return envFeatures
}

function isFeatureEnabled(feature) {
  return !!getFeatures()[feature]
}

export function isAddRecordRepeatFormEnabled() {
  return isFeatureEnabled(FeatureName.ADD_RECORD_REPEAT_FORM)
}

export function isWalletLifeEnabled() {
  return isFeatureEnabled(FeatureName.WALLET_LIFE)
}
