import React from "react"
import { Redirect, Switch } from "react-router"
import { Prompt, Route } from "react-router-dom"
import { getUserChecked, LOGOUT_ALL_TABS, setUserChecked } from "src/backend/db/localStorage"
import { formatMessage } from "src/frontend/modules/intl/i18n"
import Analytics from "src/frontend/scenes/analytics/Analytics"
import { ChartSubjectType } from "src/frontend/scenes/analytics/enums"
import { trackWebAppStartEvent } from "src/frontend/scenes/app/actions"
import Dashboard from "src/frontend/scenes/dashboard/components/Dashboard"
import Imports from "src/frontend/scenes/imports/components/Imports"
import Records from "src/frontend/scenes/records/components/Records"
import Settings from "src/frontend/scenes/settings/components/Settings"
import { CouchType, User } from "src/types/User"
import MaintenanceInProgress from "src/frontend/scenes/app/MaintenanceInProgress"
import { AllowedSettingsPaths } from "src/frontend/scenes/settings/constants"
import { isAppBoard } from "src/common/environment"
import Contacts from "src/frontend/scenes/contacts/components/Contacts"
import ContactDetail from "src/frontend/scenes/contacts/contactDetail/components/ContactDetail"
import moment from "moment"
import { isLessThan1Hour } from "src/backend/time/time"
import BillingRoot from "src/frontend/scenes/billing/components/BillingRoot"
import MainLayout from "src/frontend/scenes/app/MainLayout"
import Accounts from "src/frontend/scenes/accounts/components/Accounts"
import OnBoardingPage from "src/frontend/scenes/onBoarding/firstRun/components/OnBoardingPage"
import AccountDetail from "src/frontend/scenes/accounts/accountDetail/components/AccountDetail"
import AddVoucherModal from "src/frontend/modules/modals/components/AddVoucherModal"
import SignUpVoucher from "src/frontend/scenes/voucher/components/SignUpVoucher"
import Investments from "../investments/components/Investments"
import * as localStorageService from "src/backend/db/localStorage"

interface Props {
  showOnBoarding: boolean
  warnBeforeLeaveMessageId?: string
  appInitialized: boolean
  user: User
  getUser: Function
  onSetTodayFilters: Function
}

export default class PrivateContent extends React.Component<Props> {
  static defaultProps = {
    warnBeforeLeaveMessageId: null,
  }

  componentDidMount() {
    window.addEventListener("focus", this.checkUserAndTrack)
    window.addEventListener("storage", this.handleLogoutAllTabs)
  }

  componentWillUnmount() {
    window.removeEventListener("focus", this.checkUserAndTrack)
    window.removeEventListener("storage", this.handleLogoutAllTabs)
  }

  handleLogoutAllTabs = (e) => {
    // Todo refactor to "app shutdown / soft logoutUser" instead of tab reload
    if (e.key === LOGOUT_ALL_TABS && e.newValue === LOGOUT_ALL_TABS) {
      window.location.reload()
    }
  }

  checkUserAndTrack = async () => {
    try {
      const lastUserCheck = getUserChecked()
      // don't spam BE every time on focus browser
      if (!lastUserCheck || (!!lastUserCheck && !isLessThan1Hour(lastUserCheck))) {
        const user = await this.props.getUser()
        trackWebAppStartEvent(user)
        localStorageService.setAppStart(true)
        setUserChecked(moment().toISOString())
      }
      this.props.onSetTodayFilters()
    } catch (error) {
      // suppress
      console.log(error)
    }
  }

  render() {
    const { showOnBoarding, warnBeforeLeaveMessageId, appInitialized } = this.props
    if (
      this.props.user.replication.couchType === CouchType.COUCHBASE &&
      !this.props.user.replication.migrationFinished
    ) {
      return (
        <Switch>
          <Route
            exact
            path="/maintenance"
            component={MaintenanceInProgress}
          />
          <Route render={() => <Redirect to="/maintenance" />} />
        </Switch>
      )
    }

    if (showOnBoarding) {
      return (
        <Switch>
          <Route
            exact
            path="/welcome"
            component={OnBoardingPage}
          />
          <Route render={() => <Redirect to="/welcome" />} />
        </Switch>
      )
    }

    return (
      appInitialized && (
        <MainLayout>
          <AddVoucherModal />
          {!!warnBeforeLeaveMessageId && (
            <Prompt message={formatMessage(warnBeforeLeaveMessageId)} />
          )}
          <Switch>
            <Route
              path="/dashboard"
              component={Dashboard}
            />
            <Route
              path="/integration"
              component={Dashboard}
            />
            <Route
              exact
              path={isAppBoard() ? "/transactions" : "/records"}
              component={Records}
            />
            <Route
              exact
              path="/analytics"
              component={Analytics}
            />
            <Route
              exact
              path="/accounts"
              component={Accounts}
            />
            <Route
              exact
              path="/accounts/detail/:accountId"
              component={AccountDetail}
            />
            {!isAppBoard() && (
              <Route
                exact
                path="/investments"
                component={Investments}
              />
            )}
            <Route
              exact
              path="/investments/detail/:accountId"
              component={AccountDetail}
            />
            {isAppBoard() && (
              <Route
                exact
                path="/contacts"
                component={Contacts}
              />
            )}
            {isAppBoard() && (
              <Route
                exact
                path="/contacts/detail/:contactId"
                component={ContactDetail}
              />
            )}
            <Route
              exact
              path="/imports"
              component={Imports}
            />
            <Route
              exact
              path={`/settings/:settingsPage(${AllowedSettingsPaths.join("|")})?`}
              component={Settings}
            />
            <Route
              path="/settings/billing/"
              component={BillingRoot}
            />
            <Route
              path="/redirect/android/report/:interval(year|month|week)/:intervalValue(\d{4}-?\d{0,2})"
              component={({ match }) => (
                <Redirect
                  to={{
                    pathname: "/analytics",
                    state: {
                      subject: ChartSubjectType.INCOMES_EXPENSES_REPORT,
                      params: match.params,
                    },
                  }}
                />
              )}
            />
            <Route
              exact
              path="/addvoucher/:voucherCode?"
              component={SignUpVoucher}
            />
            <Route render={() => <Redirect to="/dashboard" />} />
          </Switch>
        </MainLayout>
      )
    )
  }
}
