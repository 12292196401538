import { selectVisibleTransactionIds } from 'src/frontend/scenes/imports/items/selectors'
import { findByTransactionIds, removeRecords } from 'src/backend/records/service'
import { selectVisibleRecords } from './selectors'

export const IMPORTS_RECORDS_RECEIVED = 'imports/RECORDS_RECEIVED'
export const IMPORTS_RECORDS_GROUP_TOGGLE = 'imports/RECORDS_GROUP_TOGGLE'
export const SELECT_RECORD = 'imports/SELECT_RECORD'
export const RECORDS_SELECT_TOGGLE = 'imports/SELECT_ALL_TOGGLE'
export const CONFIRM_DELETE_RECORDS = 'imports/CONFIRM_DELETE_RECORDS'
export const DELETING_RECORDS = 'imports/DELETING_RECORDS'

export const CANCEL_DELETE_RECORDS = 'imports/CANCEL_DELETE_RECORDS'
export const DELETE_RECORDS_DONE = 'imports/DELETE_RECORDS_DONE'

export const recordsReceived = recordList => ({ type: IMPORTS_RECORDS_RECEIVED, payload: recordList })

export const updateImportedRecords = () => async (dispatch, getState) => {
  const records = await findByTransactionIds(selectVisibleTransactionIds(getState()))
  dispatch(recordsReceived(records))
}

export const toggleRecordImport = transactionId => ({ type: IMPORTS_RECORDS_GROUP_TOGGLE, transactionId })

export const selectRecords = recordIds => {
  return ({ type: SELECT_RECORD, recordIds })
}

export const onAllRecordsCheckBoxChange = (noneRecordsSelected) => (dispatch, getState) => {
  const visibleRecordIds = selectVisibleRecords(getState()).map(record => record._id)
  dispatch({ type: RECORDS_SELECT_TOGGLE, visibleRecordIds, select: !!noneRecordsSelected })
}

export const confirmDeleteRecords = () => ({ type: CONFIRM_DELETE_RECORDS })

export const deletingRecords = () => ({ type: DELETING_RECORDS })

export const deleteRecords = records => async (dispatcher) => {
  dispatcher(deletingRecords())
  await removeRecords(records)
  dispatcher({ type: DELETE_RECORDS_DONE, deletedRecords: records })
}

export const cancelDeleteRecords = () => ({ type: CANCEL_DELETE_RECORDS })
