import * as localStorageService from "src/backend/db/localStorage"
import languages from "static/locales/languages.json" // supported languages
import { DEFAULT_LANGUAGE } from "src/frontend/modules/intl/i18n"
import { Language } from "src/frontend/modules/intl/types"

export function getSupportedLanguages(): Language[] {
  return languages
}

export function getSupportedLanguage(languageCode: string) {
  const supportedLang = getSupportedLanguages().find((supportedLanguage: Language) => {
    return supportedLanguage.code === languageCode || supportedLanguage.locale === languageCode
  })

  const englishLang = getSupportedLanguages().find((lang) => lang.code === "en")

  return supportedLang ? supportedLang : englishLang
}

export function supportedLanguagesCodes(): string[] {
  return getSupportedLanguages().map((language: Language) => language.code)
}

export function saveLanguageToLocalStorage(languageCode: string) {
  localStorageService.setUserLanguage(languageCode)
}

export function getInitialLanguageCode(): string {
  const locale = getLocaleFromLocalStorage() || getLocaleFromNavigator(navigator)
  return parseLocaleToLanguageCode(locale)
}

function getLocaleFromLocalStorage(): string {
  return localStorageService.getUserLanguage()
}

/**
 * Define user's language. Different browsers have the user locale defined
 * on different fields on the `navigator` object, so we make sure to account
 * for these different by checking all of them
 * @param navigator
 * @returns {*|string}
 */
function getLocaleFromNavigator(navigator: Navigator): string {
  return (
    navigator.language ||
    (navigator.languages && navigator.languages[0]) ||
    (navigator as any).userLanguage
  )
}

/**
 * Try supported locale, fallback to 'en'
 * @param locale
 * @returns {*}
 */
function parseLocaleToLanguageCode(locale: string): string {
  const languageWithRegionCode = locale
  const languageWithoutRegionCode = locale.toLowerCase().split(/[_-]+/)[0]

  const supportedLanguages = supportedLanguagesCodes()

  return (
    supportedLanguages.find((languageCode) => languageWithRegionCode === languageCode) ||
    supportedLanguages.find((languageCode) => languageWithoutRegionCode === languageCode) ||
    DEFAULT_LANGUAGE
  )
}
