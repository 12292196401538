import React from 'react'
import { connect } from 'react-redux'
import { RootState } from 'src/types/State'
import { selectIsPendingConnectionInProgress } from 'src/frontend/scenes/integrations/newConnection/selectors'
import { openIntegrationsForm } from 'src/frontend/scenes/integrations/newConnection/actions'
import PictureIcon from 'src/frontend/components/PictureIcon/PictureIcon'
import styles from 'src/frontend/scenes/integrations/newConnection/components/NewBankConnectionInfoMessage.module.less'
import { FormattedMessage } from 'src/frontend/modules/intl'
import { mergeClasses } from 'src/common/utils'
import { ComplementaryButton } from 'src/frontend/components/Buttons/Buttons'
import { isPendingReconnect } from "../../connections/selectors"

interface Props {
  isPendingConnection: boolean
  isPendingReconnect: boolean
  basic?: boolean
  handleOpenIntegrationsModal: Function
}

function mapStateToProps(state: RootState) {
  return {
    isPendingConnection: selectIsPendingConnectionInProgress(state),
    isPendingReconnect: isPendingReconnect(state),
  }
}

const mapDispatchToProps = {
  handleOpenIntegrationsModal: openIntegrationsForm,
}

NewBankConnectionMessage.defaultProps = {
  basic: false,
}

function NewBankConnectionMessage({
  isPendingConnection,
  isPendingReconnect,
  handleOpenIntegrationsModal,
  basic,
}: Props) {
  return isPendingConnection || isPendingReconnect ? (
    <div className={mergeClasses(styles.pendingConnectionMessage, basic && styles.basic)}>
      {!basic && (
        <div className={styles.icon}>
          <PictureIcon
            name="bank"
            size="35px"
            compact
          />
        </div>
      )}
      <h2>
        <FormattedMessage id="integrations.newConnection.in-progress.title" />
      </h2>
      <p>
        <FormattedMessage id="integrations.newConnection.in-progress.text" />
      </p>
      {/* Shows unrelevant modal content */}
      {/* <div className={styles.right}>
        <ComplementaryButton onClick={() => handleOpenIntegrationsModal()}>
          <FormattedMessage id="integrations.newConnection.in-progress.action" />
        </ComplementaryButton>
      </div> */}
    </div>
  ) : null
}

export default connect(mapStateToProps, mapDispatchToProps)(NewBankConnectionMessage)
