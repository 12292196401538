import styles from './MainLayout.module.less'
import React from 'react'
import Menu from 'src/frontend/scenes/app/Menu'
import GdprConsentModal from 'src/frontend/scenes/gdpr/GdprConsentModal'
import GdprCloseConfirmationModal from 'src/frontend/scenes/gdpr/GdprCloseConfirmationModal'
import NewBankConnectionModal from 'src/frontend/scenes/integrations/newConnection/components/NewBankConnectionModal'
import RefreshBankModal from 'src/frontend/scenes/integrations/connections/components/RefreshBankModal'
import RecordFormModal from 'src/frontend/scenes/records/recordForm/components/RecordFormModal'
import ReportsRecordListModal from 'src/frontend/scenes/analytics/Report/components/ReportsRecordListModal'
import ContactForm from 'src/frontend/scenes/contacts/contactForm/components/ContactForm'
import RecordAssignForm from 'src/frontend/scenes/records/assignForm/components/RecordAssignForm'
import MagicRuleForm from 'src/frontend/scenes/settings/magicRules/components/MagicRuleForm'
import GoodJobModal from 'src/frontend/scenes/onBoarding/inApp/components/GoodJobModal'
import InAppTopBar from 'src/frontend/scenes/onBoarding/inApp/components/InAppTopBar'
import AccountFormModal from 'src/frontend/scenes/settings/accounts/components/AccountFormModal'
import BillingModal from 'src/frontend/scenes/billing/billingModal/components/BillingModal'

interface Props {
  children: string | React.ReactNode
}

export default function MainLayout({ children }: Props) {
  return (
    <div className={styles.mainContent}>
      <Menu />

      <GdprConsentModal />
      <GdprCloseConfirmationModal />
      <NewBankConnectionModal />
      <RefreshBankModal />
      <RecordFormModal />
      <ReportsRecordListModal />
      <ContactForm />
      <RecordAssignForm />
      <MagicRuleForm />
      <GoodJobModal />
      <AccountFormModal />
      <BillingModal />
      <InAppTopBar />

      <main>
        {children}
      </main>
    </div>
  )
}
