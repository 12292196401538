import lf from "lovefield"

export const inMemoryTableNames = {
  ACCOUNT: "Account",
  BUDGET: "Budget",
  CATEGORY: "Category",
  CURRENCY: "Currency",
  FILTER: "Filter",
  LABEL: "HashTag",
  RECORD: "Record",
  STANDING_ORDER: "StandingOrder",
  TEMPLATE: "Template",
  CONFIGURE: "Configure",
  CONTACT: "Contact",
  REWARD_POINTS: "RewardPoints",
  SHOPPING_LIST: "ShoppingList",
  WEB_CONFIG: "WebConfig",
  MAGIC_RULE: "MagicRule",
  DEPRECATED_CONFIG_WEB_DASHBOARD: "ConfigWebDashboard",
  STOCKS_FUNDS_ASSET: "StocksFundsAsset",
  ASSET_TRANSACTION: "AssetTransaction",
}

// Schema definition
//
// DB saves also other attributes than defined in the schema, but you won't be able to do any DB query on these
// attributes. All attributes are not null by default except for attributes with type lf.Type.ARRAY_BUFFER and
// lf.Type.OBJECT.  For more information go to https://github.com/google/lovefield/blob/master/docs/spec/01_schema.md.

export function createSchema() {
  const schemaBuilder = lf.schema.create("wallet", 1)
  schemaBuilder
    .createTable(inMemoryTableNames.RECORD)
    .addColumn("_id", lf.Type.STRING)
    .addColumn("_rev", lf.Type.STRING)
    .addColumn("accountId", lf.Type.STRING)
    .addColumn("accuracy", lf.Type.NUMBER)
    .addColumn("amount", lf.Type.INTEGER)
    .addColumn("categoryChanged", lf.Type.BOOLEAN)
    .addColumn("categoryId", lf.Type.STRING)
    .addColumn("currencyId", lf.Type.STRING)
    .addColumn("envelopeId", lf.Type.INTEGER)
    .addColumn("contactId", lf.Type.STRING)
    .addColumn("labels", lf.Type.OBJECT)
    .addColumn("latitude", lf.Type.NUMBER)
    .addColumn("longitude", lf.Type.NUMBER)
    .addColumn("note", lf.Type.STRING)
    .addColumn("payee", lf.Type.STRING)
    .addColumn("paymentType", lf.Type.INTEGER)
    .addColumn("photos", lf.Type.OBJECT)
    .addColumn("recordDate", lf.Type.DATE_TIME)
    .addColumn("recordDateDay", lf.Type.DATE_TIME)
    .addColumn("recordDateMonth", lf.Type.DATE_TIME)
    .addColumn("recordDateWeek", lf.Type.DATE_TIME)
    .addColumn("recordState", lf.Type.INTEGER)
    .addColumn("refAmount", lf.Type.INTEGER)
    .addColumn("refObjects", lf.Type.OBJECT)
    .addColumn("reservedAuthorId", lf.Type.STRING)
    .addColumn("reservedCreatedAt", lf.Type.STRING)
    .addColumn("reservedIntegrationDate", lf.Type.STRING)
    .addColumn("reservedModelType", lf.Type.STRING)
    .addColumn("reservedOwnerId", lf.Type.STRING)
    .addColumn("reservedSource", lf.Type.STRING)
    .addColumn("reservedUpdatedAt", lf.Type.DATE_TIME)
    .addColumn("soComplete", lf.Type.BOOLEAN)
    .addColumn("standingOrderId", lf.Type.STRING)
    .addColumn("starred", lf.Type.BOOLEAN)
    .addColumn("superEnvelopeId", lf.Type.INTEGER)
    .addColumn("transactionId", lf.Type.STRING)
    .addColumn("transfer", lf.Type.BOOLEAN)
    .addColumn("transferId", lf.Type.STRING)
    .addColumn("type", lf.Type.INTEGER)
    .addColumn("warrantyInMonth", lf.Type.INTEGER)
    .addColumn("fulltextString", lf.Type.STRING)
    .addPrimaryKey(["_id"])
    .addIndex("i", [
      "type",
      "currencyId",
      "categoryId",
      "envelopeId",
      "superEnvelopeId",
      "accountId",
    ])
    .addNullable([
      "accuracy",
      "amount",
      "categoryChanged",
      "currencyId",
      "envelopeId",
      "contactId",
      "latitude",
      "longitude",
      "note",
      "payee",
      "paymentType",
      "photos",
      "recordState",
      "refAmount",
      "refObjects",
      "reservedAuthorId",
      "reservedOwnerId",
      "reservedCreatedAt",
      "reservedIntegrationDate",
      "reservedUpdatedAt",
      "soComplete",
      "standingOrderId",
      "starred",
      "superEnvelopeId",
      "transactionId",
      "transfer",
      "transferId",
      "warrantyInMonth",
      "fulltextString",
    ])

  schemaBuilder
    .createTable(inMemoryTableNames.CATEGORY)
    .addColumn("_id", lf.Type.STRING)
    .addColumn("_rev", lf.Type.STRING)
    .addColumn("color", lf.Type.STRING)
    .addColumn("customCategory", lf.Type.BOOLEAN)
    .addColumn("customName", lf.Type.BOOLEAN)
    .addColumn("defaultType", lf.Type.INTEGER)
    .addColumn("enabled", lf.Type.BOOLEAN)
    .addColumn("envelopeId", lf.Type.INTEGER)
    .addColumn("superEnvelopeId", lf.Type.INTEGER)
    .addColumn("iconName", lf.Type.STRING)
    .addColumn("name", lf.Type.STRING)
    .addColumn("position", lf.Type.INTEGER)
    .addColumn("reservedAuthorId", lf.Type.STRING)
    .addColumn("reservedCreatedAt", lf.Type.STRING)
    .addColumn("reservedUpdatedAt", lf.Type.STRING)
    .addColumn("reservedModelType", lf.Type.STRING)
    .addColumn("reservedOwnerId", lf.Type.STRING)
    .addColumn("reservedSource", lf.Type.STRING)
    .addColumn("systemCategory", lf.Type.INTEGER)
    .addPrimaryKey(["_id"])
    .addNullable([
      "customCategory",
      "customName",
      "defaultType",
      "color",
      "enabled",
      "superEnvelopeId",
      "envelopeId",
      "iconName",
      "position",
      "reservedAuthorId",
      "reservedCreatedAt",
      "reservedUpdatedAt",
      "reservedOwnerId",
      "systemCategory",
    ])

  schemaBuilder
    .createTable(inMemoryTableNames.CURRENCY)
    .addColumn("_id", lf.Type.STRING)
    .addColumn("code", lf.Type.STRING)
    .addColumn("position", lf.Type.INTEGER)
    .addColumn("ratioToReferential", lf.Type.NUMBER)
    .addColumn("referential", lf.Type.BOOLEAN)
    .addColumn("reservedCreatedAt", lf.Type.STRING)
    .addColumn("reservedModelType", lf.Type.STRING)
    .addColumn("reservedOwnerId", lf.Type.STRING)
    .addColumn("reservedSource", lf.Type.STRING)
    .addPrimaryKey(["_id"])
    .addNullable(["position", "referential", "reservedCreatedAt"])

  schemaBuilder
    .createTable(inMemoryTableNames.ACCOUNT)
    .addColumn("_id", lf.Type.STRING)
    .addColumn("color", lf.Type.STRING)
    .addColumn("currencyId", lf.Type.STRING)
    .addColumn("excludeFromStats", lf.Type.BOOLEAN)
    .addColumn("gps", lf.Type.BOOLEAN)
    .addColumn("archived", lf.Type.BOOLEAN)
    .addColumn("initAmount", lf.Type.INTEGER)
    .addColumn("initRefAmount", lf.Type.INTEGER)
    .addColumn("name", lf.Type.STRING)
    .addColumn("creditCard", lf.Type.OBJECT)
    .addColumn("position", lf.Type.INTEGER)
    .addColumn("accountType", lf.Type.INTEGER)
    .addColumn("importSettings", lf.Type.OBJECT)
    .addColumn("reservedCreatedAt", lf.Type.STRING)
    .addColumn("reservedIntegrationConnection", lf.Type.OBJECT)
    .addColumn("reservedModelType", lf.Type.STRING)
    .addColumn("reservedOwnerId", lf.Type.STRING)
    .addColumn("reservedSource", lf.Type.STRING)
    .addColumn("investmentInfo", lf.Type.OBJECT)
    .addPrimaryKey(["_id"])
    .addNullable([
      "currencyId",
      "excludeFromStats",
      "initRefAmount",
      "position",
      "creditCard",
      "reservedIntegrationConnection",
      "reservedCreatedAt",
      "investmentInfo",
    ])

  schemaBuilder
    .createTable(inMemoryTableNames.STANDING_ORDER)
    .addColumn("_id", lf.Type.STRING)
    .addColumn("accountId", lf.Type.STRING)
    .addColumn("toAccountId", lf.Type.STRING)
    .addColumn("amount", lf.Type.INTEGER)
    .addColumn("categoryId", lf.Type.STRING)
    .addColumn("currencyId", lf.Type.STRING)
    .addColumn("dueDate", lf.Type.DATE_TIME)
    .addColumn("generateFromDate", lf.Type.DATE_TIME)
    .addColumn("name", lf.Type.STRING)
    .addColumn("note", lf.Type.STRING)
    .addColumn("labels", lf.Type.OBJECT)
    .addColumn("paymentType", lf.Type.INTEGER)
    .addColumn("position", lf.Type.INTEGER)
    .addColumn("recurrenceRule", lf.Type.STRING)
    .addColumn("reservedModelType", lf.Type.STRING)
    .addColumn("type", lf.Type.STRING)
    .addPrimaryKey(["_id"])
    .addNullable([
      "dueDate",
      "type",
      "toAccountId",
      "generateFromDate",
      "note",
      "paymentType",
      "recurrenceRule",
      "position",
      "labels",
    ])

  schemaBuilder
    .createTable(inMemoryTableNames.BUDGET)
    .addColumn("_id", lf.Type.STRING)
    .addColumn("accountIds", lf.Type.OBJECT)
    .addColumn("amount", lf.Type.INTEGER)
    .addColumn("categoryIds", lf.Type.OBJECT)
    .addColumn("name", lf.Type.STRING)
    .addColumn("reservedCreatedAt", lf.Type.STRING)
    .addColumn("reservedModelType", lf.Type.STRING)
    .addColumn("reservedOwnerId", lf.Type.STRING)
    .addColumn("reservedSource", lf.Type.STRING)
    .addColumn("type", lf.Type.INTEGER)
    .addPrimaryKey(["_id"])
    .addNullable(["accountIds"])

  schemaBuilder
    .createTable(inMemoryTableNames.LABEL)
    .addColumn("_id", lf.Type.STRING)
    .addColumn("name", lf.Type.STRING)
    .addColumn("position", lf.Type.INTEGER)
    .addColumn("archived", lf.Type.BOOLEAN)
    .addColumn("autoAssign", lf.Type.BOOLEAN)
    .addColumn("reservedCreatedAt", lf.Type.STRING)
    .addColumn("reservedModelType", lf.Type.STRING)
    .addColumn("reservedOwnerId", lf.Type.STRING)
    .addColumn("reservedSource", lf.Type.STRING)
    .addPrimaryKey(["_id"])
    .addNullable(["position"])

  schemaBuilder
    .createTable(inMemoryTableNames.TEMPLATE)
    .addColumn("_id", lf.Type.STRING)
    .addColumn("accountId", lf.Type.STRING)
    .addColumn("labels", lf.Type.OBJECT)
    .addColumn("amount", lf.Type.STRING)
    .addColumn("categoryId", lf.Type.STRING)
    .addColumn("currencyId", lf.Type.STRING)
    .addColumn("name", lf.Type.STRING)
    .addColumn("paymentType", lf.Type.INTEGER)
    .addColumn("position", lf.Type.INTEGER)
    .addColumn("reservedCreatedAt", lf.Type.STRING)
    .addColumn("reservedModelType", lf.Type.STRING)
    .addColumn("reservedOwnerId", lf.Type.STRING)
    .addColumn("reservedSource", lf.Type.STRING)
    .addColumn("type", lf.Type.INTEGER)
    .addPrimaryKey(["_id"])
    .addNullable([
      "accountId",
      "amount",
      "categoryId",
      "currencyId",
      "paymentType",
      "position",
      "reservedCreatedAt",
      "type",
    ])

  schemaBuilder
    .createTable(inMemoryTableNames.FILTER)
    .addColumn("_id", lf.Type.STRING)
    .addColumn("name", lf.Type.STRING)
    .addColumn("accountId", lf.Type.STRING)
    .addColumn("categories", lf.Type.OBJECT)
    .addColumn("envelopes", lf.Type.OBJECT)
    .addColumn("currencies", lf.Type.OBJECT)
    .addColumn("payment_types", lf.Type.OBJECT)
    .addColumn("record_type", lf.Type.INTEGER)
    .addColumn("record_states", lf.Type.OBJECT)
    .addColumn("description_tokens", lf.Type.STRING)
    .addColumn("transfers", lf.Type.INTEGER)
    .addColumn("debts", lf.Type.INTEGER)
    .addColumn("standing_orders", lf.Type.INTEGER)
    .addColumn("labels", lf.Type.OBJECT)
    .addColumn("position", lf.Type.INTEGER)
    .addColumn("reservedCreatedAt", lf.Type.STRING)
    .addColumn("reservedModelType", lf.Type.STRING)
    .addColumn("reservedOwnerId", lf.Type.STRING)
    .addColumn("reservedSource", lf.Type.STRING)
    .addPrimaryKey(["_id"])
    .addNullable([
      "position",
      "accountId",
      "categories",
      "envelopes",
      "currencies",
      "payment_types",
      "record_type",
      "record_states",
      "description_tokens",
      "transfers",
      "debts",
      "standing_orders",
      "labels",
    ])

  schemaBuilder
    .createTable(inMemoryTableNames.CONFIGURE)
    .addColumn("_id", lf.Type.STRING)
    .addColumn("deviceId", lf.Type.STRING)
    .addColumn("key", lf.Type.STRING)
    .addColumn("value", lf.Type.STRING)
    .addColumn("reservedModelType", lf.Type.STRING)
    .addPrimaryKey(["_id"])
    .addNullable(["deviceId"])

  schemaBuilder
    .createTable(inMemoryTableNames.REWARD_POINTS)
    .addColumn("_id", lf.Type.STRING)
    .addColumn("amount", lf.Type.INTEGER)
    .addColumn("accountId", lf.Type.STRING)
    .addColumn("reservedCreatedAt", lf.Type.STRING)
    .addColumn("reservedUpdatedAt", lf.Type.STRING)
    .addColumn("reservedModelType", lf.Type.STRING)
    .addColumn("reservedOwnerId", lf.Type.STRING)
    .addColumn("reservedSource", lf.Type.STRING)
    .addPrimaryKey(["_id"])
    .addNullable(["accountId", "reservedUpdatedAt"])

  schemaBuilder
    .createTable(inMemoryTableNames.SHOPPING_LIST)
    .addColumn("_id", lf.Type.STRING)
    .addPrimaryKey(["_id"])

  schemaBuilder
    .createTable(inMemoryTableNames.CONTACT)
    .addColumn("_id", lf.Type.STRING)
    .addColumn("name", lf.Type.STRING)
    .addColumn("type", lf.Type.NUMBER)
    .addColumn("email", lf.Type.STRING)
    .addColumn("web", lf.Type.STRING)
    .addColumn("phone", lf.Type.STRING)
    .addColumn("avatarUrl", lf.Type.STRING)
    .addColumn("note", lf.Type.STRING)
    .addColumn("reservedModelType", lf.Type.STRING)
    .addPrimaryKey(["_id"])
    .addNullable(["email", "web", "phone", "note", "avatarUrl"])

  schemaBuilder
    .createTable(inMemoryTableNames.MAGIC_RULE)
    .addColumn("_id", lf.Type.STRING)
    .addColumn("reservedModelType", lf.Type.STRING)
    .addColumn("categoryId", lf.Type.STRING)
    .addColumn("labelIds", lf.Type.OBJECT)
    .addColumn("accountIds", lf.Type.OBJECT)
    .addColumn("keywords", lf.Type.OBJECT)
    .addColumn("recordType", lf.Type.NUMBER)
    .addColumn("sendPushNotification", lf.Type.BOOLEAN)
    .addColumn("contactId", lf.Type.STRING)
    .addNullable(["categoryId", "recordType", "contactId", "sendPushNotification", "accountIds"])
    .addPrimaryKey(["_id"])

  schemaBuilder
    .createTable(inMemoryTableNames.WEB_CONFIG)
    .addColumn("_id", lf.Type.STRING)
    .addColumn("reservedModelType", lf.Type.STRING)
    .addColumn("reservedSource", lf.Type.STRING)
    .addColumn("reservedCreatedAt", lf.Type.STRING)
    .addColumn("reservedUpdatedAt", lf.Type.STRING)
    .addColumn("reservedOwnerId", lf.Type.STRING)
    .addPrimaryKey(["_id"])

  schemaBuilder
    .createTable(inMemoryTableNames.DEPRECATED_CONFIG_WEB_DASHBOARD)
    .addColumn("_id", lf.Type.STRING)
    .addColumn("reservedModelType", lf.Type.STRING)
    .addColumn("reservedSource", lf.Type.STRING)
    .addColumn("reservedCreatedAt", lf.Type.STRING)
    .addColumn("reservedUpdatedAt", lf.Type.STRING)
    .addColumn("reservedOwnerId", lf.Type.STRING)
    .addPrimaryKey(["_id"])

  schemaBuilder
    .createTable(inMemoryTableNames.ASSET_TRANSACTION)
    .addColumn("_id", lf.Type.STRING)
    .addColumn("_rev", lf.Type.STRING)
    .addColumn("date", lf.Type.DATE_TIME)
    .addColumn("day", lf.Type.DATE_TIME)
    .addColumn("month", lf.Type.DATE_TIME)
    .addColumn("week", lf.Type.DATE_TIME)
    .addColumn("quantity", lf.Type.NUMBER)
    .addColumn("cashAmount", lf.Type.NUMBER)
    .addColumn("cashAmountCurrencyCode", lf.Type.STRING)
    .addColumn("reservedUpdatedAt", lf.Type.DATE_TIME)
    .addColumn("assetType", lf.Type.NUMBER)
    .addColumn("reservedOwnerId", lf.Type.STRING)
    .addColumn("transactionType", lf.Type.NUMBER)
    .addColumn("accountId", lf.Type.STRING)
    .addColumn("reservedSource", lf.Type.STRING)
    .addColumn("assetId", lf.Type.STRING)
    .addColumn("price", lf.Type.NUMBER)
    .addColumn("priceCurrencyCode", lf.Type.STRING)
    .addColumn("reservedCreatedAt", lf.Type.DATE_TIME)
    .addColumn("reservedAuthorId", lf.Type.STRING)
    .addColumn("reservedModelType", lf.Type.STRING)
    .addPrimaryKey(["_id"])
    .addNullable(["cashAmountCurrencyCode", "cashAmount", "priceCurrencyCode"])

  schemaBuilder
    .createTable(inMemoryTableNames.STOCKS_FUNDS_ASSET)
    .addColumn("_id", lf.Type.STRING)
    .addColumn("_rev", lf.Type.STRING)
    .addColumn("symbol", lf.Type.STRING)
    .addColumn("reservedUpdatedAt", lf.Type.DATE_TIME)
    .addColumn("priceMinDate", lf.Type.DATE_TIME)
    .addColumn("reservedOwnerId", lf.Type.STRING)
    .addColumn("reservedSource", lf.Type.STRING)
    .addColumn("reservedCreatedAt", lf.Type.DATE_TIME)
    .addColumn("reservedAuthorId", lf.Type.STRING)
    .addColumn("name", lf.Type.STRING)
    .addColumn("logo", lf.Type.STRING)
    .addColumn("reservedModelType", lf.Type.STRING)
    .addColumn("exchangeName", lf.Type.STRING)
    .addColumn("currencyCode", lf.Type.STRING)
    .addColumn("currencyMeta", lf.Type.OBJECT)
    .addColumn("exchangeCode", lf.Type.STRING)
    .addPrimaryKey(["_id"])
    .addNullable([
      "priceMinDate",
      "logo",
      "reservedOwnerId",
      "reservedCreatedAt",
      "reservedAuthorId",
    ])

  return schemaBuilder
}
