import { Action } from 'src/types/common'
import { ContactsFilterType, ContactsFilterDisplayOptions } from 'src/frontend/scenes/contacts/filter/types'
import * as actions from 'src/frontend/scenes/contacts/filter/actions'


export interface ContactFilterState {
  filter: ContactsFilterType
  display: ContactsFilterDisplayOptions
}


const initialState: ContactFilterState = {
  filter: {
    fulltext: '',
    contactTypeIds: undefined,
  },
  display: {
    contactTypeIds: true,
  },
}

export function contactFilter(state: ContactFilterState = initialState, action: Action): ContactFilterState {
  switch (action.type) {
    case actions.CONTACTS_TOGGLE_DISPLAY_FILTER: {
      return { ...state, display: { ...state.display, [action.payload]: !state.display[action.payload] } }
    }
    case actions.CONTACTS_SET_FILTER: {
      return { ...state, filter: { ...state.filter, ...action.payload } }
    }

    default: {
      return state
    }
  }
}
