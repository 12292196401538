import { convertResultToEntity, fetchAllFromTable, WalletSQLInMemory } from "../db/inMemorySqlDb"
import { inMemoryTableNames } from "../db/inMemorySqlDbSchemaBuilder"
import * as commonRepository from "src/backend/common/repository"
import { CategoryDocument } from "src/types/Category"
import lf from "lovefield"
import { Id } from "src/types/CouchDb"

export function findAllAsHashMap(): Promise<{ [key: string]: CategoryDocument }> {
  return commonRepository.findAllAsHashMap(inMemoryTableNames.CATEGORY)
}

export function findById(id): Promise<CategoryDocument> {
  const categoryId = id || null
  const sqlDb = WalletSQLInMemory.getInstance()
  const categoryTable = sqlDb.getTable(inMemoryTableNames.CATEGORY)
  return sqlDb
    .select()
    .from(categoryTable)
    .where(categoryTable._id.eq(categoryId))
    .exec()
    .then(convertResultToEntity(id, inMemoryTableNames.CATEGORY))
}

export function findCategoryForEnvelopeId(envelopeId = null): Promise<CategoryDocument> {
  const sqlDb = WalletSQLInMemory.getInstance()
  const categoryTable = sqlDb.getTable(inMemoryTableNames.CATEGORY)
  return sqlDb
    .select()
    .from(categoryTable)
    .where(
      lf.op.and(categoryTable.envelopeId.eq(envelopeId), categoryTable.customCategory.eq(false)),
    )
    .exec()
    .then(convertResultToEntity(envelopeId, inMemoryTableNames.CATEGORY))
}

export function findCustomCategoriesForEnvelopeId(envelopeId = null): Promise<CategoryDocument[]> {
  const sqlDb = WalletSQLInMemory.getInstance()
  const categoryTable = sqlDb.getTable(inMemoryTableNames.CATEGORY)
  return sqlDb
    .select()
    .from(categoryTable)
    .where(
      lf.op.and(categoryTable.envelopeId.eq(envelopeId), categoryTable.customCategory.eq(true)),
    )
    .exec() as Promise<CategoryDocument[]>
}

export function getTransferCategoryByEnvelopeId(
  transferSystemEnvelopeId: number,
): Promise<CategoryDocument> {
  const sqlDb = WalletSQLInMemory.getInstance()
  const categoryTable = sqlDb.getTable(inMemoryTableNames.CATEGORY)
  const query = sqlDb
    .select()
    .from(categoryTable)
    .where(categoryTable.envelopeId.eq(transferSystemEnvelopeId))

  return query.exec().then(convertTransferCategoryResultToEntity(transferSystemEnvelopeId))
}

export function getTransferCategoryBySystemCategoryType(
  transferSystemCategoryType,
): Promise<CategoryDocument> {
  const sqlDb = WalletSQLInMemory.getInstance()
  const categoryTable = sqlDb.getTable(inMemoryTableNames.CATEGORY)
  const query = sqlDb
    .select()
    .from(categoryTable)
    .where(categoryTable.systemCategory.eq(transferSystemCategoryType))

  return query.exec().then(convertTransferCategoryResultToEntity(transferSystemCategoryType))
}

export const convertTransferCategoryResultToEntity =
  (id: Id | number) => (result: CategoryDocument[]) => {
    if (result.length > 1) {
      console.warn(
        `
        There is more than one entry as transfer category with ID or systemCategoryType %s.
        Taking the first one...
      `,
        id,
      )
    }

    return result[0]
  }

export function getAllCategories(): Promise<CategoryDocument[]> {
  return fetchAllFromTable<CategoryDocument>(inMemoryTableNames.CATEGORY)
}
