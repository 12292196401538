import PropTypes from 'prop-types'

export const Columns = PropTypes.arrayOf(PropTypes.shape({
  colIndex: PropTypes.number.isRequired,
  colName: PropTypes.string.isRequired,
}))

export const MappingError = PropTypes.shape({
  line: PropTypes.number,
  tokens: PropTypes.arrayOf(PropTypes.shape({
    type: PropTypes.number.isRequired,
    value: PropTypes.oneOfType([
      PropTypes.string.isRequired,
      PropTypes.arrayOf(PropTypes.number.isRequired).isRequired,
    ]),
    colIndex: PropTypes.number,
  })),
})
