import { GetState } from "src/types/common"
import { selectNewConnection } from "./selectors"
import { setNewConnectionToLocaleStorage } from "src/backend/db/localStorage"
import { IntegrationSource, IntegrationSourceName } from "src/backend/integrations/enums"

export function getNoAccountsErrorText(): string[] {
  return [
    "integrations.error.no-accounts.text1",
    "integrations.error.no-accounts.text2",
    "integrations.error.no-accounts.text3",
  ]
}

/**
 * Converts IntegrationSourceName (string) to IntegrationSource (enum)
 */

export function getIntegrationSourceEnumFromIntegrationSourceName(
  integrationSourceName: IntegrationSourceName,
): IntegrationSource | undefined {
  const integrationSourceEnumKey = integrationSourceName.toUpperCase()
  return IntegrationSource[integrationSourceEnumKey]
}