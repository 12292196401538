import { StockPrice, StocksFundsAsset } from "src/types/investments.types"
import { AssetListItemType, AssetTransaction } from "src/types/investments.types"
import { getLatestStockPrice, getStocksFundsAssets } from "./service"

export const transformAssetTransactionsToAssetListItems = async (
  assetTransactions: AssetTransaction[],
): Promise<AssetListItemType> => {
  const [relatedAsset] = await getStocksFundsAssets([assetTransactions[0].assetId])

  const currentAssetValue: StockPrice = await getCurrentAssetPrice(relatedAsset, assetTransactions)

  return {
    ...relatedAsset,
    assetTransactions,
    totalQuantity: assetTransactions.reduce((acc, assetTransaction) => {
      return acc + assetTransaction.quantity
    }, 0),
    totalValue: assetTransactions.reduce((acc, assetTransaction) => {
      return acc + assetTransaction.quantity * (currentAssetValue?.value ?? assetTransaction.price)
    }, 0),
  }
}

const getCurrentAssetPrice = async (
  { exchangeCode, symbol }: StocksFundsAsset,
  assetTransactions: AssetTransaction[],
) => {
  const stockIdentifier = `${exchangeCode}:${symbol}`
  const assetPrice = await getLatestStockPrice({ exchangeCode, symbol }, new Date())
    .then((latestPrice) => {
      return { ...latestPrice, value: latestPrice.value * 100 }
    })
    .catch(() => {
      const { price: fallbackTransactionPrice, date } = assetTransactions.sort((a, b) => {
        return new Date(a.date).getTime() - new Date(b.date).getTime()
      })[0]

      return {
        value: fallbackTransactionPrice,
        date: new Date(date),
        stockIdentifier,
      }
    })

  return assetPrice
}
