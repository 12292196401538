import React from 'react'
import { connect } from 'react-redux'
import {
  Modal, Grid, Loader,
} from 'semantic-ui-react'
import { FormattedMessage } from 'src/frontend/modules/intl'
import * as actions from '../actions'
import { isSystemLabel } from 'src/backend/labels/helpers'
import { Label } from 'src/types/Label'
import { PrimaryButton, SecondaryButton } from 'src/frontend/components/Buttons/Buttons'

const mapDispatchToProps = {
  handleDeleteLabel: actions.deleteLabel,
}

interface Props {
  label: Label,
  handleDeleteLabel: Function,
}

interface State {
  processing: boolean,
  open: boolean
}

class RemoveLabelForm extends React.Component<Props, State> {

  state = { processing: false, open: false }

  handleConfirm = () => {
    this.setState({ processing: true })
    this.props.handleDeleteLabel(this.props.label._id)
  }

  handleOpen = () => {
    this.setState({ processing: false, open: true })
  }

  handleClose = () => {
    this.setState({ processing: false, open: false })
  }

  render() {
    const { label } = this.props
    const { processing, open } = this.state

    const ModalActions = () => {
      return (
        <Modal.Actions>
          <Grid>
            <Grid.Column width={8}>
              <SecondaryButton fluid onClick={this.handleClose}>
                <FormattedMessage id="form.no" />
              </SecondaryButton>
            </Grid.Column>
            <Grid.Column width={8}>
              <PrimaryButton disabled={processing} fluid color="red" onClick={this.handleConfirm}>
                <FormattedMessage id="form.yes" />
              </PrimaryButton>
            </Grid.Column>
          </Grid>
        </Modal.Actions>
      )
    }

    const trigger = (
      <span
        onClick={() => {
          if (!isSystemLabel(label)) {
            this.handleOpen()
          }
        }}
      >
        <FormattedMessage id="delete" />
      </span>
    )

    return (
      <Modal
        trigger={trigger}
        open={open}
        size="small"
        onClose={this.handleClose}
      >
        <Modal.Header><FormattedMessage id="settings.labels.remove.label" /></Modal.Header>
        <Modal.Content>
          <RemoveLabelContent processing={processing} label={label} />
        </Modal.Content>
        <ModalActions />
      </Modal>
    )
  }
}

function RemoveLabelContent({ processing, label }: { label: Label, processing: boolean }) {

  const loadingSpinner = (<Loader active inline="centered" />)

  const confirmQuestion = (
    <div>
      <FormattedMessage
        id="settings.labels.remove.label-text"
        values={{ labelName: <strong>{label.name.toUpperCase()}</strong> }}
      />
    </div>
  )

  return !processing ? confirmQuestion : loadingSpinner

}

export default connect(null, mapDispatchToProps)(RemoveLabelForm)
