export namespace Billing {
  export interface BraintreePaymentInfo {
    token?: string
    recurrentProducts: BraintreeProduct[]
    lifetimeProduct?: BraintreeProduct
    vat?: number
  }


  export interface BraintreeProduct {
    price: number,
    taxAmount?: number,
    currencyCode: string,
    product: Product,
  }

  export interface Product {
    planType: PlanType
    productId: string
    period: PeriodType
  }

  export enum PlanType {
    FREE = 0,
    FREE_PRE_TRIAL = 1,
    FREE_POST_TRIAL = 2,
    BASIC = 3,
    ADVANCED = 4,
    PRO = 5,
    VIP = 6,
    BUSINESS = 7,
  }

  export enum PeriodType {
    WEEK = 0,
    MONTH = 1,
    QUARTER = 2,
    YEAR = 3,
    UNLIMITED = 4,
  }

  export enum VoucherType {
    PREMIUM,
    DISCOUNT,
    NATIVE_TRIAL,
  }

  export interface VoucherTransactionResponse {
    voucherType: VoucherType,
    productId?: string,
    price?: number,
    taxAmount?: number,
    currencyCode?: string,
    period?: PeriodType,
  }
}
