import React from 'react'
import { FormattedMessage } from 'src/frontend/modules/intl'
import styles from 'src/frontend/scenes/auth/components/UserLinks.module.less'
import AuthLinks from 'src/frontend/components/AuthLinks'

function UserLinks() {
    return (
        <div className={styles.moreOptionsLinks}>
            <p>
                <FormattedMessage
                    id='auth.resetPassword.links'
                    values={{
                        signUpLink: <AuthLinks url='/signup' label='auth.signup' target='_self' rel='' />,
                        loginLink: <AuthLinks url='/login' label='auth.login' target='_self' rel='' />,
                        br: <br />,
                    }}
                />
            </p>
        </div>
    )
}

export default UserLinks
