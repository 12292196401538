import {
  CHANGE_SELECTED_COUNTRY,
  CHANGE_SELECTED_PROVIDER,
  CLEAR_PROVIDER_SELECTION,
  FETCH_COUNTRIES_START,
  FETCH_COUNTRIES_SUCCESS,
  FETCH_EXISTING_PROVIDERS_SUCCESS,
  FETCH_PROVIDERS_ERROR,
  FETCH_PROVIDERS_START,
  FETCH_PROVIDERS_SUCCESS,
  FETCH_PROVIDER_DETAILS_ERROR,
  FETCH_PROVIDER_DETAILS_START,
  FETCH_PROVIDER_DETAILS_SUCCESS,
  RECONNECT_BANK_START,
} from 'src/frontend/scenes/integrations/providersList/actions'
import { Action } from 'src/types/common'
import { IntegrationProviders } from 'src/frontend/scenes/integrations/providersList/types'
import IntegrationProvider = IntegrationProviders.IntegrationProvider
import IntegrationProviderDetail = IntegrationProviders.IntegrationProviderDetail
import IntegrationConnectedProvider = IntegrationProviders.IntegrationConnectedProvider

export interface ProviderListState {
  countriesLoading: boolean
  countries: string[]
  selectedCountry: string
  providersLoading: boolean
  providerDetailLoading: boolean
  countryAvailableProviders: IntegrationProvider[]
  alreadyConnectedProviders: IntegrationConnectedProvider[]
  selectedProviderCode: string
  providerDetail: IntegrationProviderDetail
  reconnectLoginId: string
}

const initialProviders = {
  countriesLoading: false,
  countries: [],
  selectedCountry: null,
  providersLoading: false,
  providerDetailLoading: false,
  countryAvailableProviders: [],
  alreadyConnectedProviders: [],
  selectedProviderCode: null,
  providerDetail: null,
  reconnectLoginId: null,
}

export default function providersList(state: ProviderListState = initialProviders, action: Action): ProviderListState {
  switch (action.type) {
    case FETCH_COUNTRIES_START: {
      return { ...state, countriesLoading: true, countries: [] }
    }

    case FETCH_COUNTRIES_SUCCESS: {
      return {
        ...state,
        countriesLoading: false,
        countries: action.payload,
      }
    }

    case CHANGE_SELECTED_COUNTRY: {
      return { ...state, selectedCountry: action.payload }
    }

    case CHANGE_SELECTED_PROVIDER: {
      return { ...state, selectedProviderCode: action.payload }
    }

    case FETCH_PROVIDERS_START: {
      return { ...state, providersLoading: true, countryAvailableProviders: [] }
    }

    case FETCH_PROVIDERS_SUCCESS: {
      return {
        ...state,
        providersLoading: false,
        countryAvailableProviders: action.payload,
      }
    }

    case FETCH_PROVIDERS_ERROR: {
      return {
        ...state,
        providersLoading: false,
        countryAvailableProviders: [],
      }
    }

    case FETCH_EXISTING_PROVIDERS_SUCCESS: {
      return {
        ...state,
        alreadyConnectedProviders: action.payload,
      }
    }

    case RECONNECT_BANK_START: {
      return { ...state, reconnectLoginId: action.payload }
    }

    case FETCH_PROVIDER_DETAILS_START: {
      return { ...state, providerDetailLoading: true }
    }

    case FETCH_PROVIDER_DETAILS_ERROR: {
      return { ...state, providerDetailLoading: false }
    }

    case FETCH_PROVIDER_DETAILS_SUCCESS: {
      return { ...state, providerDetailLoading: false, providerDetail: action.payload }
    }

    case CLEAR_PROVIDER_SELECTION: {
      return { ...state, selectedProviderCode: null, providerDetail: null, reconnectLoginId: null }
    }

    default: {
      return state
    }
  }
}

