import * as commonRepository from "src/backend/common/repository"
import { convertResultToEntity, WalletSQLInMemory } from "src/backend/db/inMemorySqlDb"
import { inMemoryTableNames } from "src/backend/db/inMemorySqlDbSchemaBuilder"

import { ConfigureDocument } from "src/types/Configure"

export function findByKey(key: string): Promise<ConfigureDocument> {
  const sqlDb = WalletSQLInMemory.getInstance()
  const table = sqlDb.getTable(inMemoryTableNames.CONFIGURE)
  return sqlDb
    .select()
    .from(table)
    .where(table.key.eq(key))
    .exec()
    .then(convertResultToEntity()) as Promise<ConfigureDocument>
}

export function findAllByKey(key: string) {
  const sqlDb = WalletSQLInMemory.getInstance()
  const table = sqlDb.getTable(inMemoryTableNames.CONFIGURE)
  return sqlDb.select().from(table).where(table.key.eq(key)).exec()
}

export function findAll(): Promise<ConfigureDocument[]> {
  return commonRepository.findAll(inMemoryTableNames.CONFIGURE) as Promise<ConfigureDocument[]>
}
