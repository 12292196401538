import { convertGranularityToGroupByAttribute } from "src/backend/common/service"
import * as accountsRepository from "src/backend/accounts/repository"
import {
  composeBalance,
  convertChartLabels,
  getDateRange,
  getDateRangeForAllDatasets,
  reduceTotalsByCategories,
} from "src/backend/analytics/helpers"
import {
  filterEmptyDatasets,
  getCardinalityEnvelopes,
  getDashboardGranularity,
  mapBarDatasetToPieDataset,
  sortByDataSum,
} from "src/backend/dashboard/helpers"
import {
  convertToFilter,
  getPreviousPeriodFilter,
  getYearAgoPeriodFilter,
} from "src/backend/filters/helpers"
import * as recordsRepository from "src/backend/records/repository"
import * as currenciesRepository from "src/backend/currencies/repository"
import {
  calcInvestmentsToDate,
  calcTotalsByAttribute,
  calcTotalsByAttributeToDate,
  getChartData,
  getOpeningBalance,
  prepareDataset,
} from "src/backend/analytics/service"
import { generateVirtualRecordsByFilter } from "src/backend/standingOrders/service"
import * as timeUtils from "src/backend/time/time"
import { ChartGroupByType, ChartSubjectType } from "src/frontend/scenes/analytics/enums"
import { LABELS_PER_CHART } from "src/frontend/scenes/dashboard/components/constants"
import {
  CreditUtilization,
  GaugeValues,
  PerformanceValues,
  WidgetData,
  WidgetOptions,
} from "src/frontend/scenes/dashboard/types"
import { Account } from "src/types/Account"
import { FilterType } from "src/types/Filter"
import { prepareLinearChartData } from "./chartDataConverter"
import * as helpers from "src/common/helpers"
import moment from "moment"
import { ViewDefinition } from "src/frontend/scenes/analytics/types"
import { ChartType } from "src/frontend/components/chart/ChartType"
import _groupBy from "lodash/groupBy"
import _omitBy from "lodash/omitBy"
import {
  getEnvelopeById,
  getEnvelopeByIds,
  getEnvelopesBySuperEnvelopeId,
  getSuperEnvelopes,
  incomeSuperEnvelopeIds,
  OPERATING_COSTS_SUPER_ENVELOPE_IDS,
  OPERATING_REVENUE_SUPER_ENVELOPE_IDS,
  SYSTEM_CATEGORIES_ID,
  SYSTEM_CATEGORIES_UNKNOWN_ID,
} from "src/backend/categories/envelopes"
import { isAppBoard } from "src/common/environment"
import { HashMap } from "src/types/common"
import {
  BalanceDisplayType,
  IntervalCompareToType,
  IntervalGranularityType,
} from "src/backend/enums"
import { reduceBy } from "src/common/utils"
import _sum from "lodash/sum"
import { Totals } from "src/backend/analytics/types"
import { SpendingPieOptions } from "src/frontend/scenes/dashboard/widgets/PieAndBarSpending/types"
import { Id } from "src/types/CouchDb"
import { RecordCategoryLevel } from "src/backend/categories/enums"
import { findCustomCategoriesForEnvelopeId } from "src/backend/categories/repository"
import { AbstractEnvelope, CategoryDocument } from "src/types/Category"
import { PieAndSpendingWidgetVariant } from "src/frontend/scenes/dashboard/widgets/PieAndBarSpending/enums"
import { SpendingByNatureVariant } from "src/frontend/scenes/dashboard/widgets/SpendingByNatureBars/enums"

export function fetchPerformance(filter: FilterType): Promise<PerformanceValues> {
  return Promise.all([
    fetchBalancePerformanceValue(filter),
    fetchCashFlowPerformanceValue(filter),
    fetchSpendingPerformanceValue(filter),
  ]).then(([balance, cashFlow, spending]) => ({
    balance,
    cashFlow,
    spending,
  }))
}

export async function fetchCashRatioLiquidity(filter: FilterType) {
  return generateVirtualRecordsByFilter(filter).then((virtualRecords) => {
    return _groupBy(virtualRecords, "accountId")
  })
}

async function fetchBalancePerformanceValue(filter: FilterType): Promise<GaugeValues> {
  const balance = await getOpeningBalance(filter)

  const filterWithoutTransfers = {
    ...filter,
    transfer: false,
  }

  let { refExpense } = await calcTotalsByAttribute(filterWithoutTransfers)
  const { initialAssetCashFlow, portfolioValue } = await calcInvestmentsToDate(filter)
  const investmentsBalance = portfolioValue * 100 + initialAssetCashFlow

  const firstRecordDate = await recordsRepository
    .findFirstRecordDate()
    .then((date) => (date && moment(date)) || moment())
  // if user's data are 'younger' than the full period interval, we average expenses of the period from the fist record
  if (firstRecordDate.isAfter(filter.period.start)) {
    const intervalSize = timeUtils.getDayIntervalSize(firstRecordDate, moment())
    const filterIntervalSize = timeUtils.getDayIntervalSize(filter.period.start, filter.period.end)
    refExpense = (refExpense / intervalSize) * filterIntervalSize
  }

  // zero values return 50% value
  if (
    (refExpense === 0 && balance === 0 && investmentsBalance === 0) ||
    moment().isBefore(filter.period.start)
  ) {
    return {
      performanceValue: 0.5,
      labelValue: 0,
    }
  }

  return {
    performanceValue: helpers.limitValue(0, 1, balance / (2 * refExpense || 1)),
    labelValue: balance + investmentsBalance,
  }
}

async function fetchCashFlowPerformanceValue(filter: FilterType): Promise<GaugeValues> {
  const filterWithoutTransfers = {
    ...filter,
    transfer: false,
  }

  const { refExpense, refIncome } = await calcTotalsByAttribute(filterWithoutTransfers)
  const refCashFlow = refIncome - refExpense

  // zero values return 50% value
  if (refIncome === 0 || moment().isBefore(filter.period.start)) {
    return {
      performanceValue: 0.5,
      labelValue: refCashFlow,
    }
  }

  return {
    performanceValue: helpers.limitValue(0, 1, refCashFlow / (refIncome * 0.2)),
    labelValue: refCashFlow,
  }
}

async function fetchSpendingPerformanceValue(filter: FilterType): Promise<GaugeValues> {
  const filterWithoutTransfers = {
    ...filter,
    transfer: false,
  }

  const PreviousPeriodFilterWithoutTransfers = getPreviousPeriodFilter(filterWithoutTransfers)

  if (moment().isBefore(filter.period.start)) {
    return {
      performanceValue: 0.5,
      labelValue: 0,
    }
  }

  const { refExpense } = await calcTotalsByAttribute(filterWithoutTransfers)
  const { refExpense: previousPeriodRefExpense } = await calcTotalsByAttribute(
    PreviousPeriodFilterWithoutTransfers,
  )

  const diff = helpers.countPercentChange(previousPeriodRefExpense, refExpense)

  const LOW_BOUND = -0.3
  const HIGH_BOUND = 0.3
  const intervalSize = HIGH_BOUND - LOW_BOUND

  const result = helpers.limitValue(LOW_BOUND, HIGH_BOUND, diff)

  return {
    performanceValue: 1 - (HIGH_BOUND + result) / intervalSize,
    labelValue: -refExpense,
  }
}

export async function fetchBalanceChartData(filter: FilterType): Promise<WidgetData> {
  const viewDefinition: ViewDefinition = {
    subject: ChartSubjectType.BALANCE,
    groupBy: ChartGroupByType.NONE,
    granularity: getDashboardGranularity(filter.period),
    chartType: ChartType.LINE,
    compareTo: IntervalCompareToType.NONE,
  }

  const data = await getChartData(viewDefinition, filter)
  const openingInvestmentsData = (await calcInvestmentsToDate(getPreviousPeriodFilter(filter))) ?? {
    portfolioValue: 0,
    initialAssetCashFlow: 0,
  }

  return {
    ...prepareLinearChartData(data, filter.period, viewDefinition.granularity),
    labels: convertChartLabels(data),
    openingBalance:
      data[0].openingBalance +
      openingInvestmentsData.portfolioValue * 100 +
      openingInvestmentsData.initialAssetCashFlow,
    closingBalance: data[0].closingValue,
    labelsPerChart: LABELS_PER_CHART,
    type: ChartType.LINE,
  }
}

export async function fetchCashFlowTrendChartData(
  filter: FilterType,
  options: WidgetOptions,
): Promise<WidgetData> {
  const filterWithoutTransfers = convertToFilter({
    ...filter,
    transfer: false,
  })

  const viewDefinition: ViewDefinition = {
    subject: ChartSubjectType.CASH_FLOW,
    groupBy: ChartGroupByType.NONE,
    granularity: getDashboardGranularity(filterWithoutTransfers.period),
    chartType: ChartType.LINE,
    compareTo: IntervalCompareToType.NONE,
  }

  const groupByGranularity = convertGranularityToGroupByAttribute(viewDefinition.granularity)

  const data = await calcTotalsByAttribute(filterWithoutTransfers, [groupByGranularity])

  const { cumulative } = options

  const cashFlowSubject = cumulative
    ? ChartSubjectType.CUMULATIVE_CASH_FLOW
    : ChartSubjectType.CASH_FLOW

  const datasets = [
    prepareDataset({ ...viewDefinition, subject: cashFlowSubject }, filterWithoutTransfers, data),
    prepareDataset(
      { ...viewDefinition, subject: ChartSubjectType.INCOME },
      filterWithoutTransfers,
      data,
    ),
    prepareDataset(
      { ...viewDefinition, subject: ChartSubjectType.EXPENSE },
      filterWithoutTransfers,
      data,
      0,
      true,
    ),
  ]

  return {
    ...prepareLinearChartData(datasets, filterWithoutTransfers.period, viewDefinition.granularity),
    labels: convertChartLabels(datasets),
    labelsPerChart: LABELS_PER_CHART,
    type: ChartType.BAR,
  }
}

export async function fetchPeriodToPeriodChartData(
  filter: FilterType,
  options: WidgetOptions,
): Promise<WidgetData> {
  const periodToPeriodFilter = isAppBoard()
    ? filter
    : {
        ...filter,
        transfer: false,
      }

  const { subject } = options

  const viewDefinition: ViewDefinition = {
    subject,
    groupBy: ChartGroupByType.NONE,
    granularity: getDashboardGranularity(filter.period),
    chartType: ChartType.LINE,
    compareTo: IntervalCompareToType.NONE,
  }

  const previousPeriodFilter = getPreviousPeriodFilter(periodToPeriodFilter)
  const yearAgoPeriodFilter = getYearAgoPeriodFilter(periodToPeriodFilter)

  const [currentPeriodData, previousPeriodData, yearAgoData] = await Promise.all([
    getChartData(viewDefinition, periodToPeriodFilter),
    getChartData(viewDefinition, previousPeriodFilter),
    getChartData(viewDefinition, yearAgoPeriodFilter),
  ])

  const datasets = [...currentPeriodData, ...previousPeriodData, ...yearAgoData]

  return {
    ...prepareLinearChartData(datasets, periodToPeriodFilter.period, viewDefinition.granularity),
    labels: convertChartLabels(datasets),
    openingBalance: currentPeriodData[0].openingBalance,
    closingBalance: currentPeriodData[0].closingValue,
    labelsPerChart: LABELS_PER_CHART,
    type: ChartType.LINE,
  }
}

export const DASHBOARD_LAST_RECORDS_COUNT = 4

export function fetchLastRecords(filter: FilterType): Promise<WidgetData> {
  return recordsRepository.findLastRecords(filter, DASHBOARD_LAST_RECORDS_COUNT)
}

export function fetchCashFlow(filter: FilterType): Promise<WidgetData> {
  const filterWithoutTransfers = {
    ...filter,
    transfer: false,
  }

  const previousPeriodTotals = calcTotalsByAttribute(
    getPreviousPeriodFilter(filterWithoutTransfers),
  )
  const currentPeriodTotals = calcTotalsByAttribute(filterWithoutTransfers)

  return Promise.all([previousPeriodTotals, currentPeriodTotals]).then(
    ([previousPeriod, currentPeriod]) => {
      return {
        previousPeriod,
        currentPeriod,
      }
    },
  )
}

export function fetchExpenseCategories(filter: FilterType): Promise<WidgetData> {
  const filterWithoutTransfers = {
    ...filter,
    transfer: false,
  }

  const GROUP_BY_SUPER_ENVELOPE = ["superEnvelopeId"]
  const GROUP_BY_ENVELOPE = ["envelopeId"]

  const previousPeriodTotals = calcTotalsByAttribute(
    getPreviousPeriodFilter(filterWithoutTransfers),
    GROUP_BY_SUPER_ENVELOPE,
  )
  const currentPeriodTotals = calcTotalsByAttribute(filterWithoutTransfers, GROUP_BY_SUPER_ENVELOPE)

  const currentPeriodUnknownTotals = calcTotalsByAttribute(
    {
      ...filterWithoutTransfers,
      envelopeIds: [SYSTEM_CATEGORIES_UNKNOWN_ID],
    },
    GROUP_BY_ENVELOPE,
  )
  const previousPeriodUnknownTotals = calcTotalsByAttribute(
    getPreviousPeriodFilter({
      ...filterWithoutTransfers,
      envelopeIds: [SYSTEM_CATEGORIES_UNKNOWN_ID],
    }),
    GROUP_BY_ENVELOPE,
  )

  return Promise.all([
    previousPeriodTotals,
    currentPeriodTotals,
    currentPeriodUnknownTotals,
    previousPeriodUnknownTotals,
  ]).then(
    ([
      previousPeriodTotalsResult,
      currentPeriodTotalsResult,
      currentPeriodUnknownTotalsResult,
      previousPeriodUnknownTotalsResult,
    ]) => {
      return {
        previousPeriod: _omitBy(
          {
            ...previousPeriodTotalsResult,
            ...previousPeriodUnknownTotalsResult,
          },
          (_value, key) => key === SYSTEM_CATEGORIES_ID.toString(),
        ),
        currentPeriod: _omitBy(
          {
            ...currentPeriodTotalsResult,
            ...currentPeriodUnknownTotalsResult,
          },
          (_value, key) => key === SYSTEM_CATEGORIES_ID.toString(),
        ),
      }
    },
  )
}

export async function fetchPlannedPayments(filter: FilterType): Promise<WidgetData> {
  return generateVirtualRecordsByFilter(filter).then((virtualRecords) => {
    return virtualRecords.sort((a, b) => a.recordDate - b.recordDate || a.refAmount - b.refAmount)
  })
}

export async function fetchBalanceByCurrency(filter: FilterType): Promise<WidgetData> {
  const [accounts, currencies, currencyTotalsToDate] = await Promise.all([
    accountsRepository.findByIds(filter.accountIds),
    currenciesRepository.findByIds(filter.currencyId),
    calcTotalsByAttributeToDate(filter, ["currencyId"]),
  ])

  const investmentsTotalByAccounts = await Promise.all(
    accounts.map(async ({ _id }) => {
      const total = await calcInvestmentsToDate({ ...filter, accountIds: [_id] }).then(
        (investments) => {
          return investments.portfolioValue * 100 + investments.initialAssetCashFlow
        },
      )

      return {
        _id,
        total,
      }
    }),
  )

  return currencies.map((currency) => {
    const currencyAccountsInitBalance = accounts
      .filter((account: Account) => account.currencyId === currency._id)
      .reduce((acc: number, value: Account) => {
        const { total } = investmentsTotalByAccounts.find(({ _id }) => value._id === _id)

        return acc + composeBalance(value, 0) + (total * currency.ratioToReferential ?? 0)
      }, 0)

    const currencyRefBalanceToDate = currencyTotalsToDate[currency._id]
      ? currencyTotalsToDate[currency._id].refIncome - currencyTotalsToDate[currency._id].refExpense
      : 0

    const { code, ratioToReferential, name } = currency
    const balance =
      currencyAccountsInitBalance + currencyRefBalanceToDate * (ratioToReferential || 1)
    const refBalance =
      currencyAccountsInitBalance / (ratioToReferential || 1) + currencyRefBalanceToDate

    return {
      currencyId: currency._id,
      code,
      ratioToReferential,
      balance,
      name,
      refBalance,
    }
  })
}

export async function fetchCreditLimitUtilization(
  filter: FilterType,
): Promise<HashMap<CreditUtilization>> {
  const accounts = await accountsRepository.findCreditOverDraftByIds(filter.accountIds)
  const accountIds = accounts.map((account) => account._id)

  const previousPeriodFilter = getPreviousPeriodFilter({ ...filter, accountIds })

  const [currencies, accountsTotalsToDate, accountsTotalsByDate] = await Promise.all([
    currenciesRepository.findAllAsHashMap(),
    calcTotalsByAttributeToDate(previousPeriodFilter, ["accountId"]),
    calcTotalsByAttribute({ ...filter, accountIds }, ["accountId", "recordDateDay"]),
  ])

  const totalsArray = accounts.map((account: Account): CreditUtilization => {
    const balanceToDate = accountsTotalsToDate[account._id]
      ? accountsTotalsToDate[account._id].income - accountsTotalsToDate[account._id].expense
      : 0

    const currency = currencies[account._id]

    const dateRangePeriod = getDateRange(accountsTotalsByDate[account._id], filter.period)
    const dates = timeUtils.generateDateStrings(dateRangePeriod, IntervalGranularityType.DAY)

    const creditRefBalances =
      account._id in accountsTotalsByDate
        ? Object.values<string>(dates)
            .reduce(
              (acc: number[], value: string, index): number[] => {
                const data = accountsTotalsByDate[account._id][value]

                return [...acc, acc[index] + (data ? data.income - data.expense : 0)]
              },
              [balanceToDate],
            )
            .map((balance) => {
              const creditBalance = composeBalance(
                account,
                balance,
                BalanceDisplayType.CREDIT_BALANCE,
              )
              return -1 * creditBalance * (currency ? currency.ratioToReferential : 1)
            })
        : [undefined]

    const maxValue = Math.max(...creditRefBalances) || 0
    const avgValue = _sum(creditRefBalances) / creditRefBalances.length || 0

    const endingCreditRefBalance = creditRefBalances[creditRefBalances.length - 1] || 0

    return {
      accountId: account._id,
      endingCreditRefBalance,
      maxValue,
      avgValue,
    }
  })

  return totalsArray.reduce(reduceBy("accountId"), {})
}

async function getPieAndBarCategories(
  widgetVariant: PieAndSpendingWidgetVariant,
  categoryLevel: RecordCategoryLevel,
  parentCategoryId?: number | Id,
): Promise<(AbstractEnvelope | CategoryDocument)[]> {
  if (categoryLevel === RecordCategoryLevel.SUPER_ENVELOPE) {
    switch (widgetVariant) {
      default:
      case PieAndSpendingWidgetVariant.SPENDING:
        return getSuperEnvelopes().filter(
          (superEnvelope) => !incomeSuperEnvelopeIds.includes(superEnvelope.id),
        )

      case PieAndSpendingWidgetVariant.REVENUE:
        return getEnvelopeByIds(OPERATING_REVENUE_SUPER_ENVELOPE_IDS)

      case PieAndSpendingWidgetVariant.COSTS:
        return getEnvelopeByIds(OPERATING_COSTS_SUPER_ENVELOPE_IDS)
    }
  }

  if (categoryLevel === RecordCategoryLevel.ENVELOPE) {
    return getEnvelopesBySuperEnvelopeId(parentCategoryId as number)
  }

  if (categoryLevel === RecordCategoryLevel.CATEGORY) {
    return await findCustomCategoriesForEnvelopeId(parentCategoryId)
  }
}

export async function fetchPieAndBarSpending(
  filter: FilterType,
  options: SpendingPieOptions,
  variant: PieAndSpendingWidgetVariant,
  parentCategoryId?: number | Id,
) {
  const viewDefinition: ViewDefinition = {
    subject: ChartSubjectType.CASH_FLOW,
    groupBy: ChartGroupByType.NONE,
    granularity: getDashboardGranularity(filter.period),
    chartType: ChartType.BAR,
    compareTo: IntervalCompareToType.NONE,
  }

  const categoryLevelAttribute = {
    [RecordCategoryLevel.SUPER_ENVELOPE]: "superEnvelopeId" as "superEnvelopeId",
    [RecordCategoryLevel.ENVELOPE]: "envelopeId" as "envelopeId",
    [RecordCategoryLevel.CATEGORY]: "categoryId" as "categoryId",
  }

  const categoryFilterAttribute = {
    [RecordCategoryLevel.SUPER_ENVELOPE]: "superEnvelopeIds",
    [RecordCategoryLevel.ENVELOPE]: "envelopeIds",
    [RecordCategoryLevel.CATEGORY]: "categoryId",
  }

  const { categoryLevel } = options

  const groupByGranularity = convertGranularityToGroupByAttribute(viewDefinition.granularity)
  const GROUP_BY = [
    groupByGranularity,
    "superEnvelopeId",
    categoryLevel === RecordCategoryLevel.CATEGORY ? "categoryId" : "envelopeId",
  ]

  const categoriesPromise = getPieAndBarCategories(variant, categoryLevel, parentCategoryId)
  const totalSuperEnvelopesPromise = getPieAndBarCategories(
    variant,
    RecordCategoryLevel.SUPER_ENVELOPE,
  )

  const [categories, totalSuperEnvelopes] = await Promise.all([
    categoriesPromise,
    totalSuperEnvelopesPromise,
  ])

  const categoryIds = categories.map((category) => {
    return (category as AbstractEnvelope).id || (category as CategoryDocument)._id
  })

  const filterWithCategories = {
    ...filter,
    [categoryFilterAttribute[categoryLevel]]: categoryIds,
  }

  const totalSuperEnvelopeIds = totalSuperEnvelopes.map(
    (category) => (category as AbstractEnvelope).id,
  )

  const filterWithTotalCategories = {
    ...filter,
    superEnvelopeIds: totalSuperEnvelopeIds,
  }

  const currentTotalsPromise = calcTotalsByAttribute(filterWithTotalCategories, [
    "superEnvelopeId",
    "envelopeId",
  ]).then((totals) => {
    return reduceTotalsByCategories(totals, totalSuperEnvelopeIds, "superEnvelopeId")
  })

  const previousTotalsPromise = calcTotalsByAttribute(
    getPreviousPeriodFilter(filterWithTotalCategories),
    ["superEnvelopeId", "envelopeId"],
  ).then((totals) => {
    return reduceTotalsByCategories(totals, totalSuperEnvelopeIds, "superEnvelopeId")
  })

  const dataPromise = calcTotalsByAttribute(filterWithCategories, GROUP_BY)

  const [data, currentTotals, previousTotals] = await Promise.all([
    dataPromise,
    currentTotalsPromise,
    previousTotalsPromise,
  ])

  const parentCategory = parentCategoryId && getEnvelopeById(parentCategoryId as number)

  const barChartDatasets = categories
    .map((category) => {
      const id = (category as AbstractEnvelope).id || (category as CategoryDocument)._id

      const aggregatorFn = (totals: HashMap<Totals>) =>
        reduceTotalsByCategories(totals, [id], categoryLevelAttribute[categoryLevel])

      const dataset = prepareDataset(
        viewDefinition,
        filterWithCategories,
        data,
        0,
        false,
        aggregatorFn,
      )

      return {
        id,
        category,
        type: ChartType.BAR,
        ...dataset,
      }
    })
    .filter(filterEmptyDatasets)
    .sort(sortByDataSum)

  const pieChartDatasets = barChartDatasets.map(mapBarDatasetToPieDataset)

  return {
    totalCashFlow: currentTotals.refIncome - currentTotals.refExpense,
    previousPeriodCashFlow: previousTotals.refIncome - previousTotals.refExpense,
    goBackId: parentCategory && parentCategory.parentId,
    barChartDatasets: {
      ...prepareLinearChartData(
        barChartDatasets,
        filterWithCategories.period,
        viewDefinition.granularity,
        true,
      ),
      labels: convertChartLabels(barChartDatasets),
      labelsPerChart: LABELS_PER_CHART,
      type: ChartType.BAR,
    },
    pieChartDatasets,
    parentCategoryId,
  }
}

export async function fetchSpendingByNature(filter: FilterType, variant: SpendingByNatureVariant) {
  const viewDefinition: ViewDefinition = {
    subject: ChartSubjectType.CASH_FLOW,
    groupBy: ChartGroupByType.NONE,
    granularity: getDashboardGranularity(filter.period),
    chartType: ChartType.BAR,
    compareTo: IntervalCompareToType.NONE,
  }

  const groupByGranularity = convertGranularityToGroupByAttribute(viewDefinition.granularity)
  const GROUP_BY = [groupByGranularity, "superEnvelopeId", "envelopeId"]

  const cardinalityEnvelopes = getCardinalityEnvelopes(variant)

  const totalEnvelopes = Object.values(cardinalityEnvelopes).flat()

  const envelopeIds = totalEnvelopes.map((envelope) => {
    return envelope.id
  })

  const filterWithCategories = {
    ...filter,
    envelopeIds,
  }

  const data = await calcTotalsByAttribute(filterWithCategories, GROUP_BY)

  const barChartDatasets = Object.keys(cardinalityEnvelopes).map((cardinality) => {
    const ids = cardinalityEnvelopes[cardinality].map((envelopes) => envelopes.id)

    const aggregatorFn = (totals: HashMap<Totals>) =>
      reduceTotalsByCategories(totals, ids, "envelopeId")

    const dataset = prepareDataset(
      viewDefinition,
      filterWithCategories,
      data,
      0,
      false,
      aggregatorFn,
    )

    return {
      id: cardinality,
      cardinality,
      ...dataset,
    }
  })

  return {
    barChartDatasets: {
      ...prepareLinearChartData(
        barChartDatasets,
        filterWithCategories.period,
        viewDefinition.granularity,
        true,
      ),
      labels: convertChartLabels(barChartDatasets),
      labelsPerChart: LABELS_PER_CHART,
      type: ChartType.BAR,
    },
  }
}
