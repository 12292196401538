import React, { useCallback, useMemo } from 'react'
import { connect } from 'react-redux'
import * as yup from 'yup'
import { Formik } from 'formik'
import * as appActions from 'src/frontend/scenes/app/actions'
import * as userActions from 'src/frontend/modules/user/actions'
import { authModuleErrorHandler } from 'src/frontend/scenes/auth/helpers'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import LoginUserPassForm from 'src/frontend/scenes/auth/login/LoginUserPassForm'
import { validate } from 'src/frontend/scenes/auth/validator'
import { removeReferralSource, useReferralSource } from 'src/frontend/scenes/auth/signup/hooks'
import { ReferralSource } from 'src/types/User'

const mapDispatchToProps = {
  login: userActions.login,
  initAuthenticatedApp: appActions.initAuthenticatedApp,
}

interface Props extends RouteComponentProps {
  login: (username: string, password: string, referralSource: ReferralSource) => void,
  initAuthenticatedApp: () => void,
  referrer?: string,
}

LoginUserPassFormContainer.defaultProps = {
  referrer: undefined,
}

function LoginUserPassFormContainer({ referrer, history, login, initAuthenticatedApp }: Props) {

  const initialValues = {
    email: '',
    password: '',
  }

  const validationSchema = useMemo(() => {
    return yup.object().shape({
      email: yup.string().required('auth.error.email.is_required'),
      password: yup.string().required('auth.error.password.is_required'),
    })
  }, [])

  const handleValidate = useCallback((values) => validate(validationSchema, values), [])

  const onSubmit = useCallback(async (values, { setSubmitting, setErrors }) => {
    const { email, password } = values

    try {
      const referralSource = useReferralSource()

      await login(email, password, referralSource)
      await initAuthenticatedApp()
      removeReferralSource()
      referrer && history.push(referrer)
    } catch (error) {
      authModuleErrorHandler(setSubmitting, setErrors, 'Login failed')(error)
    }
  }, [referrer, history])

  return (
    <Formik
      initialValues={initialValues}
      validate={handleValidate}
      onSubmit={onSubmit}
    >
      {(props) => <LoginUserPassForm {...props} />}
    </Formik>
  )
}

export default withRouter(connect(null, mapDispatchToProps)(LoginUserPassFormContainer))
