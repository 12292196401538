import { Action, GetState } from 'src/types/common'
import { selectUser } from 'src/frontend/modules/user/selectors'
import {
  checkProviderStatus,
  disconnectIntegrationAccount,
  refreshIntegrationTransactions,
  sendMFA,
} from 'src/backend/integrations/service'
import { isAuthorizationError, isIntegrationError, isServiceUnavailableError } from 'src/common/helpers'
import { expireUser } from 'src/frontend/modules/user/actions'
import { Id } from 'src/types/CouchDb'
import { IntegrationProviders } from 'src/frontend/scenes/integrations/providersList/types'
import * as logger from 'src/common/logger'
import { showError } from 'src/frontend/scenes/app/actions'
import { DEFAULT_ERROR_MESSAGE_ID } from 'src/frontend/Error/actions'
import {
  selectActiveMFARefreshes,
  selectRefreshProvider,
} from 'src/frontend/scenes/integrations/connections/selectors'
import { FormAttributeFields } from 'src/frontend/scenes/integrations/types'
import { IntegrationError, NoRemoteAccountsError } from 'src/backend/integrations/errors'
import { reconnectBank } from 'src/frontend/scenes/integrations/providersList/actions'
import { requestPushPermissions, sendPushNotification } from 'src/backend/pushNotifications/service'
import {
  trackIntegrationsDisconnect,
  trackIntegrationsMFASent,
  trackIntegrationsRefresh,
  trackIntegrationsRefreshError,
  trackIntegrationsRefreshSuccess,
} from 'src/common/mixpanel'
import { isOAuth } from 'src/backend/integrations/helpers'
import { BaseError } from 'make-error'
import { IntegrationErrorType } from 'src/frontend/scenes/integrations/connections/types'
import IntegrationLoginStatus = IntegrationProviders.IntegrationLoginStatus
import IntegrationRecipeGroup = IntegrationProviders.IntegrationRecipe.IntegrationRecipeGroup
import Timer = NodeJS.Timer
import { fetchRestrictedProviders } from 'src/frontend/scenes/integrations/restrictedProviders/actions'
import { IntegrationSource } from 'src/backend/integrations/enums'
import { getIntegrationSourcePollingTimeout } from 'src/frontend/scenes/integrations/helpers'

export const REFRESH_START = 'integrations/connections/REFRESH_START'
export const REFRESH_END = 'integrations/connections/REFRESH_SUCCESS'

export const REFRESH_ERROR = 'integrations/connections/REFRESH_ERROR'

export const DISCONNECT_ACCOUNT_START = 'integrations/connections/DISCONNECT_ACCOUNT_START'
export const DISCONNECT_ACCOUNT_SUCCESS = 'integrations/connections/DISCONNECT_ACCOUNT_SUCCESS'
export const DISCONNECT_ACCOUNT_ERROR = 'integrations/connections/DISCONNECT_ACCOUNT_ERROR'

export const EXISTING_INTEGRATIONS_FORM_OPEN = 'integrations/connections/EXISTING_INTEGRATIONS_FORM_OPEN'
export const EXISTING_INTEGRATIONS_FORM_CLOSE = 'integrations/connections/EXISTING_INTEGRATIONS_FORM_CLOSE'

export const SET_ACTIVE_MFA = 'integrations/connections/SELECT_ACTIVE_MFA'

export const REFRESH_SHOW_MFA = 'integrations/connections/REFRESH_SHOW_MFA'
export const REFRESH_HIDE_MFA = 'integrations/connections/REFRESH_HIDE_MFA'

export const RECONNECT_START = "integrations/connections/RECONNECT_START"
export const RECONNECT_END = "integrations/connections/RECONNECT_END"

export function refreshBank(loginId: Id) {
  return async (dispatch: Function, getState: GetState) => {
    const { userId } = selectUser(getState())
    const provider = selectRefreshProvider(loginId)(getState())
    if (!provider) {
      dispatch(refreshBankEnd(loginId))
    }

    trackIntegrationsRefresh()
    requestPushPermissions()
    dispatch(refreshBankStart(loginId, provider.name))
    try {
      const { status, formAttributeGroups, integrationSource } =
        await refreshIntegrationTransactions(userId, provider)
      dispatch(getRefreshAction(loginId, integrationSource, status, formAttributeGroups))
    } catch (error) {
      dispatch(
        handleCaughtExistingIntegrationError(
          loginId,
          error,
          "integrations.connections.refreshBank",
          { provider },
        ),
      )
    }
  }
}

export function checkRefreshIntegrationStatus(loginId) {
  return async (dispatch: Function, getState: GetState) => {
    const { userId } = selectUser(getState())
    const provider = selectRefreshProvider(loginId)(getState())
    if (!provider) {
      dispatch(refreshBankEnd(loginId))
    }
    try {
      const { status, formAttributeGroups, integrationSource } = await checkProviderStatus(
        userId,
        provider,
      )
      dispatch(getRefreshAction(loginId, integrationSource, status, formAttributeGroups))
    } catch (error) {
      dispatch(
        handleCaughtExistingIntegrationError(
          loginId,
          error,
          "integrations.connections.checkRefreshIntegrationStatus",
          { provider },
        ),
      )
    }
  }
}

function refreshBankStart(loginId: string, providerName: string): Action {
  return {
    type: REFRESH_START,
    payload: {
      loginId,
      providerName,
    },
  }
}

export function refreshBankEnd(loginId: string) {
  trackIntegrationsRefreshSuccess()
  return {
    type: REFRESH_END,
    payload: loginId,
  }
}

function getRefreshAction(
  loginId: Id,
  integrationSource: IntegrationSource,
  integrationLoginStatus?: IntegrationLoginStatus,
  formAttributeGroups?: IntegrationRecipeGroup[],
) {
  return (dispatch) => {
    switch (integrationLoginStatus) {
      default:
      case IntegrationLoginStatus.OK: {
        if (isOAuth(formAttributeGroups)) {
          console.warn("IntegrationLoginStatus.OK with formAttributeGroups")
          const ABORT_REFRESH_TIMEOUT = 600000 // abort refresh after 10 minutes of opened mfa form

          const MFATimeoutId = setTimeout(() => {
            dispatch(closeExistingIntegrationsForm())
            dispatch(refreshBankEnd(loginId))
          }, ABORT_REFRESH_TIMEOUT)

          return dispatch(showRefreshBankMFAForm(loginId, formAttributeGroups, MFATimeoutId))
        } else {
          console.warn("IntegrationLoginStatus.OK")
          return dispatch(refreshBankEnd(loginId))
        }
      }

      case IntegrationLoginStatus.MFA: {
        console.warn("IntegrationLoginStatus.MFA")
        const ABORT_REFRESH_TIMEOUT = 600000 // abort refresh after 10 minutes of opened mfa form

        const MFATimeoutId = setTimeout(() => {
          dispatch(closeExistingIntegrationsForm())
          dispatch(refreshBankEnd(loginId))
        }, ABORT_REFRESH_TIMEOUT)

        return dispatch(showRefreshBankMFAForm(loginId, formAttributeGroups, MFATimeoutId))
      }

      case IntegrationLoginStatus.INVALID_CREDENTIALS: {
        console.warn("IntegrationLoginStatus.INVALID_CREDENTIALS")
        dispatch(closeExistingIntegrationsForm())
        dispatch(refreshBankEnd(loginId))
        return dispatch(reconnectBank(loginId))
      }
      case IntegrationLoginStatus.ERROR: {
        console.warn("IntegrationLoginStatus.ERROR")
        return dispatch(showRefreshError(loginId))
      }

      case IntegrationLoginStatus.CHECK_LATER: {
        console.warn("IntegrationLoginStatus.CHECK_LATER")
        const pollingTimeout = getIntegrationSourcePollingTimeout(integrationSource)
        return setTimeout(() => dispatch(checkRefreshIntegrationStatus(loginId)), pollingTimeout)
      }
    }
  }
}

function showRefreshError(loginId: string) {
  trackIntegrationsRefreshError()
  return {
    type: REFRESH_ERROR,
    payload: loginId,
  }
}

export function hideRefreshError(loginId: string) {
  return (dispatch: Function) => {
    dispatch(refreshBankEnd(loginId))
    return dispatch(closeExistingIntegrationsForm())
  }
}

function showRefreshBankMFAForm(
  loginId: string,
  formAttributeGroups: IntegrationRecipeGroup[],
  MFATimeoutId: Timer,
) {
  return (dispatch: Function) => {
    dispatch(openExistingIntegrationsForm())
    sendPushNotification("integrations.connections.mfa")
    return dispatch({
      type: REFRESH_SHOW_MFA,
      payload: {
        formAttributeGroups,
        loginId,
        MFATimeoutId,
      },
    })
  }
}

function hideRefreshBankMFAForm(loginId: string) {
  return {
    type: REFRESH_HIDE_MFA,
    payload: loginId,
  }
}

export function clearAllActiveRefreshes() {
  return (_dispatch: Function, getState: GetState) => {
    const activeRefreshes = selectActiveMFARefreshes(getState())
    Object.values(activeRefreshes).forEach((refresh) => clearTimeout(refresh.MFATimeoutId))
  }
}

export function finishOAuthRefresh(OAuthCallback: { loginId: string; success: boolean }) {
  return (dispatch: Function, getState: GetState) => {
    const { loginId, success } = OAuthCallback
    if (success) {
      const activeRefresh = selectActiveMFARefreshes(getState())[loginId]
      clearTimeout(activeRefresh.MFATimeoutId)
      dispatch(closeExistingIntegrationsForm())
      dispatch(hideRefreshBankMFAForm(loginId))
      // hack to clear refresh UI after BE sends callback, accounts are not refreshed, but OK callback already received
      // 5 minutes
      const FORCE_REFRESH_END_TIMEOUT = 500000
      return setTimeout(() => dispatch(refreshBankEnd(loginId)), FORCE_REFRESH_END_TIMEOUT)
    } else {
      return dispatch(showRefreshError(loginId))
    }
  }
}

export function sendRefreshMFAForm(loginId: string, formData: FormAttributeFields) {
  return async (dispatch: Function, getState: GetState) => {
    const { userId } = selectUser(getState())
    const provider = selectRefreshProvider(loginId)(getState())
    if (!provider) {
      dispatch(refreshBankEnd(loginId))
    }
    trackIntegrationsMFASent()
    try {
      const activeRefresh = selectActiveMFARefreshes(getState())[loginId]
      clearTimeout(activeRefresh.MFATimeoutId)

      const activeMFALoginIds = Object.keys(selectActiveMFARefreshes(getState()))
      if (activeMFALoginIds.length === 1) {
        dispatch(closeExistingIntegrationsForm())
      } else {
        const otherActiveMFALoginIds = activeMFALoginIds.filter((activeMFALoginId: string) => {
          return activeMFALoginId !== loginId
        })
        dispatch(setActiveMFALoginId(otherActiveMFALoginIds[0]))
      }
      dispatch(hideRefreshBankMFAForm(loginId))
      const { status, formAttributeGroups, integrationSource } = await sendMFA(
        userId,
        provider,
        formData,
      )
      dispatch(getRefreshAction(loginId, integrationSource, status, formAttributeGroups))
    } catch (error) {
      dispatch(
        handleCaughtExistingIntegrationError(
          loginId,
          error,
          "integrations.connections.sendRefreshMFAForm",
          { provider },
        ),
      )
    } finally {
        dispatch(refreshBankEnd(loginId))
      }
  }
}

export function setActiveMFALoginId(loginId: string) {
  return {
    type: SET_ACTIVE_MFA,
    payload: loginId,
  }
}

export function disconnectIntegratedAccount(
  loginId: string,
  remoteAccountId: string,
  accountId: Id,
  sourceName: string,
) {
  return async (dispatch, getState: GetState) => {
    const { userId } = selectUser(getState())
    dispatch(disconnectIntegratedAccountStart(loginId))
    trackIntegrationsDisconnect()
    try {
      await disconnectIntegrationAccount(userId, loginId, sourceName, remoteAccountId, accountId)
      dispatch(disconnectIntegratedAccountSuccess())
    } catch (error) {
      dispatch(disconnectIntegratedAccountError())
      if (isAuthorizationError(error)) {
        dispatch(expireUser())
        return
      } else {
        logger.captureException(error, "integrations.connections.disconnectIntegratedAccount", {
          loginId,
          remoteAccountId,
          accountId,
        })
        dispatch(showError([DEFAULT_ERROR_MESSAGE_ID, "app.error.we_are_fixing_suffix"]))
      }
    }
  }
}

function disconnectIntegratedAccountStart(loginId) {
  return {
    type: DISCONNECT_ACCOUNT_START,
    payload: loginId,
  }
}

function disconnectIntegratedAccountSuccess() {
  return {
    type: DISCONNECT_ACCOUNT_SUCCESS,
  }
}

function disconnectIntegratedAccountError() {
  return {
    type: DISCONNECT_ACCOUNT_ERROR,
  }
}

export function openExistingIntegrationsForm() {
  return {
    type: EXISTING_INTEGRATIONS_FORM_OPEN,
  }
}

export function closeExistingIntegrationsForm() {
  return {
    type: EXISTING_INTEGRATIONS_FORM_CLOSE,
  }
}

function handleCaughtExistingIntegrationError(
  loginId: string,
  error: BaseError | NoRemoteAccountsError | IntegrationError,
  actionName: string,
  extraData?: any,
) {
  return (dispatch: Function) => {
    if (isAuthorizationError(error)) {
      dispatch(expireUser())
      return dispatch(refreshBankEnd(loginId))
    } else if (isIntegrationError(error)) {
      if (
        error.type === IntegrationErrorType.ConsentExpired ||
        error.type === IntegrationErrorType.ConsentNotFound ||
        error.type === IntegrationErrorType.ConsentRevoked
      ) {
        dispatch(closeExistingIntegrationsForm())
        dispatch(refreshBankEnd(loginId))
        dispatch(reconnectBank(loginId))
      } else {
        dispatch(showRefreshError(loginId))
      }
    } else if (isServiceUnavailableError(error)) {
      dispatch(fetchRestrictedProviders())
      dispatch(closeExistingIntegrationsForm())
      dispatch(refreshBankEnd(loginId))
    } else {
      logger.captureException(error, actionName, extraData)
      dispatch(showError([DEFAULT_ERROR_MESSAGE_ID, "app.error.we_are_fixing_suffix"]))
    }
  }
}

export function setPendingReconnectProvider(providerCode: string) {
  return {
    type: RECONNECT_START,
    payload: providerCode,
  }
}

export function setPendingReconnectEnd() {
  return {
    type: RECONNECT_END,
  }
}
