import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Dropdown } from 'semantic-ui-react'
import * as accountActions from 'src/frontend/scenes/imports/account/actions'
import { buildAccountOptions } from 'src/frontend/scenes/imports/account/helpers'
import {
  selectAccountList,
  selectSelectedAccountId,
  selectSelectedAccountName,
} from 'src/frontend/scenes/imports/account/selectors'
import basicLayoutStyles from 'src/frontend/components/ColumnLayout/BasicLayout.module.less'
import { FormattedMessage } from 'src/frontend/modules/intl'
import styles from './ImportAccountFilter.module.less'

function mapStateToProps(state) {
  return ({
    accountOptions: buildAccountOptions(selectAccountList(state)),
    selectedAccountName: selectSelectedAccountName(state),
    selectedAccountId: selectSelectedAccountId(state),
  })
}

const mapDispatchToProps = {
  changeAccount: (ignored, { value }) => accountActions.accountFilterChanged(value),
}

ImportAccountFilter.propTypes = {
  accountOptions: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string.isRequired, value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  })).isRequired,
  selectedAccountId: PropTypes.string.isRequired,
  selectedAccountName: PropTypes.string.isRequired,
  changeAccount: PropTypes.func.isRequired,
}

function ImportAccountFilter({
  accountOptions,
  selectedAccountId,
  selectedAccountName,
  changeAccount,
}) {
  return (
    <div className={styles.sort}>
      <span className={basicLayoutStyles.filterLabel}><FormattedMessage id="Account" /></span>
      <Dropdown
        className="tour-guide_imports-main_1"
        value={selectedAccountId}
        text={selectedAccountName}
        options={accountOptions}
        selection
        selectOnBlur={false}
        onChange={changeAccount}
      />
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(ImportAccountFilter)
