import React, { useEffect, useState } from "react"
import styles from "./DashboardWSBanner.module.less"
import PictureIcon from "src/frontend/components/PictureIcon/PictureIcon"
import { PrimaryButton, SecondaryButton } from "src/frontend/components/Buttons/Buttons"
import FormattedMessage from "src/frontend/modules/intl/components/FormattedMessage"
import { Transition, Modal } from "semantic-ui-react"
import adImageEN from "../../../assets/images/WSbanner/WSModalImageAd-EN.png"
import adImageFR from "../../../assets/images/WSbanner/WSModalImageAd-FR.png"
import adImageDE from "../../../assets/images/WSbanner/WSModalImageAd-DE.png"
import adImageNL from "../../../assets/images/WSbanner/WSModalImageAd-NL.png"
import adImageIT from "../../../assets/images/WSbanner/WSModalImageAd-IT.png"
import adImageES from "../../../assets/images/WSbanner/WSModalImageAd-ES.png"
import { getInitialLanguageCode } from "src/frontend/modules/intl"
import {
  trackWSCampaignDashboardBannerImpression,
  trackWSCampaignModalBannerImpression,
  trackWSCampaignModalCTAClickthrough,
} from "src/common/mixpanel"

interface Props {
  handleClose: () => void
}

function DashboardWSBanner({ handleClose }: Props) {
  const [modalOpened, setModalOpened] = useState(false)

  useEffect(() => {
    trackWSCampaignDashboardBannerImpression()
  }, [])

  const handleCloseModal = () => {
    setModalOpened(false)
  }

  const getModalCTALink = () => {
    const allowedLanguages = ["fr", "de", "nl", "it", "es"]
    let languageCode = getInitialLanguageCode()
    languageCode = allowedLanguages.includes(languageCode) ? languageCode : "" // empty is EN, general fallback

    return `https://woolsocks.eu/${languageCode}?is_retargeting=true&source_caller=ui&shortlink=s1m7yonu&c=Budgetbakers&af_ad=app_message&pid=budgetbakers&af_click_lookback=30d&af_channel=budgetbakers`
  }

  const getBannerImageSrc = () => {
    switch (getInitialLanguageCode()) {
      case "en":
        return adImageEN
      case "fr":
        return adImageFR
      case "de":
        return adImageDE
      case "nl":
        return adImageNL
      case "it":
        return adImageIT
      case "es":
        return adImageES
      default:
        return adImageEN
    }
  }

  return (
    <div className={styles.wsDashboardBanner}>
      <PictureIcon
        name="WS-logo"
        width="50px"
        height="50px"
        compact
        inline
        customMargin="0 1rem"
      />
      <div className={styles.bannerCopyWrapper}>
        <p className={styles.mainTitle}>
          <FormattedMessage id={`WScampaign.dashboard.title`} />
        </p>
        <p className={styles.mainCopy}>
          <FormattedMessage id={`WScampaign.dashboard.mainCopy`} />
        </p>
      </div>
      <div className={styles.bannerButtonWrapper}>
        <SecondaryButton
          color="blue"
          onClick={() => {
            setModalOpened(true)
            trackWSCampaignModalBannerImpression()
          }}
        >
          <FormattedMessage id={`WScampaign.dashboard.ctaButton`} />
        </SecondaryButton>
      </div>
      <span
        className="modal-close"
        onClick={() => handleClose()}
      />
      <Transition
        key="modal"
        directional
        visible={modalOpened}
        unmountOnHide
        onHide={handleCloseModal}
        duration={{ show: 250, hide: 150 }}
        animation="modal-animation"
      >
        <Modal
          open
          size="small"
          onClose={handleCloseModal}
          className={styles.bannerModal}
        >
          <Modal.Header>
            <FormattedMessage id="WScampaign.modal.modalTitle" />
            <span
              className="modal-close"
              onClick={handleCloseModal}
            />
          </Modal.Header>
          <Modal.Content
            className={styles.noPadding}
            style={{ padding: 0 }}
          >
            <img
              src={getBannerImageSrc()}
              className={styles.adImage}
              alt="main Woolsocks banner image"
            />
            <div className={styles.modalCopyWrapper}>
              <h4 className={styles.modalMainCopyTitle}>
                <FormattedMessage id={`WScampaign.modal.title`} />
              </h4>
              <p>
                <FormattedMessage id={`WScampaign.modal.mainCopy`} />
              </p>
              <ol className={styles.modalList}>
                <li>
                  <FormattedMessage id={`WScampaign.modal.bulletPoints1`} />
                </li>
                <li>
                  <FormattedMessage id={`WScampaign.modal.bulletPoints2`} />
                </li>
                <li>
                  <FormattedMessage id={`WScampaign.modal.bulletPoints3`} />
                </li>
              </ol>
              <div className={styles.modalButtonWrapper}>
                <PrimaryButton
                  as="a"
                  href={getModalCTALink()}
                  target="_blank"
                  onClick={() => trackWSCampaignModalCTAClickthrough()}
                  style={{ textTransform: "uppercase" }}
                >
                  <FormattedMessage id={`WScampaign.modal.ctaButton`} />
                </PrimaryButton>
              </div>
            </div>
          </Modal.Content>
        </Modal>
      </Transition>
    </div>
  )
}

export default DashboardWSBanner
