import { createSelector } from 'reselect'
import { sortEntities } from 'src/backend/common/helpers'
import { Label } from 'src/types/Label'
import { RootState } from 'src/types/State'
import { LabelsState } from 'src/frontend/modules/labels/reducer'
import { selectModules } from 'src/frontend/modules/selectors'

export const selectAllLabels = (state: RootState): LabelsState => selectModules(state).labels


export const selectSortedLabels: (RootState) => Label[] = createSelector(selectAllLabels, sortEntities)
export const selectNonArchivedLabels = createSelector(
  selectSortedLabels,
  labels => labels.filter(label => !label.archived),
)
