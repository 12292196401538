import * as React from 'react'
import { getInitialLanguageCode } from 'src/frontend/modules/intl/languages'
import {
  getDefaultFormats,
  IntlProvider,
  setDecimalPlaces,
  setLanguage,
  WITH_DECIMAL_PLACES_VALUE,
  WITHOUT_DECIMAL_PLACES_VALUE,
} from '../i18n'
import { IntlConsumerType } from 'src/frontend/modules/intl/components/types'

type State = IntlConsumerType
export const IntlContext = React.createContext<IntlConsumerType>({
  changeLanguage: null,
  changeShowDecimals: null,
  localeUnderscore: null,
  locale: null,
  formats: {},
})

const { Provider, Consumer } = IntlContext

type Props = {
  children: React.ReactNode,
}

class IntlProviderWrapper extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    /* eslint-disable react/no-unused-state */
    this.state = {
      changeLanguage: this.changeLanguage,
      changeShowDecimals: this.changeShowDecimals,
      formats: getDefaultFormats(),
    }
    /* eslint-enable react/no-unused-state */
    this.initLang()
  }

  changeLanguage = async (_e: any, { value }: { value: string }) => {
    const language = await setLanguage(value)
    this.setState(() => ({ ...language }))
  }

  changeShowDecimals = (showDecimals: boolean) => {
    const decimalPlaces = showDecimals ? WITH_DECIMAL_PLACES_VALUE : WITHOUT_DECIMAL_PLACES_VALUE
    const { locale, messages } = this.state
    const newFormats: { formats: any } = setDecimalPlaces(decimalPlaces, locale, messages)
    this.setState(() => ({ ...newFormats }))
  }

  async initLang() {
    const language = await setLanguage(getInitialLanguageCode())
    if (this.state.code !== language.code) {
      this.setState(() => ({ ...language }))
    }
  }

  render() {
    const { children } = this.props
    const { locale, messages, formats } = this.state
    if (locale) {
      return (
        <Provider value={this.state}>
          <IntlProvider
            locale={locale}
            messages={messages}
            formats={formats}
            defaultLocale="en"
          >
            {children}
          </IntlProvider>
        </Provider>
      )
    } else {
      return null
    }
  }
}


export { IntlProviderWrapper as IntlProvider, Consumer as IntlConsumer }
