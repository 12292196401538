import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import FileDropZone from 'src/frontend/scenes/imports/components/FileDropZone'
import ImportedItemList from 'src/frontend/scenes/imports/items/components/ImportedItemList'
import WaitingImportItemList from 'src/frontend/scenes/imports/items/components/WaitingImportItemList'
import DeleteItemModal from 'src/frontend/scenes/imports/items/components/DeleteItemModal'
import styles from './ItemsPanel.module.less'

import { selectIsDeleteItemModalOpen } from 'src/frontend/scenes/imports/items/selectors'

const mapStateToProps = state => ({
  isDeleteModalOpen: selectIsDeleteItemModalOpen(state),
})

const propTypes = {
  isDeleteModalOpen: PropTypes.bool.isRequired,
}

const ItemsPanel = ({ isDeleteModalOpen }) => (
  <div className={styles.itemsPanel}>
    <FileDropZone />
    {isDeleteModalOpen && <DeleteItemModal />}
    <div className="items-container">
      <WaitingImportItemList />
      <ImportedItemList />
    </div>
  </div>
)

ItemsPanel.propTypes = propTypes

export default connect(mapStateToProps)(ItemsPanel)
