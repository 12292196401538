import React, { SyntheticEvent } from 'react'
import styles from 'src/frontend/components/ColumnLayout/BasicLayout.module.less'
import { mergeClasses } from 'src/common/utils'
import { formatMessage } from 'src/frontend/modules/intl/i18n'
import { PrimaryButton } from 'src/frontend/components/Buttons/Buttons'

interface Props {
  title?: string | React.ReactNode
  leftPanel: string | React.ReactNode
  topPanel?: string | React.ReactNode
  content: string | React.ReactNode
  leftShadow?: boolean
  action?: boolean
  onActionClick?: (e: SyntheticEvent) => void
}

ColumnLayout.defaultProps = {
  title: undefined,
  leftShadow: false,
  action: false,
  topPanel: undefined,
  onActionClick: undefined,
}

export default function ColumnLayout({
  title,
  action,
  leftPanel,
  topPanel,
  content,
  onActionClick,
}: Props) {
  return (
    <div className={styles.basicLayout}>
      {topPanel && (
        <div className={styles.topPanel}>
          {topPanel}
        </div>
      )}
      <div className={styles.contentPanel}>
        <div className={mergeClasses(styles.leftPanel, !topPanel && styles.noTop)}>
          {title && (
            <h1>
              {title}
            </h1>
          )}
          {action && (
            <div className={styles.addButton}>
              <PrimaryButton fluid onClick={onActionClick}>＋&nbsp;{formatMessage('form.add')}</PrimaryButton>
            </div>
          )}
          <div className={mergeClasses(styles.leftPanelContent, !title && styles.full, action && styles.action)}>
            {leftPanel}
          </div>
        </div>

        <div className={styles.rightPanel}>
          {content}
        </div>
      </div>
    </div>
  )
}
