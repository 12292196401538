import React, { useCallback, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { removeWidget, reorderWidgets } from 'src/frontend/scenes/dashboard/actions'
import { selectDashboardWidgetsOrder, selectDashboardWidgetsWithData } from 'src/frontend/scenes/dashboard/selectors'
import { WidgetType } from 'src/frontend/scenes/dashboard/types'
import styles from './WidgetContainer.module.less'
import itemStyles from './InnerDroppableList.module.less'
import { Id } from 'src/types/CouchDb'
import { selectUser } from 'src/frontend/modules/user/selectors'
import { User } from 'src/types/User'
import { HashMap } from 'src/types/common'
import InnerDroppableList from 'src/frontend/scenes/dashboard/components/InnerDroppableList'
import { SortableContainer } from 'react-sortable-hoc'
import { formatMessage } from 'src/frontend/modules/intl'
import _isEqual from 'lodash/isEqual'
import { selectShouldDisplayDashboardOnBoarding } from 'src/frontend/scenes/onBoarding/inApp/selectors'
import { selectIsDashboardLoading } from 'src/frontend/modules/moduleStatus/selectors'
import { arrayMove } from 'src/common/utils'


function mapStateToProps(state) {
  return {
    widgetIds: selectDashboardWidgetsOrder(state),
    widgets: selectDashboardWidgetsWithData(state),
    shouldShowOnBoarding: selectShouldDisplayDashboardOnBoarding(state),
    widgetsLoading: selectIsDashboardLoading(state),
    user: selectUser(state),
  }
}

const mapDispatchToProps = {
  reorderWidgetsAction: reorderWidgets,
  removeWidgetAction: removeWidget,
}

interface Props {
  widgetIds: Id[],
  widgetsLoading: boolean
  shouldShowOnBoarding: boolean
  widgets: HashMap<WidgetType>,
  reorderWidgetsAction: Function,
  removeWidgetAction: Function,
  user: User,
}

interface SortableListProps {
  items: Id[],
  widgetsHashMap: HashMap<WidgetType>,
  widgetsLoading: boolean,
  user: User,
  getContextMenuOptions: Function
}

const SortableList = SortableContainer(({
  items,
  widgetsHashMap,
  user,
  widgetsLoading,
  getContextMenuOptions,
}: SortableListProps) => {
  return (
    <div className={styles.widgetContainerWidgets}>
      <InnerDroppableList
        widgets={items}
        widgetsHashMap={widgetsHashMap}
        widgetsLoading={widgetsLoading}
        user={user}
        getContextMenuOptions={getContextMenuOptions}
      />
    </div>
  )
})

function WidgetContainer({
  user,
  widgets,
  reorderWidgetsAction,
  widgetsLoading,
  widgetIds: propsWidgetIds,
  removeWidgetAction,
}: Props) {
  const [widgetIds, setWidgetIds] = useState<Id[]>(propsWidgetIds)

  const getContextMenuOptions = useCallback((id: Id) => {
    return [
      {
        key: 'remove',
        text: formatMessage('dashboard.widget.remove-from-dashboard'),
        onClick: () => removeWidgetAction(id),
      },
    ]
  }, [])

  useEffect(() => {
    if (!_isEqual(widgetIds, propsWidgetIds)) {
      setWidgetIds(propsWidgetIds)
    }
  }, [propsWidgetIds])


  const onSortEnd = useCallback(({ oldIndex, newIndex }) => {
    const newWidgetsOrder = arrayMove(widgetIds, oldIndex, newIndex)
    setWidgetIds(newWidgetsOrder)
    reorderWidgetsAction(newWidgetsOrder)
  }, [widgetIds])

  return (
    <SortableList
      axis="xy"
      useWindowAsScrollContainer={false}
      useDragHandle
      helperClass={itemStyles.dragging}
      items={widgetIds}
      widgetsLoading={widgetsLoading}
      widgetsHashMap={widgets}
      user={user}
      onSortEnd={onSortEnd}
      getContextMenuOptions={getContextMenuOptions}
    />
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(WidgetContainer, ((prevProps, nextProps) => {
  return prevProps.widgetIds === nextProps.widgetIds
    && _isEqual(prevProps.widgets, nextProps.widgets)
    && prevProps.user === nextProps.user
    && prevProps.widgetsLoading === nextProps.widgetsLoading
})))
