import { RootState } from 'src/types/State'
import { ActiveRefresh, ExistingConnectionsState } from 'src/frontend/scenes/integrations/connections/reducer'
import { selectIntegrations } from 'src/frontend/scenes/integrations/selectors'
import { createSelector } from 'reselect'
import { selectConnectedAccounts } from 'src/frontend/modules/accounts/selectors'
import { Account } from 'src/types/Account'
import { IntegrationProviders } from 'src/frontend/scenes/integrations/providersList/types'
import { integrationSourceName } from "src/backend/integrations/enums"
import { ExistingIntegrationStatus } from 'src/frontend/scenes/integrations/enums'
import _pickBy from 'lodash/pickBy'
import IntegrationProvider = IntegrationProviders.IntegrationProvider
import { HashMap } from 'src/types/common'
import { IntegrationSource } from "src/backend/integrations/enums"

export const selectExistingConnections = (state: RootState): ExistingConnectionsState => {
  return selectIntegrations(state).connections
}

export const selectDisconnectAccountLoading = (state: RootState): boolean => {
  return selectExistingConnections(state).disconnectAccountLoading
}

export const selectDisconnectLoginId = (state: RootState): string => {
  return selectExistingConnections(state).disconnectLoginId
}

export const selectActiveMFALoginId = (state: RootState): string => {
  return selectExistingConnections(state).activeMFALoginId
}

export const selectRefreshProvider: (
  loginId: string,
) => (state: RootState) => IntegrationProvider = (loginId: string) =>
  createSelector([selectConnectedAccounts, () => loginId], findProviderByAccount)

export function findProviderByAccount(
  accounts: HashMap<Account>,
  loginId: string,
): IntegrationProvider {
  if (!loginId) {
    return null
  }

  const connectedAccount = Object.values(accounts).find((account: Account) => {
    return (
      account.reservedIntegrationConnection &&
      account.reservedIntegrationConnection.loginId === loginId
    )
  })

  // user disconnected account during update
  if (!connectedAccount) {
    return null
  }

  const { remoteProviderName, integrationSource } = connectedAccount.reservedIntegrationConnection

  return {
    id: loginId,
    name: remoteProviderName,
    integrationSource: Object.values(integrationSourceName).indexOf(integrationSource),
    countryCode: null,
  }
}

export const selectExistingConnectionsModalOpen = (state: RootState): boolean => {
  return selectExistingConnections(state).modalOpen
}

export const selectActiveRefreshes = (state: RootState): { [key: string]: ActiveRefresh } => {
  return selectExistingConnections(state).activeRefreshes
}

export const selectActiveMFARefreshes: (state: RootState) => HashMap<ActiveRefresh> =
  createSelector([selectActiveRefreshes], (activeRefreshes) => {
    return _pickBy(activeRefreshes, (activeRefresh: ActiveRefresh) => {
      return activeRefresh.integrationStatus === ExistingIntegrationStatus.MFA_FORM
    })
  })

export const selectDialogRefreshes: (state: RootState) => HashMap<ActiveRefresh> = createSelector(
  [selectActiveRefreshes],
  (activeRefreshes) => {
    return _pickBy(activeRefreshes, (activeRefresh: ActiveRefresh) => {
      return (
        activeRefresh.integrationStatus === ExistingIntegrationStatus.MFA_FORM ||
        activeRefresh.integrationStatus === ExistingIntegrationStatus.CREDENTIALS_FORM ||
        activeRefresh.integrationStatus === ExistingIntegrationStatus.ERROR
      )
    })
  },
)

export const selectActiveErrorRefreshes: (state: RootState) => HashMap<ActiveRefresh> =
  createSelector([selectActiveRefreshes], (activeRefreshes) => {
    return _pickBy(activeRefreshes, (activeRefresh: ActiveRefresh) => {
      return activeRefresh.integrationStatus === ExistingIntegrationStatus.ERROR
    })
  })

export const selectPendingReconnectProvider = (state: RootState): string => {
  return selectExistingConnections(state).pendingReconnect
}

export const isPendingReconnect = (state: RootState): boolean => {
  return selectExistingConnections(state).pendingReconnect ? true : false
}
