import _groupBy from 'lodash/groupBy'
import moment from 'moment'
import { convertDateToLabel, getRecordsSum } from 'src/frontend/scenes/records/recordList/helpers'
import { RecordListWrapper } from 'src/frontend/components/RecordList/RecordListWrapper'
import React from 'react'
import { FormattedMoney } from 'src/frontend/modules/intl'
import { RecordListItemRecord } from 'src/frontend/components/RecordList/types'
import { connect } from 'react-redux'
import { Placeholder } from 'semantic-ui-react'
import _isEmpty from 'lodash/isEmpty'
import { openEditRecordForm } from 'src/frontend/scenes/records/recordForm/actions'
import { Id } from 'src/types/CouchDb'

import styles from './ExtendedRecordListContent.module.less'
import recordListStyles from './ExtendedRecordList.module.less'
import * as removeFormActions from 'src/frontend/scenes/records/removeForm/actions'
import { openAssignForm } from 'src/frontend/scenes/records/assignForm/actions'
import { RecordListOrderBy } from 'src/frontend/scenes/records/recordList/enums'

interface Props {
  records: RecordListItemRecord[]
  selectedRecords: RecordListItemRecord[]
  referentialCurrencyCode: string
  sortBy: RecordListOrderBy

  loading: boolean
  isRemoving: boolean
  disablePeriodSums: boolean

  onRecordCheck: Function
  handleOpenAssignForm: (recordId: Id) => void
  handleEditRecord: (recordId: Id) => void
  handleRemoveRecords: (records: RecordListItemRecord[]) => void
}

const mapDispatchToProps = {
  handleEditRecord: openEditRecordForm,
  handleRemoveRecords: removeFormActions.remove,
  handleOpenAssignForm: openAssignForm,
}


function ExtendedRecordListContent({
  records,
  selectedRecords,
  referentialCurrencyCode,
  loading,
  sortBy,
  isRemoving,
  disablePeriodSums,
  onRecordCheck,
  handleEditRecord,
  handleRemoveRecords,
  handleOpenAssignForm,
}: Props) {

  if (loading && _isEmpty(records)) {
    return (
      <div className={styles.recordsPlaceholder}>
        {[...Array(10)].map((_value, index) => (
          <div key={index}>
            <Placeholder fluid>
              <Placeholder.Header image>
                <Placeholder.Line />
                <Placeholder.Line />
              </Placeholder.Header>
            </Placeholder>
          </div>
        ))}
      </div>
    )
  }

  return sortBy === RecordListOrderBy.RECORD_DATE_DESC || sortBy === RecordListOrderBy.RECORD_DATE_ASC
    ? (
      <>
        {Object.values(_groupBy(records, 'recordDateDay')).map((groupedRecords: RecordListItemRecord[]) => {
          const firstRecordDate = moment(groupedRecords[0].recordDateDay)
          const dateKey = firstRecordDate.toISOString()
          const dateLabel = convertDateToLabel(firstRecordDate)
          return (
            <div key={dateKey}>
              <div className={recordListStyles.stickyHeader}>
                <div className={recordListStyles.stickyDate}>{dateLabel}</div>
                {!disablePeriodSums && (
                  <div className={recordListStyles.stickySum}>
                    <FormattedMoney
                      value={getRecordsSum(groupedRecords)}
                      currency={referentialCurrencyCode}
                    />
                  </div>
                )}
              </div>
              <RecordListWrapper
                isRemoving={isRemoving}
                records={groupedRecords}
                selectedRecords={selectedRecords}
                onRecordCheck={onRecordCheck}
                onRecordEdit={handleEditRecord}
                onRecordDelete={handleRemoveRecords}
                onAssignClick={handleOpenAssignForm}
              />
            </div>
          )
        })}
      </>
    )
    : (
      <div className={recordListStyles.plainContainer}>
        <RecordListWrapper
          isRemoving={isRemoving}
          records={records}
          selectedRecords={selectedRecords}
          onRecordCheck={onRecordCheck}
          onRecordEdit={handleEditRecord}
          onRecordDelete={handleRemoveRecords}
          onAssignClick={handleOpenAssignForm}
        />
      </div>
    )
}

export default connect(null, mapDispatchToProps)(ExtendedRecordListContent)
