import { Currency } from 'src/types/Currency'
import React from 'react'
import { Table } from 'semantic-ui-react'
import { FormattedMessage, FormattedMoney, FormattedPercentageChange } from 'src/frontend/modules/intl'
import './ReportTable.less'
import { CashFlowReport } from 'src/frontend/scenes/analytics/Report/types'
import { RecordCategoryLevel } from 'src/backend/categories/enums'
import {
  INVESTMENTS_SUPER_ENVELOPE_IDS,
  LOANS_REPAYMENT_ID,
  NEW_FUNDING_ID,
  OPERATING_COSTS_SUPER_ENVELOPE_IDS,
  OPERATING_REVENUE_SUPER_ENVELOPE_IDS,
  SYSTEM_CATEGORIES_TRANSFER_ID,
  SYSTEM_CATEGORIES_UNKNOWN_ID,
} from 'src/backend/categories/envelopes'
import CashFlowReportTableRow from 'src/frontend/scenes/analytics/Report/components/CashFlowReportTableRow'
import { formatMessage } from 'src/frontend/modules/intl/i18n'

interface Props {
  reportData: CashFlowReport,
  referentialCurrency: Currency,
  onOpenCategoryPreview: Function
}

export default function CashFlowReportTable({ reportData, referentialCurrency, onOpenCategoryPreview }: Props) {
  const {
    currentPeriodData,
    previousPeriodData,
    currentPeriodEndingBalance,
    currentPeriodOpeningBalance,
    previousPeriodEndingBalance,
    previousPeriodOpeningBalance,
    percentageChanges,
    openingBalanceChange,
    endingBalanceChange,
  } = reportData

  return (
    <div className="report-container">
      <Table singleLine className="report-table" basic="very">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell />
            <Table.HeaderCell textAlign="right">
              <FormattedMessage id="period-current" />
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="right">
              <FormattedMessage id="period-previous" />
            </Table.HeaderCell>
            <Table.HeaderCell />
            <Table.HeaderCell />
          </Table.Row>
        </Table.Header>
        <Table.Body>

          <Table.Row className="report-row-total">
            <Table.Cell width={5}>
              <strong>
                <FormattedMessage id="report.opening-balance" />
              </strong>
            </Table.Cell>
            <Table.Cell width={4} textAlign="right">
              <strong>
                <FormattedMoney value={currentPeriodOpeningBalance} colored currency={referentialCurrency.code} />
              </strong>
            </Table.Cell>
            <Table.Cell width={4} textAlign="right">
              <FormattedMoney value={previousPeriodOpeningBalance} currency={referentialCurrency.code} />
            </Table.Cell>
            <Table.Cell width={2} textAlign="right">
              <FormattedPercentageChange simple change={openingBalanceChange} />
            </Table.Cell>
            <Table.Cell width={1} textAlign="right" />
          </Table.Row>

          <CashFlowReportTableRow
            label={formatMessage('Operating Revenue')}
            className="report-row-values text-only"
            currentValue={currentPeriodData.operatingRevenue}
            previousValue={previousPeriodData.operatingRevenue}
            change={percentageChanges.operatingRevenue}
            referentialCurrency={referentialCurrency}
            recordCategoryLevel={RecordCategoryLevel.SUPER_ENVELOPE}
            recordCategoryValue={OPERATING_REVENUE_SUPER_ENVELOPE_IDS}
            onOpenCategoryPreview={onOpenCategoryPreview}
          />

          <CashFlowReportTableRow
            label={formatMessage('Operating Costs')}
            className="report-row-values text-only"
            currentValue={currentPeriodData.operatingCosts}
            previousValue={previousPeriodData.operatingCosts}
            change={percentageChanges.operatingCosts}
            referentialCurrency={referentialCurrency}
            recordCategoryLevel={RecordCategoryLevel.SUPER_ENVELOPE}
            recordCategoryValue={OPERATING_COSTS_SUPER_ENVELOPE_IDS}
            onOpenCategoryPreview={onOpenCategoryPreview}
          />

          <CashFlowReportTableRow
            label={formatMessage('New Funding')}
            className="report-row-values text-only"
            currentValue={currentPeriodData.newFunding}
            previousValue={previousPeriodData.newFunding}
            change={percentageChanges.newFunding}
            referentialCurrency={referentialCurrency}
            recordCategoryLevel={RecordCategoryLevel.SUPER_ENVELOPE}
            recordCategoryValue={NEW_FUNDING_ID}
            onOpenCategoryPreview={onOpenCategoryPreview}
          />

          <CashFlowReportTableRow
            label={formatMessage('Loans Repayment')}
            className="report-row-values text-only"
            currentValue={currentPeriodData.loansRepayment}
            previousValue={previousPeriodData.loansRepayment}
            change={percentageChanges.loansRepayment}
            referentialCurrency={referentialCurrency}
            recordCategoryLevel={RecordCategoryLevel.SUPER_ENVELOPE}
            recordCategoryValue={LOANS_REPAYMENT_ID}
            onOpenCategoryPreview={onOpenCategoryPreview}
          />


          <CashFlowReportTableRow
            label={formatMessage('Investments')}
            className="report-row-values text-only"
            currentValue={currentPeriodData.investments}
            previousValue={previousPeriodData.investments}
            change={percentageChanges.investments}
            referentialCurrency={referentialCurrency}
            recordCategoryLevel={RecordCategoryLevel.SUPER_ENVELOPE}
            recordCategoryValue={INVESTMENTS_SUPER_ENVELOPE_IDS}
            onOpenCategoryPreview={onOpenCategoryPreview}
          />

          <CashFlowReportTableRow
            label={formatMessage('Cash Transfers')}
            className="report-row-values text-only"
            currentValue={currentPeriodData.cashTransfers}
            previousValue={previousPeriodData.cashTransfers}
            change={percentageChanges.cashTransfers}
            referentialCurrency={referentialCurrency}
            recordCategoryLevel={RecordCategoryLevel.ENVELOPE}
            recordCategoryValue={SYSTEM_CATEGORIES_TRANSFER_ID}
            onOpenCategoryPreview={onOpenCategoryPreview}
          />

          <CashFlowReportTableRow
            label={formatMessage('unknown')}
            className="report-row-values text-only"
            currentValue={currentPeriodData.unknown}
            previousValue={previousPeriodData.unknown}
            change={percentageChanges.unknown}
            referentialCurrency={referentialCurrency}
            recordCategoryLevel={RecordCategoryLevel.ENVELOPE}
            recordCategoryValue={SYSTEM_CATEGORIES_UNKNOWN_ID}
            onOpenCategoryPreview={onOpenCategoryPreview}
          />

          <Table.Row className="report-row-total">
            <Table.Cell width={5}>
              <strong>
                <FormattedMessage id="report.ending-balance" />
              </strong>
            </Table.Cell>
            <Table.Cell width={4} textAlign="right">
              <strong>
                <FormattedMoney value={currentPeriodEndingBalance} colored currency={referentialCurrency.code} />
              </strong>
            </Table.Cell>
            <Table.Cell width={4} textAlign="right">
              <FormattedMoney value={previousPeriodEndingBalance} currency={referentialCurrency.code} />
            </Table.Cell>
            <Table.Cell width={2} textAlign="right">
              <FormattedPercentageChange simple change={endingBalanceChange} />
            </Table.Cell>
            <Table.Cell width={1} textAlign="right" />
          </Table.Row>

        </Table.Body>
      </Table>
    </div>
  )
}
