import { createSelector } from 'reselect'
import _truncate from 'lodash/truncate'
import { pipe } from 'ramda'
import { RootState } from 'src/types/State'
import { User, UserConsents } from 'src/types/User'
import { selectModules } from 'src/frontend/modules/selectors'

export const selectUser = (state: RootState): User => selectModules(state).user as User
export const selectUserCountry = (state: RootState): string => selectUser(state).countryCode

export const selectUserGroup: (RootState) => string = createSelector(selectUser, userGroupInfo)

function userGroupInfo(user: User): string {
  const userHasGroups = user.groupsList && user.groupsList.length > 0
  return userHasGroups
    ? _truncate(user.groupsList.map(group => group.name).join(', '), { length: 35 })
    : null
}

// default values unless all users agree otherwise this user profile property is missing
const defaultConsent = {
    policy: false,
    gps: false,
    segmentation: false,
    emailing: false,
    bankSynchronization: false,
    dataDelete: false,
    dataAndUserDelete: false,
}

const selectUserConsent: (RootState) => UserConsents = pipe(selectUser, (user) => user.consents || defaultConsent)

export const selectConsentPolicy: (RootState) => boolean = pipe(selectUserConsent, (consent) => consent.policy)

export const selectConsentGeolocation: (RootState) => boolean = pipe(selectUserConsent, (consent) => consent.gps)

export const selectConsentSegmentation: (RootState) => boolean = pipe(
    selectUserConsent,
    (consent) => consent.segmentation
)

export const selectConsentEmailing: (RootState) => boolean = pipe(selectUserConsent, (consent) => consent.emailing)

export const selectConsentBankSynchronization: (RootState) => boolean = pipe(
    selectUserConsent,
    (consent) => consent.bankSynchronization
)

export const selectDataDelete: (RootState) => boolean = pipe(selectUserConsent, (consent) => consent.dataDelete)

export const selectDataAndUserDeletee: (RootState) => boolean = pipe(
    selectUserConsent,
    (consent) => consent.dataAndUserDelete
)