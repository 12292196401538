import styles from "src/frontend/scenes/accounts/accountDetail/components/AccountLastUpdate.module.less"
import { BasicIcon } from "src/frontend/components/Icons/Icons"
import { getIntegrationIcon } from "src/frontend/scenes/accounts/helpers"
import { FormattedRelativeTime } from "react-intl/dist"
import React from "react"
import { isLessThan1Hour, isLessThan24Hours } from "src/backend/time/time"
import moment from "moment"
import { Account } from "src/types/Account"
import { isUndefinedOrNull } from "src/common/utils"
import FormattedMessage from "src/frontend/modules/intl/components/FormattedMessage"
import { connect } from "react-redux"
import { RootState } from "src/types/State"
import { selectRestrictedProviders } from "src/frontend/scenes/integrations/restrictedProviders/selectors"
import { RestrictedProviders } from "src/frontend/scenes/integrations/restrictedProviders/types"
import RestrictedProvider = RestrictedProviders.RestrictedProvider

interface Props {
  account: Account
  restrictedProviders: RestrictedProvider[]
}

function mapStateToProps(state: RootState) {
  return {
    restrictedProviders: selectRestrictedProviders(state),
  }
}

function AccountLastUpdate({ account, restrictedProviders }: Props) {
  const integrationConnection = account.reservedIntegrationConnection

  const restrictedProviderObject = React.useMemo(() => {
    if (integrationConnection) {
      return restrictedProviders.find((restrictedProvider) => {
        return restrictedProvider.loginIds.includes(integrationConnection.loginId)
      })
    }
  }, [account, restrictedProviders])

  const lastRefreshDate = React.useMemo(() => {
    if (
      integrationConnection &&
      integrationConnection.isActive &&
      integrationConnection.lastSuccessRefresh
    ) {
      return moment(integrationConnection.lastSuccessRefresh)
    } else if (isLessThan24Hours(account.reservedCreatedAt)) {
      return moment(account.reservedCreatedAt)
    }
  }, [integrationConnection])

  if (account.investmentInfo && !integrationConnection) {
    return (
      <>
        <div className={styles.lastUpdate}>
          <BasicIcon
            name="checkmark"
            size="1rem"
          />
          <FormattedMessage id="integrations.connections.last-refresh-now" />
        </div>
      </>
    )
  }

  if (!integrationConnection) {
    return null
  }

  if (!integrationConnection.isActive || !isUndefinedOrNull(restrictedProviderObject)) {
    return (
      <div className={styles.lastUpdate}>
        <BasicIcon
          name={getIntegrationIcon(account, restrictedProviderObject)}
          size="1em"
        />
        <div>
          <FormattedMessage id="integrations.problem.label" />
        </div>
      </div>
    )
  }

  const DIFF_UNIT = integrationConnection && isLessThan1Hour(lastRefreshDate) ? "minute" : "hour"
  const refreshDiff = lastRefreshDate && lastRefreshDate.diff(moment(), DIFF_UNIT)

  return (
    <div className={styles.lastUpdate}>
      <BasicIcon
        name={getIntegrationIcon(account)}
        size="1em"
      />
      {!isUndefinedOrNull(refreshDiff) ? (
        <div>
          {refreshDiff !== 0 ? (
            <FormattedRelativeTime
              value={refreshDiff}
              unit={DIFF_UNIT}
              style="narrow"
              numeric="auto"
              updateIntervalInSeconds={60}
            />
          ) : (
            <FormattedMessage id="integrations.connections.last-refresh-now" />
          )}
        </div>
      ) : null}
    </div>
  )
}

export default connect(mapStateToProps)(AccountLastUpdate)
