import moment from "moment"
import { Interval } from "src/backend/enums"
import {
  DASHBOARD_CHANGE_FILTER,
  DASHBOARD_RECEIVE_ACCOUNTS,
  DASHBOARD_RECEIVE_ORDER,
  DASHBOARD_RECEIVE_WIDGET,
  DASHBOARD_RECEIVE_WIDGETS,
} from "src/frontend/scenes/dashboard/actions"
import { AccountsTotals, WidgetType } from "src/frontend/scenes/dashboard/types"
import { Action, HashMap } from "src/types/common"
import { FilterType } from "src/types/Filter"
import { Id } from "src/types/CouchDb"

export type DashboardState = {
  filter: FilterType
  widgetsOrder: Id[]
  widgets: HashMap<WidgetType>
  accountsTotals: HashMap<AccountsTotals>
}

const initDashboardState = {
  filter: {
    period: {
      start: moment(),
      end: moment(),
      interval: Interval.MONTH,
    },
  },
  widgetsOrder: [],
  widgets: null,
  accountsTotals: null,
}

export default function dashboard(
  state: DashboardState = initDashboardState,
  action: Action,
): DashboardState {
  switch (action.type) {
    case DASHBOARD_CHANGE_FILTER: {
      return { ...state, filter: { ...state.filter, ...action.payload } }
    }
    case DASHBOARD_RECEIVE_WIDGETS: {
      return { ...state, widgets: action.payload }
    }
    case DASHBOARD_RECEIVE_WIDGET: {
      return { ...state, widgets: { ...state.widgets, [action.payload.id]: action.payload } }
    }

    case DASHBOARD_RECEIVE_ACCOUNTS: {
      return { ...state, accountsTotals: action.payload }
    }

    case DASHBOARD_RECEIVE_ORDER: {
      return { ...state, widgetsOrder: action.payload }
    }

    default: {
      return state
    }
  }
}
