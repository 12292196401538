import { addUpdateAttributes } from "src/backend/common/helpers"
import * as commonRepository from "src/backend/common/repository"
import { updateAuditData } from "src/backend/common/service"
import { filterConverter } from "src/backend/converters/filterConverter"
import { inMemoryTableNames } from "src/backend/db/inMemorySqlDbSchemaBuilder"
import { convertFromAndroidFilter, convertToAndroidFilter } from "src/backend/filters/converters"
import { filterDocument } from "src/backend/filters/FilterDocument"
import * as repository from "src/backend/filters/repository"
import { utcDateAsISOString } from "src/backend/time/time"
import { formatMessage } from "src/frontend/modules/intl/i18n"
import { CouchDbDocument, Id } from "src/types/CouchDb"
import { FilterType, FilterDocument, WebFilter } from "src/types/Filter"
import { User } from "src/types/User"

export function fetchFilters(): Promise<Array<FilterDocument>> {
  return repository.getAllFilters()
}

export async function getFilter(filterId: Id): Promise<WebFilter> {
  const filter = await repository.findById(filterId)

  return convertFromAndroidFilter(filter)
}

export async function saveFilter(
  filterId: Id,
  formData: FilterType,
  user: User,
): Promise<Array<CouchDbDocument>> {
  const filter: FilterDocument = filterId
    ? await repository.findById(filterId)
    : updateAuditData(user, utcDateAsISOString)(filterDocument())

  const name = formData.name || formatMessage("filter.default-name")
  const position: number =
    ((await commonRepository.findHighestPosition(inMemoryTableNames.LABEL)) || 0) + 1000
  const updatedFilter = { ...filter, ...convertToAndroidFilter(formData), name, position }

  return commonRepository.updateBulk(
    [addUpdateAttributes(updatedFilter)],
    inMemoryTableNames.FILTER,
    filterConverter(),
  )
}

export async function removeFilter(filterId: Id) {
  const filter = await repository.findById(filterId)
  return commonRepository.removeBulk([filter])
}

export async function getValidationDependencies() {
  const filters = await repository.findAllAsHashMap()

  return {
    filters,
  }
}
