import { RootState } from 'src/types/State'
import { selectContacts } from 'src/frontend/scenes/contacts/selectors'

export const selectContactForm = (state: RootState) => selectContacts(state).contactForm

export const selectFormValues = (state: RootState) => selectContactForm(state).formValues
export const selectFormOpen = (state: RootState) => selectContactForm(state).open
export const selectEditContactId = (state: RootState) => selectContactForm(state).contactId
export const selectFormType = (state: RootState) => selectContactForm(state).formType
export const selectErrors = (state: RootState) => selectContactForm(state).errors
export const selectLoading = (state: RootState) => selectContactForm(state).loading
