import React from 'react'
import Address from 'src/frontend/scenes/settings/billing/Receipt/components/Address'

export default function BBAddress() {
  return (
    <Address
      companyName="BudgetBakers s.r.o"
      streetCity="Radlická 180/50, Prague"
      countryZip="150 00 Czech Republic"
      VAT="CZ02882957"
    />
  )
}
