import {
  fetchExistingIntegrations,
  fetchIntegrationCountries,
  fetchIntegrationProvidersByCountry, fetchIntegrationProvidersDetailByLoginId,
  fetchIntegrationProvidersDetailByProviderId,
} from 'src/backend/integrations/service'
import { GetState, ThunkFunction } from 'src/types/common'
import { selectUser } from 'src/frontend/modules/user/selectors'
import { isAuthorizationError } from 'src/common/helpers'
import {
  selectReconnectProvider,
  selectSelectedProvider,
} from 'src/frontend/scenes/integrations/providersList/selectors'
import { expireUser } from 'src/frontend/modules/user/actions'
import { IntegrationProviders } from 'src/frontend/scenes/integrations/providersList/types'
import {
  closeIntegrationsForm,
  openIntegrationsForm, showPersonalDetailsForm,
} from 'src/frontend/scenes/integrations/newConnection/actions'
import { IntegrationConnection } from "src/frontend/scenes/integrations/newConnection/types"
import ConnectedProviderStatus = IntegrationProviders.ConnectedProviderStatus
import IntegrationProvider = IntegrationProviders.IntegrationProvider
import IntegrationProviderDetail = IntegrationProviders.IntegrationProviderDetail
import ConnectionType = IntegrationConnection.ConnectionType
import * as logger from "src/common/logger"
import { showError } from "src/frontend/scenes/app/actions"
import { DEFAULT_ERROR_MESSAGE_ID } from "src/frontend/Error/actions"
import { requestPushPermissions } from "src/backend/pushNotifications/service"
import {
  trackIntegrationsBankFound,
  trackIntegrationsReconnect,
  trackIntegrationsShowBank,
} from "src/common/mixpanel"
import { isUndefinedOrNull } from "src/common/utils"
import { isCNBVerified } from "src/backend/user/service"
import * as gtm from "src/common/gtm"
import { integrationSourceName } from "src/backend/integrations/enums"
import {
  setConnectionType,
  setIntegrationProviderCode,
  setIntegrationSourceNameToLocalStorage,
  setProviderLoginId,
  setReconnectLoginId,
} from "src/backend/db/localStorage"

export const FETCH_COUNTRIES_START = "integrations/providersList/FETCH_COUNTRIES_START"
export const FETCH_COUNTRIES_SUCCESS = "integrations/providersList/FETCH_COUNTRIES_SUCCESS"

export const RECONNECT_BANK_START = "integrations/providersList/RECONNECT_BANK_START"

export const FETCH_PROVIDERS_START = "integrations/providersList/FETCH_PROVIDERS_START"
export const FETCH_PROVIDERS_SUCCESS = "integrations/providersList/FETCH_PROVIDERS_SUCCESS"
export const FETCH_PROVIDERS_ERROR = "integrations/providersList/FETCH_PROVIDERS_ERROR"

export const FETCH_EXISTING_PROVIDERS_SUCCESS =
  "integrations/providersList/FETCH_EXISTING_PROVIDERS_SUCCESS"

export const CHANGE_SELECTED_COUNTRY = "integrations/providersList/CHANGE_SELECTED_COUNTRY"
export const CHANGE_SELECTED_PROVIDER = "integrations/providersList/CHANGE_SELECTED_PROVIDER"

export const FETCH_PROVIDER_DETAILS_START =
  "integrations/providersList/FETCH_PROVIDER_DETAILS_START"
export const FETCH_PROVIDER_DETAILS_ERROR =
  "integrations/providersList/FETCH_PROVIDER_DETAILS_ERROR"
export const FETCH_PROVIDER_DETAILS_SUCCESS =
  "integrations/providersList/FETCH_PROVIDER_DETAILS_SUCCESS"

export const CLEAR_PROVIDER_SELECTION = "integrations/providersList/CLEAR_PROVIDER_SELECTION"

export function fetchProviderCountries() {
  return async (dispatch: Function, getState: GetState) => {
    dispatch(fetchProviderCountriesStart())
    const { countryCode, userId } = selectUser(getState())
    trackIntegrationsShowBank()
    try {
      const [countries, integrations] = await Promise.all([
        fetchIntegrationCountries(),
        fetchExistingIntegrations(userId),
      ])

      const userCountry =
        countryCode && countries.includes(countryCode.toLowerCase())
          ? countryCode.toLowerCase()
          : null

      if (userCountry) {
        dispatch(changeSelectedCountry(userCountry))
      }
      console.log("fetchProviderCountries - intergrations")
      console.log(integrations)
      dispatch(fetchExistingProvidersSuccess(integrations || []))
      dispatch(fetchProviderCountriesSuccess(countries))
    } catch (error) {
      if (isAuthorizationError(error)) {
        dispatch(expireUser())
        return
      } else {
        logger.captureException(error, "integrations.providersList.fetchProviderCountries")
        dispatch(showError([DEFAULT_ERROR_MESSAGE_ID, "app.error.we_are_fixing_suffix"]))
      }
    }
  }
}

function fetchExistingProvidersSuccess(providers) {
  return {
    type: FETCH_EXISTING_PROVIDERS_SUCCESS,
    payload: providers,
  }
}

function fetchProviderCountriesStart() {
  return {
    type: FETCH_COUNTRIES_START,
  }
}

function fetchProviderCountriesSuccess(countries: string[]) {
  return {
    type: FETCH_COUNTRIES_SUCCESS,
    payload: countries,
  }
}

export function changeSelectedCountry(countryCode: string) {
  return (dispatch: Function) => {
    dispatch({
      type: CHANGE_SELECTED_COUNTRY,
      payload: countryCode,
    })
    return dispatch(fetchCountryProviders(countryCode))
  }
}

export function changeSelectedProvider(providerCode: string): ThunkFunction {
  return (dispatch) => {
    gtm.bankFound(providerCode)
    return dispatch({
      type: CHANGE_SELECTED_PROVIDER,
      payload: providerCode,
    })
  }
}

export function fetchCountryProviders(countryCode: string) {
  return async (dispatch: Function) => {
    dispatch(fetchCountryProvidersStart())
    try {
      const providers = await fetchIntegrationProvidersByCountry(countryCode)

      dispatch(fetchCountryProvidersSuccess(providers))
    } catch (error) {
      dispatch(fetchCountryProvidersError())
      if (isAuthorizationError(error)) {
        dispatch(expireUser())
        return
      } else {
        logger.captureException(error, "integrations.providersList.fetchCountryProviders", {
          countryCode,
        })
        dispatch(showError([DEFAULT_ERROR_MESSAGE_ID, "app.error.we_are_fixing_suffix"]))
      }
    }
  }
}

function fetchCountryProvidersStart() {
  return {
    type: FETCH_PROVIDERS_START,
  }
}

function fetchCountryProvidersError() {
  return {
    type: FETCH_PROVIDERS_ERROR,
  }
}

function fetchCountryProvidersSuccess(providers: IntegrationProvider[]) {
  return {
    type: FETCH_PROVIDERS_SUCCESS,
    payload: providers,
  }
}

export function chooseBankProvider() {
  return (dispatch: Function, getState: GetState) => {
    gtm.bankIntegrationStart()
    const { countryCode } = selectSelectedProvider(getState())
    const user = selectUser(getState())

    if (countryCode.toLowerCase() === "cz" && !isCNBVerified(user)) {
      return dispatch(showPersonalDetailsForm())
    } else {
      return dispatch(fetchProviderDetails())
    }
  }
}

export function fetchProviderDetails() {
  return async (dispatch: Function, getState: GetState) => {
    dispatch(fetchProviderDetailsStart())
    trackIntegrationsBankFound()
    const provider = selectSelectedProvider(getState())
    try {
      const { userId } = selectUser(getState())

      const providerDetails = await fetchIntegrationProvidersDetailByProviderId(userId, provider)
      if (!isUndefinedOrNull(providerDetails.isAutomatic) && !providerDetails.isAutomatic) {
        requestPushPermissions()
      }
      dispatch(openIntegrationsForm())
      saveConnectionDetails(providerDetails)
      dispatch(fetchProviderDetailsSuccess(providerDetails))
    } catch (error) {
      if (isAuthorizationError(error)) {
        dispatch(expireUser())
        return
      } else {
        dispatch(fetchProviderDetailsError())
        logger.captureException(error, "integrations.providersList.fetchProviderDetails", {
          provider,
        })
        dispatch(showError([DEFAULT_ERROR_MESSAGE_ID, "app.error.we_are_fixing_suffix"]))
      }
    }
  }
}

export function reconnectBank(loginId: string) {
  return async (dispatch: Function, getState: GetState) => {
    dispatch(reconnectBankStart(loginId))
    trackIntegrationsReconnect()
    requestPushPermissions()
    try {
      const provider = selectReconnectProvider(getState())
      const { userId } = selectUser(getState())

      if (!provider) {
        dispatch(clearAndCloseForm())
      }

      const providerDetails = await fetchIntegrationProvidersDetailByLoginId(userId, provider)
      setReconnectLoginId(loginId)
      dispatch(openIntegrationsForm())
      setConnectionType(ConnectionType.RECONNECT)
      saveConnectionDetails(providerDetails)
      dispatch(fetchProviderDetailsSuccess(providerDetails))
    } catch (error) {
      if (isAuthorizationError(error)) {
        dispatch(expireUser())
        return
      } else {
        logger.captureException(error, "integrations.providersList.reconnectBank", { loginId })
        dispatch(showError([DEFAULT_ERROR_MESSAGE_ID, "app.error.we_are_fixing_suffix"]))
      }
    }
  }
}

function saveConnectionDetails(providerDetails) {
  console.log("saveConnectionDetails - providerDetails: ", providerDetails)
  // setProviderDetailToLocaleStorage(providerDetails)
  setIntegrationProviderCode(providerDetails.code)
  const integrationSourceNameFromBE = integrationSourceName[providerDetails.integrationSource]
  if (providerDetails.loginStatus === ConnectedProviderStatus.INACTIVE) {
    setProviderLoginId(providerDetails.loginId)
  }
  setIntegrationSourceNameToLocalStorage(integrationSourceNameFromBE)
  console.log("saveConnectionDetails - integrationSourceName: ", integrationSourceNameFromBE)
}

export function reconnectBankStart(loginId: string) {
    return {
        type: RECONNECT_BANK_START,
        payload: loginId,
    }
}

export function fetchProviderDetailsStart() {
    return {
        type: FETCH_PROVIDER_DETAILS_START,
    }
}

export function fetchProviderDetailsError() {
    return {
        type: FETCH_PROVIDER_DETAILS_ERROR,
    }
}

export function fetchProviderDetailsSuccess(providerDetails: IntegrationProviderDetail) {
    return {
        type: FETCH_PROVIDER_DETAILS_SUCCESS,
        payload: providerDetails,
    }
}

export function clearProviderSelection() {
  return {
    type: CLEAR_PROVIDER_SELECTION,
  }
}

export function clearAndCloseForm() {
  return (dispatch: Function) => {
    dispatch(clearProviderSelection())
    return dispatch(closeIntegrationsForm())
  }
}

export function convertIntegrationProviderDetailToIntegrationProvider(
    integrationProviderDetail: IntegrationProviderDetail
): IntegrationProvider {
    const integrationProvider: IntegrationProvider = {
        ...integrationProviderDetail,
        maxTransactionHistoryInDays: 90,
        needPremiumToConnect: true,
    }
    return integrationProvider
}

