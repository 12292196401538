export const defaultEnvelopeNames = {
  modules_debts: "Debt",
  transfer_withdraw: "Transfer, withdraw",
  shopping_list: "Shopping list",
  oneclick_widget_name: "Wallet - OneClick record",
  bank_parsing_title: "Automatic bank statements reading",
  unknown: "Unknown",
  system_categories_unknown: "Unknown",
  cat_general: "General",
  cat_group_income: "Income",
  cat_group_housing: "Housing",
  cat_group_corrections: "Corrections",
  cat_group_others: "Others",
  cat_group_missing: "Missing",
  cat_group_food_drinks: "Food & Drinks",
  cat_group_shopping: "Shopping",
  cat_group_transportation: "Transportation",
  cat_group_vehicle: "Vehicle",
  cat_group_life_entertainment: "Life & Entertainment",
  cat_group_communication_pc: "Communication, PC",
  cat_group_financial_expenses: "Financial expenses",
  cat_group_investments: "Investments",
  cat_sub_group_others: "Others",
  cat_sub_group_groceries: "Groceries",
  cat_sub_group_restaurant_fast_food: "Restaurant, fast-food",
  cat_sub_group_bar_cafe: "Bar, cafe",
  cat_sub_group_drugstore: "Drug-store, chemist",
  cat_sub_group_clothes_shoes: "Clothes & shoes",
  cat_sub_group_jewels_accessories: "Jewels, accessories",
  cat_sub_group_health_beauty: "Health and beauty",
  cat_sub_group_kids: "Kids",
  cat_sub_group_home_garden: "Home, garden",
  cat_sub_group_pets_animals: "Pets, animals",
  cat_sub_group_electronics_accessories: "Electronics, accessories",
  cat_sub_group_gifts_joy: "Gifts, joy",
  cat_sub_group_stationery_tools: "Stationery, tools",
  cat_sub_group_free_time: "Free time",
  cat_sub_group_rent: "Rent",
  cat_sub_group_mortgage: "Mortgage",
  cat_sub_group_energy_utilities: "Energy, utilities",
  cat_sub_group_energy_services: "Services",
  cat_sub_group_maintenance_repairs: "Maintenance, repairs",
  cat_sub_group_phone_cell_phone: "Phone, mobile phone",
  cat_sub_group_internet: "Internet",
  cat_sub_group_property_insurance: "Property insurance",
  cat_sub_group_public_transport: "Public transport",
  cat_sub_group_fuel: "Fuel",
  cat_sub_group_vehicle_insurance: "Vehicle insurance",
  cat_sub_group_taxi: "Taxi",
  cat_sub_group_long_distance: "Long distance",
  cat_sub_group_parking: "Parking",
  cat_sub_group_fines: "Fines",
  cat_sub_group_vehicle_maintenance: "Vehicle maintenance",
  cat_sub_group_rentals: "Rentals",
  cat_sub_group_business_trips: "Business trips",
  cat_sub_group_health_care_doctor: "Health care, doctor",
  cat_sub_group_wellness_beauty: "Wellness, beauty",
  cat_sub_group_active_sport_fitness: "Active sport, fitness",
  cat_sub_group_culture_sport_events: "Culture, sport events",
  cat_sub_group_life_events: "Life events",
  cat_sub_group_hobbies: "Hobbies",
  cat_sub_group_education_development: "Education, development",
  cat_sub_group_books_audio_subscription: "Books, audio, subscriptions",
  cat_sub_group_tv_streaming: "TV, Streaming",
  cat_sub_group_software_apps_games: "Software, apps, games",
  cat_sub_group_holiday_trips_hotels: "Holiday, trips, hotels",
  cat_sub_group_charity_gifts: "Charity, gifts",
  cat_sub_group_alcohol_tobacco: "Alcohol, tobacco",
  cat_sub_group_lottery_gambling: "Lottery, gambling",
  cat_sub_group_postal_services: "Postal services",
  cat_sub_group_taxes: "Taxes",
  cat_sub_group_insurances: "Insurances",
  cat_sub_group_loan_interests: "Loan, interests",
  cat_sub_group_savings: "Savings",
  cat_sub_group_advisory: "Advisory",
  cat_sub_group_charges_fees: "Charges, Fees",
  cat_sub_group_leasing: "Leasing",
  cat_sub_group_aliments: "Child Support",
  cat_sub_group_realty: "Realty",
  cat_sub_group_vehicles_chattels: "Vehicles, chattels",
  cat_sub_group_financial_investments: "Financial investments",
  cat_sub_group_collections: "Collections",
  cat_sub_group_wage_invoices: "Wage, invoices",
  cat_sub_group_rental_income: "Rental income",
  cat_sub_group_dues_grants: "Dues & grants",
  cat_sub_group_lending_renting: "Lending, renting",
  cat_sub_group_checks_coupons: "Checks, coupons",
  cat_sub_group_refunds: "Refunds (tax, purchase)",
  cat_sub_group_interests_dividends: "Interests, dividends",
  cat_sub_group_sale: "Sale",
  cat_sub_group_gifts: "Gifts",
  cat_savings: "Savings",
  cat_reserves: "Reserves",
  cat_unknown_records: "Unknown",
  cat_sub_unknown_categories_income: "Unknown income",
  cat_sub_unknown_categories_expense: "Unknown expense",
  cat_group_sales_revenue: "Sales Revenue",
  cat_group_other_income: "Other Revenue",
  cat_group_new_funding: "New Funding",
  cat_group_space_and_equipment: "Space & Equipment",
  cat_group_payroll_travel: "Payroll & Travel",
  cat_group_inventory_purchase: "Inventory Purchase",
  cat_group_operational_services: "Operational Services",
  cat_group_other_bills_charges: "Other Bills & Charges",
  cat_group_loans_repayment: "Loans Repayment",
  cat_group_assets_purchase: "Assets Purchase",
  cat_sub_group_products: "Products",
  cat_sub_group_services: "Services",
  cat_sub_group_refunds_tax: "Refunds (tax)",
  cat_sub_group_interest_capital_gains: "Interest, capital gains",
  cat_sub_group_products_miscellaneous_revenue: "Miscellaneous revenue",
  cat_sub_group_new_loans: "New loans",
  cat_sub_group_new_notes_bonds: "New notes, bonds",
  cat_sub_group_new_equity_investment: "New equity investments",
  cat_sub_group_space_rent: "Space rent",
  cat_sub_group_utilities: "Utilities (energy, security)",
  cat_sub_group_equipment_rent: "Equipment rent or leasing",
  cat_sub_group_repairs_maintenance: "Repairs & maintenance",
  cat_sub_group_employees: "Employees",
  cat_sub_group_contractors: "Contractors",
  cat_sub_group_education_team_event: "Education, team events",
  cat_sub_group_travel_expenses: "Travel expenses",
  cat_sub_group_material: "Material",
  cat_sub_group_parts: "Parts",
  cat_sub_group_goods_for_sale: "Goods for sale",
  cat_sub_group_other_supplies: "Other Supplies",
  cat_sub_group_marketing_sales_services: "Marketing & Sales services",
  cat_sub_group_operations_computing_services: "Operations & computing services",
  cat_sub_group_other_key_services: "Other key services",
  cat_sub_group_customer_services: "Customer services",
  cat_sub_group_logistics_services: "Logistics services",
  cat_sub_group_sw_telco: "SW subscriptions, Telco charges",
  cat_sub_group_accounting_legal_services: "Accounting and Legal services",
  cat_sub_group_other_admin_expenses: "Other admin expenses",
  cat_sub_group_interest_charges_insurance: "Interest, Charges, Insurance",
  cat_sub_group_taxes_vat_income: "Taxes - VAT, income, other",
  cat_sub_group_bank_loans: "Bank loans",
  cat_sub_group_notes_bonds: "Notes, bonds",
  cat_sub_group_other_debts: "Other debts",
  cat_sub_group_it: "Information Technology",
  cat_sub_group_machines_vehicles: "Machines and Vehicles",
  cat_sub_group_equipment_tools: "Equipment and Tools",
  cat_sub_group_property_furniture: "Property and Furniture",
  cat_sub_group_licences: "Licences",
}
