import { combineReducers } from 'redux'
import product, { BillingProductState } from 'src/frontend/scenes/billing/product/reducer'
import paymentMethod, { BillingPaymentMethodState } from 'src/frontend/scenes/billing/paymentMethod/reducer'
import transactionHistory, { TransactionHistoryState } from 'src/frontend/scenes/billing/transactionHistory/reducer'
import cancelSubscription, { CancelSubscriptionState } from 'src/frontend/scenes/billing/cancelSubscription/reducer'
import activeSubscription, { ActiveSubscriptionState } from 'src/frontend/scenes/billing/activeSubscription/reducer'
import billingModal, { BillingModalState } from 'src/frontend/scenes/billing/billingModal/reducer'


export interface BillingState {
  product: BillingProductState
  paymentMethod: BillingPaymentMethodState
  transactionHistory: TransactionHistoryState
  cancelSubscription: CancelSubscriptionState
  activeSubscription: ActiveSubscriptionState
  billingModal: BillingModalState
}

export default combineReducers({
  product,
  paymentMethod,
  transactionHistory,
  cancelSubscription,
  activeSubscription,
  billingModal,
})
