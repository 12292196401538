import { SuperEnvelope } from 'src/types/Category'
import CategoryOption from 'src/frontend/components/Categories/CategoryOption'
import { CategoryLevel } from 'src/frontend/modules/categories/enums'
import React, { useMemo } from 'react'

interface Props {
  categoriesHierarchy: SuperEnvelope[]
  className?: string
  onSelect: (id: number) => void
}

SuperEnvelopesList.defaultProps = {
  className: undefined,
}

export function SuperEnvelopesList({ categoriesHierarchy, className, onSelect }: Props) {
  const superEnvelopeOptions = useMemo(() => categoriesHierarchy
    .filter((superEnvelope) => !superEnvelope.isHidden)
    .map(superEnvelope => {
      return (
        <li key={superEnvelope.id} onClick={() => onSelect(superEnvelope.id)}>
          <CategoryOption
            level={CategoryLevel.CATEGORY}
            color={superEnvelope.color}
            icon={superEnvelope.iconName}
            label={superEnvelope.name}
            size="23px"
          />
        </li>
      )
    }), [categoriesHierarchy])

  return (
    <ul className={className}>
      {superEnvelopeOptions}
    </ul>
  )
}
