import lf from "lovefield"
import { pipe } from "ramda"
import * as labelsRepository from "src/backend/labels/repository"
import * as categoriesRepository from "src/backend/categories/repository"
import { addUpdateAttributes } from "src/backend/common/helpers"
import * as commonRepository from "src/backend/common/repository"
import { recordConverter } from "src/backend/converters/recordConverter"
import { localDb } from "src/backend/db/documentDb"
import {
  convertResultToEntity,
  convertResultToScalar,
  WalletSQLInMemory,
} from "src/backend/db/inMemorySqlDb"
import { inMemoryTableNames } from "src/backend/db/inMemorySqlDbSchemaBuilder"
import { convertToRecordEntity } from "src/backend/records/converters"
import { utcDateAsISOString } from "src/backend/time/time"
import uuid from "uuid"
import { Record, RecordTransfer } from "src/types/Record"
import { FilterType } from "src/types/Filter"
import { Id } from "src/types/CouchDb"
import _isEmpty from "node_modules/lodash/isEmpty"
import { SYSTEM_CATEGORIES_UNKNOWN_ID } from "src/backend/categories/envelopes"
import { trackInvalidIdsQuery } from "src/common/mixpanel"

export function findAll(): Promise<Record[]> {
  return commonRepository.findAll(inMemoryTableNames.RECORD) as unknown as Promise<Record[]>
}

export function findById(id): Promise<Record> {
  const recordId = id || null
  const sqlDb = WalletSQLInMemory.getInstance()
  const recordTable = sqlDb.getTable(inMemoryTableNames.RECORD)
  return sqlDb
    .select()
    .from(recordTable)
    .where(recordTable._id.eq(recordId))
    .exec()
    .then(convertResultToEntity(id, inMemoryTableNames.RECORD))
}

export function findByIds(ids: Id[]): Promise<Record[]> {
  if (!Array.isArray(ids)) {
    trackInvalidIdsQuery()
    throw new Error("findByIds: ids is not an array")
  }
  const sqlDb = WalletSQLInMemory.getInstance()
  const recordTable = sqlDb.getTable(inMemoryTableNames.RECORD)
  return sqlDb
    .getDatabase()
    .select()
    .from(recordTable)
    .where(recordTable._id.in(ids))
    .exec() as Promise<Record[]>
}

export function findByCategoryId(categoryId = null): Promise<Record[]> {
  const sqlDb = WalletSQLInMemory.getInstance()
  const recordTable = sqlDb.getTable(inMemoryTableNames.RECORD)
  return sqlDb
    .getDatabase()
    .select()
    .from(recordTable)
    .where(recordTable.categoryId.eq(categoryId))
    .exec() as Promise<Record[]>
}

export function newTransferId(): string {
  return uuid.v4()
}

export function newRecordId(): string {
  return `Record_${uuid.v4()}`
}

export async function create(recordEntity): Promise<Record> {
  console.log("Records repository create", recordEntity)
  const recordEntityWithSystemData = pipe(
    addId(newRecordId()),
    addCreatedAt,
    addUpdateAttributes,
    convertToRecordDocument,
  )(recordEntity)

  const response = await createOrUpdateDocument(recordEntityWithSystemData)
  console.log("CouchDB put response", response)
  const [categories, labels] = await Promise.all([
    categoriesRepository.findAllAsHashMap(),
    labelsRepository.findAllAsHashMap(),
  ])
  const createdEntities = await commonRepository.insertOrReplaceEntities(
    [recordEntityWithSystemData].map(commonRepository.updateRevision([response])),
    inMemoryTableNames.RECORD,
    recordConverter(categories, labels),
  )
  return createdEntities[0] as unknown as Record
}

export async function update(recordEntity) {
  const updatedEntities = await updateBulk([recordEntity])
  return updatedEntities[0] as Record
}

export async function updateBulk(recordEntities) {
  const recordDocuments = recordEntities.map(convertToRecordDocument)
  const [categories, labels] = await Promise.all([
    categoriesRepository.findAllAsHashMap(),
    labelsRepository.findAllAsHashMap(),
  ])

  return commonRepository.updateBulk(
    recordDocuments,
    inMemoryTableNames.RECORD,
    recordConverter(categories, labels),
  ) as Promise<Record[]>
}

export function remove(recordEntity) {
  const convertRecord = pipe(convertToRecordDocument, convertToRecordEntity)
  return commonRepository.removeBulk([convertRecord(recordEntity)])
}

export function removeBulk(recordEntities) {
  return commonRepository.removeBulk(
    recordEntities.map(pipe(convertToRecordDocument, convertToRecordEntity)),
  )
}

function createOrUpdateDocument(recordVO) {
  const recordDocument = convertToRecordDocument(recordVO)
  console.log("createOrUpdateDocument", recordDocument)
  return localDb.put(recordDocument)
}

export function convertToRecordDocument(recordEntity: RecordTransfer): Record {
  const {
    envelopeId,
    fulltextString,
    recordDateDay,
    recordDateWeek,
    recordDateMonth,
    superEnvelopeId,
    fromAccountId,
    fromCurrencyId,
    fromAmount,
    toAccountId,
    toCurrencyId,
    toAmount,
    ...record
  } = recordEntity

  return record
}

export function findRelatedByTransferId(transferId: Id, recordId: Id): Promise<Record> {
  if (!transferId) {
    return Promise.resolve(null)
  }

  const sqlDb = WalletSQLInMemory.getInstance()
  const recordTable = sqlDb.getTable(inMemoryTableNames.RECORD)
  return sqlDb
    .select()
    .from(recordTable)
    .where(lf.op.and(recordTable.transferId.eq(transferId), recordTable._id.neq(recordId)))
    .exec()
    .then(convertResultToEntity())
}

export function findMultipleRelatedByTransfer(records): Promise<Record[]> {
  if (records.some((record) => !record.transferId)) {
    return Promise.resolve([])
  }

  const transferIds = records.map((record) => record.transferId)
  const recordIds = records.map((record) => record._id)

  const sqlDb = WalletSQLInMemory.getInstance()
  const recordTable = sqlDb.getTable(inMemoryTableNames.RECORD)
  return sqlDb
    .select()
    .from(recordTable)
    .where(
      lf.op.and(recordTable.transferId.in(transferIds), lf.op.not(recordTable._id.in(recordIds))),
    )
    .exec() as Promise<Record[]>
}

export function getLastRecords(): Promise<Record[]> {
  const sqlDb = WalletSQLInMemory.getInstance()
  const recordTable = sqlDb.getTable(inMemoryTableNames.RECORD)
  const categoryTable = sqlDb.getTable(inMemoryTableNames.CATEGORY)
  const accountTable = sqlDb.getTable(inMemoryTableNames.ACCOUNT)
  return sqlDb
    .select(
      recordTable._id.as("_id"),
      recordTable.note.as("note"),
      recordTable.recordDate.as("recordDate"),
      recordTable.reservedUpdatedAt.as("reservedUpdatedAt"),
      recordTable.accountId.as("accountId"),
      recordTable.categoryId.as("categoryId"),
      recordTable.currencyId.as("currencyId"),
      categoryTable.envelopeId.as("envelopeId"),
    )
    .from(recordTable, categoryTable, accountTable)
    .where(
      lf.op.and(
        recordTable.transfer.eq(false),
        recordTable.categoryId.eq(categoryTable._id),
        accountTable.archived.eq(false),
        accountTable.reservedIntegrationConnection.isNull(),
        recordTable.accountId.eq(accountTable._id),
      ),
    )
    .orderBy(recordTable.recordDate, lf.Order.DESC)
    .limit(50)
    .exec() as unknown as Promise<Record[]>
}

export function findFirstRecordDate(): Promise<Date> {
  const sqlDb = WalletSQLInMemory.getInstance()
  const recordTable = sqlDb.getTable(inMemoryTableNames.RECORD)
  return sqlDb
    .select(recordTable.recordDate.as("recordDate"))
    .from(recordTable)
    .limit(1)
    .orderBy(recordTable.recordDate, lf.Order.ASC)
    .exec()
    .then(convertResultToScalar("recordDate"))
}

export async function findRecordIdsByLabelIds(labelIds: Array<Id>) {
  if (_isEmpty(labelIds)) {
    return []
  }
  const sqlDb = WalletSQLInMemory.getInstance()
  const recordTable = sqlDb.getTable(inMemoryTableNames.RECORD)
  const query = sqlDb.select().from(recordTable).where(recordTable.labels.isNotNull())

  const records = await query.exec()

  return records
    .filter((record: Record) =>
      labelIds.some((labelId) => record.labels && record.labels.includes(labelId)),
    )
    .map((record: Record) => record._id)
}

export async function findLastRecords(
  filter: FilterType,
  countOfLastRecords: number,
): Promise<Record[]> {
  const recordIds = await findRecordIdsByLabelIds(filter.labelIds)
  const sqlDb = WalletSQLInMemory.getInstance()
  const recordTable = sqlDb.getTable(inMemoryTableNames.RECORD)

  const conditions = [...commonRepository.convertFilterToConditions(recordTable, filter, recordIds)]

  const query = sqlDb.select().from(recordTable)

  if (!_isEmpty(conditions)) {
    query.where(lf.op.and(...conditions))
  }

  query.orderBy(recordTable.recordDate, lf.Order.DESC).limit(countOfLastRecords)

  return query.exec() as Promise<Record[]>
}

/**
 * The result records are ordered by date descending (newest first)
 * @param transactionIds
 * @returns {Promise<Array<Object>>}
 */
export function findByTransactionIds(transactionIds: Id[] = []): Promise<Record[]> {
  const sqlDb = WalletSQLInMemory.getInstance()
  const recordTable = sqlDb.getTable(inMemoryTableNames.RECORD)
  return sqlDb
    .getDatabase()
    .select()
    .from(recordTable)
    .where(recordTable.transactionId.in(transactionIds))
    .orderBy(recordTable.recordDate, lf.Order.DESC)
    .exec() as Promise<Record[]>
}

export async function deleteRecordsByTransactionId(transactionId: Id) {
  if (!transactionId) {
    throw new Error("deleteRecordsByTransactionId: transactionId is null")
  }
  const records = await findByTransactionIds([transactionId])
  console.log(`Selected ${records.length} records by transactionId=${transactionId} to delete.`)
  await removeBulk(records)
}

// These functions updates audit or system information according to this table:
//          id   user  createdTime updatedTime
// create    v    v         v           v
// update    x    ?         x           v
// delete    x    x         x           v
//

export function addId(id: Id): Function {
  return (recordEntity: Record): Record => {
    return {
      ...recordEntity,
      _id: id,
    }
  }
}

function addCreatedAt(recordEntity: Record): Record {
  return {
    ...recordEntity,
    reservedCreatedAt: utcDateAsISOString(),
  }
}

export async function findRecordsByFilter(filter: FilterType): Promise<Record[]> {
  const sqlDb = WalletSQLInMemory.getInstance()
  const recordTable = sqlDb.getTable(inMemoryTableNames.RECORD)

  const recordIds = await findRecordIdsByLabelIds(filter.labelIds)

  // filtering by type doesn't make any sense
  const conditions = [...commonRepository.convertFilterToConditions(recordTable, filter, recordIds)]

  const query = sqlDb.select().from(recordTable)

  if (conditions.length > 0) {
    query.where(lf.op.and(...conditions))
  }

  return query.exec() as Promise<Record[]>
}

export async function fetchRecordsCount() {
  const sqlDb = WalletSQLInMemory.getInstance()
  const recordTable = sqlDb.getTable(inMemoryTableNames.RECORD)

  const query = sqlDb.select(lf.fn.count(recordTable._id).as("count")).from(recordTable)

  return query.exec().then(convertResultToScalar("count"))
}

export async function fetchUnknownRecordsCount() {
  const sqlDb = WalletSQLInMemory.getInstance()
  const recordTable = sqlDb.getTable(inMemoryTableNames.RECORD)

  const query = sqlDb
    .select(lf.fn.count(recordTable._id).as("count"))
    .from(recordTable)
    .where(recordTable.envelopeId.eq(SYSTEM_CATEGORIES_UNKNOWN_ID))

  return query.exec().then(convertResultToScalar("count"))
}

export async function findByCurrecyId(currencyId: Id): Promise<Record[]> {
  const sqlDb = WalletSQLInMemory.getInstance()
  const recordTable = sqlDb.getTable(inMemoryTableNames.RECORD)

  const query = sqlDb.select().from(recordTable).where(recordTable.currencyId.eq(currencyId))

  return query.exec() as Promise<Record[]>
}
