import React, { useCallback } from 'react'
import { connect } from 'react-redux'
import { Link, NavLink } from 'react-router-dom'
import { isWalletLifeEnabled } from 'src/common/features'
import { selectIsWalletLifeAvailable } from 'src/frontend/scenes/app/selectors'
import styles from './Menu.module.less'
import * as actions from 'src/frontend/scenes/records/recordForm/actions'
import { isAppBoard } from 'src/common/environment'
import UserIcon from 'src/frontend/components/UserIcon/UserIcon'
import FormattedMessage from 'src/frontend/modules/intl/components/FormattedMessage'
import { ComplementaryButton } from 'src/frontend/components/Buttons/Buttons'

function mapStateToProps(state) {
  return {
    shouldDisplayWalletLife: selectIsWalletLifeAvailable(state),
  }
}

const mapDispatchToProps = {
  handleOpenAddRecordForm: actions.openAddRecordForm,
}

interface Props {
  fake?: boolean,
  shouldDisplayWalletLife: boolean
  handleOpenAddRecordForm: Function
}

Menu.defaultProps = {
  fake: false,
}

function Menu({ shouldDisplayWalletLife, fake, handleOpenAddRecordForm }: Props) {

  const handleOpenAddRecord = useCallback(() => !fake && handleOpenAddRecordForm(), [])

  return (
    <div className={styles.topBar}>
      <div>
        <Link to="/dashboard">
          <div className={styles.logo} />
        </Link>
        <nav className={styles.menu}>
          <ul>
            <li>
              <NavLink
                to="/dashboard"
                activeClassName={styles.active}
                onClick={(e) => fake && e.preventDefault()}
              >
                <FormattedMessage id="menu.dashboard" />
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/accounts"
                activeClassName={styles.active}
                onClick={(e) => fake && e.preventDefault()}
              >
                <FormattedMessage id="menu.accounts" />
              </NavLink>
            </li>
            {isAppBoard() && (
              <li>
                <NavLink
                  to="/contacts"
                  activeClassName={styles.active}
                  onClick={(e) => fake && e.preventDefault()}
                >
                  <FormattedMessage id="menu.contacts" />
                </NavLink>
              </li>
            )}
            <li>
              <NavLink
                to={isAppBoard() ? "/transactions" : "/records"}
                activeClassName={styles.active}
                onClick={(e) => fake && e.preventDefault()}
              >
                <FormattedMessage id={{ B: "transactions", W: "menu.records" }} />
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/analytics"
                activeClassName={styles.active}
                onClick={(e) => fake && e.preventDefault()}
              >
                <FormattedMessage id="menu.analytics" />
              </NavLink>
            </li>
            {!isAppBoard() && (
            <li>
              <NavLink
                to="/investments"
                activeClassName={styles.active}
                onClick={(e) => fake && e.preventDefault()}
              >
                <FormattedMessage id="menu.investments" />
              </NavLink>
            </li>
            )}
            <li>
              <NavLink
                to="/imports"
                activeClassName={styles.active}
                onClick={(e) => fake && e.preventDefault()}
              >
                <FormattedMessage id="menu.imports" />
              </NavLink>
            </li>
          </ul>
        </nav>

        {!fake && (
          <div className={styles.userMenu}>
            {!isAppBoard() && (
              <ComplementaryButton
                color="blue"
                onClick={handleOpenAddRecord}
              >
                ＋&nbsp;
                <FormattedMessage id="Record" />
              </ComplementaryButton>
            )}
            <UserIcon />
          </div>
        )}
      </div>
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(Menu, (prevProps, nextProps) => {
  return prevProps.shouldDisplayWalletLife === nextProps.shouldDisplayWalletLife
}))
