export enum CategoryLevel {
  CATEGORY = 'category',
  SUBCATEGORY = 'subcategory',
}

export enum FormType {
  ADD = 'add',
  EDIT = 'edit',
  RENAME = 'rename',
}
