import AddableList from 'src/frontend/scenes/settings/magicRules/components/AddableList'
import { Message } from 'semantic-ui-react'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { FormType } from 'src/frontend/scenes/settings/magicRules/enums'
import HighlightedText from 'src/frontend/scenes/settings/magicRules/components/HighlightedText'
import { HashMap } from 'src/types/common'
import styles from './KeywordsSelect.module.less'
import { FormattedMessage } from 'src/frontend/modules/intl'

const MIN_KEYWORD_CHARACTERS = 3

export default function KeywordsSelect({ formType, formValues, selectOptions, error, onChange }) {

  const [keywordValue, setKeywordValue] = useState('')
  const [keywordError, setKeywordError] = useState<string>(null)
  const [highlightValid, setIsHighlightValid] = useState<HashMap<boolean>>({})

  const valueHasErrors = useMemo(() => {
    return keywordError
      || keywordValue.length === 0
      || formValues.keywords && formValues.keywords.some((item) => item.toLowerCase() === keywordValue.toLowerCase())
  }, [keywordError, keywordValue, formValues.keywords])

  const isSomeKeywordsRowValid = useMemo(() => Object.values(highlightValid).some(Boolean), [highlightValid])

  useEffect(() => {
    if (keywordValue && keywordValue.length < MIN_KEYWORD_CHARACTERS) {
      setKeywordError('settings.rules.error.keyword.too-short')
    } else if (formType === FormType.ADD_FROM_ASSIGN && keywordValue && !isSomeKeywordsRowValid) {
      setKeywordError('settings.rules.error.keyword.mismatch')
    } else if (keywordValue && selectOptions.magicRulesKeywords.some((existingKeyword) => {
      return existingKeyword.toLowerCase() === keywordValue.toLowerCase()
    })) {
      setKeywordError('settings.rules.error.keyword.already-exists')
    } else if (!!error && !keywordValue && (!formValues.keywords || formValues.keywords.length === 0)) {
      setKeywordError(error)
    } else {
      setKeywordError(null)
    }
  }, [keywordValue, isSomeKeywordsRowValid, error])


  const handleChangeValid = useCallback((id: string | number, value: boolean) => {
    setIsHighlightValid((prevState) => ({ ...prevState, [id]: value }))
  }, [])

  return (

    <div className={styles.keywordsSelect}>
      {formType === FormType.ADD_FROM_ASSIGN && selectOptions.keywordsGuide && (
        <Message
          info
          icon={{ name: 'info circle', color: 'blue' }}
          content={(
            <div>
              <p><FormattedMessage id="settings.rules.keyword.guide_title" /></p>
              {selectOptions.keywordsGuide.map((guideValue, index) => (
                <HighlightedText
                  key={index}
                  minCharacters={MIN_KEYWORD_CHARACTERS}
                  id={index}
                  text={guideValue}
                  onValidChanged={handleChangeValid}
                  keywords={formValues.keywords
                    ? [...formValues.keywords, keywordValue]
                    : [keywordValue]}
                />
              ))}
            </div>
          )}
        />
      )}

      <AddableList
        value={keywordValue}
        items={formValues.keywords}
        addDisabled={valueHasErrors}
        onItemChange={(newKeywords) => onChange(null, { name: 'keywords', value: newKeywords })}
        onValueChange={setKeywordValue}
      />

      <Message
        className="error-message"
        visible={!!keywordError}
        error
        content={<FormattedMessage id={keywordError} />}
      />
    </div>
  )
}
