import { Currency } from 'src/types/Currency'
import {
  CreditBarsData,
} from 'src/frontend/scenes/dashboard/widgets/BoardCashFlowBar/types'
import { Account } from 'src/types/Account'
import { isCreditCard, isOverdraft } from 'src/backend/accounts/helpers'
import { HashMap } from 'src/types/common'
import { CreditUtilization } from 'src/frontend/scenes/dashboard/types'
import { isUndefinedOrNull } from 'src/common/utils'

export function getCreditLimitUtilization(accounts: HashMap<Account>, referentialCurrency: Currency) {
  return (data: HashMap<CreditUtilization>): CreditBarsData => {
    if (!data || !accounts) {
      return null
    }

    const accountsArray = Object.values(accounts).filter((account) => {
      return (isCreditCard(account) || isOverdraft(account))
        && !isUndefinedOrNull(account.creditCard)
        && account._id in data
    })

    const bars = accountsArray
      .sort((a, b) => data[b._id].endingCreditRefBalance - data[a._id].endingCreditRefBalance)
      .map((account) => {
        const {
          endingCreditRefBalance,
          maxValue,
          avgValue,
        } = data[account._id]

        return {
          label: account.name,
          total: account.creditCard.limit,
          maxValue,
          avgValue,
          values: [
            {
              value: endingCreditRefBalance,
              color: account.color,

            },
          ],
        }
      })

    const totalRefCreditBalance = accountsArray.reduce((acc, value) => acc + data[value._id].endingCreditRefBalance, 0)
    const totalLimit = accountsArray.reduce((acc, value) => acc + value.creditCard.limit, 0)

    return {
      totalLimit,
      totalRefCreditBalance,
      bars,
      referentialCurrency,
    }
  }
}
