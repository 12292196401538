import { RootState } from "src/types/State"
import { RecordListItemRecord } from "src/frontend/components/RecordList/types"
import { createSelector } from "reselect"
import * as accountsSelectors from "src/frontend/modules/accounts/selectors"
import * as categoriesSelectors from "src/frontend/modules/categories/selectors"
import * as currenciesSelectors from "src/frontend/modules/currencies/selectors"
import * as labelsSelectors from "src/frontend/modules/labels/selectors"
import * as contactsSelectors from "src/frontend/modules/contacts/selectors"
import { RecordListOrderBy } from "src/frontend/scenes/records/recordList/enums"
import { aggregateRecords } from "src/frontend/scenes/records/recordList/selectors"
import { selectRecords } from "src/frontend/scenes/records/selectors"
import { Record } from "src/types/Record"

export const selectRecordListDetail = (state: RootState) => selectRecords(state).recordsDetail

export const selectRecordListDetailRecords = (state: RootState) =>
  selectRecordListDetail(state).records

export const selectAggregatedRecordListDetailRecords: (state: RootState) => RecordListItemRecord[] =
  createSelector(
    [
      accountsSelectors.selectAllAccounts,
      categoriesSelectors.selectFlattenedCategories,
      currenciesSelectors.selectCurrencies,
      labelsSelectors.selectAllLabels,
      contactsSelectors.selectContacts,
      currenciesSelectors.selectReferentialCurrency,
      () => RecordListOrderBy.RECORD_DATE_DESC,
      selectRecordListDetailRecords,
    ],
    aggregateRecords,
  )

export const selectRecordsWithDetails = (records: Record[]) =>
  createSelector(
    [
      accountsSelectors.selectAllAccounts,
      categoriesSelectors.selectFlattenedCategories,
      currenciesSelectors.selectCurrencies,
      labelsSelectors.selectAllLabels,
      contactsSelectors.selectContacts,
      currenciesSelectors.selectReferentialCurrency,
      () => RecordListOrderBy.RECORD_DATE_DESC,
      () => records,
    ],
    aggregateRecords,
  )
