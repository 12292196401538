import React from "react"
import styles from "src/frontend/scenes/accounts/accountsList/components/AccountsListItem.module.less"
import panelStyles from "src/frontend/components/ColumnLayout/PanelList.module.less"
import { AccountWithCurrencyCode } from "src/types/Account"
import { AccountIcon } from "src/frontend/components/Icons/Icons"
import { mergeClasses } from "src/common/utils"
import { AccountsTotals } from "src/frontend/scenes/dashboard/types"
import { formatMessage, FormattedMoney } from "src/frontend/modules/intl"
import { isCreditCard, isOverdraft } from "src/backend/accounts/helpers"
import IntegrationButton from "src/frontend/scenes/settings/accounts/components/IntegrationButton"
import { Link } from "react-router-dom"
import FormattedMessage from "src/frontend/modules/intl/components/FormattedMessage"
import AccountLastUpdate from "src/frontend/scenes/accounts/accountDetail/components/AccountLastUpdate"
import TruncatedText from "src/frontend/components/TruncatedText"

interface Props {
  account: AccountWithCurrencyCode & AccountsTotals
  urlParent: "accounts" | "investments"
}

export default function AccountsListItem({ account, urlParent }: Props) {
  const integrationConnection = account.reservedIntegrationConnection

  return (
    <div className={mergeClasses(panelStyles.panel, styles.item)}>
    
      <div className={styles.icon}>
        <AccountIcon
          accountType={account.accountType}
          isInvestments={!!account?.investmentInfo}
          color={account.color}
          size="35px"
          borderRadius="6px"
        />
      </div>
      <div className={styles.name}>
        <TruncatedText text={account.name} />
      </div>
      <div className={styles.type}>{formatMessage(`account.type.${account.accountType}`)}</div>
      <div className={styles.lastUpdate}>
        {(integrationConnection || account.investmentInfo) && (
          <>
            <span className={styles.lastUpdateTitle}>
              <FormattedMessage id="settings.accounts.table.last_update" />
              :&nbsp;
            </span>
            <AccountLastUpdate account={account} />
          </>
        )}
      </div>
      <div className={styles.balance}>
        <FormattedMoney
          value={account.balance}
          currency={account.currencyCode}
        />
        {(isCreditCard(account) || isOverdraft(account)) && account.creditCard && (
          <div className={styles.limit}>
            {/* tslint:disable-next-line:jsx-use-translation-function */}
            (Limit &nbsp;
            <FormattedMoney
              value={account.creditCard.limit}
              currency={account.currencyCode}
            />
            )
          </div>
        )}
      </div>
      <Link
      key={account._id}
      to={`/${urlParent}/detail/${account._id}`}
    />
      <div className={styles.action}>
        <IntegrationButton account={account}
        onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
        }} 
        />
      </div>
    </div>
  )
}
