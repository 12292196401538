import React from 'react'
import { mergeClasses } from 'src/common/utils'
import { FormattedMessage, formatMessage } from 'src/frontend/modules/intl'
import { isAppBoard } from 'src/common/environment'
import styles from './MarketingMessage.module.less'
import mImg from '../../../assets/images/marketingMessage/marketingMessageImage-wallet-default.png'
import mImgBoard from '../../../assets/images/marketingMessage/marketingMessageImage-board-default.png'

const MarketingMessage = () => {
    const appName = isAppBoard() ? 'Board' : 'Wallet'
    const titleId = isAppBoard() ? 'auth.marketing.board.message.title' : 'auth.marketing.wallet.message.title'
    const subtitleId = isAppBoard() ? 'auth.marketing.board.message.subtitle' : 'auth.marketing.wallet.message.subtitle'
    const contentId = isAppBoard() ? 'auth.marketing.board.message.content' : 'auth.marketing.wallet.message.content'
    const linktId = isAppBoard() ? 'auth.marketing.board.message.link' : 'auth.marketing.wallet.message.link'
    const imageAltId = isAppBoard()
        ? 'auth.marketing.board.message.image.alt'
        : 'auth.marketing.wallet.message.image.alt'
    const subtitleT = formatMessage(subtitleId)
    const subtitle = subtitleT === subtitleId || subtitleT === '-' ? null : <h2>{subtitleT}</h2> // - is a replacement for empty string to mitigate error
    const appLink = isAppBoard() ? 'https://board.budgetbakers.com/' : 'https://budgetbakers.com/what-is-wallet/'

    return (
        <div className={mergeClasses(styles.content)}>
            <h1>
                <FormattedMessage id={titleId} values={{ br: <br /> }} />
            </h1>
            {subtitle}
            <img
                width='100%'
                src={isAppBoard() ? mImgBoard : mImg}
                alt={formatMessage(imageAltId, {
                    appName,
                })}
            ></img>
            <p>
                <FormattedMessage id={contentId} values={{ br: <br /> }} />
            </p>
            <p>
                <a href={appLink} target='_blank'>
                    <FormattedMessage id={linktId} values={{ appName }} />
                </a>
            </p>
        </div>
    )
}

export default MarketingMessage
