import React from 'react'
import { connect } from 'react-redux'
import { Menu } from 'semantic-ui-react'
import LinearChart, { Props as LinearChartProps } from 'src/frontend/components/chart/LinearChart'
import Widget from 'src/frontend/scenes/dashboard/components/Widget/Widget'
import { WidgetView } from 'src/frontend/scenes/dashboard/types'
import { changeSubject as changeSubjectAction } from 'src/frontend/scenes/dashboard/widgets/BoardRevenueCostsGrowth/actions'
import styles from 'src/frontend/scenes/dashboard/widgets/BoardRevenueCostsGrowth/RevenueCostsGrowth.module.less'
import { FormattedMessage } from 'src/frontend/modules/intl'
import { Id } from 'src/types/CouchDb'
import { chartHasEqualProps } from 'src/frontend/scenes/dashboard/helpers'

type Props = {
  widget: WidgetView & { data: LinearChartProps },
  changeSubject: (id: Id, cumulative: boolean) => void,
}

const mapDispatchToProps = {
  changeSubject: changeSubjectAction,
}

function RevenueCostsGrowth({ widget, changeSubject }: Props) {
  const { id, data, filter, options } = widget

  if (!data || !data.datasets || !data.datasets[2] || !filter) {
    return <Widget.Placeholder />
  }

  const content = (
    <Widget.Content className={styles.operatingGrowthCard}>
      <Menu fluid widths={2} compact>
        <Menu.Item
          active={options.cumulative === true}
          onClick={() => changeSubject(id, true)}
        >
          <FormattedMessage id="cashFlow-cumulative" />
        </Menu.Item>
        <Menu.Item
          active={options.cumulative === false}
          onClick={() => changeSubject(id, false)}
        >
          <FormattedMessage id="cashFlow-trend" />
        </Menu.Item>
      </Menu>
      <div className={styles.chart}>
        <LinearChart {...data} />
      </div>
    </Widget.Content>
  )

  return (
    <Widget>
      <Widget.Header title={widget.title} />
      {content}
    </Widget>
  )
}

export default connect(null, mapDispatchToProps)(React.memo(RevenueCostsGrowth, chartHasEqualProps))
