import React from 'react'
import { Route, Switch } from 'react-router'
import RecordsOnBoarding from 'src/frontend/scenes/onBoarding/inApp/components/RecordsOnBoarding'
import DashboardTopBar from 'src/frontend/scenes/dashboard/components/DashboardTopBar'
import EmptySpace from 'src/frontend/scenes/onBoarding/inApp/components/EmptySpace'

export default function InAppTopBar() {
  return (
      <Switch>
          <Route path='/dashboard' component={DashboardTopBar} />
          <Route exact path='/transactions' component={RecordsOnBoarding} />
          <Route path='/' component={EmptySpace} />
      </Switch>
  )
}
