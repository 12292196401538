import {
  CHANGE_COLUMN_ATTRIBUTE,
  CHANGE_FIRST_RECORD_POSITION,
  CHANGE_HAS_HEADER,
  CHANGE_LAST_RECORD_POSITION,
  CHANGE_SEPARATE_FEES,
  CHANGE_SEPARATE_INCOME_EXPENSE,
  CLOSE_PREVIEW,
  GET_INITIAL_MAPPING_SUCCESS,
  GO_WIZARD_NEXT, GO_WIZARD_PREV,
  HIDE_ATTRIBUTE_MAPPING_ERROR, SAVE_MAPPING_ERROR, SAVE_MAPPING_START, SAVE_MAPPING_SUCCESS,
  SET_SETTINGS,
  SET_VALIDATED,
  SHOW_ATTRIBUTE_MAPPING_ERROR,
  SHOW_PREVIEW,
} from 'src/frontend/scenes/imports/mapping/actions'
import { assignColumnToAttribute } from 'src/backend/imports/service'
import { WIZARD_STEPS_ORDER } from 'src/frontend/scenes/imports/mapping/constants'

const buildWizardPositionState = (currentWizardStep, increment = 0) => {
  const nextWizardStepOrder = WIZARD_STEPS_ORDER.indexOf(currentWizardStep) + increment
  const nextWizardStep = WIZARD_STEPS_ORDER[nextWizardStepOrder]
  const firstStep = nextWizardStepOrder === 0
  const lastStep = nextWizardStepOrder === WIZARD_STEPS_ORDER.length - 1
  return { wizardStep: nextWizardStep, firstStep, lastStep }
}

const buildWizardPositionInitialState = () => buildWizardPositionState(WIZARD_STEPS_ORDER[0])

const buildWizardNextPositionState = (currentWizardStep) => buildWizardPositionState(currentWizardStep, 1)

const buildWizardPrevPositionState = (currentWizardStep) => buildWizardPositionState(currentWizardStep, -1)

const initialState = {
  wizard: buildWizardPositionInitialState(),
  error: {},
  validationRequired: false,
  previewOpen: false,
  saveLoading: false,
}

export default function mapping(state = initialState, action) {

  switch (action.type) {

    case GO_WIZARD_NEXT: {
      const wizardState = state.wizard
      const currentWizardStep = wizardState.wizardStep
      return { ...state, wizard: { ...wizardState, ...buildWizardNextPositionState(currentWizardStep) } }
    }

    case GO_WIZARD_PREV: {
      const wizardState = state.wizard
      const currentWizardStep = wizardState.wizardStep
      return { ...state, wizard: { ...wizardState, ...buildWizardPrevPositionState(currentWizardStep) } }
    }

    case SET_SETTINGS: {
      return { ...state, settings: action.settings }
    }

    case SET_VALIDATED: {
      return { ...state, validationRequired: false }
    }

    case GET_INITIAL_MAPPING_SUCCESS: {
      const {
        itemId,
        initialMapping: {
          requiredAttributeTypes, columns, parsedExample, rowValues, settings,
        },
      } = action
      const wizard = buildWizardPositionInitialState()
      return {
        ...initialState,
        currentItemId: itemId,
        requiredAttributeTypes,
        columns,
        parsedExample,
        rowValues,
        settings,
        wizard,
      }
    }

    case CHANGE_COLUMN_ATTRIBUTE: {
      const { columns } = state
      const { columnIndex, attributeType, validationRequired } = action
      const updatedColumns = assignColumnToAttribute({ columnIndex, attributeType, columns })
      return { ...state, validationRequired: !!validationRequired, columns: updatedColumns }
    }

    case CHANGE_HAS_HEADER: {
      const { hasHeader } = action
      const settings = { ...state.settings, skipHeader: hasHeader }
      if (hasHeader && settings.firstRecord > 1) {
        settings.firstRecord -= 1
      } else if (!hasHeader) {
        settings.firstRecord += 1
      }
      return { ...state, settings, validationRequired: true }
    }

    case CHANGE_SEPARATE_INCOME_EXPENSE: {
      const { hasSeparateIncomeExpense } = action
      return ({
        ...state,
        validationRequired: hasSeparateIncomeExpense,
        settings: {
          ...state.settings,
          hasSeparateIncomeExpense,
        },
      })
    }

    case CHANGE_SEPARATE_FEES: {
      const { hasSeparateFees } = action
      return ({
        ...state,
        validationRequired: hasSeparateFees,
        settings: {
          ...state.settings,
          hasSeparateFees,
        },
      })
    }

    case CHANGE_FIRST_RECORD_POSITION: {
      const settings = { ...state.settings, firstRecord: Math.min(action.index, state.settings.lastRecord) }
      return { ...state, settings }
    }

    case CHANGE_LAST_RECORD_POSITION: {
      const settings = { ...state.settings, lastRecord: Math.max(action.index, state.settings.firstRecord) }
      return { ...state, settings }
    }

    case SHOW_ATTRIBUTE_MAPPING_ERROR: {
      return { ...state, error: action.error }
    }

    case HIDE_ATTRIBUTE_MAPPING_ERROR: {
      return { ...state, error: {} }
    }

    case SHOW_PREVIEW: {
      return { ...state, previewOpen: true }
    }

    case CLOSE_PREVIEW: {
      return { ...state, previewOpen: false }
    }

    case SAVE_MAPPING_START: {
      return { ...state, saveLoading: true }
    }

    case SAVE_MAPPING_SUCCESS:
    case SAVE_MAPPING_ERROR: {
      return { ...state, saveLoading: false }
    }

    default: {
      return state
    }
  }
}
