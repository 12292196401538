import { reduceBy } from 'src/common/utils'
import * as service from 'src/backend/magicRules/service'
import { MagicRule } from 'src/types/MagicRule'
import { Action, HashMap } from 'src/types/common'
import { Dispatch } from 'redux'

export const MAGIC_RULES_RECEIVE = 'MAGIC_RULES_RECEIVE'

export function receiveMagicRules(magicRules: MagicRule[]): Action {
  const magicRuleHashMap = magicRules.reduce<HashMap<MagicRule>>(reduceBy('_id'), {})
  return { type: MAGIC_RULES_RECEIVE, payload: magicRuleHashMap }
}

export function fetchMagicRules() {
  return async (dispatch: Dispatch<Action>) => {
    const labels = await service.fetchMagicRules()
    dispatch(receiveMagicRules(labels))
  }
}
