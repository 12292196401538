import { AbstractWidget, WidgetData, WidgetType } from 'src/frontend/scenes/dashboard/types'
import { FilterType } from 'src/types/Filter'
import { WidgetId } from 'src/backend/dashboard/enums'
import * as dashboardService from 'src/backend/dashboard/service'
import * as boardDashboardService from 'src/backend/dashboard/board/service'
import { RelativeIntervalType } from 'src/backend/enums'
import { getNextPeriodFilter } from 'src/backend/filters/helpers'
import { isRelativeInterval } from 'src/backend/time/time'
import { SpendingPieOptions } from 'src/frontend/scenes/dashboard/widgets/PieAndBarSpending/types'
import { HashMap } from 'src/types/common'

export function mergeDataWithWidgets(
  widgets: AbstractWidget[],
  dataCollection: WidgetData[],
): HashMap<WidgetType> {
  return widgets.reduce((acc: HashMap<WidgetType>, value: AbstractWidget, index: number) => {
    const data = dataCollection[index]
    if (data) {
      return {
        ...acc,
        [value.id]: {
          ...value,
          data,
        },
      }
    } else {
      return {
        ...acc,
        [value.id]: {
          ...value,
          data: null,
        },
      }
    }
  }, {})
}

export function mapAbstractWidgetToFetchWidgetData(commonFilter: FilterType) {
  return (widget: AbstractWidget): Promise<WidgetData> => {
    // @ts-ignore
    const widgetFilter = widget.filter || {}
    const mergedFilter = { ...commonFilter, ...widgetFilter }

    // reverse filter to the future if widget option indicates it
    const filter = isRelativeInterval(mergedFilter.period.interval)
    && widget.options.relativeIntervalValue === RelativeIntervalType.NEXT
      ? getNextPeriodFilter(mergedFilter)
      : mergedFilter

    switch (widget.widgetId) {
      case WidgetId.BALANCE_CHART_CARD:
        return dashboardService.fetchBalanceChartData(filter)

      case WidgetId.LAST_RECORDS_CARD:
        return dashboardService.fetchLastRecords(filter)

      case WidgetId.SPENDING_BY_CATEGORIES_CARD:
      case WidgetId.SPENDING_BY_CATEGORIES_PIE_CHART_CARD:
        return dashboardService.fetchExpenseCategories(filter)

      case WidgetId.CASH_FLOW_CARD: {
        return dashboardService.fetchCashFlow(filter)
      }

      case WidgetId.CASH_FLOW_CARD_BOARD: {
        return boardDashboardService.fetchBoardCashFlow(filter)
      }

      case WidgetId.UPCOMING_PLANNED_PAYMENTS:
        return dashboardService.fetchPlannedPayments(filter)

      case WidgetId.PERFORMANCE_CARD:
        return dashboardService.fetchPerformance(filter)

      case WidgetId.PERIOD_2_PERIOD_CHART_CARD:
        return dashboardService.fetchPeriodToPeriodChartData(filter, widget.options)

      case WidgetId.BALANCE_BY_CURRENCIES_CARD:
        return dashboardService.fetchBalanceByCurrency(filter)

      case WidgetId.CASH_FLOW_CHART_CARD:
        return dashboardService.fetchCashFlowTrendChartData(filter, widget.options)

      case WidgetId.CASH_FLOW_CHART_CARD_BOARD:
        return boardDashboardService.fetchBoardCashFlowTrendChartData(filter, widget.options)

      case WidgetId.CASH_RATIO_LIQUIDITY_CARD:
        return dashboardService.fetchCashRatioLiquidity(filter)

      case WidgetId.REVENUE_COST_GROWTH_CARD:
        return boardDashboardService.fetchOperatingRevenueGrowthChartData(filter, widget.options, widget.variant)

      case WidgetId.OPERATING_PROFIT_CARD:
        return boardDashboardService.fetchBoardOperatingProfit(filter)

      case WidgetId.CREDIT_UTILIZATION_CARD:
        return dashboardService.fetchCreditLimitUtilization(filter)

      case WidgetId.SPENDING_BY_CATEGORIES_AND_LABELS_PIE_CHART_CARD:
        return dashboardService.fetchPieAndBarSpending(filter, widget.options as SpendingPieOptions, widget.variant)

      case WidgetId.CARDINALITY_SPENDING_CHART_CARD:
        return dashboardService.fetchSpendingByNature(filter, widget.variant)

      case WidgetId.COCKPIT_BOARD:
        return boardDashboardService.fetchBoardPerformance(filter)

      default:
        return Promise.resolve()
    }
  }
}
