import { WidgetId } from 'src/backend/dashboard/enums'
import { WidgetType } from 'src/frontend/scenes/dashboard/types'
import BalanceByCurrencyBar from 'src/frontend/scenes/dashboard/widgets/BalanceByCurrencyBar/BalanceByCurrencyBar'
import BalanceChart from 'src/frontend/scenes/dashboard/widgets/BalanceChart/BalanceChart'
import CashFlowBar from 'src/frontend/scenes/dashboard/widgets/CashFlowBar/CashFlowBar'
import CashFlowTrend from 'src/frontend/scenes/dashboard/widgets/CashFlowTrend/CashFlowTrend'
import Gauges from 'src/frontend/scenes/dashboard/widgets/Performance/Performance'
import LastRecords from 'src/frontend/scenes/dashboard/widgets/LastRecords/LastRecords'
import PeriodToPeriodChart from 'src/frontend/scenes/dashboard/widgets/PeriodToPeriodChart/PeriodToPeriodChart'
import PlannedPaymentsList from 'src/frontend/scenes/dashboard/widgets/PlannedPaymentsList/PlannedPaymentsList'
import SpendingByCategoriesBar
  from 'src/frontend/scenes/dashboard/widgets/SpendingByCategoriesBar/SpendingByCategoriesBar'
import SpendingByCategoriesPie
  from 'src/frontend/scenes/dashboard/widgets/SpendingByCategoriesPie/SpendingByCategoriesPie'
import CashRatioLiquidity from 'src/frontend/scenes/dashboard/widgets/CashRatioLiquidity/CashRatioLiquidity'
import BoardCashFlowBar from 'src/frontend/scenes/dashboard/widgets/BoardCashFlowBar/BoardCashFlowBar'
import BoardCashFlowTrend from 'src/frontend/scenes/dashboard/widgets/BoardCashFlowTrend/BoardCashFlowTrend'
import RevenueCostsGrowth from 'src/frontend/scenes/dashboard/widgets/BoardRevenueCostsGrowth/RevenueCostsGrowth'
import CreditLimitsUtilization
  from 'src/frontend/scenes/dashboard/widgets/CreditLimitsUtilization/CreditLimitsUtilization'
import PieAndBarSpending from 'src/frontend/scenes/dashboard/widgets/PieAndBarSpending/PieAndBarSpending'
import React from 'react'
import SpendingByNatureBars from 'src/frontend/scenes/dashboard/widgets/SpendingByNatureBars/SpendingByNatureBars'
import BoardPerformance from 'src/frontend/scenes/dashboard/widgets/Performance/BoardPerformance'
import AccountList from 'src/frontend/scenes/dashboard/widgets/AccountList/AccountList'

export function getWidgetComponentByWidget(widget: WidgetType): React.ComponentType<{ widget: WidgetType }> {
  return getWidgetComponentByWidgetId(widget.widgetId)
}

export function getWidgetComponentByWidgetId(
  widgetId: WidgetId,
): React.ComponentType<{ widget: WidgetType }> {
  switch (widgetId) {
    case WidgetId.BALANCE_CHART_CARD:
      return BalanceChart

    case WidgetId.LAST_RECORDS_CARD:
      return LastRecords

    case WidgetId.SPENDING_BY_CATEGORIES_CARD:
      return SpendingByCategoriesBar

    case WidgetId.SPENDING_BY_CATEGORIES_PIE_CHART_CARD:
      return SpendingByCategoriesPie

    case WidgetId.CASH_FLOW_CARD:
      return CashFlowBar

    case WidgetId.CASH_FLOW_CARD_BOARD:
    case WidgetId.OPERATING_PROFIT_CARD:
      return BoardCashFlowBar

    case WidgetId.UPCOMING_PLANNED_PAYMENTS:
      return PlannedPaymentsList

    case WidgetId.PERFORMANCE_CARD:
      return Gauges

    case WidgetId.PERIOD_2_PERIOD_CHART_CARD:
      return PeriodToPeriodChart

    case WidgetId.CASH_FLOW_CHART_CARD:
      return CashFlowTrend

    case WidgetId.CASH_FLOW_CHART_CARD_BOARD:
      return BoardCashFlowTrend

    case WidgetId.BALANCE_BY_CURRENCIES_CARD:
      return BalanceByCurrencyBar

    case WidgetId.CASH_RATIO_LIQUIDITY_CARD:
      return CashRatioLiquidity

    case WidgetId.REVENUE_COST_GROWTH_CARD:
      return RevenueCostsGrowth

    case WidgetId.CREDIT_UTILIZATION_CARD:
      return CreditLimitsUtilization

    case WidgetId.SPENDING_BY_CATEGORIES_AND_LABELS_PIE_CHART_CARD:
      return PieAndBarSpending

    case WidgetId.CARDINALITY_SPENDING_CHART_CARD:
      return SpendingByNatureBars

    case WidgetId.COCKPIT_BOARD:
      return BoardPerformance

    case WidgetId.ACCOUNTS_LIST:
      return AccountList

    default:
      throw new Error(`Attempted to map unsupported widget id=${widgetId}`)
  }
}
