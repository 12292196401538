import React, { SyntheticEvent } from "react"
import { Checkbox, Input } from "semantic-ui-react"
import { FormattedMessage, FormattedMessageLabel } from "src/frontend/modules/intl"
import { Interval, RelativeIntervalType } from "src/backend/enums"
import "./RangeContent.less"
import DatePicker from "react-datepicker/dist/react-datepicker"
import {
  isRelativeInterval,
  nextPeriod,
  relativeIntervalTypes,
  toTimePeriod,
} from "src/backend/time/time"
import moment from "moment"
import { Period } from "src/types/Filter"
import { SemanticData } from "src/types/Semantic-ui"
import { formatInterval, formatMessage } from "src/frontend/modules/intl/i18n"
import { ComplementaryButton } from "src/frontend/components/Buttons/Buttons"

interface Props {
  period: Period
  showAll: boolean
  onChange: Function
  intervalType?: RelativeIntervalType
  onClose: () => void
}

type State = {
  customStartOpen: boolean
  customEndOpen: boolean
}

export default class RangeContent extends React.Component<Props, State> {
  static defaultProps = {
    intervalType: RelativeIntervalType.LAST,
  }

  state = { customStartOpen: this.props.period.interval === Interval.CUSTOM, customEndOpen: false }

  handleChangeCustomStart = (start: moment.Moment, e: any) => {
    e.nativeEvent.stopImmediatePropagation()
    const { end } = this.props.period
    this.props.onChange({
      end,
      interval: Interval.CUSTOM,
      start: start.startOf("day"),
    })
    this.handleCloseRange()
  }

  handleChangeCustomEnd = (end: moment.Moment, e: any) => {
    e.nativeEvent.stopImmediatePropagation()
    const { start } = this.props.period
    this.props.onChange({
      interval: Interval.CUSTOM,
      start,
      end: end.endOf("day"),
    })
    this.handleCloseRange()
  }

  handleChangeCustomInterval = () => {
    this.handleOpenStartRange()
    const { start, end } = this.props.period
    this.props.onChange({
      interval: Interval.CUSTOM,
      start: start || moment().startOf("month"),
      end: end || moment().endOf("month"),
    })
  }

  handleChangeInterval = (_e: SyntheticEvent, { value }: SemanticData) => {
    if (value) {
      if (value === Interval.ALL_THE_TIME) {
        this.props.onChange({
          interval: value,
        })
      } else if (
        isRelativeInterval(value) &&
        this.props.intervalType === RelativeIntervalType.NEXT
      ) {
        const { start, end, interval } = toTimePeriod(relativeIntervalTypes)(value)
        this.props.onChange(nextPeriod(start, end, interval))
      } else {
        this.props.onChange(toTimePeriod(relativeIntervalTypes)(value))
      }
    }
    this.props.onClose()
  }

  handleOpenStartRange = () => this.setState({ customStartOpen: true, customEndOpen: false })

  handleOpenEndRange = () => this.setState({ customStartOpen: false, customEndOpen: true })

  handleCloseRange = () => this.setState({ customStartOpen: false, customEndOpen: false })

  killEvent = (event: any) => {
    event.preventDefault()
  }

  render() {
    const {
      period: { interval, start, end },
      showAll,
      intervalType,
    } = this.props
    const { customStartOpen, customEndOpen } = this.state
    return (
      <div className="date-range-picker__custom-range">
        <div className="date-range-picker__predefined-values-container">
          {/* Checkboxes with predefined relative intervals(e.g 30 days, 12 months, etc.) */}
          <Checkbox
            radio
            label={formatInterval(Interval.RELATIVE_7_DAYS, intervalType)}
            value={Interval.RELATIVE_7_DAYS}
            checked={interval === Interval.RELATIVE_7_DAYS}
            onChange={this.handleChangeInterval}
          />
          <Checkbox
            radio
            label={formatInterval(Interval.RELATIVE_30_DAYS, intervalType)}
            value={Interval.RELATIVE_30_DAYS}
            checked={interval === Interval.RELATIVE_30_DAYS}
            onChange={this.handleChangeInterval}
          />
          <Checkbox
            radio
            label={formatInterval(Interval.RELATIVE_90_DAYS, intervalType)}
            value={Interval.RELATIVE_90_DAYS}
            checked={interval === Interval.RELATIVE_90_DAYS}
            onChange={this.handleChangeInterval}
          />
          <Checkbox
            radio
            label={formatInterval(Interval.RELATIVE_12_MONTHS, intervalType)}
            value={Interval.RELATIVE_12_MONTHS}
            checked={interval === Interval.RELATIVE_12_MONTHS}
            onChange={this.handleChangeInterval}
          />
          {/* Checkboxes with current periods intervals(e.g today, this month, etc.) */}
          <Checkbox
            radio
            label={formatMessage("today")}
            value={Interval.TODAY}
            checked={interval === Interval.TODAY}
            onChange={this.handleChangeInterval}
          />
          <Checkbox
            radio
            label={formatMessage("thisWeek")}
            value={Interval.WEEK}
            checked={interval === Interval.WEEK}
            onChange={this.handleChangeInterval}
          />
          <Checkbox
            radio
            label={formatMessage("thisMonth")}
            value={Interval.MONTH}
            checked={interval === Interval.MONTH}
            onChange={this.handleChangeInterval}
          />
          <Checkbox
            radio
            label={formatMessage("thisYear")}
            value={Interval.YEAR}
            checked={interval === Interval.YEAR}
            onChange={this.handleChangeInterval}
          />
        </div>

        {showAll && (
          <div>
            <Checkbox
              radio
              label={<FormattedMessageLabel id={Interval.ALL_THE_TIME} />}
              value={Interval.ALL_THE_TIME}
              checked={interval === Interval.ALL_THE_TIME}
              onChange={this.handleChangeInterval}
            />
          </div>
        )}
        <div>
          <Checkbox
            radio
            className="radio-with-field"
            label={
              <label onClick={this.killEvent}>
                <FormattedMessage id={Interval.CUSTOM} />
                <div>
                  <Input
                    className={customStartOpen ? "active" : ""}
                    value={start ? start.format("l") : ""}
                    onClick={this.handleOpenStartRange}
                  />
                  <Input
                    className={customEndOpen ? "active" : ""}
                    value={end ? end.format("l") : ""}
                    onClick={this.handleOpenEndRange}
                  />
                </div>
              </label>
            }
            value={Interval.CUSTOM}
            checked={interval === Interval.CUSTOM}
            onChange={this.handleChangeCustomInterval}
          />
        </div>
        {interval === Interval.CUSTOM && !customStartOpen && !customEndOpen && (
          <ComplementaryButton
            className="custom-apply"
            onClick={this.props.onClose}
          >
            <FormattedMessage id="form.apply" />
          </ComplementaryButton>
        )}
        {customStartOpen && (
          <div className="custom-picker">
            <DatePicker
              selected={start}
              maxDate={end}
              inline
              onChange={this.handleChangeCustomStart}
            />
          </div>
        )}
        {customEndOpen && (
          <div className="custom-picker">
            <DatePicker
              selected={end}
              minDate={start}
              inline
              onChange={this.handleChangeCustomEnd}
            />
          </div>
        )}
      </div>
    )
  }
}
