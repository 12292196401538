import * as actions from './actions'
import { HashMap } from 'src/types/common'
import { Id } from 'src/types/CouchDb'
import { MagicRulesFormValues } from 'src/frontend/scenes/settings/magicRules/types'
import { FormType } from 'src/frontend/scenes/settings/magicRules/enums'
import { Record } from 'src/types/Record'

export interface MagicRulesFormState {
  formType: FormType
  isOpen: boolean
  keywordsGuide: string[]
  formValues: Partial<MagicRulesFormValues>
  errors: HashMap<string>
  magicRuleId: Id
  similarRecords: Record[]
  loading: boolean
}

const initialFormState: MagicRulesFormState = {
  formType: FormType.ADD,
  isOpen: false,
  formValues: null,
  keywordsGuide: null,
  magicRuleId: null,
  errors: {},
  similarRecords: null,
  loading: false,
}

function magicRules(state: MagicRulesFormState = initialFormState, action): MagicRulesFormState {
  switch (action.type) {
    case actions.SETTINGS_MAGIC_RULES_OPEN_FORM: {
      return {
        ...state,
        isOpen: true,
        formType: action.payload.formType,
        formValues: action.payload.formValues,
        keywordsGuide: action.payload.keywordsGuide,
        magicRuleId: action.payload.magicRuleId,
      }
    }

    case actions.SETTINGS_MAGIC_RULES_SIMILAR_RECORDS_SAVE:
    case actions.SETTINGS_MAGIC_RULES_SAVE_START: {
      return { ...state, loading: true }
    }

    case actions.SETTINGS_MAGIC_RULES_SHOW_SIMILAR_RECORDS: {
      return { ...state, formType: FormType.SELECT_SIMILAR_RECORDS, similarRecords: action.payload, loading: false }
    }

    case actions.SETTINGS_MAGIC_RULES_SAVE_ERROR: {
      return { ...state, errors: action.payload, loading: false }
    }
    case actions.SETTINGS_MAGIC_RULES_SAVE_SUCCESS:
    case actions.SETTINGS_MAGIC_RULES_CLOSE_FORM: {
      return initialFormState
    }
    case actions.SETTINGS_MAGIC_RULES_CHANGE_FIELD_VALUE: {
      return { ...state, formValues: { ...state.formValues, ...action.payload } }
    }
    default: {
      return state
    }
  }
}

export default magicRules
