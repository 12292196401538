import React from 'react'
import { Popup } from 'semantic-ui-react'
import { ColorIconPickerProps } from 'src/frontend/components/ColorPicker/types'
import ColorIconPickerContent from 'src/frontend/components/ColorPicker/ColorIconPickerContent'

export default function ColorIconPicker({
  colors,
  icons,
  icon,
  color,
  trigger,
  position,
  open,
  onOpen,
  onClose,
  onColorSelect,
  onIconSelect,
}: ColorIconPickerProps) {

  return (
    <Popup
      trigger={trigger}
      content={(
        <ColorIconPickerContent
          colors={colors}
          color={color}
          icon={icon}
          icons={icons}
          onColorSelect={onColorSelect}
          onIconSelect={onIconSelect}
        />
      )}
      on="click"
      open={open}
      position={position}
      hideOnScroll={false}
      onClose={onClose}
      onOpen={onOpen}
    />
  )
}
