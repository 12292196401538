import React from 'react'
import styles from 'src/frontend/components/RecordList/RecordList.module.less'
import { RecordListItemRecord } from 'src/frontend/components/RecordList/types'
import BasicRecordListItem from 'src/frontend/components/RecordList/BasicRecordListItem'
import { Id } from 'src/types/CouchDb'
import { recordHasConnectedAccount, recordIsUncleared } from 'src/backend/records/helpers'
import { isUndefinedOrNull, mergeClasses } from 'src/common/utils'

interface Props {
  records: Array<RecordListItemRecord>,
  showRelativeTime?: boolean,

  // toggles select option
  selectable?: boolean

  editable?: boolean

  // creates a box with fixed height with scrollable content of record list
  scrollable?: boolean


  selectedIds?: Id[]

  /*
    single mode - possible to select by clicking the whole row, selcted records are highligted, not selected are gray
    checkbox - renders checkboxes and selecting is made by checkboxes only
   */
  selectMethod?: 'checkbox' | 'single'

  noAccounts?: boolean
  noNotes?: boolean


  onSelect?: (recordId: Id) => void
  onEdit?: (recordId: Id) => void
}

BasicRecordList.defaultProps = {
  showRelativeTime: false,
  selectable: false,
  selectedId: undefined,
  onSelect: undefined,
  editable: false,
  scrollable: false,
  onEdit: undefined,
  noAccounts: false,
  noNotes: false,
}

export default function BasicRecordList({
  records,
  showRelativeTime,
  selectable,
  editable,
  selectedIds,
  noAccounts,
  noNotes,
  selectMethod,
  scrollable,
  onSelect,
  onEdit,
}: Props) {

  return (
    <div className={mergeClasses(styles.basicRecordList, scrollable ? styles.scrollable : undefined)}>
      <div
        className={mergeClasses(
          styles.recordList,
          styles.basic,
          selectable && styles.selectable,
          getSelectMethodClassName(selectMethod),
        )}
      >
        <ul>
          {records.map(record => {
            const isSelected = selectable && !isUndefinedOrNull(selectedIds) && selectedIds.includes(record._id)
            const selectedClassName = isSelected ? styles.selected : styles.notSelected
            const className = selectMethod === 'single' && !isUndefinedOrNull(selectedIds)
              ? selectedClassName
              : undefined
            const canEditRecord = !recordHasConnectedAccount(record) || !recordIsUncleared(record)
            return (
              <BasicRecordListItem
                selected={isSelected}
                className={className}
                key={`${record._id}-${record.recordDate.getTime()}`}
                record={record}
                noAccount={noAccounts}
                noNote={noNotes}
                showRelativeTime={showRelativeTime}
                editable={editable && canEditRecord}
                onEditClick={() => onEdit && onEdit(record._id)}
                onClick={selectMethod === 'single' && onSelect ? () => onSelect(record._id) : undefined}
                onCheck={selectMethod === 'checkbox' && onSelect ? () => onSelect(record._id) : undefined}
              />
            )
          })}
        </ul>
      </div>
    </div>
  )
}

function getSelectMethodClassName(selectMethod: 'checkbox' | 'single') {
  if (selectMethod === 'checkbox') {
    return styles.selectCheckbox
  }

  if (selectMethod === 'single') {
    return styles.selectSingle
  }

  return undefined
}
