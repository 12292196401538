
export function validate(validationSchema, values) {
  try {
    validationSchema.validateSync(values, { abortEarly: false })
    return {}
  } catch (error) {
    return getErrorsFromValidationError(error)
  }
}

function getErrorsFromValidationError(error) {
  const FIRST_ERROR = 0
  return error.inner.reduce((errors, validationError) => {
    return {
      ...errors,
      [validationError.path]: validationError.errors[FIRST_ERROR],
    }
  }, {})
}
