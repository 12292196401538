import { Record as RecordType } from "src/types/Record"
import { Platform } from "src/backend/enums"
import { inMemoryTableNames } from "src/backend/db/inMemorySqlDbSchemaBuilder"

const defaultRecord: RecordType = {
  _id: null,
  accountId: null,
  accuracy: 0,
  categoryChanged: false,
  categoryId: null,
  currencyId: null,
  amount: 0,
  latitude: 0.0,
  longitude: 0.0,
  refAmount: 0,
  note: "",
  paymentType: 0,
  photos: [],
  labels: [],
  recordDate: null,
  recordState: 0,
  reservedAuthorId: null,
  reservedCreatedAt: null,
  reservedUpdatedAt: null,
  reservedModelType: inMemoryTableNames.RECORD,
  reservedOwnerId: null,
  reservedSource: Platform.WEB,
  type: 0,
  transferId: undefined,
  transfer: false,
  payee: "",
}

export function DefaultRecord(props?: RecordType): RecordType {
  if (!props) {
    return defaultRecord
  }

  return {
    ...defaultRecord,
    ...props,
  }
}
