import { formatMessage } from 'src/frontend/modules/intl/i18n'
import {
  SYSTEM_CATEGORIES_BANK_STATEMENT,
  SYSTEM_CATEGORIES_ID,
  SYSTEM_CATEGORIES_ONE_CLICK_WIDGET,
  SYSTEM_CATEGORIES_TRANSFER_ID,
  SYSTEM_CATEGORIES_UNKNOWN_ID,
  UNKNOWN_RECORDS_ENVELOPE_ID,
  UNKNOWN_RECORDS_EXPENSE_ENVELOPE_ID,
  UNKNOWN_RECORDS_INCOME_ENVELOPE_ID,
  generalEnvelopeIds,
  getEnvelopeById,
} from "src/backend/categories/envelopes"
import { AbstractEnvelope, Category, Envelope, SuperEnvelope } from "src/types/Category"
import { CategoryLevel } from 'src/frontend/modules/categories/enums'
import { Option } from 'src/frontend/components/Filter/types'
import { matchLatinized } from 'src/common/utils'


export const isAssignedToSuperEnvelope = (superEnvelopeId: number) => {
  return (category: Category): boolean => category.superEnvelopeId === superEnvelopeId
}

export function isNotHidden(category: Category | SuperEnvelope): boolean {
  return !category.isHidden
}

export const formatCategoryName = (categoryName, isCustomCategory, hasCustomName, isGeneral): string => {
  if (isCustomCategory || hasCustomName) {
    return categoryName
  }

  const GENERAL_CATEGORY_LOCALIZATION_KEY = 'cat_general'
  return isGeneral
    ? `${formatMessage(GENERAL_CATEGORY_LOCALIZATION_KEY)}${categoryName ? ` - ${formatMessage(categoryName)}` : ''}`
    : categoryName ? formatMessage(categoryName) : ''
}

export const formatEnvelopeNameByCategory = (category: Category): string => {
  const GENERAL_CATEGORY_LOCALIZATION_KEY = 'cat_general'
  const envelopeLocalizationString = getEnvelopeById(category.envelopeId).localizationString
  return category.isGeneral
    ? `${formatMessage(GENERAL_CATEGORY_LOCALIZATION_KEY)} - ${formatMessage(envelopeLocalizationString)}`
    : formatMessage(envelopeLocalizationString)
}


export const sortByLocalizedCategoryName = (
  categoryA: Category | Envelope | Option,
  categoryB: Category | Envelope | Option,
): number => {
  if ((categoryA as Category).isGeneral && !(categoryB as Category).isGeneral) {
    return -1
  } else if (!(categoryA as Category).isGeneral && (categoryB as Category).isGeneral) {
    return 1
  } else {
    const categoryAName = categoryA?.name
    const categoryBName = categoryB?.name

    return categoryAName.localeCompare(categoryBName)
  }
}

// or lieutenant
export const isGeneralCategory = (category: Category): boolean => category.isGeneral

export const isGeneralCategory2 = (category: Category): boolean => {
  return generalEnvelopeIds.includes(category.envelopeId) && !category.customCategory
}

export const isNotUnknownCategory = (category: Category): boolean => {
  return ![
    UNKNOWN_RECORDS_ENVELOPE_ID,
    UNKNOWN_RECORDS_EXPENSE_ENVELOPE_ID,
    UNKNOWN_RECORDS_INCOME_ENVELOPE_ID,
  ].includes(category.envelopeId)
}

export const isGeneralEnvelope = (envelope: AbstractEnvelope): boolean => {
  const ENDS_WITH_OTHERS_REGEXP = /__OTHERS$/
  return ENDS_WITH_OTHERS_REGEXP.test(envelope.name)
}

export function convertToCategoryItem(category: Category | Envelope, level: CategoryLevel) {
  const subcategories = category.subcategories
    ? category.subcategories.sort(sortByLocalizedCategoryName)
    : null
  const categoryName = category?.name

  return {
    subcategories,
    category,
    level,
    name: categoryName,
  }
}

export function filterCategoriesByLatinizedName(value: string) {
  return (category: Category) => {
    const categoryName = category?.name ?? ''

    return matchLatinized(categoryName, value)
  }
}
