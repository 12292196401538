import React from 'react'
import MFAForm from 'src/frontend/scenes/integrations/components/MFAForm'
import { RootState } from 'src/types/State'
import { connect } from 'react-redux'
import { FormAttributeFields } from 'src/frontend/scenes/integrations/types'
import {
  selectActiveMFALoginId,
  selectActiveMFARefreshes,
} from 'src/frontend/scenes/integrations/connections/selectors'
import {
  finishOAuthRefresh,
  sendRefreshMFAForm,
  setActiveMFALoginId,
} from 'src/frontend/scenes/integrations/connections/actions'
import { ActiveRefresh } from 'src/frontend/scenes/integrations/connections/reducer'
import { Dropdown } from 'semantic-ui-react'
import styles from './RefreshBankMFA.module.less'
import { isOAuth } from 'src/backend/integrations/helpers'
import OAuthForm from 'src/frontend/scenes/integrations/components/OAuthForm'
import { HashMap } from 'src/types/common'

interface Props {
  activeRefreshes: HashMap<ActiveRefresh>
  selectedLoginId: string
  handleSubmit: (loginId: string) => (fields: FormAttributeFields) => void
  handleOAuthRefresh: (authCallback: { loginId: string, success: boolean }) => void
  setSelectedLoginId: (loginId: string) => void
}

function mapStateToProps(state: RootState) {
  return {
    activeRefreshes: selectActiveMFARefreshes(state),
    selectedLoginId: selectActiveMFALoginId(state),
  }
}

function mapDispatchToProps(dispatch) {
  return {
    handleSubmit: (loginId) => (formValues) => dispatch(sendRefreshMFAForm(loginId, formValues)),
    handleOAuthRefresh: (authCallBack) => dispatch(finishOAuthRefresh(authCallBack)),
    setSelectedLoginId: (loginId) => dispatch(setActiveMFALoginId(loginId)),
  }
}

function RefreshBankMFA({
  activeRefreshes,
  selectedLoginId,
  handleSubmit,
  setSelectedLoginId,
  handleOAuthRefresh,
}: Props) {
  const loginIds = Object.keys(activeRefreshes)

  const activeRefresh = activeRefreshes[selectedLoginId]

  const { params, title } = activeRefresh && activeRefresh.refreshMFA[0]

  const options = loginIds.map((id) => ({ text: activeRefreshes[id].providerName, key: id, value: id }))

  return (
    <div className={styles.refreshBankMfa}>
      {loginIds.length > 1 ? (
        <div className={styles.providerSelect}>
          <Dropdown
            options={options}
            value={selectedLoginId}
            onChange={(_e, { value }) => setSelectedLoginId(value as string)}
          />
        </div>
      ) : (
        <h3>{activeRefresh && activeRefresh.providerName}</h3>
      )}
      {!isOAuth(activeRefresh.refreshMFA) ? (
        <MFAForm
          key={activeRefresh.loginId}
          params={params}
          title={title}
          onSubmit={handleSubmit(selectedLoginId)}
        />
      ) : (
        <OAuthForm
          params={params}
          loginId={selectedLoginId}
          onOAuthCallback={handleOAuthRefresh}
        />
      )}
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(RefreshBankMFA)
