import { NewConnectionState } from "src/frontend/scenes/integrations/newConnection/reducer"
import { selectNewConnection } from "./selectors"
import { Dispatch } from "redux"
import { IntegrationProviders as IP } from "src/frontend/scenes/integrations/providersList/types"
import { IntegrationProviders } from "src/frontend/scenes/integrations/providersList/types"
import { Action, GetState, HashMap } from "src/types/common"
import {
  selectConnectionAccount,
  selectIntegrationError,
  selectIntegrationStatus,
  selectNewConnectionMFA,
  selectPendingConnectionProvider,
  selectPendingConnectionRemoteAccounts,
  selectPersonalDetailsFormValues,
} from "src/frontend/scenes/integrations/newConnection/selectors"
import { Id } from "src/types/CouchDb"
import * as service from "src/backend/integrations/service"
import { isAuthorizationError, isServerError, isServiceUnavailableError } from "src/common/helpers"
import { expireUser } from "src/frontend/modules/user/actions"
import { selectUser } from "src/frontend/modules/user/selectors"
import { isUndefinedOrNull, reduceBy } from "src/common/utils"
import { FormAttributeFields } from "src/frontend/scenes/integrations/types"
import {
  clearAndCloseForm,
  clearProviderSelection,
  fetchProviderDetails,
} from "src/frontend/scenes/integrations/providersList/actions"
import {
  selectAlreadyConnectedProviders,
  selectProviderDetail,
  selectReconnectLoginId,
} from "src/frontend/scenes/integrations/providersList/selectors"
import { NewIntegrationsStatus } from "src/frontend/scenes/integrations/enums"
import * as logger from "src/common/logger"
import { showError } from "src/frontend/scenes/app/actions"
import { DEFAULT_ERROR_MESSAGE_ID } from "src/frontend/Error/actions"
import { NoRemoteAccountsError } from "src/backend/integrations/errors"
import { sendPushNotification } from "src/backend/pushNotifications/service"
import {
  trackIntegrationsAccountsShow,
  trackIntegrationsCancel,
  trackIntegrationsError,
  trackIntegrationsMFASent,
  trackIntegrationsNewConnectionCredentialsSubmit,
  trackIntegrationsNewConnectionOAuthGranted,
  trackIntegrationsReconnectConnectionCredentialsSubmit,
  trackIntegrationsReconnectOAuthGranted,
  trackIntegrationsReconnectSuccess,
  trackIntegrationsSuccess,
  trackPersonalDetailsShow,
  trackPersonalDetailsSuccess,
} from "src/common/mixpanel"
import { openGoodJobModal } from "src/frontend/scenes/onBoarding/inApp/actions"
import { fetchRestrictedProviders } from "src/frontend/scenes/integrations/restrictedProviders/actions"
import {
  selectOnBoardingShow,
  selectOnBoardingStage,
} from "src/frontend/scenes/onBoarding/firstRun/selectors"
import { gotoFinishStage } from "src/frontend/scenes/onBoarding/firstRun/actions"
import { VerifiedProfile } from "src/types/User"
import { getIntegrationSourcePollingTimeout } from "src/frontend/scenes/integrations/helpers"
import { isAppBoard } from "src/common/environment"
import moment from "moment"
import { UNIT_DAY } from "src/backend/time/time"
import { OnBoardingStage } from "src/frontend/scenes/onBoarding/firstRun/enums"
import { IntegrationConnection } from "src/frontend/scenes/integrations/newConnection/types"

import IntegrationConnectedProvider = IntegrationProviders.IntegrationConnectedProvider
import IntegrationLoginStatus = IntegrationProviders.IntegrationLoginStatus
import IntegrationProvider = IntegrationProviders.IntegrationProvider
import IntegrationLoginResponse = IntegrationProviders.IntegrationLoginResponse
import IntegrationRecipeGroup = IntegrationProviders.IntegrationRecipe.IntegrationRecipeGroup
import ConnectionType = IntegrationConnection.ConnectionType
import ConnectedProviderStatus = IntegrationProviders.ConnectedProviderStatus

import { fetchKBProviderDetails } from "src/frontend/scenes/integrations/providersList/kb/actions"
import InputType = IntegrationProviders.IntegrationRecipe.InputType
import { setAllAccountsFilter } from "src/frontend/scenes/dashboard/actions"
import {
  setIntegrationProviderCode,
  getProviderLoginId,
  setloginResponse,
  getNewConnectionFromLocaleStorage,
  getConnectionTypeFromLocaleStorage,
  removeNewConnectionDataFromLocaleStorage,
  getIntegrationSourceNameFromLocaleStorage,
} from "src/backend/db/localStorage"
import { IntegrationSource } from "src/backend/integrations/enums"
import { fetchExistingConnectedIntegrations } from "src/backend/integrations/service"
import { setPendingReconnectEnd, setPendingReconnectProvider } from "../connections/actions"
import { getIntegrationSourceEnumFromIntegrationSourceName } from "./helpers"

export const OVERRIDE_NEW_CONNECTION = "integrations/newConnection/OVERWRITE_NEW_CONNETION"
export const CREATE_CONNECTION_START = "integrations/newConnection/CREATE_CONNECTION_START"
export const CREATE_CONNECTION_SUCCESS = "integrations/newConnection/CREATE_CONNECTION_SUCCESS"

export const CONNECTION_SHOW_MFA = "integrations/newConnection/CONNECTION_SHOW_MFA"
export const CONNECTION_HIDE_MFA = "integrations/newConnection/CONNECTION_HIDE_MFA"

export const PERSONAL_INFORMATION_SHOW = "integrations/newConnection/PERSONAL_INFORMATION_SHOW"
export const PERSONAL_INFORMATION_CHANGE_VALUE =
  "integrations/newConnection/PERSONAL_INFORMATION_CHANGE_VALUE"
export const PERSONAL_INFORMATION_SAVE_START =
  "integrations/newConnection/PERSONAL_INFORMATION_SAVE_START"
export const PERSONAL_INFORMATION_SAVE_SUCCESS =
  "integrations/newConnection/PERSONAL_INFORMATION_SAVE_SUCCESS"

export const GET_ACCOUNTS_SUCCESS = "integrations/newConnection/GET_ACCOUNTS_SUCCESS"

export const CREATE_ACCOUNTS_START = "integrations/newConnection/CREATE_ACCOUNTS_START"
export const CREATE_ACCOUNTS_ERROR = "integrations/newConnection/CREATE_ACCOUNTS_ERROR"

export const CHECK_STATUS_START = "integrations/newConnection/CHECK_STATUS_START"
export const CHECK_STATUS_SUCCESS = "integrations/newConnection/CHECK_STATUS_SUCCESS"

export const INTEGRATIONS_FORM_OPEN = "integrations/newConnection/INTEGRATIONS_FORM_OPEN"
export const INTEGRATIONS_FORM_CLOSE = "integrations/newConnection/INTEGRATIONS_FORM_CLOSE"

export const SET_ERROR = "integrations/newConnection/SET_ERROR"
export const CLEAR_ERROR = "integrations/newConnection/CLEAR_ERROR"

export const LOGIN_PROVIDER_START = "integrations/newConnection/LOGIN_PROVIDER_START"
export const LOGIN_PROVIDER_SUCCESS = "integrations/newConnection/LOGIN_PROVIDER_SUCCESS"

export function checkConnectIntegrationStatus(loginId: string) {
  return async (dispatch: Function, getState: GetState) => {
    dispatch(checkIntegrationStatusStart())
    const { userId } = selectUser(getState())
    const pendingConnectionProvider = selectPendingConnectionProvider(getState())
    console.log(
      "checkConnectIntegrationStatus - pendingConnectionProvider: ",
      pendingConnectionProvider,
    )
    try {
      if (pendingConnectionProvider) {
        const { status, integrationSource, formAttributeGroups } =
          await service.checkProviderStatus(
            userId,
            pendingConnectionProvider as IntegrationConnectedProvider,
          )
        dispatch(checkIntegrationStatusSuccess(loginId))
        dispatch(getLoginAction(loginId, integrationSource, status, formAttributeGroups))
      }
    } catch (error) {
      dispatch(
        handleCaughtIntegrationError(
          error,
          "integrations.newConnection.checkConnectIntegrationStatus",
          {
            loginId,
          },
        ),
      )
    }
  }
}

function checkIntegrationStatusStart() {
  return {
    type: CHECK_STATUS_START,
  }
}

function checkIntegrationStatusSuccess(loginId: string) {
  return {
    type: CHECK_STATUS_SUCCESS,
    payload: loginId,
  }
}

export function getLoginAction(
  loginId: Id,
  integrationSource: IntegrationSource,
  integrationLoginStatus?: IntegrationLoginStatus,
  formAttributeGroups?: IntegrationRecipeGroup[],
) {
  return (dispatch) => {
    switch (integrationLoginStatus) {
      case IntegrationLoginStatus.OK: {
        console.warn("IntegrationLoginStatus.OK")
        return dispatch(getLoginAccountsList())
      }
      case IntegrationLoginStatus.MFA: {
        console.warn("IntegrationLoginStatus.MFA")
        return dispatch(showConnectBankMFAForm(formAttributeGroups))
      }
      case IntegrationLoginStatus.CHECK_LATER: {
        console.warn("IntegrationLoginStatus.CHECK_LATER")
        // another check after 5 seconds
        const pollingTimeout = getIntegrationSourcePollingTimeout(integrationSource)
        return setTimeout(() => dispatch(checkConnectIntegrationStatus(loginId)), pollingTimeout)
      }
      case IntegrationLoginStatus.ERROR: {
        console.warn("IntegrationLoginStatus.ERROR")
        return dispatch(connectionGeneralError())
      }
      case IntegrationLoginStatus.INVALID_CREDENTIALS: {
        console.warn("IntegrationLoginStatus.INVALID_CREDENTIALS")
        return dispatch(connectionInvalidCredentialsError())
      }
      default: {
        console.warn("IntegrationLoginStatus.CHECK_LATER", "immediately")
        return dispatch(checkConnectIntegrationStatus(loginId))
      }
    }
  }
}

export function createPendingConnection(
  provider: IntegrationProvider,
  integrationLoginResponse?: IntegrationLoginResponse,
) {
  return (dispatch: Function) => {
    const { loginId, integrationSource } = integrationLoginResponse
    const pendingConnection = {
      ...provider,
      id: loginId,
    }

    setIntegrationProviderCode(provider.code)
    dispatch(createPendingConnectionStart(pendingConnection))
    dispatch(
      getLoginAction(
        loginId,
        integrationSource,
        integrationLoginResponse.status,
        integrationLoginResponse.formAttributeGroups,
      ),
    )
  }
}

const overwriteNewConnection =
  (newConnection: NewConnectionState) => (dispatch: Function, getState: GetState) => {
    return Promise.resolve(
      dispatch({
        type: OVERRIDE_NEW_CONNECTION,
        payload: newConnection,
      }),
    )
  }

function createPendingConnectionStart(provider: IntegrationProvider) {
  return {
    type: CREATE_CONNECTION_START,
    payload: provider,
  }
}

function createPendingConnectionSuccess() {
  return {
    type: CREATE_CONNECTION_SUCCESS,
  }
}

export function createAlreadyConnectedProvider(loginId: string) {
  return async (dispatch: Function, getState: GetState) => {
    const providers = selectAlreadyConnectedProviders(getState())

    const selectedProvider = providers.find((provider) => provider.loginId === loginId)

    const pendingConnection = {
      ...selectedProvider,
      id: loginId,
    }

    dispatch(createPendingConnectionStart(pendingConnection))
    dispatch(getLoginAccountsList())
  }
}

export async function getConnectedProviders(userId: string, loginId: string, dispatch: Function) {
  let provider: IntegrationProviders.IntegrationConnectedProvider
  //const { userId } = selectUser(getState());
  try {
    const [integrations] = await Promise.all([fetchExistingConnectedIntegrations(userId)])
    provider = integrations.find((integration) => integration.loginId === loginId)
  } catch (error) {
    if (isAuthorizationError(error)) {
      dispatch(expireUser())
      return
    } else {
      logger.captureException(error, "integrations.providersList.fetchExistingIntegrations")
      dispatch(showError([DEFAULT_ERROR_MESSAGE_ID, "app.error.we_are_fixing_suffix"]))
    }
  }
  return provider
}

/**
 * Used when user is redirected back from BE(Integration provider), when connecting to bank account
 *
 * - It loads newConnection data from locale storage, which was stored before  user was redirtected to Integration provider
 *
 * - It hadles different cases of bank connection - manual connection, new account connection, reconnect
 */
export const handleProviderConnection =
  (loginId: string) => async (dispatch: Function, getState: GetState) => {
    try {
      // States from store
      const { userId } = selectUser(getState())
      const alreadyConnectedProviders = selectAlreadyConnectedProviders(getState())

      // Derived values
      const selectedProvider = alreadyConnectedProviders.find(
        (provider) => provider.loginId === loginId,
      )

      // States from locale storage - used to presist state when redirected to integrator(Saltedge)
      const newConnection = getNewConnectionFromLocaleStorage()
      const connectionType = getConnectionTypeFromLocaleStorage()
      // Create new connection using already connected/existing provider(no redirect to integrator)
      if (selectedProvider) return dispatch(createAlreadyConnectedProvider(loginId))

      // Reconnect disconnected account - expired/invalid consent
      if (connectionType === ConnectionType.RECONNECT) {
        // Retrieved from BE not from store
        const alreadyIntegratedProviders = await fetchExistingConnectedIntegrations(userId)
        // set global state pendingReconnect to true
        const currentConnectionProvider: IntegrationConnectedProvider =
          alreadyIntegratedProviders.find((provider) => provider.loginId === loginId)
        return dispatch(setPendingReconnectProvider(currentConnectionProvider.code))
      }

      // Create manual connection to existing(manually disconnected) account
      if (connectionType === ConnectionType.MANUAL_CONNECTION) {
        // Set state(newConnection) from locale storage to store
        newConnection.pendingConnectionInProgress = true
        newConnection.modalOpen = false
        const overwriteNewConnectionWrapper = overwriteNewConnection({
          ...newConnection,
          integrationProvider: { ...newConnection.integrationProvider, id: loginId },
        })

        dispatch(await overwriteNewConnectionWrapper)
      }

      // Create new connection without existing provider
      if (connectionType == ConnectionType.NEW_ACCOUNT_CONNECTION) {
        const provider = newConnection.integrationProvider as IntegrationProvider
        if (provider) {
          const pendingConnection = {
            ...provider,
            id: loginId,
          }
          dispatch(createPendingConnectionStart(pendingConnection))
          // dispatch(openIntegrationsForm())
        } else {
          throw "Error while loading integration provider from local storage"
        }
      }

      const handleNewConnectionSuccess = () => {
        dispatch(getLoginAccountsList())
      }

      const handleNewConnectionFailure = (error) => {
        dispatch(
          handleCaughtIntegrationError(
            error,
            "integrations.newConnection.checkConnectIntegrationStatus",
            {
              loginId,
            },
          ),
        )
      }

      const pendingConnectionProvider = selectPendingConnectionProvider(getState())
      // provider.integrationSource is not 100% reliable source, it is missing sometimes
      const integrationSourceName = getIntegrationSourceNameFromLocaleStorage()
      pendingConnectionProvider.integrationSource =
        getIntegrationSourceEnumFromIntegrationSourceName(integrationSourceName)
      dispatch(
        handleBackendProviderDigest(
          {
            loginId,
            userId,
            provider: pendingConnectionProvider as IntegrationProvider,
          },
          handleNewConnectionSuccess,
          handleNewConnectionFailure,
        ),
      )
    } catch (error) {
      dispatch(
        handleCaughtIntegrationError(
          error,
          "integrations.newConnection.submitProviderLogin",
          { loginId },
          () => {
            dispatch(
              setConnectionError(
                "integrations.error.newConnection.general",
                NewIntegrationsStatus.PROVIDER_SELECT,
              ),
            )
          },
        ),
      )
    } finally {
      removeNewConnectionDataFromLocaleStorage()
    }
  }

/**
 * This function checks if IntegrationStatus on backend is OK/CHECK_LATER/ERROR
 *
 * - If IntegrationLoginStatus.OK - dispatch(getLoginAccountsList())
 * - If IntegrationLoginStatus.CHECK_LATER - call itself after pollingTimeout(specific timeout for each integrator)
 * - If IntegrationLoginStatus.ERROR - Throw new specific error
 */
function handleBackendProviderDigest(
  configuration: {
    loginId: string
    userId: string
    provider: IntegrationProvider
  },
  successCallback: () => void,
  failureCallback: (error: any) => void,
) {
  const { loginId, userId, provider } = configuration
  return async (dispatch: Function, getState: GetState) => {
    const PROVIDER_STATUS_API_TIMEOUT = 60000 // 10 mins
    const PROVIDER_STATUS_API_FETCH_INTERVAL = getIntegrationSourcePollingTimeout(
      provider.integrationSource,
    )

    const clearTimeEffects = (shouldThrowError: boolean = false) => {
      clearInterval(statusInterval)
      clearTimeout(providerStatusApiTimeout)
      if (shouldThrowError) throw "IntegrationLoginStatus return error"
    }

    // call status API recursively in interval
    const statusInterval = setInterval(async () => {
      try {
        const { status: integrationStatus } = await service.checkProviderStatus(userId, provider)

        switch (integrationStatus) {
          // if success clearInterval, clearTimeout, set success data
          case IntegrationLoginStatus.OK: {
            clearTimeEffects()

            // final step - display remote accounts list to connect - common for manual connect to existing account and new fresh account
            successCallback()

            break
          }
          // if error clearInterval, set error
          case IntegrationLoginStatus.ERROR: {
            clearTimeEffects(true)
          }
          // if pending, do nothing continue interval
          case IntegrationLoginStatus.CHECK_LATER: {
            console.warn(
              "Recursively calling integrationStatus API:",
              "IntegrationLoginStatus.CHECK_LATER",
            )
            break
          }
        }
      } catch (error) {
        failureCallback(error)
      }
    }, PROVIDER_STATUS_API_FETCH_INTERVAL)

    // set timeout for API calls
    // when time is up, clear interval, set error
    const providerStatusApiTimeout = setTimeout(() => {
      try {
        clearTimeEffects(true)
      } catch (error) {
        dispatch(setPendingReconnectEnd())
        dispatch(
          handleCaughtIntegrationError(
            error,
            "integrations.newConnection.checkConnectIntegrationStatus",
            {
              loginId,
            },
          ),
        )
      }
    }, PROVIDER_STATUS_API_TIMEOUT)
  }
}

function getLoginAccountsList() {
  return async (dispatch: Function, getState: GetState) => {
    dispatch(createPendingConnectionSuccess())
    const { userId } = selectUser(getState())
    const reconnectLoginId = selectReconnectLoginId(getState())
    const pendingConnectionProvider = selectPendingConnectionProvider(getState())

    try {
      if (!reconnectLoginId) {
        const accounts = await service.fetchIntegrationAccounts(userId, pendingConnectionProvider)

        trackIntegrationsAccountsShow()
        dispatch(getLoginAccountsListSuccess(accounts))
        dispatch(openIntegrationsForm())
      } else {
        trackIntegrationsReconnectSuccess()
        dispatch(setPendingReconnectEnd())
        dispatch(clearAndCloseForm())
      }
    } catch (error) {
      dispatch(
        handleCaughtIntegrationError(
          error,
          "integrations.newConnection.getLoginAccountsList",
          { pendingConnectionProvider },
          () =>
            dispatch(
              setConnectionError(
                "integrations.error.no-accounts",
                NewIntegrationsStatus.PROVIDER_SELECT,
              ),
            ),
        ),
      )
    }
  }
}

function getLoginAccountsListSuccess(accounts) {
  return {
    type: GET_ACCOUNTS_SUCCESS,
    payload: accounts,
  }
}

export function createIntegratedAccounts(remoteAccountIds: Id[]) {
  return async (dispatch: Function, getState: GetState) => {
    dispatch(createIntegratedAccountsStart())
    const { userId } = selectUser(getState())
    const pendingConnectionProvider = selectPendingConnectionProvider(getState())
    const pendingIntegrationAccounts = selectPendingConnectionRemoteAccounts(getState()).reduce(
      reduceBy("id"),
      {},
    )
    const connectionAccount = selectConnectionAccount(getState())
    try {
      // connection to existing account
      if (connectionAccount) {
        const [remoteAccountId] = remoteAccountIds
        await service.connectIntegrationAccount(
          userId,
          pendingConnectionProvider,
          remoteAccountId,
          connectionAccount._id,
        )
      } else {
        // create new accounts
        const accounts = remoteAccountIds.map(
          (accountId: Id) => pendingIntegrationAccounts[accountId],
        )
        await service.createIntegrationAccounts(userId, pendingConnectionProvider, accounts)
      }

      if (selectOnBoardingShow(getState())) {
        dispatch(clearAndCloseForm())
        dispatch(gotoFinishStage())
      } else {
        dispatch(clearAndCloseForm())

        const modalParameters: [string, HashMap<any>] = isUndefinedOrNull(
          pendingConnectionProvider.maxTransactionHistoryInDays,
        )
          ? ["integrations.success.modal", undefined]
          : [
              "integrations.success.modal-with-days",
              { daysCount: pendingConnectionProvider.maxTransactionHistoryInDays },
            ]
        dispatch(openGoodJobModal(...modalParameters))
      }

      dispatch(setAllAccountsFilter())

      trackIntegrationsSuccess()
    } catch (error) {
      dispatch(clearAndCloseForm())
      dispatch(createIntegratedAccountsError())
      dispatch(
        handleCaughtIntegrationError(error, "integrations.newConnection.createIntegratedAccounts"),
      )
    }
  }
}

function createIntegratedAccountsStart() {
  return {
    type: CREATE_ACCOUNTS_START,
  }
}

function createIntegratedAccountsError() {
  return {
    type: CREATE_ACCOUNTS_ERROR,
  }
}

function showConnectBankMFAForm(formAttributeGroups: IntegrationRecipeGroup[]) {
  return (dispatch: Function, getState: GetState) => {
    dispatch(openIntegrationsForm())
    //saveNewConnetionToLocalStorage(getState())
    sendPushNotification("integrations.connections.mfa")
    return dispatch({
      type: CONNECTION_SHOW_MFA,
      payload: formAttributeGroups,
    })
  }
}

function hideConnectBankMFAForm() {
  return {
    type: CONNECTION_HIDE_MFA,
  }
}

export function sendMFAForm(
  formData: FormAttributeFields,
  inputType?: IP.IntegrationRecipe.InputType,
  id?: string,
) {
  return async (dispatch: Function, getState: GetState) => {
    const { userId } = selectUser(getState())
    const pendingConnectionProvider = selectPendingConnectionProvider(getState())
    setIntegrationProviderCode(pendingConnectionProvider.id)
    trackIntegrationsMFASent()
    try {
      if (formData.queryParams) {
        const { params } = selectNewConnectionMFA(getState())?.[0]
        const OAuthParam = params.find((param) => param.type === InputType.OAUTH_REDIRECT_URL)
        const { integrationSource, status, formAttributeGroups } = await service.finishOauth(
          userId,
          pendingConnectionProvider,
          OAuthParam.loginId,
          formData.queryParams.value as string,
        )

        dispatch(hideConnectBankMFAForm())

        dispatch(
          getLoginAction(
            pendingConnectionProvider.id,
            integrationSource,
            status,
            formAttributeGroups,
          ),
        )
      } else {
        console.log("sendMFA - pendingConnectionProvider:", pendingConnectionProvider)
        console.log("sendMFA - inputType:", inputType)
        console.log("sendMFA - id:", id)
        if (!formData["account_names"]) {
          const integrationLoginResponse = await service.sendMFA(
            userId,
            pendingConnectionProvider,
            formData,
            inputType,
            id,
          )

          dispatch(hideConnectBankMFAForm())
          dispatch(
            getLoginAction(
              pendingConnectionProvider.id,
              integrationLoginResponse.integrationSource,
              integrationLoginResponse.status,
              integrationLoginResponse.formAttributeGroups,
            ),
          )
        }
      }
    } catch (error) {
      console.error(error)
      dispatch(
        handleCaughtIntegrationError(error, "integrations.newConnection.sendMFAForm", {
          pendingConnectionProvider,
        }),
      )
    }
  }
}

export function openIntegrationsForm(accountId?: Id) {
  return {
    type: INTEGRATIONS_FORM_OPEN,
    payload: accountId,
  }
}

export function closeIntegrationsForm() {
  return (dispatch: Function, getState: GetState) => {
    const integrationStatus = selectIntegrationStatus(getState())
    const hasError = !!selectIntegrationError(getState())
    if (integrationStatus === NewIntegrationsStatus.CREDENTIALS_FORM && !hasError) {
      dispatch(clearProviderSelection())
    }

    if (hasError) {
      dispatch(clearConnectionError())
    }

    return dispatch({
      type: INTEGRATIONS_FORM_CLOSE,
    })
  }
}

export function setConnectionError(errorMessage: string, integrationStatus: NewIntegrationsStatus) {
  return {
    type: SET_ERROR,
    payload: {
      errorMessage,
      integrationStatus,
    },
  }
}

export function clearConnectionError(): Action {
  return {
    type: CLEAR_ERROR,
  }
}

export function connectionGeneralError() {
  trackIntegrationsError("General Error")
  return setConnectionError(
    "integrations.error.newConnection.general",
    NewIntegrationsStatus.CREDENTIALS_FORM,
  )
}

export function connectionRestrictedError() {
  trackIntegrationsError("Provider Restricted Error")
  return setConnectionError(
    "integrations.error.newConnection.restricted-provider",
    NewIntegrationsStatus.PROVIDER_SELECT,
  )
}

export function connectionNoAccountsError() {
  trackIntegrationsError("No Accounts Error")
  return setConnectionError(
    "integrations.error.no-accounts",
    NewIntegrationsStatus.INTEGRATED_ACCOUNT_SELECT,
  )
}

export function connectionInvalidCredentialsError() {
  trackIntegrationsError("Invalid Credentials Error")
  return setConnectionError(
    "integrations.error.invalid-credentials",
    NewIntegrationsStatus.CREDENTIALS_FORM,
  )
}

export function submitProviderLogin(formFields: FormAttributeFields) {
  return async (dispatch: Function, getState: GetState) => {
    dispatch(submitProviderLoginStart())
    const reconnectLoginId = selectReconnectLoginId(getState())
    const provider = selectProviderDetail(getState())
    const { userId } = selectUser(getState())
    try {
      if (reconnectLoginId) {
        trackIntegrationsReconnectConnectionCredentialsSubmit()
        const loginResponse = await service.reconnectProvider(
          userId,
          provider,
          formFields,
          reconnectLoginId,
        )
        setloginResponse(loginResponse)
        dispatch(createPendingConnection(provider, loginResponse))
        dispatch(submitProviderLoginSuccess())
      } else {
        trackIntegrationsNewConnectionCredentialsSubmit()
        const loginResponse = await service.loginToProvider(userId, provider, formFields)
        setloginResponse(loginResponse)
        dispatch(createPendingConnection(provider, loginResponse))
        dispatch(submitProviderLoginSuccess())
      }
    } catch (error) {
      dispatch(
        handleCaughtIntegrationError(
          error,
          "integrations.newConnection.submitProviderLogin",
          { provider },
          () =>
            dispatch(
              setConnectionError(
                "integrations.error.newConnection.general",
                NewIntegrationsStatus.PROVIDER_SELECT,
              ),
            ),
        ),
      )
    }
  }
}

export function createOAuthLogin(OAuthCallback: {
  loginId: string
  success: boolean
  queryParams?: string
}) {
  return async (dispatch: Function, getState: GetState) => {
    dispatch(submitProviderLoginStart())

    const provider = selectProviderDetail(getState())
    const user = selectUser(getState())
    const { loginId, success, queryParams } = OAuthCallback

    if (selectReconnectLoginId(getState())) {
      trackIntegrationsReconnectOAuthGranted()
    } else {
      trackIntegrationsNewConnectionOAuthGranted()
    }

    try {
      if (queryParams) {
        const { params } = provider.formAttributeGroups[0]
        const OAuthParam = params.find((param) => param.type === InputType.OAUTH_REDIRECT_URL)
        const integrationResponse = await service.finishOauth(
          user.userId,
          provider,
          OAuthParam.loginId,
          queryParams,
        )
        setloginResponse(integrationResponse)
        dispatch(createPendingConnection(provider, integrationResponse))
      } else if (success) {
        const integrationResponse = { loginId } as IntegrationLoginResponse
        setloginResponse(integrationResponse)
        dispatch(createPendingConnection(provider, integrationResponse))
      } else {
        dispatch(connectionGeneralError())
      }

      dispatch(submitProviderLoginSuccess())
    } catch (error) {
      console.error(error)
      dispatch(
        handleCaughtIntegrationError(error, "integrations.newConnection.createOAuthLogin", {
          OAuthCallback,
          provider,
        }),
      )
    }
  }
}

function submitProviderLoginSuccess() {
  return {
    type: LOGIN_PROVIDER_SUCCESS,
  }
}

function submitProviderLoginStart() {
  return {
    type: LOGIN_PROVIDER_START,
  }
}

export function cancelPendingConnection() {
  return (dispatch: Function, getState: GetState) => {
    const { userId } = selectUser(getState())
    const pendingConnectionProvider = selectPendingConnectionProvider(getState())
    const integrationStatus = selectIntegrationStatus(getState())
    const connectedRemoteAccounts = selectPendingConnectionRemoteAccounts(getState())

    const hasConnectedAccounts =
      connectedRemoteAccounts &&
      connectedRemoteAccounts.some((remoteAccount) => {
        return !!remoteAccount.connectedAppAccountId
      })

    trackIntegrationsCancel()

    if (
      integrationStatus === NewIntegrationsStatus.INTEGRATED_ACCOUNT_SELECT &&
      !hasConnectedAccounts
    ) {
      try {
        service.deactivateIntegrationConnection(
          userId,
          pendingConnectionProvider as IntegrationConnectedProvider,
        )
      } catch (error) {
        // don't propagate error, totally async call
      }
    }
    dispatch(clearAndCloseForm())
  }
}

function handleCaughtIntegrationError(
  error: Error | NoRemoteAccountsError,
  actionName: string,
  extraData?: any,
  callback?: Function,
) {
  return (dispatch: Function) => {
    if (isAuthorizationError(error)) {
      dispatch(expireUser())
      return
    } else if (isServerError(error)) {
      dispatch(connectionGeneralError())
    } else if (isServiceUnavailableError(error)) {
      dispatch(fetchRestrictedProviders())
      dispatch(connectionRestrictedError())
    } else if (error instanceof NoRemoteAccountsError) {
      dispatch(getLoginAccountsListSuccess(null))
      dispatch(connectionNoAccountsError())
    } else {
      logger.captureException(error, actionName, extraData)
      dispatch(showError([DEFAULT_ERROR_MESSAGE_ID, "app.error.we_are_fixing_suffix"]))
      callback && callback()
    }
  }
}

export function showPersonalDetailsForm(): Action<VerifiedProfile> {
  trackPersonalDetailsShow()
  const formValues = isAppBoard()
    ? {
        fullName: "",
        streetAddress: "",
        companyId: "",
        companyName: "",
      }
    : {
        fullName: "",
        streetAddress: "",
        birthday: moment.utc().startOf(UNIT_DAY).valueOf(),
      }

  return {
    type: PERSONAL_INFORMATION_SHOW,
    payload: formValues,
  }
}

export function changePersonalFormValue(
  name: string,
  value: string | number,
): Action<HashMap<string | number>> {
  return {
    type: PERSONAL_INFORMATION_CHANGE_VALUE,
    payload: {
      [name]: value,
    },
  }
}

export function savePersonalDetails() {
  return async (dispatch: Function, getState: GetState) => {
    dispatch(savePersonalDetailsStart())
    try {
      const formValues = selectPersonalDetailsFormValues(getState())
      await service.savePersonalDetails(formValues)

      // hotfix for CNB personal data for KB
      if (
        selectOnBoardingShow(getState()) &&
        selectOnBoardingStage(getState()) === OnBoardingStage.KB_BANK_INTEGRATION
      ) {
        await dispatch(fetchKBProviderDetails())
      } else {
        await dispatch(fetchProviderDetails())
      }
      trackPersonalDetailsSuccess()
      dispatch(savePersonalDetailsSuccess())
    } catch (error) {
      console.error(error)
    }
  }
}

function savePersonalDetailsStart() {
  return {
    type: PERSONAL_INFORMATION_SAVE_START,
  }
}

function savePersonalDetailsSuccess() {
  return {
    type: PERSONAL_INFORMATION_SAVE_SUCCESS,
  }
}
