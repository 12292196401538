import React from "react"
import { AccountIcon } from "src/frontend/components/Icons/Icons"
import { AccountWithBalance } from "src/frontend/scenes/dashboard/types"
import styles from "./BasicAccountListItem.module.less"
import AccountLastUpdate from "src/frontend/scenes/accounts/accountDetail/components/AccountLastUpdate"
import FormattedMoney from "src/frontend/modules/intl/components/FormattedMoney"
import { Icon } from "semantic-ui-react"
import { Link } from "react-router-dom"
import { isConnected } from "src/backend/accounts/helpers"
import FormattedMessage from "src/frontend/modules/intl/components/FormattedMessage"
import { ComplementaryButton } from "src/frontend/components/Buttons/Buttons"
import { isUndefinedOrNull } from "src/common/utils"

interface Props {
  account: AccountWithBalance
}

export default function BasicAccountListItem({ account }: Props) {
  return (
    <div className={styles.item}>
      <div className={styles.icon}>
        <AccountIcon
          accountType={account.accountType}
          color={account.color}
          size="36px"
        />
      </div>
      <div>
        <div className={styles.name}>{account.name}</div>
        <div className={styles.description}>
          {isConnected(account) ? (
            <AccountLastUpdate account={account} />
          ) : (
            <FormattedMessage id={`account.type.${account.accountType}`} />
          )}
        </div>
      </div>
      <div className={styles.balance}>
        {!isUndefinedOrNull(account.balance) && (
          <FormattedMoney
            value={account.balance}
            currency={account.currencyCode}
          />
        )}
      </div>
      <div className={styles.action}>
        <ComplementaryButton
          icon
          as={Link}
          to={`/${account?.investmentInfo ? "investments" : "accounts"}/detail/${account._id}`}
        >
          <Icon name="chevron right" />
        </ComplementaryButton>
      </div>
    </div>
  )
}
