import React from "react"
import { SecondaryButton } from "src/frontend/components/Buttons/Buttons"
import { Link } from "react-router-dom"
import {
  getPaymentMethodImage,
  getPaymentMethodString,
} from "src/frontend/scenes/settings/billing/helpers"
import styles from "src/frontend/scenes/settings/billing/Billing.module.less"
import { SettingsItem } from "src/frontend/scenes/settings/billing/BillingItem"
import { isUndefinedOrNull } from "src/common/utils"
import {
  BraintreeSubscription,
  BraintreeSubscriptionStatus,
} from "src/frontend/scenes/billing/activeSubscription/types"
import { BraintreePaymentMethod, Method } from "src/frontend/scenes/billing/paymentMethod/types"
import { formatMessage, FormattedMessage } from "src/frontend/modules/intl"

interface Props {
  paymentMethod: BraintreePaymentMethod
  activeSubscription: BraintreeSubscription
}

export default function PaymentMethod({ paymentMethod, activeSubscription }: Props) {
  return !isUndefinedOrNull(paymentMethod) ? (
    <SettingsItem
      title={<FormattedMessage id="billing.payment-method" />}
      iconSize="30px"
      error={getPaymentMethodError(paymentMethod, activeSubscription)}
      action={
        <SecondaryButton
          as={Link}
          to="/settings/billing/update-payment-method"
        >
          <FormattedMessage id="form.update" />
        </SecondaryButton>
      }
      icon={getPaymentMethodImage(paymentMethod)}
    >
      <div>
        <div className={styles.bold}>{getPaymentMethodString(paymentMethod)}</div>
        {paymentMethod.type === Method.CREDIT_CARD && (
          <div className={styles.description}>
            <FormattedMessage
              id="billing.payment-method.expires-on"
              values={{ expirationDate: paymentMethod.expirationDate }}
            />
          </div>
        )}
      </div>
    </SettingsItem>
  ) : null
}

function getPaymentMethodError(
  paymentMethod: BraintreePaymentMethod,
  subscription: BraintreeSubscription,
) {
  if (paymentMethod.type === Method.CREDIT_CARD && paymentMethod.isExpired) {
    return formatMessage("billing.payment-method.card-expired")
  }

  if (subscription.status === BraintreeSubscriptionStatus.PAST_DUE) {
    return formatMessage("billing.subscription.past-due")
  }
}
