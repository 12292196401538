import { isUndefinedOrNull } from 'src/common/utils'
import { Label } from 'src/types/Label'
import { DataDoc } from 'src/types/CouchDb'
import { isSystemLabel } from 'src/backend/labels/helpers'

export function labelConverter() {
  return (dataDoc: Label | DataDoc<Label>): Label => {
    const labelDoc: Label = (dataDoc as DataDoc<Label>).doc || dataDoc as Label

    let { archived, autoAssign, name } = labelDoc

    if (isSystemLabel(labelDoc)) {
      return
    }

    if (isUndefinedOrNull(archived)) {
      archived = false
    }

    if (isUndefinedOrNull(autoAssign)) {
      autoAssign = false
    }

    if (isUndefinedOrNull(name)) {
      name = ''
    }

    return {
      ...labelDoc,
      archived,
      autoAssign,
      name,
    }
  }
}
