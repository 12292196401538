import {
  CLOSE_CONFIRM_MODAL,
  CLOSE_CONSENT_MODAL,
  GRANT_CONSENT,
  OPEN_CONSENT_MODAL,
} from 'src/frontend/scenes/gdpr/actions'

const defaultState = {
  gdprConsentModalOpen: false,
  gdprConfirmModalOpen: false,
  termsOfServicesHtml: null,
}

export default function (state = defaultState, action) {
  switch (action.type) {
    case GRANT_CONSENT: {
      return { ...defaultState }
    }

    case OPEN_CONSENT_MODAL: {
      // TODO improvement: load ToS during app initialization to avoid delay between spinner end and opening the modal
      return { ...state, gdprConsentModalOpen: true, termsOfServicesHtml: action.payload.termsOfServicesHtml }
    }

    case CLOSE_CONSENT_MODAL: {
      return { ...state, gdprConsentModalOpen: false, gdprConfirmModalOpen: true }
    }

    case CLOSE_CONFIRM_MODAL: {
      return { ...state, gdprConfirmModalOpen: false }
    }

    default:
      return state
  }
}
