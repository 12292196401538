import React, { useCallback, useMemo } from "react"
import { connect } from "react-redux"
import PremiumChecker from "src/frontend/components/PremiumChecker/PremiumChecker"
import { FormattedMessage } from "src/frontend/modules/intl"
import FormattedMoney from "src/frontend/modules/intl/components/FormattedMoney"
import {
  changeFilter as changeFilterAction,
  setAllAccountsFilter,
} from "src/frontend/scenes/dashboard/actions"
import SelectAllAccounts from "src/frontend/scenes/dashboard/components/SelectAllAccounts"
import {
  selectAccountsWithBalance,
  selectDashboardFilter,
} from "src/frontend/scenes/dashboard/selectors"
import { AccountWithBalance } from "src/frontend/scenes/dashboard/types"
import { openAccountForm } from "src/frontend/scenes/settings/accounts/actions"
import { Icon } from "semantic-ui-react"
import * as selectors from "src/frontend/scenes/settings/accounts/selectors"
import { FilterType } from "src/types/Filter"
import { RootState } from "src/types/State"
import styles from "src/frontend/scenes/dashboard/components/AccountsGrid.module.less"
import { FormType } from "src/frontend/scenes/settings/enums"
import { isNull, mergeClasses } from "src/common/utils"
import { AccountIcon } from "src/frontend/components/Icons/Icons"
import { selectIsPendingConnectionInProgress } from "src/frontend/scenes/integrations/newConnection/selectors"
import NewBankConnectionMessage from "src/frontend/scenes/integrations/newConnection/components/NewBankConnectionInfoMessage"
import TruncatedText from "src/frontend/components/TruncatedText"
import { isPendingReconnect } from "../../integrations/connections/selectors"

function mapStateToProps(state: RootState) {
  return {
    accounts: selectAccountsWithBalance(state),
    filter: selectDashboardFilter(state),
    canAddAccount: selectors.selectCanAddAccount(state),
    isPendingConnection: selectIsPendingConnectionInProgress(state),
    isPendingReconnect: isPendingReconnect(state),
  }
}

const mapDispatchToProps = {
  handleOpenAddForm: () => openAccountForm(FormType.ADD),
  handleOpenEditForm: (account) => openAccountForm(FormType.EDIT, account),
  handleChangeAccountsFilter: (accountIds) => changeFilterAction({ accountIds }),
  handleSelectAllAccounts: () => setAllAccountsFilter(),
}

type Props = {
  accounts: Array<AccountWithBalance>
  filter: FilterType
  canAddAccount: boolean
  isPendingConnection: boolean
  isPendingReconnect: boolean
  handleOpenAddForm: (e: React.SyntheticEvent) => void
  handleOpenEditForm: (e: React.SyntheticEvent) => void
  handleChangeAccountsFilter: Function
  handleSelectAllAccounts: (e: React.SyntheticEvent) => void
}

function AccountsGrid({
  accounts,
  filter,
  canAddAccount,
  handleOpenAddForm,
  handleSelectAllAccounts,
  isPendingConnection,
  isPendingReconnect,
  handleOpenEditForm,
  handleChangeAccountsFilter,
}: Props) {
  const { accountIds } = filter

  const areAllAccountsSelected = useMemo(
    () =>
      !!accountIds &&
      accounts.every((account) => {
        return account.excludeFromStats !== accountIds.includes(account._id)
      }),
    [accountIds, accounts],
  )

  const handleSelectAccount = (accountId) => (): void => {
    // select only account
    return handleChangeAccountsFilter([accountId])
  }

  const handleOpenEditFormClick = useCallback((e, account) => {
    e.stopPropagation()
    handleOpenEditForm(account)
  }, [])

  const accountsIcons = useMemo(
    () =>
      accounts.map((account) => {
        const isSelected = accountIds && accountIds.includes(account._id)
        return (
          <div
            key={account._id}
            className={mergeClasses(styles.account, !isSelected && styles.dimmed)}
          >
            <div
              style={{ backgroundColor: account.color }}
              onClick={handleSelectAccount(account._id)}
            >
              {!account?.investmentInfo && (
                <Icon
                  name="pencil alternate"
                  onClick={(e) => handleOpenEditFormClick(e, account)}
                />
              )}
              <div className={styles.icon}>
                <AccountIcon
                  simple
                  accountType={account.accountType}
                  color="transparent"
                  size="26px"
                  isInvestments={!!account.investmentInfo}
                />
              </div>
              <div className={styles.content}>
                <div className={styles.name}>
                  {!isNull(account.balance) ? (
                    <span>
                      <TruncatedText
                        text={account.name}
                        length={28}
                      />
                    </span>
                  ) : (
                    <div className={styles.accountNamePlaceholder} />
                  )}
                </div>
                <div className={styles.balance}>
                  {!isNull(account.balance) ? (
                    <FormattedMoney
                      currency={account.currencyCode}
                      value={account.balance}
                    />
                  ) : (
                    <div className={styles.accountBalancePlaceholder} />
                  )}
                </div>
              </div>
            </div>
          </div>
        )
      }),
    [accounts, accountIds],
  )

  return (
    <div className={styles.dashboardAccountGrid}>
      {(isPendingConnection || isPendingReconnect) && (
        <div className={styles.accountConnecting}>
          <NewBankConnectionMessage />
        </div>
      )}
      <div
        className={styles.accountsContainer}
        tabIndex={-1}
      >
        {accountsIcons}
        <div className={mergeClasses(styles.account, styles.add)}>
          <PremiumChecker
            enabled={canAddAccount}
            featureName="premium_checker.unlimited_accounts"
          >
            <button
              type="button"
              onClick={handleOpenAddForm}
            >
              <div>
                ＋&nbsp;
                <FormattedMessage id="settings.accounts.form.add_account" />
              </div>
            </button>
          </PremiumChecker>
        </div>
      </div>
      <SelectAllAccounts
        filter={filter}
        accounts={accounts}
        areAllAccountsSelected={areAllAccountsSelected}
        onSelectAll={handleSelectAllAccounts}
      />
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountsGrid)
