import React from 'react'
import ContactList from 'src/frontend/scenes/contacts/contactList/components/ContactList'
import ColumnLayout from 'src/frontend/components/ColumnLayout/ColumnLayout'
import { FormattedMessage } from 'src/frontend/modules/intl'
import ContactFilter from 'src/frontend/scenes/contacts/filter/components/ContactFilter'
import { Dropdown } from 'semantic-ui-react'
import { ContactListOrderBy } from 'src/frontend/scenes/contacts/contactList/enums'
import { RootState } from 'src/types/State'
import { selectOrderBy } from 'src/frontend/scenes/contacts/contactList/selectors'
import * as actions from 'src/frontend/scenes/contacts/contactList/actions'
import { connect } from 'react-redux'
import styles from './Contacts.module.less'
import basicLayoutStyles from 'src/frontend/components/ColumnLayout/BasicLayout.module.less'
import { openAddContactForm } from 'src/frontend/scenes/contacts/contactForm/actions'

function mapStateToProps(state: RootState) {
  return {
    order: selectOrderBy(state),
  }
}

const mapDispatchToProps = {
  onSortContactList: actions.changeOrder,
  onOpenAddContactForm: openAddContactForm,
}

export const orderOptions = [
  {
    text: 'A-Z',
    value: ContactListOrderBy.NAME_ASC,
  },
  {
    text: 'Z-A',
    value: ContactListOrderBy.NAME_DESC,
  },
]

interface Props {
  order: ContactListOrderBy,
  onSortContactList: Function,
  onOpenAddContactForm: Function
}

function Contacts({ order, onSortContactList, onOpenAddContactForm }: Props) {
  return (
    <ColumnLayout
      title={<FormattedMessage id="menu.contacts" />}
      action
      onActionClick={() => onOpenAddContactForm()}
      topPanel={(
        <div className={styles.sort}>
          <span className={basicLayoutStyles.filterLabel}><FormattedMessage id="records.sort-by" /></span>
          <Dropdown
            selection
            value={order}
            options={orderOptions}
            onChange={(_e, { value }) => onSortContactList(value as ContactListOrderBy)}
          />
        </div>
      )}
      leftPanel={<ContactFilter />}
      content={<ContactList />}
    />
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(Contacts)
