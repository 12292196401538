import spected from 'spected'
import * as commonValidator from 'src/backend/common/validator'
import omit from 'lodash/omit'

export function validate(template, validationDependencies) {
  const {
    templates,
    currencies,
    categories,
    envelopes,
    accounts,
    paymentTypes,
  } = validationDependencies

  const requiredFields = [
    'name',
    'amount',
    'currencyId',
    'categoryId',
    'accountId',
    'paymentType',
    'recordState',
  ]

  const templateWithRequiredFields = {
    ...commonValidator.addRequiredFieldsAsUndefined(template, requiredFields),
  }

  const templateSpec = {
    amount: [
      [commonValidator.isNumber, 'settings.form.not_a_number'],
    ],
    name: [
      [commonValidator.isNotEmpty, 'form.error.enter_name'],
      [commonValidator.hasUniqueName(omit(templates, template._id)), 'form.error.name_exists'],
      [commonValidator.isNotLongerThan(commonValidator.NAME_MAX_LENGTH), 'form.error.name_too_long'],
    ],
    currencyId: [
      [commonValidator.isNotEmpty, 'record.form.select_currency'],
      [commonValidator.entityExists(currencies), 'record.form.currency_does_not_exist'],
    ],
    categoryId: [
      [commonValidator.isNotEmpty, 'record.form.select_category'],
      [commonValidator.categoryExists(categories, envelopes), 'record.form.category_does_not_exist'],
      [commonValidator.isNotUnknownCategory(categories), 'record.form.category_can_not_be_unknown'],
    ],
    accountId: [
      [commonValidator.isNotEmpty, 'record.form.select_account'],
      [commonValidator.entityExists(accounts), 'record.form.account_does_not_exist'],
    ],
    paymentType: [
      [commonValidator.isNotEmpty, 'record.form.select_payment_type'],
      [commonValidator.isNumber, 'record.form.payment_type_does_not_exist'],
      [commonValidator.valueExists(paymentTypes), 'record.form.payment_type_does_not_exist'],
    ],
  }

  try {
    const validationResult = spected(templateSpec, templateWithRequiredFields)
    return commonValidator.convertValidationResultToErrors(validationResult)
  } catch (error) {
    console.error(error)
    return { general: 'Validation error!' }
  }
}
