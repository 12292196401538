import {
  CANCEL_DELETE_ITEMS,
  CONFIRM_DELETE_ITEM,
  DELETE_ITEMS_DONE,
  DELETE_ITEMS_PROGRESS,
  GET_ITEMS_SUCCESS, IMPORT_ITEM_END, IMPORT_ITEM_START,
  IMPORTS_IMPORT_ITEM_CLICKED,
  ITEM_VISIBILITY_TOGGLE,
  SHOW_NEXT,
} from 'src/frontend/scenes/imports/items/actions'

const initialDeleteItemForm = {
  open: false,
  itemId: null,
  progress: false,
  itemTransactionId: null,
}

const IMPORT_ITEMS_PER_PAGE = 7

const initialState = {
  itemList: [],
  visibleImportedItems: IMPORT_ITEMS_PER_PAGE,
  toUploadItems: new Map(),
  groupedItemList: new Map(),
  // Tt may be good idea to add open, visible flag to groupedItemList instead of
  // keeping separated openItemIds visibleItemIds, but groupedItemList is updated/reloaded
  // and flags would be cleared.
  openItemIds: new Map(),
  visibleItemIds: new Map(),
  deleteItemForm: initialDeleteItemForm,
  importLoading: false,
}

export default (state = initialState, action) => {

  switch (action.type) {

    case GET_ITEMS_SUCCESS: {
      const { toUploadItems, itemList, groupedItemList } = action
      const openItemIds = new Map(state.openItemIds)
      // This one is called on imports init and when refreshed, keep openItemIds when refreshed
      // next state = initial state + data + preserve accordion state
      return { ...initialState, toUploadItems, itemList, groupedItemList, openItemIds }
    }

    case IMPORTS_IMPORT_ITEM_CLICKED: {
      const { itemId } = action
      const openItemIds = new Map(state.openItemIds)
      openItemIds.set(itemId, !openItemIds.getOrDefault(itemId, false))
      return { ...state, openItemIds }
    }

    case ITEM_VISIBILITY_TOGGLE: {
      const { itemId, importedItemsCount } = action
      const visibleItemIds = new Map(state.visibleItemIds)
      visibleItemIds.set(itemId, !visibleItemIds.getOrDefault(itemId, false))
      const visibleItemsUniqueValues = new Set(Array.from(visibleItemIds.values()))

      // if there are no 'true' flags then clear the filter so that all items respectively
      // all records for items are displayed
      if (visibleItemsUniqueValues.size === 1 && visibleItemsUniqueValues.has(false)) {
        visibleItemIds.clear()
      }

      // if there are only true values for all items then clear the Map
      // TODO better compare item ids vs visibleItemIds than size of containers
      if (visibleItemsUniqueValues.has(true) && importedItemsCount === visibleItemIds.size) {
        visibleItemIds.clear()
      }

      return { ...state, visibleItemIds }
    }

    case CONFIRM_DELETE_ITEM: {
      const { itemId, itemTransactionId } = action
      return { ...state, deleteItemForm: { open: true, progress: false, itemId, itemTransactionId } }
    }

    case DELETE_ITEMS_PROGRESS: {
      return { ...state, deleteItemForm: { ...state.deleteItemForm, progress: true } }
    }

    case DELETE_ITEMS_DONE:
    case CANCEL_DELETE_ITEMS: {
      return { ...state, deleteItemForm: { ...initialDeleteItemForm } }
    }

    case SHOW_NEXT:
      return { ...state, visibleImportedItems: state.visibleImportedItems + IMPORT_ITEMS_PER_PAGE }

    case IMPORT_ITEM_START: {
      return { ...state, importLoading: true }
    }

    case IMPORT_ITEM_END: {
      return { ...state, importLoading: false }
    }

    default: {
      return state
    }
  }
}
