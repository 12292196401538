import React, { useCallback, useEffect } from 'react'
import { RootState } from 'src/types/State'
import {
  selectIsDetailRecordsLoading,
  selectShouldRefreshDetailRecords,
} from 'src/frontend/modules/moduleStatus/selectors'
import { connect } from 'react-redux'
import ExtendedRecordList from 'src/frontend/components/RecordList/ExtendedRecordList'
import { Id } from 'src/types/CouchDb'
import { RecordListItemRecord } from 'src/frontend/components/RecordList/types'
import { expireDetailRecordsStatus, refreshDetailRecordsStatus } from 'src/frontend/modules/moduleStatus/actions'
import { Loader } from 'semantic-ui-react'
import { FilterType } from 'src/types/Filter'
import { selectAggregatedRecordListDetailRecords } from 'src/frontend/scenes/records/recordsDetail/selectors'
import AddButton from 'src/frontend/components/ColumnLayout/AddButton'
import { openAddRecordForm } from 'src/frontend/scenes/records/recordForm/actions'
import { RecordFormValues } from 'src/frontend/scenes/records/recordForm/types'
import _isEmpty from 'lodash/isEmpty'
import EmptyState from 'src/frontend/scenes/settings/components/EmptyState'
import FormattedMessage from 'src/frontend/modules/intl/components/FormattedMessage'

function mapStateToProps(state: RootState) {
  return {
    shouldRefreshRecords: selectShouldRefreshDetailRecords(state),
    records: selectAggregatedRecordListDetailRecords(state),
    loading: selectIsDetailRecordsLoading(state),
  }
}

const mapDispatchToProps = {
  fetchRecords: refreshDetailRecordsStatus,
  expireRecords: expireDetailRecordsStatus,
  openAddForm: openAddRecordForm,
}

interface Props {
  shouldRefreshRecords: boolean,
  contactId: Id
  loading: boolean
  records: RecordListItemRecord[]

  expireRecords: () => void
  fetchRecords: (filter: Partial<FilterType>) => void
  openAddForm: (predefinedValues: Partial<RecordFormValues>) => void
}

function ContactDetailRecordList({
  shouldRefreshRecords,
  contactId,
  loading,
  records,
  fetchRecords,
  expireRecords,
  openAddForm,
}: Props) {
  useEffect(() => {
    if (shouldRefreshRecords) {
      fetchRecords({ contactIds: [contactId] })
    }
  }, [shouldRefreshRecords, contactId])

  useEffect(() => {
    fetchRecords({ contactIds: [contactId] })
    return expireRecords
  }, [])

  const handleOpenForm = useCallback(() => openAddForm({ contactId }), [contactId])

  if (shouldRefreshRecords || loading) {
    return <Loader active inline="centered" />
  }


  return (
    <>
      {!_isEmpty(records)
        ? <ExtendedRecordList records={records} transparentSelect disablePeriodSums />
        : (
          <EmptyState
            title={<FormattedMessage id="contact.recordList.empty.title" />}
            text={<FormattedMessage id="contact.recordList.empty.text" />}
          />
        )}
      <AddButton
        onClick={handleOpenForm}
      />
    </>
  )
}


export default connect(mapStateToProps, mapDispatchToProps)(ContactDetailRecordList)
