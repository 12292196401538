import React from 'react'
import { Totals } from 'src/backend/analytics/types'
import AnimatedBarTable from 'src/frontend/components/AnimatedBarTable/AnimatedBarTable'
import { AnimatedBarTableData } from 'src/frontend/components/AnimatedBarTable/types'
import { FormattedMessage, FormattedMoney } from 'src/frontend/modules/intl/index'
import Widget from 'src/frontend/scenes/dashboard/components/Widget/Widget'
import { WidgetView } from 'src/frontend/scenes/dashboard/types'
import { Id } from 'src/types/CouchDb'
import { Currency } from 'src/types/Currency'
import { widgetHasDeepEqualProps } from 'src/frontend/scenes/dashboard/helpers'
import { Modal } from 'semantic-ui-react'
import { ComplementaryButton } from 'src/frontend/components/Buttons/Buttons'

type Props = {
  widget: WidgetView & { data: CategoriesData },
}

type CategoriesData = {
  spendingCategories: Array<Totals & { color: string, categoryId: Id }>,
  referentialCurrency: Currency,
  expenseSum: number,
  expenseChange: number,
}

const NUM_OF_CATEGORIES = 3

function SpendingByCategoriesBar({ widget }: Props) {
  const { data, filter, options } = widget

  if (!data || !filter) {
    return <Widget.Placeholder />
  }

  const widgetAction = data.spendingCategories.length > NUM_OF_CATEGORIES && (
    <Modal
      size="small"
      trigger={(
        <ComplementaryButton>
          <FormattedMessage id="show-more" />
        </ComplementaryButton>
      )}
    >
      <Modal.Content>
        <AnimatedBarTable
          total={data.spendingCategories[0].refExpense}
          data={convertCategoriesToBarTableData(data.spendingCategories, data.referentialCurrency)}
        />
      </Modal.Content>
    </Modal>
  )

  const sampleData = React.useMemo(() => data.spendingCategories.slice(0, NUM_OF_CATEGORIES), [data])

  const content = (data.spendingCategories.length > 0)
    ? (
      <Widget.Content>
        <AnimatedBarTable
          total={data.spendingCategories[0].refExpense}
          data={convertCategoriesToBarTableData(sampleData, data.referentialCurrency)}
        />
      </Widget.Content>
    )
    : <Widget.EmptyContent type="bar" />

  return (
    <Widget>
      <Widget.Header title={widget.title} action={widgetAction} />
      {data.expenseSum > 0
        ? (
          <Widget.Summary
            displayWholeDateRange
            period={filter.period}
            value={-data.expenseSum}
            currencyCode={data.referentialCurrency.code}
            invertedChange
            intervalType={options.relativeIntervalValue}
            changeValue={data.expenseChange}
          />
        )
        : null}
      {content}
    </Widget>
  )
}

function convertCategoriesToBarTableData(
  data: Array<Totals & { color: string, categoryId: Id }>, currency: Currency,
): Array<AnimatedBarTableData> {
  return data.map(category => {
    return {
      id: category.categoryId,
      values: { value: category.refExpense, color: category.color },
      valueChange: category.refExpenseChange,
      invertedChange: true, // bigger expenses are considered as wrong
      label: category.name,
      valueLabel: <FormattedMoney value={-category.refExpense} currency={currency.code} />,
    }
  })
}

export default React.memo(SpendingByCategoriesBar, widgetHasDeepEqualProps)
