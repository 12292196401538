import React from 'react'
import { connect } from 'react-redux'
import { Modal } from 'semantic-ui-react'
import { formatMessage } from 'src/frontend/modules/intl/i18n'
import * as selectors from '../selectors'
import * as actions from '../actions'
import './AccountForm.less'
import { FormType } from 'src/frontend/scenes/settings/enums'
import { RootState } from 'src/types/State'
import AccountFormContent from 'src/frontend/scenes/settings/accounts/components/AccountFormContent'
import { AccountFormType } from 'src/frontend/scenes/settings/accounts/enums'

const mapStateToProps = (state: RootState): StateProps => {
  const form = selectors.selectAccountsForm(state)

  return {
    formType: form.formType,
    formOpen: form.isOpen,
    accountFormType: form.accountFormType,
  }
}

const mapDispatchToProps = {
  handleCloseForm: actions.closeForm,
  setAccountFormType: actions.selectAccountFormType,
}

type Actions = {
  handleCloseForm: Function,
  setAccountFormType: (formType: AccountFormType) => void,
}

type StateProps = {
  formType: string,
  formOpen: boolean,
  accountFormType: AccountFormType,
}

type Props = {
  imports?: boolean,
}

AccountFormModal.defaultProps = {
  imports: false,
}

function AccountFormModal({
  formType,
  formOpen,
  accountFormType,
  setAccountFormType,
  handleCloseForm,
}: Props & StateProps & Actions) {
  const formName = formatMessage(formType === FormType.ADD
    ? 'settings.accounts.form.add_account'
    : 'settings.accounts.form.edit_account')

  return (
    <Modal
      size={accountFormType === AccountFormType.SELECTION ? "large" : "small"}
      open={formOpen}
      onClose={() => {
        setAccountFormType(AccountFormType.SELECTION)
        handleCloseForm()
      }}
    >
      <Modal.Header>
        {formName}
        <span
          className="modal-close"
          onClick={() => {
            setAccountFormType(AccountFormType.SELECTION)
            handleCloseForm()
          }}
        />
      </Modal.Header>
      <Modal.Content>
        <AccountFormContent
          accountFormType={accountFormType}
          onAccountTypeChange={setAccountFormType}
        />
      </Modal.Content>
    </Modal>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountFormModal)
