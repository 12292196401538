import { BaseError } from 'make-error'
import { IntegrationErrorType } from 'src/frontend/scenes/integrations/connections/types'

export class NoRemoteAccountsError extends BaseError {
}

export class IntegrationError extends BaseError {
  public type: IntegrationErrorType

  constructor(type: IntegrationErrorType) {
    super(`IntegrationError: ${IntegrationErrorType[type]}`)
    this.type = type
  }
}
