import React from 'react'
import './IconOption.less'

interface Props {
  icon: React.ReactNode,
  className: string,
  label: string,
}

IconOption.defaultProps = {
  className: '',
  label: '',
}

export default function IconOption({ icon, label, className }: Props) {
  return (
    <div className={`icon-option ${className}`}>
      {icon}
      <div className="label">{label}</div>
    </div>
  )
}
