import { fetchAllFromTable, convertResultToEntity, WalletSQLInMemory } from "../db/inMemorySqlDb"
import { inMemoryTableNames } from "../db/inMemorySqlDbSchemaBuilder"
import * as commonRepository from "src/backend/common/repository"
import { Currency } from "src/types/Currency"
import { HashMap } from "src/types/common"

export function findAll(): Promise<Currency[]> {
  return commonRepository.findAll(inMemoryTableNames.CURRENCY) as Promise<Currency[]>
}

export function findAllAsHashMap(): Promise<HashMap<Currency>> {
  return commonRepository.findAllAsHashMap(inMemoryTableNames.CURRENCY)
}

export function findById(id): Promise<Currency> {
  const sqlDb = WalletSQLInMemory.getInstance()
  const currencyTable = sqlDb.getTable(inMemoryTableNames.CURRENCY)
  return sqlDb
    .select()
    .from(currencyTable)
    .where(currencyTable._id.eq(id))
    .exec()
    .then(convertResultToEntity())
}

export function findByIds(ids): Promise<Currency[]> {
  if (!Array.isArray(ids)) {
    return findAll() as Promise<Currency[]>
  }
  const sqlDb = WalletSQLInMemory.getInstance()
  const currencyTable = sqlDb.getTable(inMemoryTableNames.CURRENCY)
  return sqlDb
    .getDatabase()
    .select()
    .from(currencyTable)
    .where(currencyTable._id.in(ids))
    .exec() as Promise<Currency[]>
}

export function getReferentialCurrency(): Promise<Currency> {
  const sqlDb = WalletSQLInMemory.getInstance()
  const currencyTable = sqlDb.getTable(inMemoryTableNames.CURRENCY)
  return sqlDb
    .select()
    .from(currencyTable)
    .where(currencyTable.referential.eq(true))
    .exec()
    .then((result) => {
      if (result.length > 1)
        console.warn("There is more than one referential currency! Taking the first...", result)
      return result[0] as Promise<Currency>
    })
}

export function getAllCurrencies() {
  return fetchAllFromTable<Currency>(inMemoryTableNames.CURRENCY)
}

export async function removeBulk(currencyIds: string[]) {
  const currencies = await findByIds(currencyIds)
  return commonRepository.removeBulk(currencies)
}
