import { combineReducers } from 'redux'
import account from './account/reducer'
import { PageMode } from 'src/frontend/scenes/imports/constants'
import items from './items/reducer'
import records from './records/reducer'
import mapping from './mapping/reducer'
import { CLOSE_MAPPING, OPEN_MAPPING } from 'src/frontend/scenes/imports/mapping/actions'
import {
  CANCEL_DEACTIVATE_IMPORTS,
  CONFIRM_DEACTIVATE_IMPORTS,
  DEACTIVATE_IMPORTS,
  DEACTIVATE_IMPORTS_PROGRESS,
  IMPORTS_INITIALIZED,
  SET_TUTORIAL_OPEN_STATUS,
} from 'src/frontend/scenes/imports/actions'

const deactivateImportsInitialState = {
  open: false,
  progress: false,
}

const commonInitialState = {
  initialized: false,
  pageMode: PageMode.RECORDS,
  deactivateImports: deactivateImportsInitialState,
  tutorialOpen: false,
  onboardingTooltipStatus: null,
}

const common = (state = commonInitialState, action) => {
  switch (action.type) {

    case IMPORTS_INITIALIZED: {
      return { ...state, initialized: true }
    }

    case OPEN_MAPPING: {
      return { ...state, pageMode: PageMode.MAPPING }
    }

    case CLOSE_MAPPING: {
      return { ...state, pageMode: PageMode.RECORDS }
    }

    case CONFIRM_DEACTIVATE_IMPORTS: {
      return { ...state, deactivateImports: { ...state.deactivateImports, open: true } }
    }

    case DEACTIVATE_IMPORTS_PROGRESS: {
      return { ...state, deactivateImports: { ...state.deactivateImports, progress: true } }
    }

    case DEACTIVATE_IMPORTS:
    case CANCEL_DEACTIVATE_IMPORTS: {
      return { ...state, deactivateImports: deactivateImportsInitialState }
    }

    case SET_TUTORIAL_OPEN_STATUS: {
      const { tutorialOpen } = action.payload
      return { ...state, tutorialOpen }
    }

    default: {
      return state
    }
  }
}

export default combineReducers({ common, account, items, records, mapping })
