import React from 'react'
import AnimatedBarTable from 'src/frontend/components/AnimatedBarTable/AnimatedBarTable'
import { AnimatedBarTableData } from 'src/frontend/components/AnimatedBarTable/types'
import { WidgetView } from 'src/frontend/scenes/dashboard/types'
import Widget from 'src/frontend/scenes/dashboard/components/Widget/Widget'
import { Bar, BarsData, CreditBarsData } from 'src/frontend/scenes/dashboard/widgets/BoardCashFlowBar/types'
import FormattedPercentage from 'src/frontend/modules/intl/components/FormattedPercentage'
import DebtPieChart from 'src/frontend/components/DebtPieChart/DebtPieChart'
import { CashFlowBarColors } from 'src/frontend/scenes/dashboard/constants'
import { FormattedMessage, FormattedMoney } from 'src/frontend/modules/intl'
import styles from './CreditLimitUtilization.module.less'
import _isEmpty from 'lodash/isEmpty'
import CreditLimitUtilizationLegend
  from 'src/frontend/scenes/dashboard/widgets/CreditLimitsUtilization/CreditLimitsUtilizationLegend'
import { widgetHasDeepEqualProps } from 'src/frontend/scenes/dashboard/helpers'
import { Modal } from 'semantic-ui-react'
import { ComplementaryButton } from 'src/frontend/components/Buttons/Buttons'

type Props = {
  widget: WidgetView & { data: CreditBarsData },
}

function CreditLimitsUtilization({ widget }: Props) {
  const { data, filter, options } = widget
  if (!data || !filter) {
    return <Widget.Placeholder />
  }

  const tableData = convertBoardCashFlowToBarTableData(data)

  const widgetAction = !_isEmpty(tableData) && (
    <Modal
      size="small"
      trigger={(
        <ComplementaryButton>
          <FormattedMessage id="show-more" />
        </ComplementaryButton>
      )}
    >
      <Modal.Content>
        <AnimatedBarTable
          data={tableData}
        />
        <CreditLimitUtilizationLegend />
      </Modal.Content>
    </Modal>
  )

  return (
    <Widget>
      <Widget.Header
        title={widget.title}
        action={widgetAction}
      />
      <Widget.Summary
        period={filter.period}
        intervalType={options.relativeIntervalValue}
      />
      <Widget.Content>
        <div className={styles.container}>
          <DebtPieChart
            currencyCode={data.referentialCurrency.code}
            value={data.totalRefCreditBalance}
            totalValue={data.totalLimit}
          />
        </div>
      </Widget.Content>

    </Widget>
  )
}

function convertBoardCashFlowToBarTableData(data: BarsData): AnimatedBarTableData[] {
  const { bars, referentialCurrency } = data

  return bars.map((bar: Bar) => {
    const valuesSum = bar.values.reduce((acc, value) => acc + value.value, 0)

    return {
      id: bar.label,
      values: bar.values,
      maxValue: bar.maxValue,
      avgValue: bar.avgValue,
      avgValueLabel: (
        <>
          <span className={styles.valueHeader}>
            <FormattedMessage id={'dashboard.widget.average-credit'} />
          </span>
          &nbsp;
          <strong>
            <FormattedMoney value={bar.avgValue} currency={referentialCurrency.code} />
          </strong>
        </>
      ),
      maxValueLabel: (
        <>
          <span className={styles.valueHeader}>
            <FormattedMessage id={'dashboard.widget.maximum-credit'} />
          </span>
          &nbsp;
          <strong>
            <FormattedMoney value={bar.maxValue} currency={referentialCurrency.code} />
          </strong>
        </>
      ),
      total: bar.total,
      valueChange: bar.change,
      divided: false,
      label: bar.label,
      valueLabel: (
        <FormattedPercentage
          style={valuesSum >= bar.total ? { color: CashFlowBarColors.DARK_RED } : undefined}
          value={valuesSum / bar.total}
        />
      ),
      leftBottomLabel: (
        <>
          <span className={styles.valueHeader}>
            <FormattedMessage id={'dashboard.widget.credit-utilization'} />
          </span>
          &nbsp;
          <FormattedMoney value={valuesSum} currency={referentialCurrency.code} />
        </>
      ),
      rightBottomLabel: (
        <>
          <span className={styles.valueHeader}>
            <FormattedMessage id={'dashboard.widget.credit-limit'} />
          </span>
          &nbsp;
          <FormattedMoney value={bar.total} currency={referentialCurrency.code} />
        </>
      ),
    }
  })
}

export default React.memo(CreditLimitsUtilization, widgetHasDeepEqualProps)
