/* eslint-disable react/prop-types */
/* eslint-disable no-shadow */
import React from 'react'
import { connect } from 'react-redux'
import {
  Modal, Grid, Loader,
} from 'semantic-ui-react'
import { FormattedMessage } from 'src/frontend/modules/intl'
import * as actions from '../actions'
import { PrimaryButton, SecondaryButton } from 'src/frontend/components/Buttons/Buttons'

const mapStateToProps = () => {
  return {}
}
const mapDispatchToProps = (dispatch) => {
  return {
    handleDeleteTemplate(event, template) {
      console.log('handleDeleteTemplate', event)
      dispatch(actions.deleteTemplate(template))
    },
  }
}

class RemoveTemplateForm extends React.Component {

  state = { processing: false, open: false }

  handleConfirm = (e) => {
    this.setState({ processing: true })
    return this.props.handleDeleteTemplate(e, this.props.template._id)
  }

  handleOpen = () => this.setState({ processing: false, open: true })

  handleClose = () => this.setState({ processing: false, open: false })

  render() {
    const { template } = this.props
    const { processing, open } = this.state

    const RemoveTemplateContent = ({ processing }) => {

      const loadingSpinner = (<Loader active inline="centered" />)

      const confirmQuestion = (
        <div>
          <FormattedMessage
            id="settings.templates.remove.template-text"
            values={{ templateName: <strong>{template.name.toUpperCase()}</strong> }}
          />
        </div>
      )

      return !processing ? confirmQuestion : loadingSpinner

    }

    const ModalActions = () => {
      return (
        <Modal.Actions>
          <Grid>
            <Grid.Column width={8}>
              <SecondaryButton fluid onClick={this.handleClose}>
                <FormattedMessage id="form.no" />
              </SecondaryButton>
            </Grid.Column>
            <Grid.Column width={8}>
              <PrimaryButton disabled={processing} fluid color="red" onClick={this.handleConfirm}>
                <FormattedMessage id="form.yes" />
              </PrimaryButton>
            </Grid.Column>
          </Grid>
        </Modal.Actions>
      )
    }

    return (
      <Modal
        trigger={<span onClick={this.handleOpen}><FormattedMessage id="delete" /></span>}
        open={open}
        size="small"
        onClose={this.handleClose}
      >
        <Modal.Header><FormattedMessage id="settings.templates.remove.template" /></Modal.Header>
        <Modal.Content>
          <RemoveTemplateContent processing={processing} />
        </Modal.Content>
        <ModalActions />
      </Modal>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RemoveTemplateForm)
