import React, { SyntheticEvent } from 'react'
import {
  Modal, Loader,
} from 'semantic-ui-react'
import { FormattedMessage } from 'src/frontend/modules/intl'
import { connect } from 'react-redux'
import * as appActions from 'src/frontend/scenes/app/actions'
import { selectAppInfoState } from 'src/frontend/scenes/app/selectors'
import './InfoMessageModal.less'
import { SecondaryButton } from 'src/frontend/components/Buttons/Buttons'

const mapDispatchToProps = {
  closeInfoModal: appActions.closeInfoModal,
}

const mapStateToProps = selectAppInfoState

interface Props {
  messageId?: string,
  headerId?: string,
  closeable?: boolean,
  dimmer?: true | 'blurring' | 'inverted',
  spinner?: boolean,
  closeInfoModal: (e: SyntheticEvent) => void,
}

InfoMessageModal.defaultProps = {
  closeable: true,
  dimmer: undefined,
  headerId: null,
  messageId: null,
  spinner: false,
}

function InfoMessageModal({
  messageId,
  headerId,
  spinner,
  closeable,
  dimmer,
  closeInfoModal,
}: Props) {
  if (!!messageId && !headerId && !closeable && spinner) {
    return (
      <div className="info-message-loading">
        <Loader active>{messageId && <FormattedMessage id={messageId} />}</Loader>
      </div>
    )
  }

  return (
    <Modal open={!!messageId} size="small" dimmer={dimmer}>
      {headerId && (
        <Modal.Header>
          {headerId && <FormattedMessage id={headerId} />}
          {closeable && <span className="modal-close" onClick={closeInfoModal} />}
        </Modal.Header>
      )}
      <Modal.Content>
        {spinner && <Loader active inline="centered" />}
        {messageId && <FormattedMessage id={messageId} />}
      </Modal.Content>
      <Modal.Actions>
        {closeable && (
          <SecondaryButton className="capitalize" onClick={closeInfoModal}>
            <FormattedMessage
              className="capitalize"
              id="form.ok"
            />
          </SecondaryButton>
        )}
      </Modal.Actions>
    </Modal>
  )
}


export default connect(mapStateToProps, mapDispatchToProps)(InfoMessageModal)
