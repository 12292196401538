import { Text, View } from '@react-pdf/renderer'
import { formatMessage } from 'src/frontend/modules/intl'
import React from 'react'

interface Props {
  companyName: string
  streetCity: string
  countryZip: string
  VAT?: string
}

export default function Address({ companyName, streetCity, countryZip, VAT }: Props) {
  const vatRow = VAT && <>{formatMessage('billing.address.vat-id')}: {VAT}</>

  return (
    <View style={{ lineHeight: 1.4, fontFamily: 'Helvetica', width: '50%' }}>
      <Text style={{ fontFamily: 'Helvetica-Bold' }}>{companyName}</Text>
      <Text>{streetCity}</Text>
      <Text>{countryZip}</Text>
      <Text>{vatRow}</Text>
    </View>
  )
}
