import React from 'react'
import { FormattedMessage } from 'src/frontend/modules/intl'

interface Props {
  id: string | { W: string, B: string }
  linkId: string,
  linkUrl: string,
  otherValues?: { [key: string]: string | number | boolean | JSX.Element }
}

FormattedMessageWithLink.defaultProps = {
  otherValues: {},
}

function FormattedMessageWithLink({ id, linkId, linkUrl, otherValues }: Props) {
  return (
    <label>
      <FormattedMessage
        id={id}
        values={{
          link: (
            <a
              href={linkUrl}
              target="_blank"
              rel="noopener noreferrer"
              onClick={e => e.stopPropagation()}
            >
              <FormattedMessage id={linkId} />
            </a>
          ),
          ...otherValues,
        }}
      />
    </label>
  )
}


export default FormattedMessageWithLink
