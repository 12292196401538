import { combineReducers } from 'redux'
import {
  SolveDuplicitiesFormState,
  solveDuplicitiesForm,
} from 'src/frontend/scenes/records/solveDuplicitiesForm/reducer'
import { removeForm, RemoveFormState } from './removeForm/reducer'
import { recordList, RecordListState } from './recordList/reducer'
import { recordForm, RecordFormState } from 'src/frontend/scenes/records/recordForm/reducer'
import { assignForm, AssignFormState } from 'src/frontend/scenes/records/assignForm/reducer'
import recordsDetail, { RecordsDetailState } from 'src/frontend/scenes/records/recordsDetail/reducer'

export interface RecordsState {
  recordForm: RecordFormState
  solveDuplicitiesForm: SolveDuplicitiesFormState,
  removeForm: RemoveFormState
  recordList: RecordListState
  assignForm: AssignFormState
  recordsDetail: RecordsDetailState
}

export default combineReducers({
  recordList,
  recordForm,
  removeForm,
  solveDuplicitiesForm,
  assignForm,
  recordsDetail,
})
