import React from 'react'
import { Menu } from 'semantic-ui-react'
import { Period } from 'src/types/Filter'
import { SemanticData } from 'src/types/Semantic-ui'
import Content from './Content'
import { FormattedMessage } from 'src/frontend/modules/intl'
import { MenuType } from 'src/frontend/components/DateRangePicker/helpers'
import { RelativeIntervalType } from 'src/backend/enums'

type Props = {
  period: Period,
  locale: string,
  showAll: boolean,
  onChange: Function,
  intervalType: RelativeIntervalType,
  onClose: () => void,
}

type State = {
  menuType: string,
}

export default class PickerContainer extends React.Component<Props, State> {

  state = { menuType: MenuType.RANGE.value }

  handleChangeType = (_e: any, { name }: SemanticData) => this.setState({ menuType: name })

  componentWillMount() {
    const { period: { interval } } = this.props

    const { value } = Object.values(MenuType).find((menuType) => menuType.interval.includes(interval))
      || MenuType.RANGE

    this.setState({ menuType: value })
  }

  render() {
    const { menuType } = this.state
    const { showAll, period, locale, intervalType, onChange, onClose } = this.props
    return (
      <div className="date-range-picker-container">
        <Menu fluid widths={4} compact>
          <Menu.Item
            name={MenuType.RANGE.value}
            active={menuType === MenuType.RANGE.value}
            onClick={this.handleChangeType}
          >
            <FormattedMessage id="range" />
          </Menu.Item>
          <Menu.Item
            name={MenuType.WEEK.value}
            active={menuType === MenuType.WEEK.value}
            onClick={this.handleChangeType}
          >
            <FormattedMessage id="weeks" />
          </Menu.Item>
          <Menu.Item
            name={MenuType.MONTH.value}
            active={menuType === MenuType.MONTH.value}
            onClick={this.handleChangeType}
          >
            <FormattedMessage id="months" />
          </Menu.Item>
          <Menu.Item
            name={MenuType.YEAR.value}
            active={menuType === MenuType.YEAR.value}
            onClick={this.handleChangeType}
          >
            <FormattedMessage id="years" />
          </Menu.Item>
        </Menu>
        <div className="content">
          <Content
            showAll={showAll}
            type={menuType}
            period={period}
            intervalType={intervalType}
            locale={locale}
            onChange={onChange}
            onClose={onClose}
          />
        </div>
      </div>
    )
  }
}
