import { RecordType } from 'src/backend/enums'
import { getEndOfTomorrow, getStartOfYesterday } from 'src/backend/time/time'
import moment, { Moment } from 'moment'
import { formatDateShort, formatRelative } from 'src/frontend/modules/intl'
import { formatDateLong } from 'src/frontend/modules/intl/i18n'

export function getRecordsSum(records): number {
  const expenses = records.filter(record => record.type === RecordType.EXPENSE)
    .reduce((acc, { refAmount }) => acc + refAmount, 0)

  const incomes = records.filter(record => record.type === RecordType.INCOME)
    .reduce((acc, { refAmount }) => acc + refAmount, 0)

  return incomes - expenses
}

export function convertDateToLabel(date: Moment) {
  const startOfYesterday = getStartOfYesterday()
  const endOfTomorrow = getEndOfTomorrow()

  const diff = date.diff(moment(), 'day')

  // '[]' means with included edge dates
  if (date.isBetween(startOfYesterday, endOfTomorrow, null, '[]')) {
    return formatRelative(diff)
  }

  if (date.isSame(moment(), 'year')) {
    return formatDateShort(date.toDate())
  }
  return formatDateLong(date.toDate())
}
