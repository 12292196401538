/* eslint-disable react/prop-types */
/* eslint-disable no-shadow */
import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { Modal, Form, Message } from "semantic-ui-react"
import { FormattedMessage, formatMessage, formatNumber } from "src/frontend/modules/intl"
import * as selectors from "../selectors"
import * as currenciesSelectors from "src/frontend/modules/currencies/selectors"
import * as actions from "../actions"
import "./CurrencyForm.less"
import { getUnusedCurrencies } from "src/backend/currencies/currencies"
import _debounce from "lodash/debounce"
import { FormType } from "src/frontend/scenes/settings/enums"
import { PrimaryButton } from "src/frontend/components/Buttons/Buttons"
import { useDebounce } from "src/frontend/utils/hooks"

const mapStateToProps = (state) => {
  const { form } = selectors.selectCurrencies(state)
  return {
    formType: form.formType,
    formValues: form.formValues,
    formOpen: form.isOpen,
    errors: form.errors,
    isExchangeLoading: form.loadingExchange,
    currency: form.currency,
    referentialCurrency: currenciesSelectors.selectReferentialCurrency(state),
    currencies: currenciesSelectors.selectSortedCurrencies(state),
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    handleChangeRate(ratioToReferential) {
      console.log("handleChangeRate", ratioToReferential)
      dispatch(actions.changeFormFieldValue("ratioToReferential", ratioToReferential))
    },
    handleCodeChange(referentialCurrencyCode) {
      return (event, field) => {
        console.log("handleFieldChange", event, field)
        event.preventDefault()
        dispatch(actions.changeCurrencyCode(field.value, referentialCurrencyCode))
      }
    },
    handleCloseForm(event) {
      console.log("handleCloseForm", event)
      dispatch(actions.closeForm())
    },
    handleSaveCurrency(formValues, formType, currency) {
      dispatch(actions.saveCurrency(formValues, formType, currency))
    },
  }
}

const CurrencyForm = ({
  currencies,
  formValues,
  errors,
  formType,
  formOpen,
  currency,
  referentialCurrency,
  isExchangeLoading,
  handleCloseForm,
  handleChangeRate,
  handleCodeChange,
  handleSaveCurrency,
}) => {
  const currenciesToSelect = currency
    ? [...getUnusedCurrencies(currencies), currency]
    : getUnusedCurrencies(currencies)

  const currenciesOptions = currenciesToSelect.map((currency) => {
    return {
      key: `form-${currency.code}`,
      text: `${currency.code} - ${currency.name}`,
      value: currency.code,
    }
  })

  const currencyDisabled = formType === FormType.EDIT

  const SelectCurrency = ({ options, disabled }) => {
    return (
      <div className="field">
        <Form.Dropdown
          name="code"
          label={<FormattedMessage id="form.name" />}
          placeholder={formatMessage("settings.currencies.form.code_placeholder")}
          defaultValue={formValues.code}
          search
          fluid
          selection
          disabled={disabled}
          options={options}
          error={errors.code}
          onChange={handleCodeChange(referentialCurrency.code)}
        />
        {errors.code && (
          <Message
            className="error-message"
            error
            content={<FormattedMessage id={errors.code} />}
          />
        )}
      </div>
    )
  }

  const formName = formatMessage(
    formType === FormType.ADD
      ? "settings.currencies.form.add_currency"
      : "settings.currencies.form.edit_currency",
  )

  const SaveButton = ({ children, loading }) => (
    <div className="fields submit">
      <PrimaryButton
        fluid
        type="submit"
        loading={loading}
        onClick={() => handleSaveCurrency(formValues, formType, currency || {})}
      >
        {children}
      </PrimaryButton>
    </div>
  )

  return (
    <Modal
      size="small"
      open={formOpen}
      onClose={handleCloseForm}
    >
      <Modal.Header>
        {formName}
        <span
          className="modal-close"
          onClick={handleCloseForm}
        />
      </Modal.Header>
      <Modal.Content>
        <div className="add-currency-form">
          <div className="content-panel">
            <Form error>
              <SelectCurrency
                disabled={currencyDisabled}
                options={currenciesOptions}
              />
              <CurrencyRate
                formValues={formValues}
                referentialCurrency={referentialCurrency}
                errors={errors}
                handleChangeRate={handleChangeRate}
              />
              <SaveButton loading={isExchangeLoading}>
                <FormattedMessage id="form.save" />
              </SaveButton>
            </Form>
          </div>
        </div>
      </Modal.Content>
    </Modal>
  )
}

const CurrencyRate = ({ formValues, referentialCurrency, errors, handleChangeRate }) => {
  const DECIMAL_POSITIONS = 4

  const [ratio1, setRatio1] = useState<string>(
    formatExchangeRate(parseFloat(formValues?.ratioToReferential ?? "0")),
  )
  const [ratio2, setRatio2] = useState<string>(
    formatExchangeRate(parseFloat((1 / formValues?.ratioToReferential).toString() ?? "0")),
  )

  const { ratio1: debouncedRatio1, ratio2: debouncedRatio2 } = useDebounce({ ratio1, ratio2 }, 250)

  useEffect(() => {
    if (Number.isNaN(Number(debouncedRatio1))) return

    handleChangeRate(Number(debouncedRatio1))
  }, [debouncedRatio1, debouncedRatio2])

  useEffect(() => {
    setRatio1(formatExchangeRate(parseFloat(formValues?.ratioToReferential ?? "0")))
    setRatio2(
      formatExchangeRate(parseFloat((1 / formValues?.ratioToReferential).toString() ?? "0")),
    )
  }, [formValues.ratioToReferential])

  const ratio1Label = formValues.code
    ? `1 ${referentialCurrency.code} =`
    : formatMessage("settings.currencies.ratio")

  const ratio2Label = formValues.code
    ? `1 ${formValues.code} =`
    : formatMessage("settings.currencies.inverse_ratio")

  const handleChangeRatio1 = (_, field) => {
    setRatio1(field.value)

    setRatio2(formatExchangeRate(calcOpositeRatio(field.value)))
  }

  const handleChangeRatio2 = (_, field) => {
    setRatio2(field.value)

    setRatio1(formatExchangeRate(calcOpositeRatio(field.value)))
  }

  function formatExchangeRate(value: number) {
    return parseFloat(value.toFixed(DECIMAL_POSITIONS)).toString()
  }

  function calcOpositeRatio(knownRatio: number) {
    return (knownRatio && Math.round((1 / knownRatio) * 10000000) / 10000000) || 0
  }

  return (
    <div className="field">
      <Form.Group>
        <Form.Input
          className="rate"
          label={ratio1Label}
          name="ratio1"
          type="number"
          width="8"
          min="0"
          pattern="^\d*(\.\d{0,2})?$"
          value={ratio1}
          error={errors.ratioToReferential}
          onChange={handleChangeRatio1}
        />
        <Form.Input
          className="rate"
          label={ratio2Label}
          type="number"
          name="ratio2"
          value={ratio2}
          width="8"
          pattern="^\d*(\.\d{0,2})?$"
          min="0"
          error={errors.ratioToReferential}
          onChange={handleChangeRatio2}
        />
      </Form.Group>
      {errors.ratioToReferential && (
        <Message
          className="error-message"
          error
          content={<FormattedMessage id={errors.ratioToReferential} />}
        />
      )}
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(CurrencyForm)
