import { CategoryType, Platform } from 'src/backend/enums'
import { CategoryDocument } from 'src/types/Category'

const RESERVED_MODEL_TYPE = 'Category'
const ID_PREFIX = '-Category_'

export function getDefaultCategory(uuid): CategoryDocument {
  return {
    _id: ID_PREFIX + uuid,
    color: '#cccccc',
    customCategory: false,
    customName: false,
    defaultType: CategoryType.EXPENSE,
    envelopeId: 0,
    name: '',
    position: 0,
    isGeneral: undefined,
    superEnvelopeId: undefined,
    reservedAuthorId: null,
    reservedCreatedAt: null,
    reservedUpdatedAt: null,
    reservedModelType: RESERVED_MODEL_TYPE,
    reservedOwnerId: null,
    reservedSource: Platform.WEB,
  }
}
