import { Action, GetState, HashMap } from 'src/types/common'
import { FormType } from 'src/frontend/scenes/settings/enums'
import { Contact } from 'src/types/Contact'
import { User } from 'src/types/User'
import { selectUser } from 'src/frontend/modules/user/selectors'
import * as contactsService from 'src/backend/contacts/service'
import * as contactsRepository from 'src/backend/contacts/repository'
import { WalletFormValidationError } from 'src/backend/errors'
import { captureException } from 'src/common/logger'
import {
  selectEditContactId,
  selectFormType,
  selectFormValues,
} from 'src/frontend/scenes/contacts/contactForm/selectors'
import { selectContacts } from 'src/frontend/modules/contacts/selectors'
import { fetchContacts } from 'src/frontend/modules/contacts/actions'
import { validate } from 'src/backend/contacts/validator'
import { selectRecordForm } from 'src/frontend/scenes/records/recordForm/selectors'
import * as recordFormActions from 'src/frontend/scenes/records/recordForm/actions'
import { ContactFormValues } from 'src/frontend/scenes/contacts/contactForm/types'
import { Id } from 'src/types/CouchDb'
import { Dispatch } from 'redux'
import { selectAssignForm } from 'src/frontend/scenes/records/assignForm/selectors'
import * as assignFormActions from 'src/frontend/scenes/records/assignForm/actions'

export const CONTACTS_FORM_OPEN = 'CONTACTS_FORM_OPEN'
export const CONTACTS_FORM_CLOSE = 'CONTACTS_FORM_CLOSE'
export const CONTACTS_CHANGE_FIELD_VALUE = 'CONTACTS_CHANGE_FIELD_VALUE'
export const CONTACTS_CHANGE_FIELD_VALUES = 'CONTACTS_CHANGE_FIELD_VALUES'

export const CONTACTS_SAVE_START = 'CONTACTS_SAVE_START'
export const CONTACTS_SAVE_SUCCESS = 'CONTACTS_SAVE_SUCCESS'
export const CONTACTS_SAVE_ERROR = 'CONTACTS_SAVE_ERROR'

export function saveContact() {
  return async (dispatch: Function, getState: GetState) => {
    dispatch(saveContactStart())

    const formValues = selectFormValues(getState())
    const formType = selectFormType(getState())
    const contactId = selectEditContactId(getState())
    const user: User = selectUser(getState())

    const contacts = selectContacts(getState())
    const types = [Contact.Type.VENDOR, Contact.Type.INTERNAL, Contact.Type.OTHER, Contact.Type.CUSTOMER]

    try {
      validate({
        ...formValues,
        _id: contactId,
      }, { contacts, types })

      const [contact] = await contactsService.saveContact(formValues, user, contactId)
      dispatch(saveContactSuccess())
      await dispatch(fetchContacts())

      if (selectRecordForm(getState()).open) {
        dispatch(recordFormActions.changeContactId(contact._id))
      }

      if (selectAssignForm(getState()).open) {
        dispatch(assignFormActions.changeContactId(contact._id))
      }
    } catch (error) {
      if (error instanceof WalletFormValidationError) {
        dispatch(saveContactError(error.errors))
      } else {
        captureException(error, 'contacts.saveContact', { formValues, formType, contactId })
      }
    }
  }
}


export function changeFieldValue(fieldName: string, value: any): Action<HashMap<any>> {
  return {
    type: CONTACTS_CHANGE_FIELD_VALUE,
    payload: {
      [fieldName]: value,
    },
  }
}

export function changeFieldValues(fields: HashMap<any>): Action {
  return {
    type: CONTACTS_CHANGE_FIELD_VALUES,
    payload: { ...fields },
  }
}

export function openAddContactForm(
  contactName?: string,
): Action<{ formValues: ContactFormValues, formType: FormType, contactId: Id }> {
  return {
    type: CONTACTS_FORM_OPEN,
    payload: {
      contactId: null,
      formValues: {
        name: contactName || '',
        email: '',
        phone: '',
        note: '',
        web: '',
        avatarUrl: '',
        type: Contact.Type.OTHER,
      },
      formType: FormType.ADD,
    },
  }
}

export function openEditContactForm(contactId: Id) {
  return async (dispatch: Dispatch) => {
    const { name, email, phone, note, web, avatarUrl, type } = await contactsRepository.findById(contactId)

    return dispatch({
      type: CONTACTS_FORM_OPEN,
      payload: {
        contactId,
        formValues: { name, email, phone, note, web, avatarUrl, type },
        formType: FormType.EDIT,
      },
    })
  }
}

export function closeNewContactForm(): Action {
  return {
    type: CONTACTS_FORM_CLOSE,
  }
}

export function saveContactStart(): Action {
  return {
    type: CONTACTS_SAVE_START,
  }
}

export function saveContactSuccess(): Action {
  return {
    type: CONTACTS_SAVE_SUCCESS,
  }
}

export function saveContactError(errors: HashMap<string>): Action {
  return {
    type: CONTACTS_SAVE_ERROR,
    payload: errors,
  }
}

export function removeContact(contactId: Id) {
  return async (dispatch: Function) => {
    try {
      await contactsService.removeLContact(contactId)
      await dispatch(fetchContacts())

    } catch (error) {
      captureException(error, 'contacts.removeContact', { contactId })
    }
  }
}
