import * as actions from './actions'

export interface RemoveFormState {
  open: boolean
  isRemoving: boolean
}

export function removeForm(state: RemoveFormState = { open: false, isRemoving: false }, action) {
  switch (action.type) {
    case actions.OPEN_REMOVE_FORM:
    case actions.CLOSE_REMOVE_FORM:
    case actions.REMOVE_RECORD_START:
    case actions.REMOVE_RECORD_SUCCESS: {
      return { ...state, ...action.payload }
    }
    default:
      return state
  }
}
