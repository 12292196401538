import styles from "src/frontend/scenes/settings/billing/BillingItem.module.less"
import { BasicIcon } from "src/frontend/components/Icons/Icons"
import React from "react"
import { BasicIcons } from "src/frontend/components/Icons/types"
import { mergeClasses } from "src/common/utils"

interface Props {
  title: string | React.ReactNode
  action?: React.ReactNode
  icon?: BasicIcons
  iconSize?: string
  error?: string | React.ReactNode
  children: React.ReactNode
  topAligned?: boolean
}

SettingsItem.defaultProps = {
  action: undefined,
  icon: undefined,
  error: undefined,
  topAligned: false,
  iconSize: "20px",
}

export function SettingsItem({
  title,
  action,
  icon,
  children,
  iconSize,
  topAligned,
  error,
}: Props) {
  return (
    <div className={styles.item}>
      <div className={styles.header}>
        <h3>{title}</h3>
        {!!error && <div className={styles.error}>{error}</div>}
      </div>
      <div className={mergeClasses(styles.grayBox, topAligned && styles.top)}>
        {icon && (
          <div className={styles.icon}>
            <BasicIcon
              name={icon}
              size={iconSize}
            />
          </div>
        )}
        {children}
        {action && <div className={styles.action}>{action}</div>}
      </div>
    </div>
  )
}
