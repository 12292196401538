import React, { SyntheticEvent } from 'react'
import { FormattedMessage } from 'src/frontend/modules/intl'
import Accordion from './Accordion'
import _isEmpty from 'lodash/isEmpty'
import { Id } from 'src/types/CouchDb'
import { Label } from 'src/types/Label'
import SelectMultiple from 'src/frontend/components/SelectMultiple'
import { Contact } from 'src/types/Contact'
import ContactView = Contact.ContactView
import filterStyles from 'src/frontend/components/Filter/Filter.module.less'

interface Props {
  label: string,
  name: string,
  active?: boolean,
  options?: Label[] | ContactView[],
  selectedOptions?: Id[],
  onChange: Function,
  onDisplayChange: Function,
}

MultipleLabelsFilter.defaultProps = {
  options: [],
  selectedOptions: [],
  active: false,
}

function getFilterValueLabel(options, selectedOptions) {

  if (selectedOptions.length === 0) {
    return <FormattedMessage id="filter.none" />
  } else if (selectedOptions.length === 1) {
    const labelEntity = options.find(label => label._id === selectedOptions[0])
    return labelEntity ? labelEntity.name : 1
  } else if (options.length === selectedOptions.length) {
    return <FormattedMessage id="filter.all" />
  } else {
    return selectedOptions.length
  }
}

export default function MultipleLabelsFilter({
  label,
  name,
  options,
  selectedOptions,
  active,
  onChange,
  onDisplayChange,
}: Props) {

  const accordionTitle = [
    <span key="name" className={filterStyles.filterName}><FormattedMessage id={label} /></span>,
    <span key="value" className={filterStyles.filterValue}>{getFilterValueLabel(options, selectedOptions)}</span>,
  ]

  const accordionContent = (
    <SelectMultiple
      name={name}
      options={options}
      value={selectedOptions}
      onChange={(_e: SyntheticEvent, { value }) => onChange(name, !_isEmpty(value) ? value : undefined)}
    />
  )

  return (
    <Accordion
      name={name}
      className={filterStyles.filter}
      title={accordionTitle}
      active={active}
      content={accordionContent}
      onDisplayChange={onDisplayChange}
    />
  )
}
