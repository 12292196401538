import React from 'react'
import MFAForm from 'src/frontend/scenes/integrations/components/MFAForm'
import { IntegrationProviders as IP } from "src/frontend/scenes/integrations/providersList/types"
import { RootState } from "src/types/State"
import { selectNewConnectionMFA } from "src/frontend/scenes/integrations/newConnection/selectors"
import { connect } from "react-redux"
import { sendMFAForm } from "src/frontend/scenes/integrations/newConnection/actions"
import { IntegrationProviders } from "src/frontend/scenes/integrations/providersList/types"
import IntegrationRecipeGroup = IntegrationProviders.IntegrationRecipe.IntegrationRecipeGroup
import { FormAttributeFields } from "src/frontend/scenes/integrations/types"

interface Props {
  MFAData: IntegrationRecipeGroup[]
  handleSubmit: (
    fields: FormAttributeFields,
    inputType?: IP.IntegrationRecipe.InputType,
    id?: string,
  ) => void
}

function mapStateToProps(state: RootState) {
  return {
    MFAData: selectNewConnectionMFA(state),
  }
}

const mapDispatchToProps = {
  handleSubmit: sendMFAForm,
}

function NewBankConnectionMFA({ MFAData, handleSubmit }: Props) {
  const { params, title } = MFAData[0]
  return <MFAForm params={params} title={title} onSubmit={handleSubmit} />
}

export default connect(mapStateToProps, mapDispatchToProps)(NewBankConnectionMFA)
