import React from 'react'
import { FormattedMessage } from 'src/frontend/modules/intl'
import styles from 'src/frontend/scenes/dashboard/components/Widget/Widget.module.less'
import { mergeClasses } from 'src/common/utils'
import { RootState } from 'src/types/State'
import { selectShouldDisplayDashboardOnBoarding } from 'src/frontend/scenes/onBoarding/inApp/selectors'
import { connect } from 'react-redux'

type Props = {
  type: 'line' | 'bar' | 'planned' | 'pie' | 'records',
  isOnBoarding: boolean,
}

const EMPTY_CONTENT_TYPE_CLASS = {
  line: styles.lineChart,
  bar: styles.barChart,
  planned: styles.plannedPayments,
  pie: styles.pieChart,
  records: styles.recordList,
}

function mapStateToProps(state: RootState) {
  return {
    isOnBoarding: selectShouldDisplayDashboardOnBoarding(state),
  }
}

function EmptyContent({ type, isOnBoarding }: Props) {

  const text = isOnBoarding
    ? 'dashboard.empty-state-text.onboarding'
    : 'dashboard.empty-state-text'

  return (
    <div className={styles.emptyContent}>
      <div className={mergeClasses(styles.emptyIcon, EMPTY_CONTENT_TYPE_CLASS[type])} />
      <div className={styles.emptyText}>
        <FormattedMessage id={text} />
      </div>
    </div>
  )
}

export default connect(mapStateToProps)(EmptyContent)
