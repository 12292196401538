import { getSuperEnvelopes } from 'src/backend/categories/envelopes'
import * as actions from './actions'
import { combineReducers } from 'redux'
import { Category } from 'src/types/Category'
import { FormType } from 'src/frontend/modules/categories/enums'
import { CategoriesFormValues } from 'src/frontend/scenes/settings/categories/types'
import { HashMap } from 'src/types/common'

interface SettingsCategoriesFormState {
  selectedEnvelopeId: number,
  formValues: CategoriesFormValues,
  category: Category,
  isOpen: boolean,
  formType: FormType
  errors: HashMap<string>
  colorPicker: boolean
}

const initialFormState = {
  selectedEnvelopeId: getSuperEnvelopes()[0].id,
  formValues: {},
  category: null,
  isOpen: false,
  formType: FormType.EDIT,
  errors: {},
  colorPicker: false,
}

function form(state: SettingsCategoriesFormState = initialFormState, action) {
  switch (action.type) {
    case actions.SETTINGS_CATEGORY_OPEN_FORM: {
      return { ...state, isOpen: true, ...action.payload }
    }
    case actions.SETTINGS_CATEGORY_CLOSE_FORM: {
      return {
        ...state,
        category: null,
        errors: {},
        formValues: {},
        isOpen: false,
        formType: '',
        colorPicker: false,
      }
    }
    case actions.SETTINGS_CATEGORY_OPEN_COLOR_PICKER: {
      return { ...state, colorPicker: true }
    }
    case actions.SETTINGS_CATEGORY_CLOSE_COLOR_PICKER: {
      return { ...state, colorPicker: false }
    }
    case actions.SETTINGS_CATEGORY_SAVE_START:
    case actions.SETTINGS_CATEGORY_SAVE_SUCCESS:
    case actions.SETTINGS_CATEGORY_SAVE_ERROR:
    case actions.SETTINGS_CATEGORY_CHANGE: {
      return { ...state, ...action.payload }
    }
    case actions.SETTINGS_CATEGORY_CHANGE_FIELD_VALUE: {
      return { ...state, formValues: { ...state.formValues, ...action.payload } }
    }
    default: {
      return state
    }
  }
}


interface SettingsCategoriesDeleteFormState {
  canDelete: boolean,
  referencedTypes: string[],
  errors: {},
  category: Category,
}

const initialDeleteFormState = {
  canDelete: false,
  referencedTypes: [],
  errors: {},
  category: null,
}

function deleteForm(state: SettingsCategoriesDeleteFormState = initialDeleteFormState, action) {
  switch (action.type) {
    case actions.SETTINGS_CATEGORY_OPEN_DELETE_FORM: {
      return { ...state, ...action.payload }
    }
    case actions.SETTINGS_CATEGORY_CLOSE_DELETE_FORM: {
      return { ...state, canDelete: false, referencedTypes: null, errors: {}, category: null }
    }
    default: {
      return state
    }
  }
}

export interface SettingsCategories {
  form: SettingsCategoriesFormState
  deleteForm: SettingsCategoriesFormState
}

export default combineReducers({ form, deleteForm })
