import { ChartType } from 'src/frontend/components/chart/ChartType'
import { DATASET_DEFAULT_OPTIONS } from 'src/frontend/scenes/dashboard/components/constants'
import { CashFlowTrendData } from 'src/frontend/scenes/dashboard/widgets/CashFlowTrend/types'
import { Currency } from 'src/types/Currency'
import { CashFlowBarColors } from 'src/frontend/scenes/dashboard/constants'
import { formatMessage } from 'src/frontend/modules/intl'

const BALANCE_TREND_DEFAULT_OPTIONS = {
  borderWidth: 2,
  fill: 'origin',
  hideLegend: false,
  isEmpty: false,
  type: ChartType.BAR,
}

export function aggregateBoardCashFlowTrendChartData(referentialCurrency: Currency) {
  return (data: CashFlowTrendData) => {
    if (!data) {
      return null
    }

    const [cashFlowDataset, operatingRevenue, otherRevenue, operatingCosts, otherCosts] = data.datasets

    const CASHFLOW_LINE_COLOR = '#3c5273'

    const datasets = data.datasets
      ? [
        // CASH FLOW
        {
          ...cashFlowDataset,
          ...DATASET_DEFAULT_OPTIONS,
          ...BALANCE_TREND_DEFAULT_OPTIONS,
          borderColor: CASHFLOW_LINE_COLOR,
          backgroundColor: 'transparent',
          pointBackgroundColor: CASHFLOW_LINE_COLOR,
          label: formatMessage('cashFlow'),
          id: 'cashFlow',
          isEmpty: data.datasets && !!data.datasets[0] && data.datasets[0].data
            .every((value => value === null || value === 0)),
          type: ChartType.LINE,
        },
        // OPERATING REVENUE
        {
          ...operatingRevenue,
          ...DATASET_DEFAULT_OPTIONS,
          ...BALANCE_TREND_DEFAULT_OPTIONS,
          borderColor: 'transparent',
          pointBackgroundColor: CashFlowBarColors.DARK_GREEN,
          backgroundColor: CashFlowBarColors.DARK_GREEN,
          label: formatMessage('operating-revenue'),
          id: 'operating-revenue',
        },
        // OTHER REVENUE
        {
          ...otherRevenue,
          ...DATASET_DEFAULT_OPTIONS,
          ...BALANCE_TREND_DEFAULT_OPTIONS,
          borderColor: 'transparent',
          pointBackgroundColor: CashFlowBarColors.LIGHT_GREEN,
          backgroundColor: CashFlowBarColors.LIGHT_GREEN,
          label: formatMessage('other-cash-inflow'),
          id: 'other-cash-inflow',
        },
        // OPERATING COST
        {
          ...operatingCosts,
          ...DATASET_DEFAULT_OPTIONS,
          ...BALANCE_TREND_DEFAULT_OPTIONS,
          borderColor: 'transparent',
          pointBackgroundColor: CashFlowBarColors.DARK_RED,
          backgroundColor: CashFlowBarColors.DARK_RED,
          label: formatMessage('operating-costs'),
          id: 'operating-costs',
        },
        // OTHER COST
        {
          ...otherCosts,
          ...DATASET_DEFAULT_OPTIONS,
          ...BALANCE_TREND_DEFAULT_OPTIONS,
          borderColor: 'transparent',
          pointBackgroundColor: CashFlowBarColors.LIGHT_RED,
          backgroundColor: CashFlowBarColors.LIGHT_RED,
          label: formatMessage('other-cash-outflow'),
          id: 'other-cash-outflow',
          type: ChartType.BAR,
        },
      ]
      : []

    return {
      ...data,
      datasets,
      height: 174,
      options: {
        aspectRatio: null,
        maintainAspectRatio: false,
        tooltips: { mode: 'nearest' },
        plugins: {
          BarBackgroundPlugin: {
            color: '#f3f3f3',
          },
        },
      },
      hideHorizontalLines: true,
      currency: referentialCurrency,
    }
  }
}
