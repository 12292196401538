import React, { FC } from "react"
import RecordStyles from "src/frontend/components/RecordList/RecordList.module.less"
import { mergeClasses } from "src/common/utils"
import { FormattedMoney, formatMessage } from "src/frontend/modules/intl"
import TruncatedText from "../TruncatedText"
import { AssetListItemType } from "src/types/investments.types"
import AssetIcon from "./AssetIcon"

interface Props {
  assetListItem: AssetListItemType
  openTransactionsModal: (assetId: string) => void
}

const AssetListItem: FC<Props> = ({ assetListItem, openTransactionsModal }) => {
  console.log(assetListItem)
  return (
    <div
      onClick={() => openTransactionsModal(assetListItem._id)}
      className={mergeClasses(RecordStyles.recordListItem, RecordStyles.extended)}
    >
      <div className={RecordStyles.columnContent}>
        <div className={RecordStyles.columnCategory}>
          <AssetIcon src={assetListItem.logo} />
          <div
            className={mergeClasses(RecordStyles.recordCategory)}
            data-e2e="record-category-extended"
          >
            {assetListItem.name}
          </div>
        </div>

        <div className={RecordStyles.columnAccount}>
          <div className={RecordStyles.recordAccount}>
            <span className={RecordStyles.accountBullet} />
            &nbsp;
            <TruncatedText
              text={`${assetListItem.symbol} | ${assetListItem.exchange}`}
              length={35}
              showPopup
            />
          </div>
        </div>

        <div className={RecordStyles.columnPayee}>
          <div className={RecordStyles.recordPayee}>
            <TruncatedText
              text={formatMessage("investments.shares_amount", {
                amount: assetListItem.totalQuantity,
              })}
              length={40}
              showPopup
            />
          </div>
        </div>
        <div className={RecordStyles.columnAmount}>
          <div className={mergeClasses(RecordStyles.recordAmount)}>
            <FormattedMoney
              value={
                assetListItem.currencyMeta?.baseCurrencyCode
                  ? assetListItem.totalValue / assetListItem.currencyMeta.currencyScaleFactor
                  : assetListItem.totalValue
              }
              currency={assetListItem.currencyMeta?.baseCurrencyCode ?? assetListItem.currencyCode}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default AssetListItem
