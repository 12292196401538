import * as accountRepository from "src/backend/accounts/repository"
import { Interval } from "src/backend/enums"
import { WalletFormValidationError } from "src/backend/errors"
import * as filtersService from "src/backend/filters/service"
import * as filterValidator from "src/backend/filters/validator"
import { intervalTypeToPeriod } from "src/backend/time/time"
import * as logger from "src/common/logger"
import { fetchFilters } from "src/frontend/modules/filters/actions"
import { selectUser } from "src/frontend/modules/user/selectors"
import { Id } from "src/types/CouchDb"
import { FilterType, Period, WebFilter } from "src/types/Filter"
import { User } from "src/types/User"
import { Action, HashMap } from "src/types/common"
import { selectFilter, selectSelectedFilterId } from "src/frontend/modules/filter/selectors"
import { selectViewDefinition } from "src/frontend/scenes/analytics/selectors"
import { updateViewDefinitionByFilter } from "src/frontend/scenes/analytics/helpers"
import { changeViewDefinition } from "src/frontend/scenes/analytics/actions"
import {
  expireAnalyticsStatus,
  expireRecordsStatus,
} from "src/frontend/modules/moduleStatus/actions"
import { trackSaveFilter } from "src/common/mixpanel"

export const SET_FILTER_VALUES = "SET_FILTER_VALUES"
export const SET_INITIAL_FILTER = "SET_INITIAL_FILTER"

export const SET_SELECTED_FILTER = "SET_SELECTED_FILTER"

export const FILTER_FORM_OPEN = "FILTER_FORM_OPEN"
export const FILTER_FORM_CLOSE = "FILTER_FORM_CLOSE"

export const SAVE_FILTER_START = "SAVE_FILTER_START"
export const SAVE_FILTER_SUCCESS = "SAVE_FILTER_SUCCESS"
export const SAVE_FILTER_ERROR = "SAVE_FILTER_ERROR"

export const REMOVE_FILTER_START = "REMOVE_FILTER_START"
export const REMOVE_FILTER_SUCCESS = "REMOVE_FILTER_SUCCESS"
export const REMOVE_FILTER_ERROR = "REMOVE_FILTER_ERROR"

export const TOGGLE_DISPLAY_FILTER = "TOGGLE_DISPLAY_FILTER"

export function applyFilter(filterId: Id): Function {
  return async (dispatch: Function): Promise<Action> => {
    const filter: WebFilter = await filtersService.getFilter(filterId)
    dispatch(setSelectedFilter(filter, filterId))
    return dispatch(refreshData())
  }
}

export function resetFilter(): Function {
  return async (dispatch: Function): Promise<Action> => {
    await dispatch(setInitialFilter())
    return dispatch(refreshData())
  }
}

export function changeFilter(name: string, value: string): Function {
  return (dispatch: Function): Action => {
    dispatch(setFilterValues({ [name]: value }))
    return dispatch(refreshData())
  }
}

export function changeFilterValues(
  values: HashMap<string | Array<string | number> | Period>,
): Function {
  return (dispatch: Function): Action => {
    dispatch(setFilterValues(values))
    return dispatch(refreshData())
  }
}

export function addFilter(): Function {
  return (dispatch: Function) => {
    return dispatch(saveFilter())
  }
}

export function updateSelectedFilter(): Function {
  return (dispatch: Function, getState: Function) => {
    const filterId: Id = selectSelectedFilterId(getState())
    return dispatch(saveFilter(filterId))
  }
}

function saveFilter(filterId?: Id): Function {
  return async (dispatch: Function, getState: Function): Promise<void> => {
    dispatch(saveFilterStart())
    const user: User = selectUser(getState())
    const filter: FilterType = selectFilter(getState())

    try {
      const validationDependencies = await filtersService.getValidationDependencies()
      filterValidator.validate({ ...filter, _id: filterId }, validationDependencies)

      const [savedFilter] = await filtersService.saveFilter(filterId, filter, user)
      await dispatch(fetchFilters())
      dispatch(saveFilterSuccess(filter, savedFilter._id))
      trackSaveFilter()
    } catch (error) {
      if (error instanceof WalletFormValidationError) {
        dispatch(saveFilterError(error.errors))
      } else {
        logger.captureException(error, "filter.saveFilter")
      }
    }
  }
}

export function removeSelectedFilter(): Function {
  return async (dispatch: Function, getState: Function): Promise<void> => {
    dispatch(removeFilterStart())
    const filterId: Id = selectSelectedFilterId(getState())
    try {
      await filtersService.removeFilter(filterId)
      await dispatch(fetchFilters())
      dispatch(resetFilter())
      dispatch(removeFilterSuccess())
    } catch (e) {
      dispatch(removeFilterError())
      logger.captureException(e, "filter.removeSelectedFilter")
    }
  }
}

export function refreshData(): Function {
  return (dispatch: Function, getState: Function): Action => {
    const viewDefinition = selectViewDefinition(getState())
    const filter: FilterType = selectFilter(getState())

    const newViewDefinition = updateViewDefinitionByFilter(viewDefinition, filter)

    dispatch(changeViewDefinition(newViewDefinition))
    dispatch(expireAnalyticsStatus())
    return dispatch(expireRecordsStatus())
  }
}

export function setFilterValues(values: HashMap<string | Array<string | number> | Period>): Action {
  return {
    type: SET_FILTER_VALUES,
    payload: values,
  }
}

export function setInitialFilter(): Function {
  return async (dispatch): Promise<Action> => {
    const interval = Interval.RELATIVE_30_DAYS
    const { start, end } = intervalTypeToPeriod(interval)

    const accounts = await accountRepository.findAll()
    const nonExcludedAccounts = accounts.filter((account) => !account.excludeFromStats)

    const filter = {
      period: {
        start,
        end,
        interval,
      },
      accountIds:
        accounts.length !== nonExcludedAccounts.length
          ? nonExcludedAccounts.map((account) => account._id)
          : undefined,
    }

    return dispatch({
      type: SET_INITIAL_FILTER,
      payload: {
        filter,
      },
    })
  }
}

export function toggleDisplayFilter(name: string): Action {
  return {
    type: TOGGLE_DISPLAY_FILTER,
    payload: name,
  }
}

function setSelectedFilter(filter: WebFilter, filterId: string): Action {
  return {
    type: SET_SELECTED_FILTER,
    payload: { filter, filterId },
  }
}

function saveFilterSuccess(filter: FilterType, filterId: Id): Action {
  return {
    type: SAVE_FILTER_SUCCESS,
    payload: { filter, filterId },
  }
}

function saveFilterStart(): Action {
  return {
    type: SAVE_FILTER_START,
  }
}

function saveFilterError(errors): Action {
  return {
    type: SAVE_FILTER_ERROR,
    payload: errors,
  }
}

function removeFilterStart(): Action {
  return {
    type: REMOVE_FILTER_START,
  }
}

function removeFilterSuccess(): Action {
  return {
    type: REMOVE_FILTER_SUCCESS,
  }
}

function removeFilterError(): Action {
  return {
    type: REMOVE_FILTER_ERROR,
  }
}

export function openFilterForm(): Action {
  return {
    type: FILTER_FORM_OPEN,
  }
}

export function closeFilterForm(): Action {
  return {
    type: FILTER_FORM_CLOSE,
  }
}
