import React, { FC, useEffect, useState } from "react"
import { connect, useDispatch } from "react-redux"
import { selectUserSession } from "../selectors"
import { logoutHard } from "src/frontend/scenes/app/actions"
import { Modal } from "semantic-ui-react"
import { PrimaryButton, SecondaryButton } from "src/frontend/components/Buttons/Buttons"
import { expireUser } from "../../user/actions"
import { formatMessage } from "../../intl"
import { trackAutomaticLogOut, trackAutomaticLogOutCancel } from "src/common/mixpanel"
import { milisecondsToHumanTime } from "src/common/utils"

const mapStateToProps = (state) => ({
  userSession: selectUserSession(state),
})

interface Props {
  userSession: {
    expired: boolean
  }
}

const registerLastActivityListeners = () => {
  ;(window as any).lastActivity = Date.now()
  window.onclick = function () {
    ;(window as any).lastActivity = Date.now()
  }
  window.onmousemove = function () {
    ;(window as any).lastActivity = Date.now()
  }
  window.onkeydown = function () {
    ;(window as any).lastActivity = Date.now()
  }
  window.onscroll = function () {
    ;(window as any).lastActivity = Date.now()
  }
}
const unregisterLastActivityListeners = () => {
  delete (window as any).lastActivity
  window.onclick = null
  window.onmousemove = null
  window.onkeydown = null
  window.onscroll = null
}

const IdleActivityModal: FC<Props> = () => {
  const INACTIVITY_TIME_THRESHOLD = 1200000 // 20 minutes
  const AUTOMATIC_LOGOUT_TIME_THRESHOLD = 120000 // 2 minutes
  const [open, setOpen] = useState(false)
  const [automaticLogoutCountDown, setAutomaticLogoutCountDown] = useState(
    AUTOMATIC_LOGOUT_TIME_THRESHOLD,
  )
  let activityInterval: NodeJS.Timeout
  let countdownInterval: NodeJS.Timeout
  const dispatch = useDispatch()

  const checkAndHandleInactivity = (inactivityThreshold: number) => {
    const currentTime = Date.now()
    if (currentTime - (window as any).lastActivity > inactivityThreshold) {
      clearInterval(activityInterval)
      setOpen(true)
    }
  }

  const handleAutomaticLogout = () => {
    clearInterval(countdownInterval)
    setOpen(false)
    activityInterval = setInterval(() => checkAndHandleInactivity(INACTIVITY_TIME_THRESHOLD), 3000)
    dispatch(logoutHard())
    dispatch(expireUser())
    trackAutomaticLogOut()
  }

  const handleAutomaticLogoutCanel = () => {
    setOpen(false)
    setAutomaticLogoutCountDown(AUTOMATIC_LOGOUT_TIME_THRESHOLD)
    activityInterval = setInterval(() => checkAndHandleInactivity(INACTIVITY_TIME_THRESHOLD), 3000)
    trackAutomaticLogOutCancel()
  }

  // Countdown to automatic logout modal open - inactivity
  useEffect(() => {
    registerLastActivityListeners()
    activityInterval = setInterval(() => checkAndHandleInactivity(INACTIVITY_TIME_THRESHOLD), 1000)

    return () => {
      clearInterval(activityInterval)
      unregisterLastActivityListeners()
    }
  }, [])

  // Countdown to automatic logout
  useEffect(() => {
    if (!open) return

    countdownInterval = setInterval(() => {
      setAutomaticLogoutCountDown((prev) => {
        if (prev === 0) {
          handleAutomaticLogout()
          return AUTOMATIC_LOGOUT_TIME_THRESHOLD
        }
        return prev - 1000
      })
    }, 1000)
    return () => clearInterval(countdownInterval)
  }, [open])

  return (
    <Modal
      open={open}
      size="small"
    >
      <Modal.Header>{formatMessage("app.automaticLogout")}</Modal.Header>
      <Modal.Content>
        <div
          style={{ textAlign: "center", fontWeight: 800, fontSize: "24px", marginBlock: "20px" }}
        >
          {milisecondsToHumanTime(automaticLogoutCountDown)}
        </div>
      </Modal.Content>
      <Modal.Actions>
        <SecondaryButton onClick={handleAutomaticLogout}>
          {formatMessage("auth.logout")}
        </SecondaryButton>
        <PrimaryButton onClick={handleAutomaticLogoutCanel}>
          {formatMessage("app.automaticLogout.cancel")}
        </PrimaryButton>
      </Modal.Actions>
    </Modal>
  )
}

export default connect(mapStateToProps)(IdleActivityModal)
