import { reduceBy } from 'src/common/utils'
import * as service from 'src/backend/currencies/service'

export const CURRENCIES_RECEIVE = 'CURRENCIES_RECEIVE'

export function receiveCurrencies(currenciesArray) {
  const CURRENCY_ID = '_id'
  const currencies = currenciesArray.reduce(reduceBy(CURRENCY_ID), {})
  return { type: CURRENCIES_RECEIVE, payload: currencies }
}

export function fetchCurrencies() {
  return dispatch => {
    return Promise.resolve()
      .then(() => service.fetchCurrencies())
      .then(currencies => dispatch(receiveCurrencies(currencies)))
  }
}
