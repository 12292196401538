import { CashLiquidityData } from 'src/frontend/scenes/dashboard/widgets/CashRatioLiquidity/types'

export function getCashLiquidityValues(cashLiquidity: CashLiquidityData) {
  return [
    {
      value: (cashLiquidity.expenses <= cashLiquidity.availableBalance
        ? cashLiquidity.expenses
        : cashLiquidity.availableBalance),
      // color #RRGGBBAA - 55 alpha
      color: `${cashLiquidity.color}55`,
    },
    {
      value: (cashLiquidity.expenses <= cashLiquidity.availableBalance
        ? cashLiquidity.availableBalance - cashLiquidity.expenses
        : 0),
      color: cashLiquidity.color,
    },
  ]
}
