import { IntervalCompareToType, IntervalGranularityType } from 'src/backend/enums'
import { CashFlowReportRaw, ReportRaw } from 'src/frontend/scenes/analytics/Report/types'
import { HashMap } from 'src/types/common'
import { ChartSubjectType } from 'src/frontend/scenes/analytics/enums'

export type ViewDefinition = {
  subject: ChartSubjectType,
  groupBy: string,
  granularity: IntervalGranularityType,
  chartType: string,
  compareTo: IntervalCompareToType,
}

export type ViewDefinitionUpdate = HashMap<string>

export const GRANULARITY_VIEW_DEFINITION_ITEM = 'granularity'
export const GROUP_BY_VIEW_DEFINITION_ITEM = 'groupBy'
export const CHART_TYPE_VIEW_DEFINITION_ITEM = 'chartType'

export type ViewDefinitionOptions = {
  subject: Array<string>,
  groupBy: Array<string>,
  granularity: Array<IntervalGranularityType>,
  chartType: Array<string>,
  compareTo: IntervalCompareToType[],
}

export type ChartPoint = {
  x: string,
  y: number,
}

export type ChartData = {
  openingBalance?: number,
  closingValue?: number,
  superEnvelopeId?: number,
  accountId?: string,
  data: Array<ChartPoint>,
  comparisonIndex?: number,
}

export type ChartState = {
  datasets: Array<ChartData>,
}

export interface ReportState {
  categoriesReportData: ReportRaw,
  boardCashFlowReportData: CashFlowReportRaw
}

export type AnalyticsState = {
  viewDefinition: ViewDefinition,
  chart: ChartState,
  report: ReportState,
}
