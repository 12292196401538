import React from 'react'
import { FormattedMessage } from 'src/frontend/modules/intl'

interface Props {
    label: string
    target: string
    url: string
    rel?: string
}

const defaultProps: Props = {
    label: '',
    target: '',
    url: '',
    rel: 'noopener noreferrer',
}

const AuthLinks: React.FC<Props> = ({ label, target, url, rel }: Props) => {
    return (
        <a href={url} target={target} rel={rel}>
            <FormattedMessage id={label} />
        </a>
    )
}

AuthLinks.defaultProps = defaultProps

export default AuthLinks
