import { Interval } from "src/backend/enums"

export const MenuType = {
  RANGE: {
    value: "range",
    interval: [
      Interval.RELATIVE_12_MONTHS,
      Interval.RELATIVE_90_DAYS,
      Interval.RELATIVE_30_DAYS,
      Interval.RELATIVE_7_DAYS,
      Interval.ALL_THE_TIME,
      Interval.CUSTOM,
    ],
  },
  WEEK: {
    value: "week",
    interval: [Interval.WEEK],
  },
  MONTH: {
    value: "month",
    interval: [Interval.MONTH],
  },
  YEAR: {
    value: "year",
    interval: [Interval.YEAR],
  },
}

export const isIntervalPredefiedPeriod = (interval: Interval): boolean => {
  return interval === Interval.MONTH || interval === Interval.WEEK || interval === Interval.YEAR
}
