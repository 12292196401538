import React, { useState } from 'react'
import Widget from 'src/frontend/scenes/dashboard/components/Widget/Widget'
import { WidgetView } from 'src/frontend/scenes/dashboard/types'
import {
  SpendingPieOptions,
} from 'src/frontend/scenes/dashboard/widgets/PieAndBarSpending/types'
import { connect } from 'react-redux'
import WidgetBarChart from 'src/frontend/scenes/dashboard/widgets/PieAndBarSpending/WidgetBarChart'
import styles from './SpendingByNatureBars.module.less'
import { isUndefinedOrNull } from 'src/common/utils'
import { widgetHasDeepEqualProps } from 'src/frontend/scenes/dashboard/helpers'
import { Menu } from 'semantic-ui-react'
import { FormattedMessage } from 'src/frontend/modules/intl'
import { EnvelopeCardinality } from 'src/backend/categories/enums'
import { SpendingByNatureBarsData } from 'src/frontend/scenes/dashboard/widgets/SpendingByNatureBars/types'
import { Dataset } from 'src/frontend/components/chart/types'
import Legend from 'src/frontend/components/chart/Legend'

interface Props {
  widget: WidgetView & { data: SpendingByNatureBarsData, options: SpendingPieOptions },
}

const mapDispatchToProps = {}


function SpendingByNatureBars({ widget }: Props) {
  const { data, filter, options } = widget
  const [selectedDataset, selectDataset] = useState<EnvelopeCardinality>(undefined)


  if (!data || !filter || !options) {
    return <Widget.Placeholder />
  }

  return (
    <Widget>
      <Widget.Header title={widget.title} />
      {!isUndefinedOrNull(data.totalCashFlow)
        ? (
          <Widget.Summary
            displayWholeDateRange
            period={filter.period}
            value={data.totalCashFlow}
            currencyCode={data.referentialCurrency.code}
            invertedChange={data.shouldInvertChange}
            intervalType={options.relativeIntervalValue}
            changeValue={data.cashFlowChange}
          />
        )
        : null}
      <Widget.Content
        className={styles.spendingByNature}
      >
        {data.barData.datasets.length > 0 && (
          <>
            <Menu fluid widths={1 + data.barData.datasets.length} compact>
              <Menu.Item
                active={selectedDataset === undefined}
                onClick={() => selectDataset(undefined)}
              >
                <FormattedMessage id="dashboard.widget.cardinality.type.ALL" />
              </Menu.Item>
              {data.barData.datasets.map((dataset) => {
                return (
                  <Menu.Item
                    key={dataset.id}
                    active={dataset.id === selectedDataset}
                    onClick={() => selectDataset(dataset.id)}
                  >
                    <FormattedMessage id={`dashboard.widget.cardinality.type.${EnvelopeCardinality[dataset.id]}`} />
                  </Menu.Item>
                )
              })}
            </Menu>
            <div className={styles.chart}>
              <WidgetBarChart
                currency={data.referentialCurrency}
                labels={data.barData.labels}
                height={174}
                filter={filter}
                datasets={filterBarDatasets(data.barData.datasets, selectedDataset)}
              />
            </div>
            <Legend datasets={data.barData.datasets} />
          </>
        )}
      </Widget.Content>
    </Widget>
  )
}

export function filterBarDatasets(datasets: Dataset[], selectedCardinality: EnvelopeCardinality): Dataset[] {
  return [...datasets].map((dataset) => {

    return {
      ...dataset,
      hidden: !(isUndefinedOrNull(selectedCardinality) || dataset.id === selectedCardinality),
    }
  })
}


export default connect(null, mapDispatchToProps)(React.memo(SpendingByNatureBars, widgetHasDeepEqualProps))
