import React from 'react'
import styles from './InternetExplorer.module.less'
import { getAppName } from 'src/common/environment'
import { mergeClasses } from 'src/common/utils'


export default function InternetExplorer() {
  return (
    <div className={styles.internetExplorer}>
      <div>
        <div className={styles.icon} />
        {/* tslint:disable-next-line:jsx-use-translation-function */}
        <h1>Your browser is not supported</h1>
        {/* tslint:disable-next-line:jsx-use-translation-function */}
        <p>Please use Google Chrome, Mozilla Firefox or Safari to access {getAppName()}</p>
        <div className={styles.buttons}>
          <ButtonLink button="chrome" />
          <ButtonLink button="firefox" />
        </div>
      </div>
    </div>
  )
}

interface ButtonLinkProps {
  button: 'chrome' | 'firefox'
}


function ButtonLink({ button }: ButtonLinkProps) {
  const linkUrl = {
    chrome: 'https://chrome.com',
    firefox: 'https://firefox.com',
  }

  return (
    <a target="_blank" href={linkUrl[button]} className={mergeClasses(styles.button, styles?.[button])}>
      {/* tslint:disable-next-line:jsx-use-translation-function */}
      Get {button}
    </a>
  )
}
