import React from 'react'
import { connect } from 'react-redux'
import { withIntlConsumer } from 'src/frontend/modules/intl/components/withIntlConsumer'
import DateRangePicker from 'src/frontend/components/DateRangePicker'
import { changePeriod } from 'src/frontend/scenes/dashboard/actions'
import { selectDashboardFilter } from 'src/frontend/scenes/dashboard/selectors'
import { FilterType } from 'src/types/Filter'
import { RootState } from 'src/types/State'
import { RelativeIntervalType } from 'src/backend/enums'

const mapStateToProps = (state: RootState) => {
  return {
    filter: selectDashboardFilter(state),
  }
}

const mapDispatchToProps = (dispatch: Function) => {
  return {
    changeFilter: (period) => dispatch(changePeriod(period)),
  }
}

type Props = {
  filter: FilterType,
  changeFilter: Function,
  locale: string,
}

function Filter({ filter, changeFilter, locale }: Props) {
  const { period } = filter
  return (
    <DateRangePicker
      period={period}
      locale={locale}
      intervalType={RelativeIntervalType.RELATIVE}
      preferText
      onChange={changeFilter}
    />
  )
}

export default withIntlConsumer(connect(mapStateToProps, mapDispatchToProps)(Filter))
