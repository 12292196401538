import { Option, OptionId } from 'src/frontend/components/Filter/types'
import { CategoryFilterGroup } from 'src/frontend/modules/filter/selectors'


export function filterVisibleOptions(options: Array<Option>): Array<OptionId> {
  return options.filter(option => option.visible).map((option: Option) => option._id)
}

export function flattenOptionsRecursively(options: Array<Option>): Array<Option> {
  return options.reduce((acc, value) => {
    if (value.childrenOptions) {
      return [
        ...acc,
        value,
        ...flattenOptionsRecursively(value.childrenOptions),
      ]
    } else {
      return [...acc, value]
    }
  }, [])
}

export function isCategoryGroup(group: string): boolean {
  return group === CategoryFilterGroup.CATEGORY
}

export function isEnvelopeGroup(group: string): boolean {
  return group === CategoryFilterGroup.ENVELOPE
}

export function isSuperEnvelopeGroup(group: string): boolean {
  return group === CategoryFilterGroup.SUPER_ENVELOPE
}

export function updateVisibility(itemId: OptionId, allItemsVisible?: boolean, forceValue?: boolean) {
  return (item: Option): Option => {
    let visible = false

    if (forceValue !== undefined) {
      visible = forceValue
    } else if (item._id === itemId) {
      visible = allItemsVisible ? true : !item.visible
    } else {
      visible = allItemsVisible ? false : item.visible
    }

    return {
      ...item,
      visible,
    }
  }
}
