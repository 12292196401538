import { IntegrationProviders } from 'src/frontend/scenes/integrations/providersList/types'
import {
  OVERRIDE_NEW_CONNECTION,
  CHECK_STATUS_START,
  CLEAR_ERROR,
  CONNECTION_HIDE_MFA,
  CONNECTION_SHOW_MFA,
  CREATE_ACCOUNTS_ERROR,
  CREATE_ACCOUNTS_START,
  CREATE_CONNECTION_START,
  CREATE_CONNECTION_SUCCESS,
  GET_ACCOUNTS_SUCCESS,
  INTEGRATIONS_FORM_CLOSE,
  INTEGRATIONS_FORM_OPEN,
  LOGIN_PROVIDER_START,
  PERSONAL_INFORMATION_CHANGE_VALUE,
  PERSONAL_INFORMATION_SAVE_START,
  PERSONAL_INFORMATION_SAVE_SUCCESS,
  PERSONAL_INFORMATION_SHOW,
  SET_ERROR,
} from "src/frontend/scenes/integrations/newConnection/actions"
import { Action } from "src/types/common"
import { IntegrationConnection } from "src/frontend/scenes/integrations/newConnection/types"
import { NewIntegrationsStatus } from "src/frontend/scenes/integrations/enums"
import {
  CLEAR_PROVIDER_SELECTION,
  FETCH_PROVIDER_DETAILS_SUCCESS,
} from "src/frontend/scenes/integrations/providersList/actions"
import { Id } from "src/types/CouchDb"
import IntegrationProvider = IntegrationProviders.IntegrationProvider
import IntegrationRecipeGroup = IntegrationProviders.IntegrationRecipe.IntegrationRecipeGroup
import IntegrationAccount = IntegrationConnection.IntegrationAccount
import { VerifiedProfile } from "src/types/User"

export interface NewConnectionState {
  integrationProvider: IntegrationProvider
  integrationAccounts: IntegrationAccount[]
  pendingConnectionInProgress: boolean
  pendingConnectionMFA: IntegrationRecipeGroup[]
  accountsLoading: boolean
  modalOpen: boolean
  error: string
  integrationStatus: NewIntegrationsStatus
  // preset Wallet Account to connect with
  accountId: Id
  personalDetailsFormValues: VerifiedProfile
  personalDetailsLoading: boolean
}

const initConnectionsState = {
  integrationProvider: null,
  integrationAccounts: null,
  pendingConnectionInProgress: false,
  pendingConnectionMFA: null,
  accountsLoading: false,
  modalOpen: false,
  error: null,
  integrationStatus: NewIntegrationsStatus.PROVIDER_SELECT,
  accountId: null,
  personalDetailsFormValues: null,
  personalDetailsLoading: false,
}

export default function newConnection(
  state: NewConnectionState = initConnectionsState,
  action: Action,
): NewConnectionState {
  switch (action.type) {
    case OVERRIDE_NEW_CONNECTION: {
      return action.payload
    }
    case CREATE_CONNECTION_START: {
      return {
        ...state,
        integrationProvider: action.payload,
        pendingConnectionInProgress: true,
        error: null,
      }
    }

    case CREATE_CONNECTION_SUCCESS: {
      return {
        ...state,
        error: null,
        accountsLoading: true,
      }
    }

    case GET_ACCOUNTS_SUCCESS: {
      return {
        ...state,
        integrationAccounts: action.payload,
        integrationStatus: NewIntegrationsStatus.INTEGRATED_ACCOUNT_SELECT,
        accountsLoading: false,
      }
    }

    case CREATE_ACCOUNTS_START: {
      return {
        ...state,
        accountsLoading: true,
        error: null,
      }
    }

    case CREATE_ACCOUNTS_ERROR: {
      return {
        ...state,
        accountsLoading: false,
      }
    }

    case CLEAR_PROVIDER_SELECTION: {
      return initConnectionsState
    }

    case CHECK_STATUS_START: {
      return { ...state, integrationStatus: NewIntegrationsStatus.LOADING }
    }

    case CONNECTION_SHOW_MFA: {
      return {
        ...state,
        pendingConnectionMFA: action.payload,
        integrationStatus: NewIntegrationsStatus.MFA_FORM,
      }
    }

    case CONNECTION_HIDE_MFA: {
      return {
        ...state,
        pendingConnectionMFA: null,
        integrationStatus: NewIntegrationsStatus.LOADING,
      }
    }

    case SET_ERROR: {
      return {
        ...state,
        modalOpen: true,
        error: action.payload.errorMessage,
        integrationStatus: action.payload.integrationStatus,
        pendingConnectionInProgress: false,
        accountsLoading: false,
      }
    }

    case CLEAR_ERROR: {
      return {
        ...state,
        error: null,
      }
    }

    case FETCH_PROVIDER_DETAILS_SUCCESS: {
      return { ...state, integrationStatus: NewIntegrationsStatus.CREDENTIALS_FORM }
    }

    case LOGIN_PROVIDER_START: {
      return { ...state, integrationStatus: NewIntegrationsStatus.LOADING, error: null }
    }

    case INTEGRATIONS_FORM_OPEN: {
      return {
        ...state,
        modalOpen: true,
        // don't let open modal override previously set accountId with null
        accountId: action.payload || state.accountId,
      }
    }

    case INTEGRATIONS_FORM_CLOSE: {
      return { ...state, modalOpen: false }
    }

    case PERSONAL_INFORMATION_SHOW: {
      return {
        ...state,
        integrationStatus: NewIntegrationsStatus.PERSONAL_INFORMATION,
        modalOpen: true,
        personalDetailsFormValues: action.payload,
      }
    }

    case PERSONAL_INFORMATION_CHANGE_VALUE: {
      return {
        ...state,
        personalDetailsFormValues: {
          ...state.personalDetailsFormValues,
          ...action.payload,
        },
      }
    }

    case PERSONAL_INFORMATION_SAVE_START: {
      return {
        ...state,
        personalDetailsLoading: true,
      }
    }

    case PERSONAL_INFORMATION_SAVE_SUCCESS: {
      return {
        ...state,
        personalDetailsLoading: false,
        personalDetailsFormValues: null,
      }
    }

    default: {
      return state
    }
  }
}
