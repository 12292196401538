import * as helpers from 'src/common/helpers'
import { CashFlowData } from 'src/frontend/scenes/dashboard/widgets/CashFlowBar/types'
import { Currency } from 'src/types/Currency'

export function getCashFlow(referentialCurrency: Currency) {
  return (data: CashFlowData) => {
    if (!data) {
      return null
    }

    const { refIncome, refExpense } = data.currentPeriod

    const previousPeriodCashFlow = data.previousPeriod.refIncome - data.previousPeriod.refExpense
    const currentPeriodCashFlow = refIncome - refExpense

    return {
      refIncome,
      refIncomeChange: helpers.countPercentChange(data.previousPeriod.refIncome, refIncome),
      refExpense,
      refExpenseChange: helpers.countPercentChange(data.previousPeriod.refExpense, refExpense),
      refCashFlow: currentPeriodCashFlow,
      refCashFlowChange: helpers.countPercentChange(previousPeriodCashFlow, currentPeriodCashFlow),
      referentialCurrency,
    }
  }
}
