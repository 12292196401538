import React from 'react'
import styles from './DashboardOnBoarding.module.less'
import { connect } from "react-redux"
import AccountFormSelectionIcon from "src/frontend/scenes/settings/accounts/components/AccountFormSelectionIcon"
import { openAddRecordForm } from "src/frontend/scenes/records/recordForm/actions"
import { RouteComponentProps, withRouter } from "react-router"
import FormattedMessage from "src/frontend/modules/intl/components/FormattedMessage"
import { openConnectBankForm } from "src/frontend/scenes/settings/accounts/actions"
import { isAppBoard } from "src/common/environment"

interface Props extends RouteComponentProps {
  onManualClick: () => void
  onBankClick: () => void
}

const mapDispatchToProps = {
  onManualClick: openAddRecordForm,
  onBankClick: openConnectBankForm,
}

function DashboardOnBoarding({ history, onManualClick, onBankClick }: Props) {
  return (
    <div className={styles.dashboardOnBoarding}>
      <h2>
        <FormattedMessage id="onBoarding.dashboard.title" />
      </h2>
      <p>
        <FormattedMessage id="onBoarding.dashboard.text" />
      </p>
      <div className={styles.methodSelection}>
        <AccountFormSelectionIcon
          iconName="account-bank"
          titleString="settings.accounts.new.type.bank.title"
          description="settings.accounts.new.type.bank.text"
          onClick={() => onBankClick()}
        />

        <AccountFormSelectionIcon
          iconName="account-imports"
          titleString="settings.accounts.new.type.imports.title"
          description="settings.accounts.new.type.imports.text"
          onClick={() => history.push("/imports")}
        />

        <AccountFormSelectionIcon
          iconName="account-manual"
          titleString="settings.accounts.new.type.manual.title"
          description="onBoarding.manual_input"
          onClick={() => onManualClick()}
        />

        {!isAppBoard() && (
          <AccountFormSelectionIcon
            iconName="account-investments"
            titleString="settings.accounts.new.type.investments.title"
            description="settings.accounts.new.type.investments.text"
            badge="settings.accounts.new.type.investments.badge"
            disabled
          />
        )}
      </div>
    </div>
  )
}

export default withRouter(connect(null, mapDispatchToProps)(DashboardOnBoarding))
