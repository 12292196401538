import React from 'react'
import styles from './MobileAppsOnBoarding.module.less'
import WalletDownloadIcons from 'src/frontend/components/WalletDownloadIcons'
import { hideMobileAppsBanner } from 'src/frontend/modules/webConfig/actions'
import { connect } from 'react-redux'
import { BasicIcon } from 'src/frontend/components/Icons/Icons'
import { FormattedMessage } from 'src/frontend/modules/intl'

const mapDispatchToProps = {
  handleHide: hideMobileAppsBanner,
}

interface Props {
  handleHide: () => void
}

function MobileAppsOnBoarding({ handleHide }: Props) {
  return (
    <div className={styles.mobileAppsOnBoarding}>
      <BasicIcon className={styles.closeButton} name="close" size="35px" onClick={handleHide} />
      <div className={styles.content}>
        <h2>
          <FormattedMessage id="onBoarding.mobile-apps.title" />
        </h2>
        <h3>
          <FormattedMessage id="onBoarding.mobile-apps.text" />
        </h3>
        <div>
          <WalletDownloadIcons inline />
        </div>
      </div>
      <div className={styles.phoneImage} />
    </div>
  )
}

export default connect(null, mapDispatchToProps)(MobileAppsOnBoarding)
