export namespace RestrictedProviders {
  export interface RestrictedProvider {
    loginIds: string[]
    providerName: string
    status: ProviderStatus
    possibleIssues: PossibleIssue[]
    knowledgeBaseUrl?: string
    validUntil: number
    supportedByOtherProvider?: boolean
  }

  export enum ProviderStatus {
    Active = 0,
    TemporaryDisabled = 1,
    Disabled = 2,
  }

  enum PossibleIssue {
    General = 0,
    TransactionDuplicity = 1,
  }
}
