import { createSelector } from 'reselect'
import * as envelopes from 'src/backend/categories/envelopes'
import { DefaultRecord } from 'src/backend/records/Record'
import * as accountsSelectors from 'src/frontend/modules/accounts/selectors'
import * as categoriesSelectors from 'src/frontend/modules/categories/selectors'
import * as currenciesSelectors from 'src/frontend/modules/currencies/selectors'
import * as contactsSelectors from 'src/frontend/modules/contacts/selectors'
import * as labelsSelectors from 'src/frontend/modules/labels/selectors'
import { RootState } from 'src/types/State'
import { RecordListState } from 'src/frontend/scenes/records/recordList/reducer'
import { RecordListItemRecord } from 'src/frontend/components/RecordList/types'
import { Category, CategoryDocument } from 'src/types/Category'
import { Currency } from 'src/types/Currency'
import { Label } from 'src/types/Label'
import { Record } from 'src/types/Record'
import { Account } from 'src/types/Account'
import { RecordListOrderBy } from 'src/frontend/scenes/records/recordList/enums'
import {
  CATEGORY_ICON_CSS_PREFIX,
  ERROR_CATEGORY,
  UNASSIGNED_CATEGORY_ICON,
} from 'src/frontend/scenes/records/recordList/constants'
import { selectRecords } from 'src/frontend/scenes/records/selectors'
import { compareStrings, isUndefined } from 'src/common/utils'
import { Contact } from 'src/types/Contact'
import ContactDocument = Contact.ContactDocument
import ContactView = Contact.ContactView
import { isAppBoard } from 'src/common/environment'
import _omitBy from 'lodash/omitBy'
import { HashMap } from 'src/types/common'
import { FilterType } from 'src/types/Filter'
import { formatMessage } from 'src/frontend/modules/intl/i18n'
import { ContactColor } from 'src/backend/contacts/constants'
import { isConnected } from 'src/backend/accounts/helpers'

export const selectRecordList = (state: RootState): RecordListState => selectRecords(state).recordList

export const selectRecordListRecords = (state: RootState) => selectRecordList(state).records
export const selectRecordsPreview = (state: RootState) => selectRecordList(state).recordsPreview
export const selectAmountRange = (state: RootState) => selectRecordList(state).amountRange
export const selectIsRecordListFetching = (state: RootState) => selectRecordList(state).isFetching

export const selectPlannedPayments = createSelector(
  [
    (state: RootState) => selectRecordList(state).plannedPayments,
  ],
  addRRuleText,
)

export const selectOrderBy = (state: RootState): RecordListOrderBy => selectRecordList(state).order


export const selectIsPreviewOpen = (state: RootState): boolean => selectRecordList(state).recordsPreviewOpen
export const selectIsPreviewFilter = (state: RootState): FilterType => selectRecordList(state).recordsPreviewFilter


export const selectAggregatedRecords: (state: RootState) => RecordListItemRecord[] = createSelector(
  [
    accountsSelectors.selectAllAccounts,
    categoriesSelectors.selectFlattenedCategories,
    currenciesSelectors.selectCurrencies,
    labelsSelectors.selectAllLabels,
    contactsSelectors.selectContacts,
    currenciesSelectors.selectReferentialCurrency,
    selectOrderBy,
    selectRecordListRecords,
  ],
  aggregateRecords,
)

export const selectAggregatedPlannedPayments: (state: RootState) => RecordListItemRecord[] = createSelector(
  [
    accountsSelectors.selectAllAccounts,
    categoriesSelectors.selectFlattenedCategories,
    currenciesSelectors.selectCurrencies,
    labelsSelectors.selectAllLabels,
    contactsSelectors.selectContacts,
    currenciesSelectors.selectReferentialCurrency,
    selectOrderBy,
    selectPlannedPayments,
  ],
  aggregateRecords,
)

export const selectAggregatedRecordsPreview: (state: RootState) => RecordListItemRecord[] = createSelector(
  [
    accountsSelectors.selectAllAccounts,
    categoriesSelectors.selectFlattenedCategories,
    currenciesSelectors.selectCurrencies,
    labelsSelectors.selectAllLabels,
    contactsSelectors.selectContacts,
    currenciesSelectors.selectReferentialCurrency,
    () => RecordListOrderBy.RECORD_DATE_DESC,
    selectRecordsPreview,
  ],
  aggregateRecords,
)

export function getRecordCategory(record: Record, categories: Category[]) {
  const category = categories.find((cat) => cat._id === record.categoryId)
  const envelope = envelopes.getEnvelopeById(record.envelopeId || category?.envelopeId)
  const superEnvelope = envelope ? envelopes.getEnvelopeById(envelope.parentId) : null

  if (!envelope && !category) {
    console.warn('Failed to retrieve category for record: ', record)
    return ERROR_CATEGORY
  }

  if (envelope && !superEnvelope) {
    console.warn('Cannot find super envelope for given envelope: ', envelope)
    return ERROR_CATEGORY
  }

  if (!envelope && category.envelopeId) {
    console.warn('Cannot find envelope for given category: ', category)
    return ERROR_CATEGORY
  }

  const categoryName = category?.name || formatMessage(envelope.localizationString)

  // 1st priority - custom category icon name, 2nd priority category icon name, 3rd priority envelope icon name,
  // 4th priority - unknown icon name
  const categoryIcon = (category?.iconName && (CATEGORY_ICON_CSS_PREFIX + category.iconName))
    || (envelope?.iconName && (CATEGORY_ICON_CSS_PREFIX + envelope.iconName))
    || (CATEGORY_ICON_CSS_PREFIX + UNASSIGNED_CATEGORY_ICON)

  const color = category?.color || envelope?.color || superEnvelope?.color


  return {
    categoryName,
    categoryIcon,
    color,
  }
}

export function getRecordContact(record: Record, contacts: HashMap<Contact.ContactDocument>): ContactView {
  if (!record.contactId) {
    return undefined
  }

  const contact = contacts[record.contactId]

  if (!contact) {
    console.warn('Failed to retrieve contact for record', record)
    return undefined
  }

  return {
    ...contact,
    contactTypeName: formatMessage(`contacts.type.${Contact.Type[contact.type]}`),
    color: ContactColor[contact.type],
  }
}

export function getRecordAccount(record: Record, accounts: HashMap<Account>) {
  const account = accounts[record.accountId]

  if (!account) {
    console.warn('Failed to retrieve account for record %o', record)
    return { name: 'Error' } as Account
  }

  return account
}

export function getRecordCurrency(record: Record, currencies: HashMap<Currency>) {
  const currency = currencies[record.currencyId]

  if (!currency) {
    console.warn('Failed to retrieve currency for record %o', record)
    return { code: 'ERR' } as Currency
  }

  return currency
}

export function insertHashTags(note: string, tags: HashMap<Label>) {
  if (note && note.length > 0) {
    return note.replace(/\[~~hid:([^\]]+)]/g, (tagPlaceholder, tagId) => {
      const tag = tags[tagId]
      return tag ? tagPlaceholder.replace(tagPlaceholder, `#${tag.name}`) : tagPlaceholder
    })
  }

  return DefaultRecord().note
}


function createOrderByFunction(attribute: string, direction: string) {
  const compareRefAmount = (recordA?, recordB?) => recordA.refAmount - recordB.refAmount
  const compareDate = (recordA?, recordB?) => recordA.recordDate - recordB.recordDate
  const compareId = (recordA, recordB) => compareStrings(recordA._id, recordB._id)
  const compareTransferId = (recordA, recordB) => compareStrings(recordA.transferId, recordB.transferId)

  const orderByDate = (recordA, recordB) => {
    const recordsPair: RecordListItemRecord[] = direction === 'ASC' ? [recordA, recordB] : [recordB, recordA]
    return compareDate(...recordsPair)
      || compareTransferId(recordA, recordB)
      || compareId(recordA, recordB)
  }

  const orderByAmount = (recordA, recordB) => {
    const recordsPair = direction === 'ASC' ? [recordA, recordB] : [recordB, recordA]
    return compareRefAmount(...recordsPair) || compareTransferId(recordA, recordB) || compareId(recordA, recordB)
  }

  return attribute === 'recordDate' ? orderByDate : orderByAmount
}

function createSortFunction(orderBy: RecordListOrderBy) {
  const orderByParams = orderBy.split('-')
  const attribute = orderByParams[0]
  const direction = orderByParams[1]

  const orderByFunction = createOrderByFunction(attribute, direction)

  return (a, b) => orderByFunction(a, b)
}

export function aggregateRecords(
  accounts: HashMap<Account>,
  categories: CategoryDocument[],
  currencies: HashMap<Currency>,
  labels: HashMap<Label>,
  contacts: HashMap<ContactDocument>,
  referentialCurrency: Currency,
  orderBy: RecordListOrderBy,
  records: Record[],
) {
  if (records) {
    return records.map((record: Record): RecordListItemRecord => {
      const category = getRecordCategory(record, categories)
      const account = getRecordAccount(record, accounts)
      const currency = getRecordCurrency(record, currencies)
      const note = insertHashTags(record.note, labels)
      const referentialCurrencyCode = !currency.referential ? referentialCurrency.code : null
      const accountIsConnected = isConnected(account)

      // BOARD only
      const contact = isAppBoard() ? getRecordContact(record, contacts) : undefined

      return _omitBy({
        ...record,
        ...category,
        accountName: account.name,
        accountColor: account.color,
        currencyCode: currency.code,
        accountIsConnected,
        contact,
        referentialCurrencyCode,
        note,
      }, isUndefined)
    }).sort(createSortFunction(orderBy))
  }

  return []
}

export function addRRuleText(plannedPayments) {
  return plannedPayments.map(plannedPayment => {
    if (plannedPayment.recurrenceRule) {
      return {
        ...plannedPayment,
      }
    }
    return plannedPayment
  })
}
