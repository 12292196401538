import { getWidgetComponentByWidgetId } from 'src/frontend/scenes/dashboard/components/helpers'
import PremiumChecker from 'src/frontend/components/PremiumChecker/PremiumChecker'
import { isPremium } from 'src/backend/user/service'
import { WidgetId, WidgetPremiumOption } from 'src/backend/dashboard/enums'
import { FormattedMessage } from 'src/frontend/modules/intl'
import { Message } from 'semantic-ui-react'
import React from 'react'
import { User } from 'src/types/User'
import { HashMap } from 'src/types/common'
import { WidgetType, WidgetVariant } from 'src/frontend/scenes/dashboard/types'
import { isAppBoard } from 'src/common/environment'
import styles from './AddWidgetContent.module.less'

interface Props {
  dummyWidgets: HashMap<WidgetType[]>
  onAddWidget: (id: WidgetId, variant: WidgetVariant) => void
  user: User
}

export default function AddWidgetContent({ user, dummyWidgets, onAddWidget }: Props) {
  const widgetItems = Object.entries(dummyWidgets)
    // filtering out upcoming planned payments and cash ratio liquidity widgets due to business requirements https://budgetbakers.atlassian.net/browse/WEB-516
    .filter(([key, value]) => {
      return key !== "outlook"
    })
    .map(([key, value]) => {
    const widgetComponents = value.map((widget) => {
      const WidgetComponent = getWidgetComponentByWidgetId(widget.widgetId)
      return (
        <PremiumChecker
          key={`${widget.widgetId}${widget.variant}`}
          featureName="premium_checker.dashboard_widget"
          enabled={isPremium(user) || isAppBoard() || !WidgetPremiumOption[widget.widgetId]}
        >
          <div onClick={() => onAddWidget(widget.widgetId, widget.variant)}>
            <WidgetComponent
              widget={widget}
            />
            <span className={styles.addLabel}>+</span>
          </div>
        </PremiumChecker>
      )
    })

    return (
      <div key={key}>
        <h2 className={styles.widgetGalleryHeader}><FormattedMessage id={`dashboard.widgets.${key}`} /></h2>
        <div className={styles.widgetGalleryContainer}>
          {widgetComponents}
        </div>
      </div>
    )
  })

  const noWidgetsAvailable = (
    <div>
      <FormattedMessage id="dashboard.widgets.gallery.empty-placeholder" />
    </div>
  )

  const widgetGallery = (
    <div>
      <Message warning><FormattedMessage id="dashboard.widgets.gallery.notice" /></Message>
      {widgetItems}
    </div>
  )

  return widgetItems.length > 0 ? widgetGallery : noWidgetsAvailable
}

