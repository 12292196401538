import React from 'react'
import styles from './PlanSelect.module.less'
import PlanSelect from 'src/frontend/scenes/billing/product/components/PlanSelect'
import { BillingUIMethod } from 'src/frontend/scenes/billing/enums'

export default function PlanSelectModalContent() {
  return (
    <div className={styles.content}>
      <PlanSelect method={BillingUIMethod.MODAL} />
    </div>
  )
}
