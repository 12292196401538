import { isAppBoard } from 'src/common/environment'
export const COUNT_INCREMENT = 30


export const CATEGORY_ICON_CSS_PREFIX = 'category-icon category-icon-'
export const UNASSIGNED_CATEGORY_ICON = isAppBoard() ? 'question-mark-filled' : 'ghost-filled'
export const ERROR_CATEGORY = {
  color: '#8BC34A',
  categoryName: 'Unknown',
  categoryIcon: CATEGORY_ICON_CSS_PREFIX + UNASSIGNED_CATEGORY_ICON,
}
