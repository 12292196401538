import React, { SyntheticEvent } from 'react'
import { Button } from 'semantic-ui-react'
import { isAppBoard } from 'src/common/environment'
import { isUndefinedOrNull } from 'src/common/utils'

type CommonProps = {
  color?: 'blue' | 'red'
  fluid?: boolean,
  disabled?: boolean,
  loading?: boolean,
  children?: string | React.ReactNode
  className?: string
  size?: 'large' | 'big'
  type?: string

  onClick?: (e: SyntheticEvent) => void,
}

type CustomElementProps = {
  as: NonNullable<any>,
  [key: string]: any,
}

type Props = (CommonProps & Required<CustomElementProps>) | Omit<CommonProps, 'as'>

const DEFAULT_PROPS = {
  color: undefined,
  fluid: undefined,
  disabled: false,
  loading: false,
  children: undefined,
  className: undefined,
  type: undefined,
  size: undefined,
  as: undefined,
  onClick: undefined,
}

PrimaryButton.defaultProps = DEFAULT_PROPS

export function PrimaryButton({
  color,
  fluid,
  disabled,
  size,
  loading,
  children,
  type,
  className,
  onClick,
  ...props
}: Props) {
  const buttonType = isUndefinedOrNull((props as CustomElementProps).as)
    ? type || 'button'
    : type
  return (
    <Button
      primary={!color}
      className={className}
      color={color}
      fluid={fluid}
      type={buttonType}
      size={size}
      circular={!isAppBoard()}
      disabled={disabled}
      loading={loading}
      content={children}
      onClick={onClick}
      {...props}
    />
  )
}

SecondaryButton.defaultProps = {
  ...DEFAULT_PROPS,
  color: 'blue',
}

export function SecondaryButton({
  color,
  fluid,
  size,
  disabled,
  loading,
  children,
  type,
  className,
  onClick,
  ...props
}: Props) {
  const buttonType = isUndefinedOrNull((props as CustomElementProps).as)
    ? type || 'button'
    : type
  return (
    <Button
      type={buttonType}
      className={className}
      inverted
      color={color}
      size={size}
      circular={!isAppBoard()}
      fluid={fluid}
      disabled={disabled}
      loading={loading}
      content={children}
      onClick={onClick}
      {...props}
    />
  )
}

ComplementaryButton.defaultProps = {
  ...DEFAULT_PROPS,
  icon: false,
}

type ComplementaryButtonProps = Props & {
  icon?: boolean,
}

export function ComplementaryButton({
  color,
  type,
  className,
  fluid,
  icon,
  disabled,
  loading,
  children,
  onClick,
  ...props
}: ComplementaryButtonProps) {
  const buttonType = isUndefinedOrNull((props as CustomElementProps).as)
    ? type || 'button'
    : type
  return (
    <Button
      type={buttonType}
      className={className}
      color={color}
      compact
      circular={!isAppBoard()}
      fluid={fluid}
      disabled={disabled}
      loading={loading}
      content={children}
      icon={icon}
      onClick={onClick}
      {...props}
      size="mini"
    />
  )
}
