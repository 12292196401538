import * as recordsService from 'src/backend/records/service'
import { Id } from 'src/types/CouchDb'
import { Action } from 'src/types/common'
import { Record } from 'src/types/Record'


export const ACCOUNT_REQUEST_RECORDS = 'ACCOUNT_REQUEST_RECORDS'
export const ACCOUNT_RECEIVE_RECORDS = 'ACCOUNT_RECEIVE_RECORDS'

export function fetchAccountsRecords(contactId: Id) {
  return (dispatch: Function) => {
    dispatch(fetchContactRecordsStart())
    setTimeout(async () => {
      const records = await recordsService.fetchRecordsByFilter({ contactIds: [contactId] })
      dispatch(fetchContactRecordsFinish(records))
    }, 0)
  }
}

function fetchContactRecordsStart(): Action {
  return {
    type: ACCOUNT_REQUEST_RECORDS,
  }
}

function fetchContactRecordsFinish(records): Action<Record[]> {
  return {
    type: ACCOUNT_RECEIVE_RECORDS,
    payload: records,
  }
}
