import React, { SyntheticEvent } from 'react'
import { connect } from 'react-redux'
import { Divider, Form, Menu, Message, Modal } from 'semantic-ui-react'
import { formatMessage, FormattedMessage, FormattedMessageLabel } from 'src/frontend/modules/intl'
import * as selectors from '../selectors'
import * as actions from '../actions'
import styles from 'src/frontend/scenes/settings/magicRules/components/MagicRuleForm.module.less'
import { FormType } from 'src/frontend/scenes/settings/magicRules/enums'
import { RootState } from 'src/types/State'
import { HashMap } from 'src/types/common'
import SelectCategory from 'src/frontend/components/Categories/SelectCategory'
import { MagicRulesFormValues } from 'src/frontend/scenes/settings/magicRules/types'
import { Category, SuperEnvelope } from 'src/types/Category'
import { selectSortedContacts } from 'src/frontend/modules/contacts/selectors'
import * as categoriesSelectors from 'src/frontend/modules/categories/selectors'
import * as labelsSelectors from 'src/frontend/modules/labels/selectors'
import { Label } from 'src/types/Label'
import { Contact } from 'src/types/Contact'
import SelectMultiple from 'src/frontend/components/SelectMultiple'
import { isAppBoard } from 'src/common/environment'
import SelectContact from 'src/frontend/scenes/records/components/SelectContact'
import { RecordType } from 'src/backend/enums'
import { SemanticData } from 'src/types/Semantic-ui'
import { isUndefined } from 'src/common/utils'
import PremiumChecker from 'src/frontend/components/PremiumChecker/PremiumChecker'
import { NAME_MAX_LENGTH } from 'src/backend/common/validator'
import { selectAccountsAsArray } from 'src/frontend/modules/accounts/selectors'
import { Account } from 'src/types/Account'
import ContactView = Contact.ContactView
import MagicRuleUpdateSimilar from 'src/frontend/scenes/settings/magicRules/components/MagicRuleUpdateSimilar'
import { getFormTitle } from 'src/frontend/scenes/settings/magicRules/helpers'
import { selectFlattenedMagicRulesKeywords } from 'src/frontend/modules/magicRules/selectors'
import KeywordsSelect from 'src/frontend/scenes/settings/magicRules/components/KeywordsSelect'
import { PrimaryButton } from 'src/frontend/components/Buttons/Buttons'


function mapStateToProps(state: RootState) {
  const form = selectors.selectMagicRules(state)
  return {
    formType: form.formType,
    formValues: form.formValues,
    formOpen: form.isOpen,
    errors: form.errors,
    loading: form.loading,
    similarRecords: selectors.selectAggregatedSimilarRecords(state),
    canEditRules: selectors.selectCanAddEditRule(state),
    selectOptions: {
      accounts: selectAccountsAsArray(state),
      contacts: selectSortedContacts(state),
      categoriesHierarchy: categoriesSelectors.selectCategoriesHierarchy(state),
      categories: categoriesSelectors.selectFlattenedCategoriesWithoutUnknownCategories(state),
      labels: labelsSelectors.selectSortedLabels(state),
      magicRulesKeywords: selectFlattenedMagicRulesKeywords(state),
      keywordsGuide: form.keywordsGuide,
    },
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    handleNameChange(_e, { name, value }) {
      dispatch(actions.changeFormFieldValue(name, value))
    },
    handleFieldChange(_e, { name, value }) {
      dispatch(actions.changeFormFieldValue(name, value), true)
    },
    handleRadioChange(_e, { name, checked }) {
      dispatch(actions.changeFormFieldValue(name, checked))
    },
    handleCloseForm() {
      dispatch(actions.closeForm())
    },
    handleSaveLabel() {
      dispatch(actions.saveMagicRule())
    },
  }
}

interface Props {
  formValues: MagicRulesFormValues
  errors: HashMap<string>
  formType: FormType
  formOpen: boolean
  canEditRules: boolean
  loading: boolean,
  selectOptions: {
    accounts: Account[]
    categoriesHierarchy: SuperEnvelope[],
    categories: Category[],
    labels: Label[],
    contacts: ContactView[],
    magicRulesKeywords: string[]
    keywordsGuide: string[],
  },
  handleCloseForm: (MouseEvent) => void
  handleFieldChange: (e: SyntheticEvent, data: SemanticData) => void
  handleRadioChange: (e: SyntheticEvent, data: SemanticData) => void
  handleNameChange: (e: SyntheticEvent, data: SemanticData) => void
  handleSaveLabel: (MouseEvent) => void
}


function MagicRuleForm({
  formValues,
  errors,
  formType,
  formOpen,
  loading,
  canEditRules,
  selectOptions,
  handleCloseForm,
  handleFieldChange,
  handleNameChange,
  handleSaveLabel,
}: Props) {

  const labelOptions = selectOptions && selectOptions.labels && selectOptions.labels.filter((label) => {
    return !label.archived || formValues && formValues.labelIds && formValues.labelIds.includes(label._id)
  })

  const formName = formatMessage(getFormTitle(formType))


  return (
    <Modal size="small" open={formOpen} onClose={handleCloseForm}>
      <Modal.Header>
        {formName}
        <span className="modal-close" onClick={handleCloseForm} />
      </Modal.Header>
      <Modal.Content>
        {formType !== FormType.SELECT_SIMILAR_RECORDS && formValues ? (
          <div className={styles.addRuleForm}>
            <Form error>
              <div className="field">
                <Form.Input
                  label={<FormattedMessageLabel id="form.name" />}
                  name="name"
                  value={formValues.name}
                  error={!!errors.name}
                  placeholder={formatMessage('settings.rules.rule_name')}
                  maxLength={NAME_MAX_LENGTH}
                  onChange={handleNameChange}
                />
                {errors.name && (
                  <Message
                    className="error-message"
                    error
                    content={<FormattedMessage id={errors.name} />}
                  />
                )}
              </div>

              <h3><FormattedMessage id="settings.rules.set_trigger" /></h3>

              <KeywordsSelect
                formType={formType}
                formValues={formValues}
                selectOptions={selectOptions}
                error={errors.keywords}
                onChange={handleFieldChange}
              />

              <div className="field">
                <FormattedMessageLabel id="settings.rules.recordType" />
                <Menu fluid widths={3} compact>
                  <Menu.Item
                    name="Any"
                    active={isUndefined(formValues.recordType)}
                    onClick={(e) => handleFieldChange(e, { name: 'recordType', value: undefined })}
                  >
                    <FormattedMessage id="record_type.any" />
                  </Menu.Item>
                  <Menu.Item
                    name="Expense"
                    active={formValues.recordType === RecordType.EXPENSE}
                    onClick={(e) => handleFieldChange(e, { name: 'recordType', value: RecordType.EXPENSE })}
                  >
                    <FormattedMessage id={{ W: 'expense', B: 'money-spent' }} />
                  </Menu.Item>
                  <Menu.Item
                    name="Income"
                    active={formValues.recordType === RecordType.INCOME}
                    onClick={(e) => handleFieldChange(e, { name: 'recordType', value: RecordType.INCOME })}
                  >
                    <FormattedMessage id={{ W: 'income', B: 'money-received' }} />
                  </Menu.Item>
                </Menu>
              </div>
              {formType !== FormType.ADD_FROM_ASSIGN && (
                <>
                  <Divider section />

                  <h3><FormattedMessage id="settings.rules.assign_actions" /></h3>

                  {isAppBoard() && (
                    <SelectContact
                      contacts={selectOptions.contacts}
                      contactId={formValues.contactId}
                      onChange={(e, data) => handleFieldChange(e, { ...data, name: atob(data.name) })}
                    />
                  )}
                  <SelectCategory
                    value={formValues.categoryId}
                    categoriesHierarchy={selectOptions.categoriesHierarchy}
                    categories={selectOptions.categories}
                    name="categoryId"
                    error={errors.categoryId}
                    onFieldChange={handleFieldChange}
                  />
                  <SelectMultiple
                    name="labelIds"
                    label={<FormattedMessageLabel id="record.form.labels" />}
                    value={formValues.labelIds}
                    options={labelOptions}
                    error={errors.labelIds}
                    onChange={handleFieldChange}
                  />
                </>
              )}

              <Divider hidden section />
              <SaveButton loading={loading} canEditRules={canEditRules} onSaveClick={handleSaveLabel}>
                <FormattedMessage id="form.save" />
              </SaveButton>
            </Form>
          </div>

        ) : <MagicRuleUpdateSimilar />}
      </Modal.Content>
    </Modal>
  )
}

interface SaveButtonProps {
  children: React.ReactNode
  canEditRules: boolean
  loading: boolean
  disabled?: boolean
  onSaveClick: (e: SyntheticEvent) => void
}

function SaveButton({ children, loading, canEditRules, onSaveClick }: SaveButtonProps) {
  return (
    <div className="field submit">
      <PremiumChecker featureName="premium_checker.automatic_rules" enabled={canEditRules}>
        <PrimaryButton
          onClick={onSaveClick}
          fluid
          type="submit"
          disabled={loading}
          loading={loading}
        >
          {children}
        </PrimaryButton>
      </PremiumChecker>
    </div>
  )
}


export default connect(mapStateToProps, mapDispatchToProps)(MagicRuleForm)
