
import {
  CreditCardType,
  BraintreePaymentMethod,
  Method,
} from 'src/frontend/scenes/billing/paymentMethod/types'
import { maskEmail } from 'src/frontend/scenes/billing/paymentMethod/helpers'

export function getPaymentMethodImage(paymentMethod: BraintreePaymentMethod) {
  if (paymentMethod.type === Method.PAYPAL) {
    return 'paypal'
  }

  switch (paymentMethod.creditCardType) {
    default: {
      return null
    }
    case CreditCardType.MASTER_CARD: {
      return 'mastercard'
    }
    case CreditCardType.DINERS_CLUB_INTERNATIONAL: {
      return 'diners'
    }
    case CreditCardType.VISA: {
      return 'visa'
    }
    case CreditCardType.AMEX: {
      return 'amex'
    }
    case CreditCardType.CHINA_UNION_PAY: {
      return 'unionpay'
    }
    case CreditCardType.DISCOVER: {
      return 'discover'
    }
    case CreditCardType.JCB: {
      return 'jcb'
    }
    case CreditCardType.UK_MAESTRO:
    case CreditCardType.MAESTRO: {
      return 'jcb'
    }
  }
}

export function getPaymentMethodString(paymentMethod: BraintreePaymentMethod): string {
  if (paymentMethod.type === Method.PAYPAL) {
    return maskEmail(paymentMethod.payPalEmail)
  }

  if (paymentMethod.type === Method.CREDIT_CARD) {
    return paymentMethod.maskedNumber
  }
}
