import React from 'react'
import { connect } from 'react-redux'
import {
  Modal, Form, Icon, Message, Radio,
} from 'semantic-ui-react'
import { NAME_MAX_LENGTH } from 'src/backend/common/validator'
import InfoPopup from 'src/frontend/components/InfoPopup'
import { FormattedMessage, formatMessage } from 'src/frontend/modules/intl'
import { getColors } from 'src/backend/colors/colors'
import ColorPicker from 'src/frontend/components/ColorPicker/ColorPicker'
import * as selectors from '../selectors'
import * as actions from '../actions'
import './LabelForm.less'
import PremiumChecker from 'src/frontend/components/PremiumChecker/PremiumChecker'
import { FormType } from 'src/frontend/scenes/settings/enums'
import { RootState } from 'src/types/State'
import { LabelFormValues } from 'src/frontend/scenes/settings/labels/types'
import { HashMap } from 'src/types/common'
import { PrimaryButton } from 'src/frontend/components/Buttons/Buttons'

function mapStateToProps(state: RootState) {
  const form = selectors.selectLabels(state)
  return {
    formType: form.formType,
    formValues: form.formValues,
    colorPickerOpen: form.colorPicker,
    formOpen: form.isOpen,
    errors: form.errors,
    labelsColorEnabled: selectors.selectLabelsColorEnabled(state),
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    handleFieldChange(_e, { name, value }) {
      dispatch(actions.changeFormFieldValue(name, value))
    },
    handleRadioChange(_e, { name, checked }) {
      dispatch(actions.changeFormFieldValue(name, checked))
    },
    handleCloseForm() {
      dispatch(actions.closeForm())
    },
    handleSaveLabel() {
      dispatch(actions.saveLabel())
    },
    handleOpenColorPicker() {
      dispatch(actions.openColorPicker())
    },
    handleCloseColorPicker() {
      dispatch(actions.closeColorPicker())
    },
    handleColorPick(color) {
      dispatch(actions.changeFormFieldValue('color', color.hex))
      dispatch(actions.closeColorPicker())
    },
  }
}

interface Props {
  formValues: LabelFormValues
  errors: HashMap<string>
  formType: FormType
  formOpen: boolean
  labelsColorEnabled: boolean
  colorPickerOpen: boolean
  handleCloseForm: (MouseEvent) => void
  handleFieldChange: (SyntheticEvent) => void
  handleRadioChange: (MouseEvent) => void
  handleOpenColorPicker: (MouseEvent) => void
  handleCloseColorPicker: (MouseEvent) => void
  handleColorPick: (MouseEvent) => void
  handleSaveLabel: (MouseEvent) => void
}


function LabelForm({
  formValues,
  errors,
  formType,
  formOpen,
  labelsColorEnabled,
  colorPickerOpen,
  handleCloseForm,
  handleFieldChange,
  handleRadioChange,
  handleOpenColorPicker,
  handleCloseColorPicker,
  handleColorPick,
  handleSaveLabel,
}: Props) {

  const colorPickerTrigger = (
    <div className="ui selection dropdown">
      <div className="selection-inner">
        <div className="color-inner" style={{ background: formValues.color }} />
        <Icon name="dropdown" as="i" />
      </div>
    </div>
  )

  const nameAndColorPickerRow = (
    <div className="field">
      <Form.Group>
        <Form.Input
          label={formatMessage('form.name')}
          name="name"
          defaultValue={formValues.name}
          placeholder={formatMessage('settings.labels.form.name_placeholder')}
          width="11"
          maxLength={NAME_MAX_LENGTH}
          autoFocus
          error={!!errors.name}
          onChange={handleFieldChange}
        />
        <div className="five wide field small-select">
          <label><FormattedMessage id="form.color" /></label>
          <PremiumChecker
            handler="onOpen"
            enabled={labelsColorEnabled}
            featureName="premium_checker.label_colors"
          >
            <ColorPicker
              colors={getColors()}
              color={formValues.color}
              trigger={colorPickerTrigger}
              position="bottom right"
              open={colorPickerOpen}
              onOpen={handleOpenColorPicker}
              onClose={handleCloseColorPicker}
              onSelect={handleColorPick}
            />
          </PremiumChecker>
        </div>
      </Form.Group>
      {errors.name && <Message className="error-message" error content={<FormattedMessage id={errors.name} />} />}
    </div>
  )

  const autoAssignRow = (
    <div className="field">
      <Form.Group>
        <Radio
          name="autoAssign"
          toggle
          defaultChecked={formValues.autoAssign}
          label={formatMessage('settings.labels.form.autoAssign')}
          onChange={handleRadioChange}
        />
        <InfoPopup message={<FormattedMessage id="settings.labels.form.autoAssign.popup" />} />
      </Form.Group>
    </div>
  )

  const archiveRow = formType === FormType.EDIT && (
    <div className="field">
      <Form.Group>
        <Radio
          name="archived"
          toggle
          defaultChecked={formValues.archived}
          label={formatMessage('archive')}
          onChange={handleRadioChange}
        />
        <InfoPopup message={<FormattedMessage id="settings.labels.form.archive.popup" />} />
      </Form.Group>
    </div>
  )

  const formName = formatMessage(formType === FormType.ADD
    ? 'settings.labels.form.add_label'
    : 'settings.labels.form.edit_label')

  const SaveButton = ({ children }) => (
    <div className="field submit">
      <PrimaryButton
        type="submit"
        fluid
        onClick={handleSaveLabel}
      >
        {children}
      </PrimaryButton>
    </div>
  )

  return (
    <Modal size="small" open={formOpen} onClose={handleCloseForm}>
      <Modal.Header>
        {formName}
        <span className="modal-close" onClick={handleCloseForm} />
      </Modal.Header>
      <Modal.Content>
        <div className="add-label-form">
          <div className="content-panel">
            <Form error>
              {nameAndColorPickerRow}
              {autoAssignRow}
              {archiveRow}
              <SaveButton><FormattedMessage id="form.save" /></SaveButton>
            </Form>
          </div>
        </div>
      </Modal.Content>
    </Modal>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(LabelForm)
