import { IntlConsumerType } from 'src/frontend/modules/intl/components/types'
import { Billing } from 'src/types/Billing'
import { Client } from 'braintree-web'
import BraintreeProduct = Billing.BraintreeProduct

export interface PaymentProps extends IntlConsumerType {
  braintreeClient: Client
  loading: boolean,
  formType: PaymentMethodFormType,
  selectedProduct: BraintreeProduct,
  onTokenize: (nonce: string) => void
  onError?: (message: string) => void
}

export enum PaymentMethodFormType {
  ADD,
  UPDATE,
}
