import * as recordsService from "src/backend/records/service"
import * as standingOrderService from "src/backend/standingOrders/service"
import _groupBy from "lodash/groupBy"
import {
  expireAccountsStatus,
  expireAnalyticsStatus,
  expireDetailRecordsStatus,
  expireDashboardStatus,
  expireRecordsStatus,
  expireImportsRecordListStatus,
} from "src/frontend/modules/moduleStatus/actions"

export const OPEN_REMOVE_FORM = "OPEN_REMOVE_FORM"
export const CLOSE_REMOVE_FORM = "CLOSE_REMOVE_FORM"

export const REMOVE_RECORD_START = "REMOVE_RECORD_START"
export const REMOVE_RECORD_SUCCESS = "REMOVE_RECORD_SUCCESS"

export function remove(itemsToRemove) {
  return (dispatch) => {
    dispatch(removeItemsStart(itemsToRemove))
    const RESERVED_MODEL_TYPE = "reservedModelType"
    const grouppedItems = _groupBy(itemsToRemove, RESERVED_MODEL_TYPE)
    Promise.all([
      recordsService.removeRecords(grouppedItems.Record),
      standingOrderService.removeStandingOrders(grouppedItems.StandingOrder),
    ]).then(() => {
      dispatch(removeItems())
      dispatch(expireAccountsStatus())
      dispatch(expireDashboardStatus())
      dispatch(expireRecordsStatus())
      dispatch(expireAnalyticsStatus())
      dispatch(expireDetailRecordsStatus())
      dispatch(expireImportsRecordListStatus())
    })
  }
}

function removeItemsStart(records) {
  return { type: REMOVE_RECORD_START, payload: { records, isRemoving: true } }
}

function removeItems() {
  return { type: REMOVE_RECORD_SUCCESS, payload: { open: false, isRemoving: false } }
}

export function openRemoveForm() {
  return { type: OPEN_REMOVE_FORM, payload: { open: true } }
}

export function closeRemoveForm() {
  return { type: CLOSE_REMOVE_FORM, payload: { open: false } }
}
