import { Currency } from "src/types/Currency"
import {
  PieAndBarRawSpendingData,
  PieAndBarSpendingData,
} from "src/frontend/scenes/dashboard/widgets/PieAndBarSpending/types"
import { countPercentChange } from "src/common/helpers"
import { PieAndSpendingWidgetVariant } from "src/frontend/scenes/dashboard/widgets/PieAndBarSpending/enums"
import { ChartType } from "src/frontend/components/chart/ChartType"
import { Category } from "src/types/Category"

export function getSpendingPieAndBar(
  referentialCurrency: Currency,
  flattenedCategoriesHierarchy,
  variant: PieAndSpendingWidgetVariant,
) {
  return (data: PieAndBarRawSpendingData): PieAndBarSpendingData => {
    if (!data) {
      return null
    }

    if (!data || !referentialCurrency) {
      return null
    }

    const shouldInvertChange = variant !== PieAndSpendingWidgetVariant.REVENUE

    const pieData = data.pieChartDatasets.map((dataset) => {
      const categoryView = getCategoryView(dataset, flattenedCategoriesHierarchy)

      return {
        ...dataset,
        name: categoryView?.name ? categoryView.name : "",
        color: categoryView?.color ? categoryView.color : "",
      }
    })

    const barData = {
      ...data.barChartDatasets,
      datasets: [
        ...data.barChartDatasets.datasets.map((dataset) => {
          const categoryView = getCategoryView(dataset, flattenedCategoriesHierarchy)

          return {
            ...dataset,
            id: dataset.id,
            backgroundColor: categoryView?.color ? categoryView.color : "",
            pointBackgroundColor: categoryView?.color ? categoryView.color : "",
            borderColor: "transparent",
            label: categoryView?.name ? categoryView.name : "",
            type: ChartType.BAR,
          }
        }),
      ],
    }

    const cashFlowChange = countPercentChange(
      data.previousPeriodCashFlow * (shouldInvertChange ? -1 : 1),
      data.totalCashFlow * (shouldInvertChange ? -1 : 1),
    )

    return {
      totalCashFlow: data.totalCashFlow,
      previousPeriodCashFlow: data.previousPeriodCashFlow,
      cashFlowChange,
      shouldInvertChange, // invert percentages for expense widgets
      referentialCurrency,
      goBackId: data.goBackId,
      pieData,
      barData,
    }
  }
}

function getCategoryView(dataset, flattenedCategoriesHierarchy: Category[]) {
  const { category: datasetCategory } = dataset

  return flattenedCategoriesHierarchy.find((cat) => {
    // datasetCategory can be Super envelope, 2nd envelope or category
    return (
      (cat.superEnvelopeId === datasetCategory.id && cat.isGeneral) ||
      cat.envelopeId === datasetCategory.id ||
      cat._id === datasetCategory._id
    )
  })
}
