import { Id } from "src/types/CouchDb"

export namespace IntegrationConnection {
  export interface IntegrationAccountCreationBlueprint {
    color: string
    name: string
    remoteAccountId: string
    cashAccountId?: Id
  }

  export enum ConnectionType {
    NEW_ACCOUNT_CONNECTION = "new_account_connection",
    MANUAL_CONNECTION = "manual_connection",
    RECONNECT = "reconnect",
  }

  export interface IntegrationAccountConnectionPair {
    remoteAccountId: string
    appAccountId: Id
    cashAccountId?: Id
  }

  export enum Type {
    Account = 0,
    Bonus = 1,
    Card = 2,
    Checking = 3,
    Credit = 4,
    CreditCard = 5,
    DebitCard = 6,
    Ewallet = 7,
    Insurance = 8,
    Investment = 9,
    Loan = 10,
    Mortgage = 11,
    Savings = 12,
  }

  export interface IntegrationAccount {
    id: string
    name: string
    type: Type
    balance: number
    currencyCode: string
    connectedAppAccountId?: Id
  }
}
