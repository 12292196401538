import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Divider, Loader } from 'semantic-ui-react'
import { RootState } from 'src/types/State'
import {
  togglePreferBankIntegrationStage,
} from 'src/frontend/scenes/onBoarding/firstRun/actions'
import {

  selectOnBoardingStage,
} from 'src/frontend/scenes/onBoarding/firstRun/selectors'
import { Account } from 'src/types/Account'
import { selectAccountsAsArray } from 'src/frontend/modules/accounts/selectors'
import { OnBoardingStage } from 'src/frontend/scenes/onBoarding/firstRun/enums'

import { NewIntegrationContent } from 'src/frontend/scenes/integrations/newConnection/components/NewIntegrationContent'
import { selectIntegrationStatus } from 'src/frontend/scenes/integrations/newConnection/selectors'
import {
  selectProviderDetail,
  selectProviderDetailLoading,
} from 'src/frontend/scenes/integrations/providersList/selectors'
import { NewIntegrationsStatus } from 'src/frontend/scenes/integrations/enums'
import { IntegrationProviders } from 'src/frontend/scenes/integrations/providersList/types'
import IntegrationProviderDetail = IntegrationProviders.IntegrationProviderDetail
import { showKBIntegration } from 'src/frontend/scenes/integrations/providersList/kb/actions'
import styles from './KBAccountIntegration.module.less'
import { FormattedMessage } from 'src/frontend/modules/intl'
import { PrimaryButton } from 'src/frontend/components/Buttons/Buttons'
import { clearAndCloseForm } from 'src/frontend/scenes/integrations/providersList/actions'
import { User } from 'src/types/User'
import { selectUser } from 'src/frontend/modules/user/selectors'
import { isCNBVerified } from 'src/backend/user/service'

interface Props {
  providerDetail: IntegrationProviderDetail
  integrationStatus: NewIntegrationsStatus
  user: User
  loading: boolean
  accounts: Account[]
  stage: OnBoardingStage
  fetchProviderDetails: () => void
  togglePreferBank: () => void
  clearForm: () => void
}

const mapStateToProps = (state: RootState) => ({
  accounts: selectAccountsAsArray(state),
  loading: selectProviderDetailLoading(state),
  stage: selectOnBoardingStage(state),
  user: selectUser(state),
  integrationStatus: selectIntegrationStatus(state),
  providerDetail: selectProviderDetail(state),
})

const mapDispatchToProps = {
  togglePreferBank: togglePreferBankIntegrationStage,
  fetchProviderDetails: showKBIntegration,
  clearForm: clearAndCloseForm,
}

function KBAccountIntegration({
  loading,
  user,
  integrationStatus,
  providerDetail,
  fetchProviderDetails,
  togglePreferBank,
  clearForm,
}: Props) {

  const [startIntegration, setStartIntegration] = useState(isCNBVerified(user))

  useEffect(() => {
    if (!providerDetail && startIntegration) {
      fetchProviderDetails()
    }

    return () => clearForm()
  }, [startIntegration])

  if (!startIntegration) {
    return (
      <div>
        <Divider hidden section />
        <PrimaryButton
          onClick={() => setStartIntegration(true)}
          fluid
        >
          <FormattedMessage id="form.continue" />
        </PrimaryButton>
        <div className={styles.skip} onClick={() => togglePreferBank()}>
          <FormattedMessage id="kb.onBoarding.connect-later" />
        </div>
      </div>
    )
  }

  if (loading) {
    return <Loader active inline="centered" />
  }

  return (
    <div>
      <NewIntegrationContent integrationStatus={integrationStatus} hideProviderLogo />
      <div className={styles.skip} onClick={() => togglePreferBank()}>
        <FormattedMessage id="kb.onBoarding.connect-later" />
      </div>
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(KBAccountIntegration)
