import { pipe } from 'ramda'
import { createSelector } from 'reselect'
import { selectHasAccounts } from 'src/frontend/scenes/imports/account/selectors'
import { PageMode } from 'src/frontend/scenes/imports/constants'
import { commonState } from 'src/frontend/scenes/imports/local-selectors'

const selectPageModeEnum = pipe(commonState, common => common.pageMode)

export const selectPageMode = createSelector(
  [selectPageModeEnum, selectHasAccounts],
  (pageMode, hasAccounts) => {
    if (pageMode === PageMode.MAPPING) {
      return pageMode
    } else if (pageMode === PageMode.RECORDS) {
      return hasAccounts ? pageMode : PageMode.CREATE_ACCOUNT
    } else {
      throw new Error(`Unsupported configuration: pageMode=${pageMode}, hasAccounts=${hasAccounts}`)
    }
  },
)

export const selectIsImportsInitialized = pipe(commonState, common => common.initialized)

export const selectIsDeactivateImportsModalOpen = pipe(
  commonState,
  state => state.deactivateImports.open,
)

export const selectIsDeactivateImportsModalInProgress = pipe(
  commonState,
  state => state.deactivateImports.progress,
)

export const selectTutorialOpen = pipe(
  commonState,
  state => state.tutorialOpen,
)
