import React, { useCallback, useEffect } from 'react'
import { connect } from 'react-redux'
import { Divider, Form } from 'semantic-ui-react'
import { FormattedMessage, FormattedMessageLabel } from 'src/frontend/modules/intl'
import { getCurrencies } from 'src/backend/currencies/currencies'
import { RootState } from 'src/types/State'
import { changeValue, createBaseCurrency, gotoNextStage } from 'src/frontend/scenes/onBoarding/firstRun/actions'
import {
  selectOnBoardingFormValues,
  selectOnBoardingLoading,
  selectOnBoardingStage,
} from 'src/frontend/scenes/onBoarding/firstRun/selectors'
import { OnBoardingFormValues } from 'src/frontend/scenes/onBoarding/firstRun/types'
import { selectReferentialCurrency } from 'src/frontend/modules/currencies/selectors'
import { Currency } from 'src/types/Currency'
import { OnBoardingStage } from 'src/frontend/scenes/onBoarding/firstRun/enums'
import { PrimaryButton } from 'src/frontend/components/Buttons/Buttons'
import * as mixpanel from 'src/common/mixpanel'

interface Props {
  loading: boolean
  referentialCurrency: Currency
  formValues: OnBoardingFormValues,
  stage: OnBoardingStage,
  onFieldChange: (name: string, value: string) => void
  onGotoNext: () => void
  onCreateCurrency: () => void
}

const mapStateToProps = (state: RootState) => ({
  referentialCurrency: selectReferentialCurrency(state),
  formValues: selectOnBoardingFormValues(state),
  loading: selectOnBoardingLoading(state),
  stage: selectOnBoardingStage(state),
})

const mapDispatchToProps = {
  onFieldChange: changeValue,
  onGotoNext: gotoNextStage,
  onCreateCurrency: createBaseCurrency,
}

function BaseCurrencyForm({
  loading,
  referentialCurrency,
  formValues,
  stage,
  onFieldChange,
  onGotoNext,
  onCreateCurrency,
}: Props) {
  useEffect(() => {
    if (referentialCurrency && stage === OnBoardingStage.BASE_CURRENCY) {
      onGotoNext()
    }
  }, [referentialCurrency, stage])

  useEffect(() => {
    mixpanel.trackBaseCurrencyEnter()
  }, [])


  const options = React.useMemo(() => {
    return getCurrencies().map(currency => {
      return { key: currency.code, text: `${currency.code} - ${currency.name}`, value: currency.code }
    })
  }, [])

  const handleChangeCurrency = useCallback((_e, field: { value: string, name: string }) => onFieldChange(
    field.name,
    field.value,
  ), [])

  const handleCreateCurrency = useCallback(() => onCreateCurrency(), [])

  return (
    <>
      <Form>
        <Form.Select
          label={<FormattedMessageLabel id="onBoarding.BASE_CURRENCY.label" />}
          search
          name="currencyCode"
          value={formValues.currencyCode}
          options={options}
          onChange={handleChangeCurrency}
        />
        <p><FormattedMessage id="onBoarding.BASE_CURRENCY.info_message" /></p>
        <Divider hidden section />
        <PrimaryButton
          loading={loading}
          disabled={loading}
          fluid
          type="submit"
          size="big"
          onClick={handleCreateCurrency}
        >
          <FormattedMessage id="onBoarding.BASE_CURRENCY.confirm" />
        </PrimaryButton>
      </Form>
    </>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(BaseCurrencyForm)
