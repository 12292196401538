import * as commonRepository from "src/backend/common/repository"
import {
  convertResultToEntity,
  fetchAllFromTable,
  WalletSQLInMemory,
} from "src/backend/db/inMemorySqlDb"
import { inMemoryTableNames } from "src/backend/db/inMemorySqlDbSchemaBuilder"
import { Id } from "src/types/CouchDb"
import { FilterDocument } from "src/types/Filter"

export function getAllFilters() {
  return fetchAllFromTable<FilterDocument>(inMemoryTableNames.FILTER)
}

export function findById(id: Id): Promise<FilterDocument> {
  const sqlDb = WalletSQLInMemory.getInstance()
  const filterTable = sqlDb.getTable(inMemoryTableNames.FILTER)
  return sqlDb
    .select()
    .from(filterTable)
    .where(filterTable._id.eq(id))
    .exec()
    .then(convertResultToEntity(id, inMemoryTableNames.FILTER))
}

export function findAllAsHashMap() {
  return commonRepository.findAllAsHashMap(inMemoryTableNames.FILTER)
}
