import { Id } from 'src/types/CouchDb'
import { IntegrationSource } from 'src/backend/integrations/enums'
import { IntegrationConnection } from 'src/frontend/scenes/integrations/newConnection/types'


export namespace IntegrationProviders {


  import IntegrationRecipeGroupType = IntegrationProviders.IntegrationRecipe.IntegrationRecipeGroup

  export interface IntegrationProvider {
    id: Id
    name: string
    countryCode: string
    integrationSource: IntegrationSource
    providerIcon?: string
    supportedAccountTypes?: IntegrationConnection.Type[]
    logoUrl?: string,
    isAutomatic?: boolean
    code?: string
    maxTransactionHistoryInDays?: number,
    needPremiumToConnect?: boolean,
  }

  export interface IntegrationConnectedProvider {
    id: string
    name: string
    countryCode: string
    integrationSource: IntegrationSource
    loginId: string
    loginStatus: ConnectedProviderStatus
    providerIcon: string
    logoUrl?: string,
    code?: string,
    maxTransactionHistoryInDays?: number,
    needPremiumToConnect?: boolean,
  }


  export enum ConnectedProviderStatus {
    INACTIVE = 0,
    ACTIVE = 1,
    DISABLED = 3,
  }


  export interface IntegrationProviderDetail {
    id: Id,
    name: string,
    countryCode: string,
    integrationSource: IntegrationSource,
    instruction?: string,
    formAttributeGroups: IntegrationRecipeGroupType[]
    providerIcon: string, // https://s3.amazonaws.com/bb-integration-bank-icons/${providerIcon}
    supportedAccountTypes?: IntegrationConnection.Type[]
    logoUrl?: string
    code?: string
    isAutomatic?: boolean
  }


  export namespace IntegrationRecipe {
    enum Severity {
      DEFAULT,
      PRIMARY,
      SUCCESS,
      INFO,
      WARNING,
      DANGER,
    }

    export interface IntegrationRecipeGroup {
      id: string
      title?: string,
      severity?: Severity,
      params: IntegrationRecipeParam[]
    }

    export enum InputType {
      LIST = 0,
      NUMBER = 1,
      TEXT = 2,
      BOOLEAN = 3,
      DATE = 4,
      TIME = 5,
      DATE_TIME = 6,
      PASSWORD = 8,
      REGEXP = 9,
      SELECT = 10,
      OAUTH_REDIRECT_URL = 11,
      HTML = 12,
      MULTI_SELECT = 13,
    }

    enum Scope {
      SYSTEM,      // params for superscript (not for custom, visible on client)
      CUSTOM,      // params for custom scripts (visible on client)
      GENERATED,   // params generated by init script (visible for client)
      OPERATIONAL, // params updated by scripts (mainly superscript) that is not to be shown on client
    }


    export interface IntegrationRecipeParam {
      id: string
      type: InputType
      enumValues: string
      scope: Scope
      label?: string
      description?: string
      optional?: boolean
      visibleInPreview?: boolean
      barCodeScanner?: boolean
      notEditable?: boolean
      hint?: string
      value?: string | string[] | boolean
      selectOptions: IntegrationRecipeParamSelectOption[]
      expiresAt?: number
      loginId?: string
    }


    interface IntegrationRecipeParamSelectOption {
      key: string
      value: string
      text: string
      selected?: boolean
    }
  }


  export interface IntegrationLoginResponse {
    loginId: Id
    status: IntegrationLoginStatus
    integrationSource: IntegrationSource
    formAttributeGroups: IntegrationRecipeGroupType[]
    errorMessage?: string
  }

  export enum IntegrationLoginStatus {
    OK = 0,
    MFA = 1,
    ERROR = 2,
    CHECK_LATER = 3,
    INVALID_CREDENTIALS = 4,
    LOGIN_DUPLICATED = 5,
  }
}
