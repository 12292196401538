import { getCurrencies } from 'src/backend/currencies/currencies'
import { logMissingAttributes } from 'src/common/logger'
import { DataDoc } from 'src/types/CouchDb'
import { Currency } from 'src/types/Currency'

const allCurrencies = getCurrencies()

export function currencyConverter() {
  return (dataDoc: Currency | DataDoc<Currency>): Currency => {
    const doc: Currency = (dataDoc as DataDoc<Currency>).doc || dataDoc as Currency

    logMissingAttributes(['code', 'ratioToReferential'], doc)

    return {
      ...doc,
      code: doc.code || '---',
      ratioToReferential: doc.ratioToReferential || 1,
      name: getCurrencyName(doc),
    }
  }
}

function getCurrencyName(currency: Currency): string {
  const matchedCurrency = allCurrencies.find(defaultCurrency => defaultCurrency.code === currency.code)
  return matchedCurrency ? matchedCurrency.name : ''
}
