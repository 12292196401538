import { Icon, Input, Transition } from 'semantic-ui-react'
import React, { useCallback } from 'react'
import styles from 'src/frontend/scenes/settings/magicRules/components/AddableList.module.less'
import _isEqual from 'lodash/isEqual'
import FormattedMessageLabel from 'src/frontend/modules/intl/components/FormattedMessageLabel'
import { formatMessage } from 'src/frontend/modules/intl'

interface Props {
  items: string[]
  value: string,
  addDisabled: boolean
  onItemChange: Function
  onValueChange: (value: string) => void
}

AddableList.defaultProps = {
  onValueChange: undefined,
}

function AddableList({ items, onItemChange, addDisabled, value, onValueChange }: Props) {

  const handleChangeField = useCallback((_e, data) => {
    onValueChange(data.value)
  }, [])

  const handleAddItem = useCallback(() => {
    if (!addDisabled) {
      onItemChange(Array.isArray(items) ? [...items, value.trim()] : [value.trim()])
      onValueChange('')
    }
  }, [items, value, addDisabled])

  const removeItem = useCallback((itemIndex) => {
    return onItemChange(items.filter((_item, existingIndex) => itemIndex !== existingIndex))
  }, [items])

  return (
    <div className="field">
      <FormattedMessageLabel id="form.keywords" />
      <Transition.Group duration={200} animation="fade" as="ul" className={styles.keywords}>
        {items && items.length > 0
          ? items.map((item, index) => {
            return (
              <li key={`${item}-${index}`}>
                <div>
                  {item}
                  <Icon name="delete" onClick={() => removeItem(index)} />
                </div>
              </li>
            )
          })
          : null}
        <li key="add" className={styles.inputItem}>
          <Input
            size="mini"
            action={{
              icon: 'plus',
              size: 'mini',
              disabled: addDisabled,
              onClick: handleAddItem,
            }}
            onChange={handleChangeField}
            onBlur={handleAddItem}
            value={value}
            placeholder={formatMessage('form.keywords.add')}
          />
        </li>
      </Transition.Group>
    </div>
  )
}

export default React.memo(AddableList, ((prevProps, nextProps) => {
  return prevProps.value === nextProps.value
    && prevProps.addDisabled === nextProps.addDisabled
    && _isEqual(prevProps.items, nextProps.items)
}))
