/* eslint-disable react/prop-types */
import React from 'react'
import { Search } from 'semantic-ui-react'
import _debounce from 'lodash/debounce'

class AutoCompleteInput extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      isLoading: false,
      results: [],
      value: props.value,
    }
    if (props.fetchOnMount) {
      this.fetchData(props.value)
    }

  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      this.setState({ value: this.props.value })
      this.fetchData(this.props.value)
    }
  }

  componentWillUnmount() {
    this.fetchData.cancel()
  }

  fetchData = _debounce(value => {
    if (value && value.length < 1) {
      this.resetComponent()
      this.props.onChange(null, {})
    } else if (value.length > 3) {
      const { fetch } = this.props
      fetch && fetch(value).then(this.handleFetchDataResult)
    }
  }, 250)

  resetComponent = () => this.setState({ isLoading: false, results: [], value: '' })

  handleFetchDataResult = (results) => this.setState({ isLoading: false, results })

  handleSearchChange = (event, { value }) => {
    this.setState({ value, isLoading: true })
    this.props.changeOnTyping && this.props.onChange(event, value)
    this.fetchData(value)
  }

  handleResultSelect = (event, data) => {
    this.setState({ value: data.result.title })
    this.props.onChange(event, data.result)
  }

  render() {
    const { name, className, input, showNoResults } = this.props
    const { isLoading, results, value } = this.state

    return (
      <Search
        showNoResults={showNoResults}
        className={className}
        loading={isLoading}
        onResultSelect={this.handleResultSelect}
        onSearchChange={this.handleSearchChange}
        input={input}
        results={results}
        value={value}
        name={name}
      />
    )
  }
}

export default AutoCompleteInput
