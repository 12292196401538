import React, { SyntheticEvent } from 'react'
import { Form, Message } from 'semantic-ui-react'
import { FormattedMessage, formatMessage } from 'src/frontend/modules/intl'
import styles from 'src/frontend/scenes/records/components/Labels.module.less'
import { Label } from 'src/types/Label'
import { SemanticData } from 'src/types/Semantic-ui'
import { Id } from 'src/types/CouchDb'
import { filterLatinizedOptions, mergeClasses } from 'src/common/utils'
import './SelectMultiple.less'
import { isSystemLabel } from 'src/backend/labels/helpers'
import { SystemLabelType } from 'src/backend/enums'


interface Props {
  value?: Id | Id[],
  className?: string,
  name?: string,
  error?: string,
  label?: string | React.ReactNode,
  options?: MultipleSelectOption[]
  onChange: (e: SyntheticEvent, data: SemanticData) => void,
  disabled?: boolean,
}

interface MultipleSelectOption {
  _id: Id
  name: string
  description?: string
  color?: string
  systemLabelType?: SystemLabelType
}

SelectMultiple.defaultProps = {
  value: [],
  name: undefined,
  className: '',
  error: undefined,
  label: '',
  labels: [],
  disabled: false,
}

export default function SelectMultiple({ label, name, options, className, disabled, error, value, onChange }: Props) {

  const selectOptions = options.map(option => ({
    key: option._id,
    text: option.name,
    value: option._id,
    description: option.description,
    color: option.color,
    className: isSystemLabel(option as Label) ? styles.systemLabel : undefined,
    label: option.color && {
      style: { backgroundColor: !isSystemLabel(option as Label) ? option.color : '#666' },
      empty: true,
      circular: true,
    },
  }))

  const renderLabel = (labelToRender) => ({
    style: { backgroundColor: labelToRender.color },
    className: mergeClasses(styles.label, labelToRender.className),
    content: labelToRender.text,
  })

  return (
    <div className="field">
      <Form.Dropdown
        label={label}
        name={name}
        className={mergeClasses(styles.labels, className)}
        disabled={disabled}
        error={!!error}
        value={value}
        multiple
        selection
        fluid
        search={filterLatinizedOptions}
        noResultsMessage={formatMessage('record.form.multiple.not-found')}
        options={selectOptions}
        placeholder={formatMessage('record.form.multiple.placeholder')}
        renderLabel={renderLabel}
        onChange={onChange}
      />
      {error && <Message className="error-message" error content={<FormattedMessage id={error} />} />}
    </div>
  )
}
