import React from 'react'
import {
  Grid, Loader, Modal,
} from 'semantic-ui-react'
import { FormattedMessage } from 'src/frontend/modules/intl'
import BasicRecordList from 'src/frontend/components/RecordList/BasicRecordList'
import { Record } from 'src/types/Record'
import {
  eachHasCompatibleDuplicity,
  MAX_DAYS_DIFFERENCE,
} from 'src/frontend/scenes/records/solveDuplicitiesForm/helpers'
import { Id } from 'src/types/CouchDb'
import { PrimaryButton, SecondaryButton } from 'src/frontend/components/Buttons/Buttons'

interface Props {
  open: boolean
  processing?: boolean
  items?: Record[]
  trigger: React.ReactNode
  onCancelClick: Function
  onConfirmClick: (selectedDuplicities: Id[], recordIdToKeep: Id) => void
  onSelectClick: (recordId: Id) => void
  selectedItemId: Id
}

export default class SolveDuplicitiesForm extends React.Component<Props> {
  static defaultProps = {
    processing: true,
    items: [],
  }

  shouldComponentUpdate(nextProps) {
    // Avoid to re-render closed modals
    return nextProps.processing
      || (this.props.open !== nextProps.open)
      || (this.props.selectedItemId !== nextProps.selectedItemId)
  }

  handleClose = () => {
    this.props.onCancelClick()
  }

  handleConfirm = () => {
    this.props.items && this.props.onConfirmClick(
      this.props.items.map((record) => record._id),
      this.props.selectedItemId,
    )
  }

  handleSelect = (recordId) => {
    if (this.props.selectedItemId !== recordId) {
      this.props.onSelectClick(recordId)
    }
  }

  render() {
    const { items, open, processing, trigger, selectedItemId } = this.props

    return (
      <Modal trigger={trigger} open={open} size="small" onClose={this.handleClose}>
        <Modal.Header>
          <FormattedMessage id="records.solve_duplicities" />
        </Modal.Header>
        <Modal.Content>
          {!processing ? (
            <ConfirmQuestion
              items={items}
              selectedItemId={selectedItemId}
              onSelectClick={this.handleSelect}
            />
          ) : (
            <Loader active inline="centered" />
          )}
        </Modal.Content>
        <Modal.Actions>
          <Buttons
            yesNo={eachHasCompatibleDuplicity(items)}
            yesNoDisabled={processing || !selectedItemId}
            onConfirm={this.handleConfirm}
            onCancel={this.handleClose}
          />
        </Modal.Actions>
      </Modal>
    )
  }
}

function ConfirmQuestion({ items, selectedItemId, onSelectClick }) {
  const eachHasDuplicity = eachHasCompatibleDuplicity(items)

  return (
    <div>
      {eachHasDuplicity && items && items.length > 1
      && (
        <div>
          <p>
            <FormattedMessage id="record.form.solveDuplicities.confirm-text" />
          </p>
          <p>
            <strong>
              <FormattedMessage id="record.form.solveDuplicities.notice" />
            </strong>
          </p>
          <BasicRecordList
            records={items}
            selectable
            selectMethod="single"
            scrollable
            selectedIds={selectedItemId && [selectedItemId]}
            onSelect={onSelectClick}
          />
        </div>
      )}

      {!eachHasDuplicity
      && (
        <div>
          <FormattedMessage
            id="record.form.solveDuplicities.no-duplicities"
            values={{ daysCount: MAX_DAYS_DIFFERENCE }}
          />
        </div>
      )}
    </div>
  )
}

function Buttons({ yesNo, yesNoDisabled, onConfirm, onCancel }) {
  return yesNo ? (
    <Grid>
      <Grid.Column width={8}>
        <SecondaryButton fluid basic onClick={onCancel}>
          <FormattedMessage id="form.cancel" />
        </SecondaryButton>
      </Grid.Column>
      <Grid.Column width={8}>
        <PrimaryButton disabled={yesNoDisabled} fluid onClick={onConfirm}>
          <FormattedMessage id="form.save" />
        </PrimaryButton>
      </Grid.Column>
    </Grid>
  ) : (
    <Grid>
      <Grid.Column width={16}>
        <SecondaryButton fluid onClick={onCancel}>
          <FormattedMessage id="form.cancel" />
        </SecondaryButton>
      </Grid.Column>
    </Grid>
  )
}
