import barBackgroundPlugin from 'src/frontend/scenes/dashboard/widgets/PieAndBarSpending/barBackgroundPlugin'
import LinearChart from 'src/frontend/components/chart/LinearChart'
import React from 'react'
import { Dataset } from 'src/frontend/components/chart/types'
import { FilterType } from 'src/types/Filter'
import styles from './WidgetBarChart.module.less'
import { withIntlConsumer } from 'src/frontend/modules/intl/components/withIntlConsumer'
import { generateIntervalString, isToday } from 'src/backend/time/time'
import { Interval, RelativeIntervalType } from 'src/backend/enums'
import { FormattedMessage } from 'src/frontend/modules/intl'
import { Currency } from 'src/types/Currency'
import { ChartType } from 'src/frontend/components/chart/ChartType'
import { customLabel, filterExistingTooltip } from 'src/frontend/components/chart/helpers'

interface Props {
    datasets: Dataset[]
    labels: string[][]
    currency: Currency
    filter: FilterType
    locale: string
    height?: number
    filterIndex?: number
}

WidgetBarChart.defaultProps = {
    height: undefined,
}

function WidgetBarChart({ labels, currency, height, filter, locale, datasets, filterIndex = null }: Props) {
    const { period } = filter

    const [startLabel, endLabel] = generateIntervalString(
        { ...period, interval: Interval.CUSTOM },
        locale,
        true,
        false,
        RelativeIntervalType.RELATIVE,
        true
    ).split('-')

    const isEndToday = isToday(period.end)

    return (
        <div>
            <LinearChart
                hideHorizontalLines
                plainValues
                height={height}
                labelsPerChart={6}
                type={ChartType.BAR}
                labels={labels}
                datasets={datasets}
                currency={currency}
                hideLegend
                options={{
                    responsiveAnimationDuration: 0,
                    maintainAspectRatio: false,
                    tooltips: {
                        mode: 'nearest',
                        filter: filterExistingTooltip,
                        custom(tooltipModel) {
                            return customLabel(this._chart, tooltipModel, labels, datasets, currency, filterIndex)
                        },
                    },
                    plugins: {
                        BarBackgroundPlugin: {
                            color: '#f3f3f3',
                        },
                    },
                }}
                plugins={[barBackgroundPlugin]}
            />
            <div className={styles.periodLabels}>
                <div>{startLabel}</div>
                <div>{isEndToday ? <FormattedMessage id='today' /> : endLabel}</div>
            </div>
        </div>
    )
}


export default withIntlConsumer(WidgetBarChart)
