import React from 'react'
import { RootState } from 'src/types/State'
import { connect } from 'react-redux'
import { hideRefreshError } from 'src/frontend/scenes/integrations/connections/actions'
import { selectActiveErrorRefreshes } from 'src/frontend/scenes/integrations/connections/selectors'
import { ActiveRefresh } from 'src/frontend/scenes/integrations/connections/reducer'
import { FormattedMessage } from 'src/frontend/modules/intl'
import { HashMap } from 'src/types/common'
import { PrimaryButton } from 'src/frontend/components/Buttons/Buttons'

interface Props {
  errorRefreshes: HashMap<ActiveRefresh>
  handleKillRefresh: Function
}

function mapStateToProps(state: RootState) {
  return {
    errorRefreshes: selectActiveErrorRefreshes(state),
  }
}

const mapDispatchToProps = {
  handleKillRefresh: hideRefreshError,
}

function RefreshBankError({ errorRefreshes, handleKillRefresh }: Props) {
  const [errorRefresh] = Object.values(errorRefreshes)
  return (
    <div>
      <p>
        <FormattedMessage
          id="integrations.error.refresh"
          values={{ providerName: <strong>{errorRefresh.providerName}</strong> }}
        />
      </p>
      <PrimaryButton
        fluid
        onClick={() => handleKillRefresh(errorRefresh.loginId)}
      >
        <FormattedMessage id="form.ok" />
      </PrimaryButton>
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(RefreshBankError)
