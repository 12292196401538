import { RootState } from 'src/types/State'
import { selectModules } from 'src/frontend/modules/selectors'
import { createSelector } from 'node_modules/reselect'
import { compareStrings } from 'src/common/utils'
import { Contact } from 'src/types/Contact'
import ContactView = Contact.ContactView
import { formatMessage } from 'src/frontend/modules/intl/i18n'
import ContactOption = Contact.ContactOption
import { ContactColor } from 'src/backend/contacts/constants'

export const selectContacts = (state: RootState) => selectModules(state).contacts

export const selectSortedContacts: (RootState) => ContactView[] = createSelector(
  selectContacts,
  (contacts) => {
    return Object.values(contacts)
      .map((contact): ContactView => ({
        ...contact,
        contactTypeName: formatMessage(`contacts.type.${Contact.Type[contact.type]}`),
        color: ContactColor[contact.type],
      }))
      .sort((a, b) => compareStrings(a.name, b.name))
  },
)

export const selectSortedContactsOptions: (state: RootState) => ContactOption[] = createSelector(
  [
    selectSortedContacts,
  ],
  (contacts) => {
    return contacts.map((contact) => ({ ...contact, description: contact.contactTypeName }))
  },
)

