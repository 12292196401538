import { Contact } from 'src/types/Contact'
import Type = Contact.Type

export const ContactColor = {
  [Type.INTERNAL]: '#e74c3c',
  [Type.VENDOR]: '#0076FF',
  [Type.OTHER]: '#3F51B5',
  [Type.CUSTOMER]: '#00AA70',

}
