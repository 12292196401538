import React from 'react'
import styles from './SearchFilter.module.less'
import { Input } from 'semantic-ui-react'
import { formatMessage } from 'src/frontend/modules/intl/i18n'

interface Props {
  onChange: Function
  defaultValue: string
  disabled?: boolean
}

export default function SearchFilter({ onChange, defaultValue, disabled = false }) {
  return (
    <div className={styles.searchInput}>
      <Input
        input={{ "data-hj-whitelist": true }}
        icon="search"
        iconPosition="left"
        fluid
        disabled={disabled}
        maxLength={50}
        placeholder={formatMessage("search.action")}
        defaultValue={defaultValue}
        onChange={onChange}
      />
    </div>
  )
}
