import React from 'react'
import commonStyles from 'src/frontend/scenes/onBoarding/inApp/components/InAppTopBar.module.less'
import styles from './RecordsOnBoarding.module.less'
import { RootState } from 'src/types/State'
import { connect } from 'react-redux'
import {
  selectShouldDisplayRecordsOnBoarding,
} from 'src/frontend/scenes/onBoarding/inApp/selectors'
import { RouterProps } from 'react-router'
import { mergeClasses } from 'src/common/utils'
import FormattedMessage from 'src/frontend/modules/intl/components/FormattedMessage'
import EmptySpace from 'src/frontend/scenes/onBoarding/inApp/components/EmptySpace'

interface Props extends RouterProps {
  show: boolean,
}

function mapStateToProps(state: RootState) {
  return {
    show: selectShouldDisplayRecordsOnBoarding(state),
  }
}


function RecordsOnBoarding({ show }: Props) {
  return show
    ? (
      <div className={mergeClasses(commonStyles.topBar, commonStyles.inside)}>
        <div className={styles.recordsOnBoarding}>
          <h2><FormattedMessage id="onBoarding.records.title" /></h2>
          <p><FormattedMessage id="onBoarding.records.text" /></p>
        </div>
      </div>
    )
    : <EmptySpace />
}

export default connect(mapStateToProps)(RecordsOnBoarding)
