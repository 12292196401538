import styles from './CreditLimitsUtilizationLegend.module.less'
import React from 'react'
import FormattedMessage from 'src/frontend/modules/intl/components/FormattedMessage'

export default function CreditLimitUtilizationLegend() {

  return (
    <div className={styles.legend}>
      <div>
        <div className={styles.barAvg} />&nbsp;<FormattedMessage id="dashboard.widget.average-credit" />
      </div>
      <div>
        <div className={styles.barMax} />&nbsp;<FormattedMessage id="dashboard.widget.maximum-credit" />
      </div>
    </div>
  )
}
