import React from "react"
import SelectionComponent from "src/frontend/components/SelectionComponent"
import { formatMessage, FormattedMessage } from "src/frontend/modules/intl"
import { isTransfer } from "src/backend/records/helpers"
import {
  filterNotConnectedAccountOptions,
  VIRTUAL_ADD_ACCOUNT_ID,
  VIRTUAL_OUT_OF_WALLET_ACCOUNT_ID,
} from "src/backend/accounts/helpers"
import IconOption from "src/frontend/components/IconOption"
import { AccountIcon } from "src/frontend/components/Icons/Icons"
import { Account } from "src/types/Account"
import { RecordFormValues } from "src/frontend/scenes/records/recordForm/types"
import { connect } from "react-redux"
import { openAccountForm } from "src/frontend/scenes/settings/accounts/actions"
import { FormType } from "src/frontend/scenes/settings/enums"
import { AccountFormType } from "src/frontend/scenes/settings/accounts/enums"
import { Icon } from "semantic-ui-react"
import { RootState } from "src/types/State"
import { selectCanAddAccount } from "src/frontend/scenes/settings/accounts/selectors"

interface Props {
  formValues: RecordFormValues
  accounts?: Account[]
  name: string
  label?: string | React.ReactNode
  value?: string
  errors?: string
  disabled?: boolean
  canAddAccount: boolean
  onAccountChange: Function
  excludeAccountId?: string
  includeBankAccounts?: boolean
  handleOpenAddAccount: (
    formType: FormType,
    account: Account,
    accountFormType: AccountFormType,
  ) => void
}

function mapStateToProps(state: RootState) {
  return {
    canAddAccount: selectCanAddAccount(state),
  }
}

const mapDispatchToProps = {
  handleOpenAddAccount: openAccountForm,
}

SelectAccount.defaultProps = {
  label: "",
  value: "",
  accounts: [],
  errors: "",
  disabled: false,
}

function SelectAccount({
  formValues,
  name,
  label,
  value,
  accounts,
  errors,
  disabled,
  canAddAccount,
  onAccountChange,
  handleOpenAddAccount,
  excludeAccountId,
  includeBankAccounts = false,
}: Props) {
  const VIRTUAL_ADD_ACCOUNT = {
    _id: VIRTUAL_ADD_ACCOUNT_ID,
    accountType: VIRTUAL_ADD_ACCOUNT_ID,
    name: <FormattedMessage id="settings.accounts.form.add_account" />,
    color: "transparent",
  }

  const account = accounts.find((accountOption) => accountOption._id === value)

  let filteredAccounts = [...accounts, ...(canAddAccount ? [VIRTUAL_ADD_ACCOUNT] : [])].filter(
    (account) => account._id !== excludeAccountId,
  )

  if (!includeBankAccounts)
    filteredAccounts = filteredAccounts.filter(filterNotConnectedAccountOptions(account))

  const accountOptions = filteredAccounts.map(mapAccountToOption)

  return (
    <SelectionComponent
      className="icon-select"
      label={label}
      name={name}
      value={isTransfer(formValues) ? value || VIRTUAL_OUT_OF_WALLET_ACCOUNT_ID : value}
      options={accountOptions}
      placeholder={formatMessage("record.form.select_account")}
      error={errors}
      disabled={disabled}
      onChange={(_e, field) => {
        if (name === "fromAccountId" && field.value === formValues.toAccountId) {
          onAccountChange({
            name: "toAccountId",
            value: formValues.fromAccountId,
          })
        }

        if (field.value === VIRTUAL_ADD_ACCOUNT_ID) {
          handleOpenAddAccount(FormType.ADD, null, AccountFormType.MANUAL)
        } else {
          onAccountChange(field)
        }
      }}
    />
  )
}

function mapAccountToOption(account) {
  return {
    key: account._id,
    value: account._id,
    selected: false,
    active: false,
    text: account._id !== VIRTUAL_ADD_ACCOUNT_ID && (
      <IconOption
        icon={
          <AccountIcon
            accountType={account.accountType}
            color={account.color}
            size="1.8rem"
          />
        }
        label={account.name}
      />
    ),
    content:
      account._id !== VIRTUAL_ADD_ACCOUNT_ID ? (
        <IconOption
          icon={
            <AccountIcon
              accountType={account.accountType}
              color={account.color}
              size="1.8rem"
            />
          }
          label={account.name}
        />
      ) : (
        <IconOption
          icon={<Icon name="plus" />}
          label={account.name}
        />
      ),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectAccount)
