import { AbstractEnvelope, Category, SuperEnvelope } from 'src/types/Category'
import { CategoryType } from 'src/backend/enums'
import { getEnvelopeType } from 'src/backend/categories/service'
import {
  OPERATING_COSTS_SUPER_ENVELOPE_IDS,
  OPERATING_REVENUE_SUPER_ENVELOPE_IDS,
  SYSTEM_CATEGORIES_BANK_STATEMENT,
  SYSTEM_CATEGORIES_ID,
  SYSTEM_CATEGORIES_ONE_CLICK_WIDGET,
  SYSTEM_CATEGORIES_TRANSFER_ID,
  SYSTEM_CATEGORIES_UNKNOWN_ID,
  UNKNOWN_RECORDS_ENVELOPE_ID,
  UNKNOWN_RECORDS_EXPENSE_ENVELOPE_ID,
  UNKNOWN_RECORDS_INCOME_ENVELOPE_ID,
} from "src/backend/categories/envelopes"

export function isEnvelopeCategory(envelope: Category) {
  return envelope._id !== envelope.envelopeId
}

export function isEnvelopeExpense(envelope: SuperEnvelope | AbstractEnvelope): boolean {
  return getEnvelopeType(envelope) === CategoryType.EXPENSE
}

export function isEnvelopeIncome(envelope: SuperEnvelope | AbstractEnvelope): boolean {
  return getEnvelopeType(envelope) === CategoryType.INCOME
}

export function isOperatingRevenue(envelope: SuperEnvelope | AbstractEnvelope): boolean {
  return OPERATING_REVENUE_SUPER_ENVELOPE_IDS.includes(envelope.id)
}

export function isOperatingCost(envelope: SuperEnvelope | AbstractEnvelope): boolean {
  return OPERATING_COSTS_SUPER_ENVELOPE_IDS.includes(envelope.id)
}

export function isUnknownEnvelope(envelope: SuperEnvelope | AbstractEnvelope): boolean {
  return envelope.id === SYSTEM_CATEGORIES_UNKNOWN_ID
}

export function isSystemEnvelope(envelope: SuperEnvelope | AbstractEnvelope): boolean {
  return [SYSTEM_CATEGORIES_UNKNOWN_ID, SYSTEM_CATEGORIES_BANK_STATEMENT, SYSTEM_CATEGORIES_ONE_CLICK_WIDGET, SYSTEM_CATEGORIES_TRANSFER_ID, SYSTEM_CATEGORIES_ID].includes(envelope.id)
}

export function isRecordUnknownEnvelope(envelope: SuperEnvelope | AbstractEnvelope): boolean {
  return [
    UNKNOWN_RECORDS_ENVELOPE_ID,
    UNKNOWN_RECORDS_EXPENSE_ENVELOPE_ID,
    UNKNOWN_RECORDS_INCOME_ENVELOPE_ID,
  ].includes(envelope.id)
}
