/* eslint-disable react/prop-types */
import React from 'react'
import { connect } from 'react-redux'
import './Templates.less'
import { Input } from 'semantic-ui-react'
import { SortableElement } from 'react-sortable-hoc'
import DragHandle from 'src/frontend/scenes/settings/components/DragHandle'
import SortableList from 'src/frontend/scenes/settings/components/SortableList'
import { FormType } from 'src/frontend/scenes/settings/enums'
import RemoveTemplateForm from './RemoveTemplateForm'
import { CategoryIcon } from 'src/frontend/components/Icons/Icons'
import TemplateForm from './TemplateForm'
import { FormattedMessage, formatMessage, FormattedMoney } from 'src/frontend/modules/intl'
import * as templateSelectors from 'src/frontend/modules/templates/selectors'
import * as selectors from '../selectors'
import { selectUser } from 'src/frontend/modules/user/selectors'
import * as actions from '../actions'
import { RecordType } from 'src/backend/enums'
import EmptyState from 'src/frontend/scenes/settings/components/EmptyState'
import _isEmpty from 'lodash/isEmpty'
import { PrimaryButton } from 'src/frontend/components/Buttons/Buttons'

const mapStateToProps = (state) => {
  const { form } = selectors.selectTemplates(state)
  const sortedTemplates = templateSelectors.selectSortedTemplates(state)
  return {
    templates: sortedTemplates,
    formValues: form.formValues,
    formType: form.formType,
    user: selectUser(state),
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    handleChangePosition({ oldIndex, newIndex }) {
      dispatch(actions.reorderTemplates(oldIndex, newIndex))
    },
    handleChangeName(event, field) {
      console.log('handleChangeName', event, field)
      event.preventDefault()
      dispatch(actions.changeName(field.name, field.value))
    },
    handleOpenForm(event, formType, template = null) {
      console.log('handleOpenForm', event)
      dispatch(actions.openForm(formType, template))
    },
  }
}

const Templates = (props) => {
  const {
    templates,
    formType,
    formValues,
    handleChangePosition,
    handleChangeName,
    handleOpenForm,
    user,
  } = props

  const addAccountInputValue = formType === FormType.ADD ? formValues.name : ''

  return (
    <div className="settings-page">
      <div className="settings-page-add">
        <div className="title">
          <FormattedMessage id="settings.templates.add_new" />
        </div>
        <div className="container">
          <div className="item input">
            <InputWithAddButton
              name="name"
              value={addAccountInputValue || ''}
              placeholder={formatMessage('settings.templates.form.name_placeholder')}
              onChange={handleChangeName}
              onClick={e => handleOpenForm(e, FormType.ADD)}
            />
            <TemplateForm />
          </div>
        </div>
      </div>
      <div className="settings-page-list">
        <div className="title">
          <FormattedMessage id="settings.templates.your_templates" />
        </div>
        <div className="templates-row list-header">
          <div className="templates-row-drag header" />
          <div className="templates-row-category header" />
          <div className="templates-row-name header">
            <FormattedMessage id="form.name" />
          </div>
          <div className="templates-row-amount header">
            <FormattedMessage id="settings.templates.table.amount" />
          </div>
          <div className="templates-row-account header">
            <FormattedMessage id="settings.templates.table.account" />
          </div>
          <div className="templates-row-toolbar" />
        </div>
        {!_isEmpty(templates)
          ? (
            <TemplatesList
              items={templates}
              user={user}
              handleReorder={handleChangePosition}
              handleOpenForm={handleOpenForm}
            />
          ) : (
            <EmptyState
              title={<FormattedMessage id="settings.templates.empty.title" />}
              text={(
                <FormattedMessage
                  id="settings.templates.empty.text"
                  values={{ addButton: `(＋ ${formatMessage('form.add')})` }}
                />
              )}
            />
          )}
      </div>
    </div>
  )
}

const InputWithAddButton = ({ name, placeholder, value, onClick, onChange }) => (
  <div className="ui input">
    <div className="ui action input">
      <Input
        name={name}
        className="action"
        placeholder={placeholder}
        maxLength="20"
        value={value}
        onChange={onChange}
      />
      <PrimaryButton type="submit" onClick={onClick}>
        ＋
        &nbsp;
        {formatMessage('form.add')}
      </PrimaryButton>
    </div>
  </div>
)

const TemplatesList = SortableList(({ items, handleOpenForm }) => (
  <ul>
    {items.map((template, index) => (
      <TemplateItem key={template._id} index={index} template={template} onClick={handleOpenForm} />
    ))}
  </ul>
))

const TemplateItem = SortableElement(({ template, onClick }) => {

  const sign = template.type === RecordType.EXPENSE && '-' || ''
  return (
    <li className="templates-row">
      <div className="templates-row-drag">
        <DragHandle />
      </div>
      <div className="templates-row-category">
        <CategoryIcon icon={template.icon} color={template.color} />
      </div>
      <div className="templates-row-name">{template.name}</div>
      <div className="templates-row-amount">
        <FormattedMoney sign={sign} value={template.amount} currency={template.currencyCode} />
      </div>
      <div className="templates-row-account">{template.accountName}</div>
      <div className="templates-row-toolbar">
        <div>
          <span onClick={e => onClick(e, FormType.EDIT, template)}>
            <FormattedMessage id="edit" />
          </span>
          <span className="delete">
            <RemoveTemplateForm template={template} />
          </span>
        </div>
      </div>
    </li>
  )
})

export default connect(mapStateToProps, mapDispatchToProps)(Templates)
