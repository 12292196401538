import React from 'react'
import {
  Grid, Loader, Modal,
} from 'semantic-ui-react'
import { FormattedMessage } from 'src/frontend/modules/intl'
import { recordHasConnectedAccount } from 'src/backend/records/helpers'
import BasicRecordList from 'src/frontend/components/RecordList/BasicRecordList'
import _groupBy from 'lodash/groupBy'
import _isEmpty from 'lodash/isEmpty'
import { Record } from 'src/types/Record'
import { onlyConnectedRecords } from 'src/frontend/scenes/records/removeForm/helpers'
import { PrimaryButton, SecondaryButton } from 'src/frontend/components/Buttons/Buttons'

interface Props {
  open: boolean
  processing?: boolean
  items?: Record[]
  trigger?: React.ReactNode
  onCancelClick: Function
  onConfirmClick: Function
}

export default class RemoveRecordForm extends React.Component<Props> {
  static defaultProps = {
    processing: true,
    items: [],
    trigger: undefined,
  }

  shouldComponentUpdate(nextProps) {
    // Avoid to re-render closed modals
    return nextProps.processing || (!this.props.open && nextProps.open) || (this.props.open && !nextProps.open)
  }

  handleClose = () => {
    this.props.onCancelClick()
  }

  handleConfirm = () => {
    this.props.onConfirmClick()
  }

  render() {
    const { items, open, processing, trigger } = this.props

    const RESERVED_MODEL_TYPE = 'reservedModelType'
    const grouppedItems = _groupBy(items, RESERVED_MODEL_TYPE)

    const commonRecords = grouppedItems.Record
      && grouppedItems.Record.filter(record => !recordHasConnectedAccount(record))
    const connectedRecords = grouppedItems.Record
      && grouppedItems.Record.filter(record => recordHasConnectedAccount(record))
    const standingOrders = grouppedItems.StandingOrder

    return (
      <Modal trigger={trigger} open={open} size="small" onClose={this.handleClose}>
        <Modal.Header>
          <FormattedMessage
            id="record.form.removeRecords"
            values={{ recordsCount: grouppedItems.Record && grouppedItems.Record.length }}
          />
        </Modal.Header>
        <Modal.Content>
          {!processing ? (
            <ConfirmQuestion
              commonRecords={commonRecords}
              connectedRecords={connectedRecords}
              standingOrders={standingOrders}
            />
          ) : (
            <Loader active inline="centered" />
          )}
        </Modal.Content>
        <Modal.Actions>
          <Buttons
            yesNo={!onlyConnectedRecords(commonRecords, connectedRecords) || !_isEmpty(standingOrders)}
            yesNoDisabled={processing}
            onConfirm={this.handleConfirm}
            onCancel={this.handleClose}
          />
        </Modal.Actions>
      </Modal>
    )
  }
}

function ConfirmQuestion({ commonRecords, connectedRecords, standingOrders }) {
  const onlyConnected = onlyConnectedRecords(commonRecords, connectedRecords)

  return (
    <div>
      {!onlyConnected && commonRecords && commonRecords.length > 0
      && (
        <div>
          <FormattedMessage
            id={{ W: 'record.form.removeRecords_confirm_question', B: 'transaction.form.remove_prompt' }}
            values={{ recordsCount: commonRecords.length }}
          />
          <BasicRecordList scrollable records={commonRecords} />
        </div>
      )}

      {!onlyConnected && connectedRecords && connectedRecords.length > 0
      && (
        <div>
          <FormattedMessage id="record.form.removeRecords_connected_records_info" />
          <BasicRecordList scrollable records={connectedRecords} />
        </div>
      )}

      {standingOrders && standingOrders.length > 0
      && (
        <div>
          <FormattedMessage
            id="record.form.removeRecords_standingOrders_confirm_question"
            values={{ recordsCount: standingOrders.length }}
          />
          <BasicRecordList scrollable records={standingOrders} />
        </div>
      )}

      {onlyConnected
      && (
        <div>
          <FormattedMessage id="record.form.removeRecords_only_connected_records_info" />
        </div>
      )}
    </div>
  )
}

function Buttons({ yesNo, yesNoDisabled, onConfirm, onCancel }) {
  return yesNo ? (
    <Grid>
      <Grid.Column width={8}>
        <SecondaryButton
          fluid
          onClick={(e) => {
            e.stopPropagation()
            return onCancel(e)
          }}
        >
          <FormattedMessage id="form.no" />
        </SecondaryButton>
      </Grid.Column>
      <Grid.Column width={8}>
        <PrimaryButton
          disabled={yesNoDisabled}
          fluid
          color="red"
          onClick={(e) => {
            e.stopPropagation()
            return onConfirm(e)
          }}
        >
          <FormattedMessage id="form.yes" />
        </PrimaryButton>
      </Grid.Column>
    </Grid>
  ) : (
    <Grid>
      <Grid.Column width={16}>
        <SecondaryButton
          fluid
          onClick={(e) => {
            e.stopPropagation()
            return onCancel(e)
          }}
        >
          <FormattedMessage id="form.cancel" />
        </SecondaryButton>
      </Grid.Column>
    </Grid>
  )
}
