import { Icon, Transition } from 'semantic-ui-react'
import React from 'react'
import { FormattedMessage } from 'src/frontend/modules/intl'
import styles from './DepthControls.module.less'
import { ComplementaryButton } from 'src/frontend/components/Buttons/Buttons'

interface Props {
  showBack: boolean,
  showDeeper: boolean,
  onGoDeeper: (SyntheticEvent) => void,
  onGoBack: (SyntheticEvent) => void,
}

function DepthControls({
  showBack,
  showDeeper,
  onGoDeeper,
  onGoBack,
}: Props) {
  return (
    <>
      <Transition.Group as="div" className={styles.goBack}>
        {showBack && (
          <ComplementaryButton
            onClick={onGoBack}
          >
            <Icon name="chevron left" />
            <FormattedMessage id="form.back" />
          </ComplementaryButton>
        )}
      </Transition.Group>
      <Transition.Group as="div" className={styles.goDeeper}>
        {showDeeper && (
          <ComplementaryButton
            onClick={onGoDeeper}
          >
            <FormattedMessage id="dashboard.widget.go-deeper" />
            <Icon name="chevron right" />
          </ComplementaryButton>
        )}
      </Transition.Group>
    </>
  )
}

export default React.memo(DepthControls)
