import { OnBoardingStage } from 'src/frontend/scenes/onBoarding/firstRun/enums'
import styles from 'src/frontend/scenes/onBoarding/firstRun/components/OnBoardingTopBar.module.less'
import React from 'react'
import { mergeClasses } from 'src/common/utils'
import FormattedMessage from 'src/frontend/modules/intl/components/FormattedMessage'
import { BasicIcon } from 'src/frontend/components/Icons/Icons'
import { OnBoardingStatus } from 'src/backend/onBoarding/types'

interface Props {
  stage: OnBoardingStage
  stages: OnBoardingStage[]
  status: OnBoardingStatus
}

export default function OnBoardingTopBar({ stage, stages, status }: Props) {
  return (
    <div className={styles.status}>
      {getStages(stages, stage, status)}
    </div>
  )
}

function getStages(stages: OnBoardingStage[], activeStage: OnBoardingStage, status: OnBoardingStatus) {
  return stages.map((stage, index) => {
    return (
      <React.Fragment key={stage}>
        <div className={mergeClasses(styles.item, stage === activeStage && styles.active)} key={stage}>
          {!status[stage]
            ? <div className={styles.bullet}>{index + 1}</div>
            : <BasicIcon name="checkmark" size="1.7rem" />}
          <div className={styles.description}>
            <FormattedMessage id={`onBoarding.${OnBoardingStage[stage]}.title`} />
          </div>
        </div>
        {index < stages.length - 1 && <div className={styles.line} />}
      </React.Fragment>
    )
  })
}
