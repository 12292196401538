import { BaseError } from 'make-error'

export class WalletRestApiError extends BaseError {
  constructor(message, response) {
    super(`WalletRestApiError: ${message}`)
    this.response = response
  }
}

export class WalletAuthError extends BaseError {
  constructor(message, response) {
    super(message)
    this.response = response
  }
}

export class WalletError extends BaseError {
  constructor(message, error) {
    super(message)
    this.error = error
  }
}

export class WalletValidationError extends BaseError {
  constructor(errorCode, messageId = null) {
    super(`WalletValidationError messageId=${messageId}, errorCode=${errorCode}`)
    this.errorCode = errorCode
    this.messageId = messageId || errorCode
  }
}

export class WalletFormValidationError extends BaseError {
  constructor(errors) {
    super('WalletFormValidationError')
    this.errors = errors
    const [message] = Object.values(this.errors)
    this.message = message
  }
}

export class WalletBillingError extends BaseError {
  constructor(errorCode, response) {
    super(errorCode)
    this.response = response
    this.messageId = WalletBillingError.getMessageId(errorCode)
  }

  static getMessageId(errorCode) {
    const errorCodes = Object.keys(WalletBillingError.processorErrorCodes)
    const defaultMessageId = 'billing.error.general'

    return errorCodes.includes(errorCode) ? WalletBillingError.processorErrorCodes[errorCode] : defaultMessageId
  }

  static processorErrorCodes = {
    /* eslint-disable quote-props */
    '2001': 'billing.error.insufficient-funds',
    '2002': 'billing.error.limit-exceeded',
    '2004': 'billing.error.expired-card',
    '2005': 'billing.error.invalid-credit-card-number',
    '2006': 'billing.error.invalid-expiration-date',
    '2007': 'billing.error.no-account',
    '2008': 'billing.error.card-account-length-error',
    '2009': 'billing.error.invalid-card-issuer',
    '2010': 'billing.error.card-issuer-declined-cvv',
    '2011': 'billing.error.voice-authorization-required',
    '2039': 'billing.error.invalid-authorization-code',
    '2070': 'billing.error.paypal-revoked',
    '3000': 'billing.error.network-error',
    'authenticate_failed': 'billing.error.three-d-authentication-failed',
    'authenticate_signature_verification_failed': 'billing.error.three-d-authentication-failed',
    'authenticate_error': 'billing.error.three-d-authentication-failed',
    /* eslint-enable */
  }
}

export class WalletBillingAlreadyBoughtError extends BaseError {

}

export class WalletBillingUserNotFoundError extends BaseError {

}

export class WalletBillingUserDeletedError extends BaseError {

}
