import { path } from 'ramda'
import * as logger from 'src/common/logger'
import {
  deleteImportItem as deleteImportItemRequest,
  getInitialMapping,
  isItemImported,
} from 'src/backend/imports/service'
import {
  expireAccountsStatus,
  expireAnalyticsStatus,
  expireDashboardStatus, expireImportsRecordListStatus,
  expireRecordsStatus,
} from 'src/frontend/modules/moduleStatus/actions'
import { expireUser } from 'src/frontend/modules/user/actions'
import { showError } from 'src/frontend/scenes/app/actions'
import { findImportedItemId, getItemTransactionId } from 'src/frontend/scenes/imports/items/helpers'
import { deleteRecordsByTransactionId } from 'src/backend/records/service'
import {
  selectItemIdToDelete,
  selectItemList,
  selectUploadedItems,
  selectVisibleImportedItems,
} from 'src/frontend/scenes/imports/items/selectors'
import { reloadItems, showInvalidFileModal } from 'src/frontend/scenes/imports/actions'
import { getInitialMappingSuccess, validateAndOpenMapping } from 'src/frontend/scenes/imports/mapping/actions'
import { getErrorText, isAuthorizationError } from 'src/common/helpers'

export const GET_ITEMS_SUCCESS = 'imports/GET_ITEMS_RECEIVED'
export const IMPORTS_IMPORT_ITEM_CLICKED = 'imports/IMPORT_ITEM_CLICKED'
export const ITEM_VISIBILITY_TOGGLE = 'imports/ITEM_VISIBILITY_TOGGLE'
export const CONFIRM_DELETE_ITEM = 'imports/CONFIRM_DELETE_ITEM'
export const DELETE_ITEMS_DONE = 'imports/DELETE_ITEMS_DONE'
export const CANCEL_DELETE_ITEMS = 'imports/CANCEL_DELETE_ITEMS'
export const DELETE_ITEMS_PROGRESS = 'imports/DELETE_ITEMS_PROGRESS'
export const SHOW_NEXT = 'imports/SHOW_NEXT'

export const IMPORT_ITEM_START = 'imports/IMPORT_ITEM_START'
export const IMPORT_ITEM_END = 'imports/IMPORT_ITEM_END'

export const itemDetailToggle = itemId => ({ type: IMPORTS_IMPORT_ITEM_CLICKED, itemId })

export const itemVisibilityToggle = itemId => (dispatch, getState) => {
  const importedItemsCount = selectVisibleImportedItems(getState()).length
  dispatch({ type: ITEM_VISIBILITY_TOGGLE, itemId, importedItemsCount })
  dispatch(expireImportsRecordListStatus())
}

export const importedItemsReceived = payload => ({ type: GET_ITEMS_SUCCESS, ...payload })

export const confirmDeleteItem = itemId => (dispatch, getState) => {
  const itemTransactionId = getItemTransactionId(selectItemList(getState()), itemId)
  dispatch({ type: CONFIRM_DELETE_ITEM, itemId, itemTransactionId })
}

export const cancelDeleteItem = () => ({ type: CANCEL_DELETE_ITEMS })

/**
 * Common function for deleting uploaded or uploaded and imported items
 */
export const deleteItem = () => async (dispatch, getState) => {
  const itemId = selectItemIdToDelete(getState())
  try {
    dispatch({ type: DELETE_ITEMS_PROGRESS })
    const item = findImportedItemId(selectUploadedItems(getState()), itemId)
    console.log(`action=deleteItem itemId=${itemId}, item=[id=${item.itemId}, transactionId=${item.transactionId}]`)
    await deleteImportItemRequest(itemId)
    if (isItemImported(item)) {
      await deleteRecordsByTransactionId(item.transactionId)
      dispatch(expireAccountsStatus())
      dispatch(expireDashboardStatus())
      dispatch(expireRecordsStatus())
      dispatch(expireAnalyticsStatus())
    }
    await dispatch(reloadItems())
    dispatch({ type: DELETE_ITEMS_DONE })
  } catch (error) {
    dispatch(cancelDeleteItem(itemId))
    if (isAuthorizationError(error)) {
      dispatch(expireUser())
    } else {
      logger.error(`action=deleteItem unexpected error: ${getErrorText(error)}`, { error })
      dispatch(showError(['app.error.default_error_message', 'app.error.we_are_fixing_suffix']))
    }
  }
}

function importItemStart() {
  return {
    type: IMPORT_ITEM_START,
  }
}

function importItemEnd() {
  return {
    type: IMPORT_ITEM_END,
  }
}

/**
 * Load mapping settings from the backend and open mapping page
 */
export function importItem(itemId) {
  return (dispatch) => {
    dispatch(importItemStart())
    return getInitialMapping(itemId)
      .then(initialMapping => {
        dispatch(getInitialMappingSuccess(itemId, initialMapping))
        return dispatch(validateAndOpenMapping())
      })
      .then(() => dispatch(importItemEnd()))
      .catch(error => {
        if (isAuthorizationError(error)) {
          dispatch(expireUser())
        } else if (path(['response', 'status'], error) === 412) {
          // FIXME hardcoded 412 on multiple places
          dispatch(showInvalidFileModal())
        } else {
          dispatch(showError(['app.error.default_error_message', 'app.error.we_are_fixing_suffix']))
          logger.error(`action=importItem unexpected error: ${getErrorText(error)}`, { error })
        }
      })
  }
}

export const showNext = () => ({ type: SHOW_NEXT })
