export enum NewIntegrationsStatus {
  /**
   * select user country and bank
   */
  PROVIDER_SELECT,

  /**
   * collect mandatory information from user
   */
  PERSONAL_INFORMATION,

  /**
   * Credentials form
   * @see IntegrationProviders.IntegrationProviderDetail
   */
  CREDENTIALS_FORM,

  /**
   * Loading state - communicating with bank - CHECK_LATER integrationStatus
   * @see IntegrationProviders.IntegrationLoginStatus
   */
  LOADING,

  /**
   * MFA form - collect additional information from user (sms/captcha etc)
   */
  MFA_FORM,

  /**
   * Select, which connected remote accounts should be created in Wallet
   */
  INTEGRATED_ACCOUNT_SELECT,
}

export enum ExistingIntegrationStatus {
  IDLE,

  CREDENTIALS_FORM,

  LOADING,

  MFA_FORM,

  ERROR,
}
