import { Action } from 'src/types/common'
import { Contact } from 'src/types/Contact'
import ContactDocument = Contact.ContactDocument
import { CONTACTS_RECEIVE } from 'src/frontend/modules/contacts/actions'

export interface ContactsState {
  [key: string]: ContactDocument
}

export default function contacts(state: ContactsState = {}, action: Action) {
  switch (action.type) {
    case CONTACTS_RECEIVE: {
      return { ...action.payload }
    }

    default: {
      return state
    }
  }
}
