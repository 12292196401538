import { convertResultToEntity, fetchAllFromTable } from "src/backend/db/inMemorySqlDb"
import { inMemoryTableNames } from "src/backend/db/inMemorySqlDbSchemaBuilder"
import { DEPRECATED_ConfigWebDashboardV1 } from "src/types/WebConfig"

/**
 * @deprecated use WebConfig instead
 */
export function DEPRECATED_findDashboardConfig() {
  return fetchAllFromTable<DEPRECATED_ConfigWebDashboardV1>(
    inMemoryTableNames.DEPRECATED_CONFIG_WEB_DASHBOARD,
  ).then(convertResultToEntity())
}
