import { UserLoginStatus } from 'src/frontend/modules/user/enums'
import * as actions from './actions'
import { User } from 'src/types/User'

const initialUserState = {
  loading: false,
  loginStatus: UserLoginStatus.NOT_AUTHENTICATED,
}

export default function user(state: User = initialUserState as User, action) {
  switch (action.type) {
    case actions.GET_USER_ERROR: {
      return { ...state, loading: false }
    }
    case actions.GET_USER_END: {
      return { ...state, ...action.payload, loading: false }
    }
    case actions.LOGIN_USER_START: {
      return { ...state, loading: true }
    }
    case actions.EXPIRE_USER: {
      return { ...state, loginStatus: UserLoginStatus.EXPIRED }
    }
    case actions.LOGOUT_USER_END:
      return { ...state, ...action.payload }
    case actions.SET_USER_CONSENT: {
      // 'type' based on UserConsentType that matches UserConsent proto file
      const { type, value } = action.payload
      const updatedConsent = { ...state.consents, [type]: value }
      return { ...state, consents: updatedConsent }
    }
    default:
      return state
  }
}
