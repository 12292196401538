import React from 'react'
import styles from './WalletDownloadIcons.module.less'
import { Link } from 'react-router-dom'
import { isAppBoard } from 'src/common/environment'
import { mergeClasses } from 'src/common/utils'

const StoreURL = isAppBoard()
  ? {
    APPLE_STORE: 'https://itunes.apple.com/app/board-sales-expense-tracker/id1473099421?utm_source=webapp',
    GOOGLE_PLAY: 'https://play.google.com/store/apps/details?id=com.droid4you.application.board&referrer=utm_source%3Dwebapp',
    WEB: 'https://web-board.budgetbakers.com',
  }
  : {
    APPLE_STORE: 'https://itunes.apple.com/app/wallet-personal-finance-tracker/id1032467659?utm_source=webapp',
    GOOGLE_PLAY: 'https://play.google.com/store/apps/details?id=com.droid4you.application.wallet&referrer=utm_source%3Dwebapp',
    WEB: 'https://web.budgetbakers.com',
  }

type Props = {
  showWeb?: boolean,
  inline?: boolean,
}

export default function WalletDownloadIcons({ showWeb = false, inline = false }: Props) {
  return (
    <div className={mergeClasses(styles.storeIcons, inline && styles.inline)}>
      {StoreURL.GOOGLE_PLAY && (
        <div>
          <a
            className={mergeClasses(styles.buttonStore, styles.googlePlay)}
            href={StoreURL.GOOGLE_PLAY}
            target="_blank"
            rel="noopener noreferrer"
          />
        </div>
      )}
      {StoreURL.APPLE_STORE && (
        <div>
          <a
            className={mergeClasses(styles.buttonStore, styles.appleStore)}
            href={StoreURL.APPLE_STORE}
            target="_blank"
            rel="noopener noreferrer"
          />
        </div>
      )}
      {showWeb
        ? <div><Link className={mergeClasses(styles.buttonStore, styles.webapp)} to="/" target="_blank" /></div>
        : null}
    </div>
  )
}
