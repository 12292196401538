import React from "react"
import { connect } from "react-redux"
import { Modal } from "semantic-ui-react"
import { FormattedMessage } from "src/frontend/modules/intl/index"
import { selectUserSession } from "src/frontend/modules/modals/selectors"
import { PrimaryButton } from "src/frontend/components/Buttons/Buttons"

const mapStateToProps = (state) => ({
  userSession: selectUserSession(state),
})

interface Props {
  userSession: {
    open: boolean
    expired: boolean
  }
  onClick: (SyntheticEvent) => void
}

function UserSessionModal({ userSession, onClick }: Props) {
  const { expired } = userSession
  return (
    <Modal
      open={expired}
      size="small"
    >
      <Modal.Header>
        <FormattedMessage id="modals.user_session.session_expired.header" />
      </Modal.Header>
      <Modal.Content>
        <FormattedMessage id="modals.user_session.session_expired.info_message" />
      </Modal.Content>
      <Modal.Actions>
        <PrimaryButton
          fluid
          onClick={onClick}
        >
          <FormattedMessage id="modals.user_session.session_expired.go_to_login_button" />
        </PrimaryButton>
      </Modal.Actions>
    </Modal>
  )
}

export default connect(mapStateToProps)(UserSessionModal)
