import React from 'react'
import { Modal } from 'semantic-ui-react'
import { RootState } from 'src/types/State'
import { ExistingIntegrationStatus } from 'src/frontend/scenes/integrations/enums'
import { closeExistingIntegrationsForm } from 'src/frontend/scenes/integrations/connections/actions'
import {
  selectDialogRefreshes,
  selectExistingConnectionsModalOpen,
} from 'src/frontend/scenes/integrations/connections/selectors'

import { ActiveRefresh } from 'src/frontend/scenes/integrations/connections/reducer'
import { connect } from 'react-redux'
import RefreshBankMFA from 'src/frontend/scenes/integrations/connections/components/RefreshBankMFA'
import RefreshBankError from 'src/frontend/scenes/integrations/connections/components/RefreshBankError'
import { FormattedMessage } from 'src/frontend/modules/intl'
import { HashMap } from 'src/types/common'

interface Props {
  activeRefreshes: HashMap<ActiveRefresh>
  open: boolean
  handleCloseForm: Function
}

function mapStateToProps(state: RootState) {
  return {
    activeRefreshes: selectDialogRefreshes(state),
    open: selectExistingConnectionsModalOpen(state),
  }
}

const mapDispatchToProps = {
  handleCloseForm: closeExistingIntegrationsForm,
}

function RefreshBankModal({
  open,
  activeRefreshes,
  handleCloseForm,
}: Props) {
  const activeRefreshesArray = Object.values(activeRefreshes)

  const activeMFARefresh = activeRefreshesArray.find((activeRefresh: ActiveRefresh) => {
    return activeRefresh.integrationStatus === ExistingIntegrationStatus.MFA_FORM
  })
  const activeErrorRefresh = activeRefreshesArray.find((activeRefresh: ActiveRefresh) => {
    return activeRefresh.integrationStatus === ExistingIntegrationStatus.ERROR
  })

  const integrationStatus = activeErrorRefresh && activeErrorRefresh.integrationStatus
    || activeMFARefresh && activeMFARefresh.integrationStatus

  const modalContent = getModalContent(integrationStatus)

  return (
    <Modal
      size="small"
      open={open}
      onClose={() => handleCloseForm()}
    >
      <Modal.Header>
        <FormattedMessage
          id={integrationStatus === ExistingIntegrationStatus.ERROR
            ? 'integrations.error.refresh.header'
            : 'integrations.connections.mfa.header'}
        />
        <span className="modal-close" onClick={() => handleCloseForm()} />
      </Modal.Header>
      <Modal.Content>
        {modalContent}
      </Modal.Content>
    </Modal>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(RefreshBankModal)

function getModalContent(integrationStatus: ExistingIntegrationStatus) {
  switch (integrationStatus) {
    default: {
      return null
    }

    case ExistingIntegrationStatus.MFA_FORM: {
      return <RefreshBankMFA />
    }

    case ExistingIntegrationStatus.ERROR: {
      return <RefreshBankError />
    }
  }
}
