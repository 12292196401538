import { isAuthorizationError } from 'src/common/helpers'
import { expireUser } from 'src/frontend/modules/user/actions'
import * as logger from 'src/common/logger'
import { showError } from 'src/frontend/scenes/app/actions'
import { DEFAULT_ERROR_MESSAGE_ID } from 'src/frontend/Error/actions'
import { fetchProviderRestrictions } from 'src/backend/rest/backend/integrations'

export const FETCH_RESTRICTED_PROVIDERS_START = 'integrations/restrictedProviders/FETCH_RESTRICTED_PROVIDERS_START'
export const FETCH_RESTRICTED_PROVIDERS_SUCCESS = 'integrations/restrictedProviders/FETCH_RESTRICTED_PROVIDERS_SUCCESS'
export const FETCH_RESTRICTED_PROVIDERS_ERROR = 'integrations/restrictedProviders/FETCH_RESTRICTED_PROVIDERS_ERROR'


export function fetchRestrictedProviders() {
  return async (dispatch: Function) => {
    dispatch(fetchRestrictedProvidersStart())
    try {
      const providerRestrictionsResult = await fetchProviderRestrictions()
      console.log("fetchProviderRestrictions: ", providerRestrictionsResult)
      const { restrictions } = providerRestrictionsResult
      dispatch(fetchRestrictedProvidersSuccess(restrictions))
    } catch (error) {
      if (isAuthorizationError(error)) {
        dispatch(expireUser())
        return
      } else {
        logger.captureException(error, 'integrations.restrictedProviders.fetchRestrictedProviders')
        dispatch(showError([DEFAULT_ERROR_MESSAGE_ID, 'app.error.we_are_fixing_suffix']))
      }
    }
  }
}

function fetchRestrictedProvidersStart() {
  return {
    type: FETCH_RESTRICTED_PROVIDERS_START,
  }
}

function fetchRestrictedProvidersSuccess(restrictedProviders: string[]) {
  return {
    type: FETCH_RESTRICTED_PROVIDERS_SUCCESS,
    payload: restrictedProviders,
  }
}
