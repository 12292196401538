import React from 'react'
import { Placeholder } from 'node_modules/semantic-ui-react'
import styles from 'src/frontend/scenes/dashboard/components/Widget/Widget.module.less'

export default function WidgetPlaceholder() {
  return (
    <div className={styles.placeholderWidget}>
      <Placeholder>
        <Placeholder.Header image>
          <Placeholder.Line length="medium" />
          <Placeholder.Line length="short" />
        </Placeholder.Header>
      </Placeholder>
      <Placeholder>
        <Placeholder.Paragraph>
          <Placeholder.Line />
          <Placeholder.Line />
          <Placeholder.Line />
          <Placeholder.Line />
          <Placeholder.Line />
          <Placeholder.Line />
          <Placeholder.Line />
        </Placeholder.Paragraph>
      </Placeholder>
    </div>
  )
}
