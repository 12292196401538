/* eslint-disable no-shadow */
import PropTypes from "prop-types"
import React from "react"
import { Form } from "semantic-ui-react"
import { connect } from "react-redux"
import { handleDropdownChange } from "src/frontend/styles/semantic-ui/helpers"
import {
  selectDateAvailableColumnHeaders,
  selectDateColumnIndex,
} from "src/frontend/scenes/imports/mapping/selectors"
import {
  buildColumnOptions,
  formatAttributeName,
} from "src/frontend/scenes/imports/mapping/helpers"
import { dateColumnChanged } from "src/frontend/scenes/imports/mapping/actions"
import { ImportMappingAttributeType } from "src/backend/enums"
import { Columns } from "src/frontend/scenes/imports/mapping/components/commonProptypes"
import { FormattedMessage } from "src/frontend/modules/intl"

const mapStateToProps = (state) => ({
  dateColumn: selectDateColumnIndex(state),
  availableDateColumns: selectDateAvailableColumnHeaders(state),
})

const mapDispatchToProps = {
  dateColumnChanged: handleDropdownChange(dateColumnChanged),
}

const MappingDateColumnPanel = ({ dateColumn, availableDateColumns, dateColumnChanged }) => (
  <div>
    <p className="info">
      <FormattedMessage id="imports.mapping.wizard.date_description" />
    </p>
    <Form>
      <Form.Dropdown
        label={formatAttributeName(ImportMappingAttributeType.RECORDDATE)}
        value={dateColumn}
        options={buildColumnOptions(availableDateColumns)}
        selection
        selectOnBlur={false}
        onChange={dateColumnChanged}
      />
    </Form>
  </div>
)

MappingDateColumnPanel.propTypes = {
  dateColumn: PropTypes.number.isRequired,
  // eslint-disable-next-line react/no-typos
  availableDateColumns: Columns.isRequired,
  dateColumnChanged: PropTypes.func.isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(MappingDateColumnPanel)
