import { Currency } from 'src/types/Currency'
import { ChartType } from 'src/frontend/components/chart/ChartType'
import { EnvelopeCardinality } from 'src/backend/categories/enums'
import {
  RawSpendingByNatureBarsData,
  SpendingByNatureBarsData,
} from 'src/frontend/scenes/dashboard/widgets/SpendingByNatureBars/types'
import { formatMessage } from 'src/frontend/modules/intl'

export function getSpendingByNature(referentialCurrency: Currency) {
  return (data: RawSpendingByNatureBarsData): SpendingByNatureBarsData => {
    if (!data) {
      return null
    }

    if (!data || !referentialCurrency) {
      return null
    }
    return {
      referentialCurrency,
      barData: {
        ...data.barChartDatasets,
        plainValues: true,
        hideHorizontalLines: true,
        height: undefined,
        type: ChartType.BAR,
        datasets: [
          ...data.barChartDatasets.datasets.map(dataset => {

            return {
              ...dataset,
              id: dataset.id,
              backgroundColor: CARDINALITY_COLOR[dataset.id],
              pointBackgroundColor: CARDINALITY_COLOR[dataset.id],
              borderColor: 'transparent',
              label: formatMessage(`dashboard.widget.cardinality.type.${EnvelopeCardinality[dataset.id]}`),
              type: ChartType.BAR,
            }
          }),
        ],
      },
    }
  }
}

const CARDINALITY_COLOR = {
  [EnvelopeCardinality.MUST]: '#e74c3c',
  [EnvelopeCardinality.NEED]: '#ff9600',
  [EnvelopeCardinality.WANT]: '#1cd88d',
  [EnvelopeCardinality.FIXED_COSTS]: '#e74c3c',
  [EnvelopeCardinality.VARIABLE_COSTS]: '#ff9600',
  [EnvelopeCardinality.ASSETS_COSTS]: '#1cd88d',
}
